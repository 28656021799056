import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import Language from "../../../../../../../services/Language";
import CellContentRoot from "../CellContentRoot";

const TariffCellContent: React.FC<TariffCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(
		() => item.rate?.name?.[language] ?? "",
		[item.rate?.name, language],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace TariffCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default TariffCellContent;
