import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	max-height: 500px;
	min-height: 500px;

	padding: 4px;

	background-color: ${theme.colors.button_secondary};

	overflow: hidden;
`;

export default Root;
