import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const Header = ({ onClick }) => {
	const { t } = useTranslation();
	return (
		<Root justify="space-between">
			{t("orderPageWidgets.chat.usersModal.header.str130") ?? ""}
			<div style={{ cursor: "pointer" }} onClick={onClick}>
				<Icon id="close" size={13} colors={[theme.colors.secondary]} />
			</div>
		</Root>
	);
};

export default Header;
