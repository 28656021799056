import React, { PropsWithChildren, ReactNode } from "react";

import Root from "./components/Root";

const NamedMarker: React.FC<NamedMarker.Props> = ({ name }) => (
	<Root>{name}</Root>
);

declare namespace NamedMarker {
	interface Props extends PropsWithChildren {
		name?: ReactNode;
	}
}

export default NamedMarker;
