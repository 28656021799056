import makeTable from "./makeTable";

const executorTeam = makeTable({
	allIds: ["active", "name", "companies", "branches"],
	defaultIds: ["active", "name", "companies", "branches"],
	initialWidths: {
		active: 100,
		name: 320,
		companies: 220,
		branches: 896,
	},
	i18tPath: (id) => `tableColumns.executorTeams.${id}`,
});

export default executorTeam;
