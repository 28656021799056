import React from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const CloseButton: React.FC<CloseButton.Props> = ({ onClick }) => (
	<Root onClick={onClick}>
		<Icon id="close" size={12} colors={[theme.colors.secondary]} />
	</Root>
);

declare namespace CloseButton {
	interface Props {
		onClick: () => void;
	}
}

export default CloseButton;
