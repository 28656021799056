import React, {
	PropsWithChildren,
	ReactNode,
	forwardRef,
	CSSProperties,
} from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { StyledRow, StyledColumn } from "../common";

const Label = styled(StyledRow)`
	position: absolute;

	top: -9px;

	padding: 0px 5px;

	background: #ffffff;

	font-family: "Lato";
	font-style: normal;
	font-weight: 800;
	font-size: 12px;
	line-height: 18px;

	color: ${(props) => props.color || theme.colors.primary};
`;

const Root = styled(StyledColumn)<FieldsContainer.RootProps>`
	position: relative;
	padding: 10px;
	${(props) => props.label && "padding-top: 14px"};

	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: ${theme.borderRadius.m};

	${(props) => props.gaps && `gap: ${props.gaps}`};
	${(props) => props.maxedWidth && "width: 100%"};
	${(props) => props.maxedHeight && "height: 100%"};
`;

const FieldsContainer = forwardRef<FieldsContainer.Ref, FieldsContainer.Props>(
	({ children, label, labelColor, additionalFields, ...props }, ref) => (
		<Root ref={ref} label={label} {...props}>
			<Label gap="10px" alignItems="center" color={labelColor}>
				<span>{label ?? ""}</span>
				{additionalFields && <span>{additionalFields}</span>}
			</Label>
			{children}
		</Root>
	),
);

declare namespace FieldsContainer {
	type Ref = HTMLDivElement;

	interface Props extends PropsWithChildren, RootProps {
		label?: string;
		labelColor?: string;
		additionalFields?: ReactNode;
	}

	interface RootProps /* extends Column.Props */ {
		label?: string;
		gaps?: string;
		gap?: string;
		maxedWidth?: boolean;
		maxedHeight?: boolean;
		style?: Omit<
			CSSProperties,
			| "flexDirection"
			| "CSSProperties"
			| "flexWrap"
			| "alignItems"
			| "justifyContent"
		>;
	}
}

export default FieldsContainer;
