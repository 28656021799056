import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";

import createRPCQuery from "../utils/createRPCQuery.util";
import { getPRPC } from "../hooks/usePRPC";

import rootReducer from "./reducers/index";

const persistConfig = {
	key: "root",
	storage: {
		...storage,
		setItem: async (key, value) => {
			try {
				const prpcow = getPRPC();

				const isGetSetting = localStorage.getItem("getSetting");

				if (
					isGetSetting === "true" &&
					prpcow?.theirsModel?.dispatcher?.setFrontendStorage
				) {
					localStorage.setItem("getSetting", "false");
					const res = await createRPCQuery(() =>
						prpcow?.theirsModel.dispatcher.setFrontendStorage(
							value,
						),
					);

					if (res.storage) {
						localStorage.setItem("getSetting", "true");
						localStorage.setItem(key, res?.storage);
					}
					return storage.setItem(key, value);
				}

				return await storage.setItem(key, value);
			} catch (error) {
				console.error(
					"[persistedReducer] [setItem] Error saving state to server:",
					error,
				);
			}

			return storage.setItem(key, value);
		},
	},
	whitelist: [
		"session",
		"language",
		"mapSettingsReducer",
		"settings",
		"widgets",
		"tableColumns",
		"orderPageLocalStorage",
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
	devTools:
		process?.env?.NODE_ENV === "development" ||
		process?.env?.NODE_ENV === "test",
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useTypedDispatch = () => useDispatch<RootDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
