import styled from "styled-components";
import { theme } from "uikit";

const Message = styled.div<{ bgColor: string }>`
	padding: 3px 5px;

	height: 24px;

	border-radius: 6px;
	background-color: ${({ bgColor }) =>
		bgColor || theme.colors.button_secondary};
`;

export default Message;
