import React, { memo, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../components/common";

import Mark from "../../../Mark";
import Marker from "../../../Marker";

import PassengerPointDiff from "../index";

const PassengerFeature: React.FC<PassengerFeature.Props> = ({
	feature,
}): JSX.Element => {
	const { i18n } = useTranslation();

	const keysFeature = useMemo<PassengerFeature.Keys | null>(() => {
		if (!feature) return null;
		const keys = Object.keys(feature) as PassengerFeature.Keys;
		if (!keys.length) return null;
		return keys;
	}, [feature]);

	if (!feature) return <></>;

	return (
		<StyledColumn p={"0 0 0 10px"}>
			{keysFeature &&
				keysFeature.map((key) => {
					if (!key || key === "coordinates") return <></>;
					if (!feature[key]) return <></>;

					let value: any = "";

					try {
						if (key === "sector") {
							const name = feature[key]?.name;
							if (name && typeof name === "object") {
								value = name[i18n.language];
							}
						} else value = feature[key];
					} catch {
						value = "";
					}

					if (typeof value === "object") return <></>;

					return (
						<StyledRow key={key}>
							<Marker />
							<Trans
								i18nKey={`change_history.orders.fields.passenger.feature.${key}`}
								context={value}
								values={{ value }}
								components={{ mark: <Mark /> }}
							/>
						</StyledRow>
					);
				})}
		</StyledColumn>
	);
};
declare namespace PassengerFeature {
	interface Props {
		feature: Partial<PassengerPointDiff.Feature> | null;
	}

	type Keys = [keyof PassengerPointDiff.Feature];
}

export const PassengerFeatureMemo = memo(PassengerFeature);
export default PassengerFeature;
