/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";

export enum CurrencyDisplayStyle {
	SYMBOL = "symbol",
	CODE = "code",
	NAME = "name",
}

export const currencyOptions = getOptions(Object.values(CurrencyDisplayStyle));
