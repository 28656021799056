/* eslint-disable no-shadow */

import React, { Dispatch, memo } from "react";
import InternalFilters from "./components/Filters";
import Root from "./components/Root";
import Language from "../../../../../../services/Language";

const Header = memo<Header.Props>(
	({
		filters,

		allowedTaxiServiceIds,
		language,

		onChangeFilters,
	}) => (
		<Root gaps="10px*" align="center" maxedWidth>
			<InternalFilters
				value={filters}
				allowedTaxiServiceIds={allowedTaxiServiceIds}
				language={language}
				onChange={onChangeFilters}
			/>
		</Root>
	),
);

declare namespace Header {
	interface Props {
		filters: InternalFilters.Value;

		allowedTaxiServiceIds: number[];
		language: Language;

		onChangeFilters: Dispatch<InternalFilters.Value>;
	}

	namespace Filters {
		type Value = InternalFilters.Value;
		type Props = InternalFilters.Props;
	}
}

export default Header;
