import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { Option } from "uikit";
import { isEqual } from "lodash";

import Map from "../../../../../../../services/Map";
import useKeyBind from "../../../../../../../hooks/useKeyBind";
import useMapSearch from "../../../../../../../hooks/useMapSearch";
import { Style, StyledP, StyledSpan } from "../../../../../../common";

import { IField, StyledOptionList, StyledTip } from "../../styled";
import settlementToString from "../../utils/settlementToString";

const OverlaySettlementBase: React.FC<OverlaySettlementBase.Props> = ({
	data,
	onSelect,
	activeId,
	setActiveOption,
}) => {
	const { t } = useTranslation();
	const fieldRef = React.useRef<IField | null>(null);
	const styled = useMemo<{ text: Style.TextType; value: Style.TextType }>(
		() => ({
			text: {
				w: "100%",
				colors: "#21333f",
				font: { fw: "400", size: "14px", line: "18px" },
				cursor: "pointer",
				text: { tt: "uppercase" },
			},
			value: {
				colors: "#9BA3A8",
				font: { fw: "300", size: "13px", line: "14px" },
				cursor: "pointer",
				text: { tt: "lowercase" },
			},
		}),
		[],
	);

	const options = useMemo<Option<Map.Search.Object>[]>(() => {
		if (data.loading) return [];

		return data.objects?.map((object) => ({
			key: object.id,
			label: settlementToString(object),
			value: object,
		}));
	}, [data]);

	const onClick = useCallback(
		(option: Option<Map.Search.Object>) => {
			onSelect(option);
		},
		[onSelect],
	);

	useLayoutEffect(() => {
		if (fieldRef.current) {
			fieldRef.current?.scrollIntoView({
				behavior: "smooth",
				inline: "nearest",
				block: "nearest",
			});
		}
	}, [activeId, fieldRef]);

	useKeyBind(["ArrowUp"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev - 1 < 0 ? items - 1 : prev - 1;
		});
	});

	useKeyBind(["ArrowDown"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev + 1 > items - 1 ? 0 : prev + 1;
		});
	});

	useKeyBind(["Enter"], () => {
		if (options && activeId !== -1) onClick(options[activeId]);
	});

	useKeyBind(["Tab"], (event) => {
		if (options && activeId !== -1) onClick(options[activeId]);
		event.stopPropagation();
		event.preventDefault();
	});

	const valueText = useCallback(
		(data: Map.Search.Object) => {
			if (!data) return "";
			const cityTypes = [
				t(
					"pointsTable.pointOrderModalTable.overlays.overlaySettlement.str200",
				) ?? "",
				"city",
				t(
					"pointsTable.pointOrderModalTable.overlays.overlaySettlement.str201",
				) ?? "",
			];
			const type = cityTypes.includes(data?.settlementType || "")
				? `${data.settlementType}`
				: "";
			const district = data.district ? `${data.district},` : "";
			const region = data.region ? `${data.region}` : "";
			return `${type} ${district} ${region}`;
		},
		[t],
	);

	return (
		<StyledOptionList>
			{options?.map((option, i) => (
				<StyledTip
					ref={activeId === i ? fieldRef : null}
					key={`${i + 1}`}
					isActive={activeId === i}
					onClick={(event) => {
						onClick(option);
						event?.stopPropagation?.();
						event?.preventDefault?.();
						setActiveOption(i);
					}}
					flex={{ direction: "column" }}
					h="100%"
				>
					<StyledP
						{...styled.text}
						onClick={(event) => {
							onClick(option);
							event?.stopPropagation?.();
							event?.preventDefault?.();
							setActiveOption(i);
						}}
					>
						{option.label}
					</StyledP>
					<StyledSpan
						{...styled.value}
						onClick={(event) => {
							onClick(option);
							event?.stopPropagation?.();
							event?.preventDefault?.();
							setActiveOption(i);
						}}
					>
						{valueText(option.value)}
					</StyledSpan>
				</StyledTip>
			))}
		</StyledOptionList>
	);
};

declare namespace OverlaySettlementBase {
	interface Props {
		data: useMapSearch.Result;
		onSelect: (option: Option<Map.Search.Object>) => void;
		activeId: number;
		setActiveOption: Dispatch<SetStateAction<number>>;
		debounce?: number;
		query?: string;
	}
}

export const OverlaySettlement = memo(OverlaySettlementBase, (prev, next) => {
	if (prev.activeId !== next.activeId) return false;
	if (prev.query !== next.query) return false;

	if (prev.setActiveOption !== next.setActiveOption) return false;
	if (prev.onSelect !== next.onSelect) return false;

	if (!isEqual(prev.data, next.data)) return false;

	return true;
});
export default OverlaySettlementBase;
