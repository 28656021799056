/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import { SortingOrder } from "../../types/SortingOrder";
import SubscriptionBase from "../../types/Subscription";
import Base from "../Base";
import Language from "../Language";
import Client from "../Client";
import Company from "../Company";

class CustomerRate extends Base {
	static fromResponse(data: any): CustomerRate.Model {
		return {
			id: data?.id,

			customerIds:
				data?.type === CustomerRate.Model.Type.Main
					? data.mainPlanCustomerIds
					: data.additionalPlanCustomerIds,

			companies: data?.companies?.map(Company.fromResponse),
			customers:
				data?.type === CustomerRate.Model.Type.Main
					? data.mainPlanCustomers
					: data.additionalPlanCustomers,

			type: data?.type,
			default: data?.default,
			name: data?.name,
			active: data?.active,

			mode: data?.configuration?.type,
			generalData: data?.configuration?.general,
			modeData: data.configuration?.types,

			updatedAt: data?.updatedAt,
			createdAt: data?.createdAt,
			deletedAt: data?.deletedAt,
		};
	}

	static toRequest(
		model: CustomerRate.Model.New | CustomerRate.Model.Modified,
	): any {
		return {
			name: model.name,
			type: model.type,
			active: model.active,
			default: model.default,

			companyIds: model.companyIds,
			customers: model.customerIds?.map((id) => ({
				id,
				main: model.type === CustomerRate.Model.Type.Main,
			})),

			configuration: {
				type: model.mode,
				general: model.generalData,
				types: model.modeData,
			},
		};
	}

	public static async store(object: CustomerRate.Model.New) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.customerDiscountPlan.create(
				CustomerRate.toRequest(object),
			),
		);
		return data;
	}

	public static async update(object: CustomerRate.Model.Modified) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.customerDiscountPlan.update(
				object.id,
				CustomerRate.toRequest(object),
			),
		);
		return data;
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: CustomerRate.SubscribeOptions,
		onUpdate: SubscriptionBase.OnUpdate<CustomerRate.Model>,
	): Promise<SubscriptionBase<CustomerRate.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.customerDiscountPlan.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "CarModel.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CustomerRate.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as SubscriptionBase<CustomerRate.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) =>
			prpc.theirsModel.customerDiscountPlan.delete(id),
		);
	}

	private static optionsToRequest(options: CustomerRate.SubscribeOptions) {
		return {
			terse: options.terse,
			type: options.type,
			active: options.active,
			default: options.default,
			onlyIds: options.onlyIds,
			companyIds: options.companyIds,
			customerIds: options.customerIds,
			query: options.query,
			limit: options.limit,
			offset: options.offset,
			order: options.order,
		};
	}
}

namespace CustomerRate {
	export interface Model {
		id: number;

		customerIds?: number[];

		companies?: Company.Model[];
		customers?: Client.Model[];

		type: Model.Type;
		default: boolean;
		name: Record<Language, string>;
		active: boolean;

		mode: Model.Mode;
		generalData: Model.GeneralData;
		modeData: Model.ModeData;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions {
		terse?: boolean;
		type?: Model.Type;
		active?: boolean;
		default?: boolean;
		onlyIds?: boolean;
		companyIds?: number[];
		customerIds?: number[];
		query?: string;
		limit?: number;
		offset?: number;
		order?: Record<
			"id" | "name" | "active" | "createdAt" | "updatedAt",
			SortingOrder
		>;
	}

	export namespace Model {
		export enum Type {
			Main = "main",
			Additional = "additional",
		}

		export enum Mode {
			Basic = "basic",
			Fixed = "fixed",
			BonusTrip = "bonusTrip",
			Flexible = "flexible",
			Cumulative = "cumulative",
		}

		export enum Unit {
			Percentage = "percentage",
			Amount = "amount",
		}

		export interface New {
			companyIds?: number[];
			customerIds?: number[];

			type: Type;
			default?: boolean;
			name: Record<Language, string>;
			active?: boolean;

			mode: Mode;
			generalData: GeneralData;
			modeData: ModeData;
		}

		export type Modified = Partial<New> & { id: number };

		export interface ModeData {
			basic?: ModeData.Basic;
			fixed?: ModeData.Fixed;
			bonusTrip?: ModeData.BonusTrip;
			flexible?: ModeData.Flexible;
			cumulative?: ModeData.Cumulative;
		}

		export interface GeneralData {
			maxDiscount: {
				active: boolean;
				value: number;
			};

			executorCompensation: {
				active: boolean;
				unit: Model.Unit;
				value: number;

				maxAmount: {
					active: boolean;
					value: number;
				};
			};

			appliesToMinimal: boolean;
		}

		export namespace ModeData {
			export interface Item {
				threshold: number;

				discount: {
					value: number;
					unit: Model.Unit;
				};

				onlineDiscount: {
					active: boolean;
					value: number;
					unit: Model.Unit;
				};
			}

			export interface Basic {
				grid: Item[];
			}

			export interface Fixed {
				discount: {
					value: number;
					unit: Model.Unit;
				};

				onlineDiscount: {
					active: boolean;
					value: number;
					unit: Model.Unit;
				};
			}

			export interface BonusTrip {
				frequency: number;
			}

			export interface Flexible {
				period: {
					active: boolean;
					value: number;
				};

				grid: Item[];
			}

			export interface Cumulative {
				bonus: {
					frequency: number;
					amount: number;
					unit: Model.Unit;
				};

				onlineBonus: {
					active: boolean;
					frequency: number;
					amount: number;
					unit: Model.Unit;
				};
			}
		}
	}
}

export default CustomerRate;
