import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Button, Column, Icon, Nullable, Row, theme } from "uikit";
import CallButton from "./components/CallButton";
import Keyboard from "../Keyboard";
import { useTypedSelector } from "../../../../../../../../redux/store";
import softphoneWidget, {
	Call,
} from "../../../../../../../../redux/reducers/softphoneWidget";
import { toggleHold, toggleMute } from "../../../../../../../../utils/jsSip";

const CallButtonsContainer = styled(Column)`
	position: relative;
	padding-bottom: 12px;

	border-radius: 12px 12px 0px 0px;
	box-shadow: 0px -2px 6px 0px #0000001a;
`;

const ButtonStyled = styled(Button.Button)<{ active?: boolean }>`
	height: 40px;
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	background-color: ${({ active }) =>
		active ? "#E3F1F7" : theme.colors.transparent};
	&:hover {
		background-color: ${({ active }) =>
			active ? "#E3F1F7" : theme.colors.transparent};
	}
`;

const CallButtonsStyled = styled(Row)`
	padding-top: 12px;
	margin: 0px 12px;
`;

interface Props {
	selectedCall: Nullable<Call>;
	receiveCall: () => void;
	rejectCall: () => void;
}

const CallButtons: React.FC<Props> = ({ selectedCall, receiveCall, rejectCall }) => {
	const dispatch = useDispatch();
	const { showKeyboard, numberQuery, rerender } = useTypedSelector(
		(state) => state.softphoneWidget,
	);

	const onToggleMute = useCallback(() => {
		toggleMute();
		dispatch(softphoneWidget.actions.rerenderSoftphone());
	}, [dispatch]);

	const onToggleHold = useCallback(() => {
		toggleHold();
		dispatch(softphoneWidget.actions.rerenderSoftphone());
	}, [dispatch]);

	const onToggleKeyboard = useCallback(() => {
		dispatch(softphoneWidget.actions.setShowKeyboard(!showKeyboard));
	}, [dispatch, showKeyboard]);

	const openTransfer = useCallback(() => {
		dispatch(softphoneWidget.actions.setTransfer("normal"));
	}, [dispatch]);

	const openBlindTransfer = useCallback(() => {
		dispatch(softphoneWidget.actions.setTransfer("blind"));
	}, [dispatch]);

	const callButtonValidation = useMemo(() => {
		if (numberQuery.trim() !== "") {
			return false;
		}

		if (selectedCall && selectedCall.session) {
			// eslint-disable-next-line camelcase
			const { _is_confirmed, _direction } = selectedCall.session;

			// eslint-disable-next-line camelcase
			if (_is_confirmed) {
				return true;
			}
			if (_direction === "incoming") {
				return false;
			}
			if (_direction === "outgoing") {
				return true;
			}
		}
		return true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberQuery, selectedCall, rerender]);

	return (
		<CallButtonsContainer gaps="8px*">
			{showKeyboard && <Keyboard />}
			<CallButtonsStyled align="center" gaps="8px*">
				<ButtonStyled
					active={selectedCall?.session._localHold}
					onClick={onToggleHold}
					disabled={
						!selectedCall || !selectedCall?.session._is_confirmed
					}
					transparent={
						!selectedCall?.session._is_confirmed || !selectedCall
					}
					icon={
						<Icon
							id="phone-pause"
							size={24}
							colors={
								!selectedCall?.session._is_confirmed ||
								!selectedCall
									? [theme.colors.disabled_text]
									: [
											selectedCall?.session._localHold
												? theme.colors.accent
												: theme.colors.secondary,
									  ]
							}
						/>
					}
				/>
				<ButtonStyled
					active={selectedCall?.session._audioMuted}
					onClick={onToggleMute}
					icon={
						<Icon
							id={
								selectedCall?.session._audioMuted
									? "phone-micro-mute"
									: "phone-micro"
							}
							size={24}
							colors={
								!selectedCall?.session._is_confirmed ||
								!selectedCall
									? [theme.colors.disabled_text]
									: [
											selectedCall?.session._audioMuted
												? theme.colors.accent
												: theme.colors.secondary,
									  ]
							}
						/>
					}
					disabled={!selectedCall?.session._is_confirmed}
					transparent={
						!selectedCall?.session._is_confirmed || !selectedCall
					}
				/>
				<ButtonStyled
					transparent={!selectedCall?.session._is_confirmed}
					disabled={!selectedCall?.session._is_confirmed}
					onClick={openTransfer}
					icon={
						<Icon
							id="phone-redirection"
							size={24}
							colors={
								!selectedCall?.session._is_confirmed
									? [theme.colors.disabled_text]
									: [theme.colors.secondary]
							}
						/>
					}
				/>
				<ButtonStyled
					onClick={openBlindTransfer}
					transparent={!selectedCall?.session._is_confirmed}
					disabled={!selectedCall?.session._is_confirmed}
					icon={
						<Icon
							id="phone-redirection-anonymous"
							size={24}
							colors={
								!selectedCall?.session._is_confirmed
									? [theme.colors.disabled_text]
									: [theme.colors.secondary]
							}
						/>
					}
				/>
				<ButtonStyled
					active={showKeyboard}
					onClick={onToggleKeyboard}
					icon={
						<Icon
							id="phone-keyboard"
							size={24}
							colors={[
								showKeyboard
									? theme.colors.accent
									: theme.colors.secondary,
							]}
						/>
					}
				/>
			</CallButtonsStyled>
			<Row gaps="8px" style={{ margin: "0px 12px" }}>
				<CallButton
					disabled={callButtonValidation}
					bgColor="#4CAF50"
					iconColor={theme.colors.white}
					iconName="call-start"
					onClick={receiveCall}
				/>
				<CallButton
					disabled={!selectedCall}
					bgColor={theme.colors.negative}
					iconColor={theme.colors.white}
					iconName="call-end"
					onClick={rejectCall}
				/>
			</Row>
		</CallButtonsContainer>
	);
};

export default CallButtons;
