import React, { PropsWithChildren, ReactNode } from "react";
import Root from "./components/Root";
import Label from "./components/Label";

const FieldsContainer: React.FC<FieldsContainer.Props> = ({
	children,
	label,
	labelColor,
	additionalFields,
	...props
}) => (
	<Root label={label} {...props}>
		<Label gaps="10px*" align="center" color={labelColor}>
			<span>{label ?? ""}</span>
			{additionalFields && <span>{additionalFields}</span>}
		</Label>
		{children}
	</Root>
);

declare namespace FieldsContainer {
	interface Props extends PropsWithChildren, Root.Props {
		label?: string;
		labelColor?: string;
		additionalFields?: ReactNode;
	}
}

export default FieldsContainer;
