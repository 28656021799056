import constants from "../../constants/Preferences/colors";

/* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *\
|                                                                       |
|   TODO: Delete this reducer and replace with the model subscription   |
|                                                                       |
\* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** */

const initialState = {
	colors: [],
};
export default function colors(state = initialState, action: any = {}) {
	switch (action.type) {
		case constants.setColors:
			return {
				...state,
				colors: action.payload,
			};
		default:
			return state;
	}
}
