import React, { useCallback, useMemo } from "react";
import { theme } from "uikit";
import Circle from "../../../../../../../Circle";
import { checkSipForActivity } from "./helper";
import Root from "./components/Root";
import SIP from "../../../../../../../../services/SIP";

interface Props {
	data: SIP.Model.Available;
	onSubmit: (sipValue: string) => Promise<void>;
}

const Sip: React.FC<Props> = ({ data, onSubmit }) => {
	const circleColor = useMemo(
		() =>
			!checkSipForActivity(data.lastCheckMs)
				? "rgb(76, 175, 80)"
				: theme.colors.disabled_text,
		[data.lastCheckMs],
	);

	const handleSubmit = useCallback(() => {
		onSubmit(data.sipValue);
	}, [data.sipValue, onSubmit]);
	return (
		<Root gaps="5px" align="center" onClick={handleSubmit}>
			<Circle color={circleColor} />
			{data.sipValue}
		</Root>
	);
};

export default Sip;
