import React, { Dispatch, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, Option, Key } from "uikit";
import styled from "styled-components";

import SearchIconBorders from "../../../../../SearchIconBorders";
import SearchTextBox from "../../../../../SearchTextBox";
import { StyledColumn, StyledRow } from "../../../../../common";
import List from "./components/List";

const Group = styled(InputGroup.InputGroup)`
	width: 100%;
`;

const Content = <OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
	onSave,
	pullUpItemInsideArray = false,
}: Content.Props<OptionValue, ValueType>) => {
	const [search, setSearch] = useState("");

	const filteredOptions = useMemo(() => {
		const existItem = options.filter((option) =>
			option.label.toLocaleLowerCase().match(search.toLocaleLowerCase()),
		);
		if (pullUpItemInsideArray) {
			const noExistItem = options.filter(
				(option) =>
					!option.label
						.toLocaleLowerCase()
						.match(search.toLocaleLowerCase()),
			);
			return [...existItem, ...noExistItem];
		}
		return existItem;
	}, [options, pullUpItemInsideArray, search]);

	const { t } = useTranslation();
	return (
		<StyledColumn
			gap="8px"
			alignItems="center"
			w="450px"
			h={{ max: "500px" }}
			bgC="#ffffff"
		>
			<StyledRow w="100%" p="8px 14px">
				<Group sizes="32px auto">
					<SearchIconBorders>
						<Icon id="search" size={16} colors={["#5e6b73"]} />
					</SearchIconBorders>
					<SearchTextBox
						style={{ width: "100%" }}
						value={search}
						onChange={setSearch}
						placeholder={`${
							t("orders.modals.selectModal.content.str200") ?? ""
						}...`}
					/>
				</Group>
			</StyledRow>
			<List
				value={value}
				options={filteredOptions}
				onChange={onChange}
				onSave={onSave}
			/>
		</StyledColumn>
	);
};

declare namespace Content {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;

		options: Option<OptionValue>[];

		onChange: Dispatch<Value<ValueType>>;
		onSave: () => void;
		pullUpItemInsideArray?: boolean;
	}
}

export default Content;
