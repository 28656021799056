/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateSectorsIgnoreOrderType {
	LIVE = "live",
	PRELIMINARY = "preliminary",
	EXECUTING = "executing",
	LOCAL_ORDERS = "local_orders",
	GLOBAL_ORDERS = "global_orders",
}

export const ignoreOrderOptions = getOptions(
	Object.values(AutoRateSectorsIgnoreOrderType),
);
