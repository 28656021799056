/* eslint-disable no-shadow */

import { useCallback, useMemo, useState } from "react";
import { Nullable } from "uikit";
import { isNumber } from "lodash";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import ModelList from "../components/ModelList";
import CarModel from "../../../../../../../services/CarModel";
import { SortingOrder } from "../../../../../../../types/SortingOrder";

function useCarModelModelList(options: useCarModelModelList.Options) {
	const [offset, setOffset] = useState(0);
	const [chunkSize, setChunkSize] = useState(30);
	const [selected, setSelected] = useState<Nullable<number>>(null);
	const [sort, setSort] = useState<SortingOrder>("ASC");

	const carModelSubscribeOptions = useMemo<CarModel.SubscribeOptions>(
		() => ({
			offset,
			limit: chunkSize,

			carBaseTypeIds: isNumber(options.selectedCarBaseTypeId)
				? [options.selectedCarBaseTypeId]
				: [],
			carBrandIds: isNumber(options.selectedCarBrandId)
				? [options.selectedCarBrandId]
				: [],

			query: options.search,
			active: options.active,
			favorite: options.favorite,
			native: options.native,

			order: {
				name: sort,
			},
		}),
		[
			offset,
			chunkSize,
			options.selectedCarBaseTypeId,
			options.selectedCarBrandId,
			options.search,
			options.active,
			options.favorite,
			options.native,
			sort,
		],
	);

	const carModelData = useModelSubscribe(carModelSubscribeOptions, CarModel);

	const carModelModels = useMemo(
		() => carModelData.models ?? [],
		[carModelData.models],
	);

	const carModelTotal = useMemo(
		() => (carModelData.metadataState.total ?? 0) as number,
		[carModelData.metadataState.total],
	);

	const carModelItems = useMemo<ModelList.Item[]>(
		() =>
			carModelModels.map((model) => ({
				id: model.id,

				disabled:
					!options.isSelectedCarBaseTypeActive ||
					!options.isSelectedCarBrandActive,

				active: model.active,
				// deletable: !model.native,
				favorite: model.favorite,
				label: model.name,
			})),
		[
			carModelModels,
			options.isSelectedCarBaseTypeActive,
			options.isSelectedCarBrandActive,
		],
	);

	const selectedSubscribeOptions = useMemo<CarModel.SubscribeOptions>(
		() => ({
			ids: isNumber(selected) ? [selected] : [],
		}),
		[selected],
	);

	const selectedData = useModelSubscribe(selectedSubscribeOptions, CarModel);

	const selectedModels = useMemo(
		() => selectedData.models ?? [],
		[selectedData.models],
	);

	const selectedItem = useMemo(() => selectedModels.at(0), [selectedModels]);

	const isSelectedActive = useMemo(
		() => selectedItem?.active ?? false,
		[selectedItem],
	);

	const isSelectedNative = useMemo(
		() => selectedItem?.native ?? false,
		[selectedItem],
	);

	const onChangeActive = useCallback((id: number, active: boolean) => {
		CarModel.update({ id, active });
	}, []);

	const onChangeFavorite = useCallback((id: number, favorite: boolean) => {
		CarModel.update({ id, favorite });
	}, []);

	const onDelete = useCallback(async (id: number) => {
		await CarModel.destroy(id);
	}, []);

	return {
		offset,
		setOffset,

		chunkSize,
		setChunkSize,

		selected,
		setSelected,

		sort,
		setSort,

		data: carModelData,
		models: carModelModels,
		total: carModelTotal,
		items: carModelItems,

		isSelectedActive,
		isSelectedNative,

		onChangeActive,
		onChangeFavorite,

		onDelete,
	};
}

declare namespace useCarModelModelList {
	interface Options {
		search?: string;

		active?: boolean;
		favorite?: boolean;
		native?: boolean;

		isSelectedCarBaseTypeActive: boolean;
		isSelectedCarBrandActive: boolean;

		selectedCarBaseTypeId: Nullable<number>;
		selectedCarBrandId: Nullable<number>;
	}
}

export default useCarModelModelList;
