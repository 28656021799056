import { Dispatch } from "react";
import { react } from "uikit";
import TaxiServices from ".";

interface Context {
	value: TaxiServices.Value;

	setCompanyIdsError: Dispatch<boolean>;
	setTaxiServiceIdsError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const areCompanyIdsValid = this.context?.value.companyIds.length !== 0;
		const areTaxiServiceIdsValid =
			this.context?.value.taxiServiceIds.length !== 0;

		if (!areCompanyIdsValid) this.context?.setCompanyIdsError(true);
		if (!areTaxiServiceIdsValid) this.context?.setTaxiServiceIdsError(true);

		return areCompanyIdsValid && areTaxiServiceIdsValid;
	}
}
