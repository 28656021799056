import React, { useMemo, Suspense } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Column, Row, theme } from "uikit";
import styled from "styled-components";

import { useTypedSelector } from "../../../../../../redux/store";
import useDateTimeLocalizer from "../../../../../../hooks/useDateLocalizer";
import { Archive } from "../../../../../../redux/reducers/Archives/interface";

import Mark from "../../Mark";
import HistoryBadges from "../../Badges/HistoryBadges";
import useDescribeChanges from "../../useDescribeChanges";

const StyledAction = styled(Column)`
	padding-inline: 10px;
	padding-block: 5px;
	background-color: ${theme.colors.background_color};

	& * {
		white-space: pre-wrap;
	}
`;

const ActionDescription: React.FC<ActionDescription.Props> = ({
	entry,
	displayBadges,
}) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);
	const describeChanges = useDescribeChanges(language, displayBadges);
	const localizeDateTime = useDateTimeLocalizer();

	const time = useMemo(
		() => localizeDateTime(new Date(entry.timestamp), "dateTime"),
		[entry.timestamp, localizeDateTime],
	);

	const { user, userType } = useMemo(() => {
		const name = String(entry.user?.name).toLowerCase();
		const userType = t(`change_history.${entry?.user?.type || "system"}`);
		const names = [name, entry.user?.fatherName];
		const user = entry.user ? names.filter((s) => !!s).join(", ") : "";
		return {
			user,
			userType: userType === "change_history.undefined" ? "" : userType,
		};
	}, [entry.user, t]);

	const action = useMemo(
		() => (
			<Trans
				i18nKey={`change_history.actions.${entry.action}`}
				context="past"
				values={{ user: userType }}
				components={{ mark: <Mark /> }}
			/>
		),
		[entry.action, userType],
	);

	const changes = useMemo(() => {
		if (entry.changes?.length === 0) return null;

		return describeChanges(entry.changes)
			.map((change, i) => {
				if (!change?.props?.children) return null;
				return (
					<Row key={i} style={{ paddingLeft: "1rem" }} gaps="2px*">
						<Suspense fallback="Loading...">
							<div key={i}>{change}</div>
						</Suspense>
					</Row>
				);
			})
			.filter((item) => item !== null);
	}, [describeChanges, entry]);

	return (
		<>
			{!!changes?.length && (
				<StyledAction justify="space-between">
					<>
						<Row align="center">
							<b>{time}</b>
							{` - `}
							{action}
						</Row>
						{user && (
							<Row align="center">
								<Trans
									i18nKey={`change_history.actions.name`}
									context="past"
									values={{
										user:
											user || t(`change_history.system`),
									}}
									components={{ mark: <Mark /> }}
								/>
							</Row>
						)}
					</>

					{changes}
				</StyledAction>
			)}
		</>
	);
};

declare namespace ActionDescription {
	interface Props {
		entry: Archive.Orders.Order.History;
		displayBadges?: HistoryBadges.DisplayBadges;
	}
}

export default ActionDescription;
