import { RefObject } from "react";
import { react } from "uikit";
import NameAndCompanyData from ".";

interface Context {
	nameAndCompanyDataRef: RefObject<NameAndCompanyData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameAndCompanyDataRefValid =
			!!this.context?.nameAndCompanyDataRef.current?.validate();

		return isNameAndCompanyDataRefValid;
	}
}
