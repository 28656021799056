/* eslint-disable import/no-unresolved */

import { SliderData } from "uikit/dist/components/OrderTabs";

export default function createEmptyTab(): SliderData {
	return {
		label: "New order",
		type: "order",
		number: 0,
		color: "gray",
	};
}
