import { getPRPC } from "../../../../../hooks/usePRPC";
import defaultValidationsValue from "../../../../../pages/Settings/pages/Message/Provider/constants/defaultValidationsValue";
import { validResponse } from "../../../../../pages/Settings/pages/Message/Provider/providerHelper";
import createRPCQuery from "../../../../../utils/createRPCQuery.util";

export default function updateProviderSms(
	id,
	value,
	closeModal = () => {},
	setValidation: (x: any) => void = () => {},
) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() =>
			prpcow?.theirsModel.sms.provider.update(id, value),
		)
			.then(async () => {
				setValidation(defaultValidationsValue);
				closeModal();
			})
			.catch((error) => {
				const isValid = validResponse(error.message);
				setValidation(isValid);
			});
	};
}
