import React, {
	RefAttributes,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { react, useRefWithSetter } from "uikit";

import Tariff from "../../../../../../../../services/Tariff";
import CarClass from "../../../../../../../../services/CarClass";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditablePropertyNames } from "../../../../../../../../types/NonEditableProperties";
import EditModalBase from "../../../../../../../../components/EditModal";
import ModalRoot from "../../../../components/ModalRoot";

import { MainTariffTabs } from "./tabs";
import InternalController from "./Controller";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		tariffs,
		value,
		onCancel,
		headerTitle,
		createdAt,
		onSave,
		carClasses,
		controller,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const [tab, setTab] = useState<MainTariffTabs>(MainTariffTabs.MAIN);
		const [internalValue, setInternalValue] = useState(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);
		const contentData = valueEditor.usePicker(
			[
				"name",
				"additionalFields",
				"services",
				"taxiServiceIds",
				"taxiServices",
				"carClassIds",
				"companyIds",
			],
			"json",
		);

		const footerValue = valueEditor.usePicker(
			["active", "default", "carClassIds", "id"],
			"json",
		);
		const root = valueEditor.get("root");

		const isDefault = valueEditor.get("default");
		const setCarClassIds = valueEditor.useSetter("carClassIds");

		useLayoutEffect(() => {
			setCarClassIds(value.carClassIds);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.carClassIds]);

		const contentOnChange = valueEditor.useAssigner();

		const defaultTariffWithAllCarClassesAndOneBranch = useMemo(
			() =>
				tariffs.find((tariff) => {
					if (tariff.default) return false;

					if (tariff.taxiServiceIds.length !== 1) return false;
					if (
						tariff.taxiServiceIds[0] !==
						internalValue.taxiServiceIds[0]
					)
						return false;
					if (carClasses.length !== tariff.carClassIds?.length) {
						return false;
					}

					return true;
				}),
			[carClasses, internalValue, tariffs],
		);

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue);
		}, [contentRef, internalValue, onSave]);

		return (
			<EditModalBase
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Footer
						root={root}
						value={footerValue}
						onChange={contentOnChange}
						carClasses={carClasses}
					/>
				}
			>
				<ModalRoot sizes="auto! 1fr">
					<Header
						tab={tab}
						title={headerTitle}
						createdAt={createdAt}
						lockedBy={""}
						onChangeTab={setTab}
					/>
					<Content
						defaultTariffWithAllCarClassesAndOneBranch={
							defaultTariffWithAllCarClassesAndOneBranch
						}
						root={root}
						tariffs={tariffs}
						carClasses={carClasses}
						ref={setContentRef}
						tab={tab}
						disabled={false}
						value={contentData}
						onChange={contentOnChange}
						isDefault={isDefault}
					/>
				</ModalRoot>
			</EditModalBase>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		tariffs: Tariff.Model[];
		value: Value;
		headerTitle: string;
		createdAt: string;
		onCancel: () => void;
		onSave: (tariff: Tariff.New | Tariff.Modified) => void;
		carClasses: CarClass.Model[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Content.Value &
		Partial<Pick<Tariff.Model, NonEditablePropertyNames>> &
		Footer.Value & { root: boolean; companyIds: number[] };
}

export default Modal;
