import React, { HTMLAttributes } from "react";
import { Icon } from "uikit";
import Root from "./components/Root";

const ClearButton: React.FC<ClearButton.Props> = (props) => (
	<Root {...props}>
		<Icon id="close-modal" size={12} />
	</Root>
);

declare namespace ClearButton {
	interface Props extends HTMLAttributes<HTMLDivElement> {}
}

export default ClearButton;
