import moment from "moment";

import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";

export async function destroyOne(id: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() => prpc.theirsModel.sector.delete(id));
}

export function stringTimeToMilliseconds(time: Date, utc = false) {
	return moment(time).utc(utc).diff(moment(time).utc().startOf("day"));
}

export function timeDateUTS(timestamp: number, utc = true) {
	const newDate = new Date();
	newDate.setHours(0, 0, 0, 0);
	const time = moment(newDate).utc(utc).add(timestamp).format(`HH:mm:ss`);
	return time;
}

export function numberTimeToMilliseconds(time: number, utc = false) {
	return moment(time).utc(utc).diff(moment(time).utc().startOf("day"));
}

export function millisecondsToDateUTS(timestamp: number, utc = true) {
	const newDate = new Date();
	newDate.setHours(0, 0, 0, 0);
	const time = moment(newDate)
		.utc(utc)
		.add(timestamp, "milliseconds")
		.toDate();
	return time;
}

export function millisecondsToDate(timestamp: number) {
	const newDate = new Date();
	newDate.setHours(0, 0, 0, 0);
	const time = moment(newDate).add(timestamp).toDate();
	return time;
}

export default {
	destroyOne,
	stringTimeToMilliseconds,
	millisecondsToDateUTS,
	millisecondsToDate,
	timeDateUTS,
};
