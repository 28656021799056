import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { EntryList } from "../types/settingEntries";
import { useTypedDispatch, useTypedSelector } from "../redux/store";
import fonts, { fontMeta } from "../redux/reducers/settings/fonts";
import keybinds, { keybindMeta } from "../redux/reducers/settings/keybinds";
import preorders, { preOrderMeta } from "../redux/reducers/settings/preorders";
import mapSettings, { mapSettingsMeta } from "../redux/reducers/settings/map";
import executorAppGeneral, {
	executorAppGeneralMeta,
} from "../redux/reducers/settings/executorApp/general";
import executorAppInterfaceGeneral, {
	executorAppInterfaceGeneralMeta,
} from "../redux/reducers/settings/executorApp/interface/general";
import executorAppInterfaceOrders, {
	executorAppInterfaceOrdersMeta,
} from "../redux/reducers/settings/executorApp/interface/orders";
import executorAppInterfaceMap, {
	executorAppInterfaceMapMeta,
} from "../redux/reducers/settings/executorApp/interface/map";

function fixState<S extends EntryList<any, any, any>>(
	state: S,
	initialState: S,
) {
	const stateWithoutUndefined = {
		...initialState,
		...state,
	};

	Object.keys(stateWithoutUndefined).forEach((key) => {
		if (initialState[key] === undefined) {
			delete stateWithoutUndefined[key];
		}
	});

	return stateWithoutUndefined;
}

function useSettingsInit() {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	// All states
	const fontsState = useTypedSelector((state) => state.settings.fonts);
	const keybindsState = useTypedSelector((state) => state.settings.keybinds);
	const preordersState = useTypedSelector(
		(state) => state.settings.preorders,
	);
	const mapSettingsState = useTypedSelector((state) => state.settings.map);
	const executorAppGeneralState = useTypedSelector(
		(state) => state.settings.executorAppGeneral,
	);
	const executorAppInterfaceGeneralState = useTypedSelector(
		(state) => state.settings.executorAppInterfaceGeneral,
	);
	const executorAppInterfaceOrdersState = useTypedSelector(
		(state) => state.settings.executorAppInterfaceOrders,
	);
	const executorAppInterfaceMapState = useTypedSelector(
		(state) => state.settings.executorAppInterfaceMap,
	);

	useEffect(() => {
		// Translation fetcher
		[
			fontMeta,
			keybindMeta,
			preOrderMeta,
			mapSettingsMeta,
			executorAppGeneralMeta,
			executorAppInterfaceGeneralMeta,
			executorAppInterfaceOrdersMeta,
			executorAppInterfaceMapMeta,
		].forEach((meta) => {
			Object.keys(meta).forEach((k) => {
				const entry = meta[k];

				if (entry.type === "togglegroup") {
					entry.labels.displaySelf = t(entry.labels.displaySelf);

					Object.keys(entry.labels.display).forEach((key) => {
						entry.labels.display[key] = t(
							entry.labels.display[key],
						);
					});

					return;
				}

				if (
					entry.type !== "select" &&
					entry.type !== "multiselect" &&
					entry.type !== "orderselect"
				)
					return;

				entry.options.forEach((option) => {
					// eslint-disable-next-line no-param-reassign
					option.label = t(option.label);
				});
			});
		});
	}, [
		t,
		fontsState,
		keybindsState,
		preordersState,
		mapSettingsState,
		executorAppGeneralState,
		executorAppInterfaceOrdersState,
		executorAppInterfaceMapState,
		executorAppInterfaceGeneralState,
		dispatch,
	]);

	useEffect(() => {
		// State fixer (kinda works)
		dispatch(
			fonts.actions.setAll(fixState(fontsState, fonts.getInitialState())),
		);
		dispatch(
			keybinds.actions.setAll(
				fixState(keybindsState, keybinds.getInitialState()),
			),
		);
		dispatch(
			preorders.actions.setAll(
				fixState(preordersState, preorders.getInitialState()),
			),
		);
		dispatch(
			mapSettings.actions.setAll(
				fixState(mapSettingsState, mapSettings.getInitialState()),
			),
		);
		dispatch(
			executorAppGeneral.actions.setAll(
				fixState(
					executorAppGeneralState,
					executorAppGeneral.getInitialState(),
				),
			),
		);
		dispatch(
			executorAppInterfaceGeneral.actions.setAll(
				fixState(
					executorAppInterfaceGeneralState,
					executorAppInterfaceGeneral.getInitialState(),
				),
			),
		);
		dispatch(
			executorAppInterfaceOrders.actions.setAll(
				fixState(
					executorAppInterfaceOrdersState,
					executorAppInterfaceOrders.getInitialState(),
				),
			),
		);
		dispatch(
			executorAppInterfaceMap.actions.setAll(
				fixState(
					executorAppInterfaceMapState,
					executorAppInterfaceMap.getInitialState(),
				),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

export default useSettingsInit;
