import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Row, Button, Icon } from "uikit";

import { useArchiveFilters, useArchiveSwitcher } from "../../../../../../hooks";

const RightPart: React.FC<RightPart.Props> = (): JSX.Element => {
	const {
		orderFilters: {
			internalDateRange,
			filter: { dateFrom, dateTo },
		},
		modifyFilter,
	} = useArchiveFilters();

	const {
		orderToggles,
		setSearchToggle,
		setFilterModalToggle,
		setExportModalToggle,
	} = useArchiveSwitcher();

	const applyDateRange = useCallback(() => {
		modifyFilter({
			dateFrom: internalDateRange.from,
			dateTo: internalDateRange.to,
		});
	}, [modifyFilter, internalDateRange]);

	const dateRangeHasChanges = useMemo(() => {
		if (
			dateFrom.getTime() !== internalDateRange.from.getTime() ||
			dateTo.getTime() !== internalDateRange.to.getTime()
		) {
			return true;
		}

		return false;
	}, [dateFrom, dateTo, internalDateRange.from, internalDateRange.to]);

	useEffect(() => {
		if (dateRangeHasChanges) setSearchToggle("on");
		else setSearchToggle("off");
	}, [dateRangeHasChanges, setSearchToggle]);

	const openFiltersModal = useCallback(() => {
		setFilterModalToggle("toggle");
	}, [setFilterModalToggle]);

	const openExportModal = useCallback(() => {
		setExportModalToggle("toggle");
	}, [setExportModalToggle]);

	return (
		<Row gaps="5px*" align="center">
			<Button.Button
				variant="primary"
				focused={false}
				disabled={!dateRangeHasChanges}
				icon={<Icon id="search" size={20} />}
				onClick={applyDateRange}
			/>
			<Button.Button
				variant={
					orderToggles.isArchiveFilterModal ? "primary" : "secondary"
				}
				icon={<Icon id="filters" size={20} />}
				onClick={openFiltersModal}
				focused={false}
			/>

			<Button.Button
				variant={orderToggles.isExport ? "primary" : "secondary"}
				icon={<Icon id="export-page" size={20} />}
				onClick={openExportModal}
				focused={false}
			/>
		</Row>
	);
};

export const RightPartMemo = memo(RightPart);

declare namespace RightPart {
	interface Props {}
}

export default RightPart;
