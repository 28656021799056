import React, { memo, useCallback } from "react";
import { Icon } from "uikit";
import Root from "./components/Root";
import { useTypedDispatch } from "../../../../../../redux/store";
import Session from "../../../../../../redux/services/Session";

const Logout = memo(() => {
	const dispatch = useTypedDispatch();

	const logout = useCallback(() => {
		dispatch(Session.logout());

		sessionStorage.removeItem("sipData");
	}, [dispatch]);

	return (
		<Root>
			<div onClick={logout}>
				<Icon id="headerLogout" size={24} colors={["#bcc1c5"]} />
			</div>
		</Root>
	);
});

export default Logout;
