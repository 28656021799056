import React, { CSSProperties, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon } from "uikit";
import { isNumber } from "lodash";

import { IOrderPoint } from "../../redux/constants/OrdersPage/order";
import { useTypedSelector } from "../../redux/store";
import { useKeyBindSettings } from "../../hooks/useKeyBindSettings";
import LegacyTableColumn from "../../types/TableColumn";
import { Style, StyledColumn, Button, StyledRow } from "../common";
import { generateAccessName, AccessKey, hasAccess } from "../../access";

import { formatTableOrderPoint, getPointTableSchema } from "./schema";
import PointOrderModalTable from "./components/PointOrderModalTable";

const Navigation = styled(StyledColumn)`
	display: flex;
	flex-direction: ${(props) => props.dir};
	gap: 3px;
	padding: 3px 6px;
	flex-wrap: wrap;
	overflow-x: hidden;
`;

const StyledTableOrder = styled.div`
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	height: 100%;
	position: relative;
	justify-content: space-between;

	.rs-table-bordered {
		border: none;
	}

	div[role="row"] {
		border: 1px solid #f2f2f5;
	}
`;

const PointsTable: React.FC<PointsTable.Props> = ({
	data,
	activeRow,
	whereCalled,
	disabled,
	error,
	tableHeaderHeight = 26,
	tableRowHeight = 32,
	tableSchema,
	height = "100%",
	navWrap = "nowrap",
	customerId,
	setData,
	onRowClick = () => {},
	onAddBtnClick = () => {},
	onDelete = () => {},
	onEdit = () => {},
	onUpActivePoint = () => {},
	onDownActivePoint = () => {},
	onColumnResize,
	onAddRow,
	onInsertPoint = () => {},
	searchTypes,
	taxiServiceId,
}) => {
	const I18_BASE_URL = "orderPage.previewer";
	const { t } = useTranslation();

	const pointTableSchema = useMemo(() => getPointTableSchema(t), [t]);

	const _tableSchema = tableSchema ?? pointTableSchema;

	const language = useTypedSelector((state) => state.session.language);
	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_SECTION = [
			AccessKey.ORDERS,
			AccessKey.ORDER_MODAL,
			AccessKey.POINT_CONTROL_PANEL,
		];

		const retval = {
			addPoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.ADD_POINT),
				personalRole,
			),
			insertPoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.INSERT_POINT),
				personalRole,
			),
			editPoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.EDIT_POINT),
				personalRole,
			),
			deletePoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.DELETE_POINT),
				personalRole,
			),
			movePointUp: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.MOVE_POINT_UP),
				personalRole,
			),
			movePointDown: hasAccess(
				generateAccessName(
					...ACCESS_SECTION,
					AccessKey.MOVE_POINT_DOWN,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const tableData = useMemo(() => {
		// console.log("[Street] 19", data);
		const payload = [...data].map(
			(
				item: IOrderPoint & {
					save?: boolean;
					saveType?: PointOrderModalTable.SaveType;
					id?: number;
				},
				index,
			) =>
				formatTableOrderPoint(
					{
						...item,
						save: item?.save || false,
						saveType: item?.saveType,
						id: index + 1,
					},
					language,
				),
		);

		// console.log("[Street] 20", payload);
		return payload;
	}, [data, language]);

	const setDataMemorized = useCallback(
		(internalData: any[] = []) => {
			const payload = [...internalData]
				.map((item, i, arr) => {
					const raw = item?.raw;
					if (i === 0 && arr[i + 1]) raw.type = "address";
					if (raw?.type === "favorite") raw.type = "address";

					return raw;
				})
				.filter((item, i, arr) => {
					if (i === 0 && arr[i + 1]) {
						const arrItem = arr[i + 1];
						// if the second line contains data
						if (arrItem.settlement) return true;
						if (arrItem.settlement && arrItem.street) return true;
						if (arrItem.settlement && arrItem.name) return true;
					}
					if (item.settlement && item.settlementType) return true;
					if (item.settlement && item.save) return true;
					if (item.settlement && item.street) return true;
					if (item.settlement && item.save && item.name) return true;
					if (item.settlement && item.save && item.title) return true;

					return false;
				});

			setData?.(payload);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setData, data],
	);

	const setSwapFirstPointForLastPoint = useCallback(() => {
		const payload = [...tableData]
			.map((item, i, arr) => {
				const raw = item?.raw;
				if (i === 0 && arr[i + 1]) raw.type = "address";
				if (raw?.type === "favorite") raw.type = "address";
				return raw;
			})
			.filter((item, i, arr) => {
				if (i === 0 && arr[i + 1]) {
					const arrItem = arr[i + 1];
					// if the second line contains data
					if (arrItem.settlement) return true;
					if (arrItem.settlement && arrItem.street) return true;
					if (arrItem.settlement && arrItem.name) return true;
				}
				if (item.settlement && item.settlementType) return true;
				if (item.settlement && item.save) return true;
				if (item.settlement && item.street) return true;
				if (item.settlement && item.save && item.name) return true;
				return false;
			});

		if (!payload.length || payload.length <= 1) return;
		const first = payload.pop();
		const last = payload.shift();
		setData?.([first, ...payload, last]);
	}, [setData, tableData]);

	const onClickDelete = useCallback(() => {
		!disabled && onDelete?.(activeRow || 0);
		!disabled && onAddRow?.(tableData.length - 1);
	}, [activeRow, disabled, onAddRow, onDelete, tableData.length]);

	const handleRowClick = useCallback(
		(data) => {
			onRowClick?.(data.id - 1);
		},
		[onRowClick],
	);

	useKeyBindSettings("orderModalFocusPointUp", (event) => {
		if (!disabled) {
			if (onRowClick && isNumber(activeRow)) {
				const dataLength = data.length;

				if (activeRow <= 0) {
					onRowClick?.(dataLength - 1);
					return;
				}
				onRowClick?.(activeRow - 1);
			}
		}

		event.preventDefault();
		event.stopPropagation();
	});

	useKeyBindSettings("orderModalFocusPointDown", (event) => {
		if (!disabled) {
			if (onRowClick && isNumber(activeRow)) {
				const dataLength = data.length;
				if (dataLength === activeRow + 1) {
					onRowClick?.(0);
					return;
				}
				onRowClick?.(activeRow + 1);
			}
		}

		event.preventDefault();
		event.stopPropagation();
	});

	useKeyBindSettings("orderModalSwapFirstPointForLastPoint", (event) => {
		if (!disabled) {
			if (onRowClick && isNumber(activeRow)) onRowClick?.(0);

			setSwapFirstPointForLastPoint();
		}
		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<StyledColumn
			h={height}
			flex={{
				wrap: "nowrap",
				direction: "row",
			}}
			justify="space-between"
		>
			<Navigation
				w={{ max: "40px" }}
				h="max-content"
				over="hidden"
				flex={{
					direction: "column",
					wrap: navWrap,
				}}
			>
				{AccessBtnGroup.addPoint && (
					<Button
						tabIndex={-1}
						// disabled={disabled}
						disabled
						variant="primary"
						settingsKeyId="orderModalAddPoint"
						title={`${t([
							`${I18_BASE_URL}.addPoint`,
							"Add a dot",
						])}`}
						onClick={(...args) =>
							!disabled && onAddBtnClick?.(...args)
						}
					>
						<Icon id="plus" size={16} />
					</Button>
				)}
				{AccessBtnGroup.insertPoint && (
					<Button
						tabIndex={-1}
						disabled
						// disabled={disabled}
						variant="secondary"
						settingsKeyId="orderModalInsertPoint"
						title={`${t([
							`${I18_BASE_URL}.pastePoint`,
							"Insert a dot",
						])}`}
						onClick={() => {
							!disabled && onInsertPoint?.();
						}}
					>
						<Icon id="clipboard" size={20} />
					</Button>
				)}
				{AccessBtnGroup.editPoint && (
					<Button
						tabIndex={-1}
						// disabled={disabled}
						disabled
						variant="secondary"
						settingsKeyId="orderModalEditPoint"
						title={`${t([
							`${I18_BASE_URL}.editPoint`,
							"Edit the dot",
						])}`}
						onClick={(...args) => {
							!disabled && onEdit?.(...args);
						}}
					>
						<Icon id="pencil" size={20} />
					</Button>
				)}
				{AccessBtnGroup.deletePoint && (
					<Button
						tabIndex={-1}
						disabled={disabled}
						variant="secondary"
						settingsKeyId="orderModalDeletePoint"
						title={`${t([
							`${I18_BASE_URL}.deletePoint`,
							"Remove the dot",
						])}`}
						onClick={() => !disabled && onClickDelete?.()}
					>
						<Icon id="trash" size={20} />
					</Button>
				)}
				<StyledColumn gap="1px">
					{AccessBtnGroup.movePointUp && (
						<Button
							tabIndex={-1}
							disabled={disabled}
							variant="secondary"
							settingsKeyId="orderModalMovePointUp"
							title={t("pointsTable.str0") ?? ""}
							onClick={(...args) => {
								!disabled && onUpActivePoint?.(...args);
							}}
							h="16px"
							css="
							border-bottom-left-radius: 0px;
							border-bottom-right-radius: 0px;
						"
						>
							<Icon id="arrow-up" size={16} />
						</Button>
					)}

					{AccessBtnGroup.movePointDown && (
						<Button
							tabIndex={-1}
							disabled={disabled}
							variant="secondary"
							settingsKeyId="orderModalMovePointDown"
							title={t("pointsTable.str1") ?? ""}
							onClick={(...args) => {
								!disabled && onDownActivePoint?.(...args);
							}}
							h="16px"
							css="
							border-top-left-radius: 0px;
							border-top-right-radius: 0px;
						"
						>
							<Icon id="arrow-down" size={16} />
						</Button>
					)}
				</StyledColumn>
			</Navigation>

			<StyledColumn w="100%" over="hidden">
				<StyledRow
					flex={{ grow: 1 }}
					over="hidden"
					b={error ? "1px solid #ed4c5c" : "1px solid transparent"}
				>
					<StyledTableOrder>
						<PointOrderModalTable
							activeClass
							activeRow={activeRow! + 1}
							handleRowClick={handleRowClick}
							customerId={customerId}
							disabled={disabled}
							headerHeight={tableHeaderHeight}
							rowHeight={tableRowHeight}
							defaultColumns={_tableSchema}
							setData={setDataMemorized}
							data={tableData}
							tableLoading={false}
							handleColumnResize={onColumnResize}
							containerId={whereCalled}
							onAddRow={onAddRow}
							onDelete={onDelete}
							searchTypes={searchTypes}
							taxiServiceId={taxiServiceId}
						/>
					</StyledTableOrder>
				</StyledRow>
			</StyledColumn>
		</StyledColumn>
	);
};

declare namespace PointsTable {
	interface Props {
		data: IOrderPoint[];
		customerId?: number;
		activeRow?: number;
		disabled?: boolean;
		error?: boolean;
		allowVertical?: boolean;
		displayFooter?: boolean;
		onRowClick?: (index: number) => void;
		onAddBtnClick?: (...args: any[]) => void;
		onDelete?: (index) => void;
		setData?: (newArray) => void;
		onPastePointBtnClick?: (...args: any[]) => void;
		onEdit?: (...args: any[]) => void;
		onUpActivePoint?: (...args: any[]) => void;
		onDownActivePoint?: (...args: any[]) => void;
		whereCalled: "previewer" | "orderModal";
		height?: Style.BaseType["h"];
		navWrap?: CSSProperties["flexWrap"];
		tableHeaderHeight?: number;
		tableRowHeight?: number;
		tableSchema?: LegacyTableColumn<string>[];
		onColumnResize?: (width?: number, columnKey?: string | number) => void;
		onAddRow?: (num: number) => void;
		onInsertPoint?: () => void;
		searchTypes?: PointOrderModalTable.Props["searchTypes"];
		taxiServiceId?: PointOrderModalTable.Props["taxiServiceId"];
	}
}

export const PointsTableMemo = memo(PointsTable);

export default PointsTable;
