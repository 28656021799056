const defaultValidationsValue = [
	{ fieldName: "name", required: true },
	{ fieldName: "host", required: true },
	{ fieldName: "port", required: true },
	{ fieldName: "login", required: true },
	{ fieldName: "password", required: true },
	{ fieldName: "alphaName", required: true },
];

export default defaultValidationsValue;
