import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBoxWithContent,
	Column,
	MultiSelect,
	Row,
	react,
	useRefWithSetter,
} from "uikit";

import Language from "../../../../../../../../../../../../services/Language";
import TaxiService from "../../../../../../../../../../../../services/TaxiService2";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../hooks";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import {
	ValueStepper,
	CompaniesAndTaxiServices,
	CompaniesAndTaxiServicesBase,
	Name,
	NameBase,
	StyledGrid,
} from "../../../../../../../../../../../../components/common";
import TabRoot from "../TabRoot";
import ComparisonSelect from "../ComparisonSelect";

import InternalController from "./Controller";
import { getOrderTypeOptions, OrderTypeOptions } from "./data";

const MainTabBase: React.FC<MainTab.Props> = react.withController<
	MainTab.PropsBase,
	MainTab.Controller
>(({ value, visible, onChange, controller }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const orderTypeOptions = useMemo(() => getOrderTypeOptions(t), [t]);

	const [companyBranchRef, setCompanyBranchRef] =
		useRefWithSetter<CompaniesAndTaxiServicesBase.Ref | null>(null);
	const [nameRef, setNameRef] = useRefWithSetter<NameBase.Ref | null>(null);

	const valueEditor = useObjectEditor(value, onChange);

	const nameValue = valueEditor.useGetter("name");
	const nameOnChange = valueEditor.useSetter("name");

	const rootValue = valueEditor.useGetter("root");

	const amountOfMoneyToBlockEditor = valueEditor.usePropertyEditor(
		"amountOfMoneyToBlock",
	);

	const amountOfMoneyToBlockActiveValue =
		amountOfMoneyToBlockEditor.useGetter("active");
	const amountOfMoneyToBlockActiveOnChange =
		amountOfMoneyToBlockEditor.useSetter("active");

	const amountOfMoneyToBlockValueValue =
		amountOfMoneyToBlockEditor.useGetter("value");
	const amountOfMoneyToBlockValueOnChange =
		amountOfMoneyToBlockEditor.useSetter("value");

	const amountOfMoneyToBlockAutoValue =
		amountOfMoneyToBlockEditor.useGetter("auto");
	const amountOfMoneyToBlockAutoOnChange =
		amountOfMoneyToBlockEditor.useSetter("auto");

	const amountOfMoneyToUnblockEditor = valueEditor.usePropertyEditor(
		"amountOfMoneyToUnblock",
	);

	const amountOfMoneyToUnblockActiveValue =
		amountOfMoneyToUnblockEditor.useGetter("active");
	const amountOfMoneyToUnblockActiveOnChange =
		amountOfMoneyToUnblockEditor.useSetter("active");

	const amountOfMoneyToUnblockValueValue =
		amountOfMoneyToUnblockEditor.useGetter("value");
	const amountOfMoneyToUnblockValueOnChange =
		amountOfMoneyToUnblockEditor.useSetter("value");

	const amountOfMoneyToUnblockAutoValue =
		amountOfMoneyToUnblockEditor.useGetter("auto");
	const amountOfMoneyToUnblockAutoOnChange =
		amountOfMoneyToUnblockEditor.useSetter("auto");

	const orderTypeConditionEditor =
		valueEditor.usePropertyEditor("orderTypeCondition");

	const orderTypeConditionActiveValue =
		orderTypeConditionEditor.useGetter("active");
	const orderTypeConditionActiveOnChange =
		orderTypeConditionEditor.useSetter("active");

	const orderTypeConditionOperatorValue =
		orderTypeConditionEditor.useGetter("operator");
	const orderTypeConditionOperatorOnChange =
		orderTypeConditionEditor.useSetter("operator");

	const orderTypeConditionValueValue =
		orderTypeConditionEditor.useGetter("value");
	const orderTypeConditionValueOnChange =
		orderTypeConditionEditor.useSetter("value");

	const orderTypeConditionTypeValue =
		orderTypeConditionEditor.useGetter("types");
	const orderTypeConditionTypeOnChange =
		orderTypeConditionEditor.useSetter("types");

	const disallowTakeOrdersEditor =
		valueEditor.usePropertyEditor("disallowTakeOrders");

	const disallowTakeOrdersActiveValue =
		disallowTakeOrdersEditor.useGetter("active");
	const disallowTakeOrdersActiveOnChange =
		disallowTakeOrdersEditor.useSetter("active");

	const disallowTakeOrdersValueValue =
		disallowTakeOrdersEditor.useGetter("value");
	const disallowTakeOrdersValueOnChange =
		disallowTakeOrdersEditor.useSetter("value");

	const taxiServicesData = valueEditor.usePicker(
		["taxiServiceIds", "taxiServices", "companyIds"],
		"json",
	);
	const onChangeContent = valueEditor.useAssigner();
	controller.setContext({
		nameRef,
		companyBranchRef,
	});

	return (
		<TabRoot hasPaddings visible={visible}>
			<Column sizes="auto!*" gaps="19px*" maxedWidth maxedHeight>
				<FieldsContainer
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str0",
						) ?? ""
					}
				>
					<StyledGrid areas="" gap="8px" columns="2fr 1fr 1fr">
						<Name
							ref={setNameRef}
							name={nameValue || ""}
							setName={nameOnChange}
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str200",
								) ?? ""
							}
							disabled={rootValue}
						/>
						<CompaniesAndTaxiServices
							ref={setCompanyBranchRef}
							value={taxiServicesData}
							onChange={onChangeContent}
							disabled={rootValue}
						/>
					</StyledGrid>
				</FieldsContainer>
				<FieldsContainer
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str1",
						) ?? ""
					}
				>
					<Column gaps="8px*">
						<CheckBoxWithContent
							value={amountOfMoneyToBlockActiveValue}
							onChange={amountOfMoneyToBlockActiveOnChange}
						>
							<Row
								align="center"
								gaps="10px*"
								sizes="159.06px! auto!*"
							>
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str100",
									) ?? ""}
								</span>
								<ValueStepper
									disabled={!amountOfMoneyToBlockActiveValue}
									value={amountOfMoneyToBlockValueValue}
									onChange={(value) =>
										amountOfMoneyToBlockValueOnChange(
											value ?? 0,
										)
									}
								/>
								<span>{currencyGlobalSettings}</span>
								<CheckBoxWithContent
									disabled={!amountOfMoneyToBlockActiveValue}
									value={amountOfMoneyToBlockAutoValue}
									gap="10px"
									onChange={amountOfMoneyToBlockAutoOnChange}
								>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str150",
									) ?? ""}
								</CheckBoxWithContent>
							</Row>
						</CheckBoxWithContent>
						<CheckBoxWithContent
							value={amountOfMoneyToUnblockActiveValue}
							onChange={amountOfMoneyToUnblockActiveOnChange}
						>
							<Row align="center" gaps="10px*" sizes="auto!*">
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str102",
									) ?? ""}
								</span>
								<ValueStepper
									disabled={
										!amountOfMoneyToUnblockActiveValue
									}
									value={amountOfMoneyToUnblockValueValue}
									onChange={(value) =>
										amountOfMoneyToUnblockValueOnChange(
											value ?? 0,
										)
									}
								/>
								<span>{currencyGlobalSettings}</span>
								<CheckBoxWithContent
									disabled={
										!amountOfMoneyToUnblockActiveValue
									}
									value={amountOfMoneyToUnblockAutoValue}
									gap="10px"
									onChange={
										amountOfMoneyToUnblockAutoOnChange
									}
								>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str150",
									) ?? ""}
								</CheckBoxWithContent>
							</Row>
						</CheckBoxWithContent>
					</Column>
				</FieldsContainer>
				<FieldsContainer
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str2",
						) ?? ""
					}
				>
					<Column gaps="8px*">
						<CheckBoxWithContent
							value={orderTypeConditionActiveValue}
							gap="10px"
							onChange={orderTypeConditionActiveOnChange}
						>
							<Row align="center" gaps="10px*">
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str104",
									) ?? ""}
								</span>
								<ComparisonSelect
									value={orderTypeConditionOperatorValue}
									disabled={!orderTypeConditionActiveValue}
									onChange={
										orderTypeConditionOperatorOnChange
									}
								/>
								<ValueStepper
									value={orderTypeConditionValueValue}
									disabled={!orderTypeConditionActiveValue}
									onChange={(value) =>
										orderTypeConditionValueOnChange(
											value ?? 0,
										)
									}
								/>
								<span>{currencyGlobalSettings}</span>
								<MultiSelect
									value={orderTypeConditionTypeValue}
									disabled={!orderTypeConditionActiveValue}
									style={{ height: 32, width: 300 }}
									options={orderTypeOptions}
									placeholder={
										t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str3",
										) ?? ""
									}
									onChange={
										orderTypeConditionTypeOnChange as Dispatch<MultiSelect.Value>
									}
								/>
							</Row>
						</CheckBoxWithContent>
						<CheckBoxWithContent
							value={disallowTakeOrdersActiveValue}
							gap="10px"
							onChange={disallowTakeOrdersActiveOnChange}
						>
							<Row align="center" gaps="10px*">
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.str201",
									) ?? ""}
								</span>
								<ValueStepper
									value={disallowTakeOrdersValueValue}
									disabled={!disallowTakeOrdersActiveValue}
									onChange={(value) =>
										disallowTakeOrdersValueOnChange(
											value ?? 0,
										)
									}
								/>
								<span>{currencyGlobalSettings}</span>
							</Row>
						</CheckBoxWithContent>
					</Column>
				</FieldsContainer>
			</Column>
		</TabRoot>
	);
}, InternalController);

const MainTab = memo(MainTabBase);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		root: boolean;
		name: string;
		companyIds: number[];
		taxiServiceIds: number[];
		taxiServices?: TaxiService.Model[];

		amountOfMoneyToBlock: {
			active: boolean;
			value: number;
			auto: boolean;
		};
		amountOfMoneyToUnblock: {
			active: boolean;
			value: number;
			auto: boolean;
		};

		orderTypeCondition: {
			active: boolean;
			operator?: ComparisonSelect.Value;
			value: number;
			types?: OrderTypeOptions[];
		};
		disallowTakeOrders: {
			active: boolean;
			value: number;
		};
	}

	interface PropsBase {
		executorId?: number;
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
