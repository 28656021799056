import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { StyledRow } from "../../../../common";
import { useTypedSelector } from "../../../../../redux/store";
import { NAVIGATION_TYPES, useNavigationTitle } from "../../constants/business";
import { Navigation } from "../../types";
import { useChatActions } from "../../hooks";
import { Filter } from "..";

import { Tab } from "./components";

const Root = styled(StyledRow)`
	min-height: 40px;
	height: 40px;

	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid ${theme.colors.color_border_basic};
	padding: 0 12px;
`;

const GroupTabs = styled(StyledRow)`
	padding: 0 12px 0 0;
`;

const NavBar: React.FC<NavBar.Props> = memo((props) => {
	const { navigation, onChangeNavigation } = props;

	const NAVIGATION_TITLES = useNavigationTitle();

	const { filterExecutorsRead } = useTypedSelector(
		(state) => state.orders.chats.filter,
	);

	const setDispatchersTab = useCallback(() => {
		onChangeNavigation(NAVIGATION_TYPES.DISPATCHERS);
	}, [onChangeNavigation]);

	const setExecutorsTab = useCallback(() => {
		onChangeNavigation(NAVIGATION_TYPES.EXECUTORS);
	}, [onChangeNavigation]);

	const setExchangeTab = useCallback(() => {
		onChangeNavigation(NAVIGATION_TYPES.EXCHANGE);
	}, [onChangeNavigation]);

	const { saveFilterExecutorsRead } = useChatActions();

	return (
		<Root>
			<GroupTabs>
				<Tab
					title={NAVIGATION_TITLES.EXECUTORS} // TODO Make it possible to translate texts from constants from .ts
					active={navigation === NAVIGATION_TYPES.EXECUTORS}
					onChange={setExecutorsTab}
				/>
				<Tab
					title={NAVIGATION_TITLES.DISPATCHERS}
					active={navigation === NAVIGATION_TYPES.DISPATCHERS}
					onChange={setDispatchersTab}
					disabled={true}
				/>
				<Tab
					title={NAVIGATION_TITLES.EXCHANGE}
					active={navigation === NAVIGATION_TYPES.EXCHANGE}
					onChange={setExchangeTab}
					disabled={true}
				/>
			</GroupTabs>

			<Filter
				saveFilterOnRedux={saveFilterExecutorsRead}
				initFilter={filterExecutorsRead}
			/>
		</Root>
	);
});

declare namespace NavBar {
	interface Props {
		navigation: Navigation;
		onChangeNavigation: (tab: Navigation) => void;
	}
}

export default NavBar;
