import React, { memo, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DistanceHistory } from "../../../../../../../redux/reducers/Archives/GPSLog/interface";
import { useGetGPSHistory } from "../../../../../../../hooks/useGetGPSHistory";

const StyledWrap = styled.div`
	display: grid;
	grid-template-areas:
		"total total onOrders onOrders notOnOrders notOnOrders"
		"total total filing execution available available";
	grid-template-columns: 12% 12% repeat(4, 1fr);
	grid-template-rows: repeat(2, 40px);

	border-top: 1px solid #dcdcdc;
	width: 100%;
	align-items: flex-start;
	align-content: space-between;

	@media (max-width: 1020px) {
		grid-template-areas:
			"total"
			"onOrders"
			"filing"
			"execution"
			"notOnOrders"
			"available";
		grid-template-columns: 1fr;
		grid-template-rows: repeat(6, 30px);
		column-gap: 10px;
	}
`;

const StyledTotal = styled.div`
	grid-area: total;
	height: 100%;
	padding-left: 5px;
	display: flex;
`;

const StyledOnOrders = styled.div`
	grid-area: onOrders;
	border-left: 1px solid #dcdcdc;
	height: 100%;
	padding-left: 5px;
	display: flex;

	@media (max-width: 1020px) {
		border: none;
		border-top: 1px solid #dcdcdc;
		padding-left: 5px;
	}
`;

const StyledNotOnOrders = styled.div`
	grid-area: notOnOrders;
	border-left: 1px solid #dcdcdc;
	height: 100%;
	padding-left: 5px;
	display: flex;

	@media (max-width: 1020px) {
		border: none;
		border-top: 1px solid #dcdcdc;
	}
`;

const StyledFiling = styled.div`
	grid-area: filing;
	border-top: 1px solid #dcdcdc;
	border-left: 1px solid #dcdcdc;
	height: 100%;
	padding-left: 5px;
	display: flex;

	@media (max-width: 1020px) {
		border: none;
		border-top: 1px solid #dcdcdc;
	}
`;

const StyledExecution = styled.div`
	grid-area: execution;
	border-top: 1px solid #dcdcdc;
	height: 100%;
	display: flex;

	@media (max-width: 1020px) {
		border: none;
		border-top: 1px solid #dcdcdc;
		padding-left: 5px;
	}
`;

const StyledAvailable = styled.div`
	grid-area: available;
	border-top: 1px solid #dcdcdc;
	border-left: 1px solid #dcdcdc;
	height: 100%;
	padding-left: 5px;
	display: flex;

	@media (max-width: 1020px) {
		border: none;
		border-top: 1px solid #dcdcdc;
	}
`;

const StyledP = styled.p`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: start;
	flex-direction: row;

	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	box-shadow: none;
	color: #5e6b73;
`;

const StyledSpan = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: start;
	padding-left: 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	box-shadow: none;
	color: #5e6b73;
`;

const StyledColor = styled.span`
	display: flex;
	width: 60px;
	height: 10px;
	margin-right: 5px;
	border: 1px solid #5e6b73;
`;

const Footer: React.FC<Footer.Props> = (): JSX.Element => {
	const { data, setDistances } = useGetGPSHistory();
	const { t } = useTranslation();
	const kpUnit = t(`units.km`) ?? "km";
	const gpsLogTotal = t("mainPage.archives.gpsLog.total");
	const gpsLogOnOrders = t("mainPage.archives.gpsLog.on_orders");
	const gpsLogNotOnOrders = t("mainPage.archives.gpsLog.not_on_orders");
	const gpsLogFiling = t("mainPage.archives.gpsLog.filing");
	const gpsLogExecution = t("mainPage.archives.gpsLog.execution");
	const gpsLogAvailable = t("mainPage.archives.gpsLog.available");

	const distancesMemo = useMemo<DistanceHistory[]>(() => {
		if (!data.length) return [];

		const payload = data.map((item) => ({
			id: item.id,
			executorCallSign: item?.executorCallSign,
			distances: item?.counters?.distances,
		}));

		return payload;
	}, [data]);

	const counter = useMemo<Footer.Counter>(() => {
		const base: Footer.Counter = {
			total: `0 ${kpUnit}`,
			available: `0 ${kpUnit}`,
			onOrders: `0 ${kpUnit}`,
			notOnOrders: `0 ${kpUnit}`,
			execution: `0 ${kpUnit}`,
			filing: `0 ${kpUnit}`,
		};

		if (!distancesMemo.length) return base;
		const payload: Footer.CounterMath = {
			total: 0,
			onOrders: 0,
			notOnOrders: 0,
			available: 0,
			execution: 0,
			filing: 0,
		};

		distancesMemo.forEach((item) => {
			let total = 0;
			let order = 0;
			let filing = 0;

			if (item.distances?.available) {
				payload.available += item.distances.available;
				base.available = `${payload.available?.toFixed(3)} ${kpUnit}`;
			}

			if (item.distances?.total) {
				total = item.distances.total;
				payload.total += item.distances.total;
				base.total = `${payload.total?.toFixed(3)} ${kpUnit}`;
			}
			if (item.distances?.order) {
				order = item.distances.order;
				payload.onOrders += item.distances.order;
				base.onOrders = `${payload.onOrders?.toFixed(3)} ${kpUnit}`;
			}

			if (item.distances?.pickup) {
				filing = item.distances.pickup;
				payload.filing += item.distances.pickup;
				base.filing = `${payload.filing?.toFixed(3)} ${kpUnit}`;
			}
			if (typeof order === "number" && typeof filing === "number") {
				const execution = order - filing;
				payload.execution += execution;
				base.execution = `${payload.execution?.toFixed(3)} ${kpUnit}`;
			} else if (typeof order === "number") {
				payload.execution += order;
				base.execution = `${payload.execution} ${kpUnit}`;
			}
			if (typeof order === "number" && typeof total === "number") {
				const notOnOrders = total - order;
				payload.notOnOrders += notOnOrders;
				base.notOnOrders = `${payload.notOnOrders?.toFixed(
					3,
				)} ${kpUnit}`;
			} else if (typeof total === "number") {
				payload.notOnOrders += total;
				base.notOnOrders = `${payload.notOnOrders?.toFixed(
					3,
				)} ${kpUnit}`;
			}
		});

		return base;
	}, [distancesMemo, kpUnit]);

	useEffect(() => {
		if (setDistances) setDistances(distancesMemo);
	}, [distancesMemo, setDistances]);

	return (
		<StyledWrap>
			<StyledTotal>
				<StyledP>
					{gpsLogTotal}:<StyledSpan>{counter.total}</StyledSpan>
				</StyledP>
			</StyledTotal>
			<StyledOnOrders>
				<StyledP>
					<StyledColor style={{ backgroundColor: "#ff0000" }} />
					{gpsLogOnOrders}:<StyledSpan>{counter.onOrders}</StyledSpan>
				</StyledP>
			</StyledOnOrders>
			<StyledNotOnOrders>
				<StyledP>
					<StyledColor style={{ backgroundColor: "#0000ff" }} />
					{gpsLogNotOnOrders}:
					<StyledSpan>{counter.notOnOrders}</StyledSpan>
				</StyledP>
			</StyledNotOnOrders>
			<StyledFiling>
				<StyledP>
					{gpsLogFiling}:<StyledSpan>{counter.filing}</StyledSpan>
				</StyledP>
			</StyledFiling>
			<StyledExecution>
				<StyledP>
					{gpsLogExecution}:
					<StyledSpan>{counter.execution}</StyledSpan>
				</StyledP>
			</StyledExecution>
			<StyledAvailable>
				<StyledP>
					{gpsLogAvailable}:
					<StyledSpan>{counter.available}</StyledSpan>
				</StyledP>
			</StyledAvailable>
		</StyledWrap>
	);
};

export const FooterMemo = memo(Footer);

declare namespace Footer {
	interface Props {}

	interface Counter {
		total: string;
		onOrders: string;
		notOnOrders: string;
		available: string;
		execution: string;
		filing: string;
	}
	interface CounterMath {
		total: number;
		onOrders: number;
		notOnOrders: number;
		available: number;
		execution: number;
		filing: number;
	}
}

export default Footer;
