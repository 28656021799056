import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";
import { uniq } from "lodash";

import CarColor from "../../../../../../services/CarColor";
import Language from "../../../../../../services/Language";
import DeleteModal from "../../../../../../components/DeleteModal";
import TableSettings from "../../../../../../components/TableSettings";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../hooks/useTableSettings";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import { useTableOptions } from "../../../../../../components/LightTable";
import tables from "../../../../../../constants/tables";

import Content from "./components/Content";
import Header from "./components/Header";
import ColorsModal from "./components/Modal";

export default function Colors() {
	const { t } = useTranslation();
	const { editor, onChange, sort, query, setQuery } = useTableOptions();

	const columns = useColumns("color");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"preferences.colors",
		"color",
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [modalStatus, setModalStatus] = useState<number>();
	const [selected, setSelected] = useState<number[]>([]);
	const [languageFilter, setLanguageFilter] = useState<Language>("uk");
	const [showColorModal, setShowColorModal] = useState<boolean>(false);
	const [taxiServicesIds, setTaxiServicesIds] = useState<
		number[] | undefined
	>(undefined);

	const carColorSubscribeOptions = useMemo<CarColor.SubscribeOptions>(() => {
		const result: CarColor.SubscribeOptions = {
			language: languageFilter,
			query,
			taxiServiceIds: taxiServicesIds,
		};

		if (sort.dataKey && sort.sortType)
			result.order = {
				[sort.dataKey as keyof CarColor.SubscribeOptions["order"]]:
					sort.sortType.toUpperCase(),
			};

		return result;
	}, [languageFilter, query, sort.dataKey, sort.sortType, taxiServicesIds]);

	const carColorSubscription = useModelSubscribe(
		carColorSubscribeOptions,
		CarColor,
		{
			debounce: 300,
		},
	);

	const carColorModels = useMemo(
		() => carColorSubscription.models,
		[carColorSubscription.models],
	);

	const deleteCarColors = useCallback(() => {
		if (selected.length) {
			CarColor.destroy(selected);
		}
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const closeDeleteModal = useCallback(() => {
		setShowDeleteModal(false);
		setShowColorModal(false);
	}, []);

	const closeColorModal = useCallback(() => {
		setModalStatus(-2);
		setShowColorModal(false);
	}, []);

	const saveColorModalData = useCallback(
		(values) => {
			if (values.color === 0) {
				// eslint-disable-next-line no-param-reassign
				values.color += "";
			}
			if (values.id) {
				const prepareData = {
					id: values.id,
					name: values.fields,
					active: values.active,
					value: values.isUseColor
						? parseInt(values.color.substring(1), 16)
						: 0,
					taxiServiceIds: values.taxiServices,
				};
				CarColor.update(prepareData);
			} else {
				const prepareData = {
					name: values.fields,
					active: values.active,
					value: values.isUseColor
						? parseInt(values.color.substring(1), 16)
						: 0,
					taxiServiceIds: values.taxiServices,
				};
				CarColor.store(prepareData);
			}
			closeColorModal();
		},
		[closeColorModal],
	);

	const colorModalData = useMemo(
		() =>
			modalStatus === -1
				? true
				: carColorModels.find((item) => item.id === modalStatus),
		[modalStatus, carColorModels],
	);

	const companyIdsProp = useMemo(
		() =>
			modalStatus === -1
				? []
				: uniq(
						carColorModels
							.find((item) => item.id === modalStatus)
							?.taxiServices?.map(
								(taxiService) => taxiService.company?.id,
							),
				  ),
		[modalStatus, carColorModels],
	);

	const doubleClickEditCarColor = useCallback((value) => {
		setModalStatus(value.id);
		setShowColorModal(true);
	}, []);

	const editCarColor = useCallback(async () => {
		await setModalStatus(selected[selected.length - 1]);
		await setShowColorModal(true);
	}, [selected]);

	const openEmptyModal = useCallback(async () => {
		await setModalStatus(-1);
		setShowColorModal(true);
	}, []);

	const openDeleteModal = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	return (
		<Column sizes="auto 1fr" maxedWidth maxedHeight>
			<Header
				languageFilter={languageFilter}
				setLanguageFilter={setLanguageFilter}
				setTaxiServiceFilter={setTaxiServicesIds}
				onDelete={openDeleteModal}
				onEdit={editCarColor}
				notSelected={Boolean(selected.length)}
				handleOpenModal={openEmptyModal}
				query={query}
				onChangeQuery={setQuery}
			/>
			<Content
				columns={columnIds}
				onDelete={(id) => setShowDeleteModal(id)}
				onEdit={doubleClickEditCarColor}
				languageFilter={languageFilter}
				data={carColorModels || []}
				selected={selected}
				onChangeSelected={setSelected}
				editorTable={editor}
				onChangeTable={onChange}
			/>

			<TableSettings
				value={columnIds}
				onChange={setColumnIds}
				columns={columns}
				defaultValue={tables.color.defaultIds}
			/>

			{showColorModal && (
				<ColorsModal
					companyIdsProp={companyIdsProp}
					data={colorModalData}
					onCancel={closeColorModal}
					onSave={saveColorModalData}
				/>
			)}

			{showDeleteModal && (
				<DeleteModal
					label={`${
						t(
							"pages.preferencesPages.screenDirectory.colors.str200",
						) ?? ""
					}?`}
					onCancel={closeDeleteModal}
					onConfirm={deleteCarColors}
				/>
			)}
		</Column>
	);
}
