import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { isBoolean } from "lodash";

import Transaction from "../../../../../../../../../../services/Transaction";
import { StyledP } from "../../../../../../../../../../components/common";

const PaymentAccountProvider: React.FC<PaymentAccountProvider.Props> = memo(
	({ value }) => {
		const { t } = useTranslation();

		const { isProvider } = value;

		if (!isBoolean(isProvider)) return <></>;

		return (
			<StyledP colors={isProvider ? "orange" : "red"} w="100%">
				{isProvider
					? t([`paymentAccount.yes_provider`, ""])
					: t([`paymentAccount.no_provider`, ""])}
			</StyledP>
		);
	},
);

declare namespace PaymentAccountProvider {
	interface Props {
		value: Value;
	}

	interface Value {
		isProvider: Transaction.PaymentAccountRelation["isProvider"];
	}
}

export default PaymentAccountProvider;
