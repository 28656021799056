import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";

interface RateIncludePickupCostProps {
	form: UseCreateFormReturn<Schema>;
	disabled: boolean;
}

const Hourly: React.FC<RateIncludePickupCostProps> = ({ form, disabled }) => {
	const { t } = useTranslation();

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	return (
		<Controller
			name="enableHourlyService"
			control={form.control}
			render={({ field }) => (
				<CheckBox
					disabled={disabled}
					value={Boolean(field.value)}
					onChange={(value) => {
						field.onChange(value);
						handleSave();
					}}
					label={`${t([`orderPage.order_form.hourly`, "Hourly"])}`}
				/>
			)}
		/>
	);
};

export default Hourly;
