import React, { Dispatch, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Float, Icon, theme, useRefSize } from "uikit";

import { Popup } from "../../../../../../common";
import mapByKey from "../../../../../../../utils/mapByKey";

import Root from "./components/Root";
import Label from "./components/Label";
import OptionList from "./components/OptionList";

const optionsOffset: Float.OffsetCallback = ({ tracker, float }) => {
	if (!tracker || !float) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	return { x: -7, y: Number(trackerSize.height) + 14 };
};

const SearchType: React.FC<SearchType.Props> = ({
	value = "address",

	onChange,
}) => {
	const { t } = useTranslation();

	const options: OptionList.Option<SearchType.Value>[] = useMemo(
		() => [
			{
				key: "address",
				label:
					t("orderPageWidgets.map2.header.searchType.str200") ?? "",
				value: "address",
			},
			{
				key: "executor",
				label:
					t("orderPageWidgets.map2.header.searchType.str201") ?? "",
				value: "executor",
			},
		],
		[t],
	);

	const optionByValue = mapByKey(options, "value");

	const { ref: containerRef, size: containerSize } = useRefSize();

	const [popupOpen, setPopupOpen] = useState(false);

	const optionListOnSelect = useCallback(
		(option: OptionList.Option<SearchType.Value>) => {
			setPopupOpen(false);
			onChange?.(option.value);
		},
		[onChange],
	);

	return (
		<Root tabIndex={0} align="center" sizes="1fr">
			<Popup
				useClickControl
				open={popupOpen}
				tracker={
					<Label
						ref={containerRef}
						gaps="2px*"
						sizes="1fr auto!"
						maxedWidth
					>
						<div>{optionByValue[value].label}</div>
						<Icon
							id="arrow-select-down"
							style={
								popupOpen
									? { transform: "rotate(180deg)" }
									: undefined
							}
							size={16}
							colors={[theme.colors.primary]}
						/>
					</Label>
				}
				offset={optionsOffset}
				onChangeOpen={setPopupOpen}
			>
				<OptionList
					width={containerSize.width + 14}
					options={options}
					onSelect={optionListOnSelect}
				/>
			</Popup>
		</Root>
	);
};

declare namespace SearchType {
	type Value = "address" | "executor";

	interface Props {
		value?: Value;

		onChange?: Dispatch<Value>;
	}
}

export default SearchType;
