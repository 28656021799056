import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import { BaseRateAdjustmentType } from "../../../../../../../../../../../../services/Tariff/enums";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import { StyledRow } from "../../../../../../../../../../../../components/common";
import Root from "../../../../../../../../components/Root";
import tPath from "../../../../../../constants/tPath";

import Modal from "./components/Modal";
import TabRoot from "../../components/TabRoot";
import Content from "./components/Content";
import defaultInterval from "./constants/defaultInterval";

const TariffScaleTab: React.FC<TariffScaleTab.Props> = memo((props) => {
	const { value, onChange, visible } = props;

	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const isActive = valueEditor.useGetter("active");
	const setIsActive = valueEditor.useSetter("active");

	const isForAllRoute = valueEditor.useGetter("overall");
	const setIsForAllRoute = valueEditor.useSetter("overall");

	const isLastIntervalForOverall = valueEditor.useGetter(
		"useLastIntervalForOverall",
	);
	const setIsLastIntervalForOverall = valueEditor.useSetter(
		"useLastIntervalForOverall",
	);

	const [showTariffScaleModal, setShowTariffScaleModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const intervals = valueEditor.useGetter("intervals");
	const setRawIntervals = valueEditor.useSetter("intervals");

	const setIntervals = useCallback(
		(intervals: TariffScaleTab.Interval[]) =>
			setRawIntervals([...intervals].sort((a, b) => a.value - b.value)),
		[setRawIntervals],
	);

	const [selectedIntervals, setSelectedIntervals] = useState<
		TariffScaleTab.SelectedIntervals | []
	>([]);

	const [editingItem, setEditingItem] = useState(defaultInterval);
	const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
		null,
	);

	useEffect(() => {
		setEditingItem({
			...defaultInterval,
			value: intervals.reduce(
				(acc, invl) => (invl.value === acc ? acc + 1 : acc),
				1,
			),
		});
	}, [intervals, indexEditingItem]);

	const validateInterval = useCallback(
		(interval: TariffScaleTab.Interval) => {
			const exists = intervals.some(
				(i) => i.value === interval.value && i.active,
			);

			return {
				valid: !exists,
				message: t(
					`${tPath}.mainModal.tabs.tariffScale.modal.errorMessages.intervalExists`,
				),
			};
		},
		[t, intervals],
	);

	const editInterval = useCallback(
		(index: number) => {
			const item = intervals[index];

			setEditingItem({
				active: item.active,
				value: item.value,
				minimal: item.minimal,
				baseSettlement: item.baseSettlement,
				outsideSettlement: item.outsideSettlement,
				outsideSettlementRoundTrip: item.outsideSettlementRoundTrip,
			});
			setShowTariffScaleModal(true);
		},
		[intervals],
	);

	const addIntervalHandler = useCallback(() => {
		setShowTariffScaleModal(true);
	}, []);

	const editIntervalHeaderHandler = useCallback(() => {
		editInterval(selectedIntervals[0]);
		setIndexEditingItem(selectedIntervals[0]);
		setSelectedIntervals([]);
	}, [editInterval, selectedIntervals]);

	const editIntervalContentHandler = useCallback(
		(index: number) => {
			setIndexEditingItem(index);
			editInterval(index);
		},
		[editInterval],
	);

	const cancelIntervalHandler = useCallback(() => {
		setShowTariffScaleModal(false);
		setIndexEditingItem(null);
	}, []);

	const saveIntervalHandler = useCallback(
		(interval: TariffScaleTab.Interval) => {
			if (interval.value === 0) return;

			if (indexEditingItem != null) {
				const updatedIntervals = [...intervals];
				updatedIntervals[indexEditingItem] = interval;
				setIntervals(updatedIntervals);
				setIndexEditingItem(null);
			} else {
				setIntervals([...intervals, interval]);
			}

			setShowTariffScaleModal(false);
		},
		[indexEditingItem, intervals, setIntervals],
	);

	const deleteIntervalHandler = useCallback(() => {
		const newIntervals = intervals.filter(
			(_, i) => !selectedIntervals.includes(i as never),
		);
		setIntervals(newIntervals);
		setSelectedIntervals([]);
		setShowDeleteModal(false);
	}, [intervals, selectedIntervals, setIntervals]);

	return (
		<TabRoot hasPaddings={false} visible={visible}>
			<Root sizes="auto! auto 1fr" maxedWidth maxedHeight gaps="15px*">
				<StyledRow m="0 20px 10px 20px" justify="space-between">
					<StyledRow gap="30px">
						<CheckBox
							label={
								t(
									`${tPath}.mainModal.tabs.tariffScale.active`,
								) || ""
							}
							value={isActive}
							onChange={setIsActive}
						/>
						<CheckBox
							disabled={!isActive}
							label={
								t(
									`${tPath}.mainModal.tabs.tariffScale.overall`,
								) || ""
							}
							value={isForAllRoute}
							onChange={setIsForAllRoute}
						/>
					</StyledRow>
					<CheckBox
						value={isLastIntervalForOverall}
						onChange={setIsLastIntervalForOverall}
						label={
							t(
								`${tPath}.mainModal.tabs.tariffScale.useLastIntervalForOverall`,
							) || ""
						}
					/>
				</StyledRow>
				<DefaultPageHeader
					onAdd={addIntervalHandler}
					onEdit={editIntervalHeaderHandler}
					onDelete={() => {
						setShowDeleteModal(true);
					}}
					canEdit={selectedIntervals.length === 1}
					canDelete={!!selectedIntervals.length}
					afterAdditionalButtons={[
						{
							icon: { id: "copy", size: 20 },
							onClick: () => {},
							disabled: !(selectedIntervals.length === 1),
						},
					]}
				/>
				<Content
					intervals={intervals}
					selectedIntervals={selectedIntervals}
					onEdit={editIntervalContentHandler}
					setSelectedIntervals={setSelectedIntervals}
					isForAllRoute={isForAllRoute}
				/>
				{showTariffScaleModal && (
					<Modal
						value={editingItem}
						headerTitle={t(
							`${tPath}.mainModal.tabs.tariffScale.modal.title`,
						)}
						onCancel={cancelIntervalHandler}
						onSave={saveIntervalHandler}
						validateInterval={validateInterval}
						isForAllRoute={isForAllRoute}
					/>
				)}
				{showDeleteModal && (
					<DeleteModal
						label={
							(selectedIntervals.length === 1
								? t(
										`${tPath}.mainModal.tabs.tariffScale.deleteModal.title`,
								  )
								: t(
										`${tPath}.mainModal.tabs.tariffScale.deleteModal.title2`,
								  )) || ""
						}
						onCancel={() => {
							setShowDeleteModal(false);
						}}
						onConfirm={deleteIntervalHandler}
					/>
				)}
			</Root>
		</TabRoot>
	);
});

declare namespace TariffScaleTab {
	interface Props {
		value: Value;
		visible: boolean;
		onChange: (x: Value) => void;
		disabled?: boolean;
	}

	interface Value {
		active: boolean;
		overall: boolean;
		useLastIntervalForOverall: boolean;
		intervals: Interval[];
	}

	interface Interval {
		active: boolean;
		value: number;
		minimal: {
			minDistance: number;
		} & ActiveTypeValue;
		baseSettlement: ActiveTypeValue;
		outsideSettlement: ActiveTypeValue;
		outsideSettlementRoundTrip: ActiveTypeValue;
	}

	interface IntervalError {
		valid: boolean;
		message: string;
	}

	interface ActiveTypeValue {
		active: boolean;
		type: BaseRateAdjustmentType | null;
		value: number;
	}

	type SelectedIntervals = number[];
}

export default TariffScaleTab;
