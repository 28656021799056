import React, { memo, Suspense, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";
import { StyledColumn, StyledRow } from "../../../../../../components/common";

import Mark from "../../Mark";
import Marker from "../../Marker";
import { useGetDifferenceInArray } from "../hooks";

const ServicesDifference: React.FC<ServicesDifference.Props> = ({ change }) => {
	const { t, i18n } = useTranslation();
	const services = t("services");

	const previous = useMemo(
		() =>
			change.previous.length
				? change.previous.map((item) => {
						try {
							return `${item.name[i18n.language]}`;
						} catch {
							return "";
						}
				  })
				: [],
		[change.previous, i18n.language],
	);
	const actual = useMemo(
		() =>
			change.actual.length
				? change.actual.map((item) => {
						try {
							return `${item.name[i18n.language]}`;
						} catch {
							return "";
						}
				  })
				: [],
		[change.actual, i18n.language],
	);

	const items = useGetDifferenceInArray({
		previous,
		actual,
		type: change.type,
	});

	return (
		<Suspense>
			{items.length && (
				<StyledColumn>
					<p key={services}>{services}:</p>
					{items.map((item, i) => {
						if (!item.prev && !item.new) return <></>;
						return (
							<StyledRow key={i} p="0 0 0 5px">
								<Marker />
								<Trans
									i18nKey={`change_history.orders.fields.${change.field}`}
									context={item.context}
									values={{
										prev: item.prev,
										new: item.new,
									}}
									components={{ mark: <Mark /> }}
								/>
							</StyledRow>
						);
					})}
				</StyledColumn>
			)}
		</Suspense>
	);
};

declare namespace ServicesDifference {
	interface Props {
		change: History.Field.ServiceChange;
	}

	type TypeChange = "set" | "removed" | "change";

	interface Item {
		context: string;
		prev: string;
		new: string;
	}

	type Items = Item[];
}

export const ServicesDifferenceMemo = memo(ServicesDifference);
export default ServicesDifference;
