import React from "react";

import { IOrderPoint as OrderPoint } from "../../../../../../../../../redux/constants/OrdersPage/order";
import Language from "../../../../../../../../../services/Language";
import CellContentRoot from "../CellContentRoot";

const SectorCellContent: React.FC<SectorCellContent.Props> = ({
	item,
	language,
}) => (
	<CellContentRoot align="center" maxedHeight maxedWidth>
		{item.sector?.name[language]}
	</CellContentRoot>
);

declare namespace SectorCellContent {
	interface Props {
		item: OrderPoint;

		language: Language;
	}
}

export default SectorCellContent;
