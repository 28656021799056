import React, { useCallback, useMemo } from "react";

import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import { useOrderModalContext } from "../../../../../context";
import { Tab } from "../../types/Tab";
import { CheckBoxWithText } from "../../../../../../../../components/common";

interface RateIncludePickupCostProps {
	form: UseCreateFormReturn<Schema>;
	disabled: boolean;
	tab: Tab;
}

const Taximeter: React.FC<RateIncludePickupCostProps> = ({
	form,
	disabled,
	tab,
}) => {
	const { settings } = useOrderModalContext();

	const taximeter = useMemo(
		() => tab?.form?.taximeter,
		[tab?.form?.taximeter],
	);

	const setTaximeter = useCallback(
		(value: boolean) => {
			form.setValue("taximeter", Boolean(value));
		},
		[form],
	);

	const onChange = useCallback(
		(value: boolean) => {
			if (settings.allowOperatorToChangeMetering) {
				setTaximeter(value);
			}
		},
		[setTaximeter, settings.allowOperatorToChangeMetering],
	);

	return (
		<CheckBoxWithText
			disabled={disabled || !settings.allowOperatorToChangeMetering}
			value={Boolean(taximeter)}
			onChange={onChange}
			title="pages.mainPage.pages.orders.orderModal.orderRideInformation.str207"
		/>
	);
};

export default Taximeter;
