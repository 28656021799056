import makeTable from "./makeTable";

const counterpartyCheck = makeTable({
	allIds: [
		"status",
		"edrpou",
		"counterpartyName",
		"checkNumber",
		"balance",
		"tripThreshold",
		"bonusBalance",
		"orderCount",
		"discountPlan",
		"additionalDiscountPlan",
		"tariff",
		"registrationDate",
		"activationDate",
		"firstTripDate",
		"lastTripDate",
		"mobileApp",
		"corporateAccount",
		"notes",
	],
	defaultIds: [
		"status",
		"name",
		"edrpou",
		"checkNumber",
		"balance",
		"tripThreshold",
		"tariff",
		"orderCount",
	],
	i18tPath: (id) => `tableColumns.customers.counterpartyChecks.${id}`,
});

export default counterpartyCheck;
