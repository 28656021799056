import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import ParametersCellContent from "../components/ParametersCellContent";

function parameters({ width, onResize }: Column.Options) {
	return base(
		ColumnId.Parameters,
		(item) => <ParametersCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default parameters;
