import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, Icon, Nullable, theme } from "uikit";
import { useTypedSelector } from "../../../../../../redux/store";
import { Call } from "../../../../../../redux/reducers/softphoneWidget";
import Caller from "./components/Caller";

const CallListStyled = styled(Column)`
	position: relative;

	height: 250px;
	width: 100%;
	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	div:last-child {
		border-bottom: none;
	}
`;

const EmptyCallListStyled = styled(Column)`
	position: absolute;
	top: 50%;
	left: 50%;

	height: 84px;
	width: 132px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	color: ${theme.colors.text_placeholder};
	font-family: Lato;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: center;

	transform: translate(-50%, -50%);
	cursor: default;
`;

interface Props {
	data: Call[];
	selectedCall: Nullable<Call>;
	onSelectCaller: (callerData: Call) => void;
	isReduce: boolean;
}

const CallList: React.FC<Props> = ({
	data,
	selectedCall,
	isReduce,
	onSelectCaller,
}) => {
	const { showKeyboard } = useTypedSelector((state) => state.softphoneWidget);

	const { t } = useTranslation();
	return (
		<CallListStyled
			style={{
				// eslint-disable-next-line no-nested-ternary
				height: !isReduce
					? 176
					: // eslint-disable-next-line no-nested-ternary
					showKeyboard
					? 62
					: !selectedCall?.session.name && selectedCall
					? 265
					: 250,
			}}
		>
			{!showKeyboard && !data.length && (
				<EmptyCallListStyled gaps="8px">
					<Icon
						id="phone-call-wifi"
						size={40}
						colors={[theme.colors.text_placeholder]}
					/>
					<div>
						{t("softphoneWidget.dialingTab.callList.str100") ?? ""}
					</div>
					<div>
						{t("softphoneWidget.dialingTab.callList.str101") ?? ""}
					</div>
				</EmptyCallListStyled>
			)}
			{data?.map((call, index) => (
				<Caller
					key={call?.session.id}
					callerIndex={index + 1}
					selectedCall={selectedCall}
					data={call}
					onSelectCaller={onSelectCaller}
				/>
			))}
		</CallListStyled>
	);
};

export default CallList;
