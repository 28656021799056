import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeClock } from "@mui/x-date-pickers";
import { ClickAwayListener } from "@mui/material";
import { Button } from "uikit";

import useKeyBind from "../../../../../hooks/useKeyBind";
import { StyledRow } from "../../../../common";

import Root from "./components/Root";

const ClockModal: React.FC<ClockModal.Props> = ({ onSubmit, onClose }) => {
	const { t } = useTranslation();
	const [booferTime, setBooferTime] = useState<any>();

	const onChangeBooferTime = useCallback((e) => {
		setBooferTime(e);
	}, []);

	const submitTime = useCallback(() => {
		if (booferTime) {
			onSubmit(booferTime._d);
		}
	}, [booferTime, onSubmit]);

	useKeyBind(["Enter"], () => {
		submitTime();
	});
	useKeyBind(["Escape"], () => {
		onClose();
	});

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Root>
				<TimeClock onChange={onChangeBooferTime} ampm={false} />
				<StyledRow
					justify="flex-end"
					gap="10px"
					style={{ padding: "12px 16px" }}
				>
					<Button.Button
						text={t("orderPageWidgets.chat.clockModal.str0") ?? ""}
						variant="secondary"
						onClick={onClose}
					/>
					<Button.Button
						text={t("orderPageWidgets.chat.clockModal.str1") ?? ""}
						disabled={!booferTime}
						onClick={submitTime}
					/>
				</StyledRow>
			</Root>
		</ClickAwayListener>
	);
};

declare namespace ClockModal {
	interface Props {
		onSubmit: (time: Date) => void;
		onClose: () => void;
	}
}

export default ClockModal;
