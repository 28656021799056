/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

// import * as ModelEvent from "@node-elion/syncron";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Base from "../Base";
import Language from "../Language";
import ExecutorRateClassifier from "../ExecutorRateClassifier";
// import SubscriptionBase from "../../types/Subscription";
// import SubscriptionPool from "../../redux/services/SubscriptionPool";

const weekdayToNumber = {
	monday: 0,
	tuesday: 1,
	wednesday: 2,
	thursday: 3,
	friday: 4,
	saturday: 5,
	sunday: 6,
};

const conditionOperatorMaps = {
	fromResponse: {
		greater: "greater",
		less: "less",
		equal: "equal",
		greaterEqual: "greaterOrEqual",
		lessEqual: "lessOrEqual",
	},
	toRequest: {
		greater: "greater",
		less: "less",
		equal: "equal",
		greaterOrEqual: "greaterEqual",
		lessOrEqual: "lessEqual",
	},
} as const;

class ExecutorRateCommissionPlan extends Base {
	static fromResponse(data: any): ExecutorRateCommissionPlan.Model {
		return {
			id: data.id,

			classifier: data.classifier
				? ExecutorRateClassifier.fromResponse(data.classifier)
				: undefined,

			name: data.name,

			startDate: new Date(data.startDate),

			weekdays: {
				monday: data.weekdays?.includes(0) ?? false,
				tuesday: data.weekdays?.includes(1) ?? false,
				wednesday: data.weekdays?.includes(2) ?? false,
				thursday: data.weekdays?.includes(3) ?? false,
				friday: data.weekdays?.includes(4) ?? false,
				saturday: data.weekdays?.includes(5) ?? false,
				sunday: data.weekdays?.includes(6) ?? false,
			},

			timeRange: {
				active: data.configuration?.timeRange?.active ?? false,
				from: data.configuration?.timeRange?.from ?? 0,
				to: data.configuration?.timeRange?.to ?? 0,
			},

			rate: {
				includeCurrentOrdersInDiscountedCommissions: data.configuration
					?.rate
					? data.configuration.rate
							.includeCurrentOrdersInDiscountedCommissions
					: false,

				minCommission: data.configuration?.rate?.minCommission
					? {
							active: data.configuration.rate.minCommission
								.active,
							amount: data.configuration.rate.minCommission
								.amount,
					  }
					: undefined,

				maxCommission: data.configuration?.rate?.maxCommission
					? {
							active: data.configuration.rate.maxCommission
								.active,
							amount: data.configuration.rate.maxCommission
								.amount,
					  }
					: undefined,

				commissions:
					data.configuration?.rate?.commissionSortingOrder &&
					data.configuration?.rate?.commissions
						? data.configuration.rate.commissionSortingOrder.map(
								(commissionType) => {
									const commission =
										data.configuration.rate.commissions[
											commissionType
										];

									return {
										type: commissionType,

										percent: commission.percent,

										amount: commission.amount,

										threshold: {
											active: commission.threshold.active,

											comparison: {
												direction:
													conditionOperatorMaps
														.fromResponse[
														commission.threshold
															.comparison
															.direction
													],
												value: commission.threshold
													.comparison.value,
											},
											fee: {
												percent:
													commission?.threshold?.fee
														?.percent,
												amount: commission?.threshold
													?.fee?.amount,
											},
										},
									} as ExecutorRateCommissionPlan.Model.Rate.Commission;
								},
						  )
						: undefined,
			},

			action: data.action,

			active: data.active,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model:
			| ExecutorRateCommissionPlan.Model.New
			| ExecutorRateCommissionPlan.Model.Modified,
	): any {
		return {
			classifierId: model.classifierId,

			name: model.name,
			active: model.active,
			action: model.action,
			startDate: model.startDate,
			weekdays: Object.entries(model.weekdays ?? {})
				.filter(([, value]) => value)
				.map(([key]) => weekdayToNumber[key] as number),

			configuration: {
				timeRange: model.timeRange
					? {
							active: model.timeRange.active,
							from: model.timeRange.from,
							to: model.timeRange.to,
					  }
					: undefined,

				rate: model.rate
					? {
							includeCurrentOrdersInDiscountedCommissions:
								model.rate
									.includeCurrentOrdersInDiscountedCommissions,

							commissions: model.rate.commissions.reduce(
								(acc, commission) => ({
									...acc,
									[commission.type]: {
										percent: commission.percent,
										amount: commission.amount,
										threshold: {
											active: commission.threshold.active,
											comparison: {
												direction:
													conditionOperatorMaps
														.toRequest[
														commission.threshold
															.comparison
															.direction
													],
												value: commission.threshold
													.comparison.value,
											},
											fee: {
												percent:
													commission.threshold.fee
														.percent,
												amount: commission.threshold.fee
													.amount,
											},
										},
									},
								}),
								{},
							),

							commissionSortingOrder: model.rate.commissions.map(
								(commission) => commission.type,
							),

							minCommission: {
								active:
									model.rate.minCommission?.active ?? false,
								amount: model.rate.minCommission?.amount ?? 0,
							},

							maxCommission: {
								active:
									model.rate.maxCommission?.active ?? false,
								amount: model.rate.maxCommission?.amount ?? 0,
							},
					  }
					: undefined,
			},
		};
	}
}

namespace ExecutorRateCommissionPlan {
	export interface Model {
		id: number;

		classifier?: ExecutorRateClassifier.Model;

		name: Record<Language, string>;

		startDate: Date;
		weekdays: Model.Weekdays;
		timeRange: Model.TimeRange;

		rate?: Model.Rate;

		action: Model.Action;

		active: boolean;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions
		extends Omit<
			ServiceSubscribeOptionsBase<ExecutorRateCommissionPlan.Model>,
			"order"
		> {
		order?: Partial<Record<keyof Model, SortingOrder>>;
	}

	export namespace Model {
		export enum Action {
			AfterOrderClosed = "after_order_closed",
			AfterOrderAssigned = "after_order_assigned",
			AfterPeriodEnd = "after_end_period",
		}

		export interface Weekdays {
			monday: boolean;
			tuesday: boolean;
			wednesday: boolean;
			thursday: boolean;
			friday: boolean;
			saturday: boolean;
			sunday: boolean;
		}

		export interface TimeRange {
			active: boolean;

			from: number;
			to: number;
		}

		export interface Rate {
			includeCurrentOrdersInDiscountedCommissions: boolean;

			minCommission?: Rate.MinMaxCommission;
			maxCommission?: Rate.MinMaxCommission;

			commissions: Rate.Commission[];
		}

		export interface New {
			classifierId: number;

			name: Record<Language, string>;

			startDate: Date;
			weekdays: Model.Weekdays;
			timeRange: Model.TimeRange;

			rate?: Model.Rate;

			action: Model.Action;

			active: boolean;
		}

		export type Modified = Partial<New> & { id: number };

		export namespace Rate {
			export interface Commission {
				type: Commission.Type;

				percent: number;

				amount: number;

				threshold: Commission.Threshold;
			}

			export interface MinMaxCommission {
				active: boolean;

				amount: number;
			}

			export namespace Commission {
				export enum Type {
					CashOrders = "cashOrders",
					NonCashOrders = "nonCashOrders",
					SuburbanOrders = "suburbanOrders",
					OnlineOrders = "onlineOrders",
					BonusOrders = "bonusOrders",
					ExecutorOrder = "executorOrder",
					CardPaymentToTaxiService = "cardPaymentToTaxiService",
					CardPaymentToExecutor = "cardPaymentToExecutor",
					TerminalToTaxiServiceService = "terminalToTaxiService",
					TerminalToExecutor = "terminalToExecutor",
					LocalTransfer = "localTransfer",
					GlobalTransfer = "globalTransfer",
					ExternalTransfer = "externalTransfer",
				}

				export interface Threshold {
					active: boolean;

					comparison: Threshold.Comparison;
					fee: Threshold.Fee;
				}

				export namespace Threshold {
					export interface Comparison {
						direction: Comparison.Direction;
						value: number;
					}

					export interface Fee {
						percent: number;
						amount: number;
					}

					export namespace Comparison {
						export enum Direction {
							Less = "less",
							Greater = "greater",
							Equal = "equal",
							LessOrEqual = "lessOrEqual",
							GreaterOrEqual = "greaterOrEqual",
						}
					}
				}
			}
		}
	}
}

export default ExecutorRateCommissionPlan;
