/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { LatLngTuple, Map as LeafletMap } from "leaflet";
import { Button, Icon, react, Row, theme, useInternal } from "uikit";
import Control from "react-leaflet-custom-control";

import TaxiService from "../../../../../../../../../../services/TaxiService";
import Map from "../../../../../../../../../../redux/services/Map";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe";
import FormRoot from "../FormRoot";
import ObjectForm from "../ObjectForm";
import PointController from "../PointController";
import MapComponent from "../Map";
import settlementToString from "../PointForm/components/Settlement/utils/objectToLabel";
import streetToString from "../PointForm/components/Street/utils/objectToLabel";
import PointForm from "../PointForm";

import InternalController from "./Controller";

const ObjectContent = react.withController<
	ObjectContent.PropsBase,
	InternalController
>(({ value, language, controller, onChange }) => {
	const mapRef = useRef<LeafletMap | null>(null);
	const objectFormRef = useRef<ObjectForm.Controller | null>(null);
	const shouldTaxiServiceIdUpdatingChangeMapFocusRef = useRef(false);

	const [objectFormQuery, setObjectFormQuery] = useState<PointForm.Query>({
		settlement: settlementToString(value?.address ?? {}),
		street: streetToString(value?.address ?? {}),
		house: value?.address?.house ?? "",
	});

	const defaultValue = useMemo<ObjectContent.Value>(() => ({}), []);

	const [internalValue] = useInternal(value ?? defaultValue);

	const objectFormValue = useMemo<ObjectContent.Value>(
		() => ({ ...internalValue, query: objectFormQuery }),
		[internalValue, objectFormQuery],
	);

	controller.setContext({ objectFormRef });

	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;
	const taxiService = useMemo(
		() =>
			taxiServices?.find(
				(taxiService) => taxiService.id === value?.taxiServiceId,
			),
		[taxiServices, value?.taxiServiceId],
	);

	useEffect(() => {
		if (
			!shouldTaxiServiceIdUpdatingChangeMapFocusRef.current ||
			!taxiService ||
			!mapRef.current
		)
			return;

		mapRef.current.setView(
			[
				taxiService.coordinates.lat,
				taxiService.coordinates.lng,
			] as LatLngTuple,
			mapRef.current.getZoom(),
		);
	}, [taxiService]);

	const mapCenter = useMemo(
		() =>
			internalValue.coordinates ??
			(taxiService &&
				([
					taxiService?.coordinates.lat,
					taxiService?.coordinates.lng,
				] as LatLngTuple)) ??
			([50.455002, 30.511284] as LatLngTuple),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const objectFormOnChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		({ query, ...objectValue }: ObjectForm.Value) => {
			onChange({ ...internalValue, ...objectValue });

			if (query) setObjectFormQuery(query);

			if (internalValue.taxiServiceId !== objectValue.taxiServiceId)
				shouldTaxiServiceIdUpdatingChangeMapFocusRef.current = true;
		},
		[internalValue, onChange],
	);

	const pointControllerOnChange = useCallback(
		(pointValue: PointController.Value) => {
			onChange({
				...internalValue,
				...pointValue,
			});

			setObjectFormQuery({
				settlement: settlementToString(pointValue.address ?? {}),
				street: streetToString(pointValue.address ?? {}),
				house: pointValue.address?.house ?? "",
			});
		},
		[internalValue, onChange],
	);

	const addButtonOnClick = useCallback(() => {
		const { lat, lng } = mapRef.current!.getCenter();

		onChange({
			...internalValue,
			coordinates: {
				lat,
				lng,
			},
		});
	}, [internalValue, onChange]);

	const addButtonIcon = useMemo(
		() => <Icon id="plus" size={16} colors={[theme.colors.white]} />,
		[],
	);

	return (
		<Row sizes="1fr*">
			<FormRoot>
				<ObjectForm
					ref={objectFormRef}
					value={objectFormValue}
					language={language}
					onChange={objectFormOnChange}
				/>
			</FormRoot>
			<MapComponent
				ref={mapRef}
				language={language}
				center={mapCenter}
				zoom={12}
				maxZoom={19}
			>
				<PointController
					value={value}
					language={language}
					autoResolve={internalValue.autoResolve ?? false}
					onChange={pointControllerOnChange}
				/>

				<Control prepend position="topleft">
					<Button.Button
						icon={addButtonIcon}
						onClick={addButtonOnClick}
					/>
				</Control>
			</MapComponent>
		</Row>
	);
}, InternalController);

declare namespace ObjectContent {
	interface Value extends Omit<ObjectForm.Value, "query"> {}

	interface PropsBase {
		value?: Value;

		language: Map.Language;

		onChange: Dispatch<Value>;
	}

	interface Props extends PropsBase {
		controller: Controller;
	}

	type Controller = InternalController;
}

export default ObjectContent;
