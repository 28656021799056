import { getPRPC } from "../hooks/usePRPC";

import createRPCQuery from "./createRPCQuery.util";

async function getCurrentIP() {
	const prpc = getPRPC();

	if (!prpc) return "";

	return createRPCQuery(() =>
		prpc.theirsModel.utils.getIP(),
	) as Promise<string>;
}

export default getCurrentIP;
