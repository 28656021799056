import styled from "styled-components";
import { Row, theme } from "uikit";

const StyledRow = styled(Row)`
	border-bottom: 1px solid ${theme.colors.color_border_basic};
	padding-bottom: 20px;
	gap: 7px;
	max-height: 111px;
	overflow: auto;
`;

export default StyledRow;
