import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import Executor, { ExecutorSubscribeType } from "../../../../services/Executor";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectExecutorWithModalBase: React.FC<
	MultiSelectExecutorWithModalBase.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	options = { order: { callSign: "ASC" } },
	sort,
	...props
}) => {
	const { t } = useTranslation();

	const subscribe = useModelSubscribe(
		{
			...options,
			// subscribeType: ExecutorSubscribeType.EXECUTOR_MULTISELECT_MODAL,
		},
		Executor,
	);

	const items = useMemo(() => subscribe.models, [subscribe.models]);

	const [error, setError] = useState("");

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.alias,
				value: item.id,
				name: item.alias,
				data: item,
			})),
		[items],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const Ids = newValue.map((item) => item.value);
			onChange(Ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t(["all_executors"]),
			allActiveText: t(["all_active_executors"]),
		}),
		[t, titleText],
	);

	return (
		<MultiSelectWithModal
			error={error}
			disabled={disabled}
			value={selected}
			title={texts.title}
			allText={texts.allText}
			allActiveText={texts.allActiveText}
			options={selectOptions}
			onChange={selectOnChange}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			modalSetting={modalSetting}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectExecutorWithModal = memo(
	MultiSelectExecutorWithModalBase,
);

declare namespace MultiSelectExecutorWithModalBase {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		notShowExecutor?: number[];
		options?: Executor.SubscribeOptions;
	}

	type Value = number[];
}

export default MultiSelectExecutorWithModalBase;
