import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";
import { isEqual, isNumber } from "lodash";

import Company from "../../services/Company";
import TaxiService from "../../services/TaxiService";
import Map from "../../redux/services/Map";
import useModelSubscribe from "../../hooks/useModelSubscribe";
import Filters from "../../pages/PreferencesPages/components/ScreenDirectory/components/Objects/components/Header/components/Filters";

function isAllCompanies(
	companies: Filters.Props["companies"],
): companies is ["all"] {
	return companies[0] === "all";
}

function isAllTaxiServices(
	taxiServices: Filters.Props["taxiServices"],
): taxiServices is ["all"] {
	return taxiServices[0] === "all";
}

const CompaniesAndTaxiServicesFilter: React.FC<
	CompaniesAndTaxiServicesFilter.Props
> = ({
	companies: selectedCompanies,
	taxiServices: selectedTaxiServices,
	language,
	children,
	onChangeCompanies,
	onChangeTaxiServices,
	display = "all",
}) => {
	const { t } = useTranslation();
	const companies = useModelSubscribe({}, Company)?.cache;
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const processedSelectedCompanies = useMemo(
		() =>
			isAllCompanies(selectedCompanies)
				? companies?.map((company) => company.id) ?? []
				: selectedCompanies,
		[companies, selectedCompanies],
	);

	const processedSelectedTaxiServices = useMemo(
		() =>
			isAllTaxiServices(selectedTaxiServices)
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								processedSelectedCompanies.includes(
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									taxiService.company!.id,
								),
						)
						.map((taxiService) => taxiService.id) ?? []
				: selectedTaxiServices,
		[selectedTaxiServices, taxiServices, processedSelectedCompanies],
	);

	const taxiServicesById = useMemo(
		() =>
			taxiServices?.reduce((accumulator, taxiService) => {
				accumulator[taxiService.id] = taxiService;

				return accumulator;
			}, {} as Record<TaxiService.Model["id"], TaxiService.Model>) ?? {},
		[taxiServices],
	);

	const companyOptions = useMemo(
		() => [
			...(companies?.map((company) => ({
				key: company.id,
				label: company.name[language],
				value: company,
			})) ?? []),
		],
		[companies, language],
	);

	const filteredTaxiServices = useMemo(
		() =>
			isAllCompanies(selectedCompanies)
				? taxiServices
				: taxiServices?.filter(
						(taxiService) =>
							isNumber(taxiService.company?.id) &&
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							selectedCompanies.includes(taxiService.company!.id),
				  ),
		[selectedCompanies, taxiServices],
	);

	const taxiServiceOptions = useMemo(
		() => [
			...(filteredTaxiServices?.map((taxiService) => ({
				key: taxiService.id,
				label: taxiService.settlement[language],
				value: taxiService,
			})) ?? []),
		],
		[filteredTaxiServices, language],
	);

	const companyMultiSelect = useMemo(
		() =>
			display !== "taxiServices" ? (
				<MultiSelect<Company.Model | Company.Model[]>
					value={processedSelectedCompanies}
					placeholder={t("companiesAndTaxiServicesFilter.str0") ?? ""}
					options={companyOptions}
					all
					onChange={(value) => {
						let newCompanies: number[] | ["all"];

						if (
							companyOptions.every((companyOption) =>
								value.includes(companyOption.key),
							)
						)
							newCompanies = ["all"];
						else {
							newCompanies = value as number[];

							if (!isAllTaxiServices(selectedTaxiServices)) {
								let newTaxiServices: number[] | ["all"] =
									processedSelectedTaxiServices.filter(
										(taxiServiceId) =>
											isNumber(
												taxiServicesById[taxiServiceId]
													.company?.id,
											) &&
											(newCompanies as number[]).includes(
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												taxiServicesById[taxiServiceId]
													.company!.id,
											),
									);

								const allPossibleTaxiServices =
									taxiServices
										?.filter(
											(taxiService) =>
												isNumber(
													taxiService.company?.id,
												) &&
												(
													newCompanies as number[]
												).includes(
													// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													taxiService.company!.id,
												),
										)
										.map((taxiService) => taxiService.id) ??
									[];

								if (
									isEqual(
										allPossibleTaxiServices,
										newTaxiServices,
									)
								)
									newTaxiServices = ["all"];

								onChangeTaxiServices(newTaxiServices);
							}
						}
						onChangeCompanies(newCompanies);
					}}
				/>
			) : null,
		[
			companyOptions,
			display,
			onChangeCompanies,
			onChangeTaxiServices,
			processedSelectedCompanies,
			processedSelectedTaxiServices,
			selectedTaxiServices,
			taxiServices,
			taxiServicesById,
			t,
		],
	);

	const taxiServiceMultiSelect = useMemo(
		() =>
			display !== "companies" ? (
				<MultiSelect<TaxiService | TaxiService[] | undefined>
					value={processedSelectedTaxiServices}
					placeholder={t("companiesAndTaxiServicesFilter.str1") ?? ""}
					options={taxiServiceOptions}
					all
					onChange={(value) => {
						let newTaxiServices: number[] | ["all"];

						if (
							taxiServiceOptions.every((taxiServiceOption) =>
								value.includes(taxiServiceOption.key),
							)
						) {
							newTaxiServices = ["all"];
						} else {
							newTaxiServices = value as number[];
						}

						onChangeTaxiServices(newTaxiServices);
					}}
				/>
			) : null,
		[
			display,
			onChangeTaxiServices,
			processedSelectedTaxiServices,
			taxiServiceOptions,
			t,
		],
	);

	return children ? (
		children({ companyMultiSelect, taxiServiceMultiSelect })
	) : (
		<>
			{companyMultiSelect}
			{taxiServiceMultiSelect}
		</>
	);
};

declare namespace CompaniesAndTaxiServicesFilter {
	type Children = (options: Children.Options) => JSX.Element;

	namespace Children {
		interface Options {
			companyMultiSelect: JSX.Element | null;
			taxiServiceMultiSelect: JSX.Element | null;
		}
	}

	interface Props {
		companies: Company.Model["id"][] | ["all"];
		taxiServices: TaxiService.Model["id"][] | ["all"];

		language: Map.Language;
		children?: Children;

		onChangeCompanies: Dispatch<Company.Model["id"][] | ["all"]>;
		onChangeTaxiServices: Dispatch<TaxiService.Model["id"][] | ["all"]>;

		display?: "all" | "companies" | "taxiServices";
	}
}

export default CompaniesAndTaxiServicesFilter;
