import React, { useMemo } from "react";
import moment from "moment";
import { theme } from "uikit";
import { isNumber, isString, isUndefined } from "lodash";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import useTime from "../../../../../../../hooks/useTime";

function formatDuration(milliseconds: number): string {
	const sign = Math.sign(milliseconds);

	// eslint-disable-next-line no-param-reassign
	milliseconds = Math.abs(milliseconds);

	const seconds = Math.floor((milliseconds / 1e3) % 60);
	const minutes = Math.floor((milliseconds / (1e3 * 60)) % 60);
	const hours = Math.floor((milliseconds / (1e3 * 60 * 60)) % 24);
	const days = Math.floor(milliseconds / (1e3 * 60 * 60 * 24));

	const formattedHours = String(hours).padStart(2, "0");
	const formattedMinutes = String(minutes).padStart(2, "0");
	const formattedSeconds = String(seconds).padStart(2, "0");
	const formattedDays = days !== 0 ? `${days}Д ` : "";

	return `${
		sign === -1 ? "-" : ""
	}${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const TimeToDeliveryCellContent: React.FC<TimeToDeliveryCellContent.Props> = ({
	item,
}) => {
	const currentTime = useTime("every-second");

	const executorArrivalTimestamp = useMemo(
		() =>
			item.executorToOrder[0]?.additionalFields?.arrivalTime ||
			item.orderDate,
		[item.executorToOrder, item.orderDate],
	);

	const withClients = useMemo(
		() => !isUndefined(item.executingStages?.with_clients?.timestamp),
		[item.executingStages?.with_clients?.timestamp],
	);

	const isThereExecutorArrivalTimestamp = useMemo(
		() =>
			isNumber(executorArrivalTimestamp) ||
			isString(executorArrivalTimestamp),
		[executorArrivalTimestamp],
	);

	const timeLeft = useMemo(
		() =>
			isThereExecutorArrivalTimestamp
				? moment(executorArrivalTimestamp).diff(currentTime)
				: undefined,
		[
			currentTime,
			executorArrivalTimestamp,
			isThereExecutorArrivalTimestamp,
		],
	);

	const content = useMemo(
		() => (isUndefined(timeLeft) ? "" : formatDuration(timeLeft)),
		[timeLeft],
	);

	const backgroundColor = useMemo(() => {
		if (withClients) {
			return "#4CAF50";
		}

		return !isUndefined(timeLeft) && timeLeft < 0 ? "#E8807F" : undefined;
	}, [timeLeft, withClients]);

	const textColor = useMemo(
		() =>
			!isUndefined(timeLeft) && timeLeft < 0
				? theme.colors.white
				: undefined,
		[timeLeft],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace TimeToDeliveryCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default TimeToDeliveryCellContent;
