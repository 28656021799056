import { cloneDeep } from "lodash";

import { RowShiftData } from "../../../reducers/Archives/DriverShifts";
import { findValueByLanguageKey } from "../../../../assets/languages/langs";

import { responseDriverShiftsModel } from "./responseData.types";

export const responseModelToRowData = (
	arrModel: responseDriverShiftsModel[],
) => {
	if (!arrModel.length) return [];
	const arr = cloneDeep(arrModel);
	const finalArr: RowShiftData[] = [];
	arr.forEach((el) => {
		const obj: RowShiftData = {
			name: el?.executor?.person?.name,
			surname: el?.executor?.person?.surname,
			callSign: el?.executor?.callSign,
			car: el?.car?.model?.brand?.name,
			registrationNumber: "",
			garageNumber: el?.car?.callSign,
			color:
				findValueByLanguageKey(el?.car?.color?.name) ||
				el?.car?.color?.name?.ru,
			carParks: "",
			openedAt: converterIsoToLocal(el?.openedAt),
			userStartedShift: "",
			status: el?.executor?.status,
			stateSetDate: "",
			currentParking: "",
			parkingNumber: "",
			registrationDateOnParking: "",
			positionLatitude: "",
			positionLongitude: "",
			lastCompletedOrderDate: "",
			rating: "",
		};
		finalArr.push(obj);
	});
	return finalArr;
};

const converterIsoToLocal = (iso: string) => {
	if (!iso) return "";

	const data = new Date(iso);

	const date = data.toLocaleDateString();
	const time = data.toLocaleTimeString();

	return `${date} ${time}`;
};

export default { responseModelToRowData };
