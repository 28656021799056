import { Dispatch, RefObject } from "react";
import { react } from "uikit";

import { NameBase } from "../../../../../../../../../../../../../../../../components/common";

import { ContentErrors } from ".";

interface Context {
	nameRef: RefObject<NameBase.Ref | null>;
	classifierIdValue?: number;
	setErrors: Dispatch<ContentErrors>;
	fee: number;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = !!this.context?.nameRef.current?.validate();
		const isClassifierIdValue = !!this.context?.classifierIdValue;
		const isFeeValue = this.context?.fee || 0;
		const setErrors = this.context?.setErrors;

		const errors = {
			classifierId: !isClassifierIdValue,
			fee: isFeeValue <= 0,
		};

		if (setErrors) setErrors(errors);
		return isNameValid && isClassifierIdValue && isFeeValue > 0;
	}
}
