/* eslint-disable react/require-default-props */

import React from "react";
import styled from "styled-components";
import { Flex, Row, theme } from "uikit";

import { CloseIcon } from "../../icons/close";
import { MenuIcon } from "../../icons/menu-vertical";

const StyledClose = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	color: ${theme.colors.disabled_text};

	&:hover {
		color: white;
	}
`;

interface Props {
	title?: string;
	draggable?: boolean;
	onClose?: () => void;
}

const ModalHeader: React.FC<Props> = ({ title, draggable, onClose }) => (
	<Row
		justify="space-between"
		align="center"
		style={{
			width: "100%",
			height: "32px",
			backgroundColor: theme.colors.primary,
			color: "white",
			borderRadius: "10px 10px 0 0",
			overflow: "hidden",
			flexShrink: 0,
		}}
	>
		<div
			className="handle"
			style={{
				display: "flex",
				flexGrow: 1,
				cursor: draggable ? "grab" : "default",
			}}
		>
			<Flex
				justify="center"
				align="center"
				style={{
					width: "32px",
					height: "32px",
				}}
			>
				<MenuIcon fill={theme.colors.disabled_text} />
			</Flex>

			<Flex
				justify="center"
				align="center"
				style={{
					flexGrow: 1,
				}}
			>
				{title}
			</Flex>
		</div>

		<StyledClose onClick={onClose}>
			<CloseIcon fill="currentColor" />
		</StyledClose>
	</Row>
);

export default ModalHeader;
