import { RegisteredIcon } from "@ioc:uikit/icon";
import React, { memo } from "react";
import styled from "styled-components";
import { Icon, theme } from "uikit";

import {
	StyledRow,
	Style,
	baseStyles,
	flexStyles,
	distributeStyles,
} from "../../../../../components/common";

const StyledBadge = styled(StyledRow)`
	display: flex;
	justify-content: center;
	align-items: center;

	border: 1px solid rgba(33, 51, 63, 0.1);
	background-color: ${theme.colors.button_secondary};
	border-radius: 5px;

	&:hover {
		background-color: ${theme.colors.button_transparent_pressed};
	}

	& * {
		outline: none;
	}
	& svg {
		${({ colors }) => {
			if (typeof colors === "string") return `fill:${colors};`;
			if (typeof colors === "object") {
				return `
				fill: ${colors?.light};

				@media (prefers-color-scheme: dark) {
					& {
						fill: ${colors?.dark};
					}
				};
				`;
			}
			return undefined;
		}};
	}
	${baseStyles}
	${flexStyles}
	${distributeStyles}
`;

const Badge: React.FC<Badge.Props> = ({
	icon,
	tooltip,
	active,
	onClick,
	size = 20,
	width = "32px",
	height = "32px",
	disabled = false,
	...props
}): JSX.Element => (
	<StyledBadge
		w={width}
		h={height}
		cursor={disabled ? "not-allowed" : "pointer"}
		title={tooltip || undefined}
		onClick={onClick}
		colors={active ? theme.colors.accent : theme.colors.text_placeholder}
		{...props}
	>
		<Icon
			id={icon}
			size={size}
			colors={[
				active ? theme.colors.accent : theme.colors.text_placeholder,
			]}
		/>
	</StyledBadge>
);

declare namespace Badge {
	interface Props extends Style.Row {
		icon: RegisteredIcon;
		tooltip?: string | null;
		active?: boolean;
		onClick: () => void;
		size?: number;
		width?: string;
		height?: string;
		disabled?: boolean;
	}
}

export const BadgeMemo = memo(Badge);
export default Badge;
