import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row, Stepper } from "uikit";
import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import Label from "../../../../../../../../../../../../../../components/Label";
import Rules from "../Rules";

const FlexibleMode: React.FC<FlexibleMode.Props> = ({
	value,

	disabled,

	onChange,
}) => {
	const defaultValue = useMemo<FlexibleMode.Value>(
		() => ({
			period: {
				active: false,
				value: 0,
			},

			grid: [],
		}),
		[],
	);

	const valueEditor = useObjectEditor(value ?? defaultValue, onChange);

	const periodEditor = valueEditor.usePropertyEditor("period");

	const periodActiveValue = periodEditor.useGetter("active");
	const periodActiveOnChange = periodEditor.useSetter("active");

	const periodValueValue = periodEditor.useGetter("value");
	const periodValueOnChange = periodEditor.useSetter("value");

	const gridValue = valueEditor.useGetter("grid");
	const gridOnChange = valueEditor.useSetter("grid");

	const { t } = useTranslation();
	return (
		<Rules
			value={gridValue}
			disabled={disabled}
			leftFilters={
				<CheckBoxWithContent
					value={periodActiveValue}
					disabled={disabled}
					onChange={periodActiveOnChange}
				>
					<Row align="center" gaps="10px*">
						<Label>
							{t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.flexibleMode.str100",
							) ?? ""}
						</Label>
						<Stepper
							value={periodValueValue}
							disabled={disabled || !periodActiveValue}
							onChange={(value) =>
								periodValueOnChange(value ?? 0)
							}
						/>
						<Label>
							{t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.flexibleMode.str101",
							) ?? ""}
						</Label>
					</Row>
				</CheckBoxWithContent>
			}
			onChange={gridOnChange}
		/>
	);
};

declare namespace FlexibleMode {
	interface Item {
		threshold: number;

		discount: {
			value: number;
			unit: CustomerRate.Model.Unit;
		};

		onlineDiscount: {
			active: boolean;
			value: number;
			unit: CustomerRate.Model.Unit;
		};
	}

	interface Value {
		period: {
			active: boolean;
			value: number;
		};

		grid: Item[];
	}

	interface Props {
		value?: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default FlexibleMode;
