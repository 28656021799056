import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, theme } from "uikit";

import { getExecutorStatuses } from "../../constants";

const TableFooter = ({ executors }) => {
	const { t } = useTranslation();
	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const TableFooterMemo = useMemo(
		() => (
			<Row
				style={{
					borderTop: `1.5px solid ${theme.colors.button_secondary}`,
				}}
			>
				<Column
					gaps="3px*"
					style={{
						borderRight: `1.5px solid ${theme.colors.button_secondary}`,
						paddingInline: "8px",
						paddingBlock: "4px",
					}}
				>
					<span className="totalRow">{`${t(`total`, "Total")}: ${
						executors.length
					}`}</span>
					<span className="totalRow">{`${t(
						`orderPage.executors.total_online`,
						"In the app",
					)}: ${
						executors.filter((item) => item.online).length
					}`}</span>
					<span className="totalRow">{`${t(
						`orderPage.executors.total_offline`,
						"Not in the app",
					)}: ${
						executors.filter((item) => !item.online).length
					}`}</span>
				</Column>
				<Column
					gaps="3px*"
					style={{
						borderRight: `1.5px solid ${theme.colors.button_secondary}`,
						paddingInline: "8px",
						paddingBlock: "4px",
					}}
				>
					<span className="totalRow">{`${t(
						`orderPage.executors.opened_schedule`,
						"Opened schedule",
					)}: ${
						executors.filter((item) => item.isWorking).length
					}`}</span>
					<span className="totalRow">{`${t(
						`orderPage.executors.closed_schedule`,
						"Closed schedule",
					)}: ${
						executors.filter((item) => !item.isWorking).length
					}`}</span>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.closed.label}: `}
						{
							executors.filter((item) => item.status === "closed")
								.length
						}
					</span>
				</Column>
				<Column
					gaps="3px*"
					style={{
						borderRight: `1.5px solid ${theme.colors.button_secondary}`,
						paddingInline: "8px",
						paddingBlock: "4px",
					}}
				>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.available.label}: `}
						{
							executors.filter(
								(item) => item.status === "available",
							).length
						}
					</span>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.on_order.label}: `}
						{
							executors.filter(
								(item) => item.status === "on_order",
							).length
						}
					</span>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.ownOrder.label}: `}
						{
							executors.filter((executor) =>
								executor?.executorToOrders?.find(
									(order) =>
										order.source === "executor" &&
										!order.closedAt,
								),
							).length
						}
					</span>
				</Column>
				<Column
					gaps="3px*"
					style={{
						paddingInline: "8px",
						paddingBlock: "4px",
					}}
				>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.busy.label}: `}
						{
							executors.filter((item) => item.status === "busy")
								.length
						}
					</span>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.dinner.label}: `}
						{
							executors.filter((item) => item.status === "dinner")
								.length
						}
					</span>
					<span className="totalRow">
						{`${EXECUTOR_STATUSES.home.label}: `}
						{
							executors.filter((item) => item.status === "home")
								.length
						}
					</span>
				</Column>
			</Row>
		),
		[executors, t],
	);

	return TableFooterMemo;
};

export default TableFooter;
