import React, { useMemo } from "react";
import { Column, Row } from "uikit";
import { useTranslation } from "react-i18next";

import useFormatDate from "../../../../../../../../../../../../../../../../hooks/useFormatDate";

import Title from "./components/Title";
import Root from "./components/Root";

const Header: React.FC<Header.Props> = ({ title, createdAt }) => {
	const { t } = useTranslation();
	const formatDate = useFormatDate();

	const formattedCreatedAt = useMemo(
		() => formatDate(createdAt, "DD.MM.YYYY HH:mm"),
		[createdAt, formatDate],
	);

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "13px 15px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				{createdAt ? (
					<Column align="end" gaps="15px">
						<Row gaps="7px">
							<span>{t(`createdAt`)}:</span>
							<span style={{ color: "black", fontWeight: "600" }}>
								{formattedCreatedAt}
							</span>
						</Row>
					</Column>
				) : null}
			</Row>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		title: string;
		createdAt?: string;
	}
}

export default Header;
