/* eslint-disable no-shadow */

import React, { Dispatch, useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { clone } from "lodash";

import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import Company from "../../../../../../../../../../../../../../services/Company";
import Language from "../../../../../../../../../../../../../../services/Language";
import Sector from "../../../../../../../../../../../../../../services/Sector";

import Background from "./components/Background";
import Item from "./components/Item";
import Root from "./components/Root";
import Header from "./components/Header";

const Group: React.FC<Group.Props> = ({
	value,
	editing,

	language,

	onChange,
	onChangeEditing,
}) => {
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache ?? [];
	const companies = useModelSubscribe({}, Company)?.cache ?? [];

	const sorted = useMemo(
		() =>
			value.items.sort(
				(item1, item2) =>
					item2.position.valueOf() - item1.position.valueOf(),
			),
		[value],
	);

	const headerValue = useMemo(() => {
		const payload = {
			isMaxSpeedEnabled: value.isMaxSpeedEnabled,
			maxSpeed: value.maxSpeed,
			isBasicAverageSpeedEnabled:
				value.isBasicAverageSpeedEnabled || false,
			isAverageSpeedEnabled: value.isAverageSpeedEnabled || false,

			averageSpeed: value.averageSpeed?.length
				? [...value.averageSpeed].map((item, i) => ({
						...item,
						id: i + 1,
				  }))
				: [],
			basicAverageSpeed: value.basicAverageSpeed?.length
				? [...value.basicAverageSpeed].map((item, i) => ({
						...item,
						id: i + 1,
				  }))
				: [],
		};

		return payload;
	}, [value]);

	return (
		<DragDropContext
			onDragEnd={(result) => {
				const sourceIndex = result.source.index;
				const destinationIndex = result.destination?.index;

				if (
					typeof destinationIndex === "undefined" ||
					sourceIndex === destinationIndex
				)
					return;

				const newValue = clone(value);

				newValue.items = clone(newValue.items);

				const deltaIndexSign = Math.sign(
					sourceIndex - destinationIndex,
				);

				const destinationPosition = sorted[destinationIndex].position;

				for (
					let index = destinationIndex;
					(deltaIndexSign < 0 && index >= sourceIndex) ||
					(deltaIndexSign > 0 && index <= sourceIndex);
					index += deltaIndexSign
				) {
					const newValueItemIndex = newValue.items.findIndex(
						(item) => sorted[index].id === item.id,
					);

					const newValueItem = clone(
						newValue.items[newValueItemIndex],
					);

					newValueItem.position =
						index === sourceIndex
							? destinationPosition
							: sorted[index + deltaIndexSign].position;

					newValue.items[newValueItemIndex] = newValueItem;
				}

				onChange(newValue, { type: "change-position" });
			}}
		>
			<Root>
				<Header
					value={headerValue}
					onChange={(headerValue) =>
						onChange(
							{ ...value, ...headerValue },
							{ type: "change-max-speed" },
						)
					}
				/>
				<Droppable droppableId="droppable">
					{(provided) => (
						<Background
							ref={provided.innerRef}
							{...provided.droppableProps}
							gaps="1px*"
							maxedWidth
						>
							{sorted.map((item, index) => {
								const taxiService = taxiServices.find(
									(taxiService) =>
										taxiService.id === item.taxiServiceId,
								);

								const company = taxiService
									? companies.find(
											(company) =>
												company.id ===
												taxiService.company?.id,
									  )
									: undefined;

								return (
									<Item
										key={item.id}
										id={item.id}
										index={index}
										name={item.name[language]}
										subname={`${
											company?.name[language] ?? ""
										}, ${
											taxiService?.settlement[language] ??
											""
										}`}
										active={item.active}
										selected={item.selected}
										modified={item.modified}
										valid={item.valid}
										editing={editing}
										isParking={item.isParking}
										isPriceZone={item.isPriceZone}
										isMaxSpeedEnabled={
											item.isMaxSpeedEnabled
										}
										maxSpeed={item.maxSpeed}
										onSelect={(ctrl) => {
											let newValue: Group.Value;

											if (ctrl) {
												newValue = clone(value);

												newValue.items = clone(
													newValue.items,
												);

												newValue.items[index] = clone(
													newValue.items[index],
												);
												newValue.items[index].selected =
													true;
											} else {
												newValue = clone(value);
												newValue.items =
													newValue.items.map(
														(oldItem) => {
															if (
																oldItem.id ===
																item.id
															)
																if (
																	!item.selected
																)
																	return {
																		...oldItem,
																		selected:
																			true,
																	};
																else;
															else if (
																oldItem.selected
															)
																return {
																	...oldItem,
																	selected:
																		false,
																};

															return oldItem;
														},
													);
											}

											onChange(newValue, {
												type: ctrl
													? "select"
													: "set-selection",
											});
										}}
										onUnselect={() => {
											if (!item.selected) return;

											const newValue = clone(value);

											newValue.items = clone(
												newValue.items,
											);

											newValue.items[index] = clone(
												newValue[index],
											);
											newValue.items[index].selected =
												false;

											onChange(newValue, {
												type: "unselect",
											});
										}}
										onEdit={() => {
											onChangeEditing(true);
										}}
									/>
								);
							})}
							{provided.placeholder}
						</Background>
					)}
				</Droppable>
			</Root>
		</DragDropContext>
	);
};

declare namespace Group {
	interface Item {
		id: number;

		taxiServiceId: number;

		position: Date | number;
		name: Record<Language, string>;
		isMaxSpeedEnabled: boolean;
		maxSpeed: number;

		selected: boolean;
		modified: boolean;
		valid: boolean;

		active: boolean;
		isParking: boolean;
		isPriceZone: boolean;

		isAverageSpeedEnabled: boolean;
		averageSpeed: Sector.AverageSpeed[];
		isBasicAverageSpeedEnabled: boolean;
		basicAverageSpeed: Sector.AverageSpeed[];
	}

	interface Value {
		items: Item[];

		isMaxSpeedEnabled: boolean;
		maxSpeed: number;

		isAverageSpeedEnabled?: boolean;
		averageSpeed?: Sector.AverageSpeed[];
		isBasicAverageSpeedEnabled?: boolean;
		basicAverageSpeed?: Sector.AverageSpeed[];
	}

	interface Details {
		type:
			| "set-selection"
			| "select"
			| "unselect"
			| "change-position"
			| "change-max-speed";
	}

	interface Props {
		value: Value;
		editing: boolean;

		language: Language;

		onChange: (value: Value, details: Details) => void;
		onChangeEditing: Dispatch<boolean>;
	}
}

export default Group;
