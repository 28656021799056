import useOrderTypeTracking from "./useOrderTypeTracking";

const orderTableTypes = [
	"live",
	"closed",
	"preliminary",
	"executable",
	"all",
] as const;

function useOrderTracking() {
	orderTableTypes.forEach((type) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useOrderTypeTracking(type);
	});
}

export default useOrderTracking;
