import { TFunction } from "i18next";

import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	CREATED_AT = "createdAt",
	TYPE = "type",
	SIMPLE_STATUS = "simpleStatus",
	PHONE = "phone",
	TITLE = "title",
	TEXT = "text",
	COMPANY = "company",
	TAXI_SERVICE = "taxiService",
	MEDIA = "media",
}

export const getColumns = (t: TFunction): Content.ColumnType[] => [
	{
		id: Columns.CREATED_AT,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.pushHistory.content.createdAt",
			) ?? "",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.TYPE,
		label:
			t("pages.mainPage.pages.archives.tabs.pushHistory.content.type") ??
			"",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.SIMPLE_STATUS,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.pushHistory.content.simpleStatus",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.TITLE,
		label:
			t("pages.mainPage.pages.archives.tabs.pushHistory.content.title") ??
			"",
		width: 200,
		sortable: true,
	},
	{
		id: Columns.TEXT,
		label:
			t("pages.mainPage.pages.archives.tabs.pushHistory.content.text") ??
			"",
		minWidth: 300,
		sortable: true,
	},
	{
		id: Columns.MEDIA,
		label:
			t("pages.mainPage.pages.archives.tabs.pushHistory.content.media") ??
			"",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.COMPANY,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.pushHistory.content.company",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.TAXI_SERVICE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.pushHistory.content.taxiService",
			) ?? "",
		width: 120,
		sortable: true,
	},
]; // columns
