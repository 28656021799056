import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CounterpartyCheckContent: React.FC<CounterpartyCheckContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => {
		const payload = item?.checkCard?.check?.additionalFields?.checkValue;
		return payload || "";
	}, [item]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CounterpartyCheckContent {
	interface Props {
		item: Order.Model;
	}
}

export default CounterpartyCheckContent;
