/* eslint-disable react/require-default-props */

import React, { PropsWithChildren, useMemo } from "react";
import Draggable from "react-draggable";
import { Column } from "uikit";

import Modal, { ModalProps } from "../ModalController/Modal";

import ModalHeader from "./ModalHeader";

export interface BaseModalProps extends ModalProps, PropsWithChildren {
	title?: string;
	shadow?: boolean | string;
	draggable?: boolean;
	style?: React.CSSProperties;
	onClose?: () => void;
}

const BaseModal: React.FC<BaseModalProps> = ({
	title,
	dimmer,
	shadow = !dimmer,
	draggable = true,
	style,
	onClose,
	children,
	...props
}) => {
	const content = useMemo(
		() => (
			<Column
				style={{
					borderRadius: "10px",
					boxShadow: shadow ? "0px 3px 14px rgba(0,0,0,0.2)" : "none",
					...style,
				}}
			>
				<ModalHeader
					title={title}
					draggable={draggable}
					onClose={onClose}
				/>
				{children}
			</Column>
		),
		[children, draggable, onClose, shadow, style, title],
	);

	return (
		<Modal dimmer={dimmer} {...props}>
			{useMemo(
				() =>
					draggable ? (
						<Draggable handle=".handle">{content}</Draggable>
					) : (
						content
					),
				[content, draggable],
			)}
		</Modal>
	);
};

export default BaseModal;
