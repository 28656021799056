/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import {
	State,
	ModalFilter,
	MessagesTypeFilter,
	BodyFilter,
} from "./interface";

export const baseFilters: ModalFilter = {
	callSigns: ["all"],
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	executorIds: [],
	fleetIds: [],
	executorGroupIds: [],
};

const initialBodyFilter: BodyFilter = {
	criteria: baseFilters,
	isEnabled: false,
};

const initialState: State = {
	filterExecutorsRead: initialBodyFilter,
	filterExecutorsWrite: initialBodyFilter,
};

const hashFilter = (filter: ModalFilter): string =>
	CryptoJS.MD5(JSON.stringify(filter)).toString();

const baseFilterHash = hashFilter(baseFilters);

const setMegaFilter: ReduxReducer<
	State,
	{
		type: MessagesTypeFilter;
		data: ModalFilter;
	}
> = (state, action) => {
	state[action.payload.type].criteria = action.payload.data;

	/* We compare hashes to improve performance when comparing large objects, since computing and comparing hashes is usually faster than deep comparison of all properties of an object. */
	state[action.payload.type].isEnabled =
		hashFilter(state[action.payload.type].criteria) !== baseFilterHash;
};

const orderChatsFilters = createSlice({
	name: Orders.ConstantOrderSlice.CHAT_FILTERS,
	initialState,
	reducers: {
		setMegaFilter,
	},
});

export const orderChatsFiltersAction = orderChatsFilters.actions;
export const orderChatsFiltersReducer = orderChatsFilters.reducer;
export type orderChatsFiltersState = State;

export default orderChatsFilters;
