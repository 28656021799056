/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { InputBorders, useInternal } from "uikit";
import PropsOf from "uikit/src/types/PropsOf";

import MultiSelectModal from "../MultiSelectModal";

import Root from "./components/Root";
import Text from "./components/Text";

type Key = string | number;

const MultiSelectWithModal = <OptionValue, ValueType extends Key>({
	value,
	disabled,
	title,
	options,
	allText,
	onChange,
	...props
}: MultiSelectWithModal.Props<OptionValue, ValueType>) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const defaultValue = useMemo(() => [], [value]);

	const [internalValue, setInternalValue] = useInternal(
		value ?? defaultValue,
	);

	const [showModal, setShowModal] = useState(false);

	const optionByKey = useMemo(
		() =>
			options?.reduce((accumulator, option: any) => {
				if (option?.key) {
					accumulator[option.key] = option;
				}

				return accumulator;
			}, {} as Record<any, MultiSelectWithModal.Option<unknown>>) ?? {},
		[options],
	);

	const valueText = useMemo(() => {
		if (allText && options?.length === value?.length) return allText;
		return internalValue
			.map((key) => optionByKey?.[key]?.label)
			.filter((label) => !!label)
			.join(", ");
	}, [allText, options?.length, value?.length, internalValue, optionByKey]);

	const hasPlaceholder = useMemo(
		() => valueText.length === 0,
		[valueText.length],
	);

	const text = useMemo(() => valueText || (title ?? ""), [title, valueText]);

	const rootOnClick = useCallback(
		() => !disabled && setShowModal(true),
		[disabled],
	);

	const modalOnSubmit = useCallback(
		(value: MultiSelectModal.Value<ValueType>) => {
			onChange?.(value);
			setInternalValue(value);
			setShowModal(false);
		},
		[onChange, setInternalValue],
	);

	const modalOnClose = useCallback(() => setShowModal(false), []);

	return (
		<Root disabled={!!disabled} onClick={rootOnClick}>
			<InputBorders.InputBorders {...props} disabled={disabled}>
				<Text isPlaceholder={hasPlaceholder}>{text}</Text>
			</InputBorders.InputBorders>
			{showModal && (
				<MultiSelectModal
					value={internalValue}
					title={title}
					options={options}
					onSubmit={modalOnSubmit}
					onClose={modalOnClose}
				/>
			)}
		</Root>
	);
};

declare namespace MultiSelectWithModal {
	type Value<Type extends Key> = Type[];

	type Option<Value> = MultiSelectModal.Option<Value>;

	export interface PropsBase<OptionValue, ValueType extends Key> {
		value?: Value<ValueType>;

		title?: string;
		options?: Option<OptionValue>[];
		allText?: string;

		onChange?: Dispatch<Value<ValueType>>;
	}

	type Props<OptionValue, ValueType extends Key> = Omit<
		PropsOf<typeof InputBorders.InputBorders>,
		keyof PropsBase<OptionValue, ValueType>
	> &
		PropsBase<OptionValue, ValueType>;
}

export default MultiSelectWithModal;
