import makeTable from "./makeTable";

const dispatcher = makeTable({
	allIds: [
		"fullName",
		"alias",
		"phones",
		"role",
		"status",
		"registeredAt",
		"access",
		"birthAt",
	],
	i18tPath: (id) => `tableColumns.accounts.dispatcher.${id}`,
	defaultIds: [
		"fullName",
		"alias",
		"phones",
		"role",
		"status",
		"registeredAt",
		"access",
		"birthAt",
	],
	initialSortMethod: {
		column: "status",
		type: "asc",
	},
	initialWidths: {
		fullName: 331,
		alias: 200,
		phones: 240,
		role: 140,
		status: 140,
		registeredAt: 150,
		access: 331,
		birthAt: 150,
	},
	initialResizable: {
		fullName: true,
		alias: true,
		phones: true,
		role: true,
		status: true,
		registeredAt: true,
		access: true,
		birthAt: true,
	},
	initialSortable: {
		fullName: true,
		alias: true,
		phones: true,
		role: true,
		status: true,
		registeredAt: true,
		access: true,
		birthAt: true,
	},
});

export default dispatcher;
