import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 300px;
	height: 300px;

	background-color: ${theme.colors.white};
	& > div {
		border-bottom: 1px solid ${theme.colors.color_border_basic};
		&: last-child {
			border-bottom: none;
		}
	}
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

export default Root;
