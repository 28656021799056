import React, {
	Dispatch,
	memo,
	useCallback,
	// useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import moment from "moment";
import { CheckBoxWithContent } from "uikit";

// import ExecutorRateClassifier from "../../../../../../../../../../../../services/ExecutorRateClassifier";
import ExecutorRateCommissionPlan from "../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
import ExecutorRateDiscountPlan from "../../../../../../../../../../../../services/ExecutorRateDiscountPlan";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import { createObjectLanguageNames } from "../../../../../../../../../../../../assets/languages/langs";
import Root from "../../../../../../../../components/Root";
import TabRoot from "../TabRoot";

import Content from "./components/Content";
import EditModal from "./components/EditModal";

const sort = {};

const defaultCommission = {
	percent: 0,
	amount: 0,

	condition: {
		percent: 0,
		amount: 0,
		value: 0,
	},
};

const defaultCommissions = Object.values(
	ExecutorRateCommissionPlan.Model.Rate.Commission.Type,
).map((type) => ({ id: type, ...defaultCommission }));

const DiscountPlanTab: React.FC<DiscountPlanTab.Props> = memo(
	({ value, commissionPlans, visible, onChange }) => {
		const { t } = useTranslation();
		const language = useTypedSelector((state) => state.session.language);

		// const [classifiers, setClassifiers] = useState<
		// 	ExecutorRateClassifier.Model[]
		// >([]);

		const [selected, setSelected] = useState<Content.Item.Id[]>([]);
		const [showDeleteModal, setShowDeleteModal] = useState(false);
		const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
			null,
		);

		const valueEditor = useObjectEditor(value, onChange);

		const ignoreOldOrders = valueEditor.useGetter("ignoreOldOrders");
		const setIgnoreOldOrders = valueEditor.useSetter("ignoreOldOrders");

		const discountPlans = valueEditor.useGetter("discountPlans");

		const discountPlansEditor =
			valueEditor.usePropertyEditor("discountPlans");

		const editItem = useCallback(
			(id: Content.Item.Id) => {
				const discountPlan = discountPlans.find(
					(subscription) => subscription.id === id,
				);
				// const commissionPlanId =	commissionPlans.find(
				// 		(commissionPlan) =>
				// 			commissionPlan.id === value.commissionPlanId,
				// 	)

				if (!discountPlan) return;

				setEditingItem({
					id,
					createdAt: discountPlan?.createdAt,
					name: discountPlan.name[language],

					// classifierId: discountPlan.classifier?.id,

					startDate: {
						value: discountPlan.startDate,
					},

					timeRange: {
						active: discountPlan.timeRange.active,

						from: new Date(discountPlan.timeRange.from),
						to: new Date(discountPlan.timeRange.to),
					},

					period: {
						unit: discountPlan.period.type,
						value: discountPlan.period.value,
					},

					threshold: {
						value: discountPlan.threshold,
					},

					weekdays: discountPlan.weekdays,

					commissions:
						discountPlan.rate?.commissions?.map((commission) => ({
							id: commission.type,

							percent: commission.percent,
							amount: commission.amount,

							condition: {
								active: commission.threshold.active,

								type: commission.threshold.comparison.direction,
								value: commission.threshold.comparison.value,

								percent: commission.threshold.fee.percent,
								amount: commission.threshold.fee.amount,
							},
						})) ?? [],

					commissionPlanId: discountPlan?.commissionPlan?.id,

					minFee: {
						active:
							discountPlan.rate?.minCommission?.active ?? false,
						value: discountPlan.rate?.minCommission?.amount ?? 0,
					},

					maxFee: {
						active:
							discountPlan.rate?.maxCommission?.active ?? false,
						value: discountPlan.rate?.maxCommission?.amount ?? 0,
					},
				});
			},
			[discountPlans, language],
		);

		const defaultPageHeaderCanEdit = useMemo(
			() => selected.length === 1,
			[selected.length],
		);

		const defaultPageHeaderCanDelete = useMemo(
			() => selected.length !== 0,
			[selected.length],
		);

		const defaultPageHeaderCanCopy = useMemo(
			() => selected.length === 1,
			[selected.length],
		);

		const defaultPageHeaderOnAdd = useCallback(() => {
			setEditingItem({
				id: Symbol("commission plan id"),

				startDate: {
					value: moment().startOf("day").toDate(),
				},

				timeRange: {
					active: false,
				},

				period: {
					unit: ExecutorRateDiscountPlan.Model.Period.Type.Daily,
					value: 1,
				},

				threshold: {
					value: 0,
				},

				weekdays: {
					monday: true,
					tuesday: true,
					wednesday: true,
					thursday: true,
					friday: true,
					saturday: true,
					sunday: true,
				},

				commissions: defaultCommissions,

				minFee: {
					active: false,
					value: 0,
				},

				maxFee: {
					active: false,
					value: 0,
				},
			});
		}, []);

		const defaultPageHeaderOnEdit = useCallback(() => {
			editItem(selected[0]);
		}, [editItem, selected]);

		const defaultPageHeaderOnDelete = useCallback(() => {
			setShowDeleteModal(true);
		}, []);

		const defaultPageHeaderOnCopy = useCallback(() => {
			const subscription = discountPlans.find(
				(subscription) => subscription.id === selected[0],
			);

			if (!subscription) {
				return;
			}

			const newSubscription = {
				...subscription,
				id: Symbol("subscription id"),
			};

			discountPlansEditor.push(newSubscription);
		}, [selected, discountPlans, discountPlansEditor]);

		const editModalOnCancel = useCallback(() => {
			setEditingItem(null);
		}, []);

		const editModalOnSave = useCallback(
			(value: EditModal.Value.Validated) => {
				const item: DiscountPlanTab.Value.DiscountPlan = {
					id: value.id,

					// classifier: classifiers.find(
					// 	(classifier) => classifier.id === value.classifierId,
					// ),

					commissionPlan: commissionPlans.find(
						(commissionPlan) =>
							commissionPlan.id === value.commissionPlanId,
					),
					name: createObjectLanguageNames(value.name),
					// name: {
					// 	uk: value.name,
					// 	az: value.name,
					// 	tr: value.name,
					// 	en: value.name,
					// 	ru: value.name,
					// },

					threshold: value.threshold.value ?? 0,

					startDate: value.startDate.value ?? new Date(),

					weekdays: value.weekdays,

					period: {
						type: value.period.unit,
						value: value.period.value,
					},

					timeRange: {
						active: value.timeRange.active,

						from: value.timeRange.from?.getTime() ?? 0,
						to: value.timeRange.to?.getTime() ?? 0,
					},

					rate: {
						considerTemporaryDiscountedOrders: false,
						considerTemporaryCommissionOrders: false,

						minCommission: {
							active: value.minFee.active,
							amount: value.minFee.value ?? 0,
						},

						maxCommission: {
							active: value.maxFee.active,
							amount: value.maxFee.value ?? 0,
						},

						commissions: value.commissions.map((commission) => ({
							type: commission.id,

							percent: commission.percent,
							amount: commission.amount,

							threshold: {
								active: commission.condition.active ?? false,

								comparison: {
									direction:
										commission.condition.type ??
										ExecutorRateCommissionPlan.Model.Rate
											.Commission.Threshold.Comparison
											.Direction.Equal,
									value: commission.condition.value || 0,
								},

								fee: {
									percent: commission.condition.percent,
									amount: commission.condition.amount,
								},
							},
						})),
					},

					action: ExecutorRateCommissionPlan.Model.Action
						.AfterOrderClosed,

					createdAt: value.createdAt ?? new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					deletedAt: null,
				};

				const index = discountPlansEditor.value.findIndex(
					(subscription) => subscription.id === value.id,
				);

				if (index === -1) {
					discountPlansEditor.push(item);
				} else {
					discountPlansEditor.set(index, item);
				}

				setEditingItem(null);
			},
			[commissionPlans, discountPlansEditor],
		);

		const deleteModalOnCancel = useCallback(() => {
			setShowDeleteModal(false);
		}, []);

		const deleteModalOnConfirm = useCallback(() => {
			selected.forEach((id) => {
				const index = discountPlansEditor.value.findIndex(
					(subscription) => subscription.id === id,
				);

				if (index !== -1) {
					discountPlansEditor.remove(index);
				}
			});

			setShowDeleteModal(false);
		}, [selected, discountPlansEditor]);

		// useEffect(() => {
		// 	ExecutorRateClassifier.getAll({}).then(setClassifiers);
		// }, []);

		return (
			<TabRoot hasPaddings={false} visible={visible}>
				<Root sizes="auto! 1fr" maxedWidth maxedHeight>
					<DefaultPageHeader
						canEdit={defaultPageHeaderCanEdit}
						canDelete={defaultPageHeaderCanDelete}
						onAdd={defaultPageHeaderOnAdd}
						onDelete={defaultPageHeaderOnDelete}
						onEdit={defaultPageHeaderOnEdit}
						afterAdditionalButtons={[
							{
								icon: { id: "copy", size: 20 },
								onClick: defaultPageHeaderOnCopy,
								disabled: !defaultPageHeaderCanCopy,
							},
						]}
						leftFilters={
							<CheckBoxWithContent
								value={ignoreOldOrders}
								onChange={setIgnoreOldOrders}
							>
								{t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.str200",
								) ?? ""}
							</CheckBoxWithContent>
						}
					/>
					<Content
						selected={selected}
						sort={sort}
						data={discountPlans}
						loading={false}
						onChangeSelected={setSelected}
						onChangeSort={noop}
						onEdit={editItem}
						onLoadMore={noop}
					/>
					{editingItem && (
						<EditModal
							value={editingItem}
							commissionPlans={commissionPlans}
							language={language}
							onCancel={editModalOnCancel}
							onSave={editModalOnSave}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							label={`${
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.str201",
								) ?? ""
							}?`}
							onCancel={deleteModalOnCancel}
							onConfirm={deleteModalOnConfirm}
						/>
					)}
				</Root>
			</TabRoot>
		);
	},
);

declare namespace DiscountPlanTab {
	interface Value {
		discountPlans: Value.DiscountPlan[];

		ignoreOldOrders: boolean;
	}

	namespace Value {
		interface DiscountPlan
			extends Omit<ExecutorRateDiscountPlan.Model, "id"> {
			[x: string]: any;
			id: number | symbol;
		}
	}

	interface Props {
		value: Value;

		commissionPlans: ExecutorRateCommissionPlan.Model[];
		visible: boolean;
		disabled?: boolean;

		onChange: Dispatch<Value>;
	}
}

export default DiscountPlanTab;
