/* eslint-disable react/require-default-props */

import React, { PropsWithChildren, useEffect, useMemo, useRef } from "react";

import modal, { nextUID } from "../../redux/reducers/modal.reducer";
import { useTypedDispatch } from "../../redux/store";

export interface ModalProps extends PropsWithChildren {
	dimmer?: boolean | string;
}

const Modal: React.FC<ModalProps> = ({ dimmer, children }) => {
	const dispatch = useTypedDispatch();
	const uid = useRef(nextUID());

	const content = useMemo(() => children, [children]);

	useEffect(() => {
		dispatch(
			modal.actions.pushWindow({
				type: "window",
				uid: uid.current,
				dimmer,
				content,
			}),
		);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			dispatch(modal.actions.removeWindow(uid.current));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(
			modal.actions.editWindow({
				type: "window",
				uid: uid.current,
				dimmer,
				content,
			}),
		);
	}, [dimmer, dispatch, content]);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <></>;
};

export default Modal;
