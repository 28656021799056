import styled from "styled-components";

const PriceLabel = styled.span`
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;

	color: rgb(248, 53, 40);
`;

export default PriceLabel;
