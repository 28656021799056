import { Button, DateAndTimePicker, Icon, Row, TextBox } from "uikit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../redux/store";
import { GetExecutorLocationsHistoryFilters } from "../../../../../../../redux/services/Executor/getExecutorTrack";
import getExecutorByCallSign from "../../../../../../../redux/services/Executor/getExecutorByCallSign";
import { GPSHistoryFilters } from "../../../../../../../redux/reducers/Archives/GPSLog/interface";
import archiveGPSLog from "../../../../../../../redux/reducers/Archives/GPSLog";
import useDebouncedValue from "../../../../../../../hooks/useDebouncedValue";
import useDatePickerLocale from "../../../../../../../hooks/useDatePickerLocale";
import useGetGPSHistory from "../../../../../../../hooks/useGetGPSHistory";
import Header from "../../../../../../../components/BasicPageLayout/Header";
import CompaniesAndTaxiServicesFilter from "../../../../../../../components/CompaniesAndTaxiServicesFilter";
import { getNumberIds } from "../../../utils";

const GPSLogHeader: React.FC = () => {
	const { addFilters } = useGetGPSHistory();
	const { t } = useTranslation();

	const mapLang = useTypedSelector((state) => state.mapReducer.mapLang);
	const locale = useDatePickerLocale();

	const [executorCallSign, setDriverCallsign] = useState("");
	const debouncedExecutorCallSign = useDebouncedValue(executorCallSign, 250);

	const [dateFrom, setDateFrom] = useState(moment().startOf("day").toDate());
	const [dateTo, setDateTo] = useState(moment().endOf("day").toDate());
	const [licensePlate, setLicensePlate] = useState("");
	const [parkNumber, setParkNumber] = useState("");
	const [companyFilter, setCompanyFilter] = useState<
		CompaniesAndTaxiServicesFilter.Props["companies"]
	>(["all"]);
	const [taxiServiceSectorFilter, setTaxiServiceSectorFilter] = useState<
		CompaniesAndTaxiServicesFilter.Props["taxiServices"]
	>(["all"]);

	useEffect(() => {
		if (!debouncedExecutorCallSign) {
			archiveGPSLog.actions.setCallsignExists(undefined);
			return;
		}

		getExecutorByCallSign({
			callsign: debouncedExecutorCallSign,
			callback(data) {
				archiveGPSLog.actions.setCallsignExists(
					executorCallSign ? !!data : undefined,
				);
			},
		});
	}, [debouncedExecutorCallSign, executorCallSign]);

	const sectorIds = useMemo(
		() => getNumberIds(taxiServiceSectorFilter),
		[taxiServiceSectorFilter],
	);

	const taxiServiceIds = useMemo(
		() => getNumberIds(companyFilter),
		[companyFilter],
	);

	const dateRange = useMemo(
		() => ({ from: dateFrom, to: dateTo }),
		[dateFrom, dateTo],
	);

	const params = useMemo<
		GetExecutorLocationsHistoryFilters | GPSHistoryFilters
	>(
		() => ({
			dateRange,
			taxiServiceIds,
			executorCallSign,
			carNumber: licensePlate,
			sectorIds,
			carRegistrationNumber: parkNumber,
		}),
		[
			dateRange,
			executorCallSign,
			licensePlate,
			taxiServiceIds,
			sectorIds,
			parkNumber,
		],
	);

	const search = useCallback(() => {
		if (addFilters) addFilters(params);
	}, [params, addFilters]);

	return (
		<Header>
			<Row maxedWidth gaps="5px*" align="center">
				{t(`from`, { context: "capitalized" })}
				<DateAndTimePicker
					value={dateFrom}
					onChange={(newValue) => {
						if (newValue) setDateFrom(newValue);
					}}
					locale={locale}
				/>

				{t(`to`)}
				<DateAndTimePicker
					value={dateTo}
					onChange={(newValue) => {
						if (newValue) setDateTo(newValue);
					}}
					locale={locale}
				/>

				<Row
					style={{ flexGrow: 1, maxWidth: "800px" }}
					gaps="5px*"
					align="center"
					sizes="1fr!*"
				>
					<CompaniesAndTaxiServicesFilter
						companies={companyFilter}
						taxiServices={taxiServiceSectorFilter}
						language={mapLang}
						onChangeCompanies={setCompanyFilter}
						onChangeTaxiServices={setTaxiServiceSectorFilter}
					/>

					<TextBox.TextBox
						placeholder={
							t("mainPage.archives.gpsLog.driverCallsign") ??
							"Driver callsign"
						}
						value={executorCallSign}
						onChange={setDriverCallsign}
					/>

					<TextBox.TextBox
						placeholder={
							t("mainPage.archives.gpsLog.licensePlate") ??
							"License plate"
						}
						value={licensePlate}
						onChange={setLicensePlate}
					/>

					<TextBox.TextBox
						placeholder={
							t("mainPage.archives.gpsLog.parkNumber") ??
							"Park number"
						}
						value={parkNumber}
						onChange={setParkNumber}
					/>
				</Row>

				<Button.Button
					variant="primary"
					icon={<Icon id="search" size={20} />}
					onClick={search}
				/>
			</Row>
		</Header>
	);
};

export default GPSLogHeader;
