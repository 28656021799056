import { RefObject } from "react";
import { react } from "uikit";
import CheckConnectData from ".";

interface Context {
	checkConnectDataRef: RefObject<CheckConnectData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCheckConnectDataValid =
			!!this.context?.checkConnectDataRef.current?.validate();
		// const isFullNameValid = !!this.context?.fullNameRef.current?.validate();

		return isCheckConnectDataValid;
		// return isAuthorizationValid && isFullNameValid;
	}
}
