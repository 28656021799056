import React, { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import styled from "styled-components";

import { StyledP, StyledSpan, Style } from "../styles";

const StyledText = styled(StyledP)`
	flex-direction: row;
	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}
`;

const StyledValue = styled(StyledSpan)`
	padding-left: 5px;

	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}
`;

const Text: React.FC<Text.Props> = ({
	text,
	value,
	valueStyle = {},
	textStyle = {},
	...props
}): JSX.Element => (
	<StyledText {...textStyle} {...props}>
		{text}
		{value && <StyledValue {...valueStyle}>{value}</StyledValue>}
	</StyledText>
);

declare namespace Text {
	interface Props
		extends Omit<
			DetailedHTMLProps<
				HTMLAttributes<HTMLParagraphElement>,
				HTMLParagraphElement
			>,
			"ref"
		> {
		text: string | React.ReactNode;
		value?: string | number | boolean | React.ReactNode;
		valueStyle?: Style.TextType;
		textStyle?: Style.TextType;
	}
}

export const TextMemo = memo(Text);
export default Text;
