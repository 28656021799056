/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	PropsWithChildren,
	ReactNode,
	useCallback,
	useMemo,
} from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import StyleProps from "uikit/dist/types/StyleProps";
import { extend } from "lodash";

import Modal from "../Modal";
import KeyBind from "../KeyBind";

import Header from "./components/Header";
import Root from "./components/Root";
import Footer from "./components/Footer";

// eslint-disable-next-line prettier/prettier
const LightInputModal = extend(
	(({
		children,

		style,
		className,

		draggable = true,
		title,
		dimmed = true,
		useDefaultFooter = true,
		pointerEvents = "auto",
		action,
		position,
		canSubmit = true,

		onChangePosition,

		onClose,
		onSubmit,
	}) => {
		const headerOnClose = useCallback(() => {
			onClose?.();
		}, [onClose]);

		const internalOnCancel = useCallback(() => {
			onClose?.();
		}, [onClose]);

		const internalOnSubmit = useCallback(() => {
			onSubmit?.();
		}, [onSubmit]);

		const content = useMemo(
			() => (
				<Root
					style={style}
					className={className}
					sizes="auto! 1fr auto!"
					gaps={draggable ? "12px 0px* 16px" : "8px 0px* 16px"}
				>
					<Header
						draggable={draggable}
						title={title}
						onClose={headerOnClose}
						action={action}
					/>
					{children}
					{useDefaultFooter && (
						<Footer
							canSubmit={canSubmit}
							onCancel={internalOnCancel}
							onSubmit={internalOnSubmit}
						/>
					)}
				</Root>
			),
			[
				action,
				canSubmit,
				children,
				className,
				draggable,
				internalOnCancel,
				internalOnSubmit,
				headerOnClose,
				style,
				title,
				useDefaultFooter,
			],
		);

		return (
			<Modal dimmed={dimmed} pointerEvents={pointerEvents}>
				<KeyBind keys={["Escape"]} onUse={internalOnCancel} />
				<KeyBind keys={["Enter"]} onUse={internalOnSubmit} />
				{draggable ? (
					<Draggable
						handle=".draggable-area"
						position={position}
						onStop={onChangePosition}
					>
						{content}
					</Draggable>
				) : (
					content
				)}
			</Modal>
		);
	}) as React.FC<LightInputModal.Props>,
	{
		root: (Root as any).styledComponentId,
	},
);

declare namespace LightInputModal {
	interface Props extends PropsWithChildren, StyleProps {
		draggable?: boolean;
		title?: ReactNode;
		dimmed?: boolean;
		useDefaultFooter?: boolean;
		pointerEvents?:
			| "none"
			| "auto"
			| "visiblePainted"
			| "visibleFill"
			| "visibleStroke"
			| "painted"
			| "fill"
			| "stroke";
		action?: ReactNode;
		position?: { x: number; y: number };
		canSubmit?: boolean;

		onChangePosition?: DraggableEventHandler;

		onClose?: () => void;
		onSubmit?: () => void;
	}
}

export default LightInputModal;
