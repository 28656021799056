import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, CheckBox } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import ImprovedStepper from "../../../../../../../../../../../../../../../../../../../../../../../../components/ImprovedStepper";

const FromTo: React.FC<FromTo.Props> = memo(
	({ disabled, label, value, onChange }) => {
		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const stepperValue = valueEditor.useGetter("value");
		const setStepperValue = valueEditor.useSetter("value");

		const { t } = useTranslation();
		return (
			<Row gaps="10px*" align="center">
				<CheckBox
					disabled={disabled}
					label={label}
					value={active}
					onChange={setActive}
				/>
				<ImprovedStepper
					disabled={disabled || !active}
					value={stepperValue}
					onChange={setStepperValue}
				/>
				<span>
					{t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.range.str100",
					) ?? ""}
				</span>
			</Row>
		);
	},
);

declare namespace FromTo {
	interface Props {
		disabled?: boolean;
		label: string;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
	}
}

export default FromTo;
