import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useRef,
} from "react";
import Color from "color";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, react, TextBox, useRefWithSetter } from "uikit";

import { Language } from "../../../../../../../../../../../../services";
import CarClass from "../../../../../../../../../../../../services/CarClass";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { createObjectLanguageNamesFromObject } from "../../../../../../../../../../../../assets/languages/langs";
import {
	StyledRow,
	StyledGrid,
	StyledColumn,
	CompaniesAndTaxiServices,
	CompaniesAndTaxiServicesBase,
	StyledP,
	StyledGridItem,
	Names,
	NamesBase,
	TextWithStepper,
} from "../../../../../../../../../../../../components/common";
import ColorPicker from "../../../../../../../../../../../../components/ColorPicker";

import InternalController from "./Controller";

const MainDataBase = react.withController<
	MainData.PropsBase,
	MainData.Controller
>(({ controller, value, onChange }) => {
	const { t } = useTranslation();
	const [companyBranchRef, setCompanyBranchRef] =
		useRefWithSetter<CompaniesAndTaxiServicesBase.Ref | null>(null);
	const nameFormRef = useRef<NamesBase.Controller | null>(null);

	const valueEditor = useObjectEditor(value, onChange);

	const root = valueEditor.useGetter("root");
	const taxiServicesData = valueEditor.usePicker(
		["taxiServiceIds", "taxiServices", "companyIds"],
		"json",
	);
	const name = valueEditor.useGetter("name");
	const setName = valueEditor.useSetter("name");

	const handleName = useCallback(
		(value: Partial<Record<Language, string>>) => {
			setName(createObjectLanguageNamesFromObject(value));
		},
		[setName],
	);

	const shortNameValue = valueEditor.useGetter("shortName");
	const shortNameOnChange = valueEditor.useSetter("shortName");

	const useBackgroundColorValue = valueEditor.useGetter("useBackgroundColor");
	const useBackgroundColorOnChange =
		valueEditor.useSetter("useBackgroundColor");

	const backgroundColor = valueEditor.useGetter("backgroundColor");
	const setBackgroundColor = valueEditor.useSetter("backgroundColor");

	const backgroundColorValue = useMemo(
		() => backgroundColor.hex(),
		[backgroundColor],
	);

	const backgroundColorOnChange = useCallback(
		(newColor: string) => setBackgroundColor(new Color(newColor)),
		[setBackgroundColor],
	);

	const useTextColorValue = valueEditor.useGetter("useTextColor");
	const useTextColorOnChange = valueEditor.useSetter("useTextColor");

	const textColor = valueEditor.useGetter("textColor");
	const setTextColor = valueEditor.useSetter("textColor");

	const priority = valueEditor.useGetter("priority");
	const setPriority = valueEditor.useSetter("priority");

	const textColorValue = useMemo(() => textColor.hex(), [textColor]);

	const textColorOnChange = useCallback(
		(newColor: string) => setTextColor(new Color(newColor)),
		[setTextColor],
	);
	const assigner = valueEditor.useAssigner();

	controller.setContext({ companyBranchRef, nameFormRef });
	return (
		<StyledColumn gap="15px 8px">
			<StyledRow gap="8px" w="100%">
				<CompaniesAndTaxiServices
					ref={setCompanyBranchRef}
					value={taxiServicesData}
					onChange={assigner}
					disabled={root}
				/>
			</StyledRow>

			<Names
				ref={nameFormRef}
				require
				value={name}
				onChange={handleName}
				columns="repeat(3,1fr)"
				placeholder="pages.preferencesPages.screenDirectory.carClass.modal.main.mainData.str200"
			/>

			<StyledGrid
				areas="'sortName sortName sortName colors colors priority'"
				columns="repeat(6,1fr)"
				w="100%"
				gap="10px"
			>
				<StyledGridItem area="sortName" gap="0 10px" columns="auto 1fr">
					<StyledP font={{ fs: "normal", line: "18px" }}>
						{t(
							"pages.preferencesPages.screenDirectory.carClass.modal.main.mainData.str152",
						) ?? ""}
					</StyledP>
					<TextBox.TextBox
						value={shortNameValue}
						onChange={shortNameOnChange}
					/>
				</StyledGridItem>
				<StyledGridItem
					area="colors"
					gap="0 10px"
					columns="repeat(2,1fr)"
				>
					<StyledRow alignItems="center" gap="8px">
						<CheckBoxWithContent
							value={useBackgroundColorValue}
							gap="10px"
							onChange={useBackgroundColorOnChange}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.mainData.str153",
							) ?? ""}
						</CheckBoxWithContent>
						<ColorPicker
							color={backgroundColorValue}
							defaultColor="#fff"
							label=""
							onChange={backgroundColorOnChange}
						/>
					</StyledRow>
					<StyledRow alignItems="center" gap="8px">
						<CheckBoxWithContent
							value={useTextColorValue}
							gap="10px"
							onChange={useTextColorOnChange}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.mainData.str154",
							) ?? ""}
						</CheckBoxWithContent>
						<ColorPicker
							color={textColorValue}
							defaultColor="#fff"
							label=""
							onChange={textColorOnChange}
						/>
					</StyledRow>
				</StyledGridItem>
				<StyledGridItem area="priority">
					<TextWithStepper
						stepperSetting={{ min: 1 }}
						value={priority}
						onChange={setPriority}
						title={
							"pages.preferencesPages.screenDirectory.carClass.modal.main.mainData.priority"
						}
					/>
				</StyledGridItem>
			</StyledGrid>
		</StyledColumn>
	);
}, InternalController);

const MainData = memo(MainDataBase);

declare namespace MainData {
	type Ref = InternalController;
	type Controller = InternalController;

	interface Value {
		taxiServices: any[];
		taxiServiceIds: number[];
		companyIds: number[];
		name: CarClass.Model["name"];
		shortName: CarClass.Model["shortName"];
		useBackgroundColor: CarClass.Model["useBackgroundColor"];
		backgroundColor: CarClass.Model["backgroundColor"];
		textColor: CarClass.Model["textColor"];
		useTextColor: CarClass.Model["useTextColor"];
		root: boolean;
		priority: number;
	}

	interface PropsBase {
		value: Value;

		onChange: Dispatch<Value>;

		disabled: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainData;
