import React from "react";
import { Icon, Row, theme } from "uikit";
import { noop } from "lodash";
import Text from "./components/Text";
import Subtext from "./components/Subtext";
import Button from "./components/Button";
import InformationContainer from "./components/InformationContainer";

const Footer: React.FC<Footer.Props> = ({ disabled, url, name, size, onDelete }) => (
	<Row align="center" gaps="14px 10px" sizes="1fr auto!*2">
		<InformationContainer gaps="4px">
			<Text>{name}</Text>
			<Subtext>{size}</Subtext>
		</InformationContainer>
		<Button href={url} download={name}>
			<Icon id="save" size={20} colors={[theme.colors.secondary]}></Icon>
		</Button>
		<Button disabled={disabled} onClick={disabled ? noop : onDelete}>
			<Icon id="trash" size={20} colors={[theme.colors.secondary]}></Icon>
		</Button>
	</Row>
);

declare namespace Footer {
	interface Props {
		disabled: boolean;
		url: string;
		name: string;
		size: string;

		onDelete: () => void;
	}
}

export default Footer;
