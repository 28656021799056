/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { DataKind, EntryList } from "../../../types/settingEntries";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface BaseMessageEntries<Data extends DataKind> {}

export type MessageEntries<Data extends DataKind> = BaseMessageEntries<Data> &
	EntryList<Data, never, never>;
export type MessageMeta = MessageEntries<"meta">;
export type MessageState = MessageEntries<"value">;
export type MessageValue = MessageEntries<any>[keyof MessageEntries<any>];

export const messageMeta: MessageMeta = {};

const initialState: MessageState = {};

type Reducer<P> = CaseReducer<MessageState, PayloadAction<P>>;

const setAll: Reducer<MessageState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const messages = createSlice({
	name: "messages",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default messages;
