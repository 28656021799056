/* eslint-disable no-shadow */
import React, { useCallback, useMemo, useState } from "react";

import Language from "../../../../../../../../services/Language";

import LocalList from "./components/List";
import LocalEditor from "./components/Editor";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({
	value,

	language,

	canCancel,
	canSave,

	onChange,

	onSave,
	onCancel,
}) => {
	const [editing, setEditing] = useState(false);

	const listValue = useMemo<LocalList.Value>(() => value.list, [value]);

	const editorValue = useMemo<LocalEditor.Value>(() => value.editor, [value]);

	const listOnChange = useCallback(
		(list: LocalList.Value) =>
			onChange({ ...value, list }, { type: "list" }),
		[onChange, value],
	);

	const editorOnChange = useCallback(
		(editor: LocalEditor.Value) =>
			onChange({ ...value, editor }, { type: "editor" }),
		[onChange, value],
	);

	const stopEditingDecorator = useCallback(
		(callback: () => void) => () => {
			setEditing(false);
			callback();
		},
		[],
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const editorOnSave = useCallback(stopEditingDecorator(onSave), [onSave]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const editorOnCancel = useCallback(stopEditingDecorator(onCancel), [
		onCancel,
	]);

	return (
		<Root maxedWidth maxedHeight sizes="300px! 1fr">
			<LocalList
				value={listValue}
				editing={editing}
				language={language}
				onChange={listOnChange}
				onChangeEditing={setEditing}
			/>
			<LocalEditor
				value={editorValue}
				editing={editing}
				language={language}
				canCancel={canCancel}
				canSave={canSave}
				onChange={editorOnChange}
				onChangeEditing={setEditing}
				onSave={editorOnSave}
				onCancel={editorOnCancel}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		list: LocalList.Value;
		editor: LocalEditor.Value;
	}

	interface Details {
		type: "list" | "editor";
	}

	interface Props {
		value: Value;

		language: Language;

		canCancel: boolean;
		canSave: boolean;

		onChange: (value: Value, details: Details) => void;

		onSave: () => void;
		onCancel: () => void;
	}

	namespace List {
		type Group = LocalList.Group;
		type Value = LocalList.Value;

		namespace Group {
			type Item = LocalList.Group.Item;
		}
	}

	namespace Editor {
		type Item = LocalEditor.Item;
		type Value = LocalEditor.Value;
	}
}

export default Content;
