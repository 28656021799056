import React, { useCallback, useMemo, useState } from "react";
import { isEqual, isSymbol } from "lodash";
import { useTranslation } from "react-i18next";

import Service from "../../../../../../services/ExecutorRate";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import useTaxiServiceIdsDecoder from "../../../../../../hooks/useTaxiServiceIdsDecoder";
import mapByKey from "../../../../../../utils/mapByKey";
import useGetCompanyIdsByTaxiServiceIds from "../../../../../../hooks/useGetCompanyIdsByTaxiServiceIds";
import DeleteModal from "../../../../../../components/DeleteModal";
import {
	StyledColumn,
	StyledP,
	StyledRow,
	TaxiServicesByIds,
} from "../../../../../../components/common";

import { ErrorResponse, getErrorByMessage } from "./constants/errors";
import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const ExecutorTariffPlans: React.FC = () => {
	const { t } = useTranslation();
	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);

	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();
	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();
	const [sort, setSort] = useState<Content.Sort>({});
	const [isSave, setIsSave] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [requestError, setRequestError] = useState<ErrorResponse | null>(
		null,
	);
	const [preparedTariff, setPreparedTariff] =
		useState<EditModal.Value.Validated | null>(null);
	// const modelSubscriptionOptions = useMemo<Service.SubscribeOptions>(() => {
	// 	const result: Service.SubscribeOptions = {};

	// 	if (sort.column && sort.type)
	// 		result.order = {
	// 			[sort.column as keyof Service.SubscribeOptions["order"]]:
	// 				sort.type,
	// 		};

	// 	return result;
	// }, [sort.column, sort.type]);

	const modelData = useModelSubscribe({}, Service);

	const modelItems = useMemo(() => modelData.models, [modelData.models]);
	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	useMemo(() => {
		if (editingItem?.id) {
			const tariff = modelItemById?.[editingItem?.id];
			if (tariff) {
				setEditingItem({
					...editingItem,
					executorIds: tariff.executorIds,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelItemById]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		async (id: number) => {
			const item = await Service.getById(id);

			const taxiServiceIds =
				item.taxiServices?.map((taxiService) => taxiService.id) ?? [];

			setEditingItem({
				id: item.id,
				active: item.active,
				default: item.default,
				root: item.root,
				name: item.name,

				companyIds: getCompanyIdsByTaxiServiceIds(taxiServiceIds),
				taxiServiceIds,
				taxiServices: item.taxiServices,
				executorIds: item.executorIds,

				subscriptions: item.subscriptions ?? [],
				commissionPlans: item.commissionPlans ?? [],
				discountPlans: item.discountPlans ?? [],

				ignoreOldOrders: item.ignoreOldOrders,

				amountOfMoneyToBlock: {
					active: item.amountOfMoneyToBlock.active,
					value: item.amountOfMoneyToBlock.value,
					auto: item.amountOfMoneyToBlock.auto,
				},

				amountOfMoneyToUnblock: {
					active: item.amountOfMoneyToUnblock.active,
					value: item.amountOfMoneyToUnblock.value,
					auto: item.amountOfMoneyToUnblock.auto,
				},

				orderTypeCondition: {
					active: item.orderTypeCondition.active,
					operator: item.orderTypeCondition.operator,
					value: item.orderTypeCondition.value,
					types: item.orderTypeCondition.types,
				},

				disallowTakeOrders: {
					active: item.disallowTakeOrders.active,
					value: item.disallowTakeOrders.value,
				},

				createdAt: item.createdAt ?? undefined,
			});
		},
		[getCompanyIdsByTaxiServiceIds],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() =>
			!selected.some((id) => modelItemById[id].root) && !!selected.length,
		[modelItemById, selected],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			name: "",
			default: false,
			active: true,
			root: false,
			companyIds: [],
			taxiServiceIds: [],
			taxiServices: [],

			executorIds: [],

			subscriptions: [],
			commissionPlans: [],
			discountPlans: [],

			ignoreOldOrders: false,

			amountOfMoneyToBlock: {
				active: false,
				value: 0,
				auto: false,
			},
			amountOfMoneyToUnblock: {
				active: false,
				value: 0,
				auto: false,
			},

			orderTypeCondition: {
				active: false,
				value: 0,
			},
			disallowTakeOrders: {
				active: false,
				value: 0,
			},
		});
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated, force = false) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);
				return;
			}

			if (isSave) return;

			const newProperties: Service.Model.New = {
				taxiServiceIds: decodeTaxiServiceIds(
					newItem.companyIds,
					newItem.taxiServiceIds,
				),
				executorIds: newItem.executorIds,

				subscriptions: newItem.subscriptions.map((subscription) => ({
					...subscription,

					id: isSymbol(subscription.id) ? undefined : subscription.id,
				})),

				commissionPlans: newItem.commissionPlans.map(
					(commissionPlan) => ({
						...commissionPlan,

						id: isSymbol(commissionPlan.id)
							? undefined
							: commissionPlan.id,

						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						classifierId: commissionPlan.classifier!.id,
					}),
				),

				discountPlans: newItem.discountPlans.map((discountPlan) => ({
					...discountPlan,

					id: isSymbol(discountPlan.id) ? undefined : discountPlan.id,

					// classifierId: discountPlan.classifier?.id as number,

					commissionPlanId: discountPlan.commissionPlan?.id as number,
				})),

				name: newItem.name,

				default: newItem.default,

				ignoreOldOrders: newItem.ignoreOldOrders,

				amountOfMoneyToBlock: {
					active: newItem.amountOfMoneyToBlock.active,
					value: newItem.amountOfMoneyToBlock.value,
					auto: newItem.amountOfMoneyToBlock.auto,
				},

				amountOfMoneyToUnblock: {
					active: newItem.amountOfMoneyToUnblock.active,
					value: newItem.amountOfMoneyToUnblock.value,
					auto: newItem.amountOfMoneyToUnblock.auto,
				},

				orderTypeCondition: {
					active: newItem.orderTypeCondition.active,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					operator: newItem.orderTypeCondition.operator!,
					value: newItem.orderTypeCondition.value,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					types: newItem.orderTypeCondition.types!,
				},

				disallowTakeOrders: {
					active: newItem.disallowTakeOrders.active,
					value: newItem.disallowTakeOrders.value,
				},

				active: newItem.active,
			};

			setIsSave(true);
			let isAllOk = true;
			if (typeof newItem.id === "number" && newItem.id) {
				const res = await Service.update(
					{
						id: newItem.id,
						...newProperties,
					},
					force,
				);

				isAllOk = !(res?.error || res === null);
				if (res.error) {
					const { message, details } = res.data;
					setRequestError(getErrorByMessage(message, true, details));
				}
			} else {
				const res = await Service.store(newProperties, force);
				isAllOk = !(res?.error || res === null);
				if (res.error) {
					const { message, details } = res.data;
					setRequestError(getErrorByMessage(message, true, details));
				}
			}
			setIsSave(false);
			if (isAllOk) {
				setEditingItem(null);
				setRequestError(null);
			} else {
				setPreparedTariff(newItem);
				setShowErrorModal(true);
			}
		},
		[decodeTaxiServiceIds, editingItem, isSave],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await Service.destroy(selected);

		setSelected([]);
	}, [selected]);

	// --- error ---
	const errorConflictsFormat = useCallback(
		({ translation, baseText, details }: ErrorResponse) => {
			const data = details?.map?.((item) => item?.metadata);
			const tPath = "executorRates.errors.rewrite";
			const title = t([
				translation,
				baseText,
				"There is a conflict in tariff plans!",
			]);

			const taxiService = t([`${tPath}.taxi_service`, "From the branch"]);

			const rewriteTaxiService = t([
				`${tPath}.rewrite_taxi_service`,
				"Do you want to overwrite the branch(s)?",
			]);
			const removeTaxiService = t([
				`${tPath}.remove_taxi_service`,
				`will be deleted`,
			]);

			const removeDefault = t([
				`${tPath}.remove_default`,
				`the "Default" status will be removed".`,
			]);
			const hasDefault = t([
				`${tPath}.has_default`,
				`The tariff has such branches`,
			]);

			return (
				<StyledColumn gap="10px" h={{ max: "400px" }} scrollbar>
					<StyledP
						m="0 0 15px 0"
						justify="center"
						alignItems="center"
						textAlign="center"
						font={{
							size: "16px",
							line: "21px",
							fw: 700,
							fs: "normal",
						}}
						text={{ ws: "normal" }}
					>
						{title}
					</StyledP>
					{data.length ? (
						data.map((item, key) => (
							<StyledRow
								gap="10px"
								key={key}
								alignItems="end"
								flex={{ wrap: "wrap" }}
							>
								{item.actionType === "remove_taxi_service" && (
									<StyledColumn>
										<StyledRow
											gap="5px"
											textAlign="end"
											alignItems="end"
											h="30px"
										>
											<StyledP
												font={{
													line: "18px",
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{taxiService}
											</StyledP>
											<StyledP
												font={{
													size: "16px",
													line: "21px",
													fw: 700,
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{item.name?.[settingsLanguage]}
											</StyledP>

											<StyledP
												font={{
													line: "18px",
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{removeTaxiService}
											</StyledP>
										</StyledRow>
										<TaxiServicesByIds
											wrap
											font={{
												size: "16px",
												line: "21px",
												fw: 700,
												fs: "normal",
											}}
											text={{ ws: "normal" }}
											taxiServiceIds={
												item?.conflictTaxiServiceIds ||
												[]
											}
											lang={settingsLanguage}
										/>
									</StyledColumn>
								)}

								{item.actionType === "remove_default" && (
									<StyledColumn>
										<StyledRow
											gap="5px"
											textAlign="end"
											alignItems="end"
											h="30px"
										>
											<StyledP
												font={{
													line: "18px",
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{taxiService}
											</StyledP>
											<StyledP
												font={{
													size: "16px",
													line: "21px",
													fw: 700,
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{item.name?.[settingsLanguage]}
											</StyledP>
											<StyledP
												font={{
													line: "18px",
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{removeDefault}
											</StyledP>
										</StyledRow>
										<StyledRow
											flex={{ wrap: "wrap" }}
											gap="10px"
										>
											<StyledP
												font={{
													line: "18px",
													fs: "normal",
												}}
												text={{ ws: "normal" }}
											>
												{hasDefault}
											</StyledP>
											<TaxiServicesByIds
												wrap
												font={{
													size: "16px",
													line: "21px",
													fw: 700,
													fs: "normal",
												}}
												text={{ ws: "normal" }}
												taxiServiceIds={
													item?.conflictTaxiServiceIds ||
													[]
												}
												lang={settingsLanguage}
											/>
										</StyledRow>
									</StyledColumn>
								)}
							</StyledRow>
						))
					) : (
						<></>
					)}
					<StyledP
						m="auto 0 0 0"
						justify="center"
						alignItems="center"
						textAlign="center"
						font={{
							size: "16px",
							line: "21px",
							fw: 600,
							fs: "normal",
						}}
						text={{ ws: "normal" }}
					>
						{rewriteTaxiService}
					</StyledP>
				</StyledColumn>
			);
		},
		[t, settingsLanguage],
	);

	const errorModalLabel = useMemo(() => {
		if (!requestError) return "";

		if (requestError.additional && requestError.isConflicts) {
			return errorConflictsFormat(requestError);
		}

		return t([requestError.translation, requestError.baseText]);
	}, [requestError, t, errorConflictsFormat]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
					loading={isSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
			{showErrorModal && (
				<DeleteModal
					label={errorModalLabel}
					onCancel={() => {
						setShowErrorModal(false);
						setPreparedTariff(null);
						setRequestError(null);
					}}
					w={requestError?.isConflicts ? "500px" : ""}
					isConfirm
					confirmButtonLabel={
						requestError?.canRetry
							? t(`executorRates.errors.rewrite.str1`) ?? ""
							: t(`executorRates.errors.rewrite.str2`) ?? ""
					}
					onConfirm={() => {
						if (requestError?.canRetry && preparedTariff) {
							editModalOnSave(preparedTariff, true);
						}
						setShowErrorModal(false);
						setRequestError(null);
						setIsSave(false);
					}}
				/>
			)}
		</Root>
	);
};

export default ExecutorTariffPlans;
