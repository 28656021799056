import React, { memo, useCallback, useMemo, Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeInput } from "uikit";

import useObjectEditor from "../../../hooks/useObjectEditor";
import Style, {
	StyledGrid,
	StyledGridItem,
	StyledRow,
	StyledSpan,
} from "../styles";

const FromToTimeBase: React.FC<FromToTimeBase.Props> = ({
	value,
	onChange,
	errors,
	onErrors,
	disabled,
	...props
}) => {
	const { t } = useTranslation();
	const [errorsBase, setErrorsBase] = useState<FromToTimeBase.Errors>({
		to: false,
		from: false,
	});

	const valueEditor = useObjectEditor(value, onChange);
	const errorsEditor = useObjectEditor(
		errors || errorsBase,
		onErrors || setErrorsBase,
	);

	const errorTo = errorsEditor.useGetter("to");
	const setErrorTo = errorsEditor.useSetter("to");

	const errorFrom = errorsEditor.useGetter("from");
	const setErrorFrom = errorsEditor.useSetter("from");

	const from = valueEditor.useGetter("from");
	const setFrom = valueEditor.useSetter("from");
	const handleFromChange = useCallback(
		(date) => {
			setErrorFrom(false);
			setErrorTo(false);
			setFrom(date);
		},
		[setErrorFrom, setErrorTo, setFrom],
	);

	const to = valueEditor.useGetter("to");
	const setTo = valueEditor.useSetter("to");
	const handleToChange = useCallback(
		(date) => {
			setErrorFrom(false);
			setErrorTo(false);
			setTo(date);
		},
		[setErrorFrom, setErrorTo, setTo],
	);

	const errorsStyle = useMemo(
		() => ({
			from: errorFrom
				? { border: "1px solid red", borderRadius: "6px" }
				: undefined,
			to: errorTo
				? { border: "1px solid red", borderRadius: "6px" }
				: undefined,
		}),
		[errorFrom, errorTo],
	);

	return (
		<StyledGrid
			areas="'from to'"
			justify="start"
			alignItems="center"
			columns="repeat(2,1fr)"
			gap="10px"
			{...props}
		>
			<StyledGridItem area="from" alignSelf="center">
				<StyledRow gap="10px">
					<StyledSpan w="20px">{t(["from", "from"])}</StyledSpan>
					<TimeInput
						disabled={disabled}
						style={errorsStyle.from}
						value={from}
						onChange={handleFromChange}
					/>
				</StyledRow>
			</StyledGridItem>
			<StyledGridItem area="to" alignSelf="center">
				<StyledRow gap="10px">
					<StyledSpan w="20px">{t(["to", "to"])}</StyledSpan>
					<TimeInput
						disabled={disabled}
						style={errorsStyle.to}
						value={to}
						onChange={handleToChange}
					/>
				</StyledRow>
			</StyledGridItem>
		</StyledGrid>
	);
};

export const FromToTime = memo(FromToTimeBase);

declare namespace FromToTimeBase {
	interface Errors {
		from: boolean;
		to: boolean;
	}

	interface Value {
		from: Date;
		to: Date;
	}

	interface Props extends Partial<Style.Grid> {
		value: Value;
		onChange: (value: Value) => void;
		errors?: Errors;
		onErrors?: Dispatch<Errors>;
		disabled?: boolean;
	}
}

export default FromToTimeBase;
