import React, { Key, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Column, Icon, react } from "uikit";
import theme from "uikit/src/styles/theme";
import Root from "./components/Root";
import DraggableArea from "./components/DraggableArea";
import Name from "./components/Name";
import Subname from "./components/Subname";
import { SVGgeneral } from "../../../../../../../../../../../../../../../../utils/generalSprite";
import { calculateNameColor } from "./utils/coloring";

const Item: React.FC<Item.Props> = ({
	id,
	index,

	name,
	subname,
	active,
	selected,
	modified,
	valid,
	editing,
	bySector,

	onSelect,
	onUnselect,
	onEdit,
}) => {
	const rootRef = useRef<HTMLDivElement | null>(null);

	return (
		<Draggable key={id} draggableId={id.toString()} index={index}>
			{(provided) => (
				<Root
					ref={(element) => {
						react.setRefValue<HTMLElement | null>(
							provided.innerRef,
							element,
						);

						rootRef.current = element;
					}}
					{...provided.draggableProps}
					sizes="auto! 1fr 55px"
					align="center"
					gaps="6px*"
					selected={selected}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						if (selected && event.ctrlKey) {
							onUnselect();

							return;
						}

						onSelect(event.ctrlKey);
					}}
					onDoubleClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						if (!editing) onEdit();
					}}
					onMouseDown={(event) => {
						if (event.detail > 1) event.preventDefault();
					}}
				>
					<DraggableArea {...provided.dragHandleProps}>
						<SVGgeneral
							id="drag_icon"
							fill={theme.colors.disabled_text}
						/>
					</DraggableArea>
					<Column gaps="3px">
						<Name
							active={active}
							selected={selected}
							modified={modified}
							valid={valid}
						>
							{name}
						</Name>
						<Subname
							active={active}
							selected={selected}
							modified={modified}
							valid={valid}
						>
							{subname}
						</Subname>
					</Column>
					{(bySector || null) && (
						<Column align="center" gaps="1px">
							<Icon
								id="map"
								size={20}
								colors={[
									calculateNameColor({
										active,
										selected,
										modified,
										valid,
									}),
								]}
							/>
						</Column>
					)}
				</Root>
			)}
		</Draggable>
	);
};

declare namespace Item {
	interface Props {
		id: Key;
		index: number;

		name: string;
		subname: string;
		active: boolean;
		selected: boolean;
		modified: boolean;
		valid: boolean;
		editing: boolean;
		bySector: boolean;

		onSelect: (ctrl: boolean) => void;
		onUnselect: () => void;
		onEdit: () => void;
	}
}

export default Item;
