import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tabs, theme } from "uikit";

import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../../../components/common";
import { ModalTabs, getTabs } from "../../../../constants/modalTabs";

const Title = styled.div`
	height: 28px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;

	color: ${theme.colors.primary};
`;

const Header: React.FC<Header.Props> = memo(({ tab, title, onChangeTab }) => {
	const { t } = useTranslation();

	const tabs = useMemo(() => getTabs(t), [t]);
	return (
		<StyledColumn w="100%" bgC="#EBEBEB">
			<StyledRow
				alignItems="center"
				justify="space-between"
				p="16px 20px"
				gap="10px"
			>
				<Title>{title}</Title>
			</StyledRow>
			<Tabs
				value={tab}
				options={tabs}
				variant="panels"
				onChange={(newTab) =>
					onChangeTab(newTab.toString() as ModalTabs)
				}
			/>
		</StyledColumn>
	);
});

declare namespace Header {
	interface Props {
		tab: ModalTabs;

		title: string;

		onChangeTab: Dispatch<ModalTabs>;
	}
}

export default Header;
