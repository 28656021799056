import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";

import SearchBar from "../../../../../SearchBar";

import Root from "./components/Root";
import SelectAll from "./components/SelectAll";

const Header = memo<Header.Props>(
	({
		search,
		onChangeSearch,

		selectAll,
		onChangeSelectAll,
	}) => {
		const { t } = useTranslation();

		return (
			<Root gaps="16px">
				<SearchBar
					value={search}
					placeholder={`${t("search")}...`}
					onChange={onChangeSearch}
				/>
				<SelectAll
					value={selectAll}
					gap="12px"
					onChange={onChangeSelectAll}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	interface Props {
		search: string;
		onChangeSearch: Dispatch<string>;

		selectAll: boolean;
		onChangeSelectAll: Dispatch<boolean>;
	}
}

export default Header;
