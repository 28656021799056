import React, { Dispatch, memo } from "react";
import { clone } from "lodash";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Item from "./components/Item";
import ExecutorRateCommissionPlan from "../../../../../../../../../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Root from "./components/Root";

const disabledItems = [
	"terminalToTaxiService",
	"terminalToExecutor",
	"localTransfer",
	"globalTransfer",
	"externalTransfer",
];

const ListBase: React.FC<List.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	return (
		<DragDropContext
			onDragEnd={(result) => {
				const sourceIndex = result.source.index;
				const destinationIndex = result.destination?.index;

				if (
					typeof destinationIndex === "undefined" ||
					sourceIndex === destinationIndex
				)
					return;

				const newValue = clone(value);

				const sourceItem = newValue[sourceIndex];

				newValue.splice(sourceIndex, 1);
				newValue.splice(destinationIndex, 0, sourceItem);

				onChange(newValue);
			}}
		>
			<Droppable droppableId="droppable">
				{(provided) => (
					<Root ref={provided.innerRef} {...provided.droppableProps}>
						{value.map(({ id, ...item }, index) => (
							<Item
								key={id}
								value={item}
								disabled={disabledItems.includes(id)}
								id={id}
								index={index}
								label={t(
									`executorRates.commissionPlans.commissions.types.${id}`,
								)}
								onChange={(newItem) =>
									valueEditor.set(index, { ...newItem, id })
								}
							/>
						))}
						{provided.placeholder}
					</Root>
				)}
			</Droppable>
		</DragDropContext>
	);
};

const List = memo(ListBase);

declare namespace List {
	type Value = Item[];

	interface Item extends Item.Value {
		id: ExecutorRateCommissionPlan.Model.Rate.Commission.Type;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default List;
