import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import setCars from "../../actions/Cars/setCars";

export default function getCars() {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() => prpcow?.theirsModel.car.getAll()).then(
			async (result) => {
				await Promise.all([dispatch(setCars(result.items))]);
				return result.items;
			},
		);
	};
}
