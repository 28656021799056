/* eslint-disable no-shadow */

import React from "react";
import { useTranslation } from "react-i18next";

import fonts, {
	fontMeta,
	FontState,
	FontMeta,
} from "../../../../../redux/reducers/settings/fonts";
import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import useSnapshot from "../../../../../hooks/useSnapshot";
import SettingList from "../../../../../components/Settings/SettingList";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";

const AppearanceSettings: React.FC<SettingsTabProps> = ({
	methods,
	onHasChanges,
}) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const [snapshot, setSnapshot] = useSnapshot({
		state: useTypedSelector((state) => state.settings.fonts),
		methods,
		onHasChanges,
		onSave(snapshot) {
			dispatch(fonts.actions.setAll(snapshot));
		},
		onResetDefaults() {
			dispatch(fonts.actions.resetDefaults());
			return fonts.getInitialState();
		},
	});

	return (
		<SettingList<never, never, FontState, FontMeta>
			state={snapshot}
			meta={fontMeta}
			name={(key) => t(`settings.interface.fonts.${key}`)}
			onChange={(newValue, key) => {
				setSnapshot((prev) =>
					prev[key] === newValue
						? prev
						: { ...prev, [key]: newValue },
				);
			}}
		/>
	);
};

export default AppearanceSettings;
