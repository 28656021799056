import styled from "styled-components";

const Badge = styled.div`
	padding: 2px 4px;

	min-width: 24px;

	border-radius: 5px;

	background: #dee0e2;

	font-family: Lato;
	font-size: 9px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
`;

export default Badge;
