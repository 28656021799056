import { Dispatch } from "redux";

import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import { archive } from "../../../reducers";

import { ReqGetHistoryById, ResGetHistoryById } from "./interface";

/** Get the order history by id from the database. */
export default function getOrderHistoryById(orderId: number) {
	return async (dispatch: Dispatch<any>) => {
		const prpcow = getPRPC();

		if (!prpcow) return;

		try {
			dispatch(archive.orders.loadings.setIsLoadingHistory(true));
			const data: ResGetHistoryById["data"] = await createRPCQuery(
				() =>
					prpcow?.theirsModel.orders.getHistory<
						ReqGetHistoryById,
						ResGetHistoryById
					>(orderId),
				{
					verbose: true,
					name: "getOrderHistoryById",
				},
			);
			if (!data) return;
			dispatch(archive.orders.history.setOrderHistory(data || []));
			dispatch(archive.orders.loadings.setIsLoadingHistory(false));
		} catch (error) {
			if (error instanceof Error) {
				dispatch(
					archive.orders.errors.setErrorOrdersGetHistory(
						error.message,
					),
				);
			}

			dispatch(archive.orders.loadings.setIsLoadingHistory(false));
		}
	};
}
