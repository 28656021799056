/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Customers } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	error: null,
};

const setError: ReduxReducer<State, State["error"]> = (state, { payload }) => {
	state.error = payload;
};

const resetError: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const errors = createSlice({
	name: Customers.ConstantCustomersSlice.AUTODETECT_PHONE_ERRORS,
	initialState,
	reducers: {
		resetError,
		setError,
	},
});

export const errorsAction = errors.actions;
export const errorsReducer = errors.reducer;
export type errorsState = State;

export default errors;
