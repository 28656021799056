/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useInternal, useRefWithSetter } from "uikit";
import { clone, isEqual } from "lodash";
import { LatLngTuple } from "leaflet";

import Service from "../../../../../../services/Sector";
import TaxiService from "../../../../../../services/TaxiService2";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import useNavigationPrompt from "../../../../../../hooks/useNavigationPrompt";
import useLanguage from "../../../../../../hooks/useLanguage";
import mapByKey from "../../../../../../utils/mapByKey";
import DeleteModal from "../../../../../../components/DeleteModal";
import {
	createObjectLanguageNames,
	createObjectLanguageNamesFromObject,
	ValueLanguage,
} from "../../../../../../assets/languages/langs";

import useSubscribeOptions from "./hooks/useSubscribeOptions";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import EditModal from "./components/EditModal";

const Sectors: React.FC<Sectors.Props> = () => {
	const { t } = useTranslation();
	const settingsLanguage = useLanguage();

	const [editModalRef, editModalRefSetter] =
		useRefWithSetter<EditModal.Controller | null>(null);

	const [saving, setSaving] = useState(false);

	const { models, loading: deprecated } = useModelSubscribe2({}, TaxiService);

	const taxiServices = useMemo(() => models || [], [models]);

	const taxiServiceIds = useMemo(
		() => [...taxiServices].map((taxiService) => taxiService.id),
		[taxiServices],
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const [editableTaxiServices, setEditableTaxiServices] =
		useInternal(taxiServices);

	const editableTaxiServiceIds = useMemo(
		() => editableTaxiServices.map((taxiService) => taxiService.id),
		[editableTaxiServices],
	);

	const editableTaxiServiceById = useMemo(
		() => mapByKey(editableTaxiServices, "id"),
		[editableTaxiServices],
	);

	const editedTaxiServiceIds = useMemo<number[]>(
		() =>
			taxiServiceIds.filter(
				(id) =>
					editableTaxiServiceIds.includes(id) &&
					!isEqual(taxiServiceById[id], editableTaxiServiceById[id]),
			),
		[
			editableTaxiServiceById,
			editableTaxiServiceIds,
			taxiServiceById,
			taxiServiceIds,
		],
	);

	const [subscribeOptions, filters, setFilters] = useSubscribeOptions();
	const filteredModelData = useModelSubscribe(subscribeOptions, Service);

	const fullModelData = useModelSubscribe({}, Service);
	const fullModelItems = useMemo(
		() => fullModelData?.cache ?? [],
		[fullModelData?.cache],
	);

	const filteredModelItems = useMemo(
		() => filteredModelData?.cache ?? [],
		[filteredModelData?.cache],
	);

	const sectorDows = useMemo(
		() =>
			filteredModelItems.map((item) => ({
				id: item.id,
				name: item.name,
				averageSpeed: item.averageSpeed || [],
			})),
		[filteredModelItems],
	);

	const modelItemIds = useMemo(
		() => filteredModelItems.map((item) => item.id),
		[filteredModelItems],
	);

	const loading = useMemo(
		() =>
			fullModelData?.deprecated ||
			filteredModelData?.deprecated ||
			deprecated ||
			saving,
		[
			fullModelData?.deprecated,
			filteredModelData?.deprecated,
			deprecated,
			saving,
		],
	);

	const [selected, setSelected] = useState<Record<number, boolean>>({});

	const [creatingItems, setCreatingItems] = useState<Record<number, boolean>>(
		{},
	);

	type PartialProperties = "vertices";
	type A = Omit<Service.Model.Modified, PartialProperties> &
		Partial<Pick<Service.Model.Modified, PartialProperties>>;

	const baseModelItems = useMemo(
		() =>
			fullModelItems.map<Service.Model.Modified>((item) => ({
				id: item.id,

				taxiServiceId: item.taxiServiceId,

				position: item.position,
				name: item.name,
				vertices: item.vertices,
				isMaxSpeedEnabled: item.isMaxSpeedEnabled,
				maxSpeed: item.maxSpeed,

				active: item.active,
				availableForExecutor: item.availableForExecutor,
				isParking: item.isParking,
				isPriceZone: item.isPriceZone,

				isAverageSpeedEnabled: item.isAverageSpeedEnabled,
				averageSpeed: item.averageSpeed,
				isBasicAverageSpeedEnabled: item.isBasicAverageSpeedEnabled,
				basicAverageSpeed: item.basicAverageSpeed,
			})),
		[fullModelItems],
	);

	const baseModelItemById = useMemo(
		() => mapByKey(baseModelItems, "id"),
		[baseModelItems],
	);

	const baseModelItemIds = useMemo(
		() => baseModelItems.map((item) => item.id),
		[baseModelItems],
	);

	const [editableModelItems, setEditableModelItems] =
		useInternal<A[]>(baseModelItems);

	const editableModelItemById = useMemo(
		() => mapByKey(editableModelItems, "id"),
		[editableModelItems],
	);

	const editableModelItemIds = useMemo(
		() => editableModelItems.map((item) => item.id),
		[editableModelItems],
	);

	const newModelItemIds = useMemo<number[]>(
		() =>
			editableModelItemIds.filter((id) => !baseModelItemIds.includes(id)),
		[baseModelItemIds, editableModelItemIds],
	);

	const editedModelItemIds = useMemo<number[]>(
		() =>
			baseModelItemIds.filter(
				(id) =>
					editableModelItemIds.includes(id) &&
					!isEqual(baseModelItemById[id], editableModelItemById[id]),
			),
		[
			baseModelItemById,
			baseModelItemIds,
			editableModelItemById,
			editableModelItemIds,
		],
	);

	const editedPolygonModelItemIds = useMemo<number[]>(
		() =>
			baseModelItemIds.filter(
				(id) =>
					editableModelItemIds.includes(id) &&
					!isEqual(
						baseModelItemById[id].vertices,
						editableModelItemById[id].vertices,
					),
			),
		[
			baseModelItemById,
			baseModelItemIds,
			editableModelItemById,
			editableModelItemIds,
		],
	);

	const deletedModelItemIds = useMemo<number[]>(
		() =>
			baseModelItemIds.filter((id) => !editableModelItemIds.includes(id)),
		[baseModelItemIds, editableModelItemIds],
	);

	const invalidModelItemIds = useMemo(
		() =>
			editableModelItemIds.filter((id) => {
				const item = editableModelItemById[id];

				return !item.vertices || item.vertices.length < 3;
			}),
		[editableModelItemIds, editableModelItemById],
	);

	const hasChanges = useMemo(
		() =>
			editedTaxiServiceIds.length !== 0 ||
			newModelItemIds.length !== 0 ||
			editedModelItemIds.length !== 0 ||
			deletedModelItemIds.length !== 0,
		[
			deletedModelItemIds.length,
			editedModelItemIds.length,
			editedTaxiServiceIds.length,
			newModelItemIds.length,
		],
	);

	const areModelItemsValid = useMemo(
		() => invalidModelItemIds.length === 0,
		[invalidModelItemIds.length],
	);

	const currentContentValue = useMemo<Content.Value>(
		() => ({
			list: Object.entries(
				editableModelItems
					.filter(
						(item) =>
							modelItemIds.includes(item.id) ||
							newModelItemIds.includes(item.id),
					)
					.reduce((accumulator, item) => {
						accumulator[item.taxiServiceId] = accumulator[
							item.taxiServiceId
						] ?? {
							id: item.taxiServiceId,

							items: [],
							isMaxSpeedEnabled:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors?.considerSpeedLimit ??
								false,
							maxSpeed:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors?.speedLimitOutside ?? 0,

							averageSpeed:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors?.averageSpeed || [],
							isAverageSpeedEnabled:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors
									?.isAverageSpeedEnabled || false,

							basicAverageSpeed:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors?.basicAverageSpeed ||
								[],

							isBasicAverageSpeedEnabled:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors?.additionalFields
									?.isBasicAverageSpeedEnabled || false,
						};

						accumulator[item.taxiServiceId].items.push({
							id: item.id,

							taxiServiceId: item.taxiServiceId,

							position: item.position,
							name: item.name,
							isMaxSpeedEnabled: item.isMaxSpeedEnabled,
							maxSpeed: item.maxSpeed,

							selected: selected[item.id] ?? false,
							modified:
								newModelItemIds.includes(item.id) ||
								editedPolygonModelItemIds.includes(item.id),
							valid: !invalidModelItemIds.includes(item.id),
							active: item.active,
							isParking: item.isParking,
							isPriceZone: item.isPriceZone,
							averageSpeed: item?.averageSpeed || [],
							isAverageSpeedEnabled:
								item?.isAverageSpeedEnabled || false,

							isBasicAverageSpeedEnabled:
								item?.isBasicAverageSpeedEnabled || false,
							basicAverageSpeed:
								editableTaxiServiceById[item.taxiServiceId]
									?.settings?.sectors?.basicAverageSpeed ||
								[],
						});

						return accumulator;
					}, {} as Record<number, Content.List.Group>),
			).map(([, group]) => group),
			editor: editableModelItems.map((item) => ({
				id: item.id,

				taxiServiceId: item.taxiServiceId,

				name: item.name,
				vertices: item.vertices?.map(
					(vertex) => [vertex.lat, vertex.lng] as LatLngTuple,
				),
				isMaxSpeedEnabled: item.isMaxSpeedEnabled,
				maxSpeed: item.maxSpeed,

				averageSpeed: item?.averageSpeed || [],
				isAverageSpeedEnabled: item?.isAverageSpeedEnabled || false,

				isBasicAverageSpeedEnabled:
					item?.isBasicAverageSpeedEnabled || false,
				basicAverageSpeed: item?.basicAverageSpeed || [],

				selected: selected[item.id] ?? false,
				creating: creatingItems[item.id] ?? false,
				modified:
					newModelItemIds.includes(item.id) ||
					editedPolygonModelItemIds.includes(item.id),
				disabled:
					!modelItemIds.includes(item.id) &&
					!newModelItemIds.includes(item.id),
			})),
		}),
		[
			editableModelItems,
			modelItemIds,
			newModelItemIds,
			editableTaxiServiceById,
			selected,
			editedPolygonModelItemIds,
			invalidModelItemIds,
			creatingItems,
		],
	);

	const validContentValueRef = useRef(currentContentValue);

	if (!filteredModelData?.deprecated) {
		validContentValueRef.current = currentContentValue;
	}

	const contentValue = validContentValueRef.current;

	const contentCanCancel = useMemo(
		() => hasChanges && !loading,
		[hasChanges, loading],
	);

	const contentCanSave = useMemo(
		() => areModelItemsValid && hasChanges && !loading,
		[hasChanges, areModelItemsValid, loading],
	);

	const contentSelectedIds = useMemo(
		() =>
			contentValue.list
				.map((group) => group.items)
				.flat()
				.filter((item) => item.selected)
				.map((item) => item.id),
		[contentValue],
	);

	const canEdit = useMemo(
		() =>
			contentValue.list
				.map((group) => group.items)
				.flat()
				.filter((item) => item.selected).length === 1,
		[contentValue],
	);

	const canDelete = useMemo(
		() =>
			contentValue.list
				.map((group) => group.items)
				.flat()
				.some((item) => item.selected),
		[contentValue],
	);

	const edit = useCallback((id: number) => {
		setSelected({ [id]: true });
	}, []);

	const cancel = useCallback(() => {
		setSelected({});
		setEditableTaxiServices(taxiServices);
		setEditableModelItems(baseModelItems);
	}, [
		baseModelItems,
		setEditableModelItems,
		setEditableTaxiServices,
		taxiServices,
	]);

	const headerOnAdd = useCallback(() => {
		const basicAverageSpeed =
			filters.taxiServiceIds?.length === 1
				? taxiServiceById[filters.taxiServiceIds[0]]?.settings?.sectors
						?.basicAverageSpeed
				: taxiServices?.[0]?.settings?.sectors?.basicAverageSpeed;

		setEditingModelItem({
			taxiServiceId:
				filters.taxiServiceIds?.length === 1
					? filters.taxiServiceIds[0]
					: undefined,

			isMaxSpeedEnabled: false,
			maxSpeed: 0,

			active: true,
			availableForExecutor: true,
			isParking: false,
			isPriceZone: false,

			isAverageSpeedEnabled: false,
			averageSpeed: [],

			isBasicAverageSpeedEnabled: false,
			basicAverageSpeed: basicAverageSpeed || [],
		});
	}, [filters, taxiServiceById, taxiServices]);

	const headerOnEdit = useCallback(() => {
		const selectedId = Object.entries(selected).filter(
			([, selected]) => selected,
		)[0][0];

		const modelItem = editableModelItems.find(
			(item) => String(item.id) === selectedId,
		)!;

		const basicAverageSpeed = modelItem?.taxiServiceId
			? taxiServiceById[modelItem.taxiServiceId]?.settings?.sectors
					?.basicAverageSpeed
			: taxiServices?.[0]?.settings?.sectors?.basicAverageSpeed;

		setEditingModelItem({
			id: modelItem.id,

			taxiServiceId: modelItem.taxiServiceId,

			name: modelItem.name,
			isMaxSpeedEnabled: modelItem.isMaxSpeedEnabled,
			maxSpeed: modelItem.maxSpeed || 60,

			active: modelItem.active,
			availableForExecutor: modelItem.availableForExecutor,
			isParking: modelItem.isParking,
			isPriceZone: modelItem.isPriceZone,

			isAverageSpeedEnabled: modelItem.isAverageSpeedEnabled || false,
			averageSpeed: modelItem?.isBasicAverageSpeedEnabled
				? basicAverageSpeed || []
				: modelItem.averageSpeed || [],

			isBasicAverageSpeedEnabled:
				modelItem?.isBasicAverageSpeedEnabled || false,

			basicAverageSpeed: basicAverageSpeed || [],
		});
	}, [editableModelItems, selected, taxiServiceById, taxiServices]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnChange = useCallback(
		(value: Content.Value, details: Content.Details) => {
			if (details.type === "list") {
				const items = value.list.map((group) => group.items).flat();

				setSelected(
					items.reduce((accumulator, item) => {
						accumulator[item.id] = item.selected;

						return accumulator;
					}, {} as Record<number, boolean>),
				);

				setEditableModelItems(
					editableModelItems.map((item) => {
						const editedItem = items.find(
							(itemBase) => itemBase.id === item.id,
						);

						if (!editedItem) return item;

						const newItem = clone(item);

						newItem.position = editedItem.position;

						return newItem;
					}),
				);

				setEditableTaxiServices(
					editableTaxiServices.map((taxiService) => {
						const editedTaxiService = value.list.find(
							(group) => group.id === taxiService.id,
						);

						if (!editedTaxiService) return taxiService;

						const newTaxiService = clone(taxiService);

						newTaxiService.settings = clone(
							newTaxiService.settings,
						);

						newTaxiService.settings.sectors = clone(
							newTaxiService.settings.sectors,
						);

						newTaxiService.settings.sectors.considerSpeedLimit =
							editedTaxiService.isMaxSpeedEnabled;
						newTaxiService.settings.sectors.speedLimitOutside =
							editedTaxiService.maxSpeed;

						newTaxiService.settings.sectors.averageSpeed =
							editedTaxiService.averageSpeed;
						newTaxiService.settings.sectors.isAverageSpeedEnabled =
							editedTaxiService.isAverageSpeedEnabled;

						newTaxiService.settings.sectors.additionalFields.isBasicAverageSpeedEnabled =
							editedTaxiService.isBasicAverageSpeedEnabled;

						return newTaxiService;
					}),
				);
			} else if (details.type === "editor") {
				setSelected(
					value.editor.reduce((accumulator, item) => {
						accumulator[item.id] = item.selected;

						return accumulator;
					}, {} as Record<number, boolean>),
				);

				setCreatingItems(
					value.editor.reduce((accumulator, item) => {
						accumulator[item.id] = item.creating;

						return accumulator;
					}, {} as Record<number, boolean>),
				);

				setEditableModelItems(
					value.editor.map((item) => {
						const itemBase = editableModelItems.find(
							(itemBase) => itemBase.id === item.id,
						)!;

						const newItem = clone(itemBase);

						newItem.vertices = item.vertices?.map((vertex) => ({
							lat: vertex[0],
							lng: vertex[1],
						}));

						return newItem;
					}),
				);
			}
		},
		[
			editableModelItems,
			editableTaxiServices,
			setEditableModelItems,
			setEditableTaxiServices,
		],
	);

	const contentOnCancel = useCallback(() => {
		cancel();
	}, [cancel]);

	const contentOnSave = useCallback(async () => {
		setSaving(true);

		const options = { deprecate: false };

		const modelPromises = [
			...newModelItemIds.map((id) =>
				Service.store(
					editableModelItemById[id] as Service.Model.New,
					options,
				),
			),
			...editedModelItemIds.map((id) =>
				Service.update(
					editableModelItemById[id] as Service.Model.Modified,
					options,
				),
			),
		];

		if (deletedModelItemIds.length !== 0)
			modelPromises.push(Service.destroy(deletedModelItemIds, options));

		const taxiServicePromises = [
			...editedTaxiServiceIds.map((id) =>
				TaxiService.update(
					editableTaxiServiceById[id] as TaxiService.Model.Modified,
				),
			),
		];

		try {
			await Promise.all([...modelPromises, ...taxiServicePromises]);
		} finally {
			if (modelPromises.length !== 0) Service.deprecateAll();

			setSaving(false);
		}
	}, [
		deletedModelItemIds,
		editableModelItemById,
		editableTaxiServiceById,
		editedModelItemIds,
		editedTaxiServiceIds,
		newModelItemIds,
	]);

	const [editingModelItem, setEditingModelItem] =
		useState<EditModal.Value | null>(null);

	const editModalOnCancel = useCallback(() => setEditingModelItem(null), []);

	const editModalOnSave = useCallback(() => {
		if (!editModalRef.current?.validate()) return;

		const id = editingModelItem!.id ?? Date.now();

		const modelItemBaseIndex = editableModelItems.findIndex(
			(item) => item.id === id,
		);

		const modelItemBase = editableModelItems[modelItemBaseIndex];

		const basicAverageSpeed = editingModelItem?.taxiServiceId
			? taxiServiceById[editingModelItem.taxiServiceId]?.settings?.sectors
					?.basicAverageSpeed
			: taxiServices?.[0]?.settings?.sectors?.basicAverageSpeed;

		if (modelItemBaseIndex === -1) {
			const editingModelItemName = (editingModelItem!.name ||
				createObjectLanguageNames("")) as Record<ValueLanguage, string>;

			setEditableModelItems([
				{
					id,

					position: new Date(),
					taxiServiceId: editingModelItem!.taxiServiceId!,
					name: createObjectLanguageNamesFromObject(
						editingModelItemName,
					),
					// name: {
					// 	uk: editingModelItem!.name!.uk!,
					// 	az: editingModelItem!.name!.az!,
					// 	tr: editingModelItem!.name!.tr!,
					// 	en: editingModelItem!.name!.en!,
					// 	ru: editingModelItem!.name!.ru!,
					// },
					isMaxSpeedEnabled: editingModelItem!.isMaxSpeedEnabled,
					maxSpeed: editingModelItem!.maxSpeed,

					active: editingModelItem!.active,
					availableForExecutor:
						editingModelItem!.availableForExecutor,
					isParking: editingModelItem!.isParking,
					isPriceZone: editingModelItem!.isPriceZone,

					isAverageSpeedEnabled:
						editingModelItem!.isAverageSpeedEnabled || false,
					averageSpeed: editingModelItem!.averageSpeed || [],

					isBasicAverageSpeedEnabled:
						editingModelItem!.isBasicAverageSpeedEnabled || false,
					basicAverageSpeed: basicAverageSpeed || [],
				},
				...editableModelItems,
			]);
		} else {
			const newEditableModelItems = clone(editableModelItems);
			const editingModelItemName = (editingModelItem!.name ||
				createObjectLanguageNames("")) as Record<ValueLanguage, string>;
			const newModelItem = clone(modelItemBase);

			newModelItem.taxiServiceId = editingModelItem!.taxiServiceId!;

			newModelItem.name =
				createObjectLanguageNamesFromObject(editingModelItemName);

			// newModelItem.name = {
			// 	uk: editingModelItem!.name!.uk!,
			// 	az: editingModelItem!.name!.az!,
			// 	tr: editingModelItem!.name!.tr!,
			// 	en: editingModelItem!.name!.en!,
			// 	ru: editingModelItem!.name!.ru!,
			// };
			newModelItem.isMaxSpeedEnabled =
				editingModelItem!.isMaxSpeedEnabled;
			newModelItem.maxSpeed = editingModelItem!.maxSpeed;

			newModelItem.active = editingModelItem!.active;
			newModelItem.availableForExecutor =
				editingModelItem!.availableForExecutor;
			newModelItem.isParking = editingModelItem!.isParking;
			newModelItem.isPriceZone = editingModelItem!.isPriceZone;

			newModelItem.isAverageSpeedEnabled =
				editingModelItem!.isAverageSpeedEnabled;

			newModelItem.averageSpeed =
				editingModelItem?.isBasicAverageSpeedEnabled
					? basicAverageSpeed || []
					: editingModelItem?.averageSpeed || [];

			newModelItem.isBasicAverageSpeedEnabled =
				editingModelItem!.isBasicAverageSpeedEnabled;
			newModelItem.basicAverageSpeed = basicAverageSpeed || [];

			newEditableModelItems[modelItemBaseIndex] = newModelItem;

			setEditableModelItems(newEditableModelItems);
		}

		setEditingModelItem(null);

		edit(id);
	}, [
		edit,
		editModalRef,
		editableModelItems,
		editingModelItem,
		setEditableModelItems,
		taxiServiceById,
		taxiServices,
	]);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnSave = useCallback(() => {
		setEditableModelItems(
			editableModelItems.filter(
				(item) => !contentSelectedIds.includes(item.id),
			),
		);

		setShowDeleteModal(false);
	}, [editableModelItems, contentSelectedIds, setEditableModelItems]);

	const [
		showHasChangesPrompt,
		confirmHasChangesPromptNavigation,
		cancelHasChangesPromptNavigation,
	] = useNavigationPrompt(hasChanges);

	useEffect(() => {
		Service.deprecateAll();
	}, []);

	return (
		<Root sizes="auto 1fr" gaps="16px" maxedWidth maxedHeight>
			<Header
				filters={filters}
				canEdit={canEdit}
				canDelete={canDelete}
				onChangeFilters={setFilters}
				onAdd={headerOnAdd}
				onEdit={headerOnEdit}
				onDelete={headerOnDelete}
			/>
			<Content
				value={contentValue}
				language={filters.language}
				canCancel={contentCanCancel}
				canSave={contentCanSave}
				onChange={contentOnChange}
				onSave={contentOnSave}
				onCancel={contentOnCancel}
			/>
			{editingModelItem && (
				<EditModal
					ref={editModalRefSetter}
					value={editingModelItem}
					language={settingsLanguage}
					onChange={setEditingModelItem}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
					sectorDows={sectorDows}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.sectors.str200",
						) ?? ""
					}
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnSave}
				/>
			)}
			{showHasChangesPrompt && (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.sectors.str201",
						) ?? ""
					}
					onCancel={cancelHasChangesPromptNavigation}
					onConfirm={confirmHasChangesPromptNavigation}
				/>
			)}
		</Root>
	);
};

declare namespace Sectors {
	interface Props {}
}

export default Sectors;
