import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Select, react } from "uikit";
import { useTranslation } from "react-i18next";

import PaymentSystem, {
	WithdrawMethod as WithdrawMethodEnum,
} from "../../../../../../../../services/PaymentSystem";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";

import translationPath from "../../../../constants/translationPath";
import InternalController from "./Controller";

const WithdrawMethodBase = react.withController<
	WithdrawMethod.PropsBase,
	WithdrawMethod.Controller
>(({ value, onChange, controller }) => {
	const [error, setError] = useState<boolean>(false);

	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const withdrawMethod = valueEditor.useGetter("withdrawMethod");
	const withdrawMethodSet = valueEditor.useSetter("withdrawMethod");

	const selectOptionsWrap = useCallback(
		(item: { id: WithdrawMethodEnum; label: WithdrawMethodEnum }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);

	const options = useMemo(
		() =>
			Object.values(WithdrawMethodEnum).map((label) =>
				selectOptionsWrap({
					id: label,
					label: t([
						`${translationPath}.modal.withdraw.select.${label}`,
						label,
					]),
				}),
			),
		[selectOptionsWrap, t],
	);

	const updateSelectedValues = useCallback(
		(value) => {
			withdrawMethodSet(value);
			setError(false);
		},
		[withdrawMethodSet, setError],
	);

	controller.setContext({ value, setError });

	const textError = useMemo(() => {
		if (!error) return "";
		return t([
			`${translationPath}.errors.countryIso`,
			"Country not selected",
		]);
	}, [t, error]);

	const placeholder = useMemo(
		() =>
			t([
				`${translationPath}.placeholders.countryIso`,
				"Select a country",
			]),
		[t],
	);

	return (
		<Select
			style={{ width: "200px" }}
			placeholder={placeholder}
			error={textError}
			value={withdrawMethod}
			options={options}
			onChange={updateSelectedValues}
		/>
	);
}, InternalController);
const WithdrawMethod = memo(WithdrawMethodBase);

declare namespace WithdrawMethod {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default WithdrawMethod;
