import styled from "styled-components";
import { calculateNameColor } from "../../utils/coloring";

const Name = styled.div<Name.Props>`
	height: 17px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 17px;

	color: ${({ active, selected, modified, valid }) =>
		calculateNameColor({ active, selected, modified, valid })};

	overflow: hidden;

	user-select: none;
`;

declare namespace Name {
	interface Props {
		active: boolean;
		selected: boolean;
		modified: boolean;
		valid: boolean;
	}
}

export default Name;
