import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option, TextSelect } from "uikit";
import Map from "../../../../../../../../../../../../services/Map";

const House: React.FC<House.Props> = ({
	value,
	streetObject,
	autoResolve,
	error,
	onChange,
	onChangeFocused,
}) => {
	const { t } = useTranslation();
	const textSelectOptions = useMemo<Option<string>[] | undefined>(
		() =>
			streetObject?.houses
				?.reduce((accumulator, house) => {
					if (
						house.number.includes(value ?? "") &&
						!accumulator.some(
							(filteredHouse) =>
								filteredHouse.number === house.number,
						)
					)
						accumulator.push(house);

					return accumulator;
				}, [] as Map.Search.House[])
				.map((option) => ({
					key: option.id,
					label: option.number,
					value: option.number,
				})),
		[streetObject?.houses, value],
	);

	const textSelectOnFocus = useCallback(
		() => onChangeFocused?.(true),
		[onChangeFocused],
	);

	const textSelectOnBlur = useCallback(
		() => onChangeFocused?.(false),
		[onChangeFocused],
	);

	return (
		<TextSelect
			value={value}
			placeholder={
				t(
					"pages.preferencesPages.screenDirectory.objects.editModal.pointForm.house.str0",
				) ?? ""
			}
			options={autoResolve ? textSelectOptions : undefined}
			error={error}
			onChange={onChange}
			onFocus={textSelectOnFocus}
			onBlur={textSelectOnBlur}
		/>
	);
};

declare namespace House {
	type Value = string;

	interface Props {
		value?: Value;

		streetObject?: Map.Search.Object;
		autoResolve?: boolean;
		error?: boolean;

		onChange?: Dispatch<Value>;
		onChangeFocused?: Dispatch<boolean>;
	}
}

export default House;
