import makeTable from "./makeTable";

const service = makeTable({
	allIds: [
		"checkbox",
		"active",
		"name",
		"company",
		"branch",
		"isExecutorRelated",
		"isVehicleRelated",
	],
	i18tPath: (id) => `tableColumns.services.${id}`,
});

export default service;
