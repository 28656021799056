import styled from "styled-components";

const Image = styled.div<Image.Props>`
	width: 100%;
	height: 100%;

	background: no-repeat padding-box center / cover;
	background-color: black;
	background-image: url(${({ url }) => url});

	overflow: hidden;
`;

declare namespace Image {
	interface Props {
		url: string;
	}
}

export default Image;
