import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import { decompressRoute } from "../../../utils/decompressRoute.util";

interface CallbackFunction {
	(data: any): void;
}

export default function calcRoute(points: any[], callback: CallbackFunction) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow || !points?.length) return null;

		return createRPCQuery(() => prpcow.theirsModel.map.route(points || []))
			.then(decompressRoute)
			.then(callback);
	};
}
