import React, {
	DetailedHTMLProps,
	HTMLAttributes,
	memo,
	PropsWithChildren,
} from "react";
import styled from "styled-components";

import { Style, baseStyles, distributeStyles, flexStyles } from "../styles";

const StyledDivider = styled.div<Divider.Styles>`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	width: auto;
	height: 100%;
	${baseStyles}
	${flexStyles}
	${distributeStyles}
`;

const Divider: React.FC<Divider.Props> = ({
	children,
	side = "left",
	...props
}): JSX.Element => {
	switch (side) {
		case "right":
			return (
				<StyledDivider b={{ right: "1px solid #dee0e2" }} {...props}>
					{children}
				</StyledDivider>
			);
		case "top":
			return (
				<StyledDivider
					w="100%"
					h="auto"
					b={{ top: "1px solid #dee0e2" }}
					{...props}
				>
					{children}
				</StyledDivider>
			);
		case "bottom":
			return (
				<StyledDivider
					w="100%"
					h="auto"
					b={{ bottom: "1px solid #dee0e2" }}
					{...props}
				>
					{children}
				</StyledDivider>
			);
		case "none":
			return (
				<StyledDivider w="100%" h="auto" {...props}>
					{children}
				</StyledDivider>
			);
		default:
			return (
				<StyledDivider b={{ left: "1px solid #dee0e2" }} {...props}>
					{children}
				</StyledDivider>
			);
	}
};

export const DividerMemo = memo(Divider);

declare namespace Divider {
	type Styles = Style.FlexType & Style.DistributeType;

	interface Props
		extends PropsWithChildren,
			Styles,
			Omit<
				DetailedHTMLProps<
					HTMLAttributes<HTMLDivElement>,
					HTMLDivElement
				>,
				"ref"
			> {
		side?: "left" | "right" | "top" | "bottom" | "none";
	}
}

export default Divider;
