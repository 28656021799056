import styled from "styled-components";

const Root = styled.div`
	width: 300px;
	height: 300px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 0px 0px 10px 10px;
	background-color: white;
`;

export default Root;
