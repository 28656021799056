import React, { Dispatch, useMemo, useCallback } from "react";
import { noop } from "lodash";

import File from "../../services/File";
import useObjectEditor from "../../hooks/useObjectEditor";
import { browse } from "../../utils/file";
import NoFiles from "../NoFiles";

import AddItem from "./components/AddItem";
import Root from "./components/Root";
import Item from "./components/Item";

const FileList: React.FC<FileList.Props> = ({
	value,

	disabled = false,

	onChange,
}) => {
	const internalValue = useMemo(() => value ?? [], [value]);

	const valueEditor = useObjectEditor(internalValue, onChange ?? noop);

	const addFilesOnClick = useCallback(async () => {
		const files = Array.from(
			(await browse({
				type: ".xls, .csv, .jpg, .png, .jpeg, .pdf, .docx, .pptx, .xlsx, .zip, .rar, .txt",
				multiple: true,
			})) as FileList,
		);

		files.forEach((file) => valueEditor.unshift(new File.Model(file)));
	}, [valueEditor]);

	return (
		<Root gaps="17px*" sizes="250px! 200px!*">
			{internalValue.length === 0 ? (
				<NoFiles disabled={disabled} onClick={addFilesOnClick} />
			) : (
				<>
					<AddItem disabled={disabled} onClick={addFilesOnClick} />
					{internalValue.map((item, index) => (
						<Item
							key={item.id ?? index}
							disabled={disabled}
							file={item}
							onDelete={() => valueEditor.remove(index)}
						/>
					))}
				</>
			)}
		</Root>
	);
};

declare namespace FileList {
	type Value = File.Model[];

	interface Props {
		value?: Value;

		disabled?: boolean;

		onChange?: Dispatch<Value>;
	}
}

export default FileList;
