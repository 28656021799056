import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	width: 800px;

	border-radius: 5px;

	background-color: ${theme.colors.white};

	overflow: hidden;
`;

export default Root;
