import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { react, useInternal, useRefWithSetter } from "uikit";
import { clone, cloneDeep } from "lodash";

import Map from "../../../../../../../../../../redux/services/Map";
import LocalObject from "../../../../../../../../../../redux/services/Map/LocalObject";
import { limit } from "../../../../../../../../../../utils/array";
import EditModal from "../..";
import PointForm from "../PointForm";
import settlementToString from "../PointForm/components/Settlement/utils/objectToLabel";
import objectToLabel from "../PointForm/components/Street/utils/objectToLabel";
import ObjectGroupContent from "../ObjectGroupContent";
import ObjectGroupFooter from "../ObjectGroupFooter";
import {
	addToObjectByAdditionKey,
	addToObjectLanguageByAdditionKey,
	createObjectLanguage,
	createObjectLanguageNames,
	pickFromObjectByAdditionKey,
	RecordObject,
	Language,
} from "../../../../../../../../../../assets/languages/langs";

import InternalController from "./Controller";
import Root from "./components/Root";

const ObjectGroupTabContent = react.withController<
	ObjectGroupTabContent.PropsBase,
	ObjectGroupTabContent.Controller
>(
	({
		controller,

		value,
		language,

		onChange,

		onCancel,
		onSave,
	}) => {
		const [objectGroupContentRef, objectGroupContentRefSetter] =
			useRefWithSetter<ObjectGroupContent.Controller | null>(null);
		const autoResolvesRef = useRef<(boolean | undefined)[]>([]);

		limit(
			autoResolvesRef.current,
			value.localObjects?.length ?? 0,
			() => true,
		);

		const queriesRef = useRef<PointForm.Value["query"][]>([]);

		limit(
			queriesRef.current,
			value.localObjects?.length ?? 0,
			() => undefined,
		);

		const autoResolves = autoResolvesRef.current;

		const defaultValue = useMemo(() => ({}), []);

		const [internalValue] = useInternal(value ?? defaultValue);

		const objectGroupContentOnChange = useCallback(
			(contentValue: ObjectGroupContent.Value) => {
				const newAutoResolves = clone(autoResolvesRef.current);

				autoResolvesRef.current = newAutoResolves;

				limit(
					autoResolvesRef.current,
					contentValue.localObjects?.length ?? 0,
					() => true,
				);

				const newQueries = cloneDeep(queriesRef.current);

				queriesRef.current = newQueries;

				limit(
					queriesRef.current,
					contentValue.localObjects?.length ?? 0,
					() => undefined,
				);

				onChange({
					...internalValue,

					taxiServiceId: contentValue.taxiServiceId,
					fields: addToObjectLanguageByAdditionKey<"title", any, any>(
						"title",
						createObjectLanguage<RecordObject>(
							contentValue.name || createObjectLanguageNames(),
						),
						internalValue.fields ??
							createObjectLanguage<RecordObject>({}),
					),
					// fields: {
					// 	en: {
					// 		...internalValue.fields?.en,
					// 		title: contentValue.name?.en,
					// 	},
					// 	ru: {
					// 		...internalValue.fields?.ru,
					// 		title: contentValue.name?.ru,
					// 	},
					// 	uk: {
					// 		...internalValue.fields?.uk,
					// 		title: contentValue.name?.uk,
					// 	},
					// 	az: {
					// 		...internalValue.fields?.az,
					// 		title: contentValue.name?.az,
					// 	},
					// 	tr: {
					// 		...internalValue.fields?.tr,
					// 		title: contentValue.name?.tr,
					// 	},
					// },
					vertices: contentValue.vertices?.map(([lat, lng]) => ({
						lat,
						lng,
					})),
					localObjects: contentValue.localObjects?.map<
						Partial<LocalObject>
					>((localObject, index) => {
						newAutoResolves[index] =
							localObject.autoResolve ?? false;

						newQueries[index] = localObject.query;

						return {
							point: localObject.coordinates,
							fields: addToObjectByAdditionKey<"title", any, any>(
								"title",
								createObjectLanguage<RecordObject>(
									localObject.name ||
										createObjectLanguageNames(),
								),
								localObject.address ?? {},
							),
							// fields: {
							// 	en: {
							// 		title: localObject.name?.en,
							// 		...localObject.address,
							// 	},
							// 	ru: {
							// 		title: localObject.name?.ru,
							// 		...localObject.address,
							// 	},
							// 	uk: {
							// 		title: localObject.name?.uk,
							// 		...localObject.address,
							// 	},
							// 	az: {
							// 		title: localObject.name?.az,
							// 		...localObject.address,
							// 	},
							// 	tr: {
							// 		title: localObject.name?.tr,
							// 		...localObject.address,
							// 	},
							// },
						};
					}),
				});
			},
			[onChange, internalValue],
		);

		const objectFooterOnChange = useCallback(
			(footerValue: ObjectGroupFooter.Value) =>
				onChange({
					...internalValue,
					status: footerValue.active,
					visibility: footerValue.visible,
					isStreet: footerValue.type === "street",
				}),
			[onChange, internalValue],
		);

		const internalValueFields = useMemo(
			() =>
				(internalValue?.fields ??
					createObjectLanguage({
						title: "",
					})) as Record<Language, { title: string }>,
			[internalValue?.fields],
		);

		const objectGroupContentValue = useMemo<ObjectGroupContent.Value>(
			() => ({
				id: (internalValue as any).id,
				taxiServiceId: internalValue.taxiServiceId,
				name: pickFromObjectByAdditionKey("title", internalValueFields),
				// name: {
				// 	en: internalValue.fields?.en.title,
				// 	ru: internalValue.fields?.ru.title,
				// 	uk: internalValue.fields?.uk.title,
				// 	az: internalValue.fields?.az.title,
				// 	tr: internalValue.fields?.tr.title,
				// },
				center: internalValue.center,
				vertices: internalValue.vertices?.map(({ lat, lng }) => [
					lat,
					lng,
				]),
				localObjects: internalValue.localObjects?.map(
					(localObject, index) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { title, ...address } =
							localObject.fields?.en ?? {};

						const localObjectFields = (localObject?.fields ??
							createObjectLanguage({
								title: "",
							})) as Record<Language, { title: string }>;

						return {
							coordinates: localObject.point,
							address,
							name: pickFromObjectByAdditionKey(
								"title",
								localObjectFields,
							),
							// name: {
							// 	en: localObject.fields?.en.title,
							// 	ru: localObject.fields?.ru.title,
							// 	uk: localObject.fields?.uk.title,
							// 	az: localObject.fields?.az.title,
							// 	tr: localObject.fields?.tr.title,
							// },
							autoResolve: autoResolves[index] ?? true,
							query: queriesRef.current[index] ?? {
								settlement: settlementToString(address),
								street: objectToLabel(address),
								house: address.house ?? "",
							},
						};
					},
				),
			}),
			[internalValue, internalValueFields, autoResolves],
		);

		const objectFooterValue = useMemo<ObjectGroupFooter.Value>(
			() => ({
				active: value.status,
				visible: value.visibility,
				type: value.isStreet ? "street" : "object",
			}),
			[value.isStreet, value.status, value.visibility],
		);

		controller.setContext({ objectGroupContentRef });

		return useMemo(
			() => (
				<Root sizes="1fr auto!" maxedWidth maxedHeight>
					<ObjectGroupContent
						ref={objectGroupContentRefSetter}
						value={objectGroupContentValue}
						language={language}
						onChange={objectGroupContentOnChange}
					/>
					<ObjectGroupFooter
						value={objectFooterValue}
						onChange={objectFooterOnChange}
						onCancel={onCancel}
						onSave={onSave}
					/>
				</Root>
			),
			[
				objectGroupContentRefSetter,
				objectGroupContentValue,
				language,
				objectGroupContentOnChange,
				objectFooterValue,
				objectFooterOnChange,
				onCancel,
				onSave,
			],
		);
	},
	InternalController,
);

declare namespace ObjectGroupTabContent {
	type Value = EditModal.LocalObjectGroupValue;

	interface PropsBase {
		value: Value;

		language: Map.Language;

		onChange: Dispatch<Value>;

		onCancel: () => void;
		onSave: () => void;
	}

	interface InternalProps extends PropsBase {
		controller: Controller;
	}

	type Props = PropsBase & RefAttributes<Controller | null>;

	type Controller = InternalController;
}

export default ObjectGroupTabContent;
