import styled from "styled-components";
import { theme } from "uikit";

const Title = styled.div`
	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0px;
	text-align: left;
`;

export default Title;
