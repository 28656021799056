import React, {
	memo,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import AutoTariff from "../../../../../../services/AutoTariff";
import { useTypedSelector } from "../../../../../../redux/store";
import Sector from "../../../../../../services/Sector";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import useCurrentTaxiServices from "../../../../../../hooks/useCurrentTaxiService";
import mapByKey from "../../../../../../utils/mapByKey";
import { ModelId } from "../../../../../../types/ModelId";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";
import { SettingsTabProps } from "../../../../../../components/Settings/tabProps";
import { useTableOptions } from "../../../../../../components/LightTable";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../constants/access";

import Filters from "./components/Filters";
import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";
import tPath from "./constants/tPath";
import defaultValue from "./constants/defaultValue";

const Auto: React.FC<SettingsTabProps> = memo(() => {
	const { t } = useTranslation();

	const { setDataLength, dataLength, editor, onChange } = useTableOptions();

	const optionsAutoTariff = useMemo(() => {
		// const order = {};
		const payload: AutoTariff.SubscribeOptions = {
			// limit,
			// order,
		};

		// if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, []);

	const { models: autoTariffs } = useModelSubscribe2(
		optionsAutoTariff,
		AutoTariff,
	);

	const { models: carClasses } = useTypedSelector(
		(state) => state.preferencesReducer.carClasses.data,
	);
	const sectorsData = useModelSubscribe({}, Sector);
	const settings = useCurrentTaxiServices()?.settings;

	useLayoutEffect(() => {
		if (dataLength !== autoTariffs.length) {
			setDataLength(autoTariffs.length);
		}
	}, [autoTariffs.length, dataLength, setDataLength]);

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const sectors = useMemo(() => sectorsData?.cache, [sectorsData?.cache]);
	const autoRateSettings = useMemo(
		() => settings?.defaults?.autoRateSettings,
		[settings?.defaults?.autoRateSettings],
	);

	useEffect(() => {
		setActualDefaultValue({
			...defaultValue,
			additionalFields: {
				...defaultValue.additionalFields,
				general: {
					...defaultValue.additionalFields.general,
					...autoRateSettings?.general,
				},
			},
		});
	}, [autoRateSettings?.general]);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selected, setSelected] = useState<ModelId[]>([]);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

	useEffect(() => {
		if (selected.length === 1) {
			const selectedTariffIndex = autoTariffs.findIndex(
				({ id }) => id === selected[0],
			);
			setSelectedIndex(selectedTariffIndex);
		} else {
			setSelectedIndex(null);
		}
	}, [autoTariffs, selected]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);

	const modelItemById = useMemo(
		() => mapByKey(autoTariffs, "id"),
		[autoTariffs],
	);

	const edit = useCallback(
		(id: ModelId) => {
			const item = modelItemById[id];
			setEditingItem(item);

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(tariff: AutoTariff.Modified) => edit(tariff.id),
		[edit],
	);

	const copyHandler = useCallback(async () => {
		await AutoTariff.copy(selected[0]);
		setSelected([]);
	}, [selected]);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(async () => {
		await AutoTariff.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const saveHandler = useCallback(
		async (tariff: AutoTariff.New | AutoTariff.Modified, force = false) => {
			let isAllOk = true;

			if ("id" in tariff) {
				isAllOk = await AutoTariff.update(tariff, force);
			} else {
				isAllOk = await AutoTariff.store(tariff, force);
			}

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(actualDefaultValue);
			}
		},
		[actualDefaultValue],
	);

	useLayoutEffect(() => {
		if (editingItem?.id) {
			const item = modelItemById?.[editingItem.id];

			const rules = item.rules || [];

			if (item) {
				setEditingItem({
					...editingItem,
					rules,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoTariffs]);

	const headerTitle = useMemo(
		() =>
			editingItem?.id
				? `${t(`${tPath}.modal.title.edit`)} "${editingItem.name}"`
				: t(`${tPath}.modal.title.add`),
		[editingItem?.id, editingItem.name, t],
	);

	const deleteModalLabel = useMemo(
		() =>
			selected.length === 1
				? t(`${tPath}.deleteModal.title`) || ""
				: t(`${tPath}.deleteModal.title2`) || "",
		[selected.length, t],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				afterAdditionalButtons={[
					{
						icon: { id: "copy", size: 20 },
						onClick: copyHandler,
						disabled: selected.length !== 1,
					},
				]}
				filters={
					<Filters
						autoTariffs={autoTariffs}
						selectedId={selected.length === 1 && selected[0]}
						selectedIndex={selectedIndex}
						setSelected={setSelected}
					/>
				}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.AUTOMATIC_TARIFFS]}
			/>
			<Content
				value={autoTariffs}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				carClasses={carClasses}
				sectors={sectors}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					carClasses={carClasses}
					sectors={sectors}
					headerTitle={headerTitle}
					createdAt={editingItem.createdAt}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={deleteModalLabel}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
});

export default Auto;
