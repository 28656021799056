import makeTable from "./makeTable";

const client = makeTable({
	allIds: [
		"id",
		"rideCount",
		"balance",
		"bonusBalance",
		"bonusRides",
		"totalOrderCount",
		"completedOrderCount",
		"orderEstimationCount",
		"canceledOrderCount",
		"canceledOrderCountNoCarFound",
		"lastName",
		"firstName",
		"fatherName",
		"birthday",
		"gender",
		"app",
		"email",
		"phones",
		"mainLoyaltyProgram",
		"additionalLoyaltyProgram",
		"bonusCard",
		"rating",
		"tariff",
		"defaultCarClass",
		"status",
		"company",
		"defaultTaxiService",
		"latestTaxiService",
		"notes",
		"group",
		"createdAt",
		"updatedAt",
		"deletedAt",
	],
	defaultIds: [
		"lastName",
		"firstName",
		"fatherName",
		"phones",
		"balance",
		"bonusBalance",
		"status",
		"app",
		"company",
		"defaultTaxiService",
		"latestTaxiService",
		"notes",
		"totalOrderCount",
	],
	i18tPath: (id) => `tableColumns.customers.clients.${id}`,

	initialSortMethod: {
		column: "createdAt",
		type: "desc",
	},
});

export default client;
