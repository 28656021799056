import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { Option } from "uikit";
import { isEqual } from "lodash";

import Map from "../../../../../../../services/Map";
import useKeyBind from "../../../../../../../hooks/useKeyBind";
import useMapSearch from "../../../../../../../hooks/useMapSearch";
import { Style, StyledSpan, StyledP } from "../../../../../../common";

import { IField, StyledOptionList, StyledTip } from "../../styled";
import streetToString from "../../utils/streetToString";
import cityToString from "../../utils/cityToString";

const OverlayStreetBase: React.FC<OverlayStreetBase.Props> = ({
	data,
	onSelect,
	activeId = 0,
	setActiveOption,
}) => {
	const fieldRef = React.useRef<IField | null>(null);
	const styled = useMemo<{ text: Style.TextType; value: Style.TextType }>(
		() => ({
			text: {
				w: "100%",
				colors: "#21333f",
				font: { fw: "400", size: "14px", line: "18px" },
				cursor: "pointer",
				text: { tt: "uppercase" },
			},
			value: {
				colors: "#9BA3A8",
				font: { fw: "300", size: "13px", line: "14px" },
				cursor: "pointer",
				text: { tt: "lowercase" },
			},
		}),
		[],
	);

	const options = useMemo<Option<Map.Search.Object>[]>(() => {
		if (data.loading) return [];

		const filterSettlement = data.objects?.sort((object, ob2) =>
			object.settlementType === ob2.settlementType ? 1 : 0,
		);

		return filterSettlement.map((object) => ({
			key: object.id,
			label: `${streetToString(
				object,
				object.name || object.title ? "name" : "streetHouse",
			)}`,
			value: object,
		}));
	}, [data]);

	const onClick = useCallback(
		(option: Option<Map.Search.Object>) => {
			onSelect(option);
		},
		[onSelect],
	);

	useLayoutEffect(() => {
		if (fieldRef.current) {
			fieldRef.current?.scrollIntoView({
				behavior: "smooth",
				inline: "nearest",
				block: "nearest",
			});
		}
	}, [activeId, fieldRef]);

	useKeyBind(["ArrowUp"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev - 1 < 0 ? items - 1 : prev - 1;
		});
	});

	useKeyBind(["ArrowDown"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev + 1 > items - 1 ? 0 : prev + 1;
		});
	});

	useKeyBind(["Enter"], () => {
		if (options && activeId !== -1) onClick(options[activeId]);
	});

	useKeyBind(["Tab"], (event) => {
		if (options && activeId !== -1) onClick(options[activeId]);
		event.stopPropagation();
		event.preventDefault();
	});

	return (
		<StyledOptionList>
			{options?.map((option, i) => (
				<StyledTip
					ref={activeId === i ? fieldRef : null}
					key={`${i + 1}`}
					isActive={activeId === i}
					onClick={(event) => {
						onClick(option);
						event?.stopPropagation?.();
						event?.preventDefault?.();
						setActiveOption(i);
					}}
					flex={{ direction: "column" }}
				>
					<StyledP
						{...styled.text}
						onClick={(event) => {
							onClick(option);

							event?.stopPropagation?.();
							event?.preventDefault?.();
							setActiveOption(i);
						}}
					>
						{option.label}
					</StyledP>
					<StyledSpan
						{...styled.value}
						onClick={(event) => {
							onClick(option);
							event?.stopPropagation?.();
							event?.preventDefault?.();
							setActiveOption(i);
						}}
					>
						{`${cityToString(
							option.value,
							"full",
						)} ${streetToString(
							option.value,
							option.value.name || option.value.title
								? "streetHouse"
								: null,
						)}`.trim()}
					</StyledSpan>
				</StyledTip>
			))}
		</StyledOptionList>
	);
};

declare namespace OverlayStreetBase {
	interface Props {
		data: useMapSearch.Result;
		onSelect: (option: Option<Map.Search.Object>) => void;
		activeId: number;
		setActiveOption: Dispatch<SetStateAction<number>>;
		debounce?: number;
		settlement: string;
		query?: string;
	}
}

export const OverlayStreet = memo(OverlayStreetBase, (prev, next) => {
	if (prev.activeId !== next.activeId) return false;
	if (prev.settlement !== next.settlement) return false;
	if (prev.query !== next.query) return false;

	if (prev.setActiveOption !== next.setActiveOption) return false;
	if (prev.onSelect !== next.onSelect) return false;

	if (!isEqual(prev.data, next.data)) return false;

	return true;
});
export default OverlayStreetBase;
