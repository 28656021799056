import { useEffect, useMemo } from "react";
import Order from "../../../../../services/Order";
import useArchiveOrders from "./useArchiveOrders";

import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";
import useArchiveSwitcher from "./useArchiveSwitcher";

interface UseOrdersSubscribe {
	metadataState: ReturnType<typeof useModelSubscribe>["metadataState"];
}

/**
 *  `useOrdersSubscribe`
 */
export const useOrdersSubscribe = (
	subscribeOptions: Order.SubscribeOptions,
): UseOrdersSubscribe => {
	const { setOrdersLoading } = useArchiveSwitcher();
	const {
		setOrders,
		setOrder,
		orders: { orders, order },
	} = useArchiveOrders();

	const { models, metadataState, loading } = useModelSubscribe(
		subscribeOptions,
		Order,
	);

	const data = useMemo<{
		items: Order.Model[];
		orderMap: Map<number, Order.Model>;
	}>(() => {
		if (!models.length) {
			return { items: orders, orderMap: new Map() };
		}
		const orderMap: Map<number, Order.Model> = new Map();
		const oldNewOrders = [...orders, ...models];
		const items: Order.Model[] = [];
		oldNewOrders.forEach((item) => orderMap.set(item.id, item));
		orderMap.forEach((item) => {
			items.push(item);
		});
		return { items, orderMap };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [models]);

	useEffect(() => {
		if (!order) return;
		const exist = data.orderMap.get(order.id);
		if (exist) setOrder(exist);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.orderMap]);

	useEffect(() => {
		setOrdersLoading(loading);
	}, [loading, setOrdersLoading]);

	useEffect(() => {
		if (!orders.length) setOrders(models);
	}, [setOrders, orders, models]);

	useEffect(() => {
		if (data.items?.length) setOrders(data.items);
	}, [data, setOrders]);

	return useMemo<UseOrdersSubscribe>(
		() => ({ metadataState }),
		[metadataState],
	);
};

export default useOrdersSubscribe;
