import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../../../../../../../components/common";
import SettlementPickupCost from "../../../../../../../../../../components/SettlementPickupCost";
import SuburbanPickupCost from "../../../../../../../../../../components/SuburbanPickupCost";
import Item from "../../../../../../../../../../components/Item";
import PickupCost from "../../../../../../../../../../components/PickupCost";
import tPath from "../../../../../../../../constants/tPath";

const MainSettings: React.FC<MainSettings.Props> = memo(
	({ value, onChange, root }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const boardingCost = valueEditor.useGetter("boardingCost");
		const setBoardingCost = valueEditor.useSetter("boardingCost");
		const settlementCostPerKm = valueEditor.useGetter(
			"settlementCostPerKm",
		);
		const setSettlementCostPerKm = valueEditor.useSetter(
			"settlementCostPerKm",
		);
		const suburbanCostPerKm = valueEditor.useGetter("suburbanCostPerKm");
		const setSuburbanCostPerKm = valueEditor.useSetter("suburbanCostPerKm");
		const roundTripCostPerKm = valueEditor.useGetter("roundTripCostPerKm");
		const setRoundTripCostPerKm =
			valueEditor.useSetter("roundTripCostPerKm");

		const autoCalculatePickupCost = valueEditor.useGetter(
			"autoCalculatePickupCost",
		);
		const setAutoCalculatePickupCost = valueEditor.useSetter(
			"autoCalculatePickupCost",
		);

		const pickupCostCalculation = valueEditor.useGetter(
			"pickupCostCalculation",
		);
		const setPickupCostCalculationType = valueEditor.useSetter(
			"pickupCostCalculation",
		);

		const pickupCostCalculationEditor = useObjectEditor(
			pickupCostCalculation,
			setPickupCostCalculationType,
		);

		const pickupCostCalculationType =
			pickupCostCalculationEditor.useGetter("type");

		const settlementPickupCost = valueEditor.useGetter(
			"settlementPickupCost",
		);
		const setSettlementPickupCost = valueEditor.useSetter(
			"settlementPickupCost",
		);
		const suburbanPickupCost = valueEditor.useGetter("suburbanPickupCost");
		const setSuburbanPickupCost =
			valueEditor.useSetter("suburbanPickupCost");

		return (
			<FieldsContainer
				label={
					t(`${tPath}.mainModal.tabs.main.mainSettings.blockName`) ||
					"Main settings"
				}
				gap="10px"
			>
				<Item
					disabled={root}
					value={boardingCost}
					title={t(
						`${tPath}.mainModal.tabs.main.mainSettings.boardingCost`,
					)}
					onChange={setBoardingCost}
				/>
				<Item
					disabled={root}
					value={settlementCostPerKm}
					title={t(
						`${tPath}.mainModal.tabs.main.mainSettings.settlementCostPerKm`,
					)}
					onChange={setSettlementCostPerKm}
				/>
				<Item
					disabled={root}
					value={suburbanCostPerKm}
					title={t(
						`${tPath}.mainModal.tabs.main.mainSettings.suburbanCostPerKm`,
					)}
					onChange={setSuburbanCostPerKm}
				/>
				<Item
					disabled={root}
					value={roundTripCostPerKm}
					title={t(
						`${tPath}.mainModal.tabs.main.mainSettings.roundTripCostPerKm`,
					)}
					onChange={setRoundTripCostPerKm}
				/>
				<StyledRow gap="0 10px" alignItems="center">
					<StyledColumn gap="10px">
						<SettlementPickupCost
							disabled={root}
							pickupCostType={pickupCostCalculationType}
							value={settlementPickupCost}
							onChange={setSettlementPickupCost}
						/>
						<SuburbanPickupCost
							disabled={root}
							value={suburbanPickupCost}
							onChange={setSuburbanPickupCost}
						/>
					</StyledColumn>

					<PickupCost
						disabled={false}
						value={pickupCostCalculation}
						onChange={setPickupCostCalculationType}
					/>
				</StyledRow>
				<CheckBox
					value={autoCalculatePickupCost}
					onChange={setAutoCalculatePickupCost}
					label={
						t(
							`${tPath}.mainModal.tabs.main.mainSettings.autoCalculatePickupCost`,
						) || ""
					}
				/>
			</FieldsContainer>
		);
	},
);

declare namespace MainSettings {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
		disabled?: boolean;
		root: boolean;
	}

	interface Value {
		boardingCost: Item.Value;
		settlementCostPerKm: Item.Value;
		suburbanCostPerKm: Item.Value;
		roundTripCostPerKm: Item.Value;
		autoCalculatePickupCost: boolean;
		settlementPickupCost: SettlementPickupCost.Value;
		suburbanPickupCost: SuburbanPickupCost.Value;
		pickupCostCalculation: PickupCost.Value;
	}
}

export default MainSettings;
