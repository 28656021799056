/* eslint-disable no-shadow */

import { defaults } from "lodash";
import File from "../services/File";

export function download(data: string, filename: string, type = "text/plain") {
	const a = document.createElement("a");

	const url = `data:${type}, ${data}`;

	a.href = url;
	a.download = filename;

	document.body.appendChild(a);

	a.click();

	process.nextTick(() => {
		document.body.removeChild(a);
	});
}

export function downloadViaURL(url: string, filename: string) {
	const a = document.createElement("a");

	a.href = url;
	a.download = filename;
	a.setAttribute("style", "display: none");

	document.body.appendChild(a);

	a.dispatchEvent(
		new MouseEvent("click", {
			bubbles: true,
			cancelable: true,
			view: window,
		}),
	);

	process.nextTick(() => {
		document.body.removeChild(a);
	});
}

export function downloadViaFile(file: globalThis.File) {
	const url = URL.createObjectURL(file);

	const a = document.createElement("a");

	a.href = url;
	a.download = file.name;

	document.body.appendChild(a);

	a.click();

	process.nextTick(() => {
		document.body.removeChild(a);

		URL.revokeObjectURL(url);
	});
}

export async function downloadViaFileModel(file: File.Model) {
	const blob = await file.load();

	if (!blob) return;

	const url = window.URL.createObjectURL(blob);

	const elem = document.createElement("a");
	document.body.appendChild(elem);

	elem.href = url;
	elem.download = file.name;
	elem.click();

	process.nextTick(() => {
		document.body.removeChild(elem);

		window.URL.revokeObjectURL(url);
	});
}

export function browse(options: internalBrowse.Options) {
	const internalOptions = defaults({}, options, {
		type: "",
		multiple: false,
	});

	const input = document.createElement("input");

	input.type = "file";
	input.accept = internalOptions.type;
	input.multiple = internalOptions.multiple;

	const promise = new Promise<FileList | File>((resolve, reject) => {
		input.onchange = (event: Event) => {
			const { files } = event.target as HTMLInputElement;

			if (!files || files.length === 0) {
				reject();

				return;
			}

			resolve(internalOptions.multiple ? files : files[0]);
		};
	});

	input.click();

	return promise;
}

export namespace browse {
	export type Options = internalBrowse.Options;
}

declare namespace internalBrowse {
	interface Options {
		type?: string;
		multiple?: boolean;
	}
}

const defaultObject = {
	download,
	downloadViaURL,
	downloadViaFile,
	browse,
};

declare namespace defaultObject {
	namespace browse {
		type Options = internalBrowse.Options;
	}
}

export default defaultObject;
