import styled from "styled-components";
import { theme } from "uikit";

const Name = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;

	color: ${theme.colors.primary};
`;

export default Name;
