import * as ModelEvent from "@node-elion/syncron";
import { defaults } from "lodash";

import { getPRPC } from "../../hooks/usePRPC";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import Subscription from "../../types/Subscription";
import createRPCQuery from "../../utils/createRPCQuery.util";
import RequiredProperties from "../../types/RequiredProperties";
import Language from "../Language";
import TaxiService from "../TaxiService2";
import Base from "../Base";

import { destroyOne } from "./utils";

class Service extends Base {
	static defaultSharedOptions: Service.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): Service.Model {
		return {
			id: data.id,
			active: data.active,
			availableForCar: data.availableForCar,
			availableForExecutor: data.availableForExecutor,

			taxiServices: data.serviceToTaxiServices?.map((toTaxiService) =>
				TaxiService.fromResponse(toTaxiService.taxiService),
			),

			name: data.name,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static toRequest(model: Service.Model.New | Service.Model.Modified): any {
		return {};
	}

	static async store(
		object: Service.Model.New,
		options?: Service.StoreOptions,
	) {
		options = defaults(options, Service.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.role.create(Service.toRequest(object)),
		);
	}

	static async update(
		object: Service.Model.Modified,
		options?: Service.UpdateOptions,
	) {
		options = defaults(options, Service.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.role.update(object.id, Service.toRequest(object)),
		);
	}

	static async destroy(
		id: number[] | number,
		options?: Service.DestroyOptions,
	) {
		options = defaults(options, Service.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);
	}

	public static async subscribe(
		params: Service.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Service.Model>,
	): Promise<Subscription<Service.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Service] subscribe", state);
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			async (prpc) => {
				const data = await prpc.theirsModel.service.subscribe({
					params,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				});
				return data;
			},
			{ name: "Service", metadata: params },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Service.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<Service.SubscribeOptions>;
	}

	static Global = {
		async index(options: Service.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.service.getAll({
					limit: options.limit,
					offset: options.offset,
					query: options.query,
					order: options.order,

					taxiServiceIds: options.taxiServiceIds,
					availableForExecutor: options.availableForExecutor,
					availableForCar: options.availableForCar,
				}),
			);

			return {
				cache: result.items.map(Service.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}

declare namespace Service {
	interface Model {
		id: number;
		active?: boolean;
		availableForCar: boolean;
		availableForExecutor: boolean;

		taxiServices?: TaxiService.Model[];
		serviceToTaxiServices?: any[];

		name: Record<Language, string>;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Service.Model> {
		taxiServiceIds?: number[];
		availableForExecutor?: boolean;
		availableForCar?: boolean;
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type New = Omit<
			Model,
			"id" | "default" | "createdAt" | "updatedAt" | "deletedAt"
		>;
		type Modified = RequiredProperties<
			Partial<
				Omit<Model, "default" | "createdAt" | "updatedAt" | "deletedAt">
			>,
			"id"
		>;
	}
}

export default Service;
