import styled from "styled-components";
import { theme } from "uikit";

const Title = styled.div`
	font-family: "Lato";
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;

	color: ${theme.colors.primary};

	pointer-events: none;
`;

export default Title;
