import React, { PropsWithChildren } from "react";
import { Column } from "uikit";

import Title from "./components/Title";

const FieldContainer: React.FC<FieldContainer.Props> = ({
	children,
	title,
}) => (
	<Column gaps="12px*" maxedWidth maxedHeight>
		<Title>{title}</Title>
		{children}
	</Column>
);

declare namespace FieldContainer {
	interface Props extends PropsWithChildren {
		title: string;
	}
}

export default FieldContainer;
