import makeTable from "./makeTable";

const counterpartyCheckCard = makeTable({
	allIds: [
		"status",
		"cardNumber",
		"keyword",
		"orderCount",
		"registrationDate",
		"activationDate",
		"completionDate",
		"firstRideDate",
		"lastRideDate",
		"mobileApp",
		"corporateAccount",
		"notes",
	],
	defaultIds: ["status", "cardNumber", "keyword", "orderCount", "notes"],
	i18tPath: (id) => `tableColumns.customers.counterpartyCheckCards.${id}`,
});

export default counterpartyCheckCard;
