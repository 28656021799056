/* eslint-disable no-shadow */
import getOptions from "../../../../../utils/generateOptions";

export enum RateAdjustmentToDefault {
	CUSTOM_VALUE = "custom_value",
	SURCHARGE_TO_DEFAULT_RATE = "surcharge_to_default_rate",
	COEFFICIENT_TO_DEFAULT_RATE = "coefficient_to_default_rate",
}

export enum RateBinaryAdjustmentToDefault {
	CUSTOM_VALUE = "custom_value",
	DEFAULT_VALUE = "default_value",
}

export const defaultSurchargeOptions = getOptions([
	RateAdjustmentToDefault.CUSTOM_VALUE,
	RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
	RateAdjustmentToDefault.SURCHARGE_TO_DEFAULT_RATE,
]);

export const binarySurchargeOptions = getOptions([
	RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
	RateBinaryAdjustmentToDefault.CUSTOM_VALUE,
]);

export enum PickupCostType {
	NEAREST = "nearest",
	CENTROID = "centroid",
	POINT = "point",
	ZONE = "zone",
	ZONE_REVERSE = "zone_reverse",
}

export const pickupCostOptions = getOptions([
	PickupCostType.NEAREST,
	PickupCostType.CENTROID,
	PickupCostType.POINT,
	PickupCostType.ZONE_REVERSE,
]);

export enum Units {
	HOURS = "hours",
	MINUTES = "minutes",
}

export const unitsOptions = getOptions(Object.values(Units));
