import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "uikit";

import {
	RateBinaryAdjustmentToDefault,
	binarySurchargeOptions,
} from "../../constants/constants";
import tPath from "../../constants/tPath";

const BinaryAdjustmentSelect: React.FC<BinaryAdjustmentSelect.Props> = ({
	disabled,
	value,
	onChange,
}) => {
	const { t } = useTranslation();

	const tDefaultSurchargeOptions = useMemo(
		() =>
			binarySurchargeOptions.map((option) => ({
				...option,
				label: t(
					`${tPath}.mainModal.tabs.main.mainSettings.surchargeOptions.${option.key}`,
				),
			})),
		[t],
	);

	const handleChange = useCallback(
		(newType) => {
			onChange(newType);
		},
		[onChange],
	);

	return (
		<Select
			disabled={disabled}
			style={{
				width: "180px",
			}}
			value={value}
			onChange={handleChange}
			options={tDefaultSurchargeOptions}
		/>
	);
};

declare namespace BinaryAdjustmentSelect {
	interface Props {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	type Value = RateBinaryAdjustmentToDefault;
}

export default BinaryAdjustmentSelect;
