/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { DatePicker, TimeInput } from "uikit";
import { SortType } from "rsuite-table";

import Dispatcher from "../../../../../services/Dispatcher";
import Executor from "../../../../../services/Executor";
import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import {
	State,
	UsersModalTabType,
	ModalType,
	MessagesTypeKeys,
	ScheduleActualType,
	ScheduleRequestData,
	ContextMenuCoordinate,
} from "./interface";

export type {
	UsersModalTabType,
	ModalType,
	MessagesTypeKeys,
	ScheduleActualType,
	ScheduleRequestData,
};

export const initialState: State = {
	userModalSort: {
		column: undefined,
		type: undefined,
	},
	userModalTab: "executors",
	userModalQuery: "",
	userModalData: {
		dispatchers: [],
		executors: [],
	},
	modalType: "none",
	booferSelectedUsers: {
		dispatchers: [],
		executors: [],
	},
	selectedUsers: {
		dispatchers: [],
		executors: [],
	},
	booferSelectedMessageType: "default",
	contextMenuCoordinate: {
		x: null,
		y: null,
		isCurrentUser: false,
	},
	booferAnswerParentID: null,
	answerParentID: null,
	answerComponentHeight: 0,
	isShowContextMenu: false,
	isShowScrollButton: true,
	isShowClockModal: false,
	isSendingMessage: false,
	scheduleModalActualType: "time",
	scheduleHours: 0,
	scheduleMinutes: 0,
	scheduleDate: null,
	scheduleTime: null,
	lastRead: {
		newId: 0,
		oldId: 0,
		remaining: 0,
	},
};

const setUserModalTab: ReduxReducer<State, UsersModalTabType> = (
	state,
	action,
) => {
	state.userModalTab = action.payload;
};

const setUserModalQuery: ReduxReducer<State, string> = (state, action) => {
	state.userModalQuery = action.payload;
};

const setModalType: ReduxReducer<State, ModalType> = (state, action) => {
	state.modalType = action.payload;
};
const setMessageType: ReduxReducer<State, MessagesTypeKeys> = (
	state,
	action,
) => {
	state.booferSelectedMessageType = action.payload;
};

const addDispatcherToUsers: ReduxReducer<State, Dispatcher.Model[]> = (
	state,
	action,
) => {
	state.selectedUsers.dispatchers = action.payload;
};

const addExecutorsToUsers: ReduxReducer<State, Executor.Model[]> = (
	state,
	action,
) => {
	state.selectedUsers.executors = action.payload;
};

const addDispatcherToBoofer: ReduxReducer<State, Dispatcher.Model> = (
	state,
	action,
) => {
	if (
		state.booferSelectedUsers.dispatchers.some(
			(dispatcher) => dispatcher.id === action.payload.id,
		)
	)
		return;
	state.booferSelectedUsers.dispatchers.push(action.payload);
};

const setDispatchersInBoofer: ReduxReducer<State, Dispatcher.Model[]> = (
	state,
	action,
) => {
	state.booferSelectedUsers.dispatchers = action.payload;
};

const addExecutorToBoofer: ReduxReducer<State, Executor.Model> = (
	state,
	action,
) => {
	if (
		state.booferSelectedUsers.executors.some(
			(executor) => executor.id === action.payload.id,
		)
	)
		return;
	state.booferSelectedUsers.executors.push(action.payload);
};

const setExecutorsInBoofer: ReduxReducer<State, Executor.Model[]> = (
	state,
	action,
) => {
	state.booferSelectedUsers.executors = action.payload;
};

const removeDispatcherFromBoofer: ReduxReducer<State, Dispatcher.Model> = (
	state,
	action,
) => {
	const filtredDispatchers = state.booferSelectedUsers.dispatchers.filter(
		(dispatcher) => dispatcher.id !== action.payload.id,
	);
	state.booferSelectedUsers.dispatchers = filtredDispatchers;
};

const removeExecutorFromBoofer: ReduxReducer<State, Executor.Model> = (
	state,
	action,
) => {
	const filtredExecutor = state.booferSelectedUsers.executors.filter(
		(executor) => executor.id !== action.payload.id,
	);
	state.booferSelectedUsers.executors = filtredExecutor;
};

const setContextMenuCoordinate: ReduxReducer<State, ContextMenuCoordinate> = (
	state,
	action,
) => {
	state.contextMenuCoordinate = action.payload;
};

const resetUsersBoofer: ReduxReducer<State, undefined> = (state) => {
	state.booferSelectedUsers = state.selectedUsers;
};

const setIsShowContextMenu: ReduxReducer<State, boolean> = (state, action) => {
	state.isShowContextMenu = action.payload;
};

const setIsShowScrollButton: ReduxReducer<State, boolean> = (state, action) => {
	state.isShowScrollButton = action.payload;
};

const setIsShowClockModal: ReduxReducer<State, boolean> = (state, action) => {
	state.isShowClockModal = action.payload;
};

const setIsSendingMessage: ReduxReducer<State, void> = (state) => {
	state.isSendingMessage = !state.isSendingMessage;
};

const setExecutorsInUserModalData: ReduxReducer<State, Executor.Model[]> = (
	state,
	action,
) => {
	state.userModalData.executors = action.payload;
};

const setDispatchersInUserModalData: ReduxReducer<State, Dispatcher.Model[]> = (
	state,
	action,
) => {
	state.userModalData.dispatchers = action.payload;
};

const sortUserModalData: ReduxReducer<
	State,
	{
		type: SortType | undefined;
		column: string;
	}
> = (state, action) => {
	state.userModalSort.column = action.payload.column;
	state.userModalSort.type = action.payload.type;
};

const clearDispatchersInBoofer: ReduxReducer<State, void> = (state) => {
	state.booferSelectedUsers.dispatchers =
		initialState.booferSelectedUsers.dispatchers;
};

const clearExecutorsInBoofer: ReduxReducer<State, void> = (state) => {
	state.booferSelectedUsers.executors =
		initialState.booferSelectedUsers.executors;
};

const clearDispatchersInSelectedUsers: ReduxReducer<State, void> = (state) => {
	state.selectedUsers.dispatchers = initialState.selectedUsers.dispatchers;
};

const clearExecutorsInSelectedUsers: ReduxReducer<State, void> = (state) => {
	state.selectedUsers.executors = initialState.selectedUsers.executors;
};

/* groupSchedule */

const setScheduleModalActualType: ReduxReducer<State, ScheduleActualType> = (
	state,
	action,
) => {
	state.scheduleModalActualType = action.payload;
};
const setScheduleDate: ReduxReducer<State, DatePicker.Value> = (
	state,
	action,
) => {
	state.scheduleDate = action.payload;
};
const setScheduleTime: ReduxReducer<State, TimeInput.Value> = (
	state,
	action,
) => {
	state.scheduleTime = action.payload;
};
const setScheduleHours: ReduxReducer<State, number> = (state, action) => {
	state.scheduleHours = action.payload;
};
const setScheduleMinutes: ReduxReducer<State, number> = (state, action) => {
	state.scheduleMinutes = action.payload;
};
const cleanScheduleModalActualType: ReduxReducer<State, void> = (state) => {
	state.scheduleModalActualType = initialState.scheduleModalActualType;
};
const cleanScheduleDate: ReduxReducer<State, void> = (state) => {
	state.scheduleDate = initialState.scheduleDate;
};
const cleanScheduleTime: ReduxReducer<State, void> = (state) => {
	state.scheduleTime = initialState.scheduleTime;
};
const cleanScheduleHours: ReduxReducer<State, void> = (state) => {
	state.scheduleHours = initialState.scheduleHours;
};
const cleanScheduleMinutes: ReduxReducer<State, void> = (state) => {
	state.scheduleMinutes = initialState.scheduleMinutes;
};

const groupSchedule = {
	setScheduleModalActualType,
	setScheduleDate,
	setScheduleTime,
	setScheduleHours,
	setScheduleMinutes,
	cleanScheduleModalActualType,
	cleanScheduleDate,
	cleanScheduleTime,
	cleanScheduleHours,
	cleanScheduleMinutes,
};

/* groupAnswerParent */

const setBooferAnswerParent: ReduxReducer<State, number | null> = (
	state,
	action,
) => {
	state.booferAnswerParentID = action.payload;
};
const setAnswerParent: ReduxReducer<State, number | null> = (state, action) => {
	state.answerParentID = action.payload;
};
const setAnswerComponentHeight: ReduxReducer<State, number> = (
	state,
	action,
) => {
	state.answerComponentHeight = action.payload;
};
const cleanBooferAnswerParent: ReduxReducer<State, void> = (state) => {
	state.booferAnswerParentID = initialState.booferAnswerParentID;
};
const cleanAnswerParent: ReduxReducer<State, void> = (state) => {
	state.answerParentID = initialState.answerParentID;
};

const groupAnswerParent = {
	setBooferAnswerParent,
	setAnswerParent,
	setAnswerComponentHeight,
	cleanBooferAnswerParent,
	cleanAnswerParent,
};

/* groupLastRead */

const setLastReadOld: ReduxReducer<State, number> = (state, action) => {
	if (initialState.lastRead.oldId >= action.payload) return;
	state.lastRead.oldId = action.payload;
};
const setLastReadNew: ReduxReducer<State, number> = (state, action) => {
	if (state.lastRead.newId >= action.payload) return;
	state.lastRead.newId = action.payload;
};
const setLastReadRemaining: ReduxReducer<State, number> = (state, action) => {
	state.lastRead.remaining = action.payload;
};

const groupLastRead = {
	setLastReadOld,
	setLastReadNew,
	setLastReadRemaining,
};

const ordersChats = createSlice({
	name: Orders.ConstantOrderSlice.CHAT_CHATS,
	initialState,
	reducers: {
		...groupSchedule,
		...groupAnswerParent,
		...groupLastRead,
		setUserModalTab,
		setUserModalQuery,
		setModalType,
		setMessageType,
		addDispatcherToBoofer,
		addExecutorToBoofer,
		removeDispatcherFromBoofer,
		removeExecutorFromBoofer,
		setContextMenuCoordinate,
		addDispatcherToUsers,
		addExecutorsToUsers,
		resetUsersBoofer,
		setIsShowContextMenu,
		setIsShowScrollButton,
		setIsShowClockModal,
		setIsSendingMessage,
		setDispatchersInBoofer,
		setExecutorsInBoofer,
		setExecutorsInUserModalData,
		setDispatchersInUserModalData,
		sortUserModalData,
		clearDispatchersInBoofer,
		clearExecutorsInBoofer,
		clearDispatchersInSelectedUsers,
		clearExecutorsInSelectedUsers,
	},
});

export const orderChatsAction = ordersChats.actions;
export const orderChatsReducer = ordersChats.reducer;
export type orderChatsState = State;

export default ordersChats;
