import React from "react";
import { useTranslation } from "react-i18next";
import { Row, theme } from "uikit";
import CloseButton from "./components/CloseButton";
import Label from "./components/Label";

const Header: React.FC<Header.Props> = ({ onClose }) => {
	const { t } = useTranslation();
	return (
		<Row align="center" justify="space-between">
			<Label style={{ color: theme.colors.text_placeholder }}>
				{t(
					"pages.mainPage.pages.archives.tabs.messagesHistory.header.filter.popover.header.str100",
				) ?? ""}
			</Label>
			<CloseButton onClick={onClose} />
		</Row>
	);
};

declare namespace Header {
	export interface Props {
		onClose: () => void;
	}
}

export default Header;
