import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextBox } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../../../constants/tPath";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import { IName } from "../../../../../../../../../../../../../../types/IName";

const Name: React.FC<Name.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const handleNameUpdate = useCallback(
		(newName) => {
			onChange({ [lang]: newName });
		},
		[lang, onChange],
	);

	return (
		<LabeledField label={t(`${tPath}.modal.tabs.main.name`) || ""}>
			<TextBox.TextBox
				placeholder={t(`${tPath}.modal.tabs.main.name`) || ""}
				value={value[lang]}
				onChange={handleNameUpdate}
			/>
		</LabeledField>
	);
});

declare namespace Name {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	type Value = IName;
}

export default Name;
