import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BaseRateAdjustmentType } from "../../../../../../services/Tariff/enums";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../components/FieldsContainer";
import { StyledColumn } from "../../../../../../components/common";

import tPath from "../../constants/tPath";

import { surchargesOptions } from "./constants/options";
import PassengersSurcharge from "./components/PassengersSurcharge";
import AdditionalPointCost from "./components/AdditionalPointCost";
import BaseSurcharge from "./components/BaseSurcharge";

const SurchargesBlock: React.FC<SurchargesBlock.Props> = memo((props) => {
	const { surcharges, onChangeSurcharges, disabled, root } = props;

	const { t } = useTranslation();

	const editor = useObjectEditor(surcharges, onChangeSurcharges);

	const additionalPointCost = editor.useGetter("additionalPointCost");
	const setAdditionalPointCost = editor.useSetter("additionalPointCost");
	const preliminarySurcharge = editor.useGetter("preliminarySurcharge");
	const setPreliminarySurcharge = editor.useSetter("preliminarySurcharge");

	const surchargeToOrderSum = editor.useGetter("surchargeToOrderSum");
	const setSurchargeToOrderSum = editor.useSetter("surchargeToOrderSum");

	const pickupCostSurcharge = editor.useGetter("pickupCostSurcharge");
	const setPickupCostSurcharge = editor.useSetter("pickupCostSurcharge");
	const onlineOrderSurchApp = editor.useGetter("onlineOrderSurchargeApp");
	const setOnlineOrderSurchApp = editor.useSetter("onlineOrderSurchargeApp");
	const onlineOrderWeb = editor.useGetter("onlineOrderSurchargeWebsite");
	const setOnlineOrderhWeb = editor.useSetter("onlineOrderSurchargeWebsite");
	const cardPaymentSurch = editor.useGetter("creditCardPaymentSurcharge");
	const setCardPaymentSurch = editor.useSetter("creditCardPaymentSurcharge");
	const invoicePaymentSurch = editor.useGetter("invoicePaymentSurcharge");
	const setInvoicePaymentSurch = editor.useSetter("invoicePaymentSurcharge");
	const nonCashPaymentSurch = editor.useGetter("nonCashPaymentSurcharge");
	const setNonCashPaymentSurch = editor.useSetter("nonCashPaymentSurcharge");
	const terminalSurcharge = editor.useGetter("terminalPaymentSurcharge");
	const setTerminalSurcharge = editor.useSetter("terminalPaymentSurcharge");
	const taximeterSurcharge = editor.useGetter("taximeterSurcharge");
	const setTaximeterSurcharge = editor.useSetter("taximeterSurcharge");
	const ownTaximSur = editor.useGetter("executorOrderTaximeterSurcharge");
	const setOwnTaximSur = editor.useSetter("executorOrderTaximeterSurcharge");
	const ownSurcharge = editor.useGetter("executorOrderSurcharge");
	const setOwnSurcharge = editor.useSetter("executorOrderSurcharge");

	const passengersSurcharge = editor.useGetter("passengersSurcharge");
	const setPassengersSurcharge = editor.useSetter("passengersSurcharge");

	const path = useMemo(() => `${tPath}.mainModal.tabs.main.surcharges`, []);

	const options = useMemo(
		() =>
			surchargesOptions
				.filter(
					(option) =>
						!(
							root &&
							[
								BaseRateAdjustmentType.SURCHARGE_TO_DEFAULT_RATE,
								BaseRateAdjustmentType.COEFFICIENT_TO_DEFAULT_RATE,
							].includes(option.key as BaseRateAdjustmentType)
						),
				)
				.map((option) => ({
					...option,
					label: t(`${path}.options.${option.key}`),
				})),
		[path, root, t],
	);

	const surchargeToOrderSumOptions = useMemo(
		() =>
			options.filter(
				(o) =>
					o.key === BaseRateAdjustmentType.SURCHARGE_TO_ORDER_SUM ||
					o.key === BaseRateAdjustmentType.COEFFICIENT_TO_ORDER_SUM,
			),
		[options],
	);

	return (
		<FieldsContainer label={t(`${path}.blockName`) || ""} gap="10px">
			<AdditionalPointCost
				value={additionalPointCost}
				onChange={setAdditionalPointCost}
			/>
			<StyledColumn w="max-content" gap="10px">
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="preliminarySurcharge"
					value={preliminarySurcharge}
					onChange={setPreliminarySurcharge}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={surchargeToOrderSumOptions}
					label="surchargeToOrderSum"
					value={surchargeToOrderSum}
					onChange={setSurchargeToOrderSum}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="pickupCostSurcharge"
					value={pickupCostSurcharge}
					onChange={setPickupCostSurcharge}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="onlineOrderSurchargeApp"
					value={onlineOrderSurchApp}
					onChange={setOnlineOrderSurchApp}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="onlineOrderSurchargeWebsite"
					value={onlineOrderWeb}
					onChange={setOnlineOrderhWeb}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="creditCardPaymentSurcharge"
					value={cardPaymentSurch}
					onChange={setCardPaymentSurch}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="invoicePaymentSurcharge"
					value={invoicePaymentSurch}
					onChange={setInvoicePaymentSurch}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="nonCashPaymentSurcharge"
					value={nonCashPaymentSurch}
					onChange={setNonCashPaymentSurch}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="terminalPaymentSurcharge"
					value={terminalSurcharge}
					onChange={setTerminalSurcharge}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="taximeterSurcharge"
					value={taximeterSurcharge}
					onChange={setTaximeterSurcharge}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="executorOrderTaximeterSurcharge"
					value={ownTaximSur}
					onChange={setOwnTaximSur}
				/>
				<BaseSurcharge
					disabled={disabled}
					options={options}
					label="executorOrderSurcharge"
					value={ownSurcharge}
					onChange={setOwnSurcharge}
				/>
			</StyledColumn>
			<PassengersSurcharge
				disabled={disabled}
				root={root}
				value={passengersSurcharge}
				onChange={setPassengersSurcharge}
			/>
		</FieldsContainer>
	);
});

declare namespace SurchargesBlock {
	interface Props {
		surcharges: Surcharges;
		onChangeSurcharges: (x: Partial<Surcharges>) => void;
		disabled?: boolean;
		root: boolean;
	}

	interface Surcharges {
		additionalPointCost: AdditionalPointCost.Value;
		preliminarySurcharge: BaseSurcharge.Value;
		surchargeToOrderSum: BaseSurcharge.Value;
		pickupCostSurcharge: BaseSurcharge.Value;
		onlineOrderSurchargeApp: BaseSurcharge.Value;
		onlineOrderSurchargeWebsite: BaseSurcharge.Value;
		creditCardPaymentSurcharge: BaseSurcharge.Value;
		invoicePaymentSurcharge: BaseSurcharge.Value;
		nonCashPaymentSurcharge: BaseSurcharge.Value;
		terminalPaymentSurcharge: BaseSurcharge.Value;
		taximeterSurcharge: BaseSurcharge.Value;
		executorOrderTaximeterSurcharge: BaseSurcharge.Value;
		executorOrderSurcharge: BaseSurcharge.Value;
		passengersSurcharge: PassengersSurcharge.Value;
	}
}

export default SurchargesBlock;
