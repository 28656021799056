/* eslint-disable no-shadow */
import React, { Dispatch } from "react";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import Language from "../../../../../../../../../../services/Language";

const List: React.FC<List.Props> = ({
	value,
	editing,
	language,
	onChange,
	onChangeEditing,
}) => (
	<Root gaps="2px" sizes="auto! 1fr auto!">
		<Header />
		<Content
			value={value}
			editing={editing}
			language={language}
			onChange={onChange}
			onChangeEditing={onChangeEditing}
		/>
	</Root>
);

declare namespace List {
	type Item = Content.Item;
	type Value = Item[];

	interface Props {
		value: Value;
		editing: boolean;

		language: Language;

		onChange: Dispatch<Value>;
		onChangeEditing: Dispatch<boolean>;
	}
}

export default List;
