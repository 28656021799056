import { Dispatch } from "react";
import { react } from "uikit";

import Component from ".";

interface Context {
	value: Component.Value;
	require: Component.Props["require"];
	handleError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const require = this.context?.require;
		const isValid = !!this.context?.value?.trim()?.length;

		if (!isValid && require) this.context?.handleError(true);

		return require ? isValid : true;
	}
}
