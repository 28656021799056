/* eslint-disable import/prefer-default-export */

import React from "react";

interface Props {
	fill: string;
}

export const CloseIcon: React.FC<Props> = ({ fill }) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.6562 4.28125L8.9375 8L12.6562 11.7188L11.7188 12.6562L8 8.9375L4.28125 12.6562L3.34375 11.7188L7.0625 8L3.34375 4.28125L4.28125 3.34375L8 7.0625L11.7188 3.34375L12.6562 4.28125Z"
			fill={fill}
		/>
	</svg>
);
