import React, { memo, DetailedHTMLProps, HTMLAttributes } from "react";

import { theme } from "uikit";
import styled from "styled-components";

const StyledMark = styled.mark`
	color: ${theme.colors.accent};
	background-color: transparent;
	font-weight: 400;
	padding: 0px 3px;
`;

const Mark: React.FC<Mark.Props> = ({ ...props }): JSX.Element => (
	<StyledMark {...props} />
);

declare namespace Mark {
	interface Props
		extends Omit<
			DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
			"LegacyRef" | "ref"
		> {}
}

export const MarkMemo = memo(Mark);
export default Mark;
