import React, { useMemo, lazy, Suspense, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import getClasses from "../../../../redux/services/Preferences/CarClass/getClasses";
import getCompanies from "../../../../redux/services/Company/getCompanies";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import {
	StyledRow,
	SuspenseLoader,
	SideTab,
} from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyExecutor = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./Executor" /* webpackChunkName: "mobile-app-executor-page" */
	);
	return elem;
});

const MobileApps: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	useLayoutEffect(() => {
		if (dispatch) {
			dispatch(getClasses());
			dispatch(getCompanies());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.EXECUTOR,
					label: t("settings.mobileApps.executor.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutor />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR],
				},
				{
					key: TabKeys.CLIENT,
					label: t("settings.mobileApps.client.title"),
					value: {
						render() {
							return (
								<Suspense
									fallback={
										<StyledRow
											position="absolute"
											top="50%"
											left="50%"
										>
											<SuspenseLoader />
										</StyledRow>
									}
								>
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								</Suspense>
							);
						},
					},
					accessName: TabAccessNames[TabKeys.CLIENT],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<SideTab
						value={activeKey}
						onChange={setActiveKey}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

export default MobileApps;
