import { getPRPC } from "../../../../hooks/usePRPC";
import BackendSettings from "../../../../types/BackendSettings";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import executorAppGeneral from "../../../reducers/settings/executorApp/general";
import executorAppInterfaceGeneral from "../../../reducers/settings/executorApp/interface/general";
import executorAppInterfaceOrders from "../../../reducers/settings/executorApp/interface/orders";
import executorAppInterfaceMap from "../../../reducers/settings/executorApp/interface/map";
import mapSettings from "../../../reducers/settings/map";
import settingState from "../../../reducers/settings/state";

export default function getGlobalSettings() {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow || !prpcow.theirsModel?.settings?.get) return null;

		const data: BackendSettings = await createRPCQuery(() =>
			prpcow.theirsModel.settings.get(),
		);

		console.log("[getGlobalSettings]:", data);

		dispatch(
			mapSettings.actions.setAll({
				...data.map.general,
				addressCacheExpirationMs:
					data.map.general.addressCacheExpirationMs * 24 * 3600e3,
			}),
		);

		dispatch(executorAppGeneral.actions.setAll(data.executorApp.general));

		const {
			displayLiveTab,
			separatePreliminaryOrders,
			menuItemsLayout,
			freeStatus,
			danger,

			filter: { display: displayFilter, ...filter },
			sort: { display: displaySort, ...sort },
			parking: { display: displayParking, ...parking },
			call: { display: displayCall, ...call },
			currentBalance: {
				display: displayCurrentBalance,
				...currentBalance
			},
			income: { display: displayIncome, ...income },
			taximeter: { display: displayTaximeter, ...taximeter },
			ownOrder: { display: displayOwnOrder, ...ownOrder },
			rating: { display: displayRating, ...rating },
			chat: { display: displayChat, ...chat },
		} = data.executorApp.orders;

		dispatch(
			executorAppInterfaceGeneral.actions.setAll({
				displayLiveTab,
				separatePreliminaryOrders,
				menuItemsLayout,
				freeStatus,
				danger,

				filter: {
					displaySelf: displayFilter,
					display: filter,
				},

				sort: {
					displaySelf: displaySort,
					display: sort,
				},

				parking: {
					displaySelf: displayParking,
					display: parking,
				},

				call: {
					displaySelf: displayCall,
					display: call,
				},

				currentBalance: {
					displaySelf: displayCurrentBalance,
					display: currentBalance,
				},

				income: {
					displaySelf: displayIncome,
					display: income,
				},

				taximeter: {
					displaySelf: displayTaximeter,
					display: taximeter,
				},

				ownOrder: {
					displaySelf: displayOwnOrder,
					display: ownOrder,
				},

				rating: {
					displaySelf: displayRating,
					display: rating,
				},

				chat: {
					displaySelf: displayChat,
					display: chat,
				},
			}),
		);

		dispatch(
			executorAppInterfaceOrders.actions.setAll({
				// In Live
				displayDistanceInLive:
					data.executorApp.orders.live.displayDistance,
				displayCostInLive: data.executorApp.orders.live.displayCost,
				displayRouteLengthInLive:
					data.executorApp.orders.live.displayRouteLength,
				displayAverageCostPerKmInLive:
					data.executorApp.orders.live.displayAverageCostPerKm,
				displayRideCountInLive:
					data.executorApp.orders.live.displayRideCount,
				displayVehicleClassInLive:
					data.executorApp.orders.live.displayVehicleClass,
				displayClientStatusInLive:
					data.executorApp.orders.live.displayClientStatus,
				displayOrderPointsInLive:
					data.executorApp.orders.live.displayOrderPoints,
				displaySectorsInLive:
					data.executorApp.orders.live.displaySectors,
				displayNotesInLive: data.executorApp.orders.live.displayNotes,
				displayServicesInLive:
					data.executorApp.orders.live.displayServices,

				// Required Order
				displayDistanceInRequiredOrder:
					data.executorApp.orders.required.displayDistance,
				displayCostInRequiredOrder:
					data.executorApp.orders.required.displayCost,
				displayRouteLengthInRequiredOrder:
					data.executorApp.orders.required.displayRouteLength,
				displayAverageCostPerKmInRequiredOrder:
					data.executorApp.orders.required.displayAverageCostPerKm,
				displayRideCountInRequiredOrder:
					data.executorApp.orders.required.displayRideCount,
				displayVehicleClassInRequiredOrder:
					data.executorApp.orders.required.displayVehicleClass,
				displayClientStatusInRequiredOrder:
					data.executorApp.orders.required.displayClientStatus,
				displayOrderPointsInRequiredOrder:
					data.executorApp.orders.required.displayOrderPoints,
				displaySectorsInRequiredOrder:
					data.executorApp.orders.required.displaySectors,
				displayNotesInRequiredOrder:
					data.executorApp.orders.required.displayNotes,
				displayServicesInRequiredOrder:
					data.executorApp.orders.required.displayServices,

				// In Progress
				displayDistanceInOrderInProgress:
					data.executorApp.orders.inProgress.displayDistance,
				displayCostInOrderInProgress:
					data.executorApp.orders.inProgress.displayCost,
				displayRouteLengthInOrderInProgress:
					data.executorApp.orders.inProgress.displayRouteLength,
				displayAverageCostPerKmInOrderInProgress:
					data.executorApp.orders.inProgress.displayAverageCostPerKm,
				displayRideCountInOrderInProgress:
					data.executorApp.orders.inProgress.displayRideCount,
				displayVehicleClassInOrderInProgress:
					data.executorApp.orders.inProgress.displayVehicleClass,
				displayClientStatusInOrderInProgress:
					data.executorApp.orders.inProgress.displayClientStatus,
				displayOrderPointsInOrderInProgress:
					data.executorApp.orders.inProgress.displayOrderPoints,
				displaySectorsInOrderInProgress:
					data.executorApp.orders.inProgress.displaySectors,
				displayNotesInOrderInProgress:
					data.executorApp.orders.inProgress.displayNotes,
				displayServicesInOrderInProgress:
					data.executorApp.orders.inProgress.displayServices,

				// Order From Exchange Server
				displayDistanceInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayDistance,
				displayCostInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayCost,
				displayRouteLengthInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayRouteLength,
				displayAverageCostPerKmInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer
						.displayAverageCostPerKm,
				displayRideCountInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayRideCount,
				displayVehicleClassInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayVehicleClass,
				displayClientStatusInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayClientStatus,
				displayOrderPointsInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayOrderPoints,
				displaySectorsInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displaySectors,
				displayNotesInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayNotes,
				displayServicesInOrderFromExchangeServer:
					data.executorApp.orders.exchangeServer.displayServices,
			}),
		);
		dispatch(executorAppInterfaceMap.actions.setAll(data.executorApp.map));

		dispatch(settingState.actions.setLoaded(true));

		return null;
	};
}
