import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import setClasses from "../../../actions/Preferences/Classes/setClass";

interface Props {
	limit?: number;
	sortType?: string;
}

export default function getClasses(props?: Props) {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		try {
			const data = props
				? await createRPCQuery(() =>
						prpcow?.theirsModel.carClass.getAll({
							limit: props?.limit,
						}),
				  )
				: await createRPCQuery(() =>
						prpcow?.theirsModel.carClass.getAll({}),
				  );

			if (!data) return null;
			return dispatch(setClasses(data?.items));
		} catch {
			return null;
		}
	};
}
