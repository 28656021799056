import React, { PropsWithChildren, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BaseElementIdEnum } from "../../interface";
import { StyledRow } from "../../styles";
import ButtonBase from "../../Button";
import Divider from "../../Divider";

const Button = styled(ButtonBase)`
	width: max-content;
	padding: 5px 10px;
	height: 32px;
`;

const Footer: React.FC<Footer.Props> = ({
	onSave,
	onClose,
	divider = true,
	textClose,
	textSave,
	children,
	buttonSave,
	buttonClose,
	hiddenButton = false,
	hiddenButtonClose = false,
}) => {
	const { t } = useTranslation();

	const save = useMemo(() => {
		if (textSave) return textSave;
		return t(["save", "Save"]) ?? "";
	}, [t, textSave]);

	const close = useMemo(() => {
		if (textClose) return textClose;
		return t(["cancel", "Cancel"]) ?? "";
	}, [t, textClose]);

	return (
		<Divider
			side={divider ? "top" : "none"}
			w="100%"
			h="52px"
			p="0 18px"
			bgC="#ffffff"
			br="0 0 10px 10px"
			over="hidden"
			alignItems="center"
			gap="10px"
			justify="space-between"
			flex={{ wrap: "nowrap", direction: "row" }}
		>
			<StyledRow
				gap="13px"
				justify="start"
				alignItems="center"
				flex={{ grow: 1 }}
			>
				{children}
			</StyledRow>

			{!hiddenButton && (
				<StyledRow gap="13px" justify="end" alignItems="center">
					{!hiddenButtonClose && (
						<Button
							id={BaseElementIdEnum.CLOSE}
							variant="secondary"
							onClick={onClose}
							css={`
								:focus {
									border-radius: 6px;
									border: 1px solid #21333f;
								}
							`}
							{...buttonClose}
						>
							{close}
						</Button>
					)}

					<Button
						id={BaseElementIdEnum.SAVE}
						variant={buttonSave?.disabled ? "secondary" : "primary"}
						onClick={onSave}
						autoFocus
						css={`
							:focus {
								border-radius: 6px;
								border: 1px solid #21333f;
							}
						`}
						{...buttonSave}
					>
						{save}
					</Button>
				</StyledRow>
			)}
		</Divider>
	);
};

declare namespace Footer {
	interface Props extends PropsWithChildren {
		onSave: (
			event?:
				| React.MouseEvent<HTMLButtonElement, MouseEvent>
				| KeyboardEvent,
		) => void;
		onClose: (
			event?:
				| React.MouseEvent<HTMLButtonElement, MouseEvent>
				| KeyboardEvent,
		) => void;
		divider?: boolean;
		textClose?: string;
		textSave?: string;
		buttonSave?: Partial<ButtonBase.Props>;
		buttonClose?: Partial<ButtonBase.Props>;
		hiddenButton?: boolean;
		hiddenButtonClose?: boolean;
	}
}

export const FooterMemo = memo(Footer);
export default Footer;
