import React, { Dispatch, useCallback, useState } from "react";
import { Icon } from "uikit";
import Root from "./components/Root";
import { NumberInput as NumberInputModal } from "../../../../../../Orders";

const EditButton: React.FC<EditButton.Props> = ({
	value,

	title,
	units,
	useMinSet = false,
	onSubmit,
}) => {
	const [showNumberInputModal, setShowNumberInputModal] = useState(false);

	const openNumberInputModal = useCallback(
		() => setShowNumberInputModal(true),
		[],
	);

	const numberInputModalOnClose = useCallback(
		() => setShowNumberInputModal(false),
		[],
	);

	const numberInputModalOnSubmit = useCallback(
		(value: NumberInputModal.Value) => {
			onSubmit(value);

			numberInputModalOnClose();
		},
		[numberInputModalOnClose, onSubmit],
	);

	return (
		<Root onClick={openNumberInputModal}>
			<Icon id="pencil" size={16} colors={["#647079"]} />
			{showNumberInputModal && (
				<NumberInputModal
					value={value}
					title={title}
					unit={units}
					onClose={numberInputModalOnClose}
					onSubmit={numberInputModalOnSubmit}
					useMinSet={useMinSet}
				/>
			)}
		</Root>
	);
};

declare namespace EditButton {
	interface Value {
		action: "add" | "set";
		value: number;
	}

	interface Props {
		value: Value;

		title: string;
		units: string;
		useMinSet?: boolean;

		onSubmit: Dispatch<Value>;
	}
}

export default EditButton;
