import makeTable from "./makeTable";

const vehiclePark = makeTable({
	allIds: ["active", "name", "taxiServices", "createdAt"],
	defaultIds: ["active", "name", "taxiServices", "createdAt"],
	initialWidths: {
		active: 90,
		name: 861,
		taxiServices: 430,
		createdAt: 150,
	},
	i18tPath: (id) => `tableColumns.vehicleParks.${id}`,
});

export default vehiclePark;
