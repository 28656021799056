import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";

// import Tariff from "../../../../../../../../../../../../../../services/Tariff";

import AutoClasses from ".";

interface Context {
	value: {
		currentValue: AutoClasses.Value;
		// defaultTariffWithAllCarClassesAndOneBranch?: Tariff.Model;
		// carClassesLength: number;
		// tariffs: Tariff.Model[];
		// taxiServiceIds: number[];
		// companyIds: number[];
	};

	setError: Dispatch<SetStateAction<string>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		let isValid = true;
		// const carClassIds = this.context?.value.currentValue;
		// const tariffs = this.context?.value.tariffs;

		const currentValueLength = this.context?.value.currentValue.length;
		// const maxValueLength = this.context?.value.carClassesLength;

		// const isPossiblePickAnyAmountIds =
		// 	!!this.context?.value.defaultTariffWithAllCarClassesAndOneBranch;

		// const companyIds = this.context?.value.companyIds;
		// const taxiServiceIds = this.context?.value.taxiServiceIds;

		// const existCompanyTaxiServicesCars = tariffs?.find((tariff) => {
		// 	const existCompanyIds = tariff.taxiServices.some((item) => {
		// 		if (!item.company) return false;
		// 		return companyIds?.includes(item.company.id);
		// 	});

		// 	const existTaxiServiceIds = tariff.taxiServiceIds.some((item) =>
		// 		taxiServiceIds?.includes(item),
		// 	);

		// 	const existСarClassIds = tariff.carClassIds?.some((item) => {
		// 		isPossiblePickAnyAmountIds;
		// 		return carClassIds?.includes(item);
		// 	});

		// 	const existСarClassIdsLength =
		// 		tariff.carClassIds?.length !== maxValueLength;

		// 	return [
		// 		existTaxiServiceIds,
		// 		existCompanyIds,
		// 		existСarClassIds,
		// 		existСarClassIdsLength,
		// 	].every((item) => item);
		// });

		// const existCompanyTaxiServices = tariffs?.find((tariff) => {
		// 	const existCompanyIds = tariff.taxiServices.some((item) => {
		// 		if (!item.company) return false;
		// 		return companyIds?.includes(item.company.id);
		// 	});
		// 	const existTaxiServiceIds = tariff.taxiServiceIds.some((item) =>
		// 		taxiServiceIds?.includes(item),
		// 	);

		// 	const existСarClassIds =
		// 		tariff.carClassIds?.length === maxValueLength;

		// 	const root = tariff.root !== true;
		// 	return [
		// 		existTaxiServiceIds,
		// 		existCompanyIds,
		// 		existСarClassIds,
		// 		root,
		// 	].every((item) => item);
		// });

		// const rootTariff = tariffs?.find((item) => item.root);

		if (currentValueLength === 0) {
			isValid = false;
			this.context?.setError("Поле обязательное");
		}

		return isValid;
	}
}
