import Modal from ".";

export const TEMPLATE_MISSING = 0;

const defaultValue: Modal.Value = {
	contacts: [],
	data: { smsTemplateId: TEMPLATE_MISSING },
};

export default defaultValue;
