/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { ParkingFilter, State } from "./interface";

const initialState: State = {
	criteria: {
		companyIds: [],
		taxiServiceIds: [],
		statuses: [],
		online: [],
		workingStatus: [],
		isYourOrderStatus: false,
		taxiServiceIdsPosition: [],
		query: "",
	},
	sort: {
		column: "",
		type: "asc",
	},
};

const setParkingsQuery: ReduxReducer<State, string> = (state, action) => {
	state.criteria.query = action.payload;
};

const setParkingsPosition: ReduxReducer<State, number[]> = (state, action) => {
	state.criteria.taxiServiceIdsPosition = action.payload;
};

const setParkingsFilter: ReduxReducer<State, ParkingFilter> = (
	state,
	action,
) => {
	state.criteria = action.payload;
};

const setParkingsSort: ReduxReducer<State, State["sort"]> = (state, action) => {
	state.sort = action.payload;
};

const orderParkingFilters = createSlice({
	name: Orders.ConstantOrderSlice.CHAT_FILTERS,
	initialState,
	reducers: {
		setParkingsSort,
		setParkingsQuery,
		setParkingsPosition,
		setParkingsFilter,
	},
});

export const orderParkingFiltersAction = orderParkingFilters.actions;
export const orderParkingFiltersReducer = orderParkingFilters.reducer;
export type orderParkingFiltersState = State;

export default orderParkingFilters;
