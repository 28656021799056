/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import { Dispatch, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { defaults, isUndefined } from "lodash";

import KeyBinder from "../services/KeyBinder";
import KeyBindLayer from "../components/KeyBindLayer";

const defaultOptions: Partial<KeyBinder.State.Listener.Options> = {
	preventDefault: false,
	stopPropagation: false,
};

function useKeyBind(
	bind?: KeyBinder.KeyBind | undefined | null,
	handler?: Dispatch<KeyboardEvent>,
	options?: KeyBinder.State.Listener.Options,
) {
	options = defaults({}, options ?? {}, defaultOptions);

	const [id] = useState(uuid());

	const currentKeyBindLayer = useContext(KeyBindLayer.Context);

	const priority = useMemo(() => {
		if (currentKeyBindLayer) {
			const highestListenerPriority =
				KeyBinder.getHighestListenerPriority(
					currentKeyBindLayer.layerId,
				);

			if (isUndefined(highestListenerPriority)) return 0;

			return highestListenerPriority + 1;
		}

		return 0;
	}, [currentKeyBindLayer]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => KeyBinder.unregisterListener(id), []);

	KeyBinder.registerListener(id, {
		layerId: currentKeyBindLayer?.layerId ?? "",

		priority,
		bind,
		handler,
		options,
	});

	return id;
}

export default useKeyBind;
