import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

export const translationTablePath = "tableColumns.settings.parameters";

const getColumns = (t: TFunction): Column[] => [
	{
		id: "active",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str150",
			) ?? "",
	},
	{
		id: "name",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str151",
			) ?? "",
	},
	{
		id: "assigns",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str152",
			) ?? "",
	},
	{
		id: "taxiServices",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str153",
			) ?? "",
	},
	{
		id: "createdAt",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str154",
			) ?? "",
	},
];

const defaultColumnIds = [
	"active",
	"name",
	"assigns",
	"taxiServices",
	"createdAt",
];

export { defaultColumnIds, getColumns };
