import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<Root.Props>`
	width: 100%;

	padding: 16px 5px;

	cursor: ${({ disabled = false }) => (disabled ? "not-allowed" : "pointer")};

	&:not(:last-child) {
		border-bottom: 1px solid rgb(200, 207, 214);
	}

	&:hover {
		background: ${theme.colors.button_secondary};
	}
`;

declare namespace Root {
	interface Props {
		disabled?: boolean;
	}
}

export default Root;
