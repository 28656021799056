import React from "react";
import { Column, ColumnId } from ".";
import Content from "../components/OrderPriceCellContent";
import base from "./base";

function orderPrice({ width, onResize }: Column.Options) {
	return base(ColumnId.OrderPrice, (item) => <Content item={item} />, {
		width,

		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default orderPrice;
