import { RefObject } from "react";
import { react } from "uikit";

import TaxiServiceForm from "../../../../../../../../../../components/TaxiServiceForm";

interface Context {
	taxiServiceFormRef: RefObject<TaxiServiceForm.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isTaxiServiceFormValid =
			this.context?.taxiServiceFormRef.current?.validate();

		return isTaxiServiceFormValid;
	}
}
