import Order from "../../../../../../services/Order";

const smsStatusColors: Record<Order.OrderSMSMessageStatus, string> = {
	sent: "#03A9F4B0",
	failure: "#F44336",
	progress: "#FFC107",
	success: "#4CAF50",
};

export default smsStatusColors;
