import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";

interface Props {
	limit?: number;
	offset?: number;
	sortType?: string;
	sortColumn?: string;
	taxiServiceIds?: number[];
}

export default function returnServices(props?: Props) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;
		return createRPCQuery(() =>
			prpcow?.theirsModel.service.getAll({
				offset: props?.offset || 0,
				limit: props?.limit || 100,
				order: {
					[props?.sortColumn || "id"]: props?.sortType?.toUpperCase(),
				},
				taxiServiceIds: props?.taxiServiceIds || [],
			}),
		).then((data) => data?.items);
	};
}
