import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import Language from "../../../../../../../services/Language";

const ServicesCellContent: React.FC<ServicesCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(
		() =>
			item.orderToServices
				?.map(
					(orderToService) =>
						orderToService?.service?.name?.[language],
				)
				.join(", ") ?? "",
		[item.orderToServices, language],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ServicesCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default ServicesCellContent;
