import React, { memo, useCallback } from "react";

import Badge from "../Badge";

const ToggleBadge: React.FC<ToggleBadge.Props> = ({
	value,
	onChange,
	disabled = false,
	...props
}) => {
	const handleOnClick = useCallback(() => {
		if (disabled) return;
		onChange(!value);
	}, [disabled, onChange, value]);

	return (
		<Badge
			active={value}
			onClick={handleOnClick}
			disabled={disabled}
			{...props}
		/>
	);
};

declare namespace ToggleBadge {
	interface Props extends Omit<Badge.Props, "active" | "onClick"> {
		value?: boolean;
		onChange: (value: boolean) => void;
		disabled?: boolean;
	}
}

export const ToggleBadgeMemo = memo(ToggleBadge);
export default ToggleBadge;
