import { useCallback, useMemo } from "react";
import { LatLngLiteral } from "leaflet";
import { useInternal } from "uikit";

import getSectorByPoint from "../../../../../../../redux/services/Sector/getSectorByPoint";
import { IOrderPoint } from "../../../../../../../redux/constants/OrdersPage/order";

export type Sector = IOrderPoint["sector"];
export interface UseGetSector {
	response: Sector;
	get: (coordinates: LatLngLiteral) => Promise<Sector>;
	reset: () => void;
}

export type UseGetSectorProps = () => UseGetSector;

/**
 *  `useGetSector`
 */
export const useGetSector: UseGetSectorProps = () => {
	const [response, setResponse] = useInternal<Sector>(undefined);

	const get = useCallback<UseGetSector["get"]>(
		async (coordinates) => {
			const res = await getSectorByPoint(coordinates);
			setResponse(res);
			return res;
		},
		[setResponse],
	);

	const reset = useCallback(() => {
		setResponse(undefined);
	}, [setResponse]);

	return useMemo<UseGetSector>(
		() => ({
			response,
			reset,
			get,
		}),
		[response, reset, get],
	);
};
