import getValidCityName from "../../../../../../../../../../../../../utils/getValidCityName";
import processAddressPath from "../../../functions/processAddressPath";

function objectToLabel(settlement: objectToLabel.Settlement) {
	const settlementName = getValidCityName(
		settlement.settlementType,
		settlement.settlement,
	);

	return `${
		settlementName === " " ||
		!settlement.settlementType ||
		!settlement.settlement
			? ""
			: settlementName
	}${processAddressPath(settlement.district)}${processAddressPath(
		settlement.region,
	)}`.trim();
}

declare namespace objectToLabel {
	interface Settlement {
		settlementType?: string;
		settlement?: string;
		district?: string;
		region?: string;
	}
}

export default objectToLabel;
