/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Customers } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	counterparty: [],
};

const setCounterparties: ReduxReducer<State, State["counterparty"]> = (
	state,
	{ payload },
) => {
	state.counterparty = payload;
};

const addCounterparty: ReduxReducer<State, State["counterparty"][0]> = (
	state,
	{ payload },
) => {
	state.counterparty = [...state.counterparty, payload];
};

const updateCounterparty: ReduxReducer<State, State["counterparty"][0]> = (
	state,
	{ payload },
) => {
	state.counterparty = state.counterparty.map((item) => {
		if (item.value === payload.value) return payload;
		return item;
	});
};

const removeCounterparty: ReduxReducer<
	State,
	State["counterparty"][0]["value"]
> = (state, { payload }) => {
	state.counterparty = state.counterparty.filter((item) => {
		if (item.value !== payload) return payload;
		return item;
	});
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const data = createSlice({
	name: Customers.ConstantCustomersSlice.COUNTERPARTY,
	initialState,
	reducers: {
		reset,
		setCounterparties,
		addCounterparty,
		updateCounterparty,
		removeCounterparty,
	},
});

export const dataAction = data.actions;
export const dataReducer = data.reducer;
export type dataState = State;

export default data;
