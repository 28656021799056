import React, { useMemo } from "react";
import { CheckBox } from "uikit";

import CarClass from "../../../../../../../../../../services/CarClass";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { StyledRow } from "../../../../../../../../../../components/common";

const AvailableForOnlineOrderingCellContent: React.FC<
	AvailableForOnlineOrderingCellContent.Props
> = ({ item }) => {
	const active = useMemo(() => item.availableForOnlineOrdering, [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<StyledRow>
				<CheckBox value={active} disabled />
			</StyledRow>
		</CellContentRoot>
	);
};

declare namespace AvailableForOnlineOrderingCellContent {
	interface Props {
		item: CarClass.Model;
	}
}

export default AvailableForOnlineOrderingCellContent;
