import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";
import InternalController from "./Controller";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../../../../../redux/store";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import getCompanies from "../../../../../../../../../../../../../../redux/services/Company/getCompanies";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import tPath from "../../../../../../../../constants/tPath";

const CompaniesAndTaxiServicesBase = react.withController<
	CompaniesAndTaxiServices.PropsBase,
	CompaniesAndTaxiServices.Controller
>(({ value, onChange, controller }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");
	const [taxiServicesError, setTaxiServicesError] = useState(false);

	const taxiServices = valueEditor.get("taxiServices");

	const [selectedCompanies, setSelectedCompanies] = useState<number[]>();
	const [companiesError, setCompaniesError] = useState(false);
	useEffect(() => {
		const newCompanies = taxiServices?.map(
			(service) => service.company?.id,
		);
		const newSelectedCompanies = new Set(newCompanies);
		setSelectedCompanies(Array.from(newSelectedCompanies) as number[]);
	}, [taxiServices]);

	const { companies } = useTypedSelector((state) => state.ordersPageReducer);
	const dispatch = useTypedDispatch();
	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const selectOptionsWrap = useCallback(
		(item: { id: number; label: string }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);
	const companiesOptions = useMemo(
		() =>
			companies.items.map((company) =>
				selectOptionsWrap({
					id: company.id,
					label: company.name[lang],
				}),
			),
		[companies.items, lang, selectOptionsWrap],
	);
	const taxiServiceOptions = useMemo(
		() =>
			companies.items
				.filter(({ id }) => selectedCompanies?.includes(id))
				.map((company) =>
					company.taxiServices.map((service) =>
						selectOptionsWrap({
							id: service.id,
							label: service?.settlement[lang],
						}),
					),
				)
				.flat(),
		[companies.items, lang, selectOptionsWrap, selectedCompanies],
	);

	const updateSelectedValues = useCallback(
		(newCompanies) => {
			setCompaniesError(false);
			setSelectedCompanies(newCompanies);
			const newTaxiServiceIds = taxiServiceIds.filter((serviceId) => {
				const serviceCompany = companies.items.find((company) =>
					company.taxiServices.some(
						(service) => service.id === serviceId,
					),
				);
				return (
					serviceCompany && newCompanies.includes(serviceCompany.id)
				);
			});
			setTaxiServiceIds(newTaxiServiceIds);
		},
		[companies.items, taxiServiceIds, setTaxiServiceIds],
	);

	controller.setContext({
		value: { ...value, selectedCompanies },
		setCompaniesError,
		setTaxiServicesError,
	});

	return (
		<>
			<LabeledField
				label={t(`${tPath}.modal.tabs.main.company.title`) || ""}
			>
				<MultiSelect
					error={companiesError}
					all
					placeholder={
						t(`${tPath}.modal.tabs.main.company.choose`) || ""
					}
					value={selectedCompanies}
					options={companiesOptions}
					onChange={updateSelectedValues}
				/>
			</LabeledField>
			<LabeledField
				label={t(`${tPath}.modal.tabs.main.branch.title`) || ""}
			>
				<MultiSelect
					all
					placeholder={
						t(`${tPath}.modal.tabs.main.branch.choose`) || ""
					}
					value={taxiServiceIds}
					disabled={taxiServiceOptions.length === 0}
					error={taxiServicesError}
					options={
						taxiServiceOptions.length === 0
							? undefined
							: taxiServiceOptions
					}
					onChange={(ids) => {
						setTaxiServicesError(false);
						setTaxiServiceIds(ids as number[]);
					}}
				/>
			</LabeledField>
		</>
	);
}, InternalController);

const CompaniesAndTaxiServices = memo(CompaniesAndTaxiServicesBase);

declare namespace CompaniesAndTaxiServices {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
	}
}

export default CompaniesAndTaxiServices;
