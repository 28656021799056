import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	padding: 16px;

	border-radius: 5px;

	background: #f5f5f5;
`;

export default Root;
