import { getPRPC } from "../hooks/usePRPC";
import ApplicationConfig from "../types/ApplicationConfig";

import createRPCQuery from "./createRPCQuery.util";

async function getApplicationConfig() {
	const prpc = getPRPC();

	if (!prpc) return null;

	return createRPCQuery(() =>
		prpc.theirsModel.utils.getApplicationConfig(),
	) as Promise<ApplicationConfig>;
}

export default getApplicationConfig;
