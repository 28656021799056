import makeTable from "./makeTable";

const accountingBankAccountsFirstTable = makeTable({
	allIds: [
		"gosRegNumber",
		"organizationName",
		"accountNumber",
		"tariff",
		"balance",
		"disconnectionThreshold",
		"company",
	],
	i18tPath: (id) => `mainPage.accounting.bankAccounts.columns.${id}`,
	defaultIds: [
		"gosRegNumber",
		"organizationName",
		"accountNumber",
		"tariff",
		"balance",
		"disconnectionThreshold",
		"company",
	],
	initialSortMethod: {
		column: "organizationName",
		type: "asc",
	},
	initialWidths: {
		gosRegNumber: 200,
		organizationName: 200,
		accountNumber: 200,
		tariff: 200,
		balance: 200,
		disconnectionThreshold: 200,
		company: 200,
	},
	initialResizable: {
		gosRegNumber: true,
		organizationName: true,
		accountNumber: true,
		tariff: true,
		balance: true,
		disconnectionThreshold: true,
		company: true,
	},
	initialSortable: {
		gosRegNumber: true,
		organizationName: true,
		accountNumber: true,
		tariff: true,
		balance: true,
		disconnectionThreshold: true,
		company: true,
	},
});

export default accountingBankAccountsFirstTable;
