import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";
import Name from ".";

interface Context {
	value: Name.Props["name"];
	setError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = !!this.context?.value.trim().length;

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
