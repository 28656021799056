import React, { useMemo } from "react";

import Order, { OrderType } from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CustomerFullNameCellContent: React.FC<
	CustomerFullNameCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => {
		if (item.passengers.length) {
			const person =
				item.type === OrderType.CUSTOMER_SPONSORED
					? item.billingCustomer?.person
					: item.passengers?.[0]?.customer?.person;

			const name = person?.name || "";
			const surname = person?.surname || "";
			const fatherName = person?.fatherName || "";
			const fullName = `${surname || ""} ${name || ""} ${
				fatherName || ""
			}`.trim();

			return fullName || "";
		}

		return "";
	}, [item.billingCustomer?.person, item.passengers, item.type]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CustomerFullNameCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default CustomerFullNameCellContent;
