import React, { memo, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import LabeledContent from "../LabeledContent";
import TaxiServiceService from "../../../../../../services/TaxiService2";
import { useTypedSelector } from "../../../../../../redux/store";
import { sessionAction } from "../../../../../../redux/reducers/session";

const TaxiService = memo(() => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);
	const defaultTaxiServiceId = useTypedSelector(
		(state) => state.session.defaultTaxiServiceId,
	);
	const user = useTypedSelector((state) => state.account.user);

	const defaultTaxiService = useMemo(
		() =>
			user?.defaultTaxiService
				? TaxiServiceService.fromResponse(user?.defaultTaxiService)
				: null,
		[user?.defaultTaxiService],
	);

	useLayoutEffect(() => {
		if (
			defaultTaxiService &&
			defaultTaxiService?.id !== defaultTaxiServiceId
		) {
			dispatch(
				sessionAction.setDefaultTaxiServiceId(
					defaultTaxiService?.id || null,
				),
			);
		}
	}, [defaultTaxiService, defaultTaxiServiceId, dispatch]);

	const company = useMemo(
		() => defaultTaxiService?.company?.name[settingsLanguage],
		[defaultTaxiService?.company?.name, settingsLanguage],
	);

	const taxiService = useMemo(
		() => defaultTaxiService?.settlement[settingsLanguage],
		[defaultTaxiService?.settlement, settingsLanguage],
	);

	const content = useMemo(
		() => (company && taxiService ? `${company}, ${taxiService}` : "-"),
		[company, taxiService],
	);

	return (
		<LabeledContent width={180} label={t("main_header.taxiService")}>
			{content}
		</LabeledContent>
	);
});

export default TaxiService;
