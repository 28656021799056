import styled from "styled-components";
import { Row } from "uikit";

const InformationContainer = styled(Row)`
	height: 50px;

	padding: 15px 24px 10px;

	box-sizing: content-box;
`;

export default InformationContainer;
