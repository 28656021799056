import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInternal } from "uikit";

import InputModal from "../InputModal";

import Root from "./components/Root";
import OrderController from "./components/OrderController";
import Selector from "./components/Selector";

const ColumnSetupModal: React.FC<ColumnSetupModal.Props> = ({
	value,
	columns,
	disabledColumns = [],
	onChange,
	onClose,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const defaultValue = useMemo(() => [], []);
	const defaultColumns = useMemo(() => [], []);

	const [internalValue, setInternalValue] = useInternal(
		value ?? defaultValue,
	);

	const internalColumns = useMemo(
		() => columns ?? defaultColumns,
		[columns, defaultColumns],
	);

	const internalOnChange = useMemo(
		() => onChange ?? setInternalValue,
		[onChange, setInternalValue],
	);

	const content = useMemo(
		() => (
			<Root sizes="1fr*" gaps="4px">
				<Selector
					value={internalValue}
					items={internalColumns}
					onChange={internalOnChange}
					disabledItems={disabledColumns}
				/>
				<OrderController
					value={internalValue}
					items={internalColumns}
					onChange={internalOnChange}
				/>
			</Root>
		),
		[internalColumns, internalOnChange, internalValue, disabledColumns],
	);

	const inputModalOnClose = useCallback(() => onClose?.(), [onClose]);

	const inputModalOnSubmit = useCallback(
		() => internalValue && onSubmit?.(internalValue),
		[onSubmit, internalValue],
	);

	return (
		<InputModal
			title={t("columnSetupModal.str0") ?? ""}
			onClose={inputModalOnClose}
			onSubmit={inputModalOnSubmit}
		>
			{content}
		</InputModal>
	);
};

declare namespace ColumnSetupModal {
	interface Column {
		id: string;
		label: string;
	}

	type Value = string[];

	interface Props {
		value?: Value;
		columns?: Column[];
		disabledColumns?: Column["id"][];
		onChange?: Dispatch<Value>;
		onClose?: () => void;
		onSubmit?: (value: Value) => void;
	}
}

export default ColumnSetupModal;
