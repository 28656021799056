import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Preferences } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	query: "",
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	isSortMap: false,
};

const setIsSortMap: ReduxReducer<State, State["isSortMap"]> = (
	state,
	{ payload },
) => {
	state.isSortMap = payload;
};

const setQuery: ReduxReducer<State, State["query"]> = (state, { payload }) => {
	state.query = payload;
};

const setCompanyIds: ReduxReducer<State, State["companyIds"]> = (
	state,
	{ payload },
) => {
	state.companyIds = payload;
};

const setTaxiServiceIds: ReduxReducer<State, State["taxiServiceIds"]> = (
	state,
	{ payload },
) => {
	state.taxiServiceIds = payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const filters = createSlice({
	name: Preferences.ConstantPreferencesSlice.SERVICES_FILTERS,
	initialState,
	reducers: {
		reset,
		setQuery,
		setCompanyIds,
		setTaxiServiceIds,
		setIsSortMap,
	},
});

export const filtersAction = filters.actions;
export const filtersReducer = filters.reducer;
export type filtersState = State;

export default filters;
