import React, { useMemo } from "react";
import { Icon } from "uikit";
import styled from "styled-components";

import { useTypedSelector } from "../../../../../../../redux/store";
import { StyledRow } from "../../../../../../../components/common";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../access";
import Button from "../Button";

const Row = styled(StyledRow)`
	align-items: center;
`;

const ControlButtons: React.FC<ControlButtons.Props> = ({
	canAdd = true,
	canEdit,
	canDelete,
	onAdd,
	onEdit,
	onDelete,
	accessName,
}) => {
	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_IS_ALLOWED_BY_DEFAULT = true;

		const retval = {
			add: accessName
				? hasAccess(
						generateAccessName(
							...accessName,
							AccessKey.CONTROL_BUTTON_ADD,
						),
						personalRole,
				  )
				: ACCESS_IS_ALLOWED_BY_DEFAULT,
			edit: accessName
				? hasAccess(
						generateAccessName(
							...accessName,
							AccessKey.CONTROL_BUTTON_EDIT,
						),
						personalRole,
				  )
				: ACCESS_IS_ALLOWED_BY_DEFAULT,
			delete: accessName
				? hasAccess(
						generateAccessName(
							...accessName,
							AccessKey.CONTROL_BUTTON_DELETE,
						),
						personalRole,
				  )
				: ACCESS_IS_ALLOWED_BY_DEFAULT,
		};

		return retval;
	}, [accessName, personalRole]);

	return (
		<Row gap="10px">
			{AccessBtnGroup.add && (
				<Button
					variant="primary"
					icon={<Icon id="plus" size={16} />}
					disabled={!canAdd}
					onClick={onAdd}
				/>
			)}
			{AccessBtnGroup.edit && (
				<Button
					variant="secondary"
					icon={<Icon id="pencil" size={20} />}
					disabled={!canEdit}
					onClick={onEdit}
				/>
			)}
			{AccessBtnGroup.delete && (
				<Button
					variant="secondary"
					icon={<Icon id="trash" size={20} />}
					disabled={!canDelete}
					onClick={onDelete}
				/>
			)}
		</Row>
	);
};

declare namespace ControlButtons {
	interface Props {
		canAdd?: boolean;
		canEdit: boolean;
		canDelete: boolean;

		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;

		accessName?: AccessKey[];
	}
}

export default ControlButtons;
