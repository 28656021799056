import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Row, Button, CheckBoxWithContent } from "uikit";
import Root from "./components/Root";

const Footer: React.FC<Footer.Props> = ({
	value,
	canCancel,
	canSave,
	onChange,
	onCancel,
	onSave,
}) => {
	const { t } = useTranslation();
	const showAllCheckBoxOnChange = useCallback(
		(showOnlySelectedTaxiServices: boolean) => {
			onChange({
				...value,

				showOnlyFiltered: showOnlySelectedTaxiServices,
			});
		},
		[onChange, value],
	);

	return (
		<Root align="center" justify="space-between">
			<CheckBoxWithContent
				gap="10px"
				value={value.showOnlyFiltered}
				onChange={showAllCheckBoxOnChange}
			>
				{t(
					"pages.preferencesPages.screenDirectory.parkings.content.editor.footer.str150",
				) ?? ""}
			</CheckBoxWithContent>
			<Row gaps="16px">
				<Button.Button
					style={{ padding: "9px 25px", height: "36px" }}
					disabled={!canCancel}
					variant="secondary"
					text={
						t(
							"pages.preferencesPages.screenDirectory.parkings.content.editor.footer.str0",
						) ?? ""
					}
					onClick={onCancel}
				/>
				<Button.Button
					style={{ padding: "9px 25px", height: "36px" }}
					disabled={!canSave}
					text={
						t(
							"pages.preferencesPages.screenDirectory.parkings.content.editor.footer.str1",
						) ?? ""
					}
					onClick={onSave}
				/>
			</Row>
		</Root>
	);
};

declare namespace Footer {
	interface Value {
		showOnlyFiltered: boolean;
	}

	interface Props {
		value: Value;

		canCancel: boolean;
		canSave: boolean;

		onChange: Dispatch<Value>;

		onCancel: () => void;
		onSave: () => void;
	}
}

export default Footer;
