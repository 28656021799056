import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Icon, theme } from "uikit";
import { Draggable } from "react-beautiful-dnd";

import ExecutorRateCommissionPlan from "../../../../../../../../../../../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
import {
	useObjectEditor,
	useCurrencyGlobalSettings,
} from "../../../../../../../../../../../../../../../../../../../../../../hooks";
import ComparisonSelect from "../../../../../../../../../../../ComparisonSelect";
import {
	StyledRow,
	ValueStepper,
	StyledGrid,
	StyledColumn,
} from "../../../../../../../../../../../../../../../../../../../../../../components/common";

import DraggableArea from "./components/DraggableArea";
import Root from "./components/Root";

const ItemBase: React.FC<Item.Props> = ({
	value,
	disabled = false,
	id,
	index,
	label,
	onChange,
}) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const valueEditor = useObjectEditor(value, onChange);

	const percentValue = valueEditor.useGetter("percent");
	const percentOnChange = valueEditor.useSetter("percent");

	const amountValue = valueEditor.useGetter("amount");
	const amountOnChange = valueEditor.useSetter("amount");

	const conditionEditor = valueEditor.usePropertyEditor("condition");

	const conditionActiveValue = conditionEditor.useGetter("active");
	const conditionActiveOnChange = conditionEditor.useSetter("active");

	const conditionTypeValue = conditionEditor.useGetter("type");
	const conditionTypeOnChange = conditionEditor.useSetter("type");

	const conditionValue = conditionEditor.useGetter("value");
	const conditionValueOnChange = conditionEditor.useSetter("value");

	const conditionPercentValue = conditionEditor.useGetter("percent");
	const conditionPercentOnChange = conditionEditor.useSetter("percent");

	const conditionAmountValue = conditionEditor.useGetter("amount");
	const conditionAmountOnChange = conditionEditor.useSetter("amount");

	return (
		<Draggable key={id} draggableId={id} index={index}>
			{(provided) => (
				<Root
					ref={provided.innerRef}
					{...provided.draggableProps}
					label={label}
				>
					<StyledGrid
						areas=""
						alignItems="center"
						gap="10px"
						columns="auto 1fr"
					>
						<DraggableArea
							{...provided.dragHandleProps}
							tabIndex={undefined}
						>
							<Icon
								id="dragging"
								colors={[theme.colors.disabled_text]}
								size={12}
							/>
						</DraggableArea>
						<StyledColumn gap="10px">
							<StyledRow
								alignItems="center"
								gap="10px"
								justify="space-between"
							>
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.commissions.list.item.str100",
									) ?? ""}
								</span>
								<StyledRow alignItems="center" gap="10px">
									<StyledRow alignItems="center" gap="5px">
										<ValueStepper
											value={percentValue}
											disabled={disabled}
											decimalCount={2}
											min={0}
											onChange={percentOnChange}
										/>
										<span>%</span>
									</StyledRow>
									<StyledRow alignItems="center" gap="5px">
										<ValueStepper
											value={amountValue}
											disabled={disabled}
											decimalCount={2}
											min={0}
											onChange={amountOnChange}
										/>
										<span>{currencyGlobalSettings}</span>
									</StyledRow>
								</StyledRow>
							</StyledRow>
							<StyledRow
								alignItems="center"
								gap="10px"
								justify="space-between"
							>
								<StyledRow alignItems="center" gap="10px">
									<CheckBoxWithContent
										value={conditionActiveValue}
										disabled={disabled}
										gap="10px"
										onChange={conditionActiveOnChange}
									>
										<span>
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.commissions.list.item.str102",
											) ?? ""}
										</span>
									</CheckBoxWithContent>
									<ComparisonSelect
										value={conditionTypeValue}
										disabled={
											disabled || !conditionActiveValue
										}
										onChange={
											conditionTypeOnChange as Dispatch<ComparisonSelect.Value>
										}
									/>
									<StyledRow alignItems="center" gap="5px">
										<ValueStepper
											value={conditionValue}
											disabled={
												disabled ||
												!conditionActiveValue
											}
											decimalCount={2}
											min={0}
											onChange={conditionValueOnChange}
										/>

										<span>{currencyGlobalSettings}</span>
									</StyledRow>
								</StyledRow>
								<StyledRow alignItems="center" gap="10px">
									<StyledRow alignItems="center" gap="5px">
										<ValueStepper
											value={conditionPercentValue}
											disabled={
												disabled ||
												!conditionActiveValue
											}
											decimalCount={2}
											min={0}
											onChange={conditionPercentOnChange}
										/>
										<span>%</span>
									</StyledRow>
									<StyledRow alignItems="center" gap="5px">
										<ValueStepper
											value={conditionAmountValue}
											disabled={
												disabled ||
												!conditionActiveValue
											}
											decimalCount={2}
											min={0}
											onChange={conditionAmountOnChange}
										/>
										<span>{currencyGlobalSettings}</span>
									</StyledRow>
								</StyledRow>
							</StyledRow>
						</StyledColumn>
					</StyledGrid>
				</Root>
			)}
		</Draggable>
	);
};

const Item = memo(ItemBase);

declare namespace Item {
	interface Value {
		percent: number;
		amount: number;

		condition: Value.Condition;
	}

	interface Props {
		value: Value;

		disabled?: boolean;
		id: string;
		index: number;
		label: string;

		onChange: Dispatch<Value>;
	}

	namespace Value {
		interface Condition {
			active?: boolean;

			type?: ExecutorRateCommissionPlan.Model.Rate.Commission.Threshold.Comparison.Direction;
			value?: number;
			percent: number;
			amount: number;
		}
	}
}

export default Item;
