import React, { Dispatch, RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Row, Stepper, react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import InternalController from "./Controller";

const AnotherDataBase = react.withController<
	AnotherData.PropsBase,
	AnotherData.Controller
>(({ controller, value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);
	const { t } = useTranslation();

	const isMaxSmsPerSecond = valueEditor.useGetter("isMaxSmsPerSecond");
	const isMaxSmsPerSecondOnChange =
		valueEditor.useSetter("isMaxSmsPerSecond");

	const maxSmsPerSecond = valueEditor.useGetter("maxSmsPerSecond");
	const setMaxSmsPerSecond = valueEditor.useSetter("maxSmsPerSecond");

	const addPlusValue = valueEditor.useGetter("addPlus");
	const addPlusOnChange = valueEditor.useSetter("addPlus");

	return (
		<>
			<Row gaps="10px*5" align="center">
				<CheckBox
					value={isMaxSmsPerSecond}
					onChange={isMaxSmsPerSecondOnChange}
					label={
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.anotherData.str0",
						) ?? ""
					}
				/>
				<Stepper
					onChange={(newValue) => {
						if (newValue != null) setMaxSmsPerSecond(newValue);
					}}
					value={maxSmsPerSecond}
					min={0}
				/>
				<span>
					{t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.anotherData.str200",
					) ?? ""}
				</span>
			</Row>
			<Row>
				<CheckBox
					value={addPlusValue}
					onChange={addPlusOnChange}
					label={
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.anotherData.str202",
						) ?? ""
					}
				/>
			</Row>
		</>
	);
}, InternalController);

const AnotherData = memo(AnotherDataBase);

declare namespace AnotherData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		addPlus: boolean;
		maxSmsPerSecond: number;
		isMaxSmsPerSecond: boolean;
	}

	interface PropsBase {
		value: Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default AnotherData;
