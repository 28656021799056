import React, { Dispatch, RefAttributes, memo } from "react";
import { Column, Row, react, useRefWithSetter } from "uikit";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import PaymentSystem, {
	PaymentProvider,
} from "../../../../../../../../../services/PaymentSystem";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import translationPath from "../../../../../constants/translationPath";

import CompaniesAndTaxiServices from "../../../../../components/CompaniesAndTaxiServices";
import SystemFee from "../../../../../components/SystemFee";
import AssetPayment from "../../../../../components/AssetPayment";
import FondyPayment from "../../../../../components/FondyPayment";
import Name from "../../../../../components/Name";
import Root from "../../../../../components/Root";

import { destinationAppOptions } from "./constants/destinationAppOptions";
import InternalController from "./Controller";
import SelectPaymentSystems from "../../../../../components/SelectPaymentSystems";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ value, onChange, controller }) => {
		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);
		const [companyBranchRef, setCompanyBranchRef] =
			useRefWithSetter<CompaniesAndTaxiServices.Ref | null>(null);
		const [assetPaymentRef, setAssetPaymentRef] =
			useRefWithSetter<AssetPayment.Ref | null>(null);
		const [fondyPaymentRef, setFondyPaymentRef] =
			useRefWithSetter<FondyPayment.Ref | null>(null);
		const [paymentSystemRef, setPaymentSystemFeeRef] =
			useRefWithSetter<SelectPaymentSystems.Ref | null>(null);
		const [systemFeeRef, setSystemFeeRef] =
			useRefWithSetter<SystemFee.Ref | null>(null);

		const { t } = useTranslation();

		destinationAppOptions.forEach((option) => {
			// eslint-disable-next-line no-param-reassign
			option.label = t(
				`${translationPath}.modal.app.options.${option.value}`,
			);
		});

		const valueEditor = useObjectEditor(value, onChange);

		const isDefault = valueEditor.get("isDefault");

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const paymentProviderValue = valueEditor.useGetter("paymentProvider");

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "taxiServices", "companies", "companyIds"],
			"json",
		);

		const updateAllData = valueEditor.useAssigner();
		controller.setContext({
			nameRef,
			companyBranchRef,
			paymentProviderValue,
			paymentSystemRef,
			systemFeeRef,
			fondyPaymentRef,
			assetPaymentRef,
		});
		return (
			<Root hasPaddings>
				<Column>
					<Column gaps="25px*" maxedWidth maxedHeight>
						<SelectPaymentSystems
							ref={setPaymentSystemFeeRef}
							value={value}
							onChange={onChange}
						/>
						<Row sizes="40% 30% 30%" gaps="16px*">
							<Name
								ref={setNameRef}
								name={name}
								setName={setName}
								isDefault={isDefault}
							/>
							<CompaniesAndTaxiServices
								ref={setCompanyBranchRef}
								value={taxiServicesData}
								onChange={updateAllData}
							/>
						</Row>

						<Row gaps="20px*" justify="space-between">
							<Column gaps="10px*" maxedWidth>
								<SystemFee
									ref={setSystemFeeRef}
									value={value}
									onChange={onChange}
								/>

								{paymentProviderValue ===
									PaymentProvider.ASSETPAYMENT && (
									<AssetPayment
										ref={setAssetPaymentRef}
										value={value}
										onChange={onChange}
									/>
								)}

								{paymentProviderValue ===
									PaymentProvider.FONDY && (
									<FondyPayment
										ref={setFondyPaymentRef}
										value={value}
										onChange={onChange}
									/>
								)}
							</Column>
						</Row>
					</Column>
				</Column>
			</Root>
		);
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default Content;
