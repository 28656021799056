import React, { Dispatch } from "react";
import { theme } from "uikit";

import { StyledColumn } from "../../../../../../../../../../../../components/common";
import Item from "../Item";

const List: React.FC<List.Props> = ({ items, onSelect }) => (
	<StyledColumn w="100%" bgC={theme.colors.secondary}>
		{items.map((item) => (
			<Item
				key={item.value}
				value={item}
				onClick={() => onSelect(item.value)}
			/>
		))}
	</StyledColumn>
);

declare namespace List {
	type Value = Item.Value[];

	interface Props {
		items: Value;
		onSelect: Dispatch<Item.Value["value"]>;
	}
}

export default List;
