import React, { Dispatch } from "react";
import Root from "./components/Root";
import SMSTemplate from "../../../../../../../../../../../../services/SMSTemplate";
import List from "./components/List";

const PopupContent: React.FC<PopupContent.Props> = ({
	smsTemplates,
	onSelectSMSTemplate,
}) => (
	<Root>
		<List items={smsTemplates} onSelect={onSelectSMSTemplate} />
	</Root>
);

declare namespace PopupContent {
	interface Props {
		smsTemplates: SMSTemplate.Model[];
		onSelectSMSTemplate: Dispatch<SMSTemplate.Model>;
	}
}

export default PopupContent;
