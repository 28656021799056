import { useRef } from "react";

function useSizedArray<Element>(size: number, add: (index: number) => Element) {
	const arrayRef = useRef<Element[]>([]);
	const array = arrayRef.current;
	const arrayLength = array.length;

	if (arrayLength !== size) {
		if (size > arrayLength)
			arrayRef.current = [
				...array,
				...Array(size - arrayLength)
					.fill(null)
					.map((_, index) => add(index)),
			];
		else arrayRef.current = [...array].slice(0, size);
	}

	return arrayRef.current;
}

export default useSizedArray;
