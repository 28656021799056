import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";

import ComissionItem from "./components/ComissionItem";

interface Props {
	precision: number | undefined;
}

const ComissionsList: React.FC<Props> = ({ precision }) => {
	const { t } = useTranslation();

	const [allComissions, setAllComissions] = useState({
		cashOrders: {
			key: "cashOrders",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str200",
				) ?? "",
			checkbox: false,
		},
		nonCashOrders: {
			key: "nonCashOrders",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str201",
				) ?? "",
			checkbox: false,
		},
		ruralOrders: {
			key: "ruralOrders",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str202",
				) ?? "",
			checkbox: false,
		},
		onlineOrders: {
			key: "onlineOrders",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str203",
				) ?? "",
			checkbox: false,
		},
		bonusOrders: {
			key: "bonusOrders",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str204",
				) ?? "",
			checkbox: false,
		},
		cardPaymentToService: {
			key: "cardPaymentToService",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str205",
				) ?? "",
			checkbox: false,
		},
		cardPaymentToExecutor: {
			key: "cardPaymentToExecutor",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str206",
				) ?? "",
			checkbox: false,
		},
		terminalToService: {
			key: "terminalToService",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str207",
				) ?? "",
			checkbox: false,
		},
		terminalToExecutor: {
			key: "terminalToExecutor",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str208",
				) ?? "",
			checkbox: false,
		},
		localDiscount: {
			key: "localDiscount",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str209",
				) ?? "",
			checkbox: false,
		},
		globalDiscount: {
			key: "globalDiscount",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str210",
				) ?? "",
			checkbox: false,
		},
		externalDiscount: {
			key: "externalDiscount",
			name:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.modal.content.comissionsList.str211",
				) ?? "",
			checkbox: false,
		},
	});

	const itemsList = useMemo(
		() => Object?.values(allComissions) || [],
		[allComissions],
	);

	return (
		<Column gaps="10px*">
			{itemsList.map((item) => (
				<ComissionItem
					key={item.key}
					data={item}
					onChangeState={setAllComissions}
					precision={precision}
				/>
			))}
		</Column>
	);
};

export default ComissionsList;
