import * as ModelEvent from "@node-elion/syncron";
import { compact, isBoolean, uniq } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import Language from "../Language";
import TaxiService from "../TaxiService2";

class ExecutorGroup extends Base {
	static fromResponse(data: any): ExecutorGroup.Model {
		const taxiServiceIds = ({
			taxiServices = [],
		}: ExecutorGroup.Response): number[] => {
			if (!taxiServices || !taxiServices.length) return [];
			const ids = taxiServices.map((item) => item?.id);
			return compact(uniq(ids));
		};

		const companyIds = ({
			taxiServices = [],
		}: ExecutorGroup.Response): number[] => {
			if (!taxiServices || !taxiServices.length) return [];
			const ids = taxiServices.map((item) => item?.company?.id);
			return compact(uniq(ids));
		};

		return {
			id: data.id,
			name: data.name,
			active: data.active,
			default: data.default,
			executorIds: data.executorIds || [],
			taxiServiceIds: taxiServiceIds(data),
			configuration: data.configuration,
			taxiServices: data.taxiServices || [],
			companyIds: companyIds(data),
			executorAppId: data.executorApp?.id,
			priority: data.priority || 0,
			isAllowToSelectCarClassesInApp:
				data?.isAllowToSelectCarClassesInApp,

			allowBeforeCompletion: data.allowBeforeCompletion || {
				active: false,
				maxOrders: 0,
				ignorePreliminary: false,
				completionSources: [],
			},
			showCustomerPhoneNumbers: data?.showCustomerPhoneNumbers || {
				active: false,
				type: ShowCustomerPhoneNumbersType.Always,
				conditions: {
					showManually: false,
					afterArrivedMessage: false,
					afterWaitingCustomerMessage: false,
				},
				beforeArrivalTime: {
					active: false,
					threshold: 0,
				},
				afterAcceptingOrder: {
					active: false,
					threshold: 0,
				},
			},
		};
	}

	static toRequest(
		model: ExecutorGroup.Model.New | ExecutorGroup.Model.Modified,
	): ExecutorGroup.Request {
		const payload: ExecutorGroup.Request = {
			name: model.name,
			default: model.default,
			active: model.active,
			executorIds: [],
			configuration: model.configuration,
			priority: model.priority || 0,
			showCustomerPhoneNumbers: model.showCustomerPhoneNumbers,
			allowBeforeCompletion: model.allowBeforeCompletion,
		};

		if (isBoolean(model.isAllowToSelectCarClassesInApp)) {
			payload.isAllowToSelectCarClassesInApp =
				model.isAllowToSelectCarClassesInApp;
		}

		if (model.executorAppId) {
			payload.executorAppId = model.executorAppId;
		}

		if (Array.isArray(model.executorIds)) {
			payload.executorIds = model.executorIds;
		}

		if (Array.isArray(model.taxiServiceIds)) {
			payload.taxiServiceIds = model.taxiServiceIds;
		}
		console.log("[ExecutorGroup]  toRequest", { payload, model });
		return payload;
	}

	public static async store(
		object: ExecutorGroup.Model.New,
	): Promise<ExecutorGroup.Model | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.executor.group.create(
					ExecutorGroup.toRequest(object),
				),
			);
			return res;
		} catch (error) {
			console.log("[ExecutorGroup  Error]  store", { error, object });
			return null;
		}
	}

	public static async update(
		object: ExecutorGroup.Model.Modified,
	): Promise<ExecutorGroup.Model | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.executor.group.update(
					object.id,
					ExecutorGroup.toRequest(object),
				),
			);
			return res;
		} catch (error) {
			console.log("[ExecutorGroup  Error]  update", { error, object });
			return null;
		}
	}

	public static async destroy(ids: number[] | number): Promise<boolean> {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.executor.group.delete(ids),
			);
			return true;
		} catch (error) {
			console.log("[ExecutorGroup  Error] destroy", { error, ids });
			return false;
		}
	}

	public static async subscribe(
		options: ExecutorGroup.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<ExecutorGroup.Model>,
	): Promise<Subscription<ExecutorGroup.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[ExecutorGroup] subscribe", { state });
				const models = compact(state.models);

				onUpdate({
					...state,
					models: models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add((prpc) =>
			prpc.theirsModel.executor.group.subscribe({
				params: options,
				ping: () => true,
				onEvent: (events: any) => {
					modelEventConstructor.onEvent(events);
				},
				onError: (error) => {
					// eslint-disable-next-line no-console
					console.log("[ExecutorGroup] subscribe Error", error);
				},
			}),
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: ExecutorGroup.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<ExecutorGroup.SubscribeOptions>;
	}

	private static optionsToRequest(options: ExecutorGroup.SubscribeOptions) {
		return {
			limit: options.limit,
			offset: options.offset,

			taxiServiceIds: options.taxiServiceIds,
		};
	}
}

export enum ShowCustomerPhoneNumbersType {
	Always = "always",
	Sometimes = "sometimes",
}

export enum ExecutorGroupCompletionSource {
	DISTRIBUTION = "distribution",
	DISTRIBUTION_PRELIMINARY = "distribution_preliminary",
	FREE_WAVE = "free_wave",
	FREE_WAVE_PRELIMINARY = "free_wave_preliminary",
	ANY = "any",
}

declare namespace ExecutorGroup {
	export interface Response {
		id: number;
		name: Record<Language, string>;
		active: boolean;
		default: boolean;

		configuration: Configuration;
		executorAppId: number;
		executorIds: number[];

		priority: number;

		taxiServices: TaxiService.Model[];

		isAllowToSelectCarClassesInApp: boolean;
		showCustomerPhoneNumbers: ShowCustomerPhoneNumbers;
		allowBeforeCompletion: AllowBeforeCompletion;

		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	export interface AllowBeforeCompletion {
		active: boolean;
		maxOrders: number;
		ignorePreliminary: boolean;
		completionSources: ExecutorGroupCompletionSource[];
	}

	export interface ShowCustomerPhoneNumbers {
		active: boolean;

		type: ShowCustomerPhoneNumbersType;

		conditions: {
			showManually: boolean;
			afterArrivedMessage: boolean;
			afterWaitingCustomerMessage: boolean;
		};

		beforeArrivalTime: {
			active: boolean;
			threshold: number;
		};

		afterAcceptingOrder: {
			active: boolean;
			threshold: number;
		};
	}

	export interface Request
		extends Record<string, any>,
			Partial<ExecutorGroup.Model.New> {}

	export interface Model
		extends Omit<Response, "createdAt" | "updatedAt" | "deletedAt"> {
		executorIds: number[];
		taxiServiceIds: number[];
		companyIds: number[];
		priority: number;
	}

	export interface SubscribeOptions {
		limit?: number;
		offset?: number;

		executorIds?: number[];
		taxiServiceIds?: number[];

		lang?: Language;
		query?: string;
		active?: boolean;
		default?: boolean;

		order?: {
			id?: number;
			name?: SubscribeOptions.Sort;
			active?: SubscribeOptions.Sort;
			default?: SubscribeOptions.Sort;
			status?: SubscribeOptions.Sort;
			taxiService?: SubscribeOptions.Sort;
			company?: SubscribeOptions.Sort;
		};
	}

	namespace SubscribeOptions {
		type Sort = "asc" | "desc";
	}

	interface Configuration {
		/** Включить повторное предложение */
		enableReOffer: boolean;
		/** Отображать предложения во вкладке "Живые" после отмены заказа (обязательного заказа) */
		displayRequiredOffersInLiveTabAfterCancel: boolean;
		/** Отображать предложения во вкладке "Живые" после истечения времени предложения (обязательного заказа) */
		displayRequiredOffersInLiveTabAfterExpire: boolean;
		/** Отображать предложения во вкладке "Живые" после отмены заказа для заказа (свободного эфира) */
		displayFreeWayOffersInLiveTabAfterCancel: boolean;
		/** Отображать предложения во вкладке "Живые" после истечения времени предложения для заказа (свободного эфира) */
		displayFreeWayOffersInLiveTabAfterExpire: boolean;

		/** Возвращать на стоянку при отказе от обязательного заказа */
		returnToParkingOnMandatoryOrderRefusal: boolean;

		/** Возвращать на стоянку при отказе от принятого заказа со свободного эфира */
		returnToParkingOnFreeWaveOrderRefusal: boolean;

		/** Оставлять на стоянке при опоздании на обязательный заказ */
		returnToParkingOnMandatoryOrderAfterExpire: boolean;

		/** Оставлять на стоянке при снятии диспетчером с обязательного заказа */
		returnToParkingOnDispatcherRemovalFromMandatoryOrder: boolean;

		/** Возвращать на стоянку при отмене заказа */
		returnToParkingOnOrderCancellation: boolean;

		/** Разрешить постановку исполнителя на стоянку */
		allowExecutorParking: boolean;

		/** Разрешить постановку исполнителя на стоянку только по геопозиции */
		allowExecutorParkingByGeolocation: boolean;

		/** Разрешить становиться на стоянку со статусом "Долг" */
		allowParkingWithDebtStatus: boolean;

		/** Разрешить становиться на стоянку со статусом "Обед" */
		allowParkingWithLunchStatus: boolean;

		/** Разрешить становиться на стоянку со статусом "Домой" */
		allowParkingWithHomeStatus: boolean;

		/** Разрешить становиться на стоянку со статусом "Занят" */
		allowParkingWithBusyStatus: boolean;

		/** Исключить  "Предварительный заказ" */
		excludePreliminaryOrder: boolean;

		/** Исключить  "Свой заказ" */
		excludeOwnOrder: boolean;

		/** Ставить на стоянку назначения после закрытия заказа */
		autoAssignToParkingAfterOrderClosure: boolean;

		/** Предлагать стать на стоянку назначения после закрытия заказа */
		suggestParkingAfterOrderClosure: boolean;

		/** Предлагать стать на стоянку по GPS координатам после выхода на смену */
		suggestParkingByGPSAfterShiftStart: boolean;

		/** Автоматически ставить на стоянку при входе в пределы зоны стоянки */
		autoParkWhenInParkingZone: boolean;

		/** Автоматически покидать стоянку при выходе за пределы зоны стоянки */
		autoLeaveParkingWhenOutOfZone: boolean;

		/** Запретить становиться на стоянку до закрытия всех своих заказов */
		restrictParkingUntilAllOrdersClosed: boolean;

		/** Показывать СВОИ заказы в свободном эфире только водителям на стоянке */
		showOwnOrdersInBroadcastForParkingOnly: boolean;

		/** Показывать СВОИ предварительные заказы в свободном эфире только водителям на стоянке */
		showOwnPreOrdersInBroadcastForParkingOnly: boolean;

		/** Показывать заказы с БИРЖИ в свободном эфире только водителям на стоянке */
		showMarketOrdersInBroadcastForParkingOnly: boolean;

		/** Показывать предварительные заказы с БИРЖИ в свободном эфире только водителям на стоянке */
		showMarketPreOrdersInBroadcastForParkingOnly: boolean;

		/** Показывать заказы в эфире исполнителям со статусом "На заказе" */
		showOrdersInBroadcastForOnOrderStatus: boolean;

		/** Показывать заказы в эфире исполнителям со статусом "Свой заказ" */
		showOrdersInBroadcastForOwnOrderStatus: boolean;

		/** Показывать заказы в эфире исполнителям со статусом "Долг" */
		showOrdersInBroadcastForDebtStatus: boolean;

		/** Показывать заказы в эфире исполнителям со статусом "Обед" */
		showOrdersInBroadcastForLunchStatus: boolean;

		/** Показывать заказы в эфире исполнителям со статусом "Домой" */
		showOrdersInBroadcastForHomeStatus: boolean;

		/** Показывать заказы в эфире исполнителям со статусом "Занят" */
		showOrdersInBroadcastForBusyStatus: boolean;

		/** Показывать исполнителям предварительные заказы */
		showExecutorsPreOrdersInBroadcast: boolean;

		/** Разрешить брать заказы из свободного эфира исполнителям со статусом "На заказе" */
		allowTakingOrdersWithOnOrderStatus: boolean;

		/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Свой заказ" */
		allowTakingOrdersWithOwnOrderStatus: boolean;

		/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Долг" */
		allowTakingOrdersWithDebtStatus: boolean;

		/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Обед" */
		allowTakingOrdersWithLunchStatus: boolean;

		/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Домой" */
		allowTakingOrdersWithHomeStatus: boolean;

		/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Занят" */
		allowTakingOrdersWithBusyStatus: boolean;

		/** Разрешить брать заказы из свободного эфира предварительные заказы */
		allowTakingPreOrdersInBroadcast: boolean;
	}

	namespace Model {
		export interface New extends Omit<Model, "taxiServices" | "id"> {
			taxiServiceIds: number[];
			executorIds: number[];
			name: Record<Language, string>;
			active: boolean;
			default: boolean;
			configuration: Configuration;
		}

		export interface Modified extends Partial<New> {
			id: number;
		}
	}
}

export default ExecutorGroup;
