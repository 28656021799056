import React from "react";
import SMSTemplate from "../../../../../../../../../../../../../../../../services/SMSTemplate";
import Root from "./components/Root";

const Item: React.FC<Item.Props> = ({ value, onClick }) => (
	<Root onClick={onClick}>{value.name}</Root>
);

declare namespace Item {
	interface Props {
		value: SMSTemplate.Model;

		onClick: VoidFunction;
	}
}

export default Item;
