import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ACTIVE = "active",
	NAME = "name",
	RULES = "rules",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.ACTIVE, label: "", width: 90 },
	{ id: Columns.NAME, label: "", width: 140 },
	{ id: Columns.RULES, label: "" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
