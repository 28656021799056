import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import ExecutingStageWaitingCustomerTimeCellContent from "../components/ExecutingStageWaitingCustomerTimeCellContent";

function executingStageWaitingCustomerTime({
	width,
	onResize,
}: Column.Options) {
	return base(
		ColumnId.ExecutingStageWaitingCustomerTime,
		(item) => <ExecutingStageWaitingCustomerTimeCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default executingStageWaitingCustomerTime;
