import { Option } from "uikit";
import { TFunction } from "i18next";

export const getTabs = (
	t: TFunction,
): Option<
	| "main"
	| "subscriptions"
	| "commissionPlans"
	| "discountedPlans"
	| "executors"
>[] => [
	{
		key: "main",
		value: "main",
		label: t(
			"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.main",
		),
	},
	{
		key: "subscriptions",
		value: "subscriptions",
		label: t(
			"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.subscriptions",
		),
	},
	{
		key: "commissionPlans",
		value: "commissionPlans",
		label: t(
			"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.commissionPlans",
		),
	},
	{
		key: "discountedPlans",
		value: "discountedPlans",
		label: t(
			"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.discountedPlans",
		),
	},
	{
		key: "executors",
		value: "executors",
		label: t(
			"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.executors",
		),
	},
]; // tabs

export type Tab =
	| "main"
	| "subscriptions"
	| "commissionPlans"
	| "discountedPlans"
	| "executors";

export default {
	getTabs,
};
