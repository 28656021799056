import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Select } from "uikit";

import TariffScaleTab from "../../../..";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
	StyledP,
	StyledSpan,
	ValueStepper,
} from "../../../../../../../../../../../../../../../../components/common";

import { getTariffsScaleAddBlock, getTariffsScaleOptions } from "./constants";
import TabRoot from "./components/TabRoot";
import PreviousIntervalOrStandard from "./components/PreviousIntervalOrStandard";

export const convertFromType = {
	custom_rate: 1,
	surcharge_to_standard_rate: 2,
	coefficient_to_standard_rate: 3,
	surcharge_to_previous_rule: 4,
	coefficient_to_previous_rule: 5,
};
export const convertToType = {
	1: "custom_rate",
	2: "surcharge_to_standard_rate",
	3: "coefficient_to_standard_rate",
	4: "surcharge_to_previous_rule",
	5: "coefficient_to_previous_rule",
};

const Content: React.FC<Content.Props> = memo(
	({ value, onChange, intervalError, isForAllRoute }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const val = valueEditor.useGetter("value");
		const setVal = valueEditor.useSetter("value");

		const minimal = valueEditor.useGetter("minimal");
		const setMinimal = valueEditor.useSetter("minimal");

		const settlements = valueEditor.usePicker([
			"baseSettlement",
			"outsideSettlement",
			"outsideSettlementRoundTrip",
		]);
		const updateSettlements = valueEditor.useAssigner();

		const tariffsScaleOptions = useMemo(
			() => getTariffsScaleOptions(t),
			[t],
		);
		const tariffsScaleAddBlock = useMemo(
			() => getTariffsScaleAddBlock(t),
			[t],
		);
		const texts = useMemo(
			() => ({
				km: t(`units.km`) ?? "km",
				before:
					t(
						"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.modal.content.str1",
					) ?? "",
				label:
					t(
						"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.modal.content.str2",
					) ?? "",
				p:
					t(
						"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.modal.content.str3",
					) ?? "",
				placeholder:
					t(
						"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.modal.content.str4",
					) ?? "",
			}),
			[t],
		);

		return (
			<TabRoot hasPaddings visible={true}>
				<StyledColumn gap="25px">
					<StyledRow alignItems="center" gap="10px">
						<StyledSpan>{texts.before}</StyledSpan>
						<ValueStepper
							min={1}
							decimalCount={3}
							error={intervalError?.message || ""}
							value={val}
							onChange={(num) => setVal(num as number)}
						/>

						<StyledSpan>{texts.km}</StyledSpan>
					</StyledRow>
					<StyledColumn gap="10px">
						<StyledColumn gap="10px" m="0 0 20px 0">
							<StyledRow
								alignItems="center"
								gap="10px"
								justify="space-between"
								h="32px"
							>
								<CheckBox
									label={texts.label}
									value={minimal.active}
									onChange={(active) => {
										setMinimal({ ...minimal, active });
									}}
								/>
								{!minimal.active ? (
									<PreviousIntervalOrStandard />
								) : (
									<StyledRow gap="10px" alignItems="center">
										<Select
											style={{ width: 300 }}
											options={tariffsScaleOptions}
											placeholder={texts.placeholder}
											value={
												minimal?.type
													? convertFromType[
															minimal.type
													  ]
													: undefined
											}
											onChange={(type: any) => {
												setMinimal({
													...minimal,
													type: convertToType[type],
												});
											}}
										/>
										<ValueStepper
											disabled={!minimal?.type}
											value={minimal?.value}
											onChange={(newValue) => {
												setMinimal({
													...minimal,
													value: newValue,
												});
											}}
										/>
									</StyledRow>
								)}
							</StyledRow>
							<StyledRow
								alignItems="center"
								justify="space-between"
							>
								<StyledP>{texts.p}</StyledP>
								<StyledRow
									alignItems="center"
									gap="10px"
									flex={{ grow: 1 }}
									w={{ max: "400px" }}
								>
									<ValueStepper
										decimalCount={3}
										min={0}
										disabled={!isForAllRoute}
										value={minimal?.minDistance}
										onChange={(newValue) => {
											setMinimal({
												...minimal,
												minDistance: newValue as number,
											});
										}}
									/>
									<StyledSpan>{texts.km}</StyledSpan>
								</StyledRow>
							</StyledRow>
						</StyledColumn>
						{tariffsScaleAddBlock.map(({ id, textContent }) => (
							<StyledRow
								key={id}
								alignItems="center"
								gap="10px"
								justify="space-between"
								h="32px"
							>
								<CheckBox
									label={textContent}
									value={settlements[id].active}
									onChange={(active) => {
										updateSettlements({
											[id]: {
												...settlements[id],
												active,
											},
										});
									}}
								/>
								{!settlements[id].active ? (
									<PreviousIntervalOrStandard />
								) : (
									<StyledRow gap="10px" alignItems="center">
										<Select
											style={{ width: 300 }}
											options={tariffsScaleOptions}
											placeholder={texts.placeholder}
											value={
												settlements[id]?.type
													? convertFromType[
															settlements[id].type
													  ]
													: undefined
											}
											onChange={(type: any) => {
												updateSettlements({
													[id]: {
														...settlements[id],
														type: convertToType[
															type
														],
													},
												});
											}}
										/>
										<ValueStepper
											value={settlements[id].value}
											onChange={(newValue) => {
												updateSettlements({
													[id]: {
														...settlements[id],
														value: newValue,
													},
												});
											}}
										/>
									</StyledRow>
								)}
							</StyledRow>
						))}
					</StyledColumn>
				</StyledColumn>
			</TabRoot>
		);
	},
);

declare namespace Content {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
		isForAllRoute: boolean;
		intervalError: TariffScaleTab.IntervalError | null;
	}

	type Value = Omit<TariffScaleTab.Interval, "active">;
}

export default Content;
