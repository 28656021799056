/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import { defaults } from "lodash";

import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import createLogger from "../../utils/logger.util";
import ModelService from "../../redux/services/ModelService";
import dispatchers from "../../redux/reducers/dispatcher";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import {
	File,
	Person,
	Language,
	Role,
	Card,
	CarPark,
	SIPToDispatcher,
} from "..";

import { destroyOne } from "./utils";

class Dispatcher extends ModelService<
	Dispatcher.SubscribeOptions,
	Dispatcher.Model,
	"dispatchers"
>(dispatchers, (state) => state.dispatchers) {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.dispatcher.card);

		return this._Card;
	}

	public static defaultSharedOptions: Dispatcher.SharedOptions = {
		deprecate: true,
	};

	public static fromResponse(
		data: any,
		language?: Language,
	): Dispatcher.Model {
		return {
			id: data?.id,

			carParks: data?.dispatcherToFleets?.map((dispatcherToFleet) =>
				CarPark.fromResponse(dispatcherToFleet?.fleet),
			),

			roleIds:
				data?.dispatcherToRoles?.map((relation) => relation.role.id) ??
				[],

			taxiServiceIds:
				data?.dispatcherToTaxiServices
					?.map((relation) => relation?.taxiService?.id)
					.sort(
						(taxiService1Id, taxiService2Id) =>
							taxiService1Id - taxiService2Id,
					) ?? [],

			defaultTaxiServiceId: data?.defaultTaxiService?.id,

			dispatcherToSIPs: data?.dispatcherToSips?.map((dispatcherToSip) =>
				SIPToDispatcher.fromResponse(dispatcherToSip),
			),

			roles: data?.dispatcherToRoles?.map((relation) =>
				Role.fromResponse(relation.role, language),
			),

			personalFiles: data?.personalFiles?.map(File?.fromResponse),
			passportFiles: data?.passportFiles?.map(File?.fromResponse),
			otherFiles: data?.otherFiles?.map(File?.fromResponse),

			person: data?.person ? Person.fromResponse(data.person) : undefined,

			alias: data?.callSign,
			login: data?.login,
			status: data?.status,
			additionalFields: {
				passport: {
					series: data?.additionalFields?.passport?.series,
					number: data?.additionalFields?.passport?.number,
					issuedBy: data?.additionalFields?.passport?.issuedBy,
					issuedAt: data?.additionalFields?.passport?.issuedAt,
					residenceAddress:
						data?.additionalFields?.passport?.birthPlace,
					livingAddress:
						data?.additionalFields?.passport?.residencePlace,
				},
				taxId: data?.additionalFields?.taxId,
				phones: data?.additionalFields?.phones,
			},

			settings: data?.settings,

			notes: data?.notes,

			allowedIpAddresses: data?.ipWhiteList,

			createdAt: data?.createdAt,
			updatedAt: data?.updatedAt,
			closedAt: data?.closedAt,
			deletedAt: data?.deletedAt,
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public static toRequest(
		model: Dispatcher.Model.New | Dispatcher.Model.Modified,
	): any {
		return {
			personalFileIds: model.personalFileIds,
			passportFileIds: model.passportFileIds,
			otherFileIds: model.otherFileIds,

			login: model.login,
			password: model.password,
			emails:
				model.person?.emails?.map((email) => ({
					value: email,
					group: 0,
				})) ?? undefined,
			roleIds: model.roleIds,
			fleetIds: model.carParkIds,
			settings: model.settings,
			taxiServiceIds: model.taxiServiceIds,
			defaultTaxiServiceId: model.defaultTaxiServiceId,
			callSign: model.alias ? model.alias : undefined,
			status: model.status,
			additionalFields: {
				passport: {
					series: model.additionalFields?.passport?.series,
					number: model.additionalFields?.passport?.number,
					issuedBy: model.additionalFields?.passport?.issuedBy,
					issuedAt:
						model.additionalFields?.passport?.issuedAt ?? null,
					birthPlace:
						model.additionalFields?.passport?.residenceAddress,
					residencePlace:
						model.additionalFields?.passport?.livingAddress,
				},
				taxId: model.additionalFields?.taxId,
				phones: model.additionalFields?.phones?.filter(Boolean),
			},

			name: model.person?.firstName ?? undefined,
			surname: model.person?.lastName ?? undefined,
			fatherName: model.person?.fatherName ?? undefined,
			birthday: model.person?.birthday ?? null,
			city: model.person?.city,
			country: model.person?.country,
			address: model.person?.address,

			notes: model.notes,

			ipWhiteList: model.allowedIpAddresses?.filter(Boolean),
			sipIds: model.sipIds,
		};
	}

	public static async getHistory(id: number) {
		const prpc = getPRPC();

		if (!prpc) return [];

		const result = await createRPCQuery(() =>
			prpc.theirsModel.dispatcher.getHistory(id),
		);

		return result
			.filter(
				(version) =>
					version.action !== "update" || version.changes.length !== 0,
			)
			.map((version) => ({
				...version,

				user: version.user
					? {
							id: version.user.id,

							firstName: version.user.name,
							lastName: version.user.surname,
							fatherName: version.user.fatherName,
					  }
					: null,
			})) as unknown as Dispatcher.History;
	}

	public static async store(
		object: Dispatcher.Model.New,
		options?: Dispatcher.StoreOptions,
	) {
		options = defaults(options, Dispatcher.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return null;

		const result = await createRPCQuery(
			() =>
				prpc.theirsModel.dispatcher.create(
					Dispatcher.toRequest(object),
				),
			{ silent: false, error: true },
		);

		console.log("[Dispatcher] create", { object, options, result });

		if (options.deprecate) Dispatcher.deprecateAll();

		return this.fromResponse(result);
	}

	public static async update(
		object: Dispatcher.Model.Modified,
		options?: Dispatcher.UpdateOptions,
	) {
		options = defaults(options, Dispatcher.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		const res = await createRPCQuery(
			() =>
				prpc.theirsModel.dispatcher.update(
					object.id,
					Dispatcher.toRequest(object),
				),
			{ silent: false, error: true },
		);

		console.log("[Dispatcher] update", { res, object });

		if (options.deprecate) Dispatcher.deprecateAll();
	}

	public static async updateSettings(
		id: Dispatcher.Model["id"],
		settings: Dispatcher.Settings,
	) {
		const prpc = getPRPC();

		if (!prpc) return;

		console.log("[Dispatcher] updateSettings", { id, settings });

		await createRPCQuery(() =>
			prpc.theirsModel.dispatcher.updateSettings(id, settings),
		);
	}

	public static async destroy(
		id: number[] | number,
		options?: Dispatcher.DestroyOptions,
	) {
		options = defaults(options, Dispatcher.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		if (options.deprecate) Dispatcher.deprecateAll();
	}

	public static Global = {
		async index(options: Dispatcher.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const request: Record<string, any> = {
				limit: options.limit,
				offset: options.offset,
				order: options.order,
				query: options.query,
				lang: options.language,
				statuses: options.statuses,
				taxiServiceIds: options.taxiServiceIds,
				roleIds: options.roleIds,
			};

			if (options.order) {
				request.order = {};

				if (options.order.fullName)
					request.order.name = options.order.fullName.toUpperCase();

				if (options.order.phone)
					request.order.phone = options.order.phone.toUpperCase();

				if (options.order.alias)
					request.order.callSign = options.order.alias.toUpperCase();

				if (options.order.role)
					request.order.role = options.order.role.toUpperCase();

				if (options.order.status)
					request.order.status = options.order.status.toUpperCase();

				if (options.order.taxiService)
					request.order.taxiService =
						options.order.taxiService.toUpperCase();

				if (options.order.registeredAt)
					request.order.createdAt =
						options.order.registeredAt.toUpperCase();
			}

			const result = await createRPCQuery(() =>
				prpc.theirsModel.dispatcher.getAll(request),
			);

			const cache = (result.items as any[]).map((item) =>
				Dispatcher.fromResponse(item, request.language),
			);

			return {
				cache,
				offset: 0,
				limit: cache.length,
				total: cache.length,
				deprecated: false,
			};
		},
	};
}

declare namespace Dispatcher {
	type Status = "active" | "blocked" | "dismissed";

	type History = History.Item[];

	namespace History {
		interface Item {
			id: number;

			user: Item.User | null;

			action: "create" | "update" | "delete";
			comment: string;
			changes: Item.Change[];

			timestamp: number;
			version: number;
		}

		namespace Item {
			interface User {
				id: number;

				firstName: string;
				lastName: string;
				fatherName: string;
			}
		}

		namespace Item {
			interface Change {
				previous: any;
				actual: any;
				field: string;
				type:
					| "update"
					| "array_item_add"
					| "array_item_remove"
					| "array_item_update";
			}
		}
	}

	namespace Status {
		type Closed = "blocked" | "dismissed";
	}

	interface Passport {
		series?: string;
		number?: string;
		issuedBy?: string;
		issuedAt?: Date | string | null;
		residenceAddress?: string;
		livingAddress?: string;
	}

	namespace Passport {
		type New = Partial<Passport>;
		type Modified = Partial<Passport>;
	}

	interface AdditionalFields {
		passport?: Passport;
		taxId?: string;
		phones?: string[];
	}

	namespace AdditionalFields {
		type New = Partial<
			Omit<AdditionalFields, "passport"> & {
				passport: Passport.New;
			}
		>;
		type Modified = Partial<
			Omit<AdditionalFields, "passport"> & {
				passport: Passport.Modified;
			}
		>;
	}

	interface Settings extends Record<string, any> {
		language?: Language;
		fontName?: string;
		fontSize?: number;
	}

	interface Model {
		id: number;

		carParks?: CarPark.Model[];

		roleIds: number[];
		taxiServiceIds: number[];
		defaultTaxiServiceId: number;

		dispatcherToSIPs?: SIPToDispatcher.Model[];

		roles?: Role.Model[];

		personalFiles: File.Model[];
		passportFiles: File.Model[];
		otherFiles: File.Model[];

		person?: Person.Model;

		alias?: string;
		login: string;
		password?: string;
		status: Status;
		additionalFields: AdditionalFields;

		settings?: Settings;

		notes: string;

		allowedIpAddresses: string[];

		createdAt: string | null;
		updatedAt: string | null;
		closedAt: string | null;
		deletedAt: string | null;

		birthAt?: Date;

		callSign?: string;
		defaultTaxiService?: any; // todo fix this type
		dispatcherToRoles?: RoleElement[];
	}

	export interface RoleElement {
		id: number;
		role: Role.Model;
		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<Dispatcher.Model>, "order"> {
		language?: Language;
		statuses?: Status[];
		roleIds?: number[];
		taxiServiceIds?: number[];
		order?: {
			fullName?: SortingOrder;
			phone?: SortingOrder;
			alias?: SortingOrder;
			role?: SortingOrder;
			status?: SortingOrder;
			taxiService?: SortingOrder;
			registeredAt?: SortingOrder;
		};
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type NonEditablePropertyNames =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "closedAt"
			| "deletedAt";

		type ModifiedPropertyNames =
			| "person"
			| "carParks"
			| "additionalFields"
			| "personalFiles"
			| "passportFiles"
			| "otherFiles";

		type PartialNewPropertyNames = "settings" | "notes";

		type New = Omit<
			Model,
			| NonEditablePropertyNames
			| ModifiedPropertyNames
			| PartialNewPropertyNames
		> &
			Partial<Pick<Model, PartialNewPropertyNames>> & {
				person: Person.Model.New;
				additionalFields?: AdditionalFields.New;

				carParkIds: number[];
				sipIds?: number[];
				personalFileIds: number[];
				passportFileIds: number[];
				otherFileIds: number[];
			};

		type Modified = Pick<Model, "id"> &
			Partial<
				Omit<
					Model,
					NonEditablePropertyNames | ModifiedPropertyNames
				> & {
					person: Person.Model.Modified;
					additionalFields: AdditionalFields.Modified;

					carParkIds: number[];
					sipIds?: number[];
					personalFileIds: number[];
					passportFileIds: number[];
					otherFileIds: number[];
				}
			>;
	}
}

export default Dispatcher;
