export function limit<Element>(
	array: Element[],
	size: number,
	add: (index: number) => Element,
) {
	const arrayLength = array.length;

	if (arrayLength !== size)
		if (size > arrayLength)
			array.concat(
				...Array(size - arrayLength)
					.fill(null)
					.map((_, index) => add(index)),
			);
		else array.splice(size);

	return array;
}

export default { limit };
