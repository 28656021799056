import React, {
	PropsWithChildren,
	memo,
	useMemo,
	useCallback,
	useLayoutEffect,
} from "react";
import { cloneDeep } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import { StyledInput } from "../styled";
import {
	HandleEventsProps,
	useHandleEvents,
	ValueAccessors,
} from "../../hooks";
import useKeyBind from "../../../../../../hooks/useKeyBind";
import PointOrderModalTableBase from "../..";

const EntranceBase: React.FC<EntranceBase.Props> = ({
	rowData,
	rowIndex,
	dataKey,
	colIndex,
	setData,
	height,
	handleEventsOptions,
}) => {
	const {
		inputValue,
		save,
		setSave,
		onChangeInputValue,
		onEnterToInput,
		onExistFromInput,
		onClickToInput,
		setColIndex,
		setDataKey,
		setInputId,
		setRowId,
		setRowIndex,
		setInputValue,
	} = useHandleEvents(handleEventsOptions);

	useLayoutEffect(() => {
		setColIndex(`${colIndex}`);
		setDataKey(dataKey);
		setInputId(`${rowData.raw.id + dataKey}`);
		setRowId(rowData.raw.id);
		setRowIndex(`${rowIndex}`);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const debouncedSave = useDebouncedCallback((value: string) => {
		rowData.saveType = "entrance";
		rowData.raw.saveType = "entrance";
		setInputValue(value);
		setSave(true);
	}, 2000);

	const updatedData = useCallback(
		(value: string) => {
			if (!value) return;
			const house = rowData.raw.house || rowData.raw.number || null;

			if (!house) {
				if (`${rowData.raw.entrance}`.trim() === `${value}`.trim()) {
					setSave(false);
				} else {
					debouncedSave(value);
					setInputValue(value);
					return;
				}
			}

			if (`${rowData.raw.entrance}`.trim() === `${value}`.trim()) {
				setSave(false);
			} else if (rowData.raw.saveType === "street") {
				debouncedSave(value);
				setInputValue(value);
				rowData.saveType = "entrance";
				rowData.raw.saveType = "entrance";
				return;
			}

			const newData = cloneDeep(rowData);
			newData.entrance = value || "";
			newData.raw.entrance = value || "";
			newData.raw.type = "address";
			newData.save = true;
			newData.raw.save = true;
			newData.saveType = "entrance";
			newData.raw.saveType = "entrance";
			setData(newData);
			if (`${rowData.raw.entrance}`.trim() === `${value}`.trim()) {
				setInputValue(null);
			}
			setSave(false);
		},
		[debouncedSave, rowData, setData, setInputValue, setSave],
	);

	useKeyBind(["Enter"], (event) => {
		event.stopPropagation();
		event.preventDefault();
	});

	useKeyBind(["Tab"], (event) => {
		event.stopPropagation();
	});

	useMemo(() => {
		if (inputValue !== null && save) updatedData(inputValue);
	}, [inputValue, save, updatedData]);

	const propsInput = useMemo(() => {
		const baseValue = `${rowData.raw?.entrance || ""}`;

		return {
			value: inputValue !== null ? inputValue : baseValue,
		};
	}, [rowData.raw, inputValue]);

	const onFocus = useCallback((e) => e.target.select(), []);

	const size = useMemo(
		() =>
			propsInput.value.length > 2 ? propsInput.value.length * 1.25 : 2,
		[propsInput.value.length],
	);

	return (
		<StyledInput
			size={size}
			height={height}
			id={`${rowData.raw.id + dataKey}`}
			aria-label={dataKey}
			aria-rowindex={rowIndex}
			aria-colindex={colIndex}
			autoComplete="one-time-code"
			onChange={onChangeInputValue}
			onKeyUp={onEnterToInput}
			onKeyDown={onExistFromInput}
			onClick={onClickToInput}
			onFocus={onFocus}
			onCut={(event) => {
				event.preventDefault();
			}}
			onDragOver={(event) => {
				event.preventDefault();
			}}
			onDrop={(event) => {
				event.preventDefault();
			}}
			{...propsInput}
		/>
	);
};

declare namespace EntranceBase {
	interface Props extends PropsWithChildren {
		rowData: PointOrderModalTableBase.OrderModal;
		setData: (data: PointOrderModalTableBase.OrderModal) => void;
		rowIndex?: number;
		dataKey: ValueAccessors | string;
		colIndex: number;
		height?: number;
		handleEventsOptions?: HandleEventsProps;
	}
}

export const Entrance = memo(EntranceBase);

export default EntranceBase;
