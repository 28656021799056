import { useMemo } from "react";
import { LatLngLiteral } from "leaflet";

import { useTypedSelector } from "../../../../../redux/store";
import { PolygonList } from "../../../../PreferencesPages/components/ScreenDirectory/components/PolygonsEditor/type";
import { DefaultCity } from "../../../../../redux/reducers/map.reducer";
import Order from "../../../../../services/Order";
import { getOrderPassengerPoints } from "../../../../../redux/constants/OrdersPage/order";

export type OrderPassengerPoints = {
	coordinates: LatLngLiteral;
	country: string;
	countryCode: string;
	customHouse: string;
	customStreet: string;
	district: string;
	entrance: string;
	flat: string;
	house: string;
	name: string;
	region: string;
	sector: undefined;
	settlement: string;
	settlementType: string;
	street: string;
	streetType: string;
	type: string;
};

export type UseMapReducer = {
	polygons: PolygonList;
	defaultCity: DefaultCity;
	route: LatLngLiteral[][];
	points: OrderPassengerPoints[];
	distance: number;
};

/**
 *  `useMapReducer`
 */
export const useMapReducer = (order: Order.Model | null): UseMapReducer => {
	const { defaultCity } = useTypedSelector((state) => state.mapReducer);
	const polygons = useTypedSelector((state) => state.mapReducer.polygons);

	const points = useMemo(() => {
		if (!order) return [];
		return getOrderPassengerPoints(order);
	}, [order]);

	const route = useMemo(() => {
		if (!order || !order.route) return [];
		let isWrong = false;
		if (order.route?.segments.length) {
			order.route?.segments.forEach((item) => {
				if (typeof item === "string") isWrong = true;
			});
		}
		return !isWrong ? order.route?.segments : [];
	}, [order]);

	const distance = useMemo(() => {
		if (!order || !order.route) return 0;
		return order.route.distance || 0;
	}, [order]);

	return useMemo<UseMapReducer>(
		() => ({ polygons, defaultCity, route, points, distance }),
		[polygons, defaultCity, route, points, distance],
	);
};

export default useMapReducer;
