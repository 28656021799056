import moment from "moment";

export const timestampToTime = (isoString: string) =>
	moment(isoString).format("HH:mm");

export const secondsFormater = (seconds) => {
	let hours: number | string = Math.floor(seconds / 3600);
	let minutes: number | string = Math.floor((seconds % 3600) / 60);
	let remainingSeconds: number | string = Math.floor(seconds % 60);

	hours = hours < 10 ? `0${hours}` : hours;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	remainingSeconds =
		remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	if (+hours > 0) {
		return `${hours}:${minutes}:${remainingSeconds}`;
	}
	return `${minutes}:${remainingSeconds}`;
};
