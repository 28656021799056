import { css } from "styled-components";
import { theme } from "uikit";

const styles = {
	cell: (type: "header" | "body") => css`
		.rs-table-cell-content,
		.rs-table-cell {
			background: ${theme.colors.transparent} !important;

			font-family: "Lato";
			font-style: normal;
			${type === "header"
				? `
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                    `
				: `
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                    `}
			color: ${theme.colors.primary};
		}

		.rs-table-cell-content {
			padding: 14px 8px;
		}

		&:first-child {
			border-right: none;
		}

		&:last-child {
			border-right: none;
		}

		&:nth-last-child(2) {
			border-right: none;
		}
	`,
};

export default styles;
