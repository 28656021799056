/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import EditModalBase from "../../../../../../../components/EditModal";
import Header from "./components/Header";
import Content from "./components/Content";
import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import Root from "./components/Root";
import DeleteModal from "../../../../../../../components/DeleteModal";
import SMSTemplate from "../../../../../../../services/SMSTemplate";
import { useCreateForm } from "../../../../../../../hooks/useCreateForm";
import validationSchema from "./constants/validationShema";
import translationPath from "../../constants/translationPath";
import InternalController from "./Controller";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		value,
		onCancel,
		onSave,
		createdAt,
		headerTitle,

		showClarificationModal,
		setShowClarificationModal,
		preparedTemplate,
		setPreparedTemplate,
		saveHandler,

		controller,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const { t } = useTranslation();

		const [internalValue, setInternalValue] = useState(value);

		const form = useCreateForm({
			yupObject: validationSchema,
			values: structuredClone(internalValue),
		});

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			const values = form.getValues();
			onSave(values as SMSTemplate.New | SMSTemplate.Modified);
		}, [contentRef, form, onSave]);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const orderTemplate = valueEditor.useGetter("orderTemplate");
		const setOrderTemplate = valueEditor.useSetter("orderTemplate");

		const contentValues = valueEditor.usePicker(
			[
				"name",
				"templateText",
				"taxiServiceIds",
				"taxiServices",
				"automaticSending",
				"autoSendOrderChanges",
				"disableAutoSendForPreOrder",
				"disableAutoSendForOnlineOrder",
				"isAutoSendDelay",
				"autoSendDelay",
				"excludeOrderChanges",
				"isValidityPeriod",
				"validityPeriod",
				"isTransliteration",
				"isMaxSmsPartCount",
				"maxSmsPartCount",
				"isMinTimeoutSendingSms",
				"minTimeoutSendingSms",
				"isDefault",
				"lang",
				"action",
				"type",
			],
			"json",
		);
		const contentOnChange = valueEditor.useAssigner();

		return (
			<EditModalBase
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Row gaps="20px*">
						<CheckBox
							label={t(`${translationPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
						<CheckBox
							label={
								t(`${translationPath}.modal.orderTemplate`) ||
								""
							}
							value={orderTemplate}
							onChange={setOrderTemplate}
						/>
					</Row>
				}
			>
				<Root sizes="auto! 1fr">
					<Header title={headerTitle} createdAt={createdAt} />
					<Content
						ref={setContentRef}
						value={contentValues}
						onChange={contentOnChange}
						register={form.register}
					/>
				</Root>
				{showClarificationModal && (
					<DeleteModal // in fact, this modal performs clarification functionality
						label={t(`${translationPath}.clarificationModal`) || ""}
						onCancel={() => {
							setShowClarificationModal(false);
							setPreparedTemplate(null);
						}}
						onConfirm={() => {
							if (preparedTemplate) {
								saveHandler(preparedTemplate, true);
							}
							setShowClarificationModal(false);
						}}
					/>
				)}
			</EditModalBase>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		headerTitle: string;
		createdAt: string;
		onCancel: () => void;
		onSave: (x: SMSTemplate.New | SMSTemplate.Modified) => void;

		showClarificationModal: boolean;
		setShowClarificationModal: Dispatch<boolean>;
		preparedTemplate: PreparedTemplate;
		setPreparedTemplate: Dispatch<PreparedTemplate>;
		saveHandler: (
			template: SMSTemplate.New | SMSTemplate.Modified,
			force?: boolean,
		) => Promise<void>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Content.Value &
		Partial<SMSTemplate.NonEditableProperties> & {
			active: boolean;
			orderTemplate: boolean;
		};
	type PreparedTemplate = SMSTemplate.New | SMSTemplate.Modified | null;
}

export default Modal;
