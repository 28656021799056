import { TFunction } from "i18next";

import PushNotice from "../../../../../../../../../../../../services/PushNotice";

export default async function getFilteredItems(
	query: string,
	t: TFunction,
	type,
	destinationApp,
) {
	try {
		const res = await PushNotice.getParams({ destinationApp });
		const allowedParams = res[type]?.createTemplateParam || [];

		allowedParams.push(
			...Array.from({ length: 100 }, (_, index) => String(index + 1)),
			"#if",
			"isLess",
			"isGreater",
			"isEqual",
			"/if",
		);

		const translatedParams = allowedParams.map((param) => {
			const origParam = param;
			let translatedParam;

			// eslint-disable-next-line no-restricted-globals
			if (!isNaN(param)) {
				translatedParam = param;
			} else {
				translatedParam = t(
					`settings.messages.pushNotices.modal.templateBody.messageParams.${param}`,
				);
			}
			return { origParam, translatedParam };
		});

		const filteredParams = translatedParams
			?.filter((item) =>
				item.translatedParam
					.toLowerCase()
					.startsWith(query.toLowerCase()),
			)
			?.slice(0, 7);

		return filteredParams;
	} catch (error) {
		console.error(
			`${
				t(
					`settings.messages.pushNotices.modal.templateBody.messageError.str1`,
				) ?? ""
			}:`,
			error,
		);
		return [
			t(
				`settings.messages.pushNotices.modal.templateBody.messageError.str2`,
			) ?? "",
		];
	}
}
