/* eslint-disable no-shadow */

import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { CheckBox, Column, Table } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { clone, isUndefined } from "lodash";
import { UseTranslationResponse, useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import { DefaultNamespace } from "react-i18next/TransWithoutContext";

import CustomerRate from "../../../../../../../../../../../../../../../../services/CustomerRate";
import KeyBinder from "../../../../../../../../../../../../../../../../services/KeyBinder";
import {
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../../../../../../../hooks/useTableSettings";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../hooks";
import { getColumnsModeTwo } from "../../../../../../../../../../../../../../../../utils";
import LightTable from "../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../components/TableSettings";

interface ColumnContext {
	t: UseTranslationResponse<DefaultNamespace, undefined>["t"];
	width: number;
	onResize: (width?: number, columnId?: string) => void;
	currencyGlobalSettings: string;
}

type CellContent = (rowData: RowDataType, rowIndex?: number) => React.ReactNode;

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	orderCount: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell
				verticalAlign="middle"
				dataKey="orderCount"
				fullText
			>
				{
					((item: CustomerRate.Model.ModeData.Item) =>
						item.threshold) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),
	discount: ({ width, onResize, t, currencyGlobalSettings }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.modelTable.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="discount" fullText>
				{
					((item: CustomerRate.Model.ModeData.Item) =>
						`${item.discount.value} ${
							item.discount.unit ===
							CustomerRate.Model.Unit.Percentage
								? "%"
								: currencyGlobalSettings
						}`) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),
	onlineDiscount: ({ width, onResize, t, currencyGlobalSettings }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.modelTable.str151",
				) ?? ""}{" "}
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.modelTable.str200",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell
				verticalAlign="middle"
				dataKey="onlineDiscount"
				fullText
			>
				{
					((item: CustomerRate.Model.ModeData.Item) =>
						`${item.onlineDiscount.value} ${
							item.onlineDiscount.unit ===
							CustomerRate.Model.Unit.Percentage
								? "%"
								: currencyGlobalSettings
						}`) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,
	onChangeSelected,
	onChangeSort,
	onEdit,
}) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => ["orderCount", "discount", "onlineDiscount"],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const { columnIds, setColumnIds } = useVisibleColumns(
		"settings.mutualSettlements.customerRates.rules",
		"customerRateRule",
	);
	const { widths, setWidths } = useWidths(
		"settings.mutualSettlements.customerRates.rules",
		"customerRateRule",
	);

	const tableRef = useRef<Table.Ref | null>(null);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			return "";
		},
		[selected],
	);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(
						data.indexOf(rowData),
						!selected.includes(rowData.id),
				  )
				: onChangeSelected(
						selected.includes(data.indexOf(rowData))
							? []
							: [data.indexOf(rowData)],
				  ),
		[data, onChangeSelected, selected, setItemSelection],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={44}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value
										? new Array(data.length)
												.fill(null)
												.map((_, index) => index)
										: [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData, index) => (
							<CheckBox
								value={selected.includes(index ?? 0)}
								onChange={(selected) =>
									KeyBinder.isControlPressed
										? setItemSelection(index ?? 0, selected)
										: onChangeSelected(
												selected ? [index ?? 0] : [],
										  )
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) =>
					Columns[columnId]({
						currencyGlobalSettings,
						t,
						width: widths[columnId],
						onResize: (width, columnId) => {
							if (isUndefined(width) || isUndefined(columnId))
								return;

							setWidths({
								...widths,
								[columnId]: width,
							});
						},
					}),
				)}
			</LightTable>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	type Item = CustomerRate.Model.ModeData.Item;

	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: Item[];

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<Item>;
	}
}

export default ModelTable;
