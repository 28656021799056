import { useCallback } from "react";

import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import { ValueToggle } from "../../../../../redux/reducers/Archives/interface";
import {
	State,
	archiveAction as archive,
} from "../../../../../redux/reducers/Archives";
import { archiveGPS } from "../../../../../redux/reducers";

export type UseArchiveSwitcher = {
	playerToggle: boolean;
	playerOrdersToggles: boolean;
	orderToggles: State["orders"]["toggles"];
	orderLoading: State["orders"]["loadings"];

	resetToggles: () => void;
	setPlayerToggle: (data: ValueToggle) => void;
	setOrdersPlayerToggle: (data: ValueToggle) => void;
	setInfoModalToggle: (data: ValueToggle) => void;
	setOrdersLoading: (data: boolean) => void;
	setOrderLoading: (data: boolean) => void;
	setFilterModalToggle: (data: ValueToggle) => void;
	setSettingModalToggle: (data: ValueToggle) => void;
	setSubscribeToggle: (data: ValueToggle) => void;
	setSearchToggle: (data: ValueToggle) => void;
	setExportModalToggle: (data: ValueToggle) => void;
	setDisabledOrderFormToggle: (data: ValueToggle) => void;
	setButtonSaveOrderForm: (data: ValueToggle) => void;
	setSaveOrderForm: (data: ValueToggle) => void;
	setCancelOrderForm: (data: ValueToggle) => void;
};

/** `useArchiveSwitcher`
 ** Hook to get all the `toggles` and `loadings` inside the redux store archive.
 ** And has functions for changing the state of `toggles` and `loadings`.
 */
export const useArchiveSwitcher = (): UseArchiveSwitcher => {
	const dispatch = useTypedDispatch();
	const playerToggle = useTypedSelector(
		(state) => state.archiveGPSLog.player.toggle,
	);
	const orderToggles = useTypedSelector(
		(state) => state.archive.orders.toggles,
	);
	const playerOrdersToggles = useTypedSelector(
		(state) => state.archive.orders.player.toggle,
	);
	const orderLoading = useTypedSelector(
		(state) => state.archive.orders.loadings,
	);

	const resetToggles = useCallback<UseArchiveSwitcher["resetToggles"]>(() => {
		dispatch(archive.orders.toggles.resetToggles({}));
	}, [dispatch]);

	const setOrdersPlayerToggle = useCallback<
		UseArchiveSwitcher["setOrdersPlayerToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.player.setOrderPlayerToggle(data));
		},
		[dispatch],
	);

	const setPlayerToggle = useCallback<UseArchiveSwitcher["setPlayerToggle"]>(
		(data: ValueToggle) => {
			dispatch(archiveGPS.setPlayerToggle(data));
		},
		[dispatch],
	);

	const setDisabledOrderFormToggle = useCallback<
		UseArchiveSwitcher["setDisabledOrderFormToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setIsDisabledOrderFormToggle(data));
		},
		[dispatch],
	);

	const setInfoModalToggle = useCallback<
		UseArchiveSwitcher["setInfoModalToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setInfoModalToggle(data));
		},
		[dispatch],
	);

	const setOrdersLoading = useCallback<
		UseArchiveSwitcher["setOrdersLoading"]
	>(
		(data: boolean) => {
			dispatch(archive.orders.loadings.setIsLoadingOrders(data));
		},
		[dispatch],
	);

	const setOrderLoading = useCallback<UseArchiveSwitcher["setOrderLoading"]>(
		(data: boolean) => {
			dispatch(archive.orders.loadings.setIsLoadingOrder(data));
		},
		[dispatch],
	);

	const setFilterModalToggle = useCallback<
		UseArchiveSwitcher["setFilterModalToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setFilterModalToggle(data));
		},
		[dispatch],
	);

	const setSettingModalToggle = useCallback<
		UseArchiveSwitcher["setSettingModalToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setSettingModalToggle(data));
		},
		[dispatch],
	);

	const setSubscribeToggle = useCallback<
		UseArchiveSwitcher["setSubscribeToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setSubscribeToggle(data));
		},
		[dispatch],
	);

	const setSearchToggle = useCallback<UseArchiveSwitcher["setSearchToggle"]>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setSearchToggle(data));
		},
		[dispatch],
	);

	const setExportModalToggle = useCallback<
		UseArchiveSwitcher["setExportModalToggle"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setExportModalToggle(data));
		},
		[dispatch],
	);

	const setSaveOrderForm = useCallback<
		UseArchiveSwitcher["setSaveOrderForm"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setIsSaveOrderForm(data));
		},
		[dispatch],
	);

	const setButtonSaveOrderForm = useCallback<
		UseArchiveSwitcher["setButtonSaveOrderForm"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setIsButtonSaveOrderForm(data));
		},
		[dispatch],
	);

	const setCancelOrderForm = useCallback<
		UseArchiveSwitcher["setCancelOrderForm"]
	>(
		(data: ValueToggle) => {
			dispatch(archive.orders.toggles.setIsCancelOrderForm(data));
		},
		[dispatch],
	);

	return {
		playerToggle,
		playerOrdersToggles,
		orderToggles,
		orderLoading,
		resetToggles,
		setPlayerToggle,
		setExportModalToggle,
		setInfoModalToggle,
		setOrdersLoading,
		setOrderLoading,
		setSearchToggle,
		setSubscribeToggle,
		setSettingModalToggle,
		setFilterModalToggle,
		setDisabledOrderFormToggle,
		setButtonSaveOrderForm,
		setSaveOrderForm,
		setCancelOrderForm,
		setOrdersPlayerToggle,
	};
};

export default useArchiveSwitcher;
