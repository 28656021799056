/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Reports } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	reportData: [],
};

const setReportsData: ReduxReducer<State, State> = (state, action) => {
	state.reportData = action.payload.reportData;
};

const reportCashlessOrdersGeneralData = createSlice({
	name: Reports.ConstantReportSlice.CASHLESS_ORDERS_GENERAL,
	initialState,
	reducers: {
		setReportsData,
	},
});

export const reportCashlessOrdersGeneralDataAction =
	reportCashlessOrdersGeneralData.actions;
export const reportCashlessOrdersGeneralDataReducer =
	reportCashlessOrdersGeneralData.reducer;
export type reportCashlessOrdersGeneralDataState = State;

export default reportCashlessOrdersGeneralData;
