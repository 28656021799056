/* eslint-disable no-shadow */

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import keybinds, {
	keybindGroups,
	keybindMeta,
	KeybindMeta,
	KeybindState,
} from "../../../../../redux/reducers/settings/keybinds";
import { useTypedSelector } from "../../../../../redux/store";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
import useSnapshot from "../../../../../hooks/useSnapshot";
import SettingList from "../../../../../components/Settings/SettingList";

const KeybindSettings: React.FC<SettingsTabProps> = ({
	methods,
	onHasChanges,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [snapshot, setSnapshot] = useSnapshot({
		state: useTypedSelector((state) => state.settings.keybinds),
		methods,
		onHasChanges,
		onSave(snapshot) {
			dispatch(keybinds.actions.setAll(snapshot));
		},
		onResetDefaults() {
			dispatch(keybinds.actions.resetDefaults());
			return keybinds.getInitialState();
		},
	});

	return (
		<>
			<SettingList<never, never, KeybindState, KeybindMeta>
				state={snapshot}
				meta={keybindMeta}
				groups={keybindGroups}
				name={(key) => t(`settings.interface.keybinds.keys.${key}`)}
				onChange={(newValue, key) => {
					setSnapshot((prev) =>
						prev[key] === newValue
							? prev
							: { ...prev, [key]: newValue },
					);
				}}
			/>
		</>
	);
};

export default KeybindSettings;
