import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { clone } from "lodash";

import { useTypedDispatch, useTypedSelector } from "../redux/store";
import tableColumns from "../redux/reducers/TableColumns";
import Nullable from "../types/Nullable";
import TableSortMethod from "../types/SortMethod";
import TableWidths from "../types/TableWidths";
import StringWithSuggestions from "../types/StringWithSuggestions";
import { ColumnId, Tables } from "../constants/tables/types";
import tables from "../constants/tables";

/**
 * Used to get columns with localized labels
 * @param tableId id of the table to be used
 * @returns columns of the table
 */
export function useColumns<TI extends keyof Tables>(tableId: TI) {
	const { t } = useTranslation();

	return useMemo(
		() =>
			tables[tableId].columns.map((column) => ({
				...column,
				label: t(column.label),
			})),
		[t, tableId],
	);
}

/**
 * @param saveSlot key under which table data will be stored
 * @param tableId id of the table to be used
 * @returns getter and setter of visible column ids of the table
 */
export function useVisibleColumns<
	TableId extends keyof Tables,
	// eslint-disable-next-line no-shadow
	ColumnIds extends Tables[TableId]["allIds"],
>(saveSlot: string, tableId: TableId, defaultIds?: ColumnIds) {
	const dispatch = useTypedDispatch();
	const nullableColumnIds = useTypedSelector(
		(state) => state.tableColumns.visible[saveSlot],
	) as Nullable<ColumnIds>;

	const columnIds = useMemo(
		() =>
			nullableColumnIds ||
			clone(defaultIds) ||
			(tables[tableId].defaultIds as ColumnIds),
		[defaultIds, nullableColumnIds, tableId],
	);
	const setColumnIds = useCallback(
		(newColumns: StringWithSuggestions<ColumnIds[number]>[]) => {
			dispatch(
				tableColumns.actions.setVisibleColumns({
					uid: saveSlot,
					columns: newColumns,
				}),
			);
		},
		[dispatch, saveSlot],
	);

	return useMemo(
		() => ({ columnIds, setColumnIds }),
		[columnIds, setColumnIds],
	);
}

/**
 * @param saveSlot key under which table data will be stored
 * @param tableId id of the table to be used
 * @returns getter and setter of a sort method of the table
 */
export function useSort<
	TableId extends keyof Tables,
	SortMethod extends TableSortMethod<ColumnId<TableId>>,
>(saveSlot: string, tableId: TableId) {
	const dispatch = useTypedDispatch();
	const nullableSortMethod = useTypedSelector(
		(state) => state.tableColumns.sortMethod[saveSlot],
	) as Nullable<SortMethod>;

	const sortMethod = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		() =>
			(nullableSortMethod ||
				(tables[tableId].initialSortMethod as SortMethod)) ??
			{},
		[nullableSortMethod, tableId],
	);
	const setSortMethod = useCallback(
		(
			newMethod: TableSortMethod<
				StringWithSuggestions<ColumnId<TableId>>
			>,
		) => {
			dispatch(
				tableColumns.actions.setSortMethod({
					uid: saveSlot,
					method: newMethod,
				}),
			);
		},
		[dispatch, saveSlot],
	);

	return useMemo(
		() => ({ sortMethod, setSortMethod }),
		[sortMethod, setSortMethod],
	);
}

/**
 * @param saveSlot key under which table data will be stored
 * @param tableId id of the table to be used
 * @returns getter and setter of column widths of the table
 */
export function useWidths<
	TableId extends keyof Tables,
	Widths extends Tables[TableId]["initialWidths"],
>(saveSlot: string, tableId: TableId) {
	const dispatch = useTypedDispatch();
	const nullableWidths = useTypedSelector(
		(state) => state.tableColumns.widths[saveSlot],
	) as TableWidths<ColumnId<TableId>>;

	const widths = useMemo<TableWidths<ColumnId<TableId>>>(
		() => (nullableWidths || tables[tableId].initialWidths) ?? {},
		[nullableWidths, tableId],
	);

	const setWidths = useCallback(
		(newWidths: Widths) => {
			dispatch(
				tableColumns.actions.setWidths({
					uid: saveSlot,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					widths: newWidths!,
				}),
			);
		},
		[dispatch, saveSlot],
	);

	return useMemo(() => ({ widths, setWidths }), [widths, setWidths]);
}

// TODO: Should be add the required types
export const useTableSetting = (savaSlot: string, tableId: keyof Tables) => {
	const { columnIds, setColumnIds } = useVisibleColumns(savaSlot, tableId);
	const { sortMethod, setSortMethod } = useSort(savaSlot, tableId);
	const { widths, setWidths } = useWidths(savaSlot, tableId);
	const columns = useColumns(tableId);

	return useMemo(
		() => ({
			columnIds,
			sortMethod,
			widths,
			columns,
			tables,
			setColumnIds,
			setSortMethod,
			setWidths,
		}),
		[
			setColumnIds,
			setSortMethod,
			setWidths,
			columnIds,
			sortMethod,
			widths,
			columns,
		],
	);
};
