/* eslint-disable prettier/prettier */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "rsuite-table";
import { CheckBox, Row } from "uikit";

import LightTable from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";
import TariffScaleTab from "../..";
import { getTariffsScaleOptions } from "../Modal/components/Content/constants";
import { convertFromType } from "../Modal/components/Content";

import Root from "./components/Root";
import { columns, defaultColumnsId } from "./constants";

const Content: React.FC<Content.Props> = ({
	intervals,
	selectedIntervals,
	isForAllRoute,
	onEdit,
	setSelectedIntervals: setSelected,
}) => {
	const { t } = useTranslation();
	const tariffsScaleOptions = useMemo(() => getTariffsScaleOptions(t), [t]);

	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelectedIntervals = useCallback(
		(index: number) => {
			if (selectedIntervals.includes(index)) {
				setSelected(
					selectedIntervals.filter((value) => index !== value),
				);
			} else {
				const newSelected = [...selectedIntervals];
				newSelected.push(index);
				setSelected(newSelected);
			}
		},
		[setSelected, selectedIntervals],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<Column
						key={columnId}
						verticalAlign="middle"
						width={column.width || 150}
						// flexGrow={columnId === "minimal" ? 1 : 0}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{column.label}
							{!column.label && (
								<CheckBox
									value={
										selectedIntervals.length ===
											intervals.length &&
										selectedIntervals.length !== 0
									}
									onChange={(val) => {
										if (val) {
											setSelected(
												intervals.map((_, i) => i),
											);
										} else {
											setSelected([]);
										}
									}}
								/>
							)}
						</LightTable.HeaderCell>
						<LightTable.Cell style={{ padding: "0 25px" }}>
							{(rowData) => {
								switch (columnId) {
									case "checkbox":
										return (
											<CheckBox
												value={selectedIntervals.includes(
													intervals.indexOf(
														rowData as TariffScaleTab.Interval,
													),
												)}
												onChange={() => {
													updateSelectedIntervals(
														intervals.indexOf(
															rowData as TariffScaleTab.Interval,
														),
													);
												}}
											/>
										);
									case "to":
										return rowData.value;
									case "baseSettlement":
										return rowData.baseSettlement.type ? (
											<Row align="center" gaps="10px*">
												<span>
													{
														tariffsScaleOptions.find(
															(option) =>
																option.key ===
																convertFromType[
																	rowData
																		.baseSettlement
																		.type
																],
														)?.label
													}
													:
												</span>
												<span>
													{
														rowData.baseSettlement
															.value
													}
												</span>
											</Row>
										) : (
											<div>
												{isForAllRoute
													? t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str200",
													  ) ?? ""
													: t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str201",
													  ) ?? ""}
											</div>
										);
									case "outsideSettlement":
										return rowData.outsideSettlement
											.type ? (
											<Row align="center" gaps="10px*">
												<span>
													{
														tariffsScaleOptions.find(
															(option) =>
																option.key ===
																convertFromType[
																	rowData
																		.outsideSettlement
																		.type
																],
														)?.label
													}
													:
												</span>
												<span>
													{
														rowData
															.outsideSettlement
															.value
													}
												</span>
											</Row>
										) : (
											<div>
												{isForAllRoute
													? t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str200",
													  ) ?? ""
													: t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str201",
													  ) ?? ""}
											</div>
										);
									case "outsideSettlementRoundTrip":
										return rowData
											.outsideSettlementRoundTrip.type ? (
											<Row align="center" gaps="10px*">
												<span>
													{
														tariffsScaleOptions.find(
															(option) =>
																option.key ===
																convertFromType[
																	rowData
																		.outsideSettlementRoundTrip
																		?.type
																],
														)?.label
													}
													:
												</span>
												<span>
													{
														rowData
															.outsideSettlementRoundTrip
															?.value
													}
												</span>
											</Row>
										) : (
											<div>
												{isForAllRoute
													? t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str200",
													  ) ?? ""
													: t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str201",
													  ) ?? ""}
											</div>
										);
									case "minimal":
										return rowData.minimal.type ? (
											<Row align="center" gaps="10px*">
												<span>
													{
														tariffsScaleOptions.find(
															(option) =>
																option.key ===
																convertFromType[
																	rowData
																		.minimal
																		?.type
																],
														)?.label
													}
													:
												</span>
												<span>
													{rowData.minimal?.value}
												</span>
											</Row>
										) : (
											<div>
												{isForAllRoute
													? t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str200",
													  ) ?? ""
													: t(
															"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.content.str201",
													  ) ?? ""}
											</div>
										);
									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</Column>
				);
			}),
		[
			columnsId,
			selectedIntervals,
			intervals,
			setSelected,
			tariffsScaleOptions,
			isForAllRoute,
			t,
			updateSelectedIntervals,
		],
	);

	return (
		<Root sizes="1fr auto!">
			<div>
				<LightTable
					data={intervals}
					virtualized
					headerHeight={44}
					rowHeight={44}
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={(rowData) =>
						rowData &&
						selectedIntervals.includes(intervals.indexOf(rowData))
							? "selected"
							: ""
					}
					onRowClick={(invl) => {
						updateSelectedIntervals(intervals.indexOf(invl));
					}}
					onRowDoubleClick={(invl) => {
						onEdit && onEdit(intervals.indexOf(invl));
					}}
				>
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
					{renderColumns}
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
				</LightTable>
			</div>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={columns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		intervals: TariffScaleTab.Interval[];
		isForAllRoute: boolean;
		onEdit?: (x: number) => void;
		selectedIntervals: TariffScaleTab.SelectedIntervals;
		setSelectedIntervals: Dispatch<TariffScaleTab.SelectedIntervals>;
	}

	interface ColumnType {
		id: string;
		label: string;
	}
}

export default Content;
