import makeTable from "./makeTable";

const reward = makeTable({
	allIds: [
		"active",
		"default",
		"title",
		"rewardCalculationMethod",
		"amount",
		"percent",
		"maxAmountFromOrderInPercent",
	],
	i18tPath: (id) => `tableColumns.customers.rewards.${id}`,
});

export default reward;
