import tPath from "./tPath";

export interface ErrorResponse {
	message: string;
	status: 400 | 404 | 401 | 403 | 409 | 500;
	baseText: string;
	translation: string;
	canRetry: boolean;
}

type RequestErrorContext = "create" | "update";

export const unknownErrors: Record<RequestErrorContext, ErrorResponse> = {
	create: {
		message: "",
		status: 400,
		baseText: "Failed to create a new tariff. Please try again.",
		translation: `${tPath}.clarificationModal.create`,
		canRetry: true,
	},
	update: {
		message: "",
		status: 400,
		baseText: "Failed to change the tariff. Please try again.",
		translation: `${tPath}.clarificationModal.update`,
		canRetry: true,
	},
};

const errors: ErrorResponse[] = [
	{
		message: "Non-default rate should contain all or only one taxi service",
		status: 400,
		baseText:
			"Non-default tariff should contain all or only one taxi service",
		translation: `${tPath}.clarificationModal.nonDefaultTariffShouldContainAllOrOneService`,
		canRetry: false,
	},
	{
		message: "Cannot change car classes in the default rate",
		status: 400,
		baseText: "Cannot change car classes in the default tariff",
		translation: `${tPath}.clarificationModal.cannotChangeCarClassesInDefaultTariff`,
		canRetry: false,
	},
	{
		message: "Cannot create rate without taxi services and car classes",
		status: 400,
		baseText: "Cannot create tariff without taxi services and car classes",
		translation: `${tPath}.clarificationModal.emptyTaxiServiceAndClasses`,
		canRetry: false,
	},
	{
		message: "Default rate should contain all car classes",
		status: 400,
		baseText: "Default tariff should contain all car classes",
		translation: `${tPath}.clarificationModal.defaultTariffShouldContainsAllClasses`,
		canRetry: false,
	},
	{
		message: "Incorrect car classes for the default rate",
		status: 400,
		baseText: "",
		translation: `${tPath}.clarificationModal.classes`,
		canRetry: false,
	},
	{
		message: "Rate conflicts with other rates.",
		status: 400,
		baseText: "Tariff conflicts with other tariffs.",
		translation: `${tPath}.clarificationModal.conflict`,
		canRetry: false,
	},
	{
		message:
			"Rate conflicts with other rates. Use 'force' flag to override them",
		status: 400,
		baseText: "Are you sure you want to overwrite existing tariff(-s)?",
		translation: `${tPath}.clarificationModal.title`,
		canRetry: true,
	},
	{
		message: "Cannot create another default rate",
		status: 400,
		baseText: "This tariff exist!",
		translation: `${tPath}.clarificationModal.store`,
		canRetry: false,
	},
];

/**
 * Retrieve prepared error by message
 * @param message Response error message from server
 * @param isEdit Flag to determine if the error is from edit or create
 */
export const getErrorByMessage = (message: string, isEdit: boolean) => {
	const error = errors.find((error) => error.message === message);
	if (!error) {
		const context = isEdit ? "update" : "create";
		return unknownErrors[context];
	}
	return error;
};

export default errors;
