import { DisplayFields, AccessKey } from ".";

/**
 * Merges multiple user roles into a single 'super role'.
 *
 * This function is used to combine the access rights of multiple roles a user may have,
 * ensuring that the resulting access permissions encompass the most permissive rights
 * across all roles. For example, if a user has several roles, and one role grants access
 * to certain fields or functionalities, while another role does not, this function
 * will merge them, allowing the user to access more parts of the system.
 *
 * The primary goal is to extend the user's access by combining roles so that if any of
 * the roles permit access to a specific field or feature, that permission will be
 * granted in the resulting 'super role'.
 *
 * @param dataObjects - An array of user roles represented as DisplayFields objects.
 * @returns A merged DisplayFields object that reflects the most permissive access rights
 * across all the provided roles.
 */

const mergeAccessRolesUser = (dataObjects: DisplayFields[]): DisplayFields => {
	const result: DisplayFields = {};

	dataObjects.forEach((data) => {
		Object.keys(data).forEach((key) => {
			if (!result[key]) {
				result[key] = JSON.parse(JSON.stringify(data[key]));
			} else {
				result[key][AccessKey.SHOW] =
					result[key][AccessKey.SHOW] || data[key][AccessKey.SHOW];
			}

			if (typeof data[key] === "object" && !Array.isArray(data[key])) {
				Object.keys(data[key]).forEach((subKey) => {
					if (subKey !== AccessKey.SHOW) {
						if (!result[key][subKey]) {
							result[key][subKey] = JSON.parse(
								JSON.stringify(data[key][subKey]),
							);
						} else {
							result[key][subKey][AccessKey.SHOW] =
								result[key][subKey][AccessKey.SHOW] ||
								data[key][subKey][AccessKey.SHOW];
						}
					}
				});
			}
		});
	});

	return result;
};

export default mergeAccessRolesUser;
