import React, { createContext, useContext, useState } from "react";

import NotificationContainer from "../NotificationContainer";

interface NotificationContextProps {
	success: (message: string, description: string) => void;
	info: (message: string, description: string) => void;
	warn: (message: string, description: string) => void;
	error: (message: string, description: string) => void;
}

interface Notification {
	id: number;
	type: "success" | "info" | "warn" | "error";
	message: string;
	description: string;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
	undefined,
);

export const useNotification = () => {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error(
			"useNotification must be used within a NotificationProvider",
		);
	}
	return context;
};

export const NotificationProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [exitingNotificationId, setExitingNotificationId] = useState<
		number | null
	>(null);

	const showNotification = (
		type: Notification["type"],
		message: string,
		description: string,
	) => {
		const id = Date.now();
		setNotifications((prevNotifications) => [
			...prevNotifications,
			{ id, type, message, description },
		]);

		setTimeout(() => {
			setExitingNotificationId(id);
			setTimeout(() => {
				setNotifications((prevNotifications) =>
					prevNotifications.filter(
						(notification) => notification.id !== id,
					),
				);
				setExitingNotificationId(null);
			}, 500);
		}, 5000);
	};

	const value = {
		success: (message: string, description: string) =>
			showNotification("success", message, description),
		info: (message: string, description: string) =>
			showNotification("info", message, description),
		warn: (message: string, description: string) =>
			showNotification("warn", message, description),
		error: (message: string, description: string) =>
			showNotification("error", message, description),
	};

	return (
		<NotificationContext.Provider value={value}>
			{children}
			{notifications.map((notification, index) => (
				<NotificationContainer
					key={notification.id}
					type={notification.type}
					message={notification.message}
					description={notification.description}
					style={{
						top: `${index * 70 + 20}px`,
						marginTop: `${index * 15}px`,
					}}
					isExiting={notification.id === exitingNotificationId}
				/>
			))}
		</NotificationContext.Provider>
	);
};
