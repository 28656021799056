/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Reports } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	reportData: [],
};

const setReportsData: ReduxReducer<State, State> = (state, action) => {
	state.reportData = action.payload.reportData;
};

const reportAgentBillReportData = createSlice({
	name: Reports.ConstantReportSlice.AGENT_BILL_REPORT,
	initialState,
	reducers: {
		setReportsData,
	},
});

export const reportAgentBillReportDataAction =
	reportAgentBillReportData.actions;
export const reportAgentBillReportDataReducer =
	reportAgentBillReportData.reducer;
export type reportAgentBillReportDataState = State;

export default reportAgentBillReportData;
