import { extend, isNumber, isString, isUndefined } from "lodash";
import { v4 as uuid } from "uuid";
import React, { PropsWithChildren, memo, useEffect, useMemo } from "react";

import KeyBinder from "../../services/KeyBinder";

import LocalContext from "./Context";

const KeyBindLayerBase: React.FC<KeyBindLayer.Props> = ({
	id,

	priority,
	propagate = false,

	children,
}) => {
	const defaultId = useMemo(() => uuid(), []);

	const internalId = useMemo(
		() => (isString(id) ? id : defaultId),
		[id, defaultId],
	);

	const defaultPriority = useMemo(() => {
		const { highestLayerPriority } = KeyBinder;

		if (isUndefined(highestLayerPriority)) return 0;

		return highestLayerPriority + 1;
	}, []);

	const internalPriority = useMemo(
		() => (isNumber(priority) ? priority : defaultPriority),
		[defaultPriority, priority],
	);

	KeyBinder.registerLayer(internalId, {
		priority: internalPriority,
		propagate,
	});

	useEffect(() => () => KeyBinder.unregisterLayer(internalId), [internalId]);

	const contextValue = useMemo(() => ({ layerId: internalId }), [internalId]);

	return (
		<LocalContext.Provider value={contextValue}>
			{children}
		</LocalContext.Provider>
	);
};

const KeyBindLayerMemo = memo(KeyBindLayerBase);

const KeyBindLayer = extend(KeyBindLayerMemo, { Context: LocalContext });

declare namespace KeyBindLayer {
	interface Props extends PropsWithChildren {
		id?: string;

		priority?: number;
		propagate?: boolean;
	}

	namespace Context {
		type Context = LocalContext.Value;
	}
}

export default KeyBindLayer;
