import React from "react";
import { Icon, theme } from "uikit";
import Color from "color";
import Root from "./components/Root";

const darkIconColor = theme.colors.primary;
const lightIconColor = theme.colors.white;

const Type: React.FC<Type.Props> = ({ backgroundColor }) => {
	const isBackgroundColorLight = Color(backgroundColor).isLight();

	const iconColor = isBackgroundColorLight ? darkIconColor : lightIconColor;

	return (
		<Root backgroundColor={backgroundColor}>
			<Icon id="car" size={20} colors={[iconColor]} />
		</Root>
	);
};

declare namespace Type {
	interface Props {
		backgroundColor: string;
	}
}

export default Type;
