import { react } from "uikit";
import { RefObject } from "react";

import CompanyBranch from "../../../../../../../../components/CompanyBranch";
import Name from "../../../../../../../../components/Name";

import AutoClasses from "./components/AutoClasses";

interface Context {
	nameRef: RefObject<Name.Ref | null>;
	companyBranchRef: RefObject<CompanyBranch.Ref | null>;
	autoClassesRef: RefObject<AutoClasses.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = !!this.context?.nameRef.current?.validate();

		const isCompanyBranchValid =
			!!this.context?.companyBranchRef.current?.validate();

		const isAutoClassesValid =
			!!this.context?.autoClassesRef.current?.validate();

		return isNameValid && isCompanyBranchValid && isAutoClassesValid;
	}
}
