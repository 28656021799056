import { theme } from "uikit";
import Color from "color";

export interface CalculateNameColorOptions {
	active: boolean;
	selected: boolean;
	modified: boolean;
	valid: boolean;
}

export function calculateNameColor(options: CalculateNameColorOptions) {
	if (options.selected) return theme.colors.accent;

	if (!options.valid) return theme.colors.negative;

	if (options.modified) return "#F89C27";

	if (!options.active) return theme.colors.disabled_text;

	return theme.colors.primary;
}

export function calculateSubnameColor(options: CalculateNameColorOptions) {
	const nameColor = calculateNameColor(options);

	if (nameColor === theme.colors.primary) return theme.colors.secondary;

	const color = new Color(nameColor);

	return color.lighten(0.25).hex();
}

export default {
	calculateNameColor,
};
