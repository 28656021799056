import TariffScaleTab from "..";

const defaultInterval: TariffScaleTab.Interval = {
	active: true,
	value: 1,
	minimal: {
		active: false,
		type: null,
		value: 0,
		minDistance: 0,
	},
	baseSettlement: {
		active: false,
		type: null,
		value: 0,
	},
	outsideSettlement: {
		active: false,
		type: null,
		value: 0,
	},
	outsideSettlementRoundTrip: {
		active: false,
		type: null,
		value: 0,
	},
};

export default defaultInterval;
