import React, { memo, useMemo } from "react";

import Sector from "../../../../../../../../../../../../../../../../../../../../../../services/Sector";
import mapByKey from "../../../../../../../../../../../../../../../../../../../../../../utils/mapByKey";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../../../../../redux/store";

const SectorItems: React.FC<SectorItems.Props> = memo(({ value, sectors }) => {
	const lang = useTypedSelector((state) => state.session.language);
	const modelItemById = useMemo(
		() => mapByKey(sectors ?? [], "id"),
		[sectors],
	);

	return value.length ? (
		<div>
			{value
				.map((id) => modelItemById?.[id]?.name?.[lang] || "")
				.join(", ")}
		</div>
	) : null;
});

declare namespace SectorItems {
	interface Props {
		value: number[];
		sectors?: Sector.Model[];
	}
}

export default SectorItems;
