import React, {
	CSSProperties,
	useEffect,
	memo,
	useState,
	useCallback,
} from "react";
import styled from "styled-components";
import { Button, Icon } from "uikit";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { StyledRow } from "../../../common";

const StyledRangeInput = styled.input`
	flex-grow: 1;
`;

const PlayerControlBase: React.FC<PlayerControlBase.Props> = ({
	isPlaying: isPlayingExternal = false,
	position = 0,
	max = 1,
	style,
	time = 60000,
	onChangeIsPlaying,
	onChangeSpeed,
	onChangePosition,
	onChangeRun,
}): JSX.Element => {
	const [isPlaying, setIsPlaying] = useState(isPlayingExternal);
	const [pos, setPos] = useState(position);

	const [multiplier, setMultiplier] = useState(1);

	const [debouncedSpeed] = useDebounce(multiplier, 200);

	useEffect(() => {
		const frameTime = time / debouncedSpeed;
		let interval: NodeJS.Timer | null = null;

		if (isPlaying && !interval) {
			if (pos >= max) setPos(0);
			if (interval) clearInterval(interval);
			interval = setInterval(() => {
				setPos((prev) => {
					let newValue = prev + 1;
					if (newValue >= max) {
						newValue = max;
						setIsPlaying(false);
					}
					return newValue;
				});
			}, frameTime);
		}
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [debouncedSpeed, isPlaying, max, pos, time]);

	useEffect(() => {
		onChangeIsPlaying?.(isPlaying);
	}, [isPlaying, onChangeIsPlaying]);

	useEffect(() => {
		onChangePosition?.(pos);
	}, [onChangePosition, pos]);

	useEffect(() => {
		onChangeSpeed?.(debouncedSpeed);
	}, [onChangeSpeed, debouncedSpeed]);

	const handlerPlaying = useCallback(() => {
		if (setIsPlaying) setIsPlaying((prev) => !prev);
	}, []);

	const handlerPosition = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setPos(() => Number(event.target.value));
		},
		[],
	);

	const debouncedPosition = useDebouncedCallback(() => {
		onChangeRun?.(false);
	}, 50);

	const handlerMultiplier = useCallback(() => {
		setMultiplier((prev) => (Math.floor(prev) % 32) + 1);
	}, []);

	return (
		<StyledRow
			gap="10px"
			justify="stretch"
			alignItems="center"
			w="100%"
			p="10px"
			style={{ ...style }}
		>
			<Button.Button
				variant="secondary"
				icon={<Icon id={isPlaying ? "pause" : "play"} size={20} />}
				onClick={handlerPlaying}
			/>

			<StyledRangeInput
				type="range"
				step={1}
				min={0}
				max={max}
				value={pos}
				onChange={(event) => {
					handlerPosition(event);
					onChangeRun?.(true);
					debouncedPosition();
				}}
				width={"100%"}
			/>

			<Button.Button
				variant="secondary"
				text={`x${multiplier}`}
				onClick={handlerMultiplier}
			/>
		</StyledRow>
	);
};

declare namespace PlayerControlBase {
	interface Props {
		isPlaying?: boolean;
		position?: number;
		max: number;
		style?: CSSProperties;
		time?: number;
		onChangeIsPlaying?: (value: boolean) => void;
		onChangeRun?: (value: boolean) => void;
		onChangePosition?: (value: number) => void;
		onChangeSpeed?: (value: number) => void;
	}
}

export const PlayerControl = memo(PlayerControlBase);
export default PlayerControlBase;
