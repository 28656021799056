import { Option } from "uikit";
import { TFunction } from "i18next";

const getStatuses = (
	t: TFunction,
): Option<"active" | "blocked" | "dismissed">[] => [
	{
		key: "active",
		value: "active",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.active",
			) ?? "",
	},
	{
		key: "blocked",
		value: "blocked",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.blocked",
			) ?? "",
	},
	{
		key: "dismissed",
		value: "dismissed",
		label:
			t(
				"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.dismissed",
			) ?? "",
	},
]; // statuses

export default getStatuses;
