import styled from "styled-components";
import { Row, theme } from "uikit";

const Label = styled(Row)`
	width: 100%;

	font-family: Inter;
	font-size: 13px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;

	color: ${theme.colors.primary};

	cursor: pointer;

	user-select: none;
`;

export default Label;
