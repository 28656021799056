import React, { memo, PropsWithChildren, useMemo } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import { Style, StyledRow, StyledSpan } from "../styles";

const StyledRadioButton = styled.input<{ disabled: boolean }>`
	display: flex;

	height: 16px;
	width: 16px;

	cursor: pointer;
	user-select: none;

	appearance: none;
	-webkit-appearance: none;

	border-radius: 50%;

	&:focus {
		box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
		transition: box-shadow 0.1s ease;
	}

	&:not(:focus) {
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
	}

	${({ checked, disabled }) => {
		if (checked && disabled) {
			return css`
				background: #647079;
				border: 5px solid #cccccc;
			`;
		}

		if (!checked && disabled) {
			return css`
				background: #e9e9e9;
				border: 1px solid #cccccc;
			`;
		}

		if (!checked && !disabled) {
			return css`
				background: #fffff;
				border: 1px solid rgb(155, 163, 168);
			`;
		}

		return css`
			background: #fffff;
			border: 5px solid #03a9f4;
		`;
	}}
`;

const RadioButtonBase: React.FC<RadioButtonBase.Props> = ({
	value = false,
	onChange,
	disabled = false,
	title,
	children,
	titlePosition = "right",
	id,
	...props
}) => {
	const { t } = useTranslation();

	const text = useMemo(() => t(title), [t, title]);

	const colorText = useMemo(() => {
		if (disabled) return "#647079";
		if (value) return "#21333f";
		return "#647079";
	}, [value, disabled]);

	return (
		<StyledRow alignItems="center" gap="10px" {...props}>
			{titlePosition === "left" && (
				<StyledSpan colors={colorText}>{text}</StyledSpan>
			)}
			<StyledRadioButton
				id={id}
				disabled={disabled}
				type="radio"
				checked={value}
				onClick={() => {
					if (onChange) onChange();
				}}
			/>
			{titlePosition === "right" && (
				<StyledSpan colors={colorText}>{text}</StyledSpan>
			)}
			{children}
		</StyledRow>
	);
};

export const RadioButton = memo(RadioButtonBase);

declare namespace RadioButtonBase {
	type Value = boolean;

	interface Props extends Style.Row, PropsWithChildren {
		value?: Value;
		onChange?: () => void;
		title: string | string[];
		disabled?: boolean;
		titlePosition?: "left" | "right";
		id?: string;
	}
}

export default RadioButtonBase;
