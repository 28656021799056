/* eslint-disable no-shadow */
import React, {
	Dispatch,
	forwardRef,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import Language from "../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { getTabsModeOne } from "../../../../../../../../utils";
import Modal from "../../../../../../../../components/ModalController/Modal";
import { StyledGrid } from "../../../../../../../../components/common";

import Content from "./components/Content";
import Footer from "./components/Footer";

const EditModal = forwardRef<EditModal.Controller, EditModal.Props>(
	({ value, language, onChange, onCancel, onSave, sectorDows }, ref) => {
		const { t } = useTranslation();

		const tabOptions = useMemo(() => {
			const selectedTabs: Parameters<typeof getTabsModeOne>[1] = [
				"main",
				"averageSpeed",
			];

			return getTabsModeOne(t, selectedTabs);
		}, [t]);

		const [type, setType] = useState<EditModal.Type>("main");

		const valueEditor = useObjectEditor(value, onChange);

		const onSelectTab = useCallback(
			(option: Tabs.Option<any>) => setType(option.value),
			[setType],
		);

		const footerValue = valueEditor.usePicker(["active"]);
		const assigner = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<StyledGrid
					areas=""
					rows="50px 1fr 50px"
					w="800px"
					h="650px"
					br="5px"
					bgC="#ffffff"
					over="hidden"
				>
					<Tabs
						options={tabOptions}
						value={type}
						onChangeSelectedOption={onSelectTab}
						variant="panels"
					/>

					<Content
						ref={ref}
						type={type}
						language={language}
						value={value}
						onChange={assigner}
						sectorDows={sectorDows}
					/>

					<Footer
						value={footerValue}
						onChange={assigner}
						onCancel={onCancel}
						onSave={onSave}
					/>
				</StyledGrid>
			),
			[
				tabOptions,
				type,
				onSelectTab,
				ref,
				language,
				value,
				footerValue,
				assigner,
				onCancel,
				onSave,
				sectorDows,
			],
		);

		return <Modal dimmer>{content}</Modal>;
	},
);

declare namespace EditModal {
	type Type = "main" | "averageSpeed";

	interface Value extends Content.Value, Footer.Value {}

	interface Props {
		value: Value;
		sectorDows: Content.Props["sectorDows"];

		language: Language;

		onChange: Dispatch<Value>;

		onCancel: () => void;
		onSave: () => void;
	}

	type Controller = Content.Controller;
}

export default EditModal;
