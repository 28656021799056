import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "uikit";

import Card from "./components/Card";

const NoRenderer: React.FC<NoRenderer.Props> = () => {
	const { t } = useTranslation();
	return (
		<Flex align="center" justify="center" maxedWidth maxedHeight>
			<Card>{t("fileViewerModal.noRenderer.str100") ?? ""}</Card>
		</Flex>
	);
};

declare namespace NoRenderer {
	interface Props {}
}

export default NoRenderer;
