import React from "react";
import { ColumnProps, RowDataType } from "rsuite-table";

import { IOrderPoint as OrderPoint } from "../../../../../../../../redux/constants/OrdersPage/order";
import { TranslationText } from "../../../../../../../common";
import CompactTable from "../../../../../../../CompactTable";

import columns, { ColumnId } from ".";

function base(
	columnId: ColumnId,
	render: base.Render,
	column: Partial<ColumnProps<RowDataType>>,
) {
	return (
		<CompactTable.Column key={columnId} resizable {...column}>
			<CompactTable.HeaderCell>
				<TranslationText
					text={[
						columns[columnId].pathTranslation,
						columns[columnId].label,
					]}
				/>
			</CompactTable.HeaderCell>
			<CompactTable.Cell dataKey={columnId} fullText>
				{(rowData, index) => render(rowData as OrderPoint, index)}
			</CompactTable.Cell>
		</CompactTable.Column>
	);
}

declare namespace base {
	type Render = (
		item: OrderPoint,
		index: number | undefined,
	) => React.ReactNode;
}

export default base;
