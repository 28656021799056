import { useEffect, useMemo } from "react";
import { LatLngLiteral, Map } from "leaflet";
import {
	IOrderPoint,
	getOrderPassengerPoints,
} from "../../../../../redux/constants/OrdersPage/order";
import Order from "../../../../../services/Order";

/**
 *  `useFitBoundsMap`
 */
export const useFitBoundsMap = (
	map: Map | null,
	route: LatLngLiteral[][] | LatLngLiteral[],
	order: Order.Model | null,
	activePoint: number | null,
	zoom: number,
): { point: IOrderPoint | null } => {
	useEffect(() => {
		if (!map || route.length === 0) return;

		const [firstPos, ...rest] = route.flat(1);
		if (!firstPos) return;

		let min = firstPos;
		let max = firstPos;

		rest.forEach((pos) => {
			min = {
				lat: Math.min(min.lat, pos.lat),
				lng: Math.min(min.lng, pos.lng),
			};

			max = {
				lat: Math.max(max.lat, pos.lat),
				lng: Math.max(max.lng, pos.lng),
			};
		});

		if (min.lat === max.lat) {
			min.lat -= 0.01;
			max.lat += 0.01;
		}

		if (min.lng === max.lng) {
			min.lng -= 0.01;
			max.lng += 0.01;
		}

		map.fitBounds([
			[min.lat, min.lng],
			[max.lat, max.lng],
		]);
	}, [map, route]);

	const point = useMemo(() => {
		const isId = activePoint !== null;
		if (!isId) return null;

		const point: IOrderPoint = getOrderPassengerPoints(order)[activePoint];

		if (point && map) {
			map.setView(point?.coordinates, zoom, { animate: true });
		}

		return point;
	}, [activePoint]);

	return useMemo(
		() => ({
			point,
		}),
		[point],
	);
};

export default useFitBoundsMap;
