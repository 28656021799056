import React, {
	RefAttributes,
	memo,
	useCallback,
	useLayoutEffect,
	useState,
} from "react";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import AutoTariff from "../../../../../../../../services/AutoTariff";
import CarClass from "../../../../../../../../services/CarClass";
import Sector from "../../../../../../../../services/Sector";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../components/EditModal";

import tPath from "../../constants/tPath";
import { AutoTariffsTabs as Tabs } from "./tabs";
import InternalController from "./Controller";
import Content from "./components/Content";
import Root from "./components/Root";
import Header from "./components/Header";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		value,
		carClasses,
		sectors,
		onCancel,
		onSave,
		createdAt,
		headerTitle,
		controller,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const { t } = useTranslation();

		const [tab, setTab] = useState<Tabs>(Tabs.MAIN);

		const [internalValue, setInternalValue] = useState(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");
		const rules = valueEditor.useGetter("rules");
		const setRules = valueEditor.useSetter("rules");

		useLayoutEffect(() => {
			const newRules = value.rules;

			const items = rules.map((rule) => {
				const exist = newRules.find((item) => item.id === rule.id);
				if (exist) {
					return { ...rule, carClassIds: exist.carClassIds };
				}
				return rule;
			});

			setRules(items);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.rules]);

		const contentValues = valueEditor.usePicker(
			[
				"name",
				"disableOthers",
				"additionalFields",
				"taxiServiceIds",
				"taxiServices",
				"rules",
			],
			"json",
		);
		const contentOnChange = valueEditor.useAssigner();

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue as AutoTariff.New | AutoTariff.Modified);
		}, [contentRef, internalValue, onSave]);

		return (
			<EditModal
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Row>
						<CheckBox
							label={t(`${tPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
					</Row>
				}
			>
				<Root sizes="auto! 1fr">
					<Header
						title={headerTitle}
						createdAt={createdAt}
						tab={tab}
						onChangeTab={setTab}
					/>
					<Content
						ref={setContentRef}
						tab={tab}
						carClasses={carClasses}
						sectors={sectors}
						value={contentValues}
						onChange={contentOnChange}
					/>
				</Root>
			</EditModal>
		);
	},
	InternalController,
);

const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		headerTitle: string;
		carClasses: CarClass.Model[];
		sectors?: Sector.Model[];
		createdAt?: string;
		onCancel: () => void;
		onSave: (
			tariff: AutoTariff.New | AutoTariff.Modified,
			force?: boolean,
		) => Promise<void>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Content.Value &
		Partial<NonEditableProperties> & {
			active: boolean;
		};

	type PreparedTemplate = AutoTariff.New | AutoTariff.Modified | null;
}

export default Modal;
