import styled from "styled-components";
import { Row } from "uikit";

const NoFiles = styled(Row)<NoFiles.Props>`
	cursor: pointer;
	${({ disabled }) => (disabled ? "opacity: 0.5; pointer-events: none;" : "")}
`;

declare namespace NoFiles {
	interface Props extends Row.Props {
		disabled?: boolean;
	}
}

export default NoFiles;
