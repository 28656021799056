import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	border-radius: 10px;

	background: ${theme.colors.white};

	pointer-events: auto;
	overflow: hidden;
	z-index: 40;
`;
export default Root;
