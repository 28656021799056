import React, { Dispatch, memo, useCallback } from "react";
import { MultiSelect, Row } from "uikit";
import { useTranslation } from "react-i18next";
import translationPath from "../../constants/translationPath";
import { destinationAppOptions } from "../Modal/components/Content/constants/destinationAppOptions";
import { NoticeDestinationApp } from "../../../../../../../services/PushNotice";

const Filters: React.FC<Filters.Props> = memo(
	({ destinationAppFilter, setDestinationAppFilter }) => {
		const { t } = useTranslation();

		destinationAppOptions.forEach((option) => {
			// eslint-disable-next-line no-param-reassign
			option.label = t(
				`${translationPath}.modal.app.options.${option.value}`,
			);
		});

		const handleChange = useCallback(
			(value) => {
				setDestinationAppFilter(value as NoticeDestinationApp[]);
			},
			[setDestinationAppFilter],
		);

		return (
			<Row style={{ width: "250px" }}>
				<MultiSelect
					style={{ flexGrow: 1 }}
					placeholder={
						t(`${translationPath}.modal.app.placeholder`) || ""
					}
					all
					options={destinationAppOptions}
					value={destinationAppFilter}
					onChange={handleChange}
				/>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Props {
		destinationAppFilter: NoticeDestinationApp[];
		setDestinationAppFilter: Dispatch<NoticeDestinationApp[]>;
	}
}

export default Filters;
