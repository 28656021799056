/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useMemo, useState } from "react";
import { CheckBox, Column, react } from "uikit";
import { useTranslation } from "react-i18next";

import Language from "../../services/Language";
import Client from "../../services/Client";
import Company from "../../services/Company";
import useObjectEditor from "../../hooks/useObjectEditor";
import useTaxiServiceIdsDecoder from "../../hooks/useTaxiServiceIdsDecoder";
import useOldModelSubscribe from "../../hooks/useModelSubscribe";
import useModelSubscribe from "../../hooks/useModelSubscribe2";
import useTaxiServices from "../../hooks/useTaxiServices";
import makeLookupTable from "../../utils/makeLookupTable";
import CarClass from "../../services/CarClass";
import formatNumber from "../../utils/formatNumber";
import useDateTimeLocalizer from "../../hooks/useDateLocalizer";

import Root from "./components/Root";
import InternalController from "./Controller";
import ModelTable from "./components/ModelTable";

const CustomerSelectTab = memo(
	react.withController<
		CustomerSelectTab.PropsBase,
		CustomerSelectTab.Controller
	>(
		({
			value,

			disabled,
			visible,
			language,
			allowedTaxiServiceIds,
			tableSaveSlot,

			onChange,
		}) => {
			const { t } = useTranslation();

			const localizeDate = useDateTimeLocalizer();

			const companies = useOldModelSubscribe({}, Company)?.cache;
			const taxiServices = useTaxiServices();
			const carClassData = useModelSubscribe({}, CarClass);

			const carClassLookup = useMemo(
				() => makeLookupTable(carClassData.models, (c) => c.id, true),
				[carClassData.models],
			);

			const companyLookup = useMemo(
				() =>
					companies
						? makeLookupTable(companies, (c) => c.id, true)
						: ({} as Record<number, undefined>),
				[companies],
			);

			const taxiServiceLookup = useMemo(
				() =>
					taxiServices
						? makeLookupTable(taxiServices, (ts) => ts.id, true)
						: ({} as Record<number, undefined>),
				[taxiServices],
			);

			const valueEditor = useObjectEditor(value, onChange);

			const [filters, setFilters] = useState<any>({
				companyIds: ["all"],
				taxiServiceIds: ["all"],
				search: "",
			});

			const decodeTaxiServiceIds = useTaxiServiceIdsDecoder({
				allowedTaxiServiceIds,
			});

			const [sort, setSort] = useState<ModelTable.Sort>({});

			const modelSubscriptionOptions =
				useMemo<Client.SubscribeOptions>(() => {
					const result: Client.SubscribeOptions = {};

					return result;
				}, []);

			const data = useModelSubscribe(modelSubscriptionOptions, Client);

			const items = useMemo(() => data.models ?? [], [data.models]);

			const tableOnChangeSelected = valueEditor.useSetter("customerIds");

			const tableData = useMemo<any[]>(() => {
				const payload = [...(items ?? [])];

				return payload.map((client) => {
					const mainBalance = client?.paymentAccounts.find(
						(a) => a.type === "main",
					);
					const bonusBalance = client?.paymentAccounts.find(
						(a) => a.type === "bonus",
					);

					const company =
						companyLookup[client.companyId]?.value.name?.[
							language
						] || "-";

					const defaultTaxiService =
						(client.defaultTaxiServiceId &&
							taxiServiceLookup[client.defaultTaxiServiceId]
								?.value.settlement[language]) ||
						"-";

					const latestTaxiService =
						(client.latestTaxiServiceId &&
							taxiServiceLookup[client.latestTaxiServiceId]?.value
								.settlement[language]) ||
						"-";

					const defaultCarClass =
						(client.carClassId != null &&
							carClassLookup[client.carClassId]?.value.name[
								language
							]) ||
						"-";

					const createdAt = localizeDate(
						new Date(client.createdAt),
						"dateTime",
					);
					const updatedAt = localizeDate(
						new Date(client.updatedAt),
						"dateTime",
					);
					const deletedAt = client.deletedAt
						? localizeDate(new Date(client.deletedAt), "dateTime")
						: "";

					return {
						_isActive: client.status.level === "active",
						id: client.id,

						rideCount: client.rideCount,
						balance: formatNumber(mainBalance?.amount ?? 0),
						bonusBalance: formatNumber(bonusBalance?.amount ?? 0),
						bonusRides: "todo",

						totalOrderCount: client?.counters?.total ?? 0,
						completedOrderCount: client?.counters?.success ?? 0,
						orderEstimationCount: client?.counters?.failed ?? 0,
						canceledOrderCount: client?.counters?.cancelled ?? 0,
						canceledOrderCountNoCarFound: "todo",

						firstName: client?.person?.firstName,
						lastName: client?.person?.lastName || "",
						fatherName: client?.person?.fatherName || "",
						birthday: client?.person?.birthday
							? localizeDate(
									new Date(client.person.birthday),
									"date",
							  )
							: "-",
						gender:
							t(`genders.${client.gender || "unknown"}`) ||
							"Unknown",
						app: (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									pointerEvents: "none",
								}}
							>
								<CheckBox disabled value={client.isApp} />
							</div>
						),
						email:
							client?.person?.emails
								?.map((email) => email.value)
								.join(", ") || "-",
						phones:
							client?.person?.phones
								?.map((n) => n.number)
								.join(", ") || "",

						mainLoyaltyProgram: "todo",
						additionalLoyaltyProgram: "todo",

						bonusCard: "todo",
						tariff: "todo",
						defaultCarClass,

						status: t(`statuses.${client.status.level}`) || "",
						company,
						defaultTaxiService,
						latestTaxiService,

						notes: client.notes,
						orderNotes: client.orderNotes || "",
						executorNotes: client.executorNotes || "",

						group: client.customerGroup?.name,

						createdAt,
						updatedAt,
						deletedAt,
					};
				});
			}, [
				items,
				companyLookup,
				language,
				taxiServiceLookup,
				carClassLookup,
				localizeDate,
				t,
			]);

			return (
				<Root hasPaddings={false} visible={visible}>
					<Column sizes="1fr" maxedWidth maxedHeight>
						<ModelTable
							selected={valueEditor.get("customerIds")}
							disabled={disabled}
							loading={false}
							data={tableData}
							saveSlot={tableSaveSlot}
							onChangeSelected={tableOnChangeSelected}
						/>
					</Column>
				</Root>
			);
		},
		InternalController,
	),
);

declare namespace CustomerSelectTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		customerIds: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		allowedTaxiServiceIds: number[];
		tableSaveSlot: string;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CustomerSelectTab;
