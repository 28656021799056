import React, { useMemo } from "react";

import CarClass from "../../../../../../../../../../services/CarClass";
import Language from "../../../../../../../../../../services/Language";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { TaxiServicesByIds } from "../../../../../../../../../../components/common";

const BranchCellContent: React.FC<BranchCellContent.Props> = ({
	item,
	language,
}) => {
	const taxiServiceIds = useMemo(() => item.taxiServiceIds || [], [item]);
	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<TaxiServicesByIds
				taxiServiceIds={taxiServiceIds}
				lang={language}
			/>
		</CellContentRoot>
	);
};

declare namespace BranchCellContent {
	interface Props {
		item: CarClass.Model;
		language: Language;
	}
}

export default BranchCellContent;
