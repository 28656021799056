import React, { memo, useCallback, useState } from "react";

import { useTypedSelector } from "../../../../../../redux/store";
import { Popup, StyledColumn } from "../../../../../common";

import Root from "./components/Root";
import PopupContent from "./components/PopupContent";

const popupOffset = { x: -123, y: 50 };

const Language = memo(() => {
	const language = useTypedSelector((state) => state.session.language);

	const [showPopup, setShowPopup] = useState(false);

	const closePopup = useCallback(() => {
		setShowPopup(false);
	}, []);

	return (
		<Popup
			style={{ height: "100%" }}
			useHoverControl
			open={showPopup}
			offset={popupOffset}
			tracker={
				<Root justify="center" alignItems="center" h="100%">
					{language.toUpperCase()}
				</Root>
			}
			onChangeOpen={setShowPopup}
		>
			<StyledColumn
				gap="0.5rem"
				justify="space-between"
				alignItems="center"
				bgC="#ffffff"
				p="1rem 1rem 1.75rem 1rem"
				b="1px solid #c5c6c7"
				br="5px"
				w={{ min: "100px" }}
			>
				<PopupContent onClose={closePopup} />
			</StyledColumn>
		</Popup>
	);
});

export default Language;
