import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const getColumns = (t: TFunction): Column[] => [
	{
		id: "name",
		label: t("orderPageWidgets.parking.parkingTable.str150") ?? "",
	},
	{
		id: "driversAmount",
		label: t("orderPageWidgets.parking.parkingTable.str151") ?? "",
	},
	{
		id: "aliases",
		label: t("orderPageWidgets.parking.parkingTable.str152") ?? "",
	},
]; // columns

export default getColumns;
