import { Dispatch, SetStateAction } from "react";
import { react } from "uikit";

import { findValueByLanguageKey } from "../../../../../../assets/languages/langs";

import Name from ".";

interface Context {
	value: Name.Value;
	setError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = findValueByLanguageKey(this.context?.value);

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
