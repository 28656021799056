import React, { memo } from "react";

import { StyledColumn } from "../../../../../../../../components/common";
import Modal from "../../../../../../../../components/Modal";

import { useArchiveSwitcher, useArchiveOrders } from "../../../../hooks";

import InfoModalOrderForm from "./components/InfoModalOrder";

const ArchiveModalInfo: React.FC<ArchiveModalInfo.Props> = ({
	orderModal,
}): JSX.Element => {
	const {
		orders: { order },
	} = useArchiveOrders();
	const {
		orderToggles: { isInfoModal },
	} = useArchiveSwitcher();

	return (
		<>
			{isInfoModal && order && (
				<Modal dimmed>
					<StyledColumn
						over="hidden"
						bgC="#ffffff"
						br="5px"
						w="clamp(1302px, 80vw, 1402px)"
						h="clamp(574px, 84vh, 768px)"
					>
						<InfoModalOrderForm saveSlot={orderModal?.saveSlot} />
					</StyledColumn>
				</Modal>
			)}
		</>
	);
};

declare namespace ArchiveModalInfo {
	interface Props {
		orderModal?: InfoModalOrderForm.Props;
	}
}
export const ArchiveModalInfoMemo = memo(ArchiveModalInfo);
export default ArchiveModalInfo;
