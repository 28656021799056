import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import getCompanies from "../../../../../../redux/services/Company/getCompanies";
import PaymentSystem from "../../../../../../services/PaymentSystem";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../components/LabeledField";

import translationPath from "../../constants/translationPath";
import InternalController from "./Controller";

const CompaniesBase = react.withController<
	Companies.PropsBase,
	Companies.Controller
>(({ value, onChange, controller }) => {
	const [error, setError] = useState(false);
	controller.setContext({ value, setError });

	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

	const selectedCompanies = valueEditor.useGetter("companyIds");
	const setSelectedCompanies = valueEditor.useSetter("companyIds");

	const { companies } = useTypedSelector((state) => state.ordersPageReducer);
	const dispatch = useTypedDispatch();

	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const selectOptionsWrap = useCallback(
		(item: { id: number; label: string }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);
	const companiesOptions = useMemo(
		() =>
			companies.items.map((company) =>
				selectOptionsWrap({
					id: company.id,
					label: company.name[lang],
				}),
			),
		[companies.items, lang, selectOptionsWrap],
	);

	const updateSelectedValues = useCallback(
		(newCompanies) => {
			setSelectedCompanies(newCompanies);
			const newTaxiServiceIds = taxiServiceIds.filter((serviceId) => {
				const serviceCompany = companies.items.find((company) =>
					company.taxiServices.some(
						(service) => service.id === serviceId,
					),
				);
				return (
					serviceCompany && newCompanies.includes(serviceCompany.id)
				);
			});
			setTaxiServiceIds(newTaxiServiceIds);
		},
		[
			setSelectedCompanies,
			taxiServiceIds,
			setTaxiServiceIds,
			companies.items,
		],
	);

	return (
		<>
			<LabeledField
				label={t(`${translationPath}.modal.company.title`) || ""}
			>
				<MultiSelect
					all
					placeholder={
						t(`${translationPath}.modal.company.chooseCompany`) ||
						""
					}
					error={error}
					value={selectedCompanies}
					options={companiesOptions}
					onChange={updateSelectedValues}
				/>
			</LabeledField>
		</>
	);
}, InternalController);
const Companies = memo(CompaniesBase);

declare namespace Companies {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		taxiServices: PaymentSystem.Model["taxiServices"];
		taxiServiceIds: PaymentSystem.Model["taxiServiceIds"];
		companies: PaymentSystem.Model["companies"];
		companyIds: PaymentSystem.Model["companyIds"];
	}
}

export default Companies;
