import React, { memo } from "react";
import Root from "./components/Root";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { MapTabs } from "../../constants/tabs";
import Main from "./tabs/Main";

const Content: React.FC<Content.Props> = memo(({ value, onChange, tab }) => {
	const editor = useObjectEditor(value, onChange);

	const mainTabValues = editor.usePicker([
		"settings",
		"taxiServices",
		"taxiServiceIds",
	]);

	const assigner = editor.useAssigner();

	const getActualTab = (currentTab: MapTabs) => {
		switch (currentTab) {
			case MapTabs.MAIN:
				return <Main value={mainTabValues} onChange={assigner} />;

			case MapTabs.HISTORY:
				return <></>;

			default:
				return <></>;
		}
	};
	return <Root>{getActualTab(tab)}</Root>;
});

declare namespace Content {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
		tab: MapTabs;
	}

	type Value = Main.Value;
}

export default Content;
