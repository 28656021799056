import React, { memo, useRef } from "react";

import useOnClickOutside from "../../../../../../../../../../hooks/useOnClickOutside";
import { CallPhonesSelect } from "../../styled";

const BasePhonesSelect: React.FC<BasePhonesSelect.Props> = ({
	phones,
	onClose,
	onSubmit,
}) => {
	const modalContainerRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(modalContainerRef, onClose);

	return (
		<CallPhonesSelect ref={modalContainerRef}>
			<div className="CallArrayModal__items">
				{phones?.map((number) => (
					<div
						key={number}
						className="CallArrayModal__item"
						onClick={() => {
							onSubmit(number);
							onClose();
						}}
					>
						{number}
					</div>
				))}
			</div>
		</CallPhonesSelect>
	);
};

declare namespace BasePhonesSelect {
	interface Props {
		phones?: string[];
		onClose: () => void;
		onSubmit: (phone: string) => void;
	}
}

export const PhonesSelect = memo(BasePhonesSelect);
export default BasePhonesSelect;
