/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateRuleCalculationType {
	ENTIRE_ROUTE = "entire_route",
	INSIDE_PRICE_ZONE = "inside_price_zone",
}

export const calculationTypeOptions = getOptions([
	AutoRateRuleCalculationType.ENTIRE_ROUTE,
	AutoRateRuleCalculationType.INSIDE_PRICE_ZONE,
]);
