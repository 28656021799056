import styled from "styled-components";

import Button from "../../../Button";

const RightButton = styled(Button)`
	width: 17px;

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;

export default RightButton;
