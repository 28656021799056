import React from "react";

import CellContentRoot from "../CellContentRoot";
import { IOrderPoint as OrderPoint } from "../../../../../../../../../redux/constants/OrdersPage/order";

const EntranceCellContent: React.FC<EntranceCellContent.Props> = ({ item }) => (
	<CellContentRoot align="center" maxedHeight maxedWidth>
		{item.entrance}
	</CellContentRoot>
);

declare namespace EntranceCellContent {
	interface Props {
		item: OrderPoint;
	}
}

export default EntranceCellContent;
