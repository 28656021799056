import processAddressPath from "./processAddressPath";

function objectToLabel(
	params: streetToString.Street,
	add: "full" | "name" | "street" | "streetHouse" | null = null,
) {
	const name = `${params.name || params.title || ""}`;
	const region = processAddressPath(params.region);
	const house = `${
		params.house || params.number || params.customHouse
			? `, ${params.house || params.number || params.customHouse}`
			: ""
	}`;
	const street =
		params.street || params.customStreet
			? `${params?.streetType} ${
					params.street || params.customStreet
			  } ${region}`.trim()
			: "";

	switch (add) {
		case "full":
			return `${name} ${name ? ", " : ""}${street} ${house}`.trim();
		case "name":
			return `${name}`.trim();
		case "street":
			return `${street}`.trim();
		case "streetHouse":
			return `${street} ${house}`.trim();
		default:
			return "";
	}
}

declare namespace streetToString {
	interface Street {
		streetType?: string;
		street?: string;
		region?: string;
		name?: string;
		house?: string;
		number?: string;
		customHouse?: string;
		customStreet?: string;
		title?: string;
	}
}

export default objectToLabel;
