import styled, { css } from "styled-components";
import { theme } from "uikit";

const variants = {
	primary: css<Root.Variant.Props>`
		background: ${theme.colors.accent};

		--icon-color-1: ${theme.colors.white};

		&:hover {
			&::before {
				content: "";

				position: absolute;

				width: 100%;
				height: 100%;

				background: rgba(0, 0, 0, 0.05);
			}
		}
	`,
	secondary: css<Root.Variant.Props>`
		background: ${theme.colors.button_secondary};

		--icon-color-1: ${({ active = false }) =>
			active ? theme.colors.accent : theme.colors.primary};

		&:hover {
			background: ${({ active = false }) =>
				active ? "#dddddd" : "#e5e5e5"};

			border: ${({ active = false }) =>
				active ? "none" : "1px solid #cfd1d2"};

			--icon-color-1: ${theme.colors.accent};
		}

		&:disabled {
			background: ${theme.colors.button_secondary};

			border: none;

			--icon-color-1: #b5c0c7;
		}
	`,
};

const Root = styled.button<Root.Props>`
	all: unset;

	box-sizing: border-box;

	position: relative;

	display: flex;

	align-items: center;
	justify-content: center;

	width: 28px;
	height: 28px;

	border-radius: 5px;

	cursor: pointer;

	overflow: hidden;

	${({ variant }) => variants[variant]}
`;

declare namespace Root {
	type Variant = keyof typeof variants;

	interface Props extends Variant.Props {
		variant: Variant;
	}

	namespace Variant {
		interface Props {
			active?: boolean;
		}
	}
}

export default Root;
