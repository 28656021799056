import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const StatusCellContent: React.FC<StatusCellContent.Props> = ({ item }) => {
	const { t } = useTranslation();

	const content = useMemo(
		() => t(`order_status.${item.status}`),
		[item.status, t],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace StatusCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default StatusCellContent;
