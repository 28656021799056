import Address from "../types/Address";

import settlementToString from "./settlementToString2";
import streetToString from "./streetToString";

function addressToString(address: Partial<Address>) {
	return [settlementToString(address), streetToString(address), address.house]
		.filter(Boolean)
		.join(", ");
}

export default addressToString;
