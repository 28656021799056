import React from "react";

import OrderTimeCellContent from "../components/OrderTimeCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function orderTime({ width, onResize }: Column.Options) {
	return base(
		ColumnId.OrderTime,
		(item) => <OrderTimeCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default orderTime;
