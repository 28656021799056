import React, {
	Dispatch,
	RefAttributes,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { react, useRefWithSetter } from "uikit";
import { isNumber } from "lodash";

import Language from "../../../../../../../../../../services/Language";
import ExecutorRateCommissionPlan from "../../../../../../../../../../services/ExecutorRateCommissionPlan";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import useTaxiServiceIdsDecoder from "../../../../../../../../../../hooks/useTaxiServiceIdsDecoder";
import ExecutorSelectTab from "../../../../../../../../../../components/ExecutorSelectTab";

import InternalController from "./Controller";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import SubscriptionTab from "./components/SubscriptionTab";
import CommissionPlanTab from "./components/CommissionPlanTab";
import DiscountPlanTab from "./components/DiscountPlanTab";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, executorId, value, type, disabled, language, onChange }) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);
		const [disabledList, setDisabledList] = useState<number[]>([]);

		const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

		const valueEditor = useObjectEditor(value, onChange);
		const root = valueEditor.useGetter("root");
		const mainTabValue = valueEditor.usePicker([
			"companyIds",
			"taxiServiceIds",
			"name",
			"amountOfMoneyToBlock",
			"amountOfMoneyToUnblock",
			"orderTypeCondition",
			"disallowTakeOrders",
			"root",
		]);

		const subscriptionTabValue = valueEditor.usePicker(["subscriptions"]);

		const commissionPlanTabValue = valueEditor.usePicker([
			"commissionPlans",
		]);

		const discountPlanTabValue = valueEditor.usePicker([
			"discountPlans",
			"ignoreOldOrders",
		]);

		const executorSelectTabValue = valueEditor.usePicker(["executorIds"]);
		const executorIds = valueEditor.useGetter("executorIds");

		useLayoutEffect(() => {
			if (root) setDisabledList(executorIds);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const executorSelectTabAllowedTaxiServiceIds = useMemo(
			() =>
				decodeTaxiServiceIds(
					mainTabValue.companyIds,
					mainTabValue.taxiServiceIds,
				),
			[
				decodeTaxiServiceIds,
				mainTabValue.companyIds,
				mainTabValue.taxiServiceIds,
			],
		);

		const anyTabOnChange = valueEditor.useAssigner();

		const existCommissionPlans = useMemo(
			() =>
				value.commissionPlans.filter((commissionPlan) =>
					isNumber(commissionPlan.id),
				) as ExecutorRateCommissionPlan.Model[],
			[value.commissionPlans],
		);

		controller.setContext({ mainTabRef });

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						executorId={executorId}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						onChange={anyTabOnChange}
					/>
					<SubscriptionTab
						value={subscriptionTabValue}
						visible={type === "subscriptions"}
						disabled={disabled}
						onChange={anyTabOnChange}
					/>
					<CommissionPlanTab
						value={commissionPlanTabValue}
						visible={type === "commissionPlans"}
						disabled={disabled}
						onChange={anyTabOnChange}
					/>
					<DiscountPlanTab
						value={discountPlanTabValue}
						visible={type === "discountedPlans"}
						commissionPlans={existCommissionPlans}
						disabled={disabled}
						onChange={anyTabOnChange}
					/>
					<ExecutorSelectTab
						value={executorSelectTabValue}
						disabled={disabled}
						disabledList={disabledList}
						visible={type === "executors"}
						language={language}
						allowedTaxiServiceIds={
							executorSelectTabAllowedTaxiServiceIds
						}
						onChange={anyTabOnChange}
					/>
				</>
			),
			[
				setMainTabRef,
				executorId,
				mainTabValue,
				disabled,
				type,
				language,
				anyTabOnChange,
				subscriptionTabValue,
				commissionPlanTabValue,
				discountPlanTabValue,
				existCommissionPlans,
				executorSelectTabValue,
				disabledList,
				executorSelectTabAllowedTaxiServiceIds,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type =
		| "main"
		| "subscriptions"
		| "commissionPlans"
		| "discountedPlans"
		| "executors";

	type Value = MainTab.Value &
		SubscriptionTab.Value &
		CommissionPlanTab.Value &
		DiscountPlanTab.Value &
		ExecutorSelectTab.Value;

	interface PropsBase {
		executorId?: number;
		value: Value;
		language: Language;

		type: Type;
		disabled: boolean;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
