import React, { RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, CheckBox, RadioButton, react, useRefWithSetter } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import { marginLeft } from "../../../../../../../../../../../../../../../../../../../../../MainPage/pages/Accounts/tabs/ExecutorTeams/components/Modal/components/Content/tabs/Main/constants/styles";

import { AutoRateRuleSectorItemCounterType as Type } from "../../constants";
import IgnoreExecutors from "./components/IgnoreExecutors";
import Range from "./components/Range";
import Controller from "./Controller";

const ExecutorsCounterBase = react.withController<
	ExecutorsCounter.PropsBase,
	Controller
>(({ disabled, value, onChange, controller }) => {
	const { t } = useTranslation();
	const [ignoreExecutorsRef, setIgnoreExecutorsRef] =
		useRefWithSetter<IgnoreExecutors.Ref | null>(null);
	controller.setContext({ ignoreExecutorsRef });

	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	// const execPos = valueEditor.useGetter("considerExecutorPositions");
	// const setExecPos = valueEditor.useSetter("considerExecutorPositions");

	const range = valueEditor.useGetter("range");
	const setRange = valueEditor.useSetter("range");

	const ignoreExecutors = valueEditor.useGetter("ignoreExecutors");
	const setIgnoreExecutors = valueEditor.useSetter("ignoreExecutors");

	const type = valueEditor.useGetter("type");
	const setType = valueEditor.useSetter("type");

	return (
		<Column gaps="15px*">
			<CheckBox
				disabled={disabled}
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.executorsCounter.str0",
					) ?? ""
				}
				value={active}
				onChange={setActive}
			/>
			<Column gaps="15px*" style={marginLeft}>
				{/* <CheckBox
					disabled={disabled || !active}
					label={t("pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.executorsCounter.str1") ?? ""}
					value={execPos}
					onChange={setExecPos}
				/> */}
				<Range
					disabled={disabled || !active}
					value={range}
					onChange={setRange}
				/>
				<IgnoreExecutors
					ref={setIgnoreExecutorsRef}
					disabled={disabled || !active}
					value={ignoreExecutors}
					onChange={setIgnoreExecutors}
				/>
				<FieldsContainer
					label={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.executorsCounter.str2",
						) ?? ""
					}
				>
					<Column gaps="10px*">
						<RadioButton.RadioButton
							disabled={disabled || !active}
							label={
								t(
									"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.executorsCounter.str3",
								) ?? ""
							}
							selected={type === Type.EACH}
							onClick={() => {
								setType(Type.EACH);
							}}
						/>
						<RadioButton.RadioButton
							disabled={disabled || !active}
							label={
								t(
									"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.executorsCounter.str4",
								) ?? ""
							}
							selected={type === Type.OVERALL}
							onClick={() => {
								setType(Type.OVERALL);
							}}
						/>
					</Column>
				</FieldsContainer>
			</Column>
		</Column>
	);
}, Controller);

const ExecutorsCounter = memo(ExecutorsCounterBase);

declare namespace ExecutorsCounter {
	type Ref = Controller | null;
	interface PropsBase {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		active: boolean;
		considerExecutorPositions: boolean;
		ignoreExecutors: IgnoreExecutors.Value;
		range: Range.Value;
		type: Type;
	}
}

export default ExecutorsCounter;
