/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { LatLngLiteral } from "leaflet";
import { debounce, isEqual, isNumber, isUndefined, noop } from "lodash";
import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, TextBox, useInternal, useRender } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

const pasteCoordinatesRegexp = /^([+-]?\d+(?:\.\d+)?), ([+-]?\d+(?:\.\d+)?)$/s;
const numberRegexp = /^([+-]?\d+(?:\.(?:\d+)?)?)$/s;

const Coordinates: React.FC<Coordinates.Props> = ({
	value,
	error,
	onChange,
}) => {
	const { t } = useTranslation();
	const render = useRender();

	const latTextBoxRef = useRef<HTMLInputElement | null>(null);
	const lngTextBoxRef = useRef<HTMLInputElement | null>(null);

	const [focused, setFocused] = useState(0);

	const [internalValue, setInternalValue] = useInternal(value);

	const valueEditor = useObjectEditor(internalValue ?? {}, setInternalValue);

	const lat = valueEditor.useGetter("lat");
	const setLat = valueEditor.useSetter("lat");

	const lng = valueEditor.useGetter("lng");
	const setLng = valueEditor.useSetter("lng");

	const debouncedOnChange = useMemo(
		() => debounce(onChange ?? noop, 20),
		[onChange],
	);

	useEffect(() => {
		if (focused !== 0) {
			debouncedOnChange.cancel();

			return;
		}

		if (!internalValue || isEqual(internalValue, value)) return;

		debouncedOnChange(internalValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focused, internalValue]);

	const latValue = useMemo(() => (isNumber(lat) ? String(lat) : ""), [lat]);
	const lngValue = useMemo(() => (isNumber(lng) ? String(lng) : ""), [lng]);

	const [internalLat, setInternalLat] = useInternal(latValue);
	const [internalLng, setInternalLng] = useInternal(lngValue);

	const latTextBoxOnChange = useCallback(
		(latText: string) => {
			if (latText.length !== 0 && !latText.match(numberRegexp)) return;

			const newLat = latText ? Number(latText) : undefined;

			if (
				(isNumber(newLat) && (newLat >= -180 || newLat <= 180)) ||
				isUndefined(newLat)
			) {
				setLat(newLat);
				setInternalLat(latText);
			}
		},
		[setInternalLat, setLat],
	);

	const lngTextBoxOnChange = useCallback(
		(lngText: string) => {
			if (lngText.length !== 0 && !lngText.match(numberRegexp)) return;

			const newLng = lngText ? Number(lngText) : undefined;

			if (
				(isNumber(newLng) && newLng >= -180 && newLng <= 180) ||
				isUndefined(newLng)
			) {
				setLng(newLng);
				setInternalLng(lngText);
			}
		},
		[setInternalLng, setLng],
	);

	const tryPasteCoordinates = useCallback(
		(text: string) => {
			const match = text.match(pasteCoordinatesRegexp);

			if (!match) return false;

			const [, latMatch, lngMatch] = match;

			const lat = Number(latMatch);
			const lng = Number(lngMatch);

			setLat(lat);
			setLng(lng);

			onChange?.({ lat, lng });

			render();

			return true;
		},
		[onChange, render, setLat, setLng],
	);

	const latOnPaste = useCallback(
		(event: React.ClipboardEvent<HTMLInputElement>) => {
			event.stopPropagation();

			const text = event.clipboardData.getData("Text");

			const latTextBox = latTextBoxRef.current
				?.getElementsByTagName("input")
				.item(0);

			if (tryPasteCoordinates(text) || !latTextBox) {
				event.preventDefault();
			}
		},
		[tryPasteCoordinates],
	);

	const lngOnPaste = useCallback(
		(event: React.ClipboardEvent<HTMLInputElement>) => {
			event.stopPropagation();

			const text = event.clipboardData.getData("Text");

			const lngTextBox = lngTextBoxRef.current
				?.getElementsByTagName("input")
				.item(0);

			if (tryPasteCoordinates(text) || !lngTextBox) {
				event.preventDefault();
			}
		},
		[tryPasteCoordinates],
	);

	return (
		<Row sizes="1fr*" gaps="8px">
			<TextBox.TextBox
				ref={latTextBoxRef}
				value={internalLat}
				placeholder={
					t(
						"pages.preferencesPages.screenDirectory.objects.editModal.pointForm.coordinates.str0",
					) ?? ""
				}
				error={!!error}
				onChange={latTextBoxOnChange}
				onPaste={latOnPaste}
				onFocus={() => setFocused((value) => ++value)}
				onBlur={() => setFocused((value) => --value)}
			/>
			<TextBox.TextBox
				ref={lngTextBoxRef}
				value={internalLng}
				placeholder={
					t(
						"pages.preferencesPages.screenDirectory.objects.editModal.pointForm.coordinates.str1",
					) ?? ""
				}
				error={!!error}
				onChange={lngTextBoxOnChange}
				onPaste={lngOnPaste}
				onFocus={() => setFocused((value) => ++value)}
				onBlur={() => setFocused((value) => --value)}
			/>
		</Row>
	);
};

declare namespace Coordinates {
	type Value = Partial<LatLngLiteral>;

	interface Props {
		value?: Value;

		error?: boolean;

		onChange?: Dispatch<Value>;
	}
}

export default Coordinates;
