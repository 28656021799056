import getOptions from "../../../../../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

/* eslint-disable no-shadow */
export enum ExecutorStatus {
	AVAILABLE = "available",
	BUSY = "busy",
	ON_ORDER = "on_order",
	DEBT = "debt",
	CLOSED = "closed",
	HOME = "home",
	DINNER = "dinner",
	SHIFT_OPENED = "shift_opened",
	SHIFT_CLOSED = "shift_closed",
}

export const executorOptions = getOptions(Object.values(ExecutorStatus));
