/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import {
	Language,
	languageOptions,
	languages,
} from "../../../assets/languages/langs";
import {
	BooleanEntry,
	NumberEntry,
	DataKind,
	SelectEntry,
	EntryList,
	MultiSelectEntry,
} from "../../../types/settingEntries";
import DrivingType from "../../../types/DrivingType";
import SearchType from "../../../types/SearchType";

export type MapSettingsSelectTypes = Language | DrivingType;
export type MapSettingsMultiSelectTypes = SearchType;

export interface BaseMapSettings<Data extends DataKind> {
	minSearchQueryLength: NumberEntry<Data>;
	inputRequestDelayMs: NumberEntry<Data>;
	coordinateExpirationMs: NumberEntry<Data>;
	coordinateRequestIntervalMs: NumberEntry<Data>;
	revealRadiusMeters: NumberEntry<Data>;
	searchRadiusMeters: NumberEntry<Data>;
	addressCacheExpirationMs: NumberEntry<Data>;
	mapLanguage: SelectEntry<Data, Language>;
	routeCalculationMode: SelectEntry<Data, DrivingType>;
	defaultSearchType: MultiSelectEntry<Data, SearchType>;
	displayLocalObjectsInClientApp: BooleanEntry<Data>;
	calculateExtraDistance: BooleanEntry<Data>;
}

export type MapSettingEntries<Data extends DataKind> = BaseMapSettings<Data> &
	EntryList<Data, MapSettingsSelectTypes, MapSettingsMultiSelectTypes>;
export type MapSettingsMeta = MapSettingEntries<"meta">;
export type MapSettingsState = MapSettingEntries<"value">;
export type MapSettingsValue =
	MapSettingEntries<any>[keyof MapSettingEntries<any>];

export const mapSettingsMeta: MapSettingsMeta = {
	minSearchQueryLength: {
		type: "number",
	},
	inputRequestDelayMs: {
		type: "number",
		unit: "millisecond",
	},
	coordinateExpirationMs: {
		type: "number",
		unit: "millisecond",
	},
	coordinateRequestIntervalMs: {
		type: "number",
		unit: "millisecond",
	},
	revealRadiusMeters: {
		type: "number",
		unit: "meter",
	},
	searchRadiusMeters: {
		type: "number",
		unit: "meter",
	},
	addressCacheExpirationMs: {
		type: "number",
		unit: "day",
	},
	mapLanguage: {
		type: "select",
		options: languageOptions,
	},
	routeCalculationMode: {
		type: "select",
		options: [
			{
				key: "driving",
				label: "driving_type.driving",
				value: "driving",
			},
			{
				key: "driving-shortest",
				label: "driving_type.driving-shortest",
				value: "driving-shortest",
			},
		],
	},
	defaultSearchType: {
		type: "multiselect",
		options: [
			{
				key: "street",
				label: "search_type.street",
				value: "street",
			},
			{
				key: "object",
				label: "search_type.object",
				value: "object",
			},
			{
				key: "localObject",
				label: "search_type.localObject",
				value: "localObject",
			},
		],
	},
	displayLocalObjectsInClientApp: {
		type: "boolean",
	},
	calculateExtraDistance: {
		type: "boolean",
	},
};

const initialState: MapSettingsState = {
	minSearchQueryLength: 3,
	inputRequestDelayMs: 300,
	coordinateExpirationMs: 10e3,
	coordinateRequestIntervalMs: 1e3,
	revealRadiusMeters: 50,
	searchRadiusMeters: 20000,
	addressCacheExpirationMs: 30,
	mapLanguage: languages[0],
	routeCalculationMode: "driving",
	defaultSearchType: ["street", "localObject"],
	displayLocalObjectsInClientApp: true,
	calculateExtraDistance: true,
};

type Reducer<P> = CaseReducer<MapSettingsState, PayloadAction<P>>;

const setAll: Reducer<MapSettingsState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const setMinSearchQueryLength: Reducer<number> = (state, { payload }) => {
	state.minSearchQueryLength = payload;
};
const setDefaultSearchType: Reducer<MapSettingsState["defaultSearchType"]> = (
	state,
	{ payload },
) => {
	state.defaultSearchType = payload;
};

const setDelaySearchRequest: Reducer<number> = (state, { payload }) => {
	state.inputRequestDelayMs = payload;
};

const setRadius: Reducer<number> = (state, { payload }) => {
	state.revealRadiusMeters = payload;
};

const setSearchRadius: Reducer<number> = (state, { payload }) => {
	state.searchRadiusMeters = payload;
};

const setMapLang: Reducer<Language> = (state, { payload }) => {
	state.mapLanguage = payload;
};

const setExtraDistance: Reducer<DrivingType> = (state, { payload }) => {
	state.routeCalculationMode = payload;
};

const setLocalObjectsVisibility: Reducer<boolean> = (state, { payload }) => {
	state.displayLocalObjectsInClientApp = payload;
};

const mapSettings = createSlice({
	name: "mapSettings",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
		setMinSearchQueryLength,
		setDelaySearchRequest,
		setRadius,
		setSearchRadius,
		setMapLang,
		setExtraDistance,
		setLocalObjectsVisibility,
		setDefaultSearchType,
	},
});

export default mapSettings;
