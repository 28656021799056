import React, {
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { CheckBox, MultiSelect, Row, react } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

import tPath from "../../../../../../../../../../constants/tPath";
import Controller from "./Controller";
import {
	AutoRateSectorsIgnoreOrderType,
	ignoreOrderOptions,
} from "./constants";

const IgnoreOrdersBase = react.withController<
	IgnoreOrders.PropsBase,
	Controller
>(({ disabled, value, onChange, controller }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const types = valueEditor.useGetter("types");
	const setTypes = valueEditor.useSetter("types");
	const [typesError, setTypesError] = useState(false);

	const handleTypesChange = useCallback(
		(newTypes) => {
			setTypesError(false);
			setTypes(newTypes);
		},
		[setTypes],
	);

	const tOptions = useMemo(
		() =>
			ignoreOrderOptions.map((o) => ({
				...o,
				label: t(
					`${tPath}.modal.sectors.ordersCounter.ignoreOrders.options.${o.key}`,
				),
			})),
		[t],
	);

	controller.setContext({
		value,
		optionsLength: ignoreOrderOptions.length,
		setTypesError,
	});

	return (
		<Row align="center" gaps="10px" sizes="auto 1fr">
			<CheckBox
				disabled={disabled}
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.ignoreOrders.str0",
					) ?? ""
				}
				value={active}
				onChange={setActive}
			/>
			<MultiSelect
				error={
					typesError &&
					(t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.ignoreOrders.str1",
					) ??
						"")
				}
				disabled={disabled || !active}
				placeholder={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.ignoreOrders.str2",
					) ?? ""
				}
				options={tOptions}
				value={types}
				onChange={handleTypesChange}
			/>
		</Row>
	);
}, Controller);

const IgnoreOrders = memo(IgnoreOrdersBase);

declare namespace IgnoreOrders {
	type Ref = Controller | null;

	interface PropsBase {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		active: boolean;
		types: AutoRateSectorsIgnoreOrderType[];
	}
}

export default IgnoreOrders;
