import React, { Dispatch, memo, useCallback } from "react";
import Block from "../Block";
import Header from "./components/Header";
import List from "./components/List";

const OrderController = memo<OrderController.Props>(
	({
		value,

		items,

		onChange,
	}) => {
		const headerOnClear = useCallback(
			() => (value.length ? onChange([]) : undefined),
			[onChange, value.length],
		);

		return (
			<Block>
				<Header columnCount={value.length} onClear={headerOnClear} />
				<List value={value} items={items} onChange={onChange} />
			</Block>
		);
	},
);

declare namespace OrderController {
	type Item = List.Item;
	type Value = List.Value;

	interface Props {
		value: Value;

		items: Item[];

		onChange: Dispatch<Value>;
	}
}

export default OrderController;
