import React, { memo, useCallback, useRef } from "react";
import { isUndefined, isNumber, isNaN } from "lodash";
import styled, { css } from "styled-components";
import { Icon, theme, useHolder, useInternal } from "uikit";

import Style, {
	baseStyles,
	distributeStyles,
	flexStyles,
	visible,
	typographyStyles,
	StyledGrid,
} from "../styles";

interface StyledProps {
	disabled: boolean;
}

const StyledStepper = styled(StyledGrid)<StyledProps>`
	box-sizing: border-box;

	height: 32px;
	display: grid;
	grid-template-columns: 1fr 18px;
	overflow: hidden;

	background-color: white;
	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: 5px;

	.step-buttons {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		justify-content: center;
		align-items: center;

		border-left: 1px solid;
		border-color: ${theme.colors.color_border_basic};

		.step-button {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			background-color: transparent;
			border: none;
			cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

			& svg {
				fill: ${theme.colors.text_placeholder};
			}

			&:hover svg {
				fill: ${theme.colors.primary};
			}
		}
	}

	&[data-disabled="true"] {
		background: ${theme.colors.disabled_filled};
		border-color: ${theme.colors.color_border_basic};

		.input {
			color: ${theme.colors.disabled_text};
		}

		.step-buttons {
			border-color: ${theme.colors.color_border_basic};

			.step-button svg {
				fill: ${theme.colors.disabled_text};
			}
		}
	}

	&:has(.input:focus) {
		border-color: ${theme.colors.accent};

		.input,
		.step-buttons {
			border-color: ${theme.colors.accent};
		}
	}
`;

export const StyledInput = styled.input<LiteStepperBase.StyleInput>`
	font-family: ${theme.font.l};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;

	width: 100%;
	border: none;
	outline: none;

	&:hover {
		border-color: ${theme.colors.primary};
	}

	&::-webkit-inner-spin-button {
		display: none;
	}

	${({ disabled }) =>
		disabled &&
		css`
			filter: grayscale(1);
			opacity: 0.3;
			cursor: not-allowed;
		`}
	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${typographyStyles}
	${visible}
`;

const LiteStepperBase: React.FC<LiteStepperBase.Props> = ({
	id,

	disabled = false,

	value,
	onChange,

	min = -Infinity,
	max = Infinity,
	step = 1,
	decimalCount = 3,

	getRef,
	inputStyle,
	...prop
}) => {
	const ref = useRef<HTMLInputElement | null>(null);

	const [internalValue, setInternalValue] = useInternal<
		string | number | undefined
	>(value);

	const setValue = useCallback(
		(value: string | number | undefined) => {
			if (isUndefined(value)) return;

			if (typeof value === "string") {
				const parsedValue = value ? parseFloat(value) : undefined;
				// eslint-disable-next-line no-param-reassign
				if (isNumber(parsedValue)) value = parsedValue;
			}

			if (isNumber(value)) {
				const num = Number(value.toFixed(decimalCount));
				if (isNaN(num)) {
					setInternalValue("");
					onChange?.(undefined);
					return;
				}

				setInternalValue(
					/[.]{1}/.test(String(num)) ? `${num}` : `${num}.`,
				);
				onChange?.(num);
			} else {
				setInternalValue("");
				onChange?.(undefined);
			}
		},
		[decimalCount, setInternalValue, onChange],
	);

	const increase = useCallback(() => {
		const num = (Number(internalValue) || 0) + step;
		setValue(num > max ? max : num);
	}, [internalValue, step, max, setValue]);

	const decrease = useCallback(() => {
		const num = (Number(internalValue) || 0) - step;
		setValue(min > num ? min : num);
	}, [internalValue, step, min, setValue]);

	const increaseHolder = useHolder(350, 50, increase);
	const decreaseHolder = useHolder(350, 50, decrease);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			const keyEvent = event.key;
			const target = event.target as HTMLInputElement;

			if (keyEvent === "Backspace") {
				const { value, selectionStart, selectionEnd } = target;

				if (
					selectionStart !== null &&
					selectionEnd !== null &&
					selectionStart !== selectionEnd
				) {
					if (value.length === selectionEnd && selectionStart === 0) {
						setValue("");
						return;
					}
				}
			}
			if (keyEvent === "ArrowUp") {
				event.preventDefault();
				increase();
			}

			if (keyEvent === "ArrowDown") {
				event.preventDefault();
				decrease();
			}
		},
		[decrease, increase, setValue],
	);

	const onChangeInput = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			if (/^\d*([.,]?\d{0,3})?$/.test(newValue)) setValue(newValue);
		},
		[setValue],
	);

	const shareRef = useCallback(
		(input: HTMLInputElement | null) => {
			ref.current = input;
			if (input) getRef?.(input);
		},
		[getRef],
	);

	const onFocusToInput = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			const target = event.target as HTMLInputElement;
			event.stopPropagation();
			event.preventDefault();
			target?.select();
		},
		[],
	);

	return (
		<StyledStepper
			areas=""
			data-disabled={disabled}
			disabled={disabled}
			onClick={(event) => {
				// event.preventDefault();
				event.stopPropagation();
			}}
			{...prop}
		>
			<StyledInput
				ref={shareRef}
				id={id}
				type="text"
				className="input"
				inputMode="numeric"
				pattern="/^\d*([.,]?\d*)?$/"
				value={internalValue}
				min={min}
				max={max}
				disabled={disabled}
				onChange={onChangeInput}
				onKeyDown={onKeyDown}
				onFocus={onFocusToInput}
				{...inputStyle}
			/>
			<div className="step-buttons">
				<button
					className="step-button"
					tabIndex={-1}
					disabled={disabled}
					{...increaseHolder.events}
				>
					<Icon
						id="stepper-arrow-up"
						size={12}
						colors={[theme.colors.primary]}
					/>
				</button>

				<button
					className="step-button"
					tabIndex={-1}
					disabled={disabled}
					{...decreaseHolder.events}
				>
					<Icon
						id="stepper-arrow-down"
						size={12}
						colors={[theme.colors.primary]}
					/>
				</button>
			</div>
		</StyledStepper>
	);
};

declare namespace LiteStepperBase {
	type Value = number | undefined;
	type StyleInput = Style.BaseType &
		Style.FlexType &
		Style.DistributeType &
		Style.TypographyType;

	interface Props extends Partial<Style.Grid> {
		id?: string;

		disabled?: boolean;
		autoFocus?: boolean;

		value?: Value;
		onChange?: (val: Value) => void;

		min?: Value;
		max?: Value;
		step?: Value;
		decimalCount?: number;

		afterContent?: React.ReactNode;
		beforeContent?: React.ReactNode;
		inputStyle?: StyleInput;

		getRef?: (ref: HTMLInputElement | null) => void;
	}
}

export const LiteStepper = memo(LiteStepperBase);
declare namespace LiteStepper {
	interface Props extends LiteStepperBase.Props {}
}

export default LiteStepperBase;
