import styled from "styled-components";
import { Table, theme } from "uikit";

const ModalTable = styled(Table)`
	.rs-table-row-header {
		.rs-table-cell {
			border: none;
		}
		.rs-table-cell-content {
			height: 0px;
			padding: 0px;
			font-family: Lato;
			font-size: 13px;
			line-height: 13px;
			letter-spacing: 0em;
			text-align: left;
			color: #21333f !important;
		}
	}
	.rs-table-row:not(.rs-table-row-header) {
		.rs-table-cell {
			border-bottom: 1px solid ${theme.colors.color_border_basic};
		}
		.rs-table-cell-content {
			padding: 0px 6px;
			font-family: Lato;
			font-size: 13px;
			font-weight: 400;
			line-height: 14px;
			text-align: left;
			letter-spacing: 0em;
			color: #21333f !important;
		}

		&:last-child {
			& .rs-table-cell {
				border-bottom: none;
			}
		}

		&.founded {
			& .rs-table-cell {
				background-color: #fff7cb !important;
			}
		}

		&.selected {
			& .rs-table-cell {
				background-color: #eaf8fe !important;
			}
		}

		&.not-active {
			& .rs-table-cell,
			& .rs-table-cell-content {
			}
		}
	}
`;

// eslint-disable-next-line import/prefer-default-export
export { ModalTable };
