import React, { useMemo } from "react";
import { theme } from "uikit";

import Order, {
	OrderReminderStatus,
} from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const backgroundColorByStatus = {
	offer: "#51C3F7",
	executing: "rgb(76, 175, 80)",
	inactive: "#ebebeb",
	send: "#FFA500",
	dispatcherBGC: theme.colors.disabled_text,
	dispatcherText: theme.colors.white,
};

const ExecutorCallSignCellContent: React.FC<
	ExecutorCallSignCellContent.Props
> = ({ item }) => {
	const executor = useMemo(() => {
		if (item?.executorToOrder && item?.executorToOrder?.length) {
			return item?.executorToOrder?.[0]?.executor;
		}
		return item.offer?.executor;
	}, [item.executorToOrder, item.offer?.executor]);

	const content = useMemo(() => executor?.callSign, [executor?.callSign]);

	const isDispatcher = useMemo(
		() =>
			item?.executorToOrder?.find(
				(item) =>
					item?.executor.id === executor?.id &&
					item?.additionalFields?.acceptedByDispatcher?.active,
			),
		[executor?.id, item?.executorToOrder],
	);

	const backgroundColor = useMemo(() => {
		if (item?.executorReminderStatus) {
			const { status } = item.executorReminderStatus;
			if (status && OrderReminderStatus.SEND === status) {
				return backgroundColorByStatus.send;
			}
		}

		if (item.executingStages?.accepted) {
			return backgroundColorByStatus.executing;
		}

		if (
			(isDispatcher &&
				item.executingStages?.assigned &&
				!item.executingStages?.accepted) ||
			(isDispatcher &&
				!item?.offer?.executor?.online &&
				!item.executingStages?.accepted)
		) {
			return backgroundColorByStatus.dispatcherBGC;
		}

		if (item.executingStages?.assigned) {
			return backgroundColorByStatus.inactive;
		}

		if (item.offer) {
			return item.offer.executor.online
				? backgroundColorByStatus.offer
				: backgroundColorByStatus.inactive;
		}

		return undefined;
	}, [
		item.executorReminderStatus,
		item.executingStages?.accepted,
		item.executingStages?.assigned,
		item.offer,
		isDispatcher,
	]);

	const textColor = useMemo(
		() =>
			(isDispatcher && item.executingStages?.assigned) ||
			(isDispatcher && !item?.offer?.executor?.online)
				? theme.colors.white
				: undefined,
		[
			isDispatcher,
			item.executingStages?.assigned,
			item?.offer?.executor?.online,
		],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorCallSignCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutorCallSignCellContent;
