import styled from "styled-components";
import { theme } from "uikit";

const TabRoot = styled.div<TabRoot.Props>`
	${({ visible }) => (visible ? "" : "display: none;")}

	width: 100%;
	height: 100%;

	padding: ${({ hasPaddings, paddings }) =>
		hasPaddings ? paddings ?? "24px" : "0"};

	overflow: auto;

	${theme.scrollbar}
`;

declare namespace TabRoot {
	interface Props {
		hasPaddings: boolean;
		paddings?: string;
		visible: boolean;
	}
}

export default TabRoot;
