import React, { Dispatch, useMemo } from "react";

import KeyBinder from "../../services/KeyBinder";
import { BaseKeybindEntries } from "../../redux/reducers/settings/keybinds";
import useKeyBind from "../../hooks/useKeyBind";
import { useKeyBindSettings } from "../../hooks/useKeyBindSettings";

const KeyBind: React.FC<KeyBind.Props> = ({
	keys,
	settingId,
	stopPropagation,
	preventDefault,
	onUse,
}) => {
	const options = useMemo<KeyBinder.State.Listener.Options>(
		() => ({
			stopPropagation,
			preventDefault,
		}),
		[stopPropagation, preventDefault],
	);

	useKeyBind(keys, onUse, options);
	useKeyBindSettings(settingId, onUse, options);

	return <></>;
};

declare namespace KeyBind {
	interface Props extends KeyBinder.State.Listener.Options {
		keys?: KeyBinder.KeyBind;
		settingId?: keyof BaseKeybindEntries<"meta"> | undefined | null;

		onUse?: Dispatch<KeyboardEvent>;
	}
}

export default KeyBind;
