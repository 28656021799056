import React from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Icon, Row, react, theme } from "uikit";
import Title from "./components/Title";
import ButtonContainer from "./components/ButtonContainer";
import DeleteButtonText from "./components/DeleteButtonText";

const ObjectHeader = react.inputify<ObjectHeader.Props, ObjectHeader.Value>(
	({ value, title, onChange, onDelete }) => {
		const { t } = useTranslation();
		return (
			<Row align="center" justify="space-between" gaps="10px">
				<Title>{title}</Title>
				<Row align="center" gaps="20px">
					<CheckBoxWithContent
						value={!value?.autoResolve}
						gap="8px"
						onChange={(customInput) =>
							onChange({
								...(value ?? {}),
								autoResolve: !customInput,
							})
						}
					>
						{t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectHeader.str130",
						) ?? ""}
					</CheckBoxWithContent>
					<ButtonContainer
						gaps="8px"
						align="center"
						onClick={onDelete}
					>
						<Icon
							id="light-trash"
							size={16}
							colors={[theme.colors.secondary]}
						/>
						<DeleteButtonText>
							{t(
								"pages.preferencesPages.screenDirectory.objects.editModal.objectHeader.str100",
							) ?? ""}
						</DeleteButtonText>
					</ButtonContainer>
				</Row>
			</Row>
		);
	},
);

declare namespace ObjectHeader {
	interface Value {
		autoResolve?: boolean;
	}

	interface Props {
		title: string;

		onDelete: () => void;
	}
}

export default ObjectHeader;
