import { useMemo } from "react";

import useModelSubscribe from "../../../../../../../../../../../../../hooks/useModelSubscribe2";
import CarBaseType from "../../../../../../../../../../../../../services/CarBaseType";

type Key = string | number;
interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

function useCarBaseTypes(options: useCarBaseTypes.Options) {
	const subscribeOptions = useMemo<CarBaseType.SubscribeOptions>(
		() => ({ limit: 50, query: options.search, order: { name: "ASC" } }),
		[options.search],
	);

	const data = useModelSubscribe(subscribeOptions, CarBaseType);

	const items = useMemo(() => data.models ?? [], [data.models]);

	const selectOptions = useMemo<Option<number>[]>(
		() =>
			items.map((item) => ({
				key: item.id,
				value: item.id,
				label: item.name,
			})),
		[items],
	);

	return { data, items, selectOptions };
}

declare namespace useCarBaseTypes {
	interface Options {
		search: string;
	}
}

export default useCarBaseTypes;
