import { RefObject } from "react";
import { react } from "uikit";
import Sectors from "./components/Sectors";
import Name from "./components/Name";

interface Context {
	nameRef: RefObject<Name.Ref | null>;
	sectorsRef: RefObject<Sectors.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = this.context?.nameRef.current?.validate();
		const isSectorsValid = this.context?.sectorsRef.current?.validate();

		return isNameValid && isSectorsValid;
	}
}
