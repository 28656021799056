import styled, { css } from "styled-components";
import { theme } from "uikit";

const draggableStyle = css`
	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;

	color: ${theme.colors.primary};
`;

const nonDraggableStyle = css`
	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;

	color: ${theme.colors.primary};
`;

const Title = styled.div<Title.Props>`
	pointer-events: none;

	${({ draggable }) => (draggable ? draggableStyle : nonDraggableStyle)}
`;

declare namespace Title {
	interface Props {
		draggable: boolean;
	}
}

export default Title;
