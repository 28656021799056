import React from "react";
import { useTranslation } from "react-i18next";
import Orders from "../../../../../Modal/components/Content/components/Orders";
import tPath from "../../../../../../constants/tPath";
import PaymentOutput from "./components/PaymentOutput";

const OrdersOutput: React.FC<OrdersOutput.Props> = ({ value }) => {
	const { t } = useTranslation();

	return value.active ? (
		<>
			<PaymentOutput value={value.payment} />
			{value.types.length ? (
				<>
					<div>
						{value.types
							.map((tp) =>
								t(`${tPath}.modal.orders.types.options.${tp}`),
							)
							.join(", ")}
					</div>
					<span>/</span>
				</>
			) : null}
		</>
	) : null;
};

declare namespace OrdersOutput {
	interface Props {
		value: Orders.Value;
	}
}

export default OrdersOutput;
