import React, { memo } from "react";
import styled from "styled-components";

import { StyledP, StyledSpan, Style } from "../../../../../components/common";

const StyledText = styled(StyledP)`
	flex-direction: row;
	width: 100%;
`;

const StyledValue = styled(StyledSpan)`
	padding-left: 5px;
	width: 100%;
`;

const Text: React.FC<Text.Props> = ({
	text,
	value,
	valueStyle = {},
	textStyle = {},
}): JSX.Element => (
	<StyledText {...textStyle}>
		{text} <StyledValue {...valueStyle}>{value}</StyledValue>
	</StyledText>
);

export const TextMemo = memo(Text);

declare namespace Text {
	interface Props {
		text: string | React.ReactNode;
		value?: string | number | boolean | React.ReactNode;
		valueStyle?: Style.TextType;
		textStyle?: Style.TextType;
	}
}

export default Text;
