/* eslint-disable consistent-return */
import React, { useCallback, useState } from "react";
import { Button, Float, Icon, Popover as PopoverBase, Row } from "uikit";
import { useTranslation } from "react-i18next";

import BankTransaction from "../../../../../../../../../../../../services/BankTransaction";
import { ModalFilter } from "../../../../../..";

import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	const floatSize = { width: 520, height: 242 };

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

interface Props {
	filter: ModalFilter;
	setFilter: React.Dispatch<React.SetStateAction<ModalFilter>>;
	open: boolean;
	onClose: () => void;
}

const Popover: React.FC<Props> = ({ filter, setFilter, open, onClose }) => {
	const { t } = useTranslation();

	const [requestData, setRequestData] = useState<ModalFilter>({
		statuses: [],
		types: [],
	});

	const [statuses, setStatuses] = useState<
		BankTransaction.PaymentTransactionStatus[]
	>(filter.statuses);
	const [types, setTypes] = useState<BankTransaction.BankTransactionType[]>(
		filter.types,
	);

	const clearFilters = useCallback(() => {
		setStatuses([]);
		setTypes([]);
	}, []);

	const apply = useCallback(() => {
		setFilter(requestData);
		onClose();
	}, [onClose, requestData, setFilter]);

	const updateFilters = useCallback(() => {
		setStatuses(filter.statuses);
		setTypes(filter.types);
	}, [filter.statuses, filter.types]);

	const cancel = useCallback(() => {
		updateFilters();
		onClose();
	}, [onClose, updateFilters]);

	return (
		<PopoverBase
			open={open}
			containerId="root"
			trackerId="filters"
			offset={additionalFiltersOffsetCallback}
			onClose={onClose}
			style={{ boxShadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
		>
			<Root align="stretch" gaps="16px 20px">
				<Header onClose={onClose} />

				<Content
					statuses={statuses}
					setStatuses={setStatuses}
					types={types}
					setTypes={setTypes}
					onChangeRequestData={setRequestData}
				/>

				<Row justify="space-between">
					<Button.Button
						icon={<Icon size={18} id="refresh" />}
						variant="secondary"
						onClick={clearFilters}
					/>
					<Row gaps="20px">
						<Button.Button
							variant="secondary"
							text={t("cancel") || "Cancel"}
							onClick={cancel}
						/>
						<Button.Button
							text={
								t(
									"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.str0",
								) ?? ""
							}
							onClick={apply}
						/>
					</Row>
				</Row>
			</Root>
		</PopoverBase>
	);
};

export default Popover;
