import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import { StyledRow, Button } from "../../../../../common";
import PointOrderModalTableBase from "../..";
import { HandleEventsProps, useHandleEvents } from "../../hooks";

const SettingBase: React.FC<SettingBase.Props> = ({
	rowData,
	disabled,
	onDelete,
	onAddRow,
	countRow,
	handleEventsOptions,
}) => {
	const { t } = useTranslation();
	const { debounceNextElementById, nextElementById } = useHandleEvents();

	const addRow = useCallback(() => {
		if (!rowData.city || !onAddRow) return;
		if (!disabled) {
			onAddRow(rowData.id + 1);
			const next = handleEventsOptions?.nextElement;
			if (next) debounceNextElementById(next);
		}
	}, [
		disabled,
		handleEventsOptions,
		debounceNextElementById,
		onAddRow,
		rowData.city,
		rowData.id,
	]);

	const removeRow = useCallback(() => {
		if (!disabled) {
			if (onDelete) onDelete(rowData.id - 1);
			if (onAddRow) onAddRow(countRow - 1);

			const external = handleEventsOptions?.externalElement;
			const initialElement = handleEventsOptions?.initialElement;
			if (external && countRow === rowData.id && countRow > 2) {
				nextElementById(external);
			} else if (initialElement) nextElementById(initialElement);
		}
	}, [
		countRow,
		disabled,
		handleEventsOptions?.externalElement,
		handleEventsOptions?.initialElement,
		nextElementById,
		onAddRow,
		onDelete,
		rowData.id,
	]);

	const pluse = useMemo(() => {
		const isAdd = [
			Boolean(rowData.raw?.settlement),
			Boolean(rowData.raw?.type),
			rowData.id === countRow,
		];
		return isAdd.every((item) => item === true);
	}, [rowData.raw?.settlement, rowData.raw?.type, rowData.id, countRow]);

	return (
		<StyledRow
			bgC="transparent"
			h="26px"
			w="100%"
			p="0 5px"
			gap="0 5px"
			alignItems="center"
		>
			{pluse && (
				<Button
					disabled={disabled}
					variant="secondary"
					onClick={addRow}
					settingsKeyId="orderModalAddPointRow"
					title={
						t("pointsTable.pointOrderModalTable.setting.str0") ?? ""
					}
					w="24px"
					h="24px"
				>
					<Icon id={"plus"} size={14} />
				</Button>
			)}

			<Button
				variant="secondary"
				disabled={disabled}
				onClick={removeRow}
				title={
					t("pointsTable.pointOrderModalTable.setting.str200") ?? ""
				}
				w="24px"
				h="24px"
			>
				<Icon id={"minus"} size={14} />
			</Button>
		</StyledRow>
	);
};

declare namespace SettingBase {
	interface Props {
		rowData: PointOrderModalTableBase.OrderModal;
		onAddRow?: (num: number) => void;
		onDelete?: (id: number) => void;
		countRow: number;
		disabled?: boolean;
		handleEventsOptions?: HandleEventsProps;
	}
}

export const Setting = memo(SettingBase);

export default SettingBase;
