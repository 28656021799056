export enum Columns {
	NAME = "name",
	TIME = "time",
	PERIOD = "period",
}

type ColumnType = {
	id: string;
	label: string;
	width?: number;
};

export const columns: ColumnType[] = [
	{
		id: Columns.NAME,
		label: "Название",
		width: 300,
	},
	{
		id: Columns.TIME,
		label: "Время",
		width: 200,
	},
	{
		id: Columns.PERIOD,
		label: "Период",
		width: 225,
	},
];

export const defaultColumnIds = columns.map((c) => c.id);

export default { columns, defaultColumnIds, Columns };
