import styled from "styled-components";
import { theme } from "uikit";

const Splitter = styled.div`
	width: 100%;
	height: 4px;

	background-color: ${theme.colors.button_secondary};
`;

export default Splitter;
