import React, { useMemo } from "react";

import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../access";
import { useTypedSelector } from "../../../../../redux/store";
import DriverCall from "../../../../../pages/MainPage/pages/Orders/components/OrderModal/components/Footer/components/DriverCall";
import { Executor } from "../../../../../services";

const CallExecutorButton: React.FC<CallExecutorButton.Props> = ({
	executor,
}) => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.CALL_ORDER_EXECUTOR;

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const executorPhones = useMemo<string[]>(() => {
		const phones =
			executor?.person?.phones?.map((phone) => phone.number) || [];

		return phones;
	}, [executor]);

	const executorPhone = useMemo<string>(() => {
		if (!executorPhones.length) return "";
		return executorPhones?.[0] || "";
	}, [executorPhones]);

	const disabled = useMemo(() => {
		if (!executor?.id) return true;
		if (!sipToDispatcherId) return true;
		if (!executorPhone) return true;
		return false;
	}, [sipToDispatcherId, executorPhone, executor]);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<DriverCall
			disabled={disabled}
			mainPhone={executorPhone}
			phones={executorPhones}
		/>
	);
};

declare namespace CallExecutorButton {
	interface Props {
		executor: Executor.Model | null | undefined;
	}
}

export default CallExecutorButton;
