import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import {
	useArchiveOrders,
	useArchiveSwitcher,
	useGetOrderTab,
	useOrderUpdate,
} from "../../../../../../hooks";
import { Schema } from "../../../../../../../../../../redux/constants/OrdersPage/order";
import { useWidths } from "../../../../../../../../../../hooks/useTableSettings";
import tables from "../../../../../../../../../../constants/tables";
import OrderForm from "../../../../../../../Orders/components/OrderModal/OrderForm";
import PointModal from "../../../../../../../../../../components/PointModal";
import { ColumnId } from "../../../../../../../../../../constants/tables/types";
import LegacyTableColumn from "../../../../../../../../../../types/TableColumn";
import { OrderModalProvider } from "../../../../../../../context";
import { StyledColumn } from "../../../../../../../../../../components/common";
import { ArchiveInfoModalFooterMemo } from "../ArchiveInfoModalFooter";
import InfoModalTabs from "../InfoModalTabs";

declare namespace InfoModalOrder {
	interface Props {
		saveSlot?: string;
	}
}

const InfoModalOrder: React.FC<InfoModalOrder.Props> = ({
	saveSlot = "archives.orderArchiveModal",
}) => {
	const [allowMapFocusing, setPermissionBounds] = useState(true); // TODO: Replace so that OrderForm does not throw error

	const {
		orders: { order, activeOrderId, activePoint },
		setActivePoint,
	} = useArchiveOrders();
	const {
		orderToggles: {
			isDisabledOrderForm,
			isSaveOrderForm,
			isButtonSaveOrderForm,
		},
		setButtonSaveOrderForm,
		setSaveOrderForm,
	} = useArchiveSwitcher();
	const { t } = useTranslation();
	const { points, tab } = useGetOrderTab(order);
	const { update, addParams, setDataToOrder } = useOrderUpdate();

	const onFormChange = useDebouncedCallback((schema: Schema) => {
		if (!tab) return;
		const data = _.cloneDeep(schema);
		addParams(data);
		setButtonSaveOrderForm("on");
	}, 500);

	useEffect(() => {
		if (isSaveOrderForm) {
			update();
			setSaveOrderForm("off");
		}
	}, [isSaveOrderForm, update, setSaveOrderForm]);

	const [pointModal, setPointModal] = useState<{
		visible: boolean;
		isEdit: boolean;
	}>({ visible: false, isEdit: false });

	const onOpenPointModal = useCallback(() => {
		setPointModal({ visible: true, isEdit: false });
	}, [setPointModal]);

	const onEditPoint = useCallback(() => {
		setPointModal({ isEdit: true, visible: true });
	}, [setPointModal]);

	const handleClosePointModal = useCallback(
		(data?: any) => {
			if (data) setPointModal({ visible: false, isEdit: false });
			else setPointModal({ visible: false, isEdit: true });
		},
		[setPointModal],
	);

	const onSubmitPointModal = useCallback(
		(point: any) => {
			if (!tab) return;
			const form = _.cloneDeep(tab.form);

			if (pointModal.isEdit) form.points[activePoint] = point;
			else form.points.push(point);

			setDataToOrder(form);
			handleClosePointModal(true);
			if (!isButtonSaveOrderForm) setButtonSaveOrderForm("on");
		},
		[
			activePoint,
			handleClosePointModal,
			isButtonSaveOrderForm,
			pointModal.isEdit,
			setButtonSaveOrderForm,
			setDataToOrder,
			tab,
		],
	);

	const onRemovePoint = useCallback(
		(points: Schema["points"]) => {
			if (!tab) return;
			const form = _.cloneDeep(tab.form);
			form.points = points;
			setDataToOrder(form);
		},
		[setDataToOrder, tab],
	);

	const onModifyPoint = useCallback(
		(points: Schema["points"]) => {
			if (!tab) return;
			const form = _.cloneDeep(tab.form);
			form.points = points;
			setDataToOrder(form);
		},
		[setDataToOrder, tab],
	);

	// ---- Work with modal column widths
	const { widths, setWidths } = useWidths(saveSlot, "orderArchiveModal");

	const onColumnWidthChange = useCallback(
		(key: ColumnId<"orderArchiveModal">, width: number) => {
			if (widths) setWidths({ ...widths, [key]: width });
		},
		[widths, setWidths],
	);

	const onColumnResize = useCallback(
		(width: any, columnKey: any) => onColumnWidthChange(columnKey, width),
		[onColumnWidthChange],
	);

	const translatedDefaultColumns = useMemo<
		LegacyTableColumn<string>[]
	>(() => {
		const columns = tables.orderArchiveModal?.columns?.map((column) => ({
			label: t(column?.label),
			key: column?.id,
			resizable: true,
			width: widths[column?.id],
			accessor: column?.id,
			isChecked: true,
		}));

		return columns;
	}, [widths, t]);

	if (!tab) return <></>;
	if (!activeOrderId) return <></>;

	return (
		<OrderModalProvider tab={tab}>
			<StyledColumn
				over="hidden"
				bgC="#ffffff"
				br="5px"
				zi="1"
				w="clamp(1302px, 80vw, 1402px)"
				h="clamp(574px, 84vh, 768px)"
			>
				<OrderForm
					heightModal="100%"
					setPermissionBounds={setPermissionBounds}
					disabled={!isDisabledOrderForm}
					tab={tab}
					tableData={points || []}
					activePoint={activePoint}
					onFormChange={onFormChange}
					onOpenPointModal={onOpenPointModal}
					onEditPoint={onEditPoint}
					onChangeActivePoint={setActivePoint}
					onRowClick={setActivePoint}
					onRemovePoint={onRemovePoint}
					onModifyPoint={onModifyPoint}
					onColumnResize={onColumnResize}
					tableSchema={translatedDefaultColumns}
					rightPart={<InfoModalTabs />}
					footer={<ArchiveInfoModalFooterMemo />}
				/>
			</StyledColumn>
			{pointModal.visible && (
				<PointModal
					taxiServiceId={tab.form.taxiServiceId}
					defaultCity={tab.state?.defaultCity}
					popularAddresses={tab.state?.popularAddresses || []}
					editPoint={pointModal.isEdit && points[activePoint]}
					isFirstPoint={points.length === 0}
					onClose={handleClosePointModal}
					onSubmit={onSubmitPointModal}
				/>
			)}
		</OrderModalProvider>
	);
};

export default InfoModalOrder;
