import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.button<Root.Props>`
	all: unset;

	position: relative;

	display: flex;

	align-items: center;
	justify-content: center;

	width: 28px;
	height: 28px;

	border-radius: 5px;

	background: ${theme.colors.white};

	cursor: pointer;

	overflow: hidden;

	--icon-color-1: ${({ active = false }) =>
		active ? theme.colors.accent : theme.colors.primary};

	&:hover {
		&::before {
			content: "";

			position: absolute;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.03);
		}
	}

	&:disabled {
		background: #f5f5f5;

		--icon-color-1: #b5c0c7;
	}
`;

declare namespace Root {
	interface Props {
		active?: boolean;
	}
}

export default Root;
