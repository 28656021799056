import React from "react";
import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	background: ${theme.colors.button_secondary};
`;
const Title = styled.div`
	height: 28px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;

	color: ${theme.colors.primary};
`;

const Header: React.FC<Header.Props> = ({ title }) => (
	<Root sizes="auto!*" style={{ padding: "24px" }}>
		<Title>{title}</Title>
	</Root>
);

declare namespace Header {
	interface Props {
		title: string;
	}
}

export default Header;
