import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";

export default function createService(service) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() =>
			prpcow?.theirsModel.service.create(service),
		).then(async (result) => result);
	};
}
