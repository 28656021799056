import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row } from "uikit";

const Footer: React.FC<Footer.Props> = ({ onReset, onCancel, onApply }) => {
	const { t } = useTranslation();

	return (
		<Row justify="space-between">
			<Button.Button
				icon={<Icon size={18} id="refresh" />}
				variant="secondary"
				onClick={onReset}
			/>
			<Row gaps="10px">
				<Button.Button
					variant="secondary"
					text={t("cancel") || "Cancel"}
					onClick={onCancel}
				/>
				<Button.Button
					text={
						t(
							"orderPageWidgets.map2.header.filters.content.footer.str0",
						) ?? ""
					}
					onClick={onApply}
				/>
			</Row>
		</Row>
	);
};

declare namespace Footer {
	interface Props {
		onReset: () => void;

		onCancel: () => void;
		onApply: () => void;
	}
}

export default Footer;
