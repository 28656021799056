import { useCallback, useMemo, useState } from "react";

import Client from "../services/Client";
import Car from "../services/Car";
import Executor from "../services/Executor";

export interface UseGetRating {
	response: number | null;
	get: (
		id: number,
		type: "executor" | "customer" | "car",
	) => Promise<any | null>;
	reset: () => void;
}

export type UseGetRatingProps = () => UseGetRating;

/**
 *  `useGetRating`
 */
export const useGetRating: UseGetRatingProps = () => {
	const [response, setResponse] = useState<UseGetRating["response"]>(null);

	const get = useCallback<UseGetRating["get"]>(
		async (id, type) => {
			try {
				let res: UseGetRating["response"] = null;

				if (type === "customer") res = await Client.rating({ id });
				if (type === "car") res = await Car.rating({ id });
				if (type === "executor") res = await Executor.rating({ id });

				setResponse(res);

				return res;
			} catch (error) {
				console.log("[ OrderModal ] res error", error);
				return null;
			}
		},
		[setResponse],
	);

	const reset = useCallback(() => {
		setResponse(null);
	}, [setResponse]);

	return useMemo<UseGetRating>(
		() => ({
			response,
			reset,
			get,
		}),
		[response, reset, get],
	);
};
