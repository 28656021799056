import React from "react";

import s from "./ScreenDirectory.module.css";
import Objects from "./components/Objects";
import PriceZones from "./components/PriceZones";
import CarModelsAndBrands from "./components/CarModelsAndBrands";
import Colors from "./components/Colors";
import CarClasses from "./components/CarClass";
import Services from "./components/Services";
import Sectors from "./components/Sectors";
import Parkings from "./components/Parkings";

export default function ScreenDirectory(props) {
	switch (props.stringPage) {
		case "objects":
			return (
				<div className={s.screenContainer}>
					<Objects />
				</div>
			);
		case "parkings":
			return (
				<div className={s.screenContainer}>
					<Parkings />
				</div>
			);
		case "pricesZone":
			return (
				<div className={s.screenContainer}>
					<PriceZones />
				</div>
			);
		case "sectors":
			return (
				<div className={s.screenContainer}>
					<Sectors />
				</div>
			);
		case "carSettings":
			return (
				<div className={s.screenContainer}>
					<CarModelsAndBrands />
				</div>
			);
		case "colors":
			return (
				<div className={s.screenContainer}>
					<Colors />
				</div>
			);
		case "carClass":
			return (
				<div className={s.screenContainer}>
					<CarClasses />
				</div>
			);
		// case "carTypes":
		// 	return (
		// 		<div className={s.screenContainer}>
		// 			<VehicleTypes />
		// 		</div>
		// 	);
		case "carServices":
			return (
				<div className={s.screenContainer}>
					<Services />
				</div>
			);
		default:
			return (
				<div className={s.screenContainer}>
					<CarModelsAndBrands />
				</div>
			);
	}
}
