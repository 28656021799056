import React, { useRef, useEffect } from "react";
import { Polyline } from "react-leaflet";
import L from "leaflet";
import "leaflet-polylinedecorator";

const PolylineDecorator = ({ ...props }) => {
	const polyRef = useRef();
	useEffect(() => {
		const params = { patterns: [] };
		let mapElement = null;
		if (props.patterns) {
			params.patterns = props.patterns;
		}
		if (props.positions.length) {
			mapElement = L.polylineDecorator(polyRef.current, params).addTo(
				polyRef.current._map,
			);
		}

		return function cleanup() {
			if (mapElement) mapElement.remove();
		};
	}, [props.positions, props.patterns]);
	return <Polyline ref={polyRef} {...props} />;
};
export default PolylineDecorator;
