import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
	useLayoutEffect,
} from "react";
import { CheckBox, Select } from "uikit";
import { useTranslation } from "react-i18next";

import { BaseRateAdjustmentType } from "../../../../../../../../../../../../services/Tariff/enums";
import { Service } from "../../../../../../../../../../../../services";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import getOptions from "../../../../../../../../../../../../utils/generateOptions";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../hooks";
import {
	StyledColumn,
	StyledRow,
	ValueStepper,
	SuspenseLoader,
} from "../../../../../../../../../../../../components/common";
import tPath from "../../../../../../constants/tPath";
import TabRoot from "../../components/TabRoot";

const surchargesOptions = getOptions(Object.values(BaseRateAdjustmentType));

const ServicesTab: React.FC<ServicesTab.Props> = memo(
	({ visible, initialServices, onChange, root, taxiServiceIds }) => {
		const { t } = useTranslation();
		const language = useTypedSelector((state) => state.session.language);
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const { models } = useTypedSelector(
			(state) => state.preferencesReducer.services.data,
		);

		const services = useMemo(
			() =>
				taxiServiceIds?.length
					? [...models].filter((item) => {
							if (!item.active) return false;

							const existTaxiServiceIds = item.taxiServices
								? item.taxiServices?.map((item) => item.id)
								: [];

							if (!existTaxiServiceIds.length) return true;

							return (
								taxiServiceIds?.some((item) =>
									existTaxiServiceIds.includes(item),
								) === true
							);
					  })
					: [],
			[models, taxiServiceIds],
		);

		const createExternalServices = useCallback(
			(innerServices: Service.Model[]) =>
				innerServices.map((service) => {
					const exists = initialServices.find(
						(initService) => initService.id === service.id,
					);

					if (!exists) {
						return {
							id: service.id,
							name: service.name,
							active: false,
							activeInCustomerApp: false,
							adjustment: {
								type: null,
								value: 0,
							},
						};
					}

					return {
						id: service.id,
						name: service.name,
						active: exists.additionalFields?.active || false,
						activeInCustomerApp:
							exists.additionalFields?.activeInCustomerApp ||
							false,
						adjustment: exists.additionalFields?.adjustment || {
							type: null,
							value: 0,
						},
					};
				}),
			[initialServices],
		);

		const [externalServices, setExternalServices] = useState(
			createExternalServices(services),
		);

		const updateServiceById = useCallback(
			(id: number, data: any) => {
				const updated = externalServices.map((srv) => {
					if (srv.id === id) {
						return { ...srv, ...data };
					}
					return srv;
				});

				setExternalServices(updated);
				onChange(
					updated.map((item) => ({
						id: item.id,
						additionalFields: {
							active: item.active,
							activeInCustomerApp: item.activeInCustomerApp,
							adjustment: item.adjustment,
						},
					})),
				);
			},
			[externalServices, onChange],
		);

		useLayoutEffect(() => {
			[...models].forEach((item) => {
				const existTaxiServiceIds = item.taxiServices
					? item.taxiServices?.map((item) => item.id)
					: [];

				if (!existTaxiServiceIds.length) return;

				if (!item.active) {
					updateServiceById(item.id, {
						active: item.active,
					});

					return;
				}

				const active = taxiServiceIds.some((item) =>
					existTaxiServiceIds.includes(item),
				);

				if (!active) {
					updateServiceById(item.id, { active });
				}
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [models, taxiServiceIds]);

		useEffect(() => {
			setExternalServices(createExternalServices(services));
		}, [createExternalServices, services]);

		const memoSurchargeOptions = useMemo(
			() =>
				surchargesOptions
					.filter(
						(option) =>
							!(
								root &&
								[
									BaseRateAdjustmentType.SURCHARGE_TO_DEFAULT_RATE,
									BaseRateAdjustmentType.COEFFICIENT_TO_DEFAULT_RATE,
								].includes(option.key as BaseRateAdjustmentType)
							),
					)
					.map((option) => ({
						...option,
						label: t(
							`${tPath}.mainModal.tabs.main.surcharges.options.${option.key}`,
						),
					})),
			[root, t],
		);

		return (
			<TabRoot hasPaddings visible={visible}>
				<StyledColumn w="max-content" gap="10px">
					{externalServices.length ? (
						externalServices.map((item) => (
							<StyledRow
								key={item.id}
								alignItems="center"
								w="100%"
								gap="10px"
								justify="space-between"
							>
								<StyledRow
									gap="10px"
									alignItems="center"
									w={{
										min: "200px",
										max: "350px",
									}}
								>
									<CheckBox
										label={item.name[language]}
										value={item.active}
										onChange={(active) => {
											updateServiceById(item.id, {
												active,
											});
										}}
									/>
								</StyledRow>
								<StyledRow
									gap="10px"
									alignItems="center"
									w="100%"
								>
									<Select
										style={{ minWidth: "300px" }}
										options={memoSurchargeOptions}
										placeholder={
											t(
												`${tPath}.mainModal.tabs.main.surcharges.options.placeholder`,
											) || ""
										}
										value={item.adjustment.type as any}
										onChange={(type) => {
											updateServiceById(item.id, {
												adjustment: {
													...item.adjustment,
													type,
												},
											});
										}}
									/>
									<StyledRow gap="10px" alignItems="center">
										<ValueStepper
											disabled={!item.adjustment?.type}
											value={item.adjustment.value}
											onChange={(value) => {
												updateServiceById(item.id, {
													adjustment: {
														...item.adjustment,
														value,
													},
												});
											}}
										/>

										{item.adjustment.type?.includes(
											"surcharge",
										) && (
											<span>
												{currencyGlobalSettings}
											</span>
										)}
									</StyledRow>
								</StyledRow>
								<StyledRow gap="10px" alignItems="center">
									<StyledRow
										gap="10px*"
										alignItems="center"
										style={{ width: "100%" }}
									>
										<CheckBox
											label={
												t(
													"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.servicesTab.str201",
												) ?? ""
											}
											value={item.activeInCustomerApp}
											onChange={(activeInCustomerApp) => {
												updateServiceById(item.id, {
													activeInCustomerApp,
												});
											}}
										/>
									</StyledRow>
								</StyledRow>
							</StyledRow>
						))
					) : (
						<StyledRow
							position="absolute"
							top="50%"
							left="50%"
							justify="center"
							alignItems="center"
						>
							<SuspenseLoader
								loader="5"
								text={
									t(
										`${tPath}.mainModal.tabs.main.chooseBranch`,
									) || ""
								}
							/>
						</StyledRow>
					)}
				</StyledColumn>
			</TabRoot>
		);
	},
);

declare namespace ServicesTab {
	interface Props {
		taxiServiceIds: number[];
		initialServices: ServiceType[];
		onChange: (services: any[]) => void;
		visible: boolean;
		disabled?: boolean;
		root: boolean;
	}

	export interface ServiceType {
		readonly id: number;
		readonly additionalFields?: AdditionalServiceFieldsType;
	}
	interface AdditionalServiceFieldsType {
		readonly active: boolean;
		readonly activeInCustomerApp: boolean;
		readonly adjustment: ServiceAdjustmentType;
	}
	interface ServiceAdjustmentType {
		type: BaseRateAdjustmentType | null;
		value: number;
	}
}

export default ServicesTab;
