import React, { Dispatch, PropsWithChildren, memo, useMemo } from "react";
import { CheckBoxWithContent, Column } from "uikit";

import Label from "./components/Label";
import Root from "./components/Root";

function isPropsWithToggle(
	props: LabeledField.Props,
): props is LabeledField.PropsWithToggle {
	return "toggle" in props;
}

const LabeledField = memo<LabeledField.Props>((props) => {
	const { children, label } = props;

	const labelRender = useMemo(() => <Label>{label}</Label>, [label]);

	return (
		<Root gaps="8px" sizes="auto! 1fr" maxedWidth {...props}>
			{isPropsWithToggle(props) ? (
				<CheckBoxWithContent
					value={props.toggle}
					gap="10px"
					style={{
						alignSelf: "start",
					}}
					onChange={props.onChangeToggle}
				>
					{labelRender}
				</CheckBoxWithContent>
			) : (
				label
			)}
			{children}
		</Root>
	);
});

declare namespace LabeledField {
	interface PropsBase extends PropsWithChildren, Column.Props {
		label?: string;
	}

	interface PropsWithToggle extends PropsBase {
		toggle: boolean;

		onChangeToggle?: Dispatch<boolean>;
	}

	type Props = PropsBase | PropsWithToggle;
}

export default LabeledField;
