/* eslint-disable no-shadow */
import { LatLngLiteral } from "leaflet";
import { clone, isEqual } from "lodash";
import { getPRPC } from "../../../hooks/usePRPC";
import MapLanguage from "../../../types/MapLanguage";
import ServiceSubscribeOptionsBase from "../../../types/ServiceSubscribeOptionsBase";
import LocalObject from "./LocalObject";
import ModelService from "../ModelService";
import localObjectGroups from "../../reducers/map/localObjectGroups";
import { store as dataStore } from "../../store";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import map from "../../reducers/map";
import Map from ".";
import { SortingOrder } from "../../../types/SortingOrder";
import TaxiService from "../../../services/TaxiService";

function transformItemFromResponse(
	item: LocalObjectGroup.Response,
): LocalObjectGroup {
	return {
		id: item.id,

		taxiServiceId: item.taxiService.id,

		status: item.status,
		visibility: item.visibility,
		isStreet: item.isStreet,

		center: item.geoCentroid,
		vertices: item.vertices?.[0],
		fields: item.additionalFields,
		localObjects: item.localObjects.map((localObject) => ({
			id: localObject.id,

			status: localObject.status,
			visibility: localObject.visibility,

			fields: localObject.additionalFields,
			point: localObject.point,

			createdAt: localObject.createdAt,
			updatedAt: localObject.updatedAt,
			deletedAt: localObject.deletedAt,
		})),
	};
}

async function destroyOne(id: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() => prpc.theirsModel.localObjectGroup.delete(id));
}

interface LocalObjectGroup {
	id: number;

	taxiServiceId: number;

	status: boolean;
	visibility: boolean;
	isStreet: boolean;

	center?: LatLngLiteral;
	vertices?: LatLngLiteral[];
	fields: Record<MapLanguage, Partial<LocalObjectGroup.Field>>;

	localObjects: Partial<LocalObject>[];
}

class LocalObjectGroup extends ModelService<
	LocalObjectGroup.SubscribeOptions,
	LocalObjectGroup,
	"localObjectGroups"
>(localObjectGroups, (state) => state.map.localObjectGroups) {
	static Global = {
		async index(options: LocalObjectGroup.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.localObjectGroup.getAll(options),
			);

			return {
				cache: (result.items as LocalObjectGroup.Response[]).map(
					transformItemFromResponse,
				),
				limit: result.pagination.limit as number,
				offset: result.pagination.offset as number,
				total: result.pagination.count as number,
				deprecated: false,
			};
		},
	};

	static isNew(
		localObject: LocalObjectGroup.ForSave,
	): localObject is LocalObjectGroup.New {
		return !("id" in localObject);
	}

	static isModified(
		localObject: LocalObjectGroup.ForSave,
	): localObject is LocalObjectGroup.Modified {
		return "id" in localObject;
	}

	static async store(object: LocalObjectGroup.New) {
		const prpc = getPRPC();

		if (!prpc) return;

		const vertices = clone(object.vertices);

		if (vertices && !isEqual(vertices[vertices.length - 1], vertices[0]))
			vertices.push(vertices[0]);

		await createRPCQuery(() =>
			prpc.theirsModel.localObjectGroup.create({
				taxiServiceId: object.taxiServiceId,

				isStreet: object.isStreet,
				status: object.status,
				visibility: object.visibility,
				fields: object.fields,
				vertices,
				localObjects: object.localObjects,
			}),
		);

		dataStore.dispatch(map.localObjectGroups.actions.deprecateAll());
	}

	static async update(object: LocalObjectGroup.Modified) {
		const prpc = getPRPC();

		if (!prpc) return;

		const vertices = clone(object.vertices);

		if (vertices && !isEqual(vertices[vertices.length - 1], vertices[0]))
			vertices.push(vertices[0]);

		await createRPCQuery(() =>
			prpc.theirsModel.localObjectGroup.update(object.id, {
				taxiServiceId: object.taxiServiceId,

				isStreet: object.isStreet,
				status: object.status,
				visibility: object.visibility,
				fields: object.fields,
				vertices,
				localObjects: object.localObjects,
			}),
		);

		dataStore.dispatch(map.localObjectGroups.actions.deprecateAll());
	}

	static async destroy(id: number[] | number) {
		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		dataStore.dispatch(map.localObjectGroups.actions.deprecateAll());
	}
}

declare namespace LocalObjectGroup {
	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<LocalObjectGroup> {
		polygon?: boolean;
		isStreet?: boolean;
		status?: boolean;
		visibility?: boolean;
		lang?: MapLanguage;
		order?: Record<keyof LocalObjectGroup, SortingOrder> & {
			fields?: {
				[key in keyof LocalObjectGroup.Field]?: SortingOrder;
			};
		};
	}

	interface Field {
		title: string;
		country: string;
		countryCode: string;
		street: string;
		streetType: string;
		settlement: string;
		settlementType: string;
		district: string;
		region: string;
		address: string;
		house: string;
	}

	type New = Omit<LocalObjectGroup, "id">;

	type Modified = LocalObjectGroup;

	type ForSave = New | Modified;

	interface Response {
		id: number;

		geoCentroid: LatLngLiteral;
		status: boolean;
		visibility: boolean;
		isStreet: boolean;
		vertices?: [LatLngLiteral[]];
		additionalFields: Record<Map.Language, LocalObjectGroup.Field>;
		localObjects: Response.LocalObject[];
		taxiService: TaxiService.Model;

		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	namespace Response {
		interface LocalObject {
			id: number;

			point: LatLngLiteral;
			additionalFields: Record<Map.Language, LocalObject.Field>;

			status: boolean;
			visibility: boolean;

			createdAt: Date | null;
			updatedAt: Date | null;
			deletedAt: Date | null;
		}
	}
}

export default LocalObjectGroup;
