import React, {
	Dispatch,
	HTMLProps,
	SetStateAction,
	memo,
	useCallback,
	useRef,
} from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../../../../../components/Modal";
import useOnClickOutside from "../../../../../../../../../../hooks/useOnClickOutside";
import { downloadViaURL } from "../../../../../../../../../../utils/file";
import tPath from "../../../../constants/tPath";

const Player: React.FC<Player.Props> = memo(
	({ setIsPlaying, onClose, controls = true, src, ...props }) => {
		const { t } = useTranslation();

		const ref = useRef<HTMLDivElement>(null);
		const audioRef = useRef<HTMLAudioElement>(null);

		const handlePlay = useCallback(() => {
			setIsPlaying(true);
		}, [setIsPlaying]);

		const handlePause = useCallback(() => {
			setIsPlaying(false);
		}, [setIsPlaying]);

		useOnClickOutside(ref, onClose);

		const downloadSound = useCallback(async () => {
			if (src) downloadViaURL(src, "Record");
		}, [src]);

		return (
			<Modal dimmed>
				<div
					ref={ref}
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: 15,
					}}
				>
					<audio
						style={{
							width: "600px",
						}}
						ref={audioRef}
						autoPlay
						src={src}
						controls={controls}
						onPlay={props.onPlay ?? handlePlay}
						onPause={props.onPause ?? handlePause}
						{...props}
					></audio>
					<Button.Button
						onClick={downloadSound}
						text={t(`${tPath}.download`) || ""}
						variant="secondary"
						icon={<Icon id={"save"} size={20} />}
					/>
				</div>
			</Modal>
		);
	},
);

declare namespace Player {
	interface Props extends HTMLProps<HTMLAudioElement> {
		setIsPlaying: Dispatch<SetStateAction<boolean>>;
		onClose: () => void;
	}
}

export default Player;
