import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, Row, Stepper, useInternal } from "uikit";
import CustomerRate from "../../../../../../../../../../../../../../../../services/CustomerRate";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Label from "../../../../../../../../../../../../../../../../components/Label";
import InputModal from "../../../../../../../../../../../../../../../../components/InputModal";
import UnitSelect from "../../../UnitSelect";

const EditModal: React.FC<EditModal.Props> = ({
	value,

	isNew,

	onClose,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const [internalValue, setInternalValue] = useInternal(value);

	const internalValueEditor = useObjectEditor(
		internalValue,
		setInternalValue,
	);

	const thresholdValue = internalValueEditor.useGetter("threshold");
	const thresholdOnChange = internalValueEditor.useSetter("threshold");

	const discountEditor = internalValueEditor.usePropertyEditor("discount");

	const discountValueValue = discountEditor.useGetter("value");
	const discountValueOnChange = discountEditor.useSetter("value");

	const discountUnitValue = discountEditor.useGetter("unit");
	const discountUnitOnChange = discountEditor.useSetter("unit");

	const onlineDiscountEditor =
		internalValueEditor.usePropertyEditor("onlineDiscount");

	const onlineDiscountActiveValue = onlineDiscountEditor.useGetter("active");
	const onlineDiscountActiveOnChange =
		onlineDiscountEditor.useSetter("active");

	const onlineDiscountValueValue = onlineDiscountEditor.useGetter("value");
	const onlineDiscountValueOnChange = onlineDiscountEditor.useSetter("value");

	const onlineDiscountUnitValue = onlineDiscountEditor.useGetter("unit");
	const onlineDiscountUnitOnChange = onlineDiscountEditor.useSetter("unit");

	const inputModalOnSubmit = useCallback(() => {
		onSubmit(internalValueEditor.value);
	}, [internalValueEditor.value, onSubmit]);

	return (
		<InputModal
			title={
				isNew
					? t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.editModal.str200",
					  ) ?? ""
					: t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.editModal.str201",
					  ) ?? ""
			}
			width={400}
			onClose={onClose}
			onSubmit={inputModalOnSubmit}
		>
			<Column gaps="8px*" style={{ padding: "8px" }}>
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.editModal.str100",
						) ?? ""}
					</Label>
					<Stepper
						value={thresholdValue}
						min={0}
						onChange={(value) => thresholdOnChange(value ?? 0)}
					/>
					<div />
				</Row>
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.editModal.str101",
						) ?? ""}
					</Label>
					<Stepper
						value={discountValueValue}
						min={0}
						onChange={(value) => discountValueOnChange(value ?? 0)}
					/>
					<UnitSelect
						value={discountUnitValue}
						onChange={discountUnitOnChange}
					/>
				</Row>
				<CheckBoxWithContent
					value={onlineDiscountActiveValue}
					maxContentWidth
					onChange={onlineDiscountActiveOnChange}
				>
					<Row
						align="center"
						gaps="10px*"
						sizes="1fr auto! 60px"
						style={{ height: 32 }}
						maxedWidth
					>
						<Label>
							{t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.editModal.str101",
							) ?? ""}{" "}
							{t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.rules.editModal.str202",
							) ?? ""}
						</Label>
						<Stepper
							value={onlineDiscountValueValue}
							disabled={!onlineDiscountActiveValue}
							min={0}
							onChange={(value) =>
								onlineDiscountValueOnChange(value ?? 0)
							}
						/>
						<UnitSelect
							value={onlineDiscountUnitValue}
							disabled={!onlineDiscountActiveValue}
							onChange={onlineDiscountUnitOnChange}
						/>
					</Row>
				</CheckBoxWithContent>
			</Column>
		</InputModal>
	);
};

declare namespace EditModal {
	type Value = CustomerRate.Model.ModeData.Item;

	interface Props {
		value: Value;

		isNew?: boolean;

		onClose: () => void;
		onSubmit: Dispatch<Value>;
	}
}

export default EditModal;
