/* eslint-disable no-shadow */

import getOptions from "../../../../../../../../utils/generateOptions";

export const AutoRateRuleWeekday = {
	ALL: 7,
	MF: 8,
	SS: 9,
	MONDAY: 0,
	TUESDAY: 1,
	WEDNESDAY: 2,
	THURSDAY: 3,
	FRIDAY: 4,
	SATURDAY: 5,
	SUNDAY: 6,
};

export const weekOptions = getOptions(Object.values(AutoRateRuleWeekday));
