import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 732px;
	height: 621px;

	border-radius: 10px;

	background: ${theme.colors.white};

	overflow: hidden;
`;

export default Root;
