/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State } from "./interface";

export enum OrderPageExecutorColumnId {
	ONLINE = "online",
	ALIAS = "alias",
	CallSign = "carCallSigns",
	STATUS = "status",
	EXECUTOR_GROUP = "executorGroup",
	IS_WORKING = "isWorking",
	COMPANY = "company",
	TAXI_SERVICE = "taxiService",
}

export const defaultColumnIds: string[] = [
	"online",
	"alias",
	"carCallSigns",
	"status",
	"executorGroup",
	"isWorking",
	"company",
	"taxiService",
];

const initialState: State = {
	columnIds: defaultColumnIds,
};

const setColumnIds: ReduxReducer<State, string[]> = (state, action) => {
	state.columnIds = action.payload;
};

const orderExecutorsTableSettings = createSlice({
	name: Orders.ConstantOrderSlice.EXECUTORS_TABLE_SETTINGS,
	initialState,
	reducers: {
		setColumnIds,
	},
});

export const orderExecutorsTableSettingsAction =
	orderExecutorsTableSettings.actions;
export const orderExecutorsTableSettingsReducer =
	orderExecutorsTableSettings.reducer;
export type orderExecutorsTableSettingsState = State;

export default orderExecutorsTableSettings;
