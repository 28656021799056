/* eslint-disable no-param-reassign */

import { LatLngLiteral } from "leaflet";
import { cloneDeep, defaults } from "lodash";

import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import ModelService from "../../redux/services/ModelService";
import Map from "../../redux/services/Map";
import taxiServices from "../../redux/reducers/taxiService";
import { createObjectLanguageNamesFromObject } from "../../assets/languages/langs";
import Company from "../Company";
import Language from "../Language";

/**
 * @deprecated use services/TaxiService2 instead
 */
class TaxiService extends ModelService<
	TaxiService.SubscribeOptions,
	TaxiService.Model,
	"taxiServices"
>(taxiServices, (state) => state.taxiService) {
	static defaultSharedOptions: TaxiService.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): TaxiService.Model {
		return {
			id: data.id,

			company: data.company
				? Company.fromResponse(data.company)
				: undefined,
			settlement: createObjectLanguageNamesFromObject(data.settlement),
			coordinates: data.settlementPoint,
			settings: cloneDeep(data.settings),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: TaxiService.Model.Modified): any {
		return {
			settings: model.settings,
		};
	}

	static async update(
		object: TaxiService.Model.Modified,
		options?: TaxiService.UpdateOptions,
	) {
		options = defaults(options, TaxiService.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.taxiService.update(
				object.id,
				TaxiService.toRequest(object),
			),
		);

		if (options.deprecate) TaxiService.deprecateAll();
	}

	static Global = {
		async index(options: TaxiService.SubscribeOptions) {
			const prpc = getPRPC();
			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.taxiService.getAll({
					lang: options.lang,
					terse: true,
					near: options.near,
					companyIds: options.companyIds,
				}),
			);

			const cache: TaxiService.Model[] = [];

			result?.items?.forEach((item) => {
				const service = TaxiService.fromResponse(item);
				if (service) cache.push(service);
			});

			const sortCache = cache.sort(
				(taxiService1, taxiService2) =>
					taxiService1.id - taxiService2.id,
			);

			return {
				cache: sortCache,
				offset: 0,
				limit: sortCache.length,
				total: sortCache.length,
				deprecated: false,
			};
		},
	};
}

declare namespace TaxiService {
	interface Model {
		id: number;

		company?: Company.Model;

		settlement: Record<Map.Language, string>;
		coordinates: LatLngLiteral;
		settings: Record<string, any>;

		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	interface SubscribeOptions {
		lang?: Language;
		terse?: boolean;
		near?: LatLngLiteral;
		companyIds?: number[];
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		// type New = Omit<Model, "id" | "createdAt" | "updatedAt" | "deletedAt">;
		type Modified = Partial<
			Omit<Model, "createdAt" | "updatedAt" | "deletedAt">
		>;
	}
}

export default TaxiService;
