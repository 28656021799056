import { isString } from "lodash";
import styled, { css } from "styled-components";
import { Row, theme } from "uikit";
import PropsOf from "uikit/src/types/PropsOf";

const Root = styled(Row)<Root.PropsBase>`
	padding: 1px 6px;

	${({ backgroundColor }) =>
		isString(backgroundColor)
			? `background-color: ${backgroundColor};`
			: ""}

	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;

	color: ${({ textColor }) =>
		isString(textColor) ? textColor : theme.colors.primary};

	${({ highlighted = false }) =>
		highlighted
			? css`
					font-weight: bold;
					text-decoration: underline;

					font-size: 14px;
			  `
			: ""}
`;

declare namespace Root {
	interface PropsBase {
		backgroundColor?: string;
		textColor?: string;
		highlighted?: boolean;
	}

	type Props = PropsOf<typeof Root>;
}

export default Root;
