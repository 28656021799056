import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { tabbable, focusable, isTabbable } from "tabbable";
import FocusTrap from "focus-trap-react";
import { isUndefined } from "lodash";
import { Float, useRender } from "uikit";

import KeyBindLayer from "../KeyBindLayer";

import Root from "./components/Root";

const zeroSize = {
	width: 0,
	height: 0,
};

const Modal: React.FC<Modal.Props> = memo(
	({
		children,
		containerId = "page-content",
		dimmed = false,
		zIndex = 2,
		propagateKeyBinds,
		useFocusTrap,
		pointerEvents = "auto",
	}) => {
		const render = useRender();

		const isInitialFocusDone = useRef(false);

		const container = useMemo(
			() => Float.Container.get(containerId),
			[containerId],
		);

		const size = container?.getSize() ?? zeroSize;

		useEffect(() => {
			const resizeHandler = () => render(true);

			container?.on("size", resizeHandler);

			return () => {
				container?.off("size", resizeHandler);
			};
		}, [container, render]);

		const rootRef = useRef<HTMLDivElement | null>(null);

		// eslint-disable-next-line no-nested-ternary
		const focusTrapActive = isUndefined(useFocusTrap)
			? rootRef.current
				? tabbable(rootRef.current).length >= 0
				: false
			: useFocusTrap;

		const focusTrapOptions = useMemo(
			() => ({
				initialFocus: () => {
					if (isInitialFocusDone.current) return false;

					if (rootRef.current) {
						if (rootRef.current.contains(document.activeElement)) {
							isInitialFocusDone.current = true;

							return false;
						}

						const firstFocusableElement = focusable(
							rootRef.current,
						).find((element) => isTabbable(element));

						firstFocusableElement?.focus?.();

						isInitialFocusDone.current = true;
					}

					return false;
				},

				escapeDeactivates: false,
			}),
			[],
		);

		const setRootRef = useCallback(
			(node: HTMLDivElement | null) => {
				if (!node) return;

				if (rootRef.current !== node) {
					rootRef.current = node;

					render();
				}
			},
			[render],
		);

		const content = useMemo(
			() => (
				<Root
					ref={setRootRef}
					dimmed={dimmed}
					pointerEvents={pointerEvents}
					style={size}
					align="center"
					justify="center"
				>
					{children}
				</Root>
			),
			[children, dimmed, pointerEvents, setRootRef, size],
		);

		return (
			<Float
				style={{
					zIndex,
					pointerEvents,
				}}
				containerId={containerId}
				trackerId={containerId}
			>
				<KeyBindLayer propagate={propagateKeyBinds}>
					<FocusTrap
						active={focusTrapActive}
						focusTrapOptions={focusTrapOptions}
					>
						{content}
					</FocusTrap>
				</KeyBindLayer>
			</Float>
		);
	},
);

declare namespace Modal {
	interface Props extends PropsWithChildren {
		containerId?: string;
		dimmed?: boolean;
		zIndex?: number;
		propagateKeyBinds?: boolean;
		useFocusTrap?: boolean;
		pointerEvents?:
			| "none"
			| "auto"
			| "visiblePainted"
			| "visibleFill"
			| "visibleStroke"
			| "painted"
			| "fill"
			| "stroke";
	}
}

export default Modal;
