import { RefObject } from "react";
import { react } from "uikit";

import TaxiServiceForm from "../../../../../../../../../../components/TaxiServiceForm";
import { NamesBase } from "../../../../../../../../../../components/common";
// import NameForm from "../NameForm";
import PointForm from "../PointForm";
import GroupObjectForm from "../GroupObjectForm";

interface Context {
	taxiServiceFormRef: RefObject<TaxiServiceForm.Controller>;
	nameFormRef: RefObject<NamesBase.Controller>;
	pointFormRef: RefObject<PointForm.Controller>;
	groupObjectFormRefs: RefObject<GroupObjectForm.Controller | null>[];
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		let isGroupObjectFormsValid = true;

		// eslint-disable-next-line no-restricted-syntax
		for (const groupObjectFormRef of [
			...(this.context?.groupObjectFormRefs ?? []),
		].reverse()) {
			if (!groupObjectFormRef.current?.validate())
				isGroupObjectFormsValid = false;
		}

		const isNameFormValid = this.context?.nameFormRef.current?.validate();
		const isTaxiServiceFormValid =
			this.context?.taxiServiceFormRef.current?.validate();

		return (
			isTaxiServiceFormValid && isNameFormValid && isGroupObjectFormsValid
		);
	}

	scrollToLocalObject(index: number) {
		this.context?.groupObjectFormRefs[index]?.current?.scrollIntoView({
			block: "center",
			inline: "center",
			behavior: "smooth",
		});
	}
}
