/* eslint-disable no-shadow */
import React, { memo, useCallback } from "react";
import { DateAndTimePicker, Row } from "uikit";
import { useTranslation } from "react-i18next";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../redux/store";
import { modifyFilter } from "../../../../../../../../../../redux/reducers/Archives/Calls";
import useDatePickerLocale from "../../../../../../../../../../hooks/useDatePickerLocale";

const DateRange: React.FC<DateRange.Props> = memo(() => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const locale = useDatePickerLocale();
	const from = useTypedSelector((s) => s.archiveCalls.filter.dateFrom);
	const to = useTypedSelector((s) => s.archiveCalls.filter.dateTo);

	const handleFromChange = useCallback(
		(newFrom) => {
			dispatch(modifyFilter({ dateFrom: newFrom }));
		},
		[dispatch],
	);

	const handleToChange = useCallback(
		(newTo) => {
			dispatch(modifyFilter({ dateTo: newTo }));
		},
		[dispatch],
	);

	return (
		<Row gaps="7px*" align="center">
			<div>{t(`from`, { context: "capitalized" })}</div>
			<DateAndTimePicker
				value={from}
				onChange={handleFromChange}
				maxDate={new Date()}
				locale={locale}
			/>
			<div>{t(`to`)}</div>
			<DateAndTimePicker
				value={to}
				onChange={handleToChange}
				minDate={from}
				locale={locale}
			/>
		</Row>
	);
});

declare namespace DateRange {
	interface Props {}
}

export default DateRange;
