import React, { MouseEvent, ReactNode, memo, useCallback } from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";
import Title from "./components/Title";
import CloseButton from "./components/CloseButton";

const Header = memo<Header.Props>(({ draggable, title, onClose, action }) => {
	const rootOnMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
	}, []);

	return (
		<Root
			draggable={draggable}
			className="draggable-area"
			sizes={draggable ? "auto! 1fr auto!" : "1fr auto!"}
			gaps="5px*"
			align="center"
			maxedWidth
			onMouseDown={rootOnMouseDown}
		>
			{draggable && (
				<Icon
					id="dragging"
					size={draggable ? 12 : 15}
					colors={[theme.colors.disabled_text]}
				/>
			)}
			<Title draggable={draggable}>{title ?? ""}</Title>
			{action}
			<CloseButton onClick={onClose} />
		</Root>
	);
});

declare namespace Header {
	interface Props {
		draggable: boolean;
		title?: ReactNode;
		action?: ReactNode;

		onClose: () => void;
	}
}

export default Header;
