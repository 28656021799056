import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	grid-area: controls;

	padding: 4px;

	background: white;

	box-shadow: 1px 0px 5px 0px #dee0e2;

	z-index: 1;
`;

export default Root;
