import { combineReducers } from "@reduxjs/toolkit";

import { dataAction, dataReducer, dataState } from "./data";
import { errorsAction, errorsReducer, errorsState } from "./errors";
import { filtersAction, filtersReducer, filtersState } from "./filters";

export type StateExecutors = {
	data: dataState;
	errors: errorsState;
	filters: filtersState;
};

export const executors = combineReducers<StateExecutors>({
	data: dataReducer,
	errors: errorsReducer,
	filters: filtersReducer,
});

export const executorsAction = {
	data: dataAction,
	errors: errorsAction,
	filters: filtersAction,
};

export default executors;
