/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import {
	NumberEntry,
	DataKind,
	EntryList,
} from "../../../types/settingEntries";

export interface BasePreOrderEntries<Data extends DataKind> {
	becomesHotIn: NumberEntry<Data>;
}

export type PreOrderEntries<Data extends DataKind> = BasePreOrderEntries<Data> &
	EntryList<Data, never, never>;
export type PreOrderMeta = PreOrderEntries<"meta">;
export type PreOrderState = PreOrderEntries<"value">;
export type PreOrderValue = PreOrderEntries<any>[keyof PreOrderEntries<any>];

export const preOrderMeta: PreOrderMeta = {
	becomesHotIn: {
		type: "number",
		unit: "minute",
	},
};

const initialState: PreOrderState = {
	becomesHotIn: 30,
};

type Reducer<P> = CaseReducer<PreOrderState, PayloadAction<P>>;

const setAll: Reducer<PreOrderState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const orders = createSlice({
	name: "preOrder",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default orders;
