/* eslint-disable import/prefer-default-export */

import React from "react";

interface Props {
	fill: string;
}

export const WalletIcon: React.FC<Props> = ({ fill = "black" }) => (
	<svg
		width="14"
		height="12"
		viewBox="0 0 14 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.0105 0C12.3098 0 13.6668 1.32306 13.6668 3.58787H10.8461V3.61098C9.53697 3.61098 8.47572 4.64566 8.47572 5.922C8.47572 7.19835 9.53697 8.23303 10.8461 8.23303H13.6668V8.44102C13.6668 10.6769 12.3098 12 10.0105 12H3.98979C1.69053 12 0.333496 10.6769 0.333496 8.44102V3.55898C0.333496 1.32306 1.69053 0 3.98979 0H10.0105ZM13.1691 4.58161C13.444 4.58161 13.6668 4.79889 13.6668 5.06692V6.75397C13.6636 7.0207 13.4426 7.23617 13.1691 7.23929H10.8994C10.2367 7.24798 9.65715 6.80558 9.50683 6.17622C9.43155 5.78553 9.53723 5.38238 9.79554 5.07481C10.0539 4.76725 10.4384 4.58672 10.8461 4.58161H13.1691ZM11.1661 5.36158H10.9468C10.8122 5.36004 10.6826 5.41109 10.5868 5.50336C10.4911 5.59562 10.4372 5.72142 10.4372 5.85267C10.4372 6.12804 10.6644 6.35216 10.9468 6.35532H11.1661C11.4475 6.35532 11.6757 6.13286 11.6757 5.85845C11.6757 5.58404 11.4475 5.36158 11.1661 5.36158ZM7.25498 2.59413H3.49201C3.21285 2.59411 2.98563 2.81306 2.98238 3.08522C2.98238 3.36058 3.2096 3.5847 3.49201 3.58787H7.25498C7.53644 3.58787 7.76461 3.36541 7.76461 3.091C7.76461 2.81658 7.53644 2.59413 7.25498 2.59413Z"
			fill={fill}
		/>
	</svg>
);
