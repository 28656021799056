import React from "react";
import { Row } from "uikit";
import styled from "styled-components";
import Circle from "../../../Circle";

const Title = styled.div<{ isSoftphoneReduce: boolean }>`
	font-family: Lato;
	font-size: ${({ isSoftphoneReduce }) =>
		isSoftphoneReduce ? "14px" : "12px"};
	font-weight: 500;
	line-height: ${({ isSoftphoneReduce }) =>
		isSoftphoneReduce ? "22px" : "16px"};
	letter-spacing: 0em;
	text-align: left;
`;

const ModalTitle = ({ title, isSoftphoneReduce }) => (
	<Row gaps={isSoftphoneReduce ? "4px" : "6px"} align="center">
		<Title isSoftphoneReduce={isSoftphoneReduce}>{title}</Title>
		<Circle color="rgb(76, 175, 80)" size={isSoftphoneReduce ? 8 : 6} />
	</Row>
);

export default ModalTitle;
