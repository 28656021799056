import { ReactNode } from "react";

import Language from "../../../../../../../../services/Language";

import number from "./number";
import settlement from "./settlement";
import street from "./street";
import building from "./building";
import entrance from "./entrance";
import sector from "./sector";

export enum ColumnId {
	Number = "number",
	Settlement = "settlement",
	Street = "street",
	Building = "building",
	Entrance = "entrance",
	Sector = "sector",
}

const pathTranslationsMain =
	"orderPageWidgets.orderDetails.points.table.columns.";

export const columns: Record<ColumnId, Column> = {
	[ColumnId.Number]: {
		render: number,
		label: "№",
		pathTranslation: `№`,
	},
	[ColumnId.Settlement]: {
		render: settlement,
		label: "City",
		pathTranslation: `${pathTranslationsMain}city`,
	},
	[ColumnId.Street]: {
		render: street,
		label: "Street",
		pathTranslation: `${pathTranslationsMain}street`,
	},
	[ColumnId.Building]: {
		render: building,
		label: "Home",
		pathTranslation: `${pathTranslationsMain}home`,
	},
	[ColumnId.Entrance]: {
		render: entrance,
		label: "Entrance",
		pathTranslation: `${pathTranslationsMain}entrance`,
	},
	[ColumnId.Sector]: {
		render: sector,
		label: "Sector",
		pathTranslation: `${pathTranslationsMain}sector`,
	},
};

export const defaultColumnIds = Object.values(ColumnId);

export declare namespace Column {
	interface Options {
		width?: number;

		language: Language;

		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	pathTranslation: string;
	label: string;
}

export default columns;
