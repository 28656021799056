import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import TaxiServiceCellContent from "../components/TaxiServiceCellContent";

function taxiService({ width, language, onResize }: Column.Options) {
	return base(
		ColumnId.TaxiService,
		(item) => <TaxiServiceCellContent item={item} language={language} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default taxiService;
