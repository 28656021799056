import { Dispatch } from "redux";
import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import set from "../../../actions/Map/polygons/set";

export default function index() {
	return async (dispatch: Dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const result = await createRPCQuery(() =>
			prpcow.theirsModel.polygon.getAll(),
		);

		const processedValue = result.items.map((polygon) => ({
			id: polygon.id,
			taxiServiceId: polygon.taxiService.id,
			name: polygon.name,
			position: polygon.position,
			vertices: polygon.vertices[0].map(({ lat, lng }) => [lat, lng]),
			status: polygon.status,
		}));

		return dispatch(set(processedValue));
	};
}
