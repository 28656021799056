import React, { Dispatch, RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Row, Stepper, react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import InternalController from "./Controller";

const CheckConnectDataBase = react.withController<
	CheckConnectData.PropsBase,
	CheckConnectData.Controller
>(({ value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const isEnquireLinkValue = valueEditor.useGetter("isEnquireLink");
	const isEnquireLinkOnChange = valueEditor.useSetter("isEnquireLink");

	const enquireLinkValue = valueEditor.useGetter("enquireLink");
	const enquireLinkOnChange = valueEditor.useSetter("enquireLink");

	const { t } = useTranslation();
	return (
		<Row gaps="10px*" align="center">
			<CheckBox
				label={`${
					t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.checkConnectData.str200",
					) ?? ""
				} (enquire link)`}
				value={isEnquireLinkValue}
				onChange={isEnquireLinkOnChange}
			/>
			<Stepper
				value={enquireLinkValue}
				onChange={(newValue) => {
					if (newValue != null) enquireLinkOnChange(newValue);
				}}
				min={0}
			/>
			<span>
				{t(
					"pages.settings.pages.message.provider.editModal.content.mainTab.checkConnectData.str100",
				) ?? ""}
			</span>
		</Row>
	);
}, InternalController);

const CheckConnectData = memo(CheckConnectDataBase);

declare namespace CheckConnectData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		isEnquireLink: boolean;
		enquireLink: number;
	}

	interface PropsBase {
		value: Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CheckConnectData;

/* <RadioButton.RadioButton
					onClick={() => {
						setRadioValue(0);
						isEnquireLinkOnChange(false);
					}}
					selected={!radioValue}
					label={t("pages.settings.pages.message.provider.editModal.content.mainTab.checkConnectData.str201") ?? ""}
				/>
				<Row gaps="10px*3" align="center">
					<RadioButton.RadioButton
						label={t("pages.settings.pages.message.provider.editModal.content.mainTab.checkConnectData.str201") ?? ""}
						onClick={() => {
							setRadioValue(1);
							isEnquireLinkOnChange(true);
						}}
						selected={!!radioValue}
					/>
					<Stepper
						value={enquireLinkValue}
						onChange={(newValue) => {
							if (newValue != null) enquireLinkOnChange(newValue);
						}}
						min={10}
					/>
					<span>{t("pages.settings.pages.message.provider.editModal.content.mainTab.checkConnectData.str101") ?? ""}</span>
				</Row> */
