import React, {
	Dispatch,
	RefAttributes,
	memo,
	useMemo,
	useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, RadioButton, react } from "uikit";

import CarClass from "../../../../../../../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledGrid,
	StyledGridItem,
	StyledP,
} from "../../../../../../../../../../../../components/common";
import CheckBoxSelect from "../../../../../../../../../../../../components/CheckBoxSelect";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";

import InternalController from "./Controller";

const SettingsDataBase = react.withController<
	SettingsData.PropsBase,
	SettingsData.Controller
>(({ controller, value, onChange, classModels }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const valueEditor = useObjectEditor(value, onChange);

	const carClassId = valueEditor.useGetter("id");

	const distributableValue = valueEditor.useGetter("distributable");
	const distributableOnChange = valueEditor.useSetter("distributable");

	const notDistributableValue = useMemo(
		() => !distributableValue,
		[distributableValue],
	);

	const notDistributableOnChange = useCallback(
		(value: boolean) => distributableOnChange(!value),
		[distributableOnChange],
	);

	const broadcastableValue = valueEditor.useGetter("broadcastable");
	const broadcastableOnChange = valueEditor.useSetter("broadcastable");

	const notBroadcastableValue = useMemo(
		() => !broadcastableValue,
		[broadcastableValue],
	);
	const notBroadcastableOnChange = useCallback(
		(value: boolean) => broadcastableOnChange(!value),
		[broadcastableOnChange],
	);

	const distributionCompatibleModeValue = valueEditor.useGetter(
		"distributionCompatibleMode",
	);
	const distributionCompatibleModeOnChange = valueEditor.useSetter(
		"distributionCompatibleMode",
	);

	const broadcastingCompatibleModeValue = valueEditor.useGetter(
		"broadcastingCompatibleMode",
	);
	const broadcastingCompatibleModeOnChange = valueEditor.useSetter(
		"broadcastingCompatibleMode",
	);

	const compatibleCarClassIds = valueEditor.useGetter(
		"compatibleCarClassIds",
	);
	const compatibleCarClassIdsOnChange = valueEditor.useSetter(
		"compatibleCarClassIds",
	);

	const compatibleCarClassIdsToBroadcastable = valueEditor.useGetter(
		"compatibleCarClassIdsToBroadcastable",
	);
	const compatibleCarClassIdsToBroadcastableOnChange = valueEditor.useSetter(
		"compatibleCarClassIdsToBroadcastable",
	);

	const classModelsOptions = useMemo(
		() =>
			classModels
				.filter((item) => item?.id !== carClassId)
				.map((carClass) => ({
					key: carClass.id,
					label: carClass.name?.[lang],
				})),
		[classModels, lang, carClassId],
	);

	controller.setContext({});
	return (
		<StyledGrid
			areas="'compatibility' 'car_class'"
			rows="repeat(3,auto)"
			gap="20px"
		>
			<StyledGridItem
				area="compatibility"
				areas="'text text' 'distribution broadcasting'"
				columns="repeat(2,1fr)"
				rows="auto 1fr"
				w="100%"
				justify="flex-end"
				gap="10px"
			>
				<StyledGridItem
					area="text"
					areas="'title' 'checkbox'"
					gap="10px"
					columns="1fr"
				>
					<StyledGridItem area="title">
						<StyledP font={{ line: "1rem", fw: "600" }} p="10px 0">
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str150",
							) ?? ""}
						</StyledP>
					</StyledGridItem>

					<StyledGridItem
						area="checkbox"
						columns="repeat(2,1fr)"
						alignItems="center"
						gap="20px"
					>
						<CheckBoxWithContent
							value={notDistributableValue}
							onChange={notDistributableOnChange}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str151",
							) ?? ""}
						</CheckBoxWithContent>
						<CheckBoxWithContent
							value={notBroadcastableValue}
							onChange={notBroadcastableOnChange}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str152",
							) ?? ""}
						</CheckBoxWithContent>
					</StyledGridItem>
				</StyledGridItem>
				<StyledGridItem area="distribution">
					<FieldsContainer
						label={
							t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str0",
							) ?? ""
						}
					>
						<StyledColumn gap="5px">
							<RadioButton.RadioButton
								disabled={notDistributableValue}
								selected={
									distributionCompatibleModeValue ===
									CarClass.CompatibleMode.WITH_ITSELF
								}
								label={
									t(
										"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str1",
									) ?? ""
								}
								onClick={() =>
									distributionCompatibleModeOnChange(
										CarClass.CompatibleMode.WITH_ITSELF,
									)
								}
							/>
							<RadioButton.RadioButton
								disabled={notDistributableValue}
								selected={
									distributionCompatibleModeValue ===
									CarClass.CompatibleMode.AUTO
								}
								label={
									t(
										"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str2",
									) ?? ""
								}
								onClick={() =>
									distributionCompatibleModeOnChange(
										CarClass.CompatibleMode.AUTO,
									)
								}
							/>
							<RadioButton.RadioButton
								disabled={notDistributableValue}
								selected={
									distributionCompatibleModeValue ===
									CarClass.CompatibleMode.WITH_ALL
								}
								label={
									t(
										"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str3",
									) ?? ""
								}
								onClick={() =>
									distributionCompatibleModeOnChange(
										CarClass.CompatibleMode.WITH_ALL,
									)
								}
							/>
						</StyledColumn>
					</FieldsContainer>
				</StyledGridItem>
				<StyledGridItem area="broadcasting">
					<FieldsContainer
						label={
							t(
								"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str4",
							) ?? ""
						}
					>
						<StyledColumn gap="5px">
							<RadioButton.RadioButton
								disabled={notBroadcastableValue}
								selected={
									broadcastingCompatibleModeValue ===
									CarClass.CompatibleMode.WITH_ITSELF
								}
								label={
									t(
										"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str1",
									) ?? ""
								}
								onClick={() =>
									broadcastingCompatibleModeOnChange(
										CarClass.CompatibleMode.WITH_ITSELF,
									)
								}
							/>
							<RadioButton.RadioButton
								disabled={notBroadcastableValue}
								selected={
									broadcastingCompatibleModeValue ===
									CarClass.CompatibleMode.AUTO
								}
								label={
									t(
										"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str2",
									) ?? ""
								}
								onClick={() =>
									broadcastingCompatibleModeOnChange(
										CarClass.CompatibleMode.AUTO,
									)
								}
							/>
							<RadioButton.RadioButton
								disabled={notBroadcastableValue}
								selected={
									broadcastingCompatibleModeValue ===
									CarClass.CompatibleMode.WITH_ALL
								}
								label={
									t(
										"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str3",
									) ?? ""
								}
								onClick={() =>
									broadcastingCompatibleModeOnChange(
										CarClass.CompatibleMode.WITH_ALL,
									)
								}
							/>
						</StyledColumn>
					</FieldsContainer>
				</StyledGridItem>
			</StyledGridItem>

			<StyledGridItem area="car_class" columns="repeat(2,1fr)" gap="10px">
				<CheckBoxSelect
					disabled={
						notDistributableValue ||
						distributionCompatibleModeValue !==
							CarClass.CompatibleMode.AUTO
					}
					title={
						t([
							"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str200",
						]) || ""
					}
					value={compatibleCarClassIds}
					options={classModelsOptions}
					verticalScroll={true}
					onChange={compatibleCarClassIdsOnChange}
					h={{ max: "200px" }}
					p="2px"
					rows={{ auto: "21px" }}
				/>
				<CheckBoxSelect
					disabled={
						notBroadcastableValue ||
						broadcastingCompatibleModeValue !==
							CarClass.CompatibleMode.AUTO
					}
					title={
						t([
							"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str201",
						]) || ""
					}
					value={compatibleCarClassIdsToBroadcastable}
					options={classModelsOptions}
					verticalScroll={true}
					onChange={compatibleCarClassIdsToBroadcastableOnChange}
					h={{ max: "200px" }}
					p="2px"
					rows={{ auto: "21px" }}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
}, InternalController);

const SettingsData = memo(SettingsDataBase);

declare namespace SettingsData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value extends Record<string, any> {
		id?: CarClass.Model["id"];
		distributable: CarClass.Model["distributable"];
		distributionCompatibleMode: CarClass.Model["distributionCompatibleMode"];
		broadcastable: CarClass.Model["broadcastable"];
		broadcastingCompatibleMode: CarClass.Model["broadcastingCompatibleMode"];
		compatibleCarClassIds: CarClass.Model["compatibleCarClassIds"];
	}

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		classModels: CarClass.Model[];
		disabled: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default SettingsData;
