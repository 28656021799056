import { useRef } from "react";

function useRefWithUpdater<T>(value: T) {
	const ref = useRef(value);

	ref.current = value;

	return ref;
}

export default useRefWithUpdater;
