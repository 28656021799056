import React, { memo, useMemo } from "react";
import { Column } from "uikit";
import styled from "styled-components";

import MomentFnc from "../../../../../../../../../utils/MomentFnc";
import { ChatMessage as ChatMessageServices } from "../../../../../../../../../services";

import { Title, Message } from "./components";

const Root = styled(Column)``;

const GroupMessage = styled.div`
	border-radius: 8px 8px 8px 4px;
	overflow: hidden;
`;

const Messages: React.FC<Messages.Props> = ({ data, onClickFindParent }) => {
	const { isCurrentUser, createdAt, title, content, type, parent } = data;

	const momentFnc = useMemo(() => new MomentFnc(), []);
	const getTimeCreated = momentFnc.toClockFaceTime(createdAt);

	const reference = parent ?? null;

	const listContent = [reference, content].filter(Boolean);
	return (
		<Root gaps="6px">
			<Title
				data={{
					isCurrentUser,
					title,
					time: getTimeCreated,
				}}
			/>
			<GroupMessage>
				{listContent?.map((message, i) => (
					<Message
						key={i}
						data={{
							message: message || "",
							type: type || "",
							isCurrentUser,
						}}
						onClickFindParent={onClickFindParent}
					/>
				))}
			</GroupMessage>
		</Root>
	);
};

declare namespace Messages {
	interface Props {
		data: {
			isCurrentUser: boolean;
			createdAt: string;
			type?: string;
			title?: string;
			content?: string;
			parent?: ChatMessageServices.OutputGrouped.ChatMessage;
		};
		onClickFindParent?: (parentId: number) => void;
	}
}

export default memo(Messages);
