/* eslint-disable no-shadow */

import { difference, isEqual, uniq } from "lodash";
import { useEffect, useMemo, useRef } from "react";

import Card from "../services/Card";

interface Service {
	open: Card.Open;
	close: Card.Close;
}

function useKeepCardsOpen(ids: number[], Service: Service) {
	const uniqIds = useMemo(() => uniq(ids), [ids]);

	const prevIdsRef = useRef<number[]>([]);

	useEffect(() => {
		if (isEqual(uniqIds, prevIdsRef.current)) return;

		const prevIds = prevIdsRef.current;

		prevIdsRef.current = uniqIds;

		const idsForClose = difference(prevIds, uniqIds);
		const idsForOpen = difference(uniqIds, prevIds);

		if (idsForClose.length) Service.close(idsForClose);

		idsForOpen.forEach((id) => Service.open(id));
	}, [Service, ids, uniqIds]);

	useEffect(
		() => () => {
			if (prevIdsRef.current.length)
				Service.close(prevIdsRef.current).catch((error) =>
					console.error("Error catched when close card:", error),
				);
		},
		[Service],
	);
}

export default useKeepCardsOpen;
