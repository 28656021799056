import React, {
	ReactElement,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { CheckBox, Column, Table } from "uikit";
import { clone, noop } from "lodash";
import { SortType } from "rsuite-table";
import { UseTranslationResponse, useTranslation } from "react-i18next";

import Language from "../../../../../../../../../../../../services/Language";
import Company from "../../../../../../../../../../../../services/Company";
import TaxiService from "../../../../../../../../../../../../services/TaxiService";
import Executor from "../../../../../../../../../../../../services/Executor";
import useModelSubscribe from "../../../../../../../../../../../../hooks/useModelSubscribe2";
import useModelSubscribeOld from "../../../../../../../../../../../../hooks/useModelSubscribe";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import {
	getColumnsModeTwo,
	mapByKey,
} from "../../../../../../../../../../../../utils";
import TableSettings from "../../../../../../../../../../../../components/TableSettings";
import LightTable from "../../../../../../../../../../../../components/LightTable";

interface ColumnContext {
	language: Language;
	companyById: Record<number, Company.Model>;
	taxiServiceById: Record<number, TaxiService.Model>;
	t: UseTranslationResponse<"translation", undefined>[0];
}

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	fullName: ({ t }) => (
		<LightTable.Column flexGrow={1} sortable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.executors.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="fullName">
				{(item) =>
					`${item.person.lastName ?? ""} ${
						item.person.firstName ?? ""
					} ${item.person.fatherName ?? ""}`.trim()
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	roles: ({ t }) => (
		<LightTable.Column width={140} sortable>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.executors.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="roles">
				{(rowData) =>
					rowData.roles?.map((role) => role.name).join(", ") ?? ""
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	alias: ({ t }) => (
		<LightTable.Column width={200} sortable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.executors.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="alias">
				{(item) => item.alias}
			</LightTable.Cell>
		</LightTable.Column>
	),

	access: ({ language, companyById, taxiServiceById, t }) => (
		<LightTable.Column flexGrow={1} sortable>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.executors.str152",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="taxiService">
				{(item) => {
					const { taxiService } = item;
					const companyId =
						taxiServiceById[taxiService.id]?.company?.id;

					const companyName = companyId
						? companyById[companyId]?.name[language] ?? ""
						: "";
					const taxiServiceName =
						taxiServiceById[taxiService?.id]?.settlement[
							language
						] ?? "";

					return (
						<div
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
							}}
						>
							{companyName && taxiServiceName
								? `${companyName}, ${taxiServiceName}`
								: ""}
						</div>
					);
				}}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const Executors = () => {
	const [t] = useTranslation();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => ["fullName", "roles", "alias", "access"],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const tableRef = useRef<Table.Ref | null>(null);
	const canLoadMoreRef = useRef(true);

	const [sort, setSort] = useState<{
		column?: string;
		type?: SortType;
	}>({});

	const disabled = useMemo(() => false, []);

	const language = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const modelSubscriptionOptions = useMemo<Executor.SubscribeOptions>(() => {
		const result: Executor.SubscribeOptions = {
			query: "",
			language,
		};

		if (sort.column && sort.type)
			result.order = {
				[sort.column as keyof Executor.SubscribeOptions["order"]]:
					sort.type,
			};

		return result;
	}, [language, sort.column, sort.type]);

	const modelData = useModelSubscribe(modelSubscriptionOptions, Executor);

	const modelItems = useMemo(
		() => modelData.models ?? [],
		[modelData.models],
	);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const [selected, onChangeSelected] = useState<number[]>([]);

	const tableRowClassName = useCallback((item) => {
		if (!item?.active) return "not-active";

		return "";
	}, []);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = modelItems.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				noop();

				canLoadMoreRef.current = false;
			}
		};
	}, [modelItems.length]);

	const companyData = useModelSubscribeOld({}, Company);

	const companies = useMemo(
		() => companyData?.cache ?? [],
		[companyData?.cache],
	);

	const companyById = useMemo(() => mapByKey(companies, "id"), [companies]);

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const columnContext = useMemo<ColumnContext>(
		() => ({ language, companyById, taxiServiceById, t }),
		[companyById, language, t, taxiServiceById],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<div>
				<LightTable
					ref={tableRef}
					fillHeight
					virtualized
					shouldUpdateScroll={false}
					headerHeight={44}
					rowHeight={44}
					sortColumn={sort.column}
					sortType={sort.type}
					// loading={loading}
					data={modelItems}
					rowClassName={tableRowClassName}
					onScroll={tableOnScroll}
					onSortColumn={(column, type) => setSort({ column, type })}
				>
					<LightTable.Column width={36}>
						<LightTable.HeaderCell verticalAlign="middle">
							<CheckBox
								value={
									!!modelItems.length &&
									modelItems.every((item) =>
										selected.includes(item.id),
									)
								}
								disabled={disabled}
								onChange={(value) =>
									onChangeSelected(
										value
											? selected.concat(
													modelItems
														.filter(
															(item) =>
																!selected.includes(
																	item.id,
																),
														)
														.map((item) => item.id),
											  )
											: selected.filter(
													(id) =>
														!modelItems.find(
															(item) =>
																item.id === id,
														),
											  ),
									)
								}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(item) => (
								<CheckBox
									value={selected.includes(item.id)}
									disabled={disabled}
									onChange={(selected) =>
										setItemSelection(item.id, selected)
									}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>

					{columnIds.map((columnId) =>
						Columns[columnId](columnContext),
					)}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

export default Executors;
