import React, { useCallback, useEffect } from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
	StyledSpan,
	ValueStepper,
} from "../../../../../../../../components/common";
import { useCurrencyGlobalSettings } from "../../../../../../../../hooks";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
} from "../../../../constants/constants";
import tPath from "../../../../constants/tPath";

import BinaryAdjustmentSelect from "../../../BinaryAdjustmentSelect";
import SurchargeSelect from "../../../SurchareSelect";

const Item: React.FC<Item.Props> = ({ title, disabled, value, onChange }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const valueEditor = useObjectEditor(value, onChange);

	const minOrderValue = valueEditor.useGetter("value");
	const setMinOrderValue = valueEditor.useSetter("value");

	const adjustments = valueEditor.useGetter("adjustments");
	const setAdjustments = valueEditor.useSetter("adjustments");
	const adjustmentsEditor = useObjectEditor(adjustments, setAdjustments);
	const adjustmentsValue = adjustmentsEditor.useGetter("value");
	const setAdjustmentsValue = adjustmentsEditor.useSetter("value");
	const adjustmentsInitialKm = adjustmentsEditor.useGetter("initialKm");
	const setAdjustmentsInitialKm = adjustmentsEditor.useSetter("initialKm");

	const initialKm = valueEditor.useGetter("initialKm");
	const setInitialKm = valueEditor.useSetter("initialKm");

	const strict = valueEditor.useGetter("strict");
	const setStrict = valueEditor.useSetter("strict");
	const handleStrictChange = useCallback(
		(newStrict: boolean) => {
			setStrict(newStrict);
		},
		[setStrict],
	);

	useEffect(() => {
		if (
			adjustmentsValue === RateAdjustmentToDefault.CUSTOM_VALUE &&
			minOrderValue < 0
		) {
			setMinOrderValue(0);
		}
	}, [minOrderValue, adjustmentsValue, setMinOrderValue]);

	return (
		<StyledColumn gap="8px">
			<StyledRow gap="10px" alignItems="center" justify="space-between">
				<StyledSpan text={{ ws: "normal" }}>{title}</StyledSpan>
				<StyledRow gap="10px" alignItems="center">
					<SurchargeSelect
						disabled={disabled}
						value={adjustmentsValue}
						onChange={setAdjustmentsValue}
					/>
					<StyledRow gap="10px" alignItems="center" w="125px">
						<ValueStepper
							min={
								adjustmentsValue ===
								RateAdjustmentToDefault.CUSTOM_VALUE
									? 0
									: undefined
							}
							value={minOrderValue}
							onChange={setMinOrderValue}
						/>
						{adjustmentsValue !==
							RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE &&
							currencyGlobalSettings}
					</StyledRow>
					<StyledSpan text={{ ws: "normal" }}>
						{t(
							`${tPath}.mainModal.tabs.main.minimumOrderValue.include`,
						)}
					</StyledSpan>
					<BinaryAdjustmentSelect
						disabled={disabled}
						value={adjustmentsInitialKm}
						onChange={setAdjustmentsInitialKm}
					/>
					<ValueStepper
						disabled={
							adjustmentsInitialKm ===
							RateBinaryAdjustmentToDefault.DEFAULT_VALUE
						}
						min={0}
						decimalCount={3}
						value={initialKm}
						onChange={setInitialKm}
					/>
					<StyledSpan text={{ ws: "normal" }}>
						{t(
							`${tPath}.mainModal.tabs.main.minimumOrderValue.distance`,
						)}
					</StyledSpan>
				</StyledRow>
			</StyledRow>
			<CheckBox
				label={
					t(
						`${tPath}.mainModal.tabs.main.minimumOrderValue.strict`,
					) || ""
				}
				value={strict}
				onChange={handleStrictChange}
			/>
		</StyledColumn>
	);
};

declare namespace Item {
	interface Props {
		title: string;
		disabled?: boolean;
		value: Value;
		onChange: (x: Value) => void;
	}

	interface Value {
		value: number;
		initialKm: number;
		strict: boolean;
		adjustments: {
			value: RateAdjustmentToDefault;
			initialKm: RateBinaryAdjustmentToDefault;
		};
	}
}

export default Item;
