import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";
import { SideTab } from "../../../../components/common";

import Customer from "./Customer";
import Executor from "./Executor";
import { TabKeys, TabAccessNames } from "./constants/access";

const PaymentSystemSettings: React.FC = () => {
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.CUSTOMER,
					label: t("settings.paymentSystems.customer.title"),
					value: {
						render: () => (
							<Customer
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.CUSTOMER],
				},
				{
					key: TabKeys.EXECUTOR,
					label: t("settings.paymentSystems.executor.title"),
					value: {
						render: () => (
							<Executor
								methods={() => ""}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);
	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default PaymentSystemSettings;
