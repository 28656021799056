/* eslint-disable prettier/prettier */
import React, { memo, useCallback } from "react";
import { Row } from "uikit";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../redux/store";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import { modifyInternalFilter } from "../../../../../../../../../../redux/reducers/Archives/Calls";

const BranchesFilter: React.FC<BranchesFilter.Props> = memo(() => {
	const dispatch = useTypedDispatch();
	const lang = useTypedSelector((state) => state.session.language);
	const companies = useTypedSelector(
		(s) => s.archiveCalls.internalFilter.companyFilter,
	);
	const branches = useTypedSelector(
		(s) => s.archiveCalls.internalFilter.taxiServiceFilter,
	);

	const handleCompaniesChange = useCallback(
		(companyFilter) => {
			dispatch(modifyInternalFilter({ companyFilter }));
		},
		[dispatch],
	);

	const handleBranchesChange = useCallback(
		(taxiServiceFilter) => {
			dispatch(modifyInternalFilter({ taxiServiceFilter }));
		},
		[dispatch],
	);

	return (
		<Row style={{ width: "360px" }} gaps="5px*" align="center" sizes="1fr*">
			<CompaniesAndTaxiServicesFilter
				companies={companies}
				taxiServices={branches}
				language={lang}
				onChangeCompanies={handleCompaniesChange}
				onChangeTaxiServices={handleBranchesChange}
			/>
		</Row>
	);
});

declare namespace BranchesFilter {
	interface Props {}
}

export default BranchesFilter;
