import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import CreateOrderTimeCellContent from "../components/CreateOrderTimeCellContent";

function createOrderTime({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CreateOrderTime,
		(item) => <CreateOrderTimeCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default createOrderTime;
