import { Schema } from "../redux/constants/OrdersPage/order";

import { validatePhone } from "./validatePhone";

export function initValidator(schema: Schema) {
	const validator = {};

	Object.keys(schema).forEach((key) => {
		validator[key] = true;
	});

	return validator as Record<keyof Schema, boolean>;
}

export interface ShouldValidateOrderForm {
	points?: number;
	phone?: boolean;
}

export function validateOrderForm(
	form: Schema,
	options: ShouldValidateOrderForm = {},
) {
	const should = {
		points: 1,
		phone: true,
		...options,
	};

	const phones = form.additionalPhones || [];
	const points = form.points || [];
	const normalacyArr = [...points].filter((item) => {
		if (!item) return false;
		return true;
	});
	const isValidPhones = phones.length
		? [...phones]
				.filter((item) => {
					if (!item) return false;
					return true;
				})
				?.map((item) => validatePhone(item))
				?.every((item) => item === true)
		: true;

	const isValid = form?.phone ? validatePhone(form.phone) : false;
	const isPointsValid = Boolean(normalacyArr?.length >= should.points);

	return {
		...initValidator(form),
		phone: should.phone ? isValid : true,
		points: isPointsValid,
		additionalPhones: should.phone ? isValidPhones : true,
	};
}
