import React from "react";
import Root from "./components/Root";
import Item from "./components/Item";

type Key = string | number;

function List<OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
}: List.Props<OptionValue, ValueType>) {
	return (
		<Root align="stretch">
			{options.map((option) => (
				<Item
					key={option.key}
					value={value.includes(option.key as ValueType)}
					label={option.label}
					disabled={option.disabled}
					onChange={(itemValue) => {
						let newValue: List.Value<ValueType>;

						if (itemValue)
							newValue = [...value, option.key as ValueType];
						else
							newValue = value.filter(
								(key) => key !== option.key,
							);

						onChange(newValue);
					}}
				/>
			))}
		</Root>
	);
}

declare namespace List {
	type Value<Type extends Key> = Type[];

	interface Option<Value> {
		key: Key;
		label: string;
		disabled?: boolean;
		value: Value;
	}

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;

		options: Option<OptionValue>[];

		onChange: (value: Value<ValueType>) => void;
	}
}

export default List;
