import { NoticeDestinationApp } from "../../../../../../services/PushNotice";
import {
	MessageTemplateActions,
	MessageTemplateTypes,
} from "../../../../../../types/MessageTemplateEnums";
import Modal from "../components/Modal";

const defaultTemplate: Modal.Value = {
	name: "",
	title: "",
	templateText: "",
	taxiServiceIds: [],
	taxiServices: [],
	action: MessageTemplateActions.UNKNOWN_ACTION,
	lang: "uk",
	destinationApp: NoticeDestinationApp.CUSTOMER,
	active: true,
	isValidityPeriod: true,
	validityPeriod: 30,
	isDefault: false,
	type: MessageTemplateTypes.CAR_EXECUTOR_ORDER_CUSTOMER,
	bigPicture: null,
	largeIcon: null,
	orderTemplate: false,
};

export default defaultTemplate;
