/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { defaults } from "lodash";
import * as ModelEvent from "@node-elion/syncron";
import { LatLngLiteral } from "leaflet";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Subscription from "../../types/Subscription";
import Passanger from "../../types/Passanger";
import OrderStatus from "../../types/OrderStatus";
import OrderExecutingStage from "../../types/OrderExecutingStage";
import OrderSource from "../../types/OrderSource";
import { MessageTemplateActions } from "../../types/MessageTemplateEnums";
import { ModelId } from "../../types/ModelId";
import {
	ExecuteOrderStage,
	IOrderPoint,
	OrderPaymentStatus,
	getOrderPassengerPoints,
} from "../../redux/constants/OrdersPage/order";
import createRPCQuery from "../../utils/createRPCQuery.util";
import { getPRPC } from "../../hooks/usePRPC";
import Base from "../Base";
import Currency from "../Currency";
import { decompressRoute } from "../../utils/decompressRoute.util";
import Customer from "../../types/Customer";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import Agent from "../Agent";
import {
	OrderCostRequest,
	OrderCostResponse,
} from "../../pages/MainPage/pages/Orders/components/OrderModal/hooks/useCalcOrderCost";

export enum CalculationErrorType {
	THRESHOLD_ERROR_YES = "THRESHOLD_ERROR_YES",
	THRESHOLD_ERROR_NO = "THRESHOLD_ERROR_NO",
}

export interface OrderForm {
	orderId: number;
	/** this orderFormTab.form as `JSON.stringify` */
	form: string | null;
}

export enum OrderReminderStatus {
	/** green */
	START = "start",
	/** orange */
	SEND = "send",
	/** green */
	SUCCESS = "success",
	/** red */
	FAILED = "failed",
}

/**
 * Order type determines the way the order will be processed.
 */
export enum OrderType {
	/*
	 * The default order type means that the passenger will pay for the order themselves.
	 */
	DEFAULT = "default",

	/**
	 * The wizard order type means that everything in the order can be changed without any restrictions
	 */
	WIZARD = "wizard",

	/**
	 * The sponsored order type means that the order will be paid by another person (customer).
	 */
	CUSTOMER_SPONSORED = "customer_sponsored",
}

class Order extends Base {
	static defaultSharedOptions: Order.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): Order.Model | undefined {
		try {
			return {
				...data,

				id: data.id,
				paymentType: data.paymentType || "card",
				v: data.v,
				index: data.index,
				type: data.type,
				status: data.status,
				source: data.source,
				orderNumber: data.orderNumber,

				cost: data.cost,

				taxiService: data.taxiService,

				rate: { id: data.rate?.id },
				isPreliminary: data.isPreliminary,
				executorToOrder: data.executorToOrder,
				executingStages: data.executingStages,
				executingStage: data.executingStage,
				executingStageUpdatedAt: data.executingStageUpdatedAt,
				orderNote: data.orderNote,
				closedOrderComments: data.closedOrderComments,
				orderToCarClasses: data.orderToCarClasses,
				orderToServices: data.orderToServices,
				phones: data.phones,
				offer: data.offer,

				route: data.route && decompressRoute(data.route),
				sectors: data.sectors,
				points: getOrderPassengerPoints(data),
				passengers: data.passengers,
				position: data.position,

				additionalFields: data.additionalFields,

				orderDate: data.orderDate,

				createdAt: data.createdAt,
				updatedAt: data.updatedAt,
				closedAt: data.closedAt,
				smsMessages: data.smsMessages,
				paymentStatus: data.paymentStatus || null,
			};
		} catch {
			return undefined;
		}
	}

	static toRequest(model: Order.Model.New): any {
		return {
			v: model.v,
			index: model.index,
			type: model.type,
			status: model.status,
			orderNumber: model.orderNumber,

			taxiService: model.taxiService,

			executorToOrder: model.executorToOrder,

			orderNote: model.orderNote,

			offer: model.offer,
			route: model.route,
			points: model.points,
			passengers: model.passengers,
			position: model.position,

			additionalFields: model.additionalFields,

			orderDate: model.orderDate,
			closedAt: model.closedAt,
		};
	}

	static async store(object: Order.Model.New, options?: Order.StoreOptions) {
		options = defaults(options, Order.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.orders.create(Order.toRequest(object)),
		);

		// if (options.deprecate) Order.deprecateAll();
	}

	static async update(
		{ id, ...params }: Order.Model.Modified,
		options?: {
			refreshCost?: boolean;
			force?: boolean;
			dispatcherModal?: boolean;
		},
	): Promise<Order.Model | null> {
		try {
			const prpc = getPRPC();
			if (!prpc) return null;

			const paramsResolver = this.JSONLike(params);

			console.log("[Order.update] request ", {
				id,
				params,
				paramsResolver,
			});
			const data: Order.Model = await createRPCQuery(
				// TODO: add  `Order.toRequest`
				() =>
					prpc.theirsModel.orders.update(
						id,
						paramsResolver,
						options || {},
					),
				{ verbose: true, name: "Order.modified" },
			);

			return data;
		} catch (error) {
			console.log("[Order.update] Error", { id, params, error });
			return null;
		}
	}

	static async getById(id?: number): Promise<Order.Model | null> {
		const prpc = getPRPC();

		if (!prpc) return null;
		if (!id) return null;

		const res: Order.Model = await createRPCQuery(() =>
			prpc.theirsModel.orders.getById(id),
		);

		return res;
	}

	static async copy(id: number, options: Order.CopyOptions) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.orders.copy(id, {
				copies: options.amount,
				options: {
					points:
						// eslint-disable-next-line no-nested-ternary
						options.type === "all-points"
							? -1
							: options.type === "without-points"
							? 0
							: options.pointsAmount,
				},
			}),
		);
	}

	static async close(id: number, options: Order.CloseOptions) {
		const prpc = getPRPC();

		if (!prpc) return null;
		try {
			const res = await this.request(
				(prpc) => prpc.theirsModel.orders.close(id, options),
				{
					silent: false,
					error: true,
				},
			);

			console.log("[Order] close", { id, options, res });

			return res;
		} catch (error) {
			console.log("[Order] error close", { id, options, error });
			return null;
		}
	}

	static async assign(id: number, options: Order.AssignOptions) {
		const prpc = getPRPC();

		if (!prpc) return undefined;

		return createRPCQuery(() =>
			prpc.theirsModel.orders.offer.assign(id, options),
		);
	}

	static async accept(id: number, options?: Order.AcceptOptions) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.orders.offer.accept(id, options),
		);
	}

	static async recalculate(id: number, options?: Order.RecalculateOptions) {
		const prpc = getPRPC();

		if (!prpc) return;
		try {
			await createRPCQuery(() =>
				prpc.theirsModel.orders.cost.recalculate(id, {
					useActualRates: options?.useActualRates,
				}),
			);
		} catch (error) {
			console.error(error);
		}
	}

	static async refuse(id: number, options?: Order.RefuseOptions) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() => prpc.theirsModel.orders.refuse(id, options));
	}

	static async startBroadcasting(id: number) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() => prpc.theirsModel.orders.broadcast.start(id));
	}

	static async stopBroadcasting(id: number) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() => prpc.theirsModel.orders.broadcast.stop(id));
	}

	static async updateCallStatus(
		id: number,
		options: Order.UpdateCallStatusOptions,
	) {
		const prpc = getPRPC();

		if (!prpc) return;
		try {
			await createRPCQuery(() =>
				prpc.theirsModel.orders.updateCallStatus(id, options),
			);
		} catch (error) {
			console.error(error);
		}
	}

	static async revert(id: number) {
		const prpc = getPRPC();

		if (!prpc) return;
		try {
			const res = await this.request(
				(prpc) => prpc.theirsModel.orders.revert({ id }),
				{
					silent: false,
					error: true,
				},
			);

			console.log("[Order] revert", { id, res });
		} catch (error) {
			console.error(error);
		}
	}

	static async calculate(
		data: OrderCostRequest,
		params: {
			overall?: boolean;
			dispatcherModal?: boolean;
		} = {
			overall: false,
			dispatcherModal: false,
		},
	): Promise<OrderCostResponse | null> {
		const prpc = getPRPC();

		if (!prpc) return null;
		try {
			const res = await this.request(
				(prpc) => prpc?.theirsModel.orders.cost.calculate(data, params),
				{
					silent: false,
					error: true,
				},
			);

			console.log("[Order] calculate", { data, res, params });
			return res;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	static async resolveError(params: {
		orderId: number;
		type: CalculationErrorType;
	}): Promise<Order.Model.OrderForm | null> {
		const prpc = getPRPC();

		if (!prpc) return null;
		try {
			const res = await createRPCQuery(() =>
				prpc.theirsModel.orders.cost.resolveError(params),
			);

			return res;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	// static async destroy(
	// 	id: number[] | number,
	// 	options?: Order.DestroyOptions,
	// ) {
	// 	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// 	options = defaults(options, Order.defaultSharedOptions);

	// 	const prpc = getPRPC();

	// 	if (!prpc) return;

	// 	if (Array.isArray(id))
	// 		await Promise.all(id.map((id) => destroyOne(id)));
	// 	else await destroyOne(id);

	// 	// if (options.deprecate) Order.deprecateAll();
	// }

	public static async subscribe(
		options: Order.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Order.Model>,
	): Promise<Subscription<Order.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				const models: Order.Model[] = [];
				state.models.forEach((item) => {
					const model = this.fromResponse(item);
					if (model) models.push(model);
				});
				console.log("[Orders] subscribe", {
					state,
					models,
					options: this.optionsToRequest(options),
				});
				onUpdate({ ...state, models });
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.orders.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{
				name: "Order.subscribe",
				metadata: this.optionsToRequest(options),
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Order.SubscribeOptions) =>
				subscription?.update(this.optionsToRequest(options)),
		} as Subscription<Order.SubscribeOptions>;
	}

	private static optionsToRequest(options: Order.SubscribeOptions) {
		return {
			// offset: options.offset,
			// limit: options.limit,
			// order: options.order,
			query: options.query || undefined,

			tab: options.tab,
			customerIds: options.customerIds,
			executorIds: options.executorIds,
			taxiServiceIds: options.taxiServiceIds,
			counterpartyIds: options.counterpartyIds,
			checkIds: options.checkIds,
			checkCardIds: options.checkCardIds,
			employeeIds: options.employeeIds,
			hasCounterparty: options.hasCounterparty,
			hasEmployee: options.hasEmployee,
			dateRange: options.dateRange,
			agentIds: options.agentIds,

			hasExecutor: options.hasExecutor,

			customerPhones: options.customerPhones,
			executorCallSigns: options.executorCallSigns,
			carCallSigns: options.carCallSigns,
			carRegistrationNumbers: options.carRegistrationNumbers,
			pickupAddress: options.pickupAddress,
			destinationAddress: options.destinationAddress,
			// lang: options.language,
		};
	}

	static Global = {
		async index(options: Order.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.orders.getAll({
					offset: options.offset,
					limit: options.limit,
					// order: options.order,
					query: options.query || undefined,

					tab: options.tab,
					customerIds: options.customerIds,
					taxiServiceIds: options.taxiServiceIds,
					// lang: options.language,
				}),
			);

			return {
				cache: result.items.map(Order.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}
export enum CustomerBonusUsageType {
	BonusTrip = "bonusTrip",
	BonusBalance = "bonusBalance",
}

export interface CustomerBonusUsage {
	/**
	 * Indicates whether the customer uses bonuses or not.
	 */
	active: boolean;

	/**
	 * Type of the bonus usage.
	 */
	type: CustomerBonusUsageType;

	/**
	 * The limits of the bonus usage.
	 */
	limit: {
		active: boolean;
		maxAmount: number;
	};
}

export interface CustomerDiscountUsage {
	/**
	 * Indicates whether the customer uses discounts or not.
	 */
	active: boolean;
}

export interface Discount {
	active: boolean;
	bonusUsage: CustomerBonusUsage;
	discountUsage: CustomerDiscountUsage;
}

namespace Order {
	export interface IAdditionalFields {
		/**
		 * The usage of the customer bonuses.
		 *
		 * If the customer want to use bonuses, then the `bonusUsage` field should be set.
		 * Contains the bonus usage settings like limits, type, amount, etc.
		 */
		discount?: Discount | null;

		/** @deprecated not used  */
		compensation?: {
			active: boolean;
			type: "percent" | "uah";
			whom: "customer" | "taxiService";
			value: number;
		};
		/** @deprecated not used  */
		surcharge?: {
			active: boolean;
			type: "percent" | "uah";
			value: number;
		};

		/** @deprecated not used  */
		rideBonus?: {
			active: boolean;
			number: number;
			value: number;
		};
		/** @deprecated not used  */
		phoneTopUp?: {
			active: boolean;
			value: number;
		};

		idleTimeMilliseconds: number;
		waitingTimeMilliseconds: number;

		displaySettings?: {
			color?: string | number;
			showCustomerPhone?: boolean;
		};
		transportationType: "passenger" | "cargo";
		additionalCost: number;
		arbitraryCost?: number;
		hourlyMilliseconds: number;
		suburbanDistanceOverride?: {
			value: number;
			shrink?: boolean;
		};
		customerNotes?: string;
		executorNotes?: string;
		passengersCount?: number;
		rateSettings?: {
			includePickupCost?: boolean;
			isRoundTrip?: boolean;
			enableHourlyService?: boolean;
			includePickupMargin?: boolean;
		};

		taximeter: boolean;
		processedByTaximeter: boolean;
	}

	export type OrderSMSMessageStatus =
		| "sent"
		| "progress"
		| "success"
		| "failure";

	export interface IOrderSMSMessage {
		action: MessageTemplateActions;
		status: OrderSMSMessageStatus;
		text: string;
		timestamp: number;
	}

	export interface Phone {
		id?: number;
		number: string;
		group: number;
	}

	export enum Source {
		Phone = "phone",
		App = "app",
		Website = "website",
		Executor = "executor",
		Dispatcher = "dispatcher",
	}

	export enum PaymentType {
		Card = "card",
		Cash = "cash",
		Invoice = "invoice",
		CompanyBill = "company_bill",
	}

	export type RawOrder = Order;

	export enum OrderCostCalculationStage {
		ARBITRARY_COST = "arbitrary_cost",
		PICKUP_NEAREST_COST = "pickup_nearest_cost",
		PICKUP_POINT_COST = "pickup_point_cost",
		PICKUP_ZONE_COST = "pickup_zone_cost",
		PICKUP_ZONE_REVERSE_COST = "pickup_zone_reverse_cost",
		PICKUP_SETTLEMENT_COST = "pickup_settlement_cost",
		BOARDING_COST = "boarding_cost",
		ADDITIONAL_COST = "additional_cost",
		FIXED_RATE = "fixed_rate",
		RATE_GRID_SUBURBAN_COST = "rate_grid_suburban_cost",
		RATE_GRID_SETTLEMENT_COST = "rate_grid_settlement_cost",
		HOURLY_RATE_WAITING = "hourly_rate_waiting",
		HOURLY_RATE_IDLE = "hourly_rate_idle",
		HOURLY_RATE_RIDE = "hourly_rate_ride",
		MIN_AFFORDABLE_ROUTE_COST = "min_affordable_route_cost",
		SETTLEMENT_ROUTE_COST = "settlement_route_cost",
		SUBURBAN_ROUTE_COST = "suburban_route_cost",
		GENERAL_MARGIN = "margin_general",
		ORDER_SUM_MARGIN = "margin_surcharge_to_order_sum",
		PRELIMINARY_MARGIN = "preliminary_margin",
		APP_ORDER_MARGIN = "online_order_margin",
		EXECUTOR_ORDER_MARGIN = "executor_order_margin",
		WEBSITE_ORDER_MARGIN = "website_order_margin",
		PICKUP_COST_MARGIN = "pickup_cost_margin",
		PASSENGER_MARGIN = "passengers_margin",
		SERVICE_MARGIN = "services_margin",
		ADDITIONAL_POINT_MARGIN = "additional_point_margin",
		CREDIT_CARD_PAYMENT_MARGIN = "credit_card_payment_margin",
		INVOICE_PAYMENT_MARGIN = "invoice_payment_margin",
		NON_CASH_PAYMENT_MARGIN = "non_cash_payment_margin",
	}

	export type BasicCostCalculationStage = {
		value: number;
		stage: OrderCostCalculationStage;
	};

	export type OrderCost = {
		raw: number;
		stages: BasicCostCalculationStage[];
		value: number;
		rounded: string;
		currency: Currency.Model;
		settings: any;
	};

	export interface Model {
		id: number;

		checkCard: Record<string, any> | null;
		counterparty: Record<string, any> | null;

		v: number;
		index: number;
		type: OrderType;
		status: OrderStatus;
		source: OrderSource;

		executingStage: OrderExecutingStage;
		executingStages?: Record<ExecuteOrderStage, any> | null;
		executingStageUpdatedAt: string;
		executorCallStatus?: any | null;
		executorReminderStatus?: any | null;
		/**
		 * ```ts
		 *
		 * acceptedByDispatcher: {
			active: !options.dispatcherId;
			dispatcherId: options.dispatcherId;
		};
		 * ```
		 */
		executorToOrder: any | null;

		externalPaymentTransactions?: any | null;

		customerCallStatus?: any;

		orderNumber: number;
		cost: OrderCost;
		paymentType: PaymentType;
		paymentStatus: {
			status: OrderPaymentStatus;
			timestamp: number;
		} | null;
		taxiService: any;
		sectors: {
			point: LatLngLiteral;
		};
		phones: Phone[];
		orderNote: string;
		isPreliminary: boolean;
		isCustomerDiscounted: boolean;
		isManualBroadcasting: boolean;
		closedOrderComments: any[];

		orderToCarClasses: any[];
		orderToServices: any[];

		offer?: any;
		route?: {
			distance: number;
			points: LatLngLiteral[];
			sectors: any[];
			segments: LatLngLiteral[][];
		};
		points: IOrderPoint[];
		passengers: Passanger[];
		position: string;

		billingCustomer?: Customer;

		additionalFields: Order.IAdditionalFields;

		rate: Record<string, any> & { id: number };

		orderDate: string;
		updatedAt: string;
		createdAt: string;
		closedAt: string;
		filled?: boolean;
		filledAt?: string | null;
		revertedAt?: string | null;
		expiredAt?: string | null;
		// deletedAt: string | null;
		smsMessages: IOrderSMSMessage[];

		agent?: Agent.Model;
		agentReward?: any;

		highlighting?: any;
	}

	export type Type =
		| "overall"
		| "all"
		| "live"
		| "executable"
		| "preliminary"
		| "closed"
		| "own"
		| "own_closed";

	type SortingKey =
		| "orderDate"
		| "orderTime"
		| "executorArrivalDate"
		| "executorArrivalTime"
		| "timeToDelivery"
		| "executorCallSign"
		| "pickupSettlement"
		| "pickupAddress"
		| "pickupSector"
		| "phone"
		| "customerCallStatus"
		| "executorCallStatus"
		| "orderNumber"
		| "executingStageWithClientsTime"
		| "customerNote"
		| "carClass"
		| "destinationSettlement"
		| "destinationAddress"
		| "destinationSector"
		| "paymentType"
		| "executorNote"
		| "dispatcherCallSign"
		| "additionalPhone"
		| "status"
		| "source"
		| "executingStage"
		| "closeReason"
		| "rideNumber"
		| "totalRideCount"
		| "createOrderDate"
		| "createOrderTime"
		| "customerFullName"
		| "executorLate"
		| "executingStageAcceptedTime"
		| "executingStageArrivedTime"
		| "executingStageWaitingCustomerTime"
		| "smsLateStatus"
		| "smsWillBeAtStatus"
		| "smsCarNotFoundStatus"
		| "smsAwaitingPassengersStatus"
		| "smsWithPassengersStatus"
		| "smsOrderCompletedStatus"
		| "smsExecutorSwitchedStatus"
		| "counterparty"
		| "counterpartyCheck"
		| "counterpartyCheckCard"
		| "company"
		| "taxiService"
		| "idleTime"
		| "rate"
		| "services"
		| "parameters"
		| "discount"
		| "transportationType";

	export interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<Order.Model>, "order"> {
		order?: Partial<Record<SortingKey, SortingOrder>>;

		tab?: Type;
		customerIds?: number[];
		executorIds?: number[];
		taxiServiceIds?: number[];
		counterpartyIds?: ModelId[];
		checkIds?: ModelId[];
		checkCardIds?: ModelId[];
		employeeIds?: ModelId[];
		hasCounterparty?: boolean;
		hasEmployee?: boolean;
		agentIds?: ModelId[];
		dateRange?: {
			from: Date;
			to: Date;
		};

		dispatcherIds?: number[];

		hasExecutor?: boolean;

		customerPhones?: string[];
		executorCallSigns?: string[];
		carCallSigns?: string[];
		carRegistrationNumbers?: string[];

		pickupAddress?: string;
		destinationAddress?: string;

		// language?: MapLanguage;
		// status?: OrderStatus;
	}

	export interface SharedOptions {
		deprecate?: boolean;
	}

	export interface StoreOptions extends SharedOptions {}
	export interface UpdateOptions extends SharedOptions {}
	export interface DestroyOptions extends SharedOptions {}

	export interface CopyOptions {
		amount: number;
		type?: "all-points" | "without-points" | "first-points";
		pointsAmount: number;
	}

	export interface CloseOptions {
		status?: string;
		subStatus?: string;
		comment?: string;
		update?: boolean;
		processedByTaximeter?: boolean;
		timeoutForTaximeter?: number;
	}

	export interface AssignOptions {
		executorId: number;
		arrivalTime?: number;
	}

	export interface AcceptOptions {
		arrivalTime?: number;
	}

	export interface RecalculateOptions {
		useActualRates?: boolean;
	}

	export interface RefuseOptions {
		reason: "accidentally" | "decline_by_client";
	}

	export interface UpdateCallStatusOptions {
		type: "executor" | "customer";
		status: "need_call" | "calling" | "called" | "none";
	}

	export namespace Model {
		export interface OrderForm {
			orderId: number;
			/** this orderFormTab.form as `JSON.stringify` */
			form: string | null;
		}

		export type New = Omit<
			Model,
			"id" | "createdAt" | "updatedAt" | "deletedAt"
		>;

		export interface Modified {
			id: Order.Model["id"];
			additionalFields?: Partial<Order.IAdditionalFields> | null;
			orderNote?: Order.Model["orderNote"];
			orderDate?: Date;
			isPreliminary?: boolean;
			taxiServiceId?: number;
			counterpartyId?: number;
			checkCardId?: number;
			rateId?: number;
			carClassIds?: number[];
			serviceIds?: number[];
			phones?: Phone[];
			passengers?: Order.Model["passengers"];
		}
	}
}

export default Order;
