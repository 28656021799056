import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../hooks/useObjectEditor";
import { StyledRow } from "../../../../../components/common";
import ToggleBadge from "../ToggleBadge";

const Badges: React.FC<Badges.Props> = ({
	value,
	onChange,
	style,
}): JSX.Element => {
	const { t } = useTranslation();
	const internalValueEditor = useObjectEditor<Badges.DisplayBadges>(
		value,
		onChange,
	);
	const routeValue = internalValueEditor.useGetter("route");
	const routeOnChange = internalValueEditor.useSetter("route");
	const trackValue = internalValueEditor.useGetter("track");
	const trackOnChange = internalValueEditor.useSetter("track");
	const sectorsValue = internalValueEditor.useGetter("sectors");
	const sectorsOnChange = internalValueEditor.useSetter("sectors");
	const parkingValue = internalValueEditor.useGetter("parking");
	const parkingOnChange = internalValueEditor.useSetter("parking");
	const priceZonesValue = internalValueEditor.useGetter("priceZones");
	const priceZonesOnChange = internalValueEditor.useSetter("priceZones");

	const valueRouteOnChange = useCallback(
		(data: boolean) => routeOnChange(data),
		[routeOnChange],
	);
	const valueTrackOnChange = useCallback(
		(data: boolean) => trackOnChange(data),
		[trackOnChange],
	);
	const valueSectorsOnChange = useCallback(
		(data: boolean) => sectorsOnChange(data),
		[sectorsOnChange],
	);
	const valueParkingOnChange = useCallback(
		(data: boolean) => parkingOnChange(data),
		[parkingOnChange],
	);
	const valuePriceZonesOnChange = useCallback(
		(data: boolean) => priceZonesOnChange(data),
		[priceZonesOnChange],
	);

	return (
		<StyledRow gap="10px" style={style}>
			<ToggleBadge
				icon="route"
				tooltip={
					t("mainPage.archives.orders.modal.map.badges.route") ||
					`Route`
				}
				value={routeValue}
				onChange={valueRouteOnChange}
			/>
			<ToggleBadge
				icon="route"
				tooltip={
					t("mainPage.archives.orders.modal.map.badges.track") ||
					`Track`
				}
				value={trackValue}
				onChange={valueTrackOnChange}
			/>
			<ToggleBadge
				icon="map"
				tooltip={
					t("mainPage.archives.orders.modal.map.badges.sectors") ||
					`Sectors`
				}
				value={sectorsValue}
				onChange={valueSectorsOnChange}
			/>
			<ToggleBadge
				icon="parking"
				tooltip={
					t("mainPage.archives.orders.modal.map.badges.parking") ||
					`Parking`
				}
				value={parkingValue}
				onChange={valueParkingOnChange}
			/>
			<ToggleBadge
				icon="price"
				tooltip={
					t("mainPage.archives.orders.modal.map.badges.priceZones") ||
					`Price zones`
				}
				value={priceZonesValue}
				onChange={valuePriceZonesOnChange}
			/>
		</StyledRow>
	);
};

declare namespace Badges {
	interface DisplayBadges {
		route: boolean;
		track: boolean;
		sectors: boolean;
		parking: boolean;
		priceZones: boolean;
	}
	interface Props {
		value: DisplayBadges;
		onChange: (value: DisplayBadges) => void;
		style?: React.CSSProperties;
	}
}

export const BadgesMemo = memo(Badges);
export default Badges;
