export interface ErrorResponse {
	message: string;
	status: 400 | 404 | 401 | 403 | 409 | 500;
	baseText: string;
	translation: string;
	canRetry: boolean;
	/** get additional information */
	additional?: boolean;
	/** value to get more information
		*
		* ```json
		{
			"reason": "ALREADY_EXISTS",
			"entityType": "phone",
			"metadata": {
				"phones": ["380000000000"]
			}
		}
		* ```
		*/
	details?: Record<string, any>;
	isConflicts?: boolean;
}

type RequestErrorContext = "create" | "update";

export const unknownErrors: Record<RequestErrorContext, ErrorResponse> = {
	create: {
		message: "",
		status: 400,
		baseText: "Failed to create a new tariff plan. Please try again.",
		translation: `executorRates.errors.create`,
		canRetry: true,
	},
	update: {
		message: "",
		status: 400,
		baseText: "Failed to change the tariff plan. Please try again.",
		translation: `executorRates.errors.update`,
		canRetry: true,
	},
};

const errors: ErrorResponse[] = [
	{
		message: "Cannot create another default rate",
		status: 400,
		baseText: "This tariff plan exist!",
		translation: `executorRates.errors.this_tariff_plan_exist`,
		canRetry: false,
	},
	{
		message: "Executor does not exist",
		status: 400,
		baseText: "Executor does not exist!",
		translation: `executorRates.errors.executor_does_not_exist`,
		canRetry: false,
	},
	{
		message: "Executor rate already exists",
		status: 400,
		baseText: "Executor rate already exists!",
		translation: `executorRates.errors.this_tariff_plan_exist`,
		canRetry: false,
	},
	{
		message:
			"Executor rate conflicts with other rates. Use 'force' flag to override them",
		status: 409,
		baseText: "There has been a branch conflict in the tariff plans!",
		translation: `executorRates.errors.rewrite.error_title`,
		canRetry: true,
		isConflicts: true,
		additional: true,
		details: [],
	},
];

/**
 * Retrieve prepared error by message
 * @param message Response error message from server
 * @param isEdit Flag to determine if the error is from edit or create
 */
export const getErrorByMessage = (
	message: string,
	isEdit: boolean,
	details: Record<string, any> = [],
) => {
	const error = errors.find((error) => error.message === message);
	if (!error) {
		const context = isEdit ? "update" : "create";
		return unknownErrors[context];
	}

	return { ...error, details };
};

export default errors;
