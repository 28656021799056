import { RefObject } from "react";
import { react } from "uikit";
import CheckMessageData from ".";

interface Context {
	checkMessageDataRef: RefObject<CheckMessageData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCheckMessageDataValid =
			!!this.context?.checkMessageDataRef.current?.validate();
		// const isFullNameValid = !!this.context?.fullNameRef.current?.validate();

		return isCheckMessageDataValid;
		// return isAuthorizationValid && isFullNameValid;
	}
}
