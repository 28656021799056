import Car from "../../../../../services/Car";

/* eslint-disable import/prefer-default-export */
export const getExecutorFullName = (item) =>
	`${item?.alias || ""}, ${item?.person?.firstName || ""} ${
		item?.person?.lastName || ""
	}`;

export const getCarInformation = (car: Car.Model | null) => {
	if (car) {
		const carBrand =
			car?.model?.carBaseTypeToCarBrand?.carBrand?.name || "";
		const carModel = car?.model?.name || "";
		const parkNumber = car?.parkNumber || "";

		const carInformation = `${carBrand} ${carModel}, ${parkNumber}`;
		return carInformation.trim();
	}
	return "";
};
