import { TFunction } from "i18next";

export enum Columns {
	NAME = "name",
	TYPE = "type",
	TIME = "time",
	PERIOD = "period",
	TOTAL = "total",
	MODE = "mode",
	PARAMETERS = "parameters",
}

type ColumnType = {
	id: string;
	label: string;
	width?: number;
};

export const getColumns = (t: TFunction): ColumnType[] => [
	{
		id: Columns.NAME,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.name",
			) ?? "",
		width: 230,
	},
	{
		id: Columns.TYPE,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.type",
			) ?? "",
		width: 150,
	},
	{
		id: Columns.TIME,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.time",
			) ?? "",
		width: 200,
	},
	{
		id: Columns.PERIOD,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.period",
			) ?? "",
		width: 100,
	},
	{
		id: Columns.MODE,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.mode",
			) ?? "",
		width: 200,
	},
	{
		id: Columns.PARAMETERS,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.parameters",
			) ?? "",
		width: 200,
	},
	{
		id: Columns.TOTAL,
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.content.modelTable.columns.total",
			) ?? "",
		width: 100,
	},
]; // columns
