import styled from "styled-components";

const Label = styled.div`
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0px;

	color: #bcc1c5;
`;

export default Label;
