import React, {
	Dispatch,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { isBoolean } from "lodash";

import Service from "../../../../services/Service";
import { useTypedSelector } from "../../../../redux/store";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectServiceWithModalBase: React.FC<
	MultiSelectServiceWithModalBase.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	error = "",
	sort,
	subscribeOptions = {},
	...props
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const options = useMemo(() => subscribeOptions, [subscribeOptions]);
	const subscribe = useModelSubscribe(options, Service);

	const items = useMemo(() => {
		if (subscribeOptions?.taxiServiceIds) {
			const { taxiServiceIds } = subscribeOptions;
			return subscribe.models?.filter((item) =>
				item.taxiServices?.some(({ id: taxiServiceId }) =>
					taxiServiceIds.includes(taxiServiceId),
				),
			);
		}

		return subscribe.models;
	}, [subscribe.models, subscribeOptions]);

	const [errorInternal, setErrorInternal] = useState<string | boolean>("");

	useEffect(() => {
		setErrorInternal(error);
	}, [error]);

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[lang],
				value: item.id,
				name: item.name?.[lang],
				data: item,
				active: isBoolean(item.active) ? item.active : undefined,
			})),
		[items, lang],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setErrorInternal("");
			const Ids = newValue.map((item) => item.value);
			onChange(Ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t(["all_service"]),
			allActiveText: t(["all_active_service"]),
		}),
		[t, titleText],
	);

	const valuesOptions = useMemo(() => {
		const arr = Array.isArray(selectOptions) ? selectOptions : [];
		const options = [...arr].filter((item) => {
			if (!isBoolean(item.active)) return true;
			return item.active === true;
		});

		const actives = [...arr].filter(
			(item) => isBoolean(item.active) && item.active === true,
		);

		const notActives = [...arr].filter(
			(item) => isBoolean(item.active) && item.active === false,
		);

		return {
			optionsLength: options.length,
			valueLength: selected.length,
			actives,
			notActives,
		};
	}, [selectOptions, selected]);

	return (
		<MultiSelectWithModal
			error={errorInternal}
			disabled={disabled}
			value={selected}
			options={selectOptions}
			actives={valuesOptions.actives}
			notActives={valuesOptions.notActives}
			title={texts.title}
			allText={texts.allText}
			allActiveText={texts.allActiveText}
			optionsLength={valuesOptions.optionsLength}
			valueLength={valuesOptions.valueLength}
			onChange={selectOnChange}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			modalSetting={modalSetting}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectServiceWithModal = memo(
	MultiSelectServiceWithModalBase,
);

declare namespace MultiSelectServiceWithModalBase {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		error?: string | boolean;
		subscribeOptions?: Service.SubscribeOptions;
	}

	type Value = number[];
}

export default MultiSelectServiceWithModalBase;
