import React from "react";
import { useTranslation } from "react-i18next";
import { Button, InputBorders, InputGroup, react, Row } from "uikit";

import { browse } from "../../utils/file";

import Root from "./components/Root";
import Text from "./components/Text";

function isFileList(files: FileList | File): files is FileList {
	return Array.isArray(files);
}

function filesToName(files: FileList | File) {
	return isFileList(files)
		? Array.from(files)
				.map((file) => file.name)
				.join(", ")
		: files.name;
}

const FileBrowser = react.inputify<FileBrowser.PropsBase, FileBrowser.Value>(
	({
		value,

		hovered,
		focused,
		error,
		disabled,

		placeholder,
		type,
		multiple,
		onChange,
		onEnter,
		onLeave,
		onFocus,
		onBlur,
	}) => {
		const { t } = useTranslation();
		return (
			<Root
				onClick={async () => {
					onFocus();

					const files = await browse({ type, multiple });

					onBlur();

					onChange(files as FileBrowser.Value);
				}}
				onMouseEnter={onEnter}
				onMouseLeave={onLeave}
			>
				<InputGroup.InputGroup
					hovered={hovered}
					focused={focused}
					error={error}
					disabled={disabled}
					sizes="1fr auto!"
				>
					<InputBorders.InputBorders>
						<Row align="center" maxedWidth maxedHeight>
							<Text type={value ? "default" : "placeholder"}>
								{value ? filesToName(value) : placeholder ?? ""}
							</Text>
						</Row>
					</InputBorders.InputBorders>
					<Button.Button
						text={t("fileBrowser.str0") ?? ""}
						style={{
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
						}}
					/>
				</InputGroup.InputGroup>
			</Root>
		);
	},
);

declare namespace FileBrowser {
	type Value = FileList | File;

	export interface PropsBase extends browse.Options {
		placeholder?: string;
	}
}

export default FileBrowser;
