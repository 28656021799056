/* eslint-disable no-shadow */

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../Breadcrumbs";

interface Props {}

const AutoBreadcrumbs: React.FC<Props> = () => {
	const { t } = useTranslation();

	const items = useMemo(() => {
		let path = "";

		const items = window.location.pathname
			.split(/\/+/)
			.filter((item) => !!item)
			.map((item) => ({
				label: t([`routes.${item}`, item]),
				link: (path += `/${item}`),
			}));

		items.unshift({
			label: t([`routes.main`, "main"]),
			link: "/",
		});

		return items;
	}, [t]);

	return <Breadcrumbs items={items} />;
};

export default AutoBreadcrumbs;
