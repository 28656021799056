import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const getColumnsModeOne = (t: TFunction): Column[] => [
	{
		id: "date",
		label: t("utils.getColumnsModeOne.date") ?? "Date",
	},
	{
		id: "action",
		label: t("utils.getColumnsModeOne.action") ?? "Action",
	},
	{
		id: "user",
		label: t("utils.getColumnsModeOne.user") ?? "User",
	},
	{
		id: "comment",
		label: t("utils.getColumnsModeOne.comment") ?? "Comment",
	},
];

getColumnsModeOne.defaultColumnIds = ["user", "action", "comment", "date"];

export default getColumnsModeOne;
