import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const CompaniesCellContent: React.FC<CompaniesCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();

	const content = item.companies ?? "_companies";

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CompaniesCellContent {
	interface Props {
		item: any;
	}
}

export default CompaniesCellContent;
