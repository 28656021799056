import React from "react";

import { IOrderPoint as OrderPoint } from "../../../../../../../../../redux/constants/OrdersPage/order";
import CellContentRoot from "../CellContentRoot";

const BuildingCellContent: React.FC<BuildingCellContent.Props> = ({ item }) => (
	<CellContentRoot align="center" maxedHeight maxedWidth>
		{item.house}
	</CellContentRoot>
);

declare namespace BuildingCellContent {
	interface Props {
		item: OrderPoint;
	}
}

export default BuildingCellContent;
