import createEmptyOrder, {
	Schema,
} from "../../../../../../../redux/constants/OrdersPage/order";

export default function prepareValues(data: { [k: string]: any }): Schema {
	const newData = createEmptyOrder();

	Object.keys(data).forEach((k) => {
		const value = data[k];

		if (value == null) return;

		if (value instanceof Array) {
			const array = value.filter((v) => v != null && v !== "");

			if (array.length !== 0) newData[k] = array;

			return;
		}

		newData[k] = value;
	});

	return newData;
}
