import React, { useMemo } from "react";
import { Select } from "uikit";
import { SelectEntry } from "../../../types/settingEntries";

interface SelectWrapperProps<V> {
	entry: SelectEntry<"meta", V>;
	value: SelectEntry<"value", V>;
	onChange: (value: SelectEntry<"value", V>) => void;
}

function SelectWrapper<V>({ entry, value, onChange }: SelectWrapperProps<V>) {
	const selectedKey = useMemo(
		() => entry.options.find((option) => option.value === value)?.key,
		[entry.options, value],
	);

	return (
		<Select<V>
			value={selectedKey}
			options={entry.options}
			onSelect={(option) => {
				onChange(option.value);
			}}
			style={{
				width: "178px",
			}}
		/>
	);
}

export default SelectWrapper;
