import React, { Dispatch } from "react";
import Root from "./components/Root";
import "rsuite/dist/rsuite.min.css";
import ModelTable from "./components/ModelTable";

const Content: React.FC<Content.Props> = ({
	loading,
	data,
	selected,
	sort,

	onChangeSelected,
	onChangeSort,

	onEdit,
	onLoadMore,
}) => (
	<Root sizes="1fr">
		<ModelTable
			selected={selected}
			sort={sort}
			loading={loading}
			data={data}
			onChangeSelected={onChangeSelected}
			onChangeSort={onChangeSort}
			onEdit={onEdit}
			onLoadMore={onLoadMore}
		/>
	</Root>
);

declare namespace Content {
	type Sort = ModelTable.Sort;

	type Item = ModelTable.Item;

	namespace Item {
		type Id = ModelTable.Item.Id;
	}

	interface Props {
		selected: Item.Id[];
		sort: Sort;
		data: ModelTable.Item[];
		loading: boolean;
		onChangeSelected: Dispatch<Item.Id[]>;
		onChangeSort: Dispatch<Sort>;
		onEdit: Dispatch<Item.Id>;
		onLoadMore: () => void;
	}
}

export default Content;
