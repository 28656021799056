import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import NumberCellContent from "../components/NumberCellContent";

function number({ width, onResize }: Column.Options) {
	return base(
		ColumnId.Number,
		(item, index) => <NumberCellContent index={index} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default number;
