/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { LatLngLiteral, LeafletEventHandlerFnMap } from "leaflet";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import MarkerController from "react-leaflet-enhanced-marker";
import { react, usePrevValue } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";
import Marker from "../../../../../../../../../../components/Marker";
import Address from "../../../../../../../../../../types/Address";
import NamedMarker from "../../../../../../../../../../components/NamedMarker";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import reveal from "../../utils/reveal";
import Language from "../../../../../../../../../../services/Language";

const PointController = react.inputify<
	PointController.PropsBase,
	PointController.Value
>(
	({
		value,

		autoResolve,
		language,
		name,
		editable = true,

		onChange,

		onClick,
	}) => {
		const currentRevealPromiseRef = useRef<Promise<any> | null>();

		const valueEditor = useObjectEditor(value ?? {}, onChange);

		const coordinates = valueEditor.useGetter("coordinates");
		const setCoordinates = valueEditor.useSetter("coordinates");

		const setAddress = valueEditor.useSetter("address");

		const internalReveal = useCallback(
			async (coordinates: LatLngLiteral) => {
				if (!autoResolve) return;

				const revealPromise = reveal(coordinates, language);

				currentRevealPromiseRef.current = revealPromise;

				const address = await revealPromise;

				if (currentRevealPromiseRef.current !== revealPromise) return;

				setAddress(address);
			},
			[autoResolve, language, setAddress],
		);

		const eventHandlers = useMemo<LeafletEventHandlerFnMap>(
			() => ({
				async dragend(event) {
					const newCoordinates = event.target._latlng;

					setCoordinates({
						lat: newCoordinates.lat,
						lng: newCoordinates.lng,
					});

					internalReveal(newCoordinates);
				},

				click: onClick,
			}),
			[onClick, internalReveal, setCoordinates],
		);

		const prevCoordinates = usePrevValue(coordinates);

		useEffect(() => {
			if (coordinates && !prevCoordinates) internalReveal(coordinates);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [coordinates, prevCoordinates]);

		useEffect(() => {
			if (coordinates && autoResolve) internalReveal(coordinates);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [autoResolve]);

		return (
			<>
				{coordinates && (
					<MarkerController
						icon={
							typeof name === "string" ? (
								<NamedMarker key={`markerIcon`} name={name} />
							) : (
								<Marker key={`markerIcon`} />
							)
						}
						draggable={editable}
						position={coordinates}
						eventHandlers={eventHandlers}
					/>
				)}
			</>
		);
	},
);

declare namespace PointController {
	interface Value {
		coordinates?: LatLngLiteral;
		address?: Partial<Address>;
	}

	export interface PropsBase {
		autoResolve: boolean;
		language: Language;
		name?: string;
		editable?: boolean;

		onClick?: () => void;
	}

	type Props = PropsBase & InputifiedComponentProps<Value>;
}

export default PointController;
