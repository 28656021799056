import constants from "../../constants/Preferences/carTypes";

/* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *\
|                                                                       |
|   TODO: Delete this reducer and replace with the model subscription   |
|                                                                       |
\* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** */

const initialState = {
	carTypes: [],
	bodyTypes: [],
};
export default function carTypes(state = initialState, action: any = {}) {
	switch (action.type) {
		case constants.setCarTypes:
			return {
				...state,
				carTypes: action.payload,
			};
		case constants.setBodyTypesForCarsTypes:
			return {
				...state,
				bodyTypes: action.payload,
			};
		default:
			return state;
	}
}
