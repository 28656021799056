import React, {
	PropsWithChildren,
	ReactNode,
	memo,
	useCallback,
	useMemo,
} from "react";

import Modal from "../Modal";

import Root from "./components/Root";
import Footer from "./components/Footer";

const EditModal: React.FC<EditModal.Props> = memo(
	({
		children,
		footer,
		footerJustify,
		cancelText,
		submitText,
		canSave = true,
		dimmed = true,
		onCancel,
		onSave,
		loading,
		...props
	}) => {
		const footerOnCancel = useCallback(() => onCancel?.(), [onCancel]);
		const footerOnSave = useCallback(() => onSave?.(), [onSave]);

		const content = useMemo(
			() => (
				<Root>
					<div>{children}</div>
					<Footer
						canSave={canSave}
						onCancel={footerOnCancel}
						onSave={footerOnSave}
						justify={footerJustify}
						cancelText={cancelText}
						submitText={submitText}
						loading={loading}
					>
						{footer}
					</Footer>
				</Root>
			),
			[
				canSave,
				cancelText,
				children,
				footer,
				footerJustify,
				footerOnCancel,
				footerOnSave,
				loading,
				submitText,
			],
		);

		return (
			<Modal dimmed={dimmed} {...props}>
				{content}
			</Modal>
		);
	},
);
declare namespace EditModal {
	interface Props extends PropsWithChildren, Modal.Props {
		footer?: ReactNode;
		footerJustify?: Footer.Props["justify"];
		dimmed?: boolean;
		canSave?: boolean;
		cancelText?: string;
		submitText?: string;
		containerId?: string;
		onCancel?: () => void;
		onSave?: () => void;
		loading?: boolean;
	}
}

export default EditModal;
