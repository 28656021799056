function objectToLabel(street: streetToString.Street) {
	return street.streetType && street.street
		? `${street.streetType} ${street.street}`.trim()
		: "";
}

declare namespace streetToString {
	interface Street {
		streetType?: string;
		street?: string;
	}
}

export default objectToLabel;
