import React, { Suspense, memo } from "react";
import { useInternal } from "uikit";

import KeyBind from "../../KeyBind";
import { Footer } from "../../common/Modal";
import { BaseElementIdEnum, SuspenseLoader } from "../../common";

import BaseModal from "./BaseModal";

const BaseInputModal: React.FC<BaseInputModal.Props> = ({
	onSubmit,
	onClose,
	content,
	footer,
	footerDivider,
	...props
}) => {
	const [action, setAction] = useInternal<"save" | "close">("save");

	return (
		<BaseModal {...props} onClose={onClose}>
			<KeyBind
				keys={["Escape"]}
				onUse={(event) => {
					event.preventDefault();
					event.stopPropagation();
					onClose(event);
				}}
			/>
			<KeyBind
				keys={["Enter"]}
				onUse={(event) => {
					event.preventDefault();
					event.stopPropagation();
					if (action === "save") onSubmit(event);
					else onClose(event);
				}}
			/>
			<KeyBind
				keys={["Tab"]}
				onUse={(event) => {
					const elem = event?.target as HTMLElement;

					if (elem?.id === BaseElementIdEnum.STEPPER) {
						setAction("close");
					} else if (elem?.id === BaseElementIdEnum.TIME_SELECT) {
						setAction("close");
					} else setAction("save");
				}}
			/>
			<Suspense fallback={<SuspenseLoader />}>{content}</Suspense>
			<Footer onSave={onSubmit} onClose={onClose} divider={footerDivider}>
				{footer}
			</Footer>
		</BaseModal>
	);
};

declare namespace BaseInputModal {
	interface Props extends BaseModal.Props {
		value: any;
		content: React.ReactNode;
		footer?: React.ReactNode;
		header?: React.ReactNode;
		footerDivider?: boolean;
		headerDivider?: boolean;
		hideDefaultButtons?: boolean;
		onSubmit: Footer.Props["onSave"];
		onClose: Footer.Props["onClose"];
	}

	type InputModalContent<T> = {
		value: T;
		setValue: React.Dispatch<React.SetStateAction<T>>;
		onSubmit: () => void;
		onClose: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	};
}

export const BaseInputModalMemo = memo(BaseInputModal);
export default BaseInputModal;
