import React, { RefAttributes, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextBox, react } from "uikit";

import { useTypedSelector } from "../../../../../../redux/store";
import LabeledField from "../../../../../../components/LabeledField";
import { IName } from "../../../../../../types/IName";
import tPath from "../../constants/tPath";

import InternalController from "./Controller";

const NameBase = react.withController<Name.PropsBase, Name.Controller>(
	({ disabled, value, onChange, controller }) => {
		const { t } = useTranslation();
		const lang = useTypedSelector((state) => state.session.language);

		const [error, setError] = useState(false);

		const changeNameHandler = useCallback(
			(newName) => {
				setError(false);
				onChange({ [lang]: newName } as IName);
			},
			[lang, onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={t(`${tPath}.mainModal.tabs.main.name`) || "Name"}
			>
				<TextBox.TextBox
					disabled={disabled}
					placeholder={
						t(`${tPath}.mainModal.tabs.main.name`) || "Name"
					}
					value={value[lang]}
					onChange={changeNameHandler}
					// TODO: add translation
					error={
						error &&
						(t(`${tPath}.mainModal.tabs.main.nameRequired`) ?? "")
					}
				/>
			</LabeledField>
		);
	},
	InternalController,
);
const Name = memo(NameBase);

declare namespace Name {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		disabled?: boolean;
		value: Value;
		onChange: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = IName;
}

export default Name;
