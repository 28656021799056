import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import SearchBar from "./components/SearchBar";
import { useTypedSelector } from "../../../../redux/store";
import softphoneWidget from "../../../../redux/reducers/softphoneWidget";
import CallsJournal from "./components/CallsJournal";

const Tab = styled("div")`
	height: 525px;
	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

const LatestTab = () => {
	const dispatch = useDispatch();
	const { latestQuery, latestCallsData, isReduce, isShowAllLatestCalls } =
		useTypedSelector((state) => state.softphoneWidget);

	const onChangeQuery = useCallback(
		(e) => {
			dispatch(softphoneWidget.actions.setLatestQuery(e));
		},
		[dispatch],
	);

	const onChangeCallsFilter = useCallback(() => {
		dispatch(
			softphoneWidget.actions.setIsShowAllLatestCalls(
				!isShowAllLatestCalls,
			),
		);
	}, [dispatch, isShowAllLatestCalls]);

	return (
		<Tab>
			<SearchBar
				value={latestQuery}
				onChange={onChangeQuery}
				showAllCalls={isShowAllLatestCalls}
				onChangeCallsFilter={onChangeCallsFilter}
			/>
			<CallsJournal data={latestCallsData} isReduce={isReduce} />
		</Tab>
	);
};

export default LatestTab;
