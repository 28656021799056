import styled from "styled-components";
import { theme } from "uikit";

const Header = styled.b`
	z-index: 10;
	width: 100%;
	height: 43px;

	display: flex;
	align-items: center;
	padding-inline: 20px;

	color: ${theme.colors.primary};
	background-color: white;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
`;

export default Header;
