import styled from "styled-components";

const Text = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
`;

export default Text;
