// eslint-disable-next-line import/no-unresolved

import React, { memo, useMemo } from "react";
import L, { LatLngLiteral } from "leaflet";
import { useMap } from "react-leaflet";
import { react, useChanged } from "uikit";
import objectHash from "object-hash";
import { useTypedSelector } from "../../../../../redux/store";
import "leaflet-polylinedecorator";
import DecoratedPolyline from "./components/DecoratedPolyline";
import PointController from "../../../../PointController";
import LocalController from "./Controller";

const routeColors = [
	"#e83a53",
	"#54ad4a",
	"#397eb0",
	"#974ba4",
	"#f49e3b",
	"#fcfb2e",
	"#ad4f20",
	"#f680bd",
	"#aca7a3",
];
// isTaximeter ? "#8e00ff" :
const RouteBase = react.withController(({ controller }) => {
	const language = useTypedSelector((state) => state.session.language);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const map = useMap();

	const routes = useMemo(
		() => (activeOrder.route?.segments as LatLngLiteral[][]) ?? [],
		[activeOrder.route?.segments],
	);

	const isTaximeter = useMemo(
		() => activeOrder?.additionalFields?.processedByTaximeter,
		[activeOrder?.additionalFields?.processedByTaximeter],
	);

	const routePolylines = useMemo(
		() =>
			routes.map((route, index) => (
				<DecoratedPolyline
					key={index}
					positions={route}
					patterns={[
						{
							offset: 25,
							repeat: 90,
							symbol: new L.Symbol.ArrowHead({
								pixelSize: 10,
								pathOptions: {
									color: isTaximeter
										? "#8e00ff"
										: routeColors[
												index % routeColors.length
										  ],
									fillOpacity: 1,
									weight: 0,
								},
							}),
						},
					]}
					pathOptions={{
						color: isTaximeter
							? "#8e00ff"
							: routeColors[index % routeColors.length],
					}}
				/>
			)),
		[routes, isTaximeter],
	);

	const points = useMemo(
		() =>
			(activeOrder?.points as any[])?.map<LatLngLiteral>((point) => ({
				lat: point.coordinates.lat,
				lng: point.coordinates.lng,
			})) ?? [],
		[activeOrder?.points],
	);

	const routePoints = useMemo(
		() =>
			points.map((point, index) => (
				<PointController
					key={index}
					value={{ coordinates: point }}
					name={(index + 1).toString()}
					editable={false}
					language={language}
				/>
			)),
		[language, points],
	);

	controller.setContext({ map, routes, points });

	const hash = useMemo(
		() => objectHash({ routes, points }),
		[routes, points],
	);

	useChanged(() => {
		controller.focus();
	}, hash);

	return (
		<>
			{routePolylines}
			{routePoints}
		</>
	);
}, LocalController);

const Route = memo(RouteBase);

declare namespace Route {
	type Controller = LocalController;
}

export default Route;
