/* eslint-disable no-shadow */

import React, { Dispatch, useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { clone } from "lodash";
import Root from "./components/Root";
import Item from "./components/Item";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import Company from "../../../../../../../../../../../../../../services/Company";
import Language from "../../../../../../../../../../../../../../services/Language";

const Group: React.FC<Group.Props> = ({
	value,
	editing,

	language,

	onChange,
	onChangeEditing,
}) => {
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache ?? [];
	const companies = useModelSubscribe({}, Company)?.cache ?? [];

	const sorted = useMemo(
		() =>
			value.sort(
				(item1, item2) =>
					item2.position.valueOf() - item1.position.valueOf(),
			),
		[value],
	);

	return (
		<DragDropContext
			onDragEnd={(result) => {
				const sourceIndex = result.source.index;
				const destinationIndex = result.destination?.index;

				if (
					typeof destinationIndex === "undefined" ||
					sourceIndex === destinationIndex
				)
					return;

				const newValue = clone(value);

				const deltaIndexSign = Math.sign(
					sourceIndex - destinationIndex,
				);

				const destinationPosition = sorted[destinationIndex].position;

				for (
					let index = destinationIndex;
					(deltaIndexSign < 0 && index >= sourceIndex) ||
					(deltaIndexSign > 0 && index <= sourceIndex);
					index += deltaIndexSign
				) {
					const newValueItemIndex = newValue.findIndex(
						(item) => sorted[index].id === item.id,
					);

					const newValueItem = clone(newValue[newValueItemIndex]);

					newValueItem.position =
						index === sourceIndex
							? destinationPosition
							: sorted[index + deltaIndexSign].position;

					newValue[newValueItemIndex] = newValueItem;
				}

				onChange(newValue, { type: "change-position" });
			}}
		>
			<div>
				<Droppable droppableId="droppable">
					{(provided) => (
						<Root
							ref={provided.innerRef}
							{...provided.droppableProps}
							gaps="1px*"
							maxedWidth
						>
							{sorted.map((item, index) => {
								const taxiService = taxiServices.find(
									(taxiService) =>
										taxiService.id === item.taxiServiceId,
								);

								const company = taxiService
									? companies.find(
											(company) =>
												company.id ===
												taxiService.company?.id,
									  )
									: undefined;

								return (
									<Item
										key={item.id}
										id={item.id}
										index={index}
										name={item.name[language]}
										subname={`${
											company?.name[language] ?? ""
										}, ${
											taxiService?.settlement[language] ??
											""
										}`}
										active={item.active}
										selected={item.selected}
										modified={item.modified}
										valid={item.valid}
										editing={editing}
										bySector={item.bySector}
										onSelect={(ctrl) => {
											let newValue: Group.Value;

											if (ctrl) {
												newValue = clone(value);

												newValue[index] = clone(
													newValue[index],
												);
												newValue[index].selected = true;
											} else {
												newValue = value.map(
													(oldItem) => {
														if (
															oldItem.id ===
															item.id
														)
															if (!item.selected)
																return {
																	...oldItem,
																	selected:
																		true,
																};
															else;
														else if (
															oldItem.selected
														)
															return {
																...oldItem,
																selected: false,
															};

														return oldItem;
													},
												);
											}

											onChange(newValue, {
												type: ctrl
													? "select"
													: "set-selection",
											});
										}}
										onUnselect={() => {
											if (!item.selected) return;

											const newValue = clone(value);

											newValue[index] = clone(
												newValue[index],
											);
											newValue[index].selected = false;

											onChange(newValue, {
												type: "unselect",
											});
										}}
										onEdit={() => {
											onChangeEditing(true);
										}}
									/>
								);
							})}
							{provided.placeholder}
						</Root>
					)}
				</Droppable>
			</div>
		</DragDropContext>
	);
};

declare namespace Group {
	interface Item {
		id: number;

		taxiServiceId: number;

		position: Date | number;
		name: Record<Language, string>;

		selected: boolean;
		modified: boolean;
		valid: boolean;
		bySector: boolean;

		active: boolean;
	}

	type Value = Item[];

	interface Details {
		type: "set-selection" | "select" | "unselect" | "change-position";
	}

	interface Props {
		value: Value;
		editing: boolean;

		language: Language;

		onChange: (value: Value, details: Details) => void;
		onChangeEditing: Dispatch<boolean>;
	}
}

export default Group;
