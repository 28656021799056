/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import Language from "../Language";
// import Subscription from "../../types/Subscription";

class ExecutorRateClassifier extends Base {
	static fromResponse(data: any): ExecutorRateClassifier.Model {
		return {
			id: data.id,

			name: data.name,

			type: data.type,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	// static toRequest(
	// 	model: Subscription.Model.New | Subscription.Model.Modified,
	// ): any {
	// 	return {};
	// }

	public static async getAll(
		options: ExecutorRateClassifier.SubscribeOptions,
	) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.executorRate.classifier.getAll(
				ExecutorRateClassifier.optionsToRequest(options),
			),
		);

		return data.items.map(this.fromResponse);
	}

	// public static async store(object: Subscription.Model.New) {
	// 	const data = await this.request((prpc) =>
	// 		prpc.theirsModel.executorRate.create(
	// 			Subscription.toRequest(object),
	// 		),
	// 	);

	// 	return data;
	// }

	// public static async update(object: Subscription.Model.Modified) {
	// 	const data = await this.request((prpc) =>
	// 		prpc.theirsModel.executorRate.update(
	// 			object.id,
	// 			Subscription.toRequest(object),
	// 		),
	// 	);

	// 	return data;
	// }

	// public static async destroy(id: number[] | number) {
	// 	if (Array.isArray(id))
	// 		await Promise.all(id.map((id) => this.destroyOne(id)));
	// 	else await this.destroyOne(id);
	// }

	public static async subscribe(
		options: ExecutorRateClassifier.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<ExecutorRateClassifier.Model>,
	): Promise<Subscription<ExecutorRateClassifier.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.executorRate.classifier.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "ExecutorRateClassifier.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: ExecutorRateClassifier.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<ExecutorRateClassifier.SubscribeOptions>;
	}

	// private static async destroyOne(id: number) {
	// 	this.request((prpc) => prpc.theirsModel.executorRate.delete(id));
	// }

	private static optionsToRequest(
		options: ExecutorRateClassifier.SubscribeOptions,
	) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			lang: options.language,

			order: options.order,
		};
	}
}

namespace ExecutorRateClassifier {
	export interface Model {
		id: number;

		name: Record<Language, string>;

		type: Model.Type;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions
		extends Omit<
			ServiceSubscribeOptionsBase<ExecutorRateClassifier.Model>,
			"order"
		> {
		language?: Language;

		order?: Partial<Record<"active" | "createdAt", SortingOrder>>;
	}

	export namespace Model {
		export enum Type {
			Commission = "commission",
			Subscription = "subscription",
		}

		export interface New {}
		export type Modified = Partial<New> & { id: number };
	}
}

export default ExecutorRateClassifier;
