/* eslint-disable no-param-reassign */

import { Nullable } from "uikit";
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

import CallService from "../../services/Call";
import Executor from "../../services/Executor";
import Dispatcher from "../../services/Dispatcher";
import {
	DispatcherPhone,
	ExecutorPhone,
} from "../../components/SoftphoneWidget/components/Transfer/types";

export interface CallData {
	id: string;
	direction: "incoming" | "outgoing" | "pause" | "current";
	callDuration: string | null;
	pauseDuration: string | null;
	isHold: boolean;
	number: string;
	name: string | null;
	isMuted: boolean;
}

export type CallType = "onSip" | "onNumber";

export interface Call {
	originator: string;
	session: any;
	request: any;
	callDuration: string;
	holdDuration: string;
}

export interface TransferCall {
	call: Call;
	contactToRefer?: Nullable<DispatcherPhone | ExecutorPhone>;
	sipUri: string;
	numberToRefer?: string;
}

export interface SimpleCall {
	callDuration: string; // "00:00"
	holdDuration: string; // "00:01"
	isTalk: boolean; // true/false (Indicates whether the call is ongoing or on hold)
	isIncomingCall: boolean; // true/false (Incoming call or outgoing call)
	isConfirmed: boolean; // true/false (Call accepted or not accepted)
	phone: string; // "380660001122"
}

export type TransferType = "normal" | "blind" | "none";

type TabType = "dialing" | "latest";
export type TransferTabType = "dispatchers" | "executors" | "anyNumber";

export interface LatestCallData {
	id: string;
	number: string;
	name: string;
	callDate: number;
	callDuration: number;
	callType: "incoming" | "outgoing";
	isCallAbort: boolean;
}

interface InitialState {
	isOpen: boolean;
	isReduce: boolean;
	isFocused: boolean;
	position: { x: number; y: number };
	numberQuery: string;
	showKeyboard: boolean;
	callsData: Call[];
	stateCalls: SimpleCall[];
	selectedCall: Nullable<Call>;
	numberOfMissedCalls: number;
	tab: TabType;
	latestCallsData: CallService.Model[];
	latestQuery: string;
	transfer: TransferType;
	transferData: {
		executors: Executor.Model[];
		dispatchers: Dispatcher.Model[];
	};
	transferTab: TransferTabType;
	selectedTransfer: Nullable<ExecutorPhone | DispatcherPhone>;
	isShowTransferSelector: boolean;
	isShowAllLatestCalls: boolean;
	showTransferKeyboard: boolean;
	transferSearchQuery: string;
	transferNumberQuery: string;
	transferQueue: TransferCall[];
	rerender: boolean;
}

const initialState: InitialState = {
	rerender: false,
	isOpen: false,
	isReduce: false,
	isFocused: true,
	position: {
		x: window.innerWidth - 410 - (window.innerWidth - 410) / 2, // 410 = softphone width + right bar width + right padding
		y: -window.innerHeight / 2 + 225, // 225 = reduced softphone height + top padding
	},
	numberQuery: "",
	showKeyboard: false,
	selectedCall: null,
	numberOfMissedCalls: 0,
	tab: "dialing",
	latestQuery: "",
	transfer: "none",
	transferData: {
		executors: [],
		dispatchers: [],
	},
	transferTab: "dispatchers",
	selectedTransfer: null,
	isShowTransferSelector: false,
	isShowAllLatestCalls: true,
	showTransferKeyboard: false,
	transferNumberQuery: "",
	transferSearchQuery: "",
	transferQueue: [],
	callsData: [],
	stateCalls: [],
	latestCallsData: [],
};

type Reducer<P> = CaseReducer<typeof initialState, PayloadAction<P>>;

const setIsOpen: Reducer<boolean> = (state, { payload }) => {
	state.isOpen = payload;
};

const setIsReduce: Reducer<boolean> = (state, { payload }) => {
	state.isReduce = payload;
	state.showKeyboard = false;
	if (payload === true) {
		const { innerHeight } = window;
		const expandedOffset = Math.ceil((innerHeight - 604) / 2);
		if (state.position.y <= -expandedOffset) {
			state.position.y = -expandedOffset;
		}
	}
};

const setIsFocused: Reducer<boolean> = (state, { payload }) => {
	state.isFocused = payload;
};

const setPosition: Reducer<{ x: number; y: number }> = (state, { payload }) => {
	state.position = payload;
};

const setNumberQuery: Reducer<string> = (state, { payload }) => {
	state.numberQuery = payload;
};

const setShowKeyboard: Reducer<boolean> = (state, { payload }) => {
	state.showKeyboard = payload;
};

const setSelectedCaller: Reducer<Nullable<Call>> = (state, { payload }) => {
	state.selectedCall = state.callsData.find(
		(call) => call.request.call_id === payload?.request.call_id,
	);
};

const setTab: Reducer<TabType> = (state, { payload }) => {
	state.tab = payload;
};

const setLatestQuery: Reducer<string> = (state, { payload }) => {
	state.latestQuery = payload;
};

const setLatestCallsData: Reducer<CallService.Model[]> = (
	state,
	{ payload },
) => {
	state.latestCallsData = payload;
};

const setNumberOfMissedCalls: Reducer<number> = (state, { payload }) => {
	state.numberOfMissedCalls = payload;
};

const addCallerToCalls: Reducer<Call> = (state, { payload }) => {
	state.callsData.push(payload);

	const comprasion =
		payload?.request?.from?._uri?.user &&
		payload.request.from._uri.user.length > 6;

	const stateCall: SimpleCall = {
		callDuration: payload?.callDuration,
		holdDuration: payload?.holdDuration,
		isTalk: payload.session?._localHold,
		phone: comprasion
			? payload?.request?.from?._uri?.user
			: payload?.request?.to?._uri?.user,
		isIncomingCall: payload?.session?._direction === "incoming",
		isConfirmed: payload?.session?._is_confirmed,
	};

	state.stateCalls.push(stateCall);
};

const setTransfer: Reducer<TransferType> = (state, { payload }) => {
	state.transfer = payload;
	if (payload !== "none") {
		const { innerHeight } = window;
		const expandedOffset = Math.ceil((innerHeight - 562) / 2);
		if (state.position.y <= -expandedOffset) {
			state.position.y = -expandedOffset;
		}
	}
	if (payload === "none" && state.isReduce === true) {
		const { innerHeight } = window;
		const expandedOffset = Math.ceil((innerHeight - 604) / 2);
		if (state.position.y <= -expandedOffset) {
			state.position.y = -expandedOffset;
		}
	}
};

const setSelectedTransfer: Reducer<
	Nullable<ExecutorPhone | DispatcherPhone>
> = (state, { payload }) => {
	state.selectedTransfer = payload;
};

const setShowTransferKeyboard: Reducer<boolean> = (state, { payload }) => {
	state.showTransferKeyboard = payload;
};

const setIsShowTransferSelector: Reducer<boolean> = (state, { payload }) => {
	state.isShowTransferSelector = payload;
};

const setTransferTab: Reducer<TransferTabType> = (state, { payload }) => {
	state.transferTab = payload;
	if (payload === "anyNumber") {
		state.showTransferKeyboard = true;
	} else {
		state.showTransferKeyboard = false;
	}
};

const setIsShowAllLatestCalls: Reducer<boolean> = (state, { payload }) => {
	state.isShowAllLatestCalls = payload;
};

const setTransferNumberQuery: Reducer<string> = (state, { payload }) => {
	state.transferNumberQuery = payload;
};

const setTransferSearchQuery: Reducer<string> = (state, { payload }) => {
	state.transferSearchQuery = payload;
};

const addToTransferQueue: Reducer<TransferCall> = (state, { payload }) => {
	state.transferQueue.push(payload);
};

const deleteFromTransferQueue: Reducer<TransferCall> = (state, { payload }) => {
	state.transferQueue = state.transferQueue.filter(
		(transfer) =>
			transfer.call.request.call_id !== payload.call.request.call_id,
	);
};

const clearCalls: Reducer<undefined> = (state) => {
	state.callsData = [];
};

const updateCall: Reducer<Call> = (state, { payload }) => {
	state.callsData = state.callsData.map((call) =>
		call.session._contact === payload.session._contact ? payload : call,
	);
};

const terminateCall: Reducer<Nullable<Call>> = (state, { payload }) => {
	const filtredCallsData = state.callsData.filter(
		(call) => call.session.id !== payload?.session?.id,
	);
	state.callsData = filtredCallsData;
	state.selectedCall =
		state.selectedCall?.request.call_id === payload?.request?.call_id
			? null
			: state.selectedCall;

	const filtresStateCalls = state.stateCalls.filter(
		(call) =>
			call.phone !== payload?.request?.from?._uri?.user &&
			call.phone !== payload?.request?.to?._uri?.user,
	);
	state.stateCalls = filtresStateCalls;
};

const rerenderSoftphone = (state) => {
	state.rerender = !state.rerender;
};

const updateCallDuration: Reducer<Call> = (state, { payload }) => {
	state.callsData = state.callsData.map((call) =>
		call.request.call_id === payload.request.call_id ? payload : call,
	);

	state.stateCalls = state.stateCalls.map((call) =>
		call.phone === payload.request?.from?._uri?.user ||
		call.phone === payload.request?.to?._uri?.user
			? {
					...call,
					callDuration: payload.callDuration,
					holdDuration: payload.holdDuration,
					isTalk: !payload.session._localHold,
					isConfirmed: payload?.session?._is_confirmed,
			  }
			: call,
	);
};

const setExecutorsInTransferList: Reducer<Executor.Model[]> = (
	state,
	{ payload },
) => {
	state.transferData.executors = payload;
};
const setDispatchersInTransferList: Reducer<Dispatcher.Model[]> = (
	state,
	{ payload },
) => {
	state.transferData.dispatchers = payload;
};

const closeTransferModal: Reducer<undefined> = (state) => {
	state.transfer = "none";
	state.transferTab = "dispatchers";
	state.transferNumberQuery = "";
	state.showTransferKeyboard = false;
	state.selectedTransfer = null;
	state.transferSearchQuery = "";
};

const softphoneWidget = createSlice({
	name: "softphoneWidget",
	initialState,
	reducers: {
		setIsOpen,
		setIsReduce,
		setIsFocused,
		setPosition,
		setNumberQuery,
		setShowKeyboard,
		setSelectedCaller,
		setTab,
		setLatestQuery,
		addCallerToCalls,
		setTransfer,
		setTransferTab,
		setSelectedTransfer,
		setIsShowTransferSelector,
		setIsShowAllLatestCalls,
		setShowTransferKeyboard,
		setTransferNumberQuery,
		setTransferSearchQuery,
		closeTransferModal,
		addToTransferQueue,
		deleteFromTransferQueue,
		terminateCall,
		updateCall,
		rerenderSoftphone,
		setLatestCallsData,
		clearCalls,
		updateCallDuration,
		setNumberOfMissedCalls,
		setExecutorsInTransferList,
		setDispatchersInTransferList,
	},
});

export default softphoneWidget;
