/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Language, defaultLanguage } from "../../assets/languages/langs";

interface State {
	current: Language;
}

const initialState = {
	current: defaultLanguage,
};

type Reducer<P> = CaseReducer<State, PayloadAction<P>>;

const setLang: Reducer<Language> = (state, { payload }) => {
	state.current = payload;
};

const language = createSlice({
	name: "language",
	initialState,
	reducers: {
		setLang,
	},
});

export const languageAction = language.actions;
export const languageReducer = language.reducer;
export type languageState = State;
export default language;
