import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "uikit";

import Root from "./components/Root";
import ColumnCountText from "./components/ColumnCountText";
import ColumnCount from "./components/ColumnCount";

const Header = memo<Header.Props>(({ columnCount, onClear }) => {
	const { t } = useTranslation();
	return (
		<Root align="center" sizes="auto! 1fr auto!" gaps="5px*">
			<ColumnCountText>
				{t("columnSetupModal.orderController.header.str200") ?? ""}:{" "}
			</ColumnCountText>
			<ColumnCount>{columnCount}</ColumnCount>
			<Button.Button
				text={t("columnSetupModal.orderController.header.str0") ?? ""}
				variant="negative"
				transparent
				hasPaddings={false}
				onClick={onClear}
			/>
		</Root>
	);
});

declare namespace Header {
	interface Props {
		columnCount: number;

		onClear: () => void;
	}
}

export default Header;
