import React from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const ColorCircle: React.FC<ColorCircle.Props> = ({ selected, color }) => (
	<Root color={color}>
		{selected && (
			<Icon
				style={{ position: "absolute" }}
				id="check-mark"
				colors={[theme.colors.white]}
				size={20}
			/>
		)}
	</Root>
);

declare namespace ColorCircle {
	interface Props {
		selected: boolean;

		color: string;
	}
}

export default ColorCircle;
