/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Column, Icon, react, Row, theme, useDebounce } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";
import { LatLngLiteral } from "leaflet";
import { isNumber, isString } from "lodash";

import Address from "../../../../../../../../../../types/Address";
import Language from "../../../../../../../../../../services/Language";
import Map from "../../../../../../../../../../services/Map";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import useRefWithUpdater from "../../../../../../../../../../hooks/useRefWithUpdater";
import reveal from "../../utils/reveal";

import InternalController from "./Controller";
import addressToString from "./functions/addressToString";
import settlementToString from "./components/Settlement/utils/objectToLabel";
import streetToString from "./components/Street/utils/objectToLabel";
import Settlement from "./components/Settlement";
import Street from "./components/Street";
import House from "./components/House";
import Coordinates from "./components/Coordinates";

const PointForm = react.withController<
	PointForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<PointForm.PropsBaseWithController, PointForm.Value>(
		({
			value,

			language,
			autoResolve,

			controller,

			onChange,
		}) => {
			const rootRef = useRef<HTMLDivElement | null>(null);

			const {
				inputRequestDelayMs: delaySearchRequest,
				searchRadiusMeters: searchRadius,
			} = useTypedSelector((state) => state.settings.map);

			const valueEditor = useObjectEditor(value ?? {}, onChange);

			const coordinates = valueEditor.useGetter("coordinates");
			const setCoordinates = valueEditor.useSetter("coordinates");

			const address = valueEditor.useGetter("address");
			const setAddress = valueEditor.useSetter("address");

			const addressEditor = useObjectEditor(address ?? {}, setAddress);

			const setCountry = addressEditor.useSetter("country");
			const setCountryCode = addressEditor.useSetter("countryCode");
			const setSettlement = addressEditor.useSetter("settlement");
			const setSettlementType = addressEditor.useSetter("settlementType");
			const setDistrict = addressEditor.useSetter("district");
			const setRegion = addressEditor.useSetter("region");
			const setStreet = addressEditor.useSetter("street");
			const setStreetType = addressEditor.useSetter("streetType");
			const setHouse = addressEditor.useSetter("house");

			const query = valueEditor.useGetter("query");
			const setQuery = valueEditor.useSetter("query");

			const queryEditor = useObjectEditor(
				query ?? {
					settlement: "",
					street: "",
					house: "",
				},
				setQuery,
			);

			const settlementQuery = queryEditor.useGetter("settlement");
			const setSettlementQuery = queryEditor.useSetter("settlement");

			const streetQuery = queryEditor.useGetter("street");
			const setStreetQuery = queryEditor.useSetter("street");

			const houseQuery = queryEditor.useGetter("house");
			const setHouseQuery = queryEditor.useSetter("house");

			const [settlementObject, setSettlementObject] = useState<
				Map.Search.Object | undefined
			>(undefined);

			const [streetObject, setStreetObject] = useState<
				Map.Search.Object | undefined
			>(undefined);

			const [houseFieldFocused, setHouseFieldFocused] = useState(false);

			const [settlementFieldError, setSettlementFieldError] =
				useState(false);

			const [streetFieldError, setStreetFieldError] = useState(false);

			const [houseFieldError, setHouseFieldError] = useState(false);

			const [coordinatesFieldError, setCoordinatesFieldError] =
				useState(false);

			const settlementObjectRef = useRefWithUpdater(settlementObject);
			const streetObjectRef = useRefWithUpdater(streetObject);
			const houseQueryRef = useRefWithUpdater(houseQuery);
			const houseFieldFocusedRef = useRefWithUpdater(houseFieldFocused);
			const addressRef = useRefWithUpdater(address);

			const search = useCallback(async () => {
				const settlementObject = settlementObjectRef.current;
				const streetObject = streetObjectRef.current;
				const houseQuery = houseQueryRef.current;
				const houseFieldFocused = houseFieldFocusedRef.current;
				const address = addressRef.current;

				if (
					!settlementObject ||
					!streetObject ||
					!houseQuery ||
					houseFieldFocused
				)
					return;

				const searchOptions: Map.Search.Options = {
					language,
					searchType: "address",
					country: "ua",
					near: {
						point: settlementObject.coordinates,
						radius: searchRadius,
					},
				};

				const searchResult = await Map.search(
					addressToString(address ?? {}),
					searchOptions,
				);

				if (searchResult.objects[0])
					setCoordinates(searchResult.objects[0].coordinates);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [language, searchRadius]);

			const debouncedSearch = useDebounce(search, delaySearchRequest);

			const settlementOnSelectObject = useCallback(
				(settlementObject: Map.Search.Object) => {
					setSettlementObject(settlementObject);

					if (isString(settlementObject.country))
						setCountry(settlementObject.country);

					if (isString(settlementObject.countryCode))
						setCountryCode(settlementObject.countryCode);

					if (isString(settlementObject.settlementType))
						setSettlementType(settlementObject.settlementType);

					if (isString(settlementObject.settlement))
						setSettlement(settlementObject.settlement);

					if (isString(settlementObject.district))
						setDistrict(settlementObject.district);

					if (isString(settlementObject.region))
						setRegion(settlementObject.region);

					if (isString(settlementObject.streetType))
						setStreetType(settlementObject.streetType);

					if (isString(settlementObject.street))
						setStreet(settlementObject.street);
				},
				[
					setCountry,
					setCountryCode,
					setDistrict,
					setRegion,
					setSettlement,
					setSettlementType,
					setStreet,
					setStreetType,
				],
			);

			const streetOnSelectObject = useCallback(
				(streetObject: Map.Search.Object) => {
					setStreetObject(streetObject);

					if (isString(streetObject.country))
						setCountry(streetObject.country);

					if (isString(streetObject.countryCode))
						setCountryCode(streetObject.countryCode);

					if (isString(streetObject.settlementType))
						setSettlementType(streetObject.settlementType);

					if (isString(streetObject.settlement))
						setSettlement(streetObject.settlement);

					if (isString(streetObject.district))
						setDistrict(streetObject.district);

					if (isString(streetObject.region))
						setRegion(streetObject.region);

					if (isString(streetObject.streetType))
						setStreetType(streetObject.streetType);

					if (isString(streetObject.street))
						setStreet(streetObject.street);
				},
				[
					setCountry,
					setCountryCode,
					setDistrict,
					setRegion,
					setSettlement,
					setSettlementType,
					setStreet,
					setStreetType,
				],
			);

			const settlementOnChange = useCallback(
				(settlement: string) => {
					setSettlementFieldError(false);

					setSettlementQuery(settlement);

					debouncedSearch();
				},
				[debouncedSearch, setSettlementQuery],
			);

			const streetOnChange = useCallback(
				(street: string) => {
					setStreetFieldError(false);

					setStreetQuery(street);

					debouncedSearch();
				},
				[debouncedSearch, setStreetQuery],
			);

			const houseOnChange = useCallback(
				(house: string) => {
					setHouseFieldError(false);

					setHouseQuery(house);
					setHouse(house);

					debouncedSearch();
				},
				[debouncedSearch, setHouse, setHouseQuery],
			);

			const currentRevealPromiseRef = useRef<Promise<any> | null>();

			const internalReveal = useCallback(
				async (coordinates: LatLngLiteral) => {
					if (!autoResolve) return;

					const revealPromise = reveal(coordinates, language);

					currentRevealPromiseRef.current = revealPromise;

					const address = await revealPromise;

					if (currentRevealPromiseRef.current !== revealPromise)
						return;

					setAddress(address);
					setQuery({
						settlement: settlementToString(address ?? {}),
						street: streetToString(address ?? {}),
						house: address?.house ?? "",
					});
				},
				[autoResolve, language, setAddress, setQuery],
			);

			useLayoutEffect(() => {
				if (query?.house || query?.settlement || query?.street) {
					setSettlementFieldError(false);
					setStreetFieldError(false);
					setHouseFieldError(false);
				}
			}, [query]);

			useLayoutEffect(() => {
				if (
					coordinates &&
					isNumber(coordinates.lat) &&
					isNumber(coordinates.lng)
				) {
					setCoordinatesFieldError(false);
				}
			}, [coordinates]);

			controller.setContext({
				rootRef,
				settlementQuery,
				streetQuery,
				houseQuery,
				coordinates,

				setSettlementFieldError,
				setStreetFieldError,
				setHouseFieldError,
				setCoordinatesFieldError,
			});

			return (
				<>
					<Column ref={rootRef} gaps="8px* 10px">
						<Row align="center" sizes="20px! 1fr" gaps="16px 8px*">
							<Icon
								id="city"
								size={[20, 20]}
								colors={[theme.colors.primary]}
							/>
							<Settlement
								value={settlementQuery}
								language={language}
								autoResolve={autoResolve}
								error={settlementFieldError}
								onChange={settlementOnChange}
								onSelectObject={settlementOnSelectObject}
							/>
						</Row>
						<Row
							align="center"
							sizes="20px! 1fr 180px!"
							gaps="16px 8px*"
						>
							<Icon
								id="marker-on-map"
								size={[20, 20]}
								colors={[theme.colors.primary]}
							/>
							<Street
								value={streetQuery}
								settlementObject={settlementObject}
								language={language}
								autoResolve={autoResolve}
								error={streetFieldError}
								onChange={streetOnChange}
								onSelectObject={streetOnSelectObject}
							/>
							<House
								value={houseQuery}
								streetObject={streetObject}
								autoResolve={autoResolve}
								error={houseFieldError}
								onChange={houseOnChange}
								onChangeFocused={setHouseFieldFocused}
							/>
						</Row>
						<Row align="center" sizes="20px! 1fr" gaps="16px">
							<Icon
								id="compass"
								size={[20, 20]}
								colors={[theme.colors.primary]}
							/>
							<Coordinates
								value={coordinates}
								error={coordinatesFieldError}
								onChange={({ lat, lng }) => {
									if (isNumber(lat) && isNumber(lng)) {
										setCoordinatesFieldError(false);

										setCoordinates({ lat, lng });

										internalReveal({ lat, lng });
									}
								}}
							/>
						</Row>
					</Column>
				</>
			);
		},
	),
	InternalController,
);

declare namespace PointForm {
	interface Settlement {
		countryCode: string;
		country: string;
		settlementType: string;
		settlement: string;
		district: string;
		region: string;
		coordinates: LatLngLiteral;
	}

	interface Street {
		streetType: string;
		street: string;
		houses: House[];
		coordinates: LatLngLiteral;
	}

	type House = string;

	interface Query {
		settlement: string;
		street: string;
		house: string;
	}

	interface Value {
		coordinates?: LatLngLiteral;
		address?: Partial<Address>;
		query?: Query;
	}

	interface PropsBase {
		language: Language;

		autoResolve: boolean;
	}

	interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default PointForm;
