import React, { memo, useCallback, useLayoutEffect, useRef } from "react";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import { OrderFormProps } from "../../types/FormProps";
import { StyledTextBox } from "../styled";

const StyledOrderNodes = styled.div`
	height: clamp(27px, 3.7vh, 32px);
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
`;

const OrderNodes: React.FC<OrderNodes.Props> = ({ form, disabled }) => {
	const { t } = useTranslation();
	const refInputDriverNode = useRef<HTMLInputElement | null>(null);
	const refInputNode = useRef<HTMLInputElement | null>(null);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const onRenderClientNote = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "clientNote"> }) => {
			const placeholder =
				t([`orderPage.order_form.client_note`, "Notes"]) || "";

			return (
				<StyledTextBox
					autoComplete="off"
					inputRef={refInputNode}
					tabIndex={-1}
					type={"string"}
					disabled={disabled}
					placeholder={placeholder}
					value={String(field.value ?? "")}
					onChange={(value) => {
						field.onChange(value);
					}}
				/>
			);
		},
		[t, disabled],
	);

	const onRenderDriverNote = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "driverNote"> }) => {
			const placeholder =
				t([
					`orderPage.order_form.driver_note`,
					"Notes for the driver...",
				]) || "";

			const elem = refInputDriverNode.current;
			if (elem) elem.setAttribute("id", "driver-node-id");
			return (
				<StyledTextBox
					autoComplete="off"
					inputRef={refInputDriverNode}
					tabIndex={11}
					type={"string"}
					disabled={disabled}
					placeholder={placeholder}
					value={String(field.value ?? "")}
					onChange={(value) => {
						field.onChange(value);
					}}
				/>
			);
		},
		[t, disabled],
	);

	const eventKeydown = useCallback(
		(event: KeyboardEvent) => {
			const keyEvent = event?.key;
			const target = event.target as HTMLInputElement;

			if (
				keyEvent === "Tab" ||
				keyEvent === "Enter" ||
				keyEvent === "ArrowRight"
			) {
				if (keyEvent === "Tab" || keyEvent === "Enter") {
					handleSave();
				}

				const nextElem = document.getElementById("order-save-id");
				if (nextElem) {
					nextElem.focus();
					target.blur();
				}

				event.stopPropagation();
				event.preventDefault();
			}

			if (keyEvent === "ArrowLeft") {
				const nextElem = document.getElementById("2entrance");
				if (nextElem) nextElem.focus();
			}
		},
		[handleSave],
	);

	const eventKeydownNote = useCallback(
		(event: KeyboardEvent) => {
			const keyEvent = event?.key;
			if (keyEvent === "Tab" || keyEvent === "Enter") {
				handleSave();
				event.stopPropagation();
				event.preventDefault();
			}
		},
		[handleSave],
	);

	useLayoutEffect(() => {
		const elem = refInputNode.current;
		elem?.addEventListener("keydown", eventKeydownNote);
		return () => {
			elem?.removeEventListener("keydown", eventKeydownNote);
		};
	}, [eventKeydownNote, refInputNode]);

	useLayoutEffect(() => {
		const elem = refInputDriverNode.current;
		elem?.addEventListener("keydown", eventKeydown);
		return () => {
			elem?.removeEventListener("keydown", eventKeydown);
		};
	}, [eventKeydown, refInputDriverNode]);

	useKeyBindSettings("orderModalFocusDriverNodeInput", (event) => {
		const elem = refInputDriverNode?.current;
		if (elem) {
			elem.focus();
			elem.select();
		}
		event.preventDefault();
		event.stopPropagation();
	});

	useKeyBindSettings("orderModalFocusNodeInput", (event) => {
		const elem = refInputNode?.current;
		if (elem) {
			elem.focus();
			elem.select();
		}
		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<StyledOrderNodes>
			<Controller
				name="clientNote"
				control={form.control}
				render={onRenderClientNote}
			/>
			<Controller
				name="driverNote"
				control={form.control}
				render={onRenderDriverNote}
			/>
		</StyledOrderNodes>
	);
};

declare namespace OrderNodes {
	interface Props extends OrderFormProps {}
}

export const OrderNodesMemo = memo(OrderNodes);
export default OrderNodes;
