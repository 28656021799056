import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const DispatcherCallSignCellContent: React.FC<
	DispatcherCallSignCellContent.Props
> = ({ item }) => {
	const { t } = useTranslation();
	const content = useMemo(() => {
		const data = item.closedOrderComments?.[0];

		if (data?.dispatcher) {
			return data?.dispatcher?.callSign || "";
		}
		if (data?.executor) {
			return data?.executor?.callSign || "";
		}

		if (data?.customer) {
			// const name = data?.customer?.person?.name || "";
			// const surname = data?.customer?.person?.surname || "";
			// const fatherName = data?.customer?.person?.fatherName || "";
			// const fullName = `${surname || ""} ${name || ""} ${
			// 	fatherName || ""
			// }`.trim();
			return t("client") || "";
		}

		return "";
	}, [item?.closedOrderComments, t]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace DispatcherCallSignCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default DispatcherCallSignCellContent;
