import React, { useCallback, useMemo, useState } from "react";
import { Button, Icon, Row } from "uikit";

import { Filter, ModalFilter } from "../..";

import DateRange from "./components/DateRange";
import Search from "./components/Search";
import FilterComponent from "./components/Filter";

const Header: React.FC<Header.Props> = ({
	filter,
	setFilter,
	query,
	setQuery,
	modalFilter,
	setModalFilter,
}) => {
	const [booferDate, setBooferDate] = useState({
		dateFrom: filter.dateRange?.dateFrom,
		dateTo: filter.dateRange?.dateTo,
	});

	const applyDateRange = useCallback(() => {
		setFilter((prev) => ({
			...prev,
			dateRange: {
				dateFrom: booferDate?.dateFrom,
				dateTo: booferDate?.dateTo,
			},
		}));
	}, [booferDate.dateFrom, booferDate.dateTo, setFilter]);

	const dateRangeHasChanges = useMemo(() => {
		if (
			filter.dateRange?.dateFrom?.getTime() !==
				booferDate?.dateFrom?.getTime() ||
			filter.dateRange?.dateTo?.getTime() !==
				booferDate?.dateTo?.getTime()
		) {
			return true;
		}
		return false;
	}, [
		booferDate.dateFrom,
		booferDate.dateTo,
		filter.dateRange?.dateFrom,
		filter.dateRange?.dateTo,
	]);

	return (
		<Row style={{ padding: "15px 15px 5px 15px" }} justify="space-between">
			<Row></Row>
			<Row gaps="5px*" justify="center" align="center">
				<DateRange filter={booferDate} setFilter={setBooferDate} />
				<Button.Button
					variant="primary"
					focused={false}
					disabled={!dateRangeHasChanges}
					icon={<Icon id="search" size={20} />}
					onClick={applyDateRange}
				/>
			</Row>
			<Row gaps="5px*">
				<Search value={query} onChange={setQuery} />
				<FilterComponent
					filter={modalFilter}
					setFilter={setModalFilter}
				/>
			</Row>
		</Row>
	);
};

declare namespace Header {
	interface Props {
		query: string;
		setQuery: React.Dispatch<React.SetStateAction<string>>;
		filter: Filter;
		setFilter: React.Dispatch<React.SetStateAction<Filter>>;
		modalFilter: ModalFilter;
		setModalFilter: React.Dispatch<React.SetStateAction<ModalFilter>>;
	}
}

export default Header;
