import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	OrderCreatedSource,
	orderSourceOptions,
} from "../../../../../../../../../../../../../../../../types/OrderCreatedSource";
import tPath from "../../../../../../../../../../constants/tPath";

const OrderSource: React.FC<OrderSource.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const sources = valueEditor.useGetter("sources");
	const setSources = valueEditor.useSetter("sources");
	const handleSourcesChange = useCallback(
		(newSources) => {
			setSources(newSources);
		},
		[setSources],
	);

	const tOptions = useMemo(
		() =>
			orderSourceOptions
				.filter((value) => value.label !== "website")
				.map((o) => ({
					...o,
					label: t(
						`${tPath}.modal.tabs.generalSurcharges.orderSource.options.${o.key}`,
					),
				})),
		[t],
	);

	return (
		<Row align="center" gaps="10px*" sizes="12% 1fr">
			<div>
				<CheckBox
					label={
						t(
							`${tPath}.modal.tabs.generalSurcharges.orderSource.title`,
						) || ""
					}
					value={active}
					onChange={setActive}
				/>
			</div>
			<MultiSelect
				disabled={!active}
				placeholder={
					t(
						`${tPath}.modal.tabs.generalSurcharges.orderSource.placeholder`,
					) || ""
				}
				options={tOptions}
				value={sources}
				onChange={handleSourcesChange}
			/>
		</Row>
	);
});

declare namespace OrderSource {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		sources: OrderCreatedSource[];
	}
}

export default OrderSource;
