/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo } from "react";
import { Column, react, useRefWithSetter } from "uikit";
import Language from "../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../hooks/useObjectEditor";
import InternalController from "./Controller";
import TabRoot from "../TabRoot";
import NameAndCompanyData from "./components/NameAndCompanyData";
import AuthData from "./components/AuthData";
import TonAndNpiData from "./components/TonAndNpiData";
import CheckMessageData from "./components/CheckMessageData";
import CheckConnectData from "./components/CheckConnectData";
import AnotherData from "./components/AnotherData";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, value, visible, language, onChange, validation }) => {
			const [nameAndCompanyDataRef, setNameAndCompanyDataRef] =
				useRefWithSetter<NameAndCompanyData.Ref | null>(null);
			const [authDataRef, setAuthDataRef] =
				useRefWithSetter<AuthData.Ref | null>(null);
			const [tonAndNpiDataRef, setTonAndNpiDataRef] =
				useRefWithSetter<TonAndNpiData.Ref | null>(null);
			const [checkMessageDataRef, setCheckMessageDataRef] =
				useRefWithSetter<CheckMessageData.Ref | null>(null);
			const [checkConnectDataRef, setCheckConnectDataRef] =
				useRefWithSetter<CheckConnectData.Ref | null>(null);
			const [anotherDataRef, setAnotherDataRef] =
				useRefWithSetter<AnotherData.Ref | null>(null);

			controller.setContext({
				anotherDataRef,
				nameAndCompanyDataRef,
				authDataRef,
				tonAndNpiDataRef,
				checkMessageDataRef,
				checkConnectDataRef,
			});

			const valueEditor = useObjectEditor(value, onChange);

			const nameAndCompanyDataValue = valueEditor.usePicker(
				["name", "taxiServiceIds", "smsProviderToTaxiServices"],
				"json",
			);
			const anotherDataValue = valueEditor.usePicker(
				["addPlus", "isMaxSmsPerSecond", "maxSmsPerSecond"],
				"json",
			);
			const authDataValue = valueEditor.usePicker(
				["host", "port", "login", "password"],
				"json",
			);
			const tonAndNpiValue = valueEditor.usePicker(
				[
					"isSystemType",
					"systemType",
					"sourceAddrTon",
					"sourceAddrNpi",
					"destAddrTon",
					"destAddrNpi",
					"alphaName",
				],
				"json",
			);
			const checkMessageDataValue = valueEditor.usePicker(
				["checkSmsStatusMode", "activeQueryInterval"],
				"json",
			);
			const checkConnectDataValue = valueEditor.usePicker(
				["isEnquireLink", "enquireLink"],
				"json",
			);

			const assignValue = valueEditor.useAssigner();

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						// sizes="auto!*3 1fr"
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<NameAndCompanyData
							onChange={assignValue}
							value={nameAndCompanyDataValue}
							ref={setNameAndCompanyDataRef}
							validation={validation}
							language={language}
						/>
						<AuthData
							onChange={assignValue}
							value={authDataValue}
							ref={setAuthDataRef}
							validation={validation}
						/>
						<TonAndNpiData
							onChange={assignValue}
							value={tonAndNpiValue}
							ref={setTonAndNpiDataRef}
							validation={validation}
						/>
						<CheckMessageData
							onChange={assignValue}
							value={checkMessageDataValue}
							ref={setCheckMessageDataRef}
						/>
						<CheckConnectData
							onChange={assignValue}
							value={checkConnectDataValue}
							ref={setCheckConnectDataRef}
						/>
						<AnotherData
							onChange={assignValue}
							value={anotherDataValue}
							ref={setAnotherDataRef}
						/>
						<br />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value
		extends AnotherData.Value,
			NameAndCompanyData.Value,
			AuthData.Value,
			TonAndNpiData.Value,
			CheckMessageData.Value,
			CheckConnectData.Value {}

	interface PropsBase {
		value: Value;

		visible: boolean;
		language: Language;
		validation: any;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
