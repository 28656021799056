import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Icon, InputGroup, theme } from "uikit";
import styled from "styled-components";
import Draggable from "react-draggable";
import { uniq } from "lodash";

import useKeyBind from "../../../../../../../../../../../../../../../../hooks/useKeyBind";
import Modal from "../../../../../../../../../../../../../../../../components/Modal";
import {
	Divider,
	StyledColumn,
	StyledP,
	StyledRow,
	StyledSpan,
} from "../../../../../../../../../../../../../../../../components/common";
import {
	Footer,
	FooterMemo,
	HeaderMemo,
} from "../../../../../../../../../../../../../../../../components/Orders";
import SearchTextBox from "../../../../../../../../../../../../../../../../components/SearchTextBox";
import SearchIconBorders from "../../../../../../../../../../../../../../../../components/SearchIconBorders";

const Group = styled(InputGroup.InputGroup)`
	width: 100%;
`;

const Chips = styled(StyledP)`
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 4px 8px;
	border-radius: 4px;
	background: #f5f5f5;
	font-family: Lato;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
`;

const ClearChips = styled(Chips)`
	color: #f83528;
	background: transparent;
	cursor: pointer;
`;

const List = styled(StyledColumn)`
	width: 100%;
	max-height: 435px;
	overflow-y: scroll;
	${theme.scrollbar}

	& > *:not(:last-child) {
		border-bottom: 1px solid #c8cfd6;
	}
`;

const Item = styled(StyledP)<{
	active: boolean;
}>`
	padding: 16px 5px;
	gap: 16px;
	cursor: pointer;
	color: #21333f;
	background-color: ${(props) => (props.active ? "#ebebeb" : "#ffffff")};

	&:hover {
		background-color: #ebebeb;
	}
`;

const ListSelect: React.FC<ListSelect.Props> = ({
	value,
	options,
	title,
	required = false,
	children,
	footer,
	header,
	hideSearch = false,
	headerDivider,
	footerDivider,
	onSubmit,
	onClose,
}) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLInputElement | null>(null);
	const [query, setQuery] = useState("");
	const [selected, setSelected] = useState<ListSelect.Options>(value || []);
	const [filteredOptions, setOptions] = useState<ListSelect.Options>([]);
	const [activeOption, setActiveOption] = useState(-1);
	const [warning, setWarning] = useState(false);

	const actualOptions = query.length ? filteredOptions : options;

	const handleSelect = useCallback(
		(item) => {
			selected.find((option) => option.value === item.value)
				? setSelected(selected.filter((v) => v.value !== item.value))
				: setSelected([...selected, item]);
		},
		[selected],
	);

	useKeyBind(["Escape"], (e) => {
		onClose();
		e.preventDefault();
		e.stopPropagation();
	});

	useEffect(() => {
		const handleKeyboardEvent = (event) => {
			const isArrows = ["arrowup", "arrowdown"].includes(
				event.key.toLowerCase(),
			);

			if (event.key === "ArrowUp") {
				setActiveOption((prev) =>
					prev - 1 < 0 ? actualOptions.length - 1 : prev - 1,
				);
			}
			if (event.key === "ArrowDown") {
				setActiveOption((prev) =>
					prev + 1 > actualOptions.length - 1 ? 0 : prev + 1,
				);
			}
			if (isArrows) {
				event.stopPropagation();
			}
		};
		document.addEventListener("keydown", handleKeyboardEvent, true);
		return function cleanup() {
			document.removeEventListener("keydown", handleKeyboardEvent, true);
		};
	}, [actualOptions.length]);

	useKeyBind(["ShiftLeft", "Equal"], () => {
		if (activeOption !== -1) {
			setSelected(uniq([...selected, actualOptions[activeOption]]));
			setQuery("");
		}
	});
	useKeyBind(["NumpadAdd"], () => {
		if (activeOption !== -1) {
			setSelected(uniq([...selected, actualOptions[activeOption]]));
			setQuery("");
		}
	});
	useKeyBind(["Minus"], () => {
		if (activeOption !== -1) {
			setSelected(
				selected.filter(
					(v) => v.value !== actualOptions[activeOption].value,
				),
			);
			setQuery("");
		}
	});

	const handleSubmit = useCallback(() => {
		if (selected.length) {
			onSubmit(selected);
		} else if (required) {
			setWarning(true);
		} else {
			onSubmit([]);
		}
	}, [onSubmit, selected, required]);

	useKeyBind(["Enter"], () => handleSubmit());

	const getOptions = useCallback(
		(value: string) =>
			options.filter((option) =>
				option.name
					.toLocaleLowerCase()
					.includes(value.toLocaleLowerCase()),
			),
		[options],
	);

	const onChangeSearchTextBox = useCallback(
		(value: string) => {
			const isCheck =
				!value.trim().includes("+") && !value.trim().includes("-");

			if (isCheck) {
				setQuery(value);
				setOptions(getOptions(value));
				setActiveOption(0);
			}
		},
		[getOptions],
	);

	const content = useMemo(
		() => (
			<StyledColumn
				gap="15px"
				alignItems="center"
				w="732px"
				h="537px"
				bgC="#ffffff"
			>
				{!hideSearch && (
					<StyledRow w="100%" p="8px 24px">
						<Group sizes="32px auto">
							<SearchIconBorders>
								<Icon
									id="search"
									size={16}
									colors={["#5e6b73"]}
								/>
							</SearchIconBorders>
							<SearchTextBox
								inputRef={(input) => {
									if (ref.current || !input) return;
									input.focus();
								}}
								style={{ width: "100%" }}
								value={query}
								onChange={onChangeSearchTextBox}
								placeholder={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.typesSelector.listSelect.str200",
									) ?? ""
								}
							/>
						</Group>
					</StyledRow>
				)}

				<StyledRow
					gap="8px"
					w="100%"
					h={{ min: "24px", max: "56px" }}
					flex={{ wrap: "wrap" }}
					justify="start"
					p="0 24px"
					overY="scroll"
				>
					{selected.map((item) => (
						<Chips key={item.value}>
							{item.name}
							<StyledSpan
								cursor="pointer"
								onClick={() =>
									setSelected(
										selected.filter(
											(v) => v.value !== item.value,
										),
									)
								}
							>
								<Icon
									id="close"
									size={8}
									colors={[theme.colors.disabled_text]}
								/>
							</StyledSpan>
						</Chips>
					))}
					{Boolean(selected.length) && (
						<ClearChips onClick={() => setSelected([])}>
							<Icon
								id="close"
								size={8}
								colors={[theme.colors.negative]}
							/>
							{t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.typesSelector.listSelect.str150",
							) ?? ""}
						</ClearChips>
					)}
				</StyledRow>
				<Divider side="bottom" />
				<List justify="start" p="15px 24px">
					{actualOptions.map((item, i) => (
						<Item
							active={activeOption === i}
							key={item.value}
							onClick={() => {
								handleSelect(item);
								setWarning(false);
							}}
						>
							<CheckBoxWithContent
								gap="10px"
								value={Boolean(
									selected.find(
										(option) => option.value === item.value,
									),
								)}
								onChange={(status) => {
									!status
										? setSelected(
												selected.filter(
													(v) =>
														v.value !== item.value,
												),
										  )
										: setSelected([...selected, item]);
									setWarning(false);
								}}
							>
								{item.name}
							</CheckBoxWithContent>
						</Item>
					))}
				</List>
				{warning && (
					<StyledSpan colors="#F83528">
						{t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.typesSelector.listSelect.str100",
						) ?? ""}
					</StyledSpan>
				)}
				{children}
			</StyledColumn>
		),
		[
			activeOption,
			actualOptions,
			children,
			handleSelect,
			hideSearch,
			onChangeSearchTextBox,
			query,
			selected,
			warning,
			t,
		],
	);

	return (
		<Modal>
			<Draggable handle=".handle">
				<StyledColumn br="10px" shadow="0px 0px 10px 2px gray">
					<HeaderMemo
						title={title}
						onClose={onClose}
						divider={headerDivider}
					>
						{header}
					</HeaderMemo>

					{content}

					<FooterMemo
						onSave={() => handleSubmit()}
						onClose={onClose}
						divider={footerDivider}
					>
						{footer}
					</FooterMemo>
				</StyledColumn>
			</Draggable>
		</Modal>
	);
};

declare namespace ListSelect {
	interface Option {
		name: string;
		value: number;
	}

	type Options = Option[];

	interface Props extends PropsWithChildren {
		value: Options | undefined;
		options: Options;
		title?: string;
		required?: boolean;
		onSubmit: (value: Options) => void;
		onSave?: Footer.Props["onSave"];
		onClose: Footer.Props["onClose"];
		footer?: React.ReactNode;
		footerDivider?: boolean;
		headerDivider?: boolean;
		hideSearch?: boolean;
		header?: React.ReactNode;
	}
}

export const ListSelectMemo = memo(ListSelect);
export default ListSelect;
