import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "uikit";

import PriceZoneChangeDistanceThreshold from "../../../../../Modal/components/Content/components/PriceZoneChangeDistanceThreshold";

const PriceZoneChangeDistanceOutput: React.FC<PriceZoneChangeDistanceOutput.Props> =
	memo(({ value }) => {
		const { t } = useTranslation();

		return value.active ? (
			<>
				<Row gaps="3px">
					{value.value}
					<span>{t(`units.km`) ?? "km"}.</span>
				</Row>
				<span>/</span>
			</>
		) : null;
	});

declare namespace PriceZoneChangeDistanceOutput {
	interface Props {
		value: PriceZoneChangeDistanceThreshold.Value;
	}
}

export default PriceZoneChangeDistanceOutput;
