/* eslint-disable no-param-reassign */

import {
	CaseReducer,
	combineReducers,
	createSlice,
	PayloadAction,
} from "@reduxjs/toolkit";
import { LatLngLiteral } from "leaflet";

type OrderTab = "closed" | "preliminary" | "all" | "executable" | "live";

namespace Widgets {
	export namespace Map {
		export namespace Executors {
			export interface Filters {
				onActiveOrder: boolean;

				companyIds: number[] | ["all"];
				taxiServiceIds: number[] | ["all"];

				statuses: Filters.Status[];
				shifts: Filters.Shift[];
			}

			export namespace Filters {
				export type Status =
					| "free"
					| "busy"
					| "lunch"
					| "home"
					| "onOrder"
					| "ownOrder"
					| "closedByOffice"
					| "autoClose";

				export type Shift = "open" | "closed";
			}
		}

		export interface Executors {
			defaultFilters: Executors.Filters;
			filtersByOrderTab: Record<OrderTab, Executors.Filters>;
		}

		export interface Persistent {
			executors: Map.Executors;
		}

		export interface Inconstant {
			center?: LatLngLiteral;
			zoom: number;
		}
	}

	export interface Persistent {
		map: Map.Persistent;
	}

	export interface Inconstant {
		map: Map.Inconstant;
	}
}

namespace State {
	export interface Persistent {
		widgets: Widgets.Persistent;
	}

	export interface Inconstant {
		widgets: Widgets.Inconstant;
	}
}

type PersistentReducer<Payload> = CaseReducer<
	State.Persistent,
	PayloadAction<Payload>
>;

type InconstantReducer<Payload> = CaseReducer<
	State.Inconstant,
	PayloadAction<Payload>
>;

const setMapWidgetOrderTabExecutorFilters: PersistentReducer<{
	tab: OrderTab;
	filters: Widgets.Map.Executors.Filters;
}> = (state, { payload }) => {
	state.widgets.map.executors.filtersByOrderTab[payload.tab] =
		payload.filters;
};

const setMapWidgetCenter: InconstantReducer<LatLngLiteral | undefined> = (
	state,
	{ payload },
) => {
	state.widgets.map.center = payload;
};

const setMapWidgetZoom: InconstantReducer<number> = (state, { payload }) => {
	state.widgets.map.zoom = payload;
};

const defaultMapWidgetExecutorsFilters: Widgets.Map.Executors.Filters = {
	onActiveOrder: false,
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	statuses: [
		"free",
		"busy",
		"lunch",
		"home",
		"onOrder",
		"ownOrder",
		"closedByOffice",
		"autoClose",
	],
	shifts: ["open", "closed"],
};

const persistentInitialState: State.Persistent = {
	widgets: {
		map: {
			executors: {
				defaultFilters: defaultMapWidgetExecutorsFilters,
				filtersByOrderTab: {
					closed: defaultMapWidgetExecutorsFilters,
					preliminary: defaultMapWidgetExecutorsFilters,
					all: defaultMapWidgetExecutorsFilters,
					executable: defaultMapWidgetExecutorsFilters,
					live: defaultMapWidgetExecutorsFilters,
				},
			},
		},
	},
};

const inconstantInitialState: State.Inconstant = {
	widgets: {
		map: {
			center: undefined,
			zoom: 11,
		},
	},
};

const slices = {
	persistent: createSlice({
		name: "persistentOrderPage",
		initialState: persistentInitialState,
		reducers: {
			setMapWidgetOrderTabExecutorFilters,
		},
	}),

	inconstant: createSlice({
		name: "inconstantOrderPage",
		initialState: inconstantInitialState,
		reducers: {
			setMapWidgetCenter,
			setMapWidgetZoom,
		},
	}),
};

export default {
	reducer: combineReducers({
		persistent: slices.persistent.reducer,
		inconstant: slices.inconstant.reducer,
	}),
	...slices,
};
