import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import {
	useArchiveSwitcher,
	useArchiveOrders,
	useOrderUpdate,
} from "../../../../../../hooks";
import DeleteModal from "../../../../../../../../../../components/DeleteModal";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

const LeftPart: React.FC<LeftPart.Props> = (): JSX.Element => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.ORDERS];

		const retval = {
			edit: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.CONTROL_BUTTON_EDIT,
				),
				personalRole,
			),
			revert: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.REVERT_ORDER_TO_ARCHIVE,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const {
		setInfoModalToggle,
		orderToggles: { isInfoModal },
	} = useArchiveSwitcher();

	const {
		orders: { activeOrderId, order },
	} = useArchiveOrders();
	const { revertActiveOrder } = useOrderUpdate();

	const [confirmationModal, setConfirmationModal] = useState(false);

	const infoModalToggle = useCallback(() => {
		if (isInfoModal) setInfoModalToggle("off");
		else setInfoModalToggle("on");
	}, [isInfoModal, setInfoModalToggle]);

	const handleRevertSubmit = useCallback(() => {
		if (!activeOrderId) return;
		revertActiveOrder(activeOrderId);
		setConfirmationModal(false);
	}, [activeOrderId, revertActiveOrder]);

	const handleRevertClose = useCallback(() => {
		setConfirmationModal(false);
	}, []);
	const handleRevertClick = useCallback(() => {
		setConfirmationModal(true);
	}, []);

	const isRevertOrder = useMemo<boolean>(() => {
		if (!order) return false;

		if (
			order.status === "closed" &&
			!order?.executorToOrder?.length &&
			activeOrderId
		) {
			return false;
		}

		if (order.status === "closed" && activeOrderId) {
			return true;
		}
		if (!order?.executingStage && order?.closedAt && activeOrderId) {
			return true;
		}

		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.executingStage, order?.closedAt, activeOrderId]);

	return (
		<>
			{AccessBtnGroup.edit && (
				<Button.Button
					disabled={!activeOrderId}
					variant={"secondary"}
					onClick={infoModalToggle}
					focused={false}
					icon={
						<Icon
							id="pencil"
							size={20}
							style={{
								fill:
									!isInfoModal && activeOrderId
										? "#03A9F4"
										: "gray",
							}}
						/>
					}
				/>
			)}

			{AccessBtnGroup.revert && (
				<Button.Button
					disabled={!isRevertOrder}
					variant="secondary"
					focused={false}
					icon={
						<Icon
							id="return-back"
							size={20}
							style={{
								fill: isRevertOrder ? "#03A9F4" : "gray",
							}}
						/>
					}
					onClick={handleRevertClick}
				/>
			)}
			{confirmationModal && (
				<DeleteModal
					label={
						t(
							`orderPage.revert_modal.title`,
							"Do you really want to return the order to the executors?",
						) as string
					}
					onConfirm={handleRevertSubmit}
					onCancel={handleRevertClose}
				/>
			)}
			{/* <Button.Button
				variant="secondary"
				focused={false}
				icon={<Icon id="refresh" size={20} />}
				onClick={throttledRefresh}
			/> */}
		</>
	);
};

export const LeftPartMemo = memo(LeftPart);

declare namespace LeftPart {
	interface Props {}
}

export default LeftPart;
