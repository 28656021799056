import React, { Dispatch, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../../../../../../services/Language";
import { StyledColumn } from "../../../../../../../../../../../../components/common";
import Form from "../../components/Form";
import Title from "../../components/Title";

const Main = forwardRef<Main.Controller, Main.Props>(
	({ value, language, onChange }, ref) => {
		const { t } = useTranslation();

		const translateTexts: Record<string, string> = useMemo(
			() => ({
				edit:
					t(
						"pages.preferencesPages.screenDirectory.sectors.editModal.content.tabs.main.edit",
					) ?? "",
				add:
					t(
						"pages.preferencesPages.screenDirectory.sectors.editModal.content.tabs.main.add",
					) ?? "",
				sector:
					t(
						"pages.preferencesPages.screenDirectory.sectors.editModal.content.tabs.main.sector",
					) ?? "",
			}),
			[t],
		);

		return (
			<StyledColumn w="100%" h="100%" gap="20px" p="20px">
				<Title>
					{value.id ? translateTexts.edit : translateTexts.add}{" "}
					{translateTexts.sector}
				</Title>
				<Form
					ref={ref}
					value={value}
					language={language}
					onChange={onChange}
				/>
			</StyledColumn>
		);
	},
);

declare namespace Main {
	interface Value extends Form.Value {
		id?: number;
	}

	interface Props {
		value: Value;

		language: Language;

		onChange: Dispatch<Value>;
	}

	type Controller = Form.Controller;
}

export default Main;
