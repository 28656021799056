import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CheckBox, theme } from "uikit";
import styled from "styled-components";

import orderPageFilters from "../../../../../../../redux/reducers/OrdersPage/Filters";
import { useTypedSelector } from "../../../../../../../redux/store";
import useKeyBind from "../../../../../../../hooks/useKeyBind";
import { KEYBOARD_CODES } from "../../../../../../../constants/business";
import { StyledRow } from "../../../../../../common";
import CompactTable from "../../../../../../CompactTable";
import { ChatDispatcherPhone, ChatExecutorPhone } from "../../../../types";

import Avatar from "./components/Avatar";

const TextContainer = styled(StyledRow)`
	padding: 1px 6px;
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const Root = styled.div<{ selected: boolean }>`
	border: ${({ selected }) =>
		selected ? `1px solid ${theme.colors.accent}` : "none"};
	border-radius: 3px;
`;

const CheckBoxContainer = styled.div`
	position: relative;
`;

const Table: React.FC<Table.Props> = (props) => {
	const { data, onSelect, onSelectKeyboard, toggleAll, tabIndex, type } =
		props;
	const dispatch = useDispatch();

	const { Column, HeaderCell, Cell } = CompactTable;

	const { booferSelectedUsers, userModalData } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);

	const { order } = useTypedSelector(
		(state) => state.orderPageFilters.chats.filters,
	);

	const [selectedUserIndex, setSelectedUserIndex] = useState<number>(-1);

	useKeyBind([KEYBOARD_CODES.ARROW_UP], () => {
		if (tabIndex === 3) {
			if (selectedUserIndex === 0) {
				setSelectedUserIndex(data.length - 1);
			} else {
				setSelectedUserIndex((prev) => prev - 1);
			}
		}
	});

	useKeyBind([KEYBOARD_CODES.ARROW_DOWN], () => {
		if (tabIndex === 3) {
			if (selectedUserIndex === data.length - 1) {
				setSelectedUserIndex(0);
			} else {
				setSelectedUserIndex((prev) => prev + 1);
			}
		}
	});

	useKeyBind([KEYBOARD_CODES.NUMPAD_ADD], () => {
		if (!data.length) return;
		if (tabIndex === 2) {
			toggleAll("select");
			return;
		}
		if (tabIndex === 3) {
			const user = data[selectedUserIndex];
			onSelectKeyboard(user.id, "select");
		}
	});

	useKeyBind([KEYBOARD_CODES.MINUS], () => {
		if (!data.length) return;
		if (tabIndex === 2) {
			toggleAll("unselect");
			return;
		}
		if (tabIndex === 3) {
			const user = data[selectedUserIndex];
			onSelectKeyboard(user.id, "unselect");
		}
	});

	const tableRowClassName = useCallback(
		(rowData) => {
			if (data[selectedUserIndex]?.id === rowData?.id) return "founded";
			if (
				type === "dispatchers" &&
				booferSelectedUsers.dispatchers.some(
					(dispatcher) => dispatcher.id === rowData?.id,
				)
			)
				return "selected";
			if (
				type === "executors" &&
				booferSelectedUsers.executors.some(
					(executor) => executor.id === rowData?.id,
				)
			)
				return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[
			booferSelectedUsers.dispatchers,
			booferSelectedUsers.executors,
			data,
			selectedUserIndex,
			type,
		],
	);

	const clearSelectedUser = useCallback(() => {
		setSelectedUserIndex(-1);
	}, []);

	const checkoxValue = useMemo(() => {
		// if (
		// 	!userModalData.dispatchers.length &&
		// 	!booferSelectedUsers.dispatchers.length
		// )
		// 	return false;

		if (
			type === "dispatchers" &&
			userModalData.dispatchers.length ===
				booferSelectedUsers.dispatchers.length
		)
			return true;

		if (
			type === "executors" &&
			userModalData.executors.length ===
				booferSelectedUsers.executors.length
		)
			return true;
		return false;
	}, [
		booferSelectedUsers.dispatchers.length,
		booferSelectedUsers.executors.length,
		type,
		userModalData.dispatchers.length,
		userModalData.executors.length,
	]);

	const toggleCheckbox = useCallback(() => {
		if (!data.length) return;
		toggleAll();
	}, [data.length, toggleAll]);

	const sortColumn = useCallback(
		(column, type) => {
			dispatch(orderPageFilters.actions.setChatsSort({ column, type }));
		},
		[dispatch],
	);

	const checkboxValue = useCallback(
		(id: number) => {
			if (type === "dispatchers") {
				return booferSelectedUsers.dispatchers.some(
					(dispatcher) => dispatcher.id === id,
				);
			}
			if (type === "executors") {
				return booferSelectedUsers.executors.some(
					(executor) => executor.id === id,
				);
			}
			return false;
		},
		[booferSelectedUsers.dispatchers, booferSelectedUsers.executors, type],
	);

	useEffect(() => {
		if (selectedUserIndex === -1 && tabIndex === 3) {
			setSelectedUserIndex(0);
		}
		if (tabIndex !== 3) {
			setSelectedUserIndex(-1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabIndex]);

	const { t } = useTranslation();
	return (
		<Root selected={tabIndex === 2 || tabIndex === 3}>
			<CompactTable
				fillHeight
				data={data}
				headerHeight={30}
				rowHeight={32}
				rowClassName={tableRowClassName}
				onClick={clearSelectedUser}
				sortColumn={order?.column}
				sortType={order?.type}
				onSortColumn={(column, type) => sortColumn(column, type)}
			>
				<Column width={30}>
					<HeaderCell onClick={toggleCheckbox}>
						<CheckBoxContainer>
							<CheckBox
								onChange={toggleCheckbox}
								value={checkoxValue}
							/>
							{/* {tabIndex === 2 && <CheckBox value={true} />} */}
						</CheckBoxContainer>
					</HeaderCell>
					<Cell fullText align="center">
						{(rowData) => (
							<TextContainer onClick={() => onSelect(rowData.id)}>
								<CheckBox
									onChange={() => onSelect(rowData.id)}
									value={checkboxValue(rowData.id)}
								/>
							</TextContainer>
						)}
					</Cell>
				</Column>
				<Column width={70} sortable>
					<HeaderCell>
						{t("orderPageWidgets.chat.usersModal.table.str100") ??
							""}
					</HeaderCell>
					<Cell fullText dataKey="callSign">
						{(rowData) => (
							<TextContainer onClick={() => onSelect(rowData.id)}>
								{rowData.alias}
							</TextContainer>
						)}
					</Cell>
				</Column>
				<Column width={105}>
					{/* // TODO add sortable. We need to add sorting, but it is broken on the backend. You need to wait until the backend gives the go-ahead */}
					<HeaderCell>
						{t("orderPageWidgets.chat.usersModal.table.str101") ??
							""}
					</HeaderCell>
					<Cell fullText dataKey="parkNumber">
						{(rowData) => (
							<TextContainer onClick={() => onSelect(rowData.id)}>
								{rowData.parkNumber}
							</TextContainer>
						)}
					</Cell>
				</Column>
				<Column flexGrow={1} sortable>
					<HeaderCell>
						{t("orderPageWidgets.chat.usersModal.table.str102") ??
							""}
					</HeaderCell>
					<Cell fullText dataKey="name">
						{(rowData) => (
							<TextContainer
								gap="6px"
								onClick={() => onSelect(rowData.id)}
							>
								<Avatar data={rowData as ChatDispatcherPhone} />
								{rowData.name}
							</TextContainer>
						)}
					</Cell>
				</Column>
				<Column width={150} sortable>
					<HeaderCell>
						{t("orderPageWidgets.chat.usersModal.table.str103") ??
							""}
					</HeaderCell>
					<Cell fullText dataKey="phone">
						{(rowData) => (
							<TextContainer onClick={() => onSelect(rowData.id)}>
								{rowData.phone}
							</TextContainer>
						)}
					</Cell>
				</Column>
			</CompactTable>
		</Root>
	);
};

declare namespace Table {
	interface Props {
		data: ChatExecutorPhone[];
		onSelect: (userId: number) => void;
		onSelectKeyboard: (
			userId: number,
			event: "select" | "unselect",
		) => void;
		toggleAll: (event?: "select" | "unselect") => void;
		tabIndex: number;
		type: string;
	}
}

export default Table;
