import React, {
	useEffect,
	useMemo,
	useCallback,
	memo,
	useState,
	useLayoutEffect,
} from "react";
import { LatLngLiteral } from "leaflet";

import {
	Archive,
	ValueToggle,
} from "../../../../redux/reducers/Archives/interface";
import { StyledRow } from "../../../common";

import { PlayerControl } from "../PlayerControl";

const PlayerBase: React.FC<PlayerBase.Props> = ({
	data,
	player,
	setPlayerToggle,
	setPlayerPosition,
	setPlayerMax,
	setPlayerNext,
	setPlayerDate,
	setPlayerSpeed,
	setPlayerDuration,
	setPlayerRun,
}) => {
	const [time, setTime] = useState(60_000);

	const { lookupMapKeys, pointLookupMap, size } =
		useMemo<PlayerBase.PlayerPoints>(() => {
			const lookup: PlayerBase.PlayerPoints["pointLookupMap"] = new Map();

			data.forEach(({ segments }) => {
				segments?.forEach((segment) => {
					segment?.forEach((item) => {
						if (item.date) lookup.set(item.date, item);
					});
				});
			});

			const keys = Array.from(lookup.keys()).sort();

			return {
				pointLookupMap: lookup,
				lookupMapKeys: keys,
				size: lookup.size,
			};
		}, [data]);

	useEffect(() => {
		if (setPlayerMax) setPlayerMax(size);
	}, [size, setPlayerMax]);

	const onChangeIsPlaying = useCallback(
		(payload: boolean) => {
			if (payload) setPlayerToggle("on");
			else setPlayerToggle("off");
		},
		[setPlayerToggle],
	);

	const onChangePosition = useCallback(
		(next: number) => {
			const nextKey = lookupMapKeys[next];
			const nextItem = pointLookupMap.get(nextKey);
			const prevKey = lookupMapKeys[next - 1];
			const prevItem = pointLookupMap.get(prevKey);

			if (nextItem && prevItem) {
				setPlayerPosition(prevItem.point);
				setPlayerNext(nextItem.point);
				setPlayerDate(nextItem.date);
				const durationTime = nextItem.date - prevItem.date;
				setTime(Math.max(durationTime, 0));
				setPlayerDuration(Math.max(durationTime / 1000, 0));
			}
		},

		[
			lookupMapKeys,
			pointLookupMap,
			setPlayerPosition,
			setPlayerNext,
			setPlayerDate,
			setPlayerDuration,
		],
	);

	useLayoutEffect(() => {
		if (!player.next || !player.position) {
			onChangePosition(1);
		}
	}, [onChangePosition, player.position, player.next]);

	const onChangeSpeed = useCallback(
		(payload: number) => {
			setPlayerSpeed(payload);
		},
		[setPlayerSpeed],
	);

	const onChangeRun = useCallback(
		(payload: boolean) => {
			if (payload) setPlayerRun("off");
			else setPlayerRun("on");
		},
		[setPlayerRun, player],
	);

	return (
		<StyledRow position="relative" w="100%">
			<StyledRow
				position="absolute"
				w="100%"
				zi={500}
				bgC="rgba( 0, 0, 0, .3 )"
			>
				<PlayerControl
					isPlaying={player.toggle}
					position={0}
					max={player.max}
					time={time}
					onChangePosition={onChangePosition}
					onChangeIsPlaying={onChangeIsPlaying}
					onChangeSpeed={onChangeSpeed}
					onChangeRun={onChangeRun}
				/>
			</StyledRow>
		</StyledRow>
	);
};

declare namespace PlayerBase {
	interface Props {
		data: Archive.LocationPlayer[];
		player: Archive.Player;
		setPlayerRun: (data: ValueToggle) => void;
		setPlayerToggle: (data: ValueToggle) => void;
		setPlayerPosition: (data: LatLngLiteral) => void;
		setPlayerMax: (data: number) => void;
		setPlayerNext: (data: LatLngLiteral) => void;
		setPlayerDate: (data: number) => void;
		setPlayerSpeed: (data: number) => void;
		setPlayerDuration: (data: number) => void;
	}

	interface PlayerPoints {
		pointLookupMap: Map<number, Archive.SegmentPlayer>;
		lookupMapKeys: number[];
		size: number;
	}
}

export const Player = memo(PlayerBase);
export default PlayerBase;
