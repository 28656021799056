/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../../../../components/LabeledField";
import SMSTemplate from "../../../../../../../../../../../../../services/SMSTemplate";
import { MessageTemplateTypes } from "../../../../../../../../../../../../../types/MessageTemplateEnums";

import StyledRow from "./components/StyledRow";
import Param from "./components/Param";

const SmsParams: React.FC<SmsParams.Props> = memo(({ type }) => {
	const { t } = useTranslation();

	const [delay, setDelay] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setDelay(false);
		}, 3000);
	}, []);

	const [smsParams, setSmsParams] = useState<string[] | []>([]);
	useEffect(() => {
		SMSTemplate.getParams().then((res) => {
			const allowedParams = res[type].createTemplateParam?.map((item) =>
				t(
					`settings.messages.templatesSms.modal.templateBody.messageParams.${item}`,
				),
			);
			allowedParams.push(
				t(
					"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str2",
				) ?? "",
				t(
					"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str200",
				) ?? "",
				t(
					"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str0",
				) ?? "",
				t(
					"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str1",
				) ?? "",
				t(
					"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str3",
				) ?? "",
			);
			setSmsParams(allowedParams);
		});
	}, [t, type]);

	const getParamStyle = (p) =>
		p ===
			(t(
				"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str0",
			) ?? "") ||
		p ===
			(t(
				"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str1",
			) ?? "") ||
		p ===
			(t(
				"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str2",
			) ?? "") ||
		p ===
			(t(
				"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str200",
			) ?? "") ||
		p ===
			(t(
				"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str3",
			) ?? "")
			? { fontStyle: "italic" }
			: {};

	return (
		<LabeledField
			label={`${
				t(
					"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str202",
				) ?? ""
			}:`}
		>
			<StyledRow wrap="wrap" align="center">
				{smsParams.length ? (
					smsParams.map((param) => (
						<Param key={param} style={getParamStyle(param)}>
							{param}
						</Param>
					))
				) : delay ? (
					<CircularProgress />
				) : (
					t(
						"pages.settings.pages.message.templates.modal.content.templateBody.smsParams.str205",
					) ?? ""
				)}
			</StyledRow>
		</LabeledField>
	);
});

declare namespace SmsParams {
	interface Props {
		type: MessageTemplateTypes;
	}
}

export default SmsParams;
