import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Stepper, react, TextBox, useRefWithSetter } from "uikit";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import PaymentSystem, {
	PaymentSystemFrontEndType,
} from "../../../../../../services/PaymentSystem";
import {
	StyledColumn,
	StyledRow,
	StyledP,
} from "../../../../../../components/common";
import translationPath from "../../constants/translationPath";
import ValueStepper from "../ValueStepper";

import InternalController from "./Controller";
import AssetPaymentsCurrency from "./components/AssetPaymentsCurrency";
import AssetPaymentsCountryIso from "./components/AssetPaymentsCountryIso";

const AssetPaymentBase = react.withController<
	AssetPayment.PropsBase,
	AssetPayment.Controller
>(({ value, onChange, controller }) => {
	const { t } = useTranslation();

	const [countryIsoRef, setCountryIsoRef] =
		useRefWithSetter<AssetPaymentsCurrency.Ref | null>(null);
	const [currencyRef, setCurrencyRef] =
		useRefWithSetter<AssetPaymentsCountryIso.Ref | null>(null);

	const valueEditor = useObjectEditor(value, onChange);

	const type = valueEditor.useGetter("type");

	const isExecutor = useMemo(
		() =>
			PaymentSystemFrontEndType.EXECUTOR === type ||
			PaymentSystemFrontEndType.TEMPLATE_EXECUTOR === type,
		[type],
	);

	// assetPaymentsAdditionalFee
	const assetPaymentsAdditionalFeeValue = valueEditor.useGetter(
		"assetPaymentsAdditionalFee",
	);
	const setAssetPaymentsAdditionalFeeValue = valueEditor.useSetter(
		"assetPaymentsAdditionalFee",
	);
	const handleAssetPaymentsAdditionalFee = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setAssetPaymentsAdditionalFeeValue(value);
			}
		},
		[setAssetPaymentsAdditionalFeeValue],
	);
	const textAssetPaymentsAdditionalFee = useMemo(
		() =>
			t([
				`${translationPath}.text.assetPaymentsAdditionalFee`,
				"Commission in UAH.",
			]),
		[t],
	);

	// assetPaymentsFeePercentage
	const assetPaymentsFeePercentageValue = valueEditor.useGetter(
		"assetPaymentsFeePercentage",
	);
	const setAssetPaymentsFeePercentageValue = valueEditor.useSetter(
		"assetPaymentsFeePercentage",
	);
	const handleAssetPaymentsFeePercentage = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setAssetPaymentsFeePercentageValue(value);
			}
		},
		[setAssetPaymentsFeePercentageValue],
	);

	const textAssetPaymentsFeePercentage = useMemo(
		() =>
			t([
				`${translationPath}.text.assetPaymentsFeePercentage`,
				"Commission in %",
			]),
		[t],
	);

	// systemFeeAdditionalForExecutor
	const systemFeeAdditionalForExecutorValue = valueEditor.useGetter(
		"systemFeeAdditionalForExecutor",
	);
	const setSystemFeeAdditionalForExecutorValue = valueEditor.useSetter(
		"systemFeeAdditionalForExecutor",
	);
	const handleSystemFeeAdditionalForExecutor = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setSystemFeeAdditionalForExecutorValue(value);
			}
		},
		[setSystemFeeAdditionalForExecutorValue],
	);

	// textAssetPaymentsAdditionalFee
	// textAssetPaymentsFeePercentage
	// textSystemFeeAdditionalForExecutor
	const textSystemFeeAdditionalForExecutor = useMemo(
		() =>
			t([
				`${translationPath}.text.systemFeeAdditionalForExecutor`,
				"Additional commission from the driver in UAH.",
			]),
		[t],
	);
	// systemFeePercentageForExecutor
	const systemFeePercentageForExecutorValue = valueEditor.useGetter(
		"systemFeePercentageForExecutor",
	);
	const setSystemFeePercentageForExecutorValue = valueEditor.useSetter(
		"systemFeePercentageForExecutor",
	);
	const handleSystemFeePercentageForExecutor = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setSystemFeePercentageForExecutorValue(value);
			}
		},
		[setSystemFeePercentageForExecutorValue],
	);

	const textSystemFeePercentageForExecutor = useMemo(
		() =>
			t([
				`${translationPath}.text.systemFeePercentageForExecutor`,
				"Additional driver commission in %",
			]),
		[t],
	);

	// assetPaymentsKey
	const assetPaymentsKey = valueEditor.useGetter("assetPaymentsKey");
	const setAssetPaymentsKey = valueEditor.useSetter("assetPaymentsKey");
	const [errorAssetPaymentsKey, setAssetPaymentsKeyError] =
		useState<boolean>(false);
	const handleAssetPaymentsKey = useCallback(
		(value: string) => {
			if (typeof value === "string") {
				setAssetPaymentsKey(value);
				setAssetPaymentsKeyError(false);
			}
		},
		[setAssetPaymentsKey, setAssetPaymentsKeyError],
	);

	const textErrorAssetPaymentsKey = useMemo(() => {
		if (!errorAssetPaymentsKey) return "";
		return t([`${translationPath}.errors.paymentsKey`, "Key not entered"]);
	}, [t, errorAssetPaymentsKey]);

	const placeholderAssetPaymentsKey = useMemo(
		() => t([`${translationPath}.placeholders.paymentsKey`, "Enter key"]),
		[t],
	);

	const textAssetPaymentsKey = useMemo(
		() => t([`${translationPath}.text.paymentsKey`, "Key"]),
		[t],
	);

	// assetPaymentsSecretKey
	const assetPaymentsSecretKey = valueEditor.useGetter(
		"assetPaymentsSecretKey",
	);
	const assetPaymentsSecretKeySet = valueEditor.useSetter(
		"assetPaymentsSecretKey",
	);
	const [errorAssetPaymentsSecretKey, setAssetPaymentsSecretKeyError] =
		useState<boolean>(false);
	const assetPaymentsSecretKeyHandle = useCallback(
		(value: string) => {
			if (typeof value === "string") {
				assetPaymentsSecretKeySet(value);
				setAssetPaymentsSecretKeyError(false);
			}
		},
		[assetPaymentsSecretKeySet, setAssetPaymentsSecretKeyError],
	);

	const textErrorAssetPaymentsSecretKey = useMemo(() => {
		if (!errorAssetPaymentsSecretKey) return "";
		return t([
			`${translationPath}.errors.paymentsSecretKey`,
			"Secret key not entered",
		]);
	}, [t, errorAssetPaymentsSecretKey]);

	const placeholderAssetPaymentsSecretKey = useMemo(
		() =>
			t([
				`${translationPath}.placeholders.paymentsSecretKey`,
				"Enter the secret key",
			]),
		[t],
	);
	const textAssetPaymentsSecretKey = useMemo(
		() =>
			t([`${translationPath}.text.paymentsSecretKey`, "The secret key"]),
		[t],
	);

	// assetPaymentsProcessingId
	const assetPaymentsProcessingId = valueEditor.useGetter(
		"assetPaymentsProcessingId",
	);
	const assetPaymentsProcessingIdSet = valueEditor.useSetter(
		"assetPaymentsProcessingId",
	);
	const [errorAssetProcessingId, setAssetProcessingIdError] =
		useState<boolean>(false);
	const assetPaymentsProcessingIdHandle = useCallback(
		(value: string) => {
			if (typeof value === "string") {
				assetPaymentsProcessingIdSet(Number(value));
				setAssetProcessingIdError(false);
			}
		},
		[assetPaymentsProcessingIdSet, setAssetProcessingIdError],
	);

	const textErrorAssetProcessingId = useMemo(() => {
		if (!errorAssetProcessingId) return "";
		return t([
			`${translationPath}.errors.paymentsProcessingId`,
			"Processing number not entered",
		]);
	}, [t, errorAssetProcessingId]);

	const placeholderAssetProcessingId = useMemo(
		() =>
			t([
				`${translationPath}.placeholders.paymentsProcessingId`,
				"Enter processing number",
			]),
		[t],
	);
	const textAssetProcessingId = useMemo(
		() =>
			t([
				`${translationPath}.text.paymentsProcessingId`,
				"Processing number",
			]),
		[t],
	);
	// Currency and CountryIso
	const textCurrency = useMemo(
		() => t([`${translationPath}.text.currency`, "Currency"]),
		[t],
	);
	const textCountryIso = useMemo(
		() => t([`${translationPath}.text.countryIso`, "Country"]),
		[t],
	);
	// controller
	controller.setContext({
		assetPaymentsProcessingId,
		assetPaymentsSecretKey,
		assetPaymentsKey,
		currencyRef,
		countryIsoRef,
		setAssetPaymentsSecretKeyError,
		setAssetProcessingIdError,
		setAssetPaymentsKeyError,
	});

	return (
		<StyledColumn gap="10px" w="100%">
			<StyledRow alignItems="center" gap="10px" w="100%">
				<StyledP w="200px">{textAssetPaymentsKey} :</StyledP>
				<TextBox.TextBox
					style={{ width: "calc(100% - 200px)" }}
					placeholder={placeholderAssetPaymentsKey}
					error={textErrorAssetPaymentsKey}
					value={assetPaymentsKey}
					onChange={handleAssetPaymentsKey}
				/>
			</StyledRow>

			<StyledRow alignItems="center" gap="10px" w="100%">
				<StyledP w="200px">{textAssetPaymentsSecretKey} :</StyledP>
				<TextBox.TextBox
					style={{ width: "calc(100% - 200px)" }}
					error={textErrorAssetPaymentsSecretKey}
					placeholder={placeholderAssetPaymentsSecretKey}
					value={assetPaymentsSecretKey}
					onChange={assetPaymentsSecretKeyHandle}
				/>
			</StyledRow>

			<StyledRow alignItems="center" gap="10px" w="100%">
				<StyledP w="200px">{textAssetProcessingId} :</StyledP>
				<TextBox.TextBox
					style={{ width: "300px" }}
					error={textErrorAssetProcessingId}
					placeholder={placeholderAssetProcessingId}
					type="number"
					min={0}
					value={String(assetPaymentsProcessingId)}
					onChange={assetPaymentsProcessingIdHandle}
				/>
			</StyledRow>

			<StyledRow alignItems="center" gap="10px" w="100%">
				<StyledP w="200px">{textAssetPaymentsFeePercentage}:</StyledP>
				<ValueStepper
					width="80px"
					min={0}
					value={assetPaymentsFeePercentageValue}
					onChange={handleAssetPaymentsFeePercentage}
				/>
			</StyledRow>

			<StyledRow alignItems="center" gap="10px" w="100%">
				<StyledP w="200px">{textAssetPaymentsAdditionalFee}:</StyledP>
				<ValueStepper
					width="80px"
					min={0}
					value={assetPaymentsAdditionalFeeValue}
					onChange={handleAssetPaymentsAdditionalFee}
				/>
			</StyledRow>

			{isExecutor && (
				<>
					<StyledRow alignItems="center" gap="10px" w="100%">
						<StyledP w="200px">
							{textSystemFeePercentageForExecutor}:
						</StyledP>
						<ValueStepper
							width="80px"
							min={0}
							value={systemFeePercentageForExecutorValue}
							onChange={handleSystemFeePercentageForExecutor}
						/>
					</StyledRow>

					<StyledRow alignItems="center" gap="10px" w="100%">
						<StyledP w="200px">
							{textSystemFeeAdditionalForExecutor}:
						</StyledP>
						<ValueStepper
							width="80px"
							min={0}
							value={systemFeeAdditionalForExecutorValue}
							onChange={handleSystemFeeAdditionalForExecutor}
						/>
					</StyledRow>
				</>
			)}

			<StyledRow alignItems="center" gap="10px">
				<StyledP w="200px">{textCurrency}:</StyledP>
				<AssetPaymentsCurrency
					ref={setCurrencyRef}
					value={value}
					onChange={onChange}
				/>
			</StyledRow>
			<StyledRow alignItems="center" gap="10px">
				<StyledP w="200px">{textCountryIso}:</StyledP>
				<AssetPaymentsCountryIso
					ref={setCountryIsoRef}
					value={value}
					onChange={onChange}
				/>
			</StyledRow>
		</StyledColumn>
	);
}, InternalController);

const AssetPayment = memo(AssetPaymentBase);

declare namespace AssetPayment {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default AssetPayment;
