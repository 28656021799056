type RoundingType = "floor" | "ceil";

function round(number: number, type: RoundingType) {
	switch (type) {
		// eslint-disable-next-line default-case-last
		default:
		case "floor":
			return Math.floor(number);

		case "ceil":
			return Math.ceil(number);
	}
}

export default function binarySearch(
	sortedNumbers: number[],
	target: number,
	roundingType: RoundingType = "floor",
) {
	let low = 0;
	let high = sortedNumbers.length - 1;
	let index = -1;

	while (low <= high) {
		index = round((low + high) / 2, roundingType);

		if (sortedNumbers[index] === target) break;

		if (sortedNumbers[index] < target) {
			low = index + 1;
		} else {
			high = index - 1;
		}
	}

	if (sortedNumbers[index] > target && index > 0) --index;

	return index;
}
