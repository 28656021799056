import { RefObject } from "react";
import { react } from "uikit";
import ObjectGroupContent from "../ObjectGroupContent";

interface Context {
	objectGroupContentRef: RefObject<ObjectGroupContent.Controller | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isObjectContentValid =
			this.context?.objectGroupContentRef.current?.validate();

		return isObjectContentValid;
	}
}
