import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	background-color: white;
	width: 100%;
	height: 100%;
	padding: 15px;
	padding-bottom: 0px;
`;

export default Root;
