import React, {
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import Color from "color";
import { CheckBoxWithContent, useRefWithSetter, react } from "uikit";
import { isEqual, isNumber } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import Language from "../../../../../../../../services/Language";
import CarClass from "../../../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../components/EditModal";
import {
	StyledGrid,
	StyledRow,
} from "../../../../../../../../components/common";
import { ModalTabs } from "../../constants/modalTabs";

import Content from "./components/Content";
import InternalController from "./Controller";
import Header from "./components/Header";

const Modal = react.withController<Modal.PropsBase, Modal.Controller>(
	({ onCancel, onSave, value, classModels, controller, loading, title }) => {
		const { t } = useTranslation();
		const lang = useTypedSelector((state) => state.session.language);
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);

		const [tab, setTab] = useState<ModalTabs>(ModalTabs.MAIN);
		const [internalValue, setInternalValue] = useState<Modal.Value>(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;
			onSave(internalValue);
		}, [contentRef, internalValue, onSave]);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const isDefault = valueEditor.useGetter("default");
		const setDefault = valueEditor.useSetter("default");

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");

		const id = valueEditor.useGetter("id");
		const setId = valueEditor.useSetter("id");

		const availableForOnlineOrderingValue = valueEditor.useGetter(
			"availableForOnlineOrdering",
		);
		const availableForOnlineOrderingOnChange = valueEditor.useSetter(
			"availableForOnlineOrdering",
		);

		const serviceAvailableIds = valueEditor.useGetter(
			"serviceAvailableIds",
		);
		const setServiceAvailableIds = valueEditor.useSetter(
			"serviceAvailableIds",
		);

		const serviceDefaultIds = valueEditor.useGetter("serviceDefaultIds");
		const setServiceDefaultIds = valueEditor.useSetter("serviceDefaultIds");

		const compatibleCarClassIds = valueEditor.useGetter(
			"compatibleCarClassIds",
		);
		const compatibleCarClassIdsOnChange = valueEditor.useSetter(
			"compatibleCarClassIds",
		);

		const compatibleCarClassIdsToBroadcastable = valueEditor.useGetter(
			"compatibleCarClassIdsToBroadcastable",
		);
		const compatibleCarClassIdsToBroadcastableOnChange =
			valueEditor.useSetter("compatibleCarClassIdsToBroadcastable");

		useLayoutEffect(() => {
			if (isNumber(id) && !isNumber(value.id)) setId(value.id);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.id]);

		useLayoutEffect(() => {
			if (!isEqual(value.serviceAvailableIds, serviceAvailableIds)) {
				setServiceAvailableIds(value.serviceAvailableIds);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.serviceAvailableIds]);

		useLayoutEffect(() => {
			if (!isEqual(value.serviceDefaultIds, serviceDefaultIds)) {
				setServiceDefaultIds(value.serviceDefaultIds);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.serviceDefaultIds]);

		const carClasses = useMemo(
			() =>
				classModels.filter((item) =>
					item.taxiServiceIds?.some((taxiServiceId) =>
						taxiServiceIds.includes(taxiServiceId),
					),
				),
			[classModels, taxiServiceIds],
		);

		const carClassIds = useMemo(
			() => carClasses.map((item) => item.id),
			[carClasses],
		);

		const debounceFn = useDebouncedCallback(() => {
			if (
				compatibleCarClassIds.length ||
				compatibleCarClassIdsToBroadcastable.length
			) {
				const distributionIds: number[] = [];
				const broadcastableIds: number[] = [];

				carClassIds.forEach((carClassId) => {
					const distributionId = compatibleCarClassIds.find(
						(id) => id === carClassId,
					);
					const broadcastableId =
						compatibleCarClassIdsToBroadcastable.find(
							(id) => id === carClassId,
						);

					if (distributionId) distributionIds.push(distributionId);
					if (broadcastableId) broadcastableIds.push(broadcastableId);
				});

				if (!isEqual(distributionIds, compatibleCarClassIds)) {
					compatibleCarClassIdsOnChange(distributionIds);
				}
				if (
					!isEqual(
						broadcastableIds,
						compatibleCarClassIdsToBroadcastable,
					)
				) {
					compatibleCarClassIdsToBroadcastableOnChange(
						broadcastableIds,
					);
				}
			}
		}, 1000);

		useLayoutEffect(() => {
			debounceFn();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			carClassIds,
			compatibleCarClassIdsToBroadcastable,
			compatibleCarClassIds,
		]);

		const assigner = valueEditor.useAssigner();

		controller.setContext({ contentRef });
		return (
			<EditModal
				loading={loading}
				canSave={!loading}
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<StyledRow alignItems="center" gap="20px">
						<CheckBoxWithContent
							disabled={isDefault}
							value={active}
							gap="10px"
							onChange={setActive}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.str150",
							) ?? ""}
						</CheckBoxWithContent>
						<CheckBoxWithContent
							disabled={!active}
							value={isDefault}
							gap="10px"
							onChange={setDefault}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.str151",
							) ?? ""}
						</CheckBoxWithContent>
						<CheckBoxWithContent
							value={availableForOnlineOrderingValue}
							gap="10px"
							onChange={availableForOnlineOrderingOnChange}
						>
							{t(
								"pages.preferencesPages.screenDirectory.carClass.modal.str152",
							) ?? ""}
						</CheckBoxWithContent>
					</StyledRow>
				}
			>
				<StyledGrid
					areas=""
					rows="auto 1fr"
					br="5px"
					bgC="#ffffff"
					over="hidden"
					w={{ width: "1200px", min: "900px" }}
					h={{ height: "86vh", min: "500px" }}
				>
					<Header tab={tab} title={title} onChangeTab={setTab} />
					<Content
						ref={setContentRef}
						value={internalValue}
						onChange={assigner}
						type={tab}
						disabled={false}
						language={lang}
						classModels={carClasses}
					/>
				</StyledGrid>
			</EditModal>
		);
	},
	InternalController,
);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface Value extends Record<string, any>, Content.Value {
		id?: number;

		companyIds: number[];
		taxiServices: any[];
		taxiServiceIds: number[];
		compatibleCarClassIds: number[];

		name: Record<Language, string>;
		shortName: string;
		active: boolean;
		default: boolean;

		useBackgroundColor: boolean;
		backgroundColor: Color;

		useTextColor: boolean;
		textColor: Color;

		distributable: boolean;
		broadcastable: boolean;

		availableForOnlineOrdering: boolean;

		distributionCompatibleMode: CarClass.CompatibleMode;
		broadcastingCompatibleMode: CarClass.CompatibleMode;
	}

	interface PropsBase {
		value: Value;
		onSave: (model: Value) => void;
		onCancel: () => void;

		title: string;
		language: Language;
		loading: boolean;
		classModels: CarClass.Model[];

		companyIds: number[];
		taxiServices: any[];
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Data {
		id?: number;

		taxiServices: any[];
		companyIds: number[];
		taxiServiceIds: number[];
		compatibleCarClassIds: number[];

		name: Record<Language, string>;
		shortName: string;
		active: boolean;
		default: boolean;

		useBackgroundColor: boolean;
		backgroundColor: Color;

		useTextColor: boolean;
		textColor: Color;

		distributable: boolean;
		broadcastable: boolean;

		availableForOnlineOrdering: boolean;

		distributionCompatibleMode: CarClass.CompatibleMode;
		broadcastingCompatibleMode: CarClass.CompatibleMode;
	}
}

export default Modal;
