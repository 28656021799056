import { TFunction } from "i18next";

import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	TRANSACTION_ID = "transactionId",
	CREATED_AT = "createdAt",
	CLIENT_NAME = "clientName",
	TRANSACTION_AMOUNT = "transactionAmount",
	TRANSACTION_AMOUNT_COMMISSION = "transactionAmountCommission",
	REMOUNT_TRANSACTION_TYPE = "remountTransactionType",
	STATUS = "status",
	STATUS_DESCRIPTION = "statusDescription",
	CALLSIGN = "callSign",
	EXECUTOR_FULL_NAME = "executorFullName",
	AMOUNT_THAT_CAME = "amountThatCame",
	AMOUNT_DRIVER_BALANCE = "amountDriverBalance",
	AMOUNT_FROM_CLIENT = "amountFromClient",
	PAYMENT_SYSTEM = "paymentSystem",
	TRANSACTION_TYPE = "transactionType",
	COMPANY = "company",
	TAXI_SERVICE = "taxiService",
	ORDER_NUMBER = "orderNumber",
}

export const getColumns = (t: TFunction): Content.ColumnType[] => [
	{
		id: Columns.TRANSACTION_ID,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.transactionId",
			) ?? "ID Transaction",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.CREATED_AT,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.createdAt",
			) ?? "Time",
		width: 110,
		sortable: true,
	},
	{
		id: Columns.CLIENT_NAME,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.clientName",
			) ?? "Client Name",
		width: 160,
		sortable: false,
	},
	{
		id: Columns.TRANSACTION_AMOUNT,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.transactionAmount",
			) ?? "Transaction Amount",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.TRANSACTION_AMOUNT_COMMISSION,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.transactionAmountCommission",
			) ?? "Commission Amount",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.REMOUNT_TRANSACTION_TYPE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.remountTransactionType",
			) ?? "Bank Operation",
		width: 160,
		sortable: false,
	},
	{
		id: Columns.STATUS,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.status",
			) ?? "Status",
		width: 120,
		sortable: true,
	},
	{
		id: Columns.STATUS_DESCRIPTION,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.statusDescription",
			) ?? "Status Description",
		width: 200,
		sortable: true,
	},
	{
		id: Columns.CALLSIGN,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.callsign",
			) ?? "Driver Callsign",
		width: 160,
		sortable: false,
	},
	{
		id: Columns.EXECUTOR_FULL_NAME,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.executorFullName",
			) ?? "Driver Full Name",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.AMOUNT_THAT_CAME,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.amountThatCame",
			) ?? "Amount That Came to Driver's Card",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.AMOUNT_DRIVER_BALANCE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.amountDriverBalance",
			) ?? "Amount Credited to Driver's Balance",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.AMOUNT_FROM_CLIENT,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.amountFromClient",
			) ?? "Amount Received from Client",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.PAYMENT_SYSTEM,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.paymentSystem",
			) ?? "Payment System",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.TRANSACTION_TYPE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.transactionType",
			) ?? "Transaction Type",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.COMPANY,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.company",
			) ?? "Company",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.TAXI_SERVICE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.taxiService",
			) ?? "Taxi Service",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.ORDER_NUMBER,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.bankTransactions.content.columns.orderNumber",
			) ?? "Order Number",
		width: 160,
		sortable: false,
	},
];
