import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row, theme } from "uikit";
import { useDispatch } from "react-redux";
import softphoneWidget from "../../../../redux/reducers/softphoneWidget";
import { useTypedSelector } from "../../../../redux/store";
import NavBarButton from "./components/NavBarButton";

const NavBarStyled = styled(Row)`
	padding: 0px 20px;
	width: 100%;
	height: 44px;
	background-color: ${theme.colors.white};
	border-radius: 0 0 5px 5px;
	border-top: 1px solid ${theme.colors.color_border_basic};
`;

const NavBar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { tab } = useTypedSelector((state) => state.softphoneWidget);

	const selectDialingTab = useCallback(() => {
		dispatch(softphoneWidget.actions.setTab("dialing"));
	}, [dispatch]);

	const selectLatestTab = useCallback(() => {
		dispatch(softphoneWidget.actions.setTab("latest"));
	}, [dispatch]);

	return (
		<NavBarStyled justify="space-between" sizes="100%*">
			<NavBarButton
				text={t("softphoneWidget.navBar.str0") ?? ""}
				active={tab === "dialing"}
				onClick={selectDialingTab}
			/>
			<NavBarButton
				text={t("softphoneWidget.navBar.str1") ?? ""}
				active={tab === "latest"}
				onClick={selectLatestTab}
			/>
		</NavBarStyled>
	);
};

export default NavBar;
