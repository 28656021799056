import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Preferences } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	setExecutorReport: null,
};

const setExecutorReport: ReduxReducer<State, State["setExecutorReport"]> = (
	state,
	{ payload },
) => {
	state.setExecutorReport = payload;
};

const resetError: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const errors = createSlice({
	name: Preferences.ConstantPreferencesSlice.SERVICES_ERRORS,
	initialState,
	reducers: {
		resetError,
		setExecutorReport,
	},
});

export const errorsAction = errors.actions;
export const errorsReducer = errors.reducer;
export type errorsState = State;

export default errors;
