import React, { memo, Suspense, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";

import Mark from "../../Mark";

const StatusDifference: React.FC<StatusDifference.Props> = ({ change }) => {
	const { t } = useTranslation();
	const previous = useMemo(
		() => t(`change_history.orders.${change.previous}`),
		[change.previous, t],
	);
	const actual = useMemo(
		() => t(`change_history.orders.${change.actual}`),
		[change.actual, t],
	);

	return (
		<Suspense>
			<Trans
				i18nKey={`change_history.orders.fields.${change.field}`}
				context={change.previous ? "change" : "set"}
				values={{
					prev: previous,
					new: actual,
				}}
				components={{ mark: <Mark /> }}
			/>
		</Suspense>
	);
};

declare namespace StatusDifference {
	interface Props {
		change: History.Field.StatusChange;
	}
}

export const StatusDifferenceMemo = memo(StatusDifference);
export default StatusDifference;
