/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

class Person {
	static fromResponse(data: any): Person.Model {
		return {
			id: data?.id,

			firstName: data?.name || "",
			lastName: data?.surname || "",
			fatherName: data?.fatherName || "",

			birthday: data?.birthday,

			country: data?.country,
			city: data?.city,
			address: data?.address,

			emails: data?.emails || [],
			phones: data?.phones || [],

			createdAt: data?.createdAt || "",
			updatedAt: data?.updatedAt || "",
			deletedAt: data?.deletedAt || "",
		};
	}

	static getFullName(person: Person.Model): string {
		return `${person.firstName || ""} ${person.lastName || ""} ${
			person.fatherName || ""
		}`;
	}
}

declare namespace Person {
	interface Model {
		id: number;

		firstName: string;
		lastName?: string;
		fatherName?: string;

		birthday?: Date | string | null;

		country?: string | null;
		city?: string | null;
		address?: string | null;

		emails?: Model.Email[];
		phones?: Model.Phone[];

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	namespace Model {
		interface Email {
			id: number;

			group: number;

			value: string;

			createdAt: string;
			updatedAt: string;
			deletedAt: string | null;
		}

		interface Phone {
			id: number;

			group: number;

			number: string;

			createdAt: string;
			updatedAt: string;
			deletedAt: string | null;
		}

		type NonEditablePropertyNames =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		type ModifiedPropertyNames = "emails" | "phones";

		type New = Omit<
			Model,
			NonEditablePropertyNames | ModifiedPropertyNames
		> & {
			emails: string[];
			phones: string[];
		};

		type Modified = Partial<New>;
	}
}

export default Person;
