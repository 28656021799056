import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { DiscountUnit } from "../../../../../../../redux/services/Order/getPhoneInfo";
import { useTypedSelector } from "../../../../../../../redux/store";

const DiscountCellContent: React.FC<DiscountCellContent.Props> = ({ item }) => {
	const lang = useTypedSelector((state) => state.session.language);

	const customerDiscountPlans = useMemo(() => {
		const customerDiscountPlans =
			item?.cost?.settings?.customerDiscountPlans;

		return customerDiscountPlans || [];
	}, [item?.cost?.settings?.customerDiscountPlans]);

	const mainPlan = useMemo(
		() => customerDiscountPlans?.find((item) => item.type === "main"),
		[customerDiscountPlans],
	);

	const additionalPlan = useMemo(
		() => customerDiscountPlans?.find((item) => item.type === "additional"),
		[customerDiscountPlans],
	);

	const mainT = useMemo(
		() => (mainPlan ? mainPlan.name?.[lang] : ``),
		[lang, mainPlan],
	);

	const additionalT = useMemo(
		() => (additionalPlan ? additionalPlan.name?.[lang] : ``),
		[lang, additionalPlan],
	);

	const currencyName = useMemo(
		() => item?.cost?.currency?.settings?.name || "",
		[item?.cost?.currency?.settings?.name],
	);

	const mainDiscount = useMemo(() => {
		if (!mainPlan || !mainPlan?.conciseSettings?.discount) return "0";
		const { unit, value } = mainPlan.conciseSettings.discount;
		if (unit === DiscountUnit.Amount) return `${value}${currencyName}`;
		return `${value}%`;
	}, [mainPlan, currencyName]);

	const mainDiscountMax = useMemo(() => {
		if (!mainPlan || !mainPlan?.conciseSettings?.maxDiscount) return "";
		const { active, value } = mainPlan.conciseSettings.maxDiscount;
		if (!active) return ``;
		return `<= ${value}${currencyName}`;
	}, [currencyName, mainPlan]);

	const mainDiscountActive = useMemo(
		() => mainPlan?.active,
		[mainPlan?.active],
	);
	const additionalDiscount = useMemo(() => {
		if (!additionalPlan || !additionalPlan?.conciseSettings?.discount)
			return "0";
		const { unit, value } = additionalPlan.conciseSettings.discount;
		if (unit === DiscountUnit.Amount) return `${value}${currencyName}`;
		return `${value}%`;
	}, [additionalPlan, currencyName]);

	const additionalDiscountMax = useMemo(() => {
		if (!additionalPlan || !additionalPlan?.conciseSettings.maxDiscount)
			return "";
		const { active, value } = additionalPlan.conciseSettings.maxDiscount;
		if (!active) return ``;
		return `<= ${value}${currencyName}`;
	}, [additionalPlan, currencyName]);

	const additionalDiscountActive = useMemo(
		() => additionalPlan?.active,
		[additionalPlan?.active],
	);

	const active = useMemo(
		() => item.additionalFields?.discount?.active ?? "",
		[item.additionalFields?.discount?.active],
	);

	const content = useMemo(() => {
		if (!active) return "";
		const main = mainDiscountActive
			? `${mainT}: ${mainDiscount} ${mainDiscountMax}`.trim()
			: "";
		const additional = additionalDiscountActive
			? `${additionalT}: ${additionalDiscount} ${additionalDiscountMax}`.trim()
			: "";

		return `${main}${additional ? ", " : ""} ${additional}`.trim();
	}, [
		additionalDiscount,
		additionalDiscountActive,
		additionalDiscountMax,
		additionalT,
		mainDiscount,
		mainDiscountActive,
		mainDiscountMax,
		mainT,
		active,
	]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace DiscountCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default DiscountCellContent;
