/* eslint-disable import/no-unresolved */

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Column, react, Row, Select } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Map from "../../../../../../../../../../redux/services/Map";
import TaxiServiceForm from "../../../../../../../../../../components/TaxiServiceForm";

import Text from "./components/Text";
import { fileTypes } from "./constants";
import InternalController from "./Controller";

const Form = react.withController<Form.InputifiedPropsBase, Form.Controller>(
	react.inputify<Form.PropsBaseWithController, Form.Value>(
		({ controller, language, value, onChange }) => {
			const taxiServiceFormRef =
				useRef<TaxiServiceForm.Controller | null>(null);

			controller.setContext({ taxiServiceFormRef });

			const { t } = useTranslation();
			return (
				<Column gaps="10px" maxedWidth maxedHeight>
					<TaxiServiceForm
						ref={taxiServiceFormRef}
						language={language}
						value={value?.taxiServiceId}
						onChange={(taxiServiceId) => {
							value &&
								onChange({
									...value,
									taxiServiceId,
								});
						}}
					/>
					<Row align="center" sizes="70px 1fr" gaps="10px" maxedWidth>
						<Text>
							{t(
								"pages.preferencesPages.screenDirectory.objects.exportModal.form.str100",
							) ?? ""}
						</Text>
						<Select
							options={fileTypes}
							value={value?.type}
							onSelect={(option) => {
								value &&
									onChange({
										...value,
										type: option.value,
									});
							}}
						/>
					</Row>
				</Column>
			);
		},
	),
	InternalController,
);

declare namespace Form {
	interface Value {
		taxiServiceId?: number;
		type: (typeof fileTypes)[0]["value"];
	}

	interface PropsBase {
		language: Map.Language;
	}

	interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default Form;
