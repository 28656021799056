/* eslint-disable no-shadow */
import { NonEditableProperties } from "../../types/NonEditableProperties";
import OptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Base from "../Base";
import { ModelId } from "../../types/ModelId";
import Language from "../Language";
import { IName } from "../../types/IName";
import TaxiService from "../TaxiService";
import { MapResolutions, RouteCalculationMode } from "./types";

class MapSettings extends Base {
	public static fromResponse(data: any): MapSettings.Model {
		const g = data.settings.general;
		const { addressCacheExpirationMs } = g;
		const addressCacheExpiration =
			addressCacheExpirationMs / 1000 / 60 / 60 / 24;

		return {
			id: data.id,

			name: data.name,
			settings: {
				...data.settings,
				general: {
					addressCacheExpiration,
					mapServices: g.mapServices,
					additionalMapServices: g.additionalMapServices,
					mapLanguage: g.mapLanguage,
					routeCalculationMode: g.routeCalculationMode,
					defaultSearchType: g.defaultSearchType,
					minSearchQueryLength: g.minSearchQueryLength,
					revealRadiusMeters: g.revealRadiusMeters,
					searchRadiusMeters: g.searchRadiusMeters,
					inputRequestDelayMs: g.inputRequestDelayMs,
					coordinateRequestIntervalMs: g.coordinateRequestIntervalMs,
					executorCoordinatesTTL: g.executorCoordinatesTTL,
					coordinateExpirationMs: g.coordinateExpirationMs,
					displayLocalObjectsInClientApp:
						g.displayLocalObjectsInClientApp,
					calculateExtraDistance: g.calculateExtraDistance,
				},
			},
			taxiServices: data.taxiServices,
			taxiServiceIds: data.taxiServices.map(({ id }) => id),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: MapSettings.Model) {
		const g = model.settings.general;
		const { addressCacheExpiration } = g;
		const addressCacheExpirationMs =
			addressCacheExpiration * 1000 * 60 * 60 * 24;

		return {
			settings: {
				...model.settings,
				general: {
					addressCacheExpirationMs,
					mapServices: g.mapServices,
					additionalMapServices: g.additionalMapServices,
					mapLanguage: g.mapLanguage,
					routeCalculationMode: g.routeCalculationMode,
					defaultSearchType: g.defaultSearchType,
					minSearchQueryLength: g.minSearchQueryLength,
					revealRadiusMeters: g.revealRadiusMeters,
					searchRadiusMeters: g.searchRadiusMeters,
					inputRequestDelayMs: g.inputRequestDelayMs,
					coordinateRequestIntervalMs: g.coordinateRequestIntervalMs,
					executorCoordinatesTTL: g.executorCoordinatesTTL,
					coordinateExpirationMs: g.coordinateExpirationMs,
					displayLocalObjectsInClientApp:
						g.displayLocalObjectsInClientApp,
					calculateExtraDistance: g.calculateExtraDistance,
				},
			},
			taxiServiceIds: model.taxiServiceIds,
		};
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.settings.map.getAll(),
		);
		return res?.items?.map(this.fromResponse);
	}

	public static async update(object: MapSettings.Model) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.settings.map.update(
					object.id,
					this.toRequest(object),
				),
			);
		} catch (err: any) {
			return false;
		}
		return true;
	}
}

declare namespace MapSettings {
	interface Model extends NonEditableProperties {
		name: IName;
		taxiServices: TaxiService.Model[];
		taxiServiceIds: ModelId[];
		settings: Settings;
	}

	interface Settings {
		general: General;
	}

	interface General {
		mapServices: MapServices;
		additionalMapServices: AdditionalMapServices;
		mapLanguage: Language;
		routeCalculationMode: RouteCalculationMode;
		defaultSearchType: string[];
		minSearchQueryLength: number;
		revealRadiusMeters: number;
		searchRadiusMeters: number;
		inputRequestDelayMs: number;
		addressCacheExpiration: number;
		coordinateRequestIntervalMs: number;
		executorCoordinatesTTL: number;
		coordinateExpirationMs: number;
		displayLocalObjectsInClientApp: boolean;
		calculateExtraDistance: boolean;
	}

	interface MapServices {
		route: MapResolutions;
		search: MapResolutions;
		geocode: MapResolutions;
		pickupDistance: MapResolutions;
		pickupTime: MapResolutions.GOOGLE | MapResolutions.HERE_MAPS;
		travelTime: MapResolutions.GOOGLE | MapResolutions.HERE_MAPS;
	}

	interface AdditionalMapServices {
		route: MapResolutions;
		search: MapResolutions;
		geocode: MapResolutions;
	}

	interface SubscribeOptions extends OptionsBase<MapSettings.Model> {}
}

export default MapSettings;
