import styled from "styled-components";
import { theme } from "uikit";

const ColumnCount = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 13px;

	color: ${theme.colors.accent};
`;

export default ColumnCount;
