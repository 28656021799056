import { theme } from "uikit";

export const borderStyles = {
	border: `1px solid ${theme.colors.color_border_basic}`,
	borderRadius: theme.borderRadius.m,
	padding: 10,
};
export const marginLeft = { marginLeft: 30 };
export const columnGaps = "10px*";
export const rowGaps = "8px*";
