import React, {
	Dispatch,
	useEffect,
	useState,
	useCallback,
	useLayoutEffect,
} from "react";
import { Option, Key } from "uikit";
import Root from "./components/Root";
import Item from "./components/Item";

const List = <OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
	onSave,
	onEvents,
	showCheckBox,
}: List.Props<OptionValue, ValueType>) => {
	const [activeOption, setActiveOption] = useState(0);

	const selectedActiveItem = useCallback(
		(data: boolean) => {
			const option = options?.[activeOption];
			if (data && value !== option?.key) {
				onChange(option.key as ValueType);
			} else onChange(undefined);
		},
		[activeOption, onChange, options, value],
	);

	useLayoutEffect(() => {
		if (!showCheckBox) {
			const option = options?.[activeOption];
			if (value !== option?.key) {
				onChange(option.key as ValueType);
			} else onChange(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOption]);

	const eventKeydown = useCallback(
		(event: KeyboardEvent) => {
			if (onEvents) onEvents(event);
			const keyEvent = event?.code;
			const isArrows = [
				"arrowup",
				"arrowdown",
				"arrowright",
				"arrowleft",
				"enter",
				"space",
			].includes(keyEvent.toLowerCase());

			if (event.key === "ArrowUp") {
				setActiveOption((prev) =>
					prev - 1 < 0 ? options.length - 1 : prev - 1,
				);
			}
			if (event.key === "ArrowDown") {
				setActiveOption((prev) =>
					prev + 1 > options.length - 1 ? 0 : prev + 1,
				);
			}

			if (keyEvent === "Space" && showCheckBox) {
				selectedActiveItem(true);
				event.stopPropagation();
				event.preventDefault();
			}

			if (event.key === "ArrowLeft" && showCheckBox) {
				selectedActiveItem(true);
				event.stopPropagation();
				event.preventDefault();
			}
			if (event.key === "ArrowRight" && showCheckBox) {
				selectedActiveItem(false);
				event.stopPropagation();
				event.preventDefault();
			}

			if (event.key === "Enter") {
				onSave(event);
				event.stopPropagation();
				event.preventDefault();
			}

			if (isArrows) event.stopPropagation();
		},
		[onEvents, onSave, options.length, selectedActiveItem, showCheckBox],
	);

	useEffect(() => {
		document.addEventListener("keydown", eventKeydown, true);
		return function cleanup() {
			document?.removeEventListener("keydown", eventKeydown, true);
		};
	}, [eventKeydown, options.length]);

	return (
		<Root alignItems="stretch">
			{options.map((option, i) => (
				<Item
					active={activeOption === i}
					key={option.key}
					value={value === option.key}
					label={option.label}
					onChange={selectedActiveItem}
					showCheckBox={showCheckBox}
				/>
			))}
		</Root>
	);
};
declare namespace List {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;
		showCheckBox?: boolean;
		options: Option<OptionValue>[];

		onChange: Dispatch<Value<ValueType>>;
		onSave: (
			event?:
				| React.MouseEvent<HTMLButtonElement, MouseEvent>
				| KeyboardEvent,
		) => void;
		onEvents?: (e?: KeyboardEvent) => void;
	}
}

export default List;
