import { isEqual } from "lodash";
import ExecutorRateCommissionPlan from "../../../../../../../services/ExecutorRateCommissionPlan";
import ExecutorRateDiscountPlan from "../../../../../../../services/ExecutorRateDiscountPlan";
import ExecutorRateSubscription from "../../../../../../../services/ExecutorRateSubscription";

interface ActiveValue {
	active: boolean;
	value: number;
}

interface ActiveValueDate {
	active?: boolean;
	value?: Date | null | undefined;
}

interface ActiveValueMode {
	active: boolean;
	value?: ExecutorRateCommissionPlan.Model.Action;
}

interface TimeRange {
	active: boolean;
	from?: Date | null | undefined;
	to?: Date | null | undefined;
}

interface CheckTime {
	prev: {
		startDate?: ActiveValueDate | ActiveValueDate["value"];
		timeRange?: TimeRange;
	};
	next: {
		startDate?: ActiveValueDate | ActiveValueDate["value"];
		timeRange?: TimeRange;
	};
}

export const checkTime = ({ prev, next }: CheckTime) => {
	try {
		return isEqual(
			{
				startDate: prev?.startDate,
				timeRange: prev?.timeRange,
			},
			{
				startDate: next?.startDate,
				timeRange: next?.timeRange,
			},
		);
	} catch {
		return false;
	}
};
interface Commission {
	id: ExecutorRateCommissionPlan.Model.Rate.Commission.Type;
	percent: number;
	amount: number;
	condition: {
		active?: boolean;
		type?: ExecutorRateCommissionPlan.Model.Rate.Commission.Threshold.Comparison.Direction;
		value?: number;
		amount: number;
		percent: number;
	};
}

interface CheckTariff {
	prev: {
		commissions: Commission[];
	};
	next: {
		commissions: Commission[];
	};
}

export const checkTariff = ({ prev, next }: CheckTariff) => {
	const commissionsPrev = prev.commissions || [];
	const commissionsNext = next.commissions || [];

	const payload = commissionsPrev
		.map((item, i) => {
			const existByIndex = commissionsNext[i];

			if (existByIndex?.id !== item?.id) {
				return false;
			}

			const exist = commissionsNext.find(
				(nextItem) => nextItem?.id === item?.id,
			);

			if (!exist) return false;
			return isEqual(item, exist);
		})
		?.every((item) => item === true);
	return payload;
};

interface Weekdays {
	friday: boolean;
	monday: boolean;
	saturday: boolean;
	sunday: boolean;
	thursday: boolean;
	tuesday: boolean;
	wednesday: boolean;
}

interface CheckWeekdays {
	prev: {
		weekdays: Weekdays;
	};
	next: {
		weekdays: Weekdays;
	};
}

export const checkWeekdays = ({ prev, next }: CheckWeekdays) =>
	isEqual(prev.weekdays, next.weekdays);

interface CheckMinMaxFee {
	prev: {
		maxFee: ActiveValue;
		minFee: ActiveValue;
	};
	next: {
		maxFee: ActiveValue;
		minFee: ActiveValue;
	};
}

export const checkMinMaxFee = ({ prev, next }: CheckMinMaxFee): boolean =>
	isEqual(
		{ maxFee: prev.maxFee, minFee: prev.minFee },
		{ maxFee: next.maxFee, minFee: next.minFee },
	);

interface CheckFee {
	prev: {
		fee: Pick<ActiveValue, "value">;
	};
	next: {
		fee: Pick<ActiveValue, "value">;
	};
}

export const checkFee = ({ prev, next }: CheckFee): boolean =>
	isEqual(prev.fee, next.fee);

interface CheckMode {
	prev: {
		mode?: ActiveValueMode | ExecutorRateSubscription.Model.Action;
	};
	next: {
		mode?: ActiveValueMode | ExecutorRateSubscription.Model.Action;
	};
}

export const checkMode = ({ prev, next }: CheckMode): boolean =>
	isEqual(prev.mode, next.mode);

interface Period {
	unit?:
		| ExecutorRateDiscountPlan.Model.Period.Type
		| ExecutorRateSubscription.Model.PeriodUnit;
	value?: number;
}

interface CheckPeriod {
	prev: {
		period: Period;
	};
	next: {
		period: Period;
	};
}

export const checkPeriod = ({ prev, next }: CheckPeriod): boolean =>
	isEqual(prev.period, next.period);

interface Threshold {
	value?: number;
}

interface CheckThreshold {
	prev: {
		threshold: Threshold;
	};
	next: {
		threshold: Threshold;
	};
}

export const checkThreshold = ({ prev, next }: CheckThreshold): boolean =>
	isEqual(prev.threshold, next.threshold);

interface CheckCommissionPlanId {
	prev: {
		commissionPlanId?: number;
	};
	next: {
		commissionPlanId?: number;
	};
}

export const checkCommissionPlanId = ({
	prev,
	next,
}: CheckCommissionPlanId): boolean =>
	isEqual(prev.commissionPlanId, next.commissionPlanId);

interface CheckClassifierId {
	prev: {
		classifierId?: number;
	};
	next: {
		classifierId?: number;
	};
}

export const checkClassifierId = ({ prev, next }: CheckClassifierId): boolean =>
	isEqual(prev.classifierId, next.classifierId);

interface CheckChargeOnOrderCompletion {
	prev: {
		settings: {
			chargeOnOrderCompletion?: boolean;
		};
	};
	next: {
		settings: {
			chargeOnOrderCompletion?: boolean;
		};
	};
}

export const checkChargeOnOrderCompletion = ({
	prev,
	next,
}: CheckChargeOnOrderCompletion): boolean =>
	isEqual(
		prev?.settings?.chargeOnOrderCompletion,
		next?.settings?.chargeOnOrderCompletion,
	);
