import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 100%;

	background: ${theme.colors.secondary};
`;

export default Root;
