import React, { useCallback, useEffect, useRef } from "react";
import { Overlayer, theme } from "uikit";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	PointElement,
	LineElement,
} from "chart.js";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ChartProps } from "react-chartjs-2/dist/types";

ChartJS.register(
	PointElement,
	LineElement,
	LinearScale,
	BarElement,
	ArcElement,
	CategoryScale,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels,
);

const options: ChartProps<any>["options"] = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			// suggestedMin: 0,
			min: 0,
		},
	},
	plugins: {
		legend: {
			position: "top" as const,
		},
		datalabels: {
			anchor: "end",
			align: "end",
			// offset: 32,
			color: theme.colors.primary,
		},
	},
};

interface OrderChartDataSet {
	label: string;
	data: number[];
}
export interface OrderChartData {
	timePeriods: (number | string)[];
	sets: OrderChartDataSet[];
}

export type ChartType = "linear" | "columnar" | "circular";
export const defaultChartType: ChartType = "linear";
export type IntervalLabel = "minute" | "hour" | "day" | "month" | "year";
export interface Interval {
	label: IntervalLabel;
	value: number;
	minRange: number;
	format: Intl.DateTimeFormatOptions;
}

function hsl(hue: number, type: "border" | "background") {
	return type === "border"
		? `hsl(${hue}, 100%, 50%)`
		: `hsl(${hue}, 100%, 75%)`;
}

interface Props {
	data: OrderChartData;
	type?: ChartType;
}

const Chart: React.FC<Props> = ({ type = defaultChartType, data }) => {
	const parentRef = useRef<HTMLDivElement | null>(null);
	const chartRef = useRef<ChartJS<any> | null>(null);

	useEffect(() => {
		if (!parentRef.current) return undefined;

		const observer = new ResizeObserver((entries) => {
			const { width, height } = entries[0].contentRect;
			chartRef.current?.resize(width, height);
		});

		observer.observe(parentRef.current);

		return () => {
			observer.disconnect();
		};
	}, [parentRef]);

	const drawChart = useCallback(() => {
		switch (type) {
			case "circular":
				return (
					<Doughnut
						ref={chartRef}
						// width={width}
						// height={height}
						options={{
							...options,
							scales: {
								...options.scales,
								y: { display: false },
							},
						}}
						data={{
							labels: data.sets.map((set) => set.label),
							datasets: [
								{
									data: data.sets.map((set) =>
										set.data.reduce((a, b) => a + b, 0),
									),
									borderColor: data.sets.map(
										(set, i, array) =>
											hsl(
												(i * 360) / array.length,
												"border",
											),
									),
									backgroundColor: data.sets.map(
										(set, i, array) =>
											hsl(
												(i * 360) / array.length,
												"background",
											),
									),
								},
							].slice(0, 1),
						}}
					/>
				);

			case "columnar":
				return (
					<Bar
						ref={chartRef}
						// width={width}
						// height={height}
						options={options}
						data={{
							labels: data.timePeriods,
							datasets: data.sets.map((set, i, array) => {
								const hue = (i * 360) / array.length;

								return {
									...set,
									borderColor: hsl(hue, "border"),
									backgroundColor: hsl(hue, "background"),
								};
							}),
						}}
					/>
				);

			case "linear":
			default:
				return (
					<Line
						ref={chartRef}
						// width={width}
						// height={height}
						options={options}
						data={{
							labels: data.timePeriods,
							datasets: data.sets.map((set, i, array) => {
								const hue = (i * 360) / array.length;

								return {
									...set,
									borderColor: hsl(hue, "border"),
									backgroundColor: hsl(hue, "background"),
								};
							}),
						}}
					/>
				);
		}
	}, [data, type]);

	return (
		<Overlayer
			style={{
				width: "100%",
				height: "100%",
			}}
			zIndex={100}
			overlay={drawChart()}
		>
			<div
				ref={parentRef}
				style={{
					width: "100%",
					height: "100%",
				}}
			/>
		</Overlayer>
	);
};

export default Chart;
