import styled from "styled-components";
import { theme } from "uikit";

const Content = styled.div`
	font-family: Lato;
	font-size: 13px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;

	color: ${theme.colors.white};
`;

export default Content;
