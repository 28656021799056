import styled from "styled-components";
import { Flex, theme } from "uikit";

const Root = styled(Flex)`
	padding: 14px 20px;

	border-radius: 5px;

	background: ${theme.colors.white};

	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 14px;

	color: ${theme.colors.primary};

	box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
`;

export default Root;
