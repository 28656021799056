import Address from "../../../../../../../../../../../types/Address";
import settlementToString from "../components/Settlement/utils/objectToLabel";
import streetToString from "../components/Street/utils/objectToLabel";

function addressToString(address: Partial<Address>) {
	return `${address.house}, ${streetToString(address)}, ${settlementToString(
		address,
	)}`;
}

export default addressToString;
