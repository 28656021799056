import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocViewer, {
	DocViewerRenderers,
	IConfig,
	IDocument,
	IRenderSettings,
} from "@egorcloud/react-doc-viewer";
import Loader from "rsuite/Loader";
import { noop } from "lodash";

import File from "../../services/File";
import useObjectEditor from "../../hooks/useObjectEditor";
import Modal from "../Modal";
import DeleteModal from "../DeleteModal";

import Root from "./components/Root";
import Header from "./components/Header";
import NoRenderer from "./components/NoRenderer";
import Controls from "./components/Controls";

const FileViewerModal: React.FC<FileViewerModal.Props> = ({
	disabled,
	value,

	onDelete,
	onClose,
}) => {
	const { t } = useTranslation();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [document, setDocument] = useState<
		FileViewerModal.Document | undefined
	>(undefined);

	const [controller, setController] = React.useState<any>(null);
	const [settings, setSettings] = React.useState<IRenderSettings>({
		loaded: false,
		zoomLevel: 1,
		paginated: false,
		pagesCount: 1,
		currentPage: 0,
		rotationAngle: 0,
		fitType: "page",
	});

	useEffect(() => {
		if (!settings || !controller) return;

		controller.update(settings);
	}, [controller, settings]);

	const settingsEditor = useObjectEditor(settings ?? {}, setSettings);

	const documents = useMemo(() => (document ? [document] : []), [document]);

	const headerOnDelete = useCallback(() => setShowDeleteModal(true), []);

	const config = useMemo<IConfig>(
		() => ({
			header: {
				disableFileName: false,
				overrideComponent: (state) => (
					<Header
						{...state}
						disabled={disabled}
						onDelete={headerOnDelete}
						onClose={onClose ?? noop}
					/>
				),
			},
			loadingRenderer: {
				overrideComponent: Loader,
			},
			noRenderer: {
				overrideComponent: NoRenderer,
			},
		}),
		[disabled, headerOnDelete, onClose],
	);

	const docViewerOnLoaded = useCallback((data: any) => {
		setSettings(data.state);
		setController(data.controller);
	}, []);

	const deleteModalOnCancel = useCallback(
		() => setShowDeleteModal(false),
		[],
	);

	const deleteModalOnConfirm = useCallback(() => {
		onDelete?.();
		setShowDeleteModal(false);
	}, [onDelete]);

	const controlsOnZoomIn = useCallback(() => {
		settingsEditor.set(
			"zoomLevel",
			Math.min(settingsEditor.get("zoomLevel") + 0.1, 3.5),
		);
	}, [settingsEditor]);

	const controlsOnZoomOut = useCallback(() => {
		settingsEditor.set(
			"zoomLevel",
			Math.max(settingsEditor.get("zoomLevel") - 0.1, 0.2),
		);
	}, [settingsEditor]);

	const controlsOnCounterclockwiseRotate = useCallback(() => {
		settingsEditor.set(
			"rotationAngle",
			(settingsEditor.get("rotationAngle") - 90) % 360,
		);
	}, [settingsEditor]);

	const controlsOnResetRotation = useCallback(() => {
		settingsEditor.set("rotationAngle", 0);
	}, [settingsEditor]);

	const controlsOnClockwiseRotate = useCallback(() => {
		settingsEditor.set(
			"rotationAngle",
			(settingsEditor.get("rotationAngle") + 90) % 360,
		);
	}, [settingsEditor]);

	const controlsOnFill = useCallback(() => {
		settingsEditor.set("zoomLevel", 1);
	}, [settingsEditor]);

	const controlsOnFit = useCallback(() => {
		settingsEditor.set("zoomLevel", 0.46);
	}, [settingsEditor]);

	useEffect(() => {
		(async () => {
			const blob = await value.load();

			if (blob)
				setDocument({
					uri: URL.createObjectURL(blob),
					fileType: value.type,
					fileName: value.name,
					base: value,
				});
		})();
	}, [value]);

	return (
		<>
			<Modal dimmed>
				<Root>
					<DocViewer
						pluginRenderers={DocViewerRenderers}
						renderSettings={settings}
						documents={documents}
						config={config}
						onChange={setSettings}
						onLoaded={docViewerOnLoaded}
					/>
					<Controls
						onZoomIn={controlsOnZoomIn}
						onZoomOut={controlsOnZoomOut}
						onCounterclockwiseRotate={
							controlsOnCounterclockwiseRotate
						}
						onResetRotation={controlsOnResetRotation}
						onClockwiseRotate={controlsOnClockwiseRotate}
						onFill={controlsOnFill}
						onFit={controlsOnFit}
					/>
				</Root>
			</Modal>
			{showDeleteModal ? (
				<DeleteModal
					disabled={disabled}
					label={t("fileViewerModal.str200") ?? ""}
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			) : null}
		</>
	);
};

declare namespace FileViewerModal {
	type Value = File.Model;

	interface Document extends IDocument {
		base: Value;
	}

	interface Props {
		disabled: boolean;
		value: Value;

		onDelete?: () => void;
		onClose?: () => void;
	}
}

export default FileViewerModal;
