import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import tPath from "../../../../../../constants/tPath";

const ConsiderSuburbanInTaximeterOutput: React.FC<ConsiderSuburbanInTaximeterOutput.Props> =
	memo(({ value }) => {
		const { t } = useTranslation();

		return value ? (
			<>
				<div>{t(`${tPath}.modal.considerSuburbanInTaximeter`)}</div>
				<span>/</span>
			</>
		) : null;
	});

declare namespace ConsiderSuburbanInTaximeterOutput {
	interface Props {
		value: boolean;
	}
}

export default ConsiderSuburbanInTaximeterOutput;
