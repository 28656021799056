import React, { useCallback, useState } from "react";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../components/EditModal";
import { MapTabs } from "./constants/tabs";
import MapSettings from "../../../../../../../../services/MapSettings";

const Modal: React.FC<Modal.Props> = ({ value, onCancel, onSave, headerTitle }) => {
	const [tab, setTab] = useState(MapTabs.MAIN);

	const [internalValue, setInternalValue] = useState(value);

	const handleSubmit = useCallback(() => {
		onSave(internalValue);
	}, [internalValue, onSave]);

	const editor = useObjectEditor(internalValue, setInternalValue);

	const contentValues = editor.usePicker([
		"settings",
		"taxiServices",
		"taxiServiceIds",
	]);

	const assigner = editor.useAssigner();

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto! 1fr">
				<Header
					title={headerTitle}
					createdAt={value.createdAt}
					tab={tab}
					setTab={setTab}
				/>
				<Content tab={tab} value={contentValues} onChange={assigner} />
			</Root>
		</EditModal>
	);
};

declare namespace Modal {
	interface Props {
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (settings: MapSettings.Model) => Promise<void>;
	}

	type Value = MapSettings.Model;
}

export default Modal;
