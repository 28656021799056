import React, { Dispatch } from "react";
import { Column, Row, Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import tPath from "../../../../constants/tPath";
import tabs, { AutoTariffsTabs } from "../../tabs";

import Title from "./components/Title";
import Root from "./components/Root";

const Header: React.FC<Header.Props> = ({
	tab,
	title,
	createdAt,
	onChangeTab,
}) => {
	const { t } = useTranslation();
	const tTabs = tabs.map((internalTab) => ({
		...internalTab,
		label: t(`${tPath}.modal.tabs.${internalTab.key}.title`),
	}));

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "16px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Column align="end" gaps="15px">
					<Row gaps="7px">
						<span>{t(`${tPath}.modal.createdAt`)}:</span>
						<span style={{ color: "black", fontWeight: "600" }}>
							{createdAt
								? new Date(createdAt).toLocaleDateString()
								: new Date().toLocaleDateString()}
						</span>
					</Row>
				</Column>
			</Row>
			<Tabs
				value={tab}
				options={tTabs}
				variant="panels"
				onChange={(newTab) => {
					onChangeTab(newTab.toString() as AutoTariffsTabs);
				}}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		tab: AutoTariffsTabs;
		title: string;
		createdAt?: string;
		onChangeTab: Dispatch<AutoTariffsTabs>;
	}
}

export default Header;
