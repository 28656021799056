const polygonsSet = Object.seal({
	set: "map.polygons.set",
	add: "map.polygons.add",
	update: "map.polygons.update",
	remove: "map.polygons.remove",
});

const set = {
	polygons: polygonsSet,
	getTrack: "map.track",
	liveSearch: "map.liveSearch",
	setCoordinates: "map.setCoordinates",
	addNewPoint: "map.addNewPoint",
	setPosition: "map.setPosition",
	setStreets: "map.setStreets",
	setCities: "map.setCities",
	setActivePoint: "map.setActivePoint",
	setFieldData: "map.setFieldData",
	setSearchType: "map.setSearchType",
	setNearestObjects: "map.setNearestObjects",
	setDefaultFields: "map.setDefaultFields",
	setLocalObjects: "map.setLocalObjects",
	setActiveLocalObject: "map.setActiveLocalObject",
	setMapLang: "map.setMapLang",
	listType: "map.listType",
	setLocalObjectsGroups: "map.setLocalObjectsGroups",
	setPoint: "map.setPoint",
};
const mapConstants = Object.seal(set);
export default mapConstants;
