/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Column } from "uikit";

import { useTypedSelector } from "../../../../../../redux/store";
import ServicesBankTransaction from "../../../../../../services/BankTransaction";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";

import Header from "./components/Header";
import Content from "./components/Content";

export interface Filter {
	dateRange: {
		dateFrom: Date;
		dateTo: Date;
	};
	sort: {
		column: string | undefined;
		type: string | undefined;
	};
}

export interface ModalFilter {
	statuses: ServicesBankTransaction.PaymentTransactionStatus[];
	types: ServicesBankTransaction.BankTransactionType[];
}

const BankTransactions: React.FC<BankTransactions.Props> = memo(() => {
	const language = useTypedSelector((state) => state.session.language);

	const [selected, setSelected] = useState<number[]>([]);
	const [limit, setLimit] = useState<number>(20);
	const [query, setQuery] = useState("");

	const [filter, setFilter] = useState<Filter>({
		dateRange: {
			dateFrom: moment().startOf("day").toDate(),
			dateTo: moment().endOf("day").toDate(),
		},
		sort: {
			column: undefined,
			type: undefined,
		},
	});

	const [modalFilter, setModalFilter] = useState<ModalFilter>({
		statuses: [],
		types: [],
	});

	const onSortTable = useCallback((column: string, type: string) => {
		setFilter((prev) => ({
			...prev,
			sort: {
				column,
				type,
			},
		}));
	}, []);

	const options = useMemo<ServicesBankTransaction.SubscribeOptions>(() => {
		const result: ServicesBankTransaction.SubscribeOptions = {
			dateRange: {
				from: filter.dateRange.dateFrom,
				to: filter.dateRange.dateTo,
			},
			query,
			types: modalFilter.types.length ? modalFilter.types : undefined,
			statuses: modalFilter.statuses.length
				? modalFilter.statuses
				: undefined,
			limit,
		};

		if (filter.sort.column && filter.sort.type) {
			result.order = {
				[filter.sort.column]: filter.sort.type.toUpperCase(),
			};
		}

		return result;
	}, [
		filter.dateRange.dateFrom,
		filter.dateRange.dateTo,
		filter.sort.column,
		filter.sort.type,
		limit,
		modalFilter.statuses,
		modalFilter.types,
		query,
	]);

	const bankTransactionSubscribeData = useModelSubscribe(
		options,
		ServicesBankTransaction,
	);

	const bankTransactionModels = useMemo(
		() => bankTransactionSubscribeData.models,
		[bankTransactionSubscribeData.models],
	);

	return (
		<Column gaps="5px*" sizes="auto 1fr" maxedWidth maxedHeight>
			<Header
				query={query}
				setQuery={setQuery}
				filter={filter}
				modalFilter={modalFilter}
				setFilter={setFilter}
				setModalFilter={setModalFilter}
			/>
			<Content
				value={bankTransactionModels}
				selected={selected}
				setSelected={setSelected}
				sort={filter.sort}
				onSort={onSortTable}
				language={language}
				setLimit={setLimit}
			/>
		</Column>
	);
});

declare namespace BankTransactions {
	interface Props {}
}

export default BankTransactions;
