export const columns = [
	{ id: "checkbox", label: "", width: 70 },
	{ id: "to", label: "До, км.", width: 100 },
	{ id: "baseSettlement", label: "Тариф Город/Стандарт", width: 280 },
	{ id: "outsideSettlement", label: "Тариф Загород", width: 280 },
	{
		id: "outsideSettlementRoundTrip",
		label: "Тариф Загород (в обе стороны)",
		width: 280,
	},
	{ id: "minimal", label: "Минимал", width: 280 },
];

export const defaultColumnsId = columns.map(({ id }) => id);
