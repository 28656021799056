import styled from "styled-components";
import { Column, theme } from "uikit";

const EdtiorWrapper = styled(Column)`
	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: 5px;
	padding: 15px;
	overflow-y: auto;
	min-height: 280px;
	max-height: 280px;
`;

export default EdtiorWrapper;
