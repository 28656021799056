import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)<Root.Props>`
	height: 36px;

	padding-inline: 10px 8px;

	background: ${theme.colors.white};

	${({ draggable }) => (draggable ? "cursor: grab;" : "")}
	border-bottom: 1px solid ${theme.colors.color_border_basic};

	overflow: hidden;
`;

declare namespace Root {
	interface Props extends Row.Props {
		draggable: boolean;
	}
}

export default Root;
