import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	width: 100%;

	${theme.scrollbar}
`;

export default Root;
