import React, { memo, useCallback, useState } from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../../../components/EditModal";
import tPath from "../../../../../../constants/tPath";

import SurchargesBlock from "../..";
import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const path = `${tPath}.mainModal.tabs.main.surcharges.modal.active`;

const Modal: React.FC<Modal.Props> = memo((props) => {
	const { value, onCancel, onSave, headerTitle, intervals, root } = props;

	const { t } = useTranslation();

	const [internalValue, setInternalValue] = useState(value);

	const editor = useObjectEditor(internalValue, setInternalValue);

	const active = editor.useGetter("active");
	const setActive = editor.useSetter("active");

	const other = editor.usePicker(["count", "type", "value"], "json");

	const [typeError, setTypeError] = useState(false);

	const handleSubmit = useCallback(() => {
		if (internalValue.type !== null) {
			onSave(internalValue);
		} else {
			setTypeError(true);
		}
	}, [internalValue, onSave]);

	return (
		<EditModal
			onCancel={onCancel}
			onSave={handleSubmit}
			footer={
				<Row align="center">
					<CheckBox
						label={t(path) || ""}
						value={active}
						onChange={setActive}
					/>
				</Row>
			}
		>
			<Root>
				<Header title={headerTitle} />
				<Content
					root={root}
					typeError={typeError}
					setTypeError={setTypeError}
					value={other}
					onChange={editor.assign}
					intervals={intervals}
				/>
			</Root>
		</EditModal>
	);
});

declare namespace Modal {
	interface Props {
		headerTitle: string;
		value: SurchargesBlock.Interval;
		onCancel: () => void;
		onSave: (x: SurchargesBlock.Interval) => void;
		intervals: Content.Intervals;
		root: boolean;
	}
}

export default Modal;
