import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";
import { isObject } from "lodash";

import { useTypedSelector } from "../../../../../../../../redux/store";
import useKeyBind from "../../../../../../../../hooks/useKeyBind";
import OrderStatus from "../../../../../../../../types/OrderStatus";
import Divider from "../../../../../../../../components/Divider";
import { AccessKey } from "../../../../../../../../access";

import ColorButton from "./components/ColorButton";
import Root from "./components/Root";
import Button from "./components/Button";
import SMSButton from "./components/SMSButton";
import CallClientButton from "./components/CallClientButton";
import CallExecutorButton from "./components/CallExexcutorButton";
import AutoCallClientButton from "./components/AutoCallClientButton";

const SecondRow: React.FC<SecondRow.Props> = ({
	onOpenEditOrderModal,
	toggleModalStatus,

	onCloseOrderClick,
	onCloneOrderClick,
	onSetOrderExecutorClick,
	onApproveOrder,
	onRecalculateOrder,
	onRecalculateOrderWithActualRates,
	onRefuseOrder,
	onSendOrderToAir,
	onToggleOrderAir,
	onSetCallToClientBeingRequired,
	onSetCallToClientSuccess,
	onSetCallToExecutorBeingRequired,
	onSetCallToExecutorSuccess,
	onShowClientNumberToExecutor,
	onRevertOrder,
}) => {
	const { t } = useTranslation();
	const orderType = useTypedSelector(
		(state) => state.ordersPageReducer.ordersType,
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const canEditOrder = useMemo(() => "id" in activeOrder, [activeOrder]);

	const canCloseOrder = useMemo(
		() => orderType !== "closed" && "id" in activeOrder,
		[activeOrder, orderType],
	);

	const canCopyOrder = useMemo(() => "id" in activeOrder, [activeOrder]);

	const canSetOrderExecutor = useMemo(
		() =>
			orderType !== "closed" &&
			"id" in activeOrder &&
			activeOrder.status !== "executing",
		[activeOrder, orderType],
	);

	const canApproveOrder = useMemo(
		() =>
			orderType !== "closed" &&
			"id" in activeOrder &&
			isObject(activeOrder.offer) &&
			"executor" in activeOrder.offer,
		[activeOrder, orderType],
	);

	const canSendOrderAgain = useMemo(() => false, []);

	const canRecalculateOrder = useMemo(
		() => orderType !== "closed" && "id" in activeOrder,
		[activeOrder, orderType],
	);

	const canReturnOrderOnAirOrRemoveExecutor = useMemo(
		() => "id" in activeOrder && activeOrder.status === "executing",
		[activeOrder],
	);

	const canSendOrderToAir = useMemo(
		() => "id" in activeOrder && activeOrder.status === OrderStatus.CREATED,
		[activeOrder],
	);

	const canSendOrderToExecuting = useMemo(() => false, []);

	const revertOrder = useMemo(
		() =>
			"id" in activeOrder &&
			activeOrder.status === OrderStatus.CLOSED &&
			activeOrder?.executorToOrder?.length,
		[activeOrder],
	);

	const canToggleOrderAir = useMemo(
		() =>
			"id" in activeOrder &&
			(activeOrder.status === OrderStatus.FREE_WAVE ||
				activeOrder.status === OrderStatus.CREATED),
		[activeOrder],
	);

	// const canSetOrderAir = useMemo(() => "id" in activeOrder, [activeOrder]);

	const canSetCallToClientBeingRequired = useMemo(
		() => "id" in activeOrder,
		[activeOrder],
	);

	const canSetCallToClientSuccess = useMemo(
		() => "id" in activeOrder,
		[activeOrder],
	);

	const canSetCallToExecutorBeingRequired = useMemo(
		() => "id" in activeOrder,
		[activeOrder],
	);

	const canSetCallToExecutorSuccess = useMemo(
		() => "id" in activeOrder,
		[activeOrder],
	);

	const canShowClientNumberToExecutor = useMemo(
		() => "id" in activeOrder,
		[activeOrder],
	);

	const isShowClientNumberToExecutorActive = useMemo(
		() =>
			("id" in activeOrder &&
				activeOrder.additionalFields?.displaySettings
					?.showCustomerPhone) ??
			false,
		[activeOrder],
	);

	useKeyBind(["Enter"], (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (canSendOrderToAir) {
			onSendOrderToAir();

			return;
		}

		if (canApproveOrder) {
			onApproveOrder();

			return;
		}

		if (canSetOrderExecutor) {
			onSetOrderExecutorClick();
		}
	});

	return (
		<Root align="center" gaps="5px*">
			<Button
				variant="primary"
				title={
					t(
						"pages.mainPage.pages.orders.orderHeader.secondRow.str0",
					) ?? ""
				}
				settingsKeyId="newOrder"
				tabIndex={-1}
				onClick={toggleModalStatus}
				accessName={AccessKey.NEW_ORDER}
			>
				<Icon id="orders-add" size={20} />
			</Button>
			<Divider
				orientation="vertical"
				size={18}
				color="rgba(0, 0, 0, 0.12)"
			/>
			<Button
				disabled={!canEditOrder}
				variant="secondary"
				settingsKeyId="editOrder"
				title={
					t(
						"pages.mainPage.pages.orders.orderHeader.secondRow.str1",
					) ?? ""
				}
				tabIndex={-1}
				onClick={onOpenEditOrderModal}
				accessName={AccessKey.EDIT_ORDER}
			>
				<Icon id="orders-edit" size={20} />
			</Button>
			{orderType === "closed" && (
				<Button
					disabled={!revertOrder}
					variant="secondary"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str2",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onRevertOrder}
					accessName={AccessKey.REVERT_ORDER}
				>
					<Icon id="return-back" size={17} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canCloseOrder}
					variant="secondary"
					settingsKeyId="closeOrder"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str3",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onCloseOrderClick}
					accessName={AccessKey.CLOSED_ORDER}
				>
					<Icon id="orders-close" size={20} />
				</Button>
			)}

			<Button
				disabled={!canCopyOrder}
				variant="secondary"
				settingsKeyId="copyOrder"
				title={
					t(
						"pages.mainPage.pages.orders.orderHeader.secondRow.str4",
					) ?? ""
				}
				tabIndex={-1}
				onClick={onCloneOrderClick}
				accessName={AccessKey.COPY_ORDER}
			>
				<Icon id="orders-copy" size={20} />
			</Button>

			{orderType !== "closed" && (
				<Button
					disabled={!canSetOrderExecutor}
					variant="secondary"
					settingsKeyId="setOrderExecutor"
					title={(keys) =>
						`${
							t(
								"pages.mainPage.pages.orders.orderHeader.secondRow.str200",
							) ?? ""
						}${keys.join("+")}/Enter)`
					}
					tabIndex={-1}
					onClick={onSetOrderExecutorClick}
					accessName={AccessKey.SET_ORDER_EXECUTOR}
				>
					<Icon id="orders-executor-enter" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canApproveOrder}
					variant="secondary"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str201",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onApproveOrder}
					accessName={AccessKey.APPROVE_ORDER}
				>
					<Icon id="orders-executor-check" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canSendOrderAgain}
					variant="secondary"
					settingsKeyId="sendOrderAgain"
					tabIndex={-1}
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str5",
						) ?? ""
					}
					accessName={AccessKey.SEND_ORDER_AGAIN}
				>
					<Icon id="orders-executor-refresh" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canRecalculateOrder}
					variant="secondary"
					settingsKeyId="recalculateOrder"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str6",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onRecalculateOrder}
					accessName={AccessKey.RECALCULATE_ORDER}
				>
					<Icon id="orders-recalculate" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canRecalculateOrder}
					variant="secondary"
					settingsKeyId="recalculateOrderWithActualRates"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str7",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onRecalculateOrderWithActualRates}
					accessName={AccessKey.RECALCULATE_ORDER_WITH_ACTUAL_RATES}
				>
					<Icon id="orders-recalculate" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Divider
					orientation="vertical"
					size={18}
					color="rgba(0, 0, 0, 0.12)"
				/>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canReturnOrderOnAirOrRemoveExecutor}
					variant="secondary"
					settingsKeyId="returnOrderOnAirOrRemoveExecutor"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str202",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onRefuseOrder}
					accessName={
						AccessKey.RETURN_ORDER_ON_AIR_OR_REMOVE_EXECUTOR
					}
				>
					<Icon id="orders-executor-remove" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canSendOrderToAir}
					variant="secondary"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str203",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onSendOrderToAir}
					accessName={AccessKey.SEND_ORDER_TO_AIR}
				>
					<Icon id="orders-table-pivot" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canSendOrderToExecuting}
					variant="secondary"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str204",
						) ?? ""
					}
					tabIndex={-1}
					accessName={AccessKey.SEND_ORDER_TO_EXECUTING}
				>
					<Icon id="orders-return" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canToggleOrderAir}
					variant="secondary"
					settingsKeyId="toggleOrderAir"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str205",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onToggleOrderAir}
					accessName={AccessKey.TOGGLE_ORDER_AIR}
				>
					<Icon id="orders-table-eye" size={20} />
				</Button>
			)}
			{orderType !== "closed" && <ColorButton />}
			{orderType !== "closed" && (
				<Divider
					orientation="vertical"
					size={18}
					color="rgba(0, 0, 0, 0.12)"
				/>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canSetCallToClientBeingRequired}
					variant="secondary"
					settingsKeyId="setOrderCallToClientBeingRequired"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str8",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onSetCallToClientBeingRequired}
					accessName={
						AccessKey.SET_ORDER_CALL_TO_CLIENT_BEING_REQUIRED
					}
				>
					<Icon id="orders-client-call" size={20} />
				</Button>
			)}
			{orderType !== "closed" && <CallClientButton />}
			{orderType !== "closed" && (
				<Button
					disabled={!canSetCallToClientSuccess}
					variant="secondary"
					settingsKeyId="setOrderCallToClientSuccess"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str9",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onSetCallToClientSuccess}
					accessName={AccessKey.SET_ORDER_CALL_TO_CLIENT_SUCCESS}
				>
					<Icon id="orders-client-call-mark" size={20} />
				</Button>
			)}
			{orderType !== "closed" && (
				<Button
					disabled={!canSetCallToExecutorBeingRequired}
					variant="secondary"
					settingsKeyId="setOrderCallToExecutorBeingRequired"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str10",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onSetCallToExecutorBeingRequired}
					accessName={
						AccessKey.SET_ORDER_CALL_TO_EXECUTOR_BEING_REQUIRED
					}
				>
					<Icon id="orders-executor-call" size={20} />
				</Button>
			)}
			{orderType !== "closed" && <CallExecutorButton />}
			{orderType !== "closed" && (
				<Button
					disabled={!canSetCallToExecutorSuccess}
					variant="secondary"
					settingsKeyId="setOrderCallToExecutorSuccess"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str11",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onSetCallToExecutorSuccess}
					accessName={AccessKey.SET_ORDER_CALL_TO_EXECUTOR_SUCCESS}
				>
					<Icon id="orders-executor-call-mark" size={20} />
				</Button>
			)}
			{orderType !== "closed" && <AutoCallClientButton />}
			{orderType !== "closed" && (
				<Button
					disabled={!canShowClientNumberToExecutor}
					active={isShowClientNumberToExecutorActive}
					variant="secondary"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.str13",
						) ?? ""
					}
					tabIndex={-1}
					onClick={onShowClientNumberToExecutor}
					accessName={AccessKey.SHOW_CLIENT_NUMBER_TO_EXECUTOR}
				>
					<Icon
						id="orders-show-client-number-to-executor"
						size={20}
					/>
				</Button>
			)}
			{orderType !== "closed" && (
				<Divider
					orientation="vertical"
					size={18}
					color="rgba(0, 0, 0, 0.12)"
				/>
			)}
			{orderType !== "closed" && <SMSButton />}
		</Root>
	);
};

declare namespace SecondRow {
	interface Props {
		onOpenEditOrderModal: VoidFunction;
		toggleModalStatus: VoidFunction;

		onCloseOrderClick: VoidFunction;
		onCloneOrderClick: VoidFunction;
		onSetOrderExecutorClick: VoidFunction;
		onApproveOrder: VoidFunction;
		onRecalculateOrder: VoidFunction;
		onRecalculateOrderWithActualRates: VoidFunction;
		onRefuseOrder: VoidFunction;
		onSendOrderToAir: VoidFunction;
		onToggleOrderAir: VoidFunction;
		onSetCallToClientBeingRequired: VoidFunction;
		onSetCallToClientSuccess: VoidFunction;
		onSetCallToExecutorBeingRequired: VoidFunction;
		onSetCallToExecutorSuccess: VoidFunction;
		onShowClientNumberToExecutor: VoidFunction;
		onRevertOrder: VoidFunction;
	}
}

export default SecondRow;
