import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
	ordersAction as orders,
	Orders,
} from "../../../../redux/reducers/Orders"; // TODO replace in chat, all addresses with direct ones from src/redux/reducers/Orders
import { useTypedSelector } from "../../../../redux/store";
import orderPageFilters from "../../../../redux/reducers/OrdersPage/Filters";
import { MODAL_TYPES, MESSAGE_TYPES } from "../constants/business";

const useChatActions = (onClose?: () => void): useChatActions.Props => {
	const dispatch = useDispatch();
	const { modalType, booferSelectedUsers, lastRead } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);

	//

	const setMessageType = useCallback(
		(type: Orders.Chat.MessagesTypeKeys) => {
			dispatch(orders.chats.chat.setMessageType(type));
			if (onClose) {
				onClose();
			} // * Use it only in the MessageTypeModal component.
		},
		[dispatch, onClose],
	);

	const selectDefaultMessage = useCallback(() => {
		setMessageType(MESSAGE_TYPES.DEFAULT);
	}, [setMessageType]);

	const selectAnnouncementMessage = useCallback(() => {
		setMessageType(MESSAGE_TYPES.ANNOUNCEMENT);
	}, [setMessageType]);

	const selectWarningMessage = useCallback(() => {
		setMessageType(MESSAGE_TYPES.WARNING);
	}, [setMessageType]);

	const selectSosMessage = useCallback(() => {
		setMessageType(MESSAGE_TYPES.SOS);
	}, [setMessageType]);

	//

	const toggleModalType = useCallback(
		(type: Orders.Chat.ModalType) => {
			dispatch(
				orders.chats.chat.setModalType(
					modalType === type ? MODAL_TYPES.NONE : type,
				),
			);
		},
		[dispatch, modalType],
	);

	const openUserModal = useCallback(() => {
		toggleModalType(MODAL_TYPES.USERS);
	}, [toggleModalType]);

	const openFlagModal = useCallback(() => {
		toggleModalType(MODAL_TYPES.FLAGS);
	}, [toggleModalType]);

	const openScheduleModal = useCallback(() => {
		toggleModalType(MODAL_TYPES.SCHEDULE);
	}, [toggleModalType]);

	//

	const onSaveLastReadFirstLoading = useCallback(
		(value: number) => {
			// * The first record of the unread mark. Old records the permanent location of the beginning of the group (block) Unread
			dispatch(orders.chats.chat.setLastReadOld(value));
			dispatch(orders.chats.chat.setLastReadNew(value));
		},
		[dispatch],
	);

	const onSaveLastReadWhenScrollDown = onSaveLastReadFirstLoading;

	const onSaveLastReadForSendMessageAndNullLust = useCallback(
		(value: number) => {
			// * Important setting for the button. This solves the case when there were no unread messages in the chat, but when adding a message from yourself, a group of unread ones popped up for him
			if (lastRead.newId === lastRead.oldId && lastRead.remaining === 0) {
				dispatch(orders.chats.chat.setLastReadOld(value));
			}

			dispatch(orders.chats.chat.setLastReadNew(value));
		},
		[dispatch, lastRead.newId, lastRead.oldId, lastRead.remaining],
	);

	const onSaveLastReadNew = useCallback(
		(value: number) => {
			// * Fixes the value of what has already been read by the user scrolling
			dispatch(orders.chats.chat.setLastReadNew(value));
		},
		[dispatch],
	);

	const onSaveLastReadRemaining = useCallback(
		(value: number) => {
			// * Fixes the value of how much remains unread.
			dispatch(orders.chats.chat.setLastReadRemaining(value));
		},
		[dispatch],
	);

	//

	const closeModal = useCallback(() => {
		dispatch(orders.chats.chat.setModalType("none"));
		dispatch(orders.chats.chat.setUserModalQuery(""));
	}, [dispatch]);

	const onChangeQuery = useCallback(
		(value: string) => {
			dispatch(orderPageFilters.actions.setChatsQuery(value));
		},
		[dispatch],
	);

	const openAnswerComponent = useCallback(
		(value: number) => {
			dispatch(orders.chats.chat.setAnswerComponentHeight(value));
		},
		[dispatch],
	);

	const onSubmitUsers = useCallback(() => {
		dispatch(
			orders.chats.chat.addDispatcherToUsers(
				booferSelectedUsers.dispatchers,
			),
		);
		dispatch(
			orders.chats.chat.addExecutorsToUsers(
				booferSelectedUsers.executors,
			),
		);

		closeModal();
	}, [
		dispatch,
		closeModal,
		booferSelectedUsers.dispatchers,
		booferSelectedUsers.executors,
	]);

	const onCleanAllUsers = useCallback(() => {
		dispatch(orders.chats.chat.clearDispatchersInBoofer()); // todo replace file name with general purpose resolver
		dispatch(orders.chats.chat.clearExecutorsInBoofer()); // todo think about a shortened form of referring to the reducer
		dispatch(orders.chats.chat.clearDispatchersInSelectedUsers());
		dispatch(orders.chats.chat.clearExecutorsInSelectedUsers());
	}, [dispatch]);

	const onCleanAllSchedule = useCallback(() => {
		dispatch(orders.chats.chat.cleanScheduleModalActualType());
		dispatch(orders.chats.chat.cleanScheduleHours());
		dispatch(orders.chats.chat.cleanScheduleMinutes());
		dispatch(orders.chats.chat.cleanScheduleDate());
		dispatch(orders.chats.chat.cleanScheduleTime());
	}, [dispatch]);

	const onCleanAllAnswerParent = useCallback(() => {
		dispatch(orders.chats.chat.cleanBooferAnswerParent());
		dispatch(orders.chats.chat.cleanAnswerParent());
	}, [dispatch]);

	const onSubmitSchedule = useCallback(
		(data: Orders.Chat.ScheduleRequestData) => {
			dispatch(orders.chats.chat.setScheduleModalActualType(data.type)); // todo separate reducers into a separate subgroup related to time
			dispatch(orders.chats.chat.setScheduleHours(data.hours));
			dispatch(orders.chats.chat.setScheduleMinutes(data.minutes));
			dispatch(orders.chats.chat.setScheduleTime(data.time));
			dispatch(orders.chats.chat.setScheduleDate(data.date));

			closeModal();
		},
		[dispatch, closeModal],
	);

	const onSendMessage = useCallback(() => {
		dispatch(orders.chats.chat.setIsSendingMessage());
	}, [dispatch]);

	const saveFilter = useCallback(
		(
			type: Orders.Chat.MessagesTypeFilter,
			data: Orders.Chat.ModalFilter,
		) => {
			dispatch(
				orders.chats.filter.setMegaFilter({
					type,
					data,
				}),
			);
		},
		[dispatch],
	); // TODO replace argument from 2 to 1 object with imported interface

	const saveFilterExecutorsWrite = useCallback(
		(data: Orders.Chat.ModalFilter) =>
			saveFilter("filterExecutorsWrite", data),
		[saveFilter],
	);

	const saveFilterExecutorsRead = useCallback(
		(data: Orders.Chat.ModalFilter) =>
			saveFilter("filterExecutorsRead", data),
		[saveFilter],
	);

	const onOpenClockModal = useCallback(() => {
		dispatch(orders.chats.chat.setIsShowClockModal(true));
	}, [dispatch]);

	const onCloseClockModal = useCallback(() => {
		dispatch(orders.chats.chat.setIsShowClockModal(false));
	}, [dispatch]);

	const hideScrollButton = useCallback(() => {
		dispatch(orders.chats.chat.setIsShowScrollButton(false));
	}, [dispatch]);

	const showScrollButton = useCallback(() => {
		dispatch(orders.chats.chat.setIsShowScrollButton(true));
	}, [dispatch]);

	return useMemo(
		() => ({
			openUserModal,
			openFlagModal,
			openScheduleModal,
			openAnswerComponent,
			onChangeQuery,
			selectDefaultMessage,
			selectAnnouncementMessage,
			selectWarningMessage,
			selectSosMessage,
			onSubmitUsers,
			onCleanAllUsers,
			onCleanAllSchedule,
			onCleanAllAnswerParent,
			onSubmitSchedule,
			closeModal,
			onSaveLastReadFirstLoading,
			onSaveLastReadWhenScrollDown,
			onSaveLastReadForSendMessageAndNullLust,
			onSaveLastReadNew,
			onSaveLastReadRemaining,
			onSendMessage,
			saveFilterExecutorsWrite,
			saveFilterExecutorsRead,
			onOpenClockModal,
			onCloseClockModal,
			hideScrollButton,
			showScrollButton,
		}),
		[
			openUserModal,
			openFlagModal,
			openScheduleModal,
			openAnswerComponent,
			onChangeQuery,
			selectDefaultMessage,
			selectAnnouncementMessage,
			selectWarningMessage,
			selectSosMessage,
			onSubmitUsers,
			onCleanAllUsers,
			onCleanAllSchedule,
			onCleanAllAnswerParent,
			onSubmitSchedule,
			closeModal,
			onSaveLastReadFirstLoading,
			onSaveLastReadWhenScrollDown,
			onSaveLastReadForSendMessageAndNullLust,
			onSaveLastReadNew,
			onSaveLastReadRemaining,
			onSendMessage,
			saveFilterExecutorsWrite,
			saveFilterExecutorsRead,
			onOpenClockModal,
			onCloseClockModal,
			hideScrollButton,
			showScrollButton,
		],
	);
};

declare namespace useChatActions {
	interface Props {
		onChangeQuery: (value: string) => void;
		openUserModal: () => void;
		openFlagModal: () => void;
		openScheduleModal: () => void;
		openAnswerComponent: (value: number) => void;
		selectDefaultMessage: () => void;
		selectAnnouncementMessage: () => void;
		selectWarningMessage: () => void;
		selectSosMessage: () => void;
		onSubmitUsers: () => void;
		onCleanAllUsers: () => void;
		onCleanAllSchedule: () => void;
		onCleanAllAnswerParent: () => void;
		onSubmitSchedule: (data: Orders.Chat.ScheduleRequestData) => void;
		closeModal: () => void;
		onSaveLastReadFirstLoading: (value: number) => void;
		onSaveLastReadWhenScrollDown: (value: number) => void;
		onSaveLastReadForSendMessageAndNullLust: (value: number) => void;
		onSaveLastReadNew: (value: number) => void;
		onSaveLastReadRemaining: (value: number) => void;
		onSendMessage: () => void;
		saveFilterExecutorsWrite: (value: Orders.Chat.ModalFilter) => void;
		saveFilterExecutorsRead: (value: Orders.Chat.ModalFilter) => void;
		onOpenClockModal: () => void;
		onCloseClockModal: () => void;
		hideScrollButton: () => void;
		showScrollButton: () => void;
	}
}

export default useChatActions;
