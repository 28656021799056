import React from "react";
import Loader from "rsuite/Loader";
import Root from "./components/Root";

const Overlay: React.FC<Overlay.Props> = ({ dimmed, maxedSize }) => (
	<Root dimmed={dimmed} maxedSize={maxedSize}>
		<Loader />
	</Root>
);

declare namespace Overlay {
	interface Props {
		dimmed: boolean;
		maxedSize: boolean;
	}
}

export default Overlay;
