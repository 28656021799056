import { Option } from "uikit";

import ExecutorRateSubscription from "../../../../../../../../../../../../../../../../services/ExecutorRateSubscription";

export const periodUnitOptions: Option<ExecutorRateSubscription.Model.PeriodUnit>[] =
	[
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Daily,
			label: "Дней",
			value: ExecutorRateSubscription.Model.PeriodUnit.Daily,
		},
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Weekly,
			label: "Неделя",
			value: ExecutorRateSubscription.Model.PeriodUnit.Weekly,
		},
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Monthly,
			label: "Месяц",
			value: ExecutorRateSubscription.Model.PeriodUnit.Monthly,
		},
	];

export const modeOptions: Option<ExecutorRateSubscription.Model.Action>[] = [
	{
		key: ExecutorRateSubscription.Model.Action.AfterOrderClosed,
		label: "После закрытия заказа",
		value: ExecutorRateSubscription.Model.Action.AfterOrderClosed,
	},
	{
		key: ExecutorRateSubscription.Model.Action.AfterOrderAssigned,
		label: "После постановки на заказ",
		value: ExecutorRateSubscription.Model.Action.AfterOrderAssigned,
	},
	{
		key: ExecutorRateSubscription.Model.Action.AfterPeriodStart,
		label: "После начала периода",
		value: ExecutorRateSubscription.Model.Action.AfterPeriodStart,
	},
	{
		key: ExecutorRateSubscription.Model.Action.AfterPeriodEnd,
		label: "После окончания периода",
		value: ExecutorRateSubscription.Model.Action.AfterPeriodEnd,
	},
	{
		key: ExecutorRateSubscription.Model.Action.AfterShiftOpen,
		label: "После открытия смены",
		value: ExecutorRateSubscription.Model.Action.AfterShiftOpen,
	},
	{
		key: ExecutorRateSubscription.Model.Action.AfterShiftClose,
		label: "После закрытия смены",
		value: ExecutorRateSubscription.Model.Action.AfterShiftClose,
	},
];

export default {
	periodUnitOptions,
	modeOptions,
};
