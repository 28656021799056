import React from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../../../../../../../../../components/InputModals/BaseModal";

import Loader from "../../../../../../../../../../components/Loader";
import Root from "./components/Root";

interface Props {
	pictureData: {
		url: string;
		isLoading: boolean;
	};
	onClose: () => void;
}

const PictureModal: React.FC<Props> = ({ pictureData, onClose }) => {
	const { t } = useTranslation();
	return (
		<BaseModal dimmer draggable={false} onClose={onClose}>
			<Root>
				{pictureData.isLoading && <Loader active dimmed={false} />}
				{!pictureData.isLoading && (
					<img
						width={300}
						height={300}
						src={pictureData.url}
						alt=""
						style={{ borderRadius: "0px 0px 10px 10px" }}
					/>
				)}
				{!pictureData.isLoading &&
					!pictureData.url &&
					(t(
						"pages.mainPage.pages.archives.tabs.pushHistory.content.pictureModal.str200",
					) ??
						"")}
			</Root>
		</BaseModal>
	);
};

export default PictureModal;
