/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Customers } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	autodetectPhones: [],
};

const setAutodetectPhones: ReduxReducer<State, State["autodetectPhones"]> = (
	state,
	{ payload },
) => {
	state.autodetectPhones = payload;
};

const addAutodetectPhone: ReduxReducer<State, State["autodetectPhones"][0]> = (
	state,
	{ payload },
) => {
	state.autodetectPhones = [...state.autodetectPhones, payload];
};

const updateAutodetectPhone: ReduxReducer<
	State,
	State["autodetectPhones"][0]
> = (state, { payload }) => {
	state.autodetectPhones = state.autodetectPhones.map((item) => {
		if (item.value === payload.value) return payload;
		return item;
	});
};

const removeAutodetectPhone: ReduxReducer<
	State,
	State["autodetectPhones"][0]["value"]
> = (state, { payload }) => {
	state.autodetectPhones = state.autodetectPhones.filter((item) => {
		if (item.value !== payload) return payload;
		return item;
	});
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const data = createSlice({
	name: Customers.ConstantCustomersSlice.AUTODETECT_PHONE,
	initialState,
	reducers: {
		reset,
		setAutodetectPhones,
		addAutodetectPhone,
		updateAutodetectPhone,
		removeAutodetectPhone,
	},
});

export const dataAction = data.actions;
export const dataReducer = data.reducer;
export type dataState = State;

export default data;
