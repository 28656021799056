import makeTable from "./makeTable";

const accountingExecutorsGeneral = makeTable({
	allIds: [
		"id",
		"callSign",
		"lastName",
		"firstName",
		"fatherName",

		"overallAmount",
		"overallCount",
		"commission",
		"cash",

		"cashAmount",
		"cashCount",
		"cashCommissionGross",
		"cashCashDeskGross",

		"cashlessAmount",
		"cashlessCount",
		"cashlessCommissionGross",
		"cashlessCashDeskGross",

		"ordersCount",
		"ordersAmount",
		"ordersAmountCommissionGross",
		"ordersAmountCashDeskGross",

		"onlineOrdersCount",
		"onlineOrdersAmount",
		"onlineOrdersAmountCommissionGross",
		"onlineOrdersAmountCashDeskGross",

		"bonusOrdersCount",
		"bonusOrdersAmount",
		"bonusOrdersAmountCommissionGross",
		"bonusOrdersAmountCashDeskGross",

		"ordersCountToCardService",
		"ordersAmountToCardService",
		"ordersAmountToCardServiceCommissionGross",
		"ordersAmountToCardServiceCashDeskGross",

		"ordersCountToCardExecutor",
		"ordersAmountToCardExecutor",
		"ordersAmountToCardExecutorCommissionGross",
		"ordersAmountToCardExecutorCashDeskGross",

		"ordersCountToTerminalService",
		"ordersAmountToTerminalService",
		"ordersAmountToTerminalServiceCommissionGross",
		"ordersAmountToTerminalServiceCashDeskGross",

		"ordersCountToTerminalExecutor",
		"ordersAmountToTerminalExecutor",
		"ordersAmountToTerminalExecutorCommissionGross",
		"ordersAmountToTerminalExecutorCashDeskGross",

		"ordersCountWithLocal",
		"ordersAmountWithLocal",
		"ordersAmountWithLocalCommissionGross",
		"ordersAmountWithLocalCashDeskGross",

		"ordersCountWithGlobal",
		"ordersAmountWithGlobal",
		"ordersAmountWithGlobalCommissionGross",
		"ordersAmountWithGlobalCashDeskGross",

		"ordersCountWithOutSide",
		"ordersAmountWithOutSide",
		"ordersAmountWithOutSideCommissionGross",
		"ordersAmountWithOutSideCashDeskGross",

		"penaltyCount",
		"penaltyAmount",

		"fee",
		"rent",
		"additionalFee",

		"totalAmountPayable",

		"balance",

		"paymentFromExecutorCash",
		"paymentFromExecutorCashPeriod",
		"paymentFromExecutorToAccount",
		"paymentFromExecutorToAccountPeriod",

		"residue",

		"dataChangeBalance",

		"markUpOnAgencyOrders",
		"compensationByBonusOrders",

		"incomeExecutor",

		"group",
		"fleet",
		"invoiceAmount",
		"invoiceCount",
		"dueAmount",
	],
	i18tPath: (id) => `tableColumns.accounting.accountingExecutors.${id}`,
	defaultIds: [
		"callSign",
		"lastName",
		"firstName",
		"fatherName",
		"group",
		"fleet",
		"overallCount",
		"dueAmount",
		"overallAmount",
		"commission",
		"fee",
		"markUpOnAgencyOrders",
		"balance",
		"cashAmount",
		"cashlessAmount",
		"penaltyAmount",
		"compensationByBonusOrders",
		"ordersAmountToTerminalService",
		"ordersAmountToCardService",
		"paymentFromExecutorCash",
		"paymentFromExecutorToAccount",
		"dataChangeBalance",
	],
	initialSortMethod: {
		column: "callSign",
		type: "asc",
	},
	initialWidths: {
		id: 70,
		callSign: 100,
		lastName: 100,
		firstName: 100,
		fatherName: 100,

		overallAmount: 70,
		overallCount: 70,
		commission: 70,
		cash: 70,

		cashAmount: 70,
		cashCount: 70,
		cashCommissionGross: 70,
		cashCashDeskGross: 70,

		cashlessAmount: 70,
		cashlessCount: 70,
		cashlessCommissionGross: 70,
		cashlessCashDeskGross: 70,

		ordersCount: 70,
		ordersAmount: 70,
		ordersAmountCommissionGross: 70,
		ordersAmountCashDeskGross: 70,

		onlineOrdersCount: 70,
		onlineOrdersAmount: 70,
		onlineOrdersAmountCommissionGross: 70,
		onlineOrdersAmountCashDeskGross: 70,

		bonusOrdersCount: 70,
		bonusOrdersAmount: 70,
		bonusOrdersAmountCommissionGross: 70,
		bonusOrdersAmountCashDeskGross: 70,

		ordersCountToCardService: 70,
		ordersAmountToCardService: 70,
		ordersAmountToCardServiceCommissionGross: 70,
		ordersAmountToCardServiceCashDeskGross: 70,

		ordersCountToCardExecutor: 70,
		ordersAmountToCardExecutor: 70,
		ordersAmountToCardExecutorCommissionGross: 70,
		ordersAmountToCardExecutorCashDeskGross: 70,

		ordersCountToTerminalService: 70,
		ordersAmountToTerminalService: 70,
		ordersAmountToTerminalServiceCommissionGross: 70,
		ordersAmountToTerminalServiceCashDeskGross: 70,

		ordersCountToTerminalExecutor: 70,
		ordersAmountToTerminalExecutor: 70,
		ordersAmountToTerminalExecutorCommissionGross: 70,
		ordersAmountToTerminalExecutorCashDeskGross: 70,

		ordersCountWithLocal: 70,
		ordersAmountWithLocal: 70,
		ordersAmountWithLocalCommissionGross: 70,
		ordersAmountWithLocalCashDeskGross: 70,

		ordersCountWithGlobal: 70,
		ordersAmountWithGlobal: 70,
		ordersAmountWithGlobalCommissionGross: 70,
		ordersAmountWithGlobalCashDeskGross: 70,

		ordersCountWithOutSide: 70,
		ordersAmountWithOutSide: 70,
		ordersAmountWithOutSideCommissionGross: 70,
		ordersAmountWithOutSideCashDeskGross: 70,

		penaltyCount: 70,
		penaltyAmount: 70,

		fee: 70,
		rent: 70,
		additionalFee: 70,

		totalAmountPayable: 70,

		balance: 70,

		paymentFromExecutorCash: 70,
		paymentFromExecutorCashPeriod: 70,
		paymentFromExecutorToAccount: 70,
		paymentFromExecutorToAccountPeriod: 70,

		residue: 70,

		dataChangeBalance: 70,

		markUpOnAgencyOrders: 70,
		compensationByBonusOrders: 70,

		incomeExecutor: 70,
		group: 70,
		fleet: 70,
		invoiceAmount: 70,
		invoiceCount: 70,
		dueAmount: 70,
	},
	initialResizable: {
		id: true,
		group: true,
		fleet: true,
		dueAmount: true,

		invoiceAmount: true,
		invoiceCount: true,

		callSign: true,
		lastName: true,
		firstName: true,
		fatherName: true,

		overallAmount: true,
		overallCount: true,
		commission: true,
		cash: true,

		cashAmount: true,
		cashCount: true,
		cashCommissionGross: true,
		cashCashDeskGross: true,

		cashlessAmount: true,
		cashlessCount: true,
		cashlessCommissionGross: true,
		cashlessCashDeskGross: true,

		ordersCount: true,
		ordersAmount: true,
		ordersAmountCommissionGross: true,
		ordersAmountCashDeskGross: true,

		onlineOrdersCount: true,
		onlineOrdersAmount: true,
		onlineOrdersAmountCommissionGross: true,
		onlineOrdersAmountCashDeskGross: true,

		bonusOrdersCount: true,
		bonusOrdersAmount: true,
		bonusOrdersAmountCommissionGross: true,
		bonusOrdersAmountCashDeskGross: true,

		ordersCountToCardService: true,
		ordersAmountToCardService: true,
		ordersAmountToCardServiceCommissionGross: true,
		ordersAmountToCardServiceCashDeskGross: true,

		ordersCountToCardExecutor: true,
		ordersAmountToCardExecutor: true,
		ordersAmountToCardExecutorCommissionGross: true,
		ordersAmountToCardExecutorCashDeskGross: true,

		ordersCountToTerminalService: true,
		ordersAmountToTerminalService: true,
		ordersAmountToTerminalServiceCommissionGross: true,
		ordersAmountToTerminalServiceCashDeskGross: true,

		ordersCountToTerminalExecutor: true,
		ordersAmountToTerminalExecutor: true,
		ordersAmountToTerminalExecutorCommissionGross: true,
		ordersAmountToTerminalExecutorCashDeskGross: true,

		ordersCountWithLocal: true,
		ordersAmountWithLocal: true,
		ordersAmountWithLocalCommissionGross: true,
		ordersAmountWithLocalCashDeskGross: true,

		ordersCountWithGlobal: true,
		ordersAmountWithGlobal: true,
		ordersAmountWithGlobalCommissionGross: true,
		ordersAmountWithGlobalCashDeskGross: true,

		ordersCountWithOutSide: true,
		ordersAmountWithOutSide: true,
		ordersAmountWithOutSideCommissionGross: true,
		ordersAmountWithOutSideCashDeskGross: true,

		penaltyCount: true,
		penaltyAmount: true,

		fee: true,
		rent: true,
		additionalFee: true,

		totalAmountPayable: true,

		balance: true,

		paymentFromExecutorCash: true,
		paymentFromExecutorCashPeriod: true,
		paymentFromExecutorToAccount: true,
		paymentFromExecutorToAccountPeriod: true,

		residue: true,

		dataChangeBalance: true,

		markUpOnAgencyOrders: true,
		compensationByBonusOrders: true,

		incomeExecutor: true,
	},
	initialSortable: {
		id: true,
		group: true,
		fleet: true,
		dueAmount: true,

		invoiceAmount: true,
		invoiceCount: true,

		callSign: true,
		lastName: true,
		firstName: true,
		fatherName: true,

		overallAmount: true,
		overallCount: true,
		commission: true,
		cash: true,

		cashAmount: true,
		cashCount: true,
		cashCommissionGross: true,
		cashCashDeskGross: true,

		cashlessAmount: true,
		cashlessCount: true,
		cashlessCommissionGross: true,
		cashlessCashDeskGross: true,

		ordersCount: true,
		ordersAmount: true,
		ordersAmountCommissionGross: true,
		ordersAmountCashDeskGross: true,

		onlineOrdersCount: true,
		onlineOrdersAmount: true,
		onlineOrdersAmountCommissionGross: true,
		onlineOrdersAmountCashDeskGross: true,

		bonusOrdersCount: true,
		bonusOrdersAmount: true,
		bonusOrdersAmountCommissionGross: true,
		bonusOrdersAmountCashDeskGross: true,

		ordersCountToCardService: true,
		ordersAmountToCardService: true,
		ordersAmountToCardServiceCommissionGross: true,
		ordersAmountToCardServiceCashDeskGross: true,

		ordersCountToCardExecutor: true,
		ordersAmountToCardExecutor: true,
		ordersAmountToCardExecutorCommissionGross: true,
		ordersAmountToCardExecutorCashDeskGross: true,

		ordersCountToTerminalService: true,
		ordersAmountToTerminalService: true,
		ordersAmountToTerminalServiceCommissionGross: true,
		ordersAmountToTerminalServiceCashDeskGross: true,

		ordersCountToTerminalExecutor: true,
		ordersAmountToTerminalExecutor: true,
		ordersAmountToTerminalExecutorCommissionGross: true,
		ordersAmountToTerminalExecutorCashDeskGross: true,

		ordersCountWithLocal: true,
		ordersAmountWithLocal: true,
		ordersAmountWithLocalCommissionGross: true,
		ordersAmountWithLocalCashDeskGross: true,

		ordersCountWithGlobal: true,
		ordersAmountWithGlobal: true,
		ordersAmountWithGlobalCommissionGross: true,
		ordersAmountWithGlobalCashDeskGross: true,

		ordersCountWithOutSide: true,
		ordersAmountWithOutSide: true,
		ordersAmountWithOutSideCommissionGross: true,
		ordersAmountWithOutSideCashDeskGross: true,
		penaltyCount: true,
		penaltyAmount: true,

		fee: true,
		rent: true,
		additionalFee: true,

		totalAmountPayable: true,

		balance: true,

		paymentFromExecutorCash: true,
		paymentFromExecutorCashPeriod: true,
		paymentFromExecutorToAccount: true,
		paymentFromExecutorToAccountPeriod: true,

		residue: true,

		dataChangeBalance: true,

		markUpOnAgencyOrders: true,
		compensationByBonusOrders: true,

		incomeExecutor: true,
	},
});

export default accountingExecutorsGeneral;
