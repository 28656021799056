import React, { PropsWithChildren, Suspense, memo, useMemo } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";

import useKeyBind from "../../../../hooks/useKeyBind";
import Modal, { ModalProps } from "../../../ModalController/Modal";
import { BaseElementIdEnum } from "../../interface";
import Style, { StyledColumn } from "../../styles";
import SuspenseLoader from "../../SuspenseLoader";
import Header from "../Header";
import Footer from "../Footer";

const Wrap = styled(StyledColumn)`
	border-radius: 10px;
`;

const DimmedBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	display: block;
	pointer-events: none;
	z-index: 1000;
`;

/**
 * CardModalBase component
 *
 * @param {boolean} dimmed - Indicates whether the background should be dimmed
 * and non-clickable while the modal is open.
 */
const CardModalBase: React.FC<CardModalBase.Props> = ({
	title,
	dimmer,
	shadow = !dimmer,
	draggable = true,
	styles,
	children,
	header,
	headerDivider,
	footer,
	footerDivider,
	textClose,
	textSave,
	buttonSave,
	buttonClose,
	buttonCross,
	dimmed = false,
	id,
	onClose,
	onSave,
	hiddenButton,
	hiddenButtonClose,
	...props
}) => {
	useKeyBind(["Escape"], (event) => {
		onClose(event);
		event.preventDefault();
		event.stopPropagation();
	});

	useKeyBind(["Enter"], (event) => {
		onSave(event);
		event.preventDefault();
		event.stopPropagation();
	});

	const content = useMemo(
		() => (
			<Wrap
				id={id ?? BaseElementIdEnum.MODAL}
				shadow={shadow ? "0px 3px 14px rgba(0,0,0,0.2)" : "none"}
				position="absolute"
				bgC="#ffffff"
				{...styles}
			>
				<Header
					title={title}
					onClose={onClose}
					draggable={draggable}
					divider={headerDivider}
					buttonCross={buttonCross}
				>
					{header}
				</Header>
				<Suspense fallback={<SuspenseLoader />}>{children}</Suspense>

				<Footer
					onClose={onClose}
					onSave={onSave}
					divider={footerDivider}
					textClose={textClose}
					textSave={textSave}
					buttonSave={buttonSave}
					buttonClose={buttonClose}
					hiddenButton={hiddenButton}
					hiddenButtonClose={hiddenButtonClose}
				>
					{footer}
				</Footer>
			</Wrap>
		),
		[
			buttonClose,
			buttonCross,
			buttonSave,
			children,
			draggable,
			footer,
			footerDivider,
			header,
			headerDivider,
			hiddenButton,
			hiddenButtonClose,
			id,
			onClose,
			onSave,
			shadow,
			styles,
			textClose,
			textSave,
			title,
		],
	);

	const elem = useMemo(
		() =>
			draggable ? (
				<Draggable handle=".handle">{content}</Draggable>
			) : (
				content
			),
		[content, draggable],
	);

	return (
		<>
			{dimmed && <DimmedBackground />}
			<Modal dimmer={dimmer} {...props}>
				{elem}
			</Modal>
		</>
	);
};

declare namespace CardModalBase {
	interface Props
		extends ModalProps,
			PropsWithChildren,
			Omit<Footer.Props, "divider">,
			Omit<Header.Props, "divider"> {
		footer?: React.ReactNode;
		header?: React.ReactNode;
		footerDivider?: boolean;
		headerDivider?: boolean;
		shadow?: boolean | string;
		draggable?: boolean;
		styles?: Style.FlexType & Style.DistributeType;
		id?: string;
		tabId?: string[];
		dimmed?: boolean;
	}

	type Event =
		| React.MouseEvent<HTMLButtonElement, MouseEvent>
		| KeyboardEvent
		| undefined;
}

export const CardModal = memo(CardModalBase);
export default CardModalBase;
