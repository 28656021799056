import { RefObject } from "react";
import { react } from "uikit";

// import NameForm from "../NameForm";

import { NamesBase } from "../../../../../../../../../../components/common";
import PointForm from "../PointForm";

interface Context {
	rootRef: RefObject<HTMLDivElement | null>;
	nameFormRef: RefObject<NamesBase.Controller>;
	pointFormRef: RefObject<PointForm.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isPointFormValid = this.context?.pointFormRef.current?.validate();
		const isNameFormValid = this.context?.nameFormRef.current?.validate();

		return isNameFormValid && isPointFormValid;
	}

	scrollIntoView(argument?: boolean | ScrollIntoViewOptions) {
		this.context?.rootRef.current?.scrollIntoView(argument);
	}
}
