import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";

import { useInternal } from "uikit";
import { OrderFormProps } from "../../../types/FormProps";
import { OrderCodeInfo } from "../../../../../../../../../redux/services/Order/getOrderCodeInfo";

export interface UseCounterpartyBalance {
	mainBalance: number | null;
	setMainBalance: Dispatch<SetStateAction<number | null>>;
	mainBalanceType: OrderCodeInfo.PaymentAccount["type"] | null;
	setMainBalanceType: Dispatch<
		SetStateAction<OrderCodeInfo.PaymentAccount["type"] | null>
	>;

	bonusBalance: number | null;
	setBonusBalance: Dispatch<SetStateAction<number | null>>;
	bonusBalanceType: OrderCodeInfo.PaymentAccount["type"] | null;
	setBonusBalanceType: Dispatch<
		SetStateAction<OrderCodeInfo.PaymentAccount["type"] | null>
	>;

	currency: string | null;
	setCurrency: Dispatch<SetStateAction<string | null>>;

	setPaymentAccounts: (arr: OrderCodeInfo.PaymentAccount[]) => void;
	resetBalance: () => void;
}

export type UseCounterpartyBalanceProps = (
	props: Pick<OrderFormProps, "form" | "tab">,
) => UseCounterpartyBalance;

/**
 *  `useCounterpartyBalance`
 *
 */
export const useCounterpartyBalance: UseCounterpartyBalanceProps = ({
	form,
	tab,
}) => {
	const [bonusBalance, setBonusBalance] =
		useState<UseCounterpartyBalance["bonusBalance"]>(null);

	const [bonusBalanceType, setBonusBalanceType] = useState<
		OrderCodeInfo.PaymentAccount["type"] | null
	>(null);

	const [mainBalance, setMainBalance] =
		useState<UseCounterpartyBalance["mainBalance"]>(null);

	const [mainBalanceType, setMainBalanceType] = useState<
		OrderCodeInfo.PaymentAccount["type"] | null
	>(null);

	const [currency, setCurrency] =
		useInternal<UseCounterpartyBalance["currency"]>(null);

	const resetBalance = useCallback(() => {
		setCurrency(null);
		setMainBalance(null);
		setMainBalanceType(null);
		setBonusBalance(null);
		setBonusBalanceType(null);
		form.setValue("counterparty", undefined);
		form.setValue("counterpartyMainPaymentAccount", undefined);
		form.setValue("counterpartyBonusPaymentAccount", undefined);
		form.setValue("paymentType", "cash");
		if (tab.form.phone) {
			form.setValue("paymentChangeType", "company_bill_to_cash");
		}
	}, [form, setCurrency, tab.form?.phone]);

	const setPaymentAccounts = useCallback<
		UseCounterpartyBalance["setPaymentAccounts"]
	>(
		(arr) => {
			if (!Array.isArray(arr)) {
				resetBalance();
				return;
			}

			const main = arr.find((item) => item.type === "main");
			const bonus = arr.find((item) => item.type === "bonus");

			if (main) {
				setMainBalance(main.amount);
				setMainBalanceType(main.type);
				setCurrency(main.currency);

				form.setValue("counterparty.paymentAccountId", main.id);
				form.setValue("counterpartyMainPaymentAccount", main);

				form.setValue("counterparty.amount", main.amount);
				form.setValue("counterparty.type", main.type);
				form.setValue("counterparty.currency", main.currency);
			}

			if (bonus) {
				setBonusBalance(bonus.amount);
				setBonusBalanceType(bonus.type);
				form.setValue("counterpartyBonusPaymentAccount", bonus);
			}
		},
		[form, resetBalance, setCurrency],
	);

	const counterparty = useMemo(
		() => tab.form?.counterparty,
		[tab.form?.counterparty],
	);

	const counterpartyBonusPaymentAccount = useMemo(
		() => tab.form?.counterpartyBonusPaymentAccount,
		[tab.form?.counterpartyBonusPaymentAccount],
	);

	useMemo(() => {
		const type = counterparty?.type || null;
		const amount = counterparty?.amount || 0;
		const currency = counterparty?.currency || "";

		setMainBalance(amount);
		setMainBalanceType(type);

		setCurrency(currency);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counterparty?.amount, counterparty?.type, counterparty?.currency]);

	useMemo(() => {
		if (counterpartyBonusPaymentAccount) {
			const { amount, type } = counterpartyBonusPaymentAccount;
			setBonusBalance(amount);
			setBonusBalanceType(type === "bonus" ? "bonus" : null);
		}
	}, [counterpartyBonusPaymentAccount]);

	return useMemo<UseCounterpartyBalance>(
		() => ({
			mainBalance,
			setMainBalance,
			mainBalanceType,
			setMainBalanceType,

			bonusBalance,
			setBonusBalance,
			bonusBalanceType,
			setBonusBalanceType,

			currency,

			resetBalance,

			setPaymentAccounts,
			setCurrency,
		}),
		[
			mainBalance,
			setMainBalance,
			mainBalanceType,
			setMainBalanceType,

			bonusBalance,
			setBonusBalance,
			bonusBalanceType,
			setBonusBalanceType,

			currency,

			resetBalance,

			setPaymentAccounts,
			setCurrency,
		],
	);
};
