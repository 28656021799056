import React, { Dispatch, memo } from "react";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";

const Header: React.FC<Header.Props> = memo(({ headerTitle }) => (
	<Root sizes="auto!*">
		<InformationContainer
			align="center"
			justify="space-between"
			gaps="10px"
		>
			<Title style={{ marginBottom: 10 }}>{headerTitle}</Title>
			{/* <SubTitle>
					{createdAt
						? `Registration date: ${moment(createdAt).format(
								"DD.MM.YYYY",
						  )}`
						: ""}
				</SubTitle> */}
		</InformationContainer>
		{/* <Tabs
				value={tab}
				options={tabs} // main, passport, files, history, access
				variant="panels"
				onChange={(newTab) =>
					onChangeTab(newTab.toString() as Header.Tab)
				}
			/> */}
	</Root>
));

declare namespace Header {
	type Tab = "main";

	interface Props {
		tab: Tab;
		headerTitle: string;

		createdAt?: string;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
