import { LatLngLiteral } from "leaflet";
import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

export default async function getSectorByPoint(point: LatLngLiteral) {
	const prpcow = getPRPC();

	if (!prpcow) return null;

	const [sector] = await createRPCQuery(() =>
		prpcow?.theirsModel.sector.getByPoint(point),
	);

	return sector;
}
