/* eslint-disable no-param-reassign */
// import sessionConstants from "../constants/session.constants";

import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
	connected: boolean;
	authorized: boolean;
	token?: string;
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setConnected: Reducer<State["connected"]> = (state, { payload }) => {
	state.connected = payload;
};

const setAuthorized: Reducer<State["authorized"]> = (state, { payload }) => {
	state.authorized = payload;
};

const setToken: Reducer<State["token"]> = (state, { payload }) => {
	state.token = payload;
};

const initialState: State = {
	connected: false,
	authorized: false,
};

const prpc = createSlice({
	name: "prpc",
	initialState,
	reducers: {
		setConnected,
		setAuthorized,
		setToken,
	},
});

export default prpc;
