import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	position: relative;

	width: 100%;
	height: 100%;

	overflow-y: auto;

	${theme.scrollbar}

	background: ${theme.colors.white};

	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
`;

export default Root;
