import React, { memo, useMemo } from "react";
import { Tooltip, Whisper } from "rsuite";

import { useReactiveRef, useSize } from "uikit";

import CompactTable from "../../../../../CompactTable";
import { StyledRow } from "../../../../../common";

const HeaderBase: React.FC<HeaderBase.Props> = ({ children, ...props }) => {
	const [contentRef, setContentRef] = useReactiveRef<HTMLDivElement | null>(
		null,
	);

	const contentSize = useSize(contentRef.current);
	const isWhispered = useMemo(
		() => contentSize.width + 12 > (props.width ?? 0),
		[contentSize, props.width],
	);

	const whispered = useMemo(
		() => (
			<Whisper
				followCursor
				placement="bottom"
				speaker={<Tooltip>{children}</Tooltip>}
			>
				<CompactTable.HeaderCell {...props} fixed>
					<StyledRow ref={setContentRef}>{children}</StyledRow>
				</CompactTable.HeaderCell>
			</Whisper>
		),
		[children, props, setContentRef],
	);

	const notWhispered = useMemo(
		() => (
			<CompactTable.HeaderCell {...props} fixed>
				<StyledRow
					ref={setContentRef}
					alignItems="center"
					h={{ min: "20px", max: "100%" }}
				>
					{children}
				</StyledRow>
			</CompactTable.HeaderCell>
		),
		[children, props, setContentRef],
	);

	return isWhispered ? whispered : notWhispered;
};

declare namespace HeaderBase {
	interface Props extends CompactTable.HeaderCell.Props {}
}

export const Header = memo(HeaderBase);

export default HeaderBase;
