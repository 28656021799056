import React, { memo } from "react";

import CarClass from "../../../../../../../../../../services/CarClass";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../../components/common";
import { GeneralTariffTabs as Tabs } from "../../constants/tabs";

import Root from "./components/Root";
import GeneralSurcharges from "./tabs/GeneralSurcharges";

const Content: React.FC<Content.Props> = memo(
	({ autoClasses, value, onChange, tab }) => {
		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker(
			[
				"name",
				"taxiServices",
				"taxiServiceIds",
				"additionalFields",
				"carClassIds",
			],
			"json",
		);

		const updateAllData = valueEditor.useAssigner();

		const getActualTab = (currentTab: Tabs) => {
			switch (currentTab) {
				case Tabs.GENERAL_SURCHARGES:
					return (
						<GeneralSurcharges
							autoClasses={autoClasses}
							value={mainTabValue}
							onChange={updateAllData}
						/>
					);

				case Tabs.HISTORY:
					return (
						<StyledRow position="absolute" top="50%" left="50%">
							<SuspenseLoader />
						</StyledRow>
					);

				default:
					return <></>;
			}
		};
		return <Root>{getActualTab(tab)}</Root>;
	},
);

declare namespace Content {
	interface Props {
		autoClasses: CarClass.Model[];
		value: Value;
		onChange: (value: Value) => void;
		tab: Tabs;
	}
	interface Value extends GeneralSurcharges.Value {}
}

export default Content;
