export enum TransactionAction {
	TO = "to",
	FROM = "from",
}

export enum TransactionStatus {
	PENDING = "pending",
	COMPLETED = "completed",
	CANCELLED = "cancelled",
}

export enum TransactionMetadataAction {
	CHARGE = "charge",
	REFUND = "refund",
	RECALCULATE = "recalculate",
}
export enum TransactionMetadataEntityType {
	COMMISSION = "commission",
	DISCOUNTED = "discounted",
	SUBSCRIPTION = "subscription",
}

export enum TransactionMetadataContext {
	UPDATE_BALANCE = "update_balance",
	REPLENISH = "replenish",
	WITHDRAW = "withdraw",
	TRANSFER = "transfer",
	COMMISSION_FEE = "commission_fee",
	SUBSCRIPTION_FEE = "subscription_fee",
	COMPENSATION = "compensation",
	DISCOUNTED_COMMISSION_FEE = "discounted_commission_fee",
}

export enum OperationTransactionType {
	CUSTOMER = "customer",
	DISPATCHER = "dispatcher",
	EXECUTOR = "executor",
}

/**
 * All payment entities that participate in transactions.
 */
export enum PayableEntityType {
	AGENT = "agent",
	CUSTOMER = "customer",
	COUNTERPARTY = "counterparty",
	COUNTERPARTY_CHECK = "counterpartyCheck",
	EXECUTOR = "executor",
	TAXI_SERVICE = "taxiService",
	COMPANY = "company",
}

/**
 * Type of the payment account.
 */
export enum PaymentAccountType {
	MAIN = "main",
	BONUS = "bonus",
	COMMISSION = "commission",
	COMPENSATION = "compensation",
	CUSTOMER_TERMINAL = "customer_terminal",
	COUNTERPARTY_TERMINAL = "counterparty_terminal",
	AGENT_TERMINAL = "agent_terminal",
	EXECUTOR_TERMINAL = "executor_terminal",
	CARD = "card",
}
