import styled from "styled-components";
import { Map } from "uikit";

export const MapBase = styled(Map)`
	div .leaflet-control-zoom {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		right: 10px;
		bottom: 25px;

		background-color: #ffffff;

		border: none;
		border-radius: 5px;

		padding: 2px;
		margin: 0px;
		gap: 5px;

		.leaflet-control-zoom-in::after,
		#map-zoom-in-control::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: #dee0e2;
			bottom: -3px;
		}

		#map-zoom-out-control,
		#map-zoom-in-control,
		.leaflet-control-zoom-out,
		.leaflet-control-zoom-in {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0px;
			gap: 0px;
			position: relative;
			width: 28px;
			height: 28px;

			cursor: pointer;

			border: none;
			border-radius: 5px;

			span {
				color: #21333f;
				width: 28px;
				height: 28px;
				font-weight: 800;
				font-style: normal;
				font-size: 20px;
				cursor: pointer;
			}

			:hover {
				background-color: #e4e4e4;
			}
		}
	}
`;

export const StyledBaseMarkerIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;

	width: 40px;
	height: 18px;
	margin: 0px auto;

	background-color: rgb(33, 51, 63);
	color: white;

	border-radius: 10px;

	font-weight: 700;
	font-size: 14px;
	line-height: 17px;

	left: -20px;
	top: -29px;

	::after {
		content: "";
		position: absolute;
		left: 15px;
		bottom: -17px;
		border: 6px solid transparent;
		border-top: 11px solid rgb(33, 51, 63);
	}
`;

export const ReactMarkerIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;

	background-color: #ff0000;

	width: 25px;
	height: 25px;

	border: 2px solid #fff;
	border-radius: 15px;

	margin: 0px;
	left: -14px;
	top: -33px;
`;

export const ReactMarkerIconPin = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;

	background-color: #ffffff;

	width: 10px;
	height: 10px;

	margin: 0px;

	border-radius: 50%;

	::after {
		content: "";
		position: absolute;

		left: 0px;
		bottom: -21px;
		border: 5px solid transparent;
		border-top: 9px solid #fff;
	}
`;

export const ReactMarkerIconPoint = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ff0000;
	width: 3px;
	height: 3px;
	margin: 0px;
	border-radius: 50%;
`;

export const CarMarkerPoint = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;

	background-color: rgb(14, 163, 76);

	min-width: 56px;
	height: 24px;
	padding: 0px 10px 0px 20px;
	left: 0px;
	top: -36px;

	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;

	color: #ffffff;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;

	::after {
		content: "";
		position: absolute;

		left: 0px;
		bottom: -11px;

		border-top: 12.31px solid rgb(14, 163, 76);
		border-right: 20.43px solid transparent;
	}
`;

export const CarMarkerIconPoint = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;

	left: -12px;
	top: -7px;
	width: 25px;
	height: 25px;

	fill: #21333f;

	border-radius: 100%;

	background-color: #fff509;
	border: 1px solid rgba(33, 51, 63, 0.3);
`;
