import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "uikit";

import tPath from "../../constants/tPath";
import {
	RateAdjustmentToDefault,
	defaultSurchargeOptions,
} from "../../constants/constants";

const SurchargeSelect: React.FC<SurchargeSelect.Props> = ({
	value,
	onChange,
	disabled,
}) => {
	const { t } = useTranslation();

	const tDefaultSurchargeOptions = useMemo(
		() =>
			defaultSurchargeOptions.map((option) => ({
				...option,
				label: t(
					`${tPath}.mainModal.tabs.main.mainSettings.surchargeOptions.${option.key}`,
				),
			})),
		[t],
	);

	const handleChange = useCallback(
		(newType) => {
			onChange(newType);
		},
		[onChange],
	);

	return (
		<Select
			disabled={disabled}
			style={{ width: "310px" }}
			value={value}
			onChange={handleChange}
			options={tDefaultSurchargeOptions}
		/>
	);
};

declare namespace SurchargeSelect {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
		disabled?: boolean;
	}

	type Value = RateAdjustmentToDefault;
}

export default SurchargeSelect;
