import React, { useCallback } from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const DeleteButton: React.FC<DeleteButton.Props> = ({
	disabled = false,
	onClick,
}) => {
	const rootOnClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (disabled) return;

			onClick?.();
		},
		[disabled, onClick],
	);

	return (
		<Root align="center" justify="center" onClick={rootOnClick}>
			<Icon id="trash" size={16} colors={[theme.colors.secondary]} />
		</Root>
	);
};

declare namespace DeleteButton {
	interface Props {
		disabled?: boolean;

		onClick?: VoidFunction;
	}
}

export default DeleteButton;
