import React, { memo } from "react";
import { Trans } from "react-i18next";

import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../components/common";
import Mark from "../../../Mark";
import Marker from "../../../Marker";
import PassengerPointDiff from "../index";
import { PassengerFeatureMemo } from "./PassengerFeature";

const PassengerAddPoint: React.FC<PassengerAddPoint.Props> = ({
	passengerName,
	addedItems,
}): JSX.Element => {
	if (!addedItems.length) return <></>;
	return (
		<StyledColumn>
			<StyledRow>
				<Trans
					key={`${passengerName}-add`}
					i18nKey={`change_history.orders.fields.passenger.points`}
					context="add"
					values={{ name: passengerName }}
					components={{ mark: <Mark /> }}
				/>
			</StyledRow>
			<StyledColumn p={"0 0 0 10px"}>
				{addedItems.map(({ point, feature }, i) => {
					if (!point || !feature) return <></>;
					if (!point.lat || !point.lng) return <></>;
					return (
						<>
							<StyledRow key={`${passengerName}-${i}-add`}>
								<Marker />
								<Trans
									i18nKey={`change_history.orders.fields.passenger.point`}
									values={{
										lat: `${point?.lat}`,
										lng: `${point?.lng}`,
									}}
									components={{ mark: <Mark /> }}
								/>
							</StyledRow>
							<PassengerFeatureMemo
								key={`${passengerName}-${i}-feature-add`}
								feature={feature}
							/>
						</>
					);
				})}
			</StyledColumn>
		</StyledColumn>
	);
};
declare namespace PassengerAddPoint {
	interface Props {
		passengerName: string;
		addedItems: AddedItems;
	}

	type AddedItems = PassengerPointDiff.PointChange;
}

export const PassengerAddPointMemo = memo(PassengerAddPoint);
export default PassengerAddPoint;
