import { combineReducers } from "@reduxjs/toolkit";

import { dataAction, dataReducer, dataState } from "./data";
import { errorsAction, errorsReducer, errorsState } from "./errors";
import { filtersAction, filtersReducer, filtersState } from "./filters";

export interface StateCounterparty {
	data: dataState;
	errors: errorsState;
	filters: filtersState;
}

export const counterparty = combineReducers<StateCounterparty>({
	data: dataReducer,
	errors: errorsReducer,
	filters: filtersReducer,
});

export const counterpartyAction = {
	data: dataAction,
	errors: errorsAction,
	filters: filtersAction,
};

export default counterparty;
