import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import Executor, {
	ExecutorSubscribeType,
} from "../../../../../../../../../../services/Executor";
import Dispatcher from "../../../../../../../../../../services/Dispatcher";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { ordersAction as orders } from "../../../../../../../../../../redux/reducers/Orders";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import useModelSubscribeOld from "../../../../../../../../../../hooks/useModelSubscribe";
import { SortingOrder } from "../../../../../../../../../../types/SortingOrder";

const ChatsSubscribe: React.FC = () => {
	const dispatch = useDispatch();

	const { chats } = useTypedSelector((state) => state.orderPageFilters);

	const executorModelSubscribeOptions = useMemo(() => {
		const result: Executor.SubscribeOptions = {
			query: chats.filters.query,
			subscribeType: ExecutorSubscribeType.MAIN_ORDER_PAGE,
		};

		if (chats.filters.order?.column && chats.filters.order?.type) {
			result.order = {
				[chats.filters.order.column]:
					chats.filters.order.type.toUpperCase(),
			};
		}

		return result;
	}, [
		chats.filters.order?.column,
		chats.filters.order?.type,
		chats.filters.query,
	]);

	const executorsData = useModelSubscribe(
		executorModelSubscribeOptions,
		Executor,
	);

	const dispatcherSubscriptionOptions =
		useMemo<Dispatcher.SubscribeOptions>(() => {
			const result: Dispatcher.SubscribeOptions = {
				query: chats.filters.query,
			};

			if (chats.filters.order?.column && chats.filters.order?.type) {
				if (chats.filters.order?.column === "callSign") {
					result.order = {
						alias: chats.filters.order?.type.toUpperCase() as SortingOrder,
					};
				}
				if (chats.filters.order?.column === "name") {
					result.order = {
						fullName:
							chats.filters.order?.type.toUpperCase() as SortingOrder,
					};
				}
				if (chats.filters.order?.column === "phone") {
					result.order = {
						phone: chats.filters.order?.type.toUpperCase() as SortingOrder,
					};
				}
			}

			return result;
		}, [
			chats.filters.order?.column,
			chats.filters.order?.type,
			chats.filters.query,
		]);

	const dispatchersData = useModelSubscribeOld(
		dispatcherSubscriptionOptions,
		Dispatcher,
	);

	useEffect(() => {
		dispatch(
			orders.chats.chat.setExecutorsInUserModalData(
				executorsData?.models,
			),
		);
	}, [dispatch, executorsData?.models]);

	useEffect(() => {
		dispatch(
			orders.chats.chat.setDispatchersInUserModalData(
				dispatchersData?.cache || [],
			),
		);
	}, [dispatch, dispatchersData?.cache]);

	return null;
};

export default ChatsSubscribe;
