import styled from "styled-components";
import { theme } from "uikit";

const Button = styled.button`
	all: unset;

	position: relative;

	display: flex;

	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	width: 40px;
	height: 40px;

	border: none;
	border-radius: 50%;

	background: ${theme.colors.button_secondary};

	cursor: pointer;

	overflow: hidden;

	&:disabled {
		cursor: not-allowed;
	}

	&:hover {
		&::before {
			content: "";

			position: absolute;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.05);
		}
	}
`;

export default Button;
