import { RefObject } from "react";
import { react } from "uikit";

import {
	NameBase,
	CompaniesAndTaxiServicesBase,
} from "../../../../../../../../../../../../components/common";

// import PersonData from "./components/PersonData";
// import WorkData from "./components/WorkData";

interface Context {
	// personDataRef: RefObject<PersonData.Ref | null>;
	// workDataRef: RefObject<WorkData.Ref | null>;
	nameRef: RefObject<NameBase.Ref | null>;
	companyBranchRef: RefObject<CompaniesAndTaxiServicesBase.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isBranchesValid =
			!!this.context?.companyBranchRef.current?.validate();
		const isNameValid = !!this.context?.nameRef.current?.validate();

		return isBranchesValid && isNameValid;
	}
}
