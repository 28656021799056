import { isNumber, isString, isNaN } from "lodash";

export const sortByNumberOrString = (
	a: string | number,
	b: string | number,
) => {
	if (isNumber(a) && isNumber(b)) return a - b;
	const valueA = isNumber(a) ? a : parseInt(a, 10);
	const valueB = isNumber(b) ? b : parseInt(b, 10);
	if (isNumber(valueA) && isNumber(valueB)) {
		if (!isNaN(valueA) || !isNaN(valueB)) return valueA - valueB;
	}

	if (isString(a) && isString(b)) return a.localeCompare(b);

	if (!a || !b) return 0;
	if (a < b) return -1;
	return a > b ? 1 : 0;
};

export default sortByNumberOrString;
