import styled from "styled-components";
import { InputBorders } from "uikit";

const SearchIconBorders = styled(InputBorders.InputBorders)`
	.${InputBorders.classes.borders} {
		height: 32px;
		padding: 7px 6px 7px 11px;
	}
`;

export {};
export default SearchIconBorders;
