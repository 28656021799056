/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { Archive, ReduxReducer, ValueToggle } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	isInfoModal: false,
	isArchiveFilterModal: false,
	isArchiveSettingModal: false,
	isSubscribe: false,
	isSearch: false,
	isExport: false,
	isImport: false,
	isDisabledOrderForm: false,
	isSaveOrderForm: false,
	isButtonSaveOrderForm: false,
	isCancelOrderForm: false,
	sectorVisible: false,
	priseZoneVisible: false,
	parkingVisible: false,
};

const setIsCancelOrderForm: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isCancelOrderForm = !state.isCancelOrderForm;
	} else state.isCancelOrderForm = payload === "on";
};

const setIsButtonSaveOrderForm: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isButtonSaveOrderForm = !state.isButtonSaveOrderForm;
	} else state.isButtonSaveOrderForm = payload === "on";
};

const setIsSaveOrderForm: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isSaveOrderForm = !state.isSaveOrderForm;
	} else state.isSaveOrderForm = payload === "on";
};

const setIsDisabledOrderFormToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isDisabledOrderForm = !state.isDisabledOrderForm;
	} else state.isDisabledOrderForm = payload === "on";
};

const setInfoModalToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.isInfoModal = !state.isInfoModal;
	else state.isInfoModal = payload === "on";
};

const setFilterModalToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isArchiveFilterModal = !state.isArchiveFilterModal;
	} else state.isArchiveFilterModal = payload === "on";
};

const setSettingModalToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isArchiveSettingModal = !state.isArchiveSettingModal;
	} else state.isArchiveSettingModal = payload === "on";
};

const setSubscribeToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") {
		state.isSubscribe = !state.isSubscribe;
	} else state.isSubscribe = payload === "on";
};

const setSearchToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.isSearch = !state.isSearch;
	else state.isSearch = payload === "on";
};

const setExportModalToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.isExport = !state.isExport;
	else state.isExport = payload === "on";
};

const setImportModalToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.isImport = !state.isImport;
	else state.isImport = payload === "on";
};

const setParkingToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.parkingVisible = !state.parkingVisible;
	else state.parkingVisible = payload === "on";
};

const setSectorToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.sectorVisible = !state.sectorVisible;
	else state.sectorVisible = payload === "on";
};

const setPriseZoneToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.priseZoneVisible = !state.priseZoneVisible;
	else state.priseZoneVisible = payload === "on";
};

const resetToggles: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrdersToggles = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_TOGGLES,
	initialState,
	reducers: {
		setPriseZoneToggle,
		setSectorToggle,
		setParkingToggle,
		resetToggles,
		setImportModalToggle,
		setExportModalToggle,
		setInfoModalToggle,
		setFilterModalToggle,
		setSettingModalToggle,
		setSubscribeToggle,
		setSearchToggle,
		setIsDisabledOrderFormToggle,
		setIsSaveOrderForm,
		setIsButtonSaveOrderForm,
		setIsCancelOrderForm,
	},
});

export const archiveOrdersTogglesAction = archiveOrdersToggles.actions;
export const archiveOrdersTogglesReducer = archiveOrdersToggles.reducer;
export type archiveOrdersTogglesState = State;

export default archiveOrdersToggles;
