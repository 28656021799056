import React, { Dispatch, memo } from "react";
import { CheckBox, Option, Row, Select } from "uikit";
import { useTranslation } from "react-i18next";

import { BaseRateAdjustmentType } from "../../../../../../../../services/Tariff/enums";
import {
	useObjectEditor,
	useCurrencyGlobalSettings,
} from "../../../../../../../../hooks";
import { ValueStepper } from "../../../../../../../../components/common";
import tPath from "../../../../constants/tPath";

import { BaseSurchargeKeys } from "./types";

const path = `${tPath}.mainModal.tabs.main.surcharges`;

const BaseSurcharge: React.FC<BaseSurcharge.Props> = memo((props) => {
	const { disabled, options, label, value, onChange } = props;

	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const editor = useObjectEditor(value, onChange);
	try {
		const active = editor.useGetter("active");
		const setActive = editor.useSetter("active");

		const type = editor.useGetter("type");
		const setType = editor.useSetter("type");

		const surchargeValue = editor.useGetter("value");
		const setSurchargeValue = editor.useSetter("value");
		return (
			<Row align="center" gaps="10px*" justify="space-between">
				<CheckBox
					disabled={disabled}
					label={t(`${path}.${label}`) || ""}
					onChange={setActive}
					value={active}
				/>
				<Row gaps="10px*" align="center">
					<Select
						disabled={disabled || !active}
						style={{ width: "310px" }}
						options={options}
						placeholder={t(`${path}.options.placeholder`) || ""}
						value={type ?? undefined}
						onChange={setType as any}
					/>
					<Row gaps="10px*" align="center" style={{ width: 125 }}>
						<ValueStepper
							value={surchargeValue}
							onChange={setSurchargeValue}
							disabled={disabled || !active || !type}
						/>
						{type?.includes("surcharge") && (
							<span>{currencyGlobalSettings}</span>
						)}
					</Row>
				</Row>
			</Row>
		);
	} catch (error) {
		return <Row align="center" gaps="10px*" justify="space-between"></Row>;
	}
});

declare namespace BaseSurcharge {
	interface Props {
		disabled?: boolean;
		label: BaseSurchargeKeys;
		options: Option<any>[];
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		active: boolean;
		type: null | BaseRateAdjustmentType;
		value: number;
	}
}

export default BaseSurcharge;
