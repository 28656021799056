/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */

import React from "react";

interface Props {
	fill?: string;
}

export const EditIcon: React.FC<Props> = ({ fill = "currentColor" }) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.94666 13.7193C2.85417 13.7192 2.76272 13.6998 2.67813 13.6623C2.59354 13.6249 2.51767 13.5703 2.45533 13.502C2.39186 13.4342 2.34343 13.3538 2.31321 13.2661C2.283 13.1783 2.27168 13.0851 2.27999 12.9927L2.44333 11.1967L9.98866 3.654L12.3467 6.01133L4.80333 13.5533L3.00733 13.7167C2.98716 13.7185 2.96691 13.7194 2.94666 13.7193ZM12.8173 5.54L10.46 3.18266L11.874 1.76866C11.9359 1.70668 12.0094 1.65751 12.0904 1.62396C12.1713 1.59041 12.258 1.57314 12.3457 1.57314C12.4333 1.57314 12.52 1.59041 12.601 1.62396C12.6819 1.65751 12.7554 1.70668 12.8173 1.76866L14.2313 3.18266C14.2933 3.24458 14.3425 3.3181 14.376 3.39904C14.4096 3.47997 14.4269 3.56672 14.4269 3.65433C14.4269 3.74194 14.4096 3.82869 14.376 3.90962C14.3425 3.99056 14.2933 4.06408 14.2313 4.126L12.818 5.53933L12.8173 5.54Z"
			fill={fill}
		/>
	</svg>
);
