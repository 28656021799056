import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import ChatMessage from "../../../../services/ChatMessage";
import {
	StyledColumn,
	StyledRow,
	StyledP,
	CardModal,
	CardModalBase,
	Divider,
} from "../../../common";

const Content = styled(StyledColumn)`
	gap: 10px;
	padding: 8px 8px 1rem 8px;
	justify-content: start;
	background: #ffffff;
	width: 500px;
`;

const SOSModalBase: React.FC<SOSModalBase.Props> = ({
	value,
	onSave,
	onClose,
	content,
	footer,
	footerDivider,
	...props
}) => {
	const path = "sos_modal";
	const { t } = useTranslation();

	const executors = useMemo(() => {
		const payload = value?.messages?.map((item) => {
			const { content, executor } = item;

			const text = `${executor?.person?.surname ?? ""} ${
				executor?.person?.name ?? ""
			} ${executor?.person?.fatherName ?? ""} `.trim();
			const alias = executor?.callSign ? `(${executor?.callSign})` : "";
			const phones = executor?.person?.phones
				? executor?.person?.phones
						?.map((item) => item.number || "")
						.join(", ")
				: "";

			return { content, text, alias, phones };
		});
		return payload;
	}, [value]);

	const texts = useMemo(() => {
		const payload = {
			title: t([`${path}.title`, "Danger warning!"]),
			sos: t([`${path}.sos`, "SOS"]),
			save: t([`${path}.save`, `Confirm reading the message!`]),
		};
		return payload;
	}, [t]);

	if (value === null) return <></>;

	return (
		<CardModal
			{...props}
			title={texts.title}
			onClose={onClose}
			onSave={onSave}
			footer={footer}
			textSave={texts.save}
			footerDivider={footerDivider}
			buttonSave={{
				autoFocus: true,
			}}
			buttonClose={{
				visible: false,
			}}
			buttonCross={{
				visible: false,
			}}
		>
			<Content h="300px" overY="scroll" scrollbar>
				<StyledColumn gap="10px" h="100%">
					<StyledRow justify="center" p="10px">
						<StyledP
							font={{ fw: 700, size: "5rem", line: "6rem" }}
							colors="red"
						>
							{texts.sos}
						</StyledP>
					</StyledRow>
					<Divider side="top" />
					{executors?.length &&
						executors.map((item, key) => (
							<StyledRow
								key={key}
								gap="5px"
								p="0 0 0 5px"
								flex={{ wrap: "wrap" }}
							>
								<StyledP
									font={{ fw: 700, size: "15px" }}
									colors="red"
								>
									{item.content}
								</StyledP>
								<StyledP font={{ fw: 700, size: "15px" }}>
									{item.text}
								</StyledP>
								<StyledP font={{ fw: 700, size: "15px" }}>
									{item.alias}
								</StyledP>
								<StyledP font={{ fw: 700, size: "15px" }}>
									{item.phones}
								</StyledP>
							</StyledRow>
						))}
				</StyledColumn>
				{content}
			</Content>
		</CardModal>
	);
};

declare namespace SOSModalBase {
	type Value = {
		messages: ChatMessage.Model[];
	};

	interface Props extends CardModalBase.Props {
		value: Value | null;
		content?: React.ReactNode;
	}
}

export const SOSModal = memo(SOSModalBase);
export default SOSModalBase;
