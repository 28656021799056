import makeTable from "./makeTable";

const accountingExecutorsSecondTable = makeTable({
	allIds: [
		"date",
		"transactionDescription",
		"order",
		"type",
		"source",
		"user",
		"amount",
		"balance",
	],
	i18tPath: (id) => `mainPage.accounting.executors2.columns.${id}`,
	defaultIds: [
		"date",
		"transactionDescription",
		"order",
		"type",
		"source",
		"user",
		"amount",
		"balance",
	],
	initialSortMethod: {
		column: "date",
		type: "asc",
	},
	initialWidths: {
		date: 155, // minimum for excel file format
		transactionDescription: 150,
		order: 150,
		type: 150,
		source: 150,
		user: 150,
		amount: 150,
		balance: 150,
	},
	initialResizable: {
		date: true,
		transactionDescription: true,
		order: true,
		type: true,
		source: true,
		user: true,
		amount: true,
		balance: true,
	},
	initialSortable: {
		date: true,
		transactionDescription: true,
		order: true,
		type: true,
		source: true,
		user: true,
		amount: true,
		balance: true,
	},
});

export default accountingExecutorsSecondTable;
