import React from "react";

import PaymentTypeCellContent from "../components/PaymentTypeCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function paymentType({ width, onResize }: Column.Options) {
	return base(
		ColumnId.PaymentType,
		(item) => <PaymentTypeCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default paymentType;
