/* eslint-disable no-param-reassign */

import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
	value?: string;
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setValue: Reducer<State["value"]> = (state, { payload }) => {
	state.value = payload;
};

const initialState: State = {};

const currentIP = createSlice({
	name: "currentIP",
	initialState,
	reducers: {
		setValue,
	},
});

export default currentIP;
