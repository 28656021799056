import { RefObject } from "react";
import { react } from "uikit";

import Content from "./components/Content";

interface Context {
	contentRef: RefObject<Content.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isContentValid = !!this.context?.contentRef.current?.validate();

		return isContentValid;
	}
}
