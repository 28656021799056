import React from "react";
import { useTranslation } from "react-i18next";
import Root from "./components/Root";
import Text from "./components/Text";

const AddItem: React.FC<AddItem.Props> = ({ disabled, onClick }) => {
	const { t } = useTranslation();
	return (
		<Root
			disabled={disabled}
			align="center"
			justify="center"
			gaps="5px*"
			onClick={onClick}
		>
			<Text>{t("fileList.addItem.str100") ?? ""}</Text>
		</Root>
	);
};

declare namespace AddItem {
	interface Props {
		disabled: boolean;

		onClick?: () => void;
	}
}

export default AddItem;
