import { Option } from "uikit";

// eslint-disable-next-line no-shadow
export enum SystemTabs {
	MAIN = "main",
}

const tabs: Option<string>[] = [
	{
		key: SystemTabs.MAIN,
		value: SystemTabs.MAIN,
		label: "Main",
	},
]; // * label translation is done inside the component through a map

export default tabs;
