import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import modal, {
	ModalWindow,
	nextUID,
} from "../../redux/reducers/modal.reducer";

import Dimmer from "./Dimmer";

const BASE_Z_INDEX = 1000;

const StyledAbsolute = styled.div`
	position: absolute;
	z-index: ${BASE_Z_INDEX};

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;

	& > * {
		pointer-events: all;
	}
`;

const ModalController = () => {
	const dispatch = useTypedDispatch();
	const uid = useRef(nextUID());

	useEffect(() => {
		dispatch(
			modal.actions.pushController({
				type: "controller",
				uid: uid.current,
			}),
		);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			dispatch(modal.actions.removeController(uid.current));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const stack = useTypedSelector((state) => state.modal.stack);
	const allWindows = stack.slice(
		stack.findIndex((w) => w.uid === uid.current) + 1,
	);

	const upperControllerIndex = allWindows.findIndex(
		(w) => w.type === "controller",
	);
	const myWindows = (
		upperControllerIndex === -1
			? allWindows
			: allWindows.slice(0, upperControllerIndex)
	) as ModalWindow[];

	return (
		<StyledAbsolute>
			{myWindows.map((window, i) => (
				<Dimmer
					key={window.uid.toString()}
					dimmer={window.dimmer}
					zIndex={BASE_Z_INDEX + i}
				>
					{window.content}
				</Dimmer>
			))}
		</StyledAbsolute>
	);
};

export default ModalController;
