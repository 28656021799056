import styled from "styled-components";

const Root = styled.div`
	position: relative;

	width: 100%;

	#react-doc-viewer {
		background: transparent;
	}

	#pdf-renderer {
		overflow-y: auto;
		height: calc(100vh - 64px);
	}

	#pdf-controls {
		display: none;
	}

	.navigator-list {
		.page[data-active="true"] {
			.page-image {
				border: 5px solid var(--base-color-focus-violet);
			}
		}
	}
`;

export default Root;
