import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectTariffZones from "../../../../../Modal/components/Content/components/SelectTariffZones";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../../../../../../../../../../../hooks/useModelSubscribe";
import PriceZone from "../../../../../../../../../../../../../../../../../../../../services/PriceZone";

const TariffZonesOutput: React.FC<TariffZonesOutput.Props> = memo((props) => {
	const { value, outsidePriceZones } = props;
	const { t } = useTranslation();

	const lang = useTypedSelector((state) => state.session.language);

	const modelData = useModelSubscribe({}, PriceZone);
	const items = useMemo(() => modelData?.cache, [modelData?.cache]);

	const output = useMemo<string[]>(() => {
		const tariffZones = (items ?? [])
			.filter((item) => value.includes(item.id))
			.map((item) => item?.name[lang]);
		if (outsidePriceZones) {
			const text: string =
				t(
					"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.content.ruleOutput.tariffZonesOutput.str200",
				) ?? "";

			tariffZones.push(text);
		}
		return tariffZones;
	}, [items, lang, outsidePriceZones, value, t]);

	return output.length ? (
		<>
			<div>{output.join(", ")}</div>
			<span>/</span>
		</>
	) : null;
});

declare namespace TariffZonesOutput {
	interface Props {
		value: SelectTariffZones.Value;
		outsidePriceZones: boolean;
	}
}

export default TariffZonesOutput;
