/* eslint-disable import/prefer-default-export */

import React from "react";

interface Props {
	fill: string;
}

export const MenuIcon: React.FC<Props> = ({ fill = "black" }) => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.96413 2.78571C4.55586 2.78571 5.03556 2.30601 5.03556 1.71428C5.03556 1.12255 4.55586 0.642853 3.96413 0.642853C3.3724 0.642853 2.8927 1.12255 2.8927 1.71428C2.8927 2.30601 3.3724 2.78571 3.96413 2.78571Z"
			fill={fill}
		/>
		<path
			d="M3.96413 7.07141C4.55586 7.07141 5.03556 6.59172 5.03556 5.99999C5.03556 5.40825 4.55586 4.92856 3.96413 4.92856C3.3724 4.92856 2.8927 5.40825 2.8927 5.99999C2.8927 6.59172 3.3724 7.07141 3.96413 7.07141Z"
			fill={fill}
		/>
		<path
			d="M3.96413 11.3572C4.55586 11.3572 5.03556 10.8775 5.03556 10.2857C5.03556 9.69399 4.55586 9.21429 3.96413 9.21429C3.3724 9.21429 2.8927 9.69399 2.8927 10.2857C2.8927 10.8775 3.3724 11.3572 3.96413 11.3572Z"
			fill={fill}
		/>
		<path
			d="M8.24989 2.78571C8.84163 2.78571 9.32132 2.30601 9.32132 1.71428C9.32132 1.12255 8.84163 0.642853 8.24989 0.642853C7.65816 0.642853 7.17847 1.12255 7.17847 1.71428C7.17847 2.30601 7.65816 2.78571 8.24989 2.78571Z"
			fill={fill}
		/>
		<path
			d="M8.24989 7.07141C8.84163 7.07141 9.32132 6.59172 9.32132 5.99999C9.32132 5.40825 8.84163 4.92856 8.24989 4.92856C7.65816 4.92856 7.17847 5.40825 7.17847 5.99999C7.17847 6.59172 7.65816 7.07141 8.24989 7.07141Z"
			fill={fill}
		/>
		<path
			d="M8.24989 11.3572C8.84163 11.3572 9.32132 10.8775 9.32132 10.2857C9.32132 9.69399 8.84163 9.21429 8.24989 9.21429C7.65816 9.21429 7.17847 9.69399 7.17847 10.2857C7.17847 10.8775 7.65816 11.3572 8.24989 11.3572Z"
			fill={fill}
		/>
	</svg>
);
