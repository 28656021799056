import React, { useCallback } from "react";
import { TransferTabType } from "../../../../../../../../redux/reducers/softphoneWidget";
import Root from "./components/Root";

interface Props {
	active: Partial<boolean>;
	text: string;
	onChangeNavigation: (type: TransferTabType) => void;
	type: TransferTabType;
}

const NavButton: React.FC<Props> = ({
	active = false,
	text,
	onChangeNavigation,
	type,
}) => {
	const changeNavigation = useCallback(() => {
		onChangeNavigation(type);
	}, [onChangeNavigation, type]);
	return (
		<Root active={active} onClick={changeNavigation}>
			{text}
		</Root>
	);
};

export default NavButton;
