import React, { useMemo, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { SuspenseLoader, SideTab } from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyClients = lazy<React.FC<CustomersPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Clients" /* webpackChunkName: "customers-page-clients" */
	);
	return elem;
});

const LazyCounterparties = lazy<React.FC<CustomersPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Counterparties" /* webpackChunkName: "customers-page-counterparties" */
	);
	return elem;
});

const LazyAgents = lazy<React.FC<CustomersPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Agents" /* webpackChunkName: "customers-page-agents" */
	);
	return elem;
});

const LazyClientGroups = lazy<React.FC<CustomersPage.Props>>(async () => {
	const elem = await import(
		"./tabs/ClientGroups" /* webpackChunkName: "customers-page-client-groups" */
	);
	return elem;
});

const LazyCounterpartyGroups = lazy<React.FC<CustomersPage.Props>>(async () => {
	const elem = await import(
		"./tabs/CounterpartyGroups" /* webpackChunkName: "customers-page-counterparty-groups" */
	);
	return elem;
});

const LazyAgentGroups = lazy<React.FC<CustomersPage.Props>>(async () => {
	const elem = await import(
		"./tabs/AgentGroups" /* webpackChunkName: "customers-page-agent-groups" */
	);
	return elem;
});

const CustomersPage: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.CLIENTS,
					label: t("mainPage.customers.clients.title"),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyClients />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CLIENTS],
				},

				{
					key: TabKeys.COUNTERPARTIES,
					label: t("mainPage.customers.counterparties.title"),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyCounterparties />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.COUNTERPARTIES],
				},

				{
					key: TabKeys.AGENTS,
					label: t("mainPage.customers.agents.title"),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyAgents />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.AGENTS],
				},
				{
					key: TabKeys.CLIENT_GROUPS,
					label: t("mainPage.customers.clientGroups.title"),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyClientGroups />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CLIENT_GROUPS],
				},
				{
					key: TabKeys.COUNTERPARTY_GROUPS,
					label: t("mainPage.customers.counterpartyGroups.title"),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyCounterpartyGroups />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.COUNTERPARTY_GROUPS],
				},
				{
					key: TabKeys.AGENT_GROUPS,
					label: t("mainPage.customers.agentGroups.title"),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyAgentGroups />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.AGENT_GROUPS],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={(newActive) => {
						setActiveKey(newActive);
					}}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace CustomersPage {
	interface Props {}
}

export default CustomersPage;
