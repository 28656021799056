import styled from "styled-components";
import { Column, theme } from "uikit";

const Container = styled(Column)<Container.Props>`
	position: relative;

	min-height: ${({ height }) => height}px;
	height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;

	background-color: ${theme.colors.background_color};

	flex-shrink: 0;
`;

declare namespace Container {
	interface Props {
		height: number;
	}
}

export default Container;
