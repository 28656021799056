import { TFunction } from "i18next";

enum Columns {
	NAME = "name",
	TYPE = "type",
	TIME = "time",
	PERIOD = "period",
	TOTAL = "total",
	MODE = "mode",
	PARAMETERS = "parameters",
}

type ColumnType = {
	id: string;
	label: string;
	width?: number;
};

const getColumns = (t: TFunction): ColumnType[] => [
	{
		id: Columns.NAME,
		label: t("commissions.columns.name") ?? "",
		width: 230,
	},
	{
		id: Columns.TYPE,
		label: t("commissions.columns.type") ?? "",
		width: 150,
	},
	{
		id: Columns.TIME,
		label: t("commissions.columns.time") ?? "",
		width: 200,
	},
	{
		id: Columns.PERIOD,
		label: t("commissions.columns.period") ?? "",
		width: 140,
	},
	{
		id: Columns.MODE,
		label: t("commissions.columns.mode") ?? "",
	},
]; // columns

export { getColumns, Columns };
