import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../../../../../services/Transaction";
import { StyledP } from "../../../../../../../../../../components/common";

const PaymentAccountType: React.FC<PaymentAccountType.Props> = memo(
	({ value }) => {
		const { t } = useTranslation();

		const { type } = value;

		const colors = useMemo(() => {
			if (type === "bonus") return "#800080";

			return "";
		}, [type]);

		return (
			<StyledP colors={colors}>
				{type ? t([`paymentAccount.${type}`, ""]) : ""}
			</StyledP>
		);
	},
);

declare namespace PaymentAccountType {
	interface Props {
		value: Value;
	}

	interface Value {
		type: Transaction.PaymentAccountRelation["type"];
		isProvider: Transaction.PaymentAccountRelation["isProvider"];
	}
}

export default PaymentAccountType;
