import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { useInternal } from "uikit";

import { useTypedDispatch } from "../../redux/store";
import linksConstants from "../../redux/constants/links.constants";
import getServices from "../../redux/services/Preferences/Services/getServices";
import getCompanies from "../../redux/services/Company/getCompanies";

import s from "./index.module.css";
import NavInfo from "./components/NavInfo/NavInfo";
import ScreenDirectory from "./components/ScreenDirectory/ScreenDirectory";

function DirectoryPages(props) {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const [stringPage] = useInternal(props.match.params.pageName);

	useEffect(() => {
		dispatch(getServices());
		dispatch(getCompanies());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={s.containerDirectory}>
			<div className={s.subHeader}>
				<Link
					className={s.linkMain}
					to={linksConstants.index}
					activeClassName={s.activeLink}
				>
					{t("pages.preferencesPages.str150") ?? ""}
				</Link>
				<p className={s.sign}>/</p>
				<Link
					className={s.linkDirectory}
					to={linksConstants.preferences.index}
					activeClassName={s.activeLink}
				>
					{t("pages.preferencesPages.str151") ?? ""}
				</Link>
			</div>

			<main className={s.main}>
				<NavInfo currentUrl={props.match.url} />
				<ScreenDirectory stringPage={stringPage} />
			</main>
		</div>
	);
}

export default withRouter(DirectoryPages);
