/* eslint-disable import/no-unresolved */

import React from "react";
import { Icon, InputGroup, theme, react } from "uikit";
import {
	InputifiedComponentProps,
	InputifyComponentProps,
} from "uikit/dist/utils/react";
import { useTranslation } from "react-i18next";

import SearchIconBorders from "../SearchIconBorders";
import SearchTextBox from "../SearchTextBox";

const SearchBar = react.inputify<SearchBar.Props, SearchBar.Value>(
	({ value, placeholder, onChange, ...props }) => {
		const { t } = useTranslation();
		return (
			<InputGroup.InputGroup {...props} sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={placeholder ?? t("searchBar.str0") ?? ""}
					value={value}
					onChange={onChange}
				/>
			</InputGroup.InputGroup>
		);
	},
);

declare namespace SearchBar {
	type Value = string;

	interface PropsBase {
		placeholder?: string;
	}

	type InternalProps = PropsBase & InputifyComponentProps<Value>;
	type Props = PropsBase & InputifiedComponentProps<Value>;
}

export default SearchBar;
