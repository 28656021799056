import { RefObject } from "react";
import { react } from "uikit";

import ObjectTabContent from "./components/ObjectTabContent";
import ObjectGroupTabContent from "./components/ObjectGroupTabContent";

import EditModal from ".";

interface Context {
	objectTabContentRef: RefObject<ObjectTabContent.Controller>;
	objectGroupTabContentRef: RefObject<ObjectGroupTabContent.Controller>;
	type: EditModal.Type;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		return this.context?.type === "object"
			? this.context?.objectTabContentRef.current?.validate()
			: this.context?.objectGroupTabContentRef.current?.validate();
	}
}
