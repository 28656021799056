import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	NAME = "name",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.NAME, label: "", width: 320 },
];

export const defaultColumnsId = columns.map(({ id }) => id);
