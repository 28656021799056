import React, { memo, useCallback, useMemo, useState } from "react";
import { isBoolean, isNumber } from "lodash";
import { useTranslation } from "react-i18next";
import { useInternal } from "uikit";

import { ListSelect, ListSelectMemo } from "../../../Orders";
import Style, { StyledGrid, StyledP, StyledRow } from "../../styles";
import { Colors } from "../../interface";

const MultiSelectWithModalBase = <
	OptionValue extends MultiSelectWithModalBase.Value,
	ValueType extends MultiSelectWithModalBase.Value,
>({
	value,
	disabled,
	title,
	options,
	allText,
	allActiveText,
	actives,
	notActives,
	onChange,
	onClose,
	pullUpItemInsideArray,
	required,
	footer,
	header,
	headerDivider,
	footerDivider,
	hiddenButton = false,
	showSelectAll,
	showAllItem,
	modalSetting,
	error,
	transactionText,
	sort,
	optionsLength,
	valueLength,
	selectOnlyOne,
	...props
}: MultiSelectWithModalBase.Props<OptionValue, ValueType>) => {
	const { t } = useTranslation();
	const allNotActiveTr = useMemo(() => {
		const all = t("filter.options.all");
		const inactive = t("filter.options.inactive");
		return `${all} ${inactive}`;
	}, [t]);
	const [internalValue, setInternalValue] = useInternal(value ?? []);
	const [showModal, setShowModal] = useState<boolean>(false);

	const optionByKey = useMemo(
		() =>
			options.reduce((accumulator, option) => {
				accumulator[option.value] = option;

				return accumulator;
			}, {} as Record<any, ListSelect.Option>) ?? {},
		[options],
	);

	const valueText = useMemo(() => {
		const existValues = internalValue.map((item) => item.value);
		const activesValue = Array.isArray(actives)
			? actives.map((item) => item.value)
			: null;
		const notActivesValue = Array.isArray(notActives)
			? notActives.map((item) => item.value)
			: null;
		const isAllActive = activesValue?.length
			? activesValue.every((item) => existValues.includes(item))
			: null;
		const isNotAllActive = notActivesValue?.length
			? notActivesValue.every((item) => existValues.includes(item))
			: null;
		const addAllActiveText =
			isBoolean(isAllActive) && isAllActive ? allActiveText : "";
		const addAllNotActiveText =
			isBoolean(isNotAllActive) && isNotAllActive ? allNotActiveTr : "";

		if (isAllActive !== null && isNotAllActive !== null) {
			if (addAllActiveText && !addAllNotActiveText) {
				const values = internalValue
					.filter((item) => item.active === false)
					.map((item) => optionByKey[item.value]?.name)
					.filter((name) => !!name)
					.join(",  ");

				return `${addAllActiveText}${
					values ? `, ${values}` : ""
				}`.trim();
			}

			if (!addAllActiveText && addAllNotActiveText) {
				const values = internalValue
					.filter((item) => item.active === true)
					.map((item) => optionByKey[item.value]?.name)
					.filter((name) => !!name)
					.join(",  ");

				return `${addAllNotActiveText}${
					values ? `, ${values}` : ""
				}`.trim();
			}

			if (addAllActiveText && addAllNotActiveText) {
				return allText || "";
			}

			return internalValue
				.map((item) => optionByKey[item.value]?.name)
				.filter((name) => !!name)
				.join(",  ");
		}

		if (
			allText &&
			isNumber(optionsLength) &&
			isNumber(valueLength) &&
			valueLength === optionsLength
		) {
			return allText;
		}

		if (allText && options.length === value.length) return allText;
		return internalValue
			.map((item) => optionByKey[item.value]?.name)
			.filter((name) => !!name)
			.join(",  ");
	}, [
		allActiveText,
		optionsLength,
		valueLength,
		allText,
		options.length,
		value.length,
		internalValue,
		optionByKey,
		actives,
		notActives,
		allNotActiveTr,
	]);

	const hasPlaceholder = useMemo(
		() => (valueText.length === 0 ? Colors.PLACEHOLDER : Colors.TEXT_DARK),
		[valueText.length],
	);

	const text = useMemo(() => valueText || (title ?? ""), [title, valueText]);

	const rootOnClick = useCallback(
		() => !disabled && setShowModal(true),
		[disabled],
	);

	const modalOnSubmit = useCallback(
		(value: any) => {
			onChange?.(value);
			setInternalValue(value);
			setShowModal(false);
		},
		[onChange, setInternalValue],
	);

	const modalOnClose = useCallback<ListSelect.Props["onClose"]>(
		(event) => {
			if (onClose) onClose(event);
			setShowModal(false);
		},
		[onClose],
	);

	return (
		<StyledGrid
			areas=""
			w="100%"
			cursor={disabled ? "not-allowed" : "pointer"}
			onClick={rootOnClick}
			bgC={Colors.BACKGROUND_WHITE}
			br="5px"
		>
			<StyledRow
				overY="auto"
				h="max-content"
				cursor={disabled ? "not-allowed" : "pointer"}
				p="6px 6px 6px 12px"
				b="1px solid"
				br="5px"
				bc={error ? "red" : "#DEE0E2"}
				bgC={
					disabled
						? Colors.BACKGROUND_DISABLE
						: Colors.BACKGROUND_WHITE
				}
				css="
				:hover {
					border: 1px solid #21333f;
				}
			 "
			>
				<StyledP
					w="100%"
					overY="auto"
					colors={hasPlaceholder}
					h={{ max: "calc(16px * 4)" }}
					scrollbar
					text={{ ws: "normal" }}
					{...props}
				>
					{text}
				</StyledP>
			</StyledRow>

			{showModal && (
				<ListSelectMemo
					value={value}
					title={title}
					options={options}
					onClose={modalOnClose}
					onSubmit={modalOnSubmit}
					pullUpItemInsideArray={pullUpItemInsideArray}
					required={required}
					footer={footer}
					header={header}
					headerDivider={headerDivider}
					footerDivider={footerDivider}
					hiddenButton={hiddenButton}
					showSelectAll={showSelectAll}
					showAllItem={showAllItem}
					modalSetting={modalSetting}
					transactionText={transactionText}
					sort={sort}
					selectOnlyOne={selectOnlyOne}
				/>
			)}
		</StyledGrid>
	);
};

declare namespace MultiSelectWithModalBase {
	type Option = ListSelect.Option;
	type Value = ListSelect.Options;
	type ListSelectProps = ListSelect.Props;

	export interface Setting {
		disabled?: boolean;
		required?: boolean;
		error?: boolean | string;

		footer?: React.ReactNode;
		footerDivider?: boolean;
		headerDivider?: boolean;
		header?: React.ReactNode;
		onClose?: ListSelect.Props["onClose"];
		modalSetting?: ListSelect.Props["modalSetting"];
		transactionText?: ListSelect.Props["transactionText"];
		sort?: ListSelect.Props["sort"];

		showAllItem?: boolean;
		showSelectAll?: boolean;
		selectOnlyOne?: boolean;
		pullUpItemInsideArray?: boolean;
	}

	export interface PropsBase<
		OptionValue extends Value,
		ValueType extends Value,
	> extends Setting {
		value: ValueType;
		options: OptionValue;
		actives?: OptionValue;
		notActives?: OptionValue;
		onChange?: (value: ValueType) => void;
		onSave?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

		showAllItem?: boolean;
		showSelectAll?: boolean;
		selectOnlyOne?: boolean;
		hiddenButton?: boolean;
		pullUpItemInsideArray?: boolean;
		allText?: string;
		allActiveText?: string;
		title?: string;
		optionsLength?: number;
		valueLength?: number;
	}

	type Props<
		OptionValue extends Value,
		ValueType extends Value,
	> = Style.TextType & PropsBase<OptionValue, ValueType>;
}

export const MultiSelectWithModal = memo(MultiSelectWithModalBase);

export default MultiSelectWithModalBase;
