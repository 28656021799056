/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import EditModalBase from "../../../../../../../components/EditModal";
import Content from "./components/Content";
import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import Language from "../../../../../../../services/Language";
import Root from "./components/Root";
import PushNotice from "../../../../../../../services/PushNotice";
import Header from "./components/Header";
import translationPath from "../../constants/translationPath";
import ClarificationModal from "../../../../../../../components/DeleteModal";
import InternalController from "./Controller";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({
		value,
		onCancel,
		onSave,
		createdAt,
		headerTitle,

		showClarificationModal,
		setShowClarificationModal,
		preparedTemplate,
		setPreparedTemplate,

		controller,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const { t } = useTranslation();

		const [internalValue, setInternalValue] = useState(value);

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue as PushNotice.New | PushNotice.Modified);
		}, [contentRef, internalValue, onSave]);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const orderTemplate = valueEditor.useGetter("orderTemplate");
		const setOrderTemplate = valueEditor.useSetter("orderTemplate");

		const contentValues = valueEditor.usePicker(
			[
				"name",
				"title",
				"lang",
				"destinationApp",
				"templateText",
				"taxiServiceIds",
				"taxiServices",
				"isValidityPeriod",
				"validityPeriod",
				"isDefault",
				"action",
				"type",
				"additionalFields",
				"bigPicture",
				"largeIcon",
			],
			"json",
		);
		const contentOnChange = valueEditor.useAssigner();

		const cancelHandler = useCallback(() => {
			setShowClarificationModal(false);
			setPreparedTemplate(null);
		}, [setPreparedTemplate, setShowClarificationModal]);

		const confirmHandler = useCallback(() => {
			if (preparedTemplate) {
				onSave(preparedTemplate, true);
			}
			setShowClarificationModal(false);
		}, [onSave, preparedTemplate, setShowClarificationModal]);

		return (
			<EditModalBase
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Row gaps="20px*">
						<CheckBox
							label={t(`${translationPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
						<CheckBox
							label={
								t(`${translationPath}.modal.orderTemplate`) ||
								""
							}
							disabled={contentValues.isDefault}
							value={orderTemplate}
							onChange={setOrderTemplate}
						/>
					</Row>
				}
			>
				<Root sizes="auto! 1fr">
					<Header title={headerTitle} createdAt={createdAt} />
					<Content
						ref={setContentRef}
						value={contentValues}
						onChange={contentOnChange}
					/>
				</Root>
				{showClarificationModal && (
					<ClarificationModal
						label={t(`${translationPath}.clarificationModal`) || ""}
						onCancel={cancelHandler}
						onConfirm={confirmHandler}
					/>
				)}
			</EditModalBase>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		headerTitle: string;
		createdAt: string;
		lang: Language;
		onCancel: () => void;
		onSave: (
			template: PushNotice.New | PushNotice.Modified,
			force?: boolean,
		) => Promise<void>;

		showClarificationModal: boolean;
		setShowClarificationModal: Dispatch<boolean>;
		preparedTemplate: PreparedTemplate;
		setPreparedTemplate: Dispatch<PreparedTemplate>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = Content.Value &
		Partial<PushNotice.NonEditableProperties> & {
			active: boolean;
			orderTemplate: boolean;
		};
	type PreparedTemplate = PushNotice.New | PushNotice.Modified | null;
}

export default Modal;
