import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../components/FieldsContainer";
import { StyledColumn } from "../../../../../../components/common";

import tPath from "../../constants/tPath";

import Item from "./components/Item";

const MinimumOrderValueBlock: React.FC<MinimumOrderValueBlock.Props> = memo(
	({ value, onChange, root }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const minOrderCost = valueEditor.useGetter("minOrderCost");
		const setMinOrderCost = valueEditor.useSetter("minOrderCost");

		const minPreliminaryOrderCost = valueEditor.useGetter(
			"minPreliminaryOrderCost",
		);
		const setMinPreliminaryOrderCost = valueEditor.useSetter(
			"minPreliminaryOrderCost",
		);

		return (
			<FieldsContainer
				label={
					t(
						`${tPath}.mainModal.tabs.main.minimumOrderValue.blockName`,
					) || "Main settings"
				}
			>
				<StyledColumn gap="16px" w={{ max: "max-content" }}>
					<Item
						disabled={root}
						title={t(
							`${tPath}.mainModal.tabs.main.minimumOrderValue.minOrderCost`,
						)}
						value={minOrderCost}
						onChange={setMinOrderCost}
					/>
					<Item
						disabled={root}
						title={t(
							`${tPath}.mainModal.tabs.main.minimumOrderValue.minPreliminaryOrderCost`,
						)}
						value={minPreliminaryOrderCost}
						onChange={setMinPreliminaryOrderCost}
					/>
				</StyledColumn>
			</FieldsContainer>
		);
	},
);

declare namespace MinimumOrderValueBlock {
	interface Props {
		value: Value;
		onChange: (x: Partial<Value>) => void;
		root: boolean;
	}

	interface Value {
		minOrderCost: Item.Value;
		minPreliminaryOrderCost: Item.Value;
	}
}

export default MinimumOrderValueBlock;
