import React, { Suspense, useMemo, useState, lazy } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, Option, Tabs, Key } from "uikit";

import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../../components/common";

import { useArchiveOrders } from "../../../../../../hooks";
import InfoModalHistory from "../../../../../../../components/InfoModal/InfoModalHistory";

import InfoModalMap from "../InfoModalMap";
import InfoModalCalls from "../InfoModalCalls";

type Tab = "map" | "history" | "events" | "conversations" | "files" | "notes";

const TabsWrap = styled(Tabs)`
	& > div:hover {
		color: #03a9f4;
	}
`;

const LazyInfoModalHistory = lazy<React.FC<InfoModalHistory.Props>>(
	async () => {
		const elem = await import(
			"../../../../../../../components/InfoModal/InfoModalHistory" /* webpackChunkName: "info-modal-history-tab" */
		);
		return elem;
	},
);

const LazyInfoModalMap = lazy<React.FC<InfoModalMap.Props>>(async () => {
	const elem = await import(
		"../InfoModalMap" /* webpackChunkName: "info-modal-map-tab" */
	);
	return elem;
});

const LazyInfoModalCalls = lazy<React.FC<InfoModalCalls.Props>>(async () => {
	const elem = await import(
		"../InfoModalCalls" /* webpackChunkName: "info-modal-calls-tab" */
	);
	return elem;
});

// TODO: This should be returned from the service.
const OPTIONS: Option<Tab>[] = [
	{
		key: "map",
		label: "mainPage.archives.orders.modal.tabs.map",
		value: "map",
	},
	{
		key: "events",
		label: "mainPage.archives.orders.modal.tabs.events",
		value: "events",
	},
	{
		key: "conversations",
		label: "mainPage.archives.orders.modal.tabs.conversations",
		value: "conversations",
	},
	{
		key: "files",
		label: "mainPage.archives.orders.modal.tabs.files",
		value: "files",
	},

	// {
	// 	key: "notes",
	// 	label: "mainPage.archives.orders.modal.tabs.notes",
	// 	value: "notes",
	// },
];

const InfoModalTabs: React.FC<InfoModalTabs.Props> = () => {
	const { t } = useTranslation();
	const {
		orders: { order },
		history: { history },
		getOrderHistory,
	} = useArchiveOrders();

	const [selected, setSelected] = useState<Key>("map");

	const options = useMemo(
		() => OPTIONS.map((tab) => ({ ...tab, label: t(tab.label) })),
		[t],
	);
	useMemo(() => {
		switch (selected as Tab) {
			case "events":
				if (order) getOrderHistory();
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected, order]);

	return (
		<Column
			maxedHeight
			maxedWidth
			sizes={"35px 1fr"}
			style={{ overflow: "hidden" }}
		>
			<TabsWrap
				value={selected}
				onChange={setSelected}
				options={options}
				variant="bookmarks"
			/>

			<StyledRow>
				<Suspense fallback={<SuspenseLoader />}>
					{selected === "map" && <LazyInfoModalMap />}
					{selected === "events" && (
						<LazyInfoModalHistory
							history={history}
							maxHeight="83%"
						/>
					)}
					{selected === "conversations" && <LazyInfoModalCalls />}
					{selected === "files" && <SuspenseLoader />}
				</Suspense>
			</StyledRow>
		</Column>
	);
};

declare namespace InfoModalTabs {
	interface Props {}
}

export default InfoModalTabs;
