/* eslint-disable no-shadow */

import React, { Dispatch, useMemo } from "react";
import { clone } from "lodash";
import Group from "./components/Group";
import Root from "./components/Root";
import Splitter from "./components/Splitter";
import Language from "../../../../../../../../../../../../services/Language";

const Container: React.FC<Container.Props> = ({
	value,
	editing,

	language,

	onChange,
	onChangeEditing,
}) => {
	const groups = useMemo(
		() =>
			value.reduce((accumulator, item) => {
				accumulator[item.taxiServiceId] =
					accumulator[item.taxiServiceId] ?? [];

				accumulator[item.taxiServiceId].push(item);

				return accumulator;
			}, {} as Record<number, Container.Value>),
		[value],
	);

	return (
		<Root gaps="6px*" maxedWidth>
			{Object.entries(groups).map(([taxiServiceId, items], index) => (
				<>
					<Group
						key={taxiServiceId}
						value={items}
						editing={editing}
						language={language}
						onChange={(groupValue, details) => {
							const newValue =
								details.type === "set-selection"
									? value.map((item) => ({
											...item,
											selected: false,
									  }))
									: clone(value);

							groupValue.forEach((editedItem) => {
								const itemIndex = newValue.findIndex(
									(item) => editedItem.id === item.id,
								);

								newValue[itemIndex] = editedItem;
							});

							onChange(newValue);
						}}
						onChangeEditing={onChangeEditing}
					/>
					{index !== Object.keys(groups).length - 1 ? (
						<Splitter />
					) : null}
				</>
			))}
		</Root>
	);
};

declare namespace Container {
	type Item = Group.Item;
	type Value = Item[];

	interface Props {
		value: Value;
		editing: boolean;

		language: Language;

		onChange: Dispatch<Value>;
		onChangeEditing: Dispatch<boolean>;
	}
}

export default Container;
