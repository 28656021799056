import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { StyledP } from "../../../../../../../../../../components/common";

const Footer: React.FC<Footer.Props> = ({
	value,
	disabled,
	onChange,
	loading,
}) => {
	const valueEditor = useObjectEditor(value, onChange);

	// const activeValue = valueEditor.useGetter("active");
	// const activeOnChange = valueEditor.useSetter("active");

	const defaultValue = valueEditor.useGetter("default");
	const defaultOnChange = valueEditor.useSetter("default");

	const rootValue = valueEditor.useGetter("root");

	const { t } = useTranslation();
	return (
		<Row gaps="24px">
			{/* <CheckBoxWithContent
				gap="10px"
				disabled={disabled}
				value={activeValue}
				onChange={activeOnChange}
			>
				{t("pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.footer.str150") ?? ""}
			</CheckBoxWithContent> */}
			<CheckBoxWithContent
				gap="10px"
				disabled={rootValue || disabled}
				value={defaultValue}
				onChange={defaultOnChange}
			>
				{t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.footer.str151",
				) ?? ""}
			</CheckBoxWithContent>
			{loading && (
				<StyledP
					font={{
						fw: 500,
						size: "16px",
					}}
				>
					{t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.footer.str200",
					) ?? ""}
				</StyledP>
			)}
		</Row>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
		root: boolean;
		default: boolean;
	}

	interface Props {
		value: Value;

		disabled: boolean;
		loading: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Footer;
