import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import LightInputModal from "../../../../../../components/LightInputModal";

import Content from "./components/Content";

const CloseOrderModal: React.FC<CloseOrderModal.Props> = ({
	onSubmit,
	...props
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState<Content.Value>({
		addClientToBlackList: false,
	});

	const modalOnSubmit = useCallback(() => {
		onSubmit?.(value);
	}, [onSubmit, value]);

	return (
		<LightInputModal
			{...props}
			draggable={false}
			title={t("pages.mainPage.pages.orders.closeOrderModal.str0") ?? ""}
			onSubmit={modalOnSubmit}
		>
			<Content value={value} onChange={setValue} />
		</LightInputModal>
	);
};

declare namespace CloseOrderModal {
	type Value = Content.Value;

	interface Props
		extends Omit<
			LightInputModal.Props,
			"draggable" | "title" | "onSubmit"
		> {
		onSubmit?: Dispatch<Value>;
	}
}

export default CloseOrderModal;
