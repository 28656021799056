/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { react, useRefWithSetter } from "uikit";
import Map from "../../../../../../../../../../redux/services/Map";
import InternalController from "./Controller";
import EditModalBase from "../../../../../../../../../../components/EditModal";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import WithoutNullableKeys from "../../../../../../../../../../types/WithoutNullableKeys";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import Dispatcher from "../../../../../../../../../../services/Dispatcher";
import { useTypedSelector } from "../../../../../../../../../../redux/store";

const ModalWorkHistory = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const currentIP = useTypedSelector((state) => state.currentIP.value) ?? "";

	const [history, setHistory] = useState<Dispatcher.History>([]);

	useEffect(() => {
		(async () => {
			if (value.id) setHistory(await Dispatcher.getHistory(value.id));
		})();
	}, [value.id]);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<Header.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const contentOnChange = valueEditor.useAssigner();

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={value.headerTitle}
					createdAt={value.createdAt}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					value={internalValue}
					type={tab}
					language={language}
					currentIP={currentIP}
					onChange={contentOnChange}
				/>
			</Root>
		),
		[
			contentOnChange,
			currentIP,
			history,
			internalValue,
			language,
			setContentRef,
			tab,
			value.createdAt,
		],
	);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(valueEditor.value as EditModal.Value.Validated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	return (
		<EditModalBase onCancel={onCancel} onSave={modalOnSave}>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value {
		id?: number;

		createdAt?: string;
		headerTitle: string;
	}

	namespace Value {
		type ValidationPropertyNames =
			| "firstName"
			| "login"
			| "password"
			| "taxiServiceIds"
			| "defaultTaxiServiceId"
			| "roleId";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, any>>>;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ModalWorkHistory;
