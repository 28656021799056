import { combineReducers } from "@reduxjs/toolkit";

import { orderChatsAction, orderChatsReducer, orderChatsState } from "./chats";
import {
	orderChatsFiltersAction,
	orderChatsFiltersReducer,
	orderChatsFiltersState,
} from "./filter";

export type StateChats = {
	chat: orderChatsState;
	filter: orderChatsFiltersState;
};

export const chats = combineReducers<StateChats>({
	chat: orderChatsReducer,
	filter: orderChatsFiltersReducer,
});

export const ordersChatAction = {
	chat: orderChatsAction,
	filter: orderChatsFiltersAction,
};

export default chats;
