/* eslint-disable import/no-unresolved */

import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, react, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Language from "../../../../../../../../../../../../services/Language";
import TaxiServiceForm from "../../../../../../../../../../../../components/TaxiServiceForm";
import {
	Names,
	NamesBase,
} from "../../../../../../../../../../../../components/common";

// import NameForm from "./components/NameForm";
import FieldContainer from "./components/FieldContainer";
import InternalController from "./Controller";

const Form = react.withController<Form.InputifiedPropsBase, InternalController>(
	react.inputify<Form.PropsBaseWithController, Form.Value>(
		({ value, language, controller, onChange }) => {
			const { t } = useTranslation();
			const taxiServiceFormRef =
				useRef<TaxiServiceForm.Controller | null>(null);
			// const nameFormRef = useRef<NameForm.Controller | null>(null);
			const nameFormRef = useRef<NamesBase.Controller | null>(null);
			const defaultValue = useMemo<Form.Value>(() => ({}), []);
			const [internalValue] = useInternal(value ?? defaultValue);

			controller.setContext({
				taxiServiceFormRef,
				nameFormRef,
			});

			const taxiServiceFormOnChange = useCallback(
				(taxiServiceId: TaxiServiceForm.Value) =>
					onChange({ ...internalValue, taxiServiceId }),
				[internalValue, onChange],
			);

			const nameFormOnChange = useCallback(
				(name: NamesBase.Value) => {
					onChange({
						...internalValue,

						name,
					});
				},
				[internalValue, onChange],
			);

			const onSuburbanChange = useCallback(
				(suburban: Form.Value["suburban"]) => {
					onChange({
						...internalValue,
						suburban,
					});
				},
				[internalValue, onChange],
			);

			const name = useMemo(
				() => internalValue?.name || {},
				[internalValue?.name],
			);

			return (
				<Column gaps="24px*">
					<FieldContainer
						title={
							t(
								"pages.preferencesPages.screenDirectory.priceZones.editModal.content.form.str0",
							) ?? ""
						}
					>
						<TaxiServiceForm
							ref={taxiServiceFormRef}
							value={internalValue.taxiServiceId}
							language={language}
							onChange={taxiServiceFormOnChange}
						/>
					</FieldContainer>
					<FieldContainer
						title={
							t(
								"pages.preferencesPages.screenDirectory.priceZones.editModal.content.form.str1",
							) ?? ""
						}
					>
						{/* <NameForm
							ref={nameFormRef}
							placeholder={
								t(
									"pages.preferencesPages.screenDirectory.priceZones.editModal.content.form.str1",
								) ?? ""
							}
							value={internalValue.name}
							onChange={nameFormOnChange}
						/> */}

						<Names
							ref={nameFormRef}
							require
							value={name}
							onChange={nameFormOnChange}
							placeholder="pages.preferencesPages.screenDirectory.priceZones.editModal.content.form.str1"
						/>
					</FieldContainer>
					<CheckBoxWithContent
						gap="18px"
						value={internalValue.suburban}
						onChange={onSuburbanChange}
					>
						{t(
							"pages.preferencesPages.screenDirectory.priceZones.editModal.content.form.str150",
						) ?? ""}
					</CheckBoxWithContent>
				</Column>
			);
		},
	),
	InternalController,
);

declare namespace Form {
	interface Value {
		taxiServiceId?: number;
		suburban?: boolean;
		name?: NamesBase.Value;
	}

	interface PropsBase {
		language: Language;
	}

	interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default Form;
