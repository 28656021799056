import React from "react";
import { Icon } from "uikit";
import Root from "./components/Root";
import {
	DispatcherPhone,
	ExecutorPhone,
} from "../../../../../../../../../../SoftphoneWidget/components/Transfer/types";

interface Props {
	data: DispatcherPhone | ExecutorPhone;
}

const Avatar: React.FC<Props> = ({ data }) => (
	<Root>
		{data.name ? data.name[0] : <Icon id="chat-no-avatar" size={32} />}
	</Root>
);

export default Avatar;
