import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Button } from "uikit";

import Root from "./components/Root";

const Footer = memo<Footer.Props>(({ canSubmit, onCancel, onSubmit }) => {
	const { t } = useTranslation();
	return (
		<Root align="center" justify="right">
			<Row gaps="10px">
				<Button.Button
					variant="secondary"
					text={t("lightInputModal.footer.str0") ?? ""}
					onClick={onCancel}
				/>
				<Button.Button
					disabled={!canSubmit}
					tabIndex={0}
					text={t("lightInputModal.footer.str1") ?? ""}
					onClick={onSubmit}
				/>
			</Row>
		</Root>
	);
});

declare namespace Footer {
	interface Props {
		canSubmit: boolean;

		onCancel: () => void;
		onSubmit: () => void;
	}
}

export default Footer;
