/* eslint-disable no-shadow */

import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
} from "react";
import {
	CheckBox,
	Column,
	Table,
	useRefWithSetter,
	useRender,
	useSize,
} from "uikit";
import { SortType } from "rsuite-table";
import { clone } from "lodash";

import LightTable from "../../../LightTable";
import TableSettings from "../../../TableSettings";
import mapByKey from "../../../../utils/mapByKey";

type Key = string | number;

function ModelTable<OptionValue>({
	selected,
	sort,
	columnIds,

	disabled,
	loading,
	data,
	defaultColumnIds,

	isOptionActive,

	Columns,

	onChangeSelected,
	onChangeSort,
	onChangeColumnIds,

	onLoadMore,
}: ModelTable.Props<OptionValue>) {
	const render = useRender();

	const canLoadMoreRef = useRef(true);
	const [tableRootRef, setTableRootRef] =
		useRefWithSetter<HTMLDivElement | null>(null);
	const tableRef = useRef<Table.Ref | null>(null);

	const tableRootSize = useSize(tableRootRef.current);

	const ColumnById = useMemo(() => mapByKey(Columns, "id"), [Columns]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableRowClassName = useCallback(
		(item) => {
			if (item && !isOptionActive(item)) return "not-active";

			return "";
		},
		[isOptionActive],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const tableSettingsColumns = useMemo(
		() =>
			Columns.map((Column) => ({
				id: Column.id,
				label: Column.name,
			})),
		[Columns],
	);

	useLayoutEffect(() => {
		render(true);
	}, [render]);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<div ref={setTableRootRef}>
				<LightTable
					height={tableRootSize.height}
					ref={tableRef}
					fillHeight
					virtualized
					shouldUpdateScroll={false}
					headerHeight={44}
					rowHeight={44}
					sortColumn={sort.column}
					sortType={sort.type}
					loading={loading}
					data={data}
					rowClassName={tableRowClassName}
					onScroll={tableOnScroll}
					onSortColumn={(column, type) =>
						onChangeSort({ column, type })
					}
				>
					<LightTable.Column width={36}>
						<LightTable.HeaderCell verticalAlign="middle">
							<CheckBox
								value={
									!!data.length &&
									data.every((item) =>
										selected.includes(item.key),
									)
								}
								disabled={disabled}
								onChange={(value) =>
									onChangeSelected(
										value
											? selected.concat(
													data
														.filter(
															(item) =>
																!selected.includes(
																	item.key,
																),
														)
														.map(
															(item) => item.key,
														),
											  )
											: selected.filter(
													(key) =>
														!data.find(
															(item) =>
																item.key ===
																key,
														),
											  ),
									)
								}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(item) => (
								<CheckBox
									value={selected.includes(item.key)}
									disabled={disabled}
									onChange={(selected) =>
										setItemSelection(item.key, selected)
									}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>

					{columnIds.map((columnId) =>
						ColumnById[columnId]?.render(),
					)}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={tableSettingsColumns}
				onChange={onChangeColumnIds}
			/>
		</Column>
	);
}

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Column {
		id: string;
		name: string;
		render: () => ReactElement;
	}

	type Option<Value> = { key: Key } & Value;

	interface Props<OptionValue> {
		selected: Key[];
		sort: Sort;
		columnIds: string[];

		disabled: boolean;
		loading: boolean;
		data: Option<OptionValue>[];
		defaultColumnIds: string[];

		isOptionActive: (option: Option<OptionValue>) => boolean;

		Columns: Column[];

		onChangeSelected: Dispatch<Key[]>;
		onChangeSort: Dispatch<Sort>;
		onChangeColumnIds: Dispatch<string[]>;

		onLoadMore: () => void;
	}
}

export default ModelTable;
