import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	position: absolute;

	bottom: 51px;
	left: 12px;

	width: 570px;
	height: 562px;

	border-radius: 16px;
	background-color: ${theme.colors.white};
	box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 24px 2px;
`;

export default Root;
