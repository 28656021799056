import { IconButton } from "@mui/material";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Budge } from "uikit";

import sendSMSByOrder from "../../../../../../../../redux/services/Order/sendSmsByOrder";
import { useTypedSelector } from "../../../../../../../../redux/store";
import OrderStatus from "../../../../../../../../types/OrderStatus";
import { SVGgeneral } from "../../../../../../../../utils/generalSprite";
import { files } from "../../../../style/OrderSVG";
import { MessageTemplateActions } from "../../../../../../../../types/MessageTemplateEnums";

export const EXECUTED_ORDER_SMS_TEMPLATES = [
	MessageTemplateActions.WILL_BE_TIME,
	MessageTemplateActions.LATE,
	MessageTemplateActions.ORDER_SUCCESSFULLY_COMPLETED,
	MessageTemplateActions.WITH_PASSENGERS,
	MessageTemplateActions.DRIVER_SWITCHED,
	MessageTemplateActions.OUTPUT_PASSENGERS,
];

export const REGULAR_ORDER_SMS_TEMPLATES = [
	MessageTemplateActions.CAR_NOT_FOUND,
];

const SMSBadgeContainer = styled.div`
	position: relative;
	display: flex;
	gap: 1px;

	button.primary,
	button.disabled {
		outline: none;
		border-radius: 5px 0 0 5px;
	}

	.SMSBadge__icon_button {
		padding: 0;
		border-radius: 0;
		background: #ebebeb;
		border-radius: 0 5px 5px 0;
		width: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #647079;
		border: 1px solid rgba(33, 51, 63, 0.1);
		outline: none;

		&[disabled] {
			color: #9ba3a866;
			background-color: white;
		}

		* {
			outline: none;
		}
	}
`;

const SMSTemplateModalContainer = styled.div`
	position: absolute;
	top: 100%;
	background: white;
	min-width: 250px;
	border: 1px solid rgba(33, 51, 63, 0.1);
	border-radius: 5px;
	margin-top: 3px;
	padding: 2px;
	box-shadow: 0px 3px 6px rgba(33, 51, 63, 0.15);

	.SMSTemplateModal__items {
		display: flex;
		flex-direction: column;

		.SMSTemplateModal__item {
			padding: 5px 10px;
			border-radius: 2px;
			cursor: pointer;
			color: #647079;

			:hover {
				background: #ebebeb;
			}
		}
	}
`;

const SMSTemplateModal = (props: any) => {
	const modalContainerRef = useRef(null);

	useEffect(() => {
		const handleClose = (e: any) => {
			if (
				!e.target.closest(".SMSBadgeContainer") &&
				!e.target.closest(".SMSBadge__icon_button") &&
				modalContainerRef.current
			) {
				props.onClose();
			}
		};

		document.body.addEventListener("click", handleClose);

		return () => document.body.removeEventListener("click", handleClose);
	}, [props, modalContainerRef]);

	return (
		<SMSTemplateModalContainer
			ref={modalContainerRef}
			onBlur={props.onClose}
		>
			<div className="SMSTemplateModal__items">
				{props.items.map((item: any) => (
					<div
						className="SMSTemplateModal__item"
						onClick={() => props.onSubmit(item)}
						key={item.id}
					>
						{item.name}
					</div>
				))}
			</div>
		</SMSTemplateModalContainer>
	);
};

const SMSBadge = () => {
	const dispatch = useDispatch();

	const allSmsTemplates = useTypedSelector(
		(state) => state.ordersPageReducer.smsTemplates,
	);
	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const smsTemplates = useMemo(() => {
		const isExecutable =
			activeOrder.passengers?.length &&
			activeOrder?.executorToOrder?.length &&
			activeOrder?.status === OrderStatus.EXECUTING;

		if (isExecutable) {
			return allSmsTemplates.filter((template) =>
				EXECUTED_ORDER_SMS_TEMPLATES.includes(template.action),
			);
		}

		return allSmsTemplates.filter((template) =>
			REGULAR_ORDER_SMS_TEMPLATES.includes(template.action),
		);
	}, [allSmsTemplates, activeOrder]);

	const [smsTemplateModal, setSMSTemplateModal] = useState(false);

	const disabled = useMemo(
		() =>
			activeOrder?.closedAt ||
			!activeOrder?.id ||
			!activeOrder?.passengers?.length ||
			!smsTemplates.length,
		[activeOrder, smsTemplates],
	);

	const openModal = useCallback(() => {
		if (smsTemplates.length && activeOrder) {
			setSMSTemplateModal(true);
		}
	}, [activeOrder, setSMSTemplateModal, smsTemplates]);

	const onClose = useCallback(() => {
		setSMSTemplateModal(false);
	}, []);

	const onSubmit = useCallback(
		(template) => {
			console.log("onSubmit", template, activeOrder);
			dispatch(
				sendSMSByOrder(activeOrder.id, {
					action: template.action,
				}),
			);
			setSMSTemplateModal(false);
		},
		[dispatch, activeOrder],
	);

	return (
		<SMSBadgeContainer>
			<Budge.Budge
				disabled={disabled}
				circle={false}
				icon={files}
				id="sms"
			/>
			<IconButton
				className="SMSBadge__icon_button"
				type="submit"
				aria-label="edit"
				disabled={disabled}
				onClick={openModal}
			>
				<SVGgeneral id="nav-item-arrow-down" />
			</IconButton>

			{smsTemplateModal && (
				<SMSTemplateModal
					items={smsTemplates}
					onClose={onClose}
					onSubmit={onSubmit}
				/>
			)}
		</SMSBadgeContainer>
	);
};

export default SMSBadge;
