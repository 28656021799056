import React, { memo, useCallback, useMemo } from "react";
import { Select } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	StyledSpan,
	ImprovedStepper,
} from "../../../../../../../../components/common";
import tPath from "../../../../constants/tPath";
import {
	Units,
	unitsOptions,
	RateBinaryAdjustmentToDefault,
} from "../../../../constants/constants";
import BinaryAdjustmentSelect from "../../../BinaryAdjustmentSelect";

const HourlyServiceInterval: React.FC<HourlyServiceInterval.Props> = memo(
	({ root, value, onChange }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const intervalValue = valueEditor.useGetter("value");
		const setIntervalValue = valueEditor.useSetter("value");

		const intervalUnits = valueEditor.useGetter("units");
		const setIntervalUnits = valueEditor.useSetter("units");
		const handleIntervalUnitsChange = useCallback(
			(newUnit) => {
				setIntervalUnits(newUnit);
			},
			[setIntervalUnits],
		);
		const tUnitsOptions = useMemo(
			() =>
				unitsOptions.map((option) => ({
					...option,
					label: t(
						`${tPath}.mainModal.tabs.main.hourlyService.interval.options.${option.key}`,
					),
				})),
			[t],
		);

		const adjustments = valueEditor.useGetter("adjustments");
		const setAdjustments = valueEditor.useSetter("adjustments");
		const adjustEditor = useObjectEditor(adjustments, setAdjustments);
		const adjustValue = adjustEditor.useGetter("value");
		const setAdjustValue = adjustEditor.useSetter("value");

		return (
			<StyledRow gap="10px" alignItems="center">
				<StyledSpan w="290.2px">
					{t(
						`${tPath}.mainModal.tabs.main.hourlyService.interval.title`,
					)}
				</StyledSpan>
				<BinaryAdjustmentSelect
					disabled={root}
					value={adjustValue}
					onChange={setAdjustValue}
				/>
				<ImprovedStepper
					disabled={
						adjustValue ===
						RateBinaryAdjustmentToDefault.DEFAULT_VALUE
					}
					value={intervalValue}
					onChange={setIntervalValue}
				/>
				<Select
					disabled={
						adjustValue ===
						RateBinaryAdjustmentToDefault.DEFAULT_VALUE
					}
					value={intervalUnits}
					onChange={handleIntervalUnitsChange}
					options={tUnitsOptions}
					style={{ width: 100 }}
				/>
			</StyledRow>
		);
	},
);

declare namespace HourlyServiceInterval {
	interface Props {
		root: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		value: number;
		units: Units;
		active: boolean;
		adjustments: {
			value: RateBinaryAdjustmentToDefault;
		};
	}
}

export default HourlyServiceInterval;
