import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, RadioList2, Row, TextBox } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledBlock from "../../../../../../../../../../../../components/LabeledBlock";

import RadioButtonLabel from "./components/RadioButtonLabel";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const typeOptions: RadioList2.Option<"garage" | "state">[] = useMemo(
		() => [
			{
				key: "garage",
				value: "garage",
				content:
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str200",
					) ?? "",
			},
			{
				key: "state",
				value: "state",
				content:
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str201",
					) ?? "",
			},
		],
		[t],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const typeValue = valueEditor.useGetter("type");
	const typeOnChange = valueEditor.useSetter("type");

	const numberValue = valueEditor.useGetter("number");
	const numberOnChange = valueEditor.useSetter("number");

	return (
		<Root gaps="16px*">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str0",
					) ?? ""
				}
			>
				<Column gaps="16px*">
					<RadioList2
						value={typeValue}
						autoFocus
						options={typeOptions}
						onChange={typeOnChange}
					>
						{(radios) => (
							<Row align="center" gaps="32px*" sizes="1fr*">
								{radios.map((radio, index) => (
									<RadioButtonLabel key={index}>
										{radio}
									</RadioButtonLabel>
								))}
							</Row>
						)}
					</RadioList2>
					<TextBox.TextBox
						value={numberValue}
						placeholder={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.content.str1",
							) ?? ""
						}
						onChange={numberOnChange}
					/>
				</Column>
			</LabeledBlock>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		type: "state" | "garage";
		number: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
