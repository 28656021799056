import React, { useMemo } from "react";
import { Column, Row, theme } from "uikit";
import path from "path";
import moment from "moment";
import File from "../../../../../../services/File";
import Name from "./components/Name";
import Data from "./components/Data";
import getIconByExt from "../../../../../../utils/getIconByExt";
import humanFileSize from "../../../../../../utils/humanFileSize";

const Information: React.FC<Information.Props> = ({ file }) => {
	const name = useMemo(() => file?.name ?? "", [file?.name]);

	const icon = useMemo(
		() =>
			getIconByExt(path.extname(name).toLowerCase().substring(1), {
				fill: theme.colors.primary,
				width: 50,
				height: 50,
			}),
		[name],
	);
	return (
		<Row align="center" gaps="10px">
			{icon}
			<Column gaps="4px">
				<Name>{name}</Name>
				<Data>
					{humanFileSize(file?.size ?? 0, true)}
					{file
						? ` | ${moment(file.createdAt).format(
								"DD.MM.YYYY hh:mm",
						  )}`
						: ""}
				</Data>
			</Column>
		</Row>
	);
};

declare namespace Information {
	interface Props {
		file?: File.Model;
	}
}

export default Information;
