import React, { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import styled from "styled-components";

import { StyledRow, Style } from "../styles";

const StyledTab = styled(StyledRow)`
	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}
`;

const TabBase: React.FC<Text.Props> = ({
	children,
	visible = true,
	...props
}): JSX.Element => (
	<StyledTab {...props} visible={visible} w="100%">
		{children}
	</StyledTab>
);

declare namespace Text {
	interface Props
		extends Omit<
				DetailedHTMLProps<
					HTMLAttributes<HTMLParagraphElement>,
					HTMLParagraphElement
				>,
				"ref"
			>,
			Style.Row {
		visible?: boolean;
	}
}

export const Tab = memo(TabBase);
export default TabBase;
