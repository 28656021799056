/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import Base from "../Base";
import CarBaseType from "../CarBaseType";
import CarBrand from "../CarBrand";
import CarModel from "../CarModel";

class CarBaseTypeToCarBrand extends Base {
	static fromResponse(data: any): CarBaseTypeToCarBrand.Model {
		return {
			id: data.id,

			carModels: data.models?.map((model) =>
				CarModel.fromResponse(model),
			),
			carBaseType: data.baseType
				? CarBaseType.fromResponse(data.baseType)
				: undefined,
			carBrand: data.brand
				? CarBrand.fromResponse(data.brand)
				: undefined,

			active: data.active,
			native: data.native,
			favorite: data.favorite,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: CarBaseTypeToCarBrand.Model.Modified): any {
		return {
			active: model.active,
			favorite: model.favorite,
		};
	}

	public static async update(
		carBaseTypeId: number,
		carBrandId: number,
		object: CarBaseTypeToCarBrand.Model.Modified,
	) {
		await this.request((prpc) =>
			prpc.theirsModel.carBrand.updateBaseTypeRelation(
				carBaseTypeId,
				carBrandId,
				CarBaseTypeToCarBrand.toRequest(object),
			),
		);
	}

	public static async destroy(carBaseTypeId: number, carBrandId: number) {
		await this.request((prpc) =>
			prpc.theirsModel.carBrand.deleteBaseTypeRelation(
				carBaseTypeId,
				carBrandId,
			),
		);
	}
}

declare namespace CarBaseTypeToCarBrand {
	interface Model {
		id: number;

		carModels?: CarModel.Model[];
		carBaseType?: CarBaseType.Model;
		carBrand?: CarBrand.Model;

		active: boolean;
		native: boolean;
		favorite: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	namespace Model {
		interface Modified {
			active?: boolean;
			favorite?: boolean;
		}
	}
}

export default CarBaseTypeToCarBrand;
