/* eslint-disable no-case-declarations */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";
import Root from "./components/Root";
import { Columns, columns, defaultColumnsId } from "./constants";
import tPath from "../../constants/tPath";
import LightTable from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import { useTypedSelector } from "../../../../../../../../redux/store";
import Modal from "../Modal";
import MapSettings from "../../../../../../../../services/MapSettings";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const tColumns = useMemo(
		() =>
			columns.map((c) => ({
				...c,
				label: t(`${tPath}.columns.${c.id}`),
			})),
		[t],
	);
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(item) => {
			if (selected.includes(item.id)) {
				setSelected(selected.filter((id) => item.id !== id));
			} else {
				setSelected([...selected, item.id]);
			}
		},
		[setSelected, selected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = tColumns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
						>
							{(rowData) => {
								const data = rowData as Modal.Value;

								switch (columnId) {
									case Columns.NAME:
										return data.name[lang];

									case Columns.COMPANIES:
										const companyNames =
											data.taxiServices?.map(
												(branch) =>
													branch.company?.name[lang],
											);
										const uniqueCompanyNames = Array.from(
											new Set(companyNames),
										);

										return uniqueCompanyNames.join(", ");

									case Columns.BRANCHES:
										return data.taxiServices
											?.map(
												(branch) =>
													branch?.settlement[lang],
											)
											.join(", ");

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, lang, tColumns],
	);

	const getClassName = useCallback(
		(item) => (item && selected.includes(item.id) ? "selected" : ""),
		[selected],
	);

	const handleAllSelectedChange = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	return (
		<Root sizes="1fr auto!">
			<div>
				<LightTable
					data={value}
					virtualized
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
				>
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleAllSelectedChange}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
						>
							{(item) => (
								<CheckBox
									value={selected.includes(item.id)}
									onChange={() => {
										updateSelected(item);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{renderColumns}
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
				</LightTable>
			</div>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={tColumns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: MapSettings.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: Dispatch<MapSettings.Model>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
