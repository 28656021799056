import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
	useObjectEditor,
	useCurrencyGlobalSettings,
} from "../../../../../../../../hooks";
import {
	StyledRow,
	StyledSpan,
	ValueStepper,
} from "../../../../../../../../components/common";
import tPath from "../../../../constants/tPath";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
} from "../../../../constants/constants";
import BinaryAdjustmentSelect from "../../../BinaryAdjustmentSelect";
import SurchargeSelect from "../../../SurchareSelect";

const HourlyServiceCost: React.FC<HourlyServiceCost.Props> = memo(
	({ disabledAdditionalKm, root, value, onChange }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const valueEditor = useObjectEditor(value, onChange);

		const costValue = valueEditor.useGetter("value");
		const setCostValue = valueEditor.useSetter("value");

		const additionalKm = valueEditor.useGetter("additionalKm");
		const setAdditionalKm = valueEditor.useSetter("additionalKm");

		const adjustments = valueEditor.useGetter("adjustments");
		const setAdjustments = valueEditor.useSetter("adjustments");
		const adjustEditor = useObjectEditor(adjustments, setAdjustments);
		const adjustmentsValue = adjustEditor.useGetter("value");
		const setAdjustmentsValue = adjustEditor.useSetter("value");
		const adjustmentsKm = adjustEditor.useGetter("additionalKm");
		const setAdjustmentsKm = adjustEditor.useSetter("additionalKm");

		useEffect(() => {
			if (
				adjustmentsValue === RateAdjustmentToDefault.CUSTOM_VALUE &&
				costValue < 0
			) {
				setCostValue(0);
			}
		}, [adjustmentsValue, costValue, setCostValue]);

		return (
			<StyledRow gap="10px" alignItems="center">
				<StyledSpan>
					{t(
						`${tPath}.mainModal.tabs.main.hourlyService.hourlyServiceCost.title`,
					)}
				</StyledSpan>
				<SurchargeSelect
					disabled={root}
					value={adjustmentsValue}
					onChange={setAdjustmentsValue}
				/>
				<StyledRow gap="10px" alignItems="center" w="125px">
					<ValueStepper
						min={
							adjustmentsValue ===
							RateAdjustmentToDefault.CUSTOM_VALUE
								? 0
								: undefined
						}
						value={costValue}
						onChange={setCostValue}
					/>
					{adjustmentsValue !==
						RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE &&
						currencyGlobalSettings}
				</StyledRow>
				<StyledSpan>
					{t(
						`${tPath}.mainModal.tabs.main.hourlyService.hourlyServiceCost.include`,
					)}
				</StyledSpan>
				<BinaryAdjustmentSelect
					disabled={root}
					value={adjustmentsKm}
					onChange={setAdjustmentsKm}
				/>
				<ValueStepper
					decimalCount={3}
					disabled={
						disabledAdditionalKm ||
						adjustmentsKm ===
							RateBinaryAdjustmentToDefault.DEFAULT_VALUE
					}
					value={additionalKm}
					onChange={setAdditionalKm}
				/>
				<StyledSpan>{t("units.km")}.</StyledSpan>
			</StyledRow>
		);
	},
);

declare namespace HourlyServiceCost {
	interface Props {
		disabledAdditionalKm: boolean;
		root: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		value: number;
		additionalKm: number;
		adjustments: {
			value: RateAdjustmentToDefault;
			additionalKm: RateBinaryAdjustmentToDefault;
		};
	}
}

export default HourlyServiceCost;
