import React, { Dispatch, useMemo } from "react";
import { Column, Row, Tabs, theme } from "uikit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ExecutorTariffTabs, getTabs } from "../../tabs";

const Root = styled(Column)`
	background: ${theme.colors.button_secondary};
`;
const Title = styled.div`
	height: 28px;
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	color: ${theme.colors.primary};
`;

const Header: React.FC<Header.Props> = ({
	tab,
	title,
	createdAt,
	onChangeTab,
}) => {
	const { t } = useTranslation();

	const tabs = useMemo(() => getTabs(t), [t]);

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "16px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Row gaps="7px">
					<span>{t(`createdAt`)}:</span>
					<span
						style={{
							color: theme.colors.primary,
							fontWeight: 600,
						}}
					>
						{createdAt}
					</span>
				</Row>
			</Row>
			<Tabs
				value={tab}
				options={tabs}
				variant="panels"
				onChange={(newTab) => onChangeTab(newTab as ExecutorTariffTabs)}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		tab: ExecutorTariffTabs;
		title: string;
		createdAt?: string;

		onChangeTab: Dispatch<ExecutorTariffTabs>;
	}
}

export default Header;
