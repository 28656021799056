import React from "react";
import { Nullable } from "uikit";
import CallBar from "./components/CallBar";
import NumberKeyboard from "./components/NumberKeyboard";
import CallList from "./components/CallList";
import { Call } from "../../../../redux/reducers/softphoneWidget";
import Root from "./components/Root";
import CallBarReduced from "./components/CallBarReduced";

interface Props {
	selectedCall: Nullable<Call>;
	data: Call[];
	onSelectCaller: (callerData: Call) => void;
	isReduce: boolean;
}

const DialingTab: React.FC<Props> = ({
	selectedCall,
	data,
	isReduce,
	onSelectCaller,
}) => (
	<Root style={{ height: isReduce ? "525px" : "274px" }}>
		{!isReduce ? (
			<CallBarReduced selectedCall={selectedCall} data={data} />
		) : (
			<CallBar selectedCall={selectedCall} data={data} />
		)}
		<NumberKeyboard selectedCall={selectedCall}>
			<CallList
				data={data}
				selectedCall={selectedCall}
				onSelectCaller={onSelectCaller}
				isReduce={isReduce}
			/>
		</NumberKeyboard>
	</Root>
);

export default DialingTab;
