import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ACTIVE = "active",
	NAME = "name",
	RULES = "rules",
	DISABLE_OTHERS = "disableOthers",
	COMPANIES = "companies",
	BRANCHES = "branches",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.ACTIVE, label: "", width: 90 },
	{ id: Columns.NAME, label: "", width: 250 },
	{ id: Columns.RULES, label: "", width: 480 },
	{ id: Columns.DISABLE_OTHERS, label: "", width: 90 },
	{ id: Columns.COMPANIES, label: "", width: 220 },
	{ id: Columns.BRANCHES, label: "", width: 400 },
];

export const defaultColumnsId = columns.map(({ id }) => id);
