import React, { memo, useCallback } from "react";
import { Button, Popover as PopoverBase, Row, react } from "uikit";
import { useTranslation } from "react-i18next";
import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";
import { useTypedDispatch } from "../../../../../../../../../../../../redux/store";
import {
	applyInternalFilter,
	resetInternCompanyBranch,
} from "../../../../../../../../../../../../redux/reducers/Archives/Calls";

const PopoverNotMemo = react.inputify<Popover.Props>(({ open, onClose }) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const apply = useCallback(() => {
		dispatch(applyInternalFilter());
		onClose();
	}, [dispatch, onClose]);

	const cancel = useCallback(() => {
		dispatch(resetInternCompanyBranch());
		onClose();
	}, [dispatch, onClose]);

	return (
		<PopoverBase
			open={open}
			containerId="root"
			trackerId="filters"
			onClose={cancel}
		>
			<Root align="stretch" gaps="15px*">
				<Header onClose={onClose} />
				<Content />
				<Row gaps="10px*" justify="flex-end">
					<Button.Button
						text={t("filter.apply") || "Apply filter"}
						onClick={apply}
					/>
					<Button.Button
						text={t("cancel") || "Cancel"}
						onClick={cancel}
					/>
				</Row>
			</Root>
		</PopoverBase>
	);
});
const Popover = memo(PopoverNotMemo);

declare namespace Popover {
	interface Props {
		open: boolean;
		onClose: () => void;
	}
}

export default Popover;
