import { RefObject } from "react";
import { react } from "uikit";
import AnotherData from "./components/AnotherData";
import NameAndCompanyData from "./components/NameAndCompanyData";
import AuthData from "./components/AuthData";
import TonAndNpiData from "./components/TonAndNpiData";
import CheckMessageData from "./components/CheckMessageData";
import CheckConnectData from "./components/CheckConnectData";

interface Context {
	anotherDataRef: RefObject<AnotherData.Ref | null>;
	nameAndCompanyDataRef: RefObject<NameAndCompanyData.Ref | null>;
	authDataRef: RefObject<AuthData.Ref | null>;
	tonAndNpiDataRef: RefObject<TonAndNpiData.Ref | null>;
	checkMessageDataRef: RefObject<CheckMessageData.Ref | null>;
	checkConnectDataRef: RefObject<CheckConnectData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		// const isAnotherDataValid =
		// 	!!this.context?.anotherDataRef.current?.validate();
		const isNameAndCompanyDataValid =
			!!this.context?.nameAndCompanyDataRef.current?.validate();
		// const isAuthDataValid = !!this.context?.authDataRef.current?.validate();
		// const isTonAndNpiDataValid =
		// 	!!this.context?.tonAndNpiDataRef.current?.validate();
		// const isCheckMessageDataValid =
		// 	!!this.context?.checkMessageDataRef.current?.validate();
		// const isCheckConnectDataValid =
		// 	!!this.context?.checkConnectDataRef.current?.validate();
		return (
			// isAnotherDataValid &&
			isNameAndCompanyDataValid
			// isAuthDataValid &&
			// isTonAndNpiDataValid &&
			// isCheckMessageDataValid &&
			// isCheckConnectDataValid
		);
	}
}
