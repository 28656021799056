import React, { useCallback, useState, useMemo, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import { EditIcon } from "../../../../../../../../../icons/edit";
import {
	NumberInput,
	NumberInputMemo,
} from "../../../../../../../../../components/Orders";
import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";
import { Primary, Gray } from "../../styled";

import Clickable from "./Clickable";

interface Props extends OrderFormProps {
	id: "waitingTime" | "idleTime";
	title: string;
	edit: boolean;
	setEdit: (edit: boolean) => void;
}

const TimeWaitingAndIdle: React.FC<Props> = ({
	id,
	title,
	disabled,
	edit,
	form,
	tab,
	setEdit,
}) => {
	const { t } = useTranslation();
	const [value, onChangeValue] = useState<NumberInput.Value>({
		action: "add",
		value: 0,
	});
	const valueEditor = useObjectEditor(value, onChangeValue);

	const valueAction = valueEditor.useGetter("action");
	const setAction = valueEditor.useSetter("action");
	const valueTime = valueEditor.useGetter("value");
	const setValue = valueEditor.useSetter("value");

	const idleTime = useMemo(() => tab.form.idleTime || 0, [tab.form.idleTime]);
	const downtimesWithClients = useMemo(
		() => tab.form.downtimesWithClients || 0,
		[tab.form.downtimesWithClients],
	);

	const waitingTime = useMemo(
		() => tab.form.waitingTime || 0,
		[tab.form.waitingTime],
	);

	const downtimesWaitingClient = useMemo(
		() => tab.form.downtimesWaitingClient || 0,
		[tab.form.downtimesWaitingClient],
	);

	const time = useMemo(() => {
		let value = 0;
		if (id === "idleTime") value = idleTime + downtimesWithClients;
		if (id === "waitingTime") value = waitingTime + downtimesWaitingClient;

		return Math.max(value, 0);
	}, [
		downtimesWaitingClient,
		downtimesWithClients,
		id,
		idleTime,
		waitingTime,
	]);

	// const payload = useMemo(
	// 	() => Math.max(Math.round(time / 60e3) * 60e3, 0),
	// 	[time],
	// );

	const showTime = useMemo(() => moment.utc(time).format("HH:mm:ss"), [time]);

	useLayoutEffect(() => {
		if (valueTime !== time) setValue(time);
	}, [time, setValue, valueTime]);

	const add = useCallback(
		(value: number) => {
			setAction("add");
			const maxValue = Math.max(value, 0);
			setValue(maxValue);

			let timeValue = value;
			if (id === "waitingTime") {
				timeValue = maxValue - downtimesWaitingClient;
			}
			if (id === "idleTime") {
				timeValue = maxValue - downtimesWithClients;
			}

			form.setValue(id, timeValue);
		},
		[
			downtimesWaitingClient,
			downtimesWithClients,
			form,
			id,
			setAction,
			setValue,
		],
	);

	const set = useCallback(
		(value: number) => {
			setAction("set");
			setValue(value);
			let timeValue = value;

			if (id === "waitingTime") {
				timeValue = value - downtimesWaitingClient;
			}
			if (id === "idleTime") {
				timeValue = value - downtimesWithClients;
			}

			form.setValue(id, timeValue);
		},
		[
			downtimesWaitingClient,
			downtimesWithClients,
			form,
			id,
			setAction,
			setValue,
		],
	);

	const onSubmit = useCallback(
		(data: NumberInput.Value) => {
			const value =
				data.action === "add"
					? time + data.value * 60e3
					: data.value * 60e3;

			if (data.action === "add") add(value);
			if (data.action === "set") set(value);

			setEdit(false);
		},
		[time, add, set, setEdit],
	);
	return (
		<StyledRow justify="start" alignItems="end" gap="6px">
			<Primary>{`${title}:`}</Primary>

			<Gray>{`${showTime ?? ""} ${t(`units.minute`) ?? "min"}`}</Gray>

			{!disabled && (
				<Clickable onClick={() => setEdit(true)}>
					<EditIcon />
				</Clickable>
			)}

			{edit && (
				<NumberInputMemo
					title={title ?? ""}
					unit={t(`units.minute`) ?? "min"}
					value={{
						action: valueAction,
						value: Math.round((valueTime || 0) / 60e3),
					}}
					onSubmit={onSubmit}
					onClose={() => setEdit(false)}
				/>
			)}
		</StyledRow>
	);
};

export default TimeWaitingAndIdle;
