import styled from "styled-components";
import { Column, theme } from "uikit";

const ButtonGroup = styled(Column)`
	width: 32px;

	padding: 2px;

	border-radius: 5px;

	background-color: ${theme.colors.white};

	box-shadow: 0px 4px 8px 0px #0000001a;
`;

export default ButtonGroup;
