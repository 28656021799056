import React from "react";
import { Icon, InputBorder, Row, theme } from "uikit";
import Root from "./components/Root";
import Card from "./components/Card";
import Button from "./components/Button";

const Controls: React.FC<Controls.Props> = ({
	onZoomIn,
	onZoomOut,

	onCounterclockwiseRotate,
	onResetRotation,
	onClockwiseRotate,

	onFill,
	onFit,
}) => (
	<Root align="center" justify="center" maxedWidth>
		<Card gaps="15px*">
			<Row align="center" gaps="10px*">
				<Button onClick={onZoomIn}>
					<Icon
						id="zoom-in"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
				<Button onClick={onZoomOut}>
					<Icon
						id="zoom-out"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
			</Row>
			<InputBorder.InputBorder />
			<Row align="center" gaps="10px*">
				<Button onClick={onCounterclockwiseRotate}>
					<Icon
						id="rotate-counterclockwise"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
				<Button onClick={onResetRotation}>
					<Icon
						id="reset-rotation"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
				<Button onClick={onClockwiseRotate}>
					<Icon
						id="rotate-clockwise"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
			</Row>
			<InputBorder.InputBorder />
			<Row align="center" gaps="10px*">
				<Button onClick={onFill}>
					<Icon
						id="to-fill"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
				<Button onClick={onFit}>
					<Icon
						id="to-fit"
						size={22}
						colors={[theme.colors.secondary]}
					/>
				</Button>
			</Row>
		</Card>
	</Root>
);

declare namespace Controls {
	interface Props {
		onZoomIn: () => void;
		onZoomOut: () => void;

		onCounterclockwiseRotate: () => void;
		onResetRotation: () => void;
		onClockwiseRotate: () => void;

		onFill: () => void;
		onFit: () => void;
	}
}

export default Controls;
