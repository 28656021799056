import makeTable from "../makeTable";

const agent = makeTable({
	allIds: [
		"title",
		"contactPersonName",
		"contactPersonPhone",
		"balance",
		"bonusBalance",
		"mobileApp",
		"cabinet",
		"company",
		"taxiService",
		"status",
		"group",
	],
	i18tPath: (id) => `tableColumns.customers.agents.${id}`,
});

export default agent;
