import { TFunction } from "i18next";
import { theme } from "uikit";

const getColumns = (t: TFunction): Column[] => [
	{
		id: "online",
		label: t("orderPageWidgets.executors.columns.online") ?? "",
	},
	{
		id: "alias",
		label: t("orderPageWidgets.executors.columns.alias") ?? "",
	},
	{
		id: "carCallSigns",
		label: t("orderPageWidgets.executors.columns.carCallSigns") ?? "",
	},
	{
		id: "status",
		label: t("orderPageWidgets.executors.columns.status") ?? "",
	},
	{
		id: "executorGroup",
		label: t("orderPageWidgets.executors.columns.executorGroup") ?? "",
	},
	{
		id: "isWorking",
		label: t("orderPageWidgets.executors.columns.isWorking") ?? "",
	},
	{
		id: "company",
		label: t("orderPageWidgets.executors.columns.company") ?? "",
	},
	{
		id: "taxiService",
		label: t("orderPageWidgets.executors.columns.taxiService") ?? "",
	},
]; // columns

const getExecutorStatuses = (t: TFunction): Record<string, ExecutorStatus> => ({
	notWorking: {
		label: t("orderPageWidgets.executors.str200") ?? "",
		color: "rgb(190, 190, 190)",
		offlineColor: "rgba(190, 190, 190, 0.3)",
		fontColor: theme.colors.primary,
	},
	busy: {
		label: t("orderPageWidgets.executors.str201") ?? "",
		color: "rgb(252, 151, 57)",
		offlineColor: "#FEDFC3",
		fontColor: theme.colors.primary,
	},
	dinner: {
		label: t("orderPageWidgets.executors.str202") ?? "",
		color: "rgb(33, 150, 243)",
		offlineColor: "#BBDFFB",
		fontColor: theme.colors.primary,
	},
	home: {
		label: t("orderPageWidgets.executors.str203") ?? "",
		color: "rgb(16, 228, 235)",
		offlineColor: "#B6F6F8",
		fontColor: theme.colors.primary,
	},
	on_order: {
		label: t("orderPageWidgets.executors.str204") ?? "",
		color: "rgb(255, 128, 130)",
		offlineColor: "#FFD8D9",
		fontColor: theme.colors.primary,
	},
	available: {
		label: t("orderPageWidgets.executors.str205") ?? "",
		color: "rgb(76, 175, 80)",
		offlineColor: "#C8E6CA",
		fontColor: theme.colors.primary,
	},
	availableDebt: {
		label: t("orderPageWidgets.executors.str206") ?? "",
		color: "rgb(255, 128, 130)",
		offlineColor: "#f83528",
		fontColor: theme.colors.primary,
	},
	closed: {
		label: t("orderPageWidgets.executors.str207") ?? "",
		color: "rgb(134, 75, 75)",
		offlineColor: "#DAC8C8",
		fontColor: theme.colors.white,
	},
	ownOrder: {
		label: t("orderPageWidgets.executors.str208") ?? "",
		color: "rgb(102, 84, 177)",
		offlineColor: "#D0CBE7",
		fontColor: theme.colors.white,
	},
	ownOrderDebt: {
		label: t("orderPageWidgets.executors.str209") ?? "",
		color: "rgb(102, 84, 177)",
		offlineColor: "#D0CBE7",
		fontColor: theme.colors.white,
	},
}); // EXECUTOR_STATUSES

interface Column {
	id: string;
	label: string;
}

interface ExecutorStatus {
	label: string;
	color: string;
	offlineColor: string;
	fontColor: string;
}

export { getColumns, getExecutorStatuses };
