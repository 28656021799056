import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";

import getServices from "./getServices";

export default function deleteService(id) {
	return (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() =>
			prpcow?.theirsModel.service.delete(id),
		).then(async (result) => {
			await dispatch(getServices());
			return result;
		});
	};
}
