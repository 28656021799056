import styled from "styled-components";

const Root = styled.div`
	display: grid;

	grid-template-columns: auto 1fr;
	grid-template-rows: 1fr auto;

	grid-template-areas:
		"controls points"
		"controls info";

	width: 100%;
	height: 100%;
`;

export default Root;
