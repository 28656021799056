import React, { memo, useCallback, useMemo } from "react";
import { clone, extend } from "lodash";

import Language from "../../../../../../../services/Language";
import {
	useColumns,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../hooks/useTableSettings";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../redux/store";
import { IOrderPoint as OrderPoint } from "../../../../../../../redux/constants/OrdersPage/order";
import orderPage from "../../../../../../../redux/reducers/OrdersPage";
import useKeyBind from "../../../../../../../hooks/useKeyBind";

import columnBase, { ColumnId as LocalColumnId } from "./columns";
import Root from "./components/Root";

const TABLE_HEADER_HEIGHT = 26;
const TABLE_ROW_HEIGHT = 32;

const TABLE_TYPE = "orderPoints";
const TABLE_ID = "order-points";

const TableBase: React.FC<Table.Props> = ({ language }) => {
	const dispatch = useTypedDispatch();

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const activePoint = useTypedSelector(
		(state) => state.ordersPageReducer.activePoint as number,
	);

	const tableData = useMemo(
		() => (activeOrder?.points as OrderPoint[] | undefined) ?? [],
		[activeOrder?.points],
	);

	const tableRowClassName = useCallback(
		(_, index: number) => {
			if (index === activePoint) return "selected";

			return "";
		},
		[activePoint],
	);

	const tableOnRowClick = useCallback(
		(item: OrderPoint) => {
			const activePoint = tableData.indexOf(item);

			if (activePoint === -1) return;

			dispatch(orderPage.actions.setActivePoint(activePoint));
		},
		[dispatch, tableData],
	);

	const selectNextPoint = useCallback(() => {
		const nextPointIndex = activePoint + 1;

		if (nextPointIndex === -1 || nextPointIndex >= tableData.length) return;

		dispatch(orderPage.actions.setActivePoint(nextPointIndex));
	}, [activePoint, dispatch, tableData.length]);

	const selectPreviousPoint = useCallback(() => {
		const previousPointIndex = activePoint - 1;

		if (previousPointIndex === -1) return;

		dispatch(orderPage.actions.setActivePoint(previousPointIndex));
	}, [activePoint, dispatch]);

	useKeyBind(["Shift", "ArrowUp"], selectPreviousPoint);
	useKeyBind(["Shift", "ArrowDown"], selectNextPoint);

	const columnData = useColumns(TABLE_TYPE);
	const { columnIds } = useVisibleColumns(TABLE_ID, TABLE_TYPE);
	const { widths, setWidths } = useWidths(TABLE_ID, TABLE_TYPE);

	const columns = useMemo<Table.Column[]>(
		() =>
			columnData
				.map((column) => ({
					id: column.id as Table.ColumnId,
					width: widths[column.id],
					visible: columnIds.includes(column.id as Table.ColumnId),
				}))
				.sort((leftColumn, rightColumn) => {
					const leftIndex = columnIds.indexOf(leftColumn.id);
					const rightIndex = columnIds.indexOf(rightColumn.id);

					return leftIndex - rightIndex;
				}),
		[columnData, columnIds, widths],
	);

	const renderedColumns = useMemo(
		() =>
			columns
				.filter((column) => column.visible)
				.map((column) =>
					columnBase[column.id].render({
						width: column.width,
						language,

						onResize: (width, columnId) => {
							const newWidths = clone(widths);

							newWidths[columnId] = width;

							setWidths(newWidths);
						},
					}),
				),
		[columns, language, setWidths, widths],
	);

	return (
		<Root
			data={tableData}
			virtualized
			fillHeight
			disableDoubleClickDebounce
			shouldUpdateScroll={false}
			headerHeight={TABLE_HEADER_HEIGHT}
			rowHeight={TABLE_ROW_HEIGHT}
			rowClassName={tableRowClassName}
			onRowClick={tableOnRowClick}
		>
			{renderedColumns}
		</Root>
	);
};

const Table = extend(memo(TableBase), { ColumnId: LocalColumnId });

declare namespace Table {
	type ColumnId = LocalColumnId;

	interface Column {
		id: LocalColumnId;
		width: number;
		visible: boolean;
	}

	interface Props {
		language: Language;
	}
}

export default Table;
