import { RefObject } from "react";
import { react } from "uikit";
import ObjectForm from "../ObjectForm";

interface Context {
	objectFormRef: RefObject<ObjectForm.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isObjectFormValid =
			this.context?.objectFormRef.current?.validate();

		return isObjectFormValid;
	}
}
