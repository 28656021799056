import React from "react";

import DispatcherCallSignCellContent from "../components/DispatcherCallSignCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function dispatcherCallSign({ width, onResize }: Column.Options) {
	return base(
		ColumnId.DispatcherCallSign,
		(item) => <DispatcherCallSignCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default dispatcherCallSign;
