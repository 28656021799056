import styled from "styled-components";
import { theme } from "uikit";

const Param = styled.span`
	padding: 5px;
	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: 5px;
	opacity: 0.8;
`;

export default Param;
