import PassengersSurcharge from "..";

const defaultValue: PassengersSurcharge.Interval = {
	active: false,
	count: 1,
	type: null,
	value: 0,
};

export default defaultValue;
