import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import TariffCellContent from "../components/TariffCellContent";

function tariff({ width, language, onResize }: Column.Options) {
	return base(
		ColumnId.Tariff,
		(item) => <TariffCellContent item={item} language={language} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default tariff;
