import React, { Dispatch, useCallback, useMemo } from "react";
import { Float, Icon, useInternal } from "uikit";
import { sha1 } from "object-hash";
import Button from "./components/Button";
import { Popup } from "../../../../../../common";
import Content from "./components/Content";

const offset: Float.OffsetCallback = ({ tracker, float }) => {
	if (!tracker || !float) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();
	const floatSize = float.getSize();

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width),
		y: Number(trackerSize.height) + 8,
	};
};

const Filters: React.FC<Filters.Props> = ({
	value,
	defaultValue,
	onChange,
	onApply,
}) => {
	const [popupOpen, setPopupOpen] = React.useState(false);

	const [internalValue, setInternalValue] = useInternal(value);

	const valueHash = useMemo(() => sha1(value), [value]);
	const defaultValueHash = useMemo(() => sha1(defaultValue), [defaultValue]);

	const buttonActive = useMemo(
		() => popupOpen || valueHash !== defaultValueHash,
		[defaultValueHash, popupOpen, valueHash],
	);

	const internalOnChange = useCallback(
		(value: Filters.Value) => {
			setInternalValue(value);
			onChange?.(value);
		},
		[onChange, setInternalValue],
	);

	const closePopup = useCallback(() => {
		setPopupOpen(false);
	}, []);

	const contentOnReset = useCallback(() => {
		setInternalValue(defaultValue);
	}, [defaultValue, setInternalValue]);

	const contentOnApply = useCallback(() => {
		closePopup();

		onApply(internalValue);
	}, [closePopup, internalValue, onApply]);

	return (
		<Popup
			open={popupOpen}
			useClickControl
			tracker={
				<Button variant="secondary" active={buttonActive}>
					<Icon id="filters" size={20} />
				</Button>
			}
			offset={offset}
			onChangeOpen={setPopupOpen}
		>
			<Content
				value={internalValue}
				onChange={internalOnChange}
				onReset={contentOnReset}
				onClose={closePopup}
				onApply={contentOnApply}
			/>
		</Popup>
	);
};

declare namespace Filters {
	type Value = Content.Value;

	interface Props {
		value: Value;

		defaultValue: Value;

		onChange?: Dispatch<Value>;

		onApply: Dispatch<Value>;
	}
}

export default Filters;
