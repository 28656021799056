import React, { HTMLAttributes, ReactNode, forwardRef, memo } from "react";
import styled from "styled-components";

import StaticWidth from "../StaticWidth";

const CustomTooltipStyled = styled.div<{ arrowLeft?: string }>`
	position: absolute;
	z-index: 99999;
	width: max-content;
	max-width: 95vw;
	padding: 5px 10px;
	margin-top: 15px;
	font-size: 14px;
	color: #fff;
	background-color: #282c37;
	border-radius: 5px;

	&::before {
		content: "";
		position: absolute;
		top: -7px;
		left: ${({ arrowLeft }) => arrowLeft || "50%"};
		transform: translateX(-50%);
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #282c37 transparent;
	}
`;
const CustomTooltipBase: React.FC<CustomTooltip.Props> = ({
	top,
	left,
	children,
	...props
}) => (
	<CustomTooltipStyled {...props} style={{ top, left }}>
		<StaticWidth>{children}</StaticWidth>
	</CustomTooltipStyled>
);

const CustomTooltip = memo(
	forwardRef<HTMLDivElement, CustomTooltip.Props>((props, ref) => (
		<CustomTooltipBase {...props} ref={ref} />
	)),
);

declare namespace CustomTooltip {
	interface Props extends HTMLAttributes<HTMLDivElement> {
		ref?: React.ForwardedRef<HTMLDivElement>;
		top?: number;
		left?: number;
		arrowLeft?: string;
		children: ReactNode;
	}
}

export default CustomTooltip;
