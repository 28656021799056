import { Point, PointExpression } from "leaflet";

export function scaleToFit(
	toFitWidth: number,
	toFitHeight: number,
	toScaleWidth: number,
	toScaleHeight: number,
) {
	const aspectRatio1 = toFitWidth / toFitHeight;
	const aspectRatio2 = toScaleWidth / toScaleHeight;

	let width: number;
	let height: number;

	if (aspectRatio1 > aspectRatio2) {
		width = toFitWidth;
		height = (toFitWidth * toScaleHeight) / toScaleWidth;
	} else {
		height = toFitHeight;
		width = (toFitHeight * toScaleWidth) / toScaleHeight;
	}

	return { width, height };
}

export type Triangle = [Point, Point, Point];

export function triangle(
	center: PointExpression,
	radius: number,
	rotation = 0,
) {
	let processedCenter: Point;

	if (Array.isArray(center))
		processedCenter = new Point(center[0], center[1]);
	else processedCenter = center;

	const result: Point[] = [];

	const maxAngle = rotation + 2 * Math.PI;

	for (let angle = rotation; angle < maxAngle; angle += (2 / 3) * Math.PI) {
		result.push(
			new Point(
				processedCenter.x + Math.cos(angle) * radius,
				processedCenter.y + Math.sin(angle) * radius,
			),
		);
	}

	return result as Triangle;
}

export default { scaleToFit };
