import React, { memo, useMemo, Suspense } from "react";
import { CircleMarker, Tooltip } from "react-leaflet";
import { PathOptions } from "leaflet";

import {
	ConstantColors,
	PointItems,
} from "../../../../redux/reducers/Archives/GPSLog/interface";
import { Text } from "../../../common";

const CircleMarkerItemBase: React.FC<CircleMarkerItemBase.Props> = ({
	keyPoint,
	center,
	radius,
	lines,
	color = ConstantColors.RED,
	pathOptions = {},
}): JSX.Element => {
	const opt = useMemo<PathOptions>(() => {
		const option = {
			fillColor: color,
			fillOpacity: 1,
			stroke: true,
			weight: radius * 15,
			color,
			opacity: 0,
			...pathOptions,
		};
		return option;
	}, [pathOptions, radius, color]);

	const rad = useMemo<number>(
		() => (radius < 10 ? radius * 6 : radius),
		[radius],
	);

	return (
		<Suspense>
			<CircleMarker
				key={keyPoint}
				center={center}
				radius={rad}
				pathOptions={opt}
				className="leaflet-interactive"
			>
				<Tooltip>
					<div>
						{lines.length &&
							lines.map((value, j) => {
								if (!value[0]) return <></>;
								return (
									<Text
										text={value[0]}
										value={value[1]}
										key={`${keyPoint}-${j}`}
									/>
								);
							})}
					</div>
				</Tooltip>
			</CircleMarker>
		</Suspense>
	);
};

export const CircleMarkerItem = memo(CircleMarkerItemBase);

declare namespace CircleMarkerItemBase {
	interface Props extends PointItems {
		pathOptions?: PathOptions;
	}
}

export default CircleMarkerItemBase;
