/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateRulePaymentType {
	CASH = "cash",
	CORPORATE = "corporate",
	CARD = "card",
	INVOICE = "invoice",
	BONUS = "bonus",
	TERMINAL = "terminal",
}

export const paymentOptions = getOptions(
	Object.values(AutoRateRulePaymentType),
);
