import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon, InputGroup, theme } from "uikit";
import SearchIconBorders from "../../../../../../../SearchIconBorders";
import SearchTextBox from "../../../../../../../SearchTextBox";

const SearchBarContainer = styled("div")`
	padding: 8px;

	height: 48px;

	position: fixed;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: ${theme.colors.white};
`;

const InputGroupStyled = styled(InputGroup.InputGroup)`
	width: 274px;
`;

const SearchBar = ({ value, onChange }) => {
	const { t } = useTranslation();
	return (
		<SearchBarContainer>
			<InputGroupStyled sizes="auto 1fr">
				<SearchIconBorders style={{ width: 26, position: "relative" }}>
					<Icon
						id="search"
						size={20}
						colors={[theme.colors.disabled_text]}
						style={{ position: "absolute", top: 6, left: 10 }}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t("softphoneWidget.transfer.search.searchBar.str200") ??
						""
					}...`}
					value={value}
					onChange={onChange}
				/>
			</InputGroupStyled>
		</SearchBarContainer>
	);
};

export default SearchBar;
