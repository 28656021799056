/* eslint-disable no-shadow */
import React, {
	Dispatch,

	ReactNode,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, Overlayer, Row, useInternal } from "uikit";
import Nullable from "../../../../../../../../types/Nullable";
import useCarBrandSubscription from "../../../../../../../../hooks/useCarBrandSubscription";
import VirtualList from "../../../../../../../../components/VirtualList";
import makeLookupTable from "../../../../../../../../utils/makeLookupTable";
import Header from "./components/Header";
import Item from "./components/Item";
import OverlayBackground from "./components/OverlayBackground";
import SortButton from "./components/SortButton";
import { SortingOrder } from "../../../../../../../../types/SortingOrder";

const ModelList: React.FC<ModelList.Props> = ({
	selected,
	offset: externalOffset,
	count,
	sort,

	title,
	actions,
	overlayLabel,
	items,

	onChangeSelected,
	// onOffsetChange,
	onChunkSizeChange,
	onChangeSort,

	onChangeActive,
	onChangeFavorite,

	onDelete,
}) => {
	const [visibleItemCount, setVisibleItemCount] = useState(0);

	const [offset] = useInternal(externalOffset);
	// const setOffset = useCallback(
	// 	(value: number) => {
	// 		onOffsetChange(value);
	// 		_setOffset(value);
	// 	},
	// 	[_setOffset, onOffsetChange],
	// );

	const headerTitleOnClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!onChangeSort) return;

			if (sort === "ASC") onChangeSort("DESC");
			else onChangeSort("ASC");
		},
		[onChangeSort, sort],
	);

	const indexedItems = useMemo(
		() => makeLookupTable(items, (_, index) => offset + index, true),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[items],
	);

	const emptyItem = useMemo(() => <Item loading />, []);

	const renderItem = useCallback(
		(index: number) => {
			const item = indexedItems[index]?.value;

			if (!item) return emptyItem;

			return (
				<Item
					disabled={item.disabled}
					enabled={item.active}
					favorite={item.favorite}
					deletable={item.deletable}
					selected={item.id === selected}
					label={item.label}
					onChangeEnabled={(active) => {
						onChangeActive?.(item.id, active);
					}}
					onChangeFavorite={(favorite) => {
						onChangeFavorite?.(item.id, favorite);
					}}
					onClick={() => {
						onChangeSelected(item.id);
					}}
					onDelete={() => {
						onDelete?.(item.id);
					}}
				/>
			);
		},
		[
			indexedItems,
			emptyItem,
			selected,
			onChangeActive,
			onChangeFavorite,
			onChangeSelected,
			onDelete,
		],
	);

	const cachedBrands = useMemo(
		() =>
			Array(count)
				.fill(null)
				.map((_, index) => renderItem(index)),
		[count, renderItem],
	);

	const virtualListOnChangeViewBox = useCallback(
		(newOffset: number, newCount: number) => {
			const delta = Math.round(newCount * 0.5);

			// setOffset(Math.max(offset - delta, 0));

			const newVisibleItemCount = Math.max(
				visibleItemCount,
				newOffset + newCount + delta * 2,
			);

			setVisibleItemCount(newVisibleItemCount);
			onChunkSizeChange(newVisibleItemCount);
		},
		[onChunkSizeChange, visibleItemCount],
	);

	const overlay = useMemo(
		() =>
			overlayLabel ? (
				<OverlayBackground>{overlayLabel}</OverlayBackground>
			) : null,
		[overlayLabel],
	);

	return (
		<Column sizes="auto! 1fr" style={{ overflow: "hidden" }}>
			<Header>
				<Row
					align="center"
					justify="space-between"
					gaps="10px"
					maxedWidth
				>
					<Row
						align="center"
						gaps="5px"
						onClick={headerTitleOnClick}
						style={{
							cursor: sort ? "pointer" : "inherit",
							userSelect: "none",
						}}
					>
						<div>{title}</div>
						{sort ? <SortButton value={sort} /> : null}
					</Row>
					<div>{actions}</div>
				</Row>
			</Header>
			<Overlayer
				style={{ overflow: "hidden" }}
				overlay={overlay}
				zIndex={1}
			>
				<VirtualList
					totalItems={count}
					gap={1}
					itemHeight={43}
					onChangeViewBox={virtualListOnChangeViewBox}
				>
					{(index) => cachedBrands[index]}
				</VirtualList>
			</Overlayer>
		</Column>
	);
};

declare namespace ModelList {
	interface Item {
		id: number;

		disabled?: boolean;
		active?: boolean;
		favorite?: boolean;
		deletable?: boolean;

		label: string;
	}

	type SortMethod = useCarBrandSubscription.SortMethod;

	interface Props {
		selected: Nullable<number>;
		offset: number;
		count: number;
		sort?: SortingOrder;

		title: string;
		actions?: ReactNode;
		overlayLabel?: string;
		items: Item[];

		onChangeSelected: (selected: Nullable<number>) => void;
		onOffsetChange: (offset: number) => void;
		onChunkSizeChange: (chunkSize: number) => void;
		onChangeSort?: Dispatch<SortingOrder>;

		onChangeActive?: (id: number, isActive: boolean) => void;
		onChangeFavorite?: (id: number, favorite: boolean) => void;

		onDelete?: (id: number) => void;
	}
}

export default ModelList;
