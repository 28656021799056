/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, {
	PropsWithChildren,
	ReactNode,
	useCallback,
	useMemo,
} from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import StyleProps from "uikit/dist/types/StyleProps";
import { extend } from "lodash";

import Modal from "../Modal";
import KeyBind from "../KeyBind";

import Header from "./components/Header";
import Root from "./components/Root";
import Footer from "./components/Footer";

// eslint-disable-next-line prettier/prettier
const InputModal = extend(
	(({
		children,

		style,
		className,

		draggable = true,
		title,
		dimmed = true,
		useDefaultFooter = true,
		pointerEvents = "auto",
		action,
		position,
		width,
		onChangePosition,
		isSave = false,
		onClose,
		onSubmit,
	}) => {
		const headerOnClose = useCallback(() => {
			onClose?.();
		}, [onClose]);

		const footerOnCancel = useCallback(() => {
			onClose?.();
		}, [onClose]);

		const footerOnSave = useCallback(() => {
			onSubmit?.();
		}, [onSubmit]);

		const content = useMemo(
			() => (
				<Root
					style={style}
					className={className}
					width={width}
					sizes="auto! 1fr auto!"
				>
					<Header
						draggable={draggable}
						title={title}
						onClose={headerOnClose}
						action={action}
					/>
					{children}
					{useDefaultFooter && (
						<Footer
							onCancel={footerOnCancel}
							onSave={footerOnSave}
							isSave={isSave}
						/>
					)}
				</Root>
			),
			[
				action,
				children,
				className,
				draggable,
				footerOnCancel,
				footerOnSave,
				headerOnClose,
				isSave,
				style,
				title,
				useDefaultFooter,
				width,
			],
		);

		return (
			<Modal dimmed={dimmed} pointerEvents={pointerEvents}>
				<KeyBind keys={["Enter"]} onUse={onSubmit} />
				<KeyBind keys={["Escape"]} onUse={onClose} />
				{draggable ? (
					<Draggable
						handle=".draggable-area"
						position={position}
						onStop={onChangePosition}
					>
						{content}
					</Draggable>
				) : (
					content
				)}
			</Modal>
		);
	}) as React.FC<InputModal.Props>,
	{
		root: (Root as any).styledComponentId,
	},
);

declare namespace InputModal {
	interface Props extends PropsWithChildren, StyleProps {
		draggable?: boolean;
		title?: ReactNode;
		dimmed?: boolean;
		useDefaultFooter?: boolean;
		pointerEvents?:
			| "none"
			| "auto"
			| "visiblePainted"
			| "visibleFill"
			| "visibleStroke"
			| "painted"
			| "fill"
			| "stroke";
		action?: ReactNode;
		position?: { x: number; y: number };
		width?: number;
		onChangePosition?: DraggableEventHandler;
		isSave?: boolean;

		onClose?: () => void;
		onSubmit?: () => void;
	}
}

export default InputModal;
