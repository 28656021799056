/* eslint-disable no-shadow */
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { NotUndefined, sha1 } from "object-hash";
// eslint-disable-next-line import/no-unresolved
import { ModelEventMeta } from "@node-elion/syncron";
import { isEqual } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import useObjectEditor from "./useObjectEditor";

declare namespace useModelSubscribe {
	interface ServiceSubscribeOnUpdateData<Model> {
		models: Model[];
		metadataState: ModelEventMeta;
	}

	interface Data<Model> extends ServiceSubscribeOnUpdateData<Model> {
		loading: boolean;
	}

	type OnUpdate<Model> = (
		data: ServiceSubscribeOnUpdateData<Model>,
	) => void | Promise<void>;

	interface Subscription<Options> {
		unsubscribe(): Promise<void>;
		update(options: Options): Promise<void>;
	}

	interface Service<Options, Model> {
		subscribe(
			options: Options,
			onUpdate: OnUpdate<Model>,
		): Promise<Subscription<Options> | null>;
	}

	interface Options<Model> {
		debounce?: number;
		onUpdate?: Dispatch<useModelSubscribe.Data<Model>>;
	}
}

function useModelSubscribe<SubscribeOptions extends NotUndefined, Model>(
	subscribeOptions: SubscribeOptions,
	Service: useModelSubscribe.Service<SubscribeOptions, Model>,
	options: useModelSubscribe.Options<Model> = { debounce: 300 },
): useModelSubscribe.Data<Model> {
	const subscriptionRef =
		useRef<useModelSubscribe.Subscription<SubscribeOptions> | null>(null);

	const ServiceRef = useRef<useModelSubscribe.Service<
		SubscribeOptions,
		Model
	> | null>(null);

	const subscribeOptionsRef = useRef<SubscribeOptions | null>(null);

	const currentSubscribePromiseRef =
		useRef<
			Promise<useModelSubscribe.Subscription<SubscribeOptions> | null>
		>();

	const [data, setData] = useState<useModelSubscribe.Data<Model>>({
		loading: false,
		models: [],
		metadataState: {},
	});

	const dataEditor = useObjectEditor(data, setData);

	const subscribeOptionsHash = sha1(subscribeOptions);

	const lastSubscribeOptionsHashRef = useRef<string | null>(null);

	const update = useCallback(async () => {
		if (ServiceRef.current !== Service) {
			ServiceRef.current = Service;
			subscribeOptionsRef.current = subscribeOptions;

			const subscription = subscriptionRef.current;
			subscriptionRef.current = null;

			if (subscription) subscription.unsubscribe();

			const subscribePromise = Service.subscribe(
				subscribeOptions,
				(newData) => {
					setData((data) => {
						const updatedData = {
							...newData,
							loading: data.loading,
						};

						options.onUpdate?.(updatedData);

						return updatedData;
					});
				},
			);

			currentSubscribePromiseRef.current = subscribePromise;

			const newSubscription = await subscribePromise;

			if (currentSubscribePromiseRef.current === subscribePromise) {
				subscriptionRef.current = newSubscription;

				if (
					lastSubscribeOptionsHashRef.current !== subscribeOptionsHash
				) {
					dataEditor.set("loading", true);

					await subscriptionRef.current?.update(
						subscribeOptionsRef.current,
					);

					lastSubscribeOptionsHashRef.current = subscribeOptionsHash;

					dataEditor.set("loading", false);
				}
			} else newSubscription?.unsubscribe();
		} else if (!isEqual(subscribeOptionsRef.current, subscribeOptions)) {
			subscribeOptionsRef.current = subscribeOptions;

			const subscription = subscriptionRef.current;

			dataEditor.set("loading", true);

			await subscription?.update(subscribeOptions);

			lastSubscribeOptionsHashRef.current = subscribeOptionsHash;

			dataEditor.set("loading", false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Service, subscribeOptionsHash]);

	const debouncedUpdate = useDebouncedCallback(update, options.debounce);

	useEffect(() => {
		debouncedUpdate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Service, subscribeOptionsHash]);

	useEffect(
		() => () => {
			(async () => {
				(await currentSubscribePromiseRef.current)?.unsubscribe();
			})();
		},
		[],
	);

	return data;
}

export default useModelSubscribe;
