import React, { PropsWithChildren } from "react";
import { Column } from "uikit";

import Label from "./components/Label";

const LabeledBlock: React.FC<LabeledBlock.Props> = ({ label, children }) => (
	<Column gaps="12px*">
		<Label>{label}</Label>
		{children}
	</Column>
);

declare namespace LabeledBlock {
	interface Props extends PropsWithChildren {
		label: string;
	}
}

export default LabeledBlock;
