/* eslint-disable no-shadow */
import { ModelId } from "../../types/ModelId";
import OptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Base from "../Base";
import Language from "../Language";

class Currency extends Base {
	public static fromResponse(data: any): Currency.Model {
		return {
			id: data.id,
			name: data.name,
			settings: data.settings,
		};
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.currency.getAll(),
		);
		return res?.items?.map(this.fromResponse);
	}
}

declare namespace Currency {
	interface Model {
		readonly id: ModelId;
		readonly name: Name;
		readonly settings: Settings;
	}

	type Name = Record<Language, string>;

	interface Settings {
		code: string;
		name: string;
		symbol: string;
	}

	interface SubscribeOptions extends OptionsBase<Currency.Model> {}
}

export default Currency;
