import { LatLngLiteral } from "leaflet";
import { Dispatch } from "react";
import { SliderData } from "uikit/dist/components/OrderTabs";

import { Schema } from "../../../../../../../redux/constants/OrdersPage/order";
import { Language } from "../../../../../../../services";

interface IDefaultCity {
	id?: number;
	value: string;
	coordinates: Array<number> | LatLngLiteral;
	settlement?: string | Record<Language, string>;
}
export interface TabState {
	customerId: number | null;
	defaultCity: IDefaultCity;
	popularAddresses: any[];
	additionalNumbers: number;

	route: any;
	routeTaximeter: any;

	modals: {
		time: boolean;
		classes: boolean;
		services: boolean;
		editableParam?: keyof Schema | null;
	};
}

export interface Tab {
	uid: number;
	id?: number;
	data: SliderData;
	form: Schema;
	validator: Record<keyof Schema, boolean>;
	state: TabState;
	setState: Dispatch<React.SetStateAction<TabState>>;
}

export const tabStateAreEqual = (prev: TabState, next: TabState) =>
	Object.keys(prev).every((key) => prev[key] === next[key]);
