import React, { memo, useMemo, Suspense } from "react";
import { Map, theme } from "uikit";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-unresolved
import { PolygonList } from "uikit/dist/components/Map/components/PolygonEditor/type";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe";
import ParkingModel from "../../../../../services/Parking";

const BaseParkings: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const parkings = useModelSubscribe({}, ParkingModel);

	const localizedSectors = useMemo<PolygonList | undefined>(
		() =>
			parkings?.cache.map((parking) => ({
				id: parking.id,
				name:
					`${t(
						`mainPage.archives.orders.modal.map.polygons.parking`,
					)} ${parking.name?.[lang]}` ||
					Object.values(parking.name)?.[0],
				color: theme.colors.info,
				vertices: parking.vertices.map((v) => [v.lat, v.lng]),
				editable: false,
			})),
		[lang, parkings?.cache, t],
	);

	return (
		<Suspense>
			<Map.PolygonEditor value={localizedSectors} editing={false} />
		</Suspense>
	);
};

export const Parkings = memo(BaseParkings);
export default BaseParkings;
