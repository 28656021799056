import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	ValueStepper,
	StyledRow,
} from "../../../../../../../../../../../../../../../../../../../../components/common";
import tPath from "../../../../../../constants/tPath";

const PriceZoneChangeDistanceThreshold: React.FC<PriceZoneChangeDistanceThreshold.Props> =
	memo(({ value, onChange, disabled = false }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const distanceValue = valueEditor.useGetter("value");
		const setDistanceValue = valueEditor.useSetter("value");

		return (
			<StyledRow alignItems="center" gap="8px">
				<CheckBox
					disabled={disabled}
					label={`${t(
						`${tPath}.modal.priceZoneChangeDistanceThreshold`,
					)}:`}
					value={active}
					onChange={setActive}
				/>
				<ValueStepper
					disabled={disabled}
					value={distanceValue}
					onChange={setDistanceValue}
				/>
				{t("units.meter")}
			</StyledRow>
		);
	});

declare namespace PriceZoneChangeDistanceThreshold {
	interface Props {
		value: Value;
		disabled?: boolean;
		onChange: (x: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
	}
}

export default PriceZoneChangeDistanceThreshold;
