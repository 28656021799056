import React, { Suspense, memo, useMemo, useState } from "react";
import { Column } from "uikit";
import styled from "styled-components";

import { Archive } from "../../../../../../redux/reducers/Archives/interface";
import Header from "../../../../../../components/BasicPageLayout/Header";

import HistoryBadges from "../../Badges/HistoryBadges";
import SuspenseLoader from "../../SuspenseLoader";

import ActionDescription from "./ActionDescription";

const StyledHistoryBadges = styled(HistoryBadges)`
	top: 57px;
	left: 20px;
`;

const InfoModalHistory: React.FC<InfoModalHistory.Props> = ({
	history,
	maxHeight = "95%",
}) => {
	const [displayBadges, setDisplayBadges] =
		useState<HistoryBadges.DisplayBadges>({
			type: true,
			orderNote: true,
			status: true,
			orderNumber: true,
			orderDate: true,
			taxiService: true,
			services: true,
			"passenger.customers": true,
			points: true,
			"passenger.points": true,
			closedOrdersComments: true,
			cost: true,
			rateSettings: true,
			billingCustomer: true,
			paymentAccount: true,
			idleTimeMilliseconds: true,
			passengersCount: true,
			waitingTimeMilliseconds: true,
			transportationType: true,
			executorNotes: true,
			customerNotes: true,
			outsideSettlementKm: true,
			additionalCost: true,
			hourlyMilliseconds: true,
			source: true,
			base: true,
			date: true,
			carClasses: true,
			cars: true,
			executors: true,
			phones: true,
		});

	const entries = useMemo(() => {
		if (!history) return [];
		if (!displayBadges.date) {
			return [...history].sort((a, b) => a.timestamp - b.timestamp);
		}
		return history;
	}, [history, displayBadges.date]);

	return (
		<>
			<Header>
				<StyledHistoryBadges
					value={displayBadges}
					onChange={setDisplayBadges}
				/>
			</Header>
			<Column
				maxedWidth
				style={{
					padding: "5px",
					paddingBottom: "3rem",
					position: "absolute",
					overflowX: "hidden",
					overflowY: "auto",
					maxHeight,
				}}
			>
				<Column>
					<Column
						gaps="5px*"
						style={{
							borderRadius: "5px",
							overflow: "hidden",
							backgroundColor: "white",
						}}
					>
						<Suspense fallback={<SuspenseLoader />}>
							{!!entries.length &&
								entries.map((entry, i) => (
									<ActionDescription
										key={`${i}-${entry.id}`}
										entry={entry}
										displayBadges={displayBadges}
									/>
								))}
						</Suspense>
					</Column>
				</Column>
			</Column>
		</>
	);
};

declare namespace InfoModalHistory {
	interface Props {
		history: Archive.Orders.Order.History[];
		maxHeight?: string;
	}
}

export const InfoModalHistoryMemo = memo(InfoModalHistory);
export default InfoModalHistory;
