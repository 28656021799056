import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<{ active?: boolean }>`
	padding: 5px 0px;

	color: ${({ active }) =>
		active ? theme.colors.accent : theme.colors.secondary};

	cursor: pointer;
`;

export default Root;
