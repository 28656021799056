import React, { memo, useCallback, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { useInternal } from "uikit";

import { BlacklistPhoneLevel } from "../../../../pages/MainPage/pages/Blacklist/tabs/Customers/components/Modal/components/Content/tabs/Main/components/Level/constants";
import { PhoneBlacklistData } from "../../../../redux/services/Order/getCustomerByPhone";
import {
	StyledColumn,
	StyledRow,
	TextMemo,
	StyledP,
	BaseElementIdEnum,
	CardModal,
} from "../../../common";
import { CardModalBase } from "../../../common/Modal";
import useKeyBind from "../../../../hooks/useKeyBind";

const Content = styled(StyledColumn)`
	gap: 10px;
	padding: 8px 8px 1rem 8px;
	justify-content: start;
	background: #ffffff;
	overflow: hidden;
	width: 450px;
`;

const BasePhoneBlacklist: React.FC<BasePhoneBlacklist.Props> = ({
	level = BlacklistPhoneLevel.PHONE,
	description,
	createdAt,
	onSave,
	onClose,
	content,
	footer,
	footerDivider,
	...props
}) => {
	const { t } = useTranslation();
	description = description ?? t("orders.modals.phoneBlacklist.str200") ?? "";
	const [nextStep, setNextStep] = useInternal<BaseElementIdEnum>(
		BaseElementIdEnum.SAVE,
	);

	const [action, setAction] = useInternal<"save" | "close">("save");

	const descriptions = useMemo(
		() => ({
			[BlacklistPhoneLevel.BLOCKED]: t([
				`orderPage.order_form.blocked_client.modal_decline_text`,
				"",
			]),
			[BlacklistPhoneLevel.PHONE]: t([
				`orderPage.order_form.blocked_client.modal_decline_text`,
				"",
			]),
			[BlacklistPhoneLevel.WARNING]: t([
				`orderPage.order_form.blocked_client.modal_warning_text`,
				"",
			]),
		}),
		[t],
	);
	const textCancel = t([`cancel`, "Cancel"]);
	const textConfirm = t([`confirm`, "Confirm"]);

	const title =
		t([
			"orderPage.order_form.blocked_client.modal_title",
			"Client is blocked",
		]) || "";

	const reason = t([
		"orderPage.order_form.blocked_client.reason_label",
		"Reason for blocking",
	]);
	const valueBlocked = t([
		`block_client_modal.block_level.options.${level}`,
		"block_client_modal.block_level.options.base",
		"Reason is unclear",
	]);
	const textBlocked = t([
		"block_client_modal.block_level.label",
		"Type of block",
	]);

	const textTime = t([
		"orderPage.order_form.blocked_client. modal_time_blocked",
		"Block time",
	]);

	const time = useMemo(
		() => moment(createdAt).format(`DD.MM.yyyy HH:mm`) || "",
		[createdAt],
	);

	const isBlocked = useMemo(
		() => level === BlacklistPhoneLevel.BLOCKED,
		[level],
	);

	const onConfirm = useCallback<CardModalBase.Props["onSave"]>(
		(event) => {
			if (isBlocked || action === "close") onClose(event);
			else onSave(event);

			event?.preventDefault?.();
			event?.stopPropagation?.();
		},
		[action, isBlocked, onClose, onSave],
	);

	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) elem.focus();

			return elem;
		},
		[],
	);

	useLayoutEffect(() => {
		if (isBlocked) setAction("close");
	}, [isBlocked, setAction]);

	useKeyBind(["Tab"], (event) => {
		if (nextStep === BaseElementIdEnum.SAVE) {
			setAction("close");
			nextElementById(BaseElementIdEnum.CLOSE);
			setNextStep(BaseElementIdEnum.CLOSE);
		} else if (isBlocked) {
			setAction("close");
			nextElementById(BaseElementIdEnum.CLOSE);
			setNextStep(BaseElementIdEnum.CLOSE);
		} else {
			setAction("save");
			nextElementById(BaseElementIdEnum.SAVE);
			setNextStep(BaseElementIdEnum.SAVE);
		}

		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<CardModal
			{...props}
			title={title}
			onClose={onClose}
			onSave={onConfirm}
			textClose={textCancel}
			textSave={textConfirm}
			footer={footer}
			footerDivider={footerDivider}
			buttonSave={{
				disabled: isBlocked,
				autoFocus: !isBlocked,
			}}
			buttonClose={{
				autoFocus: isBlocked,
			}}
		>
			<Content>
				<StyledRow p="1rem">
					<TextMemo
						textStyle={{
							justify: "center",
							textAlign: "center",
							font: { size: "15px", line: "16px", fw: "500" },
							text: { ws: "normal" },
						}}
						text={descriptions[level]}
					/>
				</StyledRow>
				<StyledColumn p="0 0 0 1rem" gap="10px">
					<TextMemo
						textStyle={{
							font: { fw: "500" },
						}}
						text={`${textBlocked} :`}
						value={valueBlocked}
					/>
					<TextMemo
						textStyle={{
							font: { fw: "500" },
						}}
						text={`${textTime} :`}
						value={time}
					/>
					<StyledRow
						flex={{ wrap: "wrap" }}
						justify="start"
						alignItems="start"
						gap="5px"
					>
						<StyledP font={{ fw: "500" }}>{`${reason} :`}</StyledP>
						<StyledP
							text={{
								ws: "normal",
								hyphens: "manual",
								wb: "break-all",
							}}
						>{`${description}`}</StyledP>
					</StyledRow>
				</StyledColumn>

				{content}
			</Content>
		</CardModal>
	);
};

declare namespace BasePhoneBlacklist {
	interface Props extends CardModalBase.Props {
		createdAt: PhoneBlacklistData["createdAt"];
		level: PhoneBlacklistData["level"];
		description: PhoneBlacklistData["description"];
		content?: React.ReactNode;
	}
}

export const PhoneBlacklist = memo(BasePhoneBlacklist);
export default BasePhoneBlacklist;
