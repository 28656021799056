function blobToString(blob: Blob) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			resolve(reader.result as string);
		};

		reader.onerror = () => {
			reject(new Error("Unable to convert Blob to string."));
		};

		reader.readAsBinaryString(blob);
	});
}

export default blobToString;
