// eslint-disable-next-line import/no-unresolved

import React, { memo, useLayoutEffect, useMemo } from "react";
import { Polyline, useMap } from "react-leaflet";
import MarkerController from "react-leaflet-enhanced-marker";
import { Map, react } from "uikit";
import "leaflet-polylinedecorator";
import PropsOf from "uikit/src/types/PropsOf";
import LocalController from "./Controller";
import MapService from "../../../../../services/Map";
import Marker from "../../../../Marker";

// const routeColors = [
// 	"#e83a53",
// 	"#54ad4a",
// 	"#397eb0",
// 	"#974ba4",
// 	"#f49e3b",
// 	"#fcfb2e",
// 	"#ad4f20",
// 	"#f680bd",
// 	"#aca7a3",
// ];

const polylinePathOptions = { color: "#e83a53" };

const MapObjectBase = react.withController<
	MapObject.PropsBase,
	LocalController
>(({ controller, object }) => {
	const map = useMap();

	const geometry = object?.geometry;

	const node = useMemo(() => {
		if (!geometry) {
			return null;
		}

		if (geometry.type === "Point") {
			return (
				<MarkerController
					icon={<Marker key={`markerIcon`} />}
					draggable={false}
					position={geometry.coordinates}
				/>
			);
		}
		if (geometry.type === "LineString") {
			return (
				<Polyline
					positions={geometry.coordinates}
					pathOptions={polylinePathOptions}
				/>
			);
		}

		if (geometry.type === "MultiLineString") {
			return (
				<>
					{geometry.coordinates.map((coordinates, index) => (
						<Polyline
							key={index}
							positions={coordinates}
							pathOptions={polylinePathOptions}
						/>
					))}
				</>
			);
		}
		if (geometry.type === "Polygon") {
			return (
				<Map.PolygonEditor
					value={geometry.coordinates.map((coordinates, index) => ({
						id: index.toString(),
						name: "",
						vertices: coordinates.map((coordinates) => [
							coordinates.lat,
							coordinates.lng,
						]),
						color: "#54ad4a",
						creating: false,
						editable: false,
						disabled: false,
					}))}
				/>
			);
		}

		return null;
	}, [geometry]);

	controller.setContext({ map, object });

	useLayoutEffect(() => {
		controller.focus();
	}, [controller, object]);

	return node;
}, LocalController);

const MapObject = memo(MapObjectBase);

declare namespace MapObject {
	type Controller = LocalController;

	interface PropsBase {
		object?: MapService.Search.Object | null;
	}

	type Props = PropsOf<typeof MapObject>;
}

export default MapObject;
