import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { CheckBox, react, ToggleButton } from "uikit";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import PaymentSystem, {
	PaymentSystemFrontEndType,
} from "../../../../../../services/PaymentSystem";
import {
	StyledColumn,
	StyledRow,
	StyledP,
} from "../../../../../../components/common";

import translationPath from "../../constants/translationPath";

import InternalController from "./Controller";

const SystemFeeBase = react.withController<
	SystemFee.PropsBase,
	SystemFee.Controller
>(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const type = valueEditor.useGetter("type");

	// assetPaymentsGlobalFeeFromSystemValue
	const assetPaymentsGlobalFeeFromSystemValue = valueEditor.useGetter(
		"assetPaymentsGlobalFeeFromSystem",
	);
	const setAssetPaymentsGlobalFeeFromSystemValue = valueEditor.useSetter(
		"assetPaymentsGlobalFeeFromSystem",
	);

	// systemReturnFeeToExecutor
	const systemReturnFeeToExecutorValue = valueEditor.useGetter(
		"systemReturnFeeToExecutor",
	);
	const systemReturnFeeToExecutorOnChange = valueEditor.useSetter(
		"systemReturnFeeToExecutor",
	);

	// texts
	const text = useMemo(() => {
		const client =
			PaymentSystemFrontEndType.CUSTOMER === type ||
			PaymentSystemFrontEndType.TEMPLATE_CUSTOMER === type;

		const text3 = client
			? t([
					`${translationPath}.text.client`,
					"Commission from the client",
			  ])
			: t([
					`${translationPath}.text.client_executor`,
					"Commission from the client",
			  ]);

		const text2 =
			t(`${translationPath}.text.commissionFromServiseTaxi`) ?? "";

		return assetPaymentsGlobalFeeFromSystemValue ? text2 : text3;
	}, [assetPaymentsGlobalFeeFromSystemValue, type, t]);

	const placeholder = useMemo(
		() =>
			t([
				`${translationPath}.label.feeToExecutor`,
				"Driver compensation",
			]),
		[t],
	);

	return (
		<StyledColumn gap="10px">
			<StyledRow
				alignItems="center"
				gap="10px"
				onClick={(event) => event.stopPropagation()}
				w="100%"
			>
				<ToggleButton.ToggleButton
					value={assetPaymentsGlobalFeeFromSystemValue}
					onChange={setAssetPaymentsGlobalFeeFromSystemValue}
				/>
				<StyledP>{text}</StyledP>
			</StyledRow>
			<StyledRow alignItems="center" gap="10px" m="0 0 0 1rem">
				<CheckBox
					disabled={!assetPaymentsGlobalFeeFromSystemValue}
					label={placeholder}
					value={systemReturnFeeToExecutorValue}
					onChange={systemReturnFeeToExecutorOnChange}
				/>
			</StyledRow>
		</StyledColumn>
	);
}, InternalController);

const SystemFee = memo(SystemFeeBase);

declare namespace SystemFee {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default SystemFee;
