/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Reports } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	reportData: [],
};

const setReportsData: ReduxReducer<State, State> = (state, action) => {
	state.reportData = action.payload.reportData;
};

const reportCashlessAccountsSummaryReportData = createSlice({
	name: Reports.ConstantReportSlice.CASHLESS_ACCOUNTS_SUMMARY_REPORT,
	initialState,
	reducers: {
		setReportsData,
	},
});

export const reportCashlessAccountsSummaryReportDataAction =
	reportCashlessAccountsSummaryReportData.actions;
export const reportCashlessAccountsSummaryReportDataReducer =
	reportCashlessAccountsSummaryReportData.reducer;
export type reportCashlessAccountsSummaryReportDataState = State;

export default reportCashlessAccountsSummaryReportData;
