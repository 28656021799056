import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CheckBox, ToggleButton, Row, Icon, theme } from "uikit";

import TaxiService from "../../../../../../../../../../services/TaxiService";
import Company from "../../../../../../../../../../services/Company";
import Map from "../../../../../../../../../../redux/services/Map";
import LightTable from "../../../../../../../../../../components/LightTable";
import {
	ColumnId,
	ColumnIds,
} from "../../../../../../../../../../constants/tables/types";

const ObjectGroupTable: React.FC<ObjectGroupTable.Props> = ({
	columns,
	selected,
	data,
	language,
	taxiServices,
	companies,

	onChangeSelected,

	...tableProps
}) => {
	const { t } = useTranslation();

	const renderColumn = useCallback(
		(column: ColumnId<"objectGroup">) => {
			switch (column) {
				case "checkbox":
					return (
						<LightTable.Column width={36}>
							<LightTable.HeaderCell verticalAlign="middle">
								<CheckBox
									value={
										selected.length === data.length &&
										data.length !== 0
									}
									onChange={(value) =>
										onChangeSelected(
											value
												? data.map((item) => item.id)
												: [],
										)
									}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell verticalAlign="middle">
								{(rowData) => (
									<CheckBox
										value={selected.includes(rowData.id)}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "active":
					return (
						<LightTable.Column
							width={90}
							key={column}
							resizable
							sortable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectGroupTable.str150",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								dataKey={column}
								verticalAlign="middle"
							>
								{(rowData) => (
									<div
										onClick={(event) =>
											event.stopPropagation()
										}
									>
										<ToggleButton.ToggleButton
											value={rowData.status}
											onChange={(value) => {
												Map.LocalObjectGroup.update({
													...(rowData as Map.LocalObjectGroup.Modified),
													status: value,
												});
											}}
										/>
									</div>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "name":
					return (
						<LightTable.Column flexGrow={1} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectGroupTable.str151",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="fields.title"
								fullText
							>
								{(rowData) =>
									rowData.fields?.[language]?.title ?? ""
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "company":
					return (
						<LightTable.Column width={120} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectGroupTable.str152",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="company"
								fullText
							>
								{(rowData) => {
									const companyId = taxiServices?.find(
										(taxiService) =>
											taxiService.id ===
											rowData.taxiServiceId,
									)?.company?.id;

									return (
										companies?.find(
											(company) =>
												company.id === companyId,
										)?.name?.[language] ?? ""
									);
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "branch":
					return (
						<LightTable.Column width={120} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectGroupTable.str153",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="taxiService"
								fullText
							>
								{(rowData) =>
									taxiServices?.find(
										(taxiService) =>
											taxiService.id ===
											rowData.taxiServiceId,
									)?.settlement?.[language] ?? ""
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "createdAt":
					return (
						<LightTable.Column width={120} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectGroupTable.str154",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="createdAt"
							>
								{(rowData) =>
									moment(rowData.createdAt).format(
										"DD.MM.YYYY",
									)
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "properties":
					return (
						<LightTable.Column width={100}>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectGroupTable.str155",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell verticalAlign="middle">
								{(rowData) => (
									<Row align="center" gaps="16px">
										<Icon
											id="house-location"
											size={{ width: 17, height: 22 }}
											colors={[
												rowData.status
													? theme.colors.primary
													: theme.colors
															.disabled_text,
											]}
										/>
										<Icon
											id={`${
												rowData.visibility ? "" : "not-"
											}shown-in-mobile`}
											size={[20, 20]}
											colors={[
												rowData.status &&
												rowData.visibility
													? theme.colors.primary
													: theme.colors
															.disabled_text,
											]}
										/>
									</Row>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				default:
					return <></>;
			}
		},
		[
			companies,
			data,
			language,
			onChangeSelected,
			selected,
			taxiServices,
			t,
		],
	);

	return (
		<LightTable {...tableProps} data={data}>
			{columns.map((column) => renderColumn(column))}
		</LightTable>
	);
};

declare namespace ObjectGroupTable {
	interface Props extends LightTable.Props {
		columns: ColumnIds<"objectGroup">;
		selected: number[];
		data: Map.LocalObjectGroup[];
		language: Map.Language;
		taxiServices?: TaxiService.Model[];
		companies?: Company.Model[];

		onChangeSelected: Dispatch<number[]>;
	}
}

export default ObjectGroupTable;
