import React, { memo, Suspense, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";
import { StyledColumn, StyledRow } from "../../../../../../components/common";

import Mark from "../../Mark";

const RateSettingsDifference: React.FC<RateSettingsDifference.Props> = ({
	change,
}) => {
	const { t } = useTranslation();
	const filing = t("filing");
	const irt = t("intercity_road_trip");
	const yes = t("yes");
	const no = t("no");

	const previous = useMemo(() => {
		const isRoundTrip = `${irt}: ${change.previous.isRoundTrip ? yes : no}`;
		const includePickupCost = `${filing}: ${
			change.previous.includePickupCost ? yes : no
		}`;

		return {
			isRoundTrip,
			includePickupCost,
		};
	}, [
		change.previous.includePickupCost,
		change.previous.isRoundTrip,
		filing,
		irt,
		no,
		yes,
	]);

	const actual = useMemo(() => {
		const isRoundTrip = `${irt}: ${change.actual.isRoundTrip ? yes : no}`;
		const includePickupCost = `${filing}: ${
			change.actual.includePickupCost ? yes : no
		}`;

		return {
			isRoundTrip,
			includePickupCost,
		};
	}, [
		change.actual.includePickupCost,
		change.actual.isRoundTrip,
		filing,
		irt,
		no,
		yes,
	]);

	return (
		<Suspense>
			<StyledColumn>
				{actual.isRoundTrip !== previous.isRoundTrip && (
					<StyledRow>
						<Trans
							i18nKey={`change_history.orders.fields.${change.field}`}
							context={change.previous ? "change" : "set"}
							values={{
								prev: previous.isRoundTrip,
								new: actual.isRoundTrip,
							}}
							components={{ mark: <Mark /> }}
						/>
					</StyledRow>
				)}
				{previous.includePickupCost !== actual.includePickupCost && (
					<StyledRow>
						<Trans
							i18nKey={`change_history.orders.fields.${change.field}`}
							context={change.previous ? "change" : "set"}
							values={{
								prev: previous.includePickupCost,
								new: actual.includePickupCost,
							}}
							components={{ mark: <Mark /> }}
						/>
					</StyledRow>
				)}
			</StyledColumn>
		</Suspense>
	);
};

declare namespace RateSettingsDifference {
	interface Props {
		change: History.Field.RateSettings;
	}
}

export const RateSettingsDifferenceMemo = memo(RateSettingsDifference);
export default RateSettingsDifference;
