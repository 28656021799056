import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)<Root.Props>`
	${({ width }) => width && `width: ${width}px;`}

	padding-inline: 12px;
`;

declare namespace Root {
	interface Props {
		width?: number;
	}
}

export default Root;
