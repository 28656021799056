import React, { useMemo } from "react";
import { Column, RadioButton } from "uikit";
import { SelectEntry } from "../../../types/settingEntries";

interface SelectWrapperProps<V> {
	entry: SelectEntry<"meta", V>;
	value: SelectEntry<"value", V>;
	onChange: (value: SelectEntry<"value", V>) => void;
}

function RadioSelectWrapper<V>({
	entry,
	value,
	onChange,
}: SelectWrapperProps<V>) {
	const selectedKey = useMemo(
		() => entry.options.find((option) => option.value === value)?.key,
		[entry.options, value],
	);

	return (
		<Column gaps="15px*">
			{entry.options.map((option, i) => (
				<RadioButton.RadioButton
					key={i}
					label={option.label}
					selected={option.key === selectedKey}
					onClick={() => onChange(option.value)}
				/>
			))}
		</Column>
	);
}

export default RadioSelectWrapper;
