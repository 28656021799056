import L, {
	Pattern,
	Polyline as PolylineBase,
	PolylineDecorator,
} from "leaflet";
import objectHash from "object-hash";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { Polyline, PolylineProps, useMap } from "react-leaflet";

const DecoratedPolyline: React.FC<DecoratedPolyline.Props> = ({
	patterns,
	...props
}) => {
	const decoratorRef = useRef<PolylineDecorator | null>(null);
	const map = useMap();

	useLayoutEffect(
		() => () => {
			decoratorRef.current?.remove();
		},
		[],
	);

	const setRef = useCallback(
		(polyline: PolylineBase | null) => {
			decoratorRef.current?.remove();

			if (!patterns || !polyline) return;

			decoratorRef.current = L.polylineDecorator(polyline, {
				patterns,
			}).addTo(map);
		},
		[map, patterns],
	);

	const polylineKey = useMemo(
		() => objectHash([props.positions, props.pathOptions]),
		[props.pathOptions, props.positions],
	);

	return <Polyline key={polylineKey} ref={setRef} {...props} />;
};

declare namespace DecoratedPolyline {
	interface Props extends PolylineProps {
		patterns?: Pattern[];
	}
}

export default DecoratedPolyline;
