import makeTable from "./makeTable";

const customersAgents = makeTable({
	allIds: [
		"title",
		"contactPersonName",
		"contactPersonPhone",
		"balance",
		"bonusBalance",
		"mobileApp",
		"cabinet",
		"company",
		"taxiService",
		"status",
		"group",
	],
	defaultIds: [
		"title",
		"contactPersonName",
		"contactPersonPhone",
		"balance",
		"mobileApp",
		"cabinet",
		"company",
		"taxiService",
		"status",
		"group",
	],
	i18tPath: (id) => `mainPage.customers.agents.columns.${id}`,
	initialSortMethod: {
		column: "title",
		type: "desc",
	},
	initialWidths: {
		title: 150,
		contactPersonName: 150,
		contactPersonPhone: 140,
		balance: 140,
		bonusBalance: 140,
		mobileApp: 140,
		cabinet: 140,
		company: 140,
		taxiService: 140,
		status: 140,
		group: 140,
	},
	initialResizable: {
		title: true,
		contactPersonName: true,
		contactPersonPhone: true,
		balance: true,
		bonusBalance: true,
		mobileApp: true,
		cabinet: true,
		company: true,
		taxiService: true,
		status: true,
		group: true,
	},
	initialSortable: {
		title: true,
		contactPersonName: true,
		contactPersonPhone: true,
		balance: true,
		bonusBalance: true,
		mobileApp: true,
		cabinet: true,
		company: true,
		taxiService: true,
		status: true,
		group: true,
	},
});

export default customersAgents;
