import React, { memo } from "react";
import { CheckBox } from "uikit";

const DisabledCheckbox = memo(({ value }: { value: boolean }) => (
	<div
		style={{
			display: "flex",
			justifyContent: "center",
			pointerEvents: "none",
		}}
	>
		<CheckBox disabled value={value} />
	</div>
));

export default DisabledCheckbox;
