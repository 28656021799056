import React, { useState } from "react";
import Root, { StyledDatePicker, StyledTimeInput } from "./Root";

type Props = {
	disabled?: boolean;
	value: Date;
	onChange: () => any;
};

const DateAndTimeWithDisabled: React.FC<Props> = ({ disabled, value }) => {
	const [a, setA] = useState();
	return (
		<Root>
			<StyledDatePicker disabled={disabled} />
			<StyledTimeInput value={value} disabled={disabled} />
		</Root>
	);
};

export default DateAndTimeWithDisabled;
