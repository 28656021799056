/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import {
	DataKind,
	EntryList,
	SelectEntry,
	BooleanEntry,
	EntryGroup,
} from "../../../../../types/settingEntries";
import {
	DisplayOrderPoints,
	displayOrderPointsOptions,
} from "../../../../../types/DisplayOrderPoints";

export type ExecutorAppInterfaceOrdersSelectTypes = DisplayOrderPoints;
export type ExecutorAppInterfaceOrdersMultiSelectTypes = never;

export interface BaseExecutorAppInterfaceOrdersEntries<Data extends DataKind> {
	// Live
	displayDistanceInLive: BooleanEntry<Data>;
	displayCostInLive: BooleanEntry<Data>;
	displayRouteLengthInLive: BooleanEntry<Data>;
	displayAverageCostPerKmInLive: BooleanEntry<Data>;
	displayRideCountInLive: BooleanEntry<Data>;
	displayVehicleClassInLive: BooleanEntry<Data>;
	displayClientStatusInLive: BooleanEntry<Data>;
	displayOrderPointsInLive: SelectEntry<Data, DisplayOrderPoints>;
	displaySectorsInLive: BooleanEntry<Data>;
	displayNotesInLive: BooleanEntry<Data>;
	displayServicesInLive: BooleanEntry<Data>;

	// Required Order
	displayDistanceInRequiredOrder: BooleanEntry<Data>;
	displayCostInRequiredOrder: BooleanEntry<Data>;
	displayRouteLengthInRequiredOrder: BooleanEntry<Data>;
	displayAverageCostPerKmInRequiredOrder: BooleanEntry<Data>;
	displayRideCountInRequiredOrder: BooleanEntry<Data>;
	displayVehicleClassInRequiredOrder: BooleanEntry<Data>;
	displayClientStatusInRequiredOrder: BooleanEntry<Data>;
	displayOrderPointsInRequiredOrder: SelectEntry<Data, DisplayOrderPoints>;
	displaySectorsInRequiredOrder: BooleanEntry<Data>;
	displayNotesInRequiredOrder: BooleanEntry<Data>;
	displayServicesInRequiredOrder: BooleanEntry<Data>;

	// In Progress
	displayDistanceInOrderInProgress: BooleanEntry<Data>;
	displayCostInOrderInProgress: BooleanEntry<Data>;
	displayRouteLengthInOrderInProgress: BooleanEntry<Data>;
	displayAverageCostPerKmInOrderInProgress: BooleanEntry<Data>;
	displayRideCountInOrderInProgress: BooleanEntry<Data>;
	displayVehicleClassInOrderInProgress: BooleanEntry<Data>;
	displayClientStatusInOrderInProgress: BooleanEntry<Data>;
	displayOrderPointsInOrderInProgress: SelectEntry<Data, DisplayOrderPoints>;
	displaySectorsInOrderInProgress: BooleanEntry<Data>;
	displayNotesInOrderInProgress: BooleanEntry<Data>;
	displayServicesInOrderInProgress: BooleanEntry<Data>;

	// Order From Exchange Server
	displayDistanceInOrderFromExchangeServer: BooleanEntry<Data>;
	displayCostInOrderFromExchangeServer: BooleanEntry<Data>;
	displayRouteLengthInOrderFromExchangeServer: BooleanEntry<Data>;
	displayAverageCostPerKmInOrderFromExchangeServer: BooleanEntry<Data>;
	displayRideCountInOrderFromExchangeServer: BooleanEntry<Data>;
	displayVehicleClassInOrderFromExchangeServer: BooleanEntry<Data>;
	displayClientStatusInOrderFromExchangeServer: BooleanEntry<Data>;
	displayOrderPointsInOrderFromExchangeServer: SelectEntry<
		Data,
		DisplayOrderPoints
	>;
	displaySectorsInOrderFromExchangeServer: BooleanEntry<Data>;
	displayNotesInOrderFromExchangeServer: BooleanEntry<Data>;
	displayServicesInOrderFromExchangeServer: BooleanEntry<Data>;
}

export const executorAppInterfaceOrdersGroups: EntryGroup<
	BaseExecutorAppInterfaceOrdersEntries<any>
>[] = [
	{
		title: ["settings.executorApp.interface_orders.groups.free_air"],
		entryKeys: [
			"displayDistanceInLive",
			"displayCostInLive",
			"displayRouteLengthInLive",
			"displayAverageCostPerKmInLive",
			"displayRideCountInLive",
			"displayVehicleClassInLive",
			"displayClientStatusInLive",
			"displayOrderPointsInLive",
			"displaySectorsInLive",
			"displayNotesInLive",
			"displayServicesInLive",
		],
	},
	{
		title: ["settings.executorApp.interface_orders.groups.required_order"],
		entryKeys: [
			"displayDistanceInRequiredOrder",
			"displayCostInRequiredOrder",
			"displayRouteLengthInRequiredOrder",
			"displayAverageCostPerKmInRequiredOrder",
			"displayRideCountInRequiredOrder",
			"displayVehicleClassInRequiredOrder",
			"displayClientStatusInRequiredOrder",
			"displayOrderPointsInRequiredOrder",
			"displaySectorsInRequiredOrder",
			"displayNotesInRequiredOrder",
			"displayServicesInRequiredOrder",
		],
	},
	{
		title: [
			"settings.executorApp.interface_orders.groups.order_in_progress",
		],
		entryKeys: [
			"displayDistanceInOrderInProgress",
			"displayCostInOrderInProgress",
			"displayRouteLengthInOrderInProgress",
			"displayAverageCostPerKmInOrderInProgress",
			"displayRideCountInOrderInProgress",
			"displayVehicleClassInOrderInProgress",
			"displayClientStatusInOrderInProgress",
			"displayOrderPointsInOrderInProgress",
			"displaySectorsInOrderInProgress",
			"displayNotesInOrderInProgress",
			"displayServicesInOrderInProgress",
		],
	},
	{
		title: [
			"settings.executorApp.interface_orders.groups.order_from_exchange_server",
		],
		entryKeys: [
			"displayDistanceInOrderFromExchangeServer",
			"displayCostInOrderFromExchangeServer",
			"displayRouteLengthInOrderFromExchangeServer",
			"displayAverageCostPerKmInOrderFromExchangeServer",
			"displayRideCountInOrderFromExchangeServer",
			"displayVehicleClassInOrderFromExchangeServer",
			"displayClientStatusInOrderFromExchangeServer",
			"displayOrderPointsInOrderFromExchangeServer",
			"displaySectorsInOrderFromExchangeServer",
			"displayNotesInOrderFromExchangeServer",
			"displayServicesInOrderFromExchangeServer",
		],
	},
];

export type ExecutorAppInterfaceOrdersEntries<Data extends DataKind> =
	BaseExecutorAppInterfaceOrdersEntries<Data> &
		EntryList<
			Data,
			ExecutorAppInterfaceOrdersSelectTypes,
			ExecutorAppInterfaceOrdersMultiSelectTypes
		>;
export type ExecutorAppInterfaceInterfaceOrdersMeta =
	ExecutorAppInterfaceOrdersEntries<"meta">;
export type ExecutorAppInterfaceInterfaceOrdersState =
	ExecutorAppInterfaceOrdersEntries<"value">;
export type ExecutorAppInterfaceOrdersValue =
	ExecutorAppInterfaceOrdersEntries<any>[keyof ExecutorAppInterfaceOrdersEntries<any>];

const toggle: BooleanEntry<"meta"> = {
	type: "boolean",
	style: "toggle",
};

const selectDisplayPoints: SelectEntry<"meta", DisplayOrderPoints> = {
	type: "select",
	options: displayOrderPointsOptions,
	style: "radio",
};

export const executorAppInterfaceOrdersMeta: ExecutorAppInterfaceInterfaceOrdersMeta =
	{
		// Free Air
		displayDistanceInLive: toggle,
		displayCostInLive: toggle,
		displayRouteLengthInLive: toggle,
		displayAverageCostPerKmInLive: toggle,
		displayRideCountInLive: toggle,
		displayVehicleClassInLive: toggle,
		displayClientStatusInLive: toggle,
		displayOrderPointsInLive: selectDisplayPoints,
		displaySectorsInLive: toggle,
		displayNotesInLive: toggle,
		displayServicesInLive: toggle,

		// Required Order
		displayDistanceInRequiredOrder: toggle,
		displayCostInRequiredOrder: toggle,
		displayRouteLengthInRequiredOrder: toggle,
		displayAverageCostPerKmInRequiredOrder: toggle,
		displayRideCountInRequiredOrder: toggle,
		displayVehicleClassInRequiredOrder: toggle,
		displayClientStatusInRequiredOrder: toggle,
		displayOrderPointsInRequiredOrder: selectDisplayPoints,
		displaySectorsInRequiredOrder: toggle,
		displayNotesInRequiredOrder: toggle,
		displayServicesInRequiredOrder: toggle,

		// In Progress
		displayDistanceInOrderInProgress: toggle,
		displayCostInOrderInProgress: toggle,
		displayRouteLengthInOrderInProgress: toggle,
		displayAverageCostPerKmInOrderInProgress: toggle,
		displayRideCountInOrderInProgress: toggle,
		displayVehicleClassInOrderInProgress: toggle,
		displayClientStatusInOrderInProgress: toggle,
		displayOrderPointsInOrderInProgress: selectDisplayPoints,
		displaySectorsInOrderInProgress: toggle,
		displayNotesInOrderInProgress: toggle,
		displayServicesInOrderInProgress: toggle,

		// Order From Exchange Server
		displayDistanceInOrderFromExchangeServer: toggle,
		displayCostInOrderFromExchangeServer: toggle,
		displayRouteLengthInOrderFromExchangeServer: toggle,
		displayAverageCostPerKmInOrderFromExchangeServer: toggle,
		displayRideCountInOrderFromExchangeServer: toggle,
		displayVehicleClassInOrderFromExchangeServer: toggle,
		displayClientStatusInOrderFromExchangeServer: toggle,
		displayOrderPointsInOrderFromExchangeServer: selectDisplayPoints,
		displaySectorsInOrderFromExchangeServer: toggle,
		displayNotesInOrderFromExchangeServer: toggle,
		displayServicesInOrderFromExchangeServer: toggle,
	};

const initialState: ExecutorAppInterfaceInterfaceOrdersState = {
	// Free Air
	displayDistanceInLive: true,
	displayCostInLive: true,
	displayRouteLengthInLive: true,
	displayAverageCostPerKmInLive: true,
	displayRideCountInLive: true,
	displayVehicleClassInLive: true,
	displayClientStatusInLive: true,
	displayOrderPointsInLive: "all",
	displaySectorsInLive: true,
	displayNotesInLive: true,
	displayServicesInLive: true,

	// Required Order
	displayDistanceInRequiredOrder: true,
	displayCostInRequiredOrder: true,
	displayRouteLengthInRequiredOrder: true,
	displayAverageCostPerKmInRequiredOrder: true,
	displayRideCountInRequiredOrder: true,
	displayVehicleClassInRequiredOrder: true,
	displayClientStatusInRequiredOrder: true,
	displayOrderPointsInRequiredOrder: "all",
	displaySectorsInRequiredOrder: true,
	displayNotesInRequiredOrder: true,
	displayServicesInRequiredOrder: true,

	// In Progress
	displayDistanceInOrderInProgress: true,
	displayCostInOrderInProgress: true,
	displayRouteLengthInOrderInProgress: true,
	displayAverageCostPerKmInOrderInProgress: true,
	displayRideCountInOrderInProgress: true,
	displayVehicleClassInOrderInProgress: true,
	displayClientStatusInOrderInProgress: true,
	displayOrderPointsInOrderInProgress: "all",
	displaySectorsInOrderInProgress: true,
	displayNotesInOrderInProgress: true,
	displayServicesInOrderInProgress: true,

	// Order From Exchange Server
	displayDistanceInOrderFromExchangeServer: true,
	displayCostInOrderFromExchangeServer: true,
	displayRouteLengthInOrderFromExchangeServer: true,
	displayAverageCostPerKmInOrderFromExchangeServer: true,
	displayRideCountInOrderFromExchangeServer: true,
	displayVehicleClassInOrderFromExchangeServer: true,
	displayClientStatusInOrderFromExchangeServer: true,
	displayOrderPointsInOrderFromExchangeServer: "all",
	displaySectorsInOrderFromExchangeServer: true,
	displayNotesInOrderFromExchangeServer: true,
	displayServicesInOrderFromExchangeServer: true,
};

type Reducer<P> = CaseReducer<
	ExecutorAppInterfaceInterfaceOrdersState,
	PayloadAction<P>
>;

const setAll: Reducer<ExecutorAppInterfaceInterfaceOrdersState> = (
	_,
	{ payload },
) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const executorAppInterfaceOrders = createSlice({
	name: "executorAppInterfaceOrders",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default executorAppInterfaceOrders;
