/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { LatLngLiteral, LatLngTuple, Map as LeafletMap } from "leaflet";
import {
	Button,
	Icon,
	Row,
	Map as UikitMap,
	react,
	theme,
	useInternal,
	usePrevValue,
} from "uikit";
import Control from "react-leaflet-custom-control";
import { clone } from "lodash";

import Map from "../../../../../../../../../../redux/services/Map";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe";
import TaxiService from "../../../../../../../../../../services/TaxiService";
import { triangle } from "../../../../../../../../../../utils/ui";
import settlementToString from "../PointForm/components/Settlement/utils/objectToLabel";
import streetToString from "../PointForm/components/Street/utils/objectToLabel";
import FormRoot from "../FormRoot";
import PointController from "../PointController";
import ObjectGroupForm from "../ObjectGroupForm";
import MapComponent from "../Map";

import InternalController from "./Controller";
import Root from "./components/Root";

const ObjectGroupContent = react.withController<
	ObjectGroupContent.PropsBase,
	InternalController
>(({ value, language, controller, onChange, onDeletePoint }) => {
	const { t } = useTranslation();

	const mapRef = useRef<LeafletMap | null>(null);
	const mapPolygonEditorRef =
		useRef<UikitMap.PolygonEditor.Controller | null>(null);
	const objectGroupFormRef = useRef<ObjectGroupForm.Controller | null>(null);
	const shouldTaxiServiceIdUpdatingChangeMapFocusRef = useRef(false);

	const defaultValue = useMemo<ObjectGroupContent.Value>(() => ({}), []);

	const [internalValue] = useInternal(value ?? defaultValue);

	controller.setContext({ objectGroupFormRef });

	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;
	const taxiService = useMemo(
		() =>
			taxiServices?.find(
				(taxiService) => taxiService.id === internalValue.taxiServiceId,
			),
		[taxiServices, internalValue.taxiServiceId],
	);

	const prevTaxiService = usePrevValue(taxiService);

	useEffect(() => {
		if (!prevTaxiService || !taxiService || !mapRef.current) return;

		mapRef.current.setView(
			[
				taxiService.coordinates.lat,
				taxiService.coordinates.lng,
			] as LatLngTuple,
			mapRef.current.getZoom(),
		);
	}, [prevTaxiService, taxiService]);

	const mapCenter = useMemo(
		() =>
			internalValue.center ??
			(taxiService &&
				([
					taxiService?.coordinates.lat,
					taxiService?.coordinates.lng,
				] as LatLngTuple)) ??
			([50.455002, 30.511284] as LatLngTuple),
		[internalValue.center, taxiService],
	);

	const mapPolygonEditorValue = useMemo(
		() =>
			internalValue.vertices
				? [
						{
							id: "main",
							name: "",
							vertices: internalValue.vertices,
							creating: false,
						},
				  ]
				: [],
		[internalValue.vertices],
	);

	const prevMapPolygonEditorValue = usePrevValue(mapPolygonEditorValue, {
		checkEquals: false,
	});

	const mapPolygonEditorOnChange = useCallback(
		(newValue: UikitMap.PolygonEditor.Value) => {
			onChange({
				...internalValue,

				vertices: newValue[0]?.vertices,
			});
		},
		[internalValue, onChange],
	);

	const addPolygonButtonIcon = useMemo(
		() => <Icon id="plus" size={16} colors={[theme.colors.white]} />,
		[],
	);

	const addPointButtonIcon = useMemo(
		() => (
			<Icon
				id="plus"
				size={16}
				colors={[
					internalValue.vertices
						? theme.colors.white
						: theme.colors.disabled_text,
				]}
			/>
		),
		[internalValue.vertices],
	);

	const removeButtonIcon = useMemo(
		() => <Icon id="light-trash" size={16} colors={[theme.colors.white]} />,
		[],
	);

	const addPolygonButtonOnClick = useCallback(() => {
		if (!mapRef.current) return;

		const map = mapRef.current;

		const size = map.getSize();
		const pixelBounds = map.getPixelBounds();

		if (!pixelBounds.max || !pixelBounds.min) return;

		const vertices = triangle(
			pixelBounds.min.add(size.divideBy(2)),
			150,
			-Math.PI / 2,
		).map((point) => {
			const latLng = map.unproject(point.add([0, 37.4]));

			return [latLng.lat, latLng.lng] as LatLngTuple;
		});

		onChange({
			...internalValue,

			vertices,
		});
	}, [internalValue, onChange]);

	const removePolygonButtonOnClick = useCallback(() => {
		const center = mapRef.current?.getCenter();

		if (!center) return;

		const newValue = clone(internalValue);

		delete newValue.vertices;
		delete newValue.localObjects;

		onChange(newValue);
	}, [internalValue, onChange]);

	const addPointButtonOnClick = useCallback(() => {
		const center = mapRef.current?.getCenter();

		if (!center) return;

		const newLocalObject = {
			coordinates: mapRef.current?.getCenter(),
			autoResolve: true,
		};

		onChange({
			...internalValue,

			localObjects: internalValue.localObjects
				? [...internalValue.localObjects, newLocalObject]
				: [newLocalObject],
		});
	}, [internalValue, onChange]);

	useEffect(() => {
		if (
			mapPolygonEditorValue &&
			(!prevMapPolygonEditorValue ||
				prevMapPolygonEditorValue.length === 0)
		)
			setTimeout(() => mapPolygonEditorRef.current?.focus("main"), 30);
	}, [mapPolygonEditorValue, prevMapPolygonEditorValue]);

	const buttonsOnMap = useMemo(
		() => (
			<Row gaps="10px">
				{internalValue.vertices ? (
					<Button.Button
						icon={removeButtonIcon}
						text={
							t(
								"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupContent.str0",
							) ?? ""
						}
						onClick={removePolygonButtonOnClick}
					/>
				) : (
					<Button.Button
						icon={addPolygonButtonIcon}
						text={
							t(
								"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupContent.str1",
							) ?? ""
						}
						onClick={addPolygonButtonOnClick}
					/>
				)}

				<Button.Button
					icon={addPointButtonIcon}
					text={
						t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupContent.str2",
						) ?? ""
					}
					disabled={!internalValue.vertices}
					onClick={addPointButtonOnClick}
				/>
			</Row>
		),
		[
			addPointButtonIcon,
			addPointButtonOnClick,
			addPolygonButtonIcon,
			addPolygonButtonOnClick,
			internalValue.vertices,
			removeButtonIcon,
			removePolygonButtonOnClick,
			t,
		],
	);

	useEffect(() => {
		if (taxiService && shouldTaxiServiceIdUpdatingChangeMapFocusRef.current)
			mapRef.current?.setView(
				[
					taxiService?.coordinates.lat,
					taxiService?.coordinates.lng,
				] as LatLngTuple,
				12,
			);
	}, [taxiService]);

	return (
		<Root sizes="1fr*">
			<FormRoot>
				<ObjectGroupForm
					ref={objectGroupFormRef}
					value={internalValue}
					language={language}
					onChange={(value) => {
						onChange?.({ ...internalValue, ...value });

						if (internalValue.taxiServiceId !== value.taxiServiceId)
							shouldTaxiServiceIdUpdatingChangeMapFocusRef.current =
								true;
					}}
					onDeletePoint={onDeletePoint}
				/>
			</FormRoot>
			<MapComponent
				ref={mapRef}
				language={language}
				center={mapCenter}
				zoom={12}
				maxZoom={19}
			>
				<UikitMap.PolygonEditor
					ref={mapPolygonEditorRef}
					value={mapPolygonEditorValue}
					selected={
						mapPolygonEditorValue.length === 0 &&
						typeof internalValue.id !== "number"
							? undefined
							: "main"
					}
					editing={
						mapPolygonEditorValue.length !== 0 &&
						typeof internalValue.id !== "number"
					}
					onChange={mapPolygonEditorOnChange}
				/>

				{internalValue.localObjects?.map((localObject, index) => (
					<PointController
						key={index}
						value={localObject}
						language={language}
						name={(index + 1).toString()}
						autoResolve={localObject.autoResolve ?? false}
						onChange={(pointControllerValue) => {
							const newValue = clone(internalValue);

							newValue.localObjects = clone(
								newValue.localObjects!,
							);

							newValue.localObjects[index] = {
								...newValue.localObjects[index],
								...pointControllerValue,

								query: {
									settlement: settlementToString(
										pointControllerValue.address ?? {},
									),
									street: streetToString(
										pointControllerValue.address ?? {},
									),
									house:
										pointControllerValue.address?.house ??
										"",
								},
							};

							onChange(newValue);
						}}
						onClick={() =>
							objectGroupFormRef.current?.scrollToLocalObject(
								index,
							)
						}
					/>
				))}

				<Control prepend position="topleft">
					{buttonsOnMap}
				</Control>
			</MapComponent>
		</Root>
	);
}, InternalController);

declare namespace ObjectGroupContent {
	interface Value extends ObjectGroupForm.Value {
		id?: number;

		center?: LatLngLiteral;
	}

	interface PropsBase {
		value?: Value;

		language: Map.Language;

		onChange: Dispatch<Value>;

		onDeletePoint?: (index: number) => void;
	}

	interface Props extends PropsBase {
		controller: Controller;
	}

	type Controller = InternalController;
}

export default ObjectGroupContent;
