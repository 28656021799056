/* eslint-disable no-shadow */
import React, { memo, useCallback } from "react";
import { TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../redux/store";
import {
	modifyFilter,
	modifyInternalFilter,
} from "../../../../../../../../../../redux/reducers/Archives/Calls";

const Search: React.FC<Search.Props> = memo(() => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const delay = useTypedSelector((s) => s.settings.map.inputRequestDelayMs);
	const minLen = useTypedSelector((s) => s.settings.map.minSearchQueryLength);
	const query = useTypedSelector((s) => s.archiveCalls.internalFilter.query);

	const applyQuery = useDebouncedCallback((searchQuery) => {
		if (searchQuery.length >= minLen) {
			dispatch(modifyFilter({ searchQuery }));
		}
	}, delay);

	const handleQueryChange = useCallback(
		(query) => {
			applyQuery(query);
			dispatch(modifyInternalFilter({ query }));
		},
		[applyQuery, dispatch],
	);

	return (
		<TextBox.TextBox
			placeholder={`${t("search")}...`}
			value={query}
			onChange={handleQueryChange}
		/>
	);
});

declare namespace Search {
	interface Props {}
}

export default Search;
