/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Customers } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	isSortMap: false,
};

const setIsSortMap: ReduxReducer<State, State["isSortMap"]> = (
	state,
	{ payload },
) => {
	state.isSortMap = payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const filters = createSlice({
	name: Customers.ConstantCustomersSlice.COUNTERPARTY_FILTERS,
	initialState,
	reducers: {
		reset,
		setIsSortMap,
	},
});

export const filtersAction = filters.actions;
export const filtersReducer = filters.reducer;
export type filtersState = State;

export default filters;
