import React, { memo } from "react";
import { Icon } from "uikit";
import Root from "./components/Root";

const Settings = memo(() => (
	<Root>
		<Icon id="headerSettings" size={24} colors={["#bcc1c5"]} />
	</Root>
));

export default Settings;
