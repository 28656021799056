import React, { ReactNode, memo } from "react";
import styled from "styled-components";

import { AccessKey } from "../../access";
import { StyledRow } from "../common";

import { ControlButtons } from "./components";

const Root = styled(StyledRow)<DefaultPageHeader.StyledRow>`
	padding: ${(props) => props.padding || "0 20px"};
`;

const DefaultPageHeader = memo<DefaultPageHeader.Props>(
	({
		onAdd,
		onEdit,
		onDelete,
		canAdd,
		canEdit,
		canDelete,
		titleAdd,
		idAdd,
		titleEdit,
		idEdit,
		titleDelete,
		idDelete,

		beforeAdditionalButtons,
		buttons,
		afterAdditionalButtons,
		leftFilters = null,
		filters = null,
		accessName,
		ignorePoliticalAccessForAdditionalButtons,
		justify = "space-between",
		...props
	}) => (
		<Root gap="10px" justify={justify} w="100%" {...props}>
			<StyledRow gap="10px" alignItems="center">
				<ControlButtons
					onAdd={onAdd}
					onEdit={onEdit}
					onDelete={onDelete}
					canAdd={canAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					titleAdd={titleAdd}
					idAdd={idAdd}
					titleEdit={titleEdit}
					idEdit={idEdit}
					titleDelete={titleDelete}
					idDelete={idDelete}
					beforeAdditionalButtons={beforeAdditionalButtons}
					buttons={buttons}
					afterAdditionalButtons={afterAdditionalButtons}
					accessName={accessName}
					ignorePoliticalAccessForAdditionalButtons={
						ignorePoliticalAccessForAdditionalButtons
					}
				/>
				{leftFilters}
			</StyledRow>
			<StyledRow gap="20px" alignItems="center">
				{filters}
			</StyledRow>
		</Root>
	),
);

declare namespace DefaultPageHeader {
	interface Props extends ControlButtons.Props {
		leftFilters?: ReactNode;
		filters?: ReactNode;
		accessName?: AccessKey[];
		ignorePoliticalAccessForAdditionalButtons?: boolean;
		padding?: string;
		justify?: string;
	}

	interface StyledRow {
		padding?: string;
	}
}

export default DefaultPageHeader;
