import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "rsuite";
import tableData from "./mock";

const { Column, HeaderCell, Cell } = Table;

const ModalTable = () => {
	const [a, setA] = useState();

	const { t } = useTranslation();
	return (
		<Table
			isTree
			defaultExpandAllRows
			bordered
			cellBordered
			rowKey="value"
			height={400}
			data={tableData as any}
			shouldUpdateScroll={false}
			onExpandChange={(isOpen, rowData) => {
				console.log(isOpen, rowData);
			}}
		>
			<Column width={576}>
				<HeaderCell>
					{t(
						"pages.mainPage.pages.archives.tabs.driverShifts.tabs.archivalShifts.modalWorkHistory.content.mainTab.modalTable.str100",
					) ?? ""}
				</HeaderCell>
				<Cell dataKey="label" />
			</Column>
			<Column width={575}>
				<HeaderCell>
					{t(
						"pages.mainPage.pages.archives.tabs.driverShifts.tabs.archivalShifts.modalWorkHistory.content.mainTab.modalTable.str101",
					) ?? ""}
				</HeaderCell>
				<Cell dataKey="data" />
			</Column>
		</Table>
	);
};

export default ModalTable;
