import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import orderPage from "../../reducers/OrdersPage";

export default function getCompanies() {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const data = await createRPCQuery(() =>
			prpcow?.theirsModel.company.getAll(),
		);

		if (!data) return null;

		return dispatch(orderPage.actions.setCompanies(data));
	};
}
