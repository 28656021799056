import React, { memo, useEffect } from "react";
import { Row } from "uikit";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import { useCurrencyGlobalSettings } from "../../../../../../hooks";
import { ValueStepper } from "../../../../../../components/common";
import { RateAdjustmentToDefault } from "../../constants/constants";
import SurchargeSelect from "../SurchareSelect";

const Item: React.FC<Item.Props> = memo(
	({ title, disabled, value, hideSelect = false, onChange }) => {
		const currencyGlobalSettings = useCurrencyGlobalSettings();
		const valueEditor = useObjectEditor(value, onChange);

		const adjustmentType = valueEditor.useGetter("adjustmentType");
		const setAdjustmentType = valueEditor.useSetter("adjustmentType");

		const adjustmentValue = valueEditor.useGetter("value");
		const setAdjustmentValue = valueEditor.useSetter("value");

		useEffect(() => {
			if (
				adjustmentType === RateAdjustmentToDefault.CUSTOM_VALUE &&
				adjustmentValue < 0
			) {
				setAdjustmentValue(0);
			}
		}, [adjustmentType, adjustmentValue, setAdjustmentValue]);

		return (
			<Row gaps="10px*" align="center" sizes="250px auto auto auto">
				<span>{title}</span>
				{!hideSelect && (
					<SurchargeSelect
						disabled={disabled}
						value={adjustmentType}
						onChange={setAdjustmentType}
					/>
				)}
				<ValueStepper
					min={
						adjustmentType === RateAdjustmentToDefault.CUSTOM_VALUE
							? 0
							: undefined
					}
					value={adjustmentValue}
					onChange={setAdjustmentValue}
				/>
				{adjustmentType !==
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE &&
					currencyGlobalSettings}
			</Row>
		);
	},
);

declare namespace Item {
	interface Props {
		hideSelect?: boolean;
		title: string;
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		adjustmentType: RateAdjustmentToDefault;
		value: number;
	}
}

export default Item;
