import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "uikit";

import Root from "./components/Root";
import Title from "./components/Title";

const Header: React.FC<Header.Props> = () => {
	const { t } = useTranslation();
	return (
		<Root>
			<Row gaps="10px" align="center" justify="space-between">
				<Title>
					{t(
						"pages.preferencesPages.screenDirectory.parkings.content.list.header.str100",
					) ?? ""}
				</Title>
			</Row>
		</Root>
	);
};

declare namespace Header {
	interface Props {}
}

export default Header;
