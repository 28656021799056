import { MessageTemplateActions } from "../../../../../../../types/MessageTemplateEnums";

import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ALLOWED = "allowed",
	NAME = "name",
	ACTION = "action",
	DESTINATION_APP = "destinationApp",
	COMPANIES = "companies",
	BRANCHES = "branches",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.ALLOWED, label: "Allowed", width: 90 },
	{ id: Columns.NAME, label: "Name", width: 320 },
	{ id: Columns.ACTION, label: "Action", width: 250 },
	{ id: Columns.DESTINATION_APP, label: "Application", width: 160 },
	{ id: Columns.COMPANIES, label: "Companies", width: 220 },
	{ id: Columns.BRANCHES, label: "Branches" },
]; // * label translation is done inside the component through a for

export const defaultColumnsId = columns.map(({ id }) => id);

const NOTIFICATION_ACTIONS = [...Object.values(MessageTemplateActions)];
export const convertActionToString = NOTIFICATION_ACTIONS.reduce(
	(acc, action) => {
		acc[action] = MessageTemplateActions[action];
		return acc;
	},
	{},
);
