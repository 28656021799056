import { store } from "../../store";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import { getPRPC } from "../../../hooks/usePRPC";
import { defaultLanguage } from "../../../assets/languages/langs";

export default function getReveal(point, params?: any) {
	const prpcow = getPRPC();

	if (!prpcow) throw Error(`prpcow didn't initialize`);

	return createRPCQuery(() =>
		prpcow?.theirsModel.map.reveal(point, {
			...params,
			radius: store.getState().settings.map.revealRadiusMeters,
			lang: store.getState().session.language || defaultLanguage,
		}),
	);
}
