import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "uikit";
import styled from "styled-components";

import BaseModal from "../../../../../../../../../../components/InputModals/BaseModal";
import {
	DriverShiftsData,
	defaultColumnIds,
	defaultColumnNames,
} from "../Content/ModelTable/constants";

const ModalShift: React.FC<PropBase> = (props) => {
	const { t } = useTranslation();
	const StyledRoot = styled(Column)`
		background-color: white;
		width: 500px;
		height: auto;
		padding: 15px;
	`;
	const StyledButton = styled.div`
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;
		width: 100%;

		& > div {
			cursor: pointer;
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			width: 100%;
			height: 40px;
		}

		.blue {
			border-radius: 0 0 10px 10px;

			background: #03a9f4;
		}
	`;

	const StylesFooter = styled.div`
		display: flex;
	`;

	const { data, closeShiftModal } = props;

	return (
		<BaseModal
			title={
				t(
					"pages.mainPage.pages.archives.tabs.driverShifts.tabs.currentShifts.modalShift.str0",
				) ?? ""
			}
			style={{ width: 500 }}
			onClose={closeShiftModal}
		>
			<div
				style={{
					borderRadius: "10px",
				}}
			>
				<StyledRoot gaps="5px*">
					{defaultColumnIds.map((name, i) => (
						<Row key={name} align="flex-start" sizes="60%! 40%!">
							<span
								style={{ color: "#21333F", fontWeight: "bold" }}
							>
								{defaultColumnNames[i]}
							</span>
							<span>{data[defaultColumnIds[i]]}</span>
						</Row>
					))}
				</StyledRoot>
			</div>
			<StylesFooter>
				<StyledButton onClick={closeShiftModal}>
					<div className="blue">
						{t(
							"pages.mainPage.pages.archives.tabs.driverShifts.tabs.currentShifts.modalShift.str100",
						) ?? ""}
					</div>
				</StyledButton>
			</StylesFooter>
		</BaseModal>
	);
};

interface PropBase {
	data: DriverShiftsData;

	closeShiftModal: () => any;
}

export default ModalShift;
