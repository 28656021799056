import BankTransaction from "../../../../../../../../services/BankTransaction";
import Executor from "../../../../../../../../services/Executor";

export const getMessageType = (type: BankTransaction.BankTransactionType) => {
	if (type === "order_payment") return "За Заказ";
	if (type === "replenishment_executor_card")
		return "Пополнение баланса исполнителя";
	return "";
};

export const getMessageStatus = (
	status: BankTransaction.PaymentTransactionStatus,
) => {
	if (status === "completed") return "Успешный";
	if (status === "cancelled") return "Ошибка";
	if (status === "pending") return "Выполняется";
	return "";
};

export const getSender = (sender) => {
	if (sender === "system") return "Система";
	return sender;
};

export const getExecutorFullName = (executor: Executor.Model | null) => {
	if (executor) {
		const firstName = executor?.person?.firstName || "";
		const lastName = executor?.person?.lastName || "";
		const fatherName = executor?.person?.fatherName || "";

		return `${lastName} ${firstName} ${fatherName}`;
	}
	return "";
};
