/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import Base from "../Base";
import CarBodyType from "../CarBodyType";
import CarModel from "../CarModel";

class CarModelToCarBodyType extends Base {
	static fromResponse(data: any): CarModelToCarBodyType.Model {
		return {
			id: data.id,

			carModel: data.model
				? CarModel.fromResponse(data.model)
				: undefined,
			carBodyType: data.bodyType
				? CarBodyType.fromResponse(data.bodyType)
				: undefined,

			active: data.active,
			native: data.native,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: CarModelToCarBodyType.Model.Modified): any {
		return {
			active: model.active,
		};
	}

	public static async update(
		carModelId: number,
		carBodyTypeId: number,
		object: CarModelToCarBodyType.Model.Modified,
	) {
		await this.request((prpc) =>
			prpc.theirsModel.carBodyType.updateModelRelation(
				carModelId,
				carBodyTypeId,
				CarModelToCarBodyType.toRequest(object),
			),
		);
	}

	public static async destroy(carModelId: number, carBodyTypeId: number) {
		await this.request((prpc) =>
			prpc.theirsModel.carBodyType.deleteModelRelation(
				carModelId,
				carBodyTypeId,
			),
		);
	}
}

declare namespace CarModelToCarBodyType {
	interface Model {
		id: number;

		carModel?: CarModel.Model;
		carBodyType?: CarBodyType.Model;

		active: boolean;
		native: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	namespace Model {
		interface Modified {
			active?: boolean;
		}
	}
}

export default CarModelToCarBodyType;
