import { combineReducers } from "@reduxjs/toolkit";

import {
	reportCashlessOrdersPeriodReportDataState,
	reportCashlessOrdersPeriodReportDataReducer,
	reportCashlessOrdersPeriodReportDataAction,
} from "./data";

export type StateCashlessOrdersPeriodReport = {
	data: reportCashlessOrdersPeriodReportDataState;
};

export const cashlessOrdersPeriodReport =
	combineReducers<StateCashlessOrdersPeriodReport>({
		data: reportCashlessOrdersPeriodReportDataReducer,
	});

export const reportsCashlessOrdersPeriodReportAction = {
	data: reportCashlessOrdersPeriodReportDataAction,
};

export default cashlessOrdersPeriodReport;
