import React, { memo, useCallback, useMemo } from "react";
import { Icon } from "uikit";

import { OrderPaymentStatus } from "../../../../../../../../redux/constants/OrdersPage/order";
import { OrderFormProps } from "../../types/FormProps";
import { GridSelectMemo } from "../GridSelect";

import {
	paymentMethods,
	paymentMethodsIds,
	paymentColors,
} from "./paymentMethods";

const GridItemPayment: React.FC<GridItemPayment.Props> = ({
	form,
	disabled,
	area,
	tab,
}) => {
	const paymentStatus = useMemo(
		() => tab.form.paymentStatus?.status || null,
		[tab.form.paymentStatus],
	);

	const paymentType = useMemo(
		() => tab.form.paymentType || null,
		[tab.form.paymentType],
	);

	const styles = useMemo(
		() => ({
			elem: {
				justifySelf: "start",
				alignSelf: "center",
				bgC: "#F6F6F6",
			},
			wrap: {
				justify: "center",
				alignItems: "center",
				h: "28px",
				p: "0 0 0 10px",
				bgC:
					(paymentType !== "company_bill" &&
						paymentColors.find((item) => item.id === paymentStatus)
							?.color) ||
					"#F6F6F6",
			},
			icon: { w: "16px", h: "16px", colors: "#5e6b73" },
		}),
		[paymentStatus, paymentType],
	);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const onSelect = useCallback(
		(option) => {
			if (paymentMethodsIds.includes(option.value)) {
				form.setValue("paymentType", option.value);

				form.setValue("paymentMethod", {
					name: option.value,
					id: paymentMethodsIds.indexOf(option.value),
				});
				handleSave();
			}
		},
		[form, handleSave],
	);

	const dataOptions = useMemo(() => {
		const type = tab.form.source;
		const paymentType = tab.form?.paymentType;
		const paymentStatus = tab.form?.paymentStatus?.status || null;
		const checkCardId = tab.form?.counterparty?.checkCardId;
		const paymentAccountId = tab.form?.counterparty?.paymentAccountId;

		return {
			type,
			checkCardId,
			paymentAccountId,
			paymentType,
			paymentStatus,
		};
	}, [
		tab.form.source,
		tab.form?.paymentType,
		tab.form?.paymentStatus?.status,
		tab.form?.counterparty?.checkCardId,
		tab.form?.counterparty?.paymentAccountId,
	]);

	const options = useMemo(() => {
		const {
			type,
			checkCardId,
			paymentAccountId,
			paymentType,
			paymentStatus,
		} = dataOptions;

		const data = paymentMethods.filter((item) => {
			if (
				item.name === "company_bill" &&
				!paymentAccountId &&
				!checkCardId
			) {
				return false;
			}

			if (paymentType === "company_bill") {
				if (item.name === "company_bill") return true;
				return false;
			}

			const failed =
				paymentStatus === OrderPaymentStatus.PAYMENT_FAILED ||
				paymentStatus === OrderPaymentStatus.CARD_VALIDATION_FAILED;
			if (type && type !== "executor" && failed) {
				const phoneCard = type === "phone" && paymentType === "card";
				const appCard = type === "app" && paymentType === "card";
				const itemCash = item.name === "cash";
				const itemInvoice = item.name === "invoice";
				const arr = [
					phoneCard && itemCash,
					phoneCard && itemInvoice,
					appCard && itemCash,
					appCard && itemInvoice,
				];

				if (arr.some((item) => item)) return true;
			}

			if (type && item.name === "card" && type !== "executor") {
				const phoneCard = type === "phone" && paymentType === "card";
				const appCard = type === "app" && paymentType === "card";
				if (phoneCard || appCard) return true;
				return false;
			}

			if (paymentType === "invoice" || paymentType === "cash") {
				if (item.name === "invoice") return true;
				if (item.name === "cash") return true;
			}

			return false;
		});

		return data;
	}, [dataOptions]);

	return (
		<GridSelectMemo
			area={area || "payment"}
			disabled={disabled}
			name="paymentType"
			control={form.control}
			options={options}
			onSelect={onSelect}
			icon={<Icon id="order-modal-payment" size={16} />}
			styles={styles}
		/>
	);
};

declare namespace GridItemPayment {
	interface Props extends OrderFormProps {
		area?: string;
	}
}
export const GridItemPaymentMemo = memo(GridItemPayment);
export default GridItemPayment;
