/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";

export enum BlacklistPhoneLevel {
	PHONE = "phone",
	BLOCKED = "blocked",
	WARNING = "warning",
}

export const blacklistPhoneLevelOptions = getOptions(
	Object.values(BlacklistPhoneLevel),
);
