import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";

import ComissionItem from "./ComissionItem";

const ComissionsList: React.FC<ComissionsList.Props> = ({ precision }) => {
	const { t } = useTranslation();

	const baseComissions = useMemo(
		() => ({
			cashOrders: {
				key: "cashOrders",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str205",
					) ?? "",

				checkbox: false,
			},
			nonCashOrders: {
				key: "nonCashOrders",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str206",
					) ?? "",
				checkbox: false,
			},
			ruralOrders: {
				key: "ruralOrders",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str207",
					) ?? "",
				checkbox: false,
			},
			onlineOrders: {
				key: "onlineOrders",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str208",
					) ?? "",
				checkbox: false,
			},
			bonusOrders: {
				key: "bonusOrders",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str209",
					) ?? "",
				checkbox: false,
			},
			cardPaymentToService: {
				key: "cardPaymentToService",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str210",
					) ?? "",
				checkbox: false,
			},
			cardPaymentToExecutor: {
				key: "cardPaymentToExecutor",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str211",
					) ?? "",
				checkbox: false,
			},
			terminalToService: {
				key: "terminalToService",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str212",
					) ?? "",
				checkbox: false,
			},
			terminalToExecutor: {
				key: "terminalToExecutor",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str213",
					) ?? "",
				checkbox: false,
			},
			localDiscount: {
				key: "localDiscount",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str214",
					) ?? "",
				checkbox: false,
			},
			globalDiscount: {
				key: "globalDiscount",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str215",
					) ?? "",
				checkbox: false,
			},
			externalDiscount: {
				key: "externalDiscount",
				name:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.comissionsList.str216",
					) ?? "",
				checkbox: false,
			},
		}),
		[t],
	);

	const [allComissions, setAllComissions] = useState(baseComissions);

	const itemsList = useMemo(
		() => Object?.values(allComissions) || [],
		[allComissions],
	);

	return (
		<Column gaps="10px*">
			{itemsList.map((item) => (
				<ComissionItem
					key={item.key}
					data={item}
					onChangeState={setAllComissions}
					precision={precision}
				/>
			))}
		</Column>
	);
};

declare namespace ComissionsList {
	interface Props {
		precision: number | undefined;
	}
}

export default ComissionsList;
