import styled from "styled-components";

const ButtonGroup = styled.div`
	display: flex;

	flex-direction: column;
	flex-wrap: nowrap;

	gap: 1px;

	& > * {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

		border-radius: 0;

		&:first-child {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		&:last-child {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
`;

export default ButtonGroup;
