import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "uikit";

const StyledMarker = styled.div<Marker.StyledMarkerProps>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: ${({ radius }) => radius}px;
	height: 1rem;

	margin-left: ${({ marginLeft }) =>
		typeof marginLeft === "number" ? `${marginLeft}px` : marginLeft};
	margin-right: ${({ marginRight }) =>
		typeof marginRight === "number" ? `${marginRight}px` : marginRight};

	& > span {
		width: ${({ radius }) => radius}px;
		height: ${({ radius }) => radius}px;

		background-color: ${({ color }) => color};
		border-radius: 50%;
	}
`;

const Marker: React.FC<Marker.Props> = ({
	color = theme.colors.primary,
	radius = 4,
	marginLeft = 0,
	marginRight = 5,
}) => (
	<StyledMarker
		color={color}
		radius={radius}
		marginLeft={marginLeft}
		marginRight={marginRight}
	>
		<span />
	</StyledMarker>
);

declare namespace Marker {
	interface Props extends Partial<StyledMarkerProps> {}
	interface StyledMarkerProps {
		color: string;
		radius: number;
		marginLeft: number | string;
		marginRight: number | string;
	}
}

export const MarkerMemo = memo(Marker);
export default Marker;
