import React from "react";
import { Column } from "uikit";
import styled from "styled-components";
import Main from "./tabs/Main";
import { ExecutorTariffTabs } from "../../tabs";
import MembershipFee from "./tabs/MembershipFee";
import ComissionsTab from "./tabs/Comissions";
import PreferentialComissionsTab from "./tabs/PreferentialComissions";
import Executors from "./tabs/Executors";

const TabsWrapper = styled(Column)`
	overflow: hidden;
`;

const Content = ({ tab }: Content.Props) => {
	const getActualTab = (currentTab: ExecutorTariffTabs) => {
		switch (currentTab) {
			case ExecutorTariffTabs.MAIN:
				return (
					<Main
						// taxiServiceIds={taxiServiceIds}
						// setTaxiServiceIds={setTaxiServiceIds}
						visible={true}
						// value={general}
						// onChange={setGeneral}
					/>
				);

			case ExecutorTariffTabs.MEMBERSHIP_FEE:
				return (
					<MembershipFee
						value={{ rows: [] }}
						onChange={() => ""}
						visible={true}
					/>
				);

			case ExecutorTariffTabs.COMMISSIONS:
				return (
					<ComissionsTab
						value={{ rows: [] }}
						onChange={() => ""}
						visible={true}
					/>
				);

			case ExecutorTariffTabs.PREFERENTIAL_COMMISSIONS:
				return (
					<PreferentialComissionsTab
						value={{ rows: [] }}
						onChange={() => ""}
						visible={true}
					/>
				);

			case ExecutorTariffTabs.EXECUTORS:
				return <Executors />;

			default:
				return <></>;
		}
	};

	return <TabsWrapper>{getActualTab(tab)}</TabsWrapper>;
};

declare namespace Content {
	interface Props {
		tab: ExecutorTariffTabs;
		// name: string;
		// value: Value;
		// onChange: (value: Value) => void;
	}

	interface Value {
		settings: {
			general: Main.Value;
		};
		// taxiServiceIds: Main.Props["taxiServiceIds"];
	}
}

export default Content;
