import React, { ComponentType, PropsWithChildren } from "react";

export default function Layout<Props extends PropsWithChildren>(
	Component: ComponentType,
	LayoutComponent: ComponentType<Props>,
) {
	return function ComponentWithMiddleware(props: Record<string, unknown>) {
		const CorrectedLayoutComponent =
			LayoutComponent as ComponentType<PropsWithChildren>;

		return (
			<CorrectedLayoutComponent>
				<Component {...props} />
			</CorrectedLayoutComponent>
		);
	};
}
