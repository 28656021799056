import styled from "styled-components";

const Root = styled.div`
	grid-area: points;

	min-height: 134px;

	overflow: hidden;
`;

export default Root;
