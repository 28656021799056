/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import Language from "../../../../../../../../../services/Language";
import InternalController from "./Controller";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({
		controller,
		value,
		type,
		language,
		currentIP,
		onChange,
		validation,
	}) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker(
			[
				"taxiServiceIds",
				"name",
				"host",
				"port",
				"login",
				"password",
				"alphaName",

				"isSystemType",
				"systemType",
				"sourceAddrTon",
				"sourceAddrNpi",
				"destAddrTon",
				"destAddrNpi",

				"checkSmsStatusMode",
				"activeQueryInterval",
				"isEnquireLink",
				"enquireLink",
				"addPlus",
				"smsProviderToTaxiServices",
				"isMaxSmsPerSecond",
				"maxSmsPerSecond",
			],
			"json",
		);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						value={mainTabValue}
						visible={type === "main"}
						language={language}
						onChange={anyTabOnChange}
						validation={validation}
					/>
					{/* <PassportTab
						value={passportTabValue}
						visible={type === "passport"}
						onChange={anyTabOnChange}
					/>
					<FilesTab
						value={filesTabValue}
						visible={type === "files"}
						onChange={anyTabOnChange}
					/>
					<HistoryTab
						value={history}
						visible={type === "history"}
						language={language}
					/>
					<AccessTab
						value={accessTabValue}
						visible={type === "access"}
						currentIP={currentIP}
						onChange={anyTabOnChange}
					/> */}
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				type,
				language,
				anyTabOnChange,
				validation,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type = "main";

	type Value = MainTab.Value;
	interface PropsBase {
		value: Value;

		type: Type;
		language: Language;
		currentIP: string;
		validation: any;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
