import React, { Dispatch, memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton, Row } from "uikit";

import { AutoRateRulePolygonType as PolygonType } from "../../../../../../../../../../../../../../../../../../../../services/AutoTariff/enums";
import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import { MultiSelectPriceZoneWithModal } from "../../../../../../../../../../../../../../../../../../../../components/common";

const SelectTariffZones: React.FC<SelectTariffZones.Props> = memo((props) => {
	const {
		outsidePriceZones,
		setOutsidePriceZones,
		polygonType,
		setPolygonType,
		value,
		onChange,
		disabled,
	} = props;

	const { t } = useTranslation();

	const selectOnChange = useCallback(
		(newValue: any[]) => {
			if (newValue.includes(0)) {
				setOutsidePriceZones(true);
			} else {
				setOutsidePriceZones(false);
			}
			onChange(newValue);
		},
		[onChange, setOutsidePriceZones],
	);

	const setPolygonTypeToTariffZone = useCallback(() => {
		setPolygonType(PolygonType.PRICE_ZONE);
	}, [setPolygonType]);

	useEffect(() => {
		if (outsidePriceZones && !value.includes(0)) {
			const newZones = value;
			newZones.push(0);
			onChange(newZones);
		}
	}, [onChange, outsidePriceZones, value]);

	return (
		<LabeledField
			label={
				t(
					"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.selectTariffZones.str0",
				) ?? ""
			}
		>
			<Row gaps="8px*" align="center" sizes="auto 1fr">
				<RadioButton.RadioButton
					selected={polygonType === PolygonType.PRICE_ZONE}
					onClick={setPolygonTypeToTariffZone}
				/>

				<MultiSelectPriceZoneWithModal
					value={value}
					onChange={selectOnChange}
					disabled={polygonType === PolygonType.SECTOR || disabled}
					titleText={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.selectTariffZones.str200",
						) ?? ""
					}
				/>
			</Row>
		</LabeledField>
	);
});

declare namespace SelectTariffZones {
	interface Props {
		polygonType: PolygonType;
		setPolygonType: Dispatch<PolygonType>;
		outsidePriceZones: boolean;
		setOutsidePriceZones: Dispatch<boolean>;
		value: Value;
		onChange: Dispatch<Value>;
		disabled?: boolean;
	}

	type Value = number[];
}

export default SelectTariffZones;
