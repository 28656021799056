/**
 * Collection of route constants for the project.
 * This file contains the paths used in the application routing.
 */

export type RoutePaths = (typeof ROUTES)[keyof typeof ROUTES];

const ROUTES = {
	MAIN: "/",
	LOGIN: "/login",
	ORDERS: "/orders",
	ACCOUNTING: "/accounting",
	ACCOUNTS: "/accounts",
	ARCHIVES: "/archives",
	REPORTS: "/reports",
	CUSTOMERS: "/customers",
	BLACKLIST: "/blacklist",
	PREFERENCES_PAGENAME: "/preferences/:pageName?",
	SETTINGS: "/settings",

	// ? settings part
	SETTINGS_ORDERS: "/settings/orders",
	SETTINGS_TARIFFS: "/settings/tariffs",
	SETTINGS_FINANCES: "/settings/finances",
	SETTINGS_MESSAGES: "/settings/messages",
	SETTINGS_MOBILE_APPS: "/settings/mobile-apps",
	SETTINGS_PARAMETERS: "/settings/parameters",
	SETTINGS_MAP: "/settings/map",
	SETTINGS_ORDER_EXCHANGE: "/settings/order-exchange",
	SETTINGS_PAYMENT_SYSTEMS: "/settings/payment-systems",
	SETTINGS_INTERFACE: "/settings/interface",
	SETTINGS_VOIP: "/settings/voip",

	// ? reports part
	REPORTS_CHARTS: "/reports/charts",
	REPORTS_ACCOUNTING: "/reports/accounting",
	REPORTS_SALARY: "/reports/salary",
} as const;

const OPEN_ROUTES: RoutePaths[] = [ROUTES.LOGIN];
const ADMIN_ROUTES: RoutePaths[] = [];

export { ROUTES, OPEN_ROUTES, ADMIN_ROUTES };
