import React, { memo, useMemo } from "react";
import { Row } from "uikit";
import { useTranslation } from "react-i18next";

import Call from "../../../../../../../../../../services/Call";

const Status: React.FC<Status.Props> = memo(({ status }) => {
	const path = `mainPage.archives.callsHistory.status`;
	const { t } = useTranslation();

	const statusText = useMemo(() => {
		switch (status) {
			case 1:
				return t(`${path}.success`);

			case 4:
				return t(`${path}.cancel`);

			case 2:
			case 3:
			case 5:
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
			case 11:
			case 12:
				return t(`${path}.fail`);

			default:
				return null;
		}
	}, [status, t, path]);

	return <Row>{statusText}</Row>;
});

declare namespace Status {
	interface Props {
		status: Call.Model["dialStatus"];
	}
}

export default Status;
