import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, react, useInternal, useRefWithSetter } from "uikit";

import Map from "../../../../../../../../../../redux/services/Map";
import Address from "../../../../../../../../../../types/Address";
import {
	addToObjectByAdditionKeyAndObject,
	createObjectLanguage,
	Language,
	pickFromObjectByAdditionKey,
} from "../../../../../../../../../../assets/languages/langs";
import EditModal from "../..";
import ObjectContent from "../ObjectContent";
import ObjectFooter from "../ObjectFooter";

import InternalController from "./Controller";

const ObjectTabContent = react.withController<
	ObjectTabContent.PropsBase,
	ObjectTabContent.Controller
>(
	({
		controller,

		value,
		language,

		onChange,

		onCancel,
		onSave,
	}) => {
		const [objectContentRef, objectContentRefSetter] =
			useRefWithSetter<ObjectContent.Controller | null>(null);

		const [autoResolve, setAutoResolve] = useState(true);

		const defaultValue = useMemo(() => ({}), []);

		const [internalValue] = useInternal(value ?? defaultValue);

		const contentOnChange = useCallback(
			(contentValue: ObjectContent.Value) => {
				onChange({
					...internalValue,

					taxiServiceId: contentValue.taxiServiceId,
					fields: addToObjectByAdditionKeyAndObject<
						"title",
						any,
						any,
						any
					>(
						"title",
						contentValue.name,
						contentValue.address,
						internalValue.fields,
					),
					// fields: {
					// 	uk: {
					// 		...internalValue.fields?.uk,
					// 		...contentValue.address,
					// 		title: contentValue.name?.uk,
					// 	},
					// 	en: {
					// 		...internalValue.fields?.en,
					// 		...contentValue.address,
					// 		title: contentValue.name?.en,
					// 	},
					// 	az: {
					// 		...internalValue.fields?.az,
					// 		...contentValue.address,
					// 		title: contentValue.name?.az,
					// 	},
					// 	tr: {
					// 		...internalValue.fields?.tr,
					// 		...contentValue.address,
					// 		title: contentValue.name?.tr,
					// 	},
					// 	ru: {
					// 		...internalValue.fields?.ru,
					// 		...contentValue.address,
					// 		title: contentValue.name?.ru,
					// 	},
					// },

					point: contentValue.coordinates,
				});

				setAutoResolve(contentValue.autoResolve ?? autoResolve);
			},
			[onChange, internalValue, autoResolve],
		);

		const footerOnChange = useCallback(
			(footerValue: ObjectFooter.Value) =>
				onChange({
					...internalValue,

					status: footerValue.active,
					visibility: footerValue.visible,
				}),
			[onChange, internalValue],
		);

		controller.setContext({ objectContentRef });

		const address = useMemo<Partial<Address> | undefined>(() => {
			if (!value.fields?.en) return undefined;

			// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-shadow
			const { title, ...address } = value.fields.en;

			return address;
		}, [value.fields?.en]);

		const valueFields = useMemo(
			() =>
				(value?.fields ??
					createObjectLanguage({
						title: "",
					})) as Record<Language, { title: string }>,
			[value?.fields],
		);

		const objectContentValue = useMemo(
			() => ({
				autoResolve,
				taxiServiceId: value.taxiServiceId,
				name: pickFromObjectByAdditionKey("title", valueFields),
				// name: {
				// 	uk: value.fields?.uk.title,
				// 	en: value.fields?.en.title,
				// 	az: value.fields?.az.title,
				// 	tr: value.fields?.tr.title,
				// 	ru: value.fields?.ru.title,
				// },
				coordinates: value.point,
				address,
			}),
			[
				address,
				autoResolve,
				value.point,
				value.taxiServiceId,
				valueFields,
			],
		);

		const objectFooterValue = useMemo(
			() => ({
				active: value.status,
				visible: value.visibility,
			}),
			[value.status, value.visibility],
		);

		return useMemo(
			() => (
				<Column sizes="1fr auto" maxedWidth maxedHeight>
					<ObjectContent
						ref={objectContentRefSetter}
						language={language}
						value={objectContentValue}
						onChange={contentOnChange}
					/>
					<ObjectFooter
						value={objectFooterValue}
						onChange={footerOnChange}
						onCancel={onCancel}
						onSave={onSave}
					/>
				</Column>
			),
			[
				contentOnChange,
				footerOnChange,
				language,
				objectContentRefSetter,
				objectContentValue,
				objectFooterValue,
				onCancel,
				onSave,
			],
		);
	},
	InternalController,
);

declare namespace ObjectTabContent {
	type Value = EditModal.LocalObjectValue;

	interface PropsBase {
		value: Value;

		language: Map.Language;

		onChange: Dispatch<Value>;

		onCancel: () => void;
		onSave: () => void;
	}

	interface InternalProps extends PropsBase {
		controller: Controller;
	}

	type Props = PropsBase & RefAttributes<Controller | null>;

	type Controller = InternalController;
}

export default ObjectTabContent;
