import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	width: 150px;

	max-height: 170px;

	color: ${theme.colors.primary};

	background-color: transparent;
`;

export default Root;
