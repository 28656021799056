/* eslint-disable import/no-unresolved */

import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, react, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Sector from "../../../../../../../../../../../../services/Sector";
import Language from "../../../../../../../../../../../../services/Language";
import useModelSubscribe from "../../../../../../../../../../../../hooks/useModelSubscribe";
import TaxiServiceForm from "../../../../../../../../../../../../components/TaxiServiceForm";
import MultiSelectWithModal from "../../../../../../../../../../../../components/MultiSelectWithModal";
import {
	Names,
	NamesBase,
} from "../../../../../../../../../../../../components/common";

import InternalController from "./Controller";
import NameForm from "./components/NameForm";
import FieldContainer from "./components/FieldContainer";

const Form = react.withController<Form.InputifiedPropsBase, InternalController>(
	react.inputify<Form.PropsBaseWithController, Form.Value>(
		({
			value,

			language,

			controller,

			onChange,
		}) => {
			const { t } = useTranslation();
			const taxiServiceFormRef =
				useRef<TaxiServiceForm.Controller | null>(null);
			// const nameFormRef = useRef<NameForm.Controller | null>(null);
			const nameFormRef = useRef<NamesBase.Controller | null>(null);

			const defaultValue = useMemo<Form.Value>(
				() => ({ bySector: false }),
				[],
			);

			const [internalValue] = useInternal(value ?? defaultValue);

			const [sectorMultiSelectError, setSectorMultiSelectError] =
				useState(false);

			const sectorData = useModelSubscribe({}, Sector);

			const sectors = useMemo(
				() => sectorData?.cache ?? [],
				[sectorData?.cache],
			);

			const sectorById = useMemo(
				() =>
					sectors.reduce((accumulator, sector) => {
						accumulator[sector.id] = sector;

						return accumulator;
					}, {} as Record<number, Sector.Model>),
				[sectors],
			);

			const sectorOptions = useMemo<
				MultiSelectWithModal.Option<number | "outside">[]
			>(
				() => [
					{
						key: "outside",
						label:
							t(
								"pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str200",
							) ?? "",
						value: "outside",
					},
					...sectors
						.filter(
							(sector) =>
								internalValue.bySector ||
								(typeof internalValue.taxiServiceId !==
									"undefined" &&
									sector.taxiServiceId ===
										internalValue.taxiServiceId),
						)
						.map((sector) => ({
							key: sector.id,
							label: sector.name[language],
							disabled:
								internalValue.bySector && sector.isParking,
							value: sector.id,
						})),
				],
				[
					internalValue.bySector,
					internalValue.taxiServiceId,
					language,
					sectors,
					t,
				],
			);

			controller.setContext({
				value,

				setSectorMultiSelectError,

				taxiServiceFormRef,
				nameFormRef,
			});

			const taxiServiceFormOnChange = useCallback(
				(taxiServiceId: TaxiServiceForm.Value) => {
					const sectorIds = internalValue.bySector
						? internalValue.sectorIds
						: internalValue.sectorIds?.filter(
								(sectorId) =>
									!sectorById[sectorId] ||
									sectorById[sectorId].taxiServiceId ===
										taxiServiceId,
						  );

					onChange({ ...internalValue, sectorIds, taxiServiceId });
				},
				[internalValue, onChange, sectorById],
			);

			const sectorMultiSelectOnChange = useCallback(
				(sectorIds: MultiSelectWithModal.Value<number | "outside">) => {
					setSectorMultiSelectError(false);

					onChange({
						...internalValue,
						sectorIds: sectorIds as (number | "outside")[],
					});
				},
				[internalValue, onChange],
			);

			const nameFormOnChange = useCallback(
				(name: NameForm.Value) => {
					onChange({
						...internalValue,

						name,
					});
				},
				[internalValue, onChange],
			);

			const name = useMemo(
				() => internalValue?.name || {},
				[internalValue?.name],
			);

			return (
				<>
					<Column gaps="24px*">
						<FieldContainer
							title={
								t(
									"pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str0",
								) ?? ""
							}
						>
							<TaxiServiceForm
								ref={taxiServiceFormRef}
								value={internalValue.taxiServiceId}
								language={language}
								onChange={taxiServiceFormOnChange}
							/>
						</FieldContainer>
						<FieldContainer
							title={
								t(
									"pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str1",
								) ?? ""
							}
						>
							<MultiSelectWithModal
								value={internalValue.sectorIds}
								title={
									t(
										"pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str1",
									) ?? ""
								}
								error={sectorMultiSelectError}
								options={sectorOptions}
								onChange={sectorMultiSelectOnChange}
							/>
						</FieldContainer>
						<FieldContainer
							title={
								t(
									"pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str3",
								) ?? ""
							}
						>
							<Names
								ref={nameFormRef}
								require
								value={name}
								onChange={nameFormOnChange}
								placeholder="pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str3"
							/>
							{/* <NameForm
								ref={nameFormRef}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.parkings.editModal.content.form.str3",
									) ?? ""
								}
								value={internalValue.name}
								onChange={nameFormOnChange}
							/> */}
						</FieldContainer>
					</Column>
				</>
			);
		},
	),
	InternalController,
);

declare namespace Form {
	interface Value {
		taxiServiceId?: number;
		sectorIds?: (number | "outside")[];

		name?: NameForm.Value;

		bySector: boolean;
	}

	interface PropsBase {
		language: Language;
	}

	interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default Form;
