import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { ReduxReducer } from "../../../../interface";
import { Accounting } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	dateRange: {
		from: moment().startOf("day").toDate(),
		to: moment().endOf("day").toDate(),
	},
	query: "",
	callSigns: ["all"],
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	executorIds: [],
	fleetIds: [],
	executorGroupIds: [],
	isSortMap: false,
};

const setIsSortMap: ReduxReducer<State, State["isSortMap"]> = (
	state,
	{ payload },
) => {
	state.isSortMap = payload;
};

const setQuery: ReduxReducer<State, State["query"]> = (state, { payload }) => {
	state.query = payload;
};

const setDateRange: ReduxReducer<State, State["dateRange"]> = (
	state,
	{ payload },
) => {
	state.dateRange = payload;
};

const setCallSigns: ReduxReducer<State, State["callSigns"]> = (
	state,
	{ payload },
) => {
	state.callSigns = payload;
};
const setCompanyIds: ReduxReducer<State, State["companyIds"]> = (
	state,
	{ payload },
) => {
	state.companyIds = payload;
};

const setTaxiServiceIds: ReduxReducer<State, State["taxiServiceIds"]> = (
	state,
	{ payload },
) => {
	state.taxiServiceIds = payload;
};

const setExecutorIds: ReduxReducer<State, State["executorIds"]> = (
	state,
	{ payload },
) => {
	state.executorIds = payload;
};

const setExecutorGroupIds: ReduxReducer<State, State["executorGroupIds"]> = (
	state,
	{ payload },
) => {
	state.executorGroupIds = payload;
};

const setFleetIds: ReduxReducer<State, State["fleetIds"]> = (
	state,
	{ payload },
) => {
	state.fleetIds = payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const filters = createSlice({
	name: Accounting.ConstantAccountingSlice.EXECUTORS_FILTERS,
	initialState,
	reducers: {
		reset,
		setDateRange,
		setQuery,
		setCallSigns,
		setCompanyIds,
		setExecutorIds,
		setTaxiServiceIds,
		setExecutorGroupIds,
		setFleetIds,
		setIsSortMap,
	},
});

export const filtersAction = filters.actions;
export const filtersReducer = filters.reducer;
export type filtersState = State;

export default filters;
