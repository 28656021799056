/* eslint-disable import/no-unresolved */

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button, Column, react, Row, useRefWithSetter } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Map from "../../../../../../../../redux/services/Map";
import Modal from "../../../../../../../../components/ModalController/Modal";
import Root from "./components/Root";
import Form from "./components/Form";

const ImportModal: React.FC<ImportModal.Props> = react.inputify<
	ImportModal.PropsBase,
	ImportModal.Value
>(({ value, language, onChange, onCancel, onConfirm }) => {
	const [formRef, formRefSetter] = useRefWithSetter<Form.Controller | null>(
		null,
	);

	const { t } = useTranslation();

	const content = useMemo(
		() => (
			<Root>
				<Column gaps="20px*" align="center">
					<Form
						ref={formRefSetter}
						language={language}
						value={value}
						onChange={onChange}
					/>
					<Row gaps="10px">
						<Button.Button
							variant="secondary"
							text={
								t(
									"pages.preferencesPages.screenDirectory.objects.importModal.str0",
								) ?? ""
							}
							onClick={onCancel}
						/>
						<Button.Button
							text={
								t(
									"pages.preferencesPages.screenDirectory.objects.importModal.str1",
								) ?? ""
							}
							onClick={() => {
								if (!formRef.current?.validate()) return;

								onConfirm?.();
							}}
						/>
					</Row>
				</Column>
			</Root>
		),
		[
			formRef,
			formRefSetter,
			language,
			onCancel,
			onChange,
			onConfirm,
			value,
			t,
		],
	);

	return <Modal dimmer>{content}</Modal>;
});

declare namespace ImportModal {
	type Value = Form.Value;

	export interface PropsBase {
		language: Map.Language;

		onCancel: () => void;
		onConfirm: () => void;
	}

	type Props = PropsBase & InputifiedComponentProps<Value>;
}

export default ImportModal;
