import { Dispatch, RefObject } from "react";
import { react } from "uikit";

import AssetPaymentsCurrency from "./components/AssetPaymentsCurrency";
import AssetPaymentsCountryIso from "./components/AssetPaymentsCountryIso";

interface Context {
	assetPaymentsProcessingId: number;
	assetPaymentsSecretKey: string;
	assetPaymentsKey: string;
	currencyRef: RefObject<AssetPaymentsCurrency.Ref | null>;
	countryIsoRef: RefObject<AssetPaymentsCountryIso.Ref | null>;
	setAssetProcessingIdError: Dispatch<boolean>;
	setAssetPaymentsKeyError: Dispatch<boolean>;
	setAssetPaymentsSecretKeyError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCurrency = this.context?.currencyRef.current?.validate();
		const isCountryIso = this.context?.countryIsoRef.current?.validate();
		const isAssetPaymentsProcessingId =
			!!this.context?.assetPaymentsProcessingId;
		const isAssetPaymentsSecretKey =
			!!this.context?.assetPaymentsSecretKey.trim();
		const isAssetPaymentsKey = !!this.context?.assetPaymentsKey.trim();

		if (!isAssetPaymentsProcessingId) {
			this.context?.setAssetProcessingIdError(true);
		}

		if (!isAssetPaymentsKey) {
			this.context?.setAssetPaymentsKeyError(true);
		}

		if (!isAssetPaymentsSecretKey) {
			this.context?.setAssetPaymentsSecretKeyError(true);
		}

		return [
			isCurrency,
			isCountryIso,
			isAssetPaymentsProcessingId,
			isAssetPaymentsKey,
			isAssetPaymentsSecretKey,
		].every((isValid) => isValid);
	}
}
