import React, { RefAttributes, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextBox, react } from "uikit";

import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../../../constants/tPath";

import InternalController from "./Controller";

const NameBase = react.withController<Name.PropsBase, Name.Controller>(
	({ name, setName, controller }) => {
		const [error, setError] = useState(false);
		controller.setContext({ name, setError });

		const { t } = useTranslation();

		const handleNameUpdate = useCallback(
			(newName) => {
				setError(false);
				setName(newName);
			},
			[setName],
		);

		return (
			<LabeledField label={t(`${tPath}.modal.tabs.main.name`) || ""}>
				<TextBox.TextBox
					placeholder={t(`${tPath}.modal.tabs.main.name`) || ""}
					value={name}
					error={error}
					onChange={handleNameUpdate}
				/>
			</LabeledField>
		);
	},
	InternalController,
);
const Name = memo(NameBase);

declare namespace Name {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		name: Value;
		setName: (x: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = string;
}

export default Name;
