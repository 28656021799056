import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	height: 48px;

	padding-left: 16px;

	background: ${theme.colors.primary};
`;

export default Root;
