import styled from "styled-components";

const Root = styled.div`
	padding: 8px 14px 10px;

	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);

	z-index: 6;
`;

export default Root;
