import React, { Dispatch, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../../../../services/Language";

import Title from "./components/Title";
import Root from "./components/Root";
import Form from "./components/Form";

const Content = forwardRef<Content.Controller, Content.Props>(
	({ value, language, onChange }, ref) => {
		const { t } = useTranslation();

		const { textEdit, textAdd, textParking }: Record<string, string> =
			useMemo(() => {
				const getTranslation = (key: string) =>
					t(
						`pages.preferencesPages.screenDirectory.parkings.editModal.content.${key}`,
					) ?? "";

				return {
					textEdit: getTranslation("str200"),
					textAdd: getTranslation("str201"),
					textParking: getTranslation("str202"),
				};
			}, [t]);

		return (
			<Root gaps="24px">
				<Title>
					{value.id ? textEdit : textAdd} {textParking}
				</Title>
				<Form
					ref={ref}
					value={value}
					language={language}
					onChange={onChange}
				/>
			</Root>
		);
	},
);

declare namespace Content {
	interface Value extends Form.Value {
		id?: number;
	}

	interface Props {
		value: Value;

		language: Language;

		onChange: Dispatch<Value>;
	}

	type Controller = Form.Controller;
}

export default Content;
