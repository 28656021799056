import styled, { css } from "styled-components";
import { Button as ButtonBase, theme } from "uikit";

const DefaultPageHeaderButton = styled(ButtonBase.Button)`
	width: 32px;
	height: 32px;
	box-sizing: border-box;

	${({ variant, disabled }) => {
		if (variant === "secondary") {
			return css`
				border: 1px solid
					${disabled ? theme.colors.button_secondary : "#D7D9DA"} !important;
				background: ${disabled
					? theme.colors.white
					: theme.colors.button_secondary} !important;
				padding: 5px;
			`;
		}
		return "";
	}}
`;

export default DefaultPageHeaderButton;
