/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import AdditionalTariff from "../../../../../../services/AdditionalTariff";
import OrderSettings from "../../../../../../services/OrderSettings";

import mapByKey from "../../../../../../utils/mapByKey";

import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";

import additionalTariff from "../../../../../../redux/reducers/additionalTariff";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import Root from "../../components/Root";
import Content from "./components/Content";
import Modal from "./components/Modal";

const ExecutorTariffPlans = () => {
	const dispatch = useDispatch();

	const additionalTariffData = useModelSubscribe({}, AdditionalTariff);

	const [showModal, setShowModal] = useState<boolean>(false);

	const [settings, setSettings] = useState<OrderSettings.Model[]>([]);

	const [editingItem, setEditingItem] = useState<any | null>(null);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(() => mapByKey(settings, "id"), [settings]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem(cloneDeep(item));
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(setting: OrderSettings.Modified) => edit(setting.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(null);
	}, []);

	const saveHandler = useCallback(async () => {
		const isAllOk = true;

		if (isAllOk) {
			setShowModal(false);
			setEditingItem(null);
		}
	}, []);

	useEffect(() => {
		if (additionalTariffData.models.length) {
			dispatch(
				additionalTariff.actions.setSettings(
					additionalTariffData.models[0],
				),
			);
		}
	}, [additionalTariffData.models, dispatch]);

	const { t } = useTranslation();
	return (
		<Root sizes="auto! 1fr" maxedWidth maxedHeight>
			<DefaultPageHeader
				canAdd={true}
				canEdit={selected.length === 1}
				canDelete={false}
				onAdd={addHandler}
				onEdit={editHeaderHandler}
			/>
			<Content
				value={settings}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.str200",
						) ?? ""
					}
					createdAt={
						editingItem?.createdAt
							? new Date(
									editingItem?.createdAt,
							  ).toLocaleDateString()
							: new Date().toLocaleDateString()
					}
				/>
			)}
		</Root>
	);
};

export default ExecutorTariffPlans;
