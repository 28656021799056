/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { Archive, ReduxReducer } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	isOrders: false,
	isOrder: false,
	isHistory: false,
};

const setIsLoadingHistory: ReduxReducer<State, State["isHistory"]> = (
	state,
	{ payload },
) => {
	state.isHistory = payload;
};

const setIsLoadingOrders: ReduxReducer<State, State["isOrders"]> = (
	state,
	{ payload },
) => {
	state.isOrders = payload;
};

const setIsLoadingOrder: ReduxReducer<State, State["isOrder"]> = (
	state,
	{ payload },
) => {
	state.isOrder = payload;
};

const resetLoading: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrdersLoadings = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_LOADINGS,
	initialState,
	reducers: {
		resetLoading,
		setIsLoadingOrders,
		setIsLoadingOrder,
		setIsLoadingHistory,
	},
});

export const archiveOrdersLoadingsAction = archiveOrdersLoadings.actions;
export const archiveOrdersLoadingsReducer = archiveOrdersLoadings.reducer;
export type archiveOrdersLoadingsState = State;

export default archiveOrdersLoadings;
