import React, { useCallback } from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { gray } from "../../../../../../../constants/styles/colors";
import { StyledRow } from "../../../../../../common";
import { useNavigationTitle } from "../../../../constants/business";
import { Budge } from "..";

const Root = styled(StyledRow)<{ active?: boolean; disabled?: boolean }>`
	position: relative;

	display: flex;

	font-family: Lato;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;
	margin-right: 8px;

	color: ${({ active, disabled }) => {
		if (disabled) return gray[5];
		if (active) return theme.colors.accent;
		return theme.colors.text_placeholder;
	}};

	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&::before {
		content: "";
		display: ${({ active }) => (active ? "block" : "none")};
		height: 3px;
		position: absolute;
		bottom: -11px;
		left: auto;
		right: auto;
		width: 100%;
		border-radius: 3px 3px 0px 0px;
		background-color: ${theme.colors.accent};
	}
`;

const Tab: React.FC<Tab.Props> = ({
	active = false,
	disabled = false,
	title,
	onChange,
}) => {
	const NAVIGATION_TITLES = useNavigationTitle();

	const handleClick = useCallback(() => {
		if (!disabled) {
			onChange();
		}
	}, [onChange, disabled]);

	return (
		<Root
			active={active}
			disabled={disabled}
			gap="6px"
			onClick={handleClick}
		>
			{title}
			{title === NAVIGATION_TITLES.EXECUTORS && <Budge />}
		</Root>
	);
};

declare namespace Tab {
	interface Props {
		title: string;
		active?: boolean;
		onChange: () => void;
		disabled?: boolean;
	}
}

export default Tab;
