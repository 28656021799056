import makeTable from "../makeTable";

const counterparty = makeTable({
	allIds: [
		"name",
		"edrpou",
		"overallBalance",
		"disconnectionThreshold",
		"overallBonusBalance",
		"orderCount",
		"contactPerson",
		"phones",
		"email",
		"company",
		"registrationBranch",
		"registrationDate",
		"firstTripDate",
		"lastTripDate",
		"mobileApp",
		"corporateAccount",
		"notes",
		"status",
		"group",
	],
	defaultIds: [
		"name",
		"edrpou",
		"orderCount",
		"contactPerson",
		"phones",
		"email",
		"company",
		"registrationBranch",
		"status",
	],
	i18tPath: (id) => `tableColumns.customers.counterparties.${id}`,
});

export default counterparty;
