import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, theme } from "uikit";
import Root from "./components/Root";
import Item from "./components/Item";
import ClearButton from "./components/ClearButton";

type Key = string | number;
interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

const Chips = <OptionValue, ValueType extends Key>({
	value,

	options,

	onChange,
}: Chips.Props<OptionValue, ValueType>) => {
	const optionByKey = useMemo(
		() =>
			options.reduce((accumulator, option: any) => {
				accumulator[option.key] = option;

				return accumulator;
			}, {} as Record<any, Option<unknown>>),
		[options],
	);

	const { t } = useTranslation();
	return (
		<Root
			gaps="8px*"
			wrap="wrap"
			justify="start"
			style={{ width: "100%", height: "24px" }}
		>
			{value.map((currentKey) => (
				<Item key={currentKey}>
					{optionByKey[currentKey].label}
					<span
						style={{ cursor: "pointer" }}
						onClick={() =>
							onChange(value.filter((key) => key !== currentKey))
						}
					>
						<Icon
							id="close"
							size={8}
							colors={[theme.colors.disabled_text]}
						/>
					</span>
				</Item>
			))}
			{value.length !== 0 && (
				<ClearButton onClick={() => onChange([])}>
					<Icon
						id="close"
						size={8}
						colors={[theme.colors.negative]}
					/>
					{t("multiSelectModal.content.chips.str150") ?? ""}
				</ClearButton>
			)}
		</Root>
	);
};

declare namespace Chips {
	type Value<Type extends Key> = Type[];

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;

		options: Option<OptionValue>[];

		onChange: (value: Value<ValueType>) => void;
	}
}

export default Chips;
