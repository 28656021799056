import React, {
	memo,
	// useMemo,  useCallback
} from "react";
// import { isNumber, isString } from "lodash";
import { useTranslation } from "react-i18next";
// import moment from "moment";

// import useTime from "../../../../../../../../../hooks/useTime";
import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";
import { Gray, Primary } from "../../styled";

const ExecutionTime: React.FC<ExecutionTime.Props> = ({ tab }) => {
	// const formatDuration = useCallback((milliseconds: number): string => {
	// 	const sign = Math.sign(milliseconds);
	// 	// eslint-disable-next-line no-param-reassign
	// 	milliseconds = Math.abs(milliseconds);
	// 	const seconds = Math.floor((milliseconds / 1e3) % 60);
	// 	const minutes = Math.floor((milliseconds / (1e3 * 60)) % 60);
	// 	const hours = Math.floor((milliseconds / (1e3 * 60 * 60)) % 24);
	// 	const days = Math.floor(milliseconds / (1e3 * 60 * 60 * 24));
	// 	const formattedHours = String(hours).padStart(2, "0");
	// 	const formattedMinutes = String(minutes).padStart(2, "0");
	// 	const formattedSeconds = String(seconds).padStart(2, "0");
	// 	const formattedDays = days !== 0 ? `${days}Д ` : "";
	// 	return `${
	// 		sign === -1 ? "-" : ""
	// 	}${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	// }, []);

	const { t } = useTranslation();
	// const currentTime = useTime("every-second");

	// const arrivalTime = useMemo(
	// 	() => tab?.form?.arrivalTime,
	// 	[tab?.form?.arrivalTime],
	// );

	// const isThereExecutorArrivalTimestamp = useMemo(
	// 	() => isNumber(arrivalTime) || isString(arrivalTime),
	// 	[arrivalTime],
	// );

	// const timeLeft = useMemo(
	// 	() =>
	// 		isThereExecutorArrivalTimestamp
	// 			? moment(arrivalTime).diff(currentTime)
	// 			: undefined,
	// 	[currentTime, arrivalTime, isThereExecutorArrivalTimestamp],
	// );

	// const time = useMemo(() => {
	// 	 if (timeLeft) return formatDuration(timeLeft);
	// 	return "00:00:00";
	// }, [formatDuration, timeLeft]);

	return (
		<StyledRow gap="0 6px">
			<Primary>
				{`${t([`orderPage.order_form.execution`, "Fulfillment"])}:`}
			</Primary>
			<Gray>{"00:00:00"}</Gray>
		</StyledRow>
	);
};

declare namespace ExecutionTime {
	interface Props extends OrderFormProps {}
}
export const ExecutionTimeMemo = memo(ExecutionTime);
export default ExecutionTime;
