import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import ApplyToOrderPoints from "../../../../../Modal/components/Content/components/ApplyToOrderPoints";
import tPath from "../../../../../../constants/tPath";

const ApplyToOrderPointsOutput: React.FC<ApplyToOrderPointsOutput.Props> = memo(
	({ value }) => {
		const { t } = useTranslation();

		return value.active && value.points.length ? (
			<>
				<div>
					{value.points
						.map((d) =>
							t(`${tPath}.modal.applyToOrderPoints.options.${d}`),
						)
						.join(", ")}
				</div>
				<span>/</span>
			</>
		) : null;
	},
);

declare namespace ApplyToOrderPointsOutput {
	interface Props {
		value: ApplyToOrderPoints.Value;
	}
}

export default ApplyToOrderPointsOutput;
