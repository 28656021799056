import styled from "styled-components";
import { isNumber } from "lodash";
import { theme } from "uikit";

const Divider = styled.div<Divider.Props>`
	${({ orientation, thickness = 1, size }) =>
		orientation === "vertical"
			? `
        width: ${thickness}px;
        height: ${
			// eslint-disable-next-line no-nested-ternary
			isNumber(size) ? `${size}px` : size === "max" ? "100%" : "auto"
		};
    `
			: `
        width: ${
			// eslint-disable-next-line no-nested-ternary
			isNumber(size) ? `${size}px` : size === "max" ? "100%" : "auto"
		};
        height: ${thickness}px;
    `}

	background: ${({ color = theme.colors.secondary }) => color};
`;

declare namespace Divider {
	interface Props {
		orientation: "vertical" | "horizontal";
		size?: number | "max";
		thickness?: number;
		color?: string;
	}
}

export default Divider;
