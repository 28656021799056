import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useActiveTab from "../../../../hooks/useActiveTab";
import { useTypedSelector } from "../../../../redux/store";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { SideTab } from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";
import {
	CustomerPenalties,
	CustomerTariffPlans,
	ExecutorPenalties,
	ExecutorTariffPlans2,
	UserPenalties,
	UserTariffPlans,
} from "./tabs";

const Finances: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				// {
				// 	label: t("settings.finances.executorTariffPlans.title"),
				// 	value: {
				// 		render: () => <ExecutorTariffPlans />,
				// 	},
				// },
				{
					key: TabKeys.EXECUTOR_TARIFF_PLANS_2,
					label: t("settings.finances.executorTariffPlans.title"),
					value: {
						render: () => <ExecutorTariffPlans2 />,
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_TARIFF_PLANS_2],
				},
				{
					key: TabKeys.CUSTOMER_TARIFF_PLANS,
					label: t("settings.finances.customerTariffPlans.title"),
					value: {
						render: () => <CustomerTariffPlans />,
					},
					accessName: TabAccessNames[TabKeys.CUSTOMER_TARIFF_PLANS],
				},
				{
					key: TabKeys.USER_TARIFF_PLANS,
					label: t("settings.finances.userTariffPlans.title"),
					value: {
						render: () => <UserTariffPlans />,
					},
					accessName: TabAccessNames[TabKeys.USER_TARIFF_PLANS],
				},
				{
					key: TabKeys.EXECUTOR_PENALTIES,
					label: t("settings.finances.executorPenalties.title"),
					value: {
						render: () => <ExecutorPenalties />,
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_PENALTIES],
				},
				{
					key: TabKeys.CUSTOMER_PENALTIES,
					label: t("settings.finances.customerPenalties.title"),
					value: {
						render: () => <CustomerPenalties />,
					},
					accessName: TabAccessNames[TabKeys.CUSTOMER_PENALTIES],
				},
				{
					key: TabKeys.USER_PENALTIES,
					label: t("settings.finances.userPenalties.title"),
					value: {
						render: () => <UserPenalties />,
					},
					accessName: TabAccessNames[TabKeys.USER_PENALTIES],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Finances;
