import React, {
	Dispatch,
	memo,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { defaults, isSymbol } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../../../../../services/Language";
import ExecutorRateDiscountPlan from "../../../../../../../../../../../../../../services/ExecutorRateDiscountPlan";
import ExecutorRateCommissionPlan from "../../../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { DateFns } from "../../../../../../../../../../../../../../utils/DateFns";
import RequiredProperties from "../../../../../../../../../../../../../../types/RequiredProperties";
import EditModalRoot from "../../../../../../../../../../../../../../components/EditModal";
import DeleteModal from "../../../../../../../../../../../../../../components/DeleteModal";
import {
	checkCommissionPlanId,
	checkMinMaxFee,
	checkPeriod,
	checkTariff,
	checkThreshold,
	checkTime,
	checkWeekdays,
} from "../../../../../../../../utils";

import InternalController from "./Controller";
import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const defaultValue: Partial<EditModal.Value> = {
	name: "",

	startDate: {
		value: moment().startOf("day").toDate(),
	},

	timeRange: {
		active: false,
		from: undefined,
		to: undefined,
	},

	period: {
		unit: ExecutorRateDiscountPlan.Model.Period.Type.Daily,
		value: 1,
	},

	threshold: {
		value: 0,
	},

	minFee: {
		active: false,
		value: 0,
	},

	maxFee: {
		active: false,
		value: 0,
	},
};

const EditModalBase: React.FC<EditModal.Props> = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ value, commissionPlans, language, onCancel, onSave, controller }) => {
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);
	const { t } = useTranslation();
	const [showTypeModal, setShowTypeModal] = useState(false);
	const [showTimeModal, setShowTimeModal] = useState(false);
	const [showTariffModal, setShowTariffModal] = useState(false);
	const [typeName, setTypeName] = useState<string>(
		t(
			"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str200",
		) ?? "",
	);

	const [internalValue, setInternalValue] = useState(
		defaults({}, value, defaultValue),
	);

	const dateFns = useMemo(() => new DateFns(), []);
	const internalValueEditor = useObjectEditor(
		internalValue,
		setInternalValue,
	);

	const contentValue = internalValueEditor.usePicker([
		"commissionPlanId",
		"name",
		"startDate",
		"timeRange",
		"period",
		"threshold",
		"weekdays",
		"commissions",
		"minFee",
		"maxFee",
	]);

	const assignInternalValue = internalValueEditor.useAssigner();

	const validateTime = useCallback(() => {
		const steepWeekdays = checkWeekdays({
			prev: value,
			next: internalValue,
		});

		if (!steepWeekdays) return false;

		const steepThreshold = checkThreshold({
			prev: value,
			next: internalValue,
		});

		if (!steepThreshold) return false;

		const steepPeriod = checkPeriod({
			prev: value,
			next: internalValue,
		});

		if (!steepPeriod) return false;

		const steepTime = checkTime({
			prev: value,
			next: internalValue,
		});

		if (!steepTime) return false;

		return true;
	}, [internalValue, value]);

	const validateTariff = useCallback(() => {
		const steepMinMaxFee = checkMinMaxFee({
			prev: value,
			next: internalValue,
		});
		if (!steepMinMaxFee) return false;

		const steepTariff = checkTariff({
			prev: value,
			next: internalValue,
		});

		if (!steepTariff) return false;

		return true;
	}, [internalValue, value]);

	const handleSubmit = useCallback(
		(allow = false) => {
			if (!contentRef.current?.validate()) return;

			if (!internalValue.id || typeof internalValue.id === "symbol") {
				onSave(internalValue as EditModal.Value.Validated);
				return;
			}

			if (!allow) {
				const steepCommissionPlanId = checkCommissionPlanId({
					prev: value,
					next: internalValue,
				});

				if (!steepCommissionPlanId) {
					setShowTypeModal(true);
					return;
				}

				const isTime = validateTime();
				if (!isTime) {
					setShowTimeModal(true);
					return;
				}

				const isTariff = validateTariff();
				if (!isTariff) {
					setShowTariffModal(true);
					return;
				}

				onSave(internalValue as EditModal.Value.Validated);
			} else onSave(internalValue as EditModal.Value.Validated);
		},
		[
			contentRef,
			internalValue,
			onSave,
			validateTariff,
			validateTime,
			value,
		],
	);

	const typeModalOnCancel = useCallback(() => {
		setShowTypeModal(false);
	}, []);

	const typeModalOnConfirm = useCallback(async () => {
		const isTime = validateTime();
		if (!isTime) {
			setShowTimeModal(true);
			setShowTypeModal(false);
			return;
		}

		const isTariff = validateTariff();
		if (!isTariff) {
			setShowTariffModal(true);
			setShowTypeModal(false);
			return;
		}

		setShowTypeModal(false);
		handleSubmit(true);
	}, [handleSubmit, validateTariff, validateTime]);

	const timeModalOnCancel = useCallback(() => {
		setShowTimeModal(false);
	}, []);

	const timeModalOnConfirm = useCallback(async () => {
		const isTariff = validateTariff();
		if (!isTariff) {
			setShowTariffModal(true);
			setShowTimeModal(false);
			return;
		}

		setShowTimeModal(false);
		handleSubmit(true);
	}, [handleSubmit, validateTariff]);

	const tariffModalOnCancel = useCallback(() => {
		setShowTariffModal(false);
	}, []);

	const tariffModalOnConfirm = useCallback(async () => {
		setShowTariffModal(false);
		handleSubmit(true);
	}, [handleSubmit]);

	const textShowTypeModal = useMemo(() => {
		const time = dateFns.format(
			internalValue.startDate?.value || null,
			language,
		);
		const textTranslateBefore =
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str201",
			) ?? "";
		const textTranslateBetween =
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str202",
			) ?? "";
		const textTranslateAfter =
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str203",
			) ?? "";
		return `${textTranslateBefore} "${typeName}" ${textTranslateBetween} ${time}, ${textTranslateAfter}.`;
	}, [dateFns, internalValue.startDate?.value, language, t, typeName]);

	const textShowTimeModal = useMemo(() => {
		const textTranslate =
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str204",
			) ?? "";

		return textTranslate;
	}, [t]);

	const textShowTariffModal = useMemo(() => {
		const time = dateFns.format(
			internalValue.startDate?.value || null,
			language,
		);
		const textTranslate =
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str207",
			) ?? "";

		return `${textTranslate} ${time}.`;
	}, [dateFns, internalValue.startDate.value, t, language]);

	const headerTitle = useMemo(
		() =>
			isSymbol(value.id)
				? t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str209",
				  ) ?? ""
				: t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.str210",
				  ) ?? "",
		[t, value.id],
	);

	controller.setContext({ contentRef });

	return (
		<EditModalRoot onCancel={onCancel} onSave={handleSubmit}>
			<Root>
				<Header title={headerTitle} createdAt={value.createdAt} />
				<Content
					ref={setContentRef}
					value={contentValue}
					commissionPlans={commissionPlans}
					language={language}
					onChange={assignInternalValue}
					setTypeName={setTypeName}
				/>

				{showTypeModal && (
					<DeleteModal
						label={textShowTypeModal}
						onCancel={typeModalOnCancel}
						onConfirm={typeModalOnConfirm}
					/>
				)}

				{showTimeModal && (
					<DeleteModal
						label={textShowTimeModal}
						onCancel={timeModalOnCancel}
						onConfirm={timeModalOnConfirm}
					/>
				)}

				{showTariffModal && (
					<DeleteModal
						label={textShowTariffModal}
						onCancel={tariffModalOnCancel}
						onConfirm={tariffModalOnConfirm}
					/>
				)}
			</Root>
		</EditModalRoot>
	);
}, InternalController);

const EditModal = memo(EditModalBase);

declare namespace EditModal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;

		language: Language;
		commissionPlans: ExecutorRateCommissionPlan.Model[];

		onCancel: () => void;
		onSave: Dispatch<Value.Validated>;
	}

	interface Value extends Content.Value {
		id: number | symbol;

		createdAt?: string;
	}

	namespace Value {
		type Validated = RequiredProperties<
			Value,
			"name" | "startDate" | "threshold"
		>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
