import {
	DefaultSearchType,
	MapResolutions,
} from "../../../../../../../../../../../../services/MapSettings/types";
import getOptions from "../../../../../../../../../../../../utils/generateOptions";

export const mapResolutionOptions = getOptions(Object.values(MapResolutions));

export const defaultSearchTypeOptions = getOptions(
	Object.values(DefaultSearchType),
);
