/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateRuleOrderPoint {
	START = "start",
	MIDWAY = "midway",
	END = "end",
	PASS_THROUGH = "pass_through",
}

export const orderPointsOptions = getOptions([
	AutoRateRuleOrderPoint.START,
	AutoRateRuleOrderPoint.MIDWAY,
	AutoRateRuleOrderPoint.PASS_THROUGH,
	AutoRateRuleOrderPoint.END,
]);
