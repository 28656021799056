import styled from "styled-components";

const Button = styled.a<Button.Props>`
	all: unset;

	cursor: ${({ disabled = false }) => (disabled ? "not-allowed" : "pointer")};
`;

declare namespace Button {
	interface Props {
		disabled?: boolean;
	}
}

export default Button;
