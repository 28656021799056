import React from "react";

import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const NameCellContent: React.FC<NameCellContent.Props> = ({ item }) => (
	<CellContentRoot alignItems="center" w="100%" h="100%">
		{item.name ?? "_name"}
	</CellContentRoot>
);

declare namespace NameCellContent {
	interface Props {
		item: any;
	}
}

export default NameCellContent;
