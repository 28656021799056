import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<{ bgColor?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 32px;
	min-height: 32px;
	max-width: 32px;
	max-height: 32px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
	color: ${theme.colors.white};
	background-color: ${({ bgColor }) => bgColor || theme.colors.disabled_text};
	border-radius: 50%;
`;

const Avatar: React.FC<Avatar.Props> = ({ text, bgColor }) => {
	const getFirstLetterUpperCase = (str?: string): string =>
		str ? str.charAt(0).toUpperCase() : "";

	return <Root bgColor={bgColor}>{getFirstLetterUpperCase(text)}</Root>;
};

declare namespace Avatar {
	export interface Props {
		text?: string;
		bgColor?: string;
	}
}

export default memo(Avatar);
