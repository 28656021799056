import React, { memo, useMemo } from "react";
import { PathOptions } from "leaflet";

import {
	ConstantColors,
	Point,
	ValueColors,
} from "../../../../redux/reducers/Archives/GPSLog/interface";

import PolylineItem from "../PolylineItem";

const PolylineListBase: React.FC<PolylineListBase.Props> = ({
	points = [],
	...props
}): JSX.Element => {
	const items = useMemo<Point[]>(() => {
		if (!points?.length) return [];
		return points;
	}, [points]);

	if (!items?.length) return <></>;
	return (
		<>
			{items.map((item, i): JSX.Element => {
				if (!item || !item?.points?.length) return <></>;

				return (
					<PolylineItem
						key={`index:${i}`}
						color={
							item.order
								? ConstantColors.RED
								: ConstantColors.BLUE
						}
						points={item.points}
						{...props}
					/>
				);
			})}
		</>
	);
};

declare namespace PolylineListBase {
	interface Props {
		points: Point[];
		color?: ValueColors;
		polylinePathOptions?: PathOptions;
	}
}

export const PolylineList = memo(PolylineListBase);
export default PolylineListBase;
