import Map from "../services/Map";

// eslint-disable-next-line import/prefer-default-export
export function settlementToString(
	obj: Partial<Map.Search.Object> | null,
): string {
	if (!obj) return "";

	const settlement = obj.settlement ?? "";
	const settlementType = obj.settlementType ?? "";
	const district = obj.district ?? null;
	const preparedDisctrict = district ? `, ${district}` : "";
	const region = obj.region ?? null;
	const preparedRegion = region ? `, ${region}` : "";

	return `${settlementType} ${settlement}${preparedDisctrict}${preparedRegion}`;
}
