import styled from "styled-components";
import { theme } from "uikit";

const Text = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;

	color: ${theme.colors.secondary};

	user-select: none;
`;

export default Text;
