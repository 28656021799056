import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	padding: 8px;

	background: ${theme.colors.white};
`;

export default Root;
