import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";

export default function focusOrderCard(id: number) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		await createRPCQuery(() => prpcow.theirsModel.orders.card.focus(id));

		return null;
	};
}
