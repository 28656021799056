import React, { memo, Suspense } from "react";

import PassengerAddPoint, { PassengerAddPointMemo } from "./PassengerAddPoint";
import PassengerRemovePoint, {
	PassengerRemovePointMemo,
} from "./PassengerRemovePoint";
import PassengerChangePoint, {
	PassengerChangePointMemo,
} from "./PassengerChangePoint";

const ChangedPassengers: React.FC<ChangedPassengers.Props> = ({
	passengerPoints,
}) => (
	<Suspense fallback="Loading...">
		{!!passengerPoints.length &&
			passengerPoints.map((passenger, i) => {
				if (!passenger) return <></>;
				const { passengerName, pointDiff } = passenger;
				const { addedItems, removedItems, changedItems } = pointDiff;
				return (
					<>
						<PassengerAddPointMemo
							key={`${i}-add`}
							passengerName={passengerName}
							addedItems={addedItems}
						/>
						<PassengerRemovePointMemo
							key={`${i}-remove`}
							passengerName={passengerName}
							removedItems={removedItems}
						/>
						<PassengerChangePointMemo
							key={`${i}-change`}
							passengerName={passengerName}
							changedItems={changedItems}
						/>
					</>
				);
			})}
	</Suspense>
);

declare namespace ChangedPassengers {
	interface Props {
		passengerPoints: PassengerPoints;
	}

	interface PointDiff {
		addedItems: PassengerAddPoint.AddedItems;
		removedItems: PassengerRemovePoint.RemovedItems;
		changedItems: PassengerChangePoint.ChangedItems;
	}

	interface PassengerPoint {
		passengerName: string;
		pointDiff: PointDiff;
	}

	type PassengerPoints = PassengerPoint[];
}

export const ChangedPassengersMemo = memo(ChangedPassengers);
export default ChangedPassengers;
