/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Column } from "uikit";
import { SortType } from "rsuite-table";

import { useTypedSelector } from "../../../../../../redux/store";
import TaxiService from "../../../../../../services/TaxiService";
import Message from "../../../../../../services/Message";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import useTaxiServiceIdsDecoder from "../../../../../../hooks/useTaxiServiceIdsDecoder";
import useCompanyIdsDecoder from "../../../../../../hooks/useCompanyIdsDecoder";
import Content from "./components/Content";
import Header from "./components/Header";

export interface Filter {
	dateRange: {
		dateFrom: Date;
		dateTo: Date;
	};
	sort: {
		column: string | undefined;
		type: SortType | undefined;
	};
}

export interface ModalFilter {
	companyIds: number[];
	taxiServiceIds: number[];
	simpleStatuses: Message.SmsMessageSimpleStatus[];
	types: Message.SmsMessageType[];
}

const MessagesHistory: React.FC<MessagesHistory.Props> = memo(() => {
	const language = useTypedSelector((state) => state.session.language);

	const [selected, setSelected] = useState<number[]>([]);
	const [limit, setLimit] = useState<number>(40);

	const [filter, setFilter] = useState<Filter>({
		dateRange: {
			dateFrom: moment().startOf("day").toDate(),
			dateTo: moment().endOf("day").toDate(),
		},
		sort: {
			column: undefined,
			type: undefined,
		},
	});

	const [modalFilter, setModalFilter] = useState<ModalFilter>({
		companyIds: [],
		taxiServiceIds: [],
		simpleStatuses: [],
		types: [],
	});

	const allTaxiServiceIds = useTaxiServiceIdsDecoder();
	const allCompanyIds = useCompanyIdsDecoder();

	const taxiServiceIds = useMemo(
		() => allTaxiServiceIds(["all"], ["all"]),
		[allTaxiServiceIds],
	);
	const companyIds = useMemo(() => allCompanyIds(["all"]), [allCompanyIds]);

	const onSortTable = useCallback((column: string, type: SortType) => {
		setFilter((prev) => ({ ...prev, sort: { column, type } }));
	}, []);

	const options = useMemo<Message.SubscribeOptions>(() => {
		const result: Message.SubscribeOptions = {
			dateRange: {
				from: filter.dateRange.dateFrom,
				to: filter.dateRange.dateTo,
			},
			taxiServiceIds: modalFilter.taxiServiceIds,
			simpleStatuses: modalFilter.simpleStatuses,
			types: modalFilter.types,
			limit,
		};

		if (filter.sort.column && filter.sort.type) {
			result.order = {
				[filter.sort.column]: filter.sort.type.toUpperCase(),
			};
		}

		return result;
	}, [
		filter.dateRange.dateFrom,
		filter.dateRange.dateTo,
		filter.sort.column,
		filter.sort.type,
		modalFilter.simpleStatuses,
		modalFilter.taxiServiceIds,
		modalFilter.types,
		limit,
	]);

	const messageSubscribeData = useModelSubscribe2(options, Message);
	const taxiServices = useModelSubscribe({}, TaxiService);

	const messagesModels = useMemo(
		() => messageSubscribeData.models || [],
		[messageSubscribeData.models],
	);

	useEffect(() => {
		setModalFilter((prev) => ({
			...prev,
			taxiServiceIds,
			companyIds,
		}));
	}, [companyIds, taxiServiceIds]);

	return (
		<Column gaps="5px*" sizes="auto 1fr" maxedWidth maxedHeight>
			<Header
				filter={filter}
				modalFilter={modalFilter}
				setFilter={setFilter}
				setModalFilter={setModalFilter}
				language={language}
				taxiServices={taxiServices}
			/>
			<Content
				value={messagesModels}
				selected={selected}
				setSelected={setSelected}
				sort={filter.sort}
				onSort={onSortTable}
				language={language}
				setLimit={setLimit}
			/>
		</Column>
	);
});

declare namespace MessagesHistory {
	interface Props {}
}

export default MessagesHistory;
