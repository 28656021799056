import React, { useMemo } from "react";
import moment from "moment";
import { isNumber } from "lodash";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutorArrivalTimeCellContent: React.FC<
	ExecutorArrivalTimeCellContent.Props
> = ({ item }) => {
	const executorArrivalTimestamp = useMemo(
		() => item?.executorToOrder?.[0]?.additionalFields?.arrivalTime,
		[item.executorToOrder],
	);

	const time = useMemo(
		() => item?.orderDate || item?.filledAt || 1,
		[item?.filledAt, item?.orderDate],
	);
	const orderTime = useMemo(() => new Date(time).getTime(), [time]);

	const content = useMemo(
		() =>
			isNumber(executorArrivalTimestamp)
				? moment(Math.max(orderTime, executorArrivalTimestamp))
						.set({ second: 0, millisecond: 0 })
						.format("HH:mm:ss")
				: "",
		[executorArrivalTimestamp, orderTime],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorArrivalTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutorArrivalTimeCellContent;
