enum SourceAddrTon {
	UNKNOWN = "0",
	INTERNATIONAL = "1",
	NATIONAL = "2",
	NETWORK_SPECIFIC = "3",
	SUBSCRIBER_NUMBER = "4",
	ALPHANUMERIC = "5",
	ABBREVIATED = "6",
}

enum SourceAddrNpi {
	UNKNOWN = "0",
	ISDN = "1",
	DATA = "3",
	TELEX = "4",
	LAND_MOBILE = "6",
	NATIONAL = "8",
	PRIVATE = "9",
	ERMES = "10",
	INTERNET = "14",
	WAP_CLIENT_ID = "18",
}

const tonAndNpiMocks = {
	ton: [
		{
			value: "unkown",
			label: "Unknown (0)",
			key: `${SourceAddrTon.UNKNOWN}`,
		},
		{
			value: "international",
			label: "International (1)",
			key: `${SourceAddrTon.INTERNATIONAL}`,
		},
		{
			value: "national",
			label: "National (2)",
			key: `${SourceAddrTon.NATIONAL}`,
		},
		{
			value: "networkSpecific",
			label: "Network Specific (3)",
			key: `${SourceAddrTon.NETWORK_SPECIFIC}`,
		},
		{
			value: "subscriberNumber",
			label: "Subscribe Number (4)",
			key: `${SourceAddrTon.SUBSCRIBER_NUMBER}`,
		},
		{
			value: "alphanumeric",
			label: "Alphanumeric (5)",
			key: `${SourceAddrTon.ALPHANUMERIC}`,
		},
		{
			value: "abbreviated",
			label: "Abbreviated (6)",
			key: `${SourceAddrTon.ABBREVIATED}`,
		},
	],
	npi: [
		{
			value: "unkown",
			label: "Unknown (0)",
			key: `${SourceAddrNpi.UNKNOWN}`,
		},
		{
			value: "isdn",
			label: "ISDN (1)",
			key: `${SourceAddrNpi.ISDN}`,
		},
		{
			value: "data",
			label: "Data (3)",
			key: `${SourceAddrNpi.DATA}`,
		},
		{
			value: "telex",
			label: "Telex (4)",
			key: `${SourceAddrNpi.TELEX}`,
		},
		{
			value: "landMobile",
			label: "Land Mobile (6)",
			key: `${SourceAddrNpi.LAND_MOBILE}`,
		},
		{
			value: "national",
			label: "National (8)",
			key: `${SourceAddrNpi.NATIONAL}`,
		},
		{
			value: "private",
			label: "Private (9)",
			key: `${SourceAddrNpi.PRIVATE}`,
		},
		{
			value: "ermes",
			label: "ERMES (10)",
			key: `${SourceAddrNpi.ERMES}`,
		},
		{
			value: "internet",
			label: "Internet (14)",
			key: `${SourceAddrNpi.INTERNET}`,
		},
		{
			value: "wapClientId",
			label: "WAP CLIENT ID (18)",
			key: `${SourceAddrNpi.WAP_CLIENT_ID}`,
		},
	],
};
export default tonAndNpiMocks;
