import { combineReducers } from "@reduxjs/toolkit";

import { dataAction, dataReducer, dataState } from "./data";
import { errorsAction, errorsReducer, errorsState } from "./errors";
import { filtersAction, filtersReducer, filtersState } from "./filters";

export type StateCarClasses = {
	data: dataState;
	errors: errorsState;
	filters: filtersState;
};

export const carClasses = combineReducers<StateCarClasses>({
	data: dataReducer,
	errors: errorsReducer,
	filters: filtersReducer,
});

export const carClassesAction = {
	data: dataAction,
	errors: errorsAction,
	filters: filtersAction,
};

export default carClasses;
