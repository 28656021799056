import { IMainState } from "@egorcloud/react-doc-viewer/build/state/reducers/main.reducers";
import React, { useMemo } from "react";
import Root from "./components/Root";
import Information from "./components/Information";
import FileViewerModal from "../..";
import Controls from "./components/Controls";

const Header: React.FC<Header.Props> = ({
	currentDocument,
	disabled,

	onDelete,
	onClose,
}) => {
	const file = useMemo(
		() => (currentDocument as FileViewerModal.Document | undefined)?.base,
		[currentDocument],
	);

	return (
		<Root align="center" justify="space-between" maxedWidth>
			<Information file={file} />
			<Controls
				file={file}
				disabled={disabled}
				onDelete={onDelete}
				onClose={onClose}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props extends IMainState {
		disabled: boolean;

		onDelete: () => void;
		onClose: () => void;
	}
}

export default Header;
