import { combineReducers } from "@reduxjs/toolkit";

import {
	orderParkingDataState,
	orderParkingDataReducer,
	orderParkingDataAction,
} from "./data";
import {
	orderParkingFiltersState,
	orderParkingFiltersReducer,
	orderParkingFiltersAction,
} from "./filter";
import {
	orderParkingTableSettingsState,
	orderParkingTableSettingsReducer,
	orderParkingTableSettingsAction,
} from "./tableSettings";

export type StateParking = {
	data: orderParkingDataState;
	filter: orderParkingFiltersState;
	tableSettings: orderParkingTableSettingsState;
};

export const parking = combineReducers<StateParking>({
	data: orderParkingDataReducer,
	filter: orderParkingFiltersReducer,
	tableSettings: orderParkingTableSettingsReducer,
});

export const ordersParkingAction = {
	data: orderParkingDataAction,
	filter: orderParkingFiltersAction,
	tableSettings: orderParkingTableSettingsAction,
};

export default parking;
