import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme, Row, Flex } from "uikit";

import useKeyBind from "../../hooks/useKeyBind";

import BaseModal, { BaseModalProps } from "./BaseModal";

interface StyledButtonProps {
	color: string;
}

const StyledButton = styled.button<StyledButtonProps>`
	color: white;
	font-size: 14px;
	font-weight: 600;
	background-color: ${(props) => props.color};
	flex: 1 0 0;
	height: 40px;
	padding: 0;
`;

const StyledFlex = styled(Flex)`
	&:hover {
		backdrop-filter: brightness(0.9);
	}
`;

export interface ConfirmationModalProps extends BaseModalProps {
	onConfirm: () => void;
	content: React.ReactNode;
}

export function ConfirmationModal({
	onConfirm,
	onClose,
	content,
	...props
}: ConfirmationModalProps) {
	const { t } = useTranslation();
	const confirm = useCallback(() => onConfirm(), [onConfirm]);
	const close = useCallback(() => onClose?.(), [onClose]);

	useKeyBind(["Enter"], (e) => {
		confirm();
		e.preventDefault();
		e.stopPropagation();
	});

	useKeyBind(["Escape"], (e) => {
		close();
		e.preventDefault();
		e.stopPropagation();
	});

	return (
		<BaseModal {...props} onClose={onClose}>
			{content}

			<Row
				style={{
					borderRadius: "0 0 10px 10px",
					overflow: "hidden",
				}}
			>
				<StyledButton color={theme.colors.error} onClick={close}>
					<StyledFlex
						justify="center"
						align="center"
						maxedWidth
						maxedHeight
					>
						{t([`cancel`, "Cancel"])}
					</StyledFlex>
				</StyledButton>
				<StyledButton color={theme.colors.info} onClick={confirm}>
					<StyledFlex
						justify="center"
						align="center"
						maxedWidth
						maxedHeight
					>
						{t([`confirm`, "Confirm"])}
					</StyledFlex>
				</StyledButton>
			</Row>
		</BaseModal>
	);
}
