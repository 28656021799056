import { memo } from "react";
import styled from "styled-components";
import { theme } from "uikit";

const RootBase = styled.div<Root.Props>`
	width: 100%;
	height: 43px;
	gap: 10px;

	display: flex;
	align-items: center;
	padding-inline: 20px;

	cursor: pointer;

	background-color: ${({ active }) =>
		active ? theme.colors.button_secondary : "white"};

	&:hover {
		background-color: ${({ active }) =>
			active
				? theme.colors.button_secondary_hover
				: theme.colors.button_secondary};
	}
`;

const Root = memo(RootBase);

declare namespace Root {
	interface Props {
		active?: boolean;
	}
}

export default Root;
