import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent } from "uikit";

import Root from "./components/Root";
import Label from "./components/Label";

const SelectAll: React.FC<SelectAll.Props> = (props) => {
	const { t } = useTranslation();
	return (
		<Root {...props}>
			<Label>
				{t("columnSetupModal.selector.header.selectAll.str100") ?? ""}
			</Label>
		</Root>
	);
};

declare namespace SelectAll {
	interface PropsBase {
		value: boolean;

		onChange: Dispatch<boolean>;
	}

	type Props = PropsBase & Omit<CheckBoxWithContent.Props, "children">;
}

export default SelectAll;
