/* eslint-disable no-shadow */

import React, { Dispatch, Key, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, MultiSelect, Row, theme, Option } from "uikit";

import Language from "../../../../../../services/Language";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import { getStatuses } from "../../../../../../utils";
import CompanyAndTaxiServiceSelect from "../../../../../CompanyAndTaxiServiceSelect";
import SearchIconBorders from "../../../../../SearchIconBorders";
import SearchTextBox from "../../../../../SearchTextBox";

const Filters = memo<Filters.Props>(
	({ value, allowedTaxiServiceIds, language, onChange }) => {
		const { t } = useTranslation();

		const statuses = useMemo(() => getStatuses(t), [t]);

		const valueEditor = useObjectEditor(value, onChange);

		const CTSSValue = valueEditor.usePicker([
			"companyIds",
			"taxiServiceIds",
		]);

		const CTSSOnChange = valueEditor.useAssigner();

		const statusesOnChange = valueEditor.useSetter("statuses");

		const searchTextBoxOnChange = valueEditor.useSetter("search");

		return (
			<Row align="center" justify="space-between" gaps="10px" maxedWidth>
				<Row sizes="200px!*2" gaps="12px" align="center">
					<CompanyAndTaxiServiceSelect
						value={CTSSValue}
						language={language}
						allowedTaxiServiceIds={allowedTaxiServiceIds}
						onChange={CTSSOnChange}
					/>
				</Row>
				<Row sizes="200px! 270px!" gaps="10px*" align="center">
					<MultiSelect
						all
						options={statuses}
						value={valueEditor.get("statuses")}
						onChange={statusesOnChange as (values: Key[]) => void}
					/>
					<InputGroup.InputGroup sizes="auto 1fr">
						<SearchIconBorders>
							<Icon
								id="search2"
								size={16}
								colors={[theme.colors.disabled_text]}
							/>
						</SearchIconBorders>
						<SearchTextBox
							placeholder={`${
								t(
									"dispatcherSelectTab.header.filters.str200",
								) ?? ""
							}...`}
							value={valueEditor.get("search")}
							onChange={searchTextBoxOnChange}
						/>
					</InputGroup.InputGroup>
				</Row>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
		statuses: any;
		search: string;
	}

	interface Props {
		value: Value;

		allowedTaxiServiceIds: number[];
		language: Language;

		onChange: Dispatch<Value>;
	}
}

export default Filters;
