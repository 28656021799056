import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 330px;
	height: 360px;

	border-radius: 4px;

	background-color: #f5f5f5;

	overflow: hidden auto;

	${theme.scrollbar}
`;

export default Root;
