import * as ModelEvent from "@node-elion/syncron";
import { cloneDeep } from "lodash";
import { getPRPC } from "../../../../../hooks/usePRPC";
import createRPCQuery from "../../../../../utils/createRPCQuery.util";
import messageProviderSms from "../../../../reducers/SettingsPage/Message/ProviderSms";

const controllers: any[] = [];
let blocked = false;

interface Options {
	taxiServiceIds?: number[];
	limit?: number;
	offset?: number;
}

export default function subscribeProviderSms(options: Options = {}) {
	return async (dispatch) => {
		if (blocked) return null;

		const prpcow = getPRPC();

		if (!prpcow) return null;

		blocked = true;

		if (controllers.length) {
			await Promise.all(
				controllers.map((controller) => controller.unsubscribe()),
			);
			controllers.splice(0, controllers.length);
		}
		const constructor = new ModelEvent.ModelEventConstructor({
			onUpdate(state) {
				const items = state.models;
				dispatch(
					messageProviderSms.actions.setData(cloneDeep(items) as any),
				);
			},
		});

		const controller = await createRPCQuery(
			() =>
				prpcow.theirsModel.sms.provider.subscribe({
					params: {
						...options,
					},
					ping: () => true,
					onEvent: (event) => {
						constructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ verbose: true, name: "subscribeProviderSms" },
		);

		controllers.push(controller);

		return null;
	};
}
