import React, { useMemo, memo, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../services/Language";
import { useTypedSelector } from "../../../redux/store";
import { StyledP, StyledRow } from "../styles";

const CarClassesByIdsBase: React.FC<CarClassesByIdsBase.Props> = ({
	carClassIds = [],
	taxiServiceIds = [],
	lang,
	onSize,
}) => {
	const { t } = useTranslation();

	const { models } = useTypedSelector(
		(state) => state.preferencesReducer.carClasses.data,
	);

	const ref = useRef<HTMLDivElement | null>(null);

	const texts = useMemo(() => {
		const all = t("filter.options.all");
		const inactive = t("filter.options.inactive");

		return {
			allNotActive: `${all} ${inactive}`,
			allText: t(["all_cars"]),
			allActive: t(["all_active_cars"]),
		};
	}, [t]);

	useLayoutEffect(() => {
		const height = ref?.current?.offsetHeight;
		if (height !== undefined) onSize?.(height);
	}, [onSize, ref?.current?.offsetHeight]);

	const carClasses = useMemo(() => {
		if (!taxiServiceIds?.length) return models;
		return models.filter((item) =>
			item.taxiServiceIds?.some((id) => taxiServiceIds?.includes(id)),
		);
	}, [taxiServiceIds, models]);

	const data = useMemo(() => {
		if (!carClasses.length) return [];
		const actives = [...carClasses].filter((item) => item.active === true);
		const notActives = [...carClasses].filter(
			(item) => item.active === false,
		);

		const isAllActive = actives.every((item) =>
			carClassIds.includes(item.id),
		);
		const isNotAllActive = notActives.every((item) =>
			carClassIds.includes(item.id),
		);

		const addAllActiveText =
			actives.length && isAllActive ? texts.allActive : "";
		const addAllNotActiveText =
			notActives.length && isNotAllActive ? texts.allNotActive : "";

		if (addAllActiveText && !addAllNotActiveText) {
			const values = [...carClasses]
				?.filter(
					(carClass) =>
						carClassIds.includes(carClass?.id) &&
						carClass.active === false,
				)
				.map(({ name }) => name?.[lang] || "");

			return [addAllActiveText, ...values];
		}

		if (!addAllActiveText && addAllNotActiveText) {
			const values = [...carClasses]
				?.filter(
					(carClass) =>
						carClassIds.includes(carClass?.id) &&
						carClass.active === true,
				)
				.map(({ name }) => name?.[lang] || "");
			return [addAllNotActiveText, ...values];
		}

		if (
			addAllActiveText &&
			addAllNotActiveText &&
			actives?.length &&
			notActives?.length
		) {
			return [texts.allText || ""];
		}

		return [...carClasses]
			?.filter((carClass) => carClassIds.includes(carClass?.id))
			.map(({ name }) => name?.[lang] || "");
	}, [
		carClasses,
		texts.allActive,
		texts.allNotActive,
		texts.allText,
		carClassIds,
		lang,
	]);

	if (!carClassIds.length) return <StyledRow></StyledRow>;
	if (carClassIds.length === [...carClasses].length) {
		return (
			<StyledRow gap="0 8px">
				<StyledP>{texts.allText}</StyledP>
			</StyledRow>
		);
	}

	return (
		<StyledRow
			ref={ref}
			gap="0 8px"
			flex={onSize ? { wrap: "wrap" } : undefined}
			w={onSize ? { min: "200px" } : ""}
		>
			{data.length === 1 && <StyledP>{data[0]}</StyledP>}

			{data.length > 1 &&
				data.map((item, i, arr) => (
					<StyledP key={item}>{`${item}${
						arr?.[i + 1] ? "," : ""
					}`}</StyledP>
				))}
		</StyledRow>
	);
};

export const CarClassesByIds = memo(CarClassesByIdsBase);
declare namespace CarClassesByIdsBase {
	interface Props {
		taxiServiceIds?: number[];
		carClassIds: number[];
		lang: Language;
		onSize?: (value: number) => void;
	}
}

export default CarClassesByIdsBase;
