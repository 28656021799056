import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, useInternal } from "uikit";
import styled from "styled-components";

import SearchType from "../../types/SearchType";
import useObjectEditor from "../../hooks/useObjectEditor";
import { ButtonBase, StyledRow } from "../common";

export const Button = styled(ButtonBase)`
	width: clamp(29px, 3.9vh, 32px);
	height: clamp(29px, 3.9vh, 32px);
`;
const SearchFilter: React.FC<SearchFilter.Props> = ({
	activeSearchTypes,
	setSearchType,
	disabled = false,
}) => {
	const { t } = useTranslation();
	const [active, setActive] = useInternal<SearchFilter.Value>({
		street: false,
		object: false,
		localObject: false,
	});

	const internalValueEditor = useObjectEditor<SearchFilter.Value>(
		active,
		setActive,
	);

	const streetActiveValue = internalValueEditor.useGetter("street");
	const streetValueOnChange = internalValueEditor.useSetter("street");
	const objectActiveValue = internalValueEditor.useGetter("object");
	const objectOnChange = internalValueEditor.useSetter("object");
	const localObjectActiveValue = internalValueEditor.useGetter("localObject");
	const localObjectOnChange = internalValueEditor.useSetter("localObject");

	const handleChangeSearchType = useCallback(
		(type: SearchType) => {
			const isActive = activeSearchTypes?.includes(type);

			setSearchType(
				isActive
					? activeSearchTypes.filter((item) => item !== type)
					: [...activeSearchTypes, type],
			);
		},
		[activeSearchTypes, setSearchType],
	);

	const addActive = useCallback((): void => {
		if (activeSearchTypes.includes("street")) {
			streetValueOnChange(true);
		} else streetValueOnChange(false);

		if (activeSearchTypes.includes("object")) {
			objectOnChange(true);
		} else objectOnChange(false);

		if (activeSearchTypes.includes("localObject")) {
			localObjectOnChange(true);
		} else localObjectOnChange(false);
	}, [
		activeSearchTypes,
		localObjectOnChange,
		objectOnChange,
		streetValueOnChange,
	]);

	useMemo(() => {
		addActive();
	}, [addActive]);

	return (
		<StyledRow gap="5px">
			<Button
				disabled={disabled}
				variant="secondary"
				active={streetActiveValue}
				settingsKeyId="orderModalSearchFilterStreet"
				title={t("searchFilter.str0") ?? ""}
				onClick={() => handleChangeSearchType("street")}
			>
				<Icon id="order-modal-search-street" size={20} />
			</Button>
			<Button
				disabled={disabled}
				active={localObjectActiveValue}
				variant="secondary"
				settingsKeyId="orderModalSearchFilterLocalObjects"
				onClick={() => handleChangeSearchType("localObject")}
				title={t("searchFilter.str1") ?? ""}
			>
				<Icon id="order-modal-search-localObject" size={20} />
			</Button>

			<Button
				disabled={disabled}
				active={objectActiveValue}
				variant="secondary"
				settingsKeyId="orderModalSearchFilterObjects"
				onClick={() => handleChangeSearchType("object")}
				title={t("searchFilter.str2") ?? ""}
			>
				<Icon id="order-modal-search-object" size={22} />
			</Button>
		</StyledRow>
	);
};

declare namespace SearchFilter {
	interface Props extends PropsWithChildren {
		activeSearchTypes: SearchType[];
		setSearchType: (types: SearchType[]) => void;
		disabled?: boolean;
	}

	interface Value {
		street: boolean;
		object: boolean;
		localObject: boolean;
	}
}

export default SearchFilter;
