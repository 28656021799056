import constants from "../../constants/Preferences/rates";

/* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *\
|                                                                       |
|   TODO: Delete this reducer and replace with the model subscription   |
|                                                                       |
\* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** */

const initialState = {
	rates: [],
};
export default function rates(state = initialState, action: any = {}) {
	switch (action.type) {
		case constants.setRates:
			return {
				...state,
				rates: action.payload,
			};
		default:
			return state;
	}
}
