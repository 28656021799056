import styled from "styled-components";
import { Flex } from "uikit";

const Root = styled(Flex)<Root.Props>`
	background-color: ${({ dimmed }) =>
		dimmed ? `rgba(0, 0, 0, .5)` : dimmed || "transparent"};
	pointer-events: ${({ pointerEvents }) => pointerEvents || "auto"};
`;

declare namespace Root {
	interface Props {
		dimmed: boolean;
		pointerEvents:
			| "none"
			| "auto"
			| "visiblePainted"
			| "visibleFill"
			| "visibleStroke"
			| "painted"
			| "fill"
			| "stroke";
	}
}

export default Root;
