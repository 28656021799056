import styled from "styled-components";

const AdditionalPriceLabel = styled.span`
	font-weight: 400;
	font-size: 12px;
	line-height: 14.4px;

	color: rgb(76, 175, 80);
`;

export default AdditionalPriceLabel;
