import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";

import driversDataMocks from "./components/mocks";
import ModalShift from "./components/ModalShift";
import ModalWorkHistory from "./components/ModalWorkHistory";
import Header from "./components/Header";
import Content from "./components/Content";

const ArchivalShifts = () => {
	const { t } = useTranslation();
	const globalLang = useTypedSelector((state) => state.session.language);

	const [sort, setSort] = useState<Content.Sort>({});
	const [showShiftModal, setShowShiftModal] = useState<boolean>(false);
	const [showWorkHistoryModal, setShowWorkHistoryModal] =
		useState<boolean>(false);

	const openShiftModal = () => {
		setShowShiftModal(true);
	};

	const closeShiftModal = () => {
		setShowShiftModal(false);
	};

	const openWorkHistoryModal = () => {
		setShowWorkHistoryModal(true);
	};

	const closeWorkHistoryModal = () => {
		setShowWorkHistoryModal(false);
	};

	// Archived shifts
	return (
		<Column sizes="auto! 1fr" maxedWidth maxedHeight>
			{showWorkHistoryModal && (
				<ModalWorkHistory
					language={globalLang}
					onSave={() => ""}
					onCancel={closeWorkHistoryModal}
					value={{
						headerTitle: `${
							t(
								"pages.mainPage.pages.archives.tabs.driverShifts.tabs.archivalShifts.str200",
							) ?? ""
						} 123 - Anton Ivanov`, // ! WARNING - this mock data
						notes: "",
						createdAt: "",
					}}
				/>
			)}
			<Header
				onSearch={() => ""}
				openWorkHistoryModal={openWorkHistoryModal}
			/>
			<Content
				data={driversDataMocks}
				language={globalLang}
				loading={false}
				openShiftModal={openShiftModal}
				onChangeSelected={() => ""}
				onChangeSort={() => ""}
				onEdit={() => ""}
				onLoadMore={() => ""}
				selected={[1]}
				sort={sort}
			/>
			{showShiftModal && (
				<ModalShift
					closeShiftModal={closeShiftModal}
					data={driversDataMocks[0]}
				/>
			)}
		</Column>
	);
};

export default ArchivalShifts;
