import React, { memo } from "react";
import { Row } from "uikit";

import Header from "../../../../../../../../components/BasicPageLayout/Header";

import { LeftPartMemo, RightPartMemo, CenterPartMemo } from "./components";

const ArchiveHeaderOrders: React.FC<
	ArchiveHeaderOrders.Props
> = (): JSX.Element => (
	<Header>
		<Row maxedWidth gaps="5px*" justify="space-between" align="center">
			<LeftPartMemo />
			<Row
				gaps="25px*"
				justify="end"
				align="center"
				style={{ flexGrow: 1 }}
			>
				<CenterPartMemo />
				<RightPartMemo />
			</Row>
		</Row>
	</Header>
);

declare namespace ArchiveHeaderOrders {
	interface Props {}
}
export const ArchiveHeaderOrdersMemo = memo(ArchiveHeaderOrders);
export default ArchiveHeaderOrders;
