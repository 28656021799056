import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)`
	padding: 8px 12px;

	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

	z-index: 1;
`;

export default Root;
