import React, { Dispatch, Key, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Option, Row, Select } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import DefaultPageHeaderButton from "../../../../../../../../components/DefaultPageHeaderButton";
import DefaultPageHeader from "../../../../../../../../components/DefaultPageHeader";
import SearchBar from "../../../../../../../../components/SearchBar";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import Filters from "./components/Filters";

const HeaderBase: React.FC<Header.Props> = ({
	value,

	canDelete = true,

	onChange,

	onAdd,
	onDelete,
}) => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_TAB =
			ARR_ACCESS_PART_KEY[TabKeys.SETTINGS_CAR_MODELS_AND_BRANDS];

		const retval = {
			add: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.CONTROL_BUTTON_ADD),
				personalRole,
			),
			delete: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.CONTROL_BUTTON_DELETE,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const valueEditor = useObjectEditor(value, onChange);

	const searchValue = valueEditor.useGetter("search");
	const searchOnChange = valueEditor.useSetter("search");

	const searchTypeValue = valueEditor.useGetter("searchType");
	const searchTypeOnChange = valueEditor.useSetter("searchType");

	const otherFiltersValue = valueEditor.usePicker([
		"active",
		"favorite",
		"native",
	]);
	const assignValue = valueEditor.useAssigner();

	const searchTypeOptions: Option<Header.SearchType>[] = useMemo(
		() => [
			{
				key: "car-base-type",
				label:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.str200",
					) ?? "",
				value: "car-base-type",
			},
			{
				key: "car-brand",
				label:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.str201",
					) ?? "",
				value: "car-brand",
			},
			{
				key: "car-model",
				label:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.str202",
					) ?? "",
				value: "car-model",
			},
			{
				key: "car-body-type",
				label:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.str203",
					) ?? "",
				value: "car-body-type",
			},
			{
				key: "car-class",
				label:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.str204",
					) ?? "",
				value: "car-class",
			},
		],
		[t],
	);

	return (
		<DefaultPageHeader
			buttons={
				<>
					{AccessBtnGroup.add && (
						<DefaultPageHeaderButton
							variant="primary"
							onClick={onAdd}
							icon={<Icon id="plus" size={16} />}
						/>
					)}

					{AccessBtnGroup.delete && (
						<DefaultPageHeaderButton
							disabled={!canDelete}
							variant="secondary"
							icon={<Icon id="trash" size={20} />}
							onClick={onDelete}
						/>
					)}
				</>
			}
			filters={
				<Row align="center" sizes="270px! 170px! auto!" gaps="10px*">
					<SearchBar value={searchValue} onChange={searchOnChange} />
					<Select
						value={searchTypeValue}
						options={searchTypeOptions}
						onChange={searchTypeOnChange as Dispatch<Key>}
					/>
					<Filters value={otherFiltersValue} onChange={assignValue} />
				</Row>
			}
		/>
	);
};

const Header = memo(HeaderBase);

declare namespace Header {
	type SearchType =
		| "car-base-type"
		| "car-brand"
		| "car-model"
		| "car-body-type"
		| "car-class";

	interface Value {
		search: string;
		searchType: SearchType;
		active?: boolean;
		favorite?: boolean;
		native?: boolean;
	}

	interface Props {
		value: Value;

		canDelete?: boolean;

		onChange: Dispatch<Value>;

		onAdd?: VoidFunction;
		onDelete?: VoidFunction;
	}
}

export default Header;
