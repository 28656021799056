import { Dispatch } from "redux";

import { getPRPC, setPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import session from "../reducers/session";
import account from "../reducers/account";
import prpc from "../reducers/prpc";
import { RootState } from "../store";

import Account from "./Account";
import SubscriptionPool from "./SubscriptionPool";
import { prpcow } from "./base";

export default class Session {
	static create(token?: string) {
		return async (dispatch: Dispatch<any>, getState: () => RootState) => {
			const {
				prpc: { connected, token: currentToken },
			} = getState();

			if (connected && token === currentToken) return;

			dispatch(prpc.actions.setConnected(false));
			dispatch(prpc.actions.setAuthorized(false));

			prpcow(
				(connection, isReconnect) => {
					// eslint-disable-next-line no-console
					console.log("[Session] Session initialized");

					const sessionToken = connection.session.protocol;

					setPRPC(connection);

					dispatch(prpc.actions.setConnected(true));

					if (connection.theirsModel.auth?.dispatcher?.login) {
						dispatch(prpc.actions.setAuthorized(false));

						SubscriptionPool.clear();
					} else {
						dispatch(prpc.actions.setAuthorized(true));
						dispatch(prpc.actions.setToken(sessionToken));

						if (isReconnect) {
							SubscriptionPool.onReconnect(connection);
						}

						const tokenData = getState().session.token;

						if (tokenData) {
							dispatch(Account.me(tokenData.userId));
						}
					}
				},
				() => {
					// eslint-disable-next-line no-console
					console.log("[Session] Session disconnected");

					dispatch(prpc.actions.setConnected(false));
				},
				token,
			);
		};
	}

	static login(username: string, password: string) {
		return async (dispatch: Dispatch<any>) => {
			const prpcSession = getPRPC();

			if (!prpcSession) return;

			const result = await createRPCQuery(() =>
				prpcSession.theirsModel.auth.dispatcher.login({
					login: username,
					password,
				}),
			);

			const token = {
				value: result.token,
				type: result.type,
				userId: result.id,
			};

			dispatch(prpc.actions.setAuthorized(true));
			dispatch(prpc.actions.setToken(token.value));
			dispatch(session.actions.setToken(token));
			dispatch(Account.me(token.userId));
		};
	}

	static logout() {
		return (dispatch: Dispatch) => {
			const prpcSession = getPRPC();
			dispatch(session.actions.setToken(undefined));
			dispatch(account.actions.setUser(undefined));
			dispatch(prpc.actions.setAuthorized(false));
			prpcSession?.closeRequest();
		};
	}
}
