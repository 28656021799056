/* eslint-disable no-shadow */
import React, { RefAttributes, useMemo, Dispatch, SetStateAction } from "react";
import { react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../services/Language";
import {
	useObjectEditor,
	useTaxiServiceIdsDecoder,
} from "../../../../../../../../../../hooks";
import {
	ExecutorSelectTab,
	DispatcherSelectTab,
} from "../../../../../../../../../../components";
import { DisplayFields } from "../../../../../../../../../../constants/access";

import { Root, MainTab } from "./components";
import InternalController from "./Controller";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, type, disabled, language, onChange }) => {
		// const [mainTabRef, setMainTabRef] =
		// 	useRefWithSetter<MainTab.Ref | null>(null);

		// controller.setContext({ mainTabRef });

		// const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

		// const valueEditor = useObjectEditor(value, onChange);

		// const companyIds = valueEditor.useGetter("companyIds");
		// const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");

		// const decodedTaxiServiceIds = useMemo(
		// 	() => decodeTaxiServiceIds(companyIds, taxiServiceIds),
		// 	[companyIds, decodeTaxiServiceIds, taxiServiceIds],
		// );

		// const mainTabValue = valueEditor.usePicker([
		// 	"name",
		// 	"assignableTo",
		// 	"default",
		// 	"companyIds",
		// 	"taxiServiceIds",
		// 	"description",
		// 	"executorIds",
		// 	"dispatcherIds",
		// 	"serviceIds",
		// ]);

		// const userSelectTabValue = valueEditor.usePicker(["dispatcherIds"]);

		// const executorSelectTabValue = valueEditor.usePicker(["executorIds"]);

		// const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						// ref={setMainTabRef}
						value={value}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						// onChange={anyTabOnChange}
					/>
				</>
			),
			[disabled, language, type, value],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type = "main";

	type Value = any;

	interface PropsBase {
		value: Value;

		type: Type;
		disabled: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
