import styled from "styled-components";

const Title = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	user-select: none;
`;

export default Title;
