import Agent from ".";

type Value = Agent.Model["additionalFields"] | undefined;

const additionalFieldsToRequest = (data: Value): Partial<Value> | null => {
	if (!data) return null;

	const output: Partial<Value> = {};
	output.contactPersonName = data.contactPersonName || "";
	output.contactPersonPhone = data.contactPersonPhone || "";
	output.contactPersonEmail = data.contactPersonEmail || "";
	output.notes = data.notes || "";
	output.orderNotes = data.orderNotes || "";
	output.executorNotes = data.executorNotes || "";

	return Object.keys(output).length !== 0 ? output : null;
};

export default additionalFieldsToRequest;
