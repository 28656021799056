import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Option, Select, react } from "uikit";
import { isUndefined } from "lodash";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const Content = react.inputify<Content.PropsBase, Content.Value>(
	({
		value,

		onChange,
	}) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value ?? {}, onChange);

		const favoriteValue = valueEditor.useGetter("favorite");
		const favoriteOnChange = valueEditor.useSetter("favorite");

		const activeValue = valueEditor.useGetter("active");
		const activeOnChange = valueEditor.useSetter("active");

		const nativeValue = valueEditor.useGetter("native");
		const nativeOnChange = valueEditor.useSetter("native");

		const favoriteSelectValue = useMemo(() => {
			if (isUndefined(favoriteValue)) return "all";

			return favoriteValue ? "favorite" : "not-favorite";
		}, [favoriteValue]);

		const favoriteSelectOnSelect = useCallback(
			(option: Option<boolean | undefined>) => {
				favoriteOnChange(option.value);
			},
			[favoriteOnChange],
		);

		const activeSelectValue = useMemo(() => {
			if (isUndefined(activeValue)) return "all";

			return activeValue ? "active" : "not-active";
		}, [activeValue]);

		const activeSelectOnSelect = useCallback(
			(option: Option<boolean | undefined>) => {
				activeOnChange(option.value);
			},
			[activeOnChange],
		);

		const nativeSelectValue = useMemo(() => {
			if (isUndefined(nativeValue)) return "all";

			return nativeValue ? "native" : "created";
		}, [nativeValue]);

		const nativeSelectOnSelect = useCallback(
			(option: Option<boolean | undefined>) => {
				nativeOnChange(option.value);
			},
			[nativeOnChange],
		);

		const favoriteSelectOptions: Option<boolean | undefined>[] = useMemo(
			() => [
				{
					key: "all",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str206",
						) ?? "",
					value: undefined,
				},
				{
					key: "favorite",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str201",
						) ?? "",
					value: true,
				},
				{
					key: "not-favorite",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str202",
						) ?? "",
					value: false,
				},
			],
			[t],
		);

		const activeSelectOptions: Option<boolean | undefined>[] = useMemo(
			() => [
				{
					key: "all",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str206",
						) ?? "",
					value: undefined,
				},
				{
					key: "active",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str204",
						) ?? "",
					value: true,
				},
				{
					key: "not-active",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str205",
						) ?? "",
					value: false,
				},
			],
			[t],
		);

		const nativeSelectOptions: Option<boolean | undefined>[] = useMemo(
			() => [
				{
					key: "all",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str206",
						) ?? "",
					value: undefined,
				},
				{
					key: "native",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str207",
						) ?? "",
					value: true,
				},
				{
					key: "created",
					label:
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str208",
						) ?? "",
					value: false,
				},
			],
			[t],
		);

		const translateTexts: Record<string, string> = useMemo(
			() => ({
				text0:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str209",
					) ?? "",
				text1:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str210",
					) ?? "",
				text2:
					t(
						"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.content.str211",
					) ?? "",
			}),
			[t],
		);

		return (
			<Column gaps="12px*">
				<LabeledField label={translateTexts.text0}>
					<Select
						value={favoriteSelectValue}
						options={favoriteSelectOptions}
						onSelect={favoriteSelectOnSelect}
					/>
				</LabeledField>
				<LabeledField label={translateTexts.text1}>
					<Select
						value={activeSelectValue}
						options={activeSelectOptions}
						onSelect={activeSelectOnSelect}
					/>
				</LabeledField>
				<LabeledField label={translateTexts.text2}>
					<Select
						value={nativeSelectValue}
						options={nativeSelectOptions}
						onSelect={nativeSelectOnSelect}
					/>
				</LabeledField>
			</Column>
		);
	},
);

declare namespace Content {
	interface Value {
		active?: boolean;
		favorite?: boolean;
		native?: boolean;
	}

	export interface PropsBase {}
}

export default Content;
