import React, { memo } from "react";
import { Stepper } from "uikit";

/** @deprecated */
const ValueStepper: React.FC<ValueStepper.Props> = memo(
	({ width, disabled, decimalCount, value, onChange, ...props }) => (
		<Stepper
			{...props}
			disabled={disabled}
			decimalCount={decimalCount || 2}
			width={width || "90px"}
			value={value || 0}
			onChange={onChange}
		/>
	),
);

declare namespace ValueStepper {
	interface Props extends Stepper.Props {
		disabled?: boolean;
		value?: number;
		decimalCount?: number;
		onChange?: (val: any) => void;
		width?: string;
	}
}

export default ValueStepper;
