/* eslint-disable react/require-default-props */

import React from "react";
import styled from "styled-components";
import { Column, Row, theme } from "uikit";

import AutoBreadcrumbs from "../AutoBreadcrumbs";

import Header from "./Header";

const StyledRoot = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${theme.colors.button_secondary};
	flex-grow: 1;
	overflow: hidden;
`;

interface Props {
	customBreadcrumbs?: React.ReactNode;
	header?: React.ReactNode;
	navigation: React.ReactNode;
	content: React.ReactNode;
}

const BasicPageLayout: React.FC<Props> = ({
	customBreadcrumbs: breadcrumbs,
	header,
	navigation,
	content,
}) => (
	<StyledRoot>
		<Row
			align="center"
			style={{
				height: "32px",
				paddingInline: "30px",
				overflow: "hidden",
				flexShrink: 0,
			}}
		>
			{breadcrumbs || <AutoBreadcrumbs />}
		</Row>

		<Row
			style={{
				width: "100%",
				flexGrow: 1,
				overflow: "hidden",
			}}
		>
			<Column
				style={{
					padding: "20px",
					overflow: "hidden",
				}}
			>
				{navigation}
			</Column>

			<Column
				style={{
					flexGrow: 1,
					background: "white",
					boxShadow: `-1px -1px 4px rgba(0, 0, 0, 0.1)`,
					borderRadius: `10px 0px 0px 0px`,
					overflow: "hidden",
				}}
			>
				{header && <Header>{header}</Header>}
				{content}
			</Column>
		</Row>
	</StyledRoot>
);

export default BasicPageLayout;
