import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "uikit";

import CallService from "../../../../../../../../services/Call";

import Call from "./components/Call";
import timestampToDate from "./components/helper";

const Title = styled("div")`
	padding: 5px 12px;

	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;

	background-color: ${theme.colors.button_secondary};
	cursor: default;
`;

const DayInJournal: React.FC<DayInJournal.Props> = ({ data }) => {
	const { t } = useTranslation();

	const time = useMemo(
		() => timestampToDate(t, data[data.length - 1]?.createdAt),
		[data, t],
	);
	return (
		<div>
			<Title>{time}</Title>
			{data.map((data) => (
				<Call key={data.id} data={data} />
			))}
		</div>
	);
};

declare namespace DayInJournal {
	interface Props {
		data: CallService.Model[];
	}
}
export default DayInJournal;
