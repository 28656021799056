import { Tabs } from "uikit";
import { TFunction } from "i18next";

interface TabOption<Value extends string> extends Tabs.Option<Value> {
	key: Value;
	label: string;
	value: Value;
	disabled?: boolean;
}

function makeCreationTypes<Value extends string>(
	creationTypes: TabOption<Value>[],
): TabOption<Value>[] {
	return creationTypes;
}

const tPath = "pages.preferencesPages.screenDirectory.objects.editModal.";

const _creationTypes = makeCreationTypes([
	{
		key: "object",
		label: `${tPath}creationTypes.object`,
		value: "object",
	},
	{
		key: "object-group",
		disabled: true,
		label: `${tPath}creationTypes.objectGroup`,
		value: "object-group",
	},
]);

const getCreationTypes = (t: TFunction): TabOption<string>[] =>
	makeCreationTypes([
		{
			key: "object",
			label: t(`${tPath}creationTypes.object`) ?? "",
			value: "object",
		},
		{
			key: "object-group",
			disabled: true,
			label: t(`${tPath}creationTypes.objectGroup`) ?? "",
			value: "object-group",
		},
	]);

const getEditObject = (t: TFunction): TabOption<string>[] => [
	{
		key: "object",
		label: t(`${tPath}editObject.object`) ?? "",
		value: "object",
	},
];

const getEditObjectGroup = (t: TFunction): TabOption<string>[] => [
	{
		key: "object-group",
		disabled: true,
		label: t(`${tPath}editObjectGroup.objectGroup`) ?? "",
		value: "object-group",
	},
];

export { _creationTypes, getEditObjectGroup, getEditObject, getCreationTypes };
