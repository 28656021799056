import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Icon } from "uikit";

import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import Divider from "../../../../Divider";

const ControlButtons = memo(
	({ onCenter, onZoomIn, onZoomOut }: ControlButtons.Props) => {
		const { t } = useTranslation();
		return (
			<Column gaps="4px*">
				<ButtonGroup gaps="1px*">
					<Button
						title={
							t("orderPageWidgets.map2.controlButtons.str0") ?? ""
						}
						tabIndex={-1}
						onClick={onCenter}
					>
						<Icon id="mapCenter" size={20} />
					</Button>
				</ButtonGroup>
				<ButtonGroup gaps="1px*">
					<Button
						title={
							t("orderPageWidgets.map2.controlButtons.str1") ?? ""
						}
						tabIndex={-1}
						onClick={onZoomIn}
					>
						<Icon id="mapPlus" size={20} />
					</Button>
					<Divider
						orientation="horizontal"
						size="max"
						thickness={1}
						color="#ebebeb"
					/>
					<Button
						title={
							t("orderPageWidgets.map2.controlButtons.str2") ?? ""
						}
						tabIndex={-1}
						onClick={onZoomOut}
					>
						<Icon id="mapMinus" size={20} />
					</Button>
				</ButtonGroup>
			</Column>
		);
	},
);

declare namespace ControlButtons {
	interface Props {
		onCenter: () => void;

		onZoomIn: () => void;
		onZoomOut: () => void;
	}
}

export default ControlButtons;
