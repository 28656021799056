import React from "react";
import { Icon, theme } from "uikit";

import { StyledP, StyledRow } from "../../../common";

const Button: React.FC<Button.Props> = ({ icon, label, onClick }) => (
	<StyledRow alignItems="center" gap="5px" cursor="pointer" onClick={onClick}>
		<Icon id={icon} size={12} colors={[theme.colors.secondary]} />
		<StyledP colors={theme.colors.secondary}>{label}</StyledP>
	</StyledRow>
);

declare namespace Button {
	interface Props {
		icon: Icon.Props["id"];
		label: string;

		onClick?: () => void;
	}
}

export default Button;
