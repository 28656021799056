import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Icon, Nullable, Row, theme } from "uikit";
import { useDispatch } from "react-redux";

import softphoneWidget, {
	CallType,
	TransferType,
} from "../../../../../../redux/reducers/softphoneWidget";
import { useTypedSelector } from "../../../../../../redux/store";
import { DispatcherPhone, ExecutorPhone } from "../../types";
import Root from "./components/Root";
import Keyboard from "./components/Keyboard";

const KeyboardButton = styled(Button.Button)<{ active?: boolean }>`
	min-width: 40px;
	height: 40px;

	border-radius: 8px;
	background-color: ${({ active }) =>
		active ? "#E3F1F7" : theme.colors.transparent};

	&: hover {
		background-color: ${({ active }) =>
			active ? "#E3F1F7" : theme.colors.transparent};
	}
`;

const TransferButton = styled(Button.Button)`
	height: 40px;
	width: 100%;

	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	background-color: #4caf50;

	&:hover {
		background-color: #4caf50;
	}
`;

const ButtonsContainer = styled(Row)`
	padding: 8px 0 12px;

	width: 100%;

	position: relative;

	justify-content: center;
`;

interface Props {
	selectedPhone: Nullable<DispatcherPhone | ExecutorPhone>;
	type: TransferType;
	onCall: (callType: CallType) => Promise<null>;
}

const FooterButtons: React.FC<Props> = ({ selectedPhone, type, onCall }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		transferNumberQuery,
		showTransferKeyboard,
		transferTab,
		isShowTransferSelector,
	} = useTypedSelector((state) => state.softphoneWidget);

	const toggleKeyboard = useCallback(() => {
		dispatch(
			softphoneWidget.actions.setShowTransferKeyboard(
				!showTransferKeyboard,
			),
		);
	}, [dispatch, showTransferKeyboard]);

	const transferButtonValidation = useMemo(
		() =>
			!selectedPhone?.id ||
			(!transferNumberQuery && !selectedPhone?.phone),
		[selectedPhone?.id, selectedPhone?.phone, transferNumberQuery],
	);

	const transferSipsValidation = useMemo(
		() =>
			!selectedPhone?.id ||
			!selectedPhone?.dispatchersAvailableSips?.length,
		[selectedPhone?.dispatchersAvailableSips?.length, selectedPhone?.id],
	);
	const transferPhoneValidation = useMemo(
		() => !selectedPhone?.id || !selectedPhone?.phone,
		[selectedPhone?.id, selectedPhone?.phone],
	);

	const callOnNumber = useCallback(() => {
		onCall("onNumber");
	}, [onCall]);

	const showTransferSipSelector = useCallback(() => {
		dispatch(
			softphoneWidget.actions.setIsShowTransferSelector(
				!isShowTransferSelector,
			),
		);
	}, [dispatch, isShowTransferSelector]);

	return (
		<Root
			reduce={showTransferKeyboard}
			gaps={showTransferKeyboard ? "0px 8px" : "8px"}
		>
			{showTransferKeyboard && <Keyboard query={transferNumberQuery} />}
			<ButtonsContainer gaps="8px*">
				<KeyboardButton
					onClick={toggleKeyboard}
					active={showTransferKeyboard}
					icon={
						<Icon
							id="phone-keyboard"
							size={24}
							colors={[
								showTransferKeyboard
									? theme.colors.accent
									: theme.colors.secondary,
							]}
						/>
					}
				/>
				{transferTab === "dispatchers" && (
					<>
						{transferNumberQuery ? (
							<TransferButton
								disabled={transferButtonValidation}
								onClick={callOnNumber}
								icon={
									<Icon
										id={
											type === "normal"
												? "phone-redirection"
												: "phone-redirection-anonymous"
										}
										size={22}
										colors={[
											transferButtonValidation
												? theme.colors.disabled_text
												: theme.colors.white,
										]}
									/>
								}
							/>
						) : (
							<>
								<TransferButton
									disabled={transferSipsValidation}
									onClick={showTransferSipSelector}
									text="Sip"
								/>
								<TransferButton
									disabled={transferPhoneValidation}
									onClick={callOnNumber}
									text={
										t(
											"softphoneWidget.transfer.footerButtons.str0",
										) ?? ""
									}
								/>
							</>
						)}
					</>
				)}
				{transferTab !== "dispatchers" && (
					<TransferButton
						disabled={transferButtonValidation}
						onClick={callOnNumber}
						icon={
							<Icon
								id={
									type === "normal"
										? "phone-redirection"
										: "phone-redirection-anonymous"
								}
								size={22}
								colors={[
									transferButtonValidation
										? theme.colors.disabled_text
										: theme.colors.white,
								]}
							/>
						}
					/>
				)}
			</ButtonsContainer>
		</Root>
	);
};

export default FooterButtons;
