/* eslint-disable react/require-default-props */

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Column, Row } from "uikit";
import BasicPageLayout from "../../BasicPageLayout";

interface Props {
	customBreadcrumbs?: React.ReactNode;
	header?: React.ReactNode;
	navigation: React.ReactNode;
	content: React.ReactNode;
	hasChanges: boolean;
	onResetDefault: () => void;
	onSave: () => void;
	onCancel: () => void;
}

const SettingsLayout: React.FC<Props> = ({
	customBreadcrumbs,
	header,
	navigation,
	content,
	hasChanges,
	onResetDefault,
	onSave,
	onCancel,
}) => {
	const { t } = useTranslation();

	return (
		<BasicPageLayout
			customBreadcrumbs={customBreadcrumbs}
			navigation={navigation}
			header={header}
			content={
				<>
					<Column
						maxedWidth
						maxedHeight
						style={{ overflow: "hidden" }}
					>
						<Column
							align="center"
							style={{
								overflow: "overlay",
							}}
						>
							<Column
								style={{
									width: "584px",
									paddingBlock: "40px",
								}}
							>
								{content}
							</Column>
						</Column>
					</Column>

					{/* Buttons: */}
					<Row
						maxedWidth
						justify="space-between"
						style={{
							paddingInline: "16px",
							paddingBlock: "8px",
							overflow: "hidden",
						}}
					>
						<Button.Button
							text={t([`by_default`, "By default"]) ?? ""}
							variant="secondary"
							onClick={onResetDefault}
						/>
						<Row gaps="16px*">
							<Button.Button
								text={
									t([`cancel_changes`, "Cancel changes"]) ??
									""
								}
								variant="secondary"
								onClick={onCancel}
							/>
							<Button.Button
								disabled={!hasChanges}
								text={t([`save`, "Save"]) ?? ""}
								variant="primary"
								onClick={onSave}
							/>
						</Row>
					</Row>
				</>
			}
		/>
	);
};

export default SettingsLayout;
