import { useTranslation } from "react-i18next";

import LegacyTableColumn from "../../types/TableColumn";

export type Accessor =
	| "index"
	| "id"
	| "dispatcherAlias"
	| "arrivalDate"
	| "arrivalTime"
	| "timeToDelivery"
	| "status"
	| "source"
	| "paymentType"
	| "closeReason"
	| "executingStage"
	| "orderNumber"
	| "rideNumber"
	| "totalRideCount"
	| "createDateOrder"
	| "createTimeOrder"
	| "firstCity"
	| "lastCity"
	| "firstAddress"
	| "lastAddress"
	| "firstSector"
	| "lastSector"
	| "contragent"
	| "company"
	| "taxiService"
	| "fullName"
	| "phone"
	| "additionalPhone"
	| "clientCallStatus"
	| "executorCallStatus"
	| "orderTime"
	| "executorLate"
	| "executorCallSign"
	| "executingStageTimeAccepted"
	| "executingStageTimeArrived"
	| "executingStageTimeWaitingClient"
	| "executingStageTimeWithClients"
	| "smsStatusLate"
	| "smsStatusWillBeTime"
	| "smsStatusCarNotFound"
	| "smsStatusOutputPassengers"
	| "smsStatusWithPassengers"
	| "smsStatusOrderCompleted"
	| "smsStatusDriverSwitched"
	| "orderDate"
	| "idleTime"
	| "clientNote"
	| "driverNote"
	| "phoneTopUpAmount"
	| "tariff"
	| "services"
	| "params"
	| "taxiClass"
	| "discount"
	| "transportationType";

export type OrderTableColumn = LegacyTableColumn<Accessor>;

const pathT = "orderTable.columns.";

export const defaultColumns: OrderTableColumn[] = [
	{
		key: "orderDate",
		accessor: "orderDate",
		dataKey: "orderDate",
		isChecked: true,
		label: `${pathT}orderDate`,
		width: 80,
		resizable: true,
		sortable: true,
	},
	{
		key: "orderTime",
		accessor: "orderTime",
		dataKey: "orderTime",
		isChecked: true,
		label: `${pathT}orderTime`,
		width: 70,
		resizable: true,
		sortable: true,
	},
	{
		key: "arrivalDate",
		accessor: "arrivalDate",
		dataKey: "executorArrivalDate",
		isChecked: true,
		label: `${pathT}arrivalDate`,
		width: 80,
		resizable: true,
		sortable: true,
	},
	{
		key: "arrivalTime",
		accessor: "arrivalTime",
		dataKey: "executorArrivalTime",
		isChecked: true,
		label: `${pathT}arrivalTime`,
		width: 70,
		resizable: true,
		sortable: true,
	},
	{
		key: "timeToDelivery",
		accessor: "timeToDelivery",
		dataKey: "timeToDelivery",
		isChecked: true,
		label: `${pathT}timeToDelivery`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "executorCallSign",
		accessor: "executorCallSign",
		dataKey: "executorCallSign",
		isChecked: true,
		label: `${pathT}executorCallSign`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "firstCity",
		accessor: "firstCity",
		dataKey: "pickupSettlement",
		isChecked: true,
		label: `${pathT}firstCity`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "firstAddress",
		accessor: "firstAddress",
		dataKey: "pickupAddress",
		isChecked: true,
		label: `${pathT}firstAddress`,
		width: 150,
		resizable: true,
		sortable: true,
	},
	{
		key: "firstSector",
		accessor: "firstSector",
		dataKey: "pickupSector",
		isChecked: true,
		label: `${pathT}firstSector`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	// TODO: Add Entrance
	{
		key: "phone",
		accessor: "phone",
		isChecked: true,
		label: `${pathT}phone`,
		width: 100,
		resizable: true,
	},
	{
		key: "clientCallStatus",
		accessor: "clientCallStatus",
		dataKey: "customerCallStatus",
		isChecked: true,
		label: `${pathT}clientCallStatus`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "executorCallStatus",
		accessor: "executorCallStatus",
		isChecked: true,
		label: `${pathT}executorCallStatus`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "orderNumber",
		accessor: "orderNumber",
		isChecked: true,
		label: `${pathT}orderNumber`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	// TODO: Add date/time for address
	{
		key: "executingStageTimeWithClients",
		accessor: "executingStageTimeWithClients",
		isChecked: true,
		label: `${pathT}executingStageTimeWithClients`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "clientNote",
		accessor: "clientNote",
		isChecked: true,
		label: `${pathT}clientNote`,
		width: 100,
		resizable: true,
	},
	{
		key: "taxiClass",
		accessor: "taxiClass",
		dataKey: "carClass",
		isChecked: true,
		label: `${pathT}taxiClass`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "lastCity",
		accessor: "lastCity",
		isChecked: true,
		label: `${pathT}lastCity`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "lastAddress",
		accessor: "lastAddress",
		dataKey: "destinationAddress",
		isChecked: true,
		label: `${pathT}lastAddress`,
		width: 150,
		resizable: true,
		sortable: true,
	},
	{
		key: "lastSector",
		accessor: "lastSector",
		dataKey: "destinationSector",
		isChecked: true,
		label: `${pathT}lastSector`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "paymentType",
		accessor: "paymentType",
		isChecked: true,
		label: `${pathT}paymentType`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "driverNote",
		accessor: "driverNote",
		isChecked: true,
		label: `${pathT}driverNote`,
		width: 150,
		resizable: true,
		sortable: true,
	},
	{
		key: "dispatcherAlias",
		accessor: "dispatcherAlias",
		dataKey: "dispatcherCallSign",
		isChecked: true,
		label: `${pathT}dispatcherAlias`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	// End
	// TODO: Add car columns
	{
		key: "additionalPhone",
		accessor: "additionalPhone",
		isChecked: true,
		label: `${pathT}additionalPhone`,
		width: 100,
		resizable: true,
	},
	{
		key: "status",
		accessor: "status",
		isChecked: true,
		label: `${pathT}status`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "source",
		accessor: "source",
		isChecked: true,
		label: `${pathT}source`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "executingStage",
		accessor: "executingStage",
		isChecked: true,
		label: `${pathT}executingStage`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "closeReason",
		accessor: "closeReason",
		isChecked: true,
		label: `${pathT}closeReason`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "rideNumber",
		accessor: "rideNumber",
		isChecked: true,
		label: `${pathT}rideNumber`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "totalRideCount",
		accessor: "totalRideCount",
		isChecked: true,
		label: `${pathT}totalRideCount`,
		width: 60,
		resizable: true,
		sortable: true,
	},
	{
		key: "createDateOrder",
		accessor: "createDateOrder",
		isChecked: true,
		label: `${pathT}createDateOrder`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "createTimeOrder",
		accessor: "createTimeOrder",
		isChecked: true,
		label: `${pathT}createTimeOrder`,
		width: 80,
		resizable: true,
		sortable: true,
	},
	{
		key: "fullName",
		accessor: "fullName",
		dataKey: "customerFullName",
		isChecked: true,
		label: `${pathT}fullName`,
		width: 180,
		resizable: true,
		sortable: true,
	},
	{
		key: "executingStageTimeAccepted",
		accessor: "executingStageTimeAccepted",
		isChecked: true,
		label: `${pathT}executingStageTimeAccepted`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "executorLate",
		accessor: "executorLate",
		isChecked: true,
		label: `${pathT}executorLate`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "executingStageTimeArrived",
		accessor: "executingStageTimeArrived",
		isChecked: true,
		label: `${pathT}executingStageTimeArrived`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "executingStageTimeWaitingClient",
		accessor: "executingStageTimeWaitingClient",
		isChecked: true,
		label: `${pathT}executingStageTimeWaitingClient`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusLate",
		accessor: "smsStatusLate",
		isChecked: true,
		label: `${pathT}smsStatusLate`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusWillBeTime",
		accessor: "smsStatusWillBeTime",
		isChecked: true,
		label: `${pathT}smsStatusWillBeTime`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusCarNotFound",
		accessor: "smsStatusCarNotFound",
		isChecked: true,
		label: `${pathT}smsStatusCarNotFound`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusOutputPassengers",
		accessor: "smsStatusOutputPassengers",
		isChecked: true,
		label: `${pathT}smsStatusOutputPassengers`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusWithPassengers",
		accessor: "smsStatusWithPassengers",
		isChecked: true,
		label: `${pathT}smsStatusWithPassengers`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusOrderCompleted",
		accessor: "smsStatusOrderCompleted",
		isChecked: true,
		label: `${pathT}smsStatusOrderCompleted`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "smsStatusDriverSwitched",
		accessor: "smsStatusDriverSwitched",
		isChecked: true,
		label: `${pathT}smsStatusDriverSwitched`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "contragent",
		accessor: "contragent",
		dataKey: "counterparty",
		isChecked: true,
		label: `${pathT}contragent`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "company",
		accessor: "company",
		isChecked: true,
		label: `${pathT}company`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "taxiService",
		accessor: "taxiService",
		isChecked: true,
		label: `${pathT}taxiService`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "idleTime",
		accessor: "idleTime",
		isChecked: true,
		label: `${pathT}idleTime`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "phoneTopUpAmount",
		accessor: "phoneTopUpAmount",
		isChecked: true,
		label: `${pathT}phoneTopUpAmount`,
		width: 80,
		resizable: true,
	},
	{
		key: "tariff",
		accessor: "tariff",
		dataKey: "rate",
		isChecked: true,
		label: `${pathT}tariff`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "services",
		accessor: "services",
		isChecked: true,
		label: `${pathT}services`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "params",
		accessor: "params",
		isChecked: true,
		label: `${pathT}params`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "discount",
		accessor: "discount",
		isChecked: true,
		label: `${pathT}discount`,
		width: 100,
		resizable: true,
		sortable: true,
	},
	{
		key: "transportationType",
		accessor: "transportationType",
		isChecked: true,
		hidden: true,
		label: `${pathT}transportationType`,
		resizable: true,
		sortable: true,
	},
]; // ! I couldn’t replace it with useDefaultColumns so that text translation would work, since useTranslation only works in React components, and it is called in addition to the React component, even in Redux timeToDeliveryCellContent

export function useDefaultColumns(): OrderTableColumn[] {
	const { t } = useTranslation();

	return [
		{
			key: "orderDate",
			accessor: "orderDate",
			dataKey: "orderDate",
			isChecked: true,
			label: t(`${pathT}orderDate`) ?? "",
			width: 80,
			resizable: true,
			sortable: true,
		},
		{
			key: "orderTime",
			accessor: "orderTime",
			dataKey: "orderTime",
			isChecked: true,
			label: t(`${pathT}orderTime`) ?? "",
			width: 70,
			resizable: true,
			sortable: true,
		},
		{
			key: "arrivalDate",
			accessor: "arrivalDate",
			dataKey: "executorArrivalDate",
			isChecked: true,
			label: t(`${pathT}arrivalDate`) ?? "",
			width: 80,
			resizable: true,
			sortable: true,
		},
		{
			key: "arrivalTime",
			accessor: "arrivalTime",
			dataKey: "executorArrivalTime",
			isChecked: true,
			label: t(`${pathT}arrivalTime`) ?? "",
			width: 70,
			resizable: true,
			sortable: true,
		},
		{
			key: "timeToDelivery",
			accessor: "timeToDelivery",
			dataKey: "timeToDelivery",
			isChecked: true,
			label: t(`${pathT}timeToDelivery`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "executorCallSign",
			accessor: "executorCallSign",
			dataKey: "executorCallSign",
			isChecked: true,
			label: t(`${pathT}executorCallSign`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "firstCity",
			accessor: "firstCity",
			dataKey: "pickupSettlement",
			isChecked: true,
			label: t(`${pathT}firstCity`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "firstAddress",
			accessor: "firstAddress",
			dataKey: "pickupAddress",
			isChecked: true,
			label: t(`${pathT}firstAddress`) ?? "",
			width: 150,
			resizable: true,
			sortable: true,
		},
		{
			key: "firstSector",
			accessor: "firstSector",
			dataKey: "pickupSector",
			isChecked: true,
			label: t(`${pathT}firstSector`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		// TODO: Add Entrance
		{
			key: "phone",
			accessor: "phone",
			isChecked: true,
			label: t(`${pathT}phone`) ?? "",
			width: 100,
			resizable: true,
		},
		{
			key: "clientCallStatus",
			accessor: "clientCallStatus",
			dataKey: "customerCallStatus",
			isChecked: true,
			label: t(`${pathT}clientCallStatus`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "executorCallStatus",
			accessor: "executorCallStatus",
			isChecked: true,
			label: t(`${pathT}executorCallStatus`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "orderNumber",
			accessor: "orderNumber",
			isChecked: true,
			label: t(`${pathT}orderNumber`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		// TODO: Add date/time for address
		{
			key: "executingStageTimeWithClients",
			accessor: "executingStageTimeWithClients",
			isChecked: true,
			label: t(`${pathT}executingStageTimeWithClients`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "clientNote",
			accessor: "clientNote",
			isChecked: true,
			label: t(`${pathT}clientNote`) ?? "",
			width: 100,
			resizable: true,
		},
		{
			key: "taxiClass",
			accessor: "taxiClass",
			dataKey: "carClass",
			isChecked: true,
			label: t(`${pathT}taxiClass`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "lastCity",
			accessor: "lastCity",
			isChecked: true,
			label: t(`${pathT}lastCity`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "lastAddress",
			accessor: "lastAddress",
			dataKey: "destinationAddress",
			isChecked: true,
			label: t(`${pathT}lastAddress`) ?? "",
			width: 150,
			resizable: true,
			sortable: true,
		},
		{
			key: "lastSector",
			accessor: "lastSector",
			dataKey: "destinationSector",
			isChecked: true,
			label: t(`${pathT}lastSector`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "paymentType",
			accessor: "paymentType",
			isChecked: true,
			label: t(`${pathT}paymentType`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "driverNote",
			accessor: "driverNote",
			isChecked: true,
			label: t(`${pathT}driverNote`) ?? "",
			width: 150,
			resizable: true,
			sortable: true,
		},
		{
			key: "dispatcherAlias",
			accessor: "dispatcherAlias",
			dataKey: "dispatcherCallSign",
			isChecked: true,
			label: t(`${pathT}dispatcherAlias`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		// End
		// TODO: Add car columns
		{
			key: "additionalPhone",
			accessor: "additionalPhone",
			isChecked: true,
			label: t(`${pathT}additionalPhone`) ?? "",
			width: 100,
			resizable: true,
		},
		{
			key: "status",
			accessor: "status",
			isChecked: true,
			label: t(`${pathT}status`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "source",
			accessor: "source",
			isChecked: true,
			label: t(`${pathT}source`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "executingStage",
			accessor: "executingStage",
			isChecked: true,
			label: t(`${pathT}executingStage`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "closeReason",
			accessor: "closeReason",
			isChecked: true,
			label: t(`${pathT}closeReason`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "rideNumber",
			accessor: "rideNumber",
			isChecked: true,
			label: t(`${pathT}rideNumber`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "totalRideCount",
			accessor: "totalRideCount",
			isChecked: true,
			label: t(`${pathT}totalRideCount`) ?? "",
			width: 60,
			resizable: true,
			sortable: true,
		},
		{
			key: "createDateOrder",
			accessor: "createDateOrder",
			isChecked: true,
			label: t(`${pathT}createDateOrder`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "createTimeOrder",
			accessor: "createTimeOrder",
			isChecked: true,
			label: t(`${pathT}createTimeOrder`) ?? "",
			width: 80,
			resizable: true,
			sortable: true,
		},
		{
			key: "fullName",
			accessor: "fullName",
			dataKey: "customerFullName",
			isChecked: true,
			label: t(`${pathT}fullName`) ?? "",
			width: 180,
			resizable: true,
			sortable: true,
		},
		{
			key: "executingStageTimeAccepted",
			accessor: "executingStageTimeAccepted",
			isChecked: true,
			label: t(`${pathT}executingStageTimeAccepted`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "executorLate",
			accessor: "executorLate",
			isChecked: true,
			label: t(`${pathT}executorLate`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "executingStageTimeArrived",
			accessor: "executingStageTimeArrived",
			isChecked: true,
			label: t(`${pathT}executingStageTimeArrived`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "executingStageTimeWaitingClient",
			accessor: "executingStageTimeWaitingClient",
			isChecked: true,
			label: t(`${pathT}executingStageTimeWaitingClient`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusLate",
			accessor: "smsStatusLate",
			isChecked: true,
			label: t(`${pathT}smsStatusLate`) ?? "",

			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusWillBeTime",
			accessor: "smsStatusWillBeTime",
			isChecked: true,
			label: t(`${pathT}smsStatusWillBeTime`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusCarNotFound",
			accessor: "smsStatusCarNotFound",
			isChecked: true,
			label: t(`${pathT}smsStatusCarNotFound`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusOutputPassengers",
			accessor: "smsStatusOutputPassengers",
			isChecked: true,
			label: t(`${pathT}smsStatusOutputPassengers`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusWithPassengers",
			accessor: "smsStatusWithPassengers",
			isChecked: true,
			label: t(`${pathT}smsStatusWithPassengers`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusOrderCompleted",
			accessor: "smsStatusOrderCompleted",
			isChecked: true,
			label: t(`${pathT}smsStatusOrderCompleted`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "smsStatusDriverSwitched",
			accessor: "smsStatusDriverSwitched",
			isChecked: true,
			label: t(`${pathT}smsStatusDriverSwitched`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "contragent",
			accessor: "contragent",
			dataKey: "counterparty",
			isChecked: true,
			label: t(`${pathT}contragent`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "company",
			accessor: "company",
			isChecked: true,
			label: t(`${pathT}company`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "taxiService",
			accessor: "taxiService",
			isChecked: true,
			label: t(`${pathT}taxiService`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "idleTime",
			accessor: "idleTime",
			isChecked: true,
			label: t(`${pathT}idleTime`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "phoneTopUpAmount",
			accessor: "phoneTopUpAmount",
			isChecked: true,
			label: t(`${pathT}phoneTopUpAmount`) ?? "",
			width: 80,
			resizable: true,
		},
		{
			key: "tariff",
			accessor: "tariff",
			dataKey: "rate",
			isChecked: true,
			label: t(`${pathT}tariff`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "services",
			accessor: "services",
			isChecked: true,
			label: t(`${pathT}services`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "params",
			accessor: "params",
			isChecked: true,
			label: t(`${pathT}params`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "discount",
			accessor: "discount",
			isChecked: true,
			label: t(`${pathT}discount`) ?? "",
			width: 100,
			resizable: true,
			sortable: true,
		},
		{
			key: "transportationType",
			accessor: "transportationType",
			isChecked: true,
			hidden: true,
			label: t(`${pathT}transportationType`) ?? "",
			resizable: true,
			sortable: true,
		},
	];
} // defaultColumns
