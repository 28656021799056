import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import WeekDays from "../../../../../Modal/components/Content/components/WeekDays";

const WeekDaysOutput: React.FC<WeekDaysOutput.Props> = memo(({ value }) => {
	const { t } = useTranslation();

	return value.active && value.days.length ? (
		<>
			<div>
				{value.days.map((d) => t(`days-of-week.${d}`)).join(", ")}
			</div>
			<span>/</span>
		</>
	) : null;
});

declare namespace WeekDaysOutput {
	interface Props {
		value: WeekDays.Value;
	}
}

export default WeekDaysOutput;
