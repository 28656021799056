import React, { memo, useEffect, useRef } from "react";
import { Polyline, useMap } from "react-leaflet";
import L, {
	Polyline as PolylineInterface,
	PathOptions,
	LatLngLiteral,
	PolylineDecorator,
} from "leaflet";
import "leaflet-polylinedecorator";

import {
	ValueColors,
	ConstantColors,
} from "../../../../redux/reducers/Archives/GPSLog/interface";

// https://leafletjs.com/reference.html#polyline
const PolylineItem: React.FC<PolylineItem.Props> = ({
	points,
	color = ConstantColors.RED,
	polylinePathOptions = {},
}): JSX.Element => {
	const map = useMap();
	const polyRef = useRef<PolylineInterface | null>(null);
	const decoratorRef = useRef<any>(null);

	useEffect(() => {
		if (polyRef.current && decoratorRef.current) {
			polyRef.current.setLatLngs(points);
			decoratorRef.current.setPaths([polyRef.current]);
		}
	}, [points]);

	useEffect(() => {
		const main = polyRef.current;
		let decorator: PolylineDecorator | null = null;
		try {
			if (L.polylineDecorator && main)
				decorator = L.polylineDecorator(main, {
					patterns: [
						{
							offset: 25,
							repeat: 90,
							symbol: L.Symbol.arrowHead({
								pixelSize: 6,
								polygon: true,
								pathOptions: {
									color,
									fillOpacity: 1,
									weight: 2,
								},
							}),
						},
					],
				}).addTo(map);
		} catch {
			console.error("Something went wrong!.");
		}

		return () => {
			if (main) main.remove();
			if (decorator) decorator.remove();
		};
	}, [points, map, color]);

	if (!points.length) return <></>;
	return (
		<>
			<Polyline
				ref={polyRef}
				positions={points}
				color={color}
				dashArray={[1, 1]}
				aria-hidden="true"
				pathOptions={{ color, ...polylinePathOptions }}
			/>
		</>
	);
};

export const PolylineItemMemo = memo(PolylineItem);

declare namespace PolylineItem {
	interface Props {
		points: LatLngLiteral[];
		color?: ValueColors;
		polylinePathOptions?: PathOptions;
	}
}

export default PolylineItem;
