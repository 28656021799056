import React, { memo } from "react";
import { Stepper } from "uikit";

const ValueStepperBase: React.FC<ValueStepperBase.Props> = ({
	width = "90px",
	value = 0,
	decimalCount = 2,
	disabled,
	onChange,
	error,
	...props
}) => (
	<Stepper
		{...props}
		error={error}
		disabled={disabled}
		decimalCount={decimalCount}
		width={width || "90px"}
		value={value || 0}
		onChange={onChange}
	/>
);

declare namespace ValueStepperBase {
	interface Props extends Stepper.Props {
		disabled?: boolean;
		value?: number;
		onChange?: (val: any) => void;
		width?: string;
		error?: string | boolean;
		decimalCount?: number;
	}
}

export const ValueStepper = memo(ValueStepperBase);
declare namespace ValueStepper {
	interface Props extends ValueStepperBase.Props {}
}

export default ValueStepperBase;
