import { useMemo } from "react";
import { TFunction } from "i18next";

import { useTypedSelector } from "../redux/store";
import { ROUTES, OPEN_ROUTES, RoutePaths } from "../constants/routes";

import { hasAccess, DisplayFields } from ".";

/**
 * Custom hook for controlling access to routes based on the user's authorization status
 * and access rights.
 *
 * This hook is responsible for managing routing logic based on the user's connection status,
 * authorization level, and specific access permissions. It checks whether a user is authorized
 * to access a given route and provides a redirection if they are not permitted to do so.
 *
 * Key functionality:
 * - If the route is part of open, unrestricted routes (e.g., login or public pages), the hook
 *   allows navigation without any restrictions.
 * - If the user is connected but not authorized and attempts to access a restricted page,
 *   they will be redirected to the login page, and a warning will be displayed.
 * - If the user is authorized but lacks the necessary access rights for a specific route
 *   (based on `accessName` and their `personalRole`), they will be redirected to the main page
 *   with an access warning.
 *
 * Parameters:
 * @param t - i18next translation function to provide localized warning messages.
 * @param warn - Function to display warning notifications with a title and message.
 * @param pathname - The current route path being accessed.
 * @param accessName - The name of the access control key to check permissions against.
 * @param personalRole - The user's role object that contains access permissions (optional).
 *
 * Returns:
 * - An object with a `redirect` property indicating the path to redirect to if access is denied.
 *   If no redirection is needed, `redirect` will be `null`.
 */

const useAuthRouterControl = (
	t: TFunction,
	warn: (title: string, message: string) => void,
	pathname: RoutePaths,
	accessName: string,
	personalRole?: DisplayFields,
) => {
	const { connected, authorized } = useTypedSelector((state) => ({
		connected: state.prpc.connected,
		authorized: state.prpc.authorized,
	}));

	const translateTexts = useMemo(
		() => ({
			warn: t("notification.title.warn") ?? "Warn",
			accessNotToPage:
				t("notification.description.accessNotToPage") ??
				"Access to the page is limited",
			authorizedNotToSystem:
				t("notification.description.authorizedNotToSystem") ??
				"You are not authorized. Please sign in",
		}),
		[t],
	);

	const isOpenRoute = OPEN_ROUTES.includes(pathname);
	const isMainPage = pathname === ROUTES.MAIN;
	const isLoginPage = pathname === ROUTES.LOGIN;

	const hasAccessMemo = useMemo(
		() => (accessName ? hasAccess(accessName, personalRole) : false),
		[accessName, personalRole],
	);

	if (isOpenRoute) {
		return { redirect: null };
	}

	if (connected && !authorized && !isOpenRoute && !isLoginPage) {
		warn(translateTexts.warn, translateTexts.authorizedNotToSystem);
		return { redirect: ROUTES.LOGIN };
	}

	if (connected && authorized && !isMainPage && !hasAccessMemo) {
		warn(translateTexts.warn, translateTexts.accessNotToPage);
		return { redirect: ROUTES.MAIN };
	}

	return { redirect: null };
};

export default useAuthRouterControl;
