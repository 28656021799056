/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { react, Select as OriginalSelect, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";
import styled from "styled-components";

import Company from "../../../../../../../../services/Company";
import TaxiService from "../../../../../../../../services/TaxiService";
import Map from "../../../../../../../../redux/services/Map";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe";

import InternalController from "./Controller";

const StyledRow = styled.div`
	display: flex;
	gap: 16px;
	padding-bottom: 4px;
	padding-left: 20px;
`;

const Select = styled(OriginalSelect)<{ disabled?: boolean }>`
	width: 250px;
	background-color: ${(props) => (props.disabled ? "#f5f5f5" : "white")};
	pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
	opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const TaxiServiceForm = react.withController<
	TaxiServiceForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<TaxiServiceForm.InternalPropsBase, TaxiServiceForm.Value>(
		({ value, language, controller, onChange }) => {
			const { t } = useTranslation();
			const rootRef = useRef<HTMLDivElement | null>(null);

			const [companyIdFiledError, setCompanyIdFieldError] =
				useState(false);
			const [taxiServiceIdFiledError, setTaxiServiceIdFieldError] =
				useState(false);

			const companies = useModelSubscribe({}, Company)?.cache;
			const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

			const taxiService = useMemo(
				() =>
					taxiServices?.find(
						(taxiService) => taxiService.id === value,
					),
				[taxiServices, value],
			);

			const companyIdRef = useRef<number | undefined>(undefined);

			const [companyId, setCompanyId] = useInternal(
				taxiService?.company?.id ?? companyIdRef.current,
			);

			companyIdRef.current = companyId;

			const companyOptions = useMemo(
				() =>
					companies?.map((company) => ({
						key: company.id,
						label: company.name[language],
						value: company.id,
					})) ?? [],
				[companies, language],
			);

			const taxiServiceOptions = useMemo(
				() =>
					companyId
						? taxiServices
								?.filter(
									(taxiService) =>
										taxiService.company?.id === companyId,
								)
								.map((taxiService) => ({
									key: taxiService.id,
									label: taxiService.settlement[language],
									value: taxiService.id,
								}))
						: taxiServices?.map((taxiService) => ({
								key: taxiService.id,
								label: taxiService.settlement[language],
								value: taxiService.id,
						  })),
				[taxiServices, companyId, language],
			);

			controller.setContext({
				rootRef,

				value,
				companyId,
				taxiServices,

				setCompanyIdFieldError,
				setTaxiServiceIdFieldError,
			});

			return (
				<StyledRow ref={rootRef}>
					<Select
						placeholder={
							t(
								"settings.paymentSystems.modal.company.chooseCompany",
							) ?? ""
						}
						options={companyOptions}
						value={companyId}
						error={companyIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setCompanyId(option.value as number);
							onChange(undefined);
						}}
					/>
					<Select
						placeholder={
							!companyId
								? t("selectCompanyFirst") ?? ""
								: t(
										"settings.paymentSystems.modal.branch.chooseBranch",
								  ) ?? ""
						}
						options={taxiServiceOptions}
						value={value}
						error={taxiServiceIdFiledError}
						disabled={!companyId}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setTaxiServiceIdFieldError(false);
							onChange(option.value as number);
						}}
					/>
				</StyledRow>
			);
		},
	),
	InternalController,
);

declare namespace TaxiServiceForm {
	type Value = number | undefined;

	interface PropsBase {
		language: Map.Language;
	}

	interface InternalPropsBase extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = InputifiedPropsBase;

	type Controller = InternalController;
}

export default TaxiServiceForm;
