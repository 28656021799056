import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../redux/store";
import softphoneWidget from "../../../../../../../../redux/reducers/softphoneWidget";
import { softphoneConnectionColors } from "../../../../../../../../utils/jsSip";
import SoftphoneWidget from "../../../../../../../../components/SoftphoneWidget";
import KeyBind from "../../../../../../../../components/KeyBind";

import Button from "./components/Button";

const SoftphoneButton: React.FC<SoftphoneButton.Props> = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const { softPhoneConnect: softphoneConnectionStatus } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const { isOpen: isSoftphoneOpen } = useTypedSelector(
		(state) => state.softphoneWidget,
	);

	const iconColor = useMemo(
		() => softphoneConnectionColors[softphoneConnectionStatus].iconColor,
		[softphoneConnectionStatus],
	);

	const isButtonDisabled = useMemo(
		() => softphoneConnectionStatus !== "success",
		[softphoneConnectionStatus],
	);

	const openSoftphone = useCallback(() => {
		if (isButtonDisabled) return;

		dispatch(softphoneWidget.actions.setIsOpen(true));
	}, [dispatch, isButtonDisabled]);

	const toggleSoftphone = useCallback(() => {
		if (isButtonDisabled) return;

		dispatch(softphoneWidget.actions.setIsOpen(!isSoftphoneOpen));
	}, [dispatch, isButtonDisabled, isSoftphoneOpen]);

	return (
		<>
			<KeyBind
				settingId="openSoftphone"
				stopPropagation
				preventDefault
				onUse={openSoftphone}
			/>

			{isSoftphoneOpen && <SoftphoneWidget onClose={toggleSoftphone} />}

			<Button
				title={t(`orderPage.rightMenu.toggle.softphone`) || "Softphone"}
				status={softphoneConnectionStatus}
				disabled={isButtonDisabled}
				tabIndex={-1}
				onClick={toggleSoftphone}
			>
				<Icon
					id="right-menu-softphone"
					size={24}
					colors={[iconColor]}
				/>
			</Button>
		</>
	);
};

declare namespace SoftphoneButton {
	interface Props {}
}

export default SoftphoneButton;
