import styled from "styled-components";
import { theme } from "uikit";

const Divider = styled.div`
	width: 1px;
	height: 100%;

	background-color: ${theme.colors.primary};
	opacity: 0.15;
`;

export default Divider;
