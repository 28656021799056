import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "uikit";

import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../constants/tPath";

import {
	AutoRateRuleCalculationType,
	calculationTypeOptions,
} from "./constants";

const TariffCalculation: React.FC<TariffCalculation.Props> = memo(
	({ type, setType, disabled = false }) => {
		const { t } = useTranslation();

		const handleTypeChange = useCallback(
			(newType) => {
				setType(newType);
			},
			[setType],
		);

		const tOptions = useMemo(
			() =>
				calculationTypeOptions.map((o) => ({
					...o,
					label: t(
						`${tPath}.modal.tariffCalculation.options.${o.key}`,
					),
				})),
			[t],
		);

		return (
			<LabeledField
				label={t(`${tPath}.modal.tariffCalculation.title`) || ""}
			>
				<Select
					disabled={disabled}
					placeholder={
						t(`${tPath}.modal.tariffCalculation.placeholder`) || ""
					}
					options={tOptions}
					value={type}
					onChange={handleTypeChange}
				/>
			</LabeledField>
		);
	},
);

declare namespace TariffCalculation {
	interface Props {
		type: Value;
		disabled?: boolean;
		setType: (x: Value) => void;
	}

	type Value = AutoRateRuleCalculationType;
}

export default TariffCalculation;
