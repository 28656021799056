import { useCallback, useMemo } from "react";
import { isEqual, isNumber } from "lodash";

import Company from "../services/Company";
import mapByKey from "../utils/mapByKey";

import useGetCompanyIdsByTaxiServiceIds from "./useGetCompanyIdsByTaxiServiceIds";
import useTaxiServices from "./useTaxiServices";
import useModelSubscribe from "./useModelSubscribe";

type A = number[] | ["all"];

function isAllCompanies(companies: A): companies is ["all"] {
	return companies[0] === "all";
}

function isAllTaxiServices(taxiServices: A): taxiServices is ["all"] {
	return taxiServices[0] === "all";
}

function useCompanyAndTaxiServiceIdsFilter(
	companyIds: A,
	taxiServiceIds: A,
	onChangeCompanyIds: (newCompanyIds: A) => void,
	onChangeTaxiServiceIds: (newTaxiServiceIds: A) => void,
	options: { allowedTaxiServiceIds?: number[] } = {},
) {
	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();

	const companyData = useModelSubscribe({}, Company);

	const companies = useMemo(
		() => companyData?.cache ?? [],
		[companyData?.cache],
	);

	const taxiServices = useTaxiServices();

	const processedSelectedCompanyIds = useMemo(
		() =>
			isAllCompanies(companyIds)
				? companies.map((company) => company.id)
				: companyIds,
		[companies, companyIds],
	);

	const processedSelectedTaxiServiceIds = useMemo(
		() =>
			isAllTaxiServices(taxiServiceIds)
				? taxiServices
						.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								processedSelectedCompanyIds.includes(
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									taxiService.company!.id,
								),
						)
						.map((taxiService) => taxiService.id)
				: taxiServiceIds,
		[taxiServiceIds, taxiServices, processedSelectedCompanyIds],
	);

	const taxiServicesById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const allowedCompanyIds = useMemo(
		() =>
			options.allowedTaxiServiceIds
				? getCompanyIdsByTaxiServiceIds(options.allowedTaxiServiceIds)
				: [],
		[getCompanyIdsByTaxiServiceIds, options.allowedTaxiServiceIds],
	);

	const filteredCompanies = useMemo(
		() =>
			options.allowedTaxiServiceIds
				? companies.filter((company) =>
						allowedCompanyIds.includes(company.id),
				  )
				: companies,
		[allowedCompanyIds, companies, options.allowedTaxiServiceIds],
	);

	const filteredTaxiServices = useMemo(
		() =>
			(isAllCompanies(companyIds)
				? taxiServices
				: taxiServices.filter(
						(taxiService) =>
							isNumber(taxiService.company?.id) &&
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							companyIds.includes(taxiService.company!.id),
				  )
			).filter(
				(taxiService) =>
					options.allowedTaxiServiceIds?.includes(taxiService.id) ??
					true,
			),
		[companyIds, options.allowedTaxiServiceIds, taxiServices],
	);

	const setCompanyIds = useCallback(
		(newCompanyIds: number[]) => {
			let processedNewCompanyIds: A;

			if (
				filteredCompanies.every((company) =>
					newCompanyIds.includes(company.id),
				)
			)
				processedNewCompanyIds = ["all"];
			else {
				processedNewCompanyIds = newCompanyIds as number[];

				if (!isAllTaxiServices(taxiServiceIds)) {
					let newTaxiServices: A =
						processedSelectedTaxiServiceIds.filter(
							(taxiServiceId) =>
								isNumber(
									taxiServicesById[taxiServiceId]?.company
										?.id,
								) &&
								(processedNewCompanyIds as number[]).includes(
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									taxiServicesById[taxiServiceId].company!.id,
								),
						);

					const allPossibleTaxiServices =
						taxiServices
							?.filter(
								(taxiService) =>
									isNumber(taxiService.company?.id) &&
									(processedNewCompanyIds as number[])
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										.includes(taxiService.company!.id),
							)
							.map((taxiService) => taxiService.id) ?? [];

					if (isEqual(allPossibleTaxiServices, newTaxiServices))
						newTaxiServices = ["all"];

					onChangeTaxiServiceIds(newTaxiServices);
				}
			}

			onChangeCompanyIds(processedNewCompanyIds);
		},
		[
			filteredCompanies,
			onChangeCompanyIds,
			onChangeTaxiServiceIds,
			processedSelectedTaxiServiceIds,
			taxiServiceIds,
			taxiServices,
			taxiServicesById,
		],
	);

	const setTaxiServiceIds = useCallback(
		(newTaxiServiceIds: number[]) => {
			let processedNewTaxiServiceIds: A;

			if (
				filteredTaxiServices.every((taxiService) =>
					newTaxiServiceIds.includes(taxiService.id),
				)
			)
				processedNewTaxiServiceIds = ["all"];
			else processedNewTaxiServiceIds = newTaxiServiceIds as number[];

			onChangeTaxiServiceIds(processedNewTaxiServiceIds);
		},
		[filteredTaxiServices, onChangeTaxiServiceIds],
	);

	return [
		filteredCompanies,
		filteredTaxiServices,
		processedSelectedCompanyIds,
		processedSelectedTaxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] as unknown as [
		typeof filteredCompanies,
		typeof filteredTaxiServices,
		typeof processedSelectedCompanyIds,
		typeof processedSelectedTaxiServiceIds,
		typeof setCompanyIds,
		typeof setTaxiServiceIds,
	];
}

export default useCompanyAndTaxiServiceIdsFilter;
