import { combineReducers } from "redux";

import executorsReducer from "./Executors/executors.reducer";
import transportsReducer from "./Transport/transports.reducer";

const accountsReducer = combineReducers({
	executorsReducer,
	transportsReducer,
});

export default accountsReducer;
