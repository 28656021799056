import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import styled, { css } from "styled-components";

import Style, {
	StyledGrid,
	StyledGridItem,
	baseStyles,
	visible,
	distributeStyles,
	flexStyles,
} from "../styles";
import { Colors } from "../interface";

const StyledInput = styled.input<
	Style.BaseType & Style.DistributeType & Style.FlexType
>`
	width: 100%;
	${({ disabled }) =>
		disabled &&
		css`
			filter: grayscale(1);
			opacity: 0.3;
			cursor: not-allowed;
		`}
	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${visible}
`;

const StyledInput1 = styled.input<
	Style.BaseType & Style.DistributeType & Style.FlexType
>`
	width: 100%;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	outline: none;
	overflow: hidden;
	border-radius: 16px;

	&::-webkit-slider-runnable-track {
		height: 12px;
		background: #cccccc;
		border-radius: 16px;
	}

	&::-moz-range-track {
		height: 12px;
		background: #cccccc;
		border-radius: 16px;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		height: 12px;
		width: 12px;
		background-color: #fff;
		border-radius: 50%;
		border: 2px solid ${Colors.BACKGROUND_BLUE};
		box-shadow: calc(-100vw - 6px) 0 0 100vw ${Colors.BACKGROUND_BLUE};
	}

	&::-moz-range-thumb {
		height: 12px;
		width: 12px;
		background-color: #fff;
		border-radius: 50%;
		border: 2px solid ${Colors.BACKGROUND_BLUE};
		box-shadow: calc(-100vw - 6px) 0 0 100vw ${Colors.BACKGROUND_BLUE};
	}
	${({ disabled }) =>
		disabled &&
		css`
			filter: grayscale(1);
			opacity: 0.3;
			cursor: not-allowed;
		`}
	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${visible}
`;

const StyledInput2 = styled.input<
	Style.BaseType & Style.DistributeType & Style.FlexType
>`
	appearance: none;
	width: 100%;
	height: 6px;
	border-radius: 9999px;
	background: #cccccc;
	cursor: pointer;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 16px;
		height: 16px;
		border-radius: 9999px;
		background: ${Colors.BACKGROUND_BLUE};
		transition: background 0.2s;
	}

	&::-moz-range-thumb {
		width: 16px;
		height: 16px;
		border-radius: 9999px;
		background: ${Colors.BACKGROUND_BLUE};
		transition: background 0.2s;
	}

	${({ disabled }) =>
		disabled &&
		css`
			filter: grayscale(1);
			opacity: 0.3;
			cursor: not-allowed;
		`}

	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${visible}
`;

const ValueRangeBase: React.FC<ValueRangeBase.Props> = ({
	value = 0,
	onChange,
	disabled = false,
	min = 0,
	max = 100,
	step = 1,
	range,
	afterContent,
	beforeContent,
	inputStyle,
	...props
}) => {
	const [bg, setBg] = useState<string>("");

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { target } = event;
			const valueRange = target.value;
			onChange?.(Number(valueRange));
		},
		[onChange],
	);

	useLayoutEffect(() => {
		const ratio = ((value - min) / (max - min)) * 100;
		setBg(
			`linear-gradient(90deg, ${Colors.BACKGROUND_BLUE} ${ratio}%, ${Colors.DISABLED} ${ratio}%)`,
		);
	}, [max, min, value]);

	return (
		<StyledGrid
			areas="'main'"
			justify="start"
			alignItems="center"
			p="6px"
			{...props}
		>
			{afterContent}
			<StyledGridItem area="main" alignSelf="center">
				{!range && (
					<StyledInput
						{...inputStyle}
						type="range"
						value={value}
						onChange={handleOnChange}
						min={min}
						max={max}
						disabled={disabled}
						step={step}
					/>
				)}

				{range === "1" && (
					<StyledInput1
						{...inputStyle}
						type="range"
						value={value}
						onChange={handleOnChange}
						min={min}
						max={max}
						disabled={disabled}
						step={step}
					/>
				)}

				{range === "2" && (
					<StyledInput2
						{...inputStyle}
						type="range"
						value={value}
						onChange={handleOnChange}
						min={min}
						max={max}
						disabled={disabled}
						step={step}
						bg={bg}
					/>
				)}
			</StyledGridItem>
			{beforeContent}
		</StyledGrid>
	);
};

declare namespace ValueRangeBase {
	interface Props extends Partial<Style.Grid> {
		disabled?: boolean;
		value?: number;
		onChange?: (val: number) => void;
		min?: number;
		max?: number;
		range?: "1" | "2";
		step?: number;
		afterContent?: React.ReactNode;
		beforeContent?: React.ReactNode;
		inputStyle?: Style.BaseType & Style.DistributeType & Style.FlexType;
	}
}

export const ValueRange = memo(ValueRangeBase);
declare namespace ValueRange {
	interface Props extends ValueRangeBase.Props {}
}

export default ValueRangeBase;
