import { combineReducers } from "@reduxjs/toolkit";

import {
	reportAgentOrdersReportDataState,
	reportAgentOrdersReportDataReducer,
	reportAgentOrdersReportDataAction,
} from "./data";

export type StateAgentOrdersReport = {
	data: reportAgentOrdersReportDataState;
};

export const agentOrdersReport = combineReducers<StateAgentOrdersReport>({
	data: reportAgentOrdersReportDataReducer,
});

export const reportsAgentOrdersReportAction = {
	data: reportAgentOrdersReportDataAction,
};

export default agentOrdersReport;
