import { NoticeDestinationApp } from "../../../../../../../../../../services/PushNotice";

// eslint-disable-next-line import/prefer-default-export
export const destinationAppOptions = Object.keys(NoticeDestinationApp).map(
	(key) => ({
		key,
		value: NoticeDestinationApp[key],
		label: NoticeDestinationApp[key],
	}),
);
