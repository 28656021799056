import React, { memo } from "react";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

import OrderSource from "./components/OrderSource";
import Payment from "./components/Payment";

const Propagation: React.FC<Propagation.Props> = memo(({ value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const orderSource = valueEditor.useGetter("orderSource");
	const setOrderSource = valueEditor.useSetter("orderSource");

	const payment = valueEditor.useGetter("payment");
	const setPayment = valueEditor.useSetter("payment");

	return (
		<>
			<OrderSource value={orderSource} onChange={setOrderSource} />
			<Payment value={payment} onChange={setPayment} />
		</>
	);
});

declare namespace Propagation {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		orderSource: OrderSource.Value;
		payment: Payment.Value;
	}
}

export default Propagation;
