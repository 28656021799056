/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Reports } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	reportData: [],
};

const setReportsData: ReduxReducer<State, State> = (state, action) => {
	state.reportData = action.payload.reportData;
};

const reportCashlessOrdersPeriodReportData = createSlice({
	name: Reports.ConstantReportSlice.CASHLESS_ORDERS_PERIOD_REPORT,
	initialState,
	reducers: {
		setReportsData,
	},
});

export const reportCashlessOrdersPeriodReportDataAction =
	reportCashlessOrdersPeriodReportData.actions;
export const reportCashlessOrdersPeriodReportDataReducer =
	reportCashlessOrdersPeriodReportData.reducer;
export type reportCashlessOrdersPeriodReportDataState = State;

export default reportCashlessOrdersPeriodReportData;
