import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	NAME = "name",
	COMPANIES = "companies",
	BRANCHES = "branches",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.NAME, label: "", width: 280 },
	{ id: Columns.COMPANIES, label: "", width: 220 },
	{ id: Columns.BRANCHES, label: "" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
