import styled from "styled-components";
import { theme } from "uikit";

const Title = styled.div`
	height: 28px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;

	white-space: nowrap;
	overflow: hidden;
	max-width: 450px;
	text-overflow: ellipsis;

	color: ${theme.colors.primary};
`;

export default Title;
