import {
	CheckSmsStatusMode,
	SmsProviderType,
} from "../../../../../redux/services/Settings/Message/ProviderSms/enums";

interface Result {
	taxiServiceIds: number[];
	name: string;
	host: string;
	port: number;
	login: string;
	password: string;
	isSystemType: boolean;
	systemType: string;
	sourceAddrTon: string;
	sourceAddrNpi: string;
	destAddrTon: string;
	destAddrNpi: string;
	alphaName: string;
	isEnquireLink: boolean;
	checkSmsStatusMode: CheckSmsStatusMode;
	activeQueryInterval: number;

	enquireLink: number;
	addPlus: boolean;
	active: boolean;
	smsProviderToTaxiServices: any[];
	type: SmsProviderType;
	isMaxSmsPerSecond: boolean;
	maxSmsPerSecond: number;
}

export const convertToUpdateData = (providerObject: Result): Result => {
	const obj = {
		name: providerObject?.name,
		host: providerObject?.host,
		port: providerObject?.port,
		login: providerObject?.login,
		password: providerObject?.password,
		isSystemType: providerObject?.isSystemType,
		systemType: providerObject?.systemType,
		sourceAddrTon: providerObject?.sourceAddrTon,
		sourceAddrNpi: providerObject?.sourceAddrNpi,
		destAddrTon: providerObject?.destAddrTon,
		destAddrNpi: providerObject?.destAddrNpi,
		alphaName: providerObject?.alphaName,
		isEnquireLink: providerObject?.isEnquireLink,
		enquireLink:
			providerObject?.enquireLink && providerObject.enquireLink / 1000,
		activeQueryInterval:
			providerObject?.enquireLink &&
			providerObject.activeQueryInterval / 1000,
		addPlus: providerObject?.addPlus,
		active: providerObject?.active,
		checkSmsStatusMode: providerObject?.checkSmsStatusMode,
		smsProviderToTaxiServices:
			providerObject?.smsProviderToTaxiServices.map(
				({ taxiService }) => taxiService,
			),
		taxiServiceIds: providerObject?.smsProviderToTaxiServices.map(
			({ taxiService }) => taxiService.id,
		),
		isMaxSmsPerSecond: providerObject?.isMaxSmsPerSecond,
		maxSmsPerSecond: providerObject?.maxSmsPerSecond,
		type: providerObject?.type,
	};
	return obj;
};

export const validResponse = (message: string) => {
	if (!message?.length) return "";
	const host = message.split("Cannot connect to sms provider host");
	const port = message.split('"port" must be');
	const loginAndPassword = message.split("Cannot connect to sms provider");
	if (host?.length > 1) {
		return {
			host: true,
		};
		// eslint-disable-next-line no-else-return
	} else if (port?.length > 1) {
		return {
			port: true,
		};
	} else if (loginAndPassword?.length > 1) {
		return {
			login: true,
			password: true,
		};
	} else {
		console.error(message);
		return "";
	}
};
