/* eslint-disable no-shadow */
import styled from "styled-components";
import { Table, theme } from "uikit";
import { extend } from "lodash";
import React, { forwardRef, memo } from "react";

import LocalHeaderCell from "./components/HeaderCell";

const borderColor = "#cbcfd2";

const TableBase = memo(
	forwardRef<Table.Ref, Table.Props>((props, ref) => (
		<Table ref={ref} {...props} />
	)),
);

const CompactTableBase = extend(TableBase, {
	Context: Table.Context,
	Column: Table.Column,
	ColumnGroup: Table.ColumnGroup,
	Cell: Table.Cell,
	HeaderCell: LocalHeaderCell,
});

const CompactTable = styled(CompactTableBase)`
	.rs-table-row-header {
		background-color: ${theme.colors.button_secondary};

		.rs-table-cell {
			background-color: ${theme.colors.button_secondary};

			border-right: 1px solid ${borderColor};
			border-bottom: none;
		}

		.rs-table-cell-content {
			display: flex !important;

			flex-direction: row !important;
			flex-wrap: nowrap !important;

			align-items: center !important;

			overflow: hidden !important;

			padding: 2.5px 6px !important;

			div {
				font-family: Lato;
				font-size: 11px;
				font-weight: 700;
				line-height: 11px;
				letter-spacing: 0em;
				text-align: left;

				color: #21333f !important;
			}
		}

		:hover {
			background-color: ${theme.colors.button_secondary};

			.rs-table-cell {
				background-color: ${theme.colors.button_secondary};
			}
		}
	}

	.rs-table-row:not(.rs-table-row-header) {
		.rs-table-cell {
			border-right: 1px solid ${borderColor};
			border-bottom: 1px solid ${borderColor};
		}

		.rs-table-cell-content {
			padding: 0;

			font-family: Lato;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			text-align: left;
			letter-spacing: 0em;
			color: #21333f !important;

			cursor: pointer;
		}

		&.founded {
			& .rs-table-cell {
				background-color: #fff7cb !important;
			}
		}

		/* &.selected {
			& .rs-table-cell {
				background-color: #eaf8fe !important;
			}
		} */

		&.not-active {
			& .rs-table-cell,
			& .rs-table-cell-content {
				color: ${theme.colors.disabled_text} !important;
			}
		}
	}

	.active {
		background-color: #fff7cb !important;
		border-color: #d9d9d9;
	}

	.basic {
		background-color: white;
		border-color: #d9d9d9;
	}

	&.test {
		background-color: #ffd8d8;
		border-color: #d9d9d9;
	}

	.Test2 {
		background-color: rgb(235, 235, 235);
		color: rgb(33, 51, 63);
	}
`;

declare namespace CompactTable {
	type Ref = Table.Ref;
	type Props = Table.Props;

	namespace Context {
		type Value = Table.Context.Value;
	}

	namespace Column {
		type Props = Table.Column.Props;
	}

	namespace ColumnGroup {
		type Props = Table.ColumnGroup.Props;
	}

	namespace Cell {
		type Props = Table.Cell.Props;
	}

	namespace HeaderCell {
		type Props = LocalHeaderCell.Props;
	}
}

export default CompactTable;
