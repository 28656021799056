import React, { memo } from "react";
import { Column } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import CurrencyService from "../../../../../../../../../../../../services/Currency";
import TabRoot from "../../components/TabRoot";

import Currency from "./components/Currency";
import Name from "./components/Name";
import Rounding from "./components/Rounding";

const Main: React.FC<Main.Props> = memo(({ currencies, value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const name = valueEditor.useGetter("name");
	const setName = valueEditor.useSetter("name");

	const currency = valueEditor.useGetter("currency");
	const setCurrency = valueEditor.useSetter("currency");

	const additional = valueEditor.useGetter("additionalFields");
	const setAdditional = valueEditor.useSetter("additionalFields");
	const additionalEditor = useObjectEditor(additional, setAdditional);

	const general = additionalEditor.useGetter("general");
	const setGeneral = additionalEditor.useSetter("general");
	const generalEditor = useObjectEditor(general, setGeneral);

	const rounding = generalEditor.useGetter("rounding");
	const setRounding = generalEditor.useSetter("rounding");

	const generalCurrency = generalEditor.useGetter("currency");
	const setGeneralCurrency = generalEditor.useSetter("currency");

	return (
		<TabRoot hasPaddings visible={true}>
			<Column gaps="30px*" maxedWidth maxedHeight>
				<Name value={name} onChange={setName} />
				<Rounding value={rounding} onChange={setRounding} />
				<Currency
					currency={currency}
					setCurrency={setCurrency}
					currencies={currencies}
					value={generalCurrency}
					onChange={setGeneralCurrency}
				/>
			</Column>
		</TabRoot>
	);
});

declare namespace Main {
	interface Props {
		currencies: CurrencyService.Model[];
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		name: Name.Value;
		additionalFields: AdditionalFields;
		currency: CurrencyService.Model;
	}

	interface AdditionalFields {
		general: General;
	}

	interface General {
		rounding: Rounding.Value;
		currency: Currency.Value;
	}
}

export default Main;
