import React, {
	ChangeEvent,
	KeyboardEvent,
	MouseEvent,
	FocusEvent,
	useCallback,
	useMemo,
} from "react";
import { useInternal } from "uikit";
import { useDebouncedCallback } from "use-debounce";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";

import useObjectEditor from "../../../../../hooks/useObjectEditor";
import { useTypedSelector } from "../../../../../redux/store";
import useRefWithUpdater from "../../../../../hooks/useRefWithUpdater";
import { ValueLanguage } from "../../../../../assets/languages/langs";

export const ConstantAccessors = {
	id: "id",
	city: "city",
	house: "house",
	street: "street",
	entrance: "entrance",
	flat: "flat",
	polygon: "polygon",
	setting: "setting",
} as const;
export type TypeAccessors = typeof ConstantAccessors;
export type ValueAccessors =
	(typeof ConstantAccessors)[keyof typeof ConstantAccessors];

export interface Rows {
	id: number;
	inputId: string;
	dataKey: ValueAccessors | string | null;
	colIndex: string | null;
	rowIndex: string | null;
}

export interface Value {
	inputValue: string | null;
	query: string | null;
	allowType: "query" | "reset" | "input" | null;
	open: boolean;
	hasHouse: boolean;
	save: boolean;
	rows: Rows;
}

export interface UseHandleEvents {
	value: Value;
	setValue: (value: Value) => void;
	valueObjectRef: React.MutableRefObject<Value>;
	rowsObjectRef: React.MutableRefObject<Rows>;
	rows: Rows;

	rowId: number;
	setRowId: (value: number) => void;
	rowIndex: string | null;
	setRowIndex: (value: string | null) => void;
	colIndex: string | null;
	setColIndex: (value: string | null) => void;
	inputId: string;
	setInputId: (value: string) => void;
	dataKey: ValueAccessors | string | null;
	setDataKey: (value: ValueAccessors | string | null) => void;

	inputValue: string | null;
	setInputValue: (value: string | null) => void;
	query: string | null;
	setQuery: (value: string | null) => void;

	hasHouse: boolean;
	setHasHouse: (value: boolean) => void;

	allowType: Value["allowType"];
	setAllowType: (value: Value["allowType"]) => void;
	save: boolean;
	setSave: (value: boolean) => void;

	open: boolean;
	setOpen: (value: boolean) => void;
	isOpen: (data: {
		key: ValueAccessors | string;
		col: number;
		row?: number;
		id: number;
	}) => boolean;

	onChangeInputValue: (event: ChangeEvent<HTMLInputElement>) => void;
	onEnterToInput: (event: KeyboardEvent<HTMLInputElement>) => void;
	onExistFromInput: (event: KeyboardEvent<HTMLInputElement>) => void;
	onClickToInput: (event: MouseEvent<HTMLInputElement>) => void;
	onBlurCaptureToInput: (event: FocusEvent<HTMLInputElement>) => void;
	onFocusToInput: (event: FocusEvent<HTMLInputElement>) => void;

	reset: () => void;
	debounceClose: DebouncedState<() => void>;
	debounceQuery: DebouncedState<(value: any) => void>;

	debounceIndexes: DebouncedState<(target: HTMLInputElement) => void>;
	debounceNextElementById: DebouncedState<(elemId: string) => void>;
	nextElementById: (elemId: string) => HTMLElement | null;
	nextFocus: () => void;

	inputRequestDelayMs: number;
	language: ValueLanguage;
}
export interface HandleEventsProps {
	labels?: ValueAccessors[];
	nextElement?: string;
	externalElement?: string;
	initialElement?: string;
	beforeElement?: string;
	arrowLeft?: string;
	arrowRight?: string;
	/** "favorite", "object", "localObject" */
	fol?: string;
}

export type UseHandleEventsProps = (
	props?: HandleEventsProps,
) => UseHandleEvents;

/**
 *  `useHandleEvents`
 */
export const useHandleEvents: UseHandleEventsProps = (
	props = {
		labels: undefined,
	},
) => {
	const {
		labels = ["city", "street", "house"],
		nextElement,
		externalElement,
		arrowRight,
		arrowLeft,
	} = props;

	const language = useTypedSelector((state) => state.session.language);

	const { inputRequestDelayMs } = useTypedSelector(
		(state) => state.settings.map,
	);

	const [value, setValue] = useInternal<Value>({
		inputValue: null,
		query: null,
		open: false,
		hasHouse: false,
		save: false,
		allowType: null,
		rows: {
			id: -1,
			inputId: "",
			dataKey: null,
			colIndex: null,
			rowIndex: null,
		},
	});

	const valueEditor = useObjectEditor<Value>(value, setValue);
	const inputValue = valueEditor.useGetter("inputValue");
	const setInputValue = valueEditor.useSetter("inputValue");
	const query = valueEditor.useGetter("query");
	const setQuery = valueEditor.useSetter("query");
	const open = valueEditor.useGetter("open");
	const setOpen = valueEditor.useSetter("open");
	const hasHouse = valueEditor.useGetter("hasHouse");
	const setHasHouse = valueEditor.useSetter("hasHouse");

	const save = valueEditor.useGetter("save");
	const setSave = valueEditor.useSetter("save");
	const allowType = valueEditor.useGetter("allowType");
	const setAllowType = valueEditor.useSetter("allowType");

	const rows = valueEditor.useGetter("rows");
	const setRows = valueEditor.useSetter("rows");
	const rowsEditor = useObjectEditor(rows, setRows);
	const rowId = rowsEditor.useGetter("id");
	const setRowId = rowsEditor.useSetter("id");
	const dataKey = rowsEditor.useGetter("dataKey");
	const setDataKey = rowsEditor.useSetter("dataKey");
	const colIndex = rowsEditor.useGetter("colIndex");
	const setColIndex = rowsEditor.useSetter("colIndex");
	const rowIndex = rowsEditor.useGetter("rowIndex");
	const setRowIndex = rowsEditor.useSetter("rowIndex");
	const inputId = rowsEditor.useGetter("inputId");
	const setInputId = rowsEditor.useSetter("inputId");
	const rowsObjectRef = useRefWithUpdater(rows);
	const valueObjectRef = useRefWithUpdater(value);

	const checkAriaLabel = useCallback(
		(ariaLabel: string | null): boolean => {
			const exist = labels.find((label) => label === ariaLabel);
			return !!exist;
		},
		[labels],
	);
	const addRowId = useCallback(
		(id: string) => {
			const itemId = parseFloat(id);
			if (itemId > -1) setRowId(itemId);
			else setRowId(-1);
		},
		[setRowId],
	);

	const handelIndexes = useCallback(
		({ ariaColIndex, ariaRowIndex, ariaLabel, id }: HTMLInputElement) => {
			addRowId(id);
			setInputId(id);
			setColIndex(ariaColIndex);
			setRowIndex(ariaRowIndex);
			setDataKey(ariaLabel);
		},
		[addRowId, setColIndex, setDataKey, setInputId, setRowIndex],
	);

	const isOpen = useCallback<UseHandleEvents["isOpen"]>(
		({ key, col, row, id }): boolean => {
			if (open) {
				if (checkAriaLabel(dataKey)) {
					const exist = [
						`${row}` === rowIndex,
						`${col}` === colIndex,
						dataKey === key,
						id === rowId,
					].includes(false);
					return !exist;
				}
			}
			return false;
		},
		[checkAriaLabel, colIndex, dataKey, open, rowId, rowIndex],
	);
	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) elem.focus();
			if (open) setOpen(false);
			return elem;
		},
		[setOpen, open],
	);

	const debounceNextElementById = useDebouncedCallback((elemId: string) => {
		nextElementById(elemId);
	}, 100);

	const nextFocus = useCallback(() => {
		if (nextElement) {
			const elem = nextElementById(nextElement);
			if (!elem && externalElement) {
				nextElementById(externalElement);
			}
			return;
		}
		if (!nextElement && externalElement) {
			nextElementById(externalElement);
		}
	}, [externalElement, nextElement, nextElementById]);

	const debounceIndexes = useDebouncedCallback((target: HTMLInputElement) => {
		handelIndexes(target);
	}, inputRequestDelayMs);

	const debounceSelectText = useDebouncedCallback(
		(target: HTMLInputElement) => {
			if (target) target.select();
		},
		150,
	);

	const debounceOpen = useDebouncedCallback(() => {
		setOpen(true);
	}, 200);

	const debounceClose = useDebouncedCallback(() => {
		setOpen(false);
	}, 100);

	const debounceQuery = useDebouncedCallback((value: string) => {
		setQuery(value.toLowerCase());
		if (value) {
			setOpen(true);
			debounceOpen();
		}
	}, inputRequestDelayMs);

	const debounceNextFocus = useDebouncedCallback(() => {
		nextFocus();
		setOpen(false);
	}, 50);

	const updateExitData = useCallback(
		({ id }: HTMLInputElement) => {
			addRowId(id);
			setSave(true);
		},
		[addRowId, setSave],
	);

	const onChangeInputValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (
				allowType !== "input" &&
				allowType !== "reset" &&
				inputValue &&
				inputValue?.length > 1
			) {
				setAllowType("input");
			}
			const target = event.target as HTMLInputElement;
			setInputValue(target.value);
			debounceIndexes(target);
			debounceQuery(target.value);
			event.stopPropagation();
			event.preventDefault();
		},
		[
			allowType,
			debounceIndexes,
			debounceQuery,
			inputValue,
			setAllowType,
			setInputValue,
		],
	);

	const onEnterToInput = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			const target = event.target as HTMLInputElement;
			const keyEvent = event.key;

			if (keyEvent === "Backspace" && !inputValue?.length) {
				setAllowType("query");
			}
			if (keyEvent === "Backspace" && query === "") {
				setAllowType("query");
			}

			if (keyEvent === "ArrowDown" && !open) setOpen(true);
			if (keyEvent === "ArrowLeft") target?.select();
			if (keyEvent === "ArrowRight") target?.select();

			if (keyEvent === "Tab" || keyEvent === "Enter") {
				handelIndexes(target);
				if (keyEvent === "Tab" && !inputValue && inputValue !== null) {
					debounceOpen();
				}
				if (keyEvent === "Enter" && open) setOpen(false);

				if (target.ariaLabel === "house" && !open) {
					if (!inputValue && !hasHouse) setOpen(true);
				}
			}
		},
		[
			inputValue,
			query,
			open,
			setOpen,
			setAllowType,
			handelIndexes,
			debounceOpen,
			hasHouse,
		],
	);

	const onExistFromInput = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			const keyEvent = event.key;
			const target = event.target as HTMLInputElement;
			if (keyEvent === "ArrowLeft" && arrowLeft) {
				nextElementById(arrowLeft);
				debounceClose();
				target.blur();
			}
			if (keyEvent === "ArrowRight" && arrowRight) {
				nextElementById(arrowRight);
				debounceClose();
				target.blur();
			}
			if (keyEvent === "ArrowDown" && !open) setOpen(true);

			if (keyEvent === "Tab" || keyEvent === "Enter") {
				updateExitData(target);
				debounceClose();

				if (
					target.ariaLabel === "city" ||
					target.ariaLabel === "entrance"
				) {
					setOpen(false);
					debounceNextFocus();
				}
			}

			if (keyEvent === "Tab") {
				if (
					target.ariaLabel === "city" ||
					target.ariaLabel === "house" ||
					target.ariaLabel === "street" ||
					target.ariaLabel === "entrance"
				) {
					setOpen(false);
					debounceNextFocus();
				}
			}

			if (keyEvent === "Enter") {
				target.blur();
				if (
					target.ariaLabel === "city" ||
					target.ariaLabel === "entrance"
				) {
					setOpen(false);
					debounceNextFocus();
				}
			}
		},
		[
			arrowLeft,
			arrowRight,
			debounceClose,
			debounceNextFocus,
			nextElementById,
			open,
			setOpen,
			updateExitData,
		],
	);

	const onClickToInput = useCallback(
		(event: MouseEvent<HTMLInputElement>) => {
			const target = event.target as HTMLInputElement;
			handelIndexes(target);
			setOpen(true);
		},
		[handelIndexes, setOpen],
	);

	const onFocusToInput = useCallback(
		(event: FocusEvent<HTMLInputElement>) => {
			const target = event.target as HTMLInputElement;
			if (target.ariaLabel === "city") debounceSelectText(target);
			target?.select();
			event.stopPropagation();
			event.preventDefault();
		},
		[debounceSelectText],
	);

	const onBlurCaptureToInput = useCallback(
		(event: FocusEvent<HTMLInputElement>) => {
			const target = event.target as HTMLInputElement;
			if (open) {
				if (target.ariaLabel === "city") setSave(true);
				target.blur();
				debounceClose();
				event.stopPropagation();
				event.preventDefault();
			}
		},
		[debounceClose, open, setSave],
	);

	const reset = useCallback(() => {
		setInputValue(null);
		setQuery(null);
		setOpen(false);
		setAllowType(null);
	}, [setAllowType, setInputValue, setOpen, setQuery]);

	return useMemo<UseHandleEvents>(
		() => ({
			value,
			setValue,
			valueObjectRef,

			inputValue,
			setInputValue,
			query,
			setQuery,

			rows,
			rowsObjectRef,

			rowId,
			setRowId,
			rowIndex,
			setRowIndex,
			colIndex,
			setColIndex,
			inputId,
			setInputId,
			dataKey,
			setDataKey,

			allowType,
			setAllowType,
			save,
			setSave,

			open,
			setOpen,
			isOpen,

			hasHouse,
			setHasHouse,

			onChangeInputValue,
			onEnterToInput,
			onExistFromInput,
			onClickToInput,
			onBlurCaptureToInput,
			onFocusToInput,

			nextElementById,
			nextFocus,
			reset,
			debounceClose,
			debounceQuery,
			debounceIndexes,
			debounceNextElementById,
			inputRequestDelayMs,
			language,
		}),
		[
			value,
			setValue,
			valueObjectRef,
			inputValue,
			setInputValue,
			query,
			setQuery,
			rows,
			rowsObjectRef,
			rowId,
			setRowId,
			rowIndex,
			setRowIndex,
			colIndex,
			setColIndex,
			inputId,
			setInputId,
			dataKey,
			setDataKey,
			allowType,
			setAllowType,
			save,
			setSave,
			open,
			setOpen,
			isOpen,
			hasHouse,
			setHasHouse,
			onChangeInputValue,
			onEnterToInput,
			onExistFromInput,
			onClickToInput,
			onBlurCaptureToInput,
			onFocusToInput,
			nextElementById,
			nextFocus,
			reset,
			debounceClose,
			debounceQuery,
			debounceIndexes,
			debounceNextElementById,
			inputRequestDelayMs,
			language,
		],
	);
};
