import { RefObject } from "react";
import { react } from "uikit";

import ObjectForm from "../ObjectForm";

interface Context {
	objectGroupFormRef: RefObject<ObjectForm.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isObjectGroupFormValid =
			this.context?.objectGroupFormRef.current?.validate();

		return isObjectGroupFormValid;
	}
}
