/* eslint-disable react/require-default-props */

import React, { PropsWithChildren, useRef } from "react";
import styled from "styled-components";

interface Props extends PropsWithChildren {
	dimmer?: boolean | string;
	zIndex: number;
}

const StyledDimmer = styled.div<Props>`
	background-color: ${(props) =>
		props.dimmer === true
			? `rgba(0, 0, 0, .5)`
			: props.dimmer || "transparent"};
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: ${(props) => props.zIndex};
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Dimmer: React.FC<Props> = ({ dimmer, zIndex, children }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	return (
		<StyledDimmer ref={ref} dimmer={dimmer} zIndex={zIndex}>
			{children}
		</StyledDimmer>
	);
};

export default Dimmer;
