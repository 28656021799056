import { useCallback } from "react";
import { cloneDeep } from "lodash";

import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import { IOrderPoint } from "../../../../redux/constants/OrdersPage/order";
import orderPage from "../../../../redux/reducers/OrdersPage";
import Order from "../../../../services/Order";

function useActiveOrderPointUpdater() {
	const dispatch = useTypedDispatch();

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const updatePoints = useCallback(
		(points: IOrderPoint[]) => {
			if (!("id" in activeOrder)) {
				return;
			}

			const firstPassenger = activeOrder.passengers?.[0] ?? {};

			const passengers = [
				{
					customer: {
						id: firstPassenger.customer.id,
						companyId: firstPassenger.customer.companyId,
						phones: firstPassenger.customer.phones,

						name: firstPassenger.customer.name,
						surname: firstPassenger.customer.surname,
						fatherName: firstPassenger.customer.fatherName,
						birthday: firstPassenger.customer.birthday,
						carClassId: firstPassenger.customer.carClassId,
						notes: firstPassenger.customer.notes,
					},

					location: firstPassenger.location,

					points: points.map((point) => {
						const feature: IOrderPoint = {
							type: point.type,
							name: point.name,
							country: point.country,
							countryCode: point.countryCode,
							street: point.street,
							streetType: point.streetType,
							settlement: point.settlement,
							settlementType: point.settlementType,
							district: point.district,
							region: point.region,
							coordinates: point.coordinates,
							house: point.house,
							flat: point.flat,
							entrance: point.entrance,
							sector: point.sector,
						};

						return {
							coordinates: point.coordinates,
							feature,
						};
					}),
				},
			] as any;

			const newOrder = cloneDeep(activeOrder);

			newOrder.points = points;

			dispatch(orderPage.actions.setActiveOrder(newOrder));

			Order.update({ id: activeOrder.id, passengers });
		},
		[activeOrder, dispatch],
	);

	return updatePoints;
}

export default useActiveOrderPointUpdater;
