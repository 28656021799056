import React, { useCallback, useMemo } from "react";
import { theme } from "uikit";
import Circle from "../../../../../../../../../Circle";
import { checkSipForActivity } from "./helper";
import Root from "./components/Root";
import SIP from "../../../../../../../../../../services/SIP";
import { useTypedSelector } from "../../../../../../../../../../redux/store";

interface Props {
	data: SIP.Model.Available;
	onSubmit: (sipId: number) => Promise<void>;
}

const Sip: React.FC<Props> = ({ data, onSubmit }) => {
	const { sipValue } = useTypedSelector((state) => state.sipToDispatcher);
	const circleColor = useMemo(() => {
		if (data.sipValue === sipValue) return "#8000FF";
		return checkSipForActivity(data.lastCheckMs)
			? "rgb(76, 175, 80)"
			: theme.colors.negative;
	}, [data.lastCheckMs, data.sipValue, sipValue]);
	const submitSip = useCallback(() => {
		onSubmit(data.sipToDispatcherId);
	}, [data.sipToDispatcherId, onSubmit]);
	return (
		<Root gaps="5px" align="center" onClick={submitSip}>
			<Circle color={circleColor} />
			{data.sipValue}
		</Root>
	);
};

export default Sip;
