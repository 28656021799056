import React, { memo } from "react";

import { IOrderPoint as OrderPoint } from "../../../../../../../../../redux/constants/OrdersPage/order";
import { settlementToString } from "../../../../../../../../../utils/settlementToString";
import CellContentRoot from "../CellContentRoot";

// TODO: is not correct!
const cityTypes = ["город", "city", "місто"];

const SettlementCellContent: React.FC<SettlementCellContent.Props> = memo(
	({ item }) => (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{settlementToString({
				settlement: item.settlement,
				settlementType: cityTypes.includes(item.settlementType)
					? undefined
					: item.settlementType,
				// district: item.district,
				// region: item.region,
			})}
		</CellContentRoot>
	),
);

declare namespace SettlementCellContent {
	interface Props {
		item: OrderPoint;
	}
}

export default SettlementCellContent;
