/* eslint-disable no-shadow */

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
	Column,
	Row,
	CheckBoxWithContent,
	TypographyHeader,
	MultiSelect,
	CheckBox,
} from "uikit";
import { uniq } from "lodash";

import { useTypedSelector } from "../../../../../../../../redux/store";
import EditModalBase from "../../../../../../../../components/EditModal";
import {
	createObjectLanguage,
	createObjectLanguageNames,
	createObjectLanguageNamesFromObject,
} from "../../../../../../../../assets/languages/langs";
import { Names, NamesBase } from "../../../../../../../../components/common";

import Header from "./components/Header";

const Root = styled(Column)`
	width: 1200px;
	height: 700px;
	border-radius: 5px;
	overflow: hidden;
`;

const Content = styled(Column)`
	padding: 16px 20px 50px;
`;

const Fields = styled.div`
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	z-index: 99999;
`;

const StyledLabel = styled.div`
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #21333f;
`;

const StyledTitle = styled(TypographyHeader.Header)`
	padding: 0 !important;
	margin: 0 !important;
`;

const TitledSelect = ({
	title,
	value,
	onSelect,
	onUnselect,
	options,
	error,
}) => (
	<Column gaps="8px" style={{ width: "100%" }}>
		<StyledLabel>{title}</StyledLabel>
		<MultiSelect
			error={error}
			value={value}
			options={options}
			onUnselect={(option) => onUnselect(option?.value)}
			onSelect={(option) => {
				onSelect(option?.value);
			}}
		/>
	</Column>
);

const ServiceModal = ({
	onCancel,
	onSave,
	data: dataProps,
	companyIds,
	taxiServices,
}) => {
	const { t } = useTranslation();
	const nameFormRef = useRef<NamesBase.Controller | null>(null);
	const companies = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);
	const [data, setData] = useState(
		dataProps !== true
			? {
					...dataProps,
					fields: createObjectLanguageNamesFromObject(dataProps.name),
					// fields: {
					// 	uk: dataProps.name.uk,
					// 	en: dataProps.name.en,
					// 	ru: dataProps.name.ru,
					// 	az: dataProps.name?.az || "",
					// 	tr: dataProps.name?.tr || "",
					// },
					companyIds:
						uniq(
							dataProps?.serviceToTaxiServices.map(
								(item) => item.taxiService.company.id,
							),
						) || [],
					transport: dataProps.availableForCar,
					executor: dataProps.availableForExecutor,
					active: dataProps?.active,
					taxiServices:
						dataProps?.serviceToTaxiServices.map(
							(item) => item.taxiService.id,
						) || [],
			  }
			: {
					fields: createObjectLanguageNames(),
					// fields: {
					// 	en: "",
					// 	ru: "",
					// 	uk: "",
					// 	az: "",
					// 	tr: "",
					// },
					transport: false,
					executor: false,
					active: true,
					companyIds,
					taxiServices,
			  },
	);
	const [errors, setErrors] = useState({
		...createObjectLanguage<boolean>(false),
		// en: false,
		// ru: false,
		// uk: false,
		// az: false,
		// tr: false,
		companyIds: false,
		taxiServices: false,
	});

	const companyWrap = useCallback(
		(company) => ({
			key: company?.id,
			value: company?.id,
			label: company?.name?.ru,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[companies],
	);

	const companiesOptions = useMemo(
		() => companies.items.map((company) => companyWrap(company)),
		[companyWrap, companies],
	);
	const taxiServiceWrap = useCallback(
		(service) => ({
			key: service?.id,
			value: service?.id,
			label: service?.settlement?.ru,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[companies],
	);
	const taxiServiceOptions = useMemo(
		() =>
			data.companyIds
				.map((item) =>
					companies.items
						.find((company) => item === company.id)
						?.taxiServices.map((service) =>
							taxiServiceWrap(service),
						),
				)
				.flat(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data.companyIds],
	);

	const taxiServiceIds = data.taxiServices;

	const clearNameFieldsValidations = useCallback(() => {
		setErrors({
			...errors,
			...createObjectLanguage<boolean>(false),
		});
	}, [errors]);
	const changeRuNameField = useCallback(
		(fields) => {
			setData({
				...data,
				fields,
			});
			clearNameFieldsValidations();
		},
		[clearNameFieldsValidations, data],
	);

	useEffect(() => {
		setData({ ...data, taxiServices: taxiServiceIds });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceIds]);

	return (
		<EditModalBase
			onCancel={onCancel}
			onSave={() => {
				const {
					// fields,
					companyIds,
					taxiServices,
				} = data;

				// const arrLanguages = pick(fields, languages);

				// const nameFieldsError = Object.values(arrLanguages)?.every(
				// 	(item) => !item.length,
				// );

				const nameFieldsError = !!nameFormRef.current?.validate();
				setErrors({
					...errors,
					...createObjectLanguage<boolean>(nameFieldsError),
					// en: nameFieldsError,
					// ru: nameFieldsError,
					// uk: nameFieldsError,
					// az: nameFieldsError,
					// tr: nameFieldsError,
					companyIds: !companyIds.length,
					taxiServices: !taxiServices.length,
				});

				if (nameFieldsError && taxiServices.length && companyIds.length)
					onSave(data);
			}}
			footer={
				<Row align="center" gaps="20px*">
					<CheckBox
						label={
							t(
								"pages.preferencesPages.screenDirectory.services.modal.str0",
							) ?? ""
						}
						value={data.active}
						onChange={(value) =>
							setData({ ...data, active: value })
						}
					/>
				</Row>
			}
		>
			<Root sizes="auto 1fr auto">
				<Header
					title={
						dataProps !== true
							? t(
									"pages.preferencesPages.screenDirectory.services.modal.str200",
							  ) ?? ""
							: t(
									"pages.preferencesPages.screenDirectory.services.modal.str201",
							  ) ?? ""
					}
				/>
				<Content>
					<Fields>
						<Row gaps="22px" justify={"space-between"}>
							<TitledSelect
								title={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str1",
									) ?? ""
								}
								error={errors.companyIds}
								options={companiesOptions}
								value={data.companyIds}
								onUnselect={(value) => {
									const services = companies.items
										.find((company) => value === company.id)
										?.taxiServices.map((item) => item.id);

									setData({
										...data,
										companyIds: data.companyIds.filter(
											(v) => v !== value,
										),
										taxiServices: data.taxiServices.filter(
											(item) => !services.includes(item),
										),
									});
								}}
								onSelect={(value) => {
									setData({
										...data,
										companyIds: [...data.companyIds, value],
									});
								}}
							/>
							<TitledSelect
								title={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str2",
									) ?? ""
								}
								error={errors.taxiServices}
								options={taxiServiceOptions}
								value={taxiServiceIds}
								onUnselect={(value) => {
									setData({
										...data,
										taxiServices: taxiServiceIds.filter(
											(v) => v !== value,
										),
									});
								}}
								onSelect={(value) => {
									setData({
										...data,
										taxiServices: [
											...taxiServiceIds,
											value,
										],
									});
								}}
							/>
						</Row>
						<StyledTitle variant="h6">
							{t(
								"pages.preferencesPages.screenDirectory.services.modal.str100",
							) ?? ""}
						</StyledTitle>
						<Column gaps="22px*">
							<Names
								require
								ref={nameFormRef}
								value={data?.fields}
								onChange={changeRuNameField}
								placeholder="pages.preferencesPages.screenDirectory.services.modal.str202"
							/>

							{/* <RowTitledTextBox
								title={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str3",
									) ?? ""
								}
								error={errors.ru}
								value={data?.fields?.ru}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str202",
									) ?? ""
								}
								onChange={(title) =>
									setData({
										...data,
										fields: {
											...data.fields,
											ru: title,
										},
									})
								}
							/>
							<RowTitledTextBox
								title={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str4",
									) ?? ""
								}
								error={errors.uk}
								value={data?.fields?.uk}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str202",
									) ?? ""
								}
								onChange={(title) =>
									setData({
										...data,
										fields: {
											...data.fields,
											uk: title,
										},
									})
								}
							/>
							<RowTitledTextBox
								title="En"
								error={errors.en}
								value={data?.fields?.en}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.services.modal.str202",
									) ?? ""
								}
								onChange={(title) =>
									setData({
										...data,
										fields: {
											...data.fields,
											en: title,
										},
									})
								}
							/> */}
						</Column>
						<StyledLabel>
							{t(
								"pages.preferencesPages.screenDirectory.services.modal.str101",
							) ?? ""}
						</StyledLabel>
						<Row gaps="22px">
							<CheckBoxWithContent
								gap="10px"
								value={data.executor}
								onChange={(status) =>
									setData({ ...data, executor: status })
								}
							>
								{t(
									"pages.preferencesPages.screenDirectory.services.modal.str130",
								) ?? ""}
							</CheckBoxWithContent>
							<CheckBoxWithContent
								gap="10px"
								value={data.transport}
								onChange={(status) =>
									setData({
										...data,
										transport: status,
									})
								}
							>
								{t(
									"pages.preferencesPages.screenDirectory.services.modal.str131",
								) ?? ""}
							</CheckBoxWithContent>
						</Row>
					</Fields>
				</Content>
			</Root>
		</EditModalBase>
	);
};

export default ServiceModal;
