import React, {
	Dispatch,
	memo,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBoxWithContent,
	Column,
	DateAndTimePicker,
	Option,
	react,
	Row,
	Select,
	Stepper,
	useRefWithSetter,
} from "uikit";

import Language from "../../../../../../../../../../../../../../../../services/Language";
import ExecutorRateClassifier from "../../../../../../../../../../../../../../../../services/ExecutorRateClassifier";
import ExecutorRateSubscription from "../../../../../../../../../../../../../../../../services/ExecutorRateSubscription";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import useDatePickerLocale from "../../../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../hooks";
import {
	ConstantFormat,
	DateFns,
} from "../../../../../../../../../../../../../../../../utils/DateFns";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import {
	NameBase,
	Name,
	StyledP,
	StyledRow,
	ValueStepper,
	StyledSpan,
	StyledColumn,
} from "../../../../../../../../../../../../../../../../components/common";

import InternalController from "./Controller";
import { modeOptions, periodUnitOptions } from "./data";
import Root from "./components/Root";
import InternalSettings from "./components/FeeSetting";

export const marginLeft = { marginLeft: 30 };

export interface ContentErrors {
	classifierId: boolean;
	fee: boolean;
}

const ContentBase: React.FC<Content.Props> = react.withController<
	Content.PropsBase,
	Content.Controller
>(({ value, language, onChange, controller, setTypeName }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();
	const [nameRef, setNameRef] = useRefWithSetter<NameBase.Ref | null>(null);
	const classifierData = useModelSubscribe({}, ExecutorRateClassifier);
	const dateFns = useMemo(() => new DateFns(), []);

	const [errors, setErrors] = useState<ContentErrors>({
		classifierId: false,
		fee: false,
	});

	const classifierOptions = useMemo<Option<number>[]>(
		() =>
			classifierData.models
				.filter(
					(classifier) =>
						classifier.type ===
						ExecutorRateClassifier.Model.Type.Subscription,
				)
				.map((classifier) => ({
					key: classifier.id,
					value: classifier.id,
					label: classifier.name[language],
				})),
		[classifierData.models, language],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const nameValue = valueEditor.useGetter("name");
	const nameOnChange = valueEditor.useSetter("name");

	const classifierIdValue = valueEditor.useGetter("classifierId");
	const classifierIdOnChange = valueEditor.useSetter("classifierId");
	const onSelect = useCallback(
		(option: Option<number>) => {
			setErrors((prev) => ({
				...prev,
				classifierId: false,
			}));
			setTypeName(option.label);
			classifierIdOnChange(option.value);
		},
		[classifierIdOnChange, setTypeName],
	);

	const startDateValue = valueEditor.useGetter("startDate");
	const startDateOnChange = valueEditor.useSetter("startDate");

	const periodEditor = valueEditor.usePropertyEditor("period");

	const periodUnitValue = periodEditor.useGetter("unit");
	const periodUnitOnChange = periodEditor.useSetter("unit");

	const periodValueValue = periodEditor.useGetter("value");
	const periodValueOnChange = periodEditor.useSetter("value");

	const modeValue = valueEditor.useGetter("mode");
	const modeOnChange = valueEditor.useSetter("mode");

	const settingsValue = valueEditor.useGetter("settings");
	const settingsOnChange = valueEditor.useSetter("settings");

	const settingsEditor = useObjectEditor(settingsValue, settingsOnChange);
	const chargeOnOrderCompletion = settingsEditor.useGetter(
		"chargeOnOrderCompletion",
	);
	const setChargeOnOrderCompletion = settingsEditor.useSetter(
		"chargeOnOrderCompletion",
	);

	const feeEditor = valueEditor.usePropertyEditor("fee");

	const feeValueValue = feeEditor.useGetter("value");
	const feeValueOnChange = feeEditor.useSetter("value");

	const tariffOptions = useTypedSelector(
		(state) => state.additionalTariff.options,
	);
	const precision = useMemo(
		() => tariffOptions?.additionalFields?.general?.rounding?.precision,
		[tariffOptions?.additionalFields?.general?.rounding?.precision],
	);

	const locale = useDatePickerLocale();

	const onSelectPeriod = useCallback(
		(option: Option<(typeof periodUnitOptions)[number]["value"]>) => {
			if (
				option.value !== ExecutorRateSubscription.Model.PeriodUnit.Daily
			) {
				periodValueOnChange(1);
			}

			if (option.value === "shift" && periodUnitValue !== "shift") {
				modeOnChange(undefined);
			}

			if (option.value !== "shift" && periodUnitValue === "shift") {
				modeOnChange(undefined);
			}

			periodUnitOnChange(option.value);
		},
		[
			modeOnChange,
			periodUnitOnChange,
			periodUnitValue,
			periodValueOnChange,
		],
	);

	const onSelectMode = useCallback(
		(option: Option<(typeof modeOptions)[number]["value"]>) => {
			modeOnChange(option.value);
		},
		[modeOnChange],
	);

	const onChangeStepperValue = useCallback(
		(value) => {
			periodValueOnChange(value);
		},
		[periodValueOnChange],
	);

	const getDayOfWeek = useCallback(
		(date) => {
			const dayOfWeek = dateFns.formatTime(
				date,
				ConstantFormat.day,
				language,
			);
			return dayOfWeek;
		},
		[dateFns, language],
	);

	const feeModesOptions = useMemo(() => {
		if (periodUnitValue === "shift") {
			return modeOptions.filter(
				(mode) =>
					mode.value ===
						ExecutorRateSubscription.Model.Action.AfterShiftOpen ||
					mode.value ===
						ExecutorRateSubscription.Model.Action.AfterShiftClose,
			);
		}
		return modeOptions.filter(
			(mode) =>
				mode.value !==
					ExecutorRateSubscription.Model.Action.AfterShiftOpen &&
				mode.value !==
					ExecutorRateSubscription.Model.Action.AfterShiftClose,
		);
	}, [periodUnitValue]);

	const limitDay = useMemo(() => {
		if (!startDateValue) return 1;
		if (periodUnitValue !== "daily") return 1;
		return dateFns.fns.getDaysInMonth(startDateValue);
	}, [dateFns.fns, periodUnitValue, startDateValue]);

	const datePeriod = useMemo(() => {
		if (!startDateValue) return "";

		let days = periodValueValue;

		if (periodUnitValue === "weekly") days = 7;

		if (periodUnitValue === "monthly") {
			days = dateFns.fns.getDaysInMonth(startDateValue);
		}

		const timePeriod = dateFns.getCurrentPeriod(startDateValue, days);

		const formatTime =
			language === "en"
				? ConstantFormat.MDYHMSA
				: ConstantFormat.MDYHM_UK;

		const from = dateFns.formatTime(timePeriod.from, formatTime);
		const to = dateFns.formatTime(timePeriod.to, formatTime);

		return `${from} - ${to}`;
	}, [dateFns, language, periodUnitValue, periodValueValue, startDateValue]);

	const disableOrderCompletion = useMemo(() => {
		if (
			modeValue ===
				ExecutorRateSubscription.Model.Action.AfterOrderClosed ||
			modeValue ===
				ExecutorRateSubscription.Model.Action.AfterOrderAssigned
		) {
			return true;
		}

		return false;
	}, [modeValue]);

	useLayoutEffect(() => {
		if (disableOrderCompletion && !chargeOnOrderCompletion) {
			setChargeOnOrderCompletion(true);
		}
	}, [
		chargeOnOrderCompletion,
		disableOrderCompletion,
		modeValue,
		setChargeOnOrderCompletion,
	]);

	controller.setContext({
		nameRef,
		classifierIdValue,
		setErrors,
		fee: feeValueValue,
	});

	return (
		<Root hasPaddings>
			<Column gaps="27px*">
				<Row gaps="8px">
					<Name
						ref={setNameRef}
						name={nameValue || ""}
						setName={nameOnChange}
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str200",
							) ?? ""
						}
					/>

					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str0",
							) ?? ""
						}
					>
						<Select
							error={errors.classifierId}
							value={classifierIdValue}
							options={classifierOptions}
							placeholder={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str0",
								) ?? ""
							}
							onSelect={onSelect}
						/>
					</LabeledField>
				</Row>
				<Row gaps="8px">
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str2",
							) ?? ""
						}
						gaps="8px*"
					>
						<StyledRow gap="10px" alignItems="center">
							<DateAndTimePicker
								value={startDateValue}
								onChange={(value) =>
									startDateOnChange(value ?? undefined)
								}
								locale={locale}
								step={60}
							/>
							<StyledP cursor="default">
								{getDayOfWeek(startDateValue)}
							</StyledP>
						</StyledRow>
						<StyledRow p="0 10px" alignItems="center">
							<StyledP cursor="default">{datePeriod}</StyledP>
						</StyledRow>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str3",
							) ?? ""
						}
					>
						<Row align="center" gaps="5px" sizes="150px! auto!">
							<Select
								value={periodUnitValue}
								options={periodUnitOptions}
								placeholder={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str3",
									) ?? ""
								}
								onSelect={onSelectPeriod}
							/>
							<Stepper
								min={1}
								max={limitDay}
								value={periodValueValue}
								onChange={onChangeStepperValue}
								disabled={
									periodUnitValue !==
									ExecutorRateSubscription.Model.PeriodUnit
										.Daily
								}
							/>
						</Row>
					</LabeledField>
				</Row>
				<LabeledField
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str5",
						) ?? ""
					}
				>
					<Select
						value={modeValue}
						onSelect={onSelectMode}
						options={feeModesOptions}
						placeholder={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str5",
							) ?? ""
						}
					/>
				</LabeledField>
				{/* <InternalSettings
					value={settingsValue}
					onChange={settingsOnChange}
				/> */}
				<CheckBoxWithContent
					value={chargeOnOrderCompletion}
					gap="10px"
					onChange={setChargeOnOrderCompletion}
					disabled={disableOrderCompletion}
				>
					{t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str150",
					) ?? ""}
				</CheckBoxWithContent>
				<StyledColumn>
					{errors.fee && (
						<StyledSpan colors="red">
							{t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str201",
							) ?? ""}
						</StyledSpan>
					)}
					<Row gaps="10px*" align="center" sizes="auto!*3 200px!">
						<span>
							{t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.content.str202",
							) ?? ""}
							:
						</span>
						<ValueStepper
							value={feeValueValue}
							min={0.01}
							decimalCount={precision}
							onChange={(value) => {
								setErrors((prev) => ({ ...prev, fee: false }));
								feeValueOnChange(value ?? 0);
							}}
						/>
						<span>{currencyGlobalSettings}</span>
					</Row>
				</StyledColumn>
			</Column>
		</Root>
	);
}, InternalController);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;

		language: Language;

		onChange: Dispatch<Value>;

		setTypeName: Dispatch<string>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		classifierId?: number;
		name?: string;
		startDate?: Date;
		period: Value.Period;
		mode?: (typeof modeOptions)[number]["value"];
		settings: {
			chargeOnOrderCompletion: boolean;
		};
		fee: Value.Fee;
	}

	namespace Value {
		interface Period {
			unit: (typeof periodUnitOptions)[number]["value"];
			value: number;
		}

		interface Fee {
			value: number;
		}
	}

	namespace Settings {
		type Condition = InternalSettings.Condition;
		type OrderType = InternalSettings.OrderType;
	}
}

export default Content;
