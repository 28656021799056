import React, { Dispatch, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../../../../services/Language";

import Title from "./components/Title";
import Root from "./components/Root";
import Form from "./components/Form";

const Content = forwardRef<Content.Controller, Content.Props>(
	({ value, language, onChange }, ref) => {
		const { t } = useTranslation();
		return (
			<Root gaps="24px">
				<Title>
					{value.id
						? t(
								"pages.preferencesPages.screenDirectory.priceZones.editModal.content.str200",
						  ) ?? ""
						: t(
								"pages.preferencesPages.screenDirectory.priceZones.editModal.content.str201",
						  ) ?? ""}{" "}
					{t(
						"pages.preferencesPages.screenDirectory.priceZones.editModal.content.str202",
					) ?? ""}
				</Title>
				<Form
					ref={ref}
					value={value}
					language={language}
					onChange={onChange}
				/>
			</Root>
		);
	},
);

declare namespace Content {
	interface Value extends Form.Value {
		id?: number;
	}

	interface Props {
		value: Value;

		language: Language;

		onChange: Dispatch<Value>;
	}

	type Controller = Form.Controller;
}

export default Content;
