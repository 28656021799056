import styled from "styled-components";

import { StyledRow } from "../../../../../../common";

const Root = styled(StyledRow)<{ width: number }>`
	position: relative;
	width: ${({ width }) => (width ? `${width}px` : "auto")};
	height: 34px;

	transform: translateX(-12px);

	& div:nth-child(1) {
		left: 0;
	}
	& div:nth-child(2) {
		left: 17.6px;
	}
	& div:nth-child(3) {
		left: 35.2px;
	}
	& div:nth-child(4) {
		left: 52.8px;
	}
	& div:nth-child(5) {
		left: 70.4px;
	}
`;
export default Root;
