import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "uikit";
import TariffCalculation from "../../../../../Modal/components/Content/components/TariffCalculation";
import tPath from "../../../../../../constants/tPath";

const TariffCalculationOutput: React.FC<TariffCalculationOutput.Props> = memo(
	({ value }) => {
		const { t } = useTranslation();

		return value ? (
			<>
				<Row gaps="3px">
					{t(`${tPath}.modal.tariffCalculation.options.${value}`)}
				</Row>
				<span>/</span>
			</>
		) : null;
	},
);

declare namespace TariffCalculationOutput {
	interface Props {
		value: TariffCalculation.Value;
	}
}

export default TariffCalculationOutput;
