import React, { Dispatch, RefAttributes, memo } from "react";
import { react, useRefWithSetter } from "uikit";

import CarClass from "../../../../../../../../../../services/CarClass";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { StyledColumn } from "../../../../../../../../../../components/common";

import InternalController from "./Controller";
import MainData from "./components/MainData";
import SettingsData from "./components/SettingsData";

const MainBase = react.withController<Main.PropsBase, Main.Controller>(
	({ controller, value, disabled, onChange, classModels }) => {
		const [personDataRef, setPersonDataRef] =
			useRefWithSetter<MainData.Ref | null>(null);
		const [workDataRef, setWorkDataRef] =
			useRefWithSetter<SettingsData.Ref | null>(null);

		const valueEditor = useObjectEditor(value, onChange);

		const mainDataValue = valueEditor.usePicker([
			"companyIds",
			"taxiServiceIds",
			"taxiServices",
			"name",
			"shortName",
			"useTextColor",
			"textColor",
			"priority",
			"backgroundColor",
			"useBackgroundColor",
			"distributable",
			"root",
		]);

		const settingsValue = valueEditor.usePicker([
			"id",
			"distributable",
			"compatibleCarClassIds",
			"compatibleCarClassIdsToBroadcastable",
			"broadcastingCompatibleMode",
			"broadcastable",
			"distributionCompatibleMode",
			"distributable",
		]);

		const assigner = valueEditor.useAssigner();

		controller.setContext({
			mainDataRef: personDataRef,
			settingsDataRef: workDataRef,
		});
		return (
			<StyledColumn w="100%" h="100%" gap="20px" p="1rem">
				<MainData
					ref={setPersonDataRef}
					disabled={disabled}
					value={mainDataValue}
					onChange={assigner}
				/>

				<SettingsData
					ref={setWorkDataRef}
					value={settingsValue}
					onChange={assigner}
					disabled={disabled}
					classModels={classModels}
				/>
			</StyledColumn>
		);
	},
	InternalController,
);

const Main = memo(MainBase);

declare namespace Main {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends MainData.Value, SettingsData.Value {}

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		disabled: boolean;
		classModels: CarClass.Model[];
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Main;
