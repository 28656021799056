/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import Language from "../../../../../../../../../../services/Language";
import InternalController from "./Controller";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, type, disabled = false, language, onChange }) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"carBaseTypeName",
			"carBrandName",
			"carModelName",
			"carBodyTypeName",
			"carClassIds",
		]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						onChange={anyTabOnChange}
					/>
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				disabled,
				type,
				language,
				anyTabOnChange,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type = "main";

	type Value = MainTab.Value;

	interface PropsBase {
		value: Value;

		type: Type;
		disabled?: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
