import React from "react";

import useGetGPSHistory from "../../../../../../../hooks/useGetGPSHistory";
import { Player } from "../../../../../../../components/MapGPS/components";

const PlayerControl: React.FC = () => {
	const {
		data,
		player,
		setPlayerToggle,
		setPlayerPosition,
		setPlayerMax,
		setPlayerNext,
		setPlayerDate,
		setPlayerSpeed,
		setPlayerDuration,
		setPlayerRun,
	} = useGetGPSHistory();

	return (
		<Player
			data={data}
			player={player}
			setPlayerToggle={setPlayerToggle}
			setPlayerPosition={setPlayerPosition}
			setPlayerMax={setPlayerMax}
			setPlayerNext={setPlayerNext}
			setPlayerDate={setPlayerDate}
			setPlayerSpeed={setPlayerSpeed}
			setPlayerDuration={setPlayerDuration}
			setPlayerRun={setPlayerRun}
		/>
	);
};

export default PlayerControl;
