import React, { memo, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";
import { stringifyPoint } from "../../../../../../redux/constants/OrdersPage/order";
import getArrayChanges from "../../../../../../utils/getArrayChanges";
import Mark from "../../Mark";

const PointDiff: React.FC<PointDiff.Props> = ({ change }): JSX.Element => {
	const { t } = useTranslation();
	const pointDiff = getArrayChanges(change.previous, change.actual, {
		useChanged: true,
	});
	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	return (
		<>
			{pointDiff.removedItems.map((point, i) => {
				const address = stringifyPoint(point, "full", pointOnMap);

				return (
					<div key={`${i}-removed`}>
						<Trans
							i18nKey={`change_history.orders.fields.point`}
							context="remove"
							values={{ value: address }}
							components={{ mark: <Mark /> }}
						/>
					</div>
				);
			})}

			{pointDiff.changedItemIndexes.map((index, i) => {
				const prevAddress = stringifyPoint(
					change.previous[index],
					"full",
					pointOnMap,
				);
				const actualAddress = stringifyPoint(
					change.actual[index],
					"full",
					pointOnMap,
				);

				return (
					<div key={`${i}-changed`}>
						<Trans
							i18nKey={`change_history.orders.fields.point`}
							context="change"
							values={{
								prev: prevAddress,
								new: actualAddress,
							}}
							components={{ mark: <Mark /> }}
						/>
					</div>
				);
			})}

			{pointDiff.addedItems.map((point, i) => {
				const address = stringifyPoint(point, "full", pointOnMap);

				return (
					<div key={`${i}-add`}>
						<Trans
							i18nKey={`change_history.orders.fields.point`}
							context="add"
							values={{ value: address }}
							components={{ mark: <Mark /> }}
						/>
					</div>
				);
			})}
		</>
	);
};

declare namespace PointDiff {
	interface Props {
		change: History.Change & History.Field.PointChange;
	}
}

export const PointDiffMemo = memo(PointDiff);
export default PointDiff;
