import React, { memo } from "react";
import styled from "styled-components";
import { Icon } from "uikit";
import { useTypedSelector } from "../../../../../redux/store";

const Root = styled.div<{ bottom: number | false | undefined | null }>`
	position: absolute;
	right: 12px;
	bottom: 119px;
	bottom: ${({ bottom }) => (bottom ? `${bottom + 119}px` : "119px")};
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 56px;
	min-height: 56px;
	max-width: 56px;
	max-height: 56px;
	background-color: #f2f4f7;
	border-radius: 50%;
	box-shadow: 0px 9px 18px 0px #00000026;
	cursor: pointer;
`;

const ScrollButton: React.FC<ScrollButton.Props> = ({ onClick }) => {
	const { answerParentID, answerComponentHeight } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);
	return (
		<Root
			onClick={onClick}
			bottom={answerParentID && answerComponentHeight}
		>
			<Icon id="chevron-down" size={24} colors={["#101828"]} />
		</Root>
	);
};

declare namespace ScrollButton {
	interface Props {
		onClick: () => void;
	}
}

export default memo(ScrollButton);
