import React, { Dispatch, RefAttributes, memo } from "react";
import { CheckBox, Column, Row, Select, TextBox, react } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import tonAndNpiData from "../../mocks";

import InternalController from "./Controller";

const inputWidth = "538px";
const selectWidth = "563px";

const TonAndNpiDataBase = react.withController<
	TonAndNpiData.PropsBase,
	TonAndNpiData.Controller
>(({ controller, value, onChange, validation }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const isSystemTypeValue = valueEditor.useGetter("isSystemType");
	const isSystemTypeOnChange = valueEditor.useSetter("isSystemType");

	const systemTypeValue = valueEditor.useGetter("systemType");
	const systemTypeOnChange = valueEditor.useSetter("systemType");

	const sourceAddrTonValue = valueEditor.useGetter("sourceAddrTon");
	const sourceAddrTonOnChange = valueEditor.useSetter("sourceAddrTon");

	const sourceAddrNpiValue = valueEditor.useGetter("sourceAddrNpi");
	const sourceAddrNpiOnChange = valueEditor.useSetter("sourceAddrNpi");

	const destAddrTonValue = valueEditor.useGetter("destAddrTon");
	const destAddrTonOnChange = valueEditor.useSetter("destAddrTon");

	const destAddrNpiValue = valueEditor.useGetter("destAddrNpi");
	const destAddrNpiOnChange = valueEditor.useSetter("destAddrNpi");

	const alphaNameValue = valueEditor.useGetter("alphaName");
	const alphaNameOnChange = valueEditor.useSetter("alphaName");

	return (
		<Column gaps="20px*6">
			<Row justify="space-between">
				<CheckBox
					label={`${
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.tonAndNpiData.str200",
						) ?? ""
					} (system_type)`}
					value={isSystemTypeValue}
					onChange={isSystemTypeOnChange}
				/>
				<TextBox.TextBox
					width={inputWidth}
					value={systemTypeValue}
					onChange={systemTypeOnChange}
				/>
			</Row>
			<Row justify="space-between">
				<span>
					TON{" "}
					{t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.tonAndNpiData.str201",
					) ?? ""}{" "}
					(source_addr_ton)*
				</span>
				<Select
					style={{ width: selectWidth }}
					options={tonAndNpiData.ton}
					value={sourceAddrTonValue}
					onChange={sourceAddrTonOnChange as any}
				/>
			</Row>
			<Row justify="space-between">
				<span>
					NPI{" "}
					{t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.tonAndNpiData.str201",
					) ?? ""}{" "}
					(source_addr_npi)*
				</span>
				<Select
					style={{ width: selectWidth }}
					options={tonAndNpiData.npi}
					value={sourceAddrNpiValue}
					onChange={sourceAddrNpiOnChange as any}
				/>
			</Row>
			<Row justify="space-between">
				<span>
					TON{" "}
					{t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.tonAndNpiData.str203",
					) ?? ""}{" "}
					(dest_addr_ton)*
				</span>
				<Select
					style={{ width: selectWidth }}
					options={tonAndNpiData.ton}
					value={destAddrTonValue}
					onChange={destAddrTonOnChange as any}
				/>
			</Row>
			<Row justify="space-between">
				<span>
					NPI{" "}
					{t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.tonAndNpiData.str203",
					) ?? ""}{" "}
					(dest_addr_npi)*
				</span>
				<Select
					style={{ width: selectWidth }}
					options={tonAndNpiData.npi}
					value={destAddrNpiValue}
					onChange={destAddrNpiOnChange as any}
				/>
			</Row>
			<Row justify="space-between">
				<span>
					{t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.tonAndNpiData.str205",
					) ?? ""}
					*
				</span>
				<TextBox.TextBox
					width={inputWidth}
					value={alphaNameValue}
					onChange={(e) => {
						alphaNameOnChange(e);
						delete validation.alphaName;
					}}
					error={validation.alphaName}
				/>
			</Row>
		</Column>
	);
}, InternalController);

const TonAndNpiData = memo(TonAndNpiDataBase);

declare namespace TonAndNpiData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		isSystemType: boolean;
		systemType: string;
		sourceAddrTon: string;
		sourceAddrNpi: string;
		destAddrTon: string;
		destAddrNpi: string;
		alphaName: string;
	}

	interface PropsBase {
		value: Value;
		validation: any;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default TonAndNpiData;
