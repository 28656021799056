import { RefObject } from "react";
import { react } from "uikit";

import AuthData from ".";

interface Context {
	authDataRef: RefObject<AuthData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isAuthDataValid = !!this.context?.authDataRef.current?.validate();
		// const isFullNameValid = !!this.context?.fullNameRef.current?.validate();

		return isAuthDataValid;
		// return isAuthorizationValid && isFullNameValid;
	}
}
