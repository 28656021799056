/* eslint-disable no-shadow */

import React, { Dispatch, useMemo } from "react";
import { Option } from "uikit";
import { clone } from "lodash";
import Root from "./components/Root";
import Item from "./components/Item";
import Draggable from "./components/Draggable";

const List: React.FC<List.Props> = ({ value, items, onChange }) => {
	const rootOptions = useMemo(
		() =>
			items.map<Option<string>>((item) => ({
				key: item.id,
				value: item.id,
				label: item.label,
			})),
		[items],
	);

	const internalValue = useMemo(
		() => value.filter((id) => !!items.find((item) => item.id === id)),
		[items, value],
	);

	return (
		<>
			<div style={{ position: "absolute" }}></div>
			<Root
				value={internalValue}
				options={rootOptions}
				onChange={onChange}
				renderRow={(option, index, providedProps) => (
					// eslint-disable-next-line no-sequences
					<Draggable
						key={option.key}
						{...providedProps.rootProps}
						{...providedProps.dragHandleProps}
						index={
							providedProps.rootProps.style.position === "fixed"
								? index
								: 0
						}
					>
						<Item
							label={option.label}
							onDelete={() => {
								const newValue = clone(internalValue);

								newValue.splice(index, 1);

								onChange(newValue);
							}}
						/>
					</Draggable>
				)}
			/>
		</>
	);
};

declare namespace List {
	interface Item {
		id: string;
		label: string;
	}

	type Value = string[];

	interface Props {
		value: Value;

		items: Item[];

		onChange: Dispatch<Value>;
	}
}

export default List;
