import styled from "styled-components";

const Root = styled.div<Root.Props>`
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

declare namespace Root {
	interface Props {
		disabled: boolean;
	}
}

export default Root;
