import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
	ordersAction as orders,
	Orders,
} from "../../../../../../../redux/reducers/Orders";
import { StyledRow } from "../../../../../../common";
import {
	NAVIGATION_TYPES,
	useNavigationTitle,
} from "../../../../constants/business";

import { Root, NavButton } from "./components";

const GroupNavButtons = styled(StyledRow)`
	padding: 0 10px;
`;

const NavBar: React.FC<NavBar.Props> = (props) => {
	const { navigation, transferTab, tabIndex } = props;

	const NAVIGATION_TITLES = useNavigationTitle();

	const dispatch = useDispatch();

	const onSelectNavigation = useCallback(
		(type: Orders.Chat.UsersModalTabType) => {
			dispatch(orders.chats.chat.setUserModalTab(type));
		},
		[dispatch],
	);

	useEffect(() => {
		const navBar = document.querySelector('[itemId="transferNav"]');

		const horizontalScroll = (event) => {
			if (event.deltaY !== 0) {
				event.preventDefault();
				if (navBar) {
					navBar.scrollLeft += event.deltaY / 5;
				}
			}
		};

		if (navBar) {
			navBar.addEventListener("wheel", horizontalScroll);
		}

		return () => {
			navBar?.removeEventListener("wheel", horizontalScroll);
		};
	}, []);

	useEffect(() => {
		const navBar = document.querySelector('[itemId="transferNav"]');

		if (navBar) {
			switch (transferTab) {
				case NAVIGATION_TYPES.EXECUTORS: {
					navBar.scrollLeft = -100;
					break;
				}
				case NAVIGATION_TYPES.DISPATCHERS: {
					navBar.scrollLeft = 0;
					break;
				}
				case NAVIGATION_TYPES.EXCHANGE: {
					navBar.scrollLeft = 100;
					break;
				}
				default: {
					break;
				}
			}
		}
	}, [transferTab]);

	return (
		<Root gap="24px*" itemID="transferNav" selected={tabIndex === 1}>
			<GroupNavButtons>
				<NavButton
					active={navigation === NAVIGATION_TYPES.EXECUTORS}
					text={NAVIGATION_TITLES.EXECUTORS}
					onChangeNavigation={onSelectNavigation}
					type={NAVIGATION_TYPES.EXECUTORS}
				/>
				<NavButton
					active={navigation === NAVIGATION_TYPES.DISPATCHERS}
					text={NAVIGATION_TITLES.DISPATCHERS}
					onChangeNavigation={onSelectNavigation}
					type={NAVIGATION_TYPES.DISPATCHERS}
					disabled={true}
				/>
				<NavButton
					active={navigation === NAVIGATION_TYPES.EXCHANGE}
					text={NAVIGATION_TITLES.EXCHANGE}
					onChangeNavigation={onSelectNavigation}
					type={NAVIGATION_TYPES.EXCHANGE}
					disabled={true}
				/>
			</GroupNavButtons>
		</Root>
	);
};

declare namespace NavBar {
	interface Props {
		navigation: Orders.Chat.UsersModalTabType;
		transferTab: Orders.Chat.UsersModalTabType;
		tabIndex: number;
	}
}

export default NavBar;
