import styled, { css } from "styled-components";
import { Row, theme } from "uikit";

const draggableStyle = css`
	height: 36px;

	padding-inline: 8px 12px;

	cursor: grab;
`;

const nonDraggableStyle = css`
	height: 56px;

	padding: 16px 24px;
`;

const Root = styled(Row)<Root.Props>`
	background: ${theme.colors.white};

	${({ draggable }) => (draggable ? draggableStyle : nonDraggableStyle)}

	overflow: hidden;
`;

declare namespace Root {
	interface Props extends Row.Props {
		draggable: boolean;
	}
}

export default Root;
