import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useMemo,
} from "react";
import { Column, Row, Select, Stepper } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { BaseRateAdjustmentType } from "../../../../../../../../../../../../services/Tariff/enums";
import { ValueStepper } from "../../../../../../../../../../../../components/common";

import tPath from "../../../../../../../../constants/tPath";
import { surchargesOptions } from "../../../../../../constants/options";
import PassengersSurcharge from "../../../..";

import Root from "./components/Root";

const Content: React.FC<Content.Props> = memo((props) => {
	const path = `${tPath}.mainModal.tabs.main.surcharges`;
	const { root, typeError, setTypeError, value, onChange, intervals } = props;

	const { t } = useTranslation();

	const editor = useObjectEditor(value, onChange);

	const count = editor.useGetter("count");
	const setCount = editor.useSetter("count");

	const type = editor.useGetter("type");
	const setType = editor.useSetter("type");
	const handleTypeChange = useCallback(
		(newType) => {
			setTypeError(false);
			setType(newType);
		},
		[setType, setTypeError],
	);

	const cost = editor.useGetter("value");
	const setCost = editor.useSetter("value");

	const onChangeStepperCount = useCallback(
		(val: number | undefined) => {
			if (val == null) return;
			const isSameExist = intervals.find((i) => i.count === val);
			if (!isSameExist) {
				setCount(val);
			}
		},
		[intervals, setCount],
	);

	const options = useMemo(
		() =>
			surchargesOptions
				.filter(
					(option) =>
						!(
							root &&
							[
								BaseRateAdjustmentType.SURCHARGE_TO_DEFAULT_RATE,
								BaseRateAdjustmentType.COEFFICIENT_TO_DEFAULT_RATE,
							].includes(option.key as BaseRateAdjustmentType)
						),
				)
				.map((option) => ({
					...option,
					label: t(`${path}.options.${option.key}`),
				})),
		[path, root, t],
	);

	return (
		<Root hasPaddings>
			<Column gaps="25px*">
				<Row align="center" gaps="13px">
					{t(`${path}.modal.passengersAmount`)}
					<Stepper
						min={1}
						value={count}
						onChange={onChangeStepperCount}
					/>
				</Row>
				<Row align="center" justify="space-between" gaps="10px*">
					{t(`${path}.modal.value`)}
					<Row gaps="10px">
						<Select
							error={typeError}
							style={{ width: "310px" }}
							options={options}
							placeholder={t(`${path}.options.placeholder`) || ""}
							onChange={handleTypeChange}
							value={type || undefined}
						/>
						<ValueStepper
							disabled={!type}
							width="120px"
							decimalCount={2}
							value={cost}
							onChange={setCost}
						/>
					</Row>
				</Row>
			</Column>
		</Root>
	);
});

declare namespace Content {
	interface Props {
		root: boolean;
		value: Value;
		typeError: boolean;
		setTypeError: Dispatch<SetStateAction<boolean>>;
		intervals: Intervals;
		onChange: (x: Value) => void;
	}

	type Value = Omit<PassengersSurcharge.Interval, "active">;
	type Intervals = PassengersSurcharge.Interval[];
}

export default Content;
