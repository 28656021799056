import styled from "styled-components";
import { Select as BaseSelect, theme } from "uikit";

import { Style, baseStyles, flexStyles, distributeStyles } from "../styles";

interface SelectType {
	selected?: number;
	icon?: boolean;

	minWidthSelect?: number;
	maxHeightSelect?: string;
	maxWidthSelect?: string;

	heightRowSelect?: string;
	minHeightRowSelect?: string;

	topSelect?: string;
	leftSelect?: string;
	bottomSelect?: string;
	rightSelect?: string;
}

const TickMark = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12" version="1.1" width="16" height="12" aria-hidden="true"><path fill="green" d="M15.4998 1.83345L5.49984 11.8334L0.916504 7.25011L2.0915 6.07511L5.49984 9.47511L14.3248 0.658447L15.4998 1.83345Z"></path></svg>')`;

export const Select = styled(BaseSelect)<SelectType & Style.Column>`
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
	border: none;
	outline: none;
	height: 20px;

	div {
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
	}

	& div[disabled] {
		background-color: transparent;
	}

	& > div > div:nth-child(1) > div > div {
		background-color: transparent;
	}

	& > div > div:nth-child(1) > div > div > div {
		${baseStyles}
		${flexStyles}
		${distributeStyles}

		div:nth-child(2) {
			padding: 0px 4px;
		}
	}

	& > div > div:nth-child(1) > div > div > div:nth-child(2) {
		svg: {
			width: 16px;
			height: 16px;
		}
	}

	& > div > div:nth-child(2) > div {
		box-shadow: 0px 9px 24px 0px #00000026;
		border-radius: 5px;
		${({ topSelect }) => topSelect && `top:${topSelect};`}
		${({ bottomSelect }) => bottomSelect && `bottom:${bottomSelect};`}
		${({ leftSelect }) => leftSelect && `left:${leftSelect};`}
		${({ rightSelect }) => rightSelect && `right:${rightSelect};`}
	}
	& > div > div:nth-child(2) > div > div {
		border-radius: 5px;
	}

	& > div > div:nth-child(2) > div > div > div > div > div {
		font-family: Lato;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;

		border-bottom: 1px solid #e5e5ea;
		padding: 8px 12px 8px ${({ icon = true }) => (icon ? "30px" : "12px")};
		min-width: ${({ minWidthSelect = 210 }) => `${minWidthSelect}px`};
		${({ heightRowSelect }) => `height:${heightRowSelect};`}
		${({ minHeightRowSelect }) => `min-height:${minHeightRowSelect};`}
		${({ maxWidthSelect = "100%" }) => `max-width:${maxWidthSelect};`}
	}

	& > div > div:nth-child(2) > div > div > div > div > div:last-child {
		border: none;
	}

	${({ selected, icon = true }) =>
		selected &&
		icon &&
		`& > div > div:nth-child(2) > div > div > div > div > div:nth-child(${selected}) {
			background-color: transparent;
			&::before {
				content: ${TickMark};
				position: absolute;
				height: 1em;
				width: 1em;
				left: 8px;
				color: #4caf50;
			}
	}`}

	& > div > div:nth-child(2) > div > div > div > div > div:hover {
		color: #03a9f4;
		background-color: #f5f5f5;
	}

	& > div > div:nth-child(2) > div > div > div {
		max-height: 200px;
		${({ maxHeightSelect }) => `max-height:${maxHeightSelect};`}

		overflow-y: auto;

		::-webkit-scrollbar {
			width: 3px;
			height: 3px;
		}
		::-webkit-scrollbar-thumb {
			background-color: ${`${theme.colors.primary}33`};
		}

		::-webkit-scrollbar-track {
			background-color: transparent;
		}
	}

	& div {
		display: flex;
		width: auto;
		border: none;
		outline: none;
	}
	& div:focus,
	& div:focus-visible,
	& svg:focus,
	& svg:focus-visible {
		border: none;
		outline: none;
	}
`;

export default Select;
