/* eslint-disable no-param-reassign */
// import sessionConstants from "../constants/session.constants";

import { combineReducers } from "@reduxjs/toolkit";
import { extend } from "lodash";

import localObjects from "./localObjects";
import localObjectGroups from "./localObjectGroups";

const map = extend(
	combineReducers({
		localObjects: localObjects.reducer,
		localObjectGroups: localObjectGroups.reducer,
	}),
	{
		localObjects,
		localObjectGroups,
	},
);

export default map;
