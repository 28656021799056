import Color from "color";

import CarClass from "../../../../../../../services/CarClass";
import { createObjectFromConstant } from "../../../../../../../assets/languages/langs";

const defaultValue: CarClass.Model.New = {
	default: false,
	active: true,
	root: false,
	name: createObjectFromConstant(),
	shortName: "",

	companyIds: [],
	taxiServices: [],
	taxiServiceIds: [],
	compatibleCarClassIds: [],
	compatibleCarClassIdsToBroadcastable: [],
	serviceAvailableIds: [],
	serviceDefaultIds: [],

	useBackgroundColor: false,
	backgroundColor: new Color(0),

	useTextColor: false,
	textColor: new Color(0),

	distributable: true,
	broadcastable: true,

	priority: 1,

	availableForOnlineOrdering: true,

	distributionCompatibleMode: CarClass.CompatibleMode.AUTO,
	broadcastingCompatibleMode: CarClass.CompatibleMode.AUTO,
};

export default defaultValue;
