import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createRPCQuery from "../../utils/createRPCQuery.util";
import BaseOptions from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import Base from "../Base";

class CounterpartyGroup extends Base {
	public static fromResponse(data: any): CounterpartyGroup.Model {
		return {
			id: data.id,

			name: data.name,
			counterpartyIds: data.counterparties?.map(({ id }) => id) ?? [],
			notes: data.notes,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(
		model: CounterpartyGroup.New | CounterpartyGroup.Modified,
	) {
		const toRequest: Record<string, any> = {};

		const { name } = model;
		if (name) toRequest.name = name;

		const { counterpartyIds } = model;
		if (counterpartyIds) toRequest.counterpartyIds = counterpartyIds;

		const { notes } = model;
		if (notes) toRequest.notes = notes;

		return toRequest;
	}

	public static async store(object: CounterpartyGroup.New) {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.counterpartyGroup.create(
					this.toRequest(object),
				),
			);
			return !!res;
		} catch (err) {
			return false;
		}
	}

	public static async update(object: CounterpartyGroup.Modified) {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.counterpartyGroup.update(
					object.id,
					this.toRequest(object),
				),
			);
			return !!res;
		} catch (err) {
			console.error("Error in counterparty group update method:", err);
			return false;
		}
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.counterpartyGroup.delete(id));
	}

	public static async subscribe(
		options: CounterpartyGroup.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CounterpartyGroup.Model>,
	): Promise<Subscription<CounterpartyGroup.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				createRPCQuery(() =>
					prpc.theirsModel.counterpartyGroup.subscribe({
						params: options,
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							console.error(error);
						},
					}),
				),
			{ name: "CounterpartyGroup.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CounterpartyGroup.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<CounterpartyGroup.SubscribeOptions>;
	}
}

declare namespace CounterpartyGroup {
	interface Model extends NonEditableProperties {
		name: string;
		counterpartyIds: number[];
		notes: string;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> & { readonly id: number };

	interface SubscribeOptions extends BaseOptions<CounterpartyGroup.Model> {}
}

export default CounterpartyGroup;
