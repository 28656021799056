import React, { memo, useMemo } from "react";
import { PathOptions } from "leaflet";

import { PointItems } from "../../../../redux/reducers/Archives/GPSLog/interface";
import CircleMarkerItem from "../CircleMarkerItem";

const CircleMarkerListBase: React.FC<CircleMarkerListBase.Props> = ({
	data,
	pathOptions,
}): JSX.Element => {
	const items = useMemo<CircleMarkerListBase.PointItemsMemo[]>(() => {
		if (!data?.length) return [];
		const payload = data.flat();
		return payload;
	}, [data]);

	if (!items?.length) return <></>;
	return (
		<>
			{items?.length &&
				items.map((item): JSX.Element => {
					if (!item || !item.center) return <></>;
					return (
						<CircleMarkerItem
							deg={item.deg}
							key={item.keyPoint}
							keyPoint={item.keyPoint}
							center={item.center}
							radius={item.radius}
							lines={item.lines}
							pathOptions={pathOptions}
							date={item.date}
							color={item.color}
						/>
					);
				})}
		</>
	);
};

declare namespace CircleMarkerListBase {
	interface Props {
		data: PointItemsMemo[][];
		pathOptions?: PathOptions;
	}
	interface PointItemsMemo extends PointItems {}
}

export const CircleMarkerList = memo(CircleMarkerListBase);
export default CircleMarkerListBase;
