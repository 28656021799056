import React from "react";

import Language from "../../../../../services/Language";

import Root from "./components/Root";
import Table from "./components/Table";

const Points: React.FC<Points.Props> = ({ language }) => (
	<Root>
		<Table language={language} />
	</Root>
);

declare namespace Points {
	interface Props {
		language: Language;
	}
}

export default Points;
