import React, { useCallback } from "react";
import styled from "styled-components";
import { Column } from "uikit";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../../redux/store";
import Call from "./components/Call";
import softphoneWidget, {
	Call as CallType,
} from "../../../../../../../redux/reducers/softphoneWidget";
import { toggleHold } from "../../../../../../../utils/jsSip";

export const CallsListColumn = styled(Column)`
	position: absolute;
	right: 65px;
	top: 77px;
	padding-left: 28px;

	pointer-events: none;

	width: 320px;
	height: 90%;

	pointer-events: none;

	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

const CallsList = () => {
	const dispatch = useDispatch();
	const { callsData, selectedCall } = useTypedSelector(
		(state) => state.softphoneWidget,
	);
	const { uaConfig } = useTypedSelector((state) => state.sipToDispatcher);

	const onSelectCaller = useCallback(
		(callerData: CallType) => {
			if (callerData?.request.call_id !== selectedCall?.request.call_id) {
				dispatch(softphoneWidget.actions.setSelectedCaller(callerData));
				dispatch(softphoneWidget.actions.rerenderSoftphone());
				const remoteStream =
					callerData.session?.connection?.getRemoteStreams()[0];
				uaConfig.audio.call.srcObject = remoteStream;
				uaConfig.audio.call.play();
				toggleHold(callerData);
			}
		},
		[dispatch, selectedCall?.request.call_id, uaConfig?.audio?.call],
	);

	return (
		<CallsListColumn>
			<Column gaps="8px*" style={{ pointerEvents: "auto" }}>
				{callsData.map((call, index) => (
					<Call
						key={call.session.id}
						onSelectCaller={onSelectCaller}
						selectedCall={selectedCall}
						callerIndex={index + 1}
						data={call}
					/>
				))}
			</Column>
		</CallsListColumn>
	);
};

export default CallsList;
