import { LatLngLiteral } from "leaflet";
import { defaults } from "lodash";

import Map from "../../../../../../../../../redux/services/Map";
import Language from "../../../../../../../../../services/Language";
import Address from "../../../../../../../../../types/Address";
import { findValueByLanguageKey } from "../../../../../../../../../assets/languages/langs";
import addressToString from "../components/PointForm/functions/addressToString";

async function reveal(coordinates: LatLngLiteral, language: Language) {
	const { objects } = await Map.Point.reveal(coordinates, {
		language,
	});

	const address = objects.filter((object) => object.type === "address")[0]
		?.properties;

	const settlement = objects.filter(
		(object) => object.type === "settlement",
	)[0]?.properties;

	const district = objects.filter((object) => object.type === "district")[0]
		?.properties;

	const addressData = findValueByLanguageKey(address?.language);
	// address?.language.en ?? address?.language.ru ?? address?.language.uk;

	const districtData =
		district?.language.en ?? district?.language.ru ?? district?.language.uk;

	const settlementData = findValueByLanguageKey(settlement?.language);
	// settlement?.language.en ??
	// settlement?.language.ru ??
	// settlement?.language.uk;

	const data = defaults(addressData, districtData, settlementData);

	const processedAddress: Partial<Address> = {
		country: data.country ?? "",
		countryCode: data.countryCode ?? "",
		street: data.street ?? "",
		streetType: data.streetType ?? "",
		settlement: data.settlement ?? "",
		settlementType: data.settlementType ?? "",
		district: data.district ?? "",
		region: data.region ?? "",
		house: data.number ?? "",
	};

	processedAddress.address = addressToString(processedAddress);

	return processedAddress;
}

export default reveal;
