import React from "react";
import styled from "styled-components";
import { Column, theme } from "uikit";

import { ChatMessage as ChatMessageServices } from "../../../../../../../../../../../services";
import { messagesType } from "../../../../../../../../constants/business";
import {
	isObject,
	getUserData,
	getFullTitle,
} from "../../../../../../../../tools";
import Reference from "./components/Reference";

const Root = styled(Column)<{ bgColor?: boolean }>`
	position: relative;

	padding: 6px 12px;
	white-space: pre-wrap;
	font-family: Lato;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0px;
	text-align: left;
	color: ${({ bgColor }) => (bgColor ? "#ffffff" : theme.colors.primary)};
	background-color: ${({ bgColor }) => (bgColor ? "#03a9f5" : "#dcefdc")};
`;

const Message: React.FC<Message.Props> = ({ data, onClickFindParent }) => {
	const { type, message, isCurrentUser } = data;

	let messageContent: React.ReactNode = null;

	if (isObject(message)) {
		// * Reply to a message
		const userData = getUserData(message);
		const { id: parentId } = message;

		if (!userData || !parentId) return null;

		const fullTitle = getFullTitle(userData);
		const contentAnswer = message?.content ?? "";

		messageContent = (
			<Reference
				data={{
					isCurrentUser,
					text: contentAnswer,
					title: fullTitle,
					parentId,
				}}
				onClickFindParent={onClickFindParent}
			/>
		);
	} else if (typeof message === "string") {
		// * Normal Message
		messageContent = message;
	}

	return (
		<Root bgColor={isCurrentUser} gaps="8px">
			{messageContent}
		</Root>
	);
};

declare namespace Message {
	export interface Props {
		data: {
			type: string;
			message: string | ChatMessageServices.OutputGrouped.ChatMessage;
			isCurrentUser: boolean;
		};
		onClickFindParent?: (parentId: number) => void;
	}
}

export default Message;
