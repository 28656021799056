import React from "react";

import DestinationAddressCellContent from "../components/DestinationAddressCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function destinationAddress({ width, onResize }: Column.Options) {
	return base(
		ColumnId.DestinationAddress,
		(item) => <DestinationAddressCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default destinationAddress;
