export interface ErrorResponse {
	message: string;
	status: 400 | 404 | 401 | 403 | 500;
	baseText: string;
	translation: string;
	canRetry: boolean;
}

type RequestErrorContext = "create" | "update";

export const unknownErrors: Record<RequestErrorContext, ErrorResponse> = {
	create: {
		message: "",
		status: 400,
		baseText: "Failed to create a new car class. Please try again.",
		translation: `preferences.car_classes.errors.create`,
		canRetry: true,
	},
	update: {
		message: "",
		status: 400,
		baseText: "Failed to change the car class. Please try again.",
		translation: `preferences.car_classes.errors.update`,
		canRetry: true,
	},
};

const errors: ErrorResponse[] = [
	{
		message: "You cannot change the default car class or make it inactive!",
		status: 403,
		baseText:
			"You cannot change the default car class or make it inactive!!",
		translation: `preferences.car_classes.errors.cannotChangeDefaultActive`,
		canRetry: false,
	},
	{
		message: "The car class not found!",
		status: 404,
		baseText: "The car class not found!",
		translation: `preferences.car_classes.errors.notFound`,
		canRetry: true,
	},
];

/**
 * Retrieve prepared error by message
 * @param message Response error message from server
 * @param isEdit Flag to determine if the error is from edit or create
 */
export const getErrorByMessage = (message: string, isEdit: boolean) => {
	const error = errors.find((error) => error.message === message);
	if (!error) {
		const context = isEdit ? "update" : "create";
		return unknownErrors[context];
	}
	return error;
};

export default errors;
