/**
 * Collection of simple constants for the chat widgets.
 */
import { theme } from "uikit";
import { useTranslation } from "react-i18next";

import { RegisteredIcon } from "../../../../assets/icons";

const MODAL_TYPES = {
	NONE: "none",
	USERS: "users",
	FLAGS: "flags",
	SCHEDULE: "schedule",
} as const;

const MESSAGE_TYPES = {
	DEFAULT: "default",
	ANNOUNCEMENT: "announcement",
	WARNING: "warning",
	SOS: "sos",
} as const;

const NAVIGATION_TYPES = {
	EXECUTORS: "executors",
	DISPATCHERS: "dispatchers",
	EXCHANGE: "exchange",
} as const;

function useNavigationTitle() {
	const { t } = useTranslation();

	return {
		EXECUTORS: t("orderPageWidgets.chat.navigationTitles.executors") ?? "",
		DISPATCHERS:
			t("orderPageWidgets.chat.navigationTitles.dispatchers") ?? "",
		EXCHANGE: t("orderPageWidgets.chat.navigationTitles.exchange") ?? "",
	} as const;
} // NAVIGATION_TYPES

const SCHEDULE_ACTUAL_TYPES = {
	DATE: "date",
	TIME: "time",
} as const;

const FILTER_NAME = {
	EXECUTORS_WHO_TO_READ: "Executors(whoToRead?)",
	EXECUTORS_WHO_TO_WRITE: "Executors(whoToWrite?)",
} as const;

interface MessageStyle {
	primaryColor: string;
	secondaryColor: string;
	iconName: RegisteredIcon;
}

interface MessagesType {
	announcement: MessageStyle;
	default: MessageStyle;
	sos: MessageStyle;
	warning: MessageStyle;
}

const messagesType: MessagesType = {
	announcement: {
		primaryColor: theme.colors.success,
		secondaryColor: "#DBEFDC",
		iconName: "chat-announcement",
	},
	// delete iconName to default
	default: {
		primaryColor: theme.colors.primary,
		secondaryColor: theme.colors.button_secondary,
		iconName: "chat-send",
	},
	sos: {
		primaryColor: theme.colors.negative,
		secondaryColor: `${theme.colors.negative}1A`,
		iconName: "chat-sos",
	},
	warning: {
		primaryColor: "#FF9900",
		secondaryColor: "#FF99001A",
		iconName: "chat-warning",
	},
};

export {
	MODAL_TYPES,
	MESSAGE_TYPES,
	NAVIGATION_TYPES,
	SCHEDULE_ACTUAL_TYPES,
	FILTER_NAME,
	messagesType,
	useNavigationTitle,
};
