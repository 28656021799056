import { NotUndefined, sha1 } from "object-hash";
import { Unsubscribe } from "redux";

import { RootState, store as dataStore } from "../store";
import createModelStore from "../../utils/createModelStore";
import StoreQueryMap from "../../types/StoreQueryMap";
import StoreList from "../../types/StoreList";

export default function ModelService<
	SubscribeOptions extends NotUndefined,
	Model extends object,
	Name extends string,
>(
	store: ReturnType<typeof createModelStore<Model, Name>>,
	selector: (state: RootState) => StoreQueryMap<StoreList<Model>>,
) {
	type IndexResult = StoreList<Model> | null;

	return class {
		private static unsubscribes: Record<string, Unsubscribe> = {};

		private static indexPromises: Record<string, Promise<IndexResult>> = {};

		public static selector = selector;

		public static subscribe(options: SubscribeOptions) {
			const hash = sha1(options);

			this.unsubscribes[hash] = dataStore.subscribe(async () => {
				const subscription = selector(dataStore.getState())[hash];

				if (!subscription?.deprecated || hash in this.indexPromises)
					return;

				this.indexPromises[hash] = this.Global.index(options);

				const data = await this.indexPromises[hash];

				delete this.indexPromises[hash];

				dataStore.dispatch(store.actions.approve(hash));

				if (!data) return;

				dataStore.dispatch(store.actions.update({ hash, data }));
			});

			dataStore.dispatch(store.actions.register(hash));

			return hash;
		}

		public static unsubscribe(hash: string) {
			this.unsubscribes[hash]?.();

			dataStore.dispatch(store.actions.unregister(hash));
		}

		public static deprecate(hash: string) {
			dataStore.dispatch(store.actions.deprecate(hash));
		}

		public static deprecateAll() {
			dataStore.dispatch(store.actions.deprecateAll());
		}

		public static Global: {
			index(options: SubscribeOptions): Promise<IndexResult>;
		};
	};
}
