import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";

const TranslationTextBase: React.FC<TranslationTextBase.Props> = ({ text }) => {
	const { t } = useTranslation();

	const payload = useMemo(() => t(text), [text, t]);

	return <>{payload}</>;
};

export const TranslationText = memo(TranslationTextBase);
declare namespace TranslationTextBase {
	interface Props {
		text: string | string[];
	}
}

export default TranslationTextBase;
