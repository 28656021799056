import { BaseRateAdjustmentType } from "../../../../../../../services/Tariff/enums";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
	Units,
	PickupCostType,
} from "../../../constants/constants";
import Modal from "../components/Modal";

export const defaultValue: Modal.Value = {
	id: 0,
	name: {},
	active: true,
	default: false,
	root: false,
	additionalFields: {
		general: {
			boardingCost: {
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
				value: 1,
			}, // Boarding cost
			settlementCostPerKm: {
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
				value: 1,
			}, // Cost per km in the city
			suburbanCostPerKm: {
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
				value: 1,
			}, // Cost per km outside the city
			roundTripCostPerKm: {
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
				value: 1,
			}, // Cost per km round trip
			settlementPickupCost: {
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
				value: 1,
			}, // Pickup cost per km in the city
			suburbanPickupCost: {
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
				value: 1,
			}, // Pickup cost per km outside the city
			pickupCostCalculation: {
				type: PickupCostType.NEAREST,
				considerSuburbanZone: true,
			},
			autoCalculatePickupCost: true,
			// Minimum order cost
			minOrderCost: {
				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					initialKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
				strict: true,
				value: 0, // Minimum order cost
				initialKm: 0, // relative to the first n km
			},
			minPreliminaryOrderCost: {
				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					initialKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
				strict: true,
				value: 0, // Minimum preliminary order cost
				initialKm: 0, // relative to the first n km
			},
			// Idle time
			minHourlyRate: {
				value: 0,
				minutes: 0,
				initialKm: 0,
				appliesToEntireRoute: false,
				customSettlementCostPerKm: 0, // Custom value for cost per km in the city
				customSuburbanCostPerKm: 0, // Custom value for cost per km outside the city

				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					minutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
					initialKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
					appliesToEntireRoute:
						RateBinaryAdjustmentToDefault.CUSTOM_VALUE,
				},
			},
			hourlyServiceInterval: {
				value: 1,
				units: Units.HOURS,
				active: true,
				adjustments: {
					value: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			hourlyServiceCost: {
				value: 0,
				additionalKm: 0,
				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					additionalKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			idleMinuteCost: {
				// Cost per idle minute
				active: false,
				value: 0,
				freeMinutes: 0,
				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					freeMinutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			rideMinuteCost: {
				// Cost per ride minute
				active: false,
				value: 0,
				freeMinutes: 0,
				type: "fixed",
				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					freeMinutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
					type: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			waitingMinuteCost: {
				// Cost per waiting minute
				active: false,
				value: 0,
				freeMinutes: 0,
				adjustments: {
					value: RateAdjustmentToDefault.CUSTOM_VALUE,
					freeMinutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			// Surcharge for additional order points
			additionalPointCost: {
				active: false,
				after: 0, // after n km
				value: 0, // cost per point
			}, // Surcharge for preliminary order
			preliminarySurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			surchargeToOrderSum: {
				active: false,
				type: BaseRateAdjustmentType.SURCHARGE_TO_ORDER_SUM, // surcharge type
				value: 0, // surcharge value
			},
			// Surcharge for online order (app)
			onlineOrderSurchargeApp: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			// Surcharge for online order (website)
			onlineOrderSurchargeWebsite: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			// Surcharge for credit card payment
			creditCardPaymentSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			pickupCostSurcharge: {
				active: false,
				type: null,
				value: 0,
			},
			// Surcharge for invoice payment (link, QR code)
			invoicePaymentSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			// Surcharge for non-cash payment
			nonCashPaymentSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			// Surcharge for number of passengers
			passengersSurcharge: {
				active: false,
				intervals: [],
			},
			terminalPaymentSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			taximeterSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			executorOrderSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
			executorOrderTaximeterSurcharge: {
				active: false,
				type: null, // surcharge type
				value: 0, // surcharge value
			},
		},
		rateGrid: {
			active: true, // rate grid activity
			overall: true, // rates for the entire route
			useLastIntervalForOverall: true,
			intervals: [],
		},
		fixedRate: {
			rows: [],
		},
	},
	services: [],
	taxiServiceIds: [],
	taxiServices: [],
	carClassIds: [],
	companyIds: [],
};

export default defaultValue;
