import * as ModelEvent from "@node-elion/syncron";
import { compact } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import stringToUndefined from "../../utils/stringToUndefined";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { ModelId } from "../../types/ModelId";
import Base from "../Base";
import Lang from "../Language";

class PaymentSystem extends Base {
	private static optionsToRequest(optns: PaymentSystem.SubscribeOptions) {
		return {
			lang: optns.language,
			limit: optns.limit,
			offset: optns.offset,
			types: optns.types,
		};
	}

	public static fromResponseAsset(
		data: any,
	): PaymentSystem.AssetPaymentConfiguration {
		const { configuration, paymentProvider } =
			data as PaymentSystem.Response;
		const payload: PaymentSystem.AssetPaymentConfiguration = {
			assetPaymentsFeePercentage: 0,
			assetPaymentsAdditionalFee: 0,
			assetPaymentsGlobalFeeFromSystem: false,
			assetPaymentsProcessingId: 0,
			assetPaymentsCurrency: PaymentCurrency.UAH,
			assetPaymentsCountryIso: PaymentCountryIso.UKR,
			assetPaymentsKey: "",
			assetPaymentsSecretKey: "",

			systemReturnFeeToExecutor: false,
			systemFeePercentageForExecutor: 0,
			systemFeeAdditionalForExecutor: 0,
		};

		if (paymentProvider === PaymentProvider.ASSETPAYMENT) {
			const config =
				configuration as PaymentSystem.AssetPaymentConfiguration;

			payload.assetPaymentsGlobalFeeFromSystem =
				config.assetPaymentsGlobalFeeFromSystem;
			payload.assetPaymentsFeePercentage =
				config.assetPaymentsFeePercentage;
			payload.assetPaymentsAdditionalFee =
				config.assetPaymentsAdditionalFee;

			payload.assetPaymentsProcessingId =
				config.assetPaymentsProcessingId;

			payload.assetPaymentsCurrency = config.assetPaymentsCurrency;
			payload.assetPaymentsCountryIso = config.assetPaymentsCountryIso;
			payload.assetPaymentsKey = config.assetPaymentsKey;
			payload.assetPaymentsSecretKey = config.assetPaymentsSecretKey;

			payload.systemReturnFeeToExecutor =
				config.systemReturnFeeToExecutor;
			payload.systemFeePercentageForExecutor =
				config.systemFeePercentageForExecutor;
			payload.systemFeeAdditionalForExecutor =
				config.systemFeeAdditionalForExecutor;
		}

		return payload;
	}

	public static fromResponseFondy(
		data: any,
	): PaymentSystem.FondyPaymentConfiguration {
		const { configuration, paymentProvider } =
			data as PaymentSystem.Response;

		const payload: PaymentSystem.FondyPaymentConfiguration = {
			fondyCurrency: PaymentCurrency.UAH,
			fondyCountryIso: PaymentCountryIso.UKR,

			fondyFeePercentage: 0,
			fondyAdditionalFee: 0,
			fondyGlobalFeeFromSystem: false,

			systemFeePercentageForExecutor: 0,
			systemFeeAdditionalForExecutor: 0,
			systemReturnFeeToExecutor: false,
		};

		if (paymentProvider === PaymentProvider.FONDY) {
			const config =
				configuration as PaymentSystem.FondyPaymentConfiguration;

			payload.fondyCurrency = config.fondyCurrency;
			payload.fondyCountryIso = config.fondyCountryIso;

			payload.fondyFeePercentage = config.fondyFeePercentage;
			payload.fondyAdditionalFee = config.fondyAdditionalFee;
			payload.fondyGlobalFeeFromSystem = config.fondyGlobalFeeFromSystem;

			payload.systemReturnFeeToExecutor =
				config.systemReturnFeeToExecutor;
			payload.systemFeePercentageForExecutor =
				config.systemFeePercentageForExecutor;
			payload.systemFeeAdditionalForExecutor =
				config.systemFeeAdditionalForExecutor;
		}

		return payload;
	}

	public static fromResponseBase(data: any): PaymentSystem.BaseModel {
		const { paymentProvider, paymentAccounts } =
			data as PaymentSystem.Response;

		const payload: PaymentSystem.BaseModel = {
			id: data.id,
			name: data.name,
			active: data.active,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,

			isDefault: data.isDefault,
			type: data.type,

			withdrawMethod: data.withdrawMethod,
			defaultHoldValue: data.defaultHoldValue,

			paymentProvider,
			paymentAccounts,

			taxiServiceIds:
				compact(
					paymentAccounts?.map(({ taxiService }) => taxiService?.id),
				) || [],
			taxiServices:
				compact(
					paymentAccounts?.map(({ taxiService }) => taxiService),
				) || [],

			companyIds:
				compact(
					paymentAccounts?.map(({ company, taxiService }) => {
						if (company) return company?.id;
						return taxiService?.company?.id;
					}),
				) || [],

			companies:
				compact(
					paymentAccounts?.map(({ company, taxiService }) => {
						if (company) return company;
						return taxiService?.company;
					}),
				) || [],
		};

		return payload;
	}

	public static fromResponse(data: any): PaymentSystem.Model {
		const payload: PaymentSystem.Model = {
			...PaymentSystem.fromResponseBase(data),
			...PaymentSystem.fromResponseFondy(data),
			...PaymentSystem.fromResponseAsset(data),
		};

		return payload;
	}

	public static toRequest(
		data: Omit<PaymentSystem.Modified, "id" | "updateDefault">,
	): Omit<PaymentSystem.Modified, "id" | "updateDefault"> {
		const payload: Omit<PaymentSystem.Modified, "id" | "updateDefault"> & {
			configuration: any;
		} = {
			active: data.active,
			name: stringToUndefined(data.name),
			paymentProvider: data.paymentProvider,
			type: data.type,

			withdrawMethod: data.withdrawMethod,
			defaultHoldValue: data.defaultHoldValue,

			configuration: {},
		};

		if (data.type === PaymentSystemFrontEndType.CUSTOMER) {
			payload.companyIds = data.companyIds;
		} else if (data.type === PaymentSystemFrontEndType.EXECUTOR) {
			payload.taxiServiceIds = data.taxiServiceIds;
		}

		if (data.paymentProvider === PaymentProvider.ASSETPAYMENT) {
			const config = data as PaymentSystem.AssetPaymentConfiguration;
			const isExecutor = data.type === PaymentSystemFrontEndType.EXECUTOR;

			payload.configuration.assetPaymentsGlobalFeeFromSystem =
				config.assetPaymentsGlobalFeeFromSystem;
			payload.configuration.assetPaymentsFeePercentage =
				config.assetPaymentsFeePercentage;
			payload.configuration.assetPaymentsAdditionalFee =
				config.assetPaymentsAdditionalFee;

			payload.configuration.assetPaymentsProcessingId =
				config.assetPaymentsProcessingId;

			payload.configuration.assetPaymentsCurrency =
				config.assetPaymentsCurrency;
			payload.configuration.assetPaymentsCountryIso =
				config.assetPaymentsCountryIso;
			payload.configuration.assetPaymentsKey =
				config.assetPaymentsKey.trim();
			payload.configuration.assetPaymentsSecretKey =
				config.assetPaymentsSecretKey.trim();

			payload.configuration.systemReturnFeeToExecutor =
				config.systemReturnFeeToExecutor;
			if (isExecutor) {
				payload.configuration.systemFeeAdditionalForExecutor =
					config.systemFeeAdditionalForExecutor;

				payload.configuration.systemFeePercentageForExecutor =
					config.systemFeePercentageForExecutor;
			}
		} else if (data.paymentProvider === PaymentProvider.FONDY) {
			const config = data as PaymentSystem.FondyPaymentConfiguration;
			const isExecutor = data.type === PaymentSystemFrontEndType.EXECUTOR;

			payload.configuration.fondyCurrency = config.fondyCurrency;
			payload.configuration.fondyCountryIso = config.fondyCountryIso;

			payload.configuration.fondyFeePercentage =
				config.fondyFeePercentage;
			payload.configuration.fondyAdditionalFee =
				config.fondyAdditionalFee;
			payload.configuration.fondyGlobalFeeFromSystem =
				config.fondyGlobalFeeFromSystem;

			payload.configuration.systemReturnFeeToExecutor =
				config.systemReturnFeeToExecutor;

			if (isExecutor) {
				payload.configuration.systemFeeAdditionalForExecutor =
					config.systemFeeAdditionalForExecutor;

				payload.configuration.systemFeePercentageForExecutor =
					config.systemFeePercentageForExecutor;
			}
		}

		console.log("[PaymentSystem] toRequest", {
			payload,
			data,
		});

		return payload;
	}

	public static async update({
		id,
		updateDefault = false,
		...params
	}: PaymentSystem.Modified): Promise<PaymentSystem.Model | null> {
		try {
			if (!id) return null;
			const res = await this.request((prpc) =>
				prpc.theirsModel.payment.system.update(
					id,
					this.toRequest(params),
					updateDefault,
				),
			);
			console.log("[PaymentSystem]  update res", res);
			return res;
		} catch (err) {
			console.error("[PaymentSystem]  Error update", err);
			return null;
		}
	}

	public static async create({
		id,
		...params
	}: PaymentSystem.Modified): Promise<PaymentSystem.Model | null> {
		try {
			console.log("[PaymentSystem]  create params", { id, params });
			const res = await this.request((prpc) =>
				prpc.theirsModel.payment.system.create(this.toRequest(params), {
					templateId: id,
				}),
			);
			console.log("[PaymentSystem]  create res", res);
			return res;
		} catch (err) {
			console.error("[PaymentSystem] Error create", err);
			return null;
		}
	}

	public static async store(id: number): Promise<PaymentSystem.Model | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.payment.system.getById(id),
			);

			return res;
		} catch (err) {
			console.error("[PaymentSystem] Error store", err);
			return null;
		}
	}

	public static async copy(id: number): Promise<PaymentSystem.Model | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.payment.system.copy(id),
			);
			console.log("[PaymentSystem]  copy res", res);
			return res;
		} catch (err) {
			console.error("[PaymentSystem] Error copy", err);
			return null;
		}
	}

	public static async destroy(
		ids: number[],
	): Promise<PaymentSystem.Model | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.payment.system.delete(ids),
			);

			return res;
		} catch (err) {
			console.error("[PaymentSystem] Error destroy", err);
			return null;
		}
	}

	public static async subscribe(
		options: PaymentSystem.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<PaymentSystem.Model>,
	): Promise<Subscription<PaymentSystem.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[PaymentSystem] subscribe", state);
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const params = this.optionsToRequest(options);
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.payment.system.subscribe({
					params,
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log("[PaymentSystem] Error subscribe", error);
					},
				}),
			{
				name: "PaymentSystem.subscribe",
				metadata: params,
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (subOptions: PaymentSystem.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(subOptions)),
		} as Subscription<PaymentSystem.SubscribeOptions>;
	}
}
declare namespace PaymentSystem {
	interface BaseModel
		extends NonEditableProperties,
			Pick<
				Response,
				"active" | "isDefault" | "type" | "paymentProvider"
			> {
		name: string;

		paymentAccounts: PaymentAccount[];

		taxiServiceIds: number[];
		taxiServices: PaymentAccount["taxiService"][];

		companyIds: number[];
		companies: PaymentAccount["company"][];

		updateDefault?: boolean;

		withdrawMethod: WithdrawMethod;
		defaultHoldValue: number;
	}

	interface Model
		extends BaseModel,
			AssetPaymentConfiguration,
			FondyPaymentConfiguration {}

	interface SubscribeOptions
		extends Omit<
			ServiceSubscribeOptionsBase<PaymentSystem.Model>,
			"query"
		> {
		types: PaymentSystemFrontEndType[];
		/** Only for `executor` */
		taxiServiceIds?: number[];
		/** Only for `customer` */
		companyIds?: number[];
		language?: Lang;
	}

	type NonEditablePropertyNames =
		| "id"
		| "createdAt"
		| "updatedAt"
		| "deletedAt";

	interface NonEditableProperties {
		readonly id: ModelId;
		readonly createdAt: string;
		readonly updatedAt: string;
		readonly deletedAt: string | null;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> & Pick<NonEditableProperties, "id">;

	interface PaymentAccount {
		company: { id: number; name: Lang };
		taxiService: {
			id: number;
			settlement: Lang;
			company: { id: number; name: Lang };
		};
	}

	type PaymentAccounts = PaymentAccount[];

	interface Response extends NonEditableProperties {
		active: boolean;
		configuration: AssetPaymentConfiguration | FondyPaymentConfiguration;
		isDefault: boolean;
		name: string;

		withdrawMethod: WithdrawMethod;
		defaultHoldValue: number;

		paymentAccounts: PaymentAccounts;
		paymentProvider: PaymentProvider;
		type: PaymentSystemFrontEndType;

		v: number;
	}
	interface BasePaymentConfig {
		systemReturnFeeToExecutor: boolean;
		systemFeePercentageForExecutor: number;
		systemFeeAdditionalForExecutor: number;
	}

	interface AssetPaymentConfiguration extends BasePaymentConfig {
		assetPaymentsKey: string;
		assetPaymentsSecretKey: string;
		assetPaymentsProcessingId: number;
		assetPaymentsFeePercentage: number;
		assetPaymentsAdditionalFee: number;
		assetPaymentsGlobalFeeFromSystem: boolean;
		assetPaymentsCurrency: PaymentCurrency;
		assetPaymentsCountryIso: PaymentCountryIso;
	}

	interface FondyPaymentConfiguration extends BasePaymentConfig {
		fondyCurrency: PaymentCurrency;
		fondyCountryIso: PaymentCountryIso;
		fondyFeePercentage: number;
		fondyAdditionalFee: number;
		fondyGlobalFeeFromSystem: boolean;
	}

	type UpdatePaymentSystemParams = {
		configuration: AssetPaymentConfiguration | FondyPaymentConfiguration;
		name?: string;
		active?: boolean;
	};
}
export enum PaymentCurrency {
	USD = "USD",
	EUR = "EUR",
	UAH = "UAH",
	NULL = "NULL",
}

export enum PaymentCountryIso {
	UKR = "UKR",
	POL = "POL",
	AZE = "AZE",
	NULL = "NULL",
}

export enum PaymentProvider {
	ASSETPAYMENT = "assetPayments",
	FONDY = "fondy",
}

export enum WithdrawMethod {
	WITHDRAW = "withdraw",
	WITHDRAW_WITH_HOLD = "withdraw_with_hold",
}
export enum PaymentSystemFrontEndType {
	CUSTOMER = "customer",
	EXECUTOR = "executor",
	TEMPLATE_CUSTOMER = "template_customer",
	TEMPLATE_EXECUTOR = "template_executor",
}

export enum PaymentRegistrationType {
	FRAME = "frame",
	URL = "url",
}

export enum NoticeDestinationApp {
	EXECUTOR = "EXECUTOR",
	CUSTOMER = "CUSTOMER",
}

export default PaymentSystem;
