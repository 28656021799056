/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import ArchiveFilter from "../../../../types/ArchiveFilter";

interface State {
	filter: ArchiveFilter;
	internalFilter: Pick<
		ArchiveFilter,
		"companyFilter" | "taxiServiceFilter"
	> & { query: string };
}

const initialState: State = {
	filter: {
		dateFrom: moment().startOf("day").toDate(),
		dateTo: moment().endOf("day").toDate(),
		searchQuery: "",
		companyFilter: ["all"],
		taxiServiceFilter: ["all"],
	},
	internalFilter: {
		companyFilter: ["all"],
		taxiServiceFilter: ["all"],
		query: "",
	},
};

const archiveCalls = createSlice({
	name: "archiveCalls",
	initialState,
	reducers: {
		modifyFilter: (
			state,
			{ payload }: PayloadAction<Partial<State["filter"]>>,
		) => {
			state.filter = {
				...state.filter,
				...payload,
			};
		},
		modifyInternalFilter: (
			state,
			{ payload }: PayloadAction<Partial<State["internalFilter"]>>,
		) => {
			state.internalFilter = {
				...state.internalFilter,
				...payload,
			};
		},
		applyInternalFilter: (state) => {
			state.filter = { ...state.filter, ...state.internalFilter };
		},
		resetInternCompanyBranch: (state) => {
			const { companyFilter, taxiServiceFilter } = state.filter;
			state.internalFilter = {
				...state.internalFilter,
				companyFilter,
				taxiServiceFilter,
			};
		},
	},
});

export const {
	modifyFilter,
	modifyInternalFilter,
	applyInternalFilter,
	resetInternCompanyBranch,
} = archiveCalls.actions;
export default archiveCalls.reducer;
