import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioList2 } from "uikit";
import styled from "styled-components";

import useObjectEditor from "../../../../hooks/useObjectEditor";
import {
	BaseElementIdEnum,
	StyledColumn,
	StyledRow,
	StyledSpan,
	ValueStepper,
} from "../../../common";
import BaseInputModal from "../BaseInputModal";
import BaseModal from "../BaseModal";

const Wrap = styled(StyledRow)`
	& > div {
		width: 100%;
		flex-direction: row;
		justify-content: start;
		align-items: center;
		text-align: center;
		gap: 0 2rem;
	}
`;

const StepperWrap = styled(StyledRow)`
	& > div {
		width: 120px;
	}
`;

const NumberInputBase: React.FC<NumberInput.Props> = ({
	onClose,
	onSubmit,
	value,
	title,
	unit,
	decimalCount = 0,
	useMinSet = false,
	...props
}) => {
	const { t } = useTranslation();

	const [inputValue, setInputValue] = useState<NumberInput.Value>(value);

	const valueEditor = useObjectEditor(inputValue, setInputValue);

	const actionValue = valueEditor.useGetter("action");
	const actionOnChange = valueEditor.useSetter("action");

	const valueValue = valueEditor.useGetter("value");
	const valueOnChange = valueEditor.useSetter("value");

	const actionOptions = useMemo<RadioList2.Option<NumberInput.Action>[]>(
		() => [
			{
				key: "add",
				value: "add",
				content: t([`add`, "Add"]),
			},
			{
				key: "set",
				value: "set",
				content: t([`replace`, "Replace"]),
			},
		],
		[t],
	);

	const modalOnSubmit = useCallback(() => {
		onSubmit?.(inputValue);
	}, [inputValue, onSubmit]);

	const payload = useMemo(() => {
		if (useMinSet && actionValue === "set") return Math.abs(valueValue);
		return valueValue;
	}, [valueValue, useMinSet, actionValue]);

	const handleOnChange = useCallback(
		(value) => {
			if (useMinSet && actionValue === "set") {
				const data = Math.abs(value);
				valueOnChange(data);
				return;
			}

			valueOnChange(value);
		},
		[actionValue, useMinSet, valueOnChange],
	);

	const content = useMemo(
		() => (
			<StyledColumn
				gap="1rem"
				alignItems="center"
				w="330px"
				p="10px 17px;"
				bgC="#ffffff"
			>
				<Wrap w="100%">
					<RadioList2
						value={actionValue}
						options={actionOptions}
						onChange={actionOnChange}
					>
						{(radios) => (
							<StyledRow alignItems="center" gap="32px">
								{radios}
							</StyledRow>
						)}
					</RadioList2>
				</Wrap>
				<StepperWrap justify="start" w="100%">
					<ValueStepper
						id={BaseElementIdEnum.STEPPER}
						value={payload}
						autoFocus
						decimalCount={decimalCount}
						onChange={handleOnChange}
					/>

					<StyledSpan p="0 0 0 10px">{unit}</StyledSpan>
				</StepperWrap>
			</StyledColumn>
		),
		[
			actionValue,
			actionOptions,
			actionOnChange,
			payload,
			decimalCount,
			handleOnChange,
			unit,
		],
	);

	return (
		<BaseInputModal
			title={title}
			content={content}
			value={inputValue}
			onSubmit={modalOnSubmit}
			onClose={onClose}
			{...props}
		/>
	);
};

declare namespace NumberInput {
	type Action = "add" | "set";

	interface Value {
		action: Action;
		value: number;
	}

	interface Props extends BaseModal.Props {
		value: Value;
		decimalCount?: number;
		unit?: string;
		onClose: BaseModal.Props["onClose"];
		onSubmit?: (data: Value) => void;
		useMinSet?: boolean;
	}
}

export const NumberInputMemo = memo(NumberInputBase);

const NumberInput = memo(NumberInputBase);

export default NumberInput;
