import styled, { css } from "styled-components";

const focusedStyle = css`
	transform: scale(1.2);
`;

const Root = styled.div<Root.Props>`
	&.__ignore,
	.leaflet-marker-icon:has(div > &) {
		pointer-events: none;
	}

	display: flex;

	align-items: center;
	justify-content: center;

	width: 100px;
	height: 50px;

	background-color: transparent;

	${({ focused }) => (focused ? focusedStyle : "")}

	& > * {
		pointer-events: all;
	}
`;

declare namespace Root {
	interface Props {
		focused: boolean;
	}
}

export default Root;
