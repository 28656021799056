/* eslint-disable no-param-reassign */
import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Language } from "../../assets/languages/langs";

export const testData = {
	account: {
		loading: false,
		data: null,
		id: "097",
		userName: "Test Testov",
		cityOfTaxiService: "Киев",
		theme: {
			headerBgColor: "rgba(33, 51, 63, 1)",
		},
	},
	authenticated: false,
	errorAuthenticated: false,
	token: null,
	globalLang: "ru",
	softFon: {
		visible: false,
		sizeIsBig: false,
	},
};

interface State {
	token?: {
		value: string;
		userId: string;
		type: "DISPATCHER" | "EXECUTOR" | "UNKNOWN";
	};
	language: Language;
	defaultTaxiServiceId: number | null;
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setToken: Reducer<State["token"]> = (state, { payload }) => {
	state.token = payload;
};

const setLanguage: Reducer<Language> = (state, { payload }) => {
	state.language = payload;
};

const setDefaultTaxiServiceId: Reducer<number | null> = (
	state,
	{ payload },
) => {
	state.defaultTaxiServiceId = payload;
};

const initialState: State = {
	language: "ru",
	defaultTaxiServiceId: null,
};

const session = createSlice({
	name: "session",
	initialState,
	reducers: {
		setToken,
		setLanguage,
		setDefaultTaxiServiceId,
	},
});

export const sessionAction = session.actions;
export const sessionReducer = session.reducer;
export type sessionState = State;
export default session;
