import React, { memo } from "react";
import { Row } from "uikit";

import MinOrderCost from "../../../../../Modal/components/Content/components/MinOrderCost";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../../../../../hooks";

const MinOrderCostOutput: React.FC<MinOrderCostOutput.Props> = memo(
	({ value }) => {
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		return value.active ? (
			<>
				<Row gaps="3px">
					<div>{value.value}</div>
					{currencyGlobalSettings}
				</Row>
				<span>/</span>
			</>
		) : null;
	},
);

declare namespace MinOrderCostOutput {
	interface Props {
		value: MinOrderCost.Value;
	}
}

export default MinOrderCostOutput;
