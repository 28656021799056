import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Language } from "../../../../assets/languages/langs";
import useDateTimeLocalizer from "../../../../hooks/useDateLocalizer";
import { History } from "../../../../redux/services/Order/getHistory";

import Mark from "./Mark";
import HistoryBadges from "./Badges/HistoryBadges";
import {
	CostDiff,
	CustomerDiff,
	PointDiff,
	PassengerPointDiff,
	TimeDifference,
	ValueDifference,
	RateSettingsDifference,
	ServicesDifference,
	CarClassesDifference,
	ExecutorsDifference,
	CarsDifference,
	PhonesDifference,
	StatusDifference,
} from "./DescribeChanges";

const useDescribeChanges = (
	language: Language = "ru",
	displayBadges: HistoryBadges.DisplayBadges = {
		type: true,
		orderNote: true,
		status: true,
		orderNumber: true,
		orderDate: true,
		taxiService: true,
		services: true,
		points: true,
		"passenger.customers": true,
		"passenger.points": true,
		closedOrdersComments: true,
		cost: true,
		idleTimeMilliseconds: true,
		waitingTimeMilliseconds: true,
		hourlyMilliseconds: true,
		passengersCount: true,
		transportationType: true,
		executorNotes: true,
		customerNotes: true,
		outsideSettlementKm: true,
		additionalCost: true,
		rateSettings: true,
		billingCustomer: true,
		paymentAccount: true,
		source: true,
		carClasses: true,
		cars: true,
		executors: true,
		phones: true,
	},
): ((changes: History.Change[]) => (JSX.Element | null)[]) => {
	const { t } = useTranslation();
	const localizeDate = useDateTimeLocalizer();

	return useCallback(
		(changes: History.Change[]) => {
			if (!changes.length) return [];
			return changes
				.map((change) => {
					switch (change.field) {
						case "type":
							return (
								<>
									{displayBadges.type && (
										<>
											<ValueDifference change={change} />
										</>
									)}
								</>
							);
						case "status":
							if (change.actual === change.previous) return null;
							return (
								<>
									{displayBadges.status && (
										<>
											<StatusDifference change={change} />
										</>
									)}
								</>
							);
						case "orderNote":
							if (change.actual === change.previous) return null;
							return (
								<>
									{displayBadges.orderNote && (
										<ValueDifference change={change} />
									)}
								</>
							);

						case "orderNumber":
							if (change.actual === change.previous) return null;
							return (
								<>
									{displayBadges.orderNumber && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "passenger.customers":
							return (
								<>
									{displayBadges["passenger.customers"] && (
										<CustomerDiff change={change} />
									)}
								</>
							);

						case "points":
							return (
								<>
									{displayBadges.points && (
										<>
											<PointDiff change={change} />
										</>
									)}
								</>
							);

						case "passenger.points":
							return (
								<>
									{displayBadges["passenger.points"] && (
										<>
											<PassengerPointDiff
												change={change}
											/>
										</>
									)}
								</>
							);

						case "cost":
							return (
								<>
									{displayBadges.cost && (
										<>
											<CostDiff change={change} />
										</>
									)}
								</>
							);
						case "closedOrdersComments":
							if (change.actual === change.previous) return null;
							return (
								<>
									{displayBadges.closedOrdersComments && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "idleTimeMilliseconds":
							return (
								<>
									{displayBadges.idleTimeMilliseconds && (
										<TimeDifference change={change} />
									)}
								</>
							);

						case "waitingTimeMilliseconds":
							return (
								<>
									{displayBadges.waitingTimeMilliseconds && (
										<TimeDifference change={change} />
									)}
								</>
							);
						case "hourlyMilliseconds":
							return (
								<>
									{displayBadges.hourlyMilliseconds && (
										<TimeDifference change={change} />
									)}
								</>
							);
						case "outsideSettlementKm":
							return (
								<>
									{displayBadges.outsideSettlementKm && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "passengersCount":
							return (
								<>
									{displayBadges.passengersCount && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "transportationType":
							return (
								<>
									{displayBadges.transportationType && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "executorNotes":
							return (
								<>
									{displayBadges.executorNotes && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "customerNotes":
							return (
								<>
									{displayBadges.customerNotes && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "additionalCost":
							return null;
						case "orderDate":
							if (change.actual === change.previous) return null;
							return (
								<>
									{displayBadges.orderDate && (
										<>
											<Trans
												i18nKey={`change_history.orders.fields.${change.field}`}
												context={
													change.previous
														? "change"
														: "set"
												}
												values={{
													prev: localizeDate(
														new Date(
															change.previous,
														),
														"dateTime",
													),
													new: localizeDate(
														new Date(change.actual),
														"dateTime",
													),
												}}
												components={{ mark: <Mark /> }}
											/>
										</>
									)}
								</>
							);
						case "taxiService":
							return (
								<>
									{displayBadges.taxiService && (
										<Trans
											i18nKey={`change_history.orders.fields.${change.field}`}
											context={
												change.previous
													? "change"
													: "set"
											}
											values={{
												prev: change.previous?.[
													language
												],
												new: change.actual?.[language],
											}}
											components={{ mark: <Mark /> }}
										/>
									)}
								</>
							);
						case "services":
							return (
								<>
									{displayBadges.services && (
										<ServicesDifference change={change} />
									)}
								</>
							);

						case "rateSettings":
							return (
								<>
									{displayBadges.rateSettings && (
										<RateSettingsDifference
											change={change}
										/>
									)}
								</>
							);

						case "billingCustomer":
							return (
								<>
									{displayBadges.billingCustomer && (
										<ValueDifference change={change} />
									)}
								</>
							);

						case "paymentAccount":
							return (
								<>
									{displayBadges.paymentAccount && (
										<ValueDifference change={change} />
									)}
								</>
							);
						case "cars":
							return (
								<>
									{displayBadges.cars && (
										<CarsDifference change={change} />
									)}
								</>
							);
						case "executors":
							return (
								<>
									{displayBadges.executors && (
										<ExecutorsDifference change={change} />
									)}
								</>
							);
						case "carClasses":
							return (
								<>
									{displayBadges.carClasses && (
										<CarClassesDifference change={change} />
									)}
								</>
							);

						case "phones":
							return (
								<>
									{displayBadges.phones && (
										<PhonesDifference change={change} />
									)}
								</>
							);
						default:
							return (
								<p>
									{t(`change_history.change.unknown`, {
										field: (change as any)?.field,
									})}
								</p>
							);
					}
				})
				.filter(Boolean);
		},
		[language, localizeDate, t, displayBadges],
	);
};

export default useDescribeChanges;
