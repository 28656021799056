import React, { Dispatch, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Float, Icon, Row, useRefSize } from "uikit";

import { Popup } from "../../../../../../common";

import Root from "./components/Root";
import TextInput from "./components/TextInput";
import ClearButton from "./components/ClearButton";
import OptionList from "./components/OptionList";

const optionsOffset: Float.OffsetCallback = ({ tracker, float }) => {
	if (!tracker || !float) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	return { x: -7, y: Number(trackerSize.height) + 14 };
};

// eslint-disable-next-line prettier/prettier
// prettier-ignore
const SearchBar = <OptionValue, >({
	value = "",
	options,
	onChange,
	onSelect,
}: SearchBar.Props<OptionValue>) => {
  const { t } = useTranslation();

	const containerId = useMemo(() => uuid(), []);

	const { ref: textInputContainerRef, size: textInputContainerSize } =
		useRefSize();
	const textInputRef = useRef<HTMLInputElement | null>(null);

	const [textInputFocus, setTextInputFocus] = useState(false);

	const textInputOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChange?.(event.target.value);
		},
		[onChange],
	);

	const textInputOnFocus = useCallback(() => setTextInputFocus(true), []);

	const textInputOnBlur = useCallback(() => setTextInputFocus(false), []);

	const clearButtonOnClick = useCallback(() => {
		onChange?.("");
	}, [onChange]);

	const popupOpen = useMemo(
		() => textInputFocus && !!options && options.length !== 0,
		[options, textInputFocus],
	);

	const optionListOnSelect = useCallback(
		(option: OptionList.Option<OptionValue>) => {
			onSelect?.(option);

			textInputRef.current?.blur();
		},
		[onSelect],
	);

	return (
		<Float.Container id={containerId}>
			<Root align="center" gaps="8px*" sizes="auto! 1fr" maxedWidth>
				<Icon id="search" size={20} colors={["#9BA3A8"]} />
				<Popup
					open={popupOpen}
					tracker={
						<Row
							ref={textInputContainerRef}
							gaps="8px*"
							sizes="1fr auto!"
							maxedWidth
						>
							<TextInput
								ref={textInputRef}
								value={value}
								placeholder={
									t(
										"orderPageWidgets.map2.header.searchBar.str200",
									) ?? ""
								}
								onChange={textInputOnChange}
								onFocus={textInputOnFocus}
								onBlur={textInputOnBlur}
							/>
							{value && (
								<ClearButton onClick={clearButtonOnClick} />
							)}
						</Row>
					}
					containerId={containerId}
					offset={optionsOffset}
				>
					<OptionList
						width={textInputContainerSize.width + 14}
						options={options}
						onSelect={optionListOnSelect}
					/>
				</Popup>
			</Root>
		</Float.Container>
	);
};

declare namespace SearchBar {
	type Value = string;

	type Option<OptionValue> = OptionList.Option<OptionValue>;

	interface Props<OptionValue> {
		value?: Value;

		options?: Option<OptionValue>[];

		onChange?: Dispatch<Value>;

		onSelect?: Dispatch<Option<OptionValue>>;
	}
}

export default SearchBar;
