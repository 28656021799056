export const intlDateFormat: Intl.DateTimeFormatOptions = {
	day: "2-digit",
	month: "2-digit",
	year: "numeric",
};

export const intlTimeFormat: Intl.DateTimeFormatOptions = {
	hour: "2-digit",
	minute: "2-digit",
	second: "2-digit",
};

export const intlDateTimeFormat: Intl.DateTimeFormatOptions = {
	...intlDateFormat,
	...intlTimeFormat,
};
