import { useCallback, useMemo, useState } from "react";
import { Nullable } from "uikit";
import { isNumber } from "lodash";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import CarBaseType from "../../../../../../../services/CarBaseType";
import ModelList from "../components/ModelList";
import { SortingOrder } from "../../../../../../../types/SortingOrder";

function useCarBaseTypeModelList(options: useCarBaseTypeModelList.Options) {
	const [offset, setOffset] = useState(0);
	const [chunkSize, setChunkSize] = useState(30);
	const [selected, setSelected] = useState<Nullable<number>>(null);
	const [sort, setSort] = useState<SortingOrder>("ASC");

	const subscribeOptions = useMemo<CarBaseType.SubscribeOptions>(
		() => ({
			offset,
			limit: chunkSize,

			query: options.search,
			active: options.active,
			native: options.native,

			order: {
				name: sort,
			},
		}),
		[
			offset,
			chunkSize,
			options.search,
			options.active,
			options.native,
			sort,
		],
	);

	const data = useModelSubscribe(subscribeOptions, CarBaseType);

	const models = useMemo(() => data.models ?? [], [data.models]);

	const total = useMemo(
		() => (data.metadataState.total ?? 0) as number,
		[data.metadataState.total],
	);

	const items = useMemo<ModelList.Item[]>(
		() =>
			models.map((model) => ({
				id: model.id,

				active: model.active,
				// deletable: !model.native,
				label: model.name,
			})),
		[models],
	);

	const selectedSubscribeOptions = useMemo<CarBaseType.SubscribeOptions>(
		() => ({
			ids: isNumber(selected) ? [selected] : [],
		}),
		[selected],
	);

	const selectedData = useModelSubscribe(
		selectedSubscribeOptions,
		CarBaseType,
	);

	const selectedModels = useMemo(
		() => selectedData.models ?? [],
		[selectedData.models],
	);

	const selectedItem = useMemo(() => selectedModels.at(0), [selectedModels]);

	const isSelectedActive = useMemo(
		() => selectedItem?.active ?? false,
		[selectedItem],
	);

	const isSelectedNative = useMemo(
		() => selectedItem?.native ?? false,
		[selectedItem],
	);

	const onChangeActive = useCallback((id: number, active: boolean) => {
		CarBaseType.update({ id, active });
	}, []);

	const onDelete = useCallback(async (id: number) => {
		await CarBaseType.destroy(id);
	}, []);

	return {
		offset,
		setOffset,

		chunkSize,
		setChunkSize,

		selected,
		setSelected,

		sort,
		setSort,

		data,
		models,
		total,
		items,

		isSelectedActive,
		isSelectedNative,

		onChangeActive,

		onDelete,
	};
}

declare namespace useCarBaseTypeModelList {
	interface Options {
		search?: string;

		active?: boolean;
		native?: boolean;
	}
}

export default useCarBaseTypeModelList;
