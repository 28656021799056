/* eslint-disable consistent-return */
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
	Button,
	Float,
	Icon,
	Key,
	Popover as PopoverBase,
	Select,
	TextBox,
	theme,
} from "uikit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { TransactionAction } from "../../../../../../../../../../../../services/Transaction/types";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import { ValueLanguage } from "../../../../../../../../../../../../assets/languages/langs";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../../../../../components/common";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import { ModalFilter } from "../../../../../..";

import Header from "./components/Header";
import Root from "./components/Root";

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	const floatSize = { width: 520, height: 242 };

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

interface Props {
	filter: ModalFilter;
	setFilter: React.Dispatch<React.SetStateAction<ModalFilter>>;
	open: boolean;
	onClose: () => void;
}

type Name = Record<ValueLanguage, string>;

interface TargetAction {
	id: number;
	name: Name;
}

const dataTargetAction: TargetAction[] = [
	{
		id: 1,
		name: {
			uk: "Вывод средств",
			ru: "Вывод средств",
			en: "Withdrawal of funds",
			tr: "Withdrawal of funds",
			az: "Withdrawal of funds",
		},
	},
	{
		id: 2,
		name: {
			uk: "Поступление средств",
			ru: "Поступление средств",
			en: "Income of funds",
			tr: "Income of funds",
			az: "Income of funds",
		},
	},
];

export const StyledTextBox = styled(TextBox.TextBox)`
	height: 32px;
	.${TextBox.classes.input} {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;

		color: ${theme.colors.primary};
		::placeholder {
			text-transform: none;
			color: #9ba3a8;
			font-weight: 300;
			font-size: 14px;
			text-transform: none;
			line-height: 18px;
			height: 25px;
		}
	}
`;
const Popover: React.FC<Props> = ({ filter, setFilter, open, onClose }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const [requestData, setRequestData] = useState<ModalFilter>({
		paymentAccountIds: [],
		targetAction: undefined,
		operationId: undefined,
	});

	useLayoutEffect(() => {
		setRequestData(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const valueEditor = useObjectEditor(requestData, setRequestData);

	const targetAction = valueEditor.useGetter("targetAction");
	const setTargetAction = valueEditor.useSetter("targetAction");
	const operationId = valueEditor.useGetter("operationId");
	const setOperationId = valueEditor.useSetter("operationId");

	const clearFilters = useCallback(() => {
		setRequestData({
			paymentAccountIds: [],
			targetAction: undefined,
			operationId: undefined,
		});
	}, []);

	const apply = useCallback(() => {
		setFilter(requestData);
		onClose();
	}, [onClose, requestData, setFilter]);

	const updateFilters = useCallback(() => {}, []);

	const cancel = useCallback(() => {
		updateFilters();
		onClose();
	}, [onClose, updateFilters]);

	const selectOptionsWrap = useCallback(
		(item: { id: number; label: string }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);

	const valueTargetAction = useMemo(() => {
		if (targetAction === TransactionAction.TO) return 1;
		if (targetAction === TransactionAction.FROM) return 2;
		return undefined;
	}, [targetAction]);

	const optionsTargetAction = useMemo(
		() =>
			dataTargetAction.map((target) =>
				selectOptionsWrap({
					id: target.id,
					label: target.name[language],
				}),
			),
		[language, selectOptionsWrap],
	);

	const handleTargetAction = useCallback(
		(number: Key) => {
			if (number === 1) setTargetAction(TransactionAction.TO);
			if (number === 2) setTargetAction(TransactionAction.FROM);
		},
		[setTargetAction],
	);

	const onChangeOperationId = useCallback(
		(value: string) => {
			if (value) setOperationId(Number(value));
			else setOperationId(undefined);
		},
		[setOperationId],
	);

	return (
		<PopoverBase
			open={open}
			containerId="root"
			trackerId="filters"
			offset={additionalFiltersOffsetCallback}
			onClose={onClose}
			style={{ boxShadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
		>
			<Root align="stretch" gaps="16px 20px">
				<Header onClose={onClose} />
				<FieldsContainer>
					<StyledColumn w="200px" gap="10px">
						<LabeledField
							label={
								t(
									"pages.mainPage.pages.archives.tabs.transactions.header.filter.popover.str3",
								) ?? ""
							}
						>
							<Select<number>
								value={valueTargetAction}
								placeholder={
									t(
										"pages.mainPage.pages.archives.tabs.transactions.header.filter.popover.str2",
									) ?? ""
								}
								options={optionsTargetAction}
								onChange={handleTargetAction}
							/>
						</LabeledField>
						<StyledTextBox
							autoComplete="off"
							min={0}
							type={"number"}
							placeholder={
								t(
									"pages.mainPage.pages.archives.tabs.transactions.header.filter.popover.str1",
								) ?? ""
							}
							value={operationId ? String(operationId) : ""}
							onChange={onChangeOperationId}
						/>
					</StyledColumn>
				</FieldsContainer>

				<StyledRow justify="space-between">
					<Button.Button
						icon={<Icon size={18} id="refresh" />}
						variant="secondary"
						onClick={clearFilters}
					/>
					<StyledRow gap="20px">
						<Button.Button
							variant="secondary"
							text={t("cancel") || "Cancel"}
							onClick={cancel}
						/>
						<Button.Button
							text={
								t(
									"pages.mainPage.pages.archives.tabs.transactions.header.filter.popover.str0",
								) ?? ""
							}
							onClick={apply}
						/>
					</StyledRow>
				</StyledRow>
			</Root>
		</PopoverBase>
	);
};

export default Popover;
