import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { react } from "uikit";
import { isEqual, uniq } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import CarClass from "../../../../../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../components/FieldsContainer";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import Service from "../../../../../../../../../../services/Service";
import {
	CheckBoxWithText,
	StyledColumn,
	StyledGrid,
} from "../../../../../../../../../../components/common";

import InternalController from "./Controller";

const Services = react.withController<Services.PropsBase, Services.Controller>(
	({ controller, value, disabled, onChange, taxiServiceIds }) => {
		const { t } = useTranslation();
		const language = useTypedSelector((state) => state.session.language);

		const option = useMemo(
			() => ({ taxiServiceIds, availableForCar: true }),
			[taxiServiceIds],
		);
		const serviceData = useModelSubscribe(option, Service);
		const services = useMemo(
			() => serviceData?.models ?? [],
			[serviceData?.models],
		);

		const servicesForCar = useMemo(() => {
			if (!services.length) return [];
			if (!taxiServiceIds?.length) return [];
			return services.filter((item) => item.availableForCar === true);
		}, [services, taxiServiceIds]);

		const servicesForCarIds = useMemo(
			() => servicesForCar.map((item) => item.id),
			[servicesForCar],
		);

		const valueEditor = useObjectEditor(value, onChange);

		const serviceAvailableIds = valueEditor.useGetter(
			"serviceAvailableIds",
		);
		const setServiceAvailableIds = valueEditor.useSetter(
			"serviceAvailableIds",
		);

		const serviceDefaultIds = valueEditor.useGetter("serviceDefaultIds");
		const setServiceDefaultIds = valueEditor.useSetter("serviceDefaultIds");

		const debounceFn = useDebouncedCallback(() => {
			if (serviceDefaultIds.length || serviceAvailableIds.length) {
				const defaultIds: number[] = [];
				const availableIds: number[] = [];

				servicesForCarIds.forEach((serviceId) => {
					const defaultId = serviceDefaultIds.find(
						(id) => id === serviceId,
					);
					const availableId = serviceAvailableIds.find(
						(id) => id === serviceId,
					);

					if (defaultId) defaultIds.push(defaultId);
					if (availableId) availableIds.push(availableId);
				});

				if (!isEqual(defaultIds, serviceDefaultIds)) {
					setServiceDefaultIds(defaultIds);
				}
				if (!isEqual(availableIds, serviceAvailableIds)) {
					setServiceAvailableIds(availableIds);
				}
			}
		}, 1000);

		useLayoutEffect(() => {
			debounceFn();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [servicesForCarIds, serviceDefaultIds, serviceAvailableIds]);

		const handleServiceAvailableIds = useCallback(
			(id: number, status: boolean) => {
				if (status) {
					const ids = uniq([...serviceAvailableIds, id]);
					setServiceAvailableIds(ids);
				} else {
					const ids = serviceAvailableIds.filter(
						(item) => item !== id,
					);
					setServiceAvailableIds(ids);
					const defaultIds = serviceDefaultIds.filter(
						(item) => item !== id,
					);
					setServiceDefaultIds(defaultIds);
				}
			},
			[
				serviceAvailableIds,
				serviceDefaultIds,
				setServiceAvailableIds,
				setServiceDefaultIds,
			],
		);

		const handleServiceDefaultIds = useCallback(
			(id: number, status: boolean) => {
				if (status) {
					const ids = uniq([...serviceDefaultIds, id]);
					setServiceDefaultIds(ids);
				} else {
					const ids = serviceDefaultIds.filter((item) => item !== id);
					setServiceDefaultIds(ids);
				}
			},
			[serviceDefaultIds, setServiceDefaultIds],
		);

		controller.setContext({});
		return (
			<StyledGrid
				areas=""
				columns="repeat(2,1fr)"
				w="100%"
				h="100%"
				gap="20px"
			>
				<FieldsContainer
					label={
						t(
							"pages.preferencesPages.screenDirectory.carClass.modal.services.str0",
						) ?? ""
					}
				>
					<StyledColumn
						w="100%"
						flex={{ flex: "1 0 300px" }}
						gap="20px"
						p="1rem 0 0 2px"
						scrollbar
						overY="auto"
					>
						{servicesForCar.map((item) => (
							<CheckBoxWithText
								key={item.id}
								disabled={disabled}
								value={serviceAvailableIds.includes(item.id)}
								title={item.name?.[language]}
								onChange={(data) => {
									handleServiceAvailableIds(item.id, data);
								}}
							/>
						))}
					</StyledColumn>
				</FieldsContainer>
				<FieldsContainer
					label={
						t(
							"pages.preferencesPages.screenDirectory.carClass.modal.services.str1",
						) ?? ""
					}
				>
					<StyledColumn
						w="100%"
						flex={{ flex: "1 0 300px" }}
						gap="20px"
						p="1rem 0 0 2px"
						scrollbar
						overY="auto"
					>
						{servicesForCar.map((item) => (
							<CheckBoxWithText
								key={item.id}
								disabled={
									disabled ||
									!serviceAvailableIds?.includes(item.id)
								}
								value={serviceDefaultIds.includes(item.id)}
								title={item.name?.[language]}
								onChange={(data) => {
									handleServiceDefaultIds(item.id, data);
								}}
							/>
						))}
					</StyledColumn>
				</FieldsContainer>
			</StyledGrid>
		);
	},
	InternalController,
);

declare namespace Services {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface Value extends Record<string, any> {
		serviceAvailableIds: CarClass.Model["serviceAvailableIds"];
		serviceDefaultIds: CarClass.Model["serviceDefaultIds"];
	}

	interface PropsBase {
		value: Value;
		disabled: boolean;
		onChange: Dispatch<Value>;
		taxiServiceIds: CarClass.Model["taxiServiceIds"];
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Services;
