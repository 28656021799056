import styled from "styled-components";
import { theme } from "uikit";

const Text = styled.div<Text.Props>`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;

	color: ${({ isPlaceholder }) =>
		isPlaceholder
			? theme.colors.text_hovered_placeholder
			: theme.colors.primary};

	${theme.longWord}
`;

declare namespace Text {
	interface Props {
		isPlaceholder: boolean;
	}
}

export default Text;
