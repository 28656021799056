import { TFunction } from "i18next";

// Defines the possible id for column options
type ColumnId =
	| "date"
	| "action"
	| "user"
	| "comment"
	| "name"
	| "mode"
	| "companies"
	| "createdAt"
	| "orderCount"
	| "discount"
	| "onlineDiscount"
	| "rating"
	| "customer"
	| "orderNumber"
	| "fullName"
	| "phones"
	| "alias"
	| "role"
	| "status"
	| "registeredAt"
	| "access"
	| "birthAt"
	| "roles"
	| "active"
	| "alphaName"
	| "branches"
	| "count"
	| "value"
	| "taxiServices";

// Describes the structure of a column option
interface ColumnOption {
	id: ColumnId; // Unique identifier for the column
	label: string; // Display label for the column
}

/**
 * Retrieves column options based on selected keys and the translation function.
 *
 * @param t - The translation function from i18next used for localizing column labels.
 * @param selectedColumns - Array of keys representing the selected columns.
 * @returns An array of `ColumnOption` objects for the selected columns, including localized labels.
 *
 * @example
 * const { t } = useTranslation();
 *
 * const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
 *     () => ["name", "mode", "companies", "createdAt"],
 *     []
 * );
 *
 * const columns = useMemo(
 *     () => getColumnsModeTwo(t, defaultColumnIds),
 *     [t, defaultColumnIds]
 * );
 */
export const getColumnsModeTwo = (
	t: TFunction, // Translation function from i18next
	selectedColumns: ColumnId[], // Array of selected column keys
): ColumnOption[] => {
	// Define all available column options with localized labels
	const allColumns: Record<ColumnId, ColumnOption> = {
		date: {
			id: "date",
			label: t("utils.getColumnsModeOne.date") ?? "Date",
		},
		action: {
			id: "action",
			label: t("utils.getColumnsModeOne.action") ?? "Action",
		},
		user: {
			id: "user",
			label: t("utils.getColumnsModeOne.user") ?? "Appointed",
		},
		comment: {
			id: "comment",
			label: t("utils.getColumnsModeOne.comment") ?? "Comment",
		},
		name: {
			id: "name",
			label: t("utils.getColumnsModeOne.name") ?? "Name",
		},
		mode: {
			id: "mode",
			label: t("utils.getColumnsModeOne.mode") ?? "Mode",
		},
		companies: {
			id: "companies",
			label: t("utils.getColumnsModeOne.companies") ?? "Companies",
		},
		createdAt: {
			id: "createdAt",
			label: t("utils.getColumnsModeOne.createdAt") ?? "Date of creation",
		},
		orderCount: {
			id: "orderCount",
			label:
				t("utils.getColumnsModeOne.orderCount") ?? "Number of orders",
		},
		discount: {
			id: "discount",
			label: t("utils.getColumnsModeOne.discount") ?? "Discount",
		},
		onlineDiscount: {
			id: "onlineDiscount",
			label:
				t("utils.getColumnsModeOne.onlineDiscount") ??
				"Discount for online orders",
		},
		rating: {
			id: "rating",
			label: t("utils.getColumnsModeOne.rating") ?? "Rating",
		},
		customer: {
			id: "customer",
			label: t("utils.getColumnsModeOne.customer") ?? "Client",
		},
		orderNumber: {
			id: "orderNumber",
			label: t("utils.getColumnsModeOne.orderNumber") ?? "Order number",
		},
		fullName: {
			id: "fullName",
			label: t("utils.getColumnsModeOne.fullName") ?? "Full Name",
		},
		phones: {
			id: "phones",
			label: t("utils.getColumnsModeOne.phones") ?? "Phones",
		},
		alias: {
			id: "alias",
			label: t("utils.getColumnsModeOne.alias") ?? "Alias",
		},
		role: {
			id: "role",
			label: t("utils.getColumnsModeOne.role") ?? "Role",
		},
		status: {
			id: "status",
			label: t("utils.getColumnsModeOne.status") ?? "Status",
		},
		registeredAt: {
			id: "registeredAt",
			label:
				t("utils.getColumnsModeOne.registeredAt") ??
				"Registration Date",
		},
		access: {
			id: "access",
			label: t("utils.getColumnsModeOne.access") ?? "Access",
		},
		birthAt: {
			id: "birthAt",
			label: t("utils.getColumnsModeOne.birthAt") ?? "Date of Birth",
		},
		roles: {
			id: "roles",
			label: t("utils.getColumnsModeOne.roles") ?? "Job Title",
		},
		active: {
			id: "active",
			label: t("utils.getColumnsModeOne.active") ?? "Active",
		},
		alphaName: {
			id: "alphaName",
			label: t("utils.getColumnsModeOne.alphaName") ?? "Alpha name",
		},
		branches: {
			id: "branches",
			label: t("utils.getColumnsModeOne.branches") ?? "Branches",
		},
		count: {
			id: "count",
			label: t("utils.getColumnsModeOne.count") ?? "Number of passengers",
		},
		value: {
			id: "value",
			label: t("utils.getColumnsModeOne.value") ?? "Value",
		},
		taxiServices: {
			id: "taxiServices",
			label: t("utils.getColumnsModeOne.taxiServices") ?? "Branches",
		},
	};

	// Filter and map the selected columns to their corresponding options
	return selectedColumns
		.filter((columnKey) => columnKey in allColumns) // Ensure only valid column keys are included
		.map((columnKey) => allColumns[columnKey]); // Map column keys to their options
};

export default getColumnsModeTwo;
