import React, { memo, Suspense, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

import { History } from "../../../../../../redux/services/Order/getHistory";
import { StyledColumn, StyledRow } from "../../../../../../components/common";
import Mark from "../../Mark";
import Marker from "../../Marker";
import { useGetDifferenceInArray } from "../hooks";

const ExecutorsDifference: React.FC<ExecutorsDifference.Props> = ({
	change,
}) => {
	const { t } = useTranslation();
	const executors = t("executors");
	const at = t("at");
	const previous = useMemo(
		() =>
			change.previous?.length
				? change.previous.map((item) => {
						try {
							const time = `${moment
								.utc(item.arrivalTime || 0)
								.format("HH:mm")} ${
								t(`units.minute`) ?? "min"
							}`;
							return `${item?.callSign} ${at} ${time}`;
						} catch {
							return "";
						}
				  })
				: [],
		[at, change.previous, t],
	);
	const actual = useMemo(
		() =>
			change.actual?.length
				? change.actual.map((item) => {
						try {
							const time = `${moment
								.utc(item.arrivalTime || 0)
								.format("HH:mm")} ${
								t(`units.minute`) ?? "min"
							}`;
							return `${item?.callSign} ${at} ${time}`;
						} catch {
							return "";
						}
				  })
				: [],
		[at, change.actual, t],
	);

	const items = useGetDifferenceInArray({
		previous,
		actual,
		type: change.type,
	});

	return (
		<Suspense key={`${change.field}-${change.type}`}>
			{!!items.length && (
				<StyledColumn>
					<p key={executors}>{executors}:</p>
					{items.map((item, i) => {
						if (!item.prev && !item.new) return <></>;
						return (
							<StyledRow key={i} p="0 0 0 5px">
								<Marker />
								<Trans
									i18nKey={`change_history.orders.fields.${change.field}`}
									context={item.context}
									values={{
										prev: item.prev,
										new: item.new,
									}}
									components={{ mark: <Mark /> }}
								/>
							</StyledRow>
						);
					})}
				</StyledColumn>
			)}
		</Suspense>
	);
};

declare namespace ExecutorsDifference {
	interface Props {
		change: History.Field.Executors;
	}
}

export const ExecutorsDifferenceMemo = memo(ExecutorsDifference);
export default ExecutorsDifference;
