import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "uikit";

import NavigationLayout from ".";

const LinkStyled = styled(Link)<NavigationLayout.LinkStyled>`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	line-height: 19px;
	gap: 5px;
	color: ${({ disabled }) =>
		disabled ? theme.colors.disabled_text : "#647079"};
	width: 168px;
	height: max-content;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	text-decoration: none;
	border-color: ${theme.colors.color_border_basic};

	${({ disabled }) =>
		disabled
			? css`
					&:hover {
						color: ${theme.colors.disabled_text};
					}
			  `
			: css`
					&:hover {
						--icon-color-1: ${theme.colors.accent};
						color: ${theme.colors.accent};
						border-color: ${theme.colors.accent};
					}
			  `}
`;

const ImageStyled = styled.div<NavigationLayout.ImageStyled>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 168px;
	height: 168px;
	border: 1px solid #eaebec;
	box-sizing: border-box;
	border-radius: 15px;
	border-color: inherit;

	${(options) =>
		(options.colorizeFill ?? true) &&
		`fill: ${
			options.disabled
				? theme.colors.disabled_text
				: theme.colors.secondary
		};`}
	${(options) =>
		(options.colorizeStroke ?? false) &&
		`stroke: ${
			options.disabled
				? theme.colors.disabled_text
				: theme.colors.secondary
		};`}
`;

const NavigationItem: React.FC<NavigationLayout.ILink> = ({
	text,
	disabled,
	link,
	Image,
	colorizeFill,
	colorizeStroke,
}) => (
	<LinkStyled to={disabled ? "#" : link} disabled={disabled}>
		<ImageStyled
			colorizeFill={colorizeFill}
			colorizeStroke={colorizeStroke}
			disabled={disabled}
		>
			<Image />
		</ImageStyled>
		{text}
	</LinkStyled>
);

export default NavigationItem;
