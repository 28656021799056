import { Button, Icon, Row, TextBox } from "uikit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isNumber } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Header from "../../../../../../../../../../components/BasicPageLayout/Header";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe";
import TaxiService from "../../../../../../../../../../services/TaxiService";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { GetExecutorLocationsHistoryFilters } from "../../../../../../../../../../redux/services/Executor/getExecutorTrack";
import useDebouncedValue from "../../../../../../../../../../hooks/useDebouncedValue";
import getExecutorByCallSign from "../../../../../../../../../../redux/services/Executor/getExecutorByCallSign";
import archiveGPSLog from "../../../../../../../../../../redux/reducers/Archives/GPSLog";
import { SVGgeneral } from "../../../../../../../../../../utils/generalSprite";

interface Props {
	onSearch: (filters: GetExecutorLocationsHistoryFilters) => void;
	openWorkHistoryModal: () => any;
}

const GPSLogHeader: React.FC<Props> = ({ onSearch, openWorkHistoryModal }) => {
	const { t } = useTranslation();
	const callsignExists = useTypedSelector(
		(state) => state.archiveGPSLog.callsignExists,
	);
	const mapLang = useTypedSelector((state) => state.mapReducer.mapLang);
	const globalLang = useTypedSelector((state) => state.session.language);
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [dateFrom, setDateFrom] = useState(moment().startOf("day").toDate());
	const [dateTo, setDateTo] = useState(moment().endOf("day").toDate());
	const [executorCallSign, setDriverCallsign] = useState("");
	const [licensePlate, setLicensePlate] = useState("");
	const [parkNumber, setParkNumber] = useState("");

	const debouncedExecutorCallSign = useDebouncedValue(executorCallSign, 250);

	useEffect(() => {
		if (!debouncedExecutorCallSign) {
			archiveGPSLog.actions.setCallsignExists(undefined);
			return;
		}

		getExecutorByCallSign({
			callsign: debouncedExecutorCallSign,
			callback(data) {
				console.log("getExecutorByCallSign", data);

				archiveGPSLog.actions.setCallsignExists(
					executorCallSign ? !!data : undefined,
				);
			},
		});
	}, [debouncedExecutorCallSign, executorCallSign]);

	const [companyFilter, setCompanyFilter] = useState<
		CompaniesAndTaxiServicesFilter.Props["companies"]
	>(["all"]);
	const [taxiServiceFilter, setTaxiServiceFilter] = useState<
		CompaniesAndTaxiServicesFilter.Props["taxiServices"]
	>(["all"]);

	const taxiServiceIds = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			taxiServiceFilter[0] !== "all"
				? (taxiServiceFilter as number[])
				: companyFilter[0] !== "all"
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								(companyFilter as Array<number>).includes(
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									taxiService.company!.id,
								),
						)
						.map((taxiService) => taxiService.id)
				: undefined,
		[companyFilter, taxiServiceFilter, taxiServices],
	);

	const dateRange = useMemo(
		() => ({
			from: dateFrom,
			to: dateTo,
		}),
		[dateFrom, dateTo],
	);

	const params = useMemo(() => {
		// eslint-disable-next-line no-shadow
		const params: GetExecutorLocationsHistoryFilters = {
			dateRange,
			taxiServiceIds,
			executorCallSign,
			carNumber: licensePlate,
			// parkNumber,
		};

		console.log("params:", params);
		return params;
	}, [dateRange, executorCallSign, licensePlate, taxiServiceIds]);

	const search = useCallback(() => {
		onSearch(params);
	}, [onSearch, params]);

	return (
		<Header
			style={{
				boxShadow: `none`,
			}}
		>
			<Row justify="space-between" maxedWidth gaps="5px*">
				<Row maxedWidth gaps="5px*" align="center">
					{/* {t(`from`, { context: "capitalized" })}
					<DateAndTimePicker
						value={dateFrom}
						onChange={setDateFrom}
						locale={{
							moment: globalLang,
							submitButton: t(`ok`) ?? undefined,
							cancelButton: t(`cancel`) ?? undefined,
							todayButton: t(`today`) ?? undefined,
						}}
					/>

					{t(`to`)}
					<DateAndTimePicker
						value={dateTo}
						onChange={setDateTo}
						locale={{
							moment: globalLang,
							submitButton: t(`ok`) ?? undefined,
							cancelButton: t(`cancel`) ?? undefined,
							todayButton: t(`today`) ?? undefined,
						}}
					/> */}

					<Row
						style={{
							flexGrow: 1,
							maxWidth: "800px",
						}}
						gaps="5px*"
						align="center"
						sizes="1fr!*"
					>
						<CompaniesAndTaxiServicesFilter
							companies={companyFilter}
							taxiServices={taxiServiceFilter}
							language={mapLang}
							onChangeCompanies={setCompanyFilter}
							onChangeTaxiServices={setTaxiServiceFilter}
						/>

						<TextBox.TextBox
							placeholder={
								t("mainPage.archives.gpsLog.driverCallsign") ??
								"Driver callsign"
							}
							value={executorCallSign}
							onChange={setDriverCallsign}
						/>

						<TextBox.TextBox
							placeholder={
								t("mainPage.archives.gpsLog.licensePlate") ??
								"License plate"
							}
							value={licensePlate}
							onChange={setLicensePlate}
						/>

						<TextBox.TextBox
							placeholder={
								t("mainPage.archives.gpsLog.parkNumber") ??
								"Park number"
							}
							value={parkNumber}
							onChange={setParkNumber}
						/>
					</Row>

					<Button.Button
						variant="primary"
						icon={<Icon id="search" size={20} />}
						onClick={search}
					/>
				</Row>
				<Row gaps="5px*">
					<Button.Button
						variant="secondary"
						icon={<SVGgeneral id="transaction-history" size={20} />}
						onClick={openWorkHistoryModal}
					/>
					<Button.Button
						variant="secondary"
						icon={<Icon id="refresh" size={20} />}
						onClick={() => ""}
					/>
					<Button.Button
						variant="secondary"
						icon={<Icon id="export-page" size={20} />}
					/>
				</Row>
			</Row>
		</Header>
	);
};

export default GPSLogHeader;
