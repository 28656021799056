import { useCallback, useMemo } from "react";

import mapByKey from "../utils/mapByKey";
import Company from "../services/Company";

import useTaxiServices from "./useTaxiServices";
import useModelSubscribe from "./useModelSubscribe";

function useGetCompanyIdsByTaxiServiceIds() {
	const companyData = useModelSubscribe({}, Company);

	const companies = useMemo(
		() => companyData?.cache ?? [],
		[companyData?.cache],
	);

	const companyIds = useMemo(
		() => companies?.map((company) => company.id),
		[companies],
	);

	const taxiServices = useTaxiServices();

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const getter = useCallback(
		(taxiServiceIds: number[]) => {
			try {
				return companyIds?.filter(
					(companyId) =>
						!!taxiServiceIds?.find(
							(taxiServiceId) =>
								taxiServiceById?.[taxiServiceId]?.company
									?.id === companyId,
						),
				);
			} catch (error) {
				return [];
			}
		},
		[companyIds, taxiServiceById],
	);

	return getter;
}

export default useGetCompanyIdsByTaxiServiceIds;
