/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, { useCallback, useMemo, useState } from "react";
import { isEqual, isNumber } from "lodash";

import DeleteModal from "../../../../../../components/DeleteModal";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import Service from "../../../../../../services/CustomerRate";
import mapByKey from "../../../../../../utils/mapByKey";
import { useSort } from "../../../../../../hooks/useTableSettings";
import useCompanyIdsDecoder from "../../../../../../hooks/useCompanyIdsDecoder";
import { createObjectLanguageNames } from "../../../../../../assets/languages/langs";

import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const CustomerTariffPlans: React.FC = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const decodeCompanyIds = useCompanyIdsDecoder();

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});

	const modelSubscriptionOptions = useMemo<Service.SubscribeOptions>(
		() => ({
			query: headerFilters.search,
		}),
		[headerFilters.search],
	);

	const modelData = useModelSubscribe(modelSubscriptionOptions, Service);

	const modelItems = useMemo(() => modelData.models, [modelData.models]);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"settings.mutualSettlements.customerTariffs",
		"customerTariff",
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				id: item.id,

				companyIds: item.companies?.map((company) => company.id) ?? [],
				customerIds: item.customerIds ?? [],

				name: item.name.ru,
				type: item.type,
				mode: item.mode,
				modeData: item.modeData,

				generalData: item.generalData,

				active: item.active,
				default: item.default,

				createdAt: item.createdAt,
			});
		},
		[modelItemById],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() =>
			selected.length !== 0 &&
			selected.every((id) => !modelItemById[id].default),
		[modelItemById, selected],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			name: "",

			companyIds: [],
			customerIds: [],

			type: Service.Model.Type.Main,
			mode: Service.Model.Mode.Basic,
			modeData: {},

			generalData: {
				maxDiscount: {
					active: false,
					value: 0,
				},
				executorCompensation: {
					active: false,
					unit: Service.Model.Unit.Percentage,
					value: 0,
					maxAmount: {
						active: false,
						value: 0,
					},
				},
				appliesToMinimal: false,
			},

			active: true,
			default: false,
		});
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			setSort,
			settingsLanguage,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);

				return;
			}

			const modeData: Service.Model.ModeData = {
				fixed: newItem.modeData.fixed,
				cumulative: newItem.modeData.cumulative,
				bonusTrip: newItem.modeData.bonusTrip,
				basic: newItem.modeData.basic,
				flexible: newItem.modeData.flexible,
			};

			const newProperties: Service.Model.New = {
				companyIds: decodeCompanyIds(newItem.companyIds),
				customerIds: newItem.customerIds,

				type: newItem.type,
				default: newItem.default,
				name: createObjectLanguageNames(newItem.name),
				// name: {
				// 	uk: newItem.name,
				// 	az: newItem.name,
				// 	tr: newItem.name,
				// 	en: newItem.name,
				// 	ru: newItem.name,
				// },
				active: newItem.active,

				mode: newItem.mode,
				generalData: newItem.generalData,
				modeData,
			};

			if (isNumber(newItem.id))
				await Service.update({
					id: newItem.id,

					...newProperties,
				});
			else await Service.store(newProperties);

			setEditingItem(null);
		},
		[decodeCompanyIds, editingItem],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await Service.destroy(selected);

		setSelected([]);
	}, [selected]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default CustomerTariffPlans;
