import React, { Suspense } from "react";
import { Column } from "uikit";

import { SuspenseLoader } from "../../../components";

import { FooterMemo } from "./components/Footer";
import GPSLogHeader from "./components/Header";
import GPSLogMapContainer from "./components/MapContainer";
import PlayerControl from "./components/PlayerControl";

const GPSLog: React.FC = (): JSX.Element => (
	<Suspense fallback={<SuspenseLoader />}>
		<Column maxedWidth maxedHeight justify="stretch" align="center">
			<GPSLogHeader />
			<PlayerControl />
			<GPSLogMapContainer />
			<FooterMemo />
		</Column>
	</Suspense>
);

export default GPSLog;
