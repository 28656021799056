import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	position: absolute;

	bottom: 51px;
	left: 12px;

	width: 320px;
	height: 300px;

	border-radius: 15px;
	background-color: ${theme.colors.white};
	box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 24px 2px;
`;

export default Root;
