import makeTable from "./makeTable";

const averageSpeed = makeTable({
	i18tPath: (id) => `tableColumns.sectors.averageSpeed.${id}`,
	allIds: ["dow", "time", "speed"],
	defaultIds: ["dow", "time", "speed"],
	initialResizable: {
		dow: true,
		time: true,
		speed: true,
	},
	initialWidths: {
		dow: 150,
		time: 150,
		speed: 150,
	},
});

export default averageSpeed;
