import React, {
	memo,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { Column } from "uikit";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import Sector from "../../../../../../../../../../../../../../services/Sector";
import useCurrentTaxiServices from "../../../../../../../../../../../../../../hooks/useCurrentTaxiService";
import DefaultPageHeader from "../../../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../../../components/DeleteModal";

import defaultValue from "./constants/defaultValue";
import tPath from "./constants/tPath";
import Content from "./components/Content";
import Filters from "./components/Filters";
import Modal from "./components/Modal";

const Rules: React.FC<Rules.Props> = memo(
	({ carClasses, sectors, rules, setRules, taxiServiceIds }) => {
		const { t } = useTranslation();

		const [actualDefaultValue, setActualDefaultValue] =
			useState(defaultValue);

		const settings = useCurrentTaxiServices()?.settings;
		const autoRateRuleSettings = settings?.defaults.autoRateRuleSettings;

		useEffect(() => {
			setActualDefaultValue({
				...defaultValue,
				...(autoRateRuleSettings ?? {}),
				additionalFields: {
					...autoRateRuleSettings?.additionalFields,
					validityDate: defaultValue.additionalFields.validityDate,
					validityTime: defaultValue.additionalFields.validityTime,
					sectors: {
						...autoRateRuleSettings?.additionalFields.sectors,
						ordersCounter: {
							...(autoRateRuleSettings?.additionalFields?.sectors
								?.ordersCounter || {}),
							ignoreOrders: {
								...defaultValue.additionalFields.sectors
									.ordersCounter.ignoreOrders,
								...(autoRateRuleSettings?.additionalFields
									?.sectors?.ordersCounter?.ignoreOrders ||
									{}),

								types: defaultValue.additionalFields.sectors
									.ordersCounter.ignoreOrders.types,
							},
						},
					},
				},
			});
		}, [autoRateRuleSettings]);

		const [showModal, setShowModal] = useState(false);
		const [showDeleteModal, setShowDeleteModal] = useState(false);
		const [editingItem, setEditingItem] =
			useState<Rules.Rule>(actualDefaultValue);
		const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
			null,
		);
		const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
		const [selected, setSelected] = useState<number[]>([]);

		const edit = useCallback(
			(index: number) => {
				const rule = rules[index];
				setEditingItem(cloneDeep(rule));
				setShowModal(true);
			},
			[rules],
		);

		useLayoutEffect(() => {
			if (editingItem.id) {
				const rule = rules?.find((item) => item.id === editingItem.id);

				if (rule && editingItem) {
					setEditingItem({
						...editingItem,
						carClassIds: rule.carClassIds,
					});
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [rules]);

		const editHeaderHandler = useCallback(() => {
			edit(selected[0]);
			setIndexEditingItem(selected[0]);
			setSelected([]);
		}, [edit, selected]);

		const editContentHandler = useCallback(
			(index: number) => {
				setIndexEditingItem(index);
				edit(index);
			},
			[edit],
		);

		const addHandler = useCallback(() => {
			setEditingItem(actualDefaultValue);
			setShowModal(true);
		}, [actualDefaultValue]);

		const cancelHandler = useCallback(() => {
			setShowModal(false);
			setEditingItem(actualDefaultValue);
			setIndexEditingItem(null);
		}, [actualDefaultValue]);

		const preDeleteHandler = useCallback(() => {
			setShowDeleteModal(true);
		}, []);

		const deleteHandler = useCallback(() => {
			const newRules = rules.filter((_, i) => !selected.includes(i));
			setRules(newRules);
			setSelected([]);
			setShowDeleteModal(false);
		}, [rules, selected, setRules]);

		const saveHandler = useCallback(
			(rule: Rules.Rule, index: number | null = null) => {
				const internalIndex = index ?? indexEditingItem;

				if (internalIndex != null) {
					const updatedRules = [...rules];
					updatedRules[internalIndex] = rule;
					setRules(updatedRules);
					setIndexEditingItem(null);
				} else {
					setRules([...rules, rule]);
				}

				setShowModal(false);
				setEditingItem(actualDefaultValue);
			},
			[actualDefaultValue, indexEditingItem, rules, setRules],
		);

		const selectedHandler = useCallback((index: number) => {
			setIndexEditingItem(index);
			setSelected([index]);
		}, []);

		useMemo(() => {
			const index = selected.at(0) || 0;
			setSelectedIndex(index);
		}, [selected]);

		return (
			<Column sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
				<DefaultPageHeader
					canEdit={selected.length === 1}
					canDelete={!!selected.length}
					onAdd={addHandler}
					onDelete={preDeleteHandler}
					onEdit={editHeaderHandler}
					filters={
						<Filters
							rules={rules}
							selectedId={selected.length === 1 && selected[0]}
							selectedIndex={selectedIndex}
							setRules={setRules}
							setSelectedIndex={selectedHandler}
						/>
					}
					justify="flex-start"
				/>

				<Content
					value={rules ?? []}
					selected={selected}
					setSelected={setSelected}
					onEdit={editContentHandler}
					saveHandler={saveHandler}
					carClasses={carClasses}
					sectors={sectors}
				/>
				{showModal && (
					<Modal
						onCancel={cancelHandler}
						onSave={saveHandler}
						value={editingItem}
						headerTitle={t(`${tPath}.modal.title`)}
						carClasses={carClasses}
						taxiServiceIds={taxiServiceIds}
					/>
				)}
				{showDeleteModal && (
					<DeleteModal
						label={
							selected.length === 1
								? t(`${tPath}.deleteModal.title`) || ""
								: t(`${tPath}.deleteModal.title2`) || ""
						}
						onCancel={() => {
							setShowDeleteModal(false);
							setSelected([]);
						}}
						onConfirm={deleteHandler}
					/>
				)}
			</Column>
		);
	},
);

declare namespace Rules {
	interface Props {
		carClasses: CarClass.Model[];
		sectors?: Sector.Model[];
		rules: Rule[];
		taxiServiceIds: number[];
		setRules: (rule: Rule[]) => void;
	}

	type Rule = Modal.Value;
}

export default Rules;
