import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	TariffPaymentType,
	tariffPaymentOptions,
} from "../../../../../../../../../../../../../../../../types/TariffPayment";
import tPath from "../../../../../../../../../../constants/tPath";

const Payment: React.FC<Payment.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const types = valueEditor.useGetter("types");
	const setTypes = valueEditor.useSetter("types");
	const handleTypesChange = useCallback(
		(newTypes) => {
			setTypes(newTypes);
		},
		[setTypes],
	);

	const tOptions = useMemo(
		() =>
			tariffPaymentOptions.map((o) => ({
				...o,
				label: t(
					`${tPath}.modal.tabs.generalSurcharges.payment.options.${o.key}`,
				),
			})),
		[t],
	);

	return (
		<Row align="center" gaps="10px" sizes="12% 1fr">
			<div>
				<CheckBox
					label={
						t(
							`${tPath}.modal.tabs.generalSurcharges.payment.title`,
						) || ""
					}
					value={active}
					onChange={setActive}
				/>
			</div>
			<MultiSelect
				disabled={!active}
				placeholder={
					t(`${tPath}.modal.tabs.generalSurcharges.payment.title`) ||
					""
				}
				options={tOptions}
				value={types}
				onChange={handleTypesChange}
			/>
		</Row>
	);
});

declare namespace Payment {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		types: TariffPaymentType[];
	}
}

export default Payment;
