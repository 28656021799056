import styled from "styled-components";
import { theme } from "uikit";

import { StyledRow } from "../../../../../../../../common";

const Root = styled(StyledRow)`
	padding: 12px;
	height: 44px;

	font-family: Lato;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;

	color: ${theme.colors.primary};
`;

export default Root;
