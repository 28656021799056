import React, { useEffect, useState } from "react";
import { Icon, theme, useRender } from "uikit";
import { noop } from "lodash";
import Button from "./components/Button";
import { StyledRow } from "../../../../../common";
import File from "../../../../../../services/File";

const Controls: React.FC<Controls.Props> = ({
	disabled,
	file,

	onDelete,
	onClose,
}) => {
	const render = useRender();

	const [url, setURL] = useState("");

	useEffect(() => {
		if (!file) {
			setURL("");

			return () => {};
		}

		file.on("uploaded", render);

		(async () => {
			const blob = await file.load();

			if (blob) setURL(URL.createObjectURL(blob));
		})();

		return () => {
			file.off("uploaded", render);
		};
	}, [file, render]);

	return (
		<StyledRow alignItems="center" gap="10px">
			<Button href={url} download={file?.name}>
				<Icon
					id="save"
					size={22}
					colors={[theme.colors.secondary]}
				></Icon>
			</Button>
			<Button disabled={disabled} onClick={disabled ? noop : onDelete}>
				<Icon
					id="trash"
					size={22}
					colors={[theme.colors.secondary]}
				></Icon>
			</Button>
			<Button onClick={onClose}>
				<Icon
					id="close"
					size={16.5}
					colors={[theme.colors.secondary]}
				></Icon>
			</Button>
		</StyledRow>
	);
};

declare namespace Controls {
	interface Props {
		disabled: boolean;
		file?: File.Model;

		onDelete: () => void;
		onClose: () => void;
	}
}

export default Controls;
