import { Action, combineReducers } from "@reduxjs/toolkit";

import {
	counterparty,
	counterpartyAction,
	StateCounterparty,
} from "./counterparty";
import {
	autodetectPhone,
	autodetectPhoneAction,
	StateAutodetectPhones,
} from "./autodetectPhone";

export type State = {
	counterparty: StateCounterparty;
	autodetectPhone: StateAutodetectPhones;
};

export type Actions = {
	counterparty: typeof counterpartyAction;
	autodetectPhone: typeof autodetectPhoneAction;
};

export const customersReducer = combineReducers<State, Action<Actions>>({
	counterparty,
	autodetectPhone,
});

export const customersAction: Actions = {
	counterparty: counterpartyAction,
	autodetectPhone: autodetectPhoneAction,
};

export default customersReducer;
