import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextBox } from "uikit";

import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import { IName } from "../../../../../../../../../../../../../../types/IName";
import tPath from "../../../../../../../../constants/tPath";

const Name: React.FC<Name.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const handleNameChange = useCallback(
		(newName: string) => {
			onChange({ [lang]: newName });
		},
		[lang, onChange],
	);

	return (
		<LabeledField
			label={t(`${tPath}.modal.tabs.generalSurcharges.name`) || ""}
		>
			<TextBox.TextBox
				placeholder={
					t(`${tPath}.modal.tabs.generalSurcharges.name`) || ""
				}
				value={value[lang]}
				onChange={handleNameChange}
			/>
		</LabeledField>
	);
});

declare namespace Name {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
	}

	type Value = IName;
}

export default Name;
