import React, { PropsWithChildren } from "react";

import Root from "./components/Root";

type Props = PropsWithChildren;

const AuthLayout: React.FC<Props> = ({ children }) => (
	<Root align="center" justify="center" maxedWidth maxedHeight>
		{children}
	</Root>
);

export default AuthLayout;
