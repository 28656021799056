import React, { useCallback } from "react";

import styled from "styled-components";
import { theme } from "uikit";

import { Orders } from "../../../../../../../../../redux/reducers/Orders";
import { gray } from "../../../../../../../../../constants/styles/colors";

const Root = styled.div<{ active?: boolean; disabled?: boolean }>`
	height: 26px;
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	color: ${({ active, disabled }) => {
		if (disabled) return gray[5];
		return active ? theme.colors.accent : theme.colors.secondary;
	}};
	font-family: Lato;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;

	white-space: nowrap;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	margin-right: 8px;

	&::before {
		content: "";
		display: ${({ active }) => (active ? "block" : "none")};
		height: 3px;
		position: absolute;
		bottom: -5px;
		left: auto;
		right: auto;
		width: 40px;
		border-radius: 3px 3px 0px 0px;
		background-color: ${theme.colors.accent};
	}
`;

const NavButton: React.FC<NavButton.Props> = ({
	active = false,
	disabled = false,
	text,
	type,
	onChangeNavigation,
}) => {
	const handleClick = useCallback(() => {
		if (!disabled) {
			onChangeNavigation(type);
		}
	}, [onChangeNavigation, type, disabled]);

	return (
		<Root active={active} disabled={disabled} onClick={handleClick}>
			{text}
		</Root>
	);
};

declare namespace NavButton {
	interface Props {
		active: Partial<boolean>;
		text: string;
		onChangeNavigation: (type: Orders.Chat.UsersModalTabType) => void;
		type: Orders.Chat.UsersModalTabType;
		disabled?: boolean;
	}
}

export default NavButton;
