import React, { Dispatch } from "react";
import { CheckBoxWithContent } from "uikit";
import { noop } from "lodash";
import Root from "./components/Root";

const Item: React.FC<Item.Props> = ({ value, label, disabled = false, onChange }) => (
	<Root
		disabled={disabled}
		onClick={disabled ? noop : () => onChange(!value)}
	>
		<CheckBoxWithContent
			value={value}
			disabled={disabled}
			gap="10px"
			onChange={onChange}
		>
			{label}
		</CheckBoxWithContent>
	</Root>
);

declare namespace Item {
	type Value = boolean;

	interface Props {
		value: Value;

		label: string;
		disabled?: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Item;
