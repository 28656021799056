import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	padding: 8px 16px;

	background: ${theme.colors.white};

	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
`;

export default Root;
