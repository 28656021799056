import { OrderPaymentStatus } from "../../../../../../../../redux/constants/OrdersPage/order";
import { Archive } from "../../../../../../../../redux/reducers/Archives/interface";

export interface PaymentMethod {
	name: Archive.ValueOrderPayment;
	id: string | number;
}

export interface PaymentColor {
	color: string;
	id: OrderPaymentStatus | null;
}

export const paymentColors: PaymentColor[] = [
	{ color: "#03A9F4", id: OrderPaymentStatus.PAYMENT_PENDING },
	{ color: "#f83528", id: OrderPaymentStatus.PAYMENT_FAILED },
	{ color: "#4caf50", id: OrderPaymentStatus.PAYMENT_SUCCESS },
	{ color: "#03A9F4", id: OrderPaymentStatus.CARD_VALIDATION },
	{ color: "#f83528", id: OrderPaymentStatus.CARD_VALIDATION_FAILED },
	{ color: "#4caf50", id: OrderPaymentStatus.CARD_VALIDATION_SUCCESS },
	{ color: "#F6F6F6", id: null },
];

export const paymentMethods: PaymentMethod[] = [
	{ name: "cash", id: Archive.ConstantOrderPayment.CASH },
	{ name: "card", id: Archive.ConstantOrderPayment.CARD },
	{ name: "company_bill", id: Archive.ConstantOrderPayment.COMPANY_BILL },
	{ name: "invoice", id: Archive.ConstantOrderPayment.INVOICE },
];

export const paymentMethodsIds = Object.values(Archive.ConstantOrderPayment);
