import React, { useMemo } from "react";
import moment from "moment";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CreateOrderDateCellContent: React.FC<
	CreateOrderDateCellContent.Props
> = ({ item }) => {
	const content = useMemo(
		() => moment(item.createdAt).format("DD-MM-YYYY"),
		[item.createdAt],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CreateOrderDateCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default CreateOrderDateCellContent;
