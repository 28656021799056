import styled from "styled-components";

const Draggable = styled.div<Draggable.Props>`
	top: auto !important;
	left: auto !important;

	margin-top: ${({ index }) => index * 40}px;
`;

declare namespace Draggable {
	interface Props {
		index: number;
	}
}

export default Draggable;
