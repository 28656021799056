import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBox,
	Column,
	DateAndTimePicker,
	Option,
	Row,
	Stepper,
	TextBox,
	TextSelect,
	TimeInput,
} from "uikit";
import moment from "moment";

import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import useDatePickerLocale from "../../../../../../../../../../../../../../../../hooks/useDatePickerLocale";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../hooks";
import ValueStepper from "../../../../../../../../../../../../../Tariffs/tabs/Auto/components/ValueStepper";

import ComissionsList from "./components/ComissionsList";
import Root from "./components/Root";

export const marginLeft = { marginLeft: 30 };

const Content: React.FC<Content.Props> = memo(() => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();
	const locale = useDatePickerLocale();

	const language = useTypedSelector((state) => state.session.language);
	const precision = useTypedSelector(
		(state) =>
			state.additionalTariff.options?.additionalFields?.general?.rounding
				?.precision,
	);

	const [startActionEnabled, setStartActionEnabled] = useState(false);
	const [periodEnabled, setPeriodEnabled] = useState(false);
	const [timeEnabled, setTimeEnabled] = useState(false);
	const [minSumEnabled, setMinSumEnabled] = useState(false);
	const [maxSumEnabled, setMaxSumEnabled] = useState(false);
	const [moreThanOrdersEnabled, setMoreThanOrdersEnabled] = useState(false);
	const [stepperValue, setStepperValue] = useState(1);
	const [selectedPeriod, setSelectedPeriod] = useState<Option<string>>({
		key: 0,
		label: "",
		value: "",
	});
	const [selectType, setSelectType] = useState<Option<string>>({
		key: 0,
		label: "",
		value: "",
	});
	const [period, setPeriod] = useState(moment().startOf("day").toDate());
	const [name, setName] = useState("");

	const periodType = useMemo(
		() => [
			{
				key: 1,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str200",
					) ?? "",
				value: "days",
			},
			{
				key: 2,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str201",
					) ?? "",
				value: "weeks",
			},
			{
				key: 3,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str202",
					) ?? "",
				value: "months",
			},
		],
		[t],
	);

	const types = useMemo(
		() => [
			{
				key: 1,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str203",
					) ?? "",
				value: "comissions",
			},
			{
				key: 2,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str204",
					) ?? "",
				value: "cashRegister",
			},
		],
		[t],
	);

	const startTime = useMemo(() => moment().startOf("day").toDate(), []);
	const endTime = useMemo(() => moment().endOf("day").toDate(), []);

	const onChangeStartAction = useCallback((value) => {
		setStartActionEnabled(value);
	}, []);

	const onChangePeriod = useCallback((value) => {
		setPeriodEnabled(value);
	}, []);

	const onChangeTime = useCallback((value) => {
		setTimeEnabled(value);
	}, []);

	const onChangeMinSum = useCallback((value) => {
		setMinSumEnabled(value);
	}, []);

	const onChangeMaxSum = useCallback((value) => {
		setMaxSumEnabled(value);
	}, []);

	const onChangeMoreThanOrders = useCallback((value) => {
		setMoreThanOrdersEnabled(value);
	}, []);

	const onSelectPeriod = useCallback((option: Option<string>) => {
		if (option.value !== "days") {
			setStepperValue(1);
		}
		setSelectedPeriod(option);
	}, []);

	const onSelectType = useCallback((option: Option<string>) => {
		setSelectType(option);
	}, []);

	const onChangeStepperValue = useCallback((value) => {
		setStepperValue(value);
	}, []);

	const getDayOfWeek = useCallback(
		(date) => {
			moment.locale(language);
			const dayOfWeek = moment(date).format("dddd");
			return dayOfWeek;
		},
		[language],
	);

	return (
		<Root hasPaddings>
			<Column gaps="27px*">
				<Row sizes="50%*" gaps="10px">
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str0",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={name}
							onChange={setName}
							placeholder={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str0",
								) ?? ""
							}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str2",
							) ?? ""
						}
					>
						<TextSelect
							value={selectType.value}
							onSelect={onSelectType}
							options={types}
							placeholder={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str2",
								) ?? ""
							}
						/>
					</LabeledField>
				</Row>
				<Row
					gaps="10px"
					sizes="50%*"
					align="center"
					justify="space-between"
				>
					<Row gaps="10px 5px" align="center">
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str4",
								) ?? ""
							}
							value={startActionEnabled}
							onChange={onChangeStartAction}
						/>
						<DateAndTimePicker
							value={period}
							onChange={(value) => setPeriod(value as any)}
							locale={locale}
						/>
						<span>{getDayOfWeek(period)}</span>
					</Row>
					<Row gaps="10px 5px*" align="center">
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str5",
								) ?? ""
							}
							value={timeEnabled}
							onChange={onChangeTime}
						/>
						<span>
							{t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str100",
							) ?? ""}
						</span>
						<TimeInput disabled={!timeEnabled} value={startTime} />
						<span>
							{t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str101",
							) ?? ""}
						</span>
						<TimeInput disabled={!timeEnabled} value={endTime} />
					</Row>
				</Row>
				<Row
					align="center"
					gaps="10px"
					sizes="50%*"
					justify="space-between"
				>
					<Row align="center" gaps="10px 5px">
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str6",
								) ?? ""
							}
							value={periodEnabled}
							onChange={onChangePeriod}
						/>
						<TextSelect
							options={periodType}
							value={selectedPeriod.label}
							onSelect={onSelectPeriod}
							placeholder={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str6",
								) ?? ""
							}
							disabled={!periodEnabled}
						/>
						<Stepper
							min={1}
							value={stepperValue}
							onChange={onChangeStepperValue}
							disabled={
								selectedPeriod.value !== "days" ||
								!periodEnabled
							}
						/>
					</Row>
					<Row gaps="10px 5px" align="center">
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str205",
								) ?? ""
							}
							value={moreThanOrdersEnabled}
							onChange={onChangeMoreThanOrders}
						/>
						<Stepper
							disabled={!moreThanOrdersEnabled}
							min={0}
							value={0}
						/>
						<span>
							{t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str207",
							) ?? ""}
						</span>
					</Row>
				</Row>
				<ComissionsList precision={precision} />
				<Column gaps="10px">
					<Row align="center" justify="space-between">
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str8",
								) ?? ""
							}
							onChange={onChangeMinSum}
						/>
						<Row gaps="5px" align="center">
							<ValueStepper
								min={0}
								disabled={!minSumEnabled}
								decimalCount={precision}
							/>
							<span>{currencyGlobalSettings}</span>
						</Row>
					</Row>
					<Row align="center" justify="space-between">
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.preferentialComissions.modal.content.str9",
								) ?? ""
							}
							onChange={onChangeMaxSum}
						/>
						<Row gaps="5px" align="center">
							<ValueStepper
								min={0}
								disabled={!maxSumEnabled}
								decimalCount={precision}
							/>
							<span>{currencyGlobalSettings}</span>
						</Row>
					</Row>
				</Column>
			</Column>
		</Root>
	);
});

declare namespace Content {
	interface Props {
		// value: Value;
		// onChange: (x: Value) => void;
	}

	interface Value {
		name: string;
		cost: number;
	}
}

export default Content;
