import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 1100px;
	height: 86vh;

	min-width: 400px;
	min-height: 300px;

	border-radius: 5px;

	overflow: hidden;
`;

export default Root;
