import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { Option, Icon } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import {
	StyledRow,
	StyledSpan,
	Text,
	StyledWrapIcon,
	Divider,
	Select,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";
import {
	TransportationType,
	transportationTypes,
} from "../../types/transportationTypes";
import { useOrderModalContext } from "../../../../../context";

type Fn = (type: TransportationType) => Option<TransportationType>;

interface RenderTransportationType {
	field: ControllerRenderProps<Schema, "transportationType">;
}
interface RenderCompanyID {
	field: ControllerRenderProps<Schema, "companyID">;
}

interface RenderTaxiServiceId {
	field: ControllerRenderProps<Schema, "taxiServiceId">;
}
const Left: React.FC<OrderFormProps> = ({ tab, form, disabled }) => {
	const { t } = useTranslation();
	const { nextElementById, language, setIsChangTaxiService } =
		useOrderModalContext();

	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const taxiServices = useTypedSelector((state) => state.taxiServices.models);
	const companiesRedux = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);

	const { models: serviceModels } = useTypedSelector(
		(state) => state.preferencesReducer.services.data,
	);

	const [city, setCity] = useState<string>("");
	const companies = useMemo(() => companiesRedux, [companiesRedux]);

	const currentCompanyId = useMemo(
		() => tab?.form?.companyID,
		[tab?.form?.companyID],
	);

	const taxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);

	const orderServices = useMemo(
		() => tab?.form?.services || [],
		[tab?.form?.services],
	);
	const taxiClass = useMemo(() => tab.form.taxiClass, [tab.form.taxiClass]);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
		nextElementById("customer-phone-id");
	}, [form, nextElementById]);

	const companyToOption = useCallback(
		(company) => ({
			key: company.id,
			value: company.id,
			label: company.name?.[language],
		}),
		[language],
	);

	const companiesOptions = useMemo(
		() => companies.items.map(companyToOption),
		[companyToOption, companies],
	);

	const taxiServiceToOption = useCallback(
		(service) => ({
			key: service.id,
			value: service.id,
			label: service.settlement?.[language],
		}),
		[language],
	);

	const taxiServiceOptions = useMemo(() => {
		const selectedCompanyId = currentCompanyId;
		const selectedCompany = companies.items?.find(
			(item) => item.id === selectedCompanyId,
		);

		return selectedCompany.taxiServices.map(taxiServiceToOption);
	}, [currentCompanyId, companies, taxiServiceToOption]);

	const wrap = useCallback<Fn>(
		(type) => ({
			key: type,
			value: type,
			label: t([
				`orderPage.order_form.transportation_types.${type}`,
				type,
			]),
		}),
		[t],
	);

	const options = useMemo(
		() => transportationTypes.map((type) => wrap(type)),
		[wrap],
	);

	const onRenderCreatedAt = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "createdAt"> }) => {
			const text = `${t([
				`orderPage.order_form.order_time.title`,
				"Added",
			])}: `;

			return (
				<Text
					textStyle={{
						w: "auto",
					}}
					text={text}
					value={moment(field.value).format("HH:mm:ss")}
				/>
			);
		},
		[t],
	);

	const onRenderStatus = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "status"> }) => {
			const text = `${t([
				`order_status.${field.value}`,
				"Order status",
			])}`;

			return (
				<StyledSpan
					m="0 10px 0 10px"
					font={{ size: "10px", line: "12px", fw: "600" }}
					colors="#8000FF"
					bgC="#F0E0FF"
					br="4px"
					p="2px 4px"
					h="16px"
				>
					{text}
				</StyledSpan>
			);
		},
		[t],
	);

	const onRenderTransportationType = useCallback(
		({ field }: RenderTransportationType) => {
			const value = wrap(field.value || "passenger").key;
			const selected = options
				.map((item, i) => {
					if (value === item.value) return i + 1;
					return null;
				})
				.find((item) => item !== null);

			return (
				<Select
					selected={selected || 0}
					disabled={disabled}
					options={options}
					value={value}
					onSelect={(option) => {
						field.onChange({
							target: { value: option?.value },
						} as unknown as React.ChangeEvent<Element>);
						handleSave();
					}}
				/>
			);
		},
		[disabled, options, wrap, handleSave],
	);

	const getDefaultCity = useCallback(
		(id?: number) => {
			const foundedOption = [...taxiServices]
				.filter((item) => item.id === id)
				.find((item) => item.id === id);

			const defaultCity = {
				id: foundedOption?.id,
				value: foundedOption?.settlement?.[language] || "",
				settlement: foundedOption?.settlement?.[language] || "",
				coordinates: foundedOption?.coordinates as any,
			};

			return defaultCity;
		},

		[taxiServices, language],
	);

	const onDefaultCity = useCallback(
		(id: number) => {
			const defaultCity = getDefaultCity(id);
			setCity(defaultCity.value);
			tab.setState({ ...tab.state, defaultCity });
			handleSave();
		},
		[getDefaultCity, tab, handleSave],
	);

	const cityName = useMemo(() => {
		if (!city) return "";
		return tab?.state?.defaultCity?.value || "";
	}, [city, tab?.state?.defaultCity?.value]);

	useEffect(() => {
		if (taxiServiceId) onDefaultCity(taxiServiceId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceId, language]);

	const setDefaultRateId = useCallback(() => {
		const rate =
			rates.find((item) => item.default && item.active) ||
			rates.find((item) => item.root === true);

		form.setValue("rateId", rate?.id || 1);
	}, [rates, form]);

	const onRenderCompanyIDe = useCallback(
		({ field }: RenderCompanyID) => {
			const selected = companiesOptions
				.map((item, i) => {
					if (field?.value === item.value) return i + 1;
					return null;
				})
				.find((item) => item !== null);

			return (
				<Select
					selected={selected || 0}
					disabled={disabled}
					options={companiesOptions}
					value={field.value}
					onSelect={(option) => {
						field.onChange({
							target: { value: option?.value },
						} as unknown as React.ChangeEvent<Element>);
						if (option) setDefaultRateId();
					}}
				/>
			);
		},
		[companiesOptions, disabled, setDefaultRateId],
	);

	const changedRateId = useCallback(
		(companyId: number) => {
			const carClassIds = taxiClass?.map((item) => item.value) || [];
			const foundRates = rates.filter((rate) => {
				if (!rate?.active) return false;

				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const company = rate.taxiServiceIds?.includes(companyId);

				if (company && cars) return true;
				return false;
			});

			let rateId: number | undefined = foundRates?.at(0)?.id;

			if (!rateId) {
				rateId = rates?.find((item) => item.root === true)?.id;
			}

			form.setValue("rateId", rateId || 1);
		},
		[rates, taxiClass, form],
	);

	const changedServices = useCallback(
		(taxiServiceId: number) => {
			const serviceIdsForSelectedTaxiService = serviceModels
				?.filter(
					(item) =>
						item.active &&
						item.taxiServices
							?.map((item) => item?.id)
							?.includes(taxiServiceId) === true,
				)
				.map((item) => item.id);

			const modifyServices = orderServices?.filter((item) =>
				serviceIdsForSelectedTaxiService.includes(item.value),
			);

			form.setValue("services", modifyServices);
		},
		[serviceModels, form, orderServices],
	);

	const placeholderTaxiService = t(["taxiService", "Branch"]);
	const onRenderTaxiServiceId = useCallback(
		({ field }: RenderTaxiServiceId) => {
			const exist = taxiServiceOptions.find((taxi) => {
				if (field.value === taxi.value) return taxi;
				return false;
			});

			if (!exist) setCity("");
			else {
				const city = tab?.form?.city;
				if (!city) {
					if (form.getValues().city !== exist?.label)
						form.setValue("city", exist?.label);
					setCity(exist?.label || "");
				}
			}

			const selected = taxiServiceOptions
				.map((item, i) => {
					if (field?.value === item?.value) return i + 1;
					return null;
				})
				.find((item) => item !== null);

			return (
				<Select
					selected={selected}
					disabled={disabled}
					options={taxiServiceOptions}
					value={field.value}
					placeholder={placeholderTaxiService}
					onSelect={(option) => {
						if (typeof option.value === "number") {
							onDefaultCity(option.value);
							changedRateId(option.value);
							changedServices(option.value);
							setIsChangTaxiService(true);
						}

						field.onChange({
							target: { value: option?.value },
						} as unknown as React.ChangeEvent<Element>);
					}}
				/>
			);
		},

		[
			taxiServiceOptions,
			disabled,
			placeholderTaxiService,
			tab?.form?.city,
			form,
			onDefaultCity,
			changedRateId,
			changedServices,
			setIsChangTaxiService,
		],
	);

	const source = useMemo(() => {
		const value = tab?.form?.source;
		const text = `${t([`order_source.${value}`, "Source"])}`;
		return text;
	}, [tab?.form?.source, t]);

	return (
		<StyledRow
			alignItems="center"
			h="clamp(26px, 3.6vh, 32px)"
			over="visible"
			flex={{ wrap: "nowrap", flex: "0 1 100%" }}
		>
			<Controller
				name="createdAt"
				control={form.control}
				render={onRenderCreatedAt}
			/>

			<Controller
				name="status"
				control={form.control}
				render={onRenderStatus}
			/>
			<Divider h="18px" />
			<Controller
				name="transportationType"
				control={form.control}
				render={onRenderTransportationType}
			/>
			<Divider h="18px" />
			<Controller
				name="companyID"
				control={form.control}
				render={onRenderCompanyIDe}
			/>
			<Divider h="18px" />
			<Controller
				name="taxiServiceId"
				control={form.control}
				render={onRenderTaxiServiceId}
			/>
			<Divider h="18px" />

			<StyledRow alignItems="center" gap="0 6px" p="0 0 0 10px">
				<StyledWrapIcon w="15px" h="15px" colors="#647079">
					<Icon id="order-modal-city" size={15} />
				</StyledWrapIcon>
				<StyledSpan colors="#647079">{cityName}</StyledSpan>
			</StyledRow>

			<StyledRow m="0 0 0 10px" alignItems="center" gap="0 6px">
				<StyledWrapIcon
					w="16px"
					h="16px"
					offset={{ x: "0", y: "2px" }}
					colors="#647079"
				>
					<Icon id="order-modal-source" size={16} />
				</StyledWrapIcon>
				<StyledSpan colors="#647079">{source}</StyledSpan>
			</StyledRow>
		</StyledRow>
	);
};

export default Left;
