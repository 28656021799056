import React, {
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column, CheckBox, MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import tPath from "../../../../../../../../../../constants/tPath";

import Controller from "./Controller";
import { ExecutorStatus, executorOptions } from "./constants";

const IgnoreExecutorsBase = react.withController<
	IgnoreExecutors.PropsBase,
	Controller
>(({ disabled, value, onChange, controller }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const statuses = valueEditor.useGetter("statuses");
	const setStatuses = valueEditor.useSetter("statuses");
	const [statusesError, setStatusesError] = useState(false);
	const handleStatusesChange = useCallback(
		(newStatuses) => {
			setStatusesError(false);
			setStatuses(newStatuses);
		},
		[setStatuses],
	);

	const tOptions = useMemo(
		() =>
			executorOptions.map((o) => ({
				...o,
				label: t(
					`${tPath}.modal.sectors.executorsCounter.ignoreExecutors.options.${o.key}`,
				),
			})),
		[t],
	);

	controller.setContext({
		value,
		optionsLength: executorOptions.length,
		setStatusesError,
	});

	return (
		<Column gaps="8px" sizes="auto 1fr">
			<CheckBox
				disabled={disabled}
				label={
					t(
						`${tPath}.modal.sectors.executorsCounter.ignoreExecutors.title`,
					) || ""
				}
				value={active}
				onChange={setActive}
			/>
			<MultiSelect
				error={
					statusesError &&
					(t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.executorsCounter.ignoreExecutors.str200",
					) ??
						"")
				}
				disabled={disabled || !active}
				placeholder={
					t(
						`${tPath}.modal.sectors.executorsCounter.ignoreExecutors.placeholder`,
					) || ""
				}
				options={tOptions}
				value={statuses}
				onChange={handleStatusesChange}
			/>
		</Column>
	);
}, Controller);

const IgnoreExecutors = memo(IgnoreExecutorsBase);

declare namespace IgnoreExecutors {
	type Ref = Controller | null;

	interface PropsBase {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		active: boolean;
		statuses: ExecutorStatus[];
	}
}

export default IgnoreExecutors;
