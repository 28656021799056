import { Dispatch, RefObject } from "react";
import { react } from "uikit";

import TaxiService from "../../../../../../../../services/TaxiService";

import TaxiServiceForm from ".";

interface Context {
	rootRef: RefObject<HTMLDivElement | null>;

	value?: TaxiServiceForm.Value;
	companyId?: number;
	taxiServices?: TaxiService.Model[];

	setCompanyIdFieldError: Dispatch<boolean>;
	setTaxiServiceIdFieldError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		if (
			!this.context?.value ||
			!this.context?.taxiServices?.find(
				(taxiService) => taxiService.id === this.context?.value,
			)
		) {
			if (!this.context?.companyId)
				this.context?.setCompanyIdFieldError(true);

			this.context?.setTaxiServiceIdFieldError(true);

			this.context?.rootRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});

			return false;
		}

		return true;
	}
}
