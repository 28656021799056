/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import {
	DataKind,
	EntryList,
	BooleanEntry,
} from "../../../../../types/settingEntries";

export type ExecutorAppInterfaceMapSelectTypes = never;
export type ExecutorAppInterfaceMapMultiSelectTypes = never;

export interface BaseExecutorAppInterfaceMapEntries<Data extends DataKind> {
	displayMap: BooleanEntry<Data>;
	displayExecutors: BooleanEntry<Data>;
	displayOrders: BooleanEntry<Data>;
}

export type ExecutorAppInterfaceMapEntries<Data extends DataKind> =
	BaseExecutorAppInterfaceMapEntries<Data> &
		EntryList<
			Data,
			ExecutorAppInterfaceMapSelectTypes,
			ExecutorAppInterfaceMapMultiSelectTypes
		>;
export type ExecutorAppInterfaceMapMeta =
	ExecutorAppInterfaceMapEntries<"meta">;
export type ExecutorAppInterfaceMapState =
	ExecutorAppInterfaceMapEntries<"value">;
export type ExecutorAppInterfaceMapValue =
	ExecutorAppInterfaceMapEntries<any>[keyof ExecutorAppInterfaceMapEntries<any>];

const toggle: BooleanEntry<"meta"> = {
	type: "boolean",
	style: "toggle",
};

export const executorAppInterfaceMapMeta: ExecutorAppInterfaceMapMeta = {
	displayMap: toggle,
	displayExecutors: toggle,
	displayOrders: toggle,
};

const initialState: ExecutorAppInterfaceMapState = {
	displayMap: true,
	displayExecutors: true,
	displayOrders: true,
};

type Reducer<P> = CaseReducer<ExecutorAppInterfaceMapState, PayloadAction<P>>;

const setAll: Reducer<ExecutorAppInterfaceMapState> = (_, { payload }) =>
	payload;
const resetDefaults: Reducer<void> = () => initialState;

const executorAppInterfaceMap = createSlice({
	name: "executorAppInterfaceMap",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default executorAppInterfaceMap;
