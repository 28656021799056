import { useCallback, useEffect, useMemo } from "react";

import { createRPCQuery, deepParse } from "../utils";
import { useTypedDispatch } from "../redux/store";
import {
	colors,
	fonts,
	order,
	keybinds,
	paymentSystems,
	mapSettings,
	executorAppGeneral,
	executorAppInterfaceOrders,
	executorAppInterfaceMap,
	executorAppInterfaceGeneral,
} from "../redux/reducers/settings";
import tableColumns from "../redux/reducers/TableColumns";
import { ordersAction as orders, Orders } from "../redux/reducers/Orders";
import orderPage from "../redux/reducers/OrdersPage";
import { useChatActions } from "../components/OrderPageWidgets/Chat/hooks";
import useExecutorsActions from "../components/OrderPageWidgets/Executors/hooks/useExecutorsActions";
import useParkingActions from "../components/OrderPageWidgets/Parking/hooks/useParkingActions";
import useMapActions from "../components/OrderPageWidgets/Map2/hooks/useMapActions";
import useMapActionsFromOrderModelAdd from "../pages/MainPage/pages/Orders/components/OrderModal/hooks/useMapActions";

import { GetPRPC } from "./usePRPC";

const usePersistRoot = (prpcow: Prpcow) => {
	const dispatch = useTypedDispatch();

	const { saveFilterExecutorsWrite, saveFilterExecutorsRead } =
		useChatActions();
	const { onSaveColumnsIdsTable: onSaveColumnsIdsTableExecutors } =
		useExecutorsActions();
	const { onSaveColumnsIdsTable: onSaveColumnsIdsTableParking } =
		useParkingActions();
	const {
		setAll: setAllForMapFiltersAndSettings,
		setALLBtnToggles: setALLForMapBtnToggles,
	} = useMapActions();
	const {
		setALLBtnToggles: setALLForMapFromAddModelBtnToggles,
		setALLBtnTogglesForFooter: setALLForMapFromAddModelBtnTogglesForFooter,
	} = useMapActionsFromOrderModelAdd();

	const prpcowGetStorage = useMemo(
		() => !!prpcow?.theirsModel?.dispatcher?.getFrontendStorage,
		[prpcow?.theirsModel?.dispatcher?.getFrontendStorage],
	);

	const getBaseStore = useCallback(async () => {
		const dispatchSliceUpdate = (
			slice: any,
			value: any,
			method?: "set" | "setAll",
		) => {
			const _method = method ?? "setAll";

			if (slice?.actions?.[_method] && value) {
				dispatch(slice.actions[_method](value));
			}
		}; // TODO Change it individually for each one, and add typing (it is different everywhere). Leave the validation, it’s useful

		const res = await createRPCQuery(() =>
			prpcow?.theirsModel.dispatcher.getFrontendStorage(),
		);
		localStorage.setItem("getSetting", "true");

		const isResStorage: B = res && res?.storage;

		if (isResStorage) {
			const value = deepParse(res?.storage || "");

			//	console.log("deepParse", value); // TODO during testing
			const isSettings: B = value && value?.settings;
			const isTableColumns: B = value && value?.tableColumns;
			const isOrderPageLocalStorage: B =
				value && value?.orderPageLocalStorage;

			if (isSettings) {
				const {
					executorAppGeneral: _executorAppGeneral,
					map: _map,
					executorAppInterfaceGeneral: _executorAppInterfaceGeneral,
					executorAppInterfaceOrders: _executorAppInterfaceOrders,
					executorAppInterfaceMap: _executorAppInterfaceMap,
					keybinds: _keybinds,
					colors: _colors,
					fonts: _fonts,
					order: _order,
					paymentSystems: _paymentSystems,
				} = value.settings;

				dispatchSliceUpdate(executorAppGeneral, _executorAppGeneral);
				dispatchSliceUpdate(mapSettings, _map);
				dispatchSliceUpdate(
					executorAppInterfaceGeneral,
					_executorAppInterfaceGeneral,
				);
				dispatchSliceUpdate(
					executorAppInterfaceOrders,
					_executorAppInterfaceOrders,
				);
				dispatchSliceUpdate(
					executorAppInterfaceMap,
					_executorAppInterfaceMap,
				);
				dispatchSliceUpdate(keybinds, _keybinds);
				dispatchSliceUpdate(colors, _colors);
				dispatchSliceUpdate(fonts, _fonts);
				dispatchSliceUpdate(order, _order, "set");
				dispatchSliceUpdate(paymentSystems, _paymentSystems);
			}

			if (isTableColumns) {
				dispatch(tableColumns.actions.setAll(value.tableColumns));
			}

			if (isOrderPageLocalStorage) {
				// * saves for pages Orders

				const { orderPageLocalStorage } = value;

				const [
					isOrderChatsFilters,
					isOrderExecutorsFilters,
					isOrderExecutorsTableSettings,
					isOrderParkingFilters,
					isOrderParkingTableSettings,
					isOrderMapFilters,
					isOrderMapToggles,
					isOrderMapFromAddModalToggles,
					isOrderTabsFilters,
				]: [B, B, B, B, B, B, B, B, B] = [
					!!orderPageLocalStorage?.orderChatsFilters,
					!!orderPageLocalStorage?.orderExecutorsFilters,
					!!orderPageLocalStorage?.orderExecutorsTableSettings,
					!!orderPageLocalStorage?.orderParkingFilters,
					!!orderPageLocalStorage?.orderParkingTableSettings,
					!!orderPageLocalStorage?.orderMapFilters,
					!!orderPageLocalStorage?.orderMapToggles,
					!!orderPageLocalStorage?.orderMapFromAddModalToggles,
					!!orderPageLocalStorage?.orderTabsFilters,
				];

				if (isOrderChatsFilters) {
					// * saves for widget chat
					const { filterExecutorsRead, filterExecutorsWrite } =
						value.orderPageLocalStorage.orderChatsFilters;

					const criteriaFiltersRead: ChatFilter =
						filterExecutorsRead?.criteria;
					const criteriaFiltersWrite: ChatFilter =
						filterExecutorsWrite?.criteria;

					if (criteriaFiltersRead) {
						saveFilterExecutorsRead(criteriaFiltersRead);
					}

					if (criteriaFiltersWrite) {
						saveFilterExecutorsWrite(criteriaFiltersWrite);
					}
				}

				if (isOrderExecutorsFilters) {
					// * saves for widget executors
					const _criteria: ExecuterFilter =
						value.orderPageLocalStorage.orderExecutorsFilters
							?.criteria;

					if (_criteria) {
						dispatch(
							orders.executors.filter.setExecutorsFilter(
								_criteria,
							),
						);
					}
				}

				if (isOrderExecutorsTableSettings) {
					// * saves for widget executors
					const columnIds: string[] | undefined =
						value.orderPageLocalStorage.orderExecutorsTableSettings
							?.columnIds;

					if (columnIds) {
						onSaveColumnsIdsTableExecutors(columnIds);
					}
				}

				if (isOrderParkingFilters) {
					// * saves for widget parking
					const _criteria: ParkingFilter =
						value.orderPageLocalStorage.orderParkingFilters
							?.criteria;

					if (_criteria) {
						dispatch(
							orders.parking.filter.setParkingsFilter(_criteria),
						);
					}
				}

				if (isOrderParkingTableSettings) {
					// * saves for widget parking
					const columnIds: ColumnIds =
						value.orderPageLocalStorage.orderParkingTableSettings
							?.columnIds;

					if (columnIds) {
						onSaveColumnsIdsTableParking(columnIds);
					}
				}

				if (isOrderMapFilters) {
					// * saves for widget map
					const valueMapFilterAndSettings: MapFilter =
						value.orderPageLocalStorage.orderMapFilters;

					if (valueMapFilterAndSettings) {
						setAllForMapFiltersAndSettings(
							valueMapFilterAndSettings,
						);
					}
				}

				if (isOrderMapToggles) {
					// * saves for widget map
					const valueMapToggles: MapToggles =
						value.orderPageLocalStorage.orderMapToggles?.btnOnMap;

					if (valueMapToggles) {
						setALLForMapBtnToggles(valueMapToggles);
					}
				}

				if (isOrderMapFromAddModalToggles) {
					// * saves for map from add order modal

					const valueMapToggles: MapFromAddModalToggles =
						value.orderPageLocalStorage.orderMapFromAddModalToggles
							?.btnOnMap;
					const valuefooterToggles: MapFromAddModalTogglesForFooter =
						value.orderPageLocalStorage.orderMapFromAddModalToggles
							?.btnOnFooter;

					if (valueMapToggles) {
						setALLForMapFromAddModelBtnToggles(valueMapToggles);
					}
					if (valuefooterToggles) {
						setALLForMapFromAddModelBtnTogglesForFooter(
							valuefooterToggles,
						);
					}
				}

				if (isOrderTabsFilters) {
					// * saves for tabs hear row from order page
					const ordersType: TabsHeadRowOrderPage =
						value.orderPageLocalStorage.orderTabsFilters
							?.ordersType;

					if (ordersType) {
						dispatch(orderPage.actions.setOrdersType(ordersType));
						dispatch(orders.tabs.filter.setOrdersType(ordersType));
					}
				}
			}

			if (res.storage) localStorage.setItem("persist:root", res.storage);
		}
	}, [
		dispatch,
		prpcow?.theirsModel.dispatcher,
		saveFilterExecutorsRead,
		saveFilterExecutorsWrite,
		onSaveColumnsIdsTableExecutors,
		onSaveColumnsIdsTableParking,
		setAllForMapFiltersAndSettings,
		setALLForMapBtnToggles,
		setALLForMapFromAddModelBtnToggles,
		setALLForMapFromAddModelBtnTogglesForFooter,
	]);

	useEffect(() => {
		if (prpcowGetStorage) getBaseStore();
	}, [prpcowGetStorage, getBaseStore]);
};

type Prpcow = GetPRPC | null;
type B = boolean;
type ColumnIds = string[] | undefined;
type ParkingFilter = Orders.Parking.ParkingFilter | undefined;
type MapFilter = Orders.Map.MapFilterAndSettings | undefined;
type MapToggles = Orders.Map.BtnOnMapToggles | undefined;
type MapFromAddModalToggles =
	| Orders.MapFromAddModal.BtnOnMapToggles
	| undefined;
type MapFromAddModalTogglesForFooter =
	| Orders.MapFromAddModal.BtnOnFooterToggles
	| undefined;
type ExecuterFilter = Orders.Executors.ExecuterFilter | undefined;
type ChatFilter = Orders.Chat.ModalFilter | undefined;
type TabsHeadRowOrderPage = Orders.Tabs.OrderType | undefined;

export default usePersistRoot;
