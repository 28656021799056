import { Dispatch, RefObject } from "react";
import { react } from "uikit";
import Form from ".";
import TaxiServiceForm from "../../../../../../../../../../components/TaxiServiceForm";

interface Context {
	taxiServiceFormRef: RefObject<TaxiServiceForm.Controller>;

	value?: Form.Value;

	setFileBrowserError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isTaxiServiceFormValid =
			this.context?.taxiServiceFormRef.current?.validate();

		let isFileBrowserValid = true;

		if (!this.context?.value?.file) {
			this.context?.setFileBrowserError(true);

			isFileBrowserValid = false;
		}

		return isTaxiServiceFormValid && isFileBrowserValid;
	}
}
