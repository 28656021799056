import { Option } from "uikit";

import CustomerRate from "../../../../../../../../../../../../services/CustomerRate";

export const typeOptions: Option<CustomerRate.Model.Type>[] = [
	{
		key: CustomerRate.Model.Type.Main,
		value: CustomerRate.Model.Type.Main,
		label: "Основной",
	},
	{
		key: CustomerRate.Model.Type.Additional,
		value: CustomerRate.Model.Type.Additional,
		label: "Дополнительный",
	},
];

export const modeOptions: Option<CustomerRate.Model.Mode>[] = [
	{
		key: CustomerRate.Model.Mode.Fixed,
		value: CustomerRate.Model.Mode.Fixed,
		label: "Фиксированный",
	},
	{
		key: CustomerRate.Model.Mode.Flexible,
		value: CustomerRate.Model.Mode.Flexible,
		label: "Гибкий",
	},
	{
		key: CustomerRate.Model.Mode.Cumulative,
		value: CustomerRate.Model.Mode.Cumulative,
		label: "Накопительный",
	},
	{
		key: CustomerRate.Model.Mode.Basic,
		value: CustomerRate.Model.Mode.Basic,
		label: "Базовый",
	},
	{
		key: CustomerRate.Model.Mode.BonusTrip,
		value: CustomerRate.Model.Mode.BonusTrip,
		label: "Бонусная поездка",
	},
];

export default { modeOptions };
