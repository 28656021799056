import styled from "styled-components";

import Button from "../../../Button";

const LeftButton = styled(Button)`
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;

export default LeftButton;
