/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { react, Tabs, useRefWithSetter } from "uikit";
import { extend } from "lodash";

import Modal from "../../../../../../../../components/ModalController/Modal";
import Map from "../../../../../../../../redux/services/Map";

import Root from "./components/Root";
import {
	_creationTypes,
	getEditObjectGroup,
	getEditObject,
	getCreationTypes,
} from "./constants";
import InternalController from "./Controller";
import ObjectTabContent from "./components/ObjectTabContent";
import ObjectGroupTabContent from "./components/ObjectGroupTabContent";

function isObjectTypeProps(
	props: EditModal.Props,
): props is EditModal.LocalObjectProps;
function isObjectTypeProps(
	props: EditModal.InternalProps,
): props is EditModal.InternalLocalObjectProps;
function isObjectTypeProps(
	props: EditModal.Props | EditModal.InternalProps,
): props is
	| EditModal.InternalLocalObjectProps
	| EditModal.InternalLocalObjectProps {
	return props.type === "object";
}

function isObjectGroupTypeProps(
	props: EditModal.Props,
): props is EditModal.LocalObjectGroupProps;
function isObjectGroupTypeProps(
	props: EditModal.InternalProps,
): props is EditModal.InternalLocalObjectGroupProps;
function isObjectGroupTypeProps(
	props: EditModal.Props | EditModal.InternalProps,
): props is
	| EditModal.LocalObjectGroupProps
	| EditModal.InternalLocalObjectGroupProps {
	return props.type === "object-group";
}

const EditModal = extend(
	react.withController<EditModal.Props, InternalController>(
		({ controller, ...props }) => {
			const { type, onChangeType } = props;
			const { t } = useTranslation();
			const [creationTypes, editObject, editObjectGroup] = [
				useMemo(() => getCreationTypes(t), [t]),
				useMemo(() => getEditObject(t), [t]),
				useMemo(() => getEditObjectGroup(t), [t]),
			];

			const [objectTabContentRef, objectTabContentRefSetter] =
				useRefWithSetter<ObjectTabContent.Controller | null>(null);
			const [objectGroupTabContentRef, objectGroupTabContentRefSetter] =
				useRefWithSetter<ObjectGroupTabContent.Controller | null>(null);

			const isNew = useMemo(
				() =>
					(isObjectTypeProps(props) &&
						Map.LocalObject.isNew(
							props.value as Map.LocalObject.ForSave,
						)) ||
					(isObjectGroupTypeProps(props) &&
						Map.LocalObjectGroup.isNew(
							props.value as Map.LocalObjectGroup.ForSave,
						)),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[props.value],
			);

			controller.setContext({
				objectTabContentRef,
				objectGroupTabContentRef,
				type,
			});

			const editOptions = useMemo(
				() => (isObjectTypeProps(props) ? editObject : editObjectGroup),
				[editObject, editObjectGroup, props],
			);

			const tabOptions = useMemo(
				() => (isNew ? creationTypes : editOptions),
				[creationTypes, editOptions, isNew],
			);

			const onSelectTab = useCallback(
				(option: Tabs.Option<"object" | "object-group">) =>
					onChangeType(option.value),
				[onChangeType],
			);

			const tabContent = useMemo(
				() =>
					isObjectTypeProps(props) ? (
						<ObjectTabContent
							ref={objectTabContentRefSetter}
							{...props}
						/>
					) : (
						<ObjectGroupTabContent
							ref={objectGroupTabContentRefSetter}
							{...props}
						/>
					),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[
					type,
					props.value,
					props.language,
					props.onChange,
					props.onSave,
					props.onCancel,
				],
			);

			const modalContent = useMemo(
				() => (
					<Root sizes="auto! 1fr">
						<Tabs
							variant="panels"
							options={tabOptions}
							value={type}
							onChangeSelectedOption={onSelectTab}
						/>
						{tabContent}
					</Root>
				),
				[onSelectTab, tabContent, tabOptions, type],
			);

			return <Modal dimmer>{modalContent}</Modal>;
		},
		InternalController,
	),
	{
		isObjectTypeProps,
		isObjectGroupTypeProps,
	},
);

declare namespace EditModal {
	type LocalObjectValue =
		| Partial<Map.LocalObject.New>
		| Partial<Map.LocalObject.Modified>;
	type LocalObjectGroupValue =
		| Partial<Map.LocalObjectGroup.New>
		| Partial<Map.LocalObjectGroup.Modified>;
	type Value<ChosenType extends Type> = ChosenType extends "object"
		? LocalObjectValue
		: LocalObjectGroupValue;

	type Type = typeof _creationTypes extends Tabs.Option<infer Type>[]
		? Type
		: never;

	interface PropsBase<ChosenType extends Type> {
		value: Value<ChosenType>;
		language: Map.Language;
		type: Type;

		onChange: Dispatch<Value<ChosenType>>;
		onChangeType: Dispatch<Type>;

		onCancel: () => void;
		onSave: () => void;
	}

	type LocalObjectProps = PropsBase<"object">;
	type LocalObjectGroupProps = PropsBase<"object-group">;

	interface WithController {
		controller: Controller;
	}

	type InternalLocalObjectProps = LocalObjectProps & WithController;
	type InternalLocalObjectGroupProps = LocalObjectGroupProps & WithController;

	type InternalProps =
		| InternalLocalObjectProps
		| InternalLocalObjectGroupProps;

	type Props = LocalObjectProps | LocalObjectGroupProps;

	type Controller = InternalController;
}

export default EditModal;
