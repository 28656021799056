import React, { useMemo } from "react";

import Language from "../../../../../../../../../../services/Language";
import CarClass from "../../../../../../../../../../services/CarClass";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { StyledP } from "../../../../../../../../../../components/common";

const NameCellContent: React.FC<NameCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(() => {
		const { name } = item;
		return name?.[language] || "";
	}, [item, language]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<StyledP>{content}</StyledP>
		</CellContentRoot>
	);
};

declare namespace NameCellContent {
	interface Props {
		item: CarClass.Model;
		language: Language;
	}
}

export default NameCellContent;
