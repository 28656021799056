import React, {
	Dispatch,
	KeyboardEvent,
	memo,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { RowDataType, SortType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import Message from "../../../../../../../../services/Message";
import Language from "../../../../../../../../services/Language";
import ServiceTransaction from "../../../../../../../../services/Transaction";
import formatNumber from "../../../../../../../../utils/formatNumber";
import LightTable from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import {
	StyledColumn,
	StyledP,
} from "../../../../../../../../components/common";

import { Columns, columns, defaultColumnsId } from "./constants";
import { getMessageStatus } from "./utils";
import Root from "./components/Root";
import Type from "./components/Type";
import PaymentAccountType from "./components/PaymentAccountType";
import TransactionAuthor from "./components/TransactionAuthor";
import PaymentAccountProvider from "./components/PaymentAccountProvider";
import EntityType from "./components/EntityType";
import Credentials from "./components/Credentials";
import Comment from "./components/Comment";

const Content: React.FC<Content.Props> = memo(
	({ value, selected, setSelected, sort, onSort, setLimit, language }) => {
		const [ctrlPressed, setCtrlPressed] = useState(false);
		const tableRef = useRef<LightTable.Ref | null>(null);

		const tColumns = useMemo(
			() => columns.map((c) => ({ ...c, label: c.label })),
			[],
		);

		const [columnsId, setColumnsId] = useState(
			defaultColumnsId.filter(
				(id) => id !== "company" && id !== "taxiService",
			),
		);

		const updateSelected = useCallback(
			(item: RowDataType<Message.Model>, force = false) => {
				const model = item as Message.Model;
				if (ctrlPressed || force) {
					if (selected.includes(model.id)) {
						setSelected(selected.filter((id) => model.id !== id));
					} else {
						setSelected([...selected, model.id]);
					}
				} else {
					selected.includes(model.id)
						? setSelected([])
						: setSelected([model.id]);
				}
			},
			[ctrlPressed, selected, setSelected],
		);

		const renderColumns = useMemo(
			() =>
				columnsId.map((columnId) => {
					const column = columns.find((col) => col.id === columnId);
					if (!column) return null;

					return (
						<LightTable.Column
							resizable
							key={columnId}
							verticalAlign="middle"
							width={column.width}
							flexGrow={column.width ? 0 : 1}
							sortable={column.sortable}
							fixed={columnId === Columns.ID ? "left" : undefined}
						>
							<LightTable.HeaderCell
								style={{
									padding: "0 15px",
									flexWrap: "nowrap",
								}}
							>
								{column.label}
							</LightTable.HeaderCell>
							<LightTable.Cell
								style={{ padding: "0 15px" }}
								dataKey={column.id}
								fullText
							>
								{(rowData) => {
									const data =
										rowData as ServiceTransaction.Model;

									const {
										id,
										createdAt,
										amount,
										status,
										metadata,
										operation,
										paymentAccount,
										targetPaymentAccount,
										from,
										to,
									} = data;

									switch (columnId) {
										case Columns.ID:
											return <StyledP>{id}</StyledP>;
										case Columns.ORDER_NUMBER:
											return (
												<StyledP>
													{operation?.order
														?.orderNumber ?? ""}
												</StyledP>
											);
										case Columns.CREATED_AT:
											if (!createdAt) return <></>;
											return (
												<StyledColumn gap="5px">
													<div>
														{new Date(
															createdAt,
														)?.toLocaleTimeString?.()}
													</div>
													<div>
														{new Date(
															createdAt,
														)?.toLocaleDateString?.()}
													</div>
												</StyledColumn>
											);

										case Columns.TYPE:
											return (
												<Type value={{ metadata }} />
											);

										case Columns.COMMENT:
											return (
												<Comment
													operation={operation}
													metadata={metadata}
													lang={language}
												/>
											);

										case Columns.TRANSACTION_AMOUNT:
											return (
												<StyledP>
													{formatNumber(amount)}
												</StyledP>
											);
										case Columns.ACCOUNT_AMOUNT_BEFORE:
											return (
												<StyledP>
													{formatNumber(
														metadata.accountAmountBefore,
													)}
												</StyledP>
											);
										case Columns.ACCOUNT_AMOUNT_AFTER:
											return (
												<StyledP>
													{formatNumber(
														metadata.accountAmountAfter,
													)}
												</StyledP>
											);
										case Columns.DESCRIPTION:
											return (
												<StyledP>
													{operation.description}
												</StyledP>
											);

										case Columns.STATUS:
											return (
												<StyledP>
													{getMessageStatus(status)}
												</StyledP>
											);
										case Columns.TRANSACTION_AUTHOR:
											return (
												<TransactionAuthor
													operation={operation}
												/>
											);
										// TODO: START
										case Columns.TO_IS_PROVIDER:
											if (!to) return <></>;
											return (
												<PaymentAccountProvider
													value={{
														isProvider:
															to?.isProvider,
													}}
												/>
											);
										case Columns.FROM_IS_PROVIDER:
											if (!from) return <></>;
											return (
												<PaymentAccountProvider
													value={{
														isProvider:
															from?.isProvider,
													}}
												/>
											);
										case Columns.TO_TYPE:
											if (!to) return <></>;
											return (
												<PaymentAccountType
													value={{
														type: to?.type,
														isProvider:
															to?.isProvider,
													}}
												/>
											);
										case Columns.FROM_TYPE:
											if (!from) return <></>;
											return (
												<PaymentAccountType
													value={{
														type: from?.type,
														isProvider:
															from?.isProvider,
													}}
												/>
											);

										case Columns.TO_AMOUNT:
											if (!to || to?.amount === null) {
												return <></>;
											}
											return (
												<StyledP>
													{formatNumber(to?.amount)}
												</StyledP>
											);
										case Columns.FROM_AMOUNT:
											if (
												!from ||
												from?.amount === null
											) {
												return <></>;
											}
											return (
												<StyledP>
													{formatNumber(from?.amount)}
												</StyledP>
											);

										case Columns.TO_ENTITY_TYPE:
											if (!to) return <></>;
											return (
												<EntityType
													value={{
														entityType:
															to?.entityType,
													}}
												/>
											);
										case Columns.FROM_ENTITY_TYPE:
											if (!from) return <></>;
											return (
												<EntityType
													value={{
														entityType:
															from?.entityType,
													}}
												/>
											);
										case Columns.TO_CREDENTIALS:
											if (!to) return <></>;
											return (
												<Credentials
													value={{
														relation: to,
														language,
													}}
												/>
											);
										case Columns.FROM_CREDENTIALS:
											if (!from) return <></>;
											return (
												<Credentials
													value={{
														relation: from,
														language,
													}}
												/>
											);

										// TODO: END
										// ?
										case Columns.PAYMENT_ACCOUNT_ID:
											return (
												<StyledP>
													{paymentAccount?.id}
												</StyledP>
											);
										case Columns.PAYMENT_ACCOUNT_PROVIDER:
											return (
												<PaymentAccountProvider
													value={{
														isProvider:
															paymentAccount?.isProvider,
													}}
												/>
											);
										case Columns.PAYMENT_ACCOUNT_INTERNAL:
											return (
												<StyledP>
													{`${paymentAccount?.internal}`}
												</StyledP>
											);
										case Columns.PAYMENT_ACCOUNT_TYPE:
											return (
												<PaymentAccountType
													value={{
														type: paymentAccount?.type,
														isProvider:
															paymentAccount?.isProvider,
													}}
												/>
											);
										case Columns.PAYMENT_ACCOUNT_AMOUNT:
											return (
												<StyledP>
													{formatNumber(
														paymentAccount?.amount,
													)}
												</StyledP>
											);
										case Columns.PAYMENT_ACCOUNT_CONFIGURATION_NAME:
											return (
												<StyledP>
													{paymentAccount
														?.configuration?.name ||
														""}
												</StyledP>
											);
										case Columns.PAYMENT_ACCOUNT_CONFIGURATION_TYPE:
											return (
												<StyledP>
													{paymentAccount
														?.configuration
														?.configurationType ||
														""}
												</StyledP>
											);
										case Columns.PAYMENT_ACCOUNT_CONFIGURATION_PAYMENT_SYSTEM:
											return (
												<StyledP>
													{paymentAccount
														?.configuration
														?.paymentSystem || ""}
												</StyledP>
											);
										//  ?
										case Columns.TARGET_PAYMENT_ACCOUNT_ID:
											return (
												<StyledP>
													{targetPaymentAccount?.id ||
														""}
												</StyledP>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_PROVIDER:
											return (
												<PaymentAccountProvider
													value={{
														isProvider:
															targetPaymentAccount?.isProvider,
													}}
												/>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_INTERNAL:
											if (!targetPaymentAccount) {
												return <></>;
											}
											return (
												<StyledP>
													{`${targetPaymentAccount?.internal}`}
												</StyledP>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_TYPE:
											return (
												<PaymentAccountType
													value={{
														type: targetPaymentAccount?.type,
														isProvider:
															targetPaymentAccount?.isProvider,
													}}
												/>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_AMOUNT:
											return (
												<StyledP>
													{formatNumber(
														targetPaymentAccount?.amount,
													)}
												</StyledP>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_CONFIGURATION_NAME:
											return (
												<StyledP>
													{targetPaymentAccount
														?.configuration?.name ||
														""}
												</StyledP>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_CONFIGURATION_TYPE:
											return (
												<StyledP>
													{targetPaymentAccount
														?.configuration
														?.configurationType ||
														""}
												</StyledP>
											);
										case Columns.TARGET_PAYMENT_ACCOUNT_CONFIGURATION_PAYMENT_SYSTEM:
											return (
												<StyledP>
													{targetPaymentAccount
														?.configuration
														?.paymentSystem || ""}
												</StyledP>
											);
										default:
											return null;
									}
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);
				}),
			[columnsId, language],
		);

		const getClassName = useCallback(
			(item) => (item && selected.includes(item.id) ? "selected" : ""),
			[selected],
		);

		const handleKeyDown = useCallback(
			(e: KeyboardEvent<HTMLDivElement>) => {
				if (e.ctrlKey) {
					setCtrlPressed(true);
				}
			},
			[],
		);

		const handleKeyUp = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
			if (!e.ctrlKey) {
				setCtrlPressed(false);
			}
		}, []);

		// scroll - start
		const [height, setHeight] = useState<number>(-1);
		const [width, setWidth] = useState<number>(-1);
		const handleScroll = useCallback(
			(x: number, y: number) => {
				const top = Math.round(Math.abs(y));
				const left = Math.round(Math.abs(x));
				if (width >= left && height === top) return;
				if (width < left && width !== 999999999) setWidth(left);
				if (top === 0) return;
				if (top > 0 && width < 999999999) setWidth(999999999);

				const contextHeight = value.length * 46;
				const tableContainerHeight =
					tableRef.current?.root.getBoundingClientRect().height ?? 0;
				const valueHeight = contextHeight - top - tableContainerHeight;

				if (valueHeight < 300) {
					if (height - 9 >= top) return;
					setHeight(top);
					setLimit((prev) => prev + 2);
				}
			},
			[width, height, value.length, setLimit],
		);
		// scroll - end

		return (
			<Root sizes="1fr auto!">
				<div
					tabIndex={-1}
					onKeyDown={handleKeyDown}
					onKeyUp={handleKeyUp}
				>
					<LightTable
						ref={tableRef}
						data={value}
						virtualized
						fillHeight
						rowHeight={46}
						shouldUpdateScroll={false}
						rowClassName={getClassName}
						onRowClick={updateSelected}
						sortColumn={sort?.dataKey}
						sortType={sort?.sortType}
						onScroll={handleScroll}
						onSortColumn={onSort}
						wordWrap={false}
					>
						{renderColumns}
					</LightTable>
				</div>
				{/* <TableFooter data={value} /> */}
				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={tColumns}
					onChange={setColumnsId}
				/>
			</Root>
		);
	},
);

declare namespace Content {
	interface Props {
		value: ServiceTransaction.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		sort: {
			dataKey: string;
			sortType: SortType | undefined;
		};
		onSort: (dataKey: string, sortType: SortType | undefined) => void;
		language: Language;
		setLimit: Dispatch<SetStateAction<number>>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
		sortable?: boolean;
	};
}

export default Content;
