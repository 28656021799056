/* eslint-disable no-shadow */

import React, { useCallback, useMemo } from "react";
import { useInternal } from "uikit";
import Draggable from "react-draggable";

import Modal from "../Modal";

import Header from "./components/Header";
import Root from "./components/Root";
import Content from "./components/Content";
import Footer from "./components/Footer";

type Key = string | number;

// eslint-disable-next-line prettier/prettier
const MultiSelectModal = <OptionValue, ValueType extends Key>({
	value,

	title,
	options,

	onChange,

	onClose,
	onSubmit,
}: MultiSelectModal.Props<OptionValue, ValueType>) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const defaultValue = useMemo(() => [], [value]);

	const [internalValue, setInternalValue] = useInternal(
		value ?? defaultValue,
	);

	const headerOnClose = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const contentOnChange = useCallback(
		(contentValue: MultiSelectModal.Value<ValueType>) => {
			if (options)
				contentValue.sort(
					(selected1, selected2) =>
						options.findIndex(
							(option) => option.key === selected1,
						) -
						options.findIndex((option) => option.key === selected2),
				);

			setInternalValue(contentValue);
			onChange?.(contentValue);
		},
		[onChange, options, setInternalValue],
	);

	const footerOnCancel = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const footerOnSave = useCallback(() => {
		onSubmit?.(internalValue);
	}, [internalValue, onSubmit]);

	const content = useMemo(
		() => (
			<Root sizes="auto! 1fr auto!">
				<Header title={title} onClose={headerOnClose} />
				<Content
					value={internalValue}
					options={options ?? []}
					onChange={contentOnChange}
				/>
				<Footer onCancel={footerOnCancel} onSave={footerOnSave} />
			</Root>
		),
		[
			contentOnChange,
			footerOnCancel,
			footerOnSave,
			headerOnClose,
			internalValue,
			options,
			title,
		],
	);

	return (
		<Modal dimmed>
			<Draggable handle=".draggable-area">{content}</Draggable>
		</Modal>
	);
};

declare namespace MultiSelectModal {
	type Value<Type extends Key> = Type[];

	type Option<Value> = Content.Option<Value>;

	interface Props<OptionValue, ValueType extends Key> {
		value?: Value<ValueType>;

		title?: string;
		options?: Option<OptionValue>[];

		onChange?: (value: Value<ValueType>) => void;

		onClose?: () => void;
		onSubmit?: (value: Value<ValueType>) => void;
	}
}

export default MultiSelectModal;
