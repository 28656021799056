import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { Column } from "rsuite-table";
import { CheckBox } from "uikit";
import Root from "./components/Root";
import { columns, defaultColumnsId } from "./constants";
import LightTable from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";
import Modal from "../Modal";

const Content: React.FC<Content.Props> = ({
	rows,
	selectedRows,
	setSelectedRows,
	onEdit,
}) => {
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelectedRows = useCallback(
		(index: number) => {
			if (selectedRows.includes(index)) {
				setSelectedRows(
					selectedRows.filter((value) => index !== value),
				);
			} else {
				setSelectedRows([...selectedRows, index]);
			}
		},
		[setSelectedRows, selectedRows],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<Column
						key={columnId}
						verticalAlign="middle"
						width={column.width || 150}
						flexGrow={columnId === "to" ? 1 : 0}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{column.label}
							{!column.label && <CheckBox />}
						</LightTable.HeaderCell>
						<LightTable.Cell style={{ padding: "0 25px" }}>
							{(rowData) => {
								switch (columnId) {
									case "name":
										return rowData.name;

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</Column>
				);
			}),
		[columnsId],
	);

	return (
		<Root sizes="1fr auto!">
			<LightTable
				data={[]}
				virtualized
				headerHeight={44}
				rowHeight={44}
				fillHeight
				shouldUpdateScroll={false}
				rowClassName={(rowData) =>
					rowData && selectedRows.includes(rows.indexOf(rowData))
						? "selected"
						: ""
				}
				onRowClick={(row) => {
					updateSelectedRows(rows.indexOf(row));
				}}
				onRowDoubleClick={(row) => {
					onEdit && onEdit(rows.indexOf(row));
				}}
			>
				<LightTable.Column width={0}>
					<LightTable.HeaderCell>
						<></>
					</LightTable.HeaderCell>
					<LightTable.Cell />
				</LightTable.Column>
				{renderColumns}
				<LightTable.Column width={0}>
					<LightTable.HeaderCell>
						<></>
					</LightTable.HeaderCell>
					<LightTable.Cell />
				</LightTable.Column>
			</LightTable>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={columns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		rows: Modal.Value[];
		selectedRows: number[];
		setSelectedRows: Dispatch<number[]>;
		onEdit?: (x: number) => void;
	}

	interface ColumnType {
		id: string;
		label: string;
	}
}

export default Content;
