import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	height: 40px;

	padding-inline: 12px;

	background: ${theme.colors.white};

	cursor: pointer;
`;

export default Root;
