import React, { Dispatch, useCallback, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, theme } from "uikit";
import { cloneDeep } from "lodash";

import {
	StyledRow,
	Button,
	StyledColumn,
	StyledP,
} from "../../../../../../../../../../../../../../../../components/common";

import Root from "./components/Root";
import EditModal from "./components/Modal";

const Header: React.FC<Header.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [edit, setEdit] = useState<EditModal.Value>({
		isMaxSpeedEnabled: false,
		maxSpeed: 0,
		averageSpeed: [],
		isBasicAverageSpeedEnabled: false,
		isAverageSpeedEnabled: false,
		basicAverageSpeed: [],
	});

	useLayoutEffect(() => {
		setEdit(cloneDeep(value));
	}, [value]);

	const onCancel = useCallback(() => {
		setShowModal(false);
	}, []);

	const onSave = useCallback(() => {
		onChange(edit);
		setShowModal(false);
	}, [edit, onChange]);

	return (
		<Root>
			<StyledColumn alignItems="start" gap="5px">
				<StyledRow alignItems="center" gap="5px">
					<StyledP
						p="0 0 0 10px"
						font={{
							size: "14px",
							line: "15px",
						}}
					>
						{t(
							"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.str150",
						) ?? ""}
					</StyledP>
					<Button
						title={
							t(
								"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.str0",
							) ?? ""
						}
						variant="secondary"
						onClick={() => setShowModal(true)}
					>
						<Icon
							id="pencil"
							size={20}
							colors={[theme.colors.primary]}
						/>
					</Button>
				</StyledRow>
			</StyledColumn>
			{showModal && (
				<EditModal
					value={edit}
					onChange={setEdit}
					onCancel={onCancel}
					onSave={onSave}
				/>
			)}
		</Root>
	);
};

declare namespace Header {
	interface Value extends EditModal.Value {}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Header;
