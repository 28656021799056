import React, { Dispatch, RefAttributes } from "react";
import { react, useRefWithSetter } from "uikit";

import CarClass from "../../../../../../../../../../services/CarClass";
import Language from "../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	Tab,
} from "../../../../../../../../../../components/common";
import { ModalTabs } from "../../../../constants/modalTabs";
import Main from "../Main";
import Services from "../Services";

import InternalController from "./Controller";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, type, disabled, onChange, classModels }) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref | null>(
			null,
		);
		const [servicesTabRef, setServicesTabRef] =
			useRefWithSetter<Services.Ref | null>(null);

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"id",
			"companyIds",
			"taxiServiceIds",
			"taxiServices",
			"name",
			"shortName",
			"useTextColor",
			"textColor",
			"backgroundColor",
			"useBackgroundColor",
			"distributable",
			"compatibleCarClassIds",
			"compatibleCarClassIdsToBroadcastable",
			"broadcastingCompatibleMode",
			"broadcastable",
			"distributionCompatibleMode",
			"distributable",
			"root",
			"priority",
		]);

		const servicesTabValue = valueEditor.usePicker([
			"serviceAvailableIds",
			"serviceDefaultIds",
		]);

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");

		const assigner = valueEditor.useAssigner();

		controller.setContext({ mainTabRef, servicesTabRef });
		return (
			<StyledRow p="16px" w="100%" h="100%" overY="scroll" scrollbar>
				<Tab visible={type === ModalTabs.MAIN}>
					<Main
						ref={setMainTabRef}
						disabled={disabled}
						value={mainTabValue}
						onChange={assigner}
						classModels={classModels}
					/>
				</Tab>
				<Tab visible={type === ModalTabs.SERVICES}>
					<Services
						ref={setServicesTabRef}
						value={servicesTabValue}
						onChange={assigner}
						disabled={false}
						taxiServiceIds={taxiServiceIds}
					/>
				</Tab>
			</StyledRow>
		);
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface Value extends Record<string, any>, Main.Value, Services.Value {}

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		type: ModalTabs;
		disabled: boolean;
		language: Language;
		classModels: CarClass.Model[];
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
