import styled from "styled-components";

const Root = styled.div`
	width: 100%;
	height: 100%;

	border-radius: 4px;

	overflow: hidden;

	cursor: pointer;
`;

export default Root;
