import { ChatMessage as ChatMessageServices } from "../../../../services";

const composeFullName = (
	person: ChatMessageServices.OutputGrouped.Person,
): string => {
	const { name, surname, fatherName } = person;

	const fullNameParts: string[] = [];

	if (surname) fullNameParts.push(surname.trim());
	if (name) fullNameParts.push(name.trim());
	if (fatherName) fullNameParts.push(fatherName.trim());

	return fullNameParts.length ? fullNameParts.join(" ") : "";
};

const isObject = (value: any): value is Record<string, any> =>
	value !== null && typeof value === "object";

const getUserData = (
	message: ChatMessageServices.OutputGrouped.ChatMessage,
):
	| ChatMessageServices.OutputGrouped.Executor
	| ChatMessageServices.OutputGrouped.Dispatcher
	| null => {
	const isExecutor = message?.executor;
	const isDispatcher = message?.dispatcher;
	const user = isDispatcher ?? isExecutor ?? null;

	return user;
};

const getFullTitle = (
	userData:
		| ChatMessageServices.OutputGrouped.Executor
		| ChatMessageServices.OutputGrouped.Dispatcher,
): string => {
	const idPerson = userData.callSign ?? "";
	const fullName = userData.person ? composeFullName(userData.person) : "";

	const idAndFullName = `${idPerson} - ${fullName}`;

	return idAndFullName;
};

export { composeFullName, isObject, getUserData, getFullTitle };
