import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const TransportationTypeCellContent: React.FC<
	TransportationTypeCellContent.Props
> = ({ item }) => {
	const { t } = useTranslation();
	const content = useMemo(() => {
		const type = item.additionalFields?.transportationType;

		return (
			t([`orderPage.order_form.transportation_types.${type}`, type]) || ""
		);
	}, [item, t]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace TransportationTypeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default TransportationTypeCellContent;
