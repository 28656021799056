import React, { memo, useMemo } from "react";

import { type TrackStartEnd as TrackStartEndBase } from "../../../../redux/reducers/Archives/GPSLog/interface";

import { Text } from "../../../common";
import { MarkerItem } from "../MarkerItem";

const MarkerListBase: React.FC<MarkerListBase.Props> = ({
	data,
}): JSX.Element => {
	const items = useMemo<MarkerListBase.TrackStartEnd[]>(() => {
		if (!data?.length) return [];
		return data;
	}, [data]);

	if (!items?.length) return <></>;
	return (
		<>
			{items.map((item, i): JSX.Element => {
				const { endTimestamp, point, lines } = item;

				return (
					<MarkerItem
						key={i}
						position={point}
						toggle={!!endTimestamp}
					>
						<div>
							{lines.length &&
								lines.map((value, j) => {
									if (!value[0]) return <></>;
									return (
										<Text
											text={value[0]}
											value={value[1]}
											key={`${i}-${j}`}
										/>
									);
								})}
						</div>
					</MarkerItem>
				);
			})}
		</>
	);
};

declare namespace MarkerListBase {
	interface TrackStartEnd extends TrackStartEndBase {}
	interface Props {
		data: TrackStartEnd[];
	}
}

export const MarkerList = memo(MarkerListBase);
export default MarkerListBase;
