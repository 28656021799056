/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo } from "react";
import { Column, react } from "uikit";
import { useTranslation } from "react-i18next";
import Language from "../../../../../../../../../../../../../../services/Language";
import InternalController from "./Controller";
import TabRoot from "../TabRoot";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import ModalTable from "./components/ModalTable";

const symbolsCount = 13;
const smsCount = 1;

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, visible, language, onChange }) => {
			const globalLang = useTypedSelector(
				(state) => state.session.language,
			);
			const { t } = useTranslation();

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto!*3 1fr"
						gaps="19px 60px"
						maxedWidth
						maxedHeight
					>
						<ModalTable />
						<br />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		notes: string;
	}

	interface PropsBase {
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
