import styled from "styled-components";

const Root = styled.div<Root.Props>`
	position: relative;

	width: 20px;
	height: 20px;

	border-radius: 50%;

	background-color: ${({ color }) => color};
`;

declare namespace Root {
	interface Props {
		color: string;
	}
}

export default Root;
