import styled from "styled-components";
import { Column, theme } from "uikit";

const Block = styled(Column)`
	width: 500px;

	border-radius: 7px;

	background-color: ${theme.colors.white};

	overflow: hidden;
`;

export default Block;
