import React from "react";

import { StyledRow } from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";

import Left from "./Left";
import Right from "./Right";

const Header: React.FC<OrderFormProps> = ({ tab, form, disabled }) => (
	<StyledRow
		flex={{ wrap: "nowrap" }}
		justify="space-between"
		alignItems="center"
		h="clamp(26px, 3.6vh, 32px)"
		p="0px 12px"
	>
		<Left disabled={disabled} tab={tab} form={form} />
		<Right disabled={disabled} tab={tab} form={form} />
	</StyledRow>
);

export default Header;
