import React, { memo } from "react";
import { Row } from "uikit";
import FromTo from "../../../../../../../../Modal/components/Content/components/Sectors/components/OrdersCounter/components/Range/FromTo";

const FromToOutput: React.FC<FromToOutput.Props> = memo(({ label, value }) =>
	value.active ? (
		<Row gaps="3px*">
			<div>{label}</div>
			<div>{value.value}</div>
		</Row>
	) : null,
);

declare namespace FromToOutput {
	interface Props {
		label: string;
		value: FromTo.Value;
	}
}

export default FromToOutput;
