/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { LatLngExpression } from "leaflet";

import { defaultLanguage } from "../../../assets/languages/langs";
import { getPRPC } from "../../../hooks/usePRPC";
import MapLanguage from "../../../types/MapLanguage";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import { store } from "../../store";

import Map from ".";

export interface RevealParams {
	language?: MapLanguage;
	limit?: number;
	radius?: number;
}

namespace Point {
	export function reveal(
		point: LatLngExpression,
		params: RevealParams = {},
	): Promise<any> | null {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		if (Array.isArray(point)) point = { lat: point[0], lng: point[0] };
		else point = { lat: point.lat, lng: point.lng };

		const language: string =
			params.language ??
			(store.getState().settings.map.mapLanguage || defaultLanguage);

		return createRPCQuery(() =>
			prpcow?.theirsModel.map.reveal(point, {
				radius: store.getState().settings.map.revealRadiusMeters,
				...params,
				lang: language,
			}),
		);
	}

	export async function search(
		query: string,
		{ language, ...params }: Partial<Map.Search.Params> = {},
	) {
		const prpcow = getPRPC();
		const state = store.getState();

		if (!prpcow || query.length < state.settings.map.minSearchQueryLength)
			return [];

		const processedLanguage =
			language || state.settings.map.mapLanguage || defaultLanguage;

		const result: Map.Search.Response = await createRPCQuery(() =>
			prpcow.theirsModel.map.search(query, {
				...params,
				lang: processedLanguage,
			}),
		);

		if (!Array.isArray(result?.objects)) return [];

		return result.objects.map((item) => ({
			...item.properties.language[processedLanguage],
			coordinates: item.properties.geoCentroid,
			bbox: item.properties.bbox,
			geometry: item.properties.geometry,
			type: item.type,
		}));
	}
}

export default Point;
