import styled from "styled-components";
import { OrderSelect, theme } from "uikit";

const Root = styled(OrderSelect)`
	background: ${theme.colors.button_secondary};

	overflow: hidden overlay;
`;

export default Root;
