export interface FieldValidation {
	fieldName: string;
	required: boolean;
	maxLength?: number;
	minLength?: number;
	notNull?: boolean;
}

export function validateFields(
	data: Record<string, any>,
	validations: FieldValidation[],
): boolean | Record<string, boolean> {
	const invalidFields: Record<string, boolean> = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const validation of validations) {
		const fieldValue = data[validation.fieldName];

		if (
			validation.required &&
			(fieldValue === undefined ||
				fieldValue === null ||
				fieldValue === "")
		) {
			invalidFields[validation.fieldName] = true;
		} else if (
			validation.maxLength !== undefined &&
			fieldValue &&
			fieldValue.length > validation.maxLength
		) {
			invalidFields[validation.fieldName] = true;
		} else if (
			validation.minLength !== undefined &&
			fieldValue &&
			fieldValue.length < validation.minLength
		) {
			invalidFields[validation.fieldName] = true;
		}
	}

	if (Object.keys(invalidFields).length === 0) {
		return true; // All fields have passed validation
	}
	return invalidFields; // Return the object with failed fields
}
