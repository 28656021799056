import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Counterparty } from "../../../../services";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectCounterparty: React.FC<MultiSelectCounterparty.Props> = ({
	value = [],
	onChange,
	companyIds = [],
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	subscribeOptions = {},
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => subscribeOptions, [subscribeOptions]);

	const counterpartySubscribe = useModelSubscribe(options, Counterparty);

	const [error, setError] = useState("");

	const texts = useMemo(
		() => ({
			title: t("contragentChoise"),
			allText: t("all_counterparty"),
			allActiveText: t("all_active_counterparty"),
			erpou: t(
				"mainPage.customers.counterparties.modal.tabs.main.mainData.edrpou",
			),
		}),
		[t],
	);

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			counterpartySubscribe.models.map((item) => ({
				key: item.id,
				label: `${item.additionalFields?.name || "Unknown"} (${
					texts.erpou
				}: ${item.additionalFields?.edrpou || "Unknown"})`,
				value: item.id,
				name: `${item.additionalFields?.name || "Unknown"} (${
					texts.erpou
				}: ${item.additionalFields?.edrpou || "Unknown"})`,
				data: item,
			})),
		[counterpartySubscribe.models, texts.erpou],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const ids = newValue.map((item) => item.value);
			onChange(ids);
		},
		[onChange],
	);

	useEffect(() => {
		if (disabled || !companyIds?.length) {
			onChange([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled, companyIds]);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				<StyledP>{t(titleText) ?? ""}</StyledP>
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={error}
					disabled={disabled || !companyIds?.length}
					title={texts.title}
					allText={texts.allText}
					allActiveText={texts.allActiveText}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					showSelectAll
					modalSetting={modalSetting}
					sort={{
						show: true,
						active: true,
						...(sort || {}),
					}}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectCounterparty {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		companyIds?: number[];
		subscribeOptions?: Counterparty.SubscribeOptions;
	}

	type Value = number[];
}

export default memo(MultiSelectCounterparty);
