import React, { useEffect, useState } from "react";
import CurrentShifts from "./tabs/CurrentShifts";
import ArchivalShifts from "./tabs/ArchivalShifts";
import NavTabs from "./components/NavTabs";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import { convertDataToArchiveRows } from "../../../../../../redux/reducers/Archives/DriverShifts";

export enum DriverShiftsTabs {
	CURRENT = "current",
	ARCHIVAL = "archival",
}

const DriverShifts = () => {
	const dispatch = useTypedDispatch();

	const { data } = useTypedSelector((state) => state.archiveDriverShifts);

	const [tab, setTab] = useState(DriverShiftsTabs.CURRENT);

	useEffect(() => {
		dispatch(convertDataToArchiveRows());
	}, [data]);

	return (
		<>
			<NavTabs tab={tab} setTab={setTab} />
			{tab === DriverShiftsTabs.CURRENT && <CurrentShifts />}
			{tab === DriverShiftsTabs.ARCHIVAL && <ArchivalShifts />}
		</>
	);
};

export default DriverShifts;
