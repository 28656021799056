/* eslint-disable no-shadow */
import getOptions from "../utils/generateOptions";

export enum TariffPaymentType {
	CASH = "cash",
	CARD = "card",
	CORPORATE = "corporate",
	BONUS = "bonus",
	INVOICE = "invoice",
	TERMINAL = "terminal",
}

export const tariffPaymentOptions = getOptions(
	Object.values(TariffPaymentType),
);
