/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

export function maxDate(date: Date, minDate: Date) {
	if (minDate.getFullYear() < date.getFullYear()) return date;

	if (minDate.getMonth() < date.getMonth()) return date;

	if (minDate.getDate() < date.getDate()) return date;

	const newDate = new Date(date);
	newDate.setFullYear(
		minDate.getFullYear(),
		minDate.getMonth(),
		minDate.getDate(),
	);
	return newDate;
}

export function minDate(date: Date, maxDate: Date) {
	if (maxDate.getFullYear() > date.getFullYear()) return date;

	if (maxDate.getMonth() > date.getMonth()) return date;

	if (maxDate.getDate() > date.getDate()) return date;

	const newDate = new Date(date);
	newDate.setFullYear(
		maxDate.getFullYear(),
		maxDate.getMonth(),
		maxDate.getDate(),
	);
	return newDate;
}

export function clampDate(date: Date, min?: Date | null, max?: Date | null) {
	if (min) date = maxDate(date, min);

	if (max) date = minDate(date, max);

	return date;
}

export function maxTime(date: Date, minDate: Date) {
	if (minDate.getHours() < date.getHours()) return date;

	if (minDate.getMinutes() < date.getMinutes()) return date;

	if (minDate.getSeconds() < date.getSeconds()) return date;

	if (minDate.getMilliseconds() < date.getMilliseconds()) return date;

	const newDate = new Date(date);
	newDate.setHours(
		minDate.getHours(),
		minDate.getMinutes(),
		minDate.getSeconds(),
		minDate.getMilliseconds(),
	);
	return newDate;
}

export function minTime(date: Date, maxDate: Date) {
	if (maxDate.getHours() > date.getHours()) return date;

	if (maxDate.getMinutes() > date.getMinutes()) return date;

	if (maxDate.getSeconds() > date.getSeconds()) return date;

	if (maxDate.getMilliseconds() > date.getMilliseconds()) return date;

	const newDate = new Date(date);
	newDate.setHours(
		maxDate.getHours(),
		maxDate.getMinutes(),
		maxDate.getSeconds(),
		maxDate.getMilliseconds(),
	);
	return newDate;
}

export function clampTime(date: Date, min?: Date | null, max?: Date | null) {
	if (min) date = maxTime(date, min);

	if (max) date = minTime(date, max);

	return date;
}
