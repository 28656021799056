import React from "react";
import { Icon } from "uikit";
import { Link } from "react-router-dom";

import { StyledP, StyledRow } from "../../../../../common";

const Logo: React.FC<Logo.Props> = () => (
	<Link tabIndex={-1} to="/">
		<StyledRow gap="6px">
			<Icon id="logo1" size={[29, 18]} />
			<StyledP colors="#ffffff" font={{ size: "16px" }}>
				{"ES Cabinet"}
			</StyledP>
		</StyledRow>
	</Link>
);

declare namespace Logo {
	interface Props {}
}

export default Logo;
