import { useCallback, useMemo } from "react";
import { isNumber } from "lodash";

import { useTypedSelector } from "../redux/store";
import mapByKey from "../utils/mapByKey";

import useCompanyIdsDecoder from "./useCompanyIdsDecoder";

type EncodedIds = number[] | ["all"];

function isAllIds(taxiServices: EncodedIds): taxiServices is ["all"] {
	return taxiServices[0] === "all";
}

function useTaxiServiceIdsDecoder({
	allowedTaxiServiceIds,
}: useTaxiServiceIdsDecoder.Options = {}) {
	const decodeCompanyIds = useCompanyIdsDecoder();

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const availableTaxiServicesIds = useMemo(
		() =>
			taxiServices
				.map((taxiService) => taxiService.id)
				.filter(
					(taxiServiceId) =>
						allowedTaxiServiceIds?.includes(taxiServiceId) ?? true,
				),
		[allowedTaxiServiceIds, taxiServices],
	);

	const decoder = useCallback(
		(companyIds: EncodedIds, taxiServiceIds: EncodedIds) => {
			const decodedCompanyIds = decodeCompanyIds(companyIds);

			if (isAllIds(companyIds) && isAllIds(taxiServiceIds))
				return availableTaxiServicesIds;

			if (isAllIds(taxiServiceIds))
				return availableTaxiServicesIds.filter(
					(taxiServiceId) =>
						isNumber(taxiServiceById[taxiServiceId].company) &&
						decodedCompanyIds.includes(
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							taxiServiceById[taxiServiceId].company!.id,
						),
				);

			return availableTaxiServicesIds.filter((taxiServiceId) =>
				taxiServiceIds.includes(taxiServiceId),
			);
		},
		[availableTaxiServicesIds, decodeCompanyIds, taxiServiceById],
	);

	return decoder;
}

declare namespace useTaxiServiceIdsDecoder {
	interface Options {
		allowedTaxiServiceIds?: number[];
	}
}

export default useTaxiServiceIdsDecoder;
