import styled from "styled-components";
import { Table, theme } from "uikit";
import React, { forwardRef, memo } from "react";
import { extend } from "lodash";

import styles from "./styles";
import LocalHeaderCell from "./components/HeaderCell";

const TableBase = memo(
	forwardRef<Table.Ref, Table.Props>((props, ref) => (
		<Table ref={ref} {...props} />
	)),
);

const RootBase = extend(TableBase, {
	Context: Table.Context,
	Column: Table.Column,
	ColumnGroup: Table.ColumnGroup,
	Cell: Table.Cell,
	HeaderCell: LocalHeaderCell,
});

const Root = styled(RootBase)`
	.rs-table-row-header {
		box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);

		.rs-table-cell-content {
			display: flex !important;

			flex-direction: row !important;
			flex-wrap: nowrap !important;

			align-items: center !important;

			overflow: hidden !important;
		}

		& .rs-table-cell-header {
			${styles.cell("header")}
		}
	}

	.rs-table-row:not(.rs-table-row-header) {
		.rs-table-cell-first,
		.rs-table-cell-last {
			border-bottom: none !important;
		}

		&:hover {
			z-index: 1;
		}

		&.selected {
			background-color: #eaf8fe !important;

			.rs-table-cell {
				background-color: #eaf8fe !important;
			}

			&:hover {
				background-color: #e2f5fe !important;

				& .rs-table-cell {
					background-color: #e2f5fe !important;
				}
			}
		}

		&.not-active {
			.rs-table-cell,
			.rs-table-cell-content {
				color: ${theme.colors.disabled_text} !important;
			}
		}

		& .rs-table-cell {
			${styles.cell("body")}

			border-right: 1px solid var(--rs-border-secondary);

			.rs-table-cell-content {
				padding: 14px 8px;
			}
		}
	}
`;

declare namespace Root {
	type Ref = Table.Ref;
	type Props = Table.Props;

	namespace Context {
		type Value = Table.Context.Value;
	}

	namespace Column {
		type Props = Table.Column.Props;
	}

	namespace ColumnGroup {
		type Props = Table.ColumnGroup.Props;
	}

	namespace Cell {
		type Props = Table.Cell.Props;
	}

	namespace HeaderCell {
		type Props = LocalHeaderCell.Props;
	}
}

export default Root;
