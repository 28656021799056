import React, { memo } from "react";
import ExecutorsCounter from "../../../../../../../Modal/components/Content/components/Sectors/components/ExecutorsCounter";
import RangeOutput from "./RangeOutput";

const ExecutorsCounterOutput: React.FC<ExecutorsCounterOutput.Props> = memo(
	({ value }) => (value.active ? <RangeOutput value={value.range} /> : null),
);

declare namespace ExecutorsCounterOutput {
	interface Props {
		value: ExecutorsCounter.Value;
	}
}

export default ExecutorsCounterOutput;
