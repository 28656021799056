/* eslint-disable no-console */

import RuntimeError from "./runtimeError.util";

const DEFAULT_OPTIONS = {
	silent: true,
	verbose: false,
	error: false,
};

export interface CreateRPCQueryOptions {
	name?: string;
	silent?: boolean;
	verbose?: boolean;
	error?: boolean;
}

/**
 * Creates an RPC query by invoking the provided handler function.
 * @param {Function} handler - The handler function to be invoked.
 * @param {CreateRPCQueryOptions} [options] - Optional configuration options for the RPC query.
 * @returns {Promise<any>} - A promise that resolves to the response data of the RPC query.
 */
const createRPCQuery = async (handler, options: CreateRPCQueryOptions = {}) => {
	const normalizedOptions = {
		...DEFAULT_OPTIONS,
		...options,
	};

	try {
		const start = Date.now();
		const response = await handler();
		const delta = Date.now() - start;

		if (response.status !== 200) {
			throw new RuntimeError(
				response.error.message,
				response.status,
				response.error.name,
				response.error.details,
			);
		}

		if (normalizedOptions.verbose) {
			console.log(
				"[info]: RPC Query (%s) success, execution time",
				normalizedOptions.name,
				delta,
				"ms",
			);
		}
		return response.data;
	} catch (e: unknown) {
		const error = e as RuntimeError;

		console.log("[error]: RPC Query error:", error, {
			message: error.message,
			details: error.details,
			status: error.status,
			name: error.name,
			handler,
		});
		if (!options.silent && !options.error) {
			throw new RuntimeError(
				error.message,
				error.status,
				error.name,
				error.details,
			);
		} else if (!options.silent && options.error) {
			return {
				error: options.error,
				data: new RuntimeError(
					error.message,
					error.status,
					error.name,
					error.details,
				),
			};
		}

		return null;
	}
};

export default createRPCQuery;
