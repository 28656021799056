import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../../../../../services/Transaction";
import { StyledP } from "../../../../../../../../../../components/common";

const EntityType: React.FC<EntityType.Props> = memo(({ value }) => {
	const { t } = useTranslation();

	const { entityType } = value;

	const entity = useMemo(() => {
		if (entityType === null) return <></>;
		return t([`transactionEntityType.${entityType}`, entityType]);
	}, [entityType, t]);

	return <StyledP>{entity}</StyledP>;
});

declare namespace EntityType {
	interface Props {
		value: Value;
	}

	interface Value {
		entityType: Transaction.PaymentAccountRelation["entityType"] | null;
	}
}

export default EntityType;
