import getOptions from "../../../../../../../../../utils/generateOptions";

// eslint-disable-next-line no-shadow
export enum GeneralTariffTabs {
	GENERAL_SURCHARGES = "generalSurcharges",
	HISTORY = "history",
}

const tabs = getOptions(Object.values(GeneralTariffTabs));

export default tabs;
