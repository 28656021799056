import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Tabs } from "uikit";
import moment from "moment";

import Root from "./components/Root";
import Title from "./components/Title";
import { getTabs, Tab } from "./constants";
import InformationContainer from "./components/InformationContainer";
import SubTitle from "./components/SubTitle";
import LockedBy from "./components/LockedBy";

const Header: React.FC<Header.Props> = memo(
	({
		tab,

		title,
		lockedBy,
		createdAt,

		onChangeTab,
	}) => {
		const { t } = useTranslation();

		const tabs = useMemo(() => getTabs(t), [t]);
		return (
			<Root sizes="auto!*">
				<InformationContainer
					align="center"
					justify="space-between"
					gaps="10px"
				>
					<Title>{title}</Title>
					<Column align="end" gaps="15px">
						{lockedBy ? (
							<LockedBy>
								{t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.str200",
								) ?? ""}{" "}
								{lockedBy}
							</LockedBy>
						) : (
							<></>
						)}
						{createdAt ? (
							<SubTitle>
								{`${
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.header.str201",
									) ?? ""
								}: ${moment(createdAt).format(
									"DD.MM.YYYY HH:mm",
								)}`}
							</SubTitle>
						) : (
							<></>
						)}
					</Column>
				</InformationContainer>
				<Tabs
					value={tab}
					options={tabs}
					variant="panels"
					onChange={(newTab) => onChangeTab(newTab.toString() as Tab)}
				/>
			</Root>
		);
	},
);

declare namespace Header {
	interface Props {
		tab:
			| "main"
			| "subscriptions"
			| "commissionPlans"
			| "discountedPlans"
			| "executors";

		title: string;
		lockedBy?: string;
		createdAt?: string;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
