/* eslint-disable no-shadow */

import React, { Dispatch, memo, useMemo } from "react";
import Root from "./components/Root";
import Item from "./components/Item";

const List = memo<List.Props>(
	({ value, onChange, items, disabledItems = [] }) => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const valueSet = useMemo(() => new Set(value), [value.length]);

		return (
			<Root gaps="1px*" sizes="40px!*">
				{items.map((item) => (
					<Item
						key={item.id}
						selected={valueSet.has(item.id)}
						label={item.label}
						disabled={disabledItems?.includes(item.id)}
						onChangeSelected={(selected) => {
							if (disabledItems?.includes(item.id)) return;
							onChange(
								selected
									? [...value, item.id]
									: value.filter((id) => id !== item.id),
							);
						}}
					/>
				))}
			</Root>
		);
	},
);

declare namespace List {
	interface Item {
		id: string;
		label: string;
	}

	type Value = string[];

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;

		items: Item[];
		disabledItems?: Item["id"][];
		search?: string;
	}
}

export default List;
