import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, RadioList2, Select } from "uikit";

import PhoneBlacklist from "../../../../../../../../services/PhoneBlacklist";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import Divider from "../../../../../../../../components/Divider";

import Root from "./components/Root";

export enum ClientCancelReason {
	TooExpensive = "too_expensive",
	CarIsNotSelected = "client_did_not_choose_car",
}

const Content: React.FC<Content.Props> = ({
	value,
	onChange,

	addClientToBlackList,
	errorComment,
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const reasonValue = valueEditor.useGetter("reason");
	const reasonOnChange = valueEditor.useSetter("reason");

	const commentValue = valueEditor.useGetter("comment");
	const commentOnChange = valueEditor.useSetter("comment");

	const blockLevelValue = valueEditor.useGetter("blockLevel");
	const blockLevelOnChange = valueEditor.useSetter("blockLevel");

	const blockCommentValue = valueEditor.useGetter("blockComment");
	const blockCommentOnChange = valueEditor.useSetter("blockComment");

	const reasonOptions = useMemo<RadioList2.Option<ClientCancelReason>[]>(
		() => [
			{
				key: ClientCancelReason.TooExpensive,
				value: ClientCancelReason.TooExpensive,
				content:
					t(
						"pages.mainPage.pages.orders.clientCancelOrderModal.content.str203",
					) ?? "",
			},
			{
				key: ClientCancelReason.CarIsNotSelected,
				value: ClientCancelReason.CarIsNotSelected,
				content:
					t(
						"pages.mainPage.pages.orders.clientCancelOrderModal.content.str204",
					) ?? "",
			},
		],
		[t],
	);

	const clientBlockOptions = useMemo(
		() => [
			{
				key: PhoneBlacklist.Level.WARNING,
				label:
					t(
						"pages.mainPage.pages.orders.clientCancelOrderModal.content.str200",
					) ?? "",
				value: PhoneBlacklist.Level.WARNING,
			},
			{
				key: PhoneBlacklist.Level.BLOCKED,
				label:
					t(
						"pages.mainPage.pages.orders.clientCancelOrderModal.content.str201",
					) ?? "",
				value: PhoneBlacklist.Level.BLOCKED,
			},
			{
				key: PhoneBlacklist.Level.BAN_CALLS,
				label:
					t(
						"pages.mainPage.pages.orders.clientCancelOrderModal.content.str202",
					) ?? "",
				value: PhoneBlacklist.Level.BAN_CALLS,
			},
		],
		[t],
	);

	return (
		<Root gaps="16px*">
			<RadioList2
				value={reasonValue}
				autoFocus
				options={reasonOptions}
				onChange={reasonOnChange}
			>
				{(radios) => <Column gaps="16px*">{radios}</Column>}
			</RadioList2>
			<MultilineTextBox
				value={commentValue}
				style={{ height: 80 }}
				placeholder={
					t(
						"pages.mainPage.pages.orders.clientCancelOrderModal.content.str0",
					) ?? ""
				}
				onChange={commentOnChange}
			/>
			{addClientToBlackList && (
				<>
					<Divider
						orientation="horizontal"
						size="max"
						color="#DEE0E2"
					/>
					<Select
						value={blockLevelValue}
						options={clientBlockOptions}
						onSelect={(option) => blockLevelOnChange(option.value)}
					/>
					<MultilineTextBox
						error={errorComment}
						value={blockCommentValue}
						style={{ height: 80 }}
						placeholder={
							t(
								"pages.mainPage.pages.orders.clientCancelOrderModal.content.str1",
							) ?? ""
						}
						onChange={blockCommentOnChange}
					/>
				</>
			)}
		</Root>
	);
};

declare namespace Content {
	interface Value {
		reason?: ClientCancelReason;
		comment: string;
		blockLevel: PhoneBlacklist.Level;
		blockComment?: string;
	}

	interface Props {
		value: Value;

		addClientToBlackList: boolean;
		errorComment: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Content;
