import { Client } from "prpcow";

import createLogger from "../../utils/logger.util";

import { baseSocket } from "./domain/domain";

const RECONNECTION_TIMEOUT = 5_000;
let reconnectionTimeoutId: NodeJS.Timeout | number = -1;
let isReconnect = false;

const logger = createLogger({ name: "prpcow::base" });

// eslint-disable-next-line import/prefer-default-export
export async function prpcow(onConnected, onDisconnected, token) {
	const handleError = (error) => {
		logger.error("Session connection failed:", error);
		logger.info(
			"Reconnection attempt after %i seconds...",
			RECONNECTION_TIMEOUT / 1000,
		);

		clearTimeout(reconnectionTimeoutId);

		reconnectionTimeoutId = setTimeout(() => {
			isReconnect = true;
			prpcow(onConnected, onDisconnected, token);
		}, RECONNECTION_TIMEOUT);
	};

	try {
		const callback = (error, session) => {
			if (error) return handleError(error);

			session.on("theirsModelChange", async (model) => {
				logger.info("Theirs model has been changed!", model);

				clearTimeout(reconnectionTimeoutId);
				onConnected(session, isReconnect);
				isReconnect = false;
			});
			session.on("close", (reason) => {
				logger.info("Session closed", { session, reason });

				onDisconnected();
				clearTimeout(reconnectionTimeoutId);
				reconnectionTimeoutId = setTimeout(() => {
					isReconnect = true;
					prpcow(onConnected, onDisconnected, token);
				}, RECONNECTION_TIMEOUT);
			});

			return session;
		};

		const client = new Client(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			WebSocket,
			baseSocket,
			token ? [token] : [],
			{
				logger: { level: false },
			},
			callback,
		);

		client.websocket.addEventListener("close", (...args: any[]) => {
			logger.warn("Native websocket 'close' event received", ...args);
			handleError(args);
		});
		await client.init();
	} catch (error) {
		handleError(error);
	}
}
