import styled from "styled-components";
import { Flex } from "uikit";

const Root = styled(Flex)`
	position: absolute;

	bottom: 16px;
`;

export default Root;
