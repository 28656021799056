import React, { useMemo } from "react";
import moment from "moment";
import { theme } from "uikit";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const OrderDateCellContent: React.FC<OrderDateCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() =>
			item.isPreliminary
				? moment(item.orderDate).format("DD-MM-YYYY")
				: "",
		[item.isPreliminary, item.orderDate],
	);

	const backgroundColor = useMemo(
		() => (item.isPreliminary ? "#7B0078" : undefined),
		[item.isPreliminary],
	);

	const textColor = useMemo(
		() => (item.isPreliminary ? theme.colors.white : undefined),
		[item.isPreliminary],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace OrderDateCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default OrderDateCellContent;
