import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

import tPath from "../../../../../../constants/tPath";
import { AutoRateRuleOrderPoint, orderPointsOptions } from "./constants";

const ApplyToOrderPoints: React.FC<ApplyToOrderPoints.Props> = memo(
	({ value, onChange, disabled = false }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const points = valueEditor.useGetter("points");
		const setPoints = valueEditor.useSetter("points");
		const handlePointsChange = useCallback(
			(newPoints) => {
				setPoints(newPoints);
			},
			[setPoints],
		);

		const tOptions = useMemo(
			() =>
				orderPointsOptions.map((o) => ({
					...o,
					label: t(
						`${tPath}.modal.applyToOrderPoints.options.${o.key}`,
					),
				})),
			[t],
		);

		return (
			<Row align="center" gaps="10px*" sizes="auto 1fr">
				<CheckBox
					disabled={disabled}
					label={t(`${tPath}.modal.applyToOrderPoints.title`) || ""}
					value={active}
					onChange={setActive}
				/>
				<MultiSelect
					disabled={disabled}
					placeholder={
						t(`${tPath}.modal.applyToOrderPoints.placeholder`) || ""
					}
					options={tOptions}
					value={points}
					onChange={handlePointsChange}
				/>
			</Row>
		);
	},
);

declare namespace ApplyToOrderPoints {
	interface Props {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		points: AutoRateRuleOrderPoint[];
	}
}

export default ApplyToOrderPoints;
