import React, { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";

interface IncludePickupMarginProps {
	form: UseCreateFormReturn<Schema>;
	disabled: boolean;
}

const IncludePickupMargin: React.FC<IncludePickupMarginProps> = ({
	form,
	disabled,
}) => {
	const { t } = useTranslation();

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const label = useMemo(
		() => t(["orderPage.order_form.is_feed", "Arrival"]),
		[t],
	);

	return (
		<Controller
			name="includePickupMargin"
			control={form.control}
			render={({ field }) => (
				<CheckBox
					disabled={disabled}
					value={Boolean(field.value)}
					onChange={(value) => {
						field.onChange(value);
						handleSave();
					}}
					label={label}
				/>
			)}
		/>
	);
};

export default IncludePickupMargin;
