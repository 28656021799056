import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ALLOWED = "allowed",
	NAME = "name",
	PROVIDER = "provider",
	COMPANIES = "companies",
	BRANCHES = "branches",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.ALLOWED, label: "Allowed", width: 90 },
	{ id: Columns.NAME, label: "Name", width: 320 },
	{ id: Columns.PROVIDER, label: "Payment System Type", width: 320 },
	{ id: Columns.COMPANIES, label: "Companies", width: 220 },
	{ id: Columns.BRANCHES, label: "Branches" },
]; // * label translation is done inside the component through a for

export const defaultColumnsId = columns.map(({ id }) => id);
