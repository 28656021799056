import { LatLngLiteral } from "leaflet";
import { Dispatch, RefObject } from "react";
import { react } from "uikit";

interface Context {
	rootRef: RefObject<HTMLDivElement | null>;
	settlementQuery: string;
	streetQuery: string;
	houseQuery: string;
	coordinates?: LatLngLiteral;

	setSettlementFieldError: Dispatch<boolean>;
	setStreetFieldError: Dispatch<boolean>;
	setHouseFieldError: Dispatch<boolean>;
	setCoordinatesFieldError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		if (
			!this.context?.settlementQuery &&
			!this.context?.streetQuery &&
			!this.context?.houseQuery &&
			!this.context?.coordinates
		) {
			if (!this.context?.settlementQuery)
				this.context?.setSettlementFieldError(true);

			if (!this.context?.streetQuery)
				this.context?.setStreetFieldError(true);

			if (!this.context?.houseQuery)
				this.context?.setHouseFieldError(true);

			if (!this.context?.coordinates)
				this.context?.setCoordinatesFieldError(true);

			this.context?.rootRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});

			return false;
		}

		return true;
	}
}
