import React, { Dispatch, RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, Select, Stepper, react } from "uikit";
import FieldsContainer from "../../../../../../../../../../../../../components/FieldsContainer";

import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import { CheckSmsStatusMode } from "../../../../../../../../../../../../../redux/services/Settings/Message/ProviderSms/enums";
import InternalController from "./Controller";

const CheckMessageDataBase = react.withController<
	CheckMessageData.PropsBase,
	CheckMessageData.Controller
>(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const checkSmsStatusMode = valueEditor.useGetter("checkSmsStatusMode");
	const setCheckSmsStatusMode = valueEditor.useSetter("checkSmsStatusMode");

	const activeQueryInterval = valueEditor.useGetter("activeQueryInterval");
	const setActiveQueryInterval = valueEditor.useSetter("activeQueryInterval");

	return (
		<FieldsContainer
			label={
				t(
					"pages.settings.pages.message.provider.editModal.content.mainTab.checkMessageData.str0",
				) ?? ""
			}
		>
			<Column gaps="20px*">
				<Select
					placeholder={
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.checkMessageData.str1",
						) ?? ""
					}
					style={{ width: "400px" }}
					options={[
						{
							key: CheckSmsStatusMode.PASSIVE,
							value: CheckSmsStatusMode.PASSIVE,
							label: `${
								t(
									"pages.settings.pages.message.provider.editModal.content.mainTab.checkMessageData.str200",
								) ?? ""
							} (deliver_sm) [esm_class=Delivery_receipt]`,
						},
						{
							key: CheckSmsStatusMode.ACTIVE,
							value: CheckSmsStatusMode.ACTIVE,
							label: `${
								t(
									"pages.settings.pages.message.provider.editModal.content.mainTab.checkMessageData.str201",
								) ?? ""
							} (query_sm)`,
						},
					]}
					value={checkSmsStatusMode}
					onSelect={(e) => {
						setCheckSmsStatusMode(e.value);
					}}
				/>
				<Row gaps="10px*" align="center">
					<span>
						{t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.checkMessageData.str100",
						) ?? ""}
					</span>
					<Stepper
						disabled={
							!(checkSmsStatusMode === CheckSmsStatusMode.ACTIVE)
						}
						value={activeQueryInterval}
						onChange={(newValue) => {
							setActiveQueryInterval(newValue as number);
						}}
						min={0}
					/>
					<span>
						{t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.checkMessageData.str101",
						) ?? ""}
					</span>
				</Row>
			</Column>
		</FieldsContainer>
	);
}, InternalController);

const CheckMessageData = memo(CheckMessageDataBase);

declare namespace CheckMessageData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		checkSmsStatusMode: CheckSmsStatusMode;
		activeQueryInterval: number;
	}

	interface PropsBase {
		value: Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CheckMessageData;
