import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import SMSStatusCellContent from "../components/SMSStatusCellContent";
import { MessageTemplateActions } from "../../../../../../types/MessageTemplateEnums";

function smsCarNotFoundStatus({ width, onResize }: Column.Options) {
	return base(
		ColumnId.SMSCarNotFoundStatus,
		(item) => (
			<SMSStatusCellContent
				item={item}
				type={MessageTemplateActions.CAR_NOT_FOUND}
			/>
		),
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default smsCarNotFoundStatus;
