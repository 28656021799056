import React from "react";
import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const DatePopComponent = styled.p`
	padding: 4px 8px;
	margin-bottom: 12px;
	height: 26px;
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: center;
	color: ${theme.colors.secondary};
	background-color: ${theme.colors.white};
	border: 1px solid #f5f5f5;
	border-radius: 20px;
	box-shadow: 0px 2px 4px 0px #0000000f;
`;

const DatePop: React.FC<DatePop.Props> = ({ text }) => {
	const validation = (_text: any): boolean => {
		if (!_text) {
			return false;
		}

		return true;
	};

	if (!validation(text)) return null;

	return (
		<Root>
			<DatePopComponent>{text}</DatePopComponent>
		</Root>
	);
};

declare namespace DatePop {
	export interface Props {
		text: string;
	}
}

export default React.memo(DatePop);
