import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import { languagePackages } from "../assets/languages/langs";

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(Backend)
	.init({
		lng: "dev",
		fallbackLng: "dev",
		debug: false,
		resources: {
			dev: {
				translation: languagePackages.uk.translation,
			},
		},
		preload: false,
		react: {
			useSuspense: false,
			// wait: true,
			// bindStore: false,
		},
		initImmediate: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18next;
