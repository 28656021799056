import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	position: relative;

	width: 100%;
	height: 100%;

	overflow-y: auto;

	${theme.scrollbar}

	background: ${theme.colors.white};
`;

export default Root;
