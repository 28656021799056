import styled from "styled-components";

const Root = styled.div<Root.Props>`
	display: flex;

	align-items: center;
	justify-content: center;

	width: 30px;
	height: 30px;

	border: 1px solid #21333f4d;
	border-radius: 50%;

	background-color: ${({ backgroundColor }) => backgroundColor};

	transform: translate(-50%, -8px);
`;

declare namespace Root {
	interface Props {
		backgroundColor: string;
	}
}

export default Root;
