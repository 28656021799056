import { PaymentProvider } from "../../../../../../../services/PaymentSystem";

import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ALLOWED = "allowed",
	NAME = "name",
	PROVIDER = "provider",
	COMPANIES = "companies",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.ALLOWED, label: "Allowed", width: 90 },
	{ id: Columns.NAME, label: "Name", width: 420 },
	{ id: Columns.PROVIDER, label: "Payment System Type", width: 420 },
	{ id: Columns.COMPANIES, label: "Companies" },
]; // * label translation is done inside the component through a for

export const defaultColumnsId = columns.map(({ id }) => id);

const NOTIFICATION_ACTIONS = [...Object.values(PaymentProvider)];

export const convertActionToString = NOTIFICATION_ACTIONS.reduce(
	(acc, action) => {
		acc[action] = PaymentProvider[action];
		return acc;
	},
	{},
);
