import React, { memo } from "react";
import { TextBox } from "uikit";
import { useTranslation } from "react-i18next";

const Search: React.FC<Search.Props> = memo(({ onChange, value }) => {
	const { t } = useTranslation();

	return (
		<TextBox.TextBox
			placeholder={`${t("search")}...`}
			value={value}
			onChange={onChange}
		/>
	);
});

declare namespace Search {
	interface Props {
		value: string;
		onChange: React.Dispatch<React.SetStateAction<string>>;
	}
}

export default Search;
