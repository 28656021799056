import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { LatLngLiteral } from "leaflet";
import { cloneDeep } from "lodash";
import { Option } from "uikit";

import Map from "../../../../../../services/Map";
import useMapSearch from "../../../../../../hooks/useMapSearch";
import { MapSearch } from "../../../../../Orders";
import { Popup } from "../../../../../common";
import PointOrderModalTableBase from "../..";
import {
	HandleEventsProps,
	useHandleEvents,
	ValueAccessors,
} from "../../hooks";
import cityToString from "../utils/cityToString";
import { StyledInput, StyledPopup } from "../styled";
import { OverlaySettlement } from "../Overlays";

const SettlementBase: React.FC<SettlementBase.Props> = ({
	rowData,
	rowIndex,
	dataKey,
	colIndex,
	setData,
	styles,
	offset,
	customerId,
	height,
	handleEventsOptions,
	containerId,
	addSettlement,
	addSettlementCoordinates,
}) => {
	const {
		inputValue,
		open,
		query,
		save,
		setSave,
		onChangeInputValue,
		onEnterToInput,
		onExistFromInput,
		onClickToInput,
		onFocusToInput,
		onBlurCaptureToInput,
		setColIndex,
		setDataKey,
		setInputId,
		setRowId,
		setRowIndex,
		nextElementById,
		language,
		reset,
		allowType,
		setAllowType,
	} = useHandleEvents(handleEventsOptions);

	const [activeId, setActiveOption] = useState<number>(-1);
	const [result, setResult] = useState<MapSearch.Result>({
		loading: true,
	});
	useLayoutEffect(() => {
		setColIndex(`${colIndex}`);
		setDataKey(dataKey);
		setInputId(`${rowData.raw.id + dataKey}`);
		setRowId(rowData.raw.id);
		setRowIndex(`${rowIndex}`);
		addSettlement(
			rowData.raw as unknown as Map.Search.Object,
			rowData.raw.id,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useMemo(() => {
		if (!rowData.raw.type) setResult({ loading: true });
	}, [rowData]);

	useMemo(() => {
		if (query !== null && allowType === "reset") {
			setResult({ loading: true });
			addSettlement(undefined, rowData.raw.id);
			setAllowType(null);
		}
	}, [addSettlement, allowType, query, rowData.raw.id, setAllowType]);

	const querySearch = useMemo(() => {
		if (allowType === "query" && query && query?.length > 1) {
			setAllowType("reset");
		}
		return query || "";
	}, [allowType, query, setAllowType]);

	const updatedData = useCallback(
		(params: Map.Search.Object) => {
			addSettlement(params, rowData.raw.id);

			const city = cityToString(params);
			const newData = cloneDeep(rowData);
			// base
			newData.city = city;
			newData.type = params.type;
			newData.coordinates = params.coordinates;
			newData.save = true;
			newData.saveType = "city";
			newData.raw.save = true;
			newData.raw.saveType = "city";

			// raw
			newData.raw.type = params.type;
			newData.raw.coordinates = params.coordinates;
			newData.raw.country = params.country;
			newData.raw.countryCode = params.countryCode;
			newData.raw.settlement = params.settlement || "";
			newData.raw.settlementType = params.settlementType || "";

			newData.raw.region = params.region || "";
			newData.raw.district = params.district || "";
			// clear
			newData.entrance = "";
			newData.house = "";
			newData.polygon = "";
			newData.flat = "";
			newData.street = "";
			// clear raw
			newData.raw.customHouse = "";
			newData.raw.customStreet = "";
			newData.raw.entrance = "";
			newData.raw.flat = "";
			newData.raw.house = "";
			newData.raw.name = "";
			newData.raw.street = "";
			newData.raw.streetType = "";
			newData.raw.sector = undefined;
			// reset
			if (
				rowData.raw.settlement === newData.raw.settlement &&
				rowData.raw.settlementType === newData.raw.settlementType
			) {
				if (handleEventsOptions?.nextElement) {
					nextElementById(handleEventsOptions?.nextElement);
				}
				return;
			}

			setData(newData);
			reset();
			if (handleEventsOptions?.nextElement) {
				nextElementById(handleEventsOptions?.nextElement);
			}
		},
		[
			addSettlement,
			handleEventsOptions?.nextElement,
			nextElementById,
			reset,
			rowData,
			setData,
		],
	);

	const openOverlay = useMemo(() => open, [open]);

	const mapSearchOptions = useMemo<MapSearch.Options>(
		() => ({
			language,
			customerId,
			searchType: customerId ? ["settlement", "favorite"] : "settlement",
			country: "ua",
			query: querySearch ?? "",
			lite: true,
		}),
		[language, customerId, querySearch],
	);
	const mapSearchResult = useMapSearch(mapSearchOptions);

	useLayoutEffect(() => {
		if (mapSearchResult.loading) return;

		setResult(mapSearchResult);
	}, [mapSearchResult]);

	useLayoutEffect(() => {
		if (result.loading) return;
		if (result.objects?.length) setActiveOption(0);
	}, [result]);

	useMemo(() => {
		if (!open && save && !rowData.raw.save && activeId === -1) {
			const newData = cloneDeep(rowData);

			newData.save = true;
			newData.saveType = "settlement";
			newData.raw.save = true;
			newData.raw.saveType = "settlement";
			newData.type = "settlement";
			newData.raw.type = "settlement";
			addSettlementCoordinates(newData.raw.coordinates, newData.raw.id);
			setData(newData);
			setSave(false);
		}
	}, [
		open,
		save,
		rowData,
		activeId,
		addSettlementCoordinates,
		setData,
		setSave,
	]);

	const onSelect = useCallback(
		(option: Option<Map.Search.Object>) => {
			if (option) updatedData(option.value);
		},
		[updatedData],
	);

	const propsInput = useMemo(() => {
		const value = `${
			rowData.raw?.type || open ? cityToString(rowData.raw) : ""
		}`;
		return {
			value: inputValue !== null ? inputValue : value,
		};
	}, [inputValue, open, rowData.raw]);

	const placeholder = useMemo(
		() => `${rowData[dataKey] || ""}`,
		[dataKey, rowData],
	);

	const size = useMemo(
		() =>
			propsInput.value.length > 5 ? propsInput.value.length * 1.25 : 5,
		[propsInput.value.length],
	);

	return (
		<StyledPopup
			useClickControl
			useArrow={false}
			open={openOverlay}
			offset={offset}
			styles={styles}
			trackerId={dataKey + rowData.raw.id}
			containerId={containerId}
			tracker={
				<StyledInput
					size={size}
					height={height}
					id={`${rowData.raw.id + dataKey}`}
					tabIndex={-1}
					aria-label={dataKey}
					aria-rowindex={rowIndex}
					aria-colindex={colIndex}
					autoComplete="one-time-code"
					onChange={onChangeInputValue}
					onKeyUp={onEnterToInput}
					onKeyDown={onExistFromInput}
					onClick={onClickToInput}
					onBlurCapture={onBlurCaptureToInput}
					placeholder={placeholder}
					onFocus={onFocusToInput}
					onCut={(event) => {
						event.preventDefault();
					}}
					onDragOver={(event) => {
						event.preventDefault();
					}}
					onDrop={(event) => {
						event.preventDefault();
					}}
					{...propsInput}
				/>
			}
		>
			<OverlaySettlement
				data={result}
				activeId={activeId}
				setActiveOption={setActiveOption}
				onSelect={onSelect}
				query={inputValue || ""}
			/>
		</StyledPopup>
	);
};

declare namespace SettlementBase {
	interface Props extends PropsWithChildren {
		rowData: PointOrderModalTableBase.OrderModal;
		setData: (data: PointOrderModalTableBase.OrderModal) => void;
		reset?: (id: number) => void;
		addSettlement: (
			settlement: Map.Search.Object | undefined,
			id: number,
		) => void;
		addSettlementCoordinates: (
			settlement: LatLngLiteral | undefined,
			id: number,
		) => void;
		rowIndex?: number;
		dataKey: ValueAccessors | string;
		colIndex: number;
		height?: number;
		styles?: Popup.Styles;
		offset?: Popup.Props["offset"];
		customerId?: number;
		handleEventsOptions?: HandleEventsProps;
		containerId?: string;
	}
}

export const Settlement = memo(SettlementBase);

export default SettlementBase;
