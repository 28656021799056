import { clone } from "lodash";

import { PolygonList } from "../../pages/PreferencesPages/components/ScreenDirectory/components/PolygonsEditor/type";
import mapConstants from "../constants/map.constants";

export type DefaultCity = {
	value: string;
	settlementType: string;
	type: string;
	searchType: string;
	localTips: never[];
	addition: {
		lng: number;
		lat: number;
	};
	settlementID: string;
	coordinates: {
		lng: number;
		lat: number;
	};
};

export const defaultPoint = {
	local: {
		value: "",
		addition: null,
		searchType: "address",
	},
	name: {
		value: "",
		addition: [],
		localTips: [],
		searchType: "streets",
		isCoordinates: false,
	},
	city: {
		value: "Киев",
		localTips: [],
		searchType: "settlements",
		addition: { lng: 30.511284, lat: 50.455002 },
		settlementID: "STL1NQ7EP",
	},
	country: {
		value: "Украина",
	},
	coordinates: null,
	nearestObjects: [],
};

const initialState = {
	polygons: [] as PolygonList,
	coordinates: [],
	tips: {
		tips: [],
		index: -1,
		type: "",
		field: "",
	},
	points: {
		start: [
			{
				...defaultPoint,
				coordinates: { lng: 30.511284, lat: 50.455002 },
			},
		],
		localObjects: [defaultPoint],
	},
	nearestObjects: [],
	distance: 0,
	activePoint: 0,
	defaultCountry: "Украина",
	mapLang: "uk",
	defaultCity: {
		value: "Киев",
		settlementType: "місто",
		type: "місто",
		searchType: "settlements",
		localTips: [],
		addition: { lng: 30.511284, lat: 50.455002 },
		settlementID: "STL1NQ7EP",
		coordinates: { lng: 30.511284, lat: 50.455002 },
	} as DefaultCity,
	allLocalObjects: [],
	allLocalObjectsGroups: [],
	listType: "object",
	activeLocalObject: null,
	track: [],
};

export default function initialReducer(state = initialState, action: any = {}) {
	switch (action.type) {
		case mapConstants.polygons.set:
			return {
				...state,
				polygons: action.payload,
			};

		case mapConstants.polygons.add:
			return {
				...state,
				polygons: [...state.polygons, action.payload],
			};

		case mapConstants.polygons.update: {
			const newState = clone(state);

			newState.polygons = clone(newState.polygons);

			const polygonIndex = newState.polygons.findIndex(
				(polygon) => polygon.id === action.id,
			);

			newState.polygons[polygonIndex] = action.payload;

			return newState;
		}

		case mapConstants.polygons.remove: {
			const newState = clone(state);

			newState.polygons = newState.polygons.filter(
				(polygon) => !action.ids.includes(polygon.id),
			);

			return newState;
		}

		case mapConstants.setPoint:
			return {
				...state,
				points: {
					...state.points,
					[action.payload.typePoints]: state.points[
						action.payload.typePoints
					].map((item, i) =>
						i === action.payload.index
							? action.payload.point
							: item,
					),
				},
			};
		case mapConstants.getTrack:
			return {
				...state,
				coordinates: action.payload.coordinates,
				distance: action.payload.distance,
				track: action.payload.track || [],
			};
		case mapConstants.setMapLang:
			return {
				...state,
				mapLang: action.payload,
			};

		case mapConstants.listType:
			return {
				...state,
				listType: action.payload,
			};

		case mapConstants.setActivePoint:
			return {
				...state,
				activePoint: action.payload,
			};
		case mapConstants.setActiveLocalObject:
			return {
				...state,
				activeLocalObject: action.payload,
			};
		case mapConstants.addNewPoint:
			return action.payload.type !== "foundPoint"
				? {
						...state,
						points: {
							...state.points,
							[action.payload.typePoints]: [
								...state.points[action.payload.typePoints],
								{
									...defaultPoint,
									country: { value: state.defaultCountry },
									city: state.defaultCity,
								},
							],
						},
				  }
				: {
						...state,
						points: {
							...state.points,
							[action.payload.typePoints]: [
								...state.points[action.payload.typePoints],
								action.payload.point,
							],
						},
				  };
		case mapConstants.setPosition:
			return {
				...state,
				points: {
					...state.points,
					[action.payload.type]: action.payload.newPoints,
				},
			};

		case mapConstants.setStreets: {
			return {
				...state,
				points: {
					...state.points,
					[action.payload.type]: state.points[
						action.payload.type
					].map((item, i) => {
						if (action.payload.index === i) {
							return {
								...item,
								name: {
									...item.name,
									localTips: action.payload.localTips,
								},
							};
						}
						return item;
					}),
				},
			};
		}
		case mapConstants.setCities: {
			return {
				...state,
				points: {
					...state.points,
					[action.payload.type]: state.points[
						action.payload.type
					].map((item, i) => {
						if (action.payload.index === i) {
							return {
								...item,
								city: {
									...item.city,
									localTips: action.payload.localTips,
								},
							};
						}
						return item;
					}),
				},
			};
		}

		case mapConstants.setFieldData: {
			const { fieldName, data } = action.payload;
			return {
				...state,
				points: {
					...state.points,
					[action.payload.type]: state.points[
						action.payload.type
					].map((item, index) => {
						if (action.payload.index !== index) return item;

						return {
							...item,
							[fieldName]: data,
						};
					}),
				},
			};
		}

		case mapConstants.setNearestObjects: {
			const { nearestObjects, type } = action.payload;
			return {
				...state,
				points: {
					...state.points,
					[type]: state.points[type].map((item, index) => {
						if (action.payload.index !== index) return item;
						return {
							...item,
							nearestObjects,
						};
					}),
				},
				nearestObjects,
			};
		}

		case mapConstants.setCoordinates:
			return {
				...state,
				points: {
					...state.points,
					[action.payload.type]: state.points[
						action.payload.type
					].map((item, i) => {
						if (action.payload.index === i) {
							return {
								...item,
								coordinates: action.payload.coordinates,
							};
						}
						return item;
					}),
				},
			};
		case mapConstants.liveSearch:
			return {
				...state,
				tips: action.payload,
			};
		case mapConstants.setLocalObjects:
			return {
				...state,
				allLocalObjects: action.payload,
			};
		case mapConstants.setLocalObjectsGroups:
			return {
				...state,
				allLocalObjectsGroups: action.payload,
			};
		default:
			return state;
	}
}
