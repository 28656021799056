import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import SMSProvider from "../../../../../../../../../../services/SMSProvider";
import SMSTemplate from "../../../../../../../../../../services/SMSTemplate";
import Message from "../../../../../../../../../../services/Message";
import { StyledRow } from "../../../../../../../../../../components/common";

import { ButtonFooter } from "../../styled";
import Modal from "./components/Modal";

const BaseSendingSMS: React.FC<BaseSendingSMS.Props> = memo(
	({ phones, mainPhone, taxiServiceId, disabled }) => {
		const { t } = useTranslation();
		const { models: providers } = useModelSubscribe({}, SMSProvider);
		const { models: templates } = useModelSubscribe({}, SMSTemplate);
		const [showModal, setShowModal] = useState(false);

		const notOrdersTemplates = useMemo(
			() => templates.filter((t) => !t.orderTemplate),
			[templates],
		);

		const phoneDisabled = useMemo(() => !mainPhone, [mainPhone]);

		const handleShowModal = useCallback(() => {
			setShowModal(true);
		}, []);

		const handleCloseModal = useCallback(() => {
			setShowModal(false);
		}, []);

		const handleSend = useCallback(async (value: Message.Send) => {
			const res = await Message.send(value);
			if (res) {
				setShowModal(false);
			}
		}, []);

		return (
			<StyledRow>
				<ButtonFooter
					disabled={disabled || phoneDisabled}
					variant="secondary"
					// settingsKeyId="copyOrder"
					title={
						t(
							"pages.mainPage.pages.orders.orderModal.footer.sendingSMS.str0",
						) ?? ""
					}
					onClick={handleShowModal}
				>
					<Icon id="orders-sms" size={22} />
				</ButtonFooter>
				{showModal && (
					<Modal
						mainPhone={mainPhone}
						phones={phones}
						providers={providers}
						taxiServiceId={taxiServiceId}
						templates={notOrdersTemplates}
						onClose={handleCloseModal}
						onSubmit={handleSend}
					/>
				)}
			</StyledRow>
		);
	},
);

declare namespace BaseSendingSMS {
	interface Props extends PropsWithChildren {
		taxiServiceId?: number;
		mainPhone?: string;
		phones: string[];
		disabled?: boolean;
	}
}

export const SendingSMS = memo(BaseSendingSMS);
export default BaseSendingSMS;
