import React, { memo, useMemo } from "react";

import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../../../redux/store";
import CarClass from "../../../../../../../../../../../../../../../../../../../../services/CarClass";
import mapByKey from "../../../../../../../../../../../../../../../../../../../../utils/mapByKey";
import { MultiSelectCarClassWithModalBase } from "../../../../../../../../../../../../../../../../../../../../components/common";

/** @description use `MultiSelectCarClassWithModal` */
const AutoClassesOutput: React.FC<AutoClassesOutput.Props> = memo(
	({ active, value, autoClasses }) => {
		const lang = useTypedSelector((state) => state.session.language);
		const modelItemById = useMemo(
			() => mapByKey(autoClasses, "id"),
			[autoClasses],
		);

		return active && value.length ? (
			<>
				<div>
					{value
						?.map((id) => modelItemById?.[id]?.name?.[lang || ""])
						.join(", ")}
				</div>
				<span>/</span>
			</>
		) : null;
	},
);
/** @description use `MultiSelectCarClassWithModal` */
declare namespace AutoClassesOutput {
	interface Props {
		active: boolean;
		value: MultiSelectCarClassWithModalBase.Value;
		autoClasses: CarClass.Model[];
	}
}
/** @description use `MultiSelectCarClassWithModal` */
export default AutoClassesOutput;
