import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { Option } from "uikit";

import Map from "../../../../../../../services/Map";
import useKeyBind from "../../../../../../../hooks/useKeyBind";
import { TextMemo, Text } from "../../../../../../common";
import { IField, StyledOptionList, StyledTip } from "../../styled";

const OverlayHouseBase: React.FC<OverlayHouseBase.Props> = ({
	data,
	textSelectOnSelect,
	activeId,
	setActiveOption,
	houseQuery,
}) => {
	const fieldRef = React.useRef<IField | null>(null);
	const styled = useMemo<Pick<Text.Props, "textStyle" | "valueStyle">>(
		() => ({
			textStyle: {
				h: "32px",
				w: "100%",
				colors: "#21333f",
				font: { fw: "400", size: "14px", line: "18px" },
				text: { tt: "uppercase" },
				cursor: "pointer",
			},
		}),
		[],
	);

	const modifyData = useMemo(() => {
		const payload = [...(data?.houses || [])];
		if (houseQuery) {
			const exist = payload.find((item) => item.number === houseQuery);
			if (!exist) {
				setActiveOption(0);
				payload?.unshift({ id: "query", number: houseQuery });
			}
		}
		return payload;
	}, [data?.houses, houseQuery, setActiveOption]);

	const options = useMemo<Option<string>[] | undefined>(
		() =>
			modifyData
				?.reduce((accumulator, house) => {
					if (
						house.number === houseQuery &&
						!accumulator.some(
							(filteredHouse) =>
								filteredHouse.number === house.number,
						)
					) {
						accumulator.unshift(house);
					} else if (
						house.number.includes(houseQuery ?? "") &&
						!accumulator.some(
							(filteredHouse) =>
								filteredHouse.number === house.number,
						)
					) {
						accumulator.push(house);
					}

					return accumulator;
				}, [] as Map.Search.House[])
				?.map((option) => ({
					key: option.id,
					label: option.number,
					value: option.number,
				})) || [],

		[modifyData, houseQuery],
	);

	const onClick = useCallback(
		(option: Option<string>) => {
			textSelectOnSelect(option);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useLayoutEffect(() => {
		if (fieldRef.current) {
			fieldRef.current?.scrollIntoView({
				behavior: "smooth",
				inline: "nearest",
				block: "nearest",
			});
		}
	}, [activeId, fieldRef]);

	useKeyBind(["ArrowUp"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev - 1 < 0 ? items - 1 : prev - 1;
		});
	});

	useKeyBind(["ArrowDown"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev + 1 > items - 1 ? 0 : prev + 1;
		});
	});

	useKeyBind(["Enter"], () => {
		if (options) onClick(options[activeId]);
	});

	useKeyBind(["Tab"], (event) => {
		if (options) onClick(options[activeId]);
		event.stopPropagation();
		event.preventDefault();
	});

	return (
		<StyledOptionList>
			{options?.map((option, i) => (
				<StyledTip
					ref={activeId === i ? fieldRef : null}
					key={`${i + 1}`}
					isActive={activeId === i}
					onClick={(event) => {
						onClick(option);
						event?.stopPropagation?.();
						event?.preventDefault?.();
						setActiveOption(i);
					}}
				>
					<TextMemo
						{...styled}
						text={option.label}
						onClick={(event) => {
							onClick(option);
							event?.stopPropagation?.();
							event?.preventDefault?.();
							setActiveOption(i);
						}}
					/>
				</StyledTip>
			))}
		</StyledOptionList>
	);
};

declare namespace OverlayHouseBase {
	interface Props {
		houseQuery: string;
		data: Map.Search.Object | undefined;
		textSelectOnSelect: (option: Option<string>) => void;
		activeId: number;
		setActiveOption: Dispatch<SetStateAction<number>>;
		debounce?: number;
	}
}

export const OverlayHouse = memo(OverlayHouseBase);
export default OverlayHouseBase;
