import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	border-top-left-radius: 5px;

	overflow: hidden;
`;

export default Root;
