import { TFunction } from "i18next";
import moment from "moment";

const timestampToDate = (t: TFunction, isoString: string) => {
	const targetMoment = moment(isoString);

	const today = moment();

	const diffInDays = today.diff(targetMoment, "days");

	if (diffInDays === 0) {
		return t("dates.today") ?? "Today";
	}
	if (diffInDays === 1) {
		return t("dates.yesterday") ?? "Yesterday";
	}
	return targetMoment.format("DD MMM YYYY");
};

export default timestampToDate;
