import React, { ReactNode, memo } from "react";
import { useReactiveRef, useSize } from "uikit";

const StaticWidth: React.FC<StaticWidth.Props> = memo(({ children }) => {
	const [ref, setRef] = useReactiveRef(null);
	const size = useSize(ref.current);

	return (
		<div style={size}>
			<div style={{ width: "min-content" }} ref={setRef}>
				{children}
			</div>
		</div>
	);
});

declare namespace StaticWidth {
	interface Props {
		children: ReactNode;
	}
}

export default StaticWidth;
