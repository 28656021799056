/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { RefAttributes, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, RadioButton, Row, react, useRefWithSetter } from "uikit";
import { cloneDeep, isBoolean } from "lodash";
import * as yup from "yup";

import Dispatcher from "../../../../../../../services/Dispatcher";
import Language from "../../../../../../../services/Language";

import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import { useCreateForm } from "../../../../../../../hooks/useCreateForm";
import { useTypedSelector } from "../../../../../../../redux/store";
import {
	CheckSmsStatusMode,
	SmsProviderType,
} from "../../../../../../../redux/services/Settings/Message/ProviderSms/enums";

import {
	FieldValidation,
	validateFields,
} from "../../../../../../../utils/validateFields";
import WithoutNullableKeys from "../../../../../../../types/WithoutNullableKeys";
import EditModalBase from "../../../../../../../components/EditModal";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import InternalController from "./Controller";

export interface ProviderSchema {
	taxiServiceIds: number[];
	name: string;
	host: string;
	port: number;
	login: string;
	password: string;
	alphaName: string;

	active: boolean;
	isSystemType: boolean;
	systemType: string;
	sourceAddrTon: string;
	sourceAddrNpi: string;
	destAddrTon: string;
	destAddrNpi: string;

	checkSmsStatusMode: CheckSmsStatusMode;
	activeQueryInterval: number;
	isEnquireLink: boolean;
	enquireLink: number;
	addPlus: boolean;
	type: SmsProviderType;
	isMaxSmsPerSecond: boolean;
	maxSmsPerSecond: number;
}

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(
	({
		controller,
		value,
		language,
		onCancel,
		onSave,
		setValidation,
		validation,
		headerTitle,
	}) => {
		const { t } = useTranslation();
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);

		const [internalValue, setInternalValue] = useState(value);

		const schema = {
			taxiServiceIds: yup.array().of(yup.number()),
			name: yup.string(),
			host: yup.string(),
			port: yup.string(),
			login: yup.string(),
			password: yup.string(),
			alphaName: yup.string(),

			active: yup.boolean(),
			isSystemType: yup.boolean(),
			systemType: yup.string(),
			sourceAddrTon: yup.string(),
			sourceAddrNpi: yup.string(),
			destAddrTon: yup.string(),
			destAddrNpi: yup.string(),

			checkStatusMode: yup.string().required(),
			isEnquireLink: yup.boolean(),
			enquireLink: yup.number(),
			addPlus: yup.boolean(),
			isMaxSmsPerSecond: yup.boolean(),
			maxSmsPerSecond: yup.number(),
		};

		const useForm = (values: ProviderSchema | undefined) =>
			useCreateForm<ProviderSchema>({
				yupObject: schema,
				values,
			});

		const form = useForm(cloneDeep(internalValue as any));

		const handleSubmit = async () => {
			const values = form.getValues();
			const validations: FieldValidation[] = [
				{ fieldName: "taxiServiceIds", required: true },
				{ fieldName: "name", required: true },
				{ fieldName: "host", required: true },
				{ fieldName: "port", required: true },
				{ fieldName: "login", required: true },
				{ fieldName: "password", required: true },
				{ fieldName: "alphaName", required: true },
			];

			const data: { [key: string]: any } = {
				taxiServiceIds: values?.taxiServiceIds,
				name: values?.name || "",
				type: values?.type,
				host: values?.host || "",
				port: values?.port || "",
				login: values.login,
				password: values.password,
				alphaName: values.alphaName,

				active: values.active,
				isSystemType: values?.isSystemType,
				systemType: values?.systemType || "",
				sourceAddrTon: values.sourceAddrTon,
				sourceAddrNpi: values.sourceAddrNpi,
				destAddrTon: values.destAddrTon,
				destAddrNpi: values.destAddrNpi,

				checkSmsStatusMode: values.checkSmsStatusMode,
				isEnquireLink: values.isEnquireLink,
				enquireLink: values.enquireLink * 1000,
				activeQueryInterval: values.activeQueryInterval * 1000,
				addPlus: values.addPlus,

				isMaxSmsPerSecond: values.isMaxSmsPerSecond,
				maxSmsPerSecond: values.maxSmsPerSecond,
			};

			const isValid = validateFields(data, validations);

			if (isBoolean(isValid)) {
				onSave(data);
			} else {
				setValidation(isValid);
			}
		};

		const currentIP =
			useTypedSelector((state) => state.currentIP.value) ?? "";

		const [history, setHistory] = useState<Dispatcher.History>([]);

		useEffect(() => {
			(async () => {
				if (value.id) setHistory(await Dispatcher.getHistory(value.id));
			})();
		}, [value.id]);

		controller.setContext({ contentRef });

		const [tab, setTab] = useState<Header.Tab>("main");
		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const contentOnChange = valueEditor.useAssigner();

		const activeValue = valueEditor.useGetter("active");
		const activeOnChange = valueEditor.useSetter("active");

		const type = valueEditor.useGetter("type");
		const setType = valueEditor.useSetter("type");

		const modalContent = useMemo(
			() => (
				<Root sizes="auto! 1fr">
					<Header
						tab={tab}
						createdAt={value.createdAt}
						onChangeTab={setTab}
						headerTitle={headerTitle}
					/>
					<Content
						ref={setContentRef}
						value={internalValue}
						type={tab}
						language={language}
						currentIP={currentIP}
						onChange={contentOnChange}
						validation={validation}
					/>
				</Root>
			),
			[
				contentOnChange,
				currentIP,
				history,
				internalValue,
				language,
				setContentRef,
				tab,
				value.createdAt,
				value,
				validation,
			],
		);

		return (
			<EditModalBase
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Row gaps="60px*" align="center">
						<CheckBox
							label={
								t(
									"pages.settings.pages.message.provider.editModal.str0",
								) ?? ""
							}
							value={activeValue}
							onChange={activeOnChange}
						/>
						<Row align="center" gaps="20px">
							<RadioButton.RadioButton
								label={
									t(
										"pages.settings.pages.message.provider.editModal.str1",
									) ?? ""
								}
								selected={type === SmsProviderType.MAIN}
								onClick={() => {
									if (type === SmsProviderType.RESERVE) {
										setType(SmsProviderType.MAIN);
									}
								}}
							/>
							<RadioButton.RadioButton
								label={
									t(
										"pages.settings.pages.message.provider.editModal.str2",
									) ?? ""
								}
								selected={type === SmsProviderType.RESERVE}
								onClick={() => {
									if (type === SmsProviderType.MAIN) {
										setType(SmsProviderType.RESERVE);
									}
								}}
							/>
						</Row>
					</Row>
				}
			>
				{modalContent}
			</EditModalBase>
		);
	},
	InternalController,
);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value {
		id?: number;
		active: boolean;
		type: SmsProviderType;

		createdAt?: string;
	}

	namespace Value {
		type ValidationPropertyNames = "name";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, ValidationPropertyNames>>>;
	}

	interface PropsBase {
		value: Value;
		language: Language;
		validation: any;
		headerTitle: string;

		onCancel: () => void;
		onSave: (value: any) => void;
		// onSave: (value: Value.Validated) => void;
		setValidation: (value: any) => any;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
