import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LabeledContent from "../LabeledContent";
import { useTypedSelector } from "../../../../../../redux/store";
import Person from "../../../../../../services/Person";

const User = memo(() => {
	const { t } = useTranslation();

	const user = useTypedSelector((state) => state.account.user);

	const userName = useMemo(
		() =>
			user?.person
				? Person.getFullName(Person.fromResponse(user.person))
				: "-",
		[user],
	);

	return (
		<LabeledContent width={180} label={t("main_header.user")}>
			{userName}
		</LabeledContent>
	);
});

export default User;
