/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import Executor from "../../../../../services/Executor";
import { Orders } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	executors: [],
};

const setParkingsData: ReduxReducer<State, Executor.Model[]> = (
	state,
	action,
) => {
	state.executors = action.payload;
};

const orderParkingData = createSlice({
	name: Orders.ConstantOrderSlice.PARKING_DATA,
	initialState,
	reducers: {
		setParkingsData,
	},
});

export const orderParkingDataAction = orderParkingData.actions;
export const orderParkingDataReducer = orderParkingData.reducer;
export type orderParkingDataState = State;

export default orderParkingData;
