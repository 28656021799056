import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { cloneDeep, isEqual, isNumber } from "lodash";
import { useTranslation } from "react-i18next";

import CarClass from "../../../../../../services/CarClass";
import Language from "../../../../../../services/Language";
import { useTypedDispatch } from "../../../../../../redux/store";
import getCompanies from "../../../../../../redux/services/Company/getCompanies";
import getClasses from "../../../../../../redux/services/Preferences/CarClass/getClasses";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import Combine from "../../../../../../types/Combine";
import mapByKey from "../../../../../../utils/mapByKey";
import DeleteModal from "../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../components/LightTable";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../components/common";

import defaultValue from "./constants/defaultValue";
import translationPath from "./constants/translationPath";
import { ErrorResponse, getErrorByMessage } from "./constants/errors";
import getDeleteModalTitle from "./constants/getDeleteModalTitle";
import Content from "./components/Content";
import Header from "./components/Header";
import ClassModal from "./components/Modal";

const CarClasses: React.FC = () => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const {
		editor,
		onChange,
		setDataLength,
		dataLength,
		limit,
		lang,
		sort,
		// filter,
		query,
		setQuery,
	} = useTableOptions();

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [requestError, setRequestError] = useState<ErrorResponse | null>(
		null,
	);
	const [isSave, setIsSave] = useState<boolean>(false);
	const [preparedData, setPreparedData] = useState<ClassModal.Value | null>(
		null,
	);

	const [selected, setSelected] = useState<number[]>([]);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const [actualDefaultValue] = useState(defaultValue);
	const [editingItem, setEditingItem] = useState<any>(actualDefaultValue);
	const [languageFilter, setLanguageFilter] = useState<Language>("uk");

	const [filter, setFilter] = useState({
		companyIds: [-1],
		taxiServices: [],
		allCompanies: [],
		allTaxiServices: [],
	});

	const options = useMemo(() => {
		const order = {};
		const payload: CarClass.SubscribeOptions = {
			query,
			limit,
			language: languageFilter,
			// filter
		};

		if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, [languageFilter, limit, query, sort.dataKey, sort.sortType]);

	const { models } = useModelSubscribe(options, CarClass);

	const modelItems = useMemo(() => models ?? [], [models]);
	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);
	useLayoutEffect(() => {
		if (dataLength !== modelItems.length) {
			setDataLength(modelItems.length);
		}
	}, [modelItems.length, dataLength, setDataLength]);

	useEffect(() => {
		dispatch(getClasses());
		dispatch(getCompanies());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const defaultPropsToGetRequest = {
		sortType: "DESC",
		limit: 100,
		taxiServiceIds:
			filter.taxiServices.length && filter.taxiServices[0] !== -1
				? filter.taxiServices
				: filter.allTaxiServices,
	};

	useEffect(() => {
		dispatch(getClasses(defaultPropsToGetRequest));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (selected.length === 1) {
			const model = modelItems.find(
				(carClass) => carClass.id === selected[0],
			);
			const classPosition = modelItems.findIndex(
				(carClass) => carClass.id === selected[0],
			);

			if (model) setEditingItem(model);
			else setEditingItem(actualDefaultValue);
			if (isNumber(classPosition)) setSelectedIndex(classPosition);
			else setSelectedIndex(null);
		} else if (!selected.length) {
			setSelectedIndex(null);
			setEditingItem(actualDefaultValue);
		} else if (selected.length > 1) {
			setSelectedIndex(null);
		}
	}, [selected, actualDefaultValue, modelItems]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem(cloneDeep(item));
			setShowModal(true);
			setSelected([item.id]);
		},
		[modelItemById],
	);

	const editContentHandler = useCallback(
		(item: CarClass.Model.Modified) => edit(item.id),
		[edit],
	);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		async (model: ClassModal.Value, force = false) => {
			if (isEqual(editingItem, model)) {
				setEditingItem(actualDefaultValue);
				setShowModal(false);
				return;
			}
			let isAllOk = true;
			if (isSave) return;
			setIsSave(true);

			const prepareData: Combine<
				CarClass.Model.Modified,
				CarClass.Model.New
			> = {
				id: model.id,

				taxiServiceIds: model.taxiServiceIds,
				compatibleCarClassIds: model.compatibleCarClassIds,
				compatibleCarClassIdsToBroadcastable:
					model.compatibleCarClassIdsToBroadcastable,
				serviceAvailableIds: model.serviceAvailableIds,
				serviceDefaultIds: model.serviceDefaultIds,

				name: model.name,
				shortName: model.shortName,
				active: model.active,
				default: model.default,

				useBackgroundColor: model.useBackgroundColor,
				backgroundColor: model.backgroundColor,

				useTextColor: model.useTextColor,
				textColor: model.textColor,

				priority: model.priority,

				distributable: model.distributable,
				broadcastable: model.broadcastable,

				availableForOnlineOrdering: model.availableForOnlineOrdering,

				distributionCompatibleMode: model.distributionCompatibleMode,
				broadcastingCompatibleMode: model.broadcastingCompatibleMode,
			};

			if (prepareData.id) {
				const res = await CarClass.update(
					prepareData as CarClass.Model.Modified,
					force,
				);
				isAllOk = !(res?.error || res === null);
				if (res.error) {
					const { message } = res.data;
					setRequestError(getErrorByMessage(message, true));
				}
			} else {
				const res = await CarClass.store(
					prepareData as CarClass.Model.New,
					force,
				);

				isAllOk = !(res?.error || res === null);

				if (res.error) {
					const { message } = res.data;
					setRequestError(getErrorByMessage(message, false));
				}
			}
			setIsSave(false);

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(actualDefaultValue);
			} else {
				setPreparedData(model);
				setShowErrorModal(true);
			}
		},
		[actualDefaultValue, editingItem, isSave],
	);

	// --- Delete modal start---
	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const deleteHandler = useCallback(async () => {
		await CarClass.destroy(selected, true);
		setShowDeleteModal(false);
		setSelected([]);
	}, [selected]);
	// --- Delete modal end---

	// --- Header start ---
	const headerOnAdd = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);
	const headerCanDelete = useMemo(() => {
		if (!selected.length) return false;
		if (selected.length > 1) return false;
		const selectId = selected[0];
		const exist = modelItemById[selectId];
		if (exist.root) return false;
		return !exist.default;
	}, [modelItemById, selected]);
	// ---- Header end ---

	const title = useMemo(() => {
		if (editingItem.id) {
			return `${t(`${translationPath}.modal.title.edit`)} "${
				editingItem.name?.[lang]
			}"`;
		}
		return t(`${translationPath}.modal.title.add`);
	}, [editingItem.id, editingItem.name, lang, t]);

	const errorModalLabel = useMemo(() => {
		if (!requestError) return "";
		return t([requestError.translation, requestError.baseText]);
	}, [t, requestError]);

	const areas = useMemo(() => `'header' 'main'`, []);
	return (
		<StyledGrid areas={areas} rows="70px 1fr" w="100%" h="100%">
			<StyledGridItem area="header">
				<Header
					languageFilter={languageFilter}
					setLanguageFilter={setLanguageFilter}
					filter={filter}
					setFilter={setFilter}
					setSelected={setSelected}
					selected={selected}
					selectedIndex={selectedIndex}
					query={query}
					onChangeQuery={setQuery}
					models={modelItems}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
			</StyledGridItem>
			<StyledGridItem area="main">
				<Content
					languageFilter={languageFilter}
					onEdit={editContentHandler}
					data={modelItems}
					selected={selected}
					setSelected={setSelected}
					editorTable={editor}
					onChangeTable={onChange}
				/>
			</StyledGridItem>
			{showModal && (
				<ClassModal
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					title={title}
					language={lang}
					loading={isSave}
					companyIds={
						filter.companyIds[0] === -1
							? filter.allCompanies
							: filter.companyIds
					}
					taxiServices={
						filter.taxiServices.length
							? filter.taxiServices
							: filter.allTaxiServices
					}
					classModels={modelItems}
				/>
			)}

			{showDeleteModal && (
				<DeleteModal
					label={getDeleteModalTitle(selected.length, t)}
					onCancel={cancelDelete}
					onConfirm={deleteHandler}
				/>
			)}
			{showErrorModal && (
				<DeleteModal
					label={errorModalLabel}
					onCancel={() => {
						setShowErrorModal(false);
						setPreparedData(null);
						setRequestError(null);
					}}
					isConfirm
					confirmButtonLabel={
						requestError?.canRetry
							? t(
									"pages.preferencesPages.screenDirectory.carClass.str200",
							  ) ?? ""
							: t(
									"pages.preferencesPages.screenDirectory.carClass.str201",
							  ) ?? ""
					}
					onConfirm={() => {
						if (requestError?.canRetry && preparedData) {
							saveHandler(preparedData, true);
						}
						setShowErrorModal(false);
						setRequestError(null);
						setIsSave(false);
					}}
				/>
			)}
		</StyledGrid>
	);
};

export default CarClasses;
