import React from "react";
import { theme } from "uikit";
import styled from "styled-components";

import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../../../../../../common";

// const Root = styled(StyledRow)<{ bgColor?: boolean }>`
const ButtonParent = styled.button<{ bgColor?: boolean }>`
	position: relative;

	width: 100%;

	padding: 2px 10px 2px 8px;

	overflow: hidden;
	text-overflow: ellipsis;
	flex-wrap: nowrap;

	border-radius: 2px 4px 4px 2px;
	background-color: ${({ bgColor }) => (bgColor ? "#0792d5" : "#0000000f")};

	::before {
		position: absolute;

		content: "";
		width: 2px;
		height: 100%;

		top: 0;
		left: 0;

		border-radius: 2px 0px 0px 2px;

		background-color: ${({ bgColor }) =>
			bgColor ? "#002747" : theme.colors.accent};
	}
`;

const Text = styled.div<{ bgColor?: boolean }>`
	font-family: Lato;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0px;
	text-align: left;
	color: ${({ bgColor }) => (bgColor ? "#ffffff" : theme.colors.primary)};

	display: inline;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Title = styled.div<{ bgColor?: boolean }>`
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: ${({ bgColor }) => (bgColor ? "#ffffff" : theme.colors.accent)};

	display: inline;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Reference: React.FC<ReferenceProps.Props> = ({
	data,
	onClickFindParent,
}) => {
	const { title, text, isCurrentUser, parentId } = data;
	return (
		<ButtonParent
			bgColor={isCurrentUser}
			onClick={() => onClickFindParent && onClickFindParent(parentId)}
		>
			<StyledColumn
				style={{
					textOverflow: "ellipsis",
					display: "grid",
					maxWidth: "100%",
				}}
			>
				<Title bgColor={isCurrentUser}>{title}</Title>
				<Text bgColor={isCurrentUser}>{text}</Text>
			</StyledColumn>
		</ButtonParent>
	);
};

declare namespace ReferenceProps {
	interface Props {
		data: {
			title: string;
			text: string;
			isCurrentUser: boolean;
			parentId: number;
		};
		onClickFindParent?: (parentId: number) => void;
	}
}

export default Reference;
