import Dispatcher from "../../../../services/Dispatcher";
import Executor from "../../../../services/Executor";

const convertDataToFullname = (executor: Dispatcher.Model | Executor.Model) => {
	const lastName = executor.person?.lastName ? executor.person?.lastName : "";
	const firstName = executor.person?.firstName
		? executor.person?.firstName
		: "";
	const fatherName = executor.person?.fatherName
		? executor.person?.fatherName
		: "";

	return `${lastName} ${firstName} ${fatherName}`.trim();
};

// eslint-disable-next-line import/prefer-default-export
export { convertDataToFullname };
