import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";

import GeneralTariff from "../../../../../../../../services/GeneralTariff";
import CarClass from "../../../../../../../../services/CarClass";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../components/EditModal";
import tPath from "../../constants/tPath";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import { GeneralTariffTabs as Tabs } from "./constants/tabs";

const Modal: React.FC<Modal.Props> = memo(
	({ carClasses, value, onCancel, onSave, headerTitle }) => {
		const { t } = useTranslation();

		const [tab, setTab] = useState<Tabs>(Tabs.GENERAL_SURCHARGES);

		const [internalValue, setInternalValue] = useState(value);
		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const setCarClassIds = valueEditor.useSetter("carClassIds");

		useLayoutEffect(() => {
			setCarClassIds(value.carClassIds);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value.carClassIds]);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const contentValues = valueEditor.usePicker(
			[
				"name",
				"taxiServices",
				"additionalFields",
				"taxiServiceIds",
				"carClassIds",
			],
			"json",
		);
		const contentOnChange = valueEditor.useAssigner();

		const handleSubmit = useCallback(() => {
			onSave(internalValue);
		}, [internalValue, onSave]);

		return (
			<EditModal
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<Row gaps="25px*" align="center">
						<CheckBox
							label={t(`${tPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
					</Row>
				}
			>
				<Root sizes="auto! 1fr">
					<Header
						title={headerTitle}
						createdAt={value.createdAt}
						tab={tab}
						setTab={setTab}
					/>
					<Content
						autoClasses={carClasses}
						tab={tab}
						value={contentValues}
						onChange={contentOnChange}
					/>
				</Root>
			</EditModal>
		);
	},
);

declare namespace Modal {
	interface Props {
		carClasses: CarClass.Model[];
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (
			tariff: GeneralTariff.New | GeneralTariff.Modified,
		) => Promise<void>;
	}

	interface Value extends Content.Value, Partial<NonEditableProperties> {
		active: boolean;
	}
}

export default Modal;
