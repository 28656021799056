import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

interface Options {
	callsign: string;
	callback: (data: any) => void;
}

export default function getExecutorByCallSign(options: Options) {
	return async () => {
		const prpcow = getPRPC();
		if (!prpcow) return;

		const data = await createRPCQuery(() =>
			prpcow?.theirsModel.executor.getByCallSign(options.callsign),
		);

		options.callback(data);
	};
}
