import React, { Dispatch, memo } from "react";
import Root from "./components/Root";
import Header from "../Header";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { SystemTabs } from "../../constants/tabs";
import Main from "./tabs/Main";

const Content: React.FC<Content.Props> = memo(({ value, onChange, tab }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const updateAllData = valueEditor.useAssigner();

	const getActualTab = (currentTab: Header.TabType) => {
		switch (currentTab) {
			case SystemTabs.MAIN:
				return <Main value={[]} onChange={updateAllData} />;

			default:
				return <></>;
		}
	};
	return <Root>{getActualTab(tab)}</Root>;
});

declare namespace Content {
	interface Props {
		value: any;
		onChange: Dispatch<any>;
		tab: Header.TabType;
	}
	interface Value {}
}

export default Content;
