import styled from "styled-components";
import { theme } from "uikit";

const Title = styled.div`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;

	color: ${theme.colors.primary};
`;

export default Title;
