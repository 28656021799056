import styled from "styled-components";
import { theme } from "uikit";

import { Popup, StyledRow } from "../../../../common";

export const StyledPopup = styled(Popup)`
	min-width: 50px;
	min-height: 0px;
	max-height: 100px;

	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}
`;

export const StyledInput = styled.input<{ height?: number }>`
	width: 100%;
	height: ${({ height = 28 }) => `calc(${height}px - 2px)`};
	position: relative;
	padding: 0 6px;
	border-radius: 0px;
	text-anchor: start;
	font-weight: 400;
	font-size: 12px;
	line-height: 14.4px;
	text-transform: uppercase;

	background-color: transparent;
	color: #21333f;
	::placeholder {
		text-transform: none;
		color: #9ba3a8;
		font-weight: 300;
		font-size: 13px;
		text-transform: none;
		line-height: 18px;
		height: 25px;
	}
	:focus,
	:active,
	:target {
		background-color: #ffffff;
		border-radius: 0px;
		border: 1px solid #03a9f4;
	}
`;

export interface ITipProps {
	isActive: boolean;
}
export interface IField extends HTMLDivElement {
	scrollIntoViewIfNeeded?: any;
}

export const StyledOptionList = styled.div`
	display: flex;
	border-radius: 5px;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: overlay;
	width: 100%;

	&::-webkit-scrollbar {
		width: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${`${theme.colors.primary}33`};
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
`;

export const StyledTip = styled(StyledRow)<ITipProps>`
	padding: 4px 7px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);

	&:hover {
		background: #ebebeb;
	}
	${(props) => (props.isActive ? `background: #EBEBEB;` : "")}
`;
