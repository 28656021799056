import React, { PropsWithChildren } from "react";
import { Overlayer } from "uikit";
import Root from "./components/Root";
import Content from "./components/Content";

const CarMarker: React.FC<CarMarker.Props> = ({
	focused,
	mainColor,
	secondaryColor,

	alias,
}) => (
	<Root focused={focused}>
		<Overlayer
			overlay={
				<Content
					mainColor={mainColor}
					secondaryColor={secondaryColor}
					alias={alias}
				/>
			}
		/>
	</Root>
);

declare namespace CarMarker {
	interface Props extends PropsWithChildren {
		focused: boolean;
		mainColor: string;
		secondaryColor: string;

		alias: string;
	}
}

export default CarMarker;
