import React, { useMemo } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";

import General from "./tabs/General";

type Key = string | number;
interface Option<T> {
	key: Key;
	label: string;
	value: T;
}
interface Tab {
	render: () => React.ReactNode;
}

const OrderExchange = () => {
	const { t } = useTranslation();

	const tabs = useMemo<Option<Tab>[]>(
		() => [
			{
				key: "general",
				label: t("settings.orderExchange.general.title"),
				value: {
					render: () => <General />,
				},
			},
		],
		[t],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={(newActive) => {
						setActiveKey(newActive);
					}}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default OrderExchange;
