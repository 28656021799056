import { ClickAwayListener } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Root from "./components/Root";
import SipTable from "./components/SipTable";
import SIP from "../../../../../../services/SIP";
import SIPToDispatcher from "../../../../../../services/SIPToDispatcher";
import { useTypedSelector } from "../../../../../../redux/store";
import Sip from "./components/Sip";
import softphoneWidget, {
	CallType,
} from "../../../../../../redux/reducers/softphoneWidget";
import Loader from "../../../../../Loader";
import LoaderContainer from "./components/LoaderContainer";

interface Props {
	onCall: (callType: CallType, sipValue?: string) => Promise<null>;
}

const SipList: React.FC<Props> = ({ onCall }) => {
	const dispatch = useDispatch();

	const { selectedTransfer } = useTypedSelector(
		(state) => state.softphoneWidget,
	);
	const { sipValue: userSipValue } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const [sips, setSips] = useState<SIP.Model.Available[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const getSips = useCallback(async () => {
		if (selectedTransfer) {
			const sipsData = await SIPToDispatcher.getAvailable({
				dispatcherId: selectedTransfer?.id,
				status: "ALL",
			});
			const filtredSipsData = sipsData.filter(
				(sip) => sip.sipValue !== userSipValue,
			);
			setIsLoading(false);
			setSips(filtredSipsData);
		}
	}, [selectedTransfer, userSipValue]);

	const closeTransferSipSelector = useCallback(() => {
		dispatch(softphoneWidget.actions.setIsShowTransferSelector(false));
	}, [dispatch]);

	useEffect(() => {
		getSips();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const callOnSip = useCallback(
		async (sipValue: string) => {
			await onCall("onSip", sipValue);
			closeTransferSipSelector();
		},
		[closeTransferSipSelector, onCall],
	);

	return (
		<ClickAwayListener onClickAway={closeTransferSipSelector}>
			<Root>
				{isLoading && (
					<LoaderContainer>
						<Loader dimmed={false} active />
					</LoaderContainer>
				)}
				{!isLoading && (
					<SipTable>
						{sips.map((sip) => (
							<Sip
								key={sip?.sipValue}
								data={sip}
								onSubmit={callOnSip}
							/>
						))}
					</SipTable>
				)}
			</Root>
		</ClickAwayListener>
	);
};

export default SipList;
