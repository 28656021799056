import React, { memo, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { theme, ToggleButton } from "uikit";

import FieldsContainer from "../../FieldsContainer";

const FieldsContainerWithToggleBase: React.FC<
	FieldsContainerWithToggleBase.Props
> = ({ label, children, disabled, value, onChange, maxedWidth }) => {
	const { t } = useTranslation();

	const title = useMemo(() => {
		if (!label) return "";
		return t(label);
	}, [label, t]);

	return (
		<FieldsContainer
			labelColor={theme.colors.secondary}
			label={title}
			additionalFields={
				<ToggleButton.ToggleButton
					value={value}
					onChange={onChange}
					disabled={disabled}
				/>
			}
			gap="10px"
			maxedWidth={maxedWidth}
		>
			{children}
		</FieldsContainer>
	);
};

export const FieldsContainerWithToggle = memo(FieldsContainerWithToggleBase);

declare namespace FieldsContainerWithToggleBase {
	interface Props extends PropsWithChildren {
		label?: string | string[];
		disabled?: boolean;
		value?: boolean;
		onChange?: (value: boolean) => void;
		maxedWidth?: boolean;
	}
}

export default FieldsContainerWithToggleBase;
