import React from "react";
import {
	Route,
	Redirect,
	RouteProps,
	RouteComponentProps,
	useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../redux/store";
import { useNotification } from "../components/NotificationProvider";
import { useAuthRouterControl } from "../access";

const RouterWithAuthControl: React.FC<RouterWithAuthControl.Props> = React.memo(
	({ component: Component, accessName, ...rest }) => {
		const { pathname } = useLocation();
		const { t } = useTranslation();
		const { warn } = useNotification();
		const personalRole = useTypedSelector(
			(state) => state.account.personalRole,
		);

		const { redirect } = useAuthRouterControl(
			t,
			warn,
			pathname,
			accessName,
			personalRole,
		);

		if (redirect) {
			return <Redirect to={redirect} />;
		}

		return <Route {...rest} render={(props) => <Component {...props} />} />;
	},
);

declare namespace RouterWithAuthControl {
	interface Props extends RouteProps {
		component:
			| React.ComponentType<RouteComponentProps<any>>
			| React.ComponentType<any>;
		accessName: string;
	}
}

export default RouterWithAuthControl;
