import makeTable from "./makeTable";

const agentGroupsModal = makeTable({
	i18tPath: (id) => `tableColumns.customers.agents.${id}`,
	allIds: [
		"title",
		"contactPersonName",
		"contactPersonPhone",
		"balance",
		"bonusBalance",
		"mobileApp",
		"cabinet",
		"company",
		"taxiService",
		"status",
		"group",
	],
	defaultIds: [
		"title",
		"contactPersonName",
		"contactPersonPhone",
		"company",
		"taxiService",
	],
	initialWidths: {
		title: 150,
		contactPersonName: 150,
		contactPersonPhone: 150,
		balance: 150,
		bonusBalance: 150,
		mobileApp: 150,
		cabinet: 150,
		company: 150,
		taxiService: 150,
		status: 150,
		group: 150,
	},
});

export default agentGroupsModal;
