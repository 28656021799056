import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "uikit";

import Range from "../../../../../../../../Modal/components/Content/components/Sectors/components/OrdersCounter/components/Range";
import FromToOutput from "./FromToOutput";

const RangeOutput: React.FC<RangeOutput.Props> = ({ value }) => {
	const { t } = useTranslation();
	return (
		<Row>
			(
			<Row gaps="3px*">
				<FromToOutput
					label={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.content.ruleOutput.sectorsOutput.ordersCounterOutput.rangeOutput.str0",
						) ?? ""
					}
					value={value.from}
				/>
				<FromToOutput
					label={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.content.ruleOutput.sectorsOutput.ordersCounterOutput.rangeOutput.str1",
						) ?? ""
					}
					value={value.to}
				/>
				<div>
					{t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.content.ruleOutput.sectorsOutput.ordersCounterOutput.rangeOutput.str100",
					) ?? ""}
				</div>
			</Row>
			)
		</Row>
	);
};

declare namespace RangeOutput {
	interface Props {
		value: Range.Value;
	}
}

export default RangeOutput;
