import React, { useMemo } from "react";
import { MultiSelect } from "uikit";

import { MultiSelectEntry } from "../../../types/settingEntries";

interface MultiSelectWrapperProps<V> {
	entry: MultiSelectEntry<"meta", V>;
	value: MultiSelectEntry<"value", V>;
	onChange: (value: MultiSelectEntry<"value", V>) => void;
}

function MultiSelectWrapper<V>({
	entry,
	value = [],
	onChange,
}: MultiSelectWrapperProps<V>) {
	const selectedKeys = useMemo(
		() =>
			entry.options
				.filter((option) => value.includes(option.value))
				.map((option) => option.key),
		[entry.options, value],
	);

	return (
		<MultiSelect<V>
			value={selectedKeys}
			options={entry.options}
			onSelect={(option) => {
				onChange([...value, option.value]);
			}}
			onUnselect={(option) => {
				onChange(value.filter((v) => v !== option.value));
			}}
			style={{
				width: "178px",
			}}
		/>
	);
}

export default MultiSelectWrapper;
