import styled from "styled-components";
import { theme } from "uikit";

const OverlayBackground = styled.div`
	display: flex;

	justify-content: center;
	align-items: center;

	min-width: 18px;
	min-height: 18px;

	width: 100%;
	height: 100%;

	background: ${theme.colors.white};
`;

export default OverlayBackground;
