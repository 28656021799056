import { RefObject } from "react";
import { react } from "uikit";

interface Context {
	personDataRef: RefObject<any | null>;
	workDataRef: RefObject<any | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isWorkDataValid = !!this.context?.workDataRef.current?.validate();
		const isPersonDataValid =
			!!this.context?.personDataRef.current?.validate();

		return isPersonDataValid && isWorkDataValid;
	}
}
