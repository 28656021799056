import { Dispatch } from "redux";

import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import account from "../reducers/account";
import colors from "../reducers/settings/colors";
import session from "../reducers/session";

export default class Account {
	static me(id: string) {
		return async (dispatch: Dispatch) => {
			const prpc = getPRPC();

			if (!prpc?.theirsModel?.dispatcher) return;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.dispatcher.getById(id),
			);

			dispatch(account.actions.setUser(result));
			dispatch(colors.actions.setAll(result.settings.colors));
			dispatch(
				session.actions.setLanguage(result?.settings?.language || "ru"),
			);
		};
	}
}
