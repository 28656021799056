import React from "react";
import { Column, Row, Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import tPath from "../../../../constants/tPath";
import { MainTariffTabs, tabs } from "../../tabs";

import Title from "./components/Title";
import Root from "./components/Root";
import LockedBy from "./components/LockedBy";

const Header: React.FC<Header.Props> = ({
	tab,
	title,
	createdAt,
	lockedBy,
	onChangeTab,
}) => {
	const { t } = useTranslation();
	const translatedTabs = tabs.map((internalTab) => ({
		...internalTab,
		label: t(`${tPath}.mainModal.tabs.${internalTab.key}.tabName`),
	}));

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "15px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Column align="end" gaps="15px">
					{lockedBy && (
						<LockedBy>
							{t(
								"pages.settings.pages.tariffs.tabs.main.modal.header.str200",
							) ?? ""}{" "}
							{lockedBy}
						</LockedBy>
					)}
					<Row gaps="7px">
						<span>
							{t(`${tPath}.mainModal.tabs.main.createdAt`)}:
						</span>
						<span style={{ color: "black", fontWeight: "600" }}>
							{createdAt}
						</span>
					</Row>
				</Column>
			</Row>
			<Tabs
				value={tab}
				options={translatedTabs}
				variant="panels"
				onChange={onChangeTab as any}
			/>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		tab: MainTariffTabs;
		title: string;
		createdAt?: string;
		lockedBy?: string;
		onChangeTab: (x: MainTariffTabs) => void;
	}
}

export default Header;
