/* eslint-disable no-param-reassign */

function humanFileSize(bytes: number, si = false, depth = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) return `${bytes} B`;

	const units = si
		? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
		: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

	let unitIndex = -1;

	const round = 10 ** depth;

	do {
		bytes /= thresh;

		unitIndex++;
	} while (
		Math.round(Math.abs(bytes) * round) / round >= thresh &&
		unitIndex < units.length - 1
	);

	return `${bytes.toFixed(depth)} ${units[unitIndex]}`;
}

export default humanFileSize;
