import React, { Dispatch, MouseEvent, useCallback } from "react";
import { CheckBoxWithContent } from "uikit";
import Root from "./components/Root";
import Label from "./components/Label";

const Item: React.FC<Item.Props> = ({
	selected,
	label,
	disabled = false,
	onChangeSelected,
}) => {
	const rootOnClick = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			event.preventDefault();

			onChangeSelected(!selected);
		},
		[onChangeSelected, selected],
	);

	return (
		<Root align="center" onClick={rootOnClick}>
			<CheckBoxWithContent
				value={selected}
				onChange={onChangeSelected}
				gap="12px"
				disabled={disabled}
			>
				<Label>{label}</Label>
			</CheckBoxWithContent>
		</Root>
	);
};

declare namespace Item {
	interface Props {
		selected: boolean;

		label: string;
		disabled?: boolean;

		onChangeSelected: Dispatch<boolean>;
	}
}

export default Item;
