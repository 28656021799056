import React, { Dispatch, RefAttributes, memo } from "react";
import { Column, Row, react, useRefWithSetter } from "uikit";
import { FieldErrors } from "react-hook-form";

import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import PaymentSystem, {
	PaymentProvider,
} from "../../../../../../../../../services/PaymentSystem";

import Companies from "../../../../../components/Companies";
import SystemFee from "../../../../../components/SystemFee";
import AssetPayment from "../../../../../components/AssetPayment";
import FondyPayment from "../../../../../components/FondyPayment";
import SelectPaymentSystems from "../../../../../components/SelectPaymentSystems";
import Name from "../../../../../components/Name";
import Root from "../../../../../components/Root";

import InternalController from "./Controller";
import Withdraw from "../../../../../components/Withdraw";
import { StyledRow } from "../../../../../../../../../components/common";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ value, onChange, controller }) => {
		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);
		const [companyBranchRef, setCompanyBranchRef] =
			useRefWithSetter<Companies.Ref | null>(null);
		const [assetPaymentRef, setAssetPaymentRef] =
			useRefWithSetter<AssetPayment.Ref | null>(null);
		const [fondyPaymentRef, setFondyPaymentRef] =
			useRefWithSetter<FondyPayment.Ref | null>(null);
		const [paymentSystemRef, setPaymentSystemFeeRef] =
			useRefWithSetter<SelectPaymentSystems.Ref | null>(null);
		const [systemFeeRef, setSystemFeeRef] =
			useRefWithSetter<SystemFee.Ref | null>(null);

		const valueEditor = useObjectEditor(value, onChange);

		const paymentProviderValue = valueEditor.useGetter("paymentProvider");

		const isDefault = valueEditor.get("isDefault");

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "taxiServices", "companies", "companyIds"],
			"json",
		);

		const updateAllData = valueEditor.useAssigner();

		controller.setContext({
			nameRef,
			companyBranchRef,
			paymentProviderValue,
			paymentSystemRef,
			systemFeeRef,
			fondyPaymentRef,
			assetPaymentRef,
		});
		return (
			<Root hasPaddings>
				<Column>
					<Column gaps="25px*" maxedWidth maxedHeight>
						<StyledRow justify="start" gap={"0 1rem"}>
							<StyledRow justify="start" m="0 100px 0 0">
								<SelectPaymentSystems
									ref={setPaymentSystemFeeRef}
									value={value}
									onChange={onChange}
								/>
							</StyledRow>
							<Withdraw
								ref={setSystemFeeRef}
								value={value}
								onChange={onChange}
							/>
						</StyledRow>
						<Row sizes="40% 30% 30%" gaps="16px*">
							<Name
								ref={setNameRef}
								name={name}
								setName={setName}
								isDefault={isDefault}
							/>
							<Companies
								ref={setCompanyBranchRef}
								value={taxiServicesData}
								onChange={updateAllData}
							/>
						</Row>

						<StyledRow gap="20px" justify="space-between" w="100%">
							<Column gaps="10px*" maxedWidth>
								<SystemFee
									ref={setSystemFeeRef}
									value={value}
									onChange={onChange}
								/>

								{paymentProviderValue ===
									PaymentProvider.ASSETPAYMENT && (
									<AssetPayment
										ref={setAssetPaymentRef}
										value={value}
										onChange={onChange}
									/>
								)}

								{paymentProviderValue ===
									PaymentProvider.FONDY && (
									<FondyPayment
										ref={setFondyPaymentRef}
										value={value}
										onChange={onChange}
									/>
								)}
							</Column>
						</StyledRow>
					</Column>
				</Column>
			</Root>
		);
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default Content;
