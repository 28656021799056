/* eslint-disable no-nested-ternary */
import React from "react";
import { Icon, theme } from "uikit";
import styled from "styled-components";

import {
	ChatDispatcherPhone,
	ChatExecutorPhone,
	MarkedUserChatVersion,
} from "../../../../types";

const Root = styled.div<{ bgColor?: string; last?: boolean }>`
	position: absolute;

	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 32px;
	min-height: 32px;

	max-width: 32px;
	max-height: 32px;

	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: center;

	color: ${theme.colors.white};
	background-color: ${({ bgColor, last }) =>
		last ? theme.colors.accent : bgColor || theme.colors.disabled_text};

	border: 2px solid ${theme.colors.white};
	border-radius: 50%;
`;

const Avatar: React.FC<Avatar.Props> = ({ data, last }) => (
	<Root last={last}>
		{last ? (
			data.name
		) : data.name ? (
			data.name[0]
		) : (
			<Icon id="chat-no-avatar" size={32} />
		)}
	</Root>
);

declare namespace Avatar {
	interface Props {
		data: ChatDispatcherPhone | ChatExecutorPhone | MarkedUserChatVersion;
		// eslint-disable-next-line react/require-default-props
		last?: boolean;
	}
}

export default Avatar;
