import React, { Dispatch, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import useObjectEditor from "../../../../../hooks/useObjectEditor";
import Divider from "../../../../Divider";
import useMapActions from "../../hooks/useMapActions";
import { Orders } from "../../../../../redux/reducers/Orders";
import { ButtonGroup, Button } from "..";

const ShowButtons = memo(({ value, onChange }: ShowButtons.Props) => {
	const { setALLBtnToggles } = useMapActions();
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const showRouteValue = valueEditor.useGetter("route");
	const toggleShowRoute = valueEditor.useToggler("route");

	const showPriceZonesValue = valueEditor.useGetter("priceZones");
	const toggleShowPriceZones = valueEditor.useToggler("priceZones");

	const showSectorsValue = valueEditor.useGetter("sectors");
	const toggleShowSectors = valueEditor.useToggler("sectors");

	const showParkingsValue = valueEditor.useGetter("parkings");
	const toggleShowParkings = valueEditor.useToggler("parkings");

	const showExecutorsValue = valueEditor.useGetter("executors");
	const toggleShowExecutors = valueEditor.useToggler("executors");

	const showClientsValue = valueEditor.useGetter("clients");
	const toggleShowClients = valueEditor.useToggler("clients");

	useEffect(() => {
		const allBtnToggles: Orders.Map.BtnOnMapToggles = {
			route: showRouteValue,
			priceZones: showPriceZonesValue,
			sectors: showSectorsValue,
			parkings: showParkingsValue,
			executors: showExecutorsValue,
			clients: showClientsValue,
		};
		setALLBtnToggles(allBtnToggles);
	}, [
		setALLBtnToggles,
		showRouteValue,
		showPriceZonesValue,
		showSectorsValue,
		showParkingsValue,
		showExecutorsValue,
		showClientsValue,
	]);

	return (
		<ButtonGroup gaps="1px*">
			<Button
				title={t("orderPageWidgets.map2.showButtons.str0") ?? ""}
				active={showRouteValue}
				tabIndex={-1}
				onClick={toggleShowRoute}
			>
				<Icon id="mapPoints" size={20} />
			</Button>
			<Divider
				orientation="horizontal"
				size="max"
				thickness={1}
				color="#ebebeb"
			/>
			<Button
				title={t("orderPageWidgets.map2.showButtons.str1") ?? ""}
				active={showPriceZonesValue}
				tabIndex={-1}
				onClick={toggleShowPriceZones}
			>
				<Icon id="mapPriceZone" size={20} />
			</Button>
			<Divider
				orientation="horizontal"
				size="max"
				thickness={1}
				color="#ebebeb"
			/>
			<Button
				title={t("orderPageWidgets.map2.showButtons.str2") ?? ""}
				active={showSectorsValue}
				tabIndex={-1}
				onClick={toggleShowSectors}
			>
				<Icon id="mapSector" size={20} />
			</Button>
			<Divider
				orientation="horizontal"
				size="max"
				thickness={1}
				color="#ebebeb"
			/>
			<Button
				title={t("orderPageWidgets.map2.showButtons.str3") ?? ""}
				active={showParkingsValue}
				tabIndex={-1}
				onClick={toggleShowParkings}
			>
				<Icon id="mapParking" size={20} />
			</Button>
			<Divider
				orientation="horizontal"
				size="max"
				thickness={1}
				color="#ebebeb"
			/>
			<Button
				title={t("orderPageWidgets.map2.showButtons.str4") ?? ""}
				active={showExecutorsValue}
				tabIndex={-1}
				onClick={toggleShowExecutors}
			>
				<Icon id="mapExecutor" size={20} />
			</Button>
			<Divider
				orientation="horizontal"
				size="max"
				thickness={1}
				color="#ebebeb"
			/>
			<Button
				title={t("orderPageWidgets.map2.showButtons.str5") ?? ""}
				active={showClientsValue}
				tabIndex={-1}
				onClick={toggleShowClients}
			>
				<Icon id="mapClient" size={20} />
			</Button>
		</ButtonGroup>
	);
});

declare namespace ShowButtons {
	interface Props {
		value: Orders.Map.BtnOnMapToggles;
		onChange: Dispatch<Orders.Map.BtnOnMapToggles>;
	}
}

export default ShowButtons;
