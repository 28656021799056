/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import Base from "../Base";

class CarDescription extends Base {
	public static async addRoute(
		parameters: CarDescription.AddRouteParameters,
	) {
		this.request((prpc) =>
			prpc.theirsModel.carDescription.addRoute(parameters),
		);
	}
}

namespace CarDescription {
	export interface AddRouteParameters {
		carBaseTypeName: string;
		carBrandName: string;
		carModelName: string;
		carBodyTypeName: string;
		carClassIds?: number[];
	}
}

export default CarDescription;
