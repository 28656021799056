import React, { memo, useCallback } from "react";
import { Stepper } from "uikit";

const ImprovedStepperBase: React.FC<ImprovedStepperBase.Props> = ({
	value = 0,
	onChange,
	min = 0,
	disabled,
	...props
}) => {
	const newOnchange = useCallback(
		(val) => {
			onChange?.(val);
		},
		[onChange],
	);

	return (
		<Stepper
			disabled={disabled}
			value={value}
			onChange={newOnchange}
			{...props}
			min={min}
		/>
	);
};

declare namespace ImprovedStepperBase {
	interface Props extends Stepper.Props {
		value?: number;
		onChange?: (value: number) => void;
		disabled?: boolean;
	}
}

export const ImprovedStepper = memo(ImprovedStepperBase);
declare namespace ImprovedStepper {
	interface Props extends ImprovedStepperBase.Props {}
}

export default ImprovedStepperBase;
