import { NonEditablePropertyNames } from "../../../../../../../types/NonEditableProperties";
import Modal from "../components/Modal";
import { RoundingMethod } from "../components/Modal/components/Content/tabs/Main/components/Rounding/constants";

const defaultValue: Omit<Modal.Value, NonEditablePropertyNames | "currency"> = {
	name: {},
	additionalFields: {
		general: {
			currency: {
				displayStyle: null,
			},
			rounding: {
				method: RoundingMethod.CEIL,
				precision: 0,
				multiple: {
					active: false,
					value: 0,
				},
			},
		},
	},
};

export default defaultValue;
