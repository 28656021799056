import React, { memo } from "react";
import { Row } from "uikit";
import Root from "./components/Root";
import Title from "./components/Title";

const Header: React.FC<Header.Props> = memo(({ title }) => (
	<Root sizes="auto!*">
		<Row style={{ padding: "12px 15px" }}>
			<Title>{title}</Title>
		</Row>
	</Root>
));

declare namespace Header {
	interface Props {
		title: string;
	}
}

export default Header;
