import styled from "styled-components";
import { theme } from "uikit";

const Text = styled.div<Text.Props>`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;

	color: ${({ type }) =>
		type === "default" ? theme.colors.primary : theme.colors.disabled_text};

	${theme.longWord}
`;

declare namespace Text {
	interface Props {
		type: "default" | "placeholder";
	}
}

export default Text;
