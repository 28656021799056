/* eslint-disable no-restricted-globals */
import TemplateBody from "..";
import translationPath from "../../../../../../../constants/translationPath";

/* eslint-disable no-restricted-syntax */
export default function fromStringToJson(text, t): TemplateBody.Value {
	const regex = /{{(.*?)}}/g;
	const matches = [...text.matchAll(regex)].map((match) => ({
		start: match.index,
		end: match.index + match[0].length,
		value: match[1].trim(),
	}));

	const parts: any[] = [];
	let lastEnd = 0;

	if (matches.length === 0) {
		return {
			type: "doc",
			content: [
				{
					type: "paragraph",
					content: [
						{
							type: "text",
							text,
						},
					],
				},
			],
		};
	}

	for (const match of matches) {
		if (match.start > lastEnd) {
			parts.push({
				type: "text",
				value: text.slice(lastEnd, match.start),
			});
		}
		parts.push({
			type: "mention",
			value: match.value,
		});
		lastEnd = match.end;
	}

	if (lastEnd < text.length) {
		parts.push({
			type: "text",
			value: text.slice(lastEnd),
		});
	}

	return {
		type: "doc",
		content: [
			{
				type: "paragraph",
				content: parts.flatMap((part: any) => {
					if (part.type === "text") {
						return {
							type: "text",
							text: part.value,
						};
					}
					if (part.type === "mention") {
						const mentions = part.value.split(" ");

						let isConditionalInclude = false;

						const formattedMentions = mentions.map((mention) => {
							if (mention === "#if") isConditionalInclude = true;

							if (mention.startsWith("(")) {
								const newMention = mention.slice(1);

								return {
									type: "mention",
									attrs: {
										id: newMention,
										label: isNaN(Number(newMention))
											? t(
													`${translationPath}.modal.templateBody.messageParams.${newMention}`,
											  )
											: newMention,
									},
								};
							}
							if (mention.endsWith(")")) {
								const newMention = mention.slice(0, -1);

								return {
									type: "mention",
									attrs: {
										id: newMention,
										label: isNaN(Number(newMention))
											? `${t(
													`${translationPath}.modal.templateBody.messageParams.${newMention}`,
											  )}`
											: newMention,
									},
								};
							}

							return {
								type: "mention",
								attrs: {
									id: mention,
									label: isNaN(Number(mention))
										? t(
												`${translationPath}.modal.templateBody.messageParams.${mention}`,
										  )
										: mention,
								},
							};
						});

						if (isConditionalInclude) {
							[formattedMentions[1], formattedMentions[2]] = [
								formattedMentions[2],
								formattedMentions[1],
							];
						}

						return formattedMentions;
					}

					return null;
				}),
			},
		],
	};
}
