import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Popover, Whisper } from "rsuite";
import { Avatar } from "..";

const StyledPopover = styled(Popover)`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	padding: 10px;
	max-height: 350px;
	width: 250px;
`;

const Divider = styled.div`
	height: 1px;
	background-color: gray;
	margin: 5px 0;
`;

const BtnExecutorAvatarGroup = styled.button`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
	cursor: pointer;
`;

const GroupInfo = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TextInfo = styled.p`
	margin-top: 0;
`;

const GroupPaginated = styled.div`
	grid-column: span 2;
	text-align: center;
	margin-top: 10px;
`;

const BtnPaginated = styled.button`
	padding: 4px;
	margin: 0 2px;
	background-color: transparent;
	color: ${(props) => (props.disabled ? "#adb5bd" : "#007bff")};
	border: none;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	outline: none;
	font-size: 16px;

	&:hover {
		color: ${(props) => (props.disabled ? "#adb5bd" : "#0056b3")};
	}
`;

const ExecutorComponentAvatarGroup: React.FC<
	ExecutorComponentAvatarGroup.Props
> = ({ arrayUsers }) => {
	const [page, setPage] = useState(1);

	const TRIGER_STYLES_GROUP = 4;
	const ITEMS_PER_PAGE = 5;

	const lengthArrayUser = arrayUsers.length;

	const isActionTriger = lengthArrayUser > TRIGER_STYLES_GROUP;

	const listDynamicStyles = isActionTriger
		? arrayUsers.slice(0, 4)
		: arrayUsers;

	const usersList = listDynamicStyles.map((user, index, arr) => {
		const nameItemBeforeTriggers = user.name ?? "";
		const nameItemAfterTriggers =
			index <= 2 ? nameItemBeforeTriggers : `+${lengthArrayUser - 4}`;

		const _data = {
			name: isActionTriger
				? nameItemAfterTriggers
				: nameItemBeforeTriggers,
		};

		const isSpecialNumericBeforeTriggers: boolean =
			arr.length === index + 1;
		const isStandartItem = false;

		const _last: boolean = isActionTriger
			? isSpecialNumericBeforeTriggers
			: isStandartItem;

		return <Avatar key={index} data={_data} last={_last} />;
	});

	const totalPages = Math.ceil(lengthArrayUser / ITEMS_PER_PAGE);
	const paginatedUsers = arrayUsers.slice(
		(page - 1) * ITEMS_PER_PAGE,
		page * ITEMS_PER_PAGE,
	);

	const goToPage = (pageNumber: number) => {
		if (pageNumber >= 1 && pageNumber <= totalPages) {
			setPage(pageNumber);
		}
	};

	const getPersonFullName = (data: ExecutorComponentAvatarGroup.UserData) =>
		`${data?.person?.surname ?? ""} ${data?.person?.name ?? ""} ${
			data?.person?.fatherName ?? ""
		}`;

	const { t } = useTranslation();
	return (
		<Whisper
			trigger="click"
			placement="left"
			speaker={
				<StyledPopover>
					{paginatedUsers.map((user, index) => (
						<GroupInfo key={index}>
							<TextInfo>
								{t(
									"orderPageWidgets.chat.markedUsers.executorComponentAvatarGroup.str200",
								) ?? ""}
								: {user.callSign}
							</TextInfo>
							<TextInfo>
								{t(
									"orderPageWidgets.chat.markedUsers.executorComponentAvatarGroup.str201",
								) ?? ""}
								: {getPersonFullName(user)}
							</TextInfo>
							{index < paginatedUsers.length - 1 && <Divider />}
						</GroupInfo>
					))}
					<GroupPaginated>
						{totalPages > 1 && (
							<>
								<BtnPaginated
									disabled={page === 1}
									onClick={() => goToPage(page - 1)}
								>
									{"<"}
								</BtnPaginated>
								<span>
									{page}{" "}
									{t(
										"orderPageWidgets.chat.markedUsers.executorComponentAvatarGroup.str202",
									) ?? ""}{" "}
									{totalPages}
								</span>
								<BtnPaginated
									disabled={page === totalPages}
									onClick={() => goToPage(page + 1)}
								>
									{">"}
								</BtnPaginated>
							</>
						)}
					</GroupPaginated>
				</StyledPopover>
			}
		>
			<BtnExecutorAvatarGroup>{usersList}</BtnExecutorAvatarGroup>
		</Whisper>
	);
};

declare namespace ExecutorComponentAvatarGroup {
	interface UserData {
		name?: string;
		callSign?: string;
		person?: {
			surname?: string;
			name?: string;
			fatherName?: string;
		};
	}

	interface Props {
		arrayUsers: UserData[];
	}
}

export default ExecutorComponentAvatarGroup;
