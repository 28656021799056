import { Action, combineReducers } from "@reduxjs/toolkit";

import { executors, executorsAction, StateExecutors } from "./executors";

export type State = {
	executors: StateExecutors;
};

export type Actions = {
	executors: typeof executorsAction;
};

export const accountingReducer = combineReducers<State, Action<Actions>>({
	executors,
});

export const accountingAction: Actions = {
	executors: executorsAction,
};

export default accountingReducer;
