import { Dispatch, useEffect, useState } from "react";

function runTimeoutEverySecond(
	setCurrentTime: Dispatch<Date>,
	setTimeoutId: Dispatch<NodeJS.Timeout>,
) {
	const currentTime = new Date();
	const nextSecond = 1000 - currentTime.getMilliseconds();

	setTimeoutId(
		setTimeout(() => {
			setCurrentTime(new Date());

			runTimeoutEverySecond(setCurrentTime, setTimeoutId);
		}, nextSecond),
	);
}

function useTime(interval: number | "every-second") {
	const [currentTime, setCurrentTime] = useState(new Date());

	useEffect(() => {
		let intervalId: NodeJS.Timer | undefined;
		let timeoutId: NodeJS.Timeout | undefined;

		if (interval === "every-second") {
			runTimeoutEverySecond(setCurrentTime, (newTimeoutId) => {
				timeoutId = newTimeoutId;
			});
		} else {
			intervalId = setInterval(() => {
				setCurrentTime(new Date());
			}, interval);
		}

		return () => {
			if (intervalId) clearInterval(intervalId);
			if (timeoutId) clearTimeout(timeoutId);
		};
	}, [interval]);

	return currentTime;
}

export default useTime;
