/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { DataKind, EntryList } from "../../../types/settingEntries";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface BasePaymentSystemEntries<Data extends DataKind> {}

export type PaymentSystemEntries<Data extends DataKind> =
	BasePaymentSystemEntries<Data> & EntryList<Data, never, never>;
export type PaymentSystemMeta = PaymentSystemEntries<"meta">;
export type PaymentSystemState = PaymentSystemEntries<"value">;
export type PaymentSystemValue =
	PaymentSystemEntries<any>[keyof PaymentSystemEntries<any>];

export const paymentSystemMeta: PaymentSystemMeta = {};

const initialState: PaymentSystemState = {};

type Reducer<P> = CaseReducer<PaymentSystemState, PayloadAction<P>>;

const setAll: Reducer<PaymentSystemState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const paymentSystems = createSlice({
	name: "paymentSystems",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default paymentSystems;
