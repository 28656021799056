import React, { Dispatch, memo } from "react";
import { clone } from "lodash";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { StyledColumn } from "../styles";

import Item from "./Item";

const DragDropListBase: React.FC<DragDropList.Props> = ({
	value,
	onChange,
	rulesDrag,
}) => (
	<DragDropContext
		onDragEnd={(result) => {
			const sourceIndex = result.source.index;
			const destinationIndex = result.destination?.index;

			if (
				typeof destinationIndex === "undefined" ||
				sourceIndex === destinationIndex
			)
				return;

			const newValue = clone(value);

			const sourceItem = newValue[sourceIndex];
			const destinationItem = newValue[destinationIndex + 1];

			newValue.splice(sourceIndex, 1);
			newValue.splice(destinationIndex, 0, sourceItem);

			// TODO: is not correct
			const payload = newValue.map((item) => {
				if (item.id === destinationItem.id) {
					return {
						...item,
						position: sourceItem.position,
					};
				}

				if (item.id === sourceItem.id) {
					return {
						...item,
						position: destinationItem.position,
					};
				}

				return item;
			});

			onChange(payload);
		}}
	>
		<Droppable droppableId="droppable">
			{(provided) => (
				<StyledColumn
					ref={provided.innerRef}
					{...provided.droppableProps}
					w="100%"
					gap="16px"
				>
					{value.map(({ id, elem }, index) => (
						<Item
							key={id}
							disabled={rulesDrag.includes(id)}
							id={id}
							index={index}
						>
							{elem}
						</Item>
					))}
					{provided.placeholder}
				</StyledColumn>
			)}
		</Droppable>
	</DragDropContext>
);

const DragDropList = memo(DragDropListBase);

declare namespace DragDropList {
	type Value = Item[];

	interface Item {
		id: string;
		position: number;
		elem: React.ReactNode | undefined;
	}

	interface Props {
		value: Value;
		rulesDrag: string[];
		onChange: Dispatch<Value>;
	}
}

export default DragDropList;
