import React, { forwardRef } from "react";
import TaxiServiceFormBase from "../../../../../../../../../../components/TaxiServiceForm";
import Root from "./components/Root";

const TaxiServiceForm = forwardRef<
	TaxiServiceFormBase.Controller,
	TaxiServiceForm.Props
>((props, ref) => (
	<Root>
		<TaxiServiceFormBase ref={ref} {...props} />
	</Root>
));

declare namespace TaxiServiceForm {
	type Value = TaxiServiceFormBase.Value;

	type Props = TaxiServiceFormBase.Props;

	type Controller = TaxiServiceFormBase.Controller;
}

export default TaxiServiceForm;
