import { Option } from "uikit";
import { TFunction } from "i18next";

type Statuses =
	| "free"
	| "busy"
	| "lunch"
	| "home"
	| "onOrder"
	| "ownOrder"
	| "closedByOffice"
	| "autoClose";

type Shifts = "open" | "closed";

const pathTranslationsMain =
	"orderPageWidgets.map2.header.filters.content.data.";

const getStatuses = (t: TFunction): Option<Statuses>[] => [
	{
		key: "free",
		label: t(`${pathTranslationsMain}free`) ?? "",
		value: "free",
	},
	{
		key: "busy",
		label: t(`${pathTranslationsMain}busy`) ?? "",
		value: "busy",
	},
	{
		key: "lunch",
		label: t(`${pathTranslationsMain}lunch`) ?? "",
		value: "lunch",
	},
	{
		key: "home",
		label: t(`${pathTranslationsMain}home`) ?? "",
		value: "home",
	},
	{
		key: "onOrder",
		label: t(`${pathTranslationsMain}onOrder`) ?? "",
		value: "onOrder",
	},
	{
		key: "ownOrder",
		label: t(`${pathTranslationsMain}ownOrder`) ?? "",
		value: "ownOrder",
	},
	{
		key: "closedByOffice",
		label: t(`${pathTranslationsMain}closedByOffice`) ?? "",
		value: "closedByOffice",
	},
	{
		key: "autoClose",
		label: t(`${pathTranslationsMain}autoClose`) ?? "",
		value: "autoClose",
	},
]; // statuses

const getShifts = (t: TFunction): Option<Shifts>[] => [
	{
		key: "open",
		label: t(`${pathTranslationsMain}open`) ?? "",
		value: "open",
	},
	{
		key: "closed",
		label: t(`${pathTranslationsMain}closed`) ?? "",
		value: "closed",
	},
]; // shifts

export { getStatuses, getShifts };
