import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { cloneDeep, isEqual } from "lodash";
import { LatLngLiteral } from "leaflet";
import { Option } from "uikit";

import { Popup } from "../../../../../common";
import Map from "../../../../../../services/Map";
import {
	HandleEventsProps,
	useGetCoordinatesByHouse,
	useGetSector,
	useHandleEvents,
	ValueAccessors,
} from "../../hooks";
import cityToString from "../utils/cityToString";
import streetToString from "../utils/streetToString";
import { StyledInput, StyledPopup } from "../styled";
import { OverlayHouse } from "../Overlays";
import PointOrderModalTableBase from "../..";

const HouseBase: React.FC<HouseBase.Props> = ({
	rowData,
	rowIndex,
	dataKey,
	colIndex,
	setData,
	styles,
	offset,
	street,
	height,
	handleEventsOptions,
	containerId,
	addHouseCoordinates,
}) => {
	const {
		inputValue,
		open,
		onChangeInputValue,
		onEnterToInput,
		onExistFromInput,
		onClickToInput,
		onFocusToInput,
		onBlurCaptureToInput,
		setColIndex,
		setDataKey,
		setInputId,
		setRowId,
		setRowIndex,
		save,
		setSave,
		reset,
		nextFocus,
		setOpen,
		debounceClose,
		setHasHouse,
		nextElementById,
	} = useHandleEvents(handleEventsOptions);

	const { get: getSector, addSector } = useGetSector();
	const { get, response, house } = useGetCoordinatesByHouse();
	const [activeId, setActiveOption] = useState<number>(0);

	useEffect(() => {
		if (!rowData.raw.type) reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData.raw.type]);

	useEffect(() => {
		setColIndex(`${colIndex}`);
		setDataKey(dataKey);
		setInputId(`${rowData.raw.id + dataKey}`);
		setRowId(rowData.raw.id);
		setRowIndex(`${rowIndex}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updatedData = useCallback(
		async (params: Map.Search.Object) => {
			const optionsType: Map.Search.Type[] = [
				"favorite",
				"object",
				"localObject",
			];

			const type = optionsType.includes(params.type)
				? "address"
				: params.type;
			const entrance = optionsType.includes(params.type)
				? params.entrance
				: "";

			const sector = await getSector(params.coordinates);

			const city = cityToString(params);
			const street = streetToString(params);
			const cloneRowData = cloneDeep(rowData);
			const newData = addSector(cloneRowData, sector);
			addHouseCoordinates(params.coordinates, newData.raw.id);

			// base
			newData.city = city;
			newData.street = street;
			newData.type = type;
			newData.coordinates = params.coordinates;

			newData.name = params.name || "";
			newData.house = params.number || "";
			newData.customHouse = params.customHouse || "";
			newData.customStreet = params.customStreet || "";

			newData.title = params.title || "";
			newData.save = true;
			newData.raw.save = true;
			newData.saveType = "house";
			newData.raw.saveType = "house";
			// raw
			newData.raw.type = type;
			newData.raw.coordinates = params.coordinates;
			newData.raw.country = params.country;
			newData.raw.countryCode = params.countryCode;

			newData.raw.region = params.region || "";
			newData.raw.district = params.district || "";

			newData.raw.name = params.name || params.title || "";
			newData.raw.customHouse = params.customHouse || "";
			newData.raw.customStreet = params.customStreet || "";

			newData.raw.street = params.street || params.customStreet || "";
			newData.raw.streetType = params.streetType || "";

			newData.raw.settlement = params.settlement || "";
			newData.raw.settlementType = params.settlementType || "";

			newData.raw.house = house || "";
			// clear
			newData.entrance = entrance || "";
			newData.polygon = "";
			newData.flat = "";
			// clear raw
			newData.raw.entrance = entrance || "";
			newData.raw.flat = "";

			// reset
			if (
				rowData.raw.settlement === newData.raw.settlement &&
				rowData.raw.settlementType === newData.raw.settlementType &&
				rowData.raw.street === newData.raw.street &&
				rowData.raw.streetType === newData.raw.streetType &&
				rowData.raw.type === newData.raw.type &&
				rowData.raw.house === newData.raw.house &&
				rowData.raw.name === newData.raw.name &&
				isEqual(rowData.raw.coordinates, newData.raw.coordinates)
			) {
				setOpen(false);
				debounceClose();
				return;
			}

			setData(newData);
			reset();
			debounceClose();
			if (save) setSave(false);
		},
		[
			addHouseCoordinates,
			addSector,
			debounceClose,
			getSector,
			house,
			reset,
			rowData,
			save,
			setData,
			setOpen,
			setSave,
		],
	);

	useEffect(() => {
		if (response) updatedData(response);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	useEffect(() => {
		if (inputValue && activeId === -1) setActiveOption(0);
	}, [activeId, inputValue]);

	const propsInput = useMemo(() => {
		const baseValue = `${rowData.raw?.house || rowData.raw?.number || ""}`;
		return {
			value: inputValue !== null ? inputValue : baseValue,
		};
	}, [inputValue, rowData.raw?.house, rowData.raw?.number]);

	useEffect(() => {
		if (propsInput.value) setHasHouse(true);
		else setHasHouse(false);
	}, [propsInput.value, setHasHouse]);

	useEffect(() => {
		const optionsType: Map.Search.Type[] = [
			"favorite",
			"object",
			"localObject",
		];

		if (street?.type && optionsType.includes(street?.type)) {
			const streetHouse =
				street?.house || street?.number || street?.customHouse || "";

			get(street, streetHouse);
			if (handleEventsOptions?.fol) {
				nextElementById(handleEventsOptions?.fol);
			} else {
				nextFocus();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [street]);

	const onSelect = useCallback(
		(option: Option<string>) => {
			nextFocus();
			if (street) {
				if (option && option?.key !== "query") {
					get(street, option.value);
				} else if (option && option?.key === "query") {
					get(street, option.value);
				} else if (inputValue) {
					get(street, inputValue);
				}
				return;
			}
			if (!street && option && rowData.raw) {
				get(
					{
						coordinates: rowData.raw.coordinates,
						houses: [],
						street: rowData.raw.street || "",
						streetType: rowData.raw.streetType || "",
						settlement: rowData.raw.settlement || "",
						settlementType: rowData.raw.settlementType || "",
					},
					option.value,
				);
			}
		},

		[get, inputValue, nextFocus, rowData.raw, street],
	);

	const openOverlay = useMemo(() => open, [open]);
	const size = useMemo(
		() =>
			propsInput.value.length > 5 ? propsInput.value.length * 1.25 : 5,
		[propsInput.value.length],
	);

	return (
		<StyledPopup
			useClickControl
			useArrow={false}
			open={openOverlay}
			offset={offset}
			styles={styles}
			trackerId={dataKey + rowData.raw.id}
			containerId={containerId}
			onChangeOpen={setOpen}
			tracker={
				<StyledInput
					size={size}
					height={height}
					id={`${rowData.raw.id + dataKey}`}
					aria-label={dataKey}
					aria-rowindex={rowIndex}
					aria-colindex={colIndex}
					autoComplete="one-time-code"
					onChange={onChangeInputValue}
					onKeyUp={onEnterToInput}
					onKeyDown={onExistFromInput}
					onClick={onClickToInput}
					onFocus={onFocusToInput}
					onBlurCapture={onBlurCaptureToInput}
					onCut={(event) => {
						event.preventDefault();
					}}
					onDragOver={(event) => {
						event.preventDefault();
					}}
					onDrop={(event) => {
						event.preventDefault();
					}}
					{...propsInput}
				/>
			}
		>
			{openOverlay && (
				<OverlayHouse
					data={street}
					activeId={activeId}
					houseQuery={inputValue || ""}
					setActiveOption={setActiveOption}
					textSelectOnSelect={onSelect}
				/>
			)}
		</StyledPopup>
	);
};

declare namespace HouseBase {
	interface Props extends PropsWithChildren {
		rowData: PointOrderModalTableBase.OrderModal;
		setData: (data: PointOrderModalTableBase.OrderModal) => void;
		/** Street with house to selection */
		street?: Map.Search.Object;
		addHouseCoordinates: (
			coordinates: LatLngLiteral | undefined,
			id: number,
		) => void;
		reset?: (id: number) => void;

		rowIndex?: number;
		dataKey: ValueAccessors | string;
		colIndex: number;

		styles?: Popup.Styles;
		offset?: Popup.Props["offset"];
		height?: number;
		customerId?: number;
		handleEventsOptions?: HandleEventsProps;
		containerId?: string;
		countRow?: number;
	}
}

export const House = memo(HouseBase);

export default HouseBase;
