import React, { Dispatch, useEffect, useState, useCallback } from "react";
import { Option, Key } from "uikit";
import Root from "./components/Root";
import Item from "./components/Item";

const List = <OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
	onSave,
}: List.Props<OptionValue, ValueType>) => {
	const [activeOption, setActiveOption] = useState(-1);

	const selectedActiveItem = useCallback(
		(data: boolean) => {
			const option = options?.[activeOption];
			if (data && option?.key && value !== option?.key)
				onChange(option.key as ValueType);
			else onChange(undefined);
		},
		[activeOption, onChange, options, value],
	);

	const eventKeydown = useCallback(
		(event: KeyboardEvent) => {
			const keyEvent = event?.code;
			const isArrows = [
				"arrowup",
				"arrowdown",
				"arrowright",
				"arrowleft",
				"enter",
				"space",
			].includes(keyEvent.toLowerCase());

			if (keyEvent === "Space") {
				selectedActiveItem(true);
				event.stopPropagation();
				event.preventDefault();
			}
			if (event.key === "ArrowUp") {
				setActiveOption((prev) =>
					prev - 1 < 0 ? options.length - 1 : prev - 1,
				);
			}
			if (event.key === "ArrowDown") {
				setActiveOption((prev) =>
					prev + 1 > options.length - 1 ? 0 : prev + 1,
				);
			}

			if (event.key === "ArrowLeft") {
				selectedActiveItem(true);
				event.stopPropagation();
				event.preventDefault();
			}
			if (event.key === "ArrowRight") {
				selectedActiveItem(false);
				event.stopPropagation();
				event.preventDefault();
			}

			if (event.key === "Enter") onSave();

			if (isArrows) event.stopPropagation();
		},
		[onSave, options.length, selectedActiveItem],
	);

	useEffect(() => {
		document.addEventListener("keydown", eventKeydown, true);
		return function cleanup() {
			document?.removeEventListener("keydown", eventKeydown, true);
		};
	}, [eventKeydown, options.length]);

	return (
		<Root alignItems="stretch">
			{options.map((option, i) => (
				<Item
					active={activeOption === i}
					key={option.key}
					value={value === option.key}
					label={option.label}
					onChange={(itemValue) => {
						if (itemValue) onChange(option.key as ValueType);
						else onChange(undefined);
					}}
				/>
			))}
		</Root>
	);
};
declare namespace List {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;

		options: Option<OptionValue>[];

		onChange: Dispatch<Value<ValueType>>;
		onSave: () => void;
	}
}

export default List;
