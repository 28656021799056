import React, { memo, useMemo } from "react";
import { Trans } from "react-i18next";

import { StyledRow, StyledColumn } from "../../../../../../components/common";

import Mark from "../../Mark";
import Marker from "../../Marker";

import CarsDifference from "./index";

const CarParams: React.FC<CarParams.Props> = ({ car }): JSX.Element => {
	const keysCar = useMemo<CarParams.Keys>(() => {
		if (!car) return [];
		const keys = Object.keys(car).filter((key) => {
			if (key === "id") return false;
			return true;
		}) as CarParams.Keys;

		return keys;
	}, [car]);

	if (!car || !keysCar.length) return <></>;

	return (
		<StyledColumn p={"0 0 0 10px"}>
			{keysCar.map((key) => {
				if (!key) return <></>;
				if (!car[key]) return <></>;
				return (
					<StyledRow key={key}>
						<Marker />
						<Trans
							i18nKey={`change_history.orders.fields.car.${key}`}
							values={{ value: car[key] }}
							components={{ mark: <Mark /> }}
						/>
					</StyledRow>
				);
			})}
		</StyledColumn>
	);
};
declare namespace CarParams {
	interface Props {
		car: Partial<CarsDifference.CarParams> | null;
	}

	type Keys = [keyof CarsDifference.CarParams] | [];
}

export const CarParamsMemo = memo(CarParams);
export default CarParams;
