/* eslint-disable no-case-declarations */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CarClass from "../../../../../../../../../../../../../../../../services/CarClass";
import Sector from "../../../../../../../../../../../../../../../../services/Sector";
import LightTable from "../../../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../../../components/TableSettings";
import { StyledRow } from "../../../../../../../../../../../../../../../../components/common";

import tPath from "../../../../../../../../../../constants/tPath";

import Rules from "../..";
import { Columns, columns, defaultColumnsId } from "./constants";
import Root from "./components/Root";
import ActiveColumn from "./components/ActiveColumn";
import RuleOutput from "./components/RuleOutput";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	saveHandler,
	carClasses,
	sectors,
}) => {
	const { t } = useTranslation();
	const [ctrlKey, setCtrlKey] = useState<boolean>(false);

	const tColumns = useMemo(
		() =>
			columns.map((c) => ({
				...c,
				label: t(`${tPath}.columns.${c.id}`),
			})),
		[t],
	);
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(index: number) => {
			if (selected.includes(index)) {
				setSelected(selected.filter((i) => index !== i));
			} else {
				setSelected([...selected, index]);
			}
		},
		[setSelected, selected],
	);

	const handleOnRowClick = useCallback(
		(rowData) => {
			const index = value.indexOf(rowData);
			if (ctrlKey) updateSelected(index);
			else {
				const exist = selected.find((item) => item === index);
				if (exist && selected.length <= 1) setSelected([]);
				else setSelected([index]);
			}
		},
		[ctrlKey, updateSelected, value, setSelected, selected],
	);
	// --- width for scroll by overlay-y --- start
	const [widths, setWidth] = useState<number[]>([900]);

	const getWidth = useCallback((num: number) => {
		setWidth((prev) => {
			const exist = prev.includes(num);
			if (!exist) return [...prev, num];
			return prev;
		});
	}, []);

	const width = useMemo(() => {
		const max = Math.max(...widths);
		return max + 50;
	}, [widths]);
	// --- width for scroll by overlay-y --- end

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = tColumns.find((col) => col.id === columnId);
				if (!column) return null;
				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width || width}
						fullText={columnId === Columns.RULES}
						resizable
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
							dataKey={column.id}
						>
							{(rowData) => {
								switch (columnId) {
									case Columns.ACTIVE:
										return (
											<ActiveColumn
												rule={rowData as Rules.Rule}
												rules={value}
												saveHandler={saveHandler}
											/>
										);

									case Columns.NAME:
										return rowData.name;

									case Columns.RULES:
										return (
											<StyledRow
												gap="5px"
												w="100%"
												h="100%"
												alignItems="center"
											>
												<RuleOutput
													rule={rowData as Rules.Rule}
													autoClasses={carClasses}
													sectorModels={sectors}
													getWidth={getWidth}
												/>
											</StyledRow>
										);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[
			carClasses,
			columnsId,
			getWidth,
			saveHandler,
			sectors,
			tColumns,
			value,
			width,
		],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(value.indexOf(rowData))
				? "selected"
				: "",
		[selected, value],
	);

	// const handleOnChangeSelected = useCallback(
	// 	(isAllSelected) => {
	// 		if (isAllSelected) {
	// 			setSelected(value?.map((_, i) => i));
	// 		} else {
	// 			setSelected([]);
	// 		}
	// 	},
	// 	[setSelected, value],
	// );

	const handleContentEdit = useCallback(
		(rowData) => {
			onEdit?.(value.indexOf(rowData));
		},
		[onEdit, value],
	);

	return (
		<Root sizes="1fr auto!">
			<StyledRow
				w="100%"
				h={{ min: "calc(46px * 7)" }}
				overY="auto"
				onClick={(e) => {
					const key = e.ctrlKey;
					setCtrlKey(key);
				}}
			>
				<LightTable
					data={value}
					virtualized
					fillHeight
					rowHeight={46}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={handleContentEdit}
					style={{ width: "100%" }}
				>
					{/* <LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(data) => (
								<CheckBox
									value={selected.includes(
										value.indexOf(data as Rules.Rule),
									)}
									onChange={() => {
										updateSelected(
											value.indexOf(data as Rules.Rule),
										);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column> */}
					{renderColumns}
				</LightTable>
			</StyledRow>

			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={tColumns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: Rules.Rule[];

		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: (value: number) => void;
		saveHandler: (rule: Rules.Rule, index?: number | null) => void;
		carClasses: CarClass.Model[];
		sectors?: Sector.Model[];
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
	};
}

export default Content;
