import { combineReducers } from "@reduxjs/toolkit";

import {
	orderExecutorsDataState,
	orderExecutorsDataReducer,
	orderExecutorsDataAction,
} from "./data";
import {
	orderExecutorsFiltersState,
	orderExecutorsFiltersReducer,
	orderExecutorsFiltersAction,
} from "./filter";
import {
	orderExecutorsTableSettingsState,
	orderExecutorsTableSettingsReducer,
	orderExecutorsTableSettingsAction,
} from "./tableSettings";

export type StateExecutors = {
	data: orderExecutorsDataState;
	filter: orderExecutorsFiltersState;
	tableSettings: orderExecutorsTableSettingsState;
};

export const executors = combineReducers<StateExecutors>({
	data: orderExecutorsDataReducer,
	filter: orderExecutorsFiltersReducer,
	tableSettings: orderExecutorsTableSettingsReducer,
});

export const ordersExecutorsAction = {
	data: orderExecutorsDataAction,
	filter: orderExecutorsFiltersAction,
	tableSettings: orderExecutorsTableSettingsAction,
};

export default executors;
