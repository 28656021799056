import getOptions from "../utils/generateOptions";

/* eslint-disable no-shadow */
export enum TariffCommonSurchargeAdjustment {
	SURCHARGE_TO_COST_PER_KM = "surcharge_to_cost_per_km",
	COEFFICIENT_TO_COST_PER_KM = "coefficient_to_cost_per_km",
	SURCHARGE_TO_ORDER_SUM = "surcharge_to_order_sum",
	COEFFICIENT_TO_ORDER_SUM = "coefficient_to_order_sum",
}

export const tariffCommonSurchargeOptions = getOptions(
	Object.values(TariffCommonSurchargeAdjustment),
);
