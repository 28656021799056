import React from "react";
import { Column } from "uikit";

const General = () => (
	<Column>
		general tab
		<Column>general tab</Column>
		general tab
	</Column>
);

export default General;
