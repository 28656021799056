import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import s from "./index.module.css";

type Item = { origParam: string; translatedParam: string };
interface Props {
	items: Item[];
	command: any;
}

const MentionList = forwardRef(({ items, command }: Props, ref) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const selectItem = (index) => {
		const item = items[index];

		if (item) {
			command({
				id: item.origParam,
				label: item.translatedParam,
			});
		}
	};

	const upHandler = () => {
		setSelectedIndex((selectedIndex + items.length - 1) % items.length);
	};

	const downHandler = () => {
		setSelectedIndex((selectedIndex + 1) % items.length);
	};

	const enterHandler = () => {
		selectItem(selectedIndex);
	};

	useEffect(() => setSelectedIndex(0), [items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }) => {
			if (event.key === "ArrowUp") {
				upHandler();
				return true;
			}

			if (event.key === "ArrowDown") {
				downHandler();
				return true;
			}

			if (event.key === "Enter") {
				enterHandler();
				return true;
			}

			return false;
		},
	}));

	return (
		<div className={s.items}>
			{items.length ? (
				items.map((item, index) => (
					<button
						className={`${s.item} ${
							index === selectedIndex ? s.isSelected : ""
						}`}
						key={index}
						onClick={() => selectItem(index)}
					>
						{item.translatedParam}
					</button>
				))
			) : (
				<div className={s.item}>No result</div>
			)}
		</div>
	);
});

export default MentionList;
