/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { LatLngLiteral } from "leaflet";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import { ExecutorStatus } from "../Executor";
import Order from "../Order";

class ExecutorLocations extends Base {
	static fromResponse(data: any): ExecutorLocations.Model {
		return {
			id: data.id,

			taxiServiceId: data.taxiServiceId,

			executorId: data?.executorId,
			executorCallSign: data?.executorCallSign,

			order: data?.order,

			car: data?.car,
			carId: data?.carId,
			carNumber: data?.carNumber,

			executor: {
				id: data.executor.id,

				orders:
					data?.executor?.executorToOrders?.map(
						(executorToOrder: any) => executorToOrder?.order,
					) ?? undefined,

				online: data?.executor?.online,
				isWorking: data?.executor?.workingStatus,
				hasActiveOrder: data?.executor?.hasActiveOrder,
				status: data?.executor?.status,
				alias: data?.executorCallSign,
			},

			accuracy: data?.accuracy,
			altitude: data?.altitude,
			date: data?.date,
			direction: data?.direction,

			point: data?.point,
			speed: data?.speed,
		};
	}

	public static async subscribe(
		options: ExecutorLocations.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<ExecutorLocations.Model>,
	): Promise<Subscription<ExecutorLocations.SubscribeOptions> | null> {
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.executorLocations.subscribe({
					params: this.optionsToRequest(options),
					onEvent: (events: any) => {
						// console.log(
						// 	"[ExecutorLocations] subscribe",
						// 	events.data,
						// );
						onUpdate({
							models: events.data.map(this.fromResponse),
							metadataState: {},
						});
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{
				name: "ExecutorLocations.subscribe",
				metadata: this.optionsToRequest(options),
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: ExecutorLocations.SubscribeOptions) => {
				console.log("ExecutorLocations.subscribe", options);
				return subscription.update(this.optionsToRequest(options));
			},
		} as Subscription<ExecutorLocations.SubscribeOptions>;
	}

	private static optionsToRequest(
		options: ExecutorLocations.SubscribeOptions,
	) {
		return {
			revealExecutor: options.revealExecutor,
			limit: options.limit,
			offset: options.offset,

			filters: {
				orderIds: options.orderIds,

				executorDebt: options.executorDebt,
				executorWorkingStatus: options.executorWorkingStatus,
				executorHasActiveOrder: options.executorHasActiveOrder,
				executorOnline: options.executorOnline,
				executorStatuses: options.executorStatuses,
				taxiServiceIds: options.taxiServiceIds,
			},
		};
	}
}

namespace ExecutorLocations {
	export interface Model {
		id: number;

		taxiServiceId: number;

		executor: {
			id: number;

			orders?: Order.Model[];

			online: boolean;
			isWorking: boolean;
			hasActiveOrder: boolean;
			status: ExecutorStatus;
			alias: string;
		};
		executorId: number;
		executorCallSign: string;

		order: any;

		car: {
			callSign: string;
			id: number;
			registrationNumber: string;
		};
		carId: number;
		carNumber: string;

		accuracy: number;
		altitude: number;
		date: number;
		direction: LatLngLiteral;

		point: LatLngLiteral;
		speed: number;
	}

	export interface SubscribeOptions {
		orderIds?: number[];

		revealExecutor?: boolean;
		limit?: number;
		offset?: number;

		executorDebt?: boolean;
		executorWorkingStatus?: boolean;
		executorHasActiveOrder?: boolean;
		executorOnline?: boolean;
		executorStatuses?: ExecutorStatus[];
		taxiServiceIds?: number[];
	}
}

export default ExecutorLocations;
