import styled from "styled-components";

const Root = styled.div<Root.Props>`
	position: relative;

	display: flex;

	flex-direction: row;
	flex-wrap: nowrap;

	align-items: center;

	width: fit-content;
	height: fit-content;

	border-radius: 9999px 9999px 9999px 0px;

	background-color: ${({ backgroundColor }) => backgroundColor};

	transform: translateY(calc(-100% - 11px));

	::after {
		content: "";
		position: absolute;

		left: 0px;
		bottom: -11px;

		border-top: 12.31px solid ${({ backgroundColor }) => backgroundColor};
		border-right: 20.43px solid transparent;
	}
`;

declare namespace Root {
	interface Props {
		backgroundColor: string;
	}
}

export default Root;
