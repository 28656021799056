/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

import ApplicationConfig from "../../types/ApplicationConfig";

type State = ApplicationConfig;

const initialState: State = {
	timezone: {
		name: "",
		zone: "+00:00",
		utcOffset: 0,
	},
};

type Reducer<P> = CaseReducer<State, PayloadAction<P>>;

const setConfig: Reducer<State> = (state, { payload }) => {
	Object.assign(state, payload);
};

const serverSettings = createSlice({
	name: "applicationConfig",
	initialState,
	reducers: {
		setConfig,
	},
});

export default serverSettings;
