import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const RideNumberCellContent: React.FC<RideNumberCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() => item.passengers?.at(0)?.rideNumber || 1,
		[item.passengers],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace RideNumberCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default RideNumberCellContent;
