import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Float } from "uikit";

import { useTypedSelector } from "../../../../../../redux/store";
import { Popup, StyledRow } from "../../../../../common";
import LabeledContent from "../LabeledContent";

import PopupContent from "./components/PopupContent";
import Content from "./components/Content";

const popupOffset = ({ tracker, float }: Float.OffsetCallbackOptions) => {
	const trackerSize = tracker?.getSize() ?? {
		width: 0,
		height: 0,
	};
	const floatSize = float?.getSize() ?? { width: 0, height: 0 };

	return {
		x: (Number(trackerSize.width) - Number(floatSize.width)) / 2,
		y: Number(trackerSize.height) + 10,
	};
};

const SIP = memo(() => {
	const { t } = useTranslation();

	const user = useTypedSelector((state) => state.account.user);

	const { sipValue, lineType } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const [showPopup, setShowPopup] = useState(false);

	const closePopup = useCallback(() => {
		setShowPopup(false);
	}, []);

	return (
		<Popup
			useClickControl
			open={showPopup}
			offset={popupOffset}
			tracker={
				<LabeledContent
					width={180}
					label={
						lineType === "Отсутствуют"
							? t("main_header.line")
							: lineType
					}
				>
					<Content>
						{sipValue || t("main_header.notSelected")}
					</Content>
				</LabeledContent>
			}
			onChangeOpen={setShowPopup}
		>
			<StyledRow
				p="1rem"
				b="1px solid #c5c6c7"
				br="5px"
				h={{ min: "100px" }}
			>
				<PopupContent userId={user?.id || -1} onClose={closePopup} />
			</StyledRow>
		</Popup>
	);
});

export default SIP;

/*
<Popup
	useClickControl
	open={isShowSipsList}
	trackerId="SipsList"
	styles={{ arrow: { left: "50%", top: "-5px" } }}
	offset={{ x: -30, y: 55 }}
	tracker={
		<StyledWrap
			w={{ min: "130px" }}
			h="auto"
			cursor="pointer"
			onClick={openSipsList}
		>
			<StyledSpanS>
				{lineType === "Отсутствуют"
					? "Линия"
					: lineType}
			</StyledSpanS>
			<StyledSpanM>
				{sipValue || t("main_header.notSelected")}
			</StyledSpanM>
		</StyledWrap>
	}
>
	<StyledRow
		p="1rem"
		b="1px solid #c5c6c7"
		br="5px"
		h={{ min: "100px" }}
	>
		<SipList
			userId={user?.id || -1}
			onClose={closeSipsList}
		/>
	</StyledRow>
</Popup>
*/
