import styled from "styled-components";
import { theme } from "uikit";

const Text = styled.div<{ color: string }>`
	font-family: Lato;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	color: ${({ color }) => color || theme.colors.primary};
`;

export default Text;
