import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";

export async function destroyOne(id: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() => prpc.theirsModel.dispatcher.delete(id));
}

export default { destroyOne };
