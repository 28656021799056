import { combineReducers } from "@reduxjs/toolkit";

import {
	archiveOrdersAction,
	archiveOrdersReducer,
	archiveOrdersState,
} from "./orders";
import {
	archiveOrdersFiltersReducer,
	archiveOrdersFiltersState,
	archiveOrdersFiltersAction,
} from "./filter";
import {
	archiveOrdersLoadingsAction,
	archiveOrdersLoadingsReducer,
	archiveOrdersLoadingsState,
} from "./loadings";
import {
	archiveOrdersTogglesAction,
	archiveOrdersTogglesReducer,
	archiveOrdersTogglesState,
} from "./toggles";
import {
	archiveOrdersErrorsAction,
	archiveOrdersErrorsReducer,
	archiveOrdersErrorsState,
} from "./errors";
import {
	archiveOrderPlayerAction,
	archiveOrderPlayerReducer,
	archiveOrderPlayerState,
} from "./player";

import {
	archiveOrdersHistoryAction,
	archiveOrdersHistoryReducer,
	archiveOrdersHistoryState,
} from "./history";

export type StateOrders = {
	orders: archiveOrdersState;
	filters: archiveOrdersFiltersState;
	loadings: archiveOrdersLoadingsState;
	toggles: archiveOrdersTogglesState;
	errors: archiveOrdersErrorsState;
	player: archiveOrderPlayerState;
	history: archiveOrdersHistoryState;
};

export const orders = combineReducers<StateOrders>({
	orders: archiveOrdersReducer,
	filters: archiveOrdersFiltersReducer,
	loadings: archiveOrdersLoadingsReducer,
	toggles: archiveOrdersTogglesReducer,
	errors: archiveOrdersErrorsReducer,
	player: archiveOrderPlayerReducer,
	history: archiveOrdersHistoryReducer,
});

export const archiveOrderAction = {
	orders: archiveOrdersAction,
	filters: archiveOrdersFiltersAction,
	loadings: archiveOrdersLoadingsAction,
	toggles: archiveOrdersTogglesAction,
	errors: archiveOrdersErrorsAction,
	player: archiveOrderPlayerAction,
	history: archiveOrdersHistoryAction,
};

export default orders;
