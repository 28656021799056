import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import ServicesCellContent from "../components/ServicesCellContent";

function services({ width, language, onResize }: Column.Options) {
	return base(
		ColumnId.Services,
		(item) => <ServicesCellContent item={item} language={language} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default services;
