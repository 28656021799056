/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { noop } from "lodash";

import { useTypedSelector } from "../../../../../../../../redux/store";
import { decompressRoute } from "../../../../../../../../utils/decompressRoute.util";

import { ColumnId } from "../../../../../../../../constants/tables/types";
import tables from "../../../../../../../../constants/tables";
import Nullable from "../../../../../../../../types/Nullable";
import TableSortMethod from "../../../../../../../../types/SortMethod";
import TableWidths from "../../../../../../../../types/TableWidths";

import ModelTable from "../../../../../../../../components/OrderPageWidgets/OrderTable/components/ModelTable";
import { useArchiveOrders, useArchiveSwitcher } from "../../../../hooks";

declare namespace ArchiveTable {
	export type LocalSortMethod = TableSortMethod<ColumnId<"orderArchive">>;

	interface Props {
		loading: boolean;
		offset: number;
		count: number;
		totalCount: number;

		onLimitChange: (limit: number) => void;
		onOffsetChange: (offset: number) => void;

		sortMethod: Nullable<LocalSortMethod>;
		onSortMethodChange: (sortMethod: LocalSortMethod) => void;

		columnWidths: TableWidths<ColumnId<"orderArchive">>;
		onColumnWidthChange?: (
			column: ColumnId<"orderArchive">,
			width: number,
		) => void;
		onColumnWidthsChange?: (
			widths: TableWidths<ColumnId<"orderArchive">>,
		) => void;

		visibleColumns: ColumnId<"orderArchive">[];
		onVisibleColumnsChange: (
			visibleColumns: ColumnId<"orderArchive">[],
		) => void;

		tableHeaderHeight: number;
		tableRowHeight: number;
	}
}

const ArchiveTable: React.FC<ArchiveTable.Props> = ({
	loading,
	// offset,
	// count,
	// totalCount,
	onLimitChange,
	// onOffsetChange,
	sortMethod,
	onSortMethodChange,
	columnWidths,
	onColumnWidthsChange,
	visibleColumns,
	onVisibleColumnsChange,
	// tableHeaderHeight,
	tableRowHeight,
}): JSX.Element => {
	const language = useTypedSelector((state) => state.session.language);
	const {
		orders: { orders, activeOrderId },
		setOrder,
		setActiveOrderId,
	} = useArchiveOrders();
	const {
		orderToggles: { isCancelOrderForm, isInfoModal },
		setCancelOrderForm,
		setInfoModalToggle,
	} = useArchiveSwitcher();

	// const [height] = useState(0);
	const [height] = useState(768);

	const visibleItems = useMemo(
		() => height / tableRowHeight,
		[height, tableRowHeight],
	);

	// const limit = useMemo(() => Math.round(visibleItems * 3), [visibleItems]);
	const limit = useMemo(() => Math.round(visibleItems), [visibleItems]);

	useEffect(() => {
		onLimitChange(limit);
	}, [onLimitChange, limit]);

	const openedOrder = useMemo(() => {
		if (activeOrderId == null) return undefined;

		let order = orders.find((o) => o.id === activeOrderId);

		if (order?.route && typeof order.route.points === "string") {
			order = {
				...order,
				route: decompressRoute(order.route) || order.route,
			};
		}

		return order;
	}, [orders, activeOrderId]);

	useEffect(() => {
		if (openedOrder) setOrder(openedOrder);
		if (isCancelOrderForm) setCancelOrderForm("off");
		// it should be like this
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openedOrder, isCancelOrderForm]);

	const modelTableSort = useMemo(() => {
		if (sortMethod) {
			return {
				column: sortMethod.column,
				type: sortMethod.type,
			};
		}

		return undefined;
	}, [sortMethod]);

	const columns = useMemo<ModelTable.Column[]>(
		() =>
			tables.orderArchive?.columns?.map((column) => ({
				id: column.id as ModelTable.ColumnId,
				width: columnWidths[column?.id],
				visible: visibleColumns.includes(
					column.id as ModelTable.ColumnId,
				),
			})),
		[columnWidths, visibleColumns],
	);

	const modelTableOnChangeSelected = useCallback(
		(id: number) => setActiveOrderId(id),
		[setActiveOrderId],
	);

	const modelTableOnChangeSort = useCallback(
		(sort: ModelTable.Sort) => {
			onSortMethodChange(sort);
		},
		[onSortMethodChange],
	);
	// --- open modal start ---
	const infoModalToggle = useCallback(() => {
		if (isInfoModal) setInfoModalToggle("off");
		else setInfoModalToggle("on");
	}, [isInfoModal, setInfoModalToggle]);

	const modelTableOnEdit = useCallback(
		(id: number) => {
			modelTableOnChangeSelected(id);
			infoModalToggle();
		},
		[infoModalToggle, modelTableOnChangeSelected],
	);
	// --- open modal end ---

	const modelTableOnChangeColumns = useCallback(
		(columns: ModelTable.Column[]) => {
			onVisibleColumnsChange(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);

			onColumnWidthsChange?.(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;

					return accumulator;
				}, {} as TableWidths<ColumnId<"orderArchive">>),
			);
		},
		[onColumnWidthsChange, onVisibleColumnsChange],
	);

	// scroll - start

	// const [height, setHeight] = useState<number>(-1);
	// const [width, setWidth] = useState<number>(-1);
	// const handleScroll = useCallback(
	// 	(x: number, y: number) => {
	// 		const top = Math.round(Math.abs(y));
	// 		const left = Math.round(Math.abs(x));
	// 		if (width >= left && height === top) return;
	// 		if (width < left && width !== 999999999) setWidth(left);
	// 		if (top === 0) return;
	// 		if (top > 0 && width < 999999999) setWidth(999999999);

	// 		const contextHeight = value.length * 46;
	// 		const tableContainerHeight =
	// 			tableRef.current?.root.getBoundingClientRect().height ?? 0;
	// 		const valueHeight = contextHeight - top - tableContainerHeight;

	// 		if (valueHeight < 300) {
	// 			if (height - 9 >= top) return;
	// 			setHeight(top);
	// 			setLimit((prev) => prev + 2);
	// 		}
	// 	},
	// 	[width, height, value.length, setLimit],
	// );

	// scroll - end

	return (
		// <Column sizes="1fr auto" maxedWidth maxedHeight>
		// 	<div>
		// 		<Table
		// 			virtualized
		// 			activeClass
		// 			tableLoading={loading}
		// 			data={data}
		// 			shouldUpdateScroll={false}
		// 			defaultColumns={visibleTableColumns}
		// 			orderBy={
		// 				sortMethod
		// 					? {
		// 							column: sortMethod.column,
		// 							type: sortMethod.type || "asc",
		// 					  }
		// 					: undefined
		// 			}
		// 			headerHeight={tableHeaderHeight}
		// 			rowHeight={tableRowHeight}
		// 			activeOrder={activeOrder}
		// 			tableRef={tableRef}
		// 			handleScroll={handleScroll}
		// 			handleDoubleClick={handleDoubleClick}
		// 			handleRowClick={handleRowClick}
		// 			handleColumnSort={handleColumnSort}
		// 			handleColumnResize={handleColumnResize}
		// 			footer={<TableFooterMemo totalCount={totalCount} />}
		// 		/>
		// 	</div>

		// 	<TableSettings
		// 		value={visibleColumns}
		// 		onChange={handleVisibleColumnsChange}
		// 		columns={translatedDefaultColumns}
		// 		defaultValue={tables.orderArchive.defaultIds}
		// 	/>
		// </Column>
		<ModelTable
			selected={activeOrderId ?? undefined}
			sort={modelTableSort}
			columns={columns}
			loading={loading}
			data={orders}
			language={language}
			onChangeSelected={modelTableOnChangeSelected}
			onChangeSort={modelTableOnChangeSort}
			onChangeColumns={modelTableOnChangeColumns}
			onEdit={modelTableOnEdit}
			onLoadMore={noop}
		/>
	);
};

export default ArchiveTable;
