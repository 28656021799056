import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
	TransactionMetadataAction,
	TransactionMetadataContext,
} from "../../../../../../../../../../services/Transaction/types";
import Transaction from "../../../../../../../../../../services/Transaction";
import { Language } from "../../../../../../../../../../services";
import {
	StyledP,
	StyledRow,
} from "../../../../../../../../../../components/common";

const Comment: React.FC<Comment.Props> = memo(
	({ operation, metadata, lang }) => {
		const { t } = useTranslation();

		const {
			text0,
			text1,
			text2,
			text3,
			text4,
			text5,
			period,
			from,
			to,
		}: Record<string, string> = useMemo(() => {
			const getTranslation = (key: string) =>
				t(
					`pages.mainPage.pages.archives.tabs.transactions.content.comment.${key}`,
				) ?? "";

			return {
				text0: getTranslation("str200"),
				text1: getTranslation("str201"),
				text2: getTranslation("str202"),
				text3: getTranslation("str203"),
				text4: getTranslation("str205"),
				text5: getTranslation("str206"),
				period: getTranslation("str207"),
				from: getTranslation("str208"),
				to: getTranslation("str209"),
			};
		}, [t]);

		const formatAction = useCallback(
			(action?: string) => {
				if (action === TransactionMetadataAction.CHARGE) {
					return text0;
				}
				if (action === TransactionMetadataAction.REFUND) {
					return text1;
				}
				if (action === TransactionMetadataAction.RECALCULATE) {
					return text2;
				}
				return "";
			},
			[text0, text1, text2],
		);

		const additionalComment = useMemo(() => {
			const formatContext = (context?: string) => {
				if (context === TransactionMetadataContext.SUBSCRIPTION_FEE) {
					return text3;
				}

				if (context === TransactionMetadataContext.COMMISSION_FEE) {
					return text4;
				}

				if (
					context ===
					TransactionMetadataContext.DISCOUNTED_COMMISSION_FEE
				) {
					return text5;
				}

				return "";
			};

			const name =
				operation?.metadata?.entity?.name?.[lang] ||
				metadata?.entity?.name?.[lang] ||
				"";
			const action = formatAction(
				operation?.metadata?.action || metadata?.action,
			);

			const context = formatContext(
				operation?.metadata?.context || metadata?.context,
			);

			const timeFrom =
				operation?.metadata?.entity?.period?.from ||
				metadata?.entity?.period?.from;

			const formatTimeFrom = timeFrom
				? new Date(timeFrom)?.toLocaleDateString?.()
				: "";

			const timeTo =
				operation?.metadata?.entity?.period?.to ||
				metadata?.entity?.period?.to;

			const formatTimeTo = timeTo
				? new Date(timeTo)?.toLocaleDateString?.()
				: "";

			return `${name ? action : ""}${name ? context : ""}${name || ""} ${
				name && timeFrom && timeTo ? period : ""
			}${name && timeFrom && timeTo ? from : ""} ${
				name && timeFrom && timeTo ? formatTimeFrom : ""
			}${name && timeFrom && timeTo ? to : ""}${
				name && timeFrom && timeTo ? formatTimeTo : ""
			}`.trim();
		}, [
			operation?.metadata?.entity?.name,
			operation?.metadata?.entity?.period?.from,
			operation?.metadata?.entity?.period?.to,
			operation?.metadata?.action,
			operation?.metadata?.context,
			lang,
			metadata?.entity?.name,
			metadata?.entity?.period?.from,
			metadata?.entity?.period?.to,
			metadata?.action,
			metadata?.context,
			formatAction,
			period,
			from,
			to,
			text3,
			text4,
			text5,
		]);

		return (
			<StyledRow alignItems="center" gap="8px" w="100%">
				<StyledP>
					{metadata?.comment
						? t([
								`transactionTypeComment.${metadata.comment}`,
								`transactionTypeComment.${metadata.context}`,
						  ])
						: metadata.comment || ""}
				</StyledP>
				{additionalComment && <StyledP>{additionalComment}</StyledP>}
			</StyledRow>
		);
	},
);

declare namespace Comment {
	interface Props {
		operation: Transaction.Model["operation"];
		metadata: Transaction.Model["metadata"];
		lang: Language;
	}
}

export default Comment;
