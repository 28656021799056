import React from "react";

import { SVGgeneral } from "./generalSprite";

const DOCUMENT_ICONS = [
	"ai",
	"eps",
	"cdr",
	"svg",
	"ps",
	"doc",
	"docx",
	"ppt",
	"pptx",
	"xlsx",
	"xls",
	"rtf",
	"gif",
	"bmp",
	"png",
	"jpg",
	"psd",
	"txt",
	"pdf",
	"mpeg",
	"wma",
	"aac",
	"mp3",
	"mov",
	"mkv",
	"mpg",
	"avi",
	"mp4",
	"flv",
	"html",
	"java",
	"php",
	"xml",
	"css",
	"htm",
	"js",
	"iso",
	"rar",
	"zip",
	"rss",
	"bin",
	"sys",
	"ini",
	"hlp",
	"swf",
	"db",
	"exe",
	"sql",
	"aut",
	"cad",
	"csv",
];

const getValidIconName = (ext) => {
	if (DOCUMENT_ICONS.includes(ext)) return ext;
	if (ext === "jpeg") return "jpg";
	switch (ext) {
		case "jpeg":
			return "jpg";
		default:
			return "bin";
	}
};

const SVGIcon = ({ name, style }) => (
	<SVGgeneral
		id={`document-icon-${getValidIconName(name)}`}
		style={{ width: 62, height: 62, objectFit: "cover", ...style }}
	/>
);

export default function taskMenuDownloadIcon(name, style) {
	return <SVGIcon name={name.toLowerCase().trim()} style={style} />;
}
