import React from "react";
import { Column, ColumnId } from ".";
import PhoneCellContent from "../components/PhoneCellContent";
import base from "./base";

function phone({ width, onResize }: Column.Options) {
	return base(ColumnId.Phone, (item) => <PhoneCellContent item={item} />, {
		width,
		sortable: false,

		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default phone;
