import React, { Suspense, useMemo, lazy } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import {
	StyledRow,
	SuspenseLoader,
	SideTab,
} from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyCustomers = lazy<React.FC<BlacklistPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Customers" /* webpackChunkName: "blacklist-page-customers" */
	);
	return elem;
});

const LazyExecutors = lazy<React.FC<BlacklistPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Executors" /* webpackChunkName: "blacklist-page-executors" */
	);
	return elem;
});

const LazyTransport = lazy<React.FC<BlacklistPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Transport" /* webpackChunkName: "blacklist-page-transport" */
	);
	return elem;
});

const BlacklistPage: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.BLACKLIST_CUSTOMERS,
					label: t("mainPage.blacklist.customers.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyCustomers />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.BLACKLIST_CUSTOMERS],
				},
				{
					key: TabKeys.BLACKLIST_EXECUTORS,
					label: t("mainPage.blacklist.executors.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutors />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.BLACKLIST_EXECUTORS],
				},
				{
					key: TabKeys.BLACKLIST_TRANSPORT,
					label: t("mainPage.blacklist.transport.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyTransport />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.BLACKLIST_TRANSPORT],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace BlacklistPage {
	interface Props {}
}

export default BlacklistPage;
