import React from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { MessageTemplateActions } from "../../../../../../../../../../../../types/MessageTemplateEnums";

const Root = styled.div`
	position: relative;

	width: 100%;

	padding: 6px 12px;

	background: white;

	color: ${theme.colors.primary};

	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;

	user-select: none;

	&:hover {
		&::before {
			content: "";

			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.05);
		}
	}

	&:active {
		&::before {
			content: "";

			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.1);
		}
	}
`;

const Item: React.FC<Item.Props> = ({ value, onClick }) => (
	<Root onClick={onClick}>{value.label}</Root>
);

declare namespace Item {
	interface Value {
		label: string;
		value: MessageTemplateActions;
	}
	interface Props {
		value: Value;
		onClick: VoidFunction;
	}
}

export default Item;
