import React, {
	PropsWithChildren,
	memo,
	useMemo,
	useCallback,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Column,
	InputBorders,
	TextBox,
	Button,
	Icon,
	useInternal,
} from "uikit";
import styled from "styled-components";

import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	IOrderPoint,
	stringifyPoint,
} from "../../../../../../../../redux/constants/OrdersPage/order";
import { Orders } from "../../../../../../../../redux/reducers/Orders";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
	Select,
	Divider,
	Style,
	Popup,
} from "../../../../../../../../components/common";
import { ToggleBadge } from "../../../../../components";
import { useGetSector } from "../../hooks/useGetSector";
import useMapActions from "../../hooks/useMapActions";

import OverlayAllTips from "./components/OverlayAllTips";

const SearchTextBox = styled(TextBox.TextBox)`
	width: 100%;
	min-width: 100%;
	height: 28px;

	.${InputBorders.classes.borders} {
		padding: 6px;
		border: none;
	}

	.${TextBox.classes.input} {
		line-height: 18px;
		width: 100%;
		height: 18px;
	}
`;

const CloseIconBorders = styled(Button.Button)`
	min-width: 18px;
	width: 18px;
	height: 28px;
	:hover {
		background: #ffffff;
		color: #03a9f4;
	}
`;

const MapHeader = styled(StyledRow)`
	height: 50px;
	width: 100%;
	background: #ffffff;
	overflow: hidden;
`;

const ButtonOrderMap = styled(Button.Button)`
	min-width: 28px;
	width: 28px;
	height: 28px;
	margin: 0 10px;
`;

const BaseMapController: React.FC<BaseMapController.Props> = ({
	point,
	disabled = false,
	activeCard,
	mapRevealTipsState,

	allTips,
	setPoint,
	setAllowMapCentralization,
	handleAddPoint,
	debounceRequestAddress,
	setPermissionBounds,
	selectRevealTip,

	displayBadges,
	setDisplayBadges,
	children,
}) => {
	const { t } = useTranslation();
	const { setALLBtnToggles } = useMapActions();

	const { user } = useTypedSelector((state) => state.account);
	const { get } = useGetSector();

	const [activeId, setActiveOption] = useInternal<number>(0);
	const [open, setOpen] = useInternal<boolean>(false);
	const [selectTips, setSelectTips] = useInternal<SelectPoint | null>(null);

	const internalValueEditor =
		useObjectEditor<Orders.MapFromAddModal.BtnOnMapToggles>(
			displayBadges,
			setDisplayBadges,
		);
	const routeValue = internalValueEditor.useGetter("route");
	const routeOnChange = internalValueEditor.useSetter("route");

	const carsValue = internalValueEditor.useGetter("cars");
	const carsOnChange = internalValueEditor.useSetter("cars");

	const sectorsValue = internalValueEditor.useGetter("sectors");
	const sectorsOnChange = internalValueEditor.useSetter("sectors");

	const parkingValue = internalValueEditor.useGetter("parking");
	const parkingOnChange = internalValueEditor.useSetter("parking");

	const priceZonesValue = internalValueEditor.useGetter("priceZones");
	const priceZonesOnChange = internalValueEditor.useSetter("priceZones");

	const allowBound = internalValueEditor.useGetter("allowBound");
	const allowBoundOnChange = internalValueEditor.useSetter("allowBound");

	const valueRouteOnChange = useCallback(
		(data: boolean) => routeOnChange(data),
		[routeOnChange],
	);
	const valueCarsOnChange = useCallback(
		(data: boolean) => carsOnChange(data),
		[carsOnChange],
	);
	const valueSectorsOnChange = useCallback(
		(data: boolean) => sectorsOnChange(data),
		[sectorsOnChange],
	);
	const valueParkingOnChange = useCallback(
		(data: boolean) => parkingOnChange(data),
		[parkingOnChange],
	);
	const valuePriceZonesOnChange = useCallback(
		(data: boolean) => priceZonesOnChange(data),
		[priceZonesOnChange],
	);
	const valueAllowBoundOnChange = useCallback(
		(data: boolean) => allowBoundOnChange(data),
		[allowBoundOnChange],
	);
	const tooltipMap = useMemo(
		() =>
			t("mainPage.archives.orders.modal.map.badges.sectors") || `Sectors`,
		[t],
	);
	const tooltipParking = useMemo(
		() =>
			t("mainPage.archives.orders.modal.map.badges.parking") || `Parking`,
		[t],
	);

	const tooltipPriceZone = useMemo(
		() =>
			t("mainPage.archives.orders.modal.map.badges.priceZones") ||
			`Price zones`,
		[t],
	);
	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);
	const tooltipRoute = useMemo(
		() => t("mainPage.archives.orders.modal.map.badges.route") || `Route`,
		[t],
	);

	const defaultSearch = () => {
		setPoint({
			address: { value: "" },
			city: user?.defaultTaxiService,
		});
	};

	const handleSelect = useCallback(
		(item) => {
			setPoint({
				...point,
				country: item.country,
				countryCode: item.countryCode,
				postalCode: item.postalCode || "",
				settlement: item.settlement,
				street: item.street || "",
				streetType: item.streetType,
				coordinates: item.coordinates,
				bbox: item.bbox,
				geometry:
					item?.geometry?.coordinates?.length === 1
						? item.geometry?.coordinates[0]
						: item.geometry?.coordinates,
				type: item?.geometry?.type,
				city: {
					value: `${item?.settlementType} ${item?.settlement} `,
				},
				address: {
					value:
						item?.address || item?.name || item?.street
							? `${item?.streetType} ${item?.street} `
							: t(
									"pages.mainPage.pages.orders.orderModal.mapController.str200",
							  ) ?? "",
				},
				house: item?.number || "",
				value: "",
			});
			setAllowMapCentralization(true);
		},
		[point, setAllowMapCentralization, setPoint, t],
	);

	const stylesToggle = useMemo<Style.Row>(() => {
		const payload: Style.Row = {
			w: "28px",
			h: "28px",
			b: "none",
			bgC: "#ffffff",
		};

		return payload;
	}, []);

	const selectOption = useCallback(
		(value: IOrderPoint, key: number) => ({
			key,
			value,
			label: stringifyPoint(value, "full", pointOnMap),
		}),
		[pointOnMap],
	);

	const tipsSelectOptions = useMemo<SelectPoint[]>(
		() => mapRevealTipsState.data?.map((item, i) => selectOption(item, i)),
		[mapRevealTipsState.data, selectOption],
	);

	const [selectAllTips, setSelectAllTips] = useInternal<SelectPoint | null>(
		null,
	);

	const handleSelectPoint = useCallback(
		async (option) => {
			const item = option as SelectPoint;

			const getSector = async () => {
				if (item.value?.sector === null && item?.value?.coordinates) {
					const sector = await get(item.value?.coordinates);
					if (sector) return { id: sector?.id, name: sector?.name };
				}
				return null;
			};

			const sector = await getSector();
			const point = { ...item?.value, sector };
			setSelectTips(item);
			selectRevealTip(point, item.key);
		},
		[get, selectRevealTip, setSelectTips],
	);

	const selectPointComponent = useMemo(() => {
		if (!mapRevealTipsState.visible || !tipsSelectOptions.length) {
			return <></>;
		}

		return (
			<StyledRow
				bgC="rgba(255, 255, 255, 0.9)"
				w="350px"
				position="absolute"
				m="5px 10px"
				left="0px"
				zi={402}
			>
				<Select
					disabled={disabled}
					icon={false}
					selected={selectTips?.key || 0}
					value={selectTips?.key || 0}
					options={tipsSelectOptions}
					closeOnSelect
					w="330px"
					maxWidthSelect="350px"
					maxHeightSelect="calc(27.5px * 5)"
					minHeightRowSelect="27px"
					onSelect={handleSelectPoint}
				/>
			</StyledRow>
		);
	}, [
		disabled,
		handleSelectPoint,
		mapRevealTipsState.visible,
		selectTips?.key,
		tipsSelectOptions,
	]);

	const styles = useMemo<Popup.Styles>(
		() => ({
			h: { max: "200px", min: "0px" },
			w: "clamp(310px, 80vw, 350px)",
		}),
		[],
	);
	const offset = useMemo<Popup.Props["offset"]>(() => ({ x: 0, y: 33 }), []);

	useEffect(() => {
		const allBtnToggles: Orders.MapFromAddModal.BtnOnMapToggles = {
			route: routeValue,
			cars: carsValue,
			sectors: sectorsValue,
			parking: parkingValue,
			priceZones: priceZonesValue,
			allowBound,
		};
		setALLBtnToggles(allBtnToggles);
	}, [
		setALLBtnToggles,
		routeValue,
		carsValue,
		sectorsValue,
		parkingValue,
		priceZonesValue,
		allowBound,
	]);

	return (
		<Column sizes={"38px 1fr"} style={{ height: "100%" }}>
			<MapHeader>
				<StyledRow
					w="100%"
					gap="2px"
					p="5px"
					justify="space-between"
					alignItems="center"
					position="absolute"
					zi={999}
				>
					<ButtonOrderMap
						variant="primary"
						disabled={disabled || activeCard?.lockedBy}
						onClick={handleAddPoint}
						icon={<Icon id="plus" size={16} />}
					/>
					<Popup
						trackerId={"map-search-text-id"}
						containerId={"orderModal"}
						offset={offset}
						style={{ width: "100%" }}
						styles={styles}
						useArrow={false}
						useClickControl
						open={open}
						onChangeOpen={setOpen}
						tracker={
							<SearchTextBox
								placeholder={`${
									t(
										"pages.mainPage.pages.orders.orderModal.mapController.str201",
									) ?? ""
								}...`}
								value={
									point?.value
										? point?.value
										: selectAllTips?.label || ""
								}
								disabled={disabled}
								onChange={(value) => {
									setPoint({ ...point, value });
									debounceRequestAddress();
									if (selectAllTips) setSelectAllTips(null);
								}}
							/>
						}
					>
						<OverlayAllTips
							data={allTips}
							activeId={activeId}
							setActiveOption={setActiveOption}
							onSelect={(option: SelectPoint) => {
								setSelectAllTips(option);
								handleSelect(option.value);
								setOpen(false);
							}}
						/>
					</Popup>

					<CloseIconBorders
						disabled={disabled}
						variant="white"
						icon={<Icon id="close-modal" size={12} />}
						onClick={(e) => {
							defaultSearch();
							setSelectAllTips(null);
							e.stopPropagation();
						}}
					/>

					<ButtonOrderMap
						disabled={disabled}
						variant="secondary"
						icon={<Icon id="filters" size={16} />}
					/>
				</StyledRow>
			</MapHeader>

			<StyledColumn h="100%">
				{selectPointComponent}
				{/* {selectAllTipsComponent} */}
				<StyledColumn
					gap="2px"
					p="2px"
					alignItems="center"
					justify="flex-start"
					right="10px"
					bgC="#ffffff"
					w="32px"
					top="50px"
					position="absolute"
					br="5px"
					zi={998}
				>
					<ToggleBadge
						icon="map-marker-path"
						tooltip={tooltipRoute}
						value={routeValue}
						onChange={(value) => {
							valueRouteOnChange(value);
							setPermissionBounds(true);
						}}
						colors={routeValue ? "#03A9F4" : "#21333F"}
						{...stylesToggle}
					/>
					<Divider side="top" />
					<ToggleBadge
						icon="map-price-zone"
						value={priceZonesValue}
						tooltip={tooltipPriceZone}
						onChange={valuePriceZonesOnChange}
						colors={priceZonesValue ? "#03A9F4" : "#21333F"}
						{...stylesToggle}
					/>
					<Divider side="top" />
					<ToggleBadge
						icon="map-sector"
						tooltip={tooltipMap}
						value={sectorsValue}
						onChange={valueSectorsOnChange}
						colors={sectorsValue ? "#03A9F4" : "#21333F"}
						{...stylesToggle}
					/>
					<Divider side="top" />
					<ToggleBadge
						icon="map-marker-circle"
						tooltip={tooltipParking}
						value={parkingValue}
						onChange={valueParkingOnChange}
						colors={parkingValue ? "#03A9F4" : "#21333F"}
						{...stylesToggle}
					/>
					<Divider side="top" />
					<ToggleBadge
						icon="map-drivers"
						tooltip={
							t(
								"pages.mainPage.pages.orders.orderModal.mapController.str202",
							) ?? ""
						}
						value={carsValue}
						onChange={valueCarsOnChange}
						size={18}
						colors={carsValue ? "#03A9F4" : "#21333F"}
						{...stylesToggle}
					/>
					<Divider side="top" />
					<ToggleBadge
						icon="passenger"
						tooltip={
							t(
								"pages.mainPage.pages.orders.orderModal.mapController.str203",
							) ?? ""
						}
						value={false}
						onChange={console.log}
						size={14}
						colors={"#21333F"}
						{...stylesToggle}
					/>
					<Divider side="top" />
					<ToggleBadge
						icon="map-gps"
						tooltip={
							t(
								"pages.mainPage.pages.orders.orderModal.mapController.str204",
							) ?? ""
						}
						value={allowBound}
						onChange={valueAllowBoundOnChange}
						size={18}
						colors={allowBound ? "#03A9F4" : "#21333F"}
						{...stylesToggle}
					/>
				</StyledColumn>
				{children}
			</StyledColumn>
		</Column>
	);
};

declare namespace BaseMapController {
	interface Props extends PropsWithChildren {
		disabled?: boolean;
		point: { [key: string]: any };
		activeCard: any;
		setPoint: (data: { [key: string]: any }) => void;
		setAllowMapCentralization: (data: boolean) => void;

		handleAddPoint: (data?: any) => void;
		debounceRequestAddress: () => void;

		allowMapFocusing: boolean;
		setPermissionBounds: React.Dispatch<React.SetStateAction<boolean>>;

		displayBadges: Orders.MapFromAddModal.BtnOnMapToggles;
		setDisplayBadges: React.Dispatch<
			React.SetStateAction<Orders.MapFromAddModal.BtnOnMapToggles>
		>;

		selectRevealTip: (object: any, index: number) => void;
		mapRevealTipsState: {
			visible: boolean;
			data: IOrderPoint[];
			activeIndex: number;
		};
		cityTipsVisible: boolean;
		allTips: IOrderPoint[];
	}
}

interface SelectPoint {
	key: number;
	value: IOrderPoint;
	label: string;
}

export const MapController = memo(BaseMapController);
export default BaseMapController;
