import React, { useMemo } from "react";
import moment from "moment";
import { isUndefined } from "lodash";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutingStageWaitingCustomerTimeCellContent: React.FC<
	ExecutingStageWaitingCustomerTimeCellContent.Props
> = ({ item }) => {
	const timestamp = useMemo(
		() => item.executingStages?.waiting_client?.timestamp,
		[item.executingStages?.waiting_client?.timestamp],
	);

	const content = useMemo(
		() =>
			isUndefined(timestamp) ? "" : moment(timestamp).format("HH:mm:ss"),
		[timestamp],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutingStageWaitingCustomerTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutingStageWaitingCustomerTimeCellContent;
