import { isString } from "lodash";

import { AccessKey } from ".";

const cache = new Map<string, string>();

/**
 * Function to generate a hierarchical access path (access name) for an element within
 * an access control tree using predefined keys.
 *
 * This utility takes multiple access keys (`AccessKey`), filters out non-string values,
 * and concatenates them into a dot-separated string representing the path in the access tree.
 * The resulting access name can be used to identify or retrieve permissions for specific
 * elements or nodes within a role-based access control (RBAC) structure.
 *
 * Key features:
 * - Filters and validates the provided keys to ensure they are strings.
 * - Caches previously computed access names to optimize performance and avoid redundant
 *   processing.
 * - If no valid keys are provided, it returns an empty string.
 * - Access names are generated in the format: `key1.key2.key3...`, where each key represents
 *   a specific node or branch in the access hierarchy.
 *
 * Parameters:
 * @param keys - A list of `AccessKey` values to form the access name path.
 *
 * Returns:
 * - A string representing the concatenated access path, or an empty string if no valid keys
 *   are provided.
 */

const generateAccessName = (...keys: AccessKey[]): string => {
	const validKeys = keys.filter(isString);

	if (validKeys.length === 0) {
		return "";
	}

	const cacheKey = validKeys.join(",");

	const cachedResult = cache.get(cacheKey);
	if (cachedResult !== undefined) {
		return cachedResult;
	}

	let result: string = validKeys[0];
	for (let i = 1; i < validKeys.length; i++) {
		result = `${result}.${validKeys[i]}`;
	}

	cache.set(cacheKey, result);

	return result;
};

export default generateAccessName;
