/* eslint-disable no-shadow */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import createProviderSms from "../../../../../redux/services/Settings/Message/ProviderSms/createProviderSms";
import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import subscribeProviderSms from "../../../../../redux/services/Settings/Message/ProviderSms/subscribeProviderSms";
import updateProviderSms from "../../../../../redux/services/Settings/Message/ProviderSms/updateProviderSms";
import deleteProviderSms from "../../../../../redux/services/Settings/Message/ProviderSms/deleteProviderSms";
import getProviderSms from "../../../../../redux/services/Settings/Message/ProviderSms/getProviderSms";
import DefaultPageHeader from "../../../../../components/DefaultPageHeader";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
import DeleteModal from "../../../../../components/DeleteModal";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../constants/access";

import defaultProvider from "./constants/defaultProvider";
import defaultValidationsValue from "./constants/defaultValidationsValue";
import Content from "./components/Content";
import Root from "./components/Root";
import { convertToUpdateData } from "./providerHelper";
import EditModal from "./components/EditModal";

const Provider: React.FC<SettingsTabProps> = () => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	const globalLang = useTypedSelector((state) => state.session.language);
	const { data } = useTypedSelector((state) => state.messageProviderSms);

	const [sort] = useState<Content.Sort>({});
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selected, setSelected] = useState<number[]>([]);
	const [validation, setValidation] = useState<{ [key: string]: any }>(
		defaultValidationsValue,
	);

	const test = getProviderSms();
	test();

	const onAdd = () => {
		setShowEditModal(true);
	};

	const onDelete = () => {
		setShowDeleteModal(true);
	};

	const onEdit = () => {
		setIsEditMode(true);
		setShowEditModal(true);
	};

	const onCloseEditModal = () => {
		setIsEditMode(false);
		setShowEditModal(false);
		setSelected([]);
		setValidation(defaultValidationsValue);
	};

	const onCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const onSave = async (value: any) => {
		if (isEditMode) {
			dispatch(
				updateProviderSms(
					selected[0],
					value,
					onCloseEditModal,
					setValidation,
				),
			);
		} else {
			dispatch(createProviderSms(value, onCloseEditModal, setValidation));
		}
	};

	const deleteProdivers = async () => {
		if (selected.length === 1) {
			dispatch(deleteProviderSms(selected[0]));
		} else if (selected.length > 1) {
			await selected.forEach((id) => {
				dispatch(deleteProviderSms(id));
			});
		}

		setSelected([]);
		onCloseDeleteModal();
	};

	useEffect(() => {
		dispatch(subscribeProviderSms());
	}, [dispatch]);

	const selectedProviderData = cloneDeep(
		data.filter((el) => el.id === selected[0]),
	)[0];

	const editData: any = convertToUpdateData(selectedProviderData as any);

	const headerTitle = isEditMode
		? t("pages.settings.pages.message.provider.str200") ?? ""
		: t("pages.settings.pages.message.provider.str201") ?? "";

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canDelete={selected.length >= 1}
				canEdit={selected.length === 1}
				onAdd={onAdd}
				onDelete={onDelete}
				onEdit={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.PROVIDER_SMS]}
			/>
			<Content
				data={data || []}
				language={globalLang}
				loading={false}
				onChangeSelected={setSelected}
				onChangeSort={() => ""}
				onEdit={onEdit}
				onLoadMore={() => ""}
				selected={selected}
				sort={sort}
			/>
			{showEditModal && (
				<EditModal
					language={globalLang}
					onCancel={onCloseEditModal}
					validation={validation}
					setValidation={setValidation}
					onSave={onSave}
					value={isEditMode ? editData : defaultProvider}
					headerTitle={headerTitle}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onConfirm={deleteProdivers}
					onCancel={onCloseDeleteModal}
				/>
			)}
		</Root>
	);
};

export default Provider;
