import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, RadioButton, Row, TextSelect, theme } from "uikit";

import ValueStepper from "../../../../../../../../../../../../../../../Tariffs/tabs/Auto/components/ValueStepper";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../../../redux/store";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../../../hooks";

enum Type {
	Always = "always",
	Sometimes = "sometimes",
}

enum Order {
	PER_DAY = "perDay",
	PER_PERIOD = "perPeriod",
	PER_SHIFT = "perShift",
}

const borderStyles = {
	border: `1px solid ${theme.colors.color_border_basic}`,
	borderRadius: theme.borderRadius.m,
	padding: 14,
	paddingTop: 10,
	paddingBottom: 10,
};

export const marginLeft = { marginLeft: 30 };

const FeeSetting = ({ options }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const tariffOptions = useTypedSelector(
		(state) => state.additionalTariff.options,
	);
	const precision = useMemo(
		() => tariffOptions?.additionalFields?.general?.rounding?.precision,
		[tariffOptions?.additionalFields?.general?.rounding?.precision],
	);

	const [settingsEnabled, setSettingsEnabled] = useState(false);
	const [typeValue, setTypeValue] = useState(Type.Always);

	const [orderType, setOrderType] = useState(Order.PER_DAY);

	const [orderEnabled, setOrderEnabled] = useState(false);

	const onChangeTypeValueAlways = useCallback(() => {
		if (!settingsEnabled) return null;
		return setTypeValue(Type.Always);
	}, [settingsEnabled]);

	const onChangeTypeValueSometimes = useCallback(() => {
		if (!settingsEnabled) return null;
		return setTypeValue(Type.Sometimes);
	}, [settingsEnabled]);

	const orderTypeDisabled = useMemo(
		() => !settingsEnabled || typeValue === Type.Always,
		[settingsEnabled, typeValue],
	);

	const orderTypeRadioDisabled = useMemo(
		() => orderTypeDisabled || !orderEnabled,
		[orderEnabled, orderTypeDisabled],
	);

	const onChangeOrderTypePerDay = useCallback(() => {
		if (orderTypeRadioDisabled) return null;
		return setOrderType(Order.PER_DAY);
	}, [orderTypeRadioDisabled]);

	const onChangeOrderTypePerPeriod = useCallback(() => {
		if (orderTypeRadioDisabled) return null;
		return setOrderType(Order.PER_PERIOD);
	}, [orderTypeRadioDisabled]);

	const onChangeOrderTypePerShift = useCallback(() => {
		if (orderTypeRadioDisabled) return null;
		return setOrderType(Order.PER_SHIFT);
	}, [orderTypeRadioDisabled]);

	const onChangeSettingsEnabled = useCallback((value) => {
		setSettingsEnabled(value);
	}, []);

	const onChangeOrderType = useCallback((value) => {
		setOrderEnabled(value);
	}, []);

	return (
		<>
			<Column gaps="10px*" style={borderStyles}>
				<CheckBox
					label={`${
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str200",
						) ?? ""
					}:`}
					value={settingsEnabled}
					onChange={onChangeSettingsEnabled}
				/>
				<Column style={marginLeft} gaps="5px*">
					<RadioButton.RadioButton
						disabled={!settingsEnabled}
						selected={typeValue === Type.Always}
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str0",
							) ?? ""
						}
						onClick={onChangeTypeValueAlways}
					/>
					<RadioButton.RadioButton
						disabled={!settingsEnabled}
						selected={typeValue === Type.Sometimes}
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str1",
							) ?? ""
						}
						onClick={onChangeTypeValueSometimes}
					/>
					<Row
						style={{ ...marginLeft, ...borderStyles }}
						align="center"
						gaps="30px*"
					>
						<CheckBox
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str2",
								) ?? ""
							}
							disabled={orderTypeDisabled}
							value={orderEnabled}
							onChange={onChangeOrderType}
						/>
						<Row
							gaps="30px*"
							align="center"
							style={{ paddingTop: 4 }}
						>
							<RadioButton.RadioButton
								selected={orderType === Order.PER_DAY}
								disabled={orderTypeRadioDisabled}
								onClick={onChangeOrderTypePerDay}
								label={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str201",
									) ?? ""
								}
							/>
							<RadioButton.RadioButton
								selected={orderType === Order.PER_PERIOD}
								disabled={orderTypeRadioDisabled}
								onClick={onChangeOrderTypePerPeriod}
								label={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str202",
									) ?? ""
								}
							/>
							<RadioButton.RadioButton
								selected={orderType === Order.PER_SHIFT}
								disabled={orderTypeRadioDisabled}
								onClick={onChangeOrderTypePerShift}
								label={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str203",
									) ?? ""
								}
							/>
						</Row>
					</Row>
				</Column>
			</Column>
			<Row gaps="10px*" align="center">
				<span>
					{t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str204",
					) ?? ""}
					:
				</span>
				<ValueStepper min={0.0} decimalCount={precision} />
				<span>{currencyGlobalSettings}</span>
				<TextSelect
					options={options}
					placeholder={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.feeSetting.str3",
						) ?? ""
					}
				/>
			</Row>
		</>
	);
};

export default FeeSetting;
