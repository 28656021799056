import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ID = "id",
	ORDER_NUMBER = "orderNumber",
	TYPE = "type",
	CREATED_AT = "createdAt",
	STATUS = "status",
	COMMENT = "comment",
	ACCOUNT_AMOUNT_AFTER = "accountAmountAfter",
	ACCOUNT_AMOUNT_BEFORE = "accountAmountBefore",
	DESCRIPTION = "description",
	TARGET_ACTION = "targetAction",
	TRANSACTION_AUTHOR = "transactionAuthor",
	TRANSACTION_AMOUNT = "transactionAmount",

	TO_TYPE = "toType",
	TO_IS_PROVIDER = "toIsProvider",
	TO_AMOUNT = "toAmount",
	TO_ENTITY_TYPE = "toEntityType",
	TO_CREDENTIALS = "toCredentials",

	FROM_TYPE = "fromType",
	FROM_IS_PROVIDER = "fromIsProvider",
	FROM_AMOUNT = "fromAmount",
	FROM_ENTITY_TYPE = "fromEntityType",
	FROM_CREDENTIALS = "fromCredentials",

	PAYMENT_ACCOUNT_ID = "paymentAccountId",
	PAYMENT_ACCOUNT_TYPE = "paymentAccountType",
	PAYMENT_ACCOUNT_PROVIDER = "paymentAccountIsProvider",
	PAYMENT_ACCOUNT_INTERNAL = "paymentAccountIsInternal",
	PAYMENT_ACCOUNT_AMOUNT = "paymentAccountAmount",
	PAYMENT_ACCOUNT_CONFIGURATION_TYPE = "paymentAccountConfigurationType",
	PAYMENT_ACCOUNT_CONFIGURATION_NAME = "paymentAccountConfigurationName",
	PAYMENT_ACCOUNT_CONFIGURATION_PAYMENT_SYSTEM = "paymentAccountConfigurationPaymentSystem",

	TARGET_PAYMENT_ACCOUNT_ID = "targetPaymentAccountId",
	TARGET_PAYMENT_ACCOUNT_TYPE = "targetPaymentAccountType",
	TARGET_PAYMENT_ACCOUNT_PROVIDER = "targetIsProvider",
	TARGET_PAYMENT_ACCOUNT_INTERNAL = "targetPaymentAccountIsInternal",
	TARGET_PAYMENT_ACCOUNT_AMOUNT = "targetPaymentAccountAmount",
	TARGET_PAYMENT_ACCOUNT_CONFIGURATION_TYPE = "targetPaymentAccountConfigurationType",
	TARGET_PAYMENT_ACCOUNT_CONFIGURATION_NAME = "targetPaymentAccountConfigurationName",
	TARGET_PAYMENT_ACCOUNT_CONFIGURATION_PAYMENT_SYSTEM = "targetPaymentAccountConfigurationPaymentSystem",
}

export const columns: Content.ColumnType[] = [
	{
		id: Columns.ID,
		label: "ID",
		width: 70,
		sortable: true,
	},
	{
		id: Columns.ORDER_NUMBER,
		label: "Номер заказа",
		width: 150,
		sortable: true,
	},
	{ id: Columns.CREATED_AT, label: "Время", width: 110, sortable: true },
	{ id: Columns.STATUS, label: "Статус", width: 120, sortable: true },
	{ id: Columns.TYPE, label: "Тип", width: 170, sortable: true },
	{
		id: Columns.ACCOUNT_AMOUNT_BEFORE,
		label: "Баланс до",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.TRANSACTION_AMOUNT,
		label: "Сума транзакции",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.ACCOUNT_AMOUNT_AFTER,
		label: "Баланс после",
		width: 150,
		sortable: false,
	},
	{
		id: Columns.COMMENT,
		label: "Комментарий",
		width: 200,
		sortable: true,
	},
	{
		id: Columns.TRANSACTION_AUTHOR,
		label: "Автор транзакции",
		width: 200,
		sortable: false,
	},
	{
		id: Columns.DESCRIPTION,
		label: "Комментарий к операции",
		width: 200,
		sortable: false,
	},
	// FROM
	{
		id: Columns.FROM_TYPE,
		label: "Откуда счёт",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.FROM_IS_PROVIDER,
		label: "Откуда тип счёта",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.FROM_AMOUNT,
		label: "Откуда баланс",
		width: 140,
		sortable: true,
	},

	{
		id: Columns.FROM_ENTITY_TYPE,
		label: "Откуда сущность",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.FROM_CREDENTIALS,
		label: "Откуда реквизиты сущности",
		width: 400,
		sortable: true,
	},

	// TO
	{
		id: Columns.TO_TYPE,
		label: "Куда счёт",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.TO_IS_PROVIDER,
		label: "Куда тип счёта",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.TO_AMOUNT,
		label: "Куда баланс",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.TO_ENTITY_TYPE,
		label: "Куда сущность",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.TO_CREDENTIALS,
		label: "Куда реквизиты сущности",
		width: 400,
		sortable: true,
	},

	//
	{
		id: Columns.PAYMENT_ACCOUNT_TYPE,
		label: "PAYMENT_ACCOUNT_TYPE",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.PAYMENT_ACCOUNT_PROVIDER,
		label: "PAYMENT_ACCOUNT_PROVIDER",
		width: 140,
		sortable: true,
	},

	{
		id: Columns.TARGET_ACTION,
		label: "TARGET ACTION",
		width: 50,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_TYPE,
		label: "TARGET TYPE",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_PROVIDER,
		label: "TARGET PROVIDER",
		width: 140,
		sortable: true,
	},

	{
		id: Columns.PAYMENT_ACCOUNT_AMOUNT,
		label: "AMOUNT",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_AMOUNT,
		label: "TARGET AMOUNT",
		width: 140,
		sortable: true,
	},

	{
		id: Columns.PAYMENT_ACCOUNT_ID,
		label: "PAYMENT_ACCOUNT_ID",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_ID,
		label: "TARGET_PAYMENT_ACCOUNT_ID",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.PAYMENT_ACCOUNT_INTERNAL,
		label: "INTERNAL",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_INTERNAL,
		label: "TARGET INTERNAL",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.PAYMENT_ACCOUNT_CONFIGURATION_NAME,
		label: "CONFIGURATION NAME",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.PAYMENT_ACCOUNT_CONFIGURATION_TYPE,
		label: "CONFIGURATION TYPE",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.PAYMENT_ACCOUNT_CONFIGURATION_PAYMENT_SYSTEM,
		label: "CONFIGURATION PAYMENT SYSTEM",
		width: 140,
		sortable: true,
	},

	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_CONFIGURATION_NAME,
		label: "TARGET CONFIGURATION NAME",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_CONFIGURATION_TYPE,
		label: "TARGET CONFIGURATION TYPE",
		width: 140,
		sortable: true,
	},
	{
		id: Columns.TARGET_PAYMENT_ACCOUNT_CONFIGURATION_PAYMENT_SYSTEM,
		label: "TARGET CONFIGURATION PAYMENT SYSTEM",
		width: 140,
		sortable: true,
	},
];

export const defaultColumnsId = columns.map(({ id }) => id);
