import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CounterpartyCellContent: React.FC<CounterpartyCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => {
		const payload = item?.counterparty?.additionalFields?.name;
		return payload || "";
	}, [item]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CounterpartyCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default CounterpartyCellContent;
