import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<Root.Props>`
	width: 100%;
	height: 100%;

	padding: ${({ hasPaddings }) => (hasPaddings ? "20px 24px" : "0")};

	overflow: auto;

	${theme.scrollbar}
`;

declare namespace Root {
	interface Props {
		hasPaddings: boolean;
	}
}

export default Root;
