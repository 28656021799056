import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)<Root.Props>`
	height: 32px;

	padding-inline: 10px 8px;

	background-color: #ffffff;

	${({ draggable }) => (draggable ? "cursor: grab;" : "")}

	overflow: hidden;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
	border-bottom: 1px solid #dee0e2;
`;

declare namespace Root {
	interface Props extends Row.Props {
		draggable: boolean;
	}
}

export default Root;
