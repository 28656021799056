import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Column, Icon, Nullable, Row, theme } from "uikit";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "../../../../../../redux/store";
import softphoneWidget, {
	Call,
} from "../../../../../../redux/reducers/softphoneWidget";
import { getAndCallDown, callUp } from "../../../../../../utils/jsSip";
import CallButtons from "./components/CallButtons";

const NumberInput = styled("input")`
	padding: 0px 15px;
	height: 48px;
	width: 100%;

	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;

	border-bottom: 1px solid ${theme.colors.color_border_basic};
	box-shadow: 0px 3px 10px 0px #0000000D;

	&::placeholder {
		color: ${theme.colors.disabled_text};
	}
	:focus {
		caret-color: ${theme.colors.accent};
`;

interface Props {
	children: ReactNode;
	selectedCall: Nullable<Call>;
}

const NumberKeyboard: React.FC<Props> = ({ children, selectedCall }) => {
	const dispatch = useDispatch();

	const { numberQuery, isFocused, isReduce } = useTypedSelector(
		(state) => state.softphoneWidget,
	);

	const onChangeQuery = useCallback(
		(e) => {
			dispatch(
				softphoneWidget.actions.setNumberQuery(e.currentTarget.value),
			);
		},
		[dispatch],
	);

	const inputRef = useRef<any>(null);

	const onFocus = useCallback(() => {
		setTimeout(() => {
			inputRef?.current.focus();
		}, 30);
	}, []);

	const onBlur = useCallback(() => {
		setTimeout(() => {
			inputRef?.current.blur();
		}, 30);
	}, []);

	const clearQuery = useCallback(() => {
		dispatch(softphoneWidget.actions.setNumberQuery(""));
		onFocus();
	}, [dispatch, onFocus]);

	useEffect(() => {
		if (isFocused) {
			onFocus();
		} else if (!isFocused) {
			onBlur();
		}
	}, [isFocused, onBlur, onFocus]);

	const { t } = useTranslation();
	return (
		<Column>
			<Row align="center" style={{ position: "relative" }}>
				<NumberInput
					placeholder={`${
						t("softphoneWidget.dialingTab.numberKeyboard.str200") ??
						""
					}...`}
					type="text"
					value={numberQuery}
					onChange={onChangeQuery}
					ref={inputRef}
				/>
				{numberQuery && (
					<Button.Button
						style={{
							backgroundColor: "transparent",
							position: "absolute",
							right: 15,
							top: 7,
						}}
						icon={
							<Icon
								id="backspace-number"
								size={80}
								style={{
									position: "absolute",
									top: 3,
									left: 2,
									width: 35,
									height: 35,
								}}
								colors={[theme.colors.secondary]}
							/>
						}
						onClick={clearQuery}
					/>
				)}
			</Row>
			{children}
			{isReduce && (
				<CallButtons
					receiveCall={callUp}
					rejectCall={getAndCallDown}
					selectedCall={selectedCall}
				/>
			)}
		</Column>
	);
};

export default NumberKeyboard;
