import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";
import mapByKey from "../../../../../../utils/mapByKey";

import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";
import translationPath from "./constants/translationPath";
import defaultExecutorTeam from "./constants/defaultItem";

const System = () => {
	const { t } = useTranslation();

	// const actualExecutorTeams = useModelSubscribe(
	// 	{
	// 		order: { action: "ASC" },
	// 	},
	// 	PushNotice,
	// );

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showClarificationModal, setShowClarificationModal] = useState(false);

	const [executorTeams] = useState<any[] | []>([]);
	// useEffect(() => {
	// 	setExecutorTeams(actualExecutorTeams.models);
	// }, [actualExecutorTeams.models]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(defaultExecutorTeam);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(
		() => mapByKey(executorTeams, "id"),
		[executorTeams],
	);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				...item,
			});
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		// (executorTeam: ExecutorTeam.Modified) => edit(executorTeam.id),
		() => {},
		[],
	);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		// ExecutorTeam.destroy(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, []);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultExecutorTeam);
	}, []);

	const saveHandler = useCallback(
		async (
			// template: ExecutorTeam.New | ExecutorTeam.Modified,
			template: any,
		) => {
			const isAllOk = true;

			if ("id" in template) {
				// isAllOk = await ExecutorTeam.update(template, force);
			} else {
				// isAllOk = await ExecutorTeam.store(template, force);
			}
			if (isAllOk) {
				setShowModal(false);
				setEditingItem(defaultExecutorTeam);
			} else {
				setShowClarificationModal(true);
			}
		},
		[],
	);

	const createdAt = editingItem.createdAt
		? new Date(editingItem.createdAt).toLocaleDateString()
		: new Date().toLocaleDateString();

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={
					!!selected.length &&
					!selected.some((id) => modelItemById[id].isDefault)
				}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
			/>
			<Content
				value={executorTeams}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				saveHandler={saveHandler}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={
						editingItem?.id
							? `${t(`${translationPath}.modal.title.edit`)} "${
									editingItem.name
							  }"`
							: t(`${translationPath}.modal.title.add`)
					}
					createdAt={createdAt}
					showClarificationModal={showClarificationModal}
					setShowClarificationModal={setShowClarificationModal}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						selected.length === 1
							? t(`${translationPath}.deleteModal.title`) || ""
							: t(`${translationPath}.deleteModal.title2`) || ""
					}
					onCancel={() => {
						setShowDeleteModal(false);
						setSelected([]);
					}}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default System;
