import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";
import * as yup from "yup";
import { cloneDeep } from "lodash";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { useCreateForm } from "../../../../../../../../../../../../../../hooks/useCreateForm";
import { StyledRow } from "../../../../../../../../../../../../../../components/common";
import EditModalBase from "../../../../../../../../../../../../../../components/EditModal";
import TariffScaleTab from "../..";

import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const Modal: React.FC<Modal.Props> = memo(
	({
		value,
		onCancel,
		onSave,
		validateInterval,
		isForAllRoute,
		headerTitle,
	}) => {
		const { t } = useTranslation();
		const [internalValue, setInternalValue] = useState(value);
		const [intervalError, setIntervalError] =
			useState<TariffScaleTab.IntervalError | null>(null);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const isActive = valueEditor.useGetter("active");
		const setIsActive = valueEditor.useSetter("active");

		const intervalWithoutActive = valueEditor.usePicker([
			"value",
			"minimal",
			"baseSettlement",
			"outsideSettlement",
			"outsideSettlementRoundTrip",
		]);
		const updateInterval = valueEditor.useAssigner();

		const schema = {
			active: yup.boolean(),
			value: yup.number(),
			minimal: yup.object(),
			baseSettlement: yup.object(),
			outsideSettlement: yup.object(),
			outsideSettlementRoundTrip: yup.object(),
		};

		const useForm = (values: any) =>
			useCreateForm<any>({
				yupObject: schema,
				values,
			});
		const form = useForm(cloneDeep(internalValue));

		const handleSubmit = useCallback(() => {
			const interval: TariffScaleTab.Interval = form.getValues();
			const validationResult = validateInterval(interval);

			if (!validationResult.valid) {
				setIntervalError(validationResult);
				return;
			}
			onSave(interval);
		}, [form, onSave, validateInterval]);

		return (
			<EditModalBase
				onCancel={onCancel}
				onSave={handleSubmit}
				footer={
					<StyledRow alignItems="center">
						<CheckBox
							label={
								t(
									"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.modal.str0",
								) ?? ""
							}
							value={isActive}
							onChange={setIsActive}
						/>
					</StyledRow>
				}
			>
				<Root>
					<Header title={headerTitle} />
					<Content
						value={intervalWithoutActive}
						intervalError={intervalError}
						onChange={updateInterval}
						isForAllRoute={isForAllRoute}
					/>
				</Root>
			</EditModalBase>
		);
	},
);

declare namespace Modal {
	interface Props {
		value: Value;
		headerTitle: string;
		validateInterval: (x: Value) => IntervalError;
		onCancel: () => void;
		onSave: (x: Value) => void;
		isForAllRoute: boolean;
	}

	type Value = TariffScaleTab.Interval;

	type IntervalError = TariffScaleTab.IntervalError;
}

export default Modal;
