export default class RuntimeError extends Error {
	status;

	/**
	 * Additional details about the error
	 */
	details = null;

	constructor(message, status, name, details) {
		super(message);
		this.message = message;
		this.name = name;
		this.status = status;
		this.details = details;

		Error.captureStackTrace(this, this.constructor);
	}
}
