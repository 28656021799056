import { RefObject } from "react";
import { react } from "uikit";
import ObjectContent from "../ObjectContent";

interface Context {
	objectContentRef: RefObject<ObjectContent.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isObjectContentValid =
			this.context?.objectContentRef.current?.validate();

		return isObjectContentValid;
	}
}
