/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../utils/generateOptions";

export enum AutoTariffsTabs {
	MAIN = "main",
	HISTORY = "history",
}

const tabs = getOptions(Object.values(AutoTariffsTabs));

export default tabs;
