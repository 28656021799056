import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { SortType } from "rsuite-table";

import {
	ordersAction as orders,
	Orders,
} from "../../../../redux/reducers/Orders"; // TODO replace in chat, all addresses with direct ones from src/redux/reducers/Orders

const useParkingActions = (): useParkingActions.Props => {
	const dispatch = useDispatch();

	const onSaveColumnsIdsTable = useCallback(
		(value: string[]) => {
			dispatch(orders.parking.tableSettings.setColumnIds(value));
		},
		[dispatch],
	);

	const onChangeFilter = useCallback(
		(value: Orders.Parking.ParkingFilter) => {
			dispatch(orders.parking.filter.setParkingsFilter(value));
		},
		[dispatch],
	);

	const onChangeParkingsQuery = useCallback(
		(value: string) => {
			dispatch(orders.parking.filter.setParkingsQuery(value));
		},
		[dispatch],
	);

	const onChangeParkingsPosition = useCallback(
		(value: number[]) => {
			dispatch(orders.parking.filter.setParkingsPosition(value));
		},
		[dispatch],
	);

	const setParkingsSort = useCallback(
		(value) => {
			dispatch(orders.parking.filter.setParkingsSort(value));
		},
		[dispatch],
	);

	return useMemo(
		() => ({
			setParkingsSort,
			onSaveColumnsIdsTable,
			onChangeFilter,
			onChangeParkingsQuery,
			onChangeParkingsPosition,
		}),
		[
			setParkingsSort,
			onSaveColumnsIdsTable,
			onChangeFilter,
			onChangeParkingsQuery,
			onChangeParkingsPosition,
		],
	);
};

declare namespace useParkingActions {
	interface Props {
		onSaveColumnsIdsTable: (value: string[]) => void;
		onChangeFilter: (value: Orders.Parking.ParkingFilter) => void;
		onChangeParkingsQuery: (value: string) => void;
		onChangeParkingsPosition: (value: number[]) => void;
		setParkingsSort: (sort: {
			column: string;
			type: SortType | undefined;
		}) => void;
	}
}

export default useParkingActions;
