import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Icon,
	InputGroup,
	Option,
	Row,
	Select,
	theme,
	useReactiveRef,
} from "uikit";
import { isNumber } from "lodash";
import TaxiService from "../../../../../../../../../../services/TaxiService";
import Language from "../../../../../../../../../../services/Language";
import Map from "../../../../../../../../../../redux/services/Map";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import SearchIconBorders from "../../../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../../../components/SearchTextBox";

const Filters = memo<Filters.Props>(({ value, onChange }) => {
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [selectedCompaniesRef, setSelectedCompaniesRef] = useReactiveRef<
		number[] | ["all"]
	>(["all"]);
	const [selectedTaxiServicesRef, setSelectedTaxiServicesRef] =
		useReactiveRef<number[] | ["all"]>(["all"]);

	const calculateAndSendTaxiServiceIdsOnChange = useCallback(() => {
		onChange({
			...value,

			taxiServiceIds:
				// eslint-disable-next-line no-nested-ternary
				selectedTaxiServicesRef.current[0] !== "all"
					? (selectedTaxiServicesRef.current as number[])
					: selectedCompaniesRef.current[0] !== "all"
					? taxiServices
							?.filter(
								(taxiService) =>
									isNumber(taxiService.company?.id) &&
									(
										selectedCompaniesRef.current as Array<number>
									)
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										.includes(taxiService.company!.id),
							)
							.map((taxiService) => taxiService.id)
					: undefined,
		});
	}, [
		onChange,
		selectedCompaniesRef,
		selectedTaxiServicesRef,
		taxiServices,
		value,
	]);

	const CTSFOnChangeCompanies = useCallback(
		(ids: number[] | ["all"]) => {
			setSelectedCompaniesRef(ids);

			calculateAndSendTaxiServiceIdsOnChange();
		},
		[calculateAndSendTaxiServiceIdsOnChange, setSelectedCompaniesRef],
	);

	const CTSFOnChangeTaxiServices = useCallback(
		(ids: number[] | ["all"]) => {
			setSelectedTaxiServicesRef(ids);

			calculateAndSendTaxiServiceIdsOnChange();
		},
		[calculateAndSendTaxiServiceIdsOnChange, setSelectedTaxiServicesRef],
	);

	const selectOnSelect = useCallback(
		(option: Option<Map.Language>) => {
			onChange?.({
				...value,

				language: option.value,
			});
		},
		[onChange, value],
	);

	const searchTextBoxOnChange = useCallback(
		(search: string) => {
			onChange?.({
				...value,

				search,
			});
		},
		[onChange, value],
	);

	const { t } = useTranslation();
	return (
		<Row sizes="200px!*2 66px! 270px!" gaps="12px 10px*" align="center">
			<CompaniesAndTaxiServicesFilter
				companies={selectedCompaniesRef.current}
				taxiServices={selectedTaxiServicesRef.current}
				language={value.language}
				onChangeCompanies={CTSFOnChangeCompanies}
				onChangeTaxiServices={CTSFOnChangeTaxiServices}
			/>
			<Select<Map.Language>
				options={Language.constants.select.options.shortened}
				value={value.language}
				onSelect={selectOnSelect}
			/>
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t(
							"pages.preferencesPages.screenDirectory.parkings.header.filters.str200",
						) ?? ""
					}...`}
					value={value.search}
					onChange={searchTextBoxOnChange}
				/>
			</InputGroup.InputGroup>
		</Row>
	);
});

declare namespace Filters {
	interface Value {
		taxiServiceIds: number[] | undefined;
		language: Map.Language;
		search: string;

		/* Additional filters */
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Filters;
