/* eslint-disable no-shadow */

import { defaults } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "uikit";

import Card from "../services/Card";

interface Service {
	subscribe: Card.Subscribe;
}

interface Options {
	debounce?: number;
}

const defaultOptions: Options = {
	debounce: 300,
};

function useCardSubscriber(
	Service: Service,
	options: Options = {},
): Card.Session | null {
	defaults(options, defaultOptions);

	const subscriptionRef = useRef<Card.Subscription | null>(null);
	const ServiceRef = useRef<Service | null>(null);
	const currentSubscribePromiseRef =
		useRef<Promise<Card.Subscription> | null>(null);
	const [data, setData] = useState<Card.Session | null>(null);

	const update = useCallback(async () => {
		if (ServiceRef.current !== Service) {
			ServiceRef.current = Service;

			const subscription = subscriptionRef.current;

			subscriptionRef.current = null;

			if (subscription) subscription.unsubscribe();

			const subscribePromise = Service.subscribe((newData) => {
				setData(newData);
			});

			currentSubscribePromiseRef.current = subscribePromise;

			const newSubscription = await subscribePromise;

			if (currentSubscribePromiseRef.current === subscribePromise)
				subscriptionRef.current = newSubscription;
			else newSubscription?.unsubscribe();
		}
	}, [Service]);

	const debouncedUpdate = useDebounce(update, options.debounce);

	useEffect(() => {
		debouncedUpdate();
	}, [debouncedUpdate]);

	useEffect(
		() => () => {
			(async () => {
				(await currentSubscribePromiseRef.current)?.unsubscribe();
			})();
		},
		[],
	);

	return data;
}

export default useCardSubscriber;
