import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, Row, Stepper } from "uikit";

import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../hooks";
import Label from "../../../../../../../../../../../../../../components/Label";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import UnitSelect from "../UnitSelect";

const Discount: React.FC<Discount.Props> = ({
	value,

	disabled = false,

	onChange,
}) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();
	const valueEditor = useObjectEditor(value, onChange);

	const maxDiscountEditor = valueEditor.usePropertyEditor("maxDiscount");

	const maxDiscountActiveValue = maxDiscountEditor.useGetter("active");
	const maxDiscountActiveOnChange = maxDiscountEditor.useSetter("active");

	const maxDiscountValueValue = maxDiscountEditor.useGetter("value");
	const maxDiscountValueOnChange = maxDiscountEditor.useSetter("value");

	const appliesToMinimalValue = valueEditor.useGetter("appliesToMinimal");
	const appliesToMinimalOnChange = valueEditor.useSetter("appliesToMinimal");

	const executorCompensationEditor = valueEditor.usePropertyEditor(
		"executorCompensation",
	);

	const executorCompensationActiveValue =
		executorCompensationEditor.useGetter("active");
	const executorCompensationActiveOnChange =
		executorCompensationEditor.useSetter("active");

	const executorCompensationValueValue =
		executorCompensationEditor.useGetter("value");
	const executorCompensationValueOnChange =
		executorCompensationEditor.useSetter("value");

	const executorCompensationUnitValue =
		executorCompensationEditor.useGetter("unit");
	const executorCompensationUnitOnChange =
		executorCompensationEditor.useSetter("unit");

	const executorCompensationMaxAmountEditor =
		executorCompensationEditor.usePropertyEditor("maxAmount");

	const executorCompensationMaxAmountActiveValue =
		executorCompensationMaxAmountEditor.useGetter("active");
	const executorCompensationMaxAmountActiveOnChange =
		executorCompensationMaxAmountEditor.useSetter("active");

	const executorCompensationMaxAmountValueValue =
		executorCompensationMaxAmountEditor.useGetter("value");
	const executorCompensationMaxAmountValueOnChange =
		executorCompensationMaxAmountEditor.useSetter("value");

	return (
		<FieldsContainer
			label={
				t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.discount.str0",
				) ?? ""
			}
		>
			<Column gaps="8px*">
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<CheckBoxWithContent
						value={maxDiscountActiveValue}
						disabled={disabled}
						onChange={maxDiscountActiveOnChange}
					>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.discount.str150",
						) ?? ""}
					</CheckBoxWithContent>
					<Stepper
						value={maxDiscountValueValue}
						disabled={disabled || !maxDiscountActiveValue}
						onChange={(value) =>
							maxDiscountValueOnChange(value ?? 0)
						}
					/>
					<div>{currencyGlobalSettings}</div>
				</Row>
				<CheckBoxWithContent
					value={appliesToMinimalValue}
					disabled={disabled}
					onChange={appliesToMinimalOnChange}
				>
					{t(
						"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.discount.str151",
					) ?? ""}
				</CheckBoxWithContent>
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<CheckBoxWithContent
						value={executorCompensationActiveValue}
						disabled={disabled}
						onChange={executorCompensationActiveOnChange}
					>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.discount.str152",
						) ?? ""}
					</CheckBoxWithContent>
					<Stepper
						value={executorCompensationValueValue}
						disabled={disabled || !executorCompensationActiveValue}
						onChange={(value) =>
							executorCompensationValueOnChange(value ?? 0)
						}
					/>
					<UnitSelect
						value={executorCompensationUnitValue}
						disabled={disabled || !executorCompensationActiveValue}
						onChange={executorCompensationUnitOnChange}
					/>
				</Row>
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ paddingLeft: 26, height: 32 }}
					maxedWidth
				>
					<CheckBoxWithContent
						value={executorCompensationMaxAmountActiveValue}
						disabled={disabled || !executorCompensationActiveValue}
						onChange={executorCompensationMaxAmountActiveOnChange}
					>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.discount.str150",
						) ?? ""}
					</CheckBoxWithContent>
					<Stepper
						value={executorCompensationMaxAmountValueValue}
						disabled={
							disabled ||
							!executorCompensationActiveValue ||
							!executorCompensationMaxAmountActiveValue
						}
						onChange={(value) =>
							executorCompensationMaxAmountValueOnChange(
								value ?? 0,
							)
						}
					/>
					<Label>{currencyGlobalSettings}</Label>
				</Row>
			</Column>
		</FieldsContainer>
	);
};

declare namespace Discount {
	interface Value {
		maxDiscount: {
			active: boolean;
			value: number;
		};

		appliesToMinimal: boolean;

		executorCompensation: {
			active: boolean;
			unit: CustomerRate.Model.Unit;
			value: number;

			maxAmount: {
				active: boolean;
				value: number;
			};
		};
	}

	interface Props {
		value: Value;

		disabled?: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Discount;
