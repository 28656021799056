import { LatLngLiteral } from "leaflet";

import { ValueLanguage } from "../../../assets/languages/langs";

import InternalPoint from "./Point";
import InternalLocalObject from "./LocalObject";
import InternalLocalObjectGroup from "./LocalObjectGroup";

namespace Map {
	export const Point = InternalPoint;
	export const LocalObject = InternalLocalObject;
	export const LocalObjectGroup = InternalLocalObjectGroup;

	export type LocalObject = InternalLocalObject;
	export type LocalObjectGroup = InternalLocalObjectGroup;

	export type Language = ValueLanguage;

	export type Resolution = "visicom" | "osm";

	export interface Object {
		type: Map.Object.Type;
		id: number | string;
		properties: Map.Object.Properties;
	}

	export interface Category {
		id: string;
		name: Record<Map.Language, string>;
		singleName: Record<Map.Language, string>;
	}

	export interface Near {
		point: LatLngLiteral;
		zoom?: number;
		radius?: number;
	}
}

declare namespace Map.LocalObject {
	export type New = InternalLocalObject.New;
	export type Modified = InternalLocalObject.Modified;
	export type ForSave = InternalLocalObject.ForSave;
}

declare namespace Map.LocalObjectGroup {
	export type New = InternalLocalObjectGroup.New;
	export type Modified = InternalLocalObjectGroup.Modified;
	export type ForSave = InternalLocalObjectGroup.ForSave;
	export type SubscribeOptions = InternalLocalObjectGroup.SubscribeOptions;
}

declare namespace Map.Object {
	export type Type =
		| "address"
		| "street"
		| "country"
		| "settlement"
		| "settlementDistrict"
		| "region"
		| "district"
		| "road"
		| "adminLevel3"
		| "adminPlace"
		| "localObject"
		| "object";

	export interface Properties {
		language: Partial<Record<Map.Language, any>>;
		categories: Map.Category[];
		bbox: LatLngLiteral[];
		geometry?: {
			type: "Point" | "Polygon" | "LineString";
			coordinates: LatLngLiteral[] | LatLngLiteral[][] | LatLngLiteral;
		};
		geoCentroid: LatLngLiteral;
	}
}

declare namespace Map.Search {
	export type Type =
		| "settlement"
		| "street"
		| "address"
		| "object"
		| "localObject";

	export interface Response {
		resolution: Map.Resolution;
		objects: Map.Object[];
	}

	export interface Params {
		searchType: Type | Type[];
		country: string;
		near: Map.Near;
		language: Map.Language;
	}
}

export default Map;
