import React, { PropsWithChildren } from "react";
import { Overlayer } from "uikit";

import Overlay from "./components/Overlay";

const Loader: React.FC<Loader.Props> = ({
	active = false,
	dimmed = true,
	children,
}) =>
	// eslint-disable-next-line no-nested-ternary
	children ? (
		<Overlayer
			style={{ width: "100%", height: "100%" }}
			overlay={active ? <Overlay dimmed={dimmed} maxedSize /> : null}
		>
			{children}
		</Overlayer>
	) : active ? (
		<Overlay dimmed={dimmed} maxedSize={false} />
	) : null;

declare namespace Loader {
	interface Props extends PropsWithChildren {
		active?: boolean;
		dimmed?: boolean;
	}
}

export default Loader;
