/* eslint-disable no-param-reassign */

import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { Column, Row, TextBox, react } from "uikit";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";

import InternalController from "./Controller";

const AuthDataBase = react.withController<
	AuthData.PropsBase,
	AuthData.Controller
>(({ value, onChange, validation }) => {
	const { t } = useTranslation();

	const { text0, text1, text2, text3 }: Record<string, string> =
		useMemo(() => {
			const getTranslation = (key: string) =>
				t(
					`pages.settings.pages.message.provider.editModal.content.mainTab.authData.${key}`,
				) ?? "";

			return {
				text0: getTranslation("str200"),
				text1: getTranslation("str201"),
				text2: getTranslation("str202"),
				text3: getTranslation("str203"),
			};
		}, [t]);

	const valueEditor = useObjectEditor(value, onChange);

	const hostValue = valueEditor.useGetter("host");
	const hostOnChange = valueEditor.useSetter("host");

	const portValue = valueEditor.useGetter("port");
	const portOnChange = valueEditor.useSetter("port");

	const loginValue = valueEditor.useGetter("login");
	const loginOnChange = valueEditor.useSetter("login");

	const passwordValue = valueEditor.useGetter("password");
	const passwordOnChange = valueEditor.useSetter("password");

	return (
		<Column gaps="10px">
			<Row gaps="16px*2">
				<LabeledField label={text0}>
					<TextBox.TextBox
						error={validation.host}
						value={hostValue}
						onChange={(e) => {
							hostOnChange(e);
							delete validation.host;
						}}
					/>
				</LabeledField>
				<LabeledField label={text1}>
					<TextBox.TextBox
						error={validation.port}
						value={portValue as string}
						onChange={(e) => {
							portOnChange(e);
							delete validation.port;
						}}
					/>
				</LabeledField>
			</Row>
			<Row gaps="16px*2">
				<LabeledField label={text2}>
					<TextBox.TextBox
						error={validation.login}
						value={loginValue}
						onChange={(e) => {
							loginOnChange(e);
							delete validation.login;
						}}
					/>
				</LabeledField>
				<LabeledField label={text3}>
					<TextBox.TextBox
						error={validation.password}
						value={passwordValue}
						onChange={(e) => {
							passwordOnChange(e);
							delete validation.password;
						}}
					/>
				</LabeledField>
			</Row>
		</Column>
	);
}, InternalController);

const AuthData = memo(AuthDataBase);

declare namespace AuthData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		host: string;
		port: string | number;
		login: string;
		password: string;
	}

	interface PropsBase {
		value: Value;
		validation: any;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default AuthData;
