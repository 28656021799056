import {
	DestAddrNpi,
	DestAddrTon,
	SourceAddrNpi,
	SourceAddrTon,
} from "../../../../../../redux/services/Settings/Message/ProviderSms/createProviderSms";
import {
	CheckSmsStatusMode,
	SmsProviderType,
} from "../../../../../../redux/services/Settings/Message/ProviderSms/enums";
import EditModal from "../components/EditModal";

const defaultProvider: EditModal.Value = {
	taxiServiceIds: [],
	name: "",
	host: "",
	port: 0,
	login: "",
	password: "",
	isSystemType: false,
	systemType: "",
	sourceAddrTon: SourceAddrTon.UNKNOWN,
	sourceAddrNpi: SourceAddrNpi.UNKNOWN,
	destAddrTon: DestAddrTon.UNKNOWN,
	destAddrNpi: DestAddrNpi.UNKNOWN,
	alphaName: "",
	isEnquireLink: false,
	enquireLink: 10,
	addPlus: false,
	active: false,
	smsProviderToTaxiServices: [],
	type: SmsProviderType.RESERVE,
	isMaxSmsPerSecond: false,
	maxSmsPerSecond: 10,
	checkSmsStatusMode: CheckSmsStatusMode.PASSIVE,
	activeQueryInterval: 60,
};

export default defaultProvider;
