import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useInternal } from "uikit";

import { OrderFormProps } from "../../../types/FormProps";
import { OrderCodeInfo } from "../../../../../../../../../redux/services/Order/getOrderCodeInfo";

export interface UseAgentBalance {
	mainBalance: number | null;
	setMainBalance: Dispatch<SetStateAction<number | null>>;
	mainBalanceType: OrderCodeInfo.PaymentAccount["type"] | null;
	setMainBalanceType: Dispatch<
		SetStateAction<OrderCodeInfo.PaymentAccount["type"] | null>
	>;

	bonusBalance: number | null;
	setBonusBalance: Dispatch<SetStateAction<number | null>>;
	bonusBalanceType: OrderCodeInfo.PaymentAccount["type"] | null;
	setBonusBalanceType: Dispatch<
		SetStateAction<OrderCodeInfo.PaymentAccount["type"] | null>
	>;

	currency: string | null;
	setCurrency: Dispatch<SetStateAction<string | null>>;

	setPaymentAccounts: (arr: OrderCodeInfo.PaymentAccount[]) => void;
	resetBalance: () => void;
}

export type UseAgentBalanceProps = (
	props: Pick<OrderFormProps, "form" | "tab">,
) => UseAgentBalance;

/**
 *  `useAgentBalance`
 *
 */
export const useAgentBalance: UseAgentBalanceProps = ({ form, tab }) => {
	const [currency, setCurrency] =
		useInternal<UseAgentBalance["currency"]>(null);

	const [bonusBalance, setBonusBalance] =
		useState<UseAgentBalance["bonusBalance"]>(null);

	const [bonusBalanceType, setBonusBalanceType] = useState<
		OrderCodeInfo.PaymentAccount["type"] | null
	>(null);

	const [mainBalance, setMainBalance] =
		useState<UseAgentBalance["mainBalance"]>(null);

	const [mainBalanceType, setMainBalanceType] = useState<
		OrderCodeInfo.PaymentAccount["type"] | null
	>(null);

	const resetBalance = useCallback(() => {
		setCurrency(null);
		setMainBalance(null);
		setMainBalanceType(null);
		setBonusBalance(null);
		setBonusBalanceType(null);

		form.setValue("agent", undefined);
		form.setValue("agentMainPaymentAccount", undefined);
		form.setValue("agentBonusPaymentAccount", undefined);
		form.setValue("paymentType", "cash");
	}, [form, setCurrency]);

	const setPaymentAccounts = useCallback<
		UseAgentBalance["setPaymentAccounts"]
	>(
		(arr) => {
			if (!Array.isArray(arr)) {
				resetBalance();

				return;
			}

			const main = arr.find((item) => item.type === "main");
			const bonus = arr.find((item) => item.type === "bonus");

			if (main) {
				setMainBalance(main.amount);
				setMainBalanceType(main.type);
				setCurrency(main.currency);

				form.setValue("agentMainPaymentAccount", main);
			}
			if (bonus) {
				setBonusBalance(bonus.amount);
				setBonusBalanceType(bonus.type);
				form.setValue("agentBonusPaymentAccount", bonus);
			}
		},
		[form, resetBalance, setCurrency],
	);

	const agentMainPaymentAccount = useMemo(
		() => tab.form?.agentMainPaymentAccount,
		[tab.form?.agentMainPaymentAccount],
	);

	const agentBonusPaymentAccount = useMemo(
		() => tab.form?.agentBonusPaymentAccount,
		[tab.form?.agentBonusPaymentAccount],
	);

	useMemo(() => {
		const type = agentMainPaymentAccount?.type || null;
		const amount = agentMainPaymentAccount?.amount || 0;
		const currency = agentMainPaymentAccount?.currency || "";

		if (type === "threshold") return;

		setMainBalance(amount);
		setMainBalanceType(type);

		setCurrency(currency);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		agentMainPaymentAccount?.amount,
		agentMainPaymentAccount?.type,
		agentMainPaymentAccount?.currency,
	]);

	useMemo(() => {
		const type = agentBonusPaymentAccount?.type || null;
		const amount = agentBonusPaymentAccount?.amount || 0;

		if (type === "threshold") return;

		setBonusBalance(amount);
		setBonusBalanceType(type === "bonus" ? "bonus" : null);
	}, [agentBonusPaymentAccount?.amount, agentBonusPaymentAccount?.type]);

	return useMemo<UseAgentBalance>(
		() => ({
			mainBalance,
			setMainBalance,
			mainBalanceType,
			setMainBalanceType,

			bonusBalance,
			setBonusBalance,
			bonusBalanceType,
			setBonusBalanceType,

			resetBalance,
			setPaymentAccounts,
			currency,
			setCurrency,
		}),
		[
			mainBalance,
			setMainBalance,
			mainBalanceType,
			setMainBalanceType,

			bonusBalance,
			setBonusBalance,
			bonusBalanceType,
			setBonusBalanceType,

			resetBalance,
			setPaymentAccounts,
			currency,
			setCurrency,
		],
	);
};
