import makeTable from "./makeTable";

const cashlessOrdersPeriodReport = makeTable({
	allIds: [
		"erpou",
		"account",
		"companyName",
		"balanceFromDate",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"balanceToDate",
		"periodBalanceUah",
	],
	i18tPath: (id) =>
		`mainPage.reports.accounting.cashlessOrdersPeriodReport.columns.${id}`,
	defaultIds: [
		"erpou",
		"account",
		"companyName",
		"balanceFromDate",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"balanceToDate",
		"periodBalanceUah",
	],
	initialSortMethod: {
		column: "companyName",
		type: "asc",
	},
	initialWidths: {
		erpou: 85,
		account: 75,
		companyName: 260,
		balanceFromDate: 165,
		replenishmentUah: 120,
		orderCount: 150,
		orderAmountUah: 165,
		balanceToDate: 165,
		periodBalanceUah: 165,
	},
	initialResizable: {
		erpou: true,
		account: true,
		companyName: true,
		balanceFromDate: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		balanceToDate: true,
		periodBalanceUah: true,
	},
	initialSortable: {
		erpou: true,
		account: true,
		companyName: true,
		balanceFromDate: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		balanceToDate: true,
		periodBalanceUah: true,
	},
});

export default cashlessOrdersPeriodReport;
