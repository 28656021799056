import { TFunction } from "i18next";

import Message from "../../../../../../../../services/Message";

export const getMessageType = (t: TFunction, type: Message.SmsMessageType) => {
	if (type === "info")
		return (
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.message.types.info",
			) ?? ""
		);
	if (type === "tech")
		return (
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.message.types.tech",
			) ?? ""
		);
	if (type === "order")
		return (
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.message.types.order",
			) ?? ""
		);
	return "";
};

export const getMessageStatus = (
	t: TFunction,
	status: Message.SmsMessageSimpleStatus,
) => {
	if (status === "UNDELIVERABLE")
		return (
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.message.statuses.undeliverable",
			) ?? ""
		);
	if (status === "DELIVERED")
		return (
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.message.statuses.delivered",
			) ?? ""
		);
	return "";
};

export const getSender = (t: TFunction, sender) => {
	if (sender === "system")
		return (
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.message.sender.system",
			) ?? ""
		);
	return sender;
};
