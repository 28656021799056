import { cloneDeep } from "lodash";

import constants from "../../../constants/Accounts/Transports";

const initialState = {
	transports: [],
};
export default function transports(state = initialState, action: any = {}) {
	switch (action.type) {
		case constants.setTransports:
			return {
				...state,
				transports: cloneDeep(action.payload),
			};
		default:
			return state;
	}
}
