import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PriceZone from "../../../../services/PriceZone";
import { useTypedSelector } from "../../../../redux/store";
import useModelSubscribe from "../../../../hooks/useModelSubscribe";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectPriceZoneWithModalBase: React.FC<
	MultiSelectPriceZoneWithModalBase.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	...props
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const subscribe = useModelSubscribe({}, PriceZone);

	const [error, setError] = useState("");

	const items = useMemo(() => subscribe?.cache || [], [subscribe?.cache]);

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[lang],
				value: item.id,
				name: item.name?.[lang],
				data: item,
			})),
		[items, lang],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const Ids = newValue.map((item) => item.value);
			onChange(Ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t(["all_prise_zone"]),
			allActiveText: t(["all_active_prise_zone"]),
		}),
		[t, titleText],
	);

	return (
		<MultiSelectWithModal
			value={selected}
			onChange={selectOnChange}
			options={selectOptions}
			error={error}
			disabled={disabled}
			title={texts.title}
			allText={texts.allText}
			allActiveText={texts.allActiveText}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			modalSetting={modalSetting}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectPriceZoneWithModal = memo(
	MultiSelectPriceZoneWithModalBase,
);

declare namespace MultiSelectPriceZoneWithModalBase {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
	}

	type Value = number[];
}

export default MultiSelectPriceZoneWithModalBase;
