import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme, Icon } from "uikit";

import { ChatMessage as ChatMessageServices } from "../../../../../services";
import { StyledRow } from "../../../../common";
import { isObject, getUserData, getFullTitle } from "../../tools";
import { useChatActions, useElementSize } from "../../hooks";

const Root = styled(StyledRow)`
	display: flex;
	// flex-direction: column;
	height: auto;
	width: 100%;
	padding: 15px 12px 12px;
	border-top: 1px solid ${theme.colors.color_border_basic};
`;

const TextGroup = styled(StyledRow)`
	display: flex;
	flex-direction: column;
	height: auto;
	width: 100%;
`;
const TextLine1 = styled.p`
	font-size: 14px;
	color: ${theme.colors.primary};
	margin-bottom: 8px;
`;

const TextLine2 = styled.p`
	font-size: 12px;
	color: ${theme.colors.secondary};
`;

const ButtonClose = styled.button``;

const AnswerShow: React.FC<AnswerShow.Props> = ({ data }) => {
	const { t } = useTranslation();
	const { messageParent } = data;

	const { onCleanAllAnswerParent, openAnswerComponent } = useChatActions();
	const [ref, size] = useElementSize();

	useEffect(() => {
		openAnswerComponent(size.height ?? 0);
	}, [size.height, openAnswerComponent]);

	if (!isObject(messageParent)) return null;

	const userData = getUserData(messageParent);

	if (!userData) return null;

	const contentAnswer = messageParent?.content ?? "";

	const fullTitle = getFullTitle(userData);

	return (
		<Root ref={ref}>
			<TextGroup>
				<TextLine1>
					{t("orderPageWidgets.chat.answerShow.str201") ?? ""}:{" "}
					{fullTitle}
				</TextLine1>

				<TextLine2>{contentAnswer}</TextLine2>
			</TextGroup>
			<ButtonClose onClick={onCleanAllAnswerParent}>
				<Icon id="close-modal" size={14} />
			</ButtonClose>
		</Root>
	);
};

declare namespace AnswerShow {
	interface Props {
		data: {
			messageParent:
				| ChatMessageServices.OutputGrouped.ChatMessage
				| null
				| undefined;
		};
	}
}

export default AnswerShow;
