/* eslint-disable no-shadow */

import React, { Dispatch } from "react";
import { clone } from "lodash";

import Language from "../../../../../../../../../../../../services/Language";

import LocalGroup from "./components/Group";
import Root from "./components/Root";
import Splitter from "./components/Splitter";

const Container: React.FC<Container.Props> = ({
	value,
	editing,

	language,

	onChange,
	onChangeEditing,
}) => (
	<Root gaps="6px*" maxedWidth>
		{value.map((group, index) => (
			<>
				<LocalGroup
					key={group.id}
					value={group}
					editing={editing}
					language={language}
					onChange={(groupValue, details) => {
						const newValue = clone(value);

						const newGroupValue = clone(group);

						newGroupValue.items = groupValue.items;
						newGroupValue.isMaxSpeedEnabled =
							groupValue.isMaxSpeedEnabled;
						newGroupValue.maxSpeed = groupValue.maxSpeed;
						newGroupValue.isAverageSpeedEnabled =
							groupValue.isAverageSpeedEnabled;
						newGroupValue.isBasicAverageSpeedEnabled =
							groupValue.isBasicAverageSpeedEnabled;
						newGroupValue.averageSpeed = groupValue.averageSpeed;

						newValue[index] = newGroupValue;

						if (details.type === "set-selection")
							newValue.forEach((_, newIndex) => {
								if (newIndex === index) return;

								const newGroup = clone(newValue[newIndex]);

								newGroup.items = newGroup.items.map((item) => ({
									...item,
									selected: false,
								}));

								newValue[newIndex] = newGroup;
							});

						if (
							details.type === "select" ||
							details.type === "set-selection"
						)
							onChangeEditing(false);

						onChange(newValue);
					}}
					onChangeEditing={onChangeEditing}
				/>
				{index !== value.length - 1 ? <Splitter /> : null}
			</>
		))}
	</Root>
);

declare namespace Container {
	interface Group extends LocalGroup.Value {
		id: number;
	}

	type Value = Group[];

	interface Props {
		value: Value;
		editing: boolean;

		language: Language;

		onChange: Dispatch<Value>;
		onChangeEditing: Dispatch<boolean>;
	}

	namespace Group {
		type Item = LocalGroup.Item;
	}
}

export default Container;
