import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	padding: 6px;
	margin-top: 16px;
	margin-bottom: 8px;
	height: 26px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Lato;
	font-size: 10px;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 1px;
	text-align: center;
	color: #101828;
	background-color: #f5f5f5;
	border-top: 1px solid ${theme.colors.color_border_basic};
	border-bottom: 1px solid ${theme.colors.color_border_basic};
`;

const NewMessagesBar: React.FC = () => {
	const { t } = useTranslation();
	return (
		<Root>
			{t("orderPageWidgets.chat.chatList.newMessagesBar.str100") ?? ""}
		</Root>
	);
};

export default NewMessagesBar;
