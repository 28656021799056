/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useState } from "react";
import { Column, RadioButton, react, Row } from "uikit";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Language from "../../../../../../../../../../../../../../services/Language";

import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import TabRoot from "../TabRoot";
import ModalTable from "./components/ModalTable";
import DateAndTimeWithDisabled from "./components/DateAndTimeWithDisabled";
import InternalController from "./Controller";

const symbolsCount = 13;
const smsCount = 1;

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, visible, language, onChange }) => {
			const { t } = useTranslation();
			const globalLang = useTypedSelector(
				(state) => state.session.language,
			);

			const [dateFrom, setDateFrom] = useState(
				moment().startOf("day").toDate(),
			);
			const [dateTo, setDateTo] = useState(
				moment().endOf("day").toDate(),
			);

			const [radioButtonValue, setRadioButtonValue] = useState(3);

			const changeRadioButton = (number: number) => {
				setRadioButtonValue(number);
			};

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes="auto!*3 1fr"
						gaps="19px 60px"
						maxedWidth
						maxedHeight
					>
						<Column gaps="16px*">
							<RadioButton.RadioButton
								selected={radioButtonValue === 1}
								onClick={() => changeRadioButton(1)}
								label={
									t(
										"pages.mainPage.pages.archives.tabs.driverShifts.tabs.archivalShifts.modalWorkHistory.content.mainTab.str0",
									) ?? ""
								}
							/>
							<RadioButton.RadioButton
								selected={radioButtonValue === 2}
								onClick={() => changeRadioButton(2)}
								label={
									t(
										"pages.mainPage.pages.archives.tabs.driverShifts.tabs.archivalShifts.modalWorkHistory.content.mainTab.str1",
									) ?? ""
								}
							/>
							<Row align="center" gaps="20px 15px 15px">
								<RadioButton.RadioButton
									selected={radioButtonValue === 3}
									onClick={() => changeRadioButton(3)}
									label={
										t(
											"pages.mainPage.pages.archives.tabs.driverShifts.tabs.archivalShifts.modalWorkHistory.content.mainTab.str2",
										) ?? ""
									}
								/>
								<DateAndTimeWithDisabled
									value={dateFrom}
									onChange={() => ""}
									disabled={radioButtonValue !== 3}
								/>
								<span>-</span>
								<DateAndTimeWithDisabled
									value={dateTo}
									onChange={() => ""}
									disabled={radioButtonValue !== 3}
								/>
								{/* <DateAndTimePicker
									value={dateTo}
									onChange={setDateTo}
									locale={{
										moment: globalLang,
										submitButton: t(`ok`) ?? undefined,
										cancelButton: t(`cancel`) ?? undefined,
										todayButton: t(`today`) ?? undefined,
									}}
								/> */}
							</Row>
						</Column>
						<ModalTable />
						<br />
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		notes: string;
	}

	interface PropsBase {
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
