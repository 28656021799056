import React, { Key, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Draggable } from "react-beautiful-dnd";
import { Column, Icon, Row, react } from "uikit";
import theme from "uikit/src/styles/theme";
import { SVGgeneral } from "../../../../../../../../../../../../../../../../utils/generalSprite";
import { calculateNameColor } from "./utils/coloring";
import Root from "./components/Root";
import DraggableArea from "./components/DraggableArea";
import Name from "./components/Name";
import Subname from "./components/Subname";

import SpeedLimitText from "./components/SpeedLimitText";

const Item: React.FC<Item.Props> = ({
	id,
	index,

	name,
	subname,
	active,
	selected,
	modified,
	valid,
	editing,
	isParking,
	isPriceZone,
	isMaxSpeedEnabled,
	maxSpeed,

	onSelect,
	onUnselect,
	onEdit,
}) => {
	const rootRef = useRef<HTMLDivElement | null>(null);
	const { t } = useTranslation();

	return (
		<Draggable key={id} draggableId={id.toString()} index={index}>
			{(provided) => (
				<Root
					ref={(element) => {
						react.setRefValue<HTMLElement | null>(
							provided.innerRef,
							element,
						);

						rootRef.current = element;
					}}
					{...provided.draggableProps}
					sizes="auto! 1fr 55px"
					align="center"
					gaps="6px*"
					selected={selected}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						if (selected && event.ctrlKey) {
							onUnselect();

							return;
						}

						onSelect(event.ctrlKey);
					}}
					onDoubleClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						if (!editing) onEdit();
					}}
					onMouseDown={(event) => {
						if (event.detail > 1) event.preventDefault();
					}}
				>
					<DraggableArea {...provided.dragHandleProps}>
						<SVGgeneral
							id="drag_icon"
							fill={theme.colors.disabled_text}
						/>
					</DraggableArea>
					<Column gaps="3px">
						<Name
							active={active}
							selected={selected}
							modified={modified}
							valid={valid}
						>
							{name}
						</Name>
						<Subname
							active={active}
							selected={selected}
							modified={modified}
							valid={valid}
						>
							{subname}
						</Subname>
					</Column>
					{(isParking ||
						isPriceZone ||
						(isMaxSpeedEnabled && maxSpeed) ||
						null) && (
						<>
							<Column align="center" gaps="1px">
								<Row gaps="10px">
									{isParking && (
										<Icon
											id="car"
											size={20}
											colors={[
												calculateNameColor({
													active,
													selected,
													modified,
													valid,
												}),
											]}
										/>
									)}
									{isPriceZone && (
										<Icon
											id="price"
											size={20}
											colors={[
												calculateNameColor({
													active,
													selected,
													modified,
													valid,
												}),
											]}
										/>
									)}
								</Row>
								{((isMaxSpeedEnabled && maxSpeed) || null) && (
									<SpeedLimitText
										active={active}
										selected={selected}
										modified={modified}
										valid={valid}
									>
										{maxSpeed}{" "}
										{t(
											"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.item.str200",
										) ?? ""}
									</SpeedLimitText>
								)}
							</Column>
						</>
					)}
				</Root>
			)}
		</Draggable>
	);
};

declare namespace Item {
	interface Props {
		id: Key;
		index: number;

		name: string;
		subname: string;
		active: boolean;
		selected: boolean;
		modified: boolean;
		valid: boolean;
		editing: boolean;
		isParking: boolean;
		isPriceZone: boolean;
		isMaxSpeedEnabled: boolean;
		maxSpeed: number;

		onSelect: (ctrl: boolean) => void;
		onUnselect: () => void;
		onEdit: () => void;
	}
}

export default Item;
