/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { memo, useCallback, useState } from "react";

import Currency from "../../../../../../../../services/Currency";
import AdditionalTariff from "../../../../../../../../services/AdditionalTariff";
import { NonEditableProperties } from "../../../../../../../../types/NonEditableProperties";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../components/EditModal";

import { AdditionalTariffTabs as Tabs } from "./constants/tabs";
import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const Modal: React.FC<Modal.Props> = memo(
	({ currencies, value, onCancel, onSave, headerTitle }) => {
		const [tab, setTab] = useState<Tabs>(Tabs.MAIN);

		const [internalValue, setInternalValue] = useState(value);
		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const contentValues = valueEditor.usePicker([
			"name",
			"additionalFields",
			"currency",
		]);
		const contentOnChange = valueEditor.useAssigner();

		const handleSubmit = useCallback(() => {
			onSave(internalValue);
		}, [internalValue, onSave]);

		return (
			<EditModal onCancel={onCancel} onSave={handleSubmit}>
				<Root sizes="auto! 1fr">
					<Header
						title={headerTitle}
						createdAt={value.createdAt}
						tab={tab}
						setTab={setTab}
					/>
					<Content
						currencies={currencies}
						tab={tab}
						value={contentValues}
						onChange={contentOnChange}
					/>
				</Root>
			</EditModal>
		);
	},
);

declare namespace Modal {
	interface Props {
		currencies: Currency.Model[];
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (settings: AdditionalTariff.Model) => Promise<void>;
	}

	interface Value extends Content.Value, NonEditableProperties {}
}

export default Modal;
