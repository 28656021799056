import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import { preferences } from "../../../reducers";

export default function searchService(query) {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;
		const data = await createRPCQuery(() =>
			prpcow?.theirsModel.service.getAll({ query }),
		);

		if (!data) return null;
		return dispatch(preferences.services.data.setServices(data?.items));
	};
}
