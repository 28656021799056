import React, { memo, Suspense } from "react";
import Marker from "react-leaflet-enhanced-marker";
import styled from "styled-components";

import { IOrderPoint } from "../../../../../redux/constants/OrdersPage/order";

const StyledMarkerIcon = styled.div`
	background-color: rgb(33, 51, 63);
	color: white;
	display: flex;
	justify-content: center;
	width: 40px;
	height: 18px;
	margin: 0px auto;
	border-radius: 10px;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-align: center;

	&::after {
		content: "";
		position: absolute;
		left: 44px;
		bottom: 16px;
		border: 6px solid transparent;
		border-top: 11px solid rgb(33, 51, 63);
	}
`;

const Points: React.FC<Points.Props> = ({ points }) => (
	<Suspense>
		{points?.length &&
			points?.map((item, index) => (
				<Marker
					tabIndex="-1"
					key={`marker#${index}`}
					icon={
						<StyledMarkerIcon key={`markerIcon#${index}`}>
							{String(index + 1)}
						</StyledMarkerIcon>
					}
					position={item.coordinates}
				/>
			))}
	</Suspense>
);

declare namespace Points {
	interface Props {
		points: IOrderPoint[] | null | undefined;
	}
}

export const PointsMemo = memo(Points);
export default Points;
