import React, { Dispatch, useCallback, useMemo, useRef, useState } from "react";
import { RowDataType, SortType } from "rsuite-table";
import { clone } from "lodash";
import { useTranslation } from "react-i18next";
import { CheckBox, Column } from "uikit";

import ExecutorRateDiscountPlan from "../../../../../../../../../../../../../../../../services/ExecutorRateDiscountPlan";
import KeyBinder from "../../../../../../../../../../../../../../../../services/KeyBinder";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import { DateFns } from "../../../../../../../../../../../../../../../../utils/DateFns";
import TableSettings from "../../../../../../../../../../../../../../../../components/TableSettings";
import ColumnSetupModal from "../../../../../../../../../../../../../../../../components/ColumnSetupModal";
import LightTable from "../../../../../../../../../../../../../../../../components/LightTable";
import {
	StyledP,
	TranslationText,
} from "../../../../../../../../../../../../../../../../components/common";

import { columns, defaultColumnIds, Columns } from "./constants";
import TableBase from "./components/TableBase";

const TABLE_HEADER_HEIGHT = 44;
const TABLE_ROW_HEIGHT = 44;

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,

	onChangeSelected,
	onChangeSort,

	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);
	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<LightTable.Ref | null>(null);
	const dateFns = useMemo(() => new DateFns(), []);

	const tableRowClassName = useCallback(
		(item: ExecutorRateDiscountPlan.Model) => {
			if (selected.includes(item?.id ?? "")) return "selected";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (KeyBinder.isControlPressed) {
				if (value) {
					const newSelected = clone(selected);

					newSelected.push(id);

					onChangeSelected(newSelected);
				} else
					onChangeSelected(
						selected.filter((selectedId) => selectedId !== id),
					);
			} else if (value || selected.length > 1) {
				onChangeSelected([id]);
			} else {
				onChangeSelected([]);
			}
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(item) => setItemSelection(item.id, !selected.includes(item.id)),
		[selected, setItemSelection],
	);

	const tableOnRowDoubleClick = useCallback(
		(item) => {
			onEdit(item.id);
		},
		[onEdit],
	);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const renderColumns = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						sortable
						resizable
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<TranslationText text={[column.label]} />
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
							dataKey={column.id}
							fullText
						>
							{(
								rowData: RowDataType<ExecutorRateDiscountPlan.Model>,
							) => {
								const model =
									rowData as ExecutorRateDiscountPlan.Model;

								switch (columnId) {
									case Columns.NAME:
										return (
											<StyledP>
												{model.name?.[language] || ""}
											</StyledP>
										);

									case Columns.TIME:
										return (
											<StyledP>
												{dateFns.formatTime(
													model.startDate,
													language === "en"
														? "dd MMMM yyyy hh:mm:ss aa"
														: "dd MMMM yyyy HH:mm:ss",
													language,
												)}
											</StyledP>
										);
									case Columns.PERIOD:
										return (
											<StyledP>
												{model.period
													? `${
															model.period?.value
													  } ${t(
															`periods.${model.period?.type}`,
													  )}`
													: ""}
											</StyledP>
										);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, dateFns, language, t],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<TableBase
				ref={tableRef}
				fillHeight
				virtualized
				headerHeight={TABLE_HEADER_HEIGHT}
				rowHeight={TABLE_ROW_HEIGHT}
				shouldUpdateScroll={false}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={tableOnRowDoubleClick}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle" hint={false}>
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value ? data.map((item) => item.id) : [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={(selected) =>
									setItemSelection(rowData.id, selected)
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{renderColumns}
			</TableBase>

			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns as any}
				onChange={setColumnIds as Dispatch<ColumnSetupModal.Value>}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Item extends Omit<ExecutorRateDiscountPlan.Model, "id"> {
		id: Item.Id;
	}

	namespace Item {
		type Id = number | symbol;
	}

	interface Props {
		selected: Item.Id[];
		sort: Sort;
		loading: boolean;
		data: Item[];
		onChangeSelected: Dispatch<Item.Id[]>;
		onChangeSort: Dispatch<Sort>;
		onEdit: Dispatch<Item.Id>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
