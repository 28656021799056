import React from "react";
import styled from "styled-components";
import { theme } from "uikit";

const NavBarButtonStyled = styled.div<NavBarButton.StyledProps>`
	display: flex;
	justify-content: center;
	align-items: flex-start;

	position: relative;
	color: ${({ active }) =>
		active
			? `${theme.colors.primary}`
			: `${theme.colors.text_placeholder}`};
	font-family: Lato;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: center;

	cursor: pointer;
`;

const NavBarButtonTextStyled = styled("span")<NavBarButton.StyledProps>`
	position: relative;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		content: "";
		display: ${({ active }) => (active ? "block" : "none")};
		height: 3px;
		position: absolute;
		top: 0;
		left: auto;
		right: auto;
		width: 40px;
		border-radius: 0px 0px 3px 3px;
		background-color: ${theme.colors.accent};
	}
`;

const NavBarButton: React.FC<NavBarButton.Props> = ({
	text,
	active = false,
	onClick,
}) => (
	<NavBarButtonStyled onClick={onClick} active={active}>
		<NavBarButtonTextStyled active={active}>{text}</NavBarButtonTextStyled>
	</NavBarButtonStyled>
);

declare namespace NavBarButton {
	interface Props {
		active?: boolean;
		text: string;
		onClick: () => void;
	}
	interface StyledProps {
		active?: boolean;
	}
}

export default NavBarButton;
