import React, {
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { CheckBox, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import CarClass from "../../../../../../../../../../../../../../../../services/CarClass";
import { AutoRateRulePolygonType } from "../../../../../../../../../../../../../../../../services/AutoTariff/enums";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../../../../../../../../../types/NonEditableProperties";
import EditModal from "../../../../../../../../../../../../../../../../components/EditModal";
import tPath from "../../../../../../../../../../constants/tPath";

import Controller from "./Controller";
import Content from "./components/Content";
import Root from "./components/Root";
import Header from "./components/Header";

const ModalBase = react.withController<Modal.PropsBase, Controller>(
	({
		value,
		onCancel,
		onSave,
		headerTitle,
		carClasses,
		taxiServiceIds,
		controller,
	}) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const { t } = useTranslation();

		const [internalValue, setInternalValue] = useState(value);
		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const contentValue = valueEditor.usePicker([
			"name",
			"priceZoneIds",
			"carClassIds",
			"sectorIds",
			"additionalFields",
		]);

		const assigner = valueEditor.useAssigner();

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue);
		}, [contentRef, internalValue, onSave]);

		const canSave = useMemo(() => {
			const isPolygonType =
				internalValue.additionalFields.polygonType ===
				AutoRateRulePolygonType.SECTOR;
			const isOrdersCounter =
				internalValue.additionalFields.sectors.ordersCounter.active;

			if (!isPolygonType) return true;
			return isPolygonType && isOrdersCounter;
		}, [internalValue]);

		return (
			<EditModal
				onCancel={onCancel}
				onSave={handleSubmit}
				canSave={canSave}
				footer={
					<Row>
						<CheckBox
							label={t(`${tPath}.modal.active`) || ""}
							value={active}
							onChange={setActive}
						/>
					</Row>
				}
			>
				<Root sizes="auto! 1fr" style={{ minWidth: "850px" }}>
					<Header title={headerTitle} createdAt={value.createdAt} />
					<Content
						ref={setContentRef}
						value={contentValue}
						onChange={assigner}
						autoClasses={carClasses}
						taxiServiceIds={taxiServiceIds}
					/>
				</Root>
			</EditModal>
		);
	},
	Controller,
);

const Modal = memo(ModalBase);
declare namespace Modal {
	type Ref = Controller | null;
	interface PropsBase {
		value: Value;
		headerTitle: string;
		onCancel: () => void;
		onSave: (rule: Value, index?: number | null) => void;
		carClasses: CarClass.Model[];
		taxiServiceIds: number[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Content.Value, Partial<NonEditableProperties> {
		active: boolean;
	}
}

export default Modal;
