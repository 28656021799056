import { RefObject } from "react";
import { react } from "uikit";

import Main from "../Main";
import Services from "../Services";

interface Context {
	mainTabRef: RefObject<Main.Ref | null>;
	servicesTabRef: RefObject<Services.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isMainTabValid = !!this.context?.mainTabRef.current?.validate();
		const isServicesTabValid =
			!!this.context?.servicesTabRef.current?.validate();

		return isMainTabValid && isServicesTabValid;
	}
}
