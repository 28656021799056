import React, { PropsWithChildren, memo } from "react";
import Label from "./components/Label";
import Content from "./components/Content";
import Root from "./components/Root";

const LabeledContent = memo(({ label, children, width }: DualLabel.Props) => (
	<Root width={width} gaps="1px">
		<Label>{label}</Label>
		<Content>{children}</Content>
	</Root>
));

declare namespace DualLabel {
	interface Props extends PropsWithChildren {
		label: string;

		width?: number;
	}
}

export default LabeledContent;
