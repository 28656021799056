/* eslint-disable import/no-unresolved */

import React from "react";
import { useTranslation } from "react-i18next";
import { KeybindInput } from "uikit";
import { Keybind } from "uikit/dist/utils/keys";
import { SelectEntry } from "../../../types/settingEntries";

interface Props {
	value: SelectEntry<"value", Keybind>;
	onChange: (value: SelectEntry<"value", Keybind>) => void;
}

function KeybindInputWrapper({ value, onChange }: Props) {
	const { t } = useTranslation();

	return (
		<KeybindInput
			value={value as Keybind | undefined}
			onChange={(keybind) => {
				onChange(keybind || []);
			}}
			locale={{
				edit: t(`edit`),
			}}
		/>
	);
}

export default KeybindInputWrapper;
