import styled from "styled-components";

const Root = styled.div`
	display: flex;

	flex-wrap: nowrap;

	align-items: center;
	justify-content: center;

	width: 16px;
	height: 16px;

	cursor: pointer;
`;

export default Root;
