import React from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { useTypedSelector } from "../../../../../redux/store";

const Root = styled.div<{ x: number; y: number }>`
	position: absolute;

	width: 20px;
	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;

	font-family: Inter;
	font-size: 11px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;

	color: ${theme.colors.white};

	background-color: #e54f3a;

	bottom: ${({ y }) => (y ? `${y}px` : "0px")};
	right: ${({ x }) => (x ? `${x}px` : "0px")};

	border-radius: 50%;
	border: 2px solid ${theme.colors.white};
`;

const Budge: React.FC<Budge.Props> = ({ x, y }) => {
	const { lastRead } = useTypedSelector((state) => state.orders.chats.chat);
	const { remaining } = lastRead;

	if (!remaining) return null;
	return (
		<Root x={x} y={y}>
			{remaining}
		</Root>
	);
};

declare namespace Budge {
	interface Props {
		x: number;
		y: number;
	}
}

export default Budge;
