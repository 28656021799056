import { RefObject } from "react";
import { react } from "uikit";
import AnotherData from ".";

interface Context {
	anotherDataRef: RefObject<AnotherData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isAnotherDataRefValid =
			!!this.context?.anotherDataRef.current?.validate();
		// const isFullNameValid = !!this.context?.fullNameRef.current?.validate();

		return isAnotherDataRefValid;
		// return isAuthorizationValid && isFullNameValid;
	}
}
