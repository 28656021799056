import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	NAME = "name",
	METHOD = "method",
	CURRENCY = "currency",
	SHORT_NAME = "shortName",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.NAME, label: "", width: 250 },
	{ id: Columns.METHOD, label: "", width: 300 },
	{ id: Columns.CURRENCY, label: "", width: 120 },
	{ id: Columns.SHORT_NAME, label: "" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
