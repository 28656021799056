import styled from "styled-components";
import { theme } from "uikit";

const Image = styled.div<Image.Props>`
	width: 100%;
	height: 100%;
	min-height: 80px;

	background-image: url(${({ url }) => url});
	background-position: center;
	background-size: cover;

	border-radius: ${theme.borderRadius.m};
`;

declare namespace Image {
	interface Props {
		url: string;
	}
}

export default Image;
