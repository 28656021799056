import { useCallback } from "react";

import { useTypedSelector } from "../redux/store";
import {
	intlDateFormat,
	intlTimeFormat,
	intlDateTimeFormat,
} from "../constants/intlDateTimeFormat";

export default function useDateLocalizer() {
	const language = useTypedSelector((state) => state.session.language);

	const localizeDate = useCallback(
		(date: Date, format?: "date" | "time" | "dateTime") => {
			if (format === "date")
				return date.toLocaleString(language, intlDateFormat);

			if (format === "time")
				return date.toLocaleString(language, intlTimeFormat);

			return date.toLocaleString(language, intlDateTimeFormat);
		},
		[language],
	);

	return localizeDate;
}
