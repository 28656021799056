import { BoxBase, LayoutBase, PanelBase, TabBase } from "rc-dock";
import { Filter } from "rc-dock/lib/Algorithm";

function findInPanel(
	panel: PanelBase,
	id: string,
	filter: Filter,
): PanelBase | TabBase | undefined {
	// eslint-disable-next-line no-bitwise
	if (panel.id === id && filter & Filter.Panel) {
		return panel;
	}

	// eslint-disable-next-line no-bitwise
	if (filter & Filter.Tab) {
		// eslint-disable-next-line no-restricted-syntax
		for (const tab of panel.tabs) {
			if (tab.id === id) {
				return tab;
			}
		}
	}

	return undefined;
}

function findInBox(
	box: BoxBase | undefined,
	id: string,
	filter: Filter,
): PanelBase | TabBase | BoxBase | undefined {
	let result: PanelBase | TabBase | BoxBase | undefined;
	// eslint-disable-next-line no-bitwise
	if (filter | Filter.Box && box?.id === id) {
		return box;
	}

	if (!box?.children) {
		return undefined;
	}

	// eslint-disable-next-line no-restricted-syntax
	for (const child of box.children) {
		if ("children" in child) {
			// eslint-disable-next-line no-cond-assign
			if ((result = findInBox(child, id, filter))) {
				break;
			}
		} else if ("tabs" in child) {
			// eslint-disable-next-line no-cond-assign
			if ((result = findInPanel(child, id, filter))) {
				break;
			}
		}
	}

	return result;
}

export function find(
	layout: LayoutBase,
	id: string,
	filter: Filter = Filter.AnyTabPanel,
): PanelBase | TabBase | BoxBase | undefined {
	let result: PanelBase | TabBase | BoxBase | undefined;

	// eslint-disable-next-line no-bitwise
	if (filter & Filter.Docked) {
		result = findInBox(layout.dockbox, id, filter);
	}
	if (result) return result;

	// eslint-disable-next-line no-bitwise
	if (filter & Filter.Floated) {
		result = findInBox(layout.floatbox, id, filter);
	}
	if (result) return result;

	// eslint-disable-next-line no-bitwise
	if (filter & Filter.Windowed) {
		result = findInBox(layout.windowbox, id, filter);
	}
	if (result) return result;

	// eslint-disable-next-line no-bitwise
	if (filter & Filter.Max) {
		result = findInBox(layout.maxbox, id, filter);
	}

	return result;
}

export default { find };
