import styled from "styled-components";

const Label = styled.div`
	font-family: Lato;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
`;

export default Label;
