/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Row, TextBox, react } from "uikit";

import Language from "../../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import getCompanies from "../../../../../../../../../../../../../redux/services/Company/getCompanies";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../../../../redux/store";
import LabeledField from "../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";

const NameAndCompanyDataBase = react.withController<
	NameAndCompanyData.PropsBase,
	NameAndCompanyData.Controller
>(({ value, onChange, validation, language }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const nameValue = valueEditor.useGetter("name");
	const nameValueOnChange = valueEditor.useSetter("name");

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

	const taxiServices = valueEditor.get("smsProviderToTaxiServices");

	const [selectedCompanies, setSelectedCompanies] = useState<number[]>();
	useEffect(() => {
		const newCompanies = taxiServices?.map(
			(service) => service.company?.id,
		);
		const newSelectedCompanies = new Set(newCompanies);
		setSelectedCompanies(Array.from(newSelectedCompanies) as number[]);
	}, [taxiServices]);

	const { companies } = useTypedSelector((state) => state.ordersPageReducer);
	const dispatch = useTypedDispatch();
	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const selectOptionsWrap = useCallback(
		(item: { id: number; label: string }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);
	const companiesOptions = useMemo(
		() =>
			companies.items.map((company) =>
				selectOptionsWrap({
					id: company.id,
					label: company.name[language],
				}),
			),
		[companies.items, language, selectOptionsWrap],
	);
	const taxiServiceOptions = useMemo(
		() =>
			companies.items
				.filter(({ id }) => selectedCompanies?.includes(id))
				.map((company) =>
					company.taxiServices.map((service) =>
						selectOptionsWrap({
							id: service.id,
							label: service?.settlement[language],
						}),
					),
				)
				.flat(),
		[companies.items, language, selectOptionsWrap, selectedCompanies],
	);

	const updateSelectedValues = useCallback(
		(newCompanies) => {
			setSelectedCompanies(newCompanies);
			const newTaxiServiceIds = taxiServiceIds.filter((serviceId) => {
				const serviceCompany = companies.items.find((company) =>
					company.taxiServices.some(
						(service) => service.id === serviceId,
					),
				);
				return (
					serviceCompany && newCompanies.includes(serviceCompany.id)
				);
			});
			setTaxiServiceIds(newTaxiServiceIds);
		},
		[companies.items, taxiServiceIds, setTaxiServiceIds],
	);

	return (
		<Row sizes="40%! 30%! 30% - 19px!" gaps="16px*all">
			<LabeledField
				label={
					t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.nameAndCompanyData.str0",
					) ?? ""
				}
			>
				<TextBox.TextBox
					error={validation.name}
					value={nameValue}
					onChange={(e) => {
						nameValueOnChange(e);
						delete validation.name;
					}}
					placeholder={
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.nameAndCompanyData.str0",
						) ?? ""
					}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.nameAndCompanyData.str2",
					) ?? ""
				}
			>
				<MultiSelect
					all
					placeholder={
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.nameAndCompanyData.str200",
						) ?? ""
					}
					value={selectedCompanies}
					options={companiesOptions}
					onChange={updateSelectedValues}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"pages.settings.pages.message.provider.editModal.content.mainTab.nameAndCompanyData.str3",
					) ?? ""
				}
			>
				<MultiSelect
					all
					placeholder={
						t(
							"pages.settings.pages.message.provider.editModal.content.mainTab.nameAndCompanyData.str201",
						) ?? ""
					}
					value={taxiServiceIds}
					disabled={taxiServiceOptions.length === 0}
					options={
						taxiServiceOptions.length === 0
							? undefined
							: taxiServiceOptions
					}
					onChange={(ids) => {
						setTaxiServiceIds(ids as number[]);
					}}
				/>
			</LabeledField>
		</Row>
	);
}, InternalController);

const NameAndCompanyData = memo(NameAndCompanyDataBase);

declare namespace NameAndCompanyData {
	type Ref = InternalController;

	type Controller = InternalController;

	interface Value {
		name: string;
		taxiServiceIds: number[];
		smsProviderToTaxiServices: any[];
	}

	interface PropsBase {
		value: Value;
		validation: any;
		language: Language;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default NameAndCompanyData;
