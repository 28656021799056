import { combineReducers } from "@reduxjs/toolkit";

import {
	reportCashlessOrdersDataState,
	reportCashlessOrdersDataReducer,
	reportCashlessOrdersDataAction,
} from "./data";

export type StateCashlessOrders = {
	data: reportCashlessOrdersDataState;
};

export const cashlessOrders = combineReducers<StateCashlessOrders>({
	data: reportCashlessOrdersDataReducer,
});

export const reportsCashlessOrdersAction = {
	data: reportCashlessOrdersDataAction,
};

export default cashlessOrders;
