import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Select, react } from "uikit";
import { useTranslation } from "react-i18next";

import PaymentSystem, {
	PaymentCountryIso,
} from "../../../../../../../../services/PaymentSystem";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";

import translationPath from "../../../../constants/translationPath";
import InternalController from "./Controller";

const AssetPaymentsCountryIsoBase = react.withController<
	AssetPaymentsCountryIso.PropsBase,
	AssetPaymentsCountryIso.Controller
>(({ value, onChange, controller }) => {
	const [error, setError] = useState<boolean>(false);

	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const assetPaymentsCountryIso = valueEditor.useGetter(
		"assetPaymentsCountryIso",
	);
	const assetPaymentsCountryIsoSet = valueEditor.useSetter(
		"assetPaymentsCountryIso",
	);

	const selectOptionsWrap = useCallback(
		(item: { id: PaymentCountryIso; label: PaymentCountryIso }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);

	const options = useMemo(
		() =>
			Object.values(PaymentCountryIso)
				.filter((item) => item !== PaymentCountryIso.NULL)
				.map((label) =>
					selectOptionsWrap({
						id: label,
						label,
					}),
				),
		[selectOptionsWrap],
	);

	const updateSelectedValues = useCallback(
		(value) => {
			assetPaymentsCountryIsoSet(value);
			setError(false);
		},
		[assetPaymentsCountryIsoSet, setError],
	);

	controller.setContext({ value, setError });

	const textError = useMemo(() => {
		if (!error) return "";
		return t([
			`${translationPath}.errors.countryIso`,
			"Country not selected",
		]);
	}, [t, error]);

	const placeholder = useMemo(
		() =>
			t([
				`${translationPath}.placeholders.countryIso`,
				"Select a country",
			]),
		[t],
	);

	return (
		<Select
			style={{ width: "200px" }}
			placeholder={placeholder}
			error={textError}
			value={assetPaymentsCountryIso}
			options={options}
			onChange={updateSelectedValues}
		/>
	);
}, InternalController);
const AssetPaymentsCountryIso = memo(AssetPaymentsCountryIsoBase);

declare namespace AssetPaymentsCountryIso {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default AssetPaymentsCountryIso;
