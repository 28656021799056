import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "uikit";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import useDatePickerLocale from "../../../../../../hooks/useDatePickerLocale";
import BasePassenger from "../..";

const BirthdayBase: React.FC<BirthdayBase.Props> = ({
	value,
	onChange,
	floatContainerId = "orderModal",
}) => {
	const valueEditor = useObjectEditor(value, onChange);
	const birthday = valueEditor.useGetter("birthday") || undefined;
	const setBirthday = valueEditor.useSetter("birthday");

	const { t } = useTranslation();
	return (
		<DatePicker
			locale={useDatePickerLocale()}
			value={birthday}
			onChange={setBirthday}
			floatContainerId={floatContainerId}
			placeholder={t("orders.modals.passenger.birthday.str200") ?? ""}
		/>
	);
};

export const Birthday = memo(BirthdayBase);

declare namespace BirthdayBase {
	interface Props {
		floatContainerId?: string;
		value: BasePassenger.Value;
		onChange: Dispatch<BasePassenger.Value>;
	}
}
export default BirthdayBase;
