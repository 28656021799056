import styled from "styled-components";

const Top = styled.div<Top.Props>`
	display: grid;

	grid-template-columns: repeat(
		${({ compactLevel }) => {
			if (compactLevel === 0) return 3;
			if (compactLevel === 1) return 2;
			return 1;
		}},
		1fr
	);

	grid-template-areas: ${({ compactLevel }) => {
		if (compactLevel === 0) {
			return `
				"created  feeding  completion"
				"notes    notes    status    "
				"classes  services parameters"
				"executor executor client    "
			`;
		}

		if (compactLevel === 1) {
			return `
				"created    feeding "
				"completion status  "
				"notes      notes   "
				"classes    services"
				"parameters client  "
				"executor   executor"
			`;
		}

		return `
			"created"
			"feeding"
			"completion"
			"status"
			"notes"
			"classes"
			"services"
			"parameters"
			"executor"
			"client"
		`;
	}};

	gap: 4px 10px;

	width: 100%;
	height: 100%;
`;

declare namespace Top {
	interface Props {
		compactLevel: number;
	}
}

export default Top;
