import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

import { AutoRateRuleWeekday, weekOptions } from "./constants";

const WeekDays: React.FC<WeekDays.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const days = valueEditor.useGetter("days");
	const setDays = valueEditor.useSetter("days");
	const handleDaysChange = useCallback(
		(newDays) => {
			setDays(newDays);
		},
		[setDays],
	);

	const tOptions = useMemo(
		() =>
			weekOptions.map((o) => ({
				...o,
				label: t(`days-of-week.${o.key}`),
			})),
		[t],
	);

	return (
		<Row gaps="10px*" sizes="auto 1fr">
			<CheckBox
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.weekDays.str0",
					) ?? ""
				}
				value={active}
				onChange={setActive}
			/>
			<MultiSelect
				all
				placeholder={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.weekDays.str1",
					) ?? ""
				}
				options={tOptions}
				value={days}
				onChange={handleDaysChange}
			/>
		</Row>
	);
});

declare namespace WeekDays {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		days: AutoRateRuleWeekday[];
	}
}

export default WeekDays;
