import styled from "styled-components";
import { theme } from "uikit";

const AliasRow = styled.div<AliasRow.Props>`
	padding: 1px 6px;
	color: ${(props) =>
		props.useTextColor ? props.textColor : theme.colors.primary};
	background-color: ${(props) =>
		props.useBgColor ? props.bgColor : "transparent"};
	height: 100%;
	width: 100%;
	display: flex;
	gap: 5px;
	font-size: 12px;
	white-space: nowrap;
	align-items: center;
	justify-content: flex-start;

	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	${theme.longWord}
`;

declare namespace AliasRow {
	interface Props {
		bgColor: string;
		textColor: string;
		useBgColor: boolean;
		useTextColor: boolean;
	}
}

export default AliasRow;
