import React, { ComponentType, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useTypedSelector } from "../../redux/store";
import {
	StyledGrid,
	StyledGridItem,
	SuspenseLoader,
} from "../../components/common";
import { ROUTES } from "../../constants/routes";

export default function RequireNotAuth(Component: ComponentType) {
	return function ComponentWithMiddleware(props: Record<string, unknown>) {
		const history = useHistory();

		const { connected, authorized } = useTypedSelector(
			(state) => state.prpc,
		);

		useEffect(() => {
			if (connected && authorized) history.push(ROUTES.MAIN);
		}, [history, connected, authorized]);

		return !connected || authorized === true ? (
			<StyledGrid areas="'main'" w="100%" h="100vh">
				<StyledGridItem
					area="main"
					justifySelf="center"
					alignSelf="center"
				>
					<SuspenseLoader />
				</StyledGridItem>
			</StyledGrid>
		) : (
			<Component {...props} />
		);
	};
}
