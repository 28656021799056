import React from "react";
import { Row } from "uikit";
import Label from "./components/Label";
import Badge from "./components/Badge";

const TabLabel: React.FC<TabLabel.Props> = ({ label, count }) => (
	<Row align="center" gaps="8px*">
		<Label>{label}</Label>
		<Badge>{count}</Badge>
	</Row>
);

declare namespace TabLabel {
	interface Props {
		label: string;
		count: number;
	}
}

export default TabLabel;
