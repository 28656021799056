import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import Root from "../../../../../../../../components/Root";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import TabRoot from "../../components/TabRoot";
import Content from "./components/Content";
import Modal from "./components/Modal";

const defaultRow: Modal.Value = {
	name: "",
	active: false,
	cost: 0,
	points: [
		{ value: null, type: null },
		{ value: null, type: null },
	],
};

const ComissionsTab: React.FC<ComissionsTab.Props> = memo(
	({ visible, value, onChange }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const rows = valueEditor.useGetter("rows");
		const setRows = valueEditor.useSetter("rows");

		const [selectedRows, setSelectedRows] = useState<
			ComissionsTab.SelectedRows | []
		>([]);

		const [showFixedTariffsModal, setShowFixedTariffsModal] =
			useState(false);
		const [showDeleteModal, setShowDeleteModal] = useState(false);

		const [editingItem, setEditingItem] = useState(defaultRow);
		const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
			null,
		);

		useEffect(() => {
			setEditingItem(defaultRow);
		}, [indexEditingItem]);

		const editRow = useCallback(
			(index: number) => {
				const item = rows[index];

				setEditingItem({
					active: item.active ?? false,
					cost: item.cost ?? 0,
					name: item.name ?? "",
					points: item.points ?? defaultRow.points,
				});
				setShowFixedTariffsModal(true);
			},
			[rows],
		);

		const addRowHandler = useCallback(() => {
			setShowFixedTariffsModal(true);
		}, []);

		const editRowHeaderHandler = useCallback(() => {
			editRow(selectedRows[0]);
			setIndexEditingItem(selectedRows[0]);
			setSelectedRows([]);
		}, [editRow, selectedRows]);

		const editRowContentHandler = useCallback(
			(index: number) => {
				setIndexEditingItem(index);
				editRow(index);
				setSelectedRows([]);
			},
			[editRow],
		);

		const cancelRowHandler = useCallback(() => {
			setShowFixedTariffsModal(false);
			setIndexEditingItem(null);
		}, []);

		const saveRowHandler = useCallback(
			(row: Modal.Value) => {
				if (row.points.length < 2) return;

				if (indexEditingItem != null) {
					const updatedRows = [...rows];
					updatedRows[indexEditingItem] = row;
					setRows(updatedRows);
					setIndexEditingItem(null);
				} else {
					setRows([...rows, row]);
				}

				setShowFixedTariffsModal(false);
			},
			[indexEditingItem, rows, setRows],
		);

		const deleteRowHandler = useCallback(() => {
			const newRows = rows.filter(
				(_, i) => !selectedRows.includes(i as never),
			);
			setRows(newRows);
			setSelectedRows([]);
			setShowDeleteModal(false);
		}, [rows, selectedRows, setRows]);

		const copyTariffHandler = useCallback(() => {
			const item = rows[selectedRows[0]];

			setEditingItem({
				active: item.active,
				cost: item.cost,
				name: item.name,
				points: item.points,
			});
			setShowFixedTariffsModal(true);
			setSelectedRows([]);
		}, [rows, selectedRows]);

		return (
			<TabRoot hasPaddings={false} visible={visible}>
				<Root sizes="auto! 1fr" maxedWidth maxedHeight>
					<DefaultPageHeader
						canEdit={selectedRows.length === 1}
						canDelete={!!selectedRows.length}
						onAdd={addRowHandler}
						onDelete={() => {
							setShowDeleteModal(true);
						}}
						onEdit={editRowHeaderHandler}
						afterAdditionalButtons={[
							{
								icon: { id: "copy", size: 20 },
								onClick: copyTariffHandler,
								disabled: !(selectedRows.length === 1),
							},
						]}
					/>
					<Content
						rows={rows}
						selectedRows={selectedRows}
						onEdit={editRowContentHandler}
						setSelectedRows={setSelectedRows}
					/>
					{showFixedTariffsModal && (
						<Modal
							value={editingItem}
							headerTitle={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.str0",
								) ?? ""
							}
							onCancel={cancelRowHandler}
							onSave={saveRowHandler}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							label={
								selectedRows.length === 1
									? `${
											t(
												"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.str200",
											) ?? ""
									  }?`
									: `${
											t(
												"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.comissions.str200",
											) ?? ""
									  }?`
							}
							onCancel={() => {
								setShowDeleteModal(false);
							}}
							onConfirm={deleteRowHandler}
						/>
					)}
				</Root>
			</TabRoot>
		);
	},
);

declare namespace ComissionsTab {
	interface Props {
		value: Value;
		visible: boolean;
		onChange: (x: Value) => void;
		disabled?: boolean;
	}

	interface Value {
		rows: Modal.Value[];
	}
	type SelectedRows = number[];
}

export default ComissionsTab;
