import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import {
	NumberInputMemo,
	NumberInput,
} from "../../../../../../../../../components/Orders";
import { StyledRow } from "../../../../../../../../../components/common";
import { EditIcon } from "../../../../../../../../../icons/edit";
import { Gray, Primary } from "../../styled";
import { OrderFormProps } from "../../../types/FormProps";

import Clickable from "./Clickable";

interface Props extends OrderFormProps {
	edit: boolean;
	setEdit: (edit: boolean) => void;
}

const OutOfTown: React.FC<Props> = ({ disabled, edit, setEdit, form, tab }) => {
	const { t } = useTranslation();

	const formSuburban = useMemo(
		() => (tab.form.suburban ?? 0) / 1000,
		[tab.form.suburban],
	);

	const routeSuburban = useMemo(
		() => tab.state.route?.suburbanPriceZones ?? 0,
		[tab.state.route?.suburbanPriceZones],
	);
	const routeSuburbanDistance = useMemo(
		() => formSuburban ?? routeSuburban ?? 0,
		[formSuburban, routeSuburban],
	);

	const routeSuburbanDistanceRounded = useMemo(
		() => Number(routeSuburbanDistance).toFixed(3),
		[routeSuburbanDistance],
	);

	const [value, onChangeValue] = useState<NumberInput.Value>({
		action: "add",
		value: 0,
	});

	const valueEditor = useObjectEditor(value, onChangeValue);
	const setAction = valueEditor.useSetter("action");
	const setValue = valueEditor.useSetter("value");

	const onChange = useCallback(
		(data: NumberInput.Value) => {
			setValue(data.value);
			setAction(data.action);

			form.setValue(
				"suburbanDistanceOverrideState",
				data.action === "set",
			);

			if (data.action === "set") {
				const newOutOfTown = Math.max(data.value, 0);
				form.setValue("suburbanDistanceOverride", newOutOfTown);
			} else {
				const newOutOfTown =
					Number(routeSuburbanDistanceRounded) + data.value;
				form.setValue("suburbanDistanceOverride", newOutOfTown);
			}

			setEdit(false);
		},
		[setValue, setAction, routeSuburbanDistanceRounded, setEdit, form],
	);

	return (
		<StyledRow gap="6px">
			<Primary>
				{`${t([
					`orderPage.order_form.out_of_town`,
					"In particular out of town",
				])}:`}
			</Primary>

			<>
				<Gray>
					{routeSuburbanDistanceRounded} {`${t(`units.km`) ?? "km"}`}
				</Gray>

				{!disabled && (
					<Clickable onClick={() => setEdit(true)}>
						<EditIcon />
					</Clickable>
				)}

				{edit && (
					<NumberInputMemo
						shadow
						title={`${t([
							`orderPage.order_form.out_of_town`,
							"In particular out of town",
						])}:  ${routeSuburbanDistanceRounded} ${
							t(`units.km`) ?? "km"
						}`}
						unit={t(`units.km`) ?? "km"}
						value={value}
						onSubmit={onChange}
						onClose={() => setEdit(false)}
						decimalCount={3}
					/>
				)}
			</>
		</StyledRow>
	);
};

export default OutOfTown;
