/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { RefAttributes, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import Map from "../../../../../../../../redux/services/Map";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModalBase from "../../../../../../../../components/EditModal";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import InternalController from "./Controller";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const { t } = useTranslation();

	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<Header.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const headerTitle = useMemo(
		() =>
			t(
				"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.str200",
			) ?? "",
		[t],
	);

	const assignValue = valueEditor.useAssigner();

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header tab={tab} title={headerTitle} onChangeTab={setTab} />
				<Content
					ref={setContentRef}
					value={internalValue}
					type={tab}
					language={language}
					onChange={assignValue}
				/>
			</Root>
		),
		[assignValue, headerTitle, internalValue, language, setContentRef, tab],
	);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(valueEditor.value as EditModal.Value.Validated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	return (
		<EditModalBase onCancel={onCancel} onSave={modalOnSave}>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value {
		id?: number;
	}

	namespace Value {
		type Validated = Value;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
