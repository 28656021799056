import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	active: true,
	name: {},
	additionalFields: {
		general: {
			propagation: {
				orderSource: {
					active: false,
					sources: [],
				},
				payment: {
					active: false,
					types: [],
				},
			},
			surcharge: {
				active: false,
				value: 0,
			},
		},
	},
	carClassIds: [],
	taxiServices: [],
	taxiServiceIds: [],
};

export default defaultValue;
