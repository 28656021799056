import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Select } from "uikit";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
	StyledSpan,
	ValueStepper,
	ImprovedStepper,
} from "../../../../../../../../components/common";
import { useCurrencyGlobalSettings } from "../../../../../../../../hooks";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
} from "../../../../constants/constants";
import tPath from "../../../../constants/tPath";
import SurchargeSelect from "../../../SurchareSelect";
import BinaryAdjustmentSelect from "../../../BinaryAdjustmentSelect";

const RideMinuteCost: React.FC<RideMinuteCost.Props> = memo(
	({ disabled, value, onChange }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const valueEditor = useObjectEditor(value, onChange);

		const checkBox = valueEditor.useGetter("active");
		const setCheckBox = valueEditor.useSetter("active");

		const stepperValue = valueEditor.useGetter("value");
		const setStepperValue = valueEditor.useSetter("value");

		const freeMinutes = valueEditor.useGetter("freeMinutes");
		const setFreeMinutes = valueEditor.useSetter("freeMinutes");

		const type = valueEditor.useGetter("type");
		const setType = valueEditor.useSetter("type");

		const adjustments = valueEditor.useGetter("adjustments");
		const setAdjustments = valueEditor.useSetter("adjustments");
		const adjustmentsEditor = useObjectEditor(adjustments, setAdjustments);
		const adjustmentsValue = adjustmentsEditor.useGetter("value");
		const setAdjustmentsValue = adjustmentsEditor.useSetter("value");
		const adjustmentsFreeMinutes =
			adjustmentsEditor.useGetter("freeMinutes");
		const setAdjustmentsFreeMinutes =
			adjustmentsEditor.useSetter("freeMinutes");
		const adjustmentsType = adjustmentsEditor.useGetter("type");
		const setAdjustmentsType = adjustmentsEditor.useSetter("type");

		useEffect(() => {
			if (
				adjustmentsValue === RateAdjustmentToDefault.CUSTOM_VALUE &&
				stepperValue < 0
			) {
				setStepperValue(0);
			}
		}, [stepperValue, adjustmentsValue, setStepperValue]);

		return (
			<StyledColumn gap="8px">
				<StyledRow gap="10px" alignItems="center">
					<StyledRow m="0 12px 0 0">
						<CheckBox
							label={
								t(
									`${tPath}.mainModal.tabs.main.waiting.rideMinuteCost`,
								) || ""
							}
							value={checkBox}
							onChange={setCheckBox}
						/>
					</StyledRow>
					<SurchargeSelect
						disabled={disabled}
						value={adjustmentsValue}
						onChange={setAdjustmentsValue}
					/>
					<StyledRow gap="8px" alignItems="center" w="125px">
						<ValueStepper
							min={
								adjustmentsValue ===
								RateAdjustmentToDefault.CUSTOM_VALUE
									? 0
									: undefined
							}
							value={stepperValue}
							onChange={setStepperValue}
						/>
						{adjustmentsValue !==
							RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE && (
							<StyledSpan>
								{currencyGlobalSettings}
							</StyledSpan>
						)}
					</StyledRow>
				</StyledRow>
				<StyledRow gap="10px" alignItems="center" m="0 47px 0 auto">
					<StyledSpan>
						{t(`${tPath}.mainModal.tabs.main.waiting.includeTime`)}
					</StyledSpan>
					<BinaryAdjustmentSelect
						disabled={disabled}
						value={adjustmentsFreeMinutes}
						onChange={setAdjustmentsFreeMinutes}
					/>
					<ImprovedStepper
						disabled={
							adjustmentsFreeMinutes ===
							RateBinaryAdjustmentToDefault.DEFAULT_VALUE
						}
						value={freeMinutes}
						onChange={setFreeMinutes}
					/>
					<StyledSpan>
						{t(`${tPath}.mainModal.tabs.main.waiting.minutes`)}
					</StyledSpan>
					<BinaryAdjustmentSelect
						disabled={disabled}
						value={adjustmentsType}
						onChange={setAdjustmentsType}
					/>
					<Select
						disabled={
							adjustmentsType ===
							RateBinaryAdjustmentToDefault.DEFAULT_VALUE
						}
						placeholder={
							t(
								`${tPath}.mainModal.tabs.main.waiting.select.placeholder`,
							) || ""
						}
						style={{ width: 269 }}
						value={type}
						onChange={(newType) => {
							setType(newType as RideMinuteCost.ValueType);
						}}
						options={[
							{
								key: "fixed",
								value: "fixed",
								label: t(
									`${tPath}.mainModal.tabs.main.waiting.select.options.fixed`,
								),
							},
							{
								key: "calculated",
								value: "calculated",
								label: t(
									`${tPath}.mainModal.tabs.main.waiting.select.options.calculated`,
								),
							},
						]}
					/>
				</StyledRow>
			</StyledColumn>
		);
	},
);

declare namespace RideMinuteCost {
	interface Props {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
		freeMinutes: number;
		type: ValueType;
		adjustments: {
			value: RateAdjustmentToDefault;
			freeMinutes: RateBinaryAdjustmentToDefault;
			type: RateBinaryAdjustmentToDefault;
		};
	}
	type ValueType = "fixed" | "calculated";
}

export default RideMinuteCost;
