import React, { RefAttributes, memo } from "react";
import { CheckBox, Column, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import CarClass from "../../../../../../../../../../../../services/CarClass";
import Sector from "../../../../../../../../../../../../services/Sector";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import tPath from "../../../../../../constants/tPath";
import TabRoot from "../../components/TabRoot";

import InternalController from "./Controller";
import CompaniesAndTaxiServices from "./components/CompaniesAndTaxiServices";
import Tariffs from "./components/Tariffs";
import Name from "./components/Name";
import Rules from "./components/Rules";

const MainBase = react.withController<Main.PropsBase, Main.Controller>(
	({ carClasses, sectors, value, onChange, controller }) => {
		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);
		const [companyBranchRef, setCompanyBranchRef] =
			useRefWithSetter<CompaniesAndTaxiServices.Ref | null>(null);
		controller.setContext({
			nameRef,
			companyBranchRef,
		});

		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "taxiServices"],
			"json",
		);

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
		const disableOthers = valueEditor.useGetter("disableOthers");
		const setDisableOthers = valueEditor.useSetter("disableOthers");

		const rules = valueEditor.useGetter("rules");
		const setRules = valueEditor.useSetter("rules");

		const additionalFields = valueEditor.useGetter("additionalFields");
		const setAdditionalFields = valueEditor.useSetter("additionalFields");
		const additionalFieldsEditor = useObjectEditor(
			additionalFields,
			setAdditionalFields,
		);
		const general = additionalFieldsEditor.useGetter("general");
		const setGeneral = additionalFieldsEditor.useSetter("general");

		const generalEditor = useObjectEditor(general, setGeneral);

		const ignoreCoefficient = generalEditor.useGetter("ignoreCoefficient");
		const setIgnoreCoefficient =
			generalEditor.useSetter("ignoreCoefficient");

		const assigner = valueEditor.useAssigner();

		return (
			<TabRoot hasPaddings={false} visible={true}>
				<Column maxedWidth maxedHeight>
					<Column style={{ padding: 24 }} gaps="25px*">
						<Row sizes="39%! 29%! 29%!" justify="space-between">
							<Name
								name={name}
								setName={setName}
								ref={setNameRef}
							/>
							<CompaniesAndTaxiServices
								ref={setCompanyBranchRef}
								value={taxiServicesData}
								onChange={assigner}
							/>
						</Row>
						<Tariffs value={general} onChange={setGeneral} />

						<CheckBox
							label={
								t(
									`${tPath}.modal.tabs.main.ignoreCoefficient`,
								) || ""
							}
							value={ignoreCoefficient}
							onChange={setIgnoreCoefficient}
						/>
						<CheckBox
							label={
								t(`${tPath}.modal.tabs.main.disableOthers`) ||
								""
							}
							value={disableOthers}
							onChange={setDisableOthers}
						/>
					</Column>
					<Rules
						carClasses={carClasses}
						sectors={sectors}
						rules={rules}
						setRules={setRules}
						taxiServiceIds={taxiServiceIds}
					/>
				</Column>
			</TabRoot>
		);
	},
	InternalController,
);
const Main = memo(MainBase);

declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		carClasses: CarClass.Model[];
		sectors?: Sector.Model[];
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends CompaniesAndTaxiServices.Value {
		name: Name.Value;
		additionalFields: AdditionalFields;
		disableOthers: boolean;
		rules: Rules.Rule[];
	}

	interface AdditionalFields {
		general: Tariffs.Value;
	}
}

export default Main;
