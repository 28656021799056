/* eslint-disable no-shadow */
export enum MapResolutions {
	VISICOM = "visicom",
	OSM = "osm",
	GOOGLE = "google",
	HERE_MAPS = "here-maps",
}

export enum DefaultSearchType {
	STREET = "street",
	LOCAL_OBJECT = "localObject",
	OBJECT = "object",
}

export enum RouteCalculationMode {
	DRIVING_SHORTEST = "driving-shortest",
	DRIVING = "driving",
}
