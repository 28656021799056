import getValidCityName from "./getValidCityName";
import processAddressPath from "./processAddressPath";

function settlementToString(settlement: settlementToString.Settlement) {
	const settlementName = getValidCityName(
		settlement.settlementType,
		settlement.settlement,
	);

	return `${
		settlementName === " " ||
		(!settlement.settlementType && !settlement.settlement)
			? ""
			: settlementName
	}${processAddressPath(settlement.district)}${processAddressPath(
		settlement.region,
	)}`.trim();
}

declare namespace settlementToString {
	interface Settlement {
		settlementType?: string;
		settlement?: string;
		district?: string;
		region?: string;
	}
}

export default settlementToString;
