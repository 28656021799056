/* eslint-disable no-param-reassign */

import { Nullable } from "uikit";
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InitialState {
	id?: number;
	sipValue: Nullable<string>;
	lineType: string;
	softPhoneConnect: SoftPhoneConnect;
	currentCall: any;
	uaConfig: any;
}

type SoftPhoneConnect = "success" | "failure" | "inactive";

interface Sip {
	id: number;
	sipValue: string;
}

const initialState: InitialState = {
	id: undefined,
	sipValue: null,
	lineType: "Отсутствуют",
	softPhoneConnect: "inactive",
	currentCall: null,
	uaConfig: null,
};

type Reducer<P> = CaseReducer<typeof initialState, PayloadAction<P>>;

const setSip: Reducer<Nullable<Sip>> = (state, { payload }) => {
	state.id = payload?.id;
	state.sipValue = payload?.sipValue;
};

const setLineType: Reducer<string> = (state, { payload }) => {
	state.lineType = payload;
};

const setSoftphoneConnect: Reducer<SoftPhoneConnect> = (state, { payload }) => {
	state.softPhoneConnect = payload;
};

const setCurrentCall: Reducer<any> = (state, { payload }) => {
	state.currentCall = payload;
};

const setUaConfig: Reducer<any> = (state, { payload }) => {
	state.uaConfig = payload;
};

const clearSipToDispatcher: Reducer<undefined> = (state) => {
	state.id = undefined;
	state.sipValue = null;
	state.lineType = "Отсутствуют";
	state.softPhoneConnect = "inactive";
	state.currentCall = null;
	state.uaConfig = null;
};

const sipToDispatcher = createSlice({
	name: "sipToDispatcher",
	initialState,
	reducers: {
		setSip,
		setLineType,
		setSoftphoneConnect,
		setCurrentCall,
		setUaConfig,
		clearSipToDispatcher,
	},
});

export default sipToDispatcher;
