export default function fromJsonToString(jsonData) {
	if (!jsonData || !jsonData.type || !jsonData.content) {
		throw new Error("Invalid JSON data (fromJsonToString)");
	}
	const parts: any[] = [];
	const specialParts: any[] = [];
	let indexToConcat = NaN;

	jsonData.content.forEach((paragraph) => {
		if (paragraph.type === "paragraph") {
			paragraph.content.forEach((part) => {
				if (part.type === "text") {
					parts.push(part.text);
				} else if (part.type === "mention" && part.attrs) {
					const { id } = part.attrs;

					if (id === "#if") indexToConcat = parts.length;
					if (indexToConcat && specialParts.length < 4) {
						specialParts.push(id);
					} else {
						parts.push(`{{${id}}}`);
					}
				}
			});
		}
	});

	if (specialParts.length) {
		[specialParts[1], specialParts[2]] = [specialParts[2], specialParts[1]];
		const specialString = `{{${specialParts[0]} (${specialParts
			.slice(1)
			.join(" ")})}}`;

		if (indexToConcat !== -1) {
			parts.splice(indexToConcat, 0, specialString);
		}
	}

	return parts.join("");
}
