import React, { Dispatch, useCallback } from "react";
import { Icon } from "uikit";
import Root from "./components/Root";
import { SortingOrder } from "../../../../../../../../../../types/SortingOrder";

const SortButton: React.FC<SortButton.Props> = ({ value, onChange }) => {
	const rootOnClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!onChange) return;

			if (value === "ASC") onChange("DESC");
			else onChange("ASC");
		},
		[onChange, value],
	);

	return (
		<Root align="center" justify="center" onClick={rootOnClick}>
			<Icon
				id={value === "ASC" ? "sort-up" : "sort-down"}
				size={16}
				colors={["#3498FF"]}
			/>
		</Root>
	);
};

declare namespace SortButton {
	type Value = SortingOrder;

	interface Props {
		value: Value;

		onChange?: Dispatch<Value>;
	}
}

export default SortButton;
