/* eslint-disable no-shadow */

import React, {
	useCallback,
	useMemo,
	useState,
	useRef,
	useLayoutEffect,
} from "react";
import { InputBorders, Option, useInternal, Key, TextBox } from "uikit";
import PropsOf from "uikit/src/types/PropsOf";

import SelectModal from "../SelectModal";

import Root from "./components/Root";

const SelectWithModal = <OptionValue, ValueType extends Key>({
	value,
	disabled,
	title,
	options,
	onSubmit,
	pullUpItemInsideArray = false,
	...props
}: SelectWithModal.Props<OptionValue, ValueType>) => {
	const refInput = useRef<HTMLInputElement | null>(null);
	const [internalValue, setInternalValue] = useInternal(value);

	const [showModal, setShowModal] = useState(false);

	const optionByKey = useMemo(
		() =>
			options?.reduce((accumulator, option: any) => {
				accumulator[option.key] = option;

				return accumulator;
			}, {} as Record<any, Option<unknown>>) ?? {},
		[options],
	);

	const valueText = useMemo(
		() => (internalValue ? optionByKey[internalValue]?.label ?? "" : ""),
		[internalValue, optionByKey],
	);

	const hasPlaceholder = useMemo(
		() => valueText.length === 0,
		[valueText.length],
	);

	const text = useMemo(() => valueText || (title ?? ""), [title, valueText]);

	const rootOnClick = useCallback(
		() => !disabled && setShowModal(true),
		[disabled],
	);

	const modalOnSubmit = useCallback<
		SelectModal.Props<OptionValue, ValueType>["onSubmit"]
	>(
		(value, event) => {
			onSubmit?.(value, event);
			setInternalValue(value);
			setShowModal(false);
		},
		[onSubmit, setInternalValue],
	);

	const modalOnClose = useCallback(
		(event) => {
			props?.onCancel?.(event);
			setShowModal(false);
		},
		[props],
	);

	const paramsTextBox = useMemo(() => {
		if (!hasPlaceholder) return { value: text };
		return { placeholder: text };
	}, [hasPlaceholder, text]);

	const eventKeydown = useCallback(
		(event: KeyboardEvent) => {
			const keyEvent = event?.key;
			if (keyEvent === "Enter") {
				rootOnClick();
				event.stopPropagation();
				event.preventDefault();
			}
		},
		[rootOnClick],
	);

	useLayoutEffect(() => {
		const elem = refInput?.current;
		elem?.addEventListener("keydown", eventKeydown);
		return () => {
			elem?.removeEventListener("keydown", eventKeydown);
		};
	}, [eventKeydown, refInput]);

	const queryElement = useMemo(() => {
		try {
			return <TextBox.TextBox {...paramsTextBox} inputRef={refInput} />;
		} catch {
			return <></>;
		}
	}, [paramsTextBox]);

	return (
		<Root disabled={!!disabled} onClick={rootOnClick}>
			{queryElement}

			{showModal && (
				<SelectModal
					value={internalValue}
					title={title}
					options={options}
					{...props}
					onSubmit={modalOnSubmit}
					onClose={modalOnClose}
					pullUpItemInsideArray={pullUpItemInsideArray}
				/>
			)}
		</Root>
	);
};

declare namespace SelectWithModal {
	type Value<Type extends Key> = Type | undefined;

	export interface PropsBase<OptionValue, ValueType extends Key>
		extends Omit<SelectModal.Props<OptionValue, ValueType>, "onClose"> {
		value?: Value<ValueType>;
		options?: Option<OptionValue>[];
		title?: string;
		onCancel?: SelectModal.Props<OptionValue, ValueType>["onClose"];
		pullUpItemInsideArray?: boolean;
	}

	type Props<OptionValue, ValueType extends Key> = Omit<
		PropsOf<typeof InputBorders.InputBorders>,
		keyof PropsBase<OptionValue, ValueType>
	> &
		PropsBase<OptionValue, ValueType>;
}

export default SelectWithModal;
