import { combineReducers } from "@reduxjs/toolkit";

import {
	orderMapFiltersState,
	orderMapFiltersReducer,
	orderMapFiltersAction,
} from "./filter";
import {
	orderMapTogglesState,
	orderMapTogglesReducer,
	orderMapTogglesAction,
} from "./toggles";

export type StateMap = {
	filter: orderMapFiltersState;
	toggles: orderMapTogglesState;
};

export const map = combineReducers<StateMap>({
	filter: orderMapFiltersReducer,
	toggles: orderMapTogglesReducer,
});

export const ordersMapAction = {
	filter: orderMapFiltersAction,
	toggles: orderMapTogglesAction,
};

export default map;
