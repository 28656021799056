/* eslint-disable import/no-unresolved */

import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, CheckBoxWithContent, react, Row, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Root from "./components/Root";

const ObjectFooter = react.inputify<ObjectFooter.PropsBase, ObjectFooter.Value>(
	({ value, onChange, onCancel, onSave }) => {
		const { t } = useTranslation();
		const defaultValue = useMemo<ObjectFooter.Value>(() => ({}), []);

		const [internalValue] = useInternal(value ?? defaultValue);

		const activeCheckBoxOnChange = useCallback(
			(active: boolean) => onChange({ ...internalValue, active }),
			[internalValue, onChange],
		);

		const visibleCheckBoxOnChange = useCallback(
			(visible: boolean) => onChange({ ...internalValue, visible }),
			[internalValue, onChange],
		);

		return (
			<Root align="center" justify="space-between" gaps="10px">
				<Row gaps="24px*">
					<CheckBoxWithContent
						gap="10px"
						value={internalValue.active}
						onChange={activeCheckBoxOnChange}
					>
						{t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectFooter.str150",
						) ?? ""}
					</CheckBoxWithContent>
					<CheckBoxWithContent
						gap="10px"
						value={internalValue.visible}
						onChange={visibleCheckBoxOnChange}
					>
						{t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectFooter.str151",
						) ?? ""}
					</CheckBoxWithContent>
				</Row>
				<Row sizes="120px*" gaps="16px">
					<Button.Button
						variant="secondary"
						text={
							t(
								"pages.preferencesPages.screenDirectory.objects.editModal.objectFooter.str0",
							) ?? ""
						}
						onClick={onCancel}
					/>
					<Button.Button
						variant="primary"
						text={
							t(
								"pages.preferencesPages.screenDirectory.objects.editModal.objectFooter.str1",
							) ?? ""
						}
						onClick={onSave}
					/>
				</Row>
			</Root>
		);
	},
);

declare namespace ObjectFooter {
	interface Value {
		active?: boolean;
		visible?: boolean;
	}

	export interface PropsBase {
		onCancel: () => void;
		onSave: () => void;
	}

	type Props = PropsBase & InputifiedComponentProps<Value>;
}

export default ObjectFooter;
