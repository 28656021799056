import styled, { css } from "styled-components";

const TableRoot = styled.div<TableRoot.Props>`
	overflow: hidden;

	& .rs-table .rs-table-row:not(.rs-table-row-header) {
		${({ colors }) =>
			Object.entries(colors).map(
				([colorName, colorValue]) => css`
					&.${colorName} .rs-table-cell {
						background-color: ${colorValue};
					}
				`,
			)}
	}
`;

declare namespace TableRoot {
	interface Props {
		colors: Record<string, string>;
	}
}

export default TableRoot;
