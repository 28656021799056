import makeTable from "./makeTable";

const object = makeTable({
	allIds: [
		"checkbox",
		"active",
		"name",
		"city",
		"street",
		"house",
		"coordinates",
		"company",
		"branch",
		"createdAt",
		"properties",
	],
	i18tPath: (id) => `tableColumns.objects.${id}`,
});

export default object;
