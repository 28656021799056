import makeTable from "./makeTable";

const customerTariff = makeTable({
	allIds: ["name", "mode", "companies", "createdAt"],
	defaultIds: ["name", "mode", "companies", "createdAt"],
	initialWidths: {
		name: 992,
		mode: 200,
		companies: 190,
		createdAt: 150,
	},
	i18tPath: (id) => `tableColumns.dispatcher.${id}`,
});

export default customerTariff;
