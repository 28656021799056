import constants from "../../constants/Preferences/class";

/* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *\
|                                                                       |
|   TODO: Delete this reducer and replace with the model subscription   |
|                                                                       |
\* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** */

const initialState = {
	classes: [],
};

/** @deprecated */
export default function classes(state = initialState, action: any = {}) {
	switch (action.type) {
		case constants.setClasses:
			return {
				...state,
				classes: action.payload,
			};
		default:
			return state;
	}
}
