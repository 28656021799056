/* eslint-disable no-shadow */
import React, { memo } from "react";
import { TextBox } from "uikit";
import { useTranslation } from "react-i18next";

const Search: React.FC<Search.Props> = memo(() => {
	const { t } = useTranslation();

	return <TextBox.TextBox placeholder={`${t("search")}...`} />;
});

declare namespace Search {
	interface Props {}
}

export default Search;
