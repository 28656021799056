import makeTable from "./makeTable";

const cashlessAccountsSummaryReport = makeTable({
	allIds: [
		"account",
		"companyName",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"periodBalanceUah",
	],
	i18tPath: (id) =>
		`mainPage.reports.accounting.cashlessOrdersPeriodReport.columns.${id}`,
	defaultIds: [
		"account",
		"companyName",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"periodBalanceUah",
	],
	initialSortMethod: {
		column: "companyName",
		type: "asc",
	},
	initialWidths: {
		account: 75,
		companyName: 260,
		replenishmentUah: 120,
		orderCount: 150,
		orderAmountUah: 165,
		periodBalanceUah: 165,
	},
	initialResizable: {
		account: true,
		companyName: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		periodBalanceUah: true,
	},
	initialSortable: {
		account: true,
		companyName: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		periodBalanceUah: true,
	},
});

export default cashlessAccountsSummaryReport;
