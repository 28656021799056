import React, { memo, useMemo } from "react";
import { Map, theme } from "uikit";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-unresolved
import { PolygonList } from "uikit/dist/components/Map/components/PolygonEditor/type";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe";
import PriceZone from "../../../../../services/PriceZone";

const PriceZonesBase: React.FC = (): JSX.Element => {
	const { t } = useTranslation();

	const language = useTypedSelector((state) => state.session.language);

	const priceZones = useModelSubscribe({}, PriceZone);

	const processedSectors = useMemo<PolygonList | undefined>(
		() =>
			priceZones?.cache.map((priceZone) => ({
				id: priceZone.id,
				name:
					`${t(
						`mainPage.archives.orders.modal.map.polygons.priceZone`,
					)} ${priceZone.name?.[language]}` ||
					Object.values(priceZone.name)?.[0],
				color: theme.colors.success,
				vertices: priceZone.vertices.map((v) => [v.lat, v.lng]),
				editable: false,
			})),
		[language, priceZones?.cache, t],
	);

	return <Map.PolygonEditor value={processedSectors} editing={false} />;
};

const PriceZones = memo(PriceZonesBase);

export default PriceZones;
