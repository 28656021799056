import styled from "styled-components";

const Label = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 13px;

	color: #575757;
`;

export default Label;
