import { useMemo } from "react";
import { isString } from "lodash";
import { useSort } from "../../../../../hooks/useTableSettings";
import { OrderType } from "../../../../../redux/constants/OrdersPage";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";
import Order from "../../../../../services/Order";
import orderPage from "../../../../../redux/reducers/OrdersPage";
import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";

const orderTableByType = {
	live: "orderLive",
	closed: "orderClosed",
	preliminary: "orderPreliminary",
	executable: "orderExecutable",
	all: "orderAll",
} as const;

function useOrderTypeTracking(type: OrderType) {
	const dispatch = useTypedDispatch();

	const orderTableId = useMemo(
		() => `main-order-page-${type}` as const,
		[type],
	);

	const orderTableType = useMemo(() => orderTableByType[type], [type]);

	const currentUser = useTypedSelector((state) => state.account.user);

	const filters = useTypedSelector(
		(state) => state.ordersPageReducer.filters[type],
	);

	const { sortMethod } = useSort(orderTableId, orderTableType);

	const subscribeOptions = useMemo<Order.SubscribeOptions>(() => {
		const options: Order.SubscribeOptions = {
			// limit: filters.limit,
			tab: type,
			order:
				isString(sortMethod.column) && isString(sortMethod.type)
					? {
							[sortMethod.column]: sortMethod.type,
					  }
					: undefined,
		};

		if (type === "closed") {
			const dateRange = {
				from: new Date(Date.now() - 24 * 60 * 60 * 1000),
				to: new Date(Date.now() + 24 * 60 * 60 * 1000),
			};
			options.dateRange = dateRange;
		}

		if (filters.showOnlyMyOrders && currentUser) {
			options.dispatcherIds = [currentUser.id];
		}

		if (filters.showOrdersWithoutExecutors) {
			options.hasExecutor = false;
		}

		if (filters.byCar) {
			switch (filters.byCar.type) {
				case "state":
					options.carRegistrationNumbers = [filters.byCar.number];

					break;

				case "garage":
					options.carCallSigns = [filters.byCar.number];

					break;

				default:
					break;
			}
		}

		if (filters.byExecutor) {
			switch (filters.byExecutor.type) {
				case "alias":
					options.executorCallSigns = [filters.byExecutor.number];

					break;

				default:
					break;
			}
		}

		if (filters.byOrder) {
			if (filters.byOrder.phone) {
				options.customerPhones = [filters.byOrder.phone];
			}

			if (filters.byOrder.from) {
				options.pickupAddress = filters.byOrder.from;
			}

			if (filters.byOrder.to) {
				options.destinationAddress = filters.byOrder.to;
			}
		}

		return options;
	}, [
		currentUser,
		filters.byCar,
		filters.byExecutor,
		filters.byOrder,
		filters.dateRange,
		filters.showOnlyMyOrders,
		filters.showOrdersWithoutExecutors,
		sortMethod.column,
		sortMethod.type,
		type,
	]);

	const subscriberOptions = useMemo<useModelSubscribe.Options<Order.Model>>(
		() => ({
			onUpdate: (data) => {
				const pagination = data.metadataState.pagination as any;

				dispatch(
					orderPage.actions.setOrdersByTab({
						tab: type,
						data: {
							items: data.models,
							count: (pagination?.count ?? 0) as number,
							pagination,
							loaded: true,
						},
					}),
				);
			},
		}),
		[dispatch, type],
	);

	useModelSubscribe(subscribeOptions, Order, subscriberOptions);
}

export default useOrderTypeTracking;
