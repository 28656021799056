import { useCallback, useMemo } from "react";
import { LatLngBoundsLiteral, LatLngExpression } from "leaflet";

import { useTypedDispatch, useTypedSelector } from "../redux/store";
import {
	ReqGetGPSHistory,
	getGPSHistory,
} from "../redux/services/Archives/GPSLog/locations";
import { archiveGPS } from "../redux/reducers";
import {
	Track,
	PointItems,
	TrackStartEnd,
	GPSHistoryFilters,
	LocationHistory,
	DistanceHistory,
	Point,
	HistoryPlayer,
} from "../redux/reducers/Archives/GPSLog/interface";
import { ValueToggle } from "../redux/reducers/Archives/interface";

export interface UseGetGPSHistory {
	data: LocationHistory[];
	error: string | null;
	filters: GPSHistoryFilters;
	isLoading: boolean;
	player: HistoryPlayer;
	track: Track | undefined;
	positions: Point[];
	mapBounds: LatLngBoundsLiteral | null;
	centroid: LatLngExpression;
	trackStartAndEnd: TrackStartEnd[];
	circleMarkerItems: PointItems[][];
	radius: number;
	distances: DistanceHistory[];
	setRadius: (data: number) => void;
	get: (data?: ReqGetGPSHistory) => void;
	addFilters: (data?: GPSHistoryFilters) => void;
	setPositions: (data: Point[]) => void;
	setCentroid: (data: LatLngExpression) => void;
	setMapBounds: (data: LatLngBoundsLiteral | null) => void;
	setCircleMarkerItems: (data: PointItems[][]) => void;
	setTrackStartAndEnd: (data: TrackStartEnd[]) => void;
	setDistances: (data: DistanceHistory[]) => void;
	setPlayerPosition: (data: HistoryPlayer["position"]) => void;
	setPlayerNext: (data: HistoryPlayer["next"]) => void;
	setPlayerDate: (data: HistoryPlayer["date"]) => void;
	setPlayerMax: (data: HistoryPlayer["max"]) => void;
	setPlayerSpeed: (data: HistoryPlayer["speed"]) => void;
	setPlayerDeg: (data: HistoryPlayer["deg"]) => void;
	setPlayerDuration: (data: HistoryPlayer["duration"]) => void;
	setPlayerRun: (data: ValueToggle) => void;
	setPlayerToggle: (data: ValueToggle) => void;
}

/** `useGetGPSHistory`
 ** Hook to get a gps history of the data.
 ** And change the filters for them.
 */
export const useGetGPSHistory = (): UseGetGPSHistory => {
	const dispatch = useTypedDispatch();

	const {
		history: {
			items,
			error,
			isLoading,
			positions,
			mapBounds,
			centroid,
			trackStartAndEnd,
			circleMarkerItems,
			radius,
			distances,
		},
		filters,
		player,
		track,
	} = useTypedSelector((state) => state.archiveGPSLog);

	const setPlayerDeg = useCallback(
		(data: HistoryPlayer["deg"]) => {
			dispatch(archiveGPS.setPlayerDeg(data));
		},
		[dispatch],
	);
	const setPlayerSpeed = useCallback(
		(data: HistoryPlayer["speed"]) => {
			dispatch(archiveGPS.setPlayerSpeed(data));
		},
		[dispatch],
	);
	const setPlayerMax = useCallback(
		(data: HistoryPlayer["max"]) => {
			dispatch(archiveGPS.setPlayerMax(data));
		},
		[dispatch],
	);
	const setPlayerPosition = useCallback(
		(data: HistoryPlayer["position"]) => {
			dispatch(archiveGPS.setPlayerPosition(data));
		},
		[dispatch],
	);

	const setPlayerDuration = useCallback(
		(data: HistoryPlayer["duration"]) => {
			dispatch(archiveGPS.setPlayerDuration(data));
		},
		[dispatch],
	);

	const setPlayerRun = useCallback(
		(data: ValueToggle) => {
			dispatch(archiveGPS.setPlayerRun(data));
		},
		[dispatch],
	);

	const setPlayerNext = useCallback(
		(data: HistoryPlayer["next"]) => {
			dispatch(archiveGPS.setPlayerNext(data));
		},
		[dispatch],
	);

	const setPlayerDate = useCallback(
		(data: HistoryPlayer["date"]) => {
			dispatch(archiveGPS.setPlayerDate(data));
		},
		[dispatch],
	);

	const setPlayerToggle = useCallback(
		(data: ValueToggle) => {
			dispatch(archiveGPS.setPlayerToggle(data));
		},
		[dispatch],
	);

	const setRadius = useCallback(
		(data: number) => {
			dispatch(archiveGPS.setGPSHistoryRadius(data));
		},
		[dispatch],
	);

	const setDistances = useCallback(
		(data: DistanceHistory[]) => {
			dispatch(archiveGPS.setGPSHistoryDistances(data));
		},
		[dispatch],
	);
	const setTrackStartAndEnd = useCallback(
		(data: TrackStartEnd[]) => {
			dispatch(archiveGPS.setGPSHistoryTrackStartAndEnd(data));
		},
		[dispatch],
	);

	const setCircleMarkerItems = useCallback(
		(data: PointItems[][]) => {
			dispatch(archiveGPS.setGPSHistoryCircleMarkerItems(data));
		},
		[dispatch],
	);

	const setPositions = useCallback(
		(data: Point[]) => {
			dispatch(archiveGPS.setGPSHistoryPositions(data));
		},
		[dispatch],
	);

	const setCentroid = useCallback(
		(data: LatLngExpression) => {
			dispatch(archiveGPS.setGPSHistoryCentroid(data));
		},
		[dispatch],
	);

	const setMapBounds = useCallback(
		(data: LatLngBoundsLiteral | null) => {
			dispatch(archiveGPS.setGPSHistoryMapBounds(data));
		},
		[dispatch],
	);

	const get = useCallback(
		(data: ReqGetGPSHistory = {}) => {
			getGPSHistory(data)(dispatch);
		},
		[dispatch],
	);

	const addFilters = useCallback(
		(data?: GPSHistoryFilters) => {
			const payload = { ...filters, ...data };
			dispatch(archiveGPS.modifyFilters(payload));
			get({ filters: payload });
		},
		[filters, dispatch, get],
	);

	return useMemo(
		() => ({
			data: items,
			error,
			isLoading,
			filters,
			player,
			track,
			positions,
			mapBounds,
			centroid,
			trackStartAndEnd,
			circleMarkerItems,
			radius,
			distances,
			get,
			addFilters,
			setPositions,
			setCentroid,
			setMapBounds,
			setCircleMarkerItems,
			setTrackStartAndEnd,
			setRadius,
			setDistances,
			setPlayerToggle,
			setPlayerDate,
			setPlayerNext,
			setPlayerPosition,
			setPlayerMax,
			setPlayerDeg,
			setPlayerSpeed,
			setPlayerDuration,
			setPlayerRun,
		}),
		[
			addFilters,
			centroid,
			circleMarkerItems,
			distances,
			error,
			filters,
			get,
			isLoading,
			items,
			mapBounds,
			player,
			positions,
			radius,
			setCentroid,
			setCircleMarkerItems,
			setDistances,
			setMapBounds,
			setPlayerDate,
			setPlayerDeg,
			setPlayerMax,
			setPlayerNext,
			setPlayerPosition,
			setPlayerSpeed,
			setPlayerRun,
			setPlayerToggle,
			setPositions,
			setRadius,
			setTrackStartAndEnd,
			track,
			trackStartAndEnd,
			setPlayerDuration,
		],
	);
};

export default useGetGPSHistory;
