import React from "react";
import { Overlayer } from "uikit";
import Root from "./components/Root";
import Type from "./components/Type";
import Label from "./components/Label";

const Content: React.FC<Content.Props> = ({ mainColor, secondaryColor, alias }) => (
	<Root backgroundColor={mainColor}>
		<Overlayer overlay={<Type backgroundColor={secondaryColor} />}>
			<Label>{alias}</Label>
		</Overlayer>
	</Root>
);

declare namespace Content {
	interface Props {
		mainColor: string;
		secondaryColor: string;

		alias: string;
	}
}

export default Content;
