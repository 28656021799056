import React, { Dispatch } from "react";
import { CheckBoxWithContent, Column, Option, theme } from "uikit";
import FieldsContainer from "../../../../../../../../../../../../FieldsContainer";

// eslint-disable-next-line comma-spacing
const MultiSelectList = <OptionValue,>({
	value,

	label,
	disabled = false,

	options,

	onChange,
}: MultiSelectList.Props<OptionValue>) => (
	<FieldsContainer
		label={label}
		labelColor={theme.colors.text_placeholder}
		maxedHeight
	>
		<Column gaps="12px*">
			{options.map((option) => {
				const checked = value.includes(option.value);

				return (
					<CheckBoxWithContent
						value={checked}
						disabled={disabled}
						gap="10px"
						key={option.key}
						onChange={() => {
							if (checked) {
								onChange(
									value.filter((v) => v !== option.value),
								);
							} else {
								onChange([...value, option.value]);
							}
						}}
					>
						{option.label}
					</CheckBoxWithContent>
				);
			})}
		</Column>
	</FieldsContainer>
);

declare namespace MultiSelectList {
	interface Props<OptionValue> {
		value: OptionValue[];

		label: string;
		disabled?: boolean;

		options: Option<OptionValue>[];

		onChange: Dispatch<OptionValue[]>;
	}
}

export default MultiSelectList;
