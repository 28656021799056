import React, { Dispatch, useCallback, useMemo } from "react";
import { Option, Select } from "uikit";

import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../hooks";

const UnitSelect: React.FC<UnitSelect.Props> = ({
	value,
	disabled,
	onChange,
}) => {
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const options: Option<CustomerRate.Model.Unit>[] = useMemo(
		() => [
			{
				key: CustomerRate.Model.Unit.Amount,
				value: CustomerRate.Model.Unit.Amount,
				label: currencyGlobalSettings,
			},
			{
				key: CustomerRate.Model.Unit.Percentage,
				value: CustomerRate.Model.Unit.Percentage,
				label: "%",
			},
		],
		[currencyGlobalSettings],
	);

	const onSelect = useCallback(
		(option: Option<CustomerRate.Model.Unit>) => {
			onChange(option.value);
		},
		[onChange],
	);

	return (
		<Select
			value={value}
			disabled={disabled}
			options={options}
			onSelect={onSelect}
		/>
	);
};

declare namespace UnitSelect {
	type Value = CustomerRate.Model.Unit;

	interface Props {
		value: Value;

		disabled?: boolean;

		onChange: Dispatch<Value>;
	}
}

export default UnitSelect;
