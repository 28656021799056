import React, { MouseEvent, useCallback } from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const CloseButton: React.FC<CloseButton.Props> = ({ onClick }) => {
	const rootOnClick = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();

			onClick();
		},
		[onClick],
	);

	return (
		<Root onClick={rootOnClick}>
			<Icon id="close" size={10} colors={[theme.colors.disabled_text]} />
		</Root>
	);
};

declare namespace CloseButton {
	interface Props {
		onClick: () => void;
	}
}

export default CloseButton;
