import styled from "styled-components";

const Circle = styled.div<Circle.Props>`
	width: ${(props) => (props.size ? `${props.size}px` : "10px")};
	height: ${(props) => (props.size ? `${props.size}px` : "10px")};
	border-radius: 100%;
	background-color: ${(props) => props.color || "#bebebe"};
`;

declare namespace Circle {
	interface Props {
		size?: number;
		color?: string;
	}
}

export default Circle;
