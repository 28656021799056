import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../../../../../services/Transaction";
import {
	StyledRow,
	StyledP,
} from "../../../../../../../../../../components/common";

const TransactionAuthor: React.FC<TransactionAuthor.Props> = memo(
	({ operation }) => {
		const { t } = useTranslation();

		return (
			<StyledRow alignItems="center" gap="8px" w="100%">
				<StyledP>
					{t([
						`transactionAuthor.${operation?.createdBy?.type}`,
						`transactionAuthor.system`,
					])}
				</StyledP>
				<StyledP>{operation?.createdBy?.data?.person?.name}</StyledP>
			</StyledRow>
		);
	},
);

declare namespace TransactionAuthor {
	interface Props {
		operation: Transaction.Model["operation"];
	}
}

export default TransactionAuthor;
