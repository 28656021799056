import { isNumber } from "lodash";

function formatNumber(number?: number) {
	if (!isNumber(number)) return undefined;

	const singValue = Math.sign(number);

	const ceilValue =
		singValue !== -1
			? Math.ceil(number * 100) / 100
			: -(Math.ceil(Math.abs(number) * 100) / 100);

	const num = Math.abs(number) > 0.009999999 ? number : ceilValue;

	const formattedNumber = num.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	const [integerPart, decimalPart] = formattedNumber.split(".");

	const numberWithSpaces = integerPart.replaceAll(",", " ");

	return `${numberWithSpaces}.${decimalPart}`;
}

export default formatNumber;
