import { useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Option, Key } from "uikit";

import useURLParams from "./useURLParams";

function useActiveTab<T>(tabs: Option<T>[]) {
	const history = useHistory();
	const tabParam = useURLParams().get("tab");

	const [activeKey, setActiveKey] = useState<Key>(tabParam || tabs[0].key);

	const activeTab = useMemo(
		() => tabs.find((t) => t.key === activeKey) || tabs[0],
		[activeKey, tabs],
	);

	useEffect(() => {
		const activeTabKey = activeTab ? activeTab.key : tabs[0].key;
		setActiveKey(activeTabKey);

		const params = new URLSearchParams();
		params.append("tab", String(activeTabKey));

		if (tabParam) {
			history.push({ search: params.toString() });
		} else {
			history.replace({ search: params.toString() });
		}
	}, [activeTab, history, tabParam, tabs]);

	return {
		activeKey,
		activeTab,
		setActiveKey,
	};
}

export default useActiveTab;
