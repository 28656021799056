/* eslint-disable no-shadow */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { sha1 } from "object-hash";
import { cloneDeep, defaultsDeep } from "lodash";
import { useDebounce } from "uikit";
import { DeepPartial } from "react-hook-form";

import Map from "../services/Map";
import { useTypedSelector } from "../redux/store";

import useObjectEditor from "./useObjectEditor";

declare namespace useMapSearch {
	interface Options extends Map.Search.Options {
		query: Exclude<string, "">;
		debounce?: number;
		lite?: boolean;
	}

	interface LoadingResult {
		loading: true;
	}

	interface ReadyResult extends Map.Search.Response {
		loading: false;
	}

	type Result = LoadingResult | ReadyResult;
}

function useMapSearch(options: useMapSearch.Options): useMapSearch.Result {
	const {
		inputRequestDelayMs: delaySearchRequest,
		searchRadiusMeters: searchRadius,
		minSearchQueryLength,
	} = useTypedSelector((state) => state.settings.map);

	const defaultOptions = useMemo(() => {
		const result: DeepPartial<useMapSearch.Options> = {
			debounce: delaySearchRequest,
		};

		if (options.near && options.near.point) {
			result.near = {
				radius: searchRadius,
			};
		}

		return result;
	}, [delaySearchRequest, options.near, searchRadius]);

	const clonedOptions = useMemo(() => cloneDeep(options), [options]);

	const internalOptions = defaultsDeep(
		{},
		clonedOptions,
		defaultOptions,
	) as useMapSearch.Options;

	const optionsRef = useRef(internalOptions);
	const minSearchQueryLengthRef = useRef(minSearchQueryLength);
	const currentSearchPromise = useRef<Promise<Map.Search.Response> | null>(
		null,
	);

	optionsRef.current = internalOptions;
	minSearchQueryLengthRef.current = minSearchQueryLength;

	const [data, setData] = useState<useMapSearch.Result>({
		loading: true,
	});

	const dataEditor = useObjectEditor(data, setData);

	const setLoading = dataEditor.useSetter("loading");

	const optionsHash = sha1(internalOptions);

	const update = useCallback(async () => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { query, debounce, ...options } = optionsRef.current;
		const minSearchQueryLength = minSearchQueryLengthRef.current;

		setLoading(true);

		if (query.length >= minSearchQueryLength) {
			const searchPromise = Map.search(query, options);

			currentSearchPromise.current = searchPromise;

			const searchResult = await searchPromise;

			if (currentSearchPromise.current !== searchPromise) return;

			setData({
				loading: false,

				...searchResult,
			});
		}

		currentSearchPromise.current = null;
	}, [setLoading]);

	const debouncedUpdate = useDebounce(update, internalOptions.debounce);

	useEffect(() => {
		debouncedUpdate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsHash]);

	return data;
}

export default useMapSearch;
