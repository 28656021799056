import React, { useCallback, useState } from "react";
import { Button as BtnUikit, Icon } from "uikit";

import Language from "../../../../../services/Language";

import { Orders } from "../../../../../redux/reducers/Orders";

import { Popover, Popup } from "./components";

const ExecutorsFilterPanel: React.FC<ExecutorsFilterPanel.Props> = (props) => {
	const { isEnabled, filter, setFilter, language, onSave } = props;

	const { Button } = BtnUikit;

	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const popoverOpen = useCallback(() => {
		setOpenAdditionalFilters(true);
	}, []);

	const popoverClose = useCallback(() => {
		setOpenAdditionalFilters(false);
	}, []);

	return (
		<Popup
			open={openAdditionalFilters}
			offset={{ x: -470, y: 40 }}
			styles={{ shadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
			tracker={
				<Button
					style={
						isEnabled ? { backgroundColor: "rgb(109,190,255)" } : {}
					}
					variant={isEnabled ? "primary" : "secondary"}
					icon={<Icon id="filters" size={20} />}
					onClick={popoverOpen}
				/>
			}
		>
			<Popover
				language={language}
				filter={filter}
				setFilter={setFilter}
				onClose={popoverClose}
				onSave={onSave}
			/>
		</Popup>
	);
};

declare namespace ExecutorsFilterPanel {
	interface Props {
		isEnabled: boolean;
		filter: Orders.Chat.ModalFilter;
		setFilter: React.Dispatch<
			React.SetStateAction<Orders.Chat.ModalFilter>
		>;
		language: Language;
		onSave: (data: Orders.Chat.ModalFilter) => void;
	}
}

export default ExecutorsFilterPanel;
