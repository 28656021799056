import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CustomerNoteCellContent: React.FC<CustomerNoteCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() => item?.additionalFields?.customerNotes || item.orderNote || "",
		[item?.additionalFields?.customerNotes, item.orderNote],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CustomerNoteCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default CustomerNoteCellContent;
