import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Schema } from "../../../../../../../../../redux/constants/OrdersPage/order";
import { UseCreateFormReturn } from "../../../../../../../../../hooks/useCreateForm";
import { EditIcon } from "../../../../../../../../../icons/edit";
import { NumberInputMemo } from "../../../../../../../../../components/Orders";
import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";
import { Gray, Primary } from "../../styled";

import Clickable from "./Clickable";

interface Props extends OrderFormProps {
	disabled: boolean;
	edit: boolean;
	setEdit: (edit: boolean) => void;
	form: UseCreateFormReturn<Schema>;
}

const OrderHourly: React.FC<Props> = ({
	disabled,
	edit,
	setEdit,
	form,
	tab,
}) => {
	const { t } = useTranslation();

	const show = useMemo(
		() => tab?.form?.enableHourlyService,
		[tab?.form?.enableHourlyService],
	);

	return (
		<StyledRow alignItems="end" gap="6px">
			{show && (
				<>
					<Primary>
						{`${t([`orderPage.order_form.hourly`, "Hourly"])}:`}
					</Primary>
					<Controller
						name="hourly"
						control={form.control}
						render={({ field }) => (
							<>
								<Gray>
									{`${moment
										.utc(field.value || 0)
										.format("HH:mm")} ${
										t(`units.minute`) ?? "min"
									}`}
								</Gray>

								{!disabled && (
									<Clickable onClick={() => setEdit(true)}>
										<EditIcon />
									</Clickable>
								)}

								{edit && (
									<NumberInputMemo
										shadow
										title={
											t([
												`orderPage.order_form.hourly`,
												"Hourly",
											]) ?? ""
										}
										unit={t(`units.minute`) ?? "min"}
										value={{
											action: "add",
											value: Math.round(
												(field.value || 0) / 60e3,
											),
										}}
										onSubmit={(number) => {
											const value =
												number.action === "add"
													? (field.value || 0) +
													  number.value * 60e3
													: number.value * 60e3;

											field.onChange({
												target: {
													value: Math.max(value, 0),
												},
											} as unknown as React.ChangeEvent<Element>);
											setEdit(false);
										}}
										onClose={() => setEdit(false)}
									/>
								)}
							</>
						)}
					/>
				</>
			)}
		</StyledRow>
	);
};

export default OrderHourly;
