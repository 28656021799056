/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { RefAttributes, memo, useCallback, useState } from "react";
import { react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import EditModalBase from "../../../../../../../components/EditModal";
import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import PaymentSystem from "../../../../../../../services/PaymentSystem";
import Language from "../../../../../../../services/Language";

import Content from "./components/Content";
import Root from "./components/Root";
import Header from "./components/Header";
import InternalController from "./Controller";

const ModalBase = react.withController<Modal.PropsBase, Modal.Controller>(
	({ value, onCancel, onSave, createdAt, headerTitle, controller }) => {
		const { t } = useTranslation();
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const [internalValue, setInternalValue] = useState(value);

		const handleSubmit = useCallback(() => {
			if (!contentRef.current?.validate()) return;

			onSave(internalValue as PaymentSystem.Modified);
		}, [contentRef, internalValue, onSave]);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const contentOnChange = valueEditor.useAssigner();

		return (
			<EditModalBase onCancel={onCancel} onSave={handleSubmit}>
				<Root sizes="auto! 1fr">
					<Header title={headerTitle} createdAt={createdAt} />
					<Content
						ref={setContentRef}
						value={internalValue}
						onChange={contentOnChange}
					/>
				</Root>
			</EditModalBase>
		);
	},
	InternalController,
);
const Modal = memo(ModalBase);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		headerTitle: string;
		createdAt: string;
		lang: Language;
		onCancel: () => void;
		onSave: (template: PaymentSystem.Modified) => Promise<void>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = PaymentSystem.Model;
	type PreparedTemplate = PaymentSystem.New | PaymentSystem.Modified | null;
}

export default Modal;
