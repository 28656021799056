import React, {
	Dispatch,
	memo,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { compact, isNumber } from "lodash";
import {
	CheckBoxWithContent,
	Column,
	DateAndTimePicker,
	DatePicker,
	Option,
	react,
	Row,
	Select,
	Stepper,
	TimeInput,
	useRefWithSetter,
} from "uikit";

import Language from "../../../../../../../../../../../../../../../../services/Language";
import ExecutorRateDiscountPlan from "../../../../../../../../../../../../../../../../services/ExecutorRateDiscountPlan";
import ExecutorRateCommissionPlan from "../../../../../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
// import ExecutorRateClassifier from "../../../../../../../../../../../../../../../../services/ExecutorRateClassifier";
// import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import {
	useDatePickerLocale,
	useObjectEditor,
	useCurrencyGlobalSettings,
} from "../../../../../../../../../../../../../../../../hooks";
import {
	ConstantFormat,
	DateFns,
} from "../../../../../../../../../../../../../../../../utils/DateFns";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import FieldsContainer from "../../../../../../../../../../../../../../../../components/FieldsContainer";
import CheckBoxSelect from "../../../../../../../../../../../../../../../../components/CheckBoxSelect";
import {
	StyledP,
	StyledRow,
	NameBase,
	Name,
	CheckBoxWithStepper,
	StyledColumn,
	StyledSpan,
} from "../../../../../../../../../../../../../../../../components/common";

import { periodUnitOptions, weekdayOptions } from "./data";
import InternalController from "./Controller";
import Root from "./components/Root";
import Commissions from "./components/Commissions";

export const marginLeft = { marginLeft: 30 };

export interface ContentErrors {
	startDate: boolean;
	minFeeValueValue: boolean | string;
	maxFeeValueValue: boolean | string;
	commissionPlanIdValue: boolean;
}

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({
		value,
		commissionPlans,
		language,
		onChange,
		controller,
		setTypeName,
	}) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const dateFns = useMemo(() => new DateFns(), []);
		const [errors, setErrors] = useState<ContentErrors>({
			startDate: false,
			commissionPlanIdValue: false,
			minFeeValueValue: false,
			maxFeeValueValue: false,
		});

		const [nameRef, setNameRef] = useRefWithSetter<NameBase.Ref | null>(
			null,
		);

		const valueEditor = useObjectEditor(value, onChange);

		const nameValue = valueEditor.useGetter("name");
		const nameOnChange = valueEditor.useSetter("name");

		const commissionsValue = valueEditor.useGetter("commissions");
		const commissionsOnChange = valueEditor.useSetter("commissions");

		const handleCommissions = useCallback(
			(id: number) => {
				const exist = commissionPlans.find((item) => item.id === id);

				if (exist && exist.rate?.commissions) {
					const items = compact(
						[...exist.rate.commissions].map((item) => {
							const exist = commissionsValue.find(
								(commission) => commission.id === item.type,
							);
							return exist;
						}),
					);

					commissionsOnChange(items);
				}
			},
			[commissionPlans, commissionsOnChange, commissionsValue],
		);

		const commissionPlanIdValue = valueEditor.useGetter("commissionPlanId");
		const commissionPlanIdOnChange =
			valueEditor.useSetter("commissionPlanId");
		const onSelect = useCallback(
			(option: Option<number>) => {
				setTypeName(option.label);
				commissionPlanIdOnChange(option.value);
			},
			[commissionPlanIdOnChange, setTypeName],
		);
		const commissionPlanOptions = useMemo<Option<number>[]>(
			() =>
				commissionPlans.map((commissionPlan) => ({
					key: commissionPlan.id,
					value: commissionPlan.id,
					label: commissionPlan.name[language],
				})),
			[commissionPlans, language],
		);

		useLayoutEffect(() => {
			if (commissionPlanIdValue) handleCommissions(commissionPlanIdValue);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [commissionPlanIdValue, commissionPlans]);

		const startDateEditor = valueEditor.usePropertyEditor("startDate");

		const startDateValueValue = startDateEditor.useGetter("value");
		const startDateValueOnChange = startDateEditor.useSetter("value");

		const handleStartDate = useCallback(
			(value: Date | null | undefined) => {
				startDateValueOnChange(value);
				setErrors((prev) => ({ ...prev, startDate: false }));
			},
			[startDateValueOnChange],
		);

		const timeRangeEditor = valueEditor.usePropertyEditor("timeRange");

		const timeRangeActiveValue = timeRangeEditor.useGetter("active");
		const timeRangeActiveOnChange = timeRangeEditor.useSetter("active");

		const timeRangeFromValue = timeRangeEditor.useGetter("from");
		const timeRangeFromOnChange = timeRangeEditor.useSetter("from");

		const timeRangeToValue = timeRangeEditor.useGetter("to");
		const timeRangeToOnChange = timeRangeEditor.useSetter("to");

		const periodEditor = valueEditor.usePropertyEditor("period");

		const periodUnitValue = periodEditor.useGetter("unit");
		const periodUnitOnChange = periodEditor.useSetter("unit");

		const periodValueValue = periodEditor.useGetter("value");
		const periodValueOnChange = periodEditor.useSetter("value");

		const thresholdEditor = valueEditor.usePropertyEditor("threshold");

		const thresholdValueValue = thresholdEditor.useGetter("value");
		const thresholdValueOnChange = thresholdEditor.useSetter("value");

		const weekdaysValue = valueEditor.useGetter("weekdays");
		const weekdaysOnChange = valueEditor.useSetter("weekdays");

		const weekdayCheckBoxSelectValue = useMemo(
			() =>
				Object.entries(weekdaysValue)
					.filter(([, value]) => value)
					.map(([key]) => key) as (keyof Content.Value.Weekdays)[],
			[weekdaysValue],
		);

		const weekdayCheckBoxSelectOnChange = useCallback(
			(weekdays: (keyof Content.Value.Weekdays)[]) => {
				weekdaysOnChange({
					monday: weekdays.includes("monday"),
					tuesday: weekdays.includes("tuesday"),
					wednesday: weekdays.includes("wednesday"),
					thursday: weekdays.includes("thursday"),
					friday: weekdays.includes("friday"),
					saturday: weekdays.includes("saturday"),
					sunday: weekdays.includes("sunday"),
				});
			},
			[weekdaysOnChange],
		);

		const minFeeEditor = valueEditor.usePropertyEditor("minFee");
		const minFee = valueEditor.useGetter("minFee");
		const setMinFee = valueEditor.useSetter("minFee");
		const minFeeActiveValue = minFeeEditor.useGetter("active");
		const minFeeValueValue = minFeeEditor.useGetter("value");

		const maxFeeEditor = valueEditor.usePropertyEditor("maxFee");
		const maxFee = valueEditor.useGetter("maxFee");
		const setMaxFee = valueEditor.useSetter("maxFee");
		const maxFeeActiveValue = maxFeeEditor.useGetter("active");
		const maxFeeValueValue = maxFeeEditor.useGetter("value");

		const locale = useDatePickerLocale();

		const onSelectPeriod = useCallback(
			(option: Option<(typeof periodUnitOptions)[number]["value"]>) => {
				if (
					option.value !==
					ExecutorRateDiscountPlan.Model.Period.Type.Daily
				) {
					periodValueOnChange(1);
				}

				periodUnitOnChange(option.value);
			},
			[periodUnitOnChange, periodValueOnChange],
		);

		const periodStepperOnChange = useCallback(
			(value) => {
				periodValueOnChange(value);
			},
			[periodValueOnChange],
		);

		const getDayOfWeek = useCallback(
			(date) => {
				const dayOfWeek = dateFns.formatTime(
					date,
					ConstantFormat.day,
					language,
				);
				return dayOfWeek;
			},
			[dateFns, language],
		);

		const limitDay = useMemo(() => {
			if (!startDateValueValue) return 1;
			if (periodUnitValue !== "daily") return 1;
			return dateFns.fns.getDaysInMonth(startDateValueValue);
		}, [dateFns.fns, periodUnitValue, startDateValueValue]);

		const datePeriod = useMemo(() => {
			if (!startDateValueValue) return "";

			let days = periodValueValue;

			if (periodUnitValue === "weekly") days = 7;

			if (periodUnitValue === "monthly") {
				days = dateFns.fns.getDaysInMonth(startDateValueValue);
			}

			const timePeriod = dateFns.getCurrentPeriod(
				startDateValueValue,
				days,
			);

			const formatTime =
				language === "en"
					? ConstantFormat.MDYHMSA
					: ConstantFormat.MDYHM_UK;

			const from = dateFns.formatTime(timePeriod.from, formatTime);
			const to = dateFns.formatTime(timePeriod.to, formatTime);

			return `${from} - ${to}`;
		}, [
			dateFns,
			language,
			periodUnitValue,
			periodValueValue,
			startDateValueValue,
		]);

		useLayoutEffect(() => {
			if (
				minFeeActiveValue &&
				maxFeeActiveValue &&
				isNumber(minFeeValueValue) &&
				isNumber(maxFeeValueValue)
			) {
				const minFee = minFeeValueValue;
				const maxFee = maxFeeValueValue;

				setErrors((prev) => ({
					...prev,
					minFeeValueValue: minFee > maxFee,
					maxFeeValueValue: maxFee < minFee,
				}));
			}

			if (!minFeeActiveValue || !maxFeeActiveValue) {
				setErrors((prev) => ({
					...prev,
					minFeeValueValue: false,
					maxFeeValueValue: false,
				}));
			}
		}, [
			maxFeeActiveValue,
			maxFeeValueValue,
			minFeeActiveValue,
			minFeeValueValue,
		]);

		const minMaxOptions = useMemo(
			() => ({
				min: {
					decimalCount: 2,
					error: errors.minFeeValueValue,
				},
				max: {
					decimalCount: 2,
					error: errors.maxFeeValueValue,
				},
			}),
			[errors.maxFeeValueValue, errors.minFeeValueValue],
		);

		const minDate = useMemo(() => {
			if (!commissionPlans?.length) return undefined;
			const exist = commissionPlans.find(
				(item) => item.id === commissionPlanIdValue,
			);
			if (!exist || !exist.startDate) return undefined;

			return new Date(exist.startDate);
		}, [commissionPlans, commissionPlanIdValue]);

		const isStartDate = useMemo(() => {
			if (!minDate || !startDateValueValue) return true;

			const timeStartDate = startDateValueValue?.getTime();
			const timeMinDate = minDate?.getTime() || timeStartDate;
			if (timeMinDate > timeStartDate) {
				setErrors((prev) => ({ ...prev, startDate: true }));
				// startDateValueOnChange(minDate);
			}

			if (timeStartDate >= timeMinDate) {
				setErrors((prev) => ({ ...prev, startDate: false }));
			}

			return timeStartDate >= timeMinDate;
		}, [minDate, startDateValueValue]);

		controller.setContext({
			nameRef,
			minFeeValueValue,
			maxFeeValueValue,
			minFeeActiveValue,
			maxFeeActiveValue,
			commissionPlanIdValue,
			isStartDate,
			setErrors,
		});

		return (
			<Root hasPaddings>
				<Column gaps="19px*">
					<FieldsContainer
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str0",
							) ?? ""
						}
					>
						<Column gaps="10px*">
							<Row gaps="8px*" sizes="1fr*">
								<Name
									ref={setNameRef}
									name={nameValue || ""}
									setName={nameOnChange}
									label={
										t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str200",
										) ?? ""
									}
								/>
								{/* <LabeledField label={t("pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str1") ?? ""}>
									<Select
										value={classifierIdValue}
										options={classifierOptions}
										placeholder={t("pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str1") ?? ""}
										onSelect={(option) =>
											classifierIdOnChange(option.value)
										}
									/>
								</LabeledField> */}
								<LabeledField
									label={
										t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str2",
										) ?? ""
									}
								>
									<Select
										error={errors.commissionPlanIdValue}
										value={commissionPlanIdValue}
										disabled={
											commissionPlanOptions.length === 0
										}
										options={commissionPlanOptions}
										placeholder={
											t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str2",
											) ?? ""
										}
										onSelect={onSelect}
									/>
								</LabeledField>
							</Row>
							<Row gaps="8px" sizes="1fr*">
								<StyledColumn>
									{errors.startDate && (
										<StyledSpan colors="red">
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str201",
											) ?? ""}
										</StyledSpan>
									)}
									<StyledRow
										gap="10px"
										h="32px"
										alignItems="center"
									>
										<StyledSpan>
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str100",
											) ?? ""}
										</StyledSpan>
										{timeRangeActiveValue ? (
											<DatePicker
												value={startDateValueValue}
												locale={locale}
												onChange={handleStartDate}
											/>
										) : (
											<DateAndTimePicker
												value={startDateValueValue}
												locale={locale}
												onChange={handleStartDate}
												step={60}
											/>
										)}
										<StyledSpan>
											{getDayOfWeek(startDateValueValue)}
										</StyledSpan>
									</StyledRow>
								</StyledColumn>
								<CheckBoxWithContent
									value={timeRangeActiveValue}
									gap="10px"
									onChange={timeRangeActiveOnChange}
								>
									<Row
										gaps="10px*"
										align="center"
										style={{
											height: 32,
											lineHeight: "18px",
										}}
									>
										<span>
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str101",
											) ?? ""}
										</span>
										<TimeInput
											value={timeRangeFromValue}
											disabled={!timeRangeActiveValue}
											onChange={timeRangeFromOnChange}
										/>
										<span>
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str102",
											) ?? ""}
										</span>
										<TimeInput
											value={timeRangeToValue}
											disabled={!timeRangeActiveValue}
											onChange={timeRangeToOnChange}
										/>
									</Row>
								</CheckBoxWithContent>
							</Row>
							<Row gaps="8px" sizes="1fr*">
								<Row
									align="center"
									gaps="10px*"
									sizes="auto! 150px! auto!"
								>
									<span>
										{t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str4",
										) ?? ""}
									</span>
									<Select
										value={periodUnitValue}
										options={periodUnitOptions}
										placeholder={
											t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str4",
											) ?? ""
										}
										onSelect={onSelectPeriod}
									/>
									<Stepper
										value={periodValueValue}
										min={1}
										max={limitDay}
										onChange={periodStepperOnChange}
										disabled={
											periodUnitValue !==
											ExecutorRateDiscountPlan.Model
												.Period.Type.Daily
										}
									/>
								</Row>
								<Row align="center" gaps="10px*">
									<span>
										{t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str202",
										) ?? ""}
										:{" "}
									</span>
									<Stepper
										value={thresholdValueValue}
										onChange={thresholdValueOnChange}
									/>
									<span>
										{t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str203",
										) ?? ""}
									</span>
								</Row>
							</Row>
							<StyledRow p="0 10px" alignItems="center">
								<StyledP cursor="default">{datePeriod}</StyledP>
							</StyledRow>
						</Column>
					</FieldsContainer>

					<CheckBoxSelect
						value={weekdayCheckBoxSelectValue}
						title={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str5",
							) ?? ""
						}
						options={weekdayOptions}
						onChange={weekdayCheckBoxSelectOnChange}
					/>

					<Commissions
						value={commissionsValue}
						onChange={commissionsOnChange}
					/>

					<FieldsContainer
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str204",
							) ?? ""
						}
					>
						<StyledColumn gap="10px">
							<CheckBoxWithStepper
								value={minFee}
								onChange={setMinFee}
								textAfterStepper={currencyGlobalSettings}
								stepperSetting={minMaxOptions.min}
								columns={"1fr auto"}
								title={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str206",
									) ?? ""
								}
							/>
							<CheckBoxWithStepper
								value={maxFee}
								onChange={setMaxFee}
								textAfterStepper={currencyGlobalSettings}
								stepperSetting={minMaxOptions.max}
								columns={"1fr auto"}
								title={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.discountPlanTab.editModal.content.str208",
									) ?? ""
								}
							/>
						</StyledColumn>
					</FieldsContainer>
				</Column>
			</Root>
		);
	},
	InternalController,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;

		commissionPlans: ExecutorRateCommissionPlan.Model[];
		language: Language;

		onChange: Dispatch<Value>;
		setTypeName: Dispatch<string>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		// classifierId?: number;
		commissionPlanId?: number;

		name?: string;

		startDate: Value.StartDate;
		timeRange: Value.TimeRange;
		period: Value.Period;
		threshold: Value.Threshold;
		weekdays: Value.Weekdays;
		commissions: Commissions.Value;
		minFee: Value.MinMaxFee;
		maxFee: Value.MinMaxFee;
	}

	namespace Value {
		interface Weekdays {
			monday: boolean;
			tuesday: boolean;
			wednesday: boolean;
			thursday: boolean;
			friday: boolean;
			saturday: boolean;
			sunday: boolean;
		}

		interface StartDate {
			value?: Date | null;
		}

		interface TimeRange {
			active: boolean;
			from?: Date | null;
			to?: Date | null;
		}

		interface Period {
			unit: (typeof periodUnitOptions)[number]["value"];
			value: number;
		}

		interface Threshold {
			value?: number;
		}

		interface MinMaxFee {
			active: boolean;
			value: number;
		}
	}
}

export default Content;
