import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, Row, Stepper } from "uikit";
import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import Label from "../../../../../../../../../../../../../../components/Label";

import UnitSelect from "../UnitSelect";

const CumulativeMode: React.FC<CumulativeMode.Props> = ({
	value,

	disabled,

	onChange,
}) => {
	const { t } = useTranslation();
	const defaultValue = useMemo<CumulativeMode.Value>(
		() => ({
			bonus: {
				frequency: 0,
				amount: 0,
				unit: CustomerRate.Model.Unit.Percentage,
			},

			onlineBonus: {
				active: false,
				frequency: 0,
				amount: 0,
				unit: CustomerRate.Model.Unit.Percentage,
			},
		}),
		[],
	);

	const valueEditor = useObjectEditor(value ?? defaultValue, onChange);

	const bonusEditor = valueEditor.usePropertyEditor("bonus");

	const bonusFrequencyValue = bonusEditor.useGetter("frequency");
	const bonusFrequencyOnChange = bonusEditor.useSetter("frequency");

	const bonusAmountValue = bonusEditor.useGetter("amount");
	const bonusAmountOnChange = bonusEditor.useSetter("amount");

	const bonusUnitValue = bonusEditor.useGetter("unit");
	const bonusUnitOnChange = bonusEditor.useSetter("unit");

	const onlineBonusEditor = valueEditor.usePropertyEditor("onlineBonus");

	const onlineBonusActiveValue = onlineBonusEditor.useGetter("active");
	const onlineBonusActiveOnChange = onlineBonusEditor.useSetter("active");

	const onlineBonusFrequencyValue = onlineBonusEditor.useGetter("frequency");
	const onlineBonusFrequencyOnChange =
		onlineBonusEditor.useSetter("frequency");

	const onlineBonusValueValue = onlineBonusEditor.useGetter("amount");
	const onlineBonusValueOnChange = onlineBonusEditor.useSetter("amount");

	const onlineBonusUnitValue = onlineBonusEditor.useGetter("unit");
	const onlineBonusUnitOnChange = onlineBonusEditor.useSetter("unit");

	return (
		<FieldsContainer
			label={
				t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.cumulativeMode.str0",
				) ?? ""
			}
		>
			<Column gaps="8px*">
				<Row
					align="center"
					gaps="10px*"
					sizes="auto!* 1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.cumulativeMode.str100",
						) ?? ""}
					</Label>
					<Stepper
						disabled={disabled}
						min={0}
						value={bonusFrequencyValue}
						onChange={(value) => bonusFrequencyOnChange(value ?? 0)}
					/>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.cumulativeMode.str101",
						) ?? ""}
					</Label>
					<Stepper
						disabled={disabled}
						min={0}
						value={bonusAmountValue}
						onChange={(value) => bonusAmountOnChange(value ?? 0)}
					/>
					<UnitSelect
						value={bonusUnitValue}
						disabled={disabled}
						onChange={bonusUnitOnChange}
					/>
				</Row>
				<CheckBoxWithContent
					value={onlineBonusActiveValue}
					disabled={disabled}
					maxContentWidth
					onChange={onlineBonusActiveOnChange}
				>
					<Row
						align="center"
						gaps="10px*"
						sizes="auto!* 1fr auto! 60px"
						style={{ height: 32 }}
						maxedWidth
					>
						<Label>
							{t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.cumulativeMode.str100",
							) ?? ""}
						</Label>
						<Stepper
							value={onlineBonusFrequencyValue}
							min={0}
							disabled={disabled || !onlineBonusActiveValue}
							onChange={(value) =>
								onlineBonusFrequencyOnChange(value ?? 0)
							}
						/>
						<Label>
							{t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.cumulativeMode.str130",
							) ?? ""}
						</Label>
						<Stepper
							value={onlineBonusValueValue}
							min={0}
							disabled={disabled || !onlineBonusActiveValue}
							onChange={(value) =>
								onlineBonusValueOnChange(value ?? 0)
							}
						/>
						<UnitSelect
							value={onlineBonusUnitValue}
							disabled={disabled || !onlineBonusActiveValue}
							onChange={onlineBonusUnitOnChange}
						/>
					</Row>
				</CheckBoxWithContent>
			</Column>
		</FieldsContainer>
	);
};

declare namespace CumulativeMode {
	interface Value {
		bonus: {
			frequency: number;
			amount: number;
			unit: CustomerRate.Model.Unit;
		};

		onlineBonus: {
			active: boolean;
			frequency: number;
			amount: number;
			unit: CustomerRate.Model.Unit;
		};
	}

	interface Props {
		value?: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default CumulativeMode;
