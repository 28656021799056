import React, { memo } from "react";
import { Row } from "uikit";
import DateRange from "./components/DateRange";
import Search from "./components/Search";
import PopupFilter from "./components/PopupFilter";

const Header: React.FC<Header.Props> = memo(() => (
	<Row
		style={{ padding: "15px 15px 5px 15px" }}
		justify="center"
		gaps="20px*"
	>
		<Row gaps="15px*" justify="center" align="center">
			<DateRange />
			<Search />
			<PopupFilter />
		</Row>
	</Row>
));

declare namespace Header {
	interface Props {}
}

export default Header;
