import styled from "styled-components";
import { theme } from "uikit";
import Item from "../Item";

const ClearButton = styled(Item)`
	background: transparent;

	color: ${theme.colors.negative};

	cursor: pointer;
`;

export default ClearButton;
