import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import ExecutingStageAcceptedTimeCellContent from "../components/ExecutingStageAcceptedTimeCellContent";

function executingStageAcceptedTime({ width, onResize }: Column.Options) {
	return base(
		ColumnId.ExecutingStageAcceptedTime,
		(item) => <ExecutingStageAcceptedTimeCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default executingStageAcceptedTime;
