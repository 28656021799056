/* eslint-disable import/no-unresolved */

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InputifiedValueEvent } from "uikit/dist/utils/react";
import { CheckBox, Column, Row, MultiSelect } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	createObjectLanguage,
	createObjectLanguageNames,
	createObjectLanguageNamesFromObject,
} from "../../../../../../../../assets/languages/langs";
import ColorPicker from "../../../../../../../../components/ColorPicker";
import EditModalBase from "../../../../../../../../components/EditModal";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import LabeledField from "../../../../../../../../components/LabeledField";
import { Names, NamesBase } from "../../../../../../../../components/common";
// import RowTitledTextBox from "../../../components/TitledTextBox";

import { Value } from "./types";
import Header from "./components/Header";

const Root = styled(Column)`
	width: 1200px;
	height: 84vh;

	min-width: 900px;
	min-height: 500px;

	border-radius: 5px;
	background-color: white;
	overflow: hidden;
`;

const StyledCheckBox = styled(CheckBox)`
	width: 200px;
`;

const Content = styled(Column)`
	padding: 16px 20px 50px;
`;

const Fields = styled.div`
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	z-index: 99999;
`;

const ColorsModal: React.FC<any> = ({
	onCancel,
	onSave,
	data: dataProps,
	companyIdsProp,
}) => {
	const { t } = useTranslation();
	const nameFormRef = useRef<NamesBase.Controller | null>(null);

	const [data, setData] = useState(
		dataProps !== true
			? {
					...dataProps,
					fields: createObjectLanguageNamesFromObject(dataProps.name),
					color: dataProps?.value
						? `#${dataProps?.value.toString(16)}`
						: 0,
					companyIds: companyIdsProp,
					taxiServices:
						dataProps?.taxiServices.map((item) => item.id) || [],
					isUseColor: Boolean(dataProps?.value),
			  }
			: {
					fields: createObjectLanguageNames(""),
					color: "#df8d21",
					companyIds: [],
					taxiServices: [],
					active: true,
					isUseColor: true,
			  },
	);

	const [errors, setErrors] = useState({
		...createObjectLanguage<boolean>(false),
		companyIds: false,
		taxiServices: false,
	});

	const language = useTypedSelector((state) => state.mapReducer.mapLang);

	const [companyAndTaxiIds, setCompanyAndTaxiIds] = useState<Value>({
		companyIds: companyIdsProp || [],
		taxiServiceIds: dataProps?.taxiServices?.map((item) => item.id) || [],
	});

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			setErrors({ ...errors, taxiServices: false });

			setCompanyAndTaxiIds({ ...companyAndTaxiIds, taxiServiceIds });
		},
		[companyAndTaxiIds, errors, setCompanyAndTaxiIds],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			setErrors({ ...errors, companyIds: false });

			setCompanyAndTaxiIds({ ...companyAndTaxiIds, companyIds });
		},
		[companyAndTaxiIds, errors, setCompanyAndTaxiIds],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyAndTaxiIds.companyIds,
		companyAndTaxiIds.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: taxiService.settlement[language],
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name[language],
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	useEffect(() => {
		setData({
			...data,
			companyIds: companyAndTaxiIds.companyIds,
			taxiServices: companyAndTaxiIds.taxiServiceIds,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyAndTaxiIds.taxiServiceIds, companyAndTaxiIds.companyIds]);

	const clearNameFieldsValidations = useCallback(() => {
		setErrors({
			...errors,
			...createObjectLanguage<boolean>(false),
		});
	}, [errors]);

	// const changeRuNameField = useCallback(
	// 	(title) => {
	// 		setData({
	// 			...data,
	// 			fields: {
	// 				...data.fields,
	// 				ru: title,
	// 			},
	// 		});
	// 		clearNameFieldsValidations();
	// 	},
	// 	[clearNameFieldsValidations, data],
	// );
	const changeRuNameField = useCallback(
		(fields) => {
			setData({
				...data,
				fields,
			});
			clearNameFieldsValidations();
		},
		[clearNameFieldsValidations, data],
	);

	// const changeUkNameField = useCallback(
	// 	(title) => {
	// 		setData({
	// 			...data,
	// 			fields: {
	// 				...data.fields,
	// 				uk: title,
	// 			},
	// 		});
	// 		clearNameFieldsValidations();
	// 	},
	// 	[clearNameFieldsValidations, data],
	// );
	// const changeEnNameField = useCallback(
	// 	(title) => {
	// 		setData({
	// 			...data,
	// 			fields: {
	// 				...data.fields,
	// 				en: title,
	// 			},
	// 		});
	// 		clearNameFieldsValidations();
	// 	},
	// 	[clearNameFieldsValidations, data],
	// );

	const colorModalTitle = useMemo(
		() =>
			dataProps !== true
				? t(
						"pages.preferencesPages.screenDirectory.colors.modal.str200",
				  ) ?? ""
				: t(
						"pages.preferencesPages.screenDirectory.colors.modal.str201",
				  ) ?? "",
		[dataProps, t],
	);

	const confirmSaveData = useCallback(() => {
		// const { fields } = data;
		// const arrLanguages = pick(fields, languages);

		// const nameFieldsError = Object.values(arrLanguages)?.every(
		// 	(item) => !item.length,
		// );

		const nameFieldsError = !!nameFormRef.current?.validate();
		const { companyIds, taxiServices } = data;

		if (!companyIds.length) errors.companyIds = true;
		if (!taxiServices.length) errors.taxiServices = true;

		setErrors({
			...errors,
			...createObjectLanguage<boolean>(nameFieldsError),
		});

		if (nameFieldsError && companyIds.length && taxiServices.length) {
			data.taxiServices = taxiServiceIds;
			onSave(data);
		}
	}, [data, errors, onSave, taxiServiceIds]);

	return (
		<EditModalBase
			onCancel={onCancel}
			onSave={confirmSaveData}
			footer={
				<Row align="center" gaps="20px*">
					<CheckBox
						label={
							t(
								"pages.preferencesPages.screenDirectory.colors.modal.str0",
							) ?? ""
						}
						value={data.active}
						onChange={(active) => setData({ ...data, active })}
					/>
				</Row>
			}
		>
			<Root sizes="auto 1fr auto">
				<Header title={colorModalTitle} />
				<Content>
					<Fields>
						<Row gaps="22px" justify={"space-between"}>
							<LabeledField
								label={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str1",
									) ?? ""
								}
							>
								<MultiSelect
									all
									style={{ width: "100%" }}
									error={errors.companyIds}
									placeholder={
										t(
											"pages.preferencesPages.screenDirectory.colors.modal.str1",
										) ?? ""
									}
									options={possibleCompanyOptions}
									value={companyIds}
									onChange={
										setCompanyIds as InputifiedValueEvent<MultiSelect.Value>
									}
								/>
							</LabeledField>
							<LabeledField
								label={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str3",
									) ?? ""
								}
							>
								<MultiSelect
									all
									style={{ width: "100%" }}
									error={errors.taxiServices}
									placeholder={
										t(
											"pages.preferencesPages.screenDirectory.colors.modal.str3",
										) ?? ""
									}
									options={possibleTaxiServiceOptions}
									value={taxiServiceIds}
									onChange={
										setTaxiServiceIds as InputifiedValueEvent<MultiSelect.Value>
									}
								/>
							</LabeledField>
						</Row>
						<Row gaps="22px">
							<ColorPicker
								label={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str202",
									) ?? ""
								}
								defaultColor={data.color}
								color={data.color}
								onChange={(color) =>
									setData({
										...data,
										color,
									})
								}
							/>
							<StyledCheckBox
								value={data.isUseColor}
								label={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str203",
									) ?? ""
								}
								onChange={(value) =>
									setData({ ...data, isUseColor: value })
								}
							/>
						</Row>
						<Column gaps="22px*">
							<Names
								require
								ref={nameFormRef}
								value={data?.fields}
								onChange={changeRuNameField}
								placeholder="pages.preferencesPages.screenDirectory.carClass.modal.main.mainData.str200"
							/>

							{/* <RowTitledTextBox
								error={errors.ru}
								title={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str5",
									) ?? ""
								}
								value={data?.fields?.ru}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str204",
									) ?? ""
								}
								onChange={changeRuNameField}
							/>
							<RowTitledTextBox
								error={errors.uk}
								title={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str6",
									) ?? ""
								}
								value={data?.fields?.uk}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str204",
									) ?? ""
								}
								onChange={changeUkNameField}
							/>
							<RowTitledTextBox
								error={errors.en}
								title="En"
								value={data?.fields?.en}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.colors.modal.str204",
									) ?? ""
								}
								onChange={changeEnNameField}
							/> */}
						</Column>
					</Fields>
				</Content>
			</Root>
		</EditModalBase>
	);
};

export default ColorsModal;
