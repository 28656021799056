import { ColumnId } from "../../../components/OrderPageWidgets/OrderDetails/components/Points/components/Table/columns";
import makeTable from "../makeTable";

const initialWidths = {
	[ColumnId.Number]: 36,
	[ColumnId.Settlement]: 100,
	[ColumnId.Street]: 440,
	[ColumnId.Building]: 50,
	[ColumnId.Entrance]: 50,
	[ColumnId.Sector]: 115,
};

const allIds = Object.values(ColumnId);

const hiddenColumns: ColumnId[] = [];

const defaultIds = allIds.filter((id) => !hiddenColumns.includes(id));

const orderLive = makeTable({
	allIds,
	defaultIds,
	i18tPath: (id) => `tableColumns.orders.points.${id}`,

	initialSortMethod: {
		column: ColumnId.Number,
		type: "asc",
	},
	initialWidths,
});

export default orderLive;
