import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 292px;
	height: 562px;

	position: relative;

	align-items: center;

	background-color: ${theme.colors.white};
	border-radius: 5px;
	box-shadow: 0px 8px 30px 6px #0000001a;
`;

export default Root;
