import React, { useMemo } from "react";
import { isNumber } from "lodash";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutorLateCellContent: React.FC<ExecutorLateCellContent.Props> = ({
	item,
}) => {
	const delay = useMemo(
		() => item.executingStages?.delayed?.data?.delay,
		[item.executingStages?.delayed?.data?.delay],
	);

	const content = useMemo(
		() =>
			isNumber(delay) && delay > 0
				? `${(delay / 60e3).toFixed(0)} мин.`
				: "",
		[delay],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorLateCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutorLateCellContent;
