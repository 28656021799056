import React from "react";
import { ColumnProps, RowDataType } from "rsuite-table";

import CompactTable from "../../../../../CompactTable";
import Order from "../../../../../../services/Order";
import { TranslationText } from "../../../../../common";

import columns, { ColumnId } from ".";

function base(
	columnId: ColumnId,
	render: base.Render,
	column: Partial<ColumnProps<RowDataType>>,
) {
	return (
		<CompactTable.Column key={columnId} resizable sortable {...column}>
			<CompactTable.HeaderCell>
				<TranslationText
					text={[
						columns[columnId].pathTranslation,
						columns[columnId].label,
					]}
				/>
			</CompactTable.HeaderCell>
			<CompactTable.Cell dataKey={columnId} fullText>
				{(rowData) => render(rowData as Order.Model)}
			</CompactTable.Cell>
		</CompactTable.Column>
	);
}

declare namespace base {
	type Render = (item: Order.Model) => React.ReactNode;
}

export default base;
