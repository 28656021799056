import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "uikit";

import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../components/common";
import { useArchiveSwitcher } from "../../../../hooks";
import Modal from "../../../../../../../../components/Modal";
import { CenterPartMemo } from "../Header/components";

const StyledColumnWrap = styled(StyledColumn)`
	overflow: hidden;
	border-radius: 10px;
	background-color: white;
`;

const StyledRowWrap = styled(StyledRow)`
	overflow: hidden;
	bottom: 0px;
	right: 0px;
`;

const ArchiveFiltersModal: React.FC<
	ArchiveFiltersModal.Props
> = (): JSX.Element => {
	const { t } = useTranslation();
	const {
		orderToggles: { isArchiveFilterModal },
		setFilterModalToggle,
	} = useArchiveSwitcher();

	const onCloseModal = useCallback(() => {
		if (isArchiveFilterModal) setFilterModalToggle("off");
	}, [isArchiveFilterModal, setFilterModalToggle]);

	return (
		<>
			{isArchiveFilterModal && (
				<Modal dimmed>
					<StyledColumnWrap w="95%" h="95%" position="relative">
						<StyledColumn
							w="100%"
							h="100%"
							p="1rem"
							justify="start"
							alignItems="center"
							gap="10px"
							position="absolute"
						>
							<CenterPartMemo />
						</StyledColumn>
						<StyledRowWrap
							justify="end"
							alignItems="center"
							p="10px"
							gap="10px"
							w="100%"
							position="absolute"
						>
							<Button.Button
								variant="secondary"
								text={t(`save`) || "Save"}
								onClick={onCloseModal}
							/>
							<Button.Button
								variant="secondary"
								text={t(`close`) || "Close"}
								onClick={onCloseModal}
							/>
						</StyledRowWrap>
					</StyledColumnWrap>
				</Modal>
			)}
		</>
	);
};

declare namespace ArchiveFiltersModal {
	interface Props {}
}
export const ArchiveFiltersModalMemo = memo(ArchiveFiltersModal);
export default ArchiveFiltersModal;
