import React, { memo, useCallback, useMemo } from "react";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import { Stepper } from "uikit";

import Style, {
	StyledGrid,
	StyledGridItem,
	StyledRow,
	StyledSpan,
} from "../styles";
import ValueStepperBase, { ValueStepper } from "../ValueStepper";
import LiteStepper from "../LiteStepper";

const TextWithStepperBase: React.FC<TextWithStepperBase.Props> = ({
	value,
	onChange,
	disabled = false,
	title,
	titleStepper,
	stepperSetting,
	textAfterStepper,
	lite,
	liteStepperSetting,
	...props
}) => {
	const { t } = useTranslation();

	const data = useMemo(() => {
		if (titleStepper === "units.second" || titleStepper === "units.km") {
			return value / 1e3;
		}

		if (
			titleStepper === "units.minute" ||
			titleStepper === "units.minutes"
		) {
			return value / 60e3;
		}

		return value;
	}, [titleStepper, value]);

	const handelValue = useCallback(
		(value: Stepper.Value) => {
			if (isUndefined(value)) {
				onChange(0);
				return;
			}

			if (
				titleStepper === "units.second" ||
				titleStepper === "units.km"
			) {
				onChange(isUndefined(value) ? 0 : value * 1e3);
				return;
			}

			if (
				titleStepper === "units.minute" ||
				titleStepper === "units.minutes"
			) {
				onChange(isUndefined(value) ? 0 : value * 60e3);
				return;
			}

			onChange(isUndefined(value) ? 0 : value);
		},
		[onChange, titleStepper],
	);

	const texts = useMemo(
		() => ({
			title: t(title),
			titleStepper: titleStepper ? t(titleStepper) : "",
			after: textAfterStepper ? t(textAfterStepper) : "",
		}),
		[t, textAfterStepper, title, titleStepper],
	);

	const colorText = useMemo(
		() => (!disabled ? "#21333f" : "#647079"),
		[disabled],
	);

	const stepperError = useMemo(() => {
		if (stepperSetting?.error)
			return `div {
							border-color: red;
						}`;

		return "";
	}, [stepperSetting?.error]);

	return (
		<StyledGrid
			areas="'checkbox stepper'"
			alignItems="center"
			gap="10px"
			columns="auto 1fr"
			{...props}
		>
			<StyledGridItem area="checkbox" gap="10px">
				<StyledRow alignItems="center" gap="10px">
					<StyledSpan colors={colorText}>{texts.title}</StyledSpan>
				</StyledRow>
			</StyledGridItem>

			<StyledGridItem area="stepper" gap="10px">
				<StyledRow alignItems="center" gap="10px" css={stepperError}>
					{!lite && (
						<ValueStepper
							min={0}
							decimalCount={0}
							width="60px"
							{...stepperSetting}
							value={data}
							disabled={disabled}
							onChange={handelValue}
						/>
					)}

					{lite && (
						<LiteStepper
							min={0}
							w="60px"
							{...liteStepperSetting}
							value={data}
							disabled={disabled}
							onChange={handelValue}
						/>
					)}
					<StyledSpan colors={colorText}>
						{texts.titleStepper}
					</StyledSpan>
					{texts.after && (
						<StyledSpan colors={colorText}>
							{texts.after}
						</StyledSpan>
					)}
				</StyledRow>
			</StyledGridItem>
		</StyledGrid>
	);
};

export const TextWithStepper = memo(TextWithStepperBase);

declare namespace TextWithStepperBase {
	type Value = number;

	interface Props extends Partial<Style.Grid> {
		value: Value;
		onChange: (value: Value) => void;
		title: string | string[];

		stepperSetting?: Omit<
			ValueStepperBase.Props,
			"disabled" | "value" | "onChange"
		>;
		textAfterStepper?: string | string[];
		titleStepper?:
			| "units.pieces"
			| "units.millisecond"
			| "units.second"
			| "units.minutes"
			| "units.hours"
			| "units.minute"
			| "units.hour"
			| "units.day"
			| "units.kph"
			| "units.km"
			| "units.meter"
			| "units.mps";

		disabled?: boolean;

		lite?: boolean;

		liteStepperSetting?: Omit<
			LiteStepper.Props,
			"disabled" | "value" | "onChange"
		>;
	}
}

export default TextWithStepperBase;
