import Account from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/Modal/components/Content/tabs/Accounts";
import stringToUndefined from "../../utils/stringToUndefined";

import checkCardToRequest from "./checkCardToRequest";

export default function checkToRequest(
	check: Account.Check,
): Record<string, any> {
	const mainDiscountPlanId = check.mainRateId === 0 ? null : check.mainRateId;
	const additionalDiscountPlanId =
		check.additionalRateId === 0 ? null : check.additionalRateId;

	const toRequest: Record<string, any> = {
		id: check.id,
		active: check.active,
		activationDate: check.activationDate,
		completionDate: check.completionDate ?? undefined,
		additionalFields: {
			address: stringToUndefined(check.additionalFields?.address),
			contactName: stringToUndefined(check.additionalFields?.contactName),
			email: stringToUndefined(check.additionalFields?.email),
			notes: stringToUndefined(check.additionalFields?.notes),
			orderNotes: stringToUndefined(check.additionalFields?.orderNotes),
			executorNotes: stringToUndefined(
				check.additionalFields?.executorNotes,
			),
			deactivationThreshold: {
				description: stringToUndefined(
					check.additionalFields?.deactivationThreshold.description,
				),
				value: check.additionalFields?.deactivationThreshold.value,
			},
			checkValue: check.additionalFields.checkValue,
			phones: check.additionalFields?.phones.filter(
				(phone) => !!stringToUndefined(phone),
			),
			tripLimit: check.additionalFields.tripLimit,
		},
		corporateLogin: check.corporateLogin ?? undefined,
		corporatePassword: check.corporatePassword ?? undefined,
		otherFileIds: check.otherFiles?.map(({ id }) => id) ?? [],
		personalFileIds: check.personalFiles?.map(({ id }) => id) ?? [],

		accessAllEmployeeToPersonalApp: check.accessAllEmployeeToPersonalApp,
		isAccessToCorporateAccount: check.isAccessToCorporateAccount,

		useGeneralMainBalance: check.useGeneralMainBalance,
		useGeneralBonusBalance: check.useGeneralBonusBalance,
		useGeneralThresholdBalance: check.useGeneralThresholdBalance,
		ignoreThresholdBalance: check.ignoreThresholdBalance,
		allowBonusBalance: check.allowBonusBalance,

		mainDiscountPlanId,
		additionalDiscountPlanId,

		checkCards: check.checkCards.map(checkCardToRequest),
	};

	if (check.paymentTransactions) {
		const threshold = check?.paymentTransactions.threshold?.[0];

		const totalAmount = threshold?.amount || 0;

		const description = threshold?.description || "";

		const deactivationThreshold = {
			value: totalAmount,
			description: stringToUndefined(description),
		};

		toRequest.additionalFields.deactivationThreshold =
			deactivationThreshold;
	}

	if (!("id" in check) && !check.id) {
		toRequest.paymentTransactions = check.paymentTransactions;
	}

	if (toRequest?.paymentTransactions?.threshold) {
		delete toRequest.paymentTransactions.threshold;
	}

	if (
		check.paymentAccounts?.some((payment) => payment.type === "threshold")
	) {
		const threshold = check?.paymentAccounts?.find(
			(payment) => payment.type === "threshold",
		);
		toRequest.additionalFields.deactivationThreshold.description =
			stringToUndefined(threshold?.description || "");
		toRequest.additionalFields.deactivationThreshold.value =
			threshold?.amount || 0;
	}

	return toRequest;
}
