import React, { Dispatch } from "react";
import Root from "./components/Root";

import Item from "./components/Item";
import SMSTemplate from "../../../../../../../../../../../../../../services/SMSTemplate";

const List: React.FC<List.Props> = ({ items, onSelect }) => (
	<Root>
		{items.map((item) => (
			<Item key={item.id} value={item} onClick={() => onSelect(item)} />
		))}
	</Root>
);

declare namespace List {
	interface Props {
		items: SMSTemplate.Model[];
		onSelect: Dispatch<SMSTemplate.Model>;
	}
}

export default List;
