import { Action, combineReducers } from "@reduxjs/toolkit";

import { chats, ordersChatAction, StateChats } from "./Chat";
import { parking, ordersParkingAction, StateParking } from "./Parking";
import { executors, ordersExecutorsAction, StateExecutors } from "./Executors";
import { map, ordersMapAction, StateMap } from "./Map";
import {
	mapFromAddModal,
	ordersMapFromAddModalAction,
	StateMapFromAddModal,
} from "./MapFromAddModal";
import { tabs, ordersTabsAction, StateTabs } from "./Tabs";
import { Orders } from "./interface";

export { Orders };

export type State = {
	chats: StateChats;
	parking: StateParking;
	executors: StateExecutors;
	map: StateMap;
	mapFromAddModal: StateMapFromAddModal;
	tabs: StateTabs;
};

export type Actions = {
	chats: typeof ordersChatAction;
	parking: typeof ordersParkingAction;
	executors: typeof ordersExecutorsAction;
	map: typeof ordersMapAction;
	mapFromAddModal: typeof ordersMapFromAddModalAction;
	tabs: typeof ordersTabsAction;
};

export const ordersReducer = combineReducers<State, Action<Actions>>({
	chats,
	parking,
	executors,
	map,
	tabs,
	mapFromAddModal,
});

export const ordersAction: Actions = {
	chats: ordersChatAction,
	parking: ordersParkingAction,
	executors: ordersExecutorsAction,
	map: ordersMapAction,
	mapFromAddModal: ordersMapFromAddModalAction,
	tabs: ordersTabsAction,
};

export default ordersReducer;
