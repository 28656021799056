import { combineReducers } from "@reduxjs/toolkit";

import {
	orderTabsFiltersState,
	orderTabsFiltersReducer,
	orderTabsFiltersAction,
} from "./filter";

export type StateTabs = {
	filter: orderTabsFiltersState;
};

export const tabs = combineReducers<StateTabs>({
	filter: orderTabsFiltersReducer,
});

export const ordersTabsAction = {
	filter: orderTabsFiltersAction,
};

export default tabs;
