import React, { useCallback, useMemo } from "react";
import { Row, CheckBox, DateAndTimePicker } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import useDatePickerLocale from "../../../../../../../../../../../../../../../../../../../../hooks/useDatePickerLocale";

const ValidityDate: React.FC<ValidityDate.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const from = valueEditor.useGetter("from");
	const setFrom = valueEditor.useSetter("from");
	const handleFromChange = useCallback(
		(date) => {
			const timestamp = new Date(date).getTime();
			setFrom(timestamp);
		},
		[setFrom],
	);

	const to = valueEditor.useGetter("to");
	const setTo = valueEditor.useSetter("to");
	const handleToChange = useCallback(
		(date) => {
			const timestamp = new Date(date).getTime();
			setTo(timestamp);
		},
		[setTo],
	);

	const dateFrom = useMemo(() => new Date(from), [from]);
	const dateTo = useMemo(() => new Date(to), [to]);

	return (
		<Row gaps="10px*" align="center">
			<CheckBox
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.validityDate.str0",
					) ?? ""
				}
				value={active}
				onChange={setActive}
			/>
			{t(`from`)}
			<DateAndTimePicker
				value={dateFrom}
				onChange={handleFromChange}
				locale={locale}
			/>

			{t(`to`)}
			<DateAndTimePicker
				value={dateTo}
				onChange={handleToChange}
				locale={locale}
			/>
		</Row>
	);
};

declare namespace ValidityDate {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		from: number;
		to: number;
	}
}

export default ValidityDate;
