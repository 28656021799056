import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import GeneralTariff from "../../../../../../services/GeneralTariff";
import CarClass from "../../../../../../services/CarClass";
import useCurrentTaxiServices from "../../../../../../hooks/useCurrentTaxiService";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../../utils/mapByKey";
import { ModelId } from "../../../../../../types/ModelId";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";
import { SettingsTabProps } from "../../../../../../components/Settings/tabProps";
import { useTableOptions } from "../../../../../../components/LightTable";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../constants/access";

import defaultValue from "./constants/defaultValue";
import tPath from "./constants/tPath";
import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";

const General: React.FC<SettingsTabProps> = () => {
	const { t } = useTranslation();
	const { setDataLength, dataLength, lang, limit, editor, onChange } =
		useTableOptions();

	const optionsGeneralTariff = useMemo(() => {
		// const order = {};
		const payload: GeneralTariff.SubscribeOptions = {
			limit,
			// order,
		};

		// if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, [limit]);

	const { models: generalTariffs } = useModelSubscribe(
		optionsGeneralTariff,
		GeneralTariff,
	);

	useLayoutEffect(() => {
		if (dataLength !== generalTariffs.length) {
			setDataLength(generalTariffs.length);
		}
	}, [generalTariffs.length, dataLength, setDataLength]);

	const { models: carClasses } = useModelSubscribe({}, CarClass);

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const settings = useCurrentTaxiServices()?.settings;
	const generalRateSettings = settings?.defaults.generalRateSettings;

	useEffect(() => {
		setActualDefaultValue({
			...defaultValue,
			additionalFields: {
				...defaultValue.additionalFields,
				...(generalRateSettings ?? {}),
			},
		});
	}, [generalRateSettings]);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selected, setSelected] = useState<ModelId[]>([]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);

	const modelItemById = useMemo(
		() => mapByKey(generalTariffs, "id"),
		[generalTariffs],
	);

	const edit = useCallback(
		(id: ModelId) => {
			const item = modelItemById[id];
			setEditingItem(cloneDeep(item));

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(tariff: GeneralTariff.Modified) => edit(tariff.id),
		[edit],
	);

	const copyHandler = useCallback(async () => {
		await GeneralTariff.copy(selected[0]);
		setSelected([]);
	}, [selected]);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(async () => {
		await GeneralTariff.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const saveHandler = useCallback(
		async (tariff: GeneralTariff.New | GeneralTariff.Modified) => {
			if ("id" in tariff) {
				await GeneralTariff.update(tariff);
			} else {
				await GeneralTariff.store(tariff);
			}

			setShowModal(false);
			setEditingItem(actualDefaultValue);
		},
		[actualDefaultValue],
	);

	const headerTitle = useMemo(
		() =>
			editingItem?.id
				? `${t(`${tPath}.modal.title.edit`)} "${
						editingItem.name[lang]
				  }"`
				: t(`${tPath}.modal.title.add`),
		[editingItem?.id, editingItem.name, lang, t],
	);

	const deleteModalLabel = useMemo(
		() =>
			selected.length === 1
				? t(`${tPath}.deleteModal.title`) || ""
				: t(`${tPath}.deleteModal.title2`) || "",
		[selected.length, t],
	);

	useLayoutEffect(() => {
		if (editingItem?.id) {
			const item = modelItemById?.[editingItem.id];

			const carClassIds = item.carClassIds || [];

			if (item) {
				setEditingItem({
					...editingItem,
					carClassIds,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalTariffs]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				afterAdditionalButtons={[
					{
						icon: { id: "copy", size: 20 },
						onClick: copyHandler,
						disabled: selected.length !== 1,
					},
				]}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.GENERAL_TARIFFS]}
			/>
			<Content
				value={generalTariffs}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					carClasses={carClasses}
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={headerTitle}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={deleteModalLabel}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default General;
