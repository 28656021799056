import React, { memo } from "react";

import { StyledColumn } from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";

import Customer from "./Customer";
import CodeCounterpartOrAgent from "./CodeCounterpartOrAgent";
import OrderNodes from "./OrderNodes";

const BasePersonalInformation: React.FC<BasePersonalInformation.Props> = ({
	tab,
	form,
	disabled = true,
	setTabAccept,
}) => (
	<StyledColumn
		gap="clamp(3.7px, 0.6vh, 6px)"
		p="clamp(3.5px, 0.7vh, 6px) 10px"
		bgC="#F6F6F6"
	>
		<Customer
			disabled={disabled}
			tab={tab}
			form={form}
			setTabAccept={setTabAccept}
		/>
		<CodeCounterpartOrAgent
			disabled={disabled}
			tab={tab}
			form={form}
			setTabAccept={setTabAccept}
		/>
		<OrderNodes disabled={disabled} tab={tab} form={form} />
	</StyledColumn>
);

declare namespace BasePersonalInformation {
	interface Props extends OrderFormProps {
		setTabAccept: () => void;
	}
}

export const PersonalInformation = memo(BasePersonalInformation);
export default BasePersonalInformation;
