function UInt8ArrayToString(array: Uint8Array) {
	let result = "";

	array.forEach((byte) => {
		result += String.fromCharCode(byte);
	});

	return result;
}

export default UInt8ArrayToString;
