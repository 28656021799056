import { getPRPC } from "../../../hooks/usePRPC";
import { DateRange, DateRangeLiteral } from "../../../types/DataRange";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import archiveReportsByOrders, {
	PartialOrder,
} from "../../reducers/Archives/Reports/charts/byOrders";

interface Options {
	taxiServiceIds?: number[];
	dateRange: DateRange | DateRangeLiteral;
}

interface RequestParams {
	taxiServiceIds?: number[];
	dateRange: DateRange;
}

interface Response {
	items: PartialOrder[];
	pagination: {
		limit: number;
		offset: number;
		count: number;
	};
}

export default function getArchiveOrdersForChart(options: Options) {
	return async (dispatch) => {
		const prpcow = getPRPC();
		if (!prpcow) return;

		const requestParams: RequestParams = {
			taxiServiceIds: options?.taxiServiceIds || [],
			dateRange: {
				from: new Date(options.dateRange.from),
				to: new Date(options.dateRange.to),
			},
		};

		dispatch(archiveReportsByOrders.actions.setLoading(true));

		const data = (await createRPCQuery(() =>
			prpcow?.theirsModel.orders.getStatusStatistic(requestParams),
		)) as Response;

		if (data) {
			dispatch(archiveReportsByOrders.actions.setLoading(false));
			dispatch(
				archiveReportsByOrders.actions.setDateRange(options.dateRange),
			);
			dispatch(
				archiveReportsByOrders.actions.determineInterval(
					options.dateRange,
				),
			);
			dispatch(
				archiveReportsByOrders.actions.setOrders(
					data.items.map((order) => {
						const partialOrder: PartialOrder = {
							...order,
							createdAt: new Date(order.createdAt).getTime(),
						};

						return partialOrder;
					}),
				),
			);
		}
	};
}
