/* eslint-disable no-shadow */
import React, { memo } from "react";
import { Row } from "uikit";

import ControlButtons from "../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/ControlButtons";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../../constants/access";

import InternalFilters from "./components/Filters";
import Root from "./components/Root";

const Header = memo<Header.Props>(
	({ canEdit, canDelete, onAdd, onEdit, onDelete }) => (
		<Root gaps="10px*" align="center" maxedWidth>
			<Row gaps="60px*" align="center">
				<ControlButtons
					canEdit={canEdit}
					canDelete={canDelete}
					onAdd={onAdd}
					onEdit={onEdit}
					onDelete={onDelete}
					accessName={
						ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR_TARIFF_PLANS_2]
					}
				/>
			</Row>
		</Root>
	),
);

declare namespace Header {
	interface Props {
		canEdit: boolean;
		canDelete: boolean;
		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
	}

	namespace Filters {
		type Value = InternalFilters.Value;
		type Props = InternalFilters.Props;
	}
}

export default Header;
