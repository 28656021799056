import { RefObject } from "react";
import { react } from "uikit";

import Companies from "../../../../../components/Companies";
import Name from "../../../../../components/Name";
import AssetPayment from "../../../../../components/AssetPayment";
import FondyPayment from "../../../../../components/FondyPayment";
import SystemFee from "../../../../../components/SystemFee";
import SelectPaymentSystems from "../../../../../components/SelectPaymentSystems";
import { PaymentProvider } from "../../../../../../../../../services/PaymentSystem";

interface Context {
	nameRef: RefObject<Name.Ref | null>;
	companyBranchRef: RefObject<Companies.Ref | null>;
	assetPaymentRef: RefObject<AssetPayment.Ref | null>;
	fondyPaymentRef: RefObject<FondyPayment.Ref | null>;
	systemFeeRef: RefObject<SystemFee.Ref | null>;
	paymentSystemRef: RefObject<SelectPaymentSystems.Ref | null>;
	paymentProviderValue: PaymentProvider;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = !!this.context?.nameRef.current?.validate();
		const isBranchesValid =
			!!this.context?.companyBranchRef.current?.validate();

		const assetPayment = this.context?.assetPaymentRef.current?.validate();

		const fondyPayment = this.context?.fondyPaymentRef.current?.validate();

		// const systemFee = this.context?.systemFeeRef.current?.validate();

		const paymentSystem =
			this.context?.paymentSystemRef.current?.validate();

		const paymentProviderValue = this.context?.paymentProviderValue;

		const isValid = [
			isNameValid,
			isBranchesValid,
			paymentSystem,
			!!paymentProviderValue,
		];

		if (paymentProviderValue === PaymentProvider.ASSETPAYMENT) {
			isValid.push(assetPayment);
			return isValid.every((isValid) => isValid);
		}

		if (paymentProviderValue === PaymentProvider.FONDY) {
			isValid.push(fondyPayment);
			return isValid.every((isValid) => isValid);
		}

		return false;
	}
}
