import React, { Dispatch, RefAttributes, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, Stepper, react } from "uikit";

import Language from "../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import TaxiServiceForm from "../../../../../../../../../../../../components/TaxiServiceForm";
import {
	Names,
	NamesBase,
	StyledRow,
} from "../../../../../../../../../../../../components/common";
import AverageSpeed from "../../tabs/AverageSpeed";

import InternalController from "./Controller";
import FieldContainer from "./components/FieldContainer";

const Form = react.withController<Form.PropsBase, InternalController>(
	({
		value,

		language,

		controller,

		onChange,
	}) => {
		const { t } = useTranslation();
		const taxiServiceFormRef = useRef<TaxiServiceForm.Controller | null>(
			null,
		);
		const nameFormRef = useRef<NamesBase.Controller | null>(null);

		const valueEditor = useObjectEditor(value, onChange);

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");
		const taxiServiceId = valueEditor.useGetter("taxiServiceId");
		const setTaxiServiceId = valueEditor.useSetter("taxiServiceId");
		const isMaxSpeedEnabled = valueEditor.useGetter("isMaxSpeedEnabled");
		const setIsMaxSpeedEnabled = valueEditor.useSetter("isMaxSpeedEnabled");
		const maxSpeed = valueEditor.useGetter("maxSpeed");
		const setMaxSpeed = valueEditor.useSetter("maxSpeed");
		const isAverageSpeedEnabled = valueEditor.useGetter(
			"isAverageSpeedEnabled",
		);
		const setIsAverageSpeedEnabled = valueEditor.useSetter(
			"isAverageSpeedEnabled",
		);
		const isBasicAverageSpeedEnabled = valueEditor.useGetter(
			"isBasicAverageSpeedEnabled",
		);
		const setIsBasicAverageSpeedEnabled = valueEditor.useSetter(
			"isBasicAverageSpeedEnabled",
		);

		const basicAverageSpeed = valueEditor.useGetter("basicAverageSpeed");
		const setAverageSpeed = valueEditor.useSetter("averageSpeed");

		const handleIsBasicAverageSpeedEnabled = useCallback(
			(value: boolean) => {
				setIsBasicAverageSpeedEnabled(value);
				if (value) {
					const averageSpeed = [...basicAverageSpeed].map(
						(item, i) => ({
							...item,
							id: i + 1,
						}),
					);
					setAverageSpeed(averageSpeed);
				} else setAverageSpeed([]);
			},
			[basicAverageSpeed, setAverageSpeed, setIsBasicAverageSpeedEnabled],
		);

		const availableForExecutor = valueEditor.useGetter(
			"availableForExecutor",
		);
		const setAvailableForExecutor = valueEditor.useSetter(
			"availableForExecutor",
		);

		const isParking = valueEditor.useGetter("isParking");
		const setIsParking = valueEditor.useSetter("isParking");
		const isPriceZone = valueEditor.useGetter("isPriceZone");
		const setIsPriceZone = valueEditor.useSetter("isPriceZone");

		controller.setContext({
			taxiServiceFormRef,
			nameFormRef,
		});

		const speedLimitOnChange = useCallback(
			(maxSpeed: number | undefined) => {
				if (maxSpeed != null) setMaxSpeed(maxSpeed);
			},
			[setMaxSpeed],
		);

		return (
			<Column gaps="24px*">
				<FieldContainer
					title={
						t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str0",
						) ?? ""
					}
				>
					<TaxiServiceForm
						ref={taxiServiceFormRef}
						value={taxiServiceId}
						language={language}
						onChange={setTaxiServiceId}
					/>
				</FieldContainer>
				<FieldContainer
					title={
						t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str1",
						) ?? ""
					}
				>
					<Names
						ref={nameFormRef}
						placeholder={
							t(
								"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str1",
							) ?? ""
						}
						value={name}
						onChange={setName}
						require
					/>
				</FieldContainer>
				<FieldContainer
					title={
						t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str3",
						) ?? ""
					}
				>
					<StyledRow alignItems="center" gap="10px">
						<CheckBoxWithContent
							gap="18px"
							value={isMaxSpeedEnabled}
							onChange={setIsMaxSpeedEnabled}
						>
							{t(
								"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str200",
							) ?? ""}
						</CheckBoxWithContent>
						<Stepper
							disabled={!isMaxSpeedEnabled}
							value={maxSpeed}
							min={0}
							onChange={speedLimitOnChange}
						/>
					</StyledRow>
					<StyledRow alignItems="center" gap="0 1rem">
						<CheckBoxWithContent
							gap="18px"
							value={isAverageSpeedEnabled}
							onChange={setIsAverageSpeedEnabled}
						>
							{t(
								"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str150",
							) ?? ""}
						</CheckBoxWithContent>
						<CheckBoxWithContent
							gap="10px"
							disabled={!isAverageSpeedEnabled}
							value={isBasicAverageSpeedEnabled}
							onChange={handleIsBasicAverageSpeedEnabled}
						>
							{t(
								"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str151",
							) ?? ""}
						</CheckBoxWithContent>
					</StyledRow>
					<CheckBoxWithContent
						gap="18px"
						value={availableForExecutor}
						onChange={setAvailableForExecutor}
					>
						{t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str152",
						) ?? ""}
					</CheckBoxWithContent>
					<CheckBoxWithContent
						gap="18px"
						value={isParking}
						onChange={setIsParking}
					>
						{t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str153",
						) ?? ""}
					</CheckBoxWithContent>
					<CheckBoxWithContent
						gap="18px"
						value={isPriceZone}
						onChange={setIsPriceZone}
					>
						{t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.content.form.str154",
						) ?? ""}
					</CheckBoxWithContent>
				</FieldContainer>
			</Column>
		);
	},

	InternalController,
);

declare namespace Form {
	type Ref = InternalController | null;

	interface Value {
		taxiServiceId?: number;

		isAverageSpeedEnabled: boolean;
		isBasicAverageSpeedEnabled: boolean;

		averageSpeed: AverageSpeed.Value["averageSpeed"];
		basicAverageSpeed: AverageSpeed.Value["averageSpeed"];

		name?: NamesBase.Value;
		isMaxSpeedEnabled: boolean;
		maxSpeed: number;

		availableForExecutor: boolean;
		isParking: boolean;
		isPriceZone: boolean;
	}

	interface PropsBase {
		language: Language;
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	type Controller = InternalController;
}

export default Form;
