import { combineReducers } from "@reduxjs/toolkit";

import {
	reportCashlessOrdersGeneralDataState,
	reportCashlessOrdersGeneralDataReducer,
	reportCashlessOrdersGeneralDataAction,
} from "./data";

export type StateCashlessOrdersGeneral = {
	data: reportCashlessOrdersGeneralDataState;
};

export const cashlessOrdersGeneral =
	combineReducers<StateCashlessOrdersGeneral>({
		data: reportCashlessOrdersGeneralDataReducer,
	});

export const reportsCashlessOrdersGeneralAction = {
	data: reportCashlessOrdersGeneralDataAction,
};

export default cashlessOrdersGeneral;
