import React, {
	CSSProperties,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import styled, { css } from "styled-components";
import { Float, Popover, useDebounce, useInternal } from "uikit";
import { v4 as uuid } from "uuid";

import { isUndefined } from "lodash";
// eslint-disable-next-line import/no-unresolved
import StyleProps from "uikit/dist/types/StyleProps";
import Style, {
	baseStyles,
	flexStyles,
	distributeStyles,
} from "../../../../../../common/styles";

const arrow = css<Popup.Styles>`
	&::after {
		content: "";

		position: absolute;

		box-shadow: -1px -1px 0 0 #bababc;

		${({ arrow }) =>
			arrow?.shadow
				? css`
						box-shadow: ${arrow.shadow};
				  `
				: css`
						box-shadow: -1px -1px 0 0 #bababc;
				  `}

		transform: ${({ arrow }) => `rotate(${arrow?.rotate || 45}deg)`};

		width: ${({ arrow }) => arrow?.width || "0.75rem"};
		height: ${({ arrow }) => arrow?.height || "0.75rem"};
		top: ${({ arrow }) => arrow?.top || "-5%"};
		left: ${({ arrow }) => arrow?.left || "85%"};
		right: ${({ arrow }) => arrow?.right || "auto"};
		bottom: ${({ arrow }) => arrow?.bottom || "auto"};
		background: ${({ bg = "#ffffff" }) => bg};

		z-index: 2;
	}
`;

const ContentWrapper = styled.div<Popup.Styles & { useArrow: boolean }>`
	position: relative;

	display: flex;

	border: 0px solid transparent;
	border-radius: 5px;

	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);

	${flexStyles}
	${baseStyles}
	${distributeStyles}

	background: ${({ bg = "#ffffff" }) => bg};

	${({ useArrow }) => (useArrow ? arrow : "")}
`;

/**
 * ``` tsx
 * import { Float } from "uikit";
 * import { Popup } from "../components/common"
 *
 * <Float.Container id={"containerId"}>
 * 	<Popup></Popup>
 * </Float.Container>
 * ```
 */
const Popup: React.FC<Popup.Props> = ({
	children,
	tracker,

	style,
	className,

	useHoverControl = false,
	useClickControl = false,
	useArrow = false,

	open,
	styles,

	trackerId,
	containerId,

	offset,

	onChangeOpen,
}) => {
	const uniqueTrackerId = useMemo(() => uuid(), []);

	const [isButtonLocked, setIsButtonLocked] = useState(false);
	const [internalOpen, setInternalOpen] = useInternal(open ?? false, {
		when: () => isUndefined(open),
	});

	const closePopupBase = useCallback(() => {
		setInternalOpen(false);
		onChangeOpen?.(false);
	}, [onChangeOpen, setInternalOpen]);

	const closePopup = useDebounce(closePopupBase, useHoverControl ? 200 : 0);

	const trackerOnClick = useCallback(() => {
		if (!useClickControl || internalOpen || isButtonLocked) return;

		setInternalOpen(true);
		onChangeOpen?.(true);
	}, [
		useClickControl,
		internalOpen,
		isButtonLocked,
		setInternalOpen,
		onChangeOpen,
	]);

	const trackerOnMouseDown = useCallback(() => {
		if (internalOpen) setIsButtonLocked(true);
	}, [internalOpen, setIsButtonLocked]);

	const onClose = useCallback(() => {
		closePopup();
	}, [closePopup]);

	const trackerOnMouseOver = useCallback(() => {
		if (useHoverControl) {
			closePopup.cancel();

			setInternalOpen(true);
			onChangeOpen?.(true);
		}
	}, [useHoverControl, closePopup, setInternalOpen, onChangeOpen]);

	const trackerOnMouseLeave = useCallback(() => {
		if (useHoverControl) {
			closePopup();
		}
	}, [useHoverControl, closePopup]);

	const contentWrapperOnMouseOver = useCallback(() => {
		if (useHoverControl) {
			closePopup.cancel();
		}
	}, [useHoverControl, closePopup]);

	const contentWrapperOnMouseLeave = useCallback(() => {
		if (useHoverControl) {
			closePopup();
		}
	}, [useHoverControl, closePopup]);

	useEffect(() => {
		const unblockHandler = () => {
			setIsButtonLocked(false);
		};

		document.addEventListener("click", unblockHandler);
		return () => {
			document.removeEventListener("click", unblockHandler);
		};
	}, [setIsButtonLocked]);

	return (
		<>
			<Float.Tracker
				style={style}
				className={className}
				id={trackerId || uniqueTrackerId}
				onClick={trackerOnClick}
				onMouseDown={trackerOnMouseDown}
				onMouseOver={trackerOnMouseOver}
				onMouseLeave={trackerOnMouseLeave}
			>
				{tracker}
			</Float.Tracker>
			<Popover
				open={internalOpen}
				containerId={containerId || "root"}
				trackerId={trackerId || uniqueTrackerId}
				offset={offset}
				onClose={onClose}
			>
				<ContentWrapper
					useArrow={useArrow}
					onMouseOver={contentWrapperOnMouseOver}
					onMouseLeave={contentWrapperOnMouseLeave}
					{...styles}
				>
					{children}
				</ContentWrapper>
			</Popover>
		</>
	);
};

declare namespace Popup {
	interface ArrowStyle
		extends Pick<Style.BaseType, "b" | "br" | "bc">,
			Pick<
				Style.DistributeType,
				"top" | "bottom" | "left" | "shadow" | "right"
			> {
		width?: string;
		height?: string;
		rotate?: CSSProperties["rotate"];
	}

	type Styles = Style.BaseType &
		Style.FlexType &
		Style.DistributeType & { arrow?: ArrowStyle };

	interface Props extends StyleProps {
		children?: React.ReactNode;
		tracker?: React.ReactNode;

		useHoverControl?: boolean;
		useClickControl?: boolean;
		useArrow?: boolean;

		open?: boolean;

		styles?: Styles;

		containerId?: string;
		trackerId?: string;

		offset?: Float.Props["offset"];

		onChangeOpen?: React.Dispatch<boolean>;
	}
}

export const PopupMemo = memo(Popup);
export default Popup;
