import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
} from "react";
import { react, Stepper } from "uikit";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import PaymentSystem, {
	WithdrawMethod as WithdrawMethodEnum,
} from "../../../../../../services/PaymentSystem";
import {
	StyledColumn,
	StyledRow,
	StyledP,
} from "../../../../../../components/common";

import translationPath from "../../constants/translationPath";

import InternalController from "./Controller";
import WithdrawMethodElem from "./components/WithdrawMethod";
import ValueStepper from "../ValueStepper";

const WithdrawBase = react.withController<
	Withdraw.PropsBase,
	Withdraw.Controller
>(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	// withdrawMethod
	const withdrawMethod = valueEditor.useGetter("withdrawMethod");

	// defaultHoldValue
	const defaultHoldValue = valueEditor.useGetter("defaultHoldValue");
	const setDefaultHoldValue = valueEditor.useSetter("defaultHoldValue");
	const handleAssetPaymentsFeePercentage = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setDefaultHoldValue(value);
			}
		},
		[setDefaultHoldValue],
	);
	const textDefaultHoldValue = useMemo(
		() =>
			t([
				`${translationPath}.modal.withdraw.text.defaultHoldValue`,
				"Prepayment amount for unaccounted orders",
			]),
		[t],
	);

	const text = useMemo(
		() =>
			t([
				`${translationPath}.modal.withdraw.text.withdraw`,
				"Debit method",
			]),
		[t],
	);

	return (
		<StyledColumn gap="5px">
			<StyledRow
				alignItems="center"
				gap="10px"
				onClick={(event) => event.stopPropagation()}
				w="100%"
			>
				<StyledP>{text}:</StyledP>
				<WithdrawMethodElem value={value} onChange={onChange} />
			</StyledRow>
			<StyledRow alignItems="center" gap="10px" w="100%">
				<StyledP>{textDefaultHoldValue}:</StyledP>
				<ValueStepper
					width="80px"
					min={0}
					disabled={withdrawMethod === WithdrawMethodEnum.WITHDRAW}
					value={defaultHoldValue}
					onChange={handleAssetPaymentsFeePercentage}
				/>
			</StyledRow>
		</StyledColumn>
	);
}, InternalController);

const Withdraw = memo(WithdrawBase);

declare namespace Withdraw {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default Withdraw;
