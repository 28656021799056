import { string, array, boolean, number } from "yup";

const validationSchema = {
	name: string()
		.min(3, "Имя должно состоять хотя бы из 3 символов")
		.required("Имя обязательно"),
	templateText: string().required("Шаблон обязателен"),
	taxiServiceIds: array().of(number()).required("Поле обязательно"),
	taxiServices: array().required("Поле обязательно"),
	automaticSending: boolean().required("Поле обязательно"),
	autoSendOrderChanges: boolean().required("Поле обязательно"),
	disableAutoSendForPreOrder: boolean().required("Поле обязательно"),
	disableAutoSendForOnlineOrder: boolean().required("Поле обязательно"),
	isAutoSendDelay: boolean().required("Поле обязательно"),
	autoSendDelay: number().required("Поле обязательно"),
	excludeOrderChanges: boolean().required("Поле обязательно"),
	isDefault: boolean().required("Поле обязательно"),
};

// export const validationSchema1 = object({
// name: string()
// 	.min(3, "Имя должно состоять хотя бы из 3 символов")
// 	.required("Имя обязательно"),
// templateText: string().required("Шаблон обязателен"),
// taxiServiceIds: array().of(number()),
// taxiServices: array(),
// automaticSending: boolean(),
// autoSendOrderChanges: boolean(),
// disableAutoSendForPreOrder: boolean(),
// disableAutoSendForOnlineOrder: boolean(),
// isAutomaticSendingPermissionAfterOrderDate: boolean(),
// automaticSendingPermissionAfterOrderDate: number(),
// isAutoSendDelay: boolean(),
// autoSendDelay: number(),
// excludeOrderChanges: boolean(),
// isDefault: boolean(),
// });

export default validationSchema;
