import { useState, useEffect, useRef, useCallback } from "react";

const useElementSize = (): useElementSize.HookReturn => {
	const [size, setSize] = useState<useElementSize.Size>({
		width: 0,
		height: 0,
	});
	const ref = useRef<HTMLDivElement | null>(null);

	const updateSize = useCallback(() => {
		if (ref.current) {
			setSize({
				width: ref.current.offsetWidth,
				height: ref.current.offsetHeight,
			});
		}
	}, []);

	useEffect(() => {
		if (ref.current) {
			const observer = new ResizeObserver(updateSize);
			observer.observe(ref.current);
			return () => observer.disconnect();
		}
		return undefined;
	}, [updateSize]);

	useEffect(() => {
		updateSize();
	}, [updateSize]);

	return [ref, size] as const;
};

declare namespace useElementSize {
	export type Size = {
		width: number;
		height: number;
	};

	export type Ref = React.RefObject<HTMLDivElement>;

	export type HookReturn = readonly [Ref, Size];
}

export default useElementSize;
