import { react } from "uikit";
import { Dispatch } from "react";

import CompaniesAndTaxiServices from ".";

interface Context {
	value: CompaniesAndTaxiServices.Value;
	setError: Dispatch<string>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = !!this.context?.value.paymentProvider;

		console.log("[PaymentSystem] Content paymentProvider", {
			paymentProvider: this.context?.value.paymentProvider,
		});

		if (!isValid) this.context?.setError("Платёжная система не выбрана");
		this.context?.setError("");
		return isValid;
	}
}
