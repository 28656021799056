import React, { RefAttributes, memo } from "react";
import { Column, Row, react, useRefWithSetter } from "uikit";

import Tariff from "../../../../../../../../../../../../services/Tariff";
import CarClass from "../../../../../../../../../../../../services/CarClass";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import Name from "../../../../../../../../components/Name";
import CompanyBranch from "../../../../../../../../components/CompanyBranch";
import MinimumOrderValueBlock from "../../../../../../../../components/MinimumOrderValueBlock";
import HourlyService from "../../../../../../../../components/HourlyService";
import Waiting from "../../../../../../../../components/Waiting";
import SurchargesBlock from "../../../../../../../../components/SurchargesBlock";
import TabRoot from "../../components/TabRoot";

import AutoClasses from "./components/AutoClasses";
import MainSettings from "./components/MainSettings";
import InternalController from "./Controller";

const MainTabBase = react.withController<MainTab.PropsBase, MainTab.Controller>(
	({
		defaultTariffWithAllCarClassesAndOneBranch,
		value,
		onChange,
		generalSettings,
		updateGeneralSettings,
		visible,
		disabled,
		isDefault,
		carClasses,
		tariffs,
		root,
		controller,
	}) => {
		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);
		const [companyBranchRef, setCompanyBranchRef] =
			useRefWithSetter<CompanyBranch.Ref | null>(null);
		const [autoClassesRef, setAutoClassesRef] =
			useRefWithSetter<AutoClasses.Ref | null>(null);

		controller.setContext({
			nameRef,
			companyBranchRef,
			autoClassesRef,
		});

		const mainTabEditor = useObjectEditor(value, onChange);
		const updateAllMainTabValues = mainTabEditor.useAssigner();

		const name = mainTabEditor.useGetter("name");
		const setName = mainTabEditor.useSetter("name");

		const companyBranchData = mainTabEditor.usePicker([
			"taxiServiceIds",
			"taxiServices",
			"companyIds",
		]);

		const carClassIds = mainTabEditor.useGetter("carClassIds");
		const updateCarClassIds = mainTabEditor.useSetter("carClassIds");

		const taxiServiceIds = mainTabEditor.useGetter("taxiServiceIds");
		const companyIds = mainTabEditor.useGetter("companyIds");

		const generalSettingsEditor = useObjectEditor(
			generalSettings,
			updateGeneralSettings,
		);

		const mainTariffSettings = generalSettingsEditor.usePicker(
			[
				"boardingCost",
				"settlementCostPerKm",
				"suburbanCostPerKm",
				"roundTripCostPerKm",
				"settlementPickupCost",
				"suburbanPickupCost",
				"autoCalculatePickupCost",
				"pickupCostCalculation",
			],
			"json",
		);

		const minimumOrderCost = generalSettingsEditor.usePicker(
			["minOrderCost", "minPreliminaryOrderCost"],
			"json",
		);
		const hourlyService = generalSettingsEditor.usePicker(
			[
				"minHourlyRate",
				"hourlyServiceInterval",
				"hourlyServiceCost",
				"suburbanCostPerKm",
				"settlementCostPerKm",
			],
			"json",
		);
		const waiting = generalSettingsEditor.usePicker(
			["idleMinuteCost", "rideMinuteCost", "waitingMinuteCost"],
			"json",
		);
		const surcharges = generalSettingsEditor.usePicker(
			[
				"additionalPointCost",
				"preliminarySurcharge",
				"surchargeToOrderSum",
				"onlineOrderSurchargeApp",
				"onlineOrderSurchargeWebsite",
				"creditCardPaymentSurcharge",
				"invoicePaymentSurcharge",
				"nonCashPaymentSurcharge",
				"pickupCostSurcharge",
				"terminalPaymentSurcharge",
				"taximeterSurcharge",
				"executorOrderSurcharge",
				"executorOrderTaximeterSurcharge",
				"passengersSurcharge",
			],
			"json",
		);
		const newUpdateGeneralSettings = generalSettingsEditor.useAssigner();

		return (
			<TabRoot hasPaddings visible={visible}>
				<Column sizes="auto" gaps="30px*" maxedWidth maxedHeight>
					<Row sizes="40% 30% 30%" gaps="16px*">
						<Name
							ref={setNameRef}
							value={name}
							onChange={setName}
						/>
						<CompanyBranch
							isDefault={isDefault}
							ref={setCompanyBranchRef}
							value={companyBranchData}
							onChange={updateAllMainTabValues}
							disabled={disabled || root}
						/>
					</Row>
					<AutoClasses
						defaultTariffWithAllCarClassesAndOneBranch={
							defaultTariffWithAllCarClassesAndOneBranch
						}
						ref={setAutoClassesRef}
						carClasses={carClasses}
						taxiServiceIds={taxiServiceIds}
						tariffs={tariffs}
						companyIds={companyIds ?? []}
						value={carClassIds ?? []}
						onChange={updateCarClassIds}
						disabled={
							disabled || isDefault || !taxiServiceIds.length
						}
					/>
					<MainSettings
						root={root}
						value={mainTariffSettings}
						onChange={newUpdateGeneralSettings}
						disabled={disabled}
					/>
					<MinimumOrderValueBlock
						root={root}
						value={minimumOrderCost}
						onChange={newUpdateGeneralSettings}
					/>
					<HourlyService
						root={root}
						value={hourlyService}
						onChange={newUpdateGeneralSettings}
					/>
					<Waiting
						root={root}
						value={waiting}
						onChange={newUpdateGeneralSettings}
					/>
					<SurchargesBlock
						root={root}
						surcharges={surcharges}
						onChangeSurcharges={newUpdateGeneralSettings}
						disabled={disabled}
					/>
				</Column>
			</TabRoot>
		);
	},
	InternalController,
);
const MainTab = memo(MainTabBase);

declare namespace MainTab {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		defaultTariffWithAllCarClassesAndOneBranch?: Tariff.Model;
		value: Value;
		onChange: (x: Partial<Value>) => void;
		generalSettings: GeneralSettings;
		updateGeneralSettings: (x: GeneralSettings) => void;
		visible: boolean;
		disabled?: boolean;
		isDefault: boolean;
		carClasses: CarClass.Model[];
		root: boolean;
		tariffs: Tariff.Model[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends CompanyBranch.Value {
		carClassIds?: number[];
		companyIds: number[];
		name: Name.Value;
	}
	interface GeneralSettings
		extends MainSettings.Value,
			MinimumOrderValueBlock.Value,
			Waiting.Value,
			SurchargesBlock.Surcharges,
			HourlyService.Value {}
}

export default MainTab;
