import Nullable from "../../types/Nullable";
import TableSortMethod from "../../types/SortMethod";
import TableWidths from "../../types/TableWidths";
import TableBoolean from "../../types/TableBoolean";
import TableFixed from "../../types/TableFixed";

import { TableData } from "./types";

interface ColumnParams {
	id: string;
	label: string;
	sortable?: boolean;
	resizable?: boolean;
	fixed?: boolean;
	width?: number;
}

export default function makeTable<
	TColumnId extends string,
	SortMethod extends TableSortMethod<TColumnId> | undefined = undefined,
	Widths extends TableWidths<TColumnId> | undefined = undefined,
	Sortable extends TableBoolean<TColumnId> | undefined = undefined,
	Resizable extends TableBoolean<TColumnId> | undefined = undefined,
	Fixed extends TableFixed<TColumnId> | undefined = undefined,
>({
	allIds,
	defaultIds,
	i18tPath,
	initialSortMethod,
	initialWidths,
	initialSortable,
	initialResizable,
	initialFixed,
}: {
	allIds: TColumnId[];
	defaultIds?: Nullable<TColumnId[]>;

	i18tPath: (columnId: TColumnId) => string;

	initialWidths?: Widths;
	initialSortMethod?: SortMethod;
	initialSortable?: Sortable;
	initialResizable?: Resizable;
	initialFixed?: Fixed;
}): TableData<TColumnId, SortMethod, Widths, Sortable, Resizable, Fixed> {
	return {
		allIds,
		defaultIds: defaultIds || allIds,

		columns: allIds.map((id) => {
			const payload: ColumnParams = {
				id,
				label: i18tPath(id),
			};

			if (initialSortable) {
				payload.sortable = Boolean(initialSortable[id]);
			}

			if (initialResizable) {
				payload.resizable = Boolean(initialResizable[id]);
			}

			if (initialFixed) {
				payload.fixed = Boolean(initialFixed[id]);
			}

			if (initialWidths) {
				payload.width = Number(initialWidths[id]);
			}

			return payload;
		}),

		initialSortMethod,
		initialWidths,
		initialSortable,
		initialResizable,
		initialFixed,
	};
}
