/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import Nullable from "../../../types/Nullable";
import TableSortMethod from "../../../types/SortMethod";
import TableWidths from "../../../types/TableWidths";

export interface TableColumn<ID extends string = string> {
	id: ID;
	label: string;
	sortable?: boolean;
	resizable?: boolean;
	fixed?: boolean;
	width?: number;
}

type RecordOfTables<Data> = Record<string, Nullable<Data>>;

interface TableColumnsState {
	visible: RecordOfTables<string[]>;
	sortMethod: RecordOfTables<TableSortMethod>;
	widths: RecordOfTables<TableWidths>;
}

const initialState: TableColumnsState = {
	visible: {},
	sortMethod: {},
	widths: {},
};

function setVisibleColumns(
	state: TableColumnsState,
	action: {
		payload: {
			uid: string;
			columns: string[];
		};
		type: string;
	},
) {
	state.visible[action.payload.uid] = action.payload.columns;
}

function setSortMethod(
	state: TableColumnsState,
	action: {
		payload: {
			uid: string;
			method: TableSortMethod;
		};
		type: string;
	},
) {
	state.sortMethod[action.payload.uid] = {
		...state.sortMethod[action.payload.uid],
		...action.payload.method,
	};
}

function setWidths(
	state: TableColumnsState,
	action: {
		payload: {
			uid: string;
			widths: TableWidths;
		};
		type: string;
	},
) {
	state.widths[action.payload.uid] = action.payload.widths;
}

function setAll(
	state: TableColumnsState,
	action: {
		payload: TableColumnsState;
		type: string;
	},
) {
	state.sortMethod = action.payload.sortMethod;
	state.widths = action.payload.widths;
	state.visible = action.payload.visible;
}

// Slice //

const tableColumns = createSlice({
	name: "tableColumns",
	initialState,
	reducers: {
		setVisibleColumns,
		setSortMethod,
		setWidths,
		setAll,
	},
});

export default tableColumns;
