import React, { Dispatch } from "react";
import { Key } from "uikit";

import Root from "./components/Root";
import Item from "./components/Item";

const PopupContent: React.FC<PopupContent.Props> = ({ value, options, onChange }) => (
	<Root gaps="1px*">
		{options.map((option, index) => (
			<Item
				key={option.key}
				selected={value === option.key}
				color={option.color}
				name={option.name}
				index={index}
				onClick={() => onChange(option.key)}
			/>
		))}
	</Root>
);

declare namespace PopupContent {
	type Value = Key | undefined;

	interface Option {
		key: Key;
		color: string;
		name: string;
	}

	interface Props {
		value: Value;

		options: Option[];

		onChange: Dispatch<Value>;
	}
}

export default PopupContent;
