/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateRuleSectorItemCounterType {
	EACH = "each",
	OVERALL = "overall",
}
export const counterTypeOptions = getOptions(
	Object.values(AutoRateRuleSectorItemCounterType),
);

export enum AutoRateRuleSectorsDistributionType {
	ALL = "all",
	PICKUP_POINT = "pickup_point",
	PICKUP_POINT_WITH_REACHED_COUNT = "pickup_point_with_reached_count",
}
export const distributionTypeOptions = getOptions(
	Object.values(AutoRateRuleSectorsDistributionType),
);
