import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

function useDebouncedValue<V>(value: V, timeMS: number, initValue: V = value) {
	const [debouncedValue, setDebouncedValue] = useState(initValue);

	// const debounce = useDebounce((value: V) => {
	// 	setDebouncedValue(value);
	// }, timeMS);

	const debounce = useMemo(() => _.debounce(setDebouncedValue, 500), []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => debounce.cancel(), []);

	useEffect(() => {
		debounce(value);
	}, [debounce, value]);

	return debouncedValue;
}

export default useDebouncedValue;
