import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { clone } from "lodash";
import { theme } from "uikit";

import { StyledRow } from "../common";
import ColumnSetupModal from "../ColumnSetupModal";

import { Button } from "./components";

const TableSettings: React.FC<TableSettings.Props> = ({
	value,
	defaultValue,
	columns,
	disabledColumnsIds = [],
	onChange,
}) => {
	const { t } = useTranslation();
	const [showColumnModal, setShowColumnModal] = useState(false);

	const resetButtonOnClick = useCallback(
		() => defaultValue && onChange?.(clone(defaultValue)),
		[defaultValue, onChange],
	);
	const editButtonOnClick = useCallback(() => setShowColumnModal(true), []);

	const columnSetupModalOnClose = useCallback(
		() => setShowColumnModal(false),
		[],
	);

	const columnSetupModalOnSubmit = useCallback(
		(columnSetupModalValue: ColumnSetupModal.Value) => {
			onChange?.(columnSetupModalValue);
			setShowColumnModal(false);
		},
		[onChange],
	);

	return (
		<>
			<StyledRow
				alignItems="center"
				gap="16px"
				p="7px 16px"
				bgC={theme.colors.button_secondary}
			>
				<Button
					icon="reset"
					label={t("tableSettings.str0") ?? ""}
					onClick={resetButtonOnClick}
				/>
				<Button
					icon="table-edit"
					label={t("tableSettings.str1") ?? ""}
					onClick={editButtonOnClick}
				/>
			</StyledRow>

			{showColumnModal && (
				<ColumnSetupModal
					columns={columns}
					disabledColumns={disabledColumnsIds}
					value={value}
					onClose={columnSetupModalOnClose}
					onSubmit={columnSetupModalOnSubmit}
				/>
			)}
		</>
	);
};

declare namespace TableSettings {
	type Column = ColumnSetupModal.Column;
	type Value = ColumnSetupModal.Value;

	interface Props {
		value?: Value;

		defaultValue?: Value;
		columns?: Column[];
		disabledColumnsIds?: Column["id"][];

		onChange?: any;
	}
}

export default TableSettings;
