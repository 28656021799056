import React, { memo, useMemo } from "react";

import {
	StyledGridItem,
	StyledGrid,
	Divider,
	StyledRow,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";
import { TaxiClassMemo } from "../TaxiClass";
import { ServicesMemo } from "../Services";
import { ScheduledTimeMemo } from "../ScheduledTime";
import { GridItemPaymentMemo } from "../GridItemPayment";
import { GridItemTariffMemo } from "../GridItemTariff";
import { GridItemPassengersMemo } from "../GritItemPassenger";

const OrderInformation: React.FC<OrderInformation.Props> = ({
	tab,
	form,
	disabled,
	width,
}) => {
	const styles = useMemo(
		() => ({
			wrap: {
				gap: "5px 8px",
				p: "4px 6px 5px 10px",
				justify: "start",
				bgC: "#F6F6F6",
				w: width || "calc(100% - 38px)",
				m: "0 0 0 auto",
			},
			first: {
				areas: `'time divider1 payment divider2 tariff divider3 passenger'`,
				columns: "repeat(8, auto)",
				rows: "28px",
				gap: "0 5px",
				alignItems: "center",
				justify: "start",
				bgC: "#F6F6F6",
			},
		}),
		[width],
	);

	return (
		<StyledRow
			{...styles.wrap}
			b={{
				top: "1px solid #dee0e2",
				left: "1px solid #dee0e2",
			}}
			// flex={{ wrap: "wrap" }}
		>
			<StyledGridItem area="first">
				<StyledGrid {...styles.first}>
					<StyledGridItem area="time" alignSelf="center">
						<ScheduledTimeMemo
							form={form}
							tab={tab}
							disabled={disabled}
						/>
					</StyledGridItem>

					<StyledGridItem area="divider1" alignSelf="center">
						<Divider h="18px" />
					</StyledGridItem>

					<GridItemPaymentMemo
						area="payment"
						form={form}
						tab={tab}
						disabled={disabled}
					/>

					<StyledGridItem area="divider2" alignSelf="center">
						<Divider h="18px" />
					</StyledGridItem>

					<GridItemTariffMemo
						area="tariff"
						form={form}
						tab={tab}
						disabled={disabled}
					/>

					<StyledGridItem area="divider3" alignSelf="center">
						<Divider h="18px" />
					</StyledGridItem>

					<GridItemPassengersMemo
						area="passenger"
						form={form}
						tab={tab}
						disabled={disabled}
					/>
				</StyledGrid>
			</StyledGridItem>

			<TaxiClassMemo form={form} tab={tab} disabled={disabled} />
			<ServicesMemo form={form} tab={tab} disabled={disabled} />
		</StyledRow>
	);
};

declare namespace OrderInformation {
	interface Props extends OrderFormProps {
		width?: string;
	}
}

export const OrderInformationMemo = memo(OrderInformation);
export default OrderInformation;
