/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CheckBox, Column, Row, theme } from "uikit";
import BankTransaction from "../../../../../../../../../../../../../../services/BankTransaction";
import useCarBrandSubscription from "../../../../../../../../../../../../../../hooks/useCarBrandSubscription";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import { ModalFilter } from "../../../../../../../..";

interface Props {
	statuses: BankTransaction.PaymentTransactionStatus[];
	setStatuses: React.Dispatch<
		React.SetStateAction<BankTransaction.PaymentTransactionStatus[]>
	>;
	types: BankTransaction.BankTransactionType[];
	setTypes: React.Dispatch<
		React.SetStateAction<BankTransaction.BankTransactionType[]>
	>;
	onChangeRequestData: React.Dispatch<React.SetStateAction<ModalFilter>>;
}

const CheckboxText = styled("span")`
	font-size: 14px;
	color: ${theme.colors.text_placeholder};
	cursor: default;
	line-height: 20px;
`;

const Content: React.FC<Props> = ({
	statuses,
	setStatuses,
	types,
	setTypes,
	onChangeRequestData,
}) => {
	const { t } = useTranslation();
	const onChageStatusesCheckbox = useCallback(
		(value: boolean, status: BankTransaction.PaymentTransactionStatus) => {
			if (value && !statuses?.includes(status)) {
				setStatuses([...statuses, status]);
			} else if (!value && statuses?.includes(status)) {
				setStatuses([...statuses.filter((el) => el !== status)]);
			}
		},
		[setStatuses, statuses],
	);

	const isPending = useMemo(() => statuses?.includes("pending"), [statuses]);
	const isCompleted = useMemo(
		() => statuses?.includes("completed"),
		[statuses],
	);
	const isCancelled = useMemo(
		() => statuses?.includes("cancelled"),
		[statuses],
	);

	const onChangePending = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "pending");
		},
		[onChageStatusesCheckbox],
	);

	const onChangeCompleted = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "completed");
		},
		[onChageStatusesCheckbox],
	);

	const onChangeCancelled = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "cancelled");
		},
		[onChageStatusesCheckbox],
	);

	const onChageTypeCheckbox = useCallback(
		(value: boolean, type: BankTransaction.BankTransactionType) => {
			if (value && !types?.includes(type)) {
				setTypes([...types, type]);
			} else if (!value && types?.includes(type)) {
				setTypes([...types.filter((el) => el !== type)]);
			}
		},
		[setTypes, types],
	);

	const isOrderPayment = useMemo(
		() => types?.includes("order_payment"),
		[types],
	);
	const isReplenishmentExecutorCard = useMemo(
		() => types?.includes("replenishment_executor_card"),
		[types],
	);

	const onChangeOrderPayment = useCallback(
		(value) => {
			onChageTypeCheckbox(value, "order_payment");
		},
		[onChageTypeCheckbox],
	);

	const onChangeReplenishmentExecutorCard = useCallback(
		(value) => {
			onChageTypeCheckbox(value, "replenishment_executor_card");
		},
		[onChageTypeCheckbox],
	);

	useEffect(() => {
		onChangeRequestData({
			statuses,
			types,
		});
	}, [onChangeRequestData, statuses, types]);

	return (
		<Row gaps="12px*">
			<Column maxedWidth>
				<FieldsContainer
					label={
						t(
							"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str0",
						) ?? ""
					}
					labelColor={theme.colors.text_placeholder}
					style={{ height: "100%" }}
				>
					<Column gaps="12px*" maxedHeight>
						<Row gaps="10px">
							<CheckBox
								value={isOrderPayment}
								onChange={onChangeOrderPayment}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str100",
								) ?? ""}
							</CheckboxText>
						</Row>
						<Row gaps="10px">
							<CheckBox
								value={isReplenishmentExecutorCard}
								onChange={onChangeReplenishmentExecutorCard}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str150",
								) ?? ""}
							</CheckboxText>
						</Row>
					</Column>
				</FieldsContainer>
			</Column>
			<Column maxedWidth>
				<FieldsContainer
					label={
						t(
							"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str1",
						) ?? ""
					}
					labelColor={theme.colors.text_placeholder}
					style={{ height: "100%" }}
				>
					<Column gaps="12px*" maxedHeight>
						<Row gaps="10px">
							<CheckBox
								value={isCompleted}
								onChange={onChangeCompleted}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str101",
								) ?? ""}
							</CheckboxText>
						</Row>
						<Row gaps="10px">
							<CheckBox
								value={isCancelled}
								onChange={onChangeCancelled}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str102",
								) ?? ""}
							</CheckboxText>
						</Row>
						<Row gaps="10px">
							<CheckBox
								value={isPending}
								onChange={onChangePending}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.bankTransactions.header.filter.popover.content.str103",
								) ?? ""}
							</CheckboxText>
						</Row>
					</Column>
				</FieldsContainer>
			</Column>
		</Row>
	);
};

declare namespace Content {
	interface Value extends Pick<useCarBrandSubscription.Filter, "activity"> {}

	export interface PropsBase {}
}

export default Content;
