import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import DayInJournal from "./components/DayInJournal";
import Call from "../../../../../../services/Call";
import { useTypedSelector } from "../../../../../../redux/store";

const Journal = styled("div")<{ reduce?: boolean }>`
	overflow-y: scroll;
	height: ${({ reduce }) => (reduce ? "auto" : "278px")};
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	div:last-child {
		border-bottom: none;
	}
`;

interface Props {
	data: Call.Model[];
	isReduce: boolean;
}

const CallsJournal: React.FC<Props> = ({ data, isReduce }) => {
	const timezoneName = useTypedSelector(
		(state) => state.applicationConfig.timezone.name,
	);

	const dataArrangedByDays = useMemo<Call.Model[][]>(() => {
		const groupedByDay = {};

		data.forEach((event) => {
			const day = moment(event.createdAt)
				.tz(timezoneName)
				.format("YYYY-MM-DD");

			if (!groupedByDay[day]) {
				groupedByDay[day] = [];
			}

			groupedByDay[day].push(event);
		});

		const result = Object.values(groupedByDay).map((dayArray: any) =>
			dayArray.sort((a, b) => {
				const dateA = moment(a.createdAt).tz(timezoneName).valueOf();
				const dateB = moment(b.createdAt).tz(timezoneName).valueOf();
				return dateB - dateA;
			}),
		);

		return result;
	}, [data, timezoneName]);

	return (
		<Journal reduce={isReduce}>
			<div style={{ height: 48, backgroundColor: "transparent" }}></div>
			{dataArrangedByDays.map((latestCall, i) => (
				<DayInJournal key={i} data={latestCall} />
			))}
		</Journal>
	);
};
export default CallsJournal;
