import React, { Dispatch, useCallback } from "react";
import { Icon } from "uikit";
import Root from "./components/Root";

const FavoriteButton: React.FC<FavoriteButton.Props> = ({
	value,

	disabled = false,

	onChange,
}) => {
	const rootOnClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (disabled) return;

			onChange?.(!value);
		},
		[disabled, onChange, value],
	);

	return (
		<Root align="center" justify="center" onClick={rootOnClick}>
			<Icon
				id="star"
				size={16}
				colors={[value ? "#76CAA0" : "#C5C5C5"]}
			/>
		</Root>
	);
};

declare namespace FavoriteButton {
	type Value = boolean;

	interface Props {
		value: Value;

		disabled?: boolean;

		onChange?: Dispatch<Value>;
	}
}

export default FavoriteButton;
