/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { react } from "uikit";
import { Dispatch } from "react";
import MainTab from ".";

interface Context {
	value: MainTab.Value;

	setCarBaseTypeNameError: Dispatch<boolean>;
	setCarBrandNameError: Dispatch<boolean>;
	setCarModelNameError: Dispatch<boolean>;
	setCarBodyTypeNameError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		if (!this.context) return false;

		const isBodyTypeValid = this.context.value.carBodyTypeName.length > 0;

		if (!isBodyTypeValid) {
			this.context.setCarBodyTypeNameError(true);
		}

		const isModelValid = this.context.value.carModelName.length > 0;

		if (!isModelValid) {
			this.context.setCarModelNameError(true);
		}

		const isBrandValid = this.context.value.carBrandName.length > 0;

		if (!isBrandValid) {
			this.context.setCarBrandNameError(true);
		}

		const isBaseTypeValid = this.context.value.carBaseTypeName.length > 0;

		if (!isBaseTypeValid) {
			this.context.setCarBaseTypeNameError(true);
		}

		return (
			isBodyTypeValid && isModelValid && isBrandValid && isBaseTypeValid
		);
	}
}
