import { useMemo, useState } from "react";
import { Nullable } from "uikit";
import { isNumber } from "lodash";

import CarClass from "../../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import ModelList from "../components/ModelList";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useCarClassModelList(options: useCarClassModelList.Options) {
	const [offset, setOffset] = useState(0);
	const [chunkSize, setChunkSize] = useState(30);
	const lang = useTypedSelector((state) => state.session.language);

	const subscribeOptions = useMemo<CarClass.SubscribeOptions>(
		() => ({
			offset,
			limit: chunkSize,

			carModelIds: isNumber(options.selectedCarModelId)
				? [options.selectedCarModelId]
				: [],
			carBodyTypeIds: isNumber(options.selectedCarBodyTypeId)
				? [options.selectedCarBodyTypeId]
				: [],

			query: options.search,
		}),
		[
			offset,
			chunkSize,
			options.selectedCarModelId,
			options.selectedCarBodyTypeId,
			options.search,
		],
	);

	const data = useModelSubscribe(subscribeOptions, CarClass);

	const models = useMemo(() => data.models ?? [], [data.models]);

	const total = useMemo(
		() => (data.metadataState.total ?? 0) as number,
		[data.metadataState.total],
	);

	const items = useMemo<ModelList.Item[]>(
		() =>
			models.map((model) => ({
				id: model.id,

				label: model.name?.[lang],

				deletable: true,
			})),
		[models, lang],
	);

	return {
		offset,
		setOffset,

		chunkSize,
		setChunkSize,

		data,
		models,
		total,
		items,
	};
}

declare namespace useCarClassModelList {
	interface Options {
		search?: string;

		selectedCarModelId: Nullable<number>;
		selectedCarBodyTypeId: Nullable<number>;
	}
}

export default useCarClassModelList;
