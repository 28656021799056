import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, Stepper } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import Label from "../../../../../../../../../../../../../../components/Label";

const BonusTripMode: React.FC<BonusTripMode.Props> = ({
	value,

	disabled,

	onChange,
}) => {
	const { t } = useTranslation();
	const defaultValue = useMemo<BonusTripMode.Value>(
		() => ({
			frequency: 0,
		}),
		[],
	);

	const valueEditor = useObjectEditor(value ?? defaultValue, onChange);

	const frequencyValue = valueEditor.useGetter("frequency");
	const frequencyOnChange = valueEditor.useSetter("frequency");

	return (
		<FieldsContainer
			label={
				t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.bonusTripMode.str0",
				) ?? ""
			}
		>
			<Column gaps="8px*">
				<Row
					align="center"
					gaps="10px*"
					sizes="auto!*"
					style={{ height: 32 }}
					maxedWidth
				>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.bonusTripMode.str100",
						) ?? ""}
					</Label>
					<Stepper
						value={frequencyValue}
						min={0}
						disabled={disabled}
						onChange={(value) => frequencyOnChange(value ?? 0)}
					/>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.bonusTripMode.str101",
						) ?? ""}
					</Label>
				</Row>
			</Column>
		</FieldsContainer>
	);
};

declare namespace BonusTripMode {
	interface Value {
		frequency: number;
	}

	interface Props {
		value?: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default BonusTripMode;
