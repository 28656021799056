import makeTable from "./makeTable";

const accountingExecutorsFirstTable = makeTable({
	allIds: [
		"lastName",
		"firstName",
		"fatherName",
		"car",
		"balance",
		"shutdownThreshold",
		"dataLatestRefill",
		"sumLatestRefill",
		"company",
		"taxiService",
	],
	i18tPath: (id) => `mainPage.accounting.executors2.columns.${id}`,
	defaultIds: [
		"lastName",
		"firstName",
		"fatherName",
		"car",
		"balance",
		"shutdownThreshold",
		"dataLatestRefill",
		"sumLatestRefill",
		"company",
		"taxiService",
	],
	initialSortMethod: {
		column: "lastName",
		type: "asc",
	},
	initialWidths: {
		lastName: 150,
		firstName: 150,
		fatherName: 150,
		car: 150,
		balance: 150,
		shutdownThreshold: 150,
		dataLatestRefill: 150,
		sumLatestRefill: 150,
		company: 150,
		taxiService: 150,
	},
	initialResizable: {
		lastName: true,
		firstName: true,
		fatherName: true,
		car: true,
		balance: true,
		shutdownThreshold: true,
		dataLatestRefill: true,
		sumLatestRefill: true,
		company: true,
		taxiService: true,
	},
	initialSortable: {
		lastName: true,
		firstName: true,
		fatherName: true,
		car: true,
		balance: true,
		shutdownThreshold: true,
		dataLatestRefill: true,
		sumLatestRefill: true,
		company: true,
		taxiService: true,
	},
});

export default accountingExecutorsFirstTable;
