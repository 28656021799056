/* eslint-disable no-shadow */

import styled from "styled-components";
import { Map as MapBase } from "uikit";

const Map = styled(MapBase)`
	.disabled-polygon {
		cursor: not-allowed;
	}

	.leaflet-tooltip {
		display: flex;

		flex-direction: column;
		flex-wrap: nowrap;

		align-items: center;
		justify-content: center;
	}
`;

declare namespace Map {
	type Props = MapBase.Props;

	namespace PolygonEditor {
		type Value = MapBase.PolygonEditor.Value;
		type Props = MapBase.PolygonEditor.Props;
		type Controller = MapBase.PolygonEditor.Controller;
	}
}

export default Map;
