import { RefObject } from "react";
import { react } from "uikit";

import TaxiServiceForm from "../../../../../../../../../../../../components/TaxiServiceForm";
import { NamesBase } from "../../../../../../../../../../../../components/common";

// import NameForm from "./components/NameForm";

interface Context {
	taxiServiceFormRef: RefObject<TaxiServiceForm.Controller>;
	nameFormRef: RefObject<NamesBase.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameFormValid = this.context?.nameFormRef.current?.validate();
		const isTaxiServiceFormValid =
			this.context?.taxiServiceFormRef.current?.validate();

		return isTaxiServiceFormValid && isNameFormValid;
	}
}
