import styled from "styled-components";
import BaseButton from "../../../Button";
import { softphoneConnectionColors } from "../../../../../../../../../../utils/jsSip";

const Button = styled(BaseButton)<Button.Props>`
	background-color: ${({ status }) =>
		softphoneConnectionColors[status].backgroundColor};

	cursor: ${({ status }) =>
		status === "success" ? "pointer" : "not-allowed"};
`;

declare namespace Button {
	interface Props {
		status: "success" | "failure" | "inactive";
	}
}

export default Button;
