import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, Row, Stepper } from "uikit";
import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import Label from "../../../../../../../../../../../../../../components/Label";
import UnitSelect from "../UnitSelect";

const FixedMode: React.FC<FixedMode.Props> = ({
	value,
	disabled,
	onChange,
}) => {
	const { t } = useTranslation();
	const defaultValue = useMemo(
		() => ({
			discount: {
				unit: CustomerRate.Model.Unit.Percentage,
				value: 0,
			},
			onlineDiscount: {
				active: false,
				unit: CustomerRate.Model.Unit.Percentage,
				value: 0,
			},
		}),
		[],
	);

	const valueEditor = useObjectEditor(value ?? defaultValue, onChange);

	const discountEditor = valueEditor.usePropertyEditor("discount");

	const discountValueValue = discountEditor.useGetter("value");
	const discountValueOnChange = discountEditor.useSetter("value");

	const discountUnitValue = discountEditor.useGetter("unit");
	const discountUnitOnChange = discountEditor.useSetter("unit");

	const onlineDiscountEditor =
		valueEditor.usePropertyEditor("onlineDiscount");

	const onlineDiscountActiveValue = onlineDiscountEditor.useGetter("active");
	const onlineDiscountActiveOnChange =
		onlineDiscountEditor.useSetter("active");

	const onlineDiscountValueValue = onlineDiscountEditor.useGetter("value");
	const onlineDiscountValueOnChange = onlineDiscountEditor.useSetter("value");

	const onlineDiscountUnitValue = onlineDiscountEditor.useGetter("unit");
	const onlineDiscountUnitOnChange = onlineDiscountEditor.useSetter("unit");

	return (
		<FieldsContainer
			label={
				t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.fixedMode.str0",
				) ?? ""
			}
		>
			<Column gaps="8px*">
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<Label>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.fixedMode.str100",
						) ?? ""}
					</Label>
					<Stepper
						disabled={disabled}
						min={0}
						value={discountValueValue}
						onChange={(value) => discountValueOnChange(value ?? 0)}
					/>
					<UnitSelect
						value={discountUnitValue}
						disabled={disabled}
						onChange={discountUnitOnChange}
					/>
				</Row>
				<Row
					align="center"
					gaps="10px*"
					sizes="1fr auto! 60px"
					style={{ height: 32 }}
					maxedWidth
				>
					<CheckBoxWithContent
						value={onlineDiscountActiveValue}
						disabled={disabled}
						onChange={onlineDiscountActiveOnChange}
					>
						{t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.fixedMode.str150",
						) ?? ""}
					</CheckBoxWithContent>
					<Stepper
						value={onlineDiscountValueValue}
						min={0}
						disabled={disabled || !onlineDiscountActiveValue}
						onChange={(value) =>
							onlineDiscountValueOnChange(value ?? 0)
						}
					/>
					<UnitSelect
						value={onlineDiscountUnitValue}
						disabled={disabled || !onlineDiscountActiveValue}
						onChange={onlineDiscountUnitOnChange}
					/>
				</Row>
			</Column>
		</FieldsContainer>
	);
};

declare namespace FixedMode {
	interface Value {
		discount: {
			unit: UnitSelect.Value;
			value: number;
		};

		onlineDiscount: {
			active: boolean;
			unit: UnitSelect.Value;
			value: number;
		};
	}

	interface Props {
		value?: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default FixedMode;
