import React, { useCallback } from "react";
import { Column, Icon, Row, theme } from "uikit";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Button from "./components/Button";
import { useTypedSelector } from "../../../../../../../../redux/store";
import softphoneWidget from "../../../../../../../../redux/reducers/softphoneWidget";

const KeyboardStyled = styled(Column)`
	padding: 15px;

	width: 100%;
	height: 180px;
`;

const Keyboard = () => {
	const dispatch = useDispatch();
	const { numberQuery } = useTypedSelector((state) => state.softphoneWidget);

	const onPressButton = useCallback(
		(value) => {
			dispatch(
				softphoneWidget.actions.setNumberQuery(numberQuery + value),
			);
		},
		[dispatch, numberQuery],
	);
	return (
		<KeyboardStyled gaps="4px*">
			<Row gaps="4px*">
				<Button text="1" value="1" onPressButton={onPressButton} />
				<Button text="2" value="2" onPressButton={onPressButton} />
				<Button text="3" value="3" onPressButton={onPressButton} />
			</Row>
			<Row gaps="4px*">
				<Button text="4" value="4" onPressButton={onPressButton} />
				<Button text="5" value="5" onPressButton={onPressButton} />
				<Button text="6" value="6" onPressButton={onPressButton} />
			</Row>
			<Row gaps="4px*">
				<Button text="7" value="7" onPressButton={onPressButton} />
				<Button text="8" value="8" onPressButton={onPressButton} />
				<Button text="9" value="9" onPressButton={onPressButton} />
			</Row>
			<Row gaps="4px*">
				<Button
					value="*"
					onPressButton={onPressButton}
					text={
						<Icon
							id="phone-star"
							size={24}
							colors={[theme.colors.secondary]}
						/>
					}
				/>
				<Button text="0" value="0" onPressButton={onPressButton} />
				<Button
					value="#"
					onPressButton={onPressButton}
					text={
						<Icon
							id="phone-hash"
							size={24}
							colors={[theme.colors.secondary]}
						/>
					}
				/>
			</Row>
		</KeyboardStyled>
	);
};

export default Keyboard;
