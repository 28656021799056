const removeOldMissedCallsStorage = () => {
	const oldMissedCalls = localStorage.getItem("missedCalls");
	const oldMissedCallsPhone = localStorage.getItem("missedCallsPhones");
	if (
		oldMissedCalls &&
		JSON.parse(oldMissedCalls) &&
		oldMissedCallsPhone &&
		JSON.parse(oldMissedCallsPhone)
	) {
		localStorage.removeItem("missedCalls");
		localStorage.removeItem("missedCallsPhones");
	}
};

export default removeOldMissedCallsStorage;
