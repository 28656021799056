import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	position: relative;

	width: 100%;

	padding: 6px 12px;

	background: white;

	color: ${theme.colors.primary};

	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;

	user-select: none;

	&:hover {
		&::before {
			content: "";

			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.05);
		}
	}

	&:active {
		&::before {
			content: "";

			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background: rgba(0, 0, 0, 0.1);
		}
	}
`;

export default Root;
