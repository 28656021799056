/* eslint-disable no-param-reassign */

import { defaults } from "lodash";

function getArrayChanges<T>(
	previous: T[],
	actual: T[],
	options: getArrayChanges.Options = getArrayChanges.defaultOptions,
) {
	options = defaults(options, getArrayChanges.defaultOptions);

	if (options.useChanged) {
		const changedItemIndexes = actual
			.slice(0, Math.min(actual.length, previous.length))
			.map((_, index) => index)
			.filter((index) => previous[index] !== actual[index]);
		const addedItems = actual.slice(previous.length);
		const removedItems = previous.slice(actual.length);

		return { changedItemIndexes, addedItems, removedItems };
	}

	const changedItemIndexes = [];
	const addedItems = actual.filter((item) => !previous.includes(item));
	const removedItems = previous.filter((item) => !actual.includes(item));

	return { changedItemIndexes, addedItems, removedItems };
}

namespace getArrayChanges {
	export const defaultOptions: Options = {
		useChanged: true,
	};

	export interface Options {
		useChanged?: boolean;
	}
}

export default getArrayChanges;
