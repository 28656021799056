/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import Dispatcher from "../Dispatcher";
import Client from "../Client";
import Company from "../Company";

class PhoneBlacklist extends Base {
	static fromResponse(data: any): PhoneBlacklist.Model {
		return {
			id: data.id,

			dispatcher: data.dispatcher
				? Dispatcher.fromResponse(data.dispatcher)
				: undefined,
			client: data.customer
				? Client.fromResponse(data.customer)
				: undefined,

			companies: data.blacklistPhoneToCompanies?.map(
				(blacklistPhoneToCompany) =>
					Company.fromResponse(blacklistPhoneToCompany.company),
			),

			number: data.number,
			phone: data.phone,
			description: data.description,
			firstName: data.name,
			lastName: data.lastName,
			fatherName: data.fatherName,

			level: data.level,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model: PhoneBlacklist.Model.New | PhoneBlacklist.Model.Modified,
	): any {
		return {
			customerId: model.clientId,
			companyIds: model.companyIds,

			phone: model.phone,
			description: model.description,
			name: model.firstName,
			surname: model.lastName,
			fatherName: model.fatherName,

			level: model.level,
		};
	}

	public static async store(object: PhoneBlacklist.Model.New) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.blacklistPhone.create(
				PhoneBlacklist.toRequest(object),
			),
		);
		return data;
	}

	public static async update(object: PhoneBlacklist.Model.Modified) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.blacklistPhone.update(
				object.id,
				PhoneBlacklist.toRequest(object),
			),
		);
		return data;
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: PhoneBlacklist.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<PhoneBlacklist.Model>,
	): Promise<Subscription<PhoneBlacklist.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const params = this.optionsToRequest(options);
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.blacklistPhone.subscribe({
					params,
					ping: () => true,
					onEvent: (event) => {
						// console.log(event);

						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{
				name: "PhoneBlacklist.subscribe",
				metadata: params,
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: PhoneBlacklist.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<PhoneBlacklist.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.blacklistPhone.delete(id));
	}

	private static optionsToRequest(options: PhoneBlacklist.SubscribeOptions) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			customerId: options.customerId,
			dispatcherId: options.dispatcherId,
			companyIds: options.companyIds,

			dateRange: options.dateRange,

			order: options.order,
		};
	}
}

namespace PhoneBlacklist {
	export enum Level {
		ACTIVE = "active",
		BAN_CALLS = "phone",
		BLOCKED = "blocked",
		WARNING = "warning",
	}

	export interface Model {
		id: number;

		dispatcher?: Dispatcher.Model;
		client?: Client.Model;

		companies?: Company.Model[];

		number: number;
		phone: string;
		description: string;
		firstName: string;
		lastName: string;
		fatherName: string;

		level: Level;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions
		extends Omit<
			ServiceSubscribeOptionsBase<PhoneBlacklist.Model>,
			"order"
		> {
		customerId?: number;
		dispatcherId?: number;
		companyIds?: number[];

		dateRange?: {
			from?: Date | string;
			to?: Date | string;
		};

		order?: Partial<Record<keyof Model, SortingOrder>>;
	}

	export namespace Model {
		export interface New {
			clientId?: number;
			companyIds?: number[];

			phone: string;
			description: string;
			firstName?: string;
			lastName?: string;
			fatherName?: string;

			level: Level;
		}

		export type Modified = Partial<New> & { id: number };
	}
}

export default PhoneBlacklist;
