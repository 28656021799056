import { ColumnId } from "../../../components/OrderPageWidgets/OrderTable/components/ModelTable/columns";

export const initialWidths = {
	[ColumnId.OrderDate]: 80,
	[ColumnId.OrderTime]: 70,
	[ColumnId.ExecutorArrivalDate]: 80,
	[ColumnId.ExecutorArrivalTime]: 70,
	[ColumnId.TimeToDelivery]: 100,
	[ColumnId.ExecutorCallSign]: 60,
	[ColumnId.PickupSettlement]: 100,
	[ColumnId.PickupAddress]: 150,
	[ColumnId.PickupSector]: 100,
	[ColumnId.Phone]: 100,
	[ColumnId.OrderPrice]: 70,
	[ColumnId.CustomerCallStatus]: 60,
	[ColumnId.ExecutorCallStatus]: 60,
	[ColumnId.OrderNumber]: 60,
	[ColumnId.ExecutingStageWithClientsTime]: 100,
	[ColumnId.CustomerNote]: 100,
	[ColumnId.CarClass]: 100,
	[ColumnId.DestinationSettlement]: 100,
	[ColumnId.DestinationAddress]: 150,
	[ColumnId.DestinationSector]: 100,
	[ColumnId.PaymentType]: 60,
	[ColumnId.ExecutorNote]: 150,
	[ColumnId.DispatcherCallSign]: 60,
	[ColumnId.AdditionalPhone]: 100,
	[ColumnId.Status]: 60,
	[ColumnId.Source]: 60,
	[ColumnId.ExecutingStage]: 60,
	[ColumnId.CloseReason]: 60,
	[ColumnId.RideNumber]: 60,
	[ColumnId.TotalRideCount]: 60,
	[ColumnId.CreateOrderDate]: 100,
	[ColumnId.CreateOrderTime]: 80,
	[ColumnId.CustomerFullName]: 180,
	[ColumnId.Counterparty]: 100,
	[ColumnId.CounterpartyCheck]: 100,
	[ColumnId.CounterpartyCheckCard]: 100,
	[ColumnId.ExecutorLate]: 100,
	[ColumnId.ExecutingStageAcceptedTime]: 100,
	[ColumnId.ExecutingStageArrivedTime]: 100,
	[ColumnId.ExecutingStageWaitingCustomerTime]: 100,
	[ColumnId.SMSLateStatus]: 100,
	[ColumnId.SMSWillBeAtStatus]: 100,
	[ColumnId.SMSCarNotFoundStatus]: 100,
	[ColumnId.SMSAwaitingPassengersStatus]: 100,
	[ColumnId.SMSWithPassengersStatus]: 100,
	[ColumnId.SMSOrderCompletedStatus]: 100,
	[ColumnId.SMSExecutorSwitchedStatus]: 100,
	[ColumnId.Company]: 100,
	[ColumnId.TaxiService]: 100,
	[ColumnId.IdleTime]: 100,
	[ColumnId.Tariff]: 100,
	[ColumnId.Services]: 100,
	[ColumnId.Parameters]: 100,
	[ColumnId.Discount]: 100,
	[ColumnId.TransportationType]: 100,
};

export const initialSortMethod = {
	column: ColumnId.CreateOrderDate,
	type: "desc" as const,
};

export default { initialSortMethod, initialWidths };
