/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Subscription from "../../types/Subscription";
import CarBaseTypeToCarBrand from "../CarBaseTypeToCarBrand";
import CarModelToCarBodyType from "../CarModelToCarBodyType";
import Base from "../Base";

class CarModel extends Base {
	static fromResponse(data: any): CarModel.Model {
		return {
			id: data.id,

			carBaseTypeToCarBrand: data.baseTypeToBrand
				? CarBaseTypeToCarBrand.fromResponse(data.baseTypeToBrand)
				: undefined,
			carModelToCarBodyTypes: data.modelToBodyTypes?.map(
				(modelToBodyType) =>
					CarModelToCarBodyType.fromResponse(
						modelToBodyType.bodyType,
					),
			),

			name: data.name,
			active: data.active,
			favorite: data.favorite,
			native: data.native,

			position: data.position,
			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: CarModel.Model.New | CarModel.Model.Modified): any {
		return {
			carBodyTypeIds: model.carBodyTypeIds,

			name: model.name,
			active: model.active,
			favorite: model.favorite,
		};
	}

	public static async store(object: CarModel.Model.New) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.carModel.create(CarModel.toRequest(object)),
		);
		return data;
	}

	public static async update(object: CarModel.Model.Modified) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.carModel.update(
				object.id,
				CarModel.toRequest(object),
			),
		);
		return data;
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: CarModel.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarModel.Model>,
	): Promise<Subscription<CarModel.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.carModel.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "CarModel.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarModel.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarModel.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.carModel.delete(id));
	}

	private static optionsToRequest(options: CarModel.SubscribeOptions) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			ids: options.ids,
			carBrandIds: options.carBrandIds,
			carBaseTypeIds: options.carBaseTypeIds,

			active: options.active,
			favorite: options.favorite,
			native: options.native,

			order: options.order,
		};
	}
}

declare namespace CarModel {
	export interface Model {
		id: number;

		carBaseTypeToCarBrand?: CarBaseTypeToCarBrand.Model;
		carModelToCarBodyTypes?: CarModelToCarBodyType.Model[];

		name: string;
		active: boolean;
		favorite: boolean;
		native: boolean;
		position: Date | number;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<CarModel.Model>, "order"> {
		ids?: number[];
		carBrandIds?: number[];
		carBaseTypeIds?: number[];

		active?: boolean;
		favorite?: boolean;
		native?: boolean;

		order?: Partial<Record<keyof Model, SortingOrder>>;
	}

	namespace Model {
		interface New {
			id?: number;

			carBodyTypeIds?: number[];

			name: string;
			active?: boolean;
			favorite?: boolean;
		}
		type Modified = Partial<New> & { id: number };
	}
}

export default CarModel;
