import styled from "styled-components";

const Root = styled.div`
	display: flex;

	align-items: center;
	justify-content: center;

	width: 20px;

	cursor: pointer;
`;

export default Root;
