import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import File from "../../services/File";
import { browse } from "../../utils/file";

import Root from "./components/Root";
import NoFiles from "./components/NoFiles";
import Item from "./components/Item";

const FileUpload: React.FC<FileUpload.Props> = ({
	value,
	onChange,

	disabled = false,
	label,
}) => {
	const { t } = useTranslation();
	const addFilesOnClick = useCallback(async () => {
		const file = (await browse({
			type: ".gif, .jpg, .png, .jpeg",
		})) as globalThis.File;

		const newFile = new File.Model(file);

		onChange(newFile);
	}, [onChange]);

	const removeFileHandler = useCallback(() => {
		onChange(null);
	}, [onChange]);

	return (
		<Root gaps="17px*">
			{!value ? (
				<NoFiles
					disabled={disabled}
					gaps="8px*"
					align="center"
					onClick={addFilesOnClick}
				>
					<Icon id="upload" size={20} />
					<span>{(label || t("fileUpload.str200")) ?? ""}</span>
				</NoFiles>
			) : (
				<Item
					disabled={disabled}
					file={value}
					onDelete={removeFileHandler}
				/>
			)}
		</Root>
	);
};

declare namespace FileUpload {
	type Value = File.Model | null;

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		label?: string;

		disabled?: boolean;
	}
}

export default FileUpload;
