/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useArchiveOrders } from "../../../../../../hooks";
import Call from "../../../../../../../../../../services/Call";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";

import Record from "./components/Record";
import Status from "./components/Status";
import {
	StyledColumn,
	StyledP,
	StyledRow,
	Divider,
	Text,
	StyledSpan,
} from "../../../../../../../../../../components/common";

const CallsHistory: React.FC<CallsHistory.Props> = memo(() => {
	const [open, setOpen] = useState<boolean>(false);
	const [active, setActive] = useState<number>(0);
	const { t } = useTranslation();
	const {
		orders: { activeOrderId },
	} = useArchiveOrders();

	const id = useMemo(() => activeOrderId || -1, [activeOrderId]);
	const options = useMemo<Call.SubscribeOptions>(
		() => ({ orderIds: [id] }),
		[id],
	);
	const { models } = useModelSubscribe(options, Call);
	const calls = useMemo(() => models || [], [models]);

	const arrow = useCallback(
		(index: number) => {
			if (open && active === index) {
				return {
					left: "5px solid transparent",
					right: "5px solid transparent",
					top: "5px solid #03A9F4",
				};
			}
			return {
				bottom: "5px solid transparent",
				left: "5px solid #21333F",
				top: "5px solid transparent",
			};
		},
		[active, open],
	);

	return (
		<StyledColumn
			gap="5px"
			w="100%"
			h="93%"
			position="absolute"
			justify="start"
			alignItems="center"
			flex={{ wrap: "nowrap" }}
			over="hidden auto"
		>
			{!calls.length && (
				<StyledRow justify="center" alignItems="start" p="1rem">
					<Text
						text={t("change_history.calls.call")}
						value={t("change_history.calls.not")}
					/>
				</StyledRow>
			)}

			{!!calls.length && (
				<StyledRow
					p="0 0 0 24px"
					justify="start"
					alignItems="center"
					w="100%"
				>
					<StyledP w="114px" h="30px" p="0 7px 0 0">
						{t("change_history.calls.employee")}
					</StyledP>
					<StyledP w="86px" h="30px" p="8px 7px 8px 0px">
						{t("change_history.calls.time")}
					</StyledP>
					<StyledP w="83px" h="30px" p="8px 7px" m="0 0 0 0px">
						{t("change_history.calls.status")}
					</StyledP>
				</StyledRow>
			)}
			{!!calls.length &&
				calls.map((orderCall, i) => {
					if (!orderCall) return <></>;
					try {
						const waitTimeInSeconds =
							orderCall?.waitTimeInSeconds || 0;
						const durationInSeconds =
							orderCall?.durationInSeconds || 0;
						const recordTime =
							durationInSeconds - waitTimeInSeconds;

						const waitingDate = new Date(0, 0, 0, 0);
						waitingDate.setSeconds(
							orderCall?.waitTimeInSeconds || 0,
						);

						const durationDate = new Date(0, 0, 0, 0);
						durationDate.setSeconds(
							orderCall?.durationInSeconds || 0,
						);

						const recordData = new Date(0, 0, 0, 0);
						recordData.setSeconds(recordTime);

						const wait = waitingDate.toLocaleTimeString();
						const duration = durationDate.toLocaleTimeString();
						const record = recordData.toLocaleTimeString();

						const {
							firstName = "",
							lastName = "",
							fatherName = "",
						} = orderCall.dispatcherToSip?.dispatcher?.person ?? {};
						const employee = [firstName, lastName, fatherName]
							.filter(Boolean)
							.join(" ");

						const getStartTimeByUniqueId = (
							asteriskUniqueId: string,
						) => {
							if (!asteriskUniqueId) {
								return new Date(orderCall.createdAt);
							}
							const time = asteriskUniqueId?.split(".")[0];
							const timestampInMilliseconds = Number(time) * 1000;
							return new Date(timestampInMilliseconds);
						};

						const time = `${getStartTimeByUniqueId(
							orderCall.asteriskUniqueId,
						).toLocaleTimeString()}`;

						const dataTime = `${getStartTimeByUniqueId(
							orderCall.asteriskUniqueId,
						).toLocaleDateString()}`;

						return (
							<Divider
								key={i}
								side="top"
								w="100%"
								bgC={
									open && active === i + 1
										? "#F5F5F5"
										: "#ffffff"
								}
							>
								<StyledColumn
									w="100%"
									h={
										open && active === i + 1
											? "80px"
											: "50px"
									}
									position="relative"
								>
									<StyledSpan
										w="0px"
										h="0px"
										b={arrow(i + 1)}
										position="absolute"
										left="10px"
										top="17px"
										cursor="pointer"
										onClick={() => {
											setActive(i + 1);
											setOpen((prev) => !prev);
										}}
									/>
									<StyledRow
										w="100%"
										p="0 0 0 24px"
										h="50px"
										justify="start"
									>
										<StyledColumn
											w="114px"
											p="8px 0 8px 3px"
											gap="2px"
											cursor="pointer"
											onClick={() => {
												setActive(i + 1);
												setOpen((prev) => !prev);
											}}
										>
											<StyledP
												colors="#21333F"
												w="114px"
												font={{
													fw: "500",
													size: "13px",
													line: "16px",
												}}
											>
												{orderCall.operator}
											</StyledP>
											<StyledP
												w="114px"
												colors="#647079"
												font={{
													fw: "400",
													size: "12px",
													line: "16px",
												}}
											>
												{employee}
											</StyledP>
										</StyledColumn>
										<StyledColumn
											w="86px"
											p="8px 0px"
											gap="2px"
											cursor="pointer"
											onClick={() => {
												setActive(i + 1);
												setOpen((prev) => !prev);
											}}
										>
											<StyledP
												w="86px"
												colors="#21333F"
												font={{
													fw: "400",
													size: "13px",
													line: "17px",
												}}
											>
												{dataTime}
											</StyledP>
											<StyledP
												w="86px"
												colors="#21333F"
												font={{
													fw: "400",
													size: "13px",
													line: "17px",
												}}
											>
												{time}
											</StyledP>
										</StyledColumn>
										<StyledRow
											w="100%"
											gap="0px 1rem"
											p="0px 8px"
											alignItems="center"
											justify="space-between"
											cursor="pointer"
											onClick={() => {
												setActive(i + 1);
												setOpen((prev) => !prev);
											}}
										>
											<Status
												status={orderCall.dialStatus}
												duration={duration}
											/>
										</StyledRow>
										<StyledRow
											w="100%"
											gap="0px 1rem"
											p="0px 8px"
											alignItems="center"
											justify="space-between"
										>
											<Record
												playSoundUrl={
													orderCall.playSoundUrl
												}
												downloadSoundUrl={
													orderCall.downloadSoundUrl
												}
											/>
										</StyledRow>
									</StyledRow>
									{open && active === i + 1 && (
										<StyledRow
											p="0 0 0 24px"
											h="30px"
											gap="0 1rem"
											alignItems="center"
											cursor="pointer"
											onClick={() => {
												setActive(i + 1);
												setOpen((prev) => !prev);
											}}
										>
											<Text
												textStyle={{
													h: "16px",
													colors: "#21333F",
													font: {
														fw: "500",
														size: "12px",
														line: "14.4px",
													},
												}}
												text={t(
													"change_history.calls.wait",
												)}
												valueStyle={{
													h: "16px",
													colors: "#647079",
													p: "0 0 2px 0",
													font: {
														fw: "400",
														size: "12.7px",
														line: "14.4px",
													},
												}}
												value={wait}
											/>
											<Text
												textStyle={{
													h: "16px",
													colors: "#21333F",
													font: {
														fw: "500",
														size: "12px",
														line: "14.4px",
													},
												}}
												valueStyle={{
													h: "16px",
													colors: "#647079",
													p: "0 0 2px 0",
													font: {
														fw: "400",
														size: "12.7px",
														line: "14.4px",
													},
												}}
												text={t(
													"change_history.calls.record",
												)}
												value={record}
											/>
										</StyledRow>
									)}
								</StyledColumn>
							</Divider>
						);
					} catch {
						return <></>;
					}
				})}
		</StyledColumn>
	);
});

declare namespace CallsHistory {
	interface Props {}
}

export default CallsHistory;
