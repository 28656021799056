import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme, Row } from "uikit";

interface LinkProps {
	bold?: string;
}

const StyledLink = styled(Link)<LinkProps>`
	font-size: 12px;
	color: ${theme.colors.secondary};
	cursor: pointer;

	font-weight: ${(props) => (props.bold ? "bold" : "normal")};

	&:hover {
		color: ${theme.colors.accent_hover};
		text-decoration: underline;
	}
`;

const StyledSplitter = styled.div`
	font-size: 12px;
	color: ${theme.colors.secondary};
`;

const Breadcrumbs: React.FC<Breadcrumbs.Props> = memo(({ items }) => (
	<Row align="center" gaps="8px*">
		{items.map((item, i) => (
			<Row key={i} gaps="8px*">
				{i !== 0 && <StyledSplitter>/</StyledSplitter>}
				<StyledLink
					bold={i === items.length - 1 ? "true" : undefined}
					to={item.link}
				>
					{item.label}
				</StyledLink>
			</Row>
		))}
	</Row>
));

declare namespace Breadcrumbs {
	interface Props {
		items: {
			label: string;
			link: string;
		}[];
	}
}

export default Breadcrumbs;
