import { ReactNode } from "react";

import Language from "../../../../../../../../../services/Language";
import { translationTablePath } from "../../../constants/translationPath";

import active from "./active";
import name from "./name";
import company from "./company";
import branch from "./branch";
import availableForOnlineOrdering from "./availableForOnlineOrdering";

export enum ColumnId {
	Active = "active",
	Name = "name",
	Company = "company",
	Branch = "branch",
	AvailableForOnlineOrdering = "availableForOnlineOrdering",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;

		language: Language;

		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.Active]: {
		render: active,
		label: [`${translationTablePath}.table.${ColumnId.Active}`, "Active."],
	},
	[ColumnId.Name]: {
		render: name,
		label: [`${translationTablePath}.table.${ColumnId.Name}`, "Name"],
	},
	[ColumnId.Company]: {
		render: company,
		label: [`${translationTablePath}.table.${ColumnId.Company}`, "Company"],
	},
	[ColumnId.Branch]: {
		render: branch,
		label: [`${translationTablePath}.table.${ColumnId.Branch}`, "Branch"],
	},
	[ColumnId.AvailableForOnlineOrdering]: {
		render: availableForOnlineOrdering,
		label: [
			`${translationTablePath}.table.${ColumnId.AvailableForOnlineOrdering}`,
			"Available for customer app",
		],
	},
};

export const defaultColumnIds = [ColumnId.Active];
export const disabledColumnsIds = [];
export default columns;
