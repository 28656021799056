import {
	MessageTemplateActions,
	MessageTemplateTypes,
} from "../../../../../../types/MessageTemplateEnums";
import Modal from "../components/Modal";

const defaultTemplate: Modal.Value = {
	name: "",
	templateText: "",
	taxiServiceIds: [],
	taxiServices: [],
	action: MessageTemplateActions.UNKNOWN_ACTION,
	lang: "uk",
	active: true,
	automaticSending: false,
	autoSendOrderChanges: false,
	disableAutoSendForPreOrder: false,
	disableAutoSendForOnlineOrder: true,
	isAutoSendDelay: true,
	autoSendDelay: 300,
	excludeOrderChanges: false,
	isValidityPeriod: true,
	validityPeriod: 30,
	isTransliteration: false,
	orderTemplate: false,
	isMaxSmsPartCount: false,
	maxSmsPartCount: 10,
	isMinTimeoutSendingSms: false,
	minTimeoutSendingSms: 30,
	isDefault: false,
	type: MessageTemplateTypes.CAR_EXECUTOR_ORDER_CUSTOMER,
};

export default defaultTemplate;
