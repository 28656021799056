import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Stepper } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../../../../../../../../../../../../../components/common";
import Title from "../../../../../../../../../../../../../Title";
import Sector from "../../../../../../../../../../../../../../../../../../../../services/Sector";

const Main: React.FC<Main.Props> = ({ value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const isMaxSpeedEnabled = valueEditor.useGetter("isMaxSpeedEnabled");
	const setIsMaxSpeedEnabled = valueEditor.useSetter("isMaxSpeedEnabled");
	const maxSpeed = valueEditor.useGetter("maxSpeed");
	const setMaxSpeed = valueEditor.useSetter("maxSpeed");
	const maxSpeedOnChange = useCallback(
		(maxSpeed: number | undefined) => {
			if (maxSpeed != null) setMaxSpeed(maxSpeed);
		},
		[setMaxSpeed],
	);

	const isAverageSpeedEnabled = valueEditor.useGetter(
		"isAverageSpeedEnabled",
	);
	const setIsAverageSpeedEnabled = valueEditor.useSetter(
		"isAverageSpeedEnabled",
	);

	const isBasicAverageSpeedEnabled = valueEditor.useGetter(
		"isBasicAverageSpeedEnabled",
	);
	const setIsBasicAverageSpeedEnabled = valueEditor.useSetter(
		"isBasicAverageSpeedEnabled",
	);

	const basicAverageSpeed = valueEditor.useGetter("basicAverageSpeed");
	const setAverageSpeed = valueEditor.useSetter("averageSpeed");

	const handleIsBasicAverageSpeedEnabled = useCallback(
		(value: boolean) => {
			setIsBasicAverageSpeedEnabled(value);
			if (value) {
				const averageSpeed = [...basicAverageSpeed].map((item, i) => ({
					...item,
					id: i + 1,
				}));
				setAverageSpeed(averageSpeed);
			} else setAverageSpeed([]);
		},
		[basicAverageSpeed, setAverageSpeed, setIsBasicAverageSpeedEnabled],
	);

	const { t } = useTranslation();
	return (
		<StyledColumn w="100%" h="100%" gap="20px" p="20px">
			<Title>
				{t(
					"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.main.str200",
				) ?? ""}
			</Title>
			<StyledRow alignItems="center" gap="10px">
				<CheckBoxWithContent
					gap="10px"
					value={isMaxSpeedEnabled}
					onChange={setIsMaxSpeedEnabled}
				>
					{t(
						"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.main.str201",
					) ?? ""}
				</CheckBoxWithContent>
				<StyledRow w="57px">
					<Stepper
						disabled={!isMaxSpeedEnabled}
						value={maxSpeed}
						min={0}
						onChange={maxSpeedOnChange}
					/>
				</StyledRow>
			</StyledRow>
			<StyledRow alignItems="center" gap="0 1rem">
				<CheckBoxWithContent
					gap="18px"
					value={isAverageSpeedEnabled}
					onChange={setIsAverageSpeedEnabled}
				>
					{t(
						"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.main.str150",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					gap="10px"
					disabled={!isAverageSpeedEnabled}
					value={isBasicAverageSpeedEnabled}
					onChange={handleIsBasicAverageSpeedEnabled}
				>
					{t(
						"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.main.str151",
					) ?? ""}
				</CheckBoxWithContent>
			</StyledRow>
		</StyledColumn>
	);
};

declare namespace Main {
	interface Value {
		isMaxSpeedEnabled: boolean;
		maxSpeed: number;
		isAverageSpeedEnabled: boolean;
		isBasicAverageSpeedEnabled: boolean;
		basicAverageSpeed: Sector.AverageSpeed[];
		averageSpeed: Sector.AverageSpeed[];
	}

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}
}

export default Main;
