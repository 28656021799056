import React, { memo, useCallback } from "react";

import { StyledSpan } from "../styles";

const StarBase: React.FC<Star.Props> = ({ value, starNumber }) => {
	const fn = useCallback((num: number, star: number) => {
		const prev = star - 1;
		if (num >= star) return "#ffb300";
		if (num < star && num <= star - 1) return "#21333f";
		const value = num - prev;
		const positive = value * 100;
		const negative = Math.abs(value - 1) * 100;

		return `linear-gradient(to right, #ffb300 ${positive}%, #21333f ${negative}%)`;
	}, []);

	return (
		<StyledSpan
			font={{ size: "20px" }}
			colors="#ffb300"
			bg={fn(value, starNumber)}
			css="
          background-clip: text;
          -webkit-text-fill-color: transparent;
        "
		>
			★
		</StyledSpan>
	);
};

export const Star = memo(StarBase);

declare namespace Star {
	interface Props {
		value: number;
		starNumber: number;
	}
}

export default StarBase;
