import React, { Dispatch, memo } from "react";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";

const Header: React.FC<Header.Props> = memo(
	({ tab, title, createdAt, onChangeTab }) => (
		<Root sizes="auto!*">
			<InformationContainer
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title style={{ marginBottom: 10 }}>{title}</Title>
			</InformationContainer>
		</Root>
	),
);

declare namespace Header {
	type Tab = "main";

	interface Props {
		tab: Tab;

		title: string;
		createdAt?: string;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
