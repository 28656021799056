import * as FNS from "date-fns";
import {
	compareDesc,
	format,
	formatDistance,
	isValid,
	differenceInMilliseconds,
	startOfDay,
	addMilliseconds,
} from "date-fns";
import { ru, uk, enUS as en, az, tr } from "date-fns/locale";
import { isBoolean, isNumber } from "lodash";

import Language from "../services/Language";

export type Period = {
	from?: Date;
	to?: Date;
};
export type DaysNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DaysGuard = (day?: number): day is DaysNumber => {
	if (!isNumber(day)) return false;
	const days = [0, 1, 2, 3, 4, 5, 6];
	return days.includes(day);
};

export const ConstantFormat = {
	day: "eeee",
	month: "MMMM",
	year: "yyyy",

	MDYHMSA: "MM.dd.yyyy hh:mm:ss aa",
	MDYHMA: "MM.dd.yyyy hh:mm aa",
	MDY: "MM.dd.yyyy",
	HMSA: "hh:mm:ss aa",
	HMA: "hh:mm aa",
	HA: "hh aa",
	DMMYHMSA: "dd MMMM yyyy hh:mm:ss aa",

	MDYHMS_UK: "dd.MM.yyyy HH:mm:ss",
	MDYHM_UK: "dd.MM.yyyy HH:mm",
	DMMYHMS_UK: "dd MMMM yyyy HH:mm:ss",
	MDY_UK: "dd.MM.yyyy",
	HMS_UK: "HH:mm:ss",
	HM_UK: "HH:mm",
	H_UK: "HH",
	K_UK: "k",
	KK_UK: "kk",

	YOrder: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
} as const;
export type TypeFormat = typeof ConstantFormat;
export type ValueFormat = (typeof ConstantFormat)[keyof typeof ConstantFormat];

// https://www.npmjs.com/package/date-fns-tz
// https://date-fns.org/v2.23.0/docs/format
export class DateFns {
	public ONE_DAY_MS: number = 1000 * 60 * 60 * 24;

	public year: number = new Date().getFullYear();

	public month: number = new Date().getMonth();

	public locales: Map<Language | "az" | "tr", Locale> = new Map<
		Language | "az" | "tr",
		Locale
	>();

	lang: Language = "uk";

	public fns = FNS;

	constructor(data: { lang?: Language } = { lang: "uk" }) {
		this.locales.set("uk", uk);
		this.locales.set("ru", ru);
		this.locales.set("en", en);
		this.locales.set("az", az);
		this.locales.set("tr", tr);
		this.lang = data.lang || "uk";
		this.fns = FNS;
	}

	public getMonths = (): string[] => {
		const months = Array.from(Array(this.month), (_, i) =>
			format(new Date(new Date().getFullYear(), i + 1, i + 1), "MMMM"),
		);
		return months;
	};

	public getAllMonths = (): string[] => {
		const months = Array.from(Array(12), (_, i) =>
			format(new Date(new Date().getFullYear(), i + 1, i + 1), "MMMM"),
		);
		return months;
	};

	public getDay = (): number =>
		Number(format(new Date(), "dd MMMM yyyy").substring(0, 2));

	public getDaysInWeek = (): string[] =>
		Array.from(Array(7), (_, i) =>
			format(
				new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					i + 1,
				),
				"dd",
			),
		);

	public getDaysInMonth = (
		month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
		year: number = new Date().getFullYear(),
		type: "eeee" | "eee" | "do" | "d" | "dd" = "dd",
	): string[] => {
		const f = new Date(year, month);
		const days = format(f, "dd");
		const getMonth: number = month || new Date().getMonth();
		return Array.from(Array(Number(days)), (_, i) =>
			format(new Date(year, getMonth, i + 1), type),
		);
	};

	public getDayName = (
		type: "eeee" | "eee" | "eeeeee" | "eo" | "e" | "ee" = "ee",
	): number => Number(format(new Date(), type).substring(0, 2));

	public getDayNameByNumber = (
		weekStartsOn: DaysNumber = 0,
		type: "eeee" | "eee" | "eeeeee" | "eo" | "e" | "ee" = "eeee",
	): string => {
		const locale = this.locale(this.lang);
		const start = this.fns.startOfWeek(new Date(), {
			weekStartsOn: 1,
			locale,
		});
		const day = this.fns.addDays(start, weekStartsOn);

		return format(day, type, { locale });
	};

	private locale = (str?: Language): Locale | undefined => {
		if (!str) return undefined;
		return this.locales.get(str);
	};

	/** * Get Year, month, day, hour, min, second   */
	public format = (
		date: Date | number | null | undefined,
		lang: Language = "uk",
	): string => {
		if (date === null || date === undefined) return "";
		const locale = this.locale(lang);

		const formatTime =
			lang === "en" ? "MM.dd.yyyy hh:mm" : "dd.MM.yyyy HH:mm:ss";
		return format(date, formatTime, { locale });
	};

	/** * Get Year, month, day. */
	public format2 = (
		date: Date | number | null | undefined,
		lang: Language = "uk",
	): string => {
		if (date === null || date === undefined) return "";
		const locale = this.locale(lang);

		const formatTime = lang === "en" ? "MM.dd.yyyy" : "dd.MM.yyyy";
		return format(date, formatTime, { locale });
	};

	/** * Get hour, min, second */
	public format3 = (
		date: Date | number | null | undefined,
		lang: Language = "uk",
	): string => {
		if (date === null || date === undefined) return "";
		const locale = this.locale(lang);

		const formatTime = lang === "en" ? "hh:mm:ss aa" : "HH:mm:ss";
		return format(date, formatTime, { locale });
	};

	public formatTime = (
		date: string | Date | number | null | undefined,
		formatTime: ValueFormat,
		lang: Language = "uk",
	): string => {
		if (date === null || date === undefined) return "";

		const locale = this.locale(lang);

		if (typeof date === "string") {
			const time = new Date(date);
			if (!isValid(time)) return "";
			return format(time, formatTime, { locale });
		}

		if (!isValid(date)) return "";
		return format(date, formatTime, { locale });
	};

	public formatTimeFromMilliseconds = (
		date: number,
		formatTime: ValueFormat,
		lang: Language = "uk",
	): string => {
		const locale = this.locale(lang);
		const time = new Date(date);
		if (!isValid(time)) return "";
		return format(time, formatTime, { locale });
	};

	/**
	 * Getting the remaining time
	 *
	 * @param {(number | Date)} start
	 * @param {(number | Date)} end
	 * @param {?Language} [locale]
	 * @returns {string}
	 */
	public formatDistance = (
		start: number | Date,
		end: number | Date,
		locale?: Language,
	) => {
		const time = formatDistance(start, end, {
			locale: this.locale(locale),
		});
		return time;
	};

	/** * https://date-fns.org/v2.30.0/docs/compareDesc
	 ** now === to = 0
	 ** now < to  = 1
	 ** now > to  = -1
	 */
	public compareDesc = (
		from: number | Date,
		to: number | Date,
	): 0 | 1 | -1 => {
		const value = compareDesc(from, to);
		if (value === 1) return 1;
		if (value === 0) return 0;
		return -1;
	};

	/** * Sorts the array by the specified time */
	public isPeriod = (
		date: string | Date | null | undefined,
		period?: Period,
	): boolean => {
		if (date === null || date === undefined) return false;
		const newDate = new Date(date);

		if (period?.from && period?.to) {
			const dateFrom = new Date(period.from);
			const dateTo = new Date(period.to);
			const isValidTo = this.compareDesc(dateFrom, newDate);
			const isValidFrom = this.compareDesc(newDate, dateTo);
			return isValidTo !== -1 && isValidFrom !== -1;
		}
		if (period?.from) {
			const from = new Date(period.from);
			const isValid = this.compareDesc(from, newDate);
			return isValid !== -1;
		}
		if (period?.to) {
			const to = new Date(period.to);
			const isValid = this.compareDesc(newDate, to);
			return isValid !== -1;
		}

		return true;
	};

	/**
	 * Formats a date string to a human-readable format.
	 * @param dateString - The date string to format.
	 * @param badValue - The value to return if the date string is invalid.
	 * @returns The formatted date string or the badValue if invalid.
	 */

	getCurrentPeriod = (
		startDate: number | Date,
		periodDays: number,
	): {
		from: number;
		to: number;
	} => {
		const now = new Date().getTime();
		const startDateMs = new Date(startDate).getTime();

		const differenceDays = (now - startDateMs) / this.ONE_DAY_MS;
		const periodsFloor =
			Math.floor(differenceDays / periodDays) * periodDays;
		const periodsCeil = Math.ceil(differenceDays / periodDays) * periodDays;
		const from = startDateMs + periodsFloor * this.ONE_DAY_MS;
		const to = startDateMs + periodsCeil * this.ONE_DAY_MS;

		return { from, to };
	};

	millisecondsToDate = (
		timestamp: number,
		options: {
			/** timezone + or - */
			plus?: boolean;
			utc?: boolean;
		},
	) => {
		const { plus, utc = false } = options;
		const newDate = startOfDay(new Date());
		const timezone = this.getTimezone();

		const timePlus =
			!utc && isBoolean(plus) && plus ? timestamp + timezone : timestamp;

		const time = addMilliseconds(newDate, timePlus);

		if (utc) {
			const value = plus
				? time.getTime() + timezone
				: time.getTime() - timezone;

			const utcTime = new Date(value);
			return utcTime;
		}

		return time;
	};

	millisecondsFromDate = (
		date: Date,
		options: {
			plus?: boolean;
			utc?: boolean;
		},
	) => {
		const { plus = true, utc = false } = options;
		let givenDate = date;
		const timezone = this.getTimezone();

		if (utc) {
			const value = plus
				? date.getTime() + timezone
				: date.getTime() - timezone;
			givenDate = new Date(value);
		}

		const startOfGivenDay = startOfDay(givenDate);
		const milliseconds = differenceInMilliseconds(
			givenDate,
			startOfGivenDay,
		);

		if (!utc && isBoolean(plus) && !plus) {
			return milliseconds - timezone;
		}

		return milliseconds;
	};

	getUTCTime = (
		date: Date | number | string,
		options: {
			ms?: boolean;
			plus?: boolean;
			utc?: boolean;
			iso?: boolean;
			isFns?: boolean;
		},
	): number | Date | string => {
		const { ms = false, plus = true, iso = false, isFns = false } = options;
		const dateTime = new Date(date).getTime();

		if (isFns) return format(dateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
		if (plus) {
			const time =
				dateTime + Math.abs(new Date().getTimezoneOffset() * 60000);

			if (ms) return time;
			if (iso) return new Date(time).toISOString();

			return new Date(time);
		}

		const time =
			dateTime - Math.abs(new Date().getTimezoneOffset() * 60000);

		if (ms) return time;
		if (iso) return new Date(time).toISOString();

		return new Date(time);
	};

	public getTimezone = (): number => {
		const time = Math.abs(new Date().getTimezoneOffset() * 60_000);
		return time;
	};
}
