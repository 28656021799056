import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";

import Parking from ".";

export async function destroyOne(id: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() => prpc.theirsModel.parking.delete(id));
}

export async function joinOne(parkingId: number, executorId: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() =>
		prpc.theirsModel.parking.queue.join(parkingId, {
			executorId,
		}),
	);
}

export async function leaveOne(executorId: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() =>
		prpc.theirsModel.parking.queue.leave({
			executorId,
		}),
	);
}

export async function rearrangeOne(
	parkingId: number,
	executor: Parking.RearrangePosition,
) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() =>
		prpc.theirsModel.parking.queue.rearrange(parkingId, executor),
	);
}

export default { destroyOne, joinOne, leaveOne, rearrangeOne };
