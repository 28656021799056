import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Key, Option } from "uikit";

import useSettingsSave from "../../hooks/useSettingsSave";
import useActiveTab from "../../hooks/useActiveTab";
import { ConfirmationModal } from "../InputModals/ConfirmationModal";
import { SideTab } from "../common";

import { CommunicationMethods } from "./tabProps";
import SettingsLayout from "./Layout";

export interface SettingsTab {
	render: () => React.ReactNode;
}

interface Props {
	tabs: Option<SettingsTab>[];
	methods: React.MutableRefObject<CommunicationMethods | undefined>;
	hasChanges: boolean;
	onSetHasChanges: (has: boolean) => void;
	header?: React.ReactNode;
}

const Settings: React.FC<Props> = ({
	tabs,
	methods,
	hasChanges,
	onSetHasChanges,
	header,
}) => {
	const { t } = useTranslation();
	const requestedTab = useRef<Key>();
	const [unsavedWarn, setUnsavedWarn] = useState(false);
	const saveSettings = useSettingsSave();

	const save = useCallback(() => {
		methods.current?.save();
		if (hasChanges) onSetHasChanges?.(false);
		saveSettings();
	}, [hasChanges, methods, onSetHasChanges, saveSettings]);

	const cancel = useCallback(() => {
		methods.current?.cancel();
		if (hasChanges) onSetHasChanges?.(false);
	}, [hasChanges, methods, onSetHasChanges]);

	const resetDefaults = useCallback(() => {
		methods.current?.resetDefaults();
		if (hasChanges) onSetHasChanges?.(false);
		saveSettings();
	}, [hasChanges, methods, onSetHasChanges, saveSettings]);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<>
			<SettingsLayout
				header={header}
				navigation={
					<SideTab
						value={activeKey}
						onChange={(newActive) => {
							if (hasChanges) {
								setUnsavedWarn(true);
								requestedTab.current = newActive as Key;
								return;
							}

							setActiveKey(newActive as Key);
						}}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab && activeTab.value.render()}
				hasChanges={!!hasChanges}
				onSave={save}
				onCancel={cancel}
				onResetDefault={resetDefaults}
			/>

			{unsavedWarn && (
				<ConfirmationModal
					dimmer
					content={
						<Flex
							style={{
								backgroundColor: "white",
								padding: "20px",
							}}
						>
							{t(`settings.you_have_unsaved_changes`)}
						</Flex>
					}
					onConfirm={() => {
						save();
						setUnsavedWarn(false);

						if (requestedTab.current) {
							setActiveKey(requestedTab.current);
							requestedTab.current = undefined;
						}
					}}
					onClose={() => {
						setUnsavedWarn(false);
					}}
				/>
			)}
		</>
	);
};

export default Settings;
