/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useMemo } from "react";

import Language from "../../../../../../../../../../services/Language";

import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";

const List: React.FC<List.Props> = ({
	value,
	editing,
	language,
	onChange,
	onChangeEditing,
}) => {
	const contentValue = useMemo<Content.Value>(() => value, [value]);

	const contentOnChange = useCallback(
		(contentValue: Content.Value) => onChange(contentValue),
		[onChange],
	);

	return (
		<Root gaps="2px" sizes="auto! 1fr">
			<Header />
			<Content
				value={contentValue}
				editing={editing}
				language={language}
				onChange={contentOnChange}
				onChangeEditing={onChangeEditing}
			/>
		</Root>
	);
};

declare namespace List {
	type Group = Content.Group;
	type Value = Content.Value;

	interface Props {
		value: Value;
		editing: boolean;

		language: Language;

		onChange: Dispatch<Value>;
		onChangeEditing: Dispatch<boolean>;
	}

	namespace Group {
		type Item = Content.Group.Item;
	}
}

export default List;
