import React, { useCallback, useState } from "react";
import { Button, Icon, Float, react } from "uikit";
import Popover from "./components/Popover";

const PopupFilter = react.inputify<PopupFilter.Props>(() => {
	const [open, setOpen] = useState(false);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<>
			<Float.Tracker id="filters">
				<Button.Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={handleOpen}
				/>
			</Float.Tracker>
			<Popover open={open} onClose={handleClose} />
		</>
	);
});

declare namespace PopupFilter {
	interface Props {}
}

export default PopupFilter;
