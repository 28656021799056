import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import orderPage from "../../reducers/OrdersPage";

export default function getExecutorsByCarCallsign(
	carCallSigns: string | number,
) {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() =>
			prpcow?.theirsModel.executor.getAll({
				carCallSigns: [carCallSigns],
			}),
		)
			.then(async (result) => {
				dispatch(
					orderPage.actions.setExecutorsByCarCallsign(
						result?.items ?? [],
					),
				);
			})
			.catch((error) => console.error(error));
	};
}
