import { useCallback, useMemo } from "react";

import {
	ordersAction as orders,
	Orders,
} from "../../../../../../../redux/reducers/Orders";
import { useTypedDispatch } from "../../../../../../../redux/store";

const useMapActions = (): useMapActions.Props => {
	const dispatch = useTypedDispatch();

	const setALLBtnToggles = useCallback(
		(value: Orders.MapFromAddModal.BtnOnMapToggles) => {
			dispatch(
				orders.mapFromAddModal.toggles.toggleAllButtonsOnMap(value),
			);
		},
		[dispatch],
	);

	const setALLBtnTogglesForFooter = useCallback(
		(value: Orders.MapFromAddModal.BtnOnFooterToggles) => {
			dispatch(
				orders.mapFromAddModal.toggles.toggleAllButtonsOnFooter(value),
			);
		},
		[dispatch],
	);

	return useMemo(
		() => ({
			setALLBtnToggles,
			setALLBtnTogglesForFooter,
		}),
		[setALLBtnToggles, setALLBtnTogglesForFooter],
	);
};

declare namespace useMapActions {
	interface Props {
		setALLBtnToggles: (
			value: Orders.MapFromAddModal.BtnOnMapToggles,
		) => void;
		setALLBtnTogglesForFooter: (
			value: Orders.MapFromAddModal.BtnOnFooterToggles,
		) => void;
	}
}

export default useMapActions;
