import { isUndefined } from "lodash";
import styled from "styled-components";
import { theme } from "uikit";

const ParameterValue = styled.div<ParameterValue.Props>`
	font-family: Lato;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;

	color: ${({ color }) =>
		isUndefined(color) ? theme.colors.primary : color};
`;

declare namespace ParameterValue {
	interface Props {
		color?: string;
	}
}

export default ParameterValue;
