import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	height: 44px;

	padding-inline: 12px;

	background: ${theme.colors.white};

	user-select: none;

	cursor: pointer;

	&:hover {
		background: #f5f5f5;
	}
`;

export default Root;
