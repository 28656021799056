import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, useInternal } from "uikit";

import {
	StyledColumn,
	StyledP,
	StyledRow,
	TextMemo,
} from "../../../../../../../../components/common";
import { useTypedSelector } from "../../../../../../../../redux/store";

declare namespace BaseItemTabHeader {
	interface Props {
		phone?: string;
	}
}

const BaseItemTabHeader: React.FC<BaseItemTabHeader.Props> = ({ phone }) => {
	const { t } = useTranslation();

	const [isCall, setIsCall] = useInternal<boolean>(false);
	const [isActive, setIsActive] = useInternal<boolean>(false);
	const [isIncomingCall, setIsIncomingCall] = useInternal<boolean>(true);
	const [isConfirmed, setIsConfirmed] = useInternal(false);
	const [callDuration, setCallDuration] = useInternal<string>("00:00");
	const [holdDuration, setHoldDuration] = useInternal<string>("00:00");

	const stateCalls = useTypedSelector(
		(state) => state.softphoneWidget.stateCalls,
	);

	useMemo(() => {
		const call = stateCalls.find((item) => item?.phone === phone);

		if (!call) {
			setIsActive(false);
			return;
		}

		setIsActive(true);
		setIsConfirmed(call.isConfirmed);
		setIsCall(call.isTalk);
		setIsIncomingCall(call.isIncomingCall);
		setCallDuration(call?.callDuration || "00:00");
		setHoldDuration(call?.holdDuration || "00:00");
	}, [
		stateCalls,
		setIsActive,
		setIsConfirmed,
		setIsCall,
		setIsIncomingCall,
		setCallDuration,
		setHoldDuration,
		phone,
	]);

	const existPhone = useMemo(
		() =>
			phone ||
			(t("pages.mainPage.pages.orders.orderModal.itemTabHeader.str200") ??
				""),
		[phone, t],
	);
	const activeOrder = useMemo(() => isCall, [isCall]);

	const styles = useMemo(() => {
		const payload = {
			textStyle: {
				h: "12px",
				font: {
					fw: "500",
					size: "10px",
					line: "12px",
				},
			},
			valueStyle: {
				h: "12px",
				font: {
					fw: "500",
					size: "10px",
					line: "12px",
				},
			},
		};
		return payload;
	}, []);

	const callTypeIcon = useMemo<{ color: string; icon: Icon.Props["id"] }>(
		() => ({
			icon: isIncomingCall
				? "order-modal-call-enter"
				: "order-modal-call",
			color: isIncomingCall ? "#4CAF50" : "#03A9F4",
		}),
		[isIncomingCall],
	);

	const callConfirmedTypeIcon = useMemo<{
		color: string;
		icon: Icon.Props["id"];
	}>(
		() => ({
			icon:
				isConfirmed && isCall
					? "order-modal-call-now"
					: "order-modal-call",
			color: isConfirmed && isCall ? "#8000FF" : "#03A9F4",
		}),
		[isCall, isConfirmed],
	);

	return (
		<StyledRow alignItems="center" gap="0 5px" h="30px">
			<StyledRow>
				<StyledP
					colors={"#647079"}
					p="7px"
					font={{
						fw: "600",
						size: "12px",
						line: "14.4px",
					}}
				>
					{existPhone}
				</StyledP>
			</StyledRow>
			<StyledColumn alignItems="center" justify="center">
				{isActive && !activeOrder && (
					<>
						<TextMemo
							textStyle={{
								...styles.textStyle,
								colors: callTypeIcon.color,
							}}
							valueStyle={{
								...styles.valueStyle,
								colors: callTypeIcon.color,
							}}
							text={
								<Icon
									id={callTypeIcon.icon}
									size={9}
									style={{ fill: callTypeIcon.color }}
								/>
							}
							value={callDuration}
						/>
						<TextMemo
							textStyle={{
								...styles.textStyle,
								colors: "#F2BD00",
							}}
							valueStyle={{
								...styles.valueStyle,
								colors: "#F2BD00",
							}}
							text={
								<Icon
									id="order-modal-call-paused"
									size={9}
									style={{ fill: "#F2BD00" }}
								/>
							}
							value={holdDuration}
						/>
					</>
				)}

				{isActive && activeOrder && (
					<TextMemo
						textStyle={{
							...styles.textStyle,
							alignItems: "start",
							colors: callConfirmedTypeIcon.color,
							h: "14px",
						}}
						valueStyle={{
							...styles.valueStyle,
							colors: callConfirmedTypeIcon.color,
						}}
						text={
							<Icon
								id={callConfirmedTypeIcon.icon}
								size={12}
								style={{ fill: callConfirmedTypeIcon.color }}
							/>
						}
						value={callDuration}
					/>
				)}
			</StyledColumn>
		</StyledRow>
	);
};

export const ItemTabHeader = memo(BaseItemTabHeader);
export default BaseItemTabHeader;
