import React from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const OrderNumberCellContent: React.FC<OrderNumberCellContent.Props> = ({
	item,
}) => (
	<CellContentRoot align="center" maxedHeight maxedWidth>
		{item.orderNumber}
	</CellContentRoot>
);

declare namespace OrderNumberCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default OrderNumberCellContent;
