import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	padding: 8px 8px 9.5px 8px;

	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

	z-index: 1;
`;

export default Root;
