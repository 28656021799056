import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	height: 100%;

	overflow: hidden auto;

	${theme.scrollbar}
`;

export default Root;
