import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Column,
	DatePicker,
	Icon,
	RadioButton,
	Stepper,
	theme,
	TimeInput,
} from "uikit";
import styled from "styled-components";

import { StyledRow } from "../../../../common";

import useDatePickerLocale from "../../../../../hooks/useDatePickerLocale";
import { useTypedSelector } from "../../../../../redux/store";

import { Orders } from "../../../../../redux/reducers/Orders";

import useKeyBind from "../../../../../hooks/useKeyBind";
import { KEYBOARD_CODES } from "../../../../../constants/business";

import { Root, Header } from "./components";

const CustomStyledRow = styled(StyledRow)<{
	align?: string | undefined | null;
}>`
	align-items: ${({ align }) => align ?? "center"};
	cursor: ${({ align }) => (align ? "not-allowed" : "auto")};
`;

const CustomDatePicker = styled(DatePicker)`
	width: 144px;
`;

const CustomTimeInput = styled(TimeInput)`
	width: 102px;
`;

const TimeButton = styled.button<{ disabled: boolean }>`
	position: absolute;
	top: 2px;
	right: 4px;
	height: 32.5px;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const ScheduleModal: React.FC<ScheduleModal.Props> = ({
	onClose,
	onOpenClock,
	onSubmit,
	scheduleTimeBoofer,
	onChangeScheduleTimeBoofer,
}) => {
	const { t } = useTranslation();
	const [inputs, setInputs] = useState<any>(null);

	const scheduleModalRef = useRef<any>(null);

	const locale = useDatePickerLocale();

	const {
		scheduleDate,
		scheduleModalActualType,
		scheduleHours,
		scheduleMinutes,
		isShowClockModal,
	} = useTypedSelector((state) => state.orders.chats.chat);

	const [scheduleTypeBoofer, setScheduleTypeBoofer] =
		useState<Orders.Chat.ScheduleActualType>(
			scheduleModalActualType || "time",
		);

	const [scheduleDateBoofer, setSchedulelDateBoofer] =
		useState<DatePicker.Value>(scheduleDate || (new Date() as any));

	const [scheduleHoursBoofer, setScheduleHoursBoofer] = useState<number>(
		scheduleHours || 0,
	);
	const [scheduleMinutesBoofer, setScheduleMinutesBoofer] = useState<number>(
		scheduleMinutes || 0,
	);

	const onChangeTime = useCallback(
		(e: TimeInput.Value) => {
			if (e) {
				onChangeScheduleTimeBoofer(e);
				setTabIndex(3);
			}
		},
		[onChangeScheduleTimeBoofer],
	);

	const setTimeMidnight = useCallback(() => {
		const dateUTC = new Date("1970-01-01T23:59:59Z");

		const timezoneOffset = dateUTC.getTimezoneOffset();

		const dateLocal = new Date(dateUTC.getTime() + timezoneOffset * 60000);
		onChangeTime(dateLocal);
	}, [onChangeTime]);

	const onChangeDate = useCallback(
		(e: DatePicker.Value) => {
			setSchedulelDateBoofer(e);
			setTimeMidnight();
			setTabIndex(2);
		},
		[setTimeMidnight],
	);

	const selectByTime = useCallback(() => {
		setScheduleTypeBoofer("time");
	}, []);

	const selectByDate = useCallback(() => {
		setScheduleTypeBoofer("date");
	}, []);

	const onChangeHours = useCallback((e) => {
		setScheduleHoursBoofer(e);
		setTabIndex(0);
	}, []);

	const onChangeMinutes = useCallback((e) => {
		setScheduleMinutesBoofer(e);
		setTabIndex(1);
	}, []);

	const openClockModal = useCallback(() => onOpenClock(), [onOpenClock]);

	const submitData = useCallback(() => {
		const data: Orders.Chat.ScheduleRequestData = {
			type: scheduleTypeBoofer,
			date: scheduleDateBoofer,
			hours: scheduleHoursBoofer,
			minutes: scheduleMinutesBoofer,
			time: scheduleTimeBoofer,
		};
		onSubmit(data);
	}, [
		onSubmit,
		scheduleDateBoofer,
		scheduleHoursBoofer,
		scheduleMinutesBoofer,
		scheduleTimeBoofer,
		scheduleTypeBoofer,
	]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	useKeyBind([KEYBOARD_CODES.SHIFT, KEYBOARD_CODES.TAB], (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (tabIndex === 2) {
			selectByTime();
		}
		if (tabIndex === 0) {
			selectByDate();
			setTabIndex(3);
		} else {
			setTabIndex((prev) => prev - 1);
		}
	});

	useKeyBind([KEYBOARD_CODES.TAB], (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (tabIndex === 1) {
			selectByDate();
		}
		if (tabIndex === 3) {
			selectByTime();
			setTabIndex(0);
		} else {
			setTabIndex((prev) => prev + 1);
		}
	});

	useKeyBind([KEYBOARD_CODES.ENTER], () => {
		if (!isShowClockModal) {
			submitData();
		}
	});

	useKeyBind([KEYBOARD_CODES.ESCAPE], () => {
		if (!isShowClockModal) {
			onClose();
		}
	});

	useKeyBind([KEYBOARD_CODES.ARROW_LEFT], () => {});
	useKeyBind([KEYBOARD_CODES.ARROW_RIGHT], () => {});

	useEffect(() => {
		const inputNodes = scheduleModalRef?.current?.querySelectorAll("input");
		setInputs(inputNodes);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (
				inputs?.length &&
				tabIndex >= 0 &&
				tabIndex <= inputs.length - 1
			) {
				inputs[tabIndex].focus();
			}
		}, 200);
	}, [inputs, selectByDate, selectByTime, tabIndex]);

	return (
		<Root ref={scheduleModalRef}>
			<Header onClick={onClose} />
			<CustomStyledRow
				align="flex-start"
				style={{ padding: 16, height: 97 }}
			>
				<RadioButton.RadioButton
					selected={scheduleTypeBoofer === "time"}
					onClick={selectByTime}
				/>
				<Column gaps="16px">
					<div>
						{t("orderPageWidgets.chat.scheduleModal.str100") ?? ""}
					</div>
					<CustomStyledRow gap="20px">
						<CustomStyledRow gap="10px" align="center">
							<Stepper
								value={scheduleHoursBoofer}
								onChange={onChangeHours}
								min={0}
								max={24}
							/>
							<div>
								{t(
									"orderPageWidgets.chat.scheduleModal.str101",
								) ?? ""}
							</div>
						</CustomStyledRow>
						<CustomStyledRow gap="10px" align="center">
							<Stepper
								value={scheduleMinutesBoofer}
								onChange={onChangeMinutes}
								min={0}
								max={60}
							/>
							<div>
								{t(
									"orderPageWidgets.chat.scheduleModal.str102",
								) ?? ""}
							</div>
						</CustomStyledRow>
					</CustomStyledRow>
				</Column>
			</CustomStyledRow>
			<CustomStyledRow
				align="flex-start"
				style={{ padding: 16, height: 95 }}
			>
				<RadioButton.RadioButton
					selected={scheduleTypeBoofer === "date"}
					onClick={selectByDate}
				/>
				<Column gaps="16px">
					<div>
						{t("orderPageWidgets.chat.scheduleModal.str103") ?? ""}
					</div>
					<CustomStyledRow gap="20px">
						<CustomStyledRow gap="10px">
							<CustomDatePicker
								iconColor={theme.colors.secondary}
								placeholder={
									t(
										"orderPageWidgets.chat.scheduleModal.str0",
									) ?? ""
								}
								locale={locale}
								onChange={onChangeDate}
								value={scheduleDateBoofer}
							/>
						</CustomStyledRow>
						<CustomStyledRow
							gap="10px"
							style={{ position: "relative" }}
						>
							<CustomTimeInput
								disabled={false}
								value={scheduleTimeBoofer}
								onChange={onChangeTime}
							/>
							<TimeButton
								disabled={false}
								onClick={openClockModal}
							>
								<Icon
									id="chat-schedule"
									size={16}
									colors={[theme.colors.secondary]}
								/>
							</TimeButton>
						</CustomStyledRow>
					</CustomStyledRow>
				</Column>
			</CustomStyledRow>
			<CustomStyledRow
				justify="flex-end"
				gap="10px"
				style={{ padding: "12px 16px" }}
			>
				<Button.Button
					text={t("orderPageWidgets.chat.scheduleModal.str1") ?? ""}
					variant="secondary"
					onClick={onClose}
					style={{ width: 90 }}
				/>
				<Button.Button
					text={t("orderPageWidgets.chat.scheduleModal.str2") ?? ""}
					onClick={submitData}
					style={{ width: 98 }}
				/>
			</CustomStyledRow>
		</Root>
	);
};

declare namespace ScheduleModal {
	interface Props {
		onClose: () => void;
		onOpenClock: () => void;
		onSubmit: (data: Orders.Chat.ScheduleRequestData) => void;
		scheduleTimeBoofer: TimeInput.Value;
		onChangeScheduleTimeBoofer: React.Dispatch<
			React.SetStateAction<TimeInput.Value>
		>;
	}
}

export default ScheduleModal;
