import React, { memo } from "react";
import { Column, react } from "uikit";
import BranchesFilter from "../../../../../BranchesFilter";

const ContentBase = react.inputify<Content.Props>(() => (
	<Column gaps="12px*" style={{ flexGrow: 1 }}>
		<BranchesFilter />
	</Column>
));
const Content = memo(ContentBase);

declare namespace Content {
	interface Props {}
}

export default Content;
