import React, { RefAttributes, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextBox, react } from "uikit";

import LabeledField from "../../LabeledField";

import InternalController from "./Controller";

const NameBase = react.withController<NameBase.PropsBase, NameBase.Controller>(
	({
		name,
		setName,
		disabled = false,
		placeholder = "",
		label = "",
		controller,
	}) => {
		const { t } = useTranslation();

		const [error, setError] = useState(false);

		const updateNameHandler = useCallback(
			(newName: string) => {
				setError(false);
				setName(newName);
			},
			[setName],
		);

		controller.setContext({ name, setError });
		return (
			<LabeledField label={t(label) || ""}>
				<TextBox.TextBox
					disabled={disabled}
					error={error}
					placeholder={t(placeholder) || ""}
					value={name}
					onChange={updateNameHandler}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace NameBase {
	type Value = string;

	interface PropsBase {
		name: Value;
		setName: (name: Value) => void;
		disabled?: boolean;
		label?: string[] | string;
		placeholder?: string[] | string;
	}

	type Ref = InternalController | null;
	type Controller = InternalController;
	type Props = PropsBase & RefAttributes<Ref>;
}

export const Name = memo(NameBase);
declare namespace Name {
	interface PropsBase extends NameBase.PropsBase {}

	type Ref = NameBase.Ref;
	type Controller = NameBase.Controller;
	type Props = PropsBase & RefAttributes<Ref>;
}

export default NameBase;
