import styled from "styled-components";
import { Row } from "uikit";

const Item = styled(Row)<Item.Props>`
	box-sizing: content-box;

	min-height: 32px;

	padding: 4px 7px;

	user-select: none;

	cursor: pointer;

	&:not(:first-child) {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	${({ hovered }) => (hovered ? "&, " : "")} &:hover {
		background-color: #ebebeb;
	}
`;

declare namespace Item {
	interface Props {
		hovered?: boolean;
	}
}

export default Item;
