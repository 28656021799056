import React, { Fragment } from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { StyledRow } from "../../../../../../../../../../common";

const Root = styled(StyledRow)`
	height: 18px;
	font-family: Lato;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	color: ${theme.colors.accent};
`;

const Time = styled.span<{ alignRight?: boolean }>`
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: ${(props) => (props.alignRight ? "right" : "left")};
	color: ${theme.colors.secondary};
	flex: 1;
`;

const TitleInfo = styled.span`
	font-size: 14px;
`;

const Title: React.FC<Title.Props> = ({ data }) => {
	const { title, time, isCurrentUser } = data;
	return (
		<Root gap="8px">
			{isCurrentUser ? (
				<Time alignRight>{time}</Time>
			) : (
				<Fragment>
					<TitleInfo>{title}</TitleInfo>
					<Time>{time}</Time>
				</Fragment>
			)}
		</Root>
	);
};

declare namespace Title {
	interface Props {
		data: {
			isCurrentUser: boolean;
			title?: string;
			time?: string | null;
		};
	}
}

export default Title;
