import makeTable from "./makeTable";

const costomerCounterparty = makeTable({
	allIds: [
		"name",
		"edrpou",
		"counterpartyGroupName",
		"contactName",
		"phones",
		"email",
		"orderCount",
		"overallBalance",
		"overallBonusBalance",
		"disconnectionThreshold",
		"accountsBalance",
		"accountsBonusBalance",
		"accountsDisconnectionThreshold",
		"active",
		"company",
		"registrationBranch",
	],
	defaultIds: [
		"name",
		"edrpou",
		"counterpartyGroupName",
		"contactName",
		"phones",
		"email",
		"orderCount",
		"overallBalance",
		"accountsBalance",
		"active",
		"company",
		"registrationBranch",
	],
	i18tPath: (id) => `tableColumns.customers.counterparties.${id}`,
});

export default costomerCounterparty;
