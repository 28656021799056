import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	border-radius: 5px;

	background: ${theme.colors.white};

	box-shadow: 0px 9px 24px 0px rgba(0, 0, 0, 0.15);

	pointer-events: auto;
	overflow: hidden;
`;
export default Root;
