import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isArray, isNumber } from "lodash";
import { Column } from "uikit";

import { Language } from "../../../../../../services";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import { preferences } from "../../../../../../redux/reducers";
import createService from "../../../../../../redux/services/Preferences/Services/createService";
import deleteService from "../../../../../../redux/services/Preferences/Services/deleteService";
import returnServices from "../../../../../../redux/services/Preferences/Services/returnServices";
import updateService from "../../../../../../redux/services/Preferences/Services/updateService";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../hooks/useTableSettings";
import TableSettings from "../../../../../../components/TableSettings";
import DeleteModal from "../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../components/LightTable";
import tables from "../../../../../../constants/tables";

import Content from "./components/Content";
import Header from "./components/Header";
import ServicesModal from "./components/Modal";

const Services: React.FC = () => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const { editor, onChange, sort, limit } = useTableOptions();

	const { services } = useTypedSelector(
		(state) => state.preferencesReducer.services.data,
	);

	const columns = useColumns("service");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"preferences.services",
		"service",
	);

	const [showDeleteModal, setShowDeleteModal] = useState<boolean | number[]>(
		false,
	);
	const [filterWithActive, setActiveFilter] = useState<boolean>(false);

	const filteredDataWithActive = filterWithActive
		? services.filter((model) => model.active)
		: services;

	const [showModal, setModalStatus] = useState<boolean | number>(false);
	const [data, setData] = useState(filteredDataWithActive);
	const [selected, setSelected] = useState<number[]>([]);
	const [languageFilter, setLanguageFilter] = useState<Language>("uk");

	const [filter, setFilter] = useState({
		companyIds: [-1],
		taxiServices: [],
		allCompanies: [],
		allTaxiServices: [],
	});

	useEffect(() => {
		setData(filteredDataWithActive);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languageFilter, services, filterWithActive]);

	useEffect(() => {
		const promise: any = dispatch(
			returnServices({
				sortColumn: sort.dataKey,
				sortType: sort.sortType,
				limit,
				taxiServiceIds:
					filter.taxiServices.length && filter.taxiServices[0] !== -1
						? filter.taxiServices
						: filter.allTaxiServices,
			}),
		);

		promise.then((result) => {
			dispatch(preferences.services.data.setServices(result));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, sort, limit]);

	return (
		<Column sizes="auto 1fr" maxedWidth maxedHeight>
			<Header
				languageFilter={languageFilter}
				setLanguageFilter={setLanguageFilter}
				filterWithActive={filterWithActive}
				filter={filter}
				setFilter={setFilter}
				onDelete={() => setShowDeleteModal(selected)}
				onEdit={() => setModalStatus(selected[selected.length - 1])}
				notSelected={Boolean(selected.length)}
				setFilterWithActive={setActiveFilter}
				handleOpenModal={() => setModalStatus(true)}
			/>

			<Content
				columns={columnIds}
				onDelete={(id) => setShowDeleteModal(id)}
				onEdit={(value) => setModalStatus(value)}
				languageFilter={languageFilter}
				data={data || []}
				selected={selected}
				onChangeSelected={setSelected}
				editorTable={editor}
				onChangeTable={onChange}
			/>

			<TableSettings
				value={columnIds}
				onChange={setColumnIds}
				columns={columns}
				defaultValue={tables.service.defaultIds}
			/>

			{showModal && (
				<ServicesModal
					companyIds={
						filter.companyIds[0] === -1
							? filter.allCompanies
							: filter.companyIds
					}
					taxiServices={
						filter.taxiServices.length
							? filter.taxiServices
							: filter.allTaxiServices
					}
					data={
						!isNumber(showModal)
							? showModal
							: data.find((item) => item.id === showModal)
					}
					onCancel={() => setModalStatus(false)}
					onSave={(values) => {
						if (values.id) {
							const prepareData = {
								id: values.id,
								name: values.fields,
								active: values.active,
								availableForExecutor: values.executor,
								availableForCar: values.transport,
								taxiServiceIds: values.taxiServices,
							};
							const promise: any = dispatch(
								updateService(prepareData),
							);
							promise.then((result) => {
								dispatch(
									preferences.services.data.setServices(
										data.map((item) =>
											item.id === result.id
												? result
												: item,
										),
									),
								);
							});
						} else {
							const prepareData = {
								name: values.fields,
								active: values.active,
								availableForExecutor: values.executor,
								availableForCar: values.transport,
								taxiServiceIds: values.taxiServices,
							};

							const promise: any = dispatch(
								createService(prepareData),
							);
							promise.then((result) => {
								dispatch(
									preferences.services.data.setServices([
										result,
										...data,
									]),
								);
							});
						}
						setModalStatus(false);
					}}
				/>
			)}

			{showDeleteModal && (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.services.str200",
						) ?? ""
					}
					onCancel={() => setShowDeleteModal(false)}
					onConfirm={() => {
						if (isArray(showDeleteModal)) {
							selected.forEach((item) => {
								dispatch(deleteService(item));
							});
						} else {
							dispatch(deleteService(showDeleteModal));
						}
						setSelected([]);
						setShowDeleteModal(false);
					}}
				/>
			)}
		</Column>
	);
};

export default Services;
