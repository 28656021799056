import React from "react";

import ExecutorLateCellContent from "../components/ExecutorLateCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function executorLate({ width, onResize }: Column.Options) {
	return base(
		ColumnId.ExecutorLate,
		(item) => <ExecutorLateCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default executorLate;
