import React, { useLayoutEffect } from "react";

import { CarClass, Service, TaxiService } from "../../services";
import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import { preferences } from "../../redux/reducers";
import useModelSubscribe from "../../hooks/useModelSubscribe2";
import taxiServices from "../../redux/reducers/taxiServices";

const BaseModelsLoader: React.FC<BaseModelsLoader.Props> = () => {
	const dispatch = useTypedDispatch();
	const connected = useTypedSelector((state) => state.prpc.connected);
	const authorized = useTypedSelector((state) => state.prpc.authorized);

	const subscribeTaxiService = useModelSubscribe({}, TaxiService);
	const subscribeCarClass = useModelSubscribe({}, CarClass);
	const subscribeService = useModelSubscribe({}, Service);

	useLayoutEffect(() => {
		if (connected && authorized) {
			dispatch(
				preferences.services.data.setModels(subscribeService.models),
			);
		} else {
			dispatch(preferences.services.data.setModels([]));
		}
	}, [authorized, connected, dispatch, subscribeService.models]);

	useLayoutEffect(() => {
		if (connected && authorized) {
			dispatch(
				preferences.carClasses.data.setModels(subscribeCarClass.models),
			);
		} else {
			dispatch(preferences.carClasses.data.setModels([]));
		}
	}, [authorized, connected, dispatch, subscribeCarClass.models]);

	useLayoutEffect(() => {
		if (connected && authorized) {
			dispatch(
				taxiServices.actions.setModels(subscribeTaxiService.models),
			);
		} else {
			dispatch(taxiServices.actions.setModels([]));
		}
	}, [authorized, connected, dispatch, subscribeTaxiService.models]);

	return <></>;
};

declare namespace BaseModelsLoader {
	interface Props {}
}

export default BaseModelsLoader;
