import makeTable from "./makeTable";

const accountsExecutors = makeTable({
	allIds: [
		"fullName",
		"alias",
		"parkNumber",
		"phones",
		"balance",
		"bonusBalance",
		"role",
		"company",
		"taxiService",
		"registeredAt",
		"responsibleDispatcher",
		"notes",
	],
	i18tPath: (id) => `mainPage.accounts.executors.columns.${id}`,
	defaultIds: [
		"fullName",
		"alias",
		"parkNumber",
		"phones",
		"balance",
		"bonusBalance",
		"role",
		"company",
		"taxiService",
		"registeredAt",
		"responsibleDispatcher",
		"notes",
	],
	initialSortMethod: {
		column: "alias",
		type: "asc",
	},
	initialWidths: {
		fullName: 137,
		alias: 100,
		parkNumber: 100,
		phones: 150,
		balance: 110,
		bonusBalance: 130,
		role: 140,
		company: 120,
		taxiService: 120,
		registeredAt: 150,
		responsibleDispatcher: 137,
		notes: 137,
	},
	initialResizable: {
		fullName: true,
		alias: true,
		parkNumber: true,
		phones: true,
		balance: true,
		bonusBalance: true,
		role: true,
		company: true,
		taxiService: true,
		registeredAt: true,
		responsibleDispatcher: true,
		notes: true,
	},
	initialSortable: {
		fullName: true,
		alias: true,
		parkNumber: true,
		phones: true,
		balance: true,
		bonusBalance: true,
		role: true,
		company: true,
		taxiService: true,
		registeredAt: true,
		responsibleDispatcher: true,
		notes: true,
	},
});

export default accountsExecutors;
