import styled from "styled-components";
import { theme } from "uikit";

import Button from "../Button";

const ToggleButton = styled(Button)<ToggleButton.Props>`
	--icon-color-1: ${({ active }) =>
		active ? theme.colors.secondary : "#D7DADC"};

	background: ${({ active }) =>
		active ? theme.colors.button_secondary : theme.colors.white};

	border: ${({ active }) =>
		active ? "none" : "1px solid rgba(0, 0, 0, 0.10)"};
`;

declare namespace ToggleButton {
	interface Props {
		active: boolean;
	}
}

export default ToggleButton;
