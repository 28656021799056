import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	StyledSpan,
	ValueStepper,
	ImprovedStepper,
} from "../../../../../../../../components/common";
import { useCurrencyGlobalSettings } from "../../../../../../../../hooks";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
} from "../../../../constants/constants";
import tPath from "../../../../constants/tPath";
import SurchargeSelect from "../../../SurchareSelect";
import BinaryAdjustmentSelect from "../../../BinaryAdjustmentSelect";

const Item: React.FC<Item.Props> = memo(
	({ disabled, label, value, onChange }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const valueEditor = useObjectEditor(value, onChange);

		const checkBox = valueEditor.useGetter("active");
		const setCheckBox = valueEditor.useSetter("active");

		const stepperValue = valueEditor.useGetter("value");
		const setStepperValue = valueEditor.useSetter("value");

		const freeMinutes = valueEditor.useGetter("freeMinutes");
		const setFreeMinutes = valueEditor.useSetter("freeMinutes");

		const adjustments = valueEditor.useGetter("adjustments");
		const setAdjustments = valueEditor.useSetter("adjustments");
		const adjustmentsEditor = useObjectEditor(adjustments, setAdjustments);
		const adjustmentsValue = adjustmentsEditor.useGetter("value");
		const setAdjustmentsValue = adjustmentsEditor.useSetter("value");
		const adjustmentsFreeMinutes =
			adjustmentsEditor.useGetter("freeMinutes");
		const setAdjustmentsFreeMinutes =
			adjustmentsEditor.useSetter("freeMinutes");

		useEffect(() => {
			if (
				adjustmentsValue === RateAdjustmentToDefault.CUSTOM_VALUE &&
				stepperValue < 0
			) {
				setStepperValue(0);
			}
		}, [stepperValue, adjustmentsValue, setStepperValue]);

		return (
			<StyledRow gap="10px" alignItems="center">
				<StyledRow flex={{ grow: 1 }}>
					<CheckBox
						label={
							t([
								`${tPath}.mainModal.tabs.main.waiting.${label}`,
								"",
							]) || ""
						}
						value={checkBox}
						onChange={setCheckBox}
					/>
				</StyledRow>
				<SurchargeSelect
					disabled={disabled}
					value={adjustmentsValue}
					onChange={setAdjustmentsValue}
				/>
				<StyledRow gap="8px" alignItems="center" w="125px">
					<ValueStepper
						min={
							adjustmentsValue ===
							RateAdjustmentToDefault.CUSTOM_VALUE
								? 0
								: undefined
						}
						value={stepperValue}
						onChange={setStepperValue}
					/>
					{adjustmentsValue !==
						RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE && (
						<StyledSpan>{currencyGlobalSettings}</StyledSpan>
					)}
				</StyledRow>
				<StyledSpan>
					{t([
						`${tPath}.mainModal.tabs.main.waiting.includeTime`,
						"",
					])}
				</StyledSpan>
				<BinaryAdjustmentSelect
					disabled={disabled}
					value={adjustmentsFreeMinutes}
					onChange={setAdjustmentsFreeMinutes}
				/>
				<ImprovedStepper
					disabled={
						adjustmentsFreeMinutes ===
						RateBinaryAdjustmentToDefault.DEFAULT_VALUE
					}
					value={freeMinutes}
					onChange={(val) => setFreeMinutes(val as number)}
				/>
				<StyledSpan>
					{t([`${tPath}.mainModal.tabs.main.waiting.minutes`, ""])}
				</StyledSpan>
			</StyledRow>
		);
	},
);

declare namespace Item {
	interface Props {
		disabled?: boolean;
		label: string;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
		freeMinutes: number;
		adjustments: {
			value: RateAdjustmentToDefault;
			freeMinutes: RateBinaryAdjustmentToDefault;
		};
	}
}

export default Item;
