/* eslint-disable no-shadow */
import { assign as assignBase, clone, isArray, isObject } from "lodash";
import objectHash from "object-hash";
import { Dispatch, useCallback, useMemo, useRef } from "react";
import { useChanged, useRender } from "uikit";

import OptionalKeys from "../types/OptionalKeys";
import SpecificKeys from "../types/SpecificKeys";

function useObjectEditor<Value>(value: Value, onChange: Dispatch<Value>) {
	type Item = Value extends any[] ? Value[number] : never;

	const render = useRender();

	const currentValueRef = useRef(value);

	useChanged(() => {
		currentValueRef.current = value;
	}, value);

	const assign = useCallback(
		<Fields extends keyof Value>(
			fields: Pick<Value, Fields> | Partial<Value>,
		) => {
			currentValueRef.current = assignBase(
				clone(currentValueRef.current),
				fields,
			);

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const set = useCallback(
		<Field extends keyof Value>(field: Field, fieldValue: Value[Field]) => {
			assign({ [field]: fieldValue } as Pick<Value, Field>);
		},
		[assign],
	);

	const get = useCallback(
		<Field extends keyof Value>(field: Field) =>
			currentValueRef.current[field],
		[],
	);

	const pick = useCallback(
		<Field extends keyof Value>(fields: Field[]) =>
			fields.reduce((accumulator, field) => {
				accumulator[field] = currentValueRef.current[field];

				return accumulator;
			}, {} as Pick<Value, Field>),
		[],
	);

	const includes = useCallback((value: Value[keyof Value]) => {
		if (!isObject(currentValueRef.current)) return false;

		if (isArray(currentValueRef.current))
			return currentValueRef.current.includes(value);

		return !!Object.entries(
			currentValueRef.current as Record<keyof Value, Value[keyof Value]>,
		).find(([, entryValue]) => entryValue === value);
	}, []);

	const toggle = useCallback(
		<Field extends SpecificKeys<Value, boolean>>(field: Field) => {
			set(
				field as keyof Value,
				!get(field as keyof Value) as Value[keyof Value],
			);
		},
		[get, set],
	);

	const push = useCallback(
		(item: Item) => {
			currentValueRef.current = clone(currentValueRef.current);

			(currentValueRef.current as Item[]).push(item);

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const pushUniq = useCallback(
		(item: Item) => {
			currentValueRef.current = clone(currentValueRef.current);

			if ((currentValueRef.current as Item[]).includes(item)) return;

			(currentValueRef.current as Item[]).push(item);

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const concat = useCallback(
		(...arrays: Item[][]) => {
			currentValueRef.current = (
				currentValueRef.current as Item[]
			).concat(...arrays) as Value;

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const concatUnique = useCallback(
		(...arrays: Item[][]) => {
			currentValueRef.current = (
				currentValueRef.current as Item[]
			).concat(
				...arrays.map((array) =>
					array.filter(
						(item) => !includes(item as Value[keyof Value]),
					),
				),
			) as Value;

			onChange(currentValueRef.current);

			render(true);
		},
		[includes, onChange, render],
	);

	const unshift = useCallback(
		(item: Item) => {
			currentValueRef.current = clone(currentValueRef.current);

			(currentValueRef.current as Item[]).unshift(item);

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const shift = useCallback(() => {
		currentValueRef.current = clone(currentValueRef.current);

		const item = (currentValueRef.current as Item[]).shift();

		onChange(currentValueRef.current);

		render(true);

		return item;
	}, [onChange, render]);

	const pop = useCallback(() => {
		currentValueRef.current = clone(currentValueRef.current);

		const item = (currentValueRef.current as Item[]).pop();

		onChange(currentValueRef.current);

		render(true);

		return item;
	}, [onChange, render]);

	const remove = useCallback(
		(key: OptionalKeys<Value>) => {
			currentValueRef.current = clone(currentValueRef.current);

			if (!Array.isArray(currentValueRef.current)) {
				delete currentValueRef.current[key as keyof Value];

				onChange(currentValueRef.current);

				return;
			}

			currentValueRef.current.splice(key as number, 1);

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const removeByValue = useCallback(
		(value: Value[keyof Value]) => {
			if (!isObject(currentValueRef.current)) return;

			const key = Object.entries(
				currentValueRef.current as Record<
					keyof Value,
					Value[keyof Value]
				>,
			).find(([, entryValue]) => entryValue === value)?.[0];

			if (!key) return;

			currentValueRef.current = clone(currentValueRef.current);

			if (isArray(currentValueRef.current))
				(currentValueRef.current as any[]).splice(Number(key), 1);
			else delete currentValueRef.current[key];

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const removeByValues = useCallback(
		(values: Value[keyof Value][]) => {
			if (!isObject(currentValueRef.current)) return;

			values.forEach((value) => {
				const key = Object.entries(
					currentValueRef.current as Record<
						keyof Value,
						Value[keyof Value]
					>,
				).find(([, entryValue]) => entryValue === value)?.[0];

				if (!key) return;

				currentValueRef.current = clone(currentValueRef.current);

				if (isArray(currentValueRef.current))
					(currentValueRef.current as any[]).splice(Number(key), 1);
				else delete currentValueRef.current[key];
			});

			onChange(currentValueRef.current);

			render(true);
		},
		[onChange, render],
	);

	const clear = useCallback(() => {
		if (typeof currentValueRef.current !== "object") return;

		if (isArray(currentValueRef.current))
			currentValueRef.current = [] as Value;
		else if (isObject(currentValueRef.current))
			currentValueRef.current = {} as Value;

		onChange(currentValueRef.current);

		render(true);
	}, [onChange, render]);

	const setter = useCallback(
		<Key extends keyof Value>(key: Key) =>
			(value: Value[Key]) =>
				set(key, value),
		[set],
	);

	const useSetter = useCallback(
		<Key extends keyof Value>(key: Key) =>
			// eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
			useCallback(setter(key), [setter, key]),
		[setter],
	);

	const useAssigner = useCallback(
		() =>
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				<Fields extends keyof Value>(
					fields: Pick<Value, Fields> | Partial<Value>,
				) => assign(fields),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[assign],
			),
		[assign],
	);

	const useGetter = useCallback(
		<Field extends keyof Value>(field: Field) =>
			// eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
			useMemo(() => get(field), [currentValueRef.current, get, field]),
		[get],
	);

	const useToggler = useCallback(
		<Field extends SpecificKeys<Value, boolean>>(field: Field) =>
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(() => toggle(field), [field]),
		[toggle],
	);

	const usePicker = useCallback(
		<Field extends keyof Value>(
			fields: Field[],
			depsCheck: "hash" | "json" = "hash",
		) =>
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() => pick(fields),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[
					// eslint-disable-next-line react-hooks/exhaustive-deps
					depsCheck === "json"
						? JSON.stringify(pick(fields))
						: objectHash(pick(fields), {
								replacer: (value) => {
									if (
										isObject(value) &&
										"forHash" in value &&
										typeof value.forHash === "function"
									)
										return value.forHash();

									return value instanceof Promise
										? "promise"
										: value;
								},
						  }),
				],
			),
		[pick],
	);

	const useProperty = useCallback(
		// eslint-disable-next-line react-hooks/rules-of-hooks
		<Key extends keyof Value>(key: Key) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const value = useGetter(key);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const setValue = useSetter(key);

			return [value, setValue] as [typeof value, typeof setValue];
		},
		[useGetter, useSetter],
	);

	const usePropertyEditor = useCallback(
		// eslint-disable-next-line react-hooks/rules-of-hooks
		<Key extends keyof Value>(key: Key) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const [value, setValue] = useProperty(key);

			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useObjectEditor(value, setValue);
		},
		[useProperty],
	);

	const editor = useMemo(() => {
		const result = {
			assign,
			set,
			get,
			pick,

			includes,

			concat,
			concatUnique,
			push,
			pushUniq,
			unshift,
			shift,
			pop,
			remove,
			removeByValue,
			removeByValues,
			clear,

			setter,

			useSetter,
			useAssigner,
			useGetter,
			useToggler,
			usePicker,

			useProperty,
			usePropertyEditor,
		};

		Object.defineProperty(result, "value", {
			get: () => currentValueRef.current,
			set: (value: Value) => {
				currentValueRef.current = value;

				onChange(currentValueRef.current);

				render(true);
			},
		});

		return result as typeof result & {
			value: Value;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentValueRef.current,
		assign,
		get,
		pick,
		pop,
		push,
		pushUniq,
		remove,
		set,
		shift,
		unshift,
	]);

	return editor;
}

export default useObjectEditor;
