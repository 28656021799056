import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import Transaction from "../../../../../../../../../../services/Transaction";
import { StyledP } from "../../../../../../../../../../components/common";

const Type: React.FC<Type.Props> = memo(({ value }) => {
	const { t } = useTranslation();

	const { metadata } = value;

	return (
		<StyledP>
			{metadata.context ? t(`transactionType.${metadata.context}`) : ""}
		</StyledP>
	);
});

declare namespace Type {
	interface Props {
		value: Value;
	}

	interface Value {
		metadata: Transaction.Model["metadata"];
	}
}

export default Type;
