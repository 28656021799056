import Color from "color";
import styled, { css } from "styled-components";
import { theme } from "uikit";

const buttonSecondaryDefaultIconColor = Color(theme.colors.primary)
	.alpha(0.8)
	.rgb()
	.string();

const variants = {
	primary: css<Button.Variant.Props>`
		background: ${theme.colors.accent};

		--icon-color-1: ${theme.colors.white};

		&:hover {
			&::before {
				content: "";

				position: absolute;

				width: 100%;
				height: 100%;

				background: rgba(0, 0, 0, 0.05);
			}
		}
	`,
	secondary: css<Button.Variant.Props>`
		background: ${theme.colors.button_secondary};

		border: 1px solid #21333f1a;

		--icon-color-1: ${buttonSecondaryDefaultIconColor};

		&:hover {
			background: #e5e5e5;

			--icon-color-1: ${theme.colors.primary};
		}

		&:disabled {
			background: ${theme.colors.button_secondary};

			border: none;

			--icon-color-1: #b5c0c7;
		}
	`,
};

const Button = styled.button<Button.Props>`
	all: unset;

	box-sizing: border-box;

	position: relative;

	display: flex;

	align-items: center;
	justify-content: center;

	width: 32px;
	height: 32px;

	border-radius: 5px;

	cursor: pointer;

	overflow: hidden;

	${({ variant }) => variants[variant]}
`;

declare namespace Button {
	type Variant = keyof typeof variants;

	interface Props extends Variant.Props {
		variant: Variant;
	}

	namespace Variant {
		interface Props {
			active?: boolean;
		}
	}
}

export default Button;
