import makeTable from "./makeTable";

const role = makeTable({
	allIds: ["active", "name", "assigns", "description", "createdAt"],
	i18tPath: (id) => `tableColumns.accounts.roles.${id}`,
	defaultIds: ["active", "name", "assigns", "description", "createdAt"],
	initialSortMethod: {
		column: "assigns",
		type: "asc",
	},
	initialWidths: {
		active: 120,
		name: 200,
		assigns: 130,
		description: 390,
		createdAt: 150,
	},
	initialResizable: {
		active: true,
		name: true,
		assigns: true,
		description: true,
		createdAt: true,
	},
	initialSortable: {
		active: true,
		name: true,
		assigns: true,
		description: true,
		createdAt: true,
	},
});

export default role;
