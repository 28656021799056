import React, { Dispatch, useMemo, useRef } from "react";
import { SortType } from "rsuite-table";
import "rsuite/dist/rsuite.min.css";

import Company from "../../../../../../../../services/Company";
import TaxiService from "../../../../../../../../services/TaxiService";
import Map from "../../../../../../../../redux/services/Map";
import { Language } from "../../../../../../../../assets/languages/langs";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../hooks/useTableSettings";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe";
import TableSettings from "../../../../../../../../components/TableSettings";
import tables from "../../../../../../../../constants/tables";
import {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";

import Root from "./components/Root";
import ObjectTable from "./components/ObjectTable";
import ObjectGroupTable from "./components/ObjectGroupTable";

const Content: React.FC<Content.Props> = ({
	type,

	loading,
	language,
	data,

	selected,
	onChangeSelected,

	onEdit,
	onLoadMore,

	onChangeTable,
	editorTable,
}) => {
	const { sort, onSort, tableRef, onKeyUp, onKeyDown, ctrlPressed } =
		useTableOptions({
			value: editorTable,
			setValue: onChangeTable,
		});

	const companies = useModelSubscribe({}, Company)?.cache;
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const columns = useColumns(type === "objects" ? "object" : "objectGroup");

	const { columnIds: objectColumnIds, setColumnIds: setObjectColumnIds } =
		useVisibleColumns("preferences.object", "object");
	const {
		columnIds: objectGroupColumnIds,
		setColumnIds: setObjectGroupColumnIds,
	} = useVisibleColumns("preferences.objectGroup", "objectGroup");

	const canLoadMoreRef = useRef(true);
	const tableContainerRef = useRef<HTMLDivElement | null>(null);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableContainerRef.current?.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const tableProps = useMemo(
		() => ({
			ref: tableRef,
			loading,
			data,
			headerHeight: 44,
			rowHeight: 44,
			fillHeight: true,
			virtualized: true,
			sortColumn: sort?.dataKey,
			sortType: sort?.sortType,
			onSortColumn: onSort,
			onRowClick: (rowData, force = false) => {
				if (ctrlPressed || force) {
					if (selected.includes(rowData.id)) {
						onChangeSelected(
							selected.filter((id) => rowData.id !== id),
						);
					} else {
						onChangeSelected([...selected, rowData.id]);
					}
				} else {
					selected.includes(rowData.id)
						? onChangeSelected([])
						: onChangeSelected([rowData.id]);
				}
			},
			onRowDoubleClick: (rowData) => {
				onEdit(rowData.id);
			},
			onScroll: tableOnScroll,
			rowClassName: (rowData) =>
				[
					rowData && selected.includes(rowData.id) ? "selected" : "",
					rowData && rowData.status ? "" : "not-active",
				].join(" "),
			shouldUpdateScroll: false,
		}),
		[
			tableRef,
			loading,
			data,
			sort?.dataKey,
			sort?.sortType,
			onSort,
			tableOnScroll,
			ctrlPressed,
			selected,
			onChangeSelected,
			onEdit,
		],
	);

	const tableModeProps = useMemo(
		() => ({
			selected,
			language,
			taxiServices,
			companies,
			onChangeSelected,
		}),
		[companies, language, onChangeSelected, selected, taxiServices],
	);

	return (
		<Root sizes="1fr auto!">
			{type === "objects" ? (
				<>
					<div
						ref={tableContainerRef}
						onKeyDown={onKeyDown}
						onKeyUp={onKeyUp}
					>
						<ObjectTable
							columns={objectColumnIds}
							{...tableProps}
							{...tableModeProps}
							data={data}
						/>
					</div>

					<TableSettings
						value={objectColumnIds}
						onChange={setObjectColumnIds}
						columns={columns}
						defaultValue={tables.object.defaultIds}
					/>
				</>
			) : (
				<>
					<div
						ref={tableContainerRef}
						onKeyDown={onKeyDown}
						onKeyUp={onKeyUp}
					>
						<ObjectGroupTable
							columns={objectGroupColumnIds}
							{...tableProps}
							{...tableModeProps}
							data={data}
						/>
					</div>

					<TableSettings
						value={objectGroupColumnIds}
						onChange={setObjectGroupColumnIds}
						columns={columns}
						defaultValue={tables.objectGroup.defaultIds}
					/>
				</>
			)}
		</Root>
	);
};

declare namespace Content {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface PropsBase {
		loading: boolean;
		language: Language;

		selected: number[];
		onChangeSelected: Dispatch<number[]>;

		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}

	interface ObjectProps extends PropsBase {
		type: "objects";

		data: Map.LocalObject[];
	}

	interface ObjectGroupProps extends PropsBase {
		type: "object-groups";

		data: Map.LocalObjectGroup[];
	}

	type Props = ObjectProps | ObjectGroupProps;
}

export default Content;
