import React, { useMemo } from "react";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
import DriverCall from "../../../../../OrderModal/components/Footer/components/DriverCall";

const CallExecutorButton: React.FC<CallExecutorButton.Props> = () => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.CALL_ORDER_EXECUTOR;

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const executorPhones = useMemo<string[]>(() => {
		const executorToOrder = activeOrder?.executorToOrder?.at(0);
		if (!executorToOrder) return [];
		const { executor } = executorToOrder;
		return executor?.person?.phones?.map((phone) => phone.number) || [];
	}, [activeOrder?.executorToOrder]);

	const executorPhone = useMemo<string>(() => {
		if (!executorPhones.length) return "";
		return executorPhones?.[0] || "";
	}, [executorPhones]);

	const disabled = useMemo(() => {
		if (!activeOrder?.id) return true;
		if (!sipToDispatcherId) return true;
		if (!activeOrder.passengers?.length) return true;
		if (activeOrder.status === "closed") return true;
		if (!executorPhone) return true;

		const hasExecutor = activeOrder.executorToOrder.length;

		return !hasExecutor;
	}, [sipToDispatcherId, activeOrder, executorPhone]);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<DriverCall
			disabled={disabled}
			mainPhone={executorPhone}
			phones={executorPhones}
			showOnlyModal
			settingsKeyId="orderModalCallOrderExecutor"
			leftButtonStyled={{
				w: "28px",
				h: "28px",
			}}
			rightButtonStyled={{
				w: "17px",
				h: "28px",
			}}
		/>
	);
};

declare namespace CallExecutorButton {
	interface Props {}
}

export default CallExecutorButton;
