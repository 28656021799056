import React, {
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { isBoolean } from "lodash";
import { TextBox, react } from "uikit";

import Style, { StyledGrid, StyledGridItem, StyledP } from "../styles";

import InternalController from "./Controller";

const TextWithInputBase = react.withController<
	TextWithInputBase.PropsBase,
	TextWithInputBase.Controller
>(
	({
		value = "",
		setValue,
		title = "",
		placeholder = "",
		disabled = false,
		require = false,
		controller,
		setError,
		error,
		titleStyled,
		textBoxSetting,
		...props
	}) => {
		const { t } = useTranslation();

		const [internalError, setInternalError] = useState<boolean>(false);

		const handleError = useCallback(
			(err: boolean) => {
				if (setError) setError(require ? err : false);
				else setInternalError(require ? err : false);
			},
			[setError, require],
		);

		const isError = useMemo(() => {
			if (!require) return false;
			if (isBoolean(error)) return error;
			return internalError;
		}, [error, internalError, require]);

		const handleValue = useCallback(
			(text: string) => {
				handleError(false);
				setValue?.(text);
			},
			[handleError, setValue],
		);

		const translateText = useMemo(
			() => ({
				title: t(title) || "",
				placeholder: t(placeholder) || "",
			}),
			[placeholder, t, title],
		);

		controller.setContext({ value, handleError, require });
		return (
			<StyledGrid
				areas="'title textBox'"
				alignItems="center"
				gap="10px"
				columns="auto 1fr"
				{...props}
			>
				<StyledGridItem area="title" gap="10px">
					<StyledP
						text={{ tt: "uppercase", ls: "0.1em" }}
						font={{ line: "18px", size: "13px", fw: 400 }}
						{...titleStyled}
					>
						{translateText.title}
					</StyledP>
				</StyledGridItem>

				<StyledGridItem area="textBox" gap="10px">
					<TextBox.TextBox
						{...textBoxSetting}
						disabled={disabled}
						error={isError}
						placeholder={translateText.placeholder}
						value={value}
						onChange={handleValue}
					/>
				</StyledGridItem>
			</StyledGrid>
		);
	},
	InternalController,
);

declare namespace TextWithInputBase {
	type Ref = InternalController | null;
	type Controller = InternalController;
	type Value = string;

	interface PropsBase extends Partial<Style.Grid> {
		value?: Value;
		setValue?: (text: Value) => void;

		title?: string[] | string;
		placeholder?: string[] | string;

		disabled?: boolean;
		require?: boolean;

		error?: boolean;
		setError?: React.Dispatch<React.SetStateAction<boolean>>;

		titleStyled?: Style.TextType;
		textBoxSetting?: TextBox.Props;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export const TextWithInput = memo(TextWithInputBase);

declare namespace TextWithInput {
	interface PropsBase extends TextWithInputBase.PropsBase {}

	type Ref = TextWithInputBase.Ref;
	type Controller = TextWithInputBase.Controller;
	type Props = PropsBase & RefAttributes<Ref>;
}

export default TextWithInputBase;
