import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { MessageTemplateActions } from "../../../../../../../types/MessageTemplateEnums";
import smsStatusColors from "../../data/smsStatusColors";

const SMSLateStatusCellContent: React.FC<SMSLateStatusCellContent.Props> = ({
	item,

	type,
}) => {
	const { t } = useTranslation();

	const smsMessageStatus = useMemo(
		() =>
			item.smsMessages?.find(
				(smsMessage) =>
					smsMessage.action.toString() === type.toString(),
			),
		[item.smsMessages, type],
	);

	const content = useMemo(
		() =>
			smsMessageStatus
				? t(`order_sms_message_statuses.${smsMessageStatus.status}`)
				: "",
		[smsMessageStatus, t],
	);

	const backgroundColor = useMemo(
		() =>
			smsMessageStatus
				? smsStatusColors[smsMessageStatus.status]
				: undefined,
		[smsMessageStatus],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace SMSLateStatusCellContent {
	interface Props {
		item: Order.Model;

		type: MessageTemplateActions;
	}
}

export default SMSLateStatusCellContent;
