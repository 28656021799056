/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";
import { Nullable } from "uikit";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import SIPToDispatcher from "../SIPToDispatcher";

function isNewModel(
	model: SIP.Model.New | SIP.Model.Modified,
): model is SIP.Model.New {
	return "taxiServiceId" in model;
}

class SIP extends Base {
	static fromResponse(data: any): SIP.Model {
		return {
			id: data.id,

			sipToDispatcher: data.sipToDispatcher
				? SIPToDispatcher.fromResponse(data.sipToDispatcher)
				: undefined,

			domain: data.domain,
			isSoftPhone: data.isSoftPhone,
			password: data.password,
			value: data.sipValue,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: SIP.Model.New | SIP.Model.Modified): any {
		if (isNewModel(model)) {
			return {
				taxiServiceId: model.taxiServiceId,
				dispatcherId: model.dispatcherId,
				sipId: model.sipId,

				isSoftPhone: model.isSoftPhone,
			};
		}

		return {
			isSoftPhone: model.isSoftPhone,
		};
	}

	public static async store(object: SIP.Model.New) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.carBrand.create(SIP.toRequest(object)),
		);
		return data;
	}

	public static async update(object: SIP.Model.Modified) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.carBrand.update(object.id, SIP.toRequest(object)),
		);
		return data;
	}

	public static async destroy(ids: number | number[]) {
		this.request((prpc) => prpc.theirsModel.sip.delete(ids));
	}

	public static async callToExecutor(object: SIP.RecallParams) {
		return this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.recallToExecutor(object),
		);
	}

	public static async callToCustomer(object: SIP.RecallParams) {
		return this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.recallToCustomer(object),
		);
	}

	public static async callToPhone(object: SIP.CallParams) {
		return this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.callToPhone(object),
		);
	}

	public static async subscribe(
		options: SIP.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<SIP.Model>,
	): Promise<Subscription<SIP.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.sip.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "SIP.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: SIP.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<SIP.SubscribeOptions>;
	}

	private static optionsToRequest(options: SIP.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,
			query: options.query,

			isSoftPhone: options.isSoftPhone,
			isFree: options.isFree,

			order: options.order,
		};
	}
}

declare namespace SIP {
	export interface Model {
		id: number;

		sipToDispatcher?: SIPToDispatcher.Model;

		domain: string;
		isSoftPhone: boolean;
		password: string;
		value: string;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface RecallParams {
		sipToDispatcherId: number;
		orderId: number;
	}

	interface CallParams {
		sipToDispatcherId: number;
		phone: string;
	}

	interface SubscribeOptions extends ServiceSubscribeOptionsBase<SIP.Model> {
		isSoftPhone?: boolean;
		isFree?: boolean;
	}

	namespace Model {
		interface New {
			taxiServiceId: number;
			dispatcherId: number;
			sipId: number;

			isSoftPhone: string;
		}

		interface Modified {
			id: number;

			isSoftPhone: string;
		}

		interface Available {
			sipToDispatcherId: number;
			isSoftPhone: boolean;
			accountCode: string;
			sipValue: string;
			lastCheckMs: Nullable<string>;
		}
	}
}

export default SIP;
