/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import {
	CaseReducer,
	Draft,
	PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";

import StoreList from "../types/StoreList";
import StoreQueryMap from "../types/StoreQueryMap";

export default function createModelStore<
	Model extends object,
	Name extends string,
>(name: Name) {
	type Data = StoreList<Model>;
	type State = StoreQueryMap<Data>;

	type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

	interface UpdatePayload {
		hash: string;
		data: Data;
	}

	const register: Reducer<string> = (state, { payload }) => {
		if (state[payload]) return;

		state[payload] = {
			cache: [],
			offset: 0,
			limit: 0,
			total: 0,
			deprecated: true,
		};
	};

	const unregister: Reducer<string> = (state, { payload }) => {
		delete state[payload];
	};

	const update: Reducer<UpdatePayload> = (
		state,
		{ payload: { hash, data } },
	) => {
		state[hash] = data as Draft<Data>;
	};

	const deprecate: Reducer<string> = (state, { payload }) => {
		if (!state[payload]) return;

		state[payload].deprecated = true;
	};

	const deprecateAll: Reducer<void> = (state) => {
		for (const hash of Object.keys(state)) state[hash].deprecated = true;
	};

	const approve: Reducer<string> = (state, { payload }) => {
		if (!state[payload]) return;

		state[payload].deprecated = false;
	};

	const approveAll: Reducer<void> = (state) => {
		for (const hash of Object.keys(state)) state[hash].deprecated = false;
	};

	const initialState: State = {};

	return createSlice({
		name,
		initialState,
		reducers: {
			register,
			unregister,

			update,

			deprecate,
			deprecateAll,

			approve,
			approveAll,
		},
	});
}
