import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import {
	getOrderPassengerPoints,
	stringifyPoint,
} from "../../../../../../../redux/constants/OrdersPage/order";
import { StyledRow } from "../../../../../../common";

const PickupAddressCellContent: React.FC<PickupAddressCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();
	const points = useMemo(() => getOrderPassengerPoints(item), [item]);
	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const content = useMemo(
		() =>
			points?.[0] ? stringifyPoint(points[0], "address", pointOnMap) : "",
		[pointOnMap, points],
	);

	const backgroundColor = useMemo(() => {
		if (item?.highlighting?.type === "address") {
			return item?.highlighting?.color || "";
		}
		return undefined;
	}, [item.highlighting]);

	return (
		<StyledRow
			bgC={backgroundColor ? "#ffffff" : undefined}
			w="100%"
			h="100%"
		>
			<CellContentRoot
				backgroundColor={backgroundColor}
				align="center"
				maxedHeight
				maxedWidth
			>
				{content}
			</CellContentRoot>
		</StyledRow>
	);
};

declare namespace PickupAddressCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default PickupAddressCellContent;
