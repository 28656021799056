import React, { PropsWithChildren, memo } from "react";
import styled from "styled-components";
import { Icon } from "uikit";

import { BaseElementIdEnum } from "../../interface";
import { StyledP, StyledRow, StyledWrapIcon } from "../../styles";
import ButtonBase from "../../Button";
import Divider from "../../Divider";

const Button = styled(ButtonBase)`
	width: 32px;
	height: 32px;
	color: #5e6b73;
	&:hover {
		color: #21333f;
	}
`;

const CloseButton = styled(StyledWrapIcon)`
	width: 32px;
	height: 32px;
	color: #5e6b73;
	&:hover {
		color: #21333f;
	}
`;

const Wrap = styled(Divider)`
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 36px;
	overflow: hidden;
	border-radius: 10px 10px 0 0;
	background-color: #ffffff;
`;

const Header: React.FC<Header.Props> = ({
	title,
	onClose,
	draggable = true,
	divider = true,
	children,
	buttonCross,
}) => (
	<Wrap p="7px 12px 7px 8px" side={divider ? "bottom" : "none"}>
		<StyledRow h="38px" w="100%" alignItems="center">
			<StyledRow
				className="handle"
				cursor={draggable ? "grab" : "point"}
				w="100%"
				h="32px"
			>
				<CloseButton cursor={draggable ? "grab" : "point"}>
					{draggable && <Icon id="menu-modal" size={14} />}
				</CloseButton>

				{title && (
					<StyledP
						justify="start"
						alignItems="center"
						text={{ tt: "none" }}
					>
						{title}
					</StyledP>
				)}
			</StyledRow>

			<Button
				onClick={onClose}
				cursor="pointer"
				variant="transpiration"
				id={BaseElementIdEnum.CROSS}
				{...buttonCross}
			>
				<Icon id="close-modal" size={12} />
			</Button>
		</StyledRow>
		{children}
	</Wrap>
);

declare namespace Header {
	interface Props extends PropsWithChildren {
		title?: string;
		onClose: (
			event?:
				| React.MouseEvent<HTMLButtonElement, MouseEvent>
				| KeyboardEvent,
		) => void;
		draggable?: boolean;
		divider?: boolean;
		buttonCross?: Partial<ButtonBase.Props>;
	}
}

export const HeaderMemo = memo(Header);
export default Header;
