/* eslint-disable no-shadow */
import { LatLngLiteral } from "leaflet";
import { Unsubscribe } from "redux";
import { sha1 } from "object-hash";

import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import file from "../../../utils/file";
import pipe, { WebStream } from "../../../utils/pipe";
import ServiceSubscribeOptionsBase from "../../../types/ServiceSubscribeOptionsBase";
import StoreList from "../../../types/StoreList";
import Address from "../../../types/Address";
import map from "../../reducers/map";
import { RootState, store as dataStore } from "../../store";
import { SortingOrder } from "../../../types/SortingOrder";

import Map from ".";

interface LocalObject {
	id: number;

	taxiServiceId?: number;

	point: LatLngLiteral;
	fields: Record<Map.Language, LocalObject.Field>;

	status: boolean;
	visibility: boolean;

	createdAt: Date | null;
	updatedAt: Date | null;
	deletedAt: Date | null;
}

interface ResponseLocalObject {
	id: number;

	taxiService: { id: number } | null;

	point: LatLngLiteral;
	additionalFields: Record<Map.Language, LocalObject.Field>;

	status: boolean;
	visibility: boolean;

	createdAt: Date | null;
	updatedAt: Date | null;
	deletedAt: Date | null;
}

function transformItemForStore({
	additionalFields,
	taxiService,
	...item
}: ResponseLocalObject): LocalObject {
	return {
		...item,

		taxiServiceId: taxiService?.id,

		fields: additionalFields,
	};
}

async function destroyOne(id: number) {
	const prpc = getPRPC();

	if (!prpc) return;

	await createRPCQuery(() => prpc.theirsModel.localObject.delete(id));
}

const unsubscribes: Record<string, Unsubscribe> = {};
const indexPromises: Record<
	string,
	Promise<StoreList<LocalObject> | null>
> = {};

namespace LocalObject {
	export interface Field extends Partial<Address> {
		title?: string;
	}

	export type New = Omit<
		LocalObject,
		"id" | "createdAt" | "updatedAt" | "deletedAt"
	>;

	export type Modified = Omit<
		LocalObject,
		"createdAt" | "updatedAt" | "deletedAt"
	>;

	export type ForSave = New | Modified;

	export type SubscribeOptions = ServiceSubscribeOptionsBase<LocalObject> & {
		taxiServiceIds?: number[];
		grouped?: boolean;
		status?: boolean;
		visibility?: boolean;
		order?: Record<keyof LocalObject, SortingOrder> & {
			fields?: {
				[key in keyof LocalObject.Field]?: SortingOrder;
			};
		};
	};

	export interface ExportOptions {
		type: "json" | "csv";
		taxiServiceId?: number;
		limit?: number;
		offset?: number;
		order?: Record<string, SortingOrder>;
		query?: string;
	}

	export interface ImportOptions {
		type: "json" | "csv";
		taxiServiceId: number;
		stream: ReadableStream;
	}

	export function isNew(localObject: ForSave): localObject is New {
		return !("id" in localObject);
	}

	export function isModified(localObject: ForSave): localObject is Modified {
		return "id" in localObject;
	}

	export function subscribe(options: SubscribeOptions) {
		const hash = sha1(options);

		unsubscribes[hash] = dataStore.subscribe(async () => {
			const subscription = dataStore.getState().map.localObjects[hash];

			if (!subscription?.deprecated || hash in indexPromises) return;

			indexPromises[hash] = Global.index(options);

			const data = await indexPromises[hash];

			delete indexPromises[hash];

			dataStore.dispatch(map.localObjects.actions.approve(hash));

			if (!data) return;

			dataStore.dispatch(map.localObjects.actions.update({ hash, data }));
		});

		dataStore.dispatch(map.localObjects.actions.register(hash));

		return hash;
	}

	export function unsubscribe(hash: string) {
		unsubscribes[hash]?.();

		dataStore.dispatch(map.localObjects.actions.unregister(hash));
	}

	export function selector(state: RootState) {
		return state.map.localObjects;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	export async function store(object: New) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.localObject.create({
				taxiServiceId: object.taxiServiceId,

				point: object.point,
				status: object.status,
				visibility: object.visibility,
				fields: object.fields,
			}),
		);

		dataStore.dispatch(map.localObjects.actions.deprecateAll());
	}

	export async function update(object: LocalObject) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.localObject.update(object.id, {
				taxiServiceId: object.taxiServiceId,

				point: object.point,
				status: object.status,
				visibility: object.visibility,
				fields: object.fields,
			}),
		);

		dataStore.dispatch(map.localObjects.actions.deprecateAll());
	}

	export async function destroy(id: number[] | number) {
		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		dataStore.dispatch(map.localObjects.actions.deprecateAll());
	}

	export async function unload({ type, ...options }: ExportOptions) {
		const prpc = getPRPC();

		if (!prpc) return;

		const stream = (await createRPCQuery(() =>
			prpc.theirsModel.localObject.export({ type }, options),
		)) as any;

		let data = "";

		// eslint-disable-next-line no-constant-condition
		while (true) {
			const { done, value } = await stream.read();

			if (done) break;

			data += value;
		}

		file.download(
			data,
			`local-objects-export-${options.taxiServiceId}.${type}`,
			"text/plain;",
		);
	}

	export async function upload({
		stream: localStream,
		...options
	}: ImportOptions) {
		const prpc = getPRPC();

		if (!prpc) return;

		const webStream = (await createRPCQuery(() =>
			prpc.theirsModel.localObject.import(options),
		)) as WebStream;

		await pipe(webStream, localStream);

		dataStore.dispatch(map.localObjects.actions.deprecateAll());
	}

	namespace Global {
		export async function index(options: SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.localObject.getAll(options),
			);

			return {
				cache: (result.items as ResponseLocalObject[]).map(
					transformItemForStore,
				),
				limit: result.pagination.limit as number,
				offset: result.pagination.offset as number,
				total: result.pagination.count as number,
				deprecated: false,
			};
		}
	}
}

export default LocalObject;
