import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, Icon, Nullable, Row, theme } from "uikit";

import { Call } from "../../../../../../redux/reducers/softphoneWidget";
import getCallTypes from "../../../../constants";

const CarBarStyled = styled(Column)`
	padding: 12px 0px;

	display: flex;
	justify-content: center;
	align-items: center;

	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;

	border-bottom: 1px solid ${theme.colors.color_border_basic};
`;

const CallDuration = styled("span")<{ color?: string }>`
	color: ${({ color }) => color || theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: center;

	cursor: default;
`;

const PhoneNumber = styled("span")`
	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0px;
	text-align: center;

	cursor: default;
`;

const CallerName = styled("span")`
	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: center;

	cursor: default;
`;

const CallType = styled("span")<{ color?: string }>`
	color: ${({ color }) => color || theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: center;

	cursor: default;
`;

const EmptyBlock = styled("div")`
	height: 115px;
	border-bottom: 1px solid ${theme.colors.color_border_basic};
`;

interface Props {
	data: Call[];
	selectedCall: Nullable<Call>;
}
const CallBar: React.FC<Props> = ({ data, selectedCall }) => {
	const { t } = useTranslation();
	const callTypes = useMemo(() => getCallTypes(t), [t]);

	const foundedCall = useMemo(() => {
		const index = data.findIndex(
			(call) => call.request.call_id === selectedCall?.request.call_id,
		);
		return typeof index === "number" ? data[index] : null;
	}, [data, selectedCall?.request.call_id]);

	const callBarPrimaryColor = useMemo(() => {
		if (selectedCall?.session._localHold)
			return callTypes.pause.primaryColor;
		if (selectedCall?.session._is_confirmed)
			return callTypes.current.primaryColor;
		return callTypes[selectedCall?.session.direction]?.primaryColor;
	}, [
		callTypes,
		selectedCall?.session._is_confirmed,
		selectedCall?.session._localHold,
		selectedCall?.session.direction,
	]);

	const callBarIconName = useMemo(() => {
		if (selectedCall?.session._localHold) return callTypes.pause.iconName;
		if (selectedCall?.session._is_confirmed)
			return callTypes.current.iconName;
		return callTypes[selectedCall?.session.direction]?.iconName;
	}, [
		callTypes,
		selectedCall?.session._is_confirmed,
		selectedCall?.session._localHold,
		selectedCall?.session.direction,
	]);

	const callBarCallText = useMemo(() => {
		if (selectedCall?.session._localHold) return callTypes.pause.text;
		if (selectedCall?.session._is_confirmed) return callTypes.current.text;
		return callTypes[selectedCall?.session.direction]?.text;
	}, [
		callTypes,
		selectedCall?.session._is_confirmed,
		selectedCall?.session._localHold,
		selectedCall?.session.direction,
	]);

	return (
		<>
			{!selectedCall && <EmptyBlock></EmptyBlock>}
			{selectedCall && (
				<CarBarStyled gaps="6px 2px 6px">
					<Row gaps="4px">
						<Icon
							size={16}
							id={callBarIconName}
							colors={[callBarPrimaryColor]}
						/>
						<CallDuration color={callBarPrimaryColor}>
							{selectedCall.session._localHold
								? foundedCall?.holdDuration
								: foundedCall?.callDuration}
						</CallDuration>
					</Row>
					<PhoneNumber>
						{selectedCall.session.remote_identity.uri.user}
					</PhoneNumber>{" "}
					<CallerName>{selectedCall.session.name}</CallerName>
					<CallType color={callBarPrimaryColor}>
						{callBarCallText}
					</CallType>
				</CarBarStyled>
			)}
		</>
	);
};
export default CallBar;
