import React from "react";
import { Icon, theme } from "uikit";

import styled from "styled-components";
import { ChatDispatcherPhone } from "../../../../../../types";

const Root = styled.div<{ bgColor?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 26px;
	min-height: 26px;

	max-width: 26px;
	max-height: 26px;

	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;

	color: ${theme.colors.white};
	background-color: ${({ bgColor }) => bgColor || theme.colors.disabled_text};

	border-radius: 50%;
`;

const Avatar: React.FC<Avatar.Props> = ({ data }) => (
	<Root>
		{data.name ? data.name[0] : <Icon id="chat-no-avatar" size={32} />}
	</Root>
);

declare namespace Avatar {
	interface Props {
		data: ChatDispatcherPhone | ChatDispatcherPhone;
	}
}

export default Avatar;
