import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Column, TextBox, Button, theme } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../redux/store";
import Session from "../../redux/services/Session";
import useKeyBind from "../../hooks/useKeyBind";

const Root = styled(Column)`
	padding: 20px;

	border: 1px solid #f2f2f2;
	border-radius: 5px;

	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	text-align: center;

	color: ${theme.colors.primary};
`;

const Label = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	color: ${theme.colors.secondary};
`;

function LoginPage() {
	const dispatch = useDispatch();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [translate, setTranslate] = useState<boolean>(false);
	const { i18n } = useTranslation();

	const language = useTypedSelector((state) => state.session.language);
	const languageUser = useTypedSelector(
		(state) => state.account.user?.settings?.language,
	);

	const login = useCallback(() => {
		setTranslate(true);
		dispatch(Session.login(username, password));
	}, [dispatch, password, username]);

	useKeyBind(["Enter"], (event) => {
		login();

		event.stopPropagation();
		event.preventDefault();
	});

	const getTranslate = useCallback(() => {
		setTranslate(false);
		if (languageUser) i18n.changeLanguage(languageUser);
		else i18n.changeLanguage(language);
	}, [i18n, language, languageUser]);

	// Initialize with default language
	useEffect(() => {
		if (translate) {
			setTimeout(() => {
				getTranslate();
			}, 1000);
		}
	}, [getTranslate, translate]);

	return (
		<Root gaps="20px 10px* 20px">
			<Title>Sign in</Title>
			<Label>Login</Label>
			<TextBox.TextBox
				name="login"
				value={username}
				onChange={setUsername}
				placeholder="username"
			/>
			<Label>Password</Label>
			<TextBox.TextBox
				type="password"
				name="password"
				value={password}
				onChange={setPassword}
				placeholder="********"
			/>
			<Button.Button text="Login" onClick={login} />
		</Root>
	);
}

export default LoginPage;
