import React, { RefAttributes, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegister } from "react-hook-form";
import { TextBox, react } from "uikit";
import LabeledField from "../../../../../../../../../../../components/LabeledField";
import translationPath from "../../../../../../constants/translationPath";
import InternalController from "./Controller";

const NameBase = react.withController<Name.PropsBase, Name.Controller>(
	({ name, setName, isDefault, controller }) => {
		const [error, setError] = useState(false);
		controller.setContext({ name, setError });

		const { t } = useTranslation();

		return (
			<LabeledField label={t(`${translationPath}.modal.name`) || ""}>
				<TextBox.TextBox
					disabled={isDefault}
					error={error}
					placeholder={t(`${translationPath}.modal.name`) || ""}
					value={name}
					onChange={(newName) => {
						setError(false);
						setName(newName.trim());
					}}
				/>
			</LabeledField>
		);
	},
	InternalController,
);
const Name = memo(NameBase);

declare namespace Name {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		name: string;
		setName: (x: string) => void;
		isDefault: boolean;
		register: UseFormRegister<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default Name;
