import React, { Dispatch, useMemo } from "react";
import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import Rules from "../Rules";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

const BasicMode: React.FC<BasicMode.Props> = ({
	value,

	disabled,

	onChange,
}) => {
	const defaultValue = useMemo<BasicMode.Value>(
		() => ({
			grid: [],
		}),
		[],
	);

	const valueEditor = useObjectEditor(value ?? defaultValue, onChange);

	const gridValue = valueEditor.useGetter("grid");
	const gridOnChange = valueEditor.useSetter("grid");

	return (
		<Rules value={gridValue} disabled={disabled} onChange={gridOnChange} />
	);
};

declare namespace BasicMode {
	interface Item {
		threshold: number;

		discount: {
			value: number;
			unit: CustomerRate.Model.Unit;
		};

		onlineDiscount: {
			active: boolean;
			value: number;
			unit: CustomerRate.Model.Unit;
		};
	}

	interface Value {
		grid: Item[];
	}

	interface Props {
		value?: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default BasicMode;
