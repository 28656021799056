import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import CounterpartyCellContent from "../components/CounterpartyCellContent";

function counterparty({ width, onResize }: Column.Options) {
	return base(
		ColumnId.Counterparty,
		(item) => <CounterpartyCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default counterparty;
