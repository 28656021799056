import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	&.__ignore,
	.leaflet-marker-icon:has(div > &) {
		pointer-events: none;
	}

	display: flex;

	align-items: center;
	justify-content: center;

	width: 40px;
	height: 18px;

	margin: 0px auto;

	background-color: ${theme.colors.primary};

	border-radius: 10px;

	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	text-align: center;

	color: white;

	pointer-events: all;

	&::after {
		content: "";

		position: absolute;

		left: 44px;
		bottom: 16px;

		border: 6px solid transparent;
		border-top: 11px solid ${theme.colors.primary};

		z-index: -1;
	}
`;

export default Root;
