import React, { useEffect, useMemo } from "react";
import { LatLngBoundsLiteral, LatLngExpression } from "leaflet";

import { useTypedSelector } from "../../../../../../../redux/store";
import { Point } from "../../../../../../../redux/reducers/Archives/GPSLog/interface";
import useGetGPSHistory from "../../../../../../../hooks/useGetGPSHistory";
import { MapGPS } from "../../../../../../../components/MapGPS";

interface PositionPoints {
	positions: Point[];
	positionsMap: Map<string, Point>;
}

const GPSLogMapContainer: React.FC = (): JSX.Element => {
	const { data, filters, setCentroid, setPositions, setMapBounds } =
		useGetGPSHistory();

	const companies = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);

	const centroid = useMemo<LatLngExpression>(() => {
		const taxiServiceIds = filters?.taxiServiceIds;
		if (!taxiServiceIds) return [50.455002, 30.511284];
		if (!companies?.items?.length) return [50.455002, 30.511284];

		const company = companies.items.find(
			(c) => c?.taxiService?.id === taxiServiceIds[0],
		);

		return company?.centroid;
	}, [companies.items, filters?.taxiServiceIds]);

	const { positions } = useMemo<PositionPoints>(() => {
		const pointsMap: PositionPoints["positionsMap"] = new Map();
		const pointsList: PositionPoints["positions"] = [];

		data.forEach(({ segments, executorCallSign, id }) => {
			segments.forEach((segment, i) => {
				segment.forEach((item, j, arr) => {
					const order = !!item.order;
					const prevOrder = !!arr[j - 1]?.order;
					const keySegment = `${id}:${executorCallSign}:${i}:${order}`;
					const pointMap = pointsMap.get(keySegment);

					if (!pointMap) {
						const index = j - 1;
						const startPont = arr[j - 1];

						if (startPont && index > 0) {
							pointsMap.set(keySegment, {
								order,
								points: [startPont.point, item.point],
							});
						} else if (startPont && prevOrder !== order) {
							pointsMap.set(keySegment, {
								order,
								points: [startPont.point, item.point],
							});
						} else {
							pointsMap.set(keySegment, {
								order,
								points: [item.point],
							});
						}
					} else pointMap.points.push(item.point);
				});
			});
		});

		pointsMap.forEach((point) => pointsList.push(point));

		return {
			positionsMap: pointsMap,
			positions: pointsList,
		};
	}, [data]);

	const mapBounds = useMemo<LatLngBoundsLiteral | null>(() => {
		if (!positions) return null;
		const bounds: LatLngBoundsLiteral = [];

		positions.forEach((item): void => {
			const [firstPos, ...rest] = item.points;
			if (!firstPos) return;

			let min = firstPos;
			let max = firstPos;

			rest.forEach((pos) => {
				min = {
					lat: Math.min(min?.lat, pos?.lat),
					lng: Math.min(min?.lng, pos?.lng),
				};

				max = {
					lat: Math.max(max?.lat, pos?.lat),
					lng: Math.max(max?.lng, pos?.lng),
				};
			});

			bounds.push([min.lat, min.lng], [max.lat, max.lng]);
		});

		if (!bounds.length) return null;
		return bounds;
	}, [positions]);

	useEffect(() => {
		if (setMapBounds) setMapBounds(mapBounds);
	}, [mapBounds, setMapBounds]);

	useEffect(() => {
		if (setPositions) setPositions(positions);
	}, [positions, setPositions]);

	useEffect(() => {
		if (setCentroid) setCentroid(centroid);
	}, [centroid, setCentroid]);

	return <MapGPS />;
};

export default GPSLogMapContainer;
