import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { theme } from "uikit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
	IOrderPoint,
	stringifyPoint,
} from "../../../../../../../../../../redux/constants/OrdersPage/order";
import {
	StyledRow,
	Text,
	TextMemo,
} from "../../../../../../../../../../components/common";
import useKeyBind from "../../../../../../../../../../hooks/useKeyBind";

const OptionList = styled.div`
	display: flex;
	border-radius: 5px;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: overlay;
	width: 100%;
	&::-webkit-scrollbar {
		width: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${`${theme.colors.primary}33`};
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
`;

const Tip = styled(StyledRow)<{
	isActive: boolean;
}>`
	padding: 4px 7px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	&:hover {
		background: #ebebeb;
	}
	${(props) => (props.isActive ? `background: #EBEBEB;` : "")}
`;

interface SelectPoint {
	key: number;
	value: IOrderPoint;
	label: string;
}

const BaseOverlayAllTip: React.FC<BaseOverlayAllTip.Props> = ({
	data = [],
	onSelect,
	activeId,
	setActiveOption,
}) => {
	const { t } = useTranslation();
	const fieldRef = React.useRef<HTMLDivElement | null>(null);

	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const styled = useMemo<Pick<Text.Props, "textStyle">>(
		() => ({
			textStyle: {
				h: { min: "32px" },
				w: "100%",
				colors: "#21333f",
				font: { fw: "400", size: "14px", line: "18px" },
				text: { ws: "normal" },
				cursor: "pointer",
			},
		}),
		[],
	);

	const selectOption = useCallback(
		(value: IOrderPoint, key: number) => ({
			key,
			value,
			label: stringifyPoint(value, "full", pointOnMap),
		}),
		[pointOnMap],
	);

	const options = useMemo<SelectPoint[]>(() => {
		if (!data?.length && !Array.isArray(data)) return [];
		return data?.map((item, i) => selectOption(item, i));
	}, [data, selectOption]);

	const onClick = useCallback(
		(option: SelectPoint) => onSelect(option),
		[onSelect],
	);

	useLayoutEffect(() => {
		if (fieldRef.current) {
			fieldRef.current?.scrollIntoView({
				behavior: "smooth",
				inline: "nearest",
				block: "nearest",
			});
		}
	}, [activeId, fieldRef]);

	useKeyBind(["ArrowUp"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev - 1 < 0 ? items - 1 : prev - 1;
		});
	});

	useKeyBind(["ArrowDown"], () => {
		setActiveOption((prev) => {
			const items = options?.length || 0;
			return prev + 1 > items - 1 ? 0 : prev + 1;
		});
	});

	useKeyBind(["Enter"], () => {
		if (options && activeId !== -1) onClick(options[activeId]);
	});

	useKeyBind(["Tab"], () => {
		if (options && activeId !== -1) onClick(options[activeId]);
	});

	return (
		<OptionList>
			{options?.map((option, i) => (
				<Tip
					ref={activeId === i ? fieldRef : null}
					key={`${i + 1}`}
					isActive={activeId === i}
					onClick={() => onClick(option)}
				>
					<TextMemo {...styled} text={option.label} />
				</Tip>
			))}
		</OptionList>
	);
};

declare namespace BaseOverlayAllTip {
	interface Props {
		data: IOrderPoint[] | undefined;
		onSelect: (option: SelectOption) => void;
		activeId: number;
		setActiveOption: Dispatch<SetStateAction<number>>;
	}

	interface SelectOption {
		key: number;
		value: IOrderPoint;
		label: string;
	}
}

export const OverlayHouse = memo(BaseOverlayAllTip);
export default BaseOverlayAllTip;
