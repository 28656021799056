import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<{ selected?: boolean }>`
	padding: 8px 16px;

	width: 100%;
	min-height: 37px;
	max-height: 53px;

	justify-content: center;
	align-items: flex-start;

	background-color: ${({ selected }) =>
		selected ? "#f5f5f5" : theme.colors.white};
	border-bottom: 1px solid #f5f5f5;
	cursor: pointer;

	&:hover {
		background-color: ${({ selected }) =>
			selected ? "#f5f5f5" : "#fafafa"};
	}
`;

export default Root;
