import { LeafletEvent, Map } from "leaflet";
import { useEffect, useState } from "react";

export default function useMapZoom(
	map: Map | undefined | null,
	initialZoom = 9,
) {
	const [zoom, setZoom] = useState(initialZoom);

	useEffect(() => {
		if (!map) return undefined;

		function callback(e: LeafletEvent) {
			setZoom(e.target._zoom);
		}

		map.on("zoomend", callback);

		return () => {
			map.off("zoomend", callback);
		};
	}, [map]);

	return zoom;
}
