import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Root from "./components/Root";
import SearchBar from "./components/SearchBar";
import { useTypedSelector } from "../../../../../../redux/store";
import softphoneWidget from "../../../../../../redux/reducers/softphoneWidget";

const Search = () => {
	const dispatch = useDispatch();
	const { transferSearchQuery, showTransferKeyboard, transferTab } =
		useTypedSelector((state) => state.softphoneWidget);

	const onChangeQuery = useCallback(
		(value: string) => {
			dispatch(softphoneWidget.actions.setTransferSearchQuery(value));
		},
		[dispatch],
	);

	const inputRef = useRef<any>();

	useEffect(() => {
		if (inputRef.current) {
			const input = inputRef.current.querySelector("input");
			if (showTransferKeyboard) {
				input.blur();
			} else {
				input.focus();
			}
		}
	}, [showTransferKeyboard, transferTab]);
	return (
		<Root ref={inputRef}>
			<SearchBar onChange={onChangeQuery} value={transferSearchQuery} />
		</Root>
	);
};

export default Search;
