import makeTable from "./makeTable";

const accountingBankAccountsSecondTableCards = makeTable({
	allIds: ["keyword", "cardNumber"],
	i18tPath: (id) => `mainPage.accounting.bankAccounts.columns.${id}`,
	defaultIds: ["keyword", "cardNumber"],
	initialSortMethod: {
		column: "cardNumber",
		type: "asc",
	},
	initialWidths: {
		keyword: 400,
		cardNumber: 400,
	},
	initialResizable: {
		keyword: true,
		cardNumber: true,
	},
	initialSortable: {
		keyword: true,
		cardNumber: true,
	},
});

export default accountingBankAccountsSecondTableCards;
