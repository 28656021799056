import React, { Dispatch, memo } from "react";
import { CheckBox, Row, Stepper } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { ValueStepper } from "../../../../../../../../components/common";

import tPath from "../../../../constants/tPath";

const path = `${tPath}.mainModal.tabs.main.surcharges.additionalPointCost`;

const AdditionalPointCost: React.FC<AdditionalPointCost.Props> = memo(
	(props) => {
		const { disabled, value, onChange } = props;

		const { t } = useTranslation();

		const editor = useObjectEditor(value, onChange);

		const active = editor.useGetter("active");
		const setActive = editor.useSetter("active");

		const after = editor.useGetter("after");
		const setAfter = editor.useSetter("after");

		const surchargeValue = editor.useGetter("value");
		const setSurchargeValue = editor.useSetter("value");

		return (
			<Row align="center" gaps="10px*">
				<CheckBox
					disabled={disabled}
					label={t(`${path}.name`) || ""}
					value={active}
					onChange={setActive}
				/>
				{t(`${path}.after`)}
				<Stepper
					value={after}
					onChange={setAfter as any}
					disabled={disabled || !active}
					min={0}
				/>
				{t(`${path}.everyPointWillAdd`)}
				<ValueStepper
					value={surchargeValue}
					onChange={setSurchargeValue}
					disabled={disabled || !active}
				/>
				{t(`${path}.valueToOrderCost`)}
			</Row>
		);
	},
);

declare namespace AdditionalPointCost {
	interface Props {
		disabled?: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		active: boolean;
		value: number;
		after: number;
	}
}

export default AdditionalPointCost;
