import styled from "styled-components";

const Item = styled.div`
	display: flex;

	flex-direction: row;
	justify-content: center;

	align-items: center;

	gap: 10px;

	padding: 4px 8px;

	border-radius: 4px;

	background: #eeeff1;

	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #647079;
`;

export default Item;
