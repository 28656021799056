import TaxiService from "../../../../../services/TaxiService";
import getNumberIds from "./getNumberIds";

export const getTaxiServiceIds = (
	taxiServiceFilter: number[] | ["all"],
	companyFilter: number[] | ["all"],
	taxiServices: TaxiService.Model[] | undefined,
): number[] => {
	let ids: number[] = getNumberIds(taxiServiceFilter);
	if (!ids.length && taxiServices) {
		const companyIds = getNumberIds(companyFilter);
		const taxiServiceList = taxiServices?.filter((taxiService) => {
			if (
				typeof taxiService.company?.id === "number" ||
				typeof taxiService.company?.id === "string"
			) {
				const second = companyIds.includes(
					Number(taxiService.company.id),
				);
				if (second) return true;
			}
			return false;
		});
		ids = taxiServiceList.map((taxiService) => taxiService.id);
	}

	return ids;
};

export default getTaxiServiceIds;
