import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, ToggleButton, Row, Icon, theme } from "uikit";
import { clone } from "lodash";
import moment from "moment";

import TaxiService from "../../../../../../../../../../services/TaxiService";
import Company from "../../../../../../../../../../services/Company";
import Map from "../../../../../../../../../../redux/services/Map";
import LightTable from "../../../../../../../../../../components/LightTable";
import {
	ColumnId,
	ColumnIds,
} from "../../../../../../../../../../constants/tables/types";

const ObjectTable: React.FC<ObjectTable.Props> = ({
	columns,
	selected,
	data,
	language,
	taxiServices,
	companies,

	onChangeSelected,

	...tableProps
}) => {
	const { t } = useTranslation();

	const renderColumn = useCallback(
		(column: ColumnId<"object">) => {
			switch (column) {
				case "checkbox":
					return (
						<LightTable.Column width={36}>
							<LightTable.HeaderCell verticalAlign="middle">
								<CheckBox
									value={
										selected.length === data.length &&
										data.length !== 0
									}
									onChange={(value) =>
										onChangeSelected(
											value
												? data.map((item) => item.id)
												: [],
										)
									}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell verticalAlign="middle">
								{(rowData) => (
									<CheckBox
										value={selected.includes(rowData.id)}
										onChange={() => {
											const newSelected = clone(selected);

											if (
												!newSelected.includes(
													rowData.id,
												)
											)
												newSelected.push(rowData.id);
											else if (
												newSelected.includes(rowData.id)
											)
												newSelected.splice(
													newSelected.indexOf(
														rowData.id,
													),
													1,
												);

											onChangeSelected(newSelected);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "active":
					return (
						<LightTable.Column
							key={column}
							width={90}
							resizable
							sortable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str150",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								dataKey={column}
								verticalAlign="middle"
							>
								{(rowData) => (
									<div
										onClick={(event) =>
											event.stopPropagation()
										}
									>
										<ToggleButton.ToggleButton
											value={rowData.status}
											onChange={(value) => {
												Map.LocalObject.update({
													...(rowData as Map.LocalObject),
													status: value,
												});
											}}
										/>
									</div>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "name":
					return (
						<LightTable.Column flexGrow={0.4} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str151",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="fields.title"
								fullText
							>
								{(rowData) =>
									rowData.fields?.[language]?.title || ""
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "city":
					return (
						<LightTable.Column flexGrow={0.25} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str152",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="fields.settlement"
								fullText
							>
								{(rowData) =>
									rowData.fields[language].settlement
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "street":
					return (
						<LightTable.Column flexGrow={0.3} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str153",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="fields.street"
								fullText
							>
								{(rowData) => rowData.fields[language].street}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "house":
					return (
						<LightTable.Column flexGrow={0.2} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str154",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="fields.house"
								fullText
							>
								{(rowData) => rowData.fields[language].house}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "coordinates":
					return (
						<LightTable.Column width={160} resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str200",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell verticalAlign="middle" fullText>
								{(rowData) =>
									`${rowData.point.lat.toFixed(
										4,
									)}, ${rowData.point.lng.toFixed(4)}`
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "company":
					return (
						<LightTable.Column
							flexGrow={0.25}
							minWidth={120}
							sortable
							resizable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str155",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="company"
								fullText
							>
								{(rowData) => {
									const companyId = taxiServices?.find(
										(taxiService) =>
											taxiService.id ===
											rowData.taxiServiceId,
									)?.company?.id;

									return (
										companies?.find(
											(company) =>
												company.id === companyId,
										)?.name[language] ?? ""
									);
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "branch":
					return (
						<LightTable.Column
							flexGrow={0.25}
							minWidth={120}
							sortable
							resizable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str156",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="taxiService"
								fullText
							>
								{(rowData) =>
									taxiServices?.find(
										(taxiService) =>
											taxiService.id ===
											rowData.taxiServiceId,
									)?.settlement[language] ?? ""
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "createdAt":
					return (
						<LightTable.Column
							flexGrow={0.25}
							minWidth={120}
							sortable
							resizable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str157",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								dataKey="createdAt"
								fullText
							>
								{(rowData) =>
									moment(rowData.createdAt).format(
										"DD.MM.YYYY",
									)
								}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "properties":
					return (
						<LightTable.Column width={100}>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.objects.content.objectTable.str158",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell verticalAlign="middle">
								{(rowData) => (
									<Row align="center" gaps="16px">
										<Icon
											id="house-location"
											size={{ width: 17, height: 22 }}
											colors={[
												rowData.status
													? theme.colors.primary
													: theme.colors
															.disabled_text,
											]}
										/>
										<Icon
											id={`${
												rowData.visibility ? "" : "not-"
											}shown-in-mobile`}
											size={[20, 20]}
											colors={[
												rowData.status &&
												rowData.visibility
													? theme.colors.primary
													: theme.colors
															.disabled_text,
											]}
										/>
									</Row>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);
				default:
					return <></>;
			}
		},
		[
			companies,
			data,
			language,
			onChangeSelected,
			selected,
			taxiServices,
			t,
		],
	);

	return (
		<LightTable {...tableProps} data={data}>
			{columns.map((column) => renderColumn(column))}
		</LightTable>
	);
};

declare namespace ObjectTable {
	interface Props extends LightTable.Props {
		columns: ColumnIds<"object">;
		selected: number[];
		data: Map.LocalObject[];
		language: Map.Language;
		taxiServices?: TaxiService.Model[];
		companies?: Company.Model[];

		onChangeSelected: Dispatch<number[]>;
	}
}

export default ObjectTable;
