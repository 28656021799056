import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { theme } from "uikit";

const ButtonStyled = styled("button")`
	height: 40px;
	width: 100%;

	color: ${theme.colors.secondary};
	font-family: Roboto;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: center;

	border-radius: 3px;
	background-color: ${theme.colors.transparent};
`;

interface Props {
	text: ReactNode;
	onPressButton: (value: string) => void;
	value: string;
}

const Button: React.FC<Props> = ({ text, value, onPressButton }) => {
	const pressButton = useCallback(() => {
		onPressButton(value);
	}, [onPressButton, value]);
	return <ButtonStyled onClick={pressButton}>{text}</ButtonStyled>;
};

export default Button;
