import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	TYPE = "type",
	CALL_RECEIVED_TIME = "callReceivedTime",
	EMPLOYEE = "employee",
	SURNAME = "surname",
	NAME = "name",
	FATHER_NAME = "fatherName",
	SIP = "sip",
	SERVICE_PHONE = "servicePhone",
	CUSTOMER_PHONE = "customerPhone",
	COMPANY = "company",
	BRANCH = "branch",
	STATUS = "status",
	WAITING_TIME = "waitingTime",
	CALL_DURATION = "callDuration",
	RECORD = "record",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.TYPE, label: "", width: 100, sortable: true },
	{ id: Columns.CALL_RECEIVED_TIME, label: "", width: 100, sortable: true },
	{ id: Columns.EMPLOYEE, label: "", width: 250, sortable: true },
	{ id: Columns.SURNAME, label: "", width: 130, sortable: true },
	{ id: Columns.NAME, label: "", width: 130, sortable: true },
	{ id: Columns.FATHER_NAME, label: "", width: 130, sortable: true },
	{ id: Columns.SIP, label: "", width: 80, sortable: true },
	{ id: Columns.SERVICE_PHONE, label: "", width: 150, sortable: true },
	{ id: Columns.CUSTOMER_PHONE, label: "", width: 130 },
	{ id: Columns.STATUS, label: "", width: 110, sortable: true },
	{ id: Columns.WAITING_TIME, label: "", width: 100 },
	{ id: Columns.CALL_DURATION, label: "", width: 100, sortable: true },
	{ id: Columns.COMPANY, label: "", width: 130, sortable: true },
	{ id: Columns.BRANCH, label: "", width: 130, sortable: true },
	{ id: Columns.RECORD, label: "" },
];

export const defaultColumnsId = columns
	.filter(
		(c) =>
			c.id !== Columns.NAME &&
			c.id !== Columns.SURNAME &&
			c.id !== Columns.FATHER_NAME,
	)
	.map(({ id }) => id);
