import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	position: relative;

	width: 100%;

	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	background-color: white;

	border-radius: 0px;

	z-index: 2;
`;

export default Root;
