import { useCallback } from "react";

import { useTypedDispatch } from "../redux/store";
import saveGlobalSettings from "../redux/services/Settings/global/save";

function useSettingsSave() {
	const dispatch = useTypedDispatch();

	return useCallback(() => {
		dispatch(saveGlobalSettings());
	}, [dispatch]);
}

export default useSettingsSave;
