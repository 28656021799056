import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Preferences } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	models: [],
	services: [],
	service: null,
};

const setModels: ReduxReducer<State, State["models"]> = (
	state,
	{ payload },
) => {
	state.models = payload;
};

const setServices: ReduxReducer<State, State["services"]> = (
	state,
	{ payload },
) => {
	state.services = payload;
};

const setService: ReduxReducer<State, State["service"]> = (
	state,
	{ payload },
) => {
	state.service = payload;
};

const updateService: ReduxReducer<State, State["service"]> = (
	state,
	{ payload },
) => {
	if (payload) {
		state.services = [...state.services].map((item) => {
			if (item.id === payload.id) return payload;
			return item;
		});
	}
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		if (key === "models") return;
		state[key] = initialState[key];
	});
};

const data = createSlice({
	name: Preferences.ConstantPreferencesSlice.SERVICES_DATA,
	initialState,
	reducers: {
		reset,
		setModels,
		setServices,
		setService,
		updateService,
	},
});

export const dataAction = data.actions;
export const dataReducer = data.reducer;
export type dataState = State;

export default data;
