import styled from "styled-components";
import { theme } from "uikit";

const Name = styled.div<Name.Props>`
	font-family: Lato;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	color: ${({ selected }) =>
		selected ? theme.colors.accent : theme.colors.primary};
`;

declare namespace Name {
	interface Props {
		selected: boolean;
	}
}

export default Name;
