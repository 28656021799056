import { Action, combineReducers } from "@reduxjs/toolkit";

import { orders, archiveOrderAction, StateOrders } from "./Orders";
import { State as StateGPS } from "./GPSLog/interface";
import { archiveGPSLogAction, archiveGPSLogReducer } from "./GPSLog";

export type State = {
	orders: StateOrders;
	gps: StateGPS;
};

export type Actions = {
	orders: typeof archiveOrderAction;
	gps: typeof archiveGPSLogAction;
};

export const archiveReducer = combineReducers<State, Action<Actions>>({
	orders,
	gps: archiveGPSLogReducer,
});

export const archiveAction: Actions = {
	orders: archiveOrderAction,
	gps: archiveGPSLogAction,
};

export default archiveReducer;
