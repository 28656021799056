import L, { LatLngLiteral, LatLngTuple } from "leaflet";
import { react } from "uikit";

interface Context {
	routes: LatLngLiteral[][];
	points: LatLngLiteral[];

	map?: L.Map;
}

export default class Controller extends react.ControllerBase<Context> {
	focus() {
		return this.focusRoute() || this.focusPoints();
	}

	focusRoute() {
		const { routes = [], map } = this.context ?? {};

		if (
			!map ||
			!routes.length ||
			routes.every((route) => route.length === 0)
		)
			return false;

		const bounds = new L.LatLngBounds([]);

		routes.forEach((route) => {
			bounds.extend(
				route.map((point) => [point.lat, point.lng] as LatLngTuple),
			);
		});

		map.fitBounds(bounds, {
			padding: [40, 40],
			maxZoom: 19,
			animate: false,
		});

		return true;
	}

	focusPoints() {
		const { points = [], map } = this.context ?? {};

		if (!map || !points.length) return false;

		const bounds = new L.LatLngBounds([]);

		points.forEach((point) => {
			bounds.extend([point.lat, point.lng] as LatLngTuple);
		});

		map.fitBounds(bounds, {
			padding: [40, 40],
			maxZoom: points.length > 1 ? 19 : 16,
			animate: false,
		});

		return true;
	}

	focusPoint(index: number) {
		const { points = [], map } = this.context ?? {};

		if (!map || !points.length) return false;

		const point = points[index];

		map.setView(point, 16, { animate: false });

		return true;
	}
}
