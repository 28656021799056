import React, {
	Dispatch,
	memo,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { defaults, isSymbol } from "lodash";
import moment from "moment";
import { react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../../../../../services/Language";
import ExecutorRateSubscription from "../../../../../../../../../../../../../../services/ExecutorRateSubscription";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { DateFns } from "../../../../../../../../../../../../../../utils/DateFns";
import RequiredProperties from "../../../../../../../../../../../../../../types/RequiredProperties";
import EditModalRoot from "../../../../../../../../../../../../../../components/EditModal";
import DeleteModal from "../../../../../../../../../../../../../../components/DeleteModal";
import {
	checkChargeOnOrderCompletion,
	checkClassifierId,
	checkFee,
	checkMode,
	checkPeriod,
	checkTime,
} from "../../../../../../../../utils";

import InternalController from "./Controller";
import InternalContent from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const defaultValue: Partial<EditModal.Value> = {
	name: "",
	startDate: moment().startOf("day").toDate(),
	mode: ExecutorRateSubscription.Model.Action.AfterOrderClosed,
};

const EditModalBase: React.FC<EditModal.Props> = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ value, language, onCancel, onSave, controller }) => {
	const { t } = useTranslation();

	const translateTexts: Record<string, string> = useMemo(
		() => ({
			typeName:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str200",
				) ?? "",
			textTranslateBefore:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str201",
				) ?? "",
			textTranslateBetween:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str202",
				) ?? "",
			textTranslateAfter:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str203",
				) ?? "",
			text0:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str204",
				) ?? "",
			text1:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str207",
				) ?? "",
			text2:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str209",
				) ?? "",
			text3:
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.editModal.str210",
				) ?? "",
		}),
		[t],
	);

	const [showTypeModal, setShowTypeModal] = useState(false);
	const [showTimeModal, setShowTimeModal] = useState(false);
	const [showTariffModal, setShowTariffModal] = useState(false);
	const [typeName, setTypeName] = useState<string>(translateTexts.typeName);

	const [contentRef, setContentRef] =
		useRefWithSetter<InternalContent.Ref | null>(null);
	const dateFns = useMemo(() => new DateFns(), []);

	const [internalValue, setInternalValue] = useState(
		defaults({}, value, defaultValue),
	);

	const internalValueEditor = useObjectEditor(
		internalValue,
		setInternalValue,
	);

	const contentValue = internalValueEditor.usePicker([
		"classifierId",
		"name",
		"startDate",
		"mode",
		"period",
		"settings",
		"fee",
	]);

	const assignInternalValue = internalValueEditor.useAssigner();

	const validateTime = useCallback(() => {
		const steepPeriod = checkPeriod({
			prev: value,
			next: internalValue,
		});

		if (!steepPeriod) return false;

		const steepTime = checkTime({
			prev: value,
			next: internalValue,
		});

		if (!steepTime) return false;

		return true;
	}, [internalValue, value]);

	const validateTariff = useCallback(() => {
		const steepMode = checkMode({
			prev: value,
			next: internalValue,
		});

		if (!steepMode) return false;

		const steepFee = checkFee({
			prev: value,
			next: internalValue,
		});

		if (!steepFee) return false;

		const steepChargeOnOrderCompletion = checkChargeOnOrderCompletion({
			prev: value,
			next: internalValue,
		});

		if (!steepChargeOnOrderCompletion) return false;

		return true;
	}, [internalValue, value]);

	const handleSubmit = useCallback(
		(allow = false) => {
			if (!contentRef.current?.validate()) return;

			if (!internalValue.id || typeof internalValue.id === "symbol") {
				onSave(internalValue as EditModal.Value.Validated);
				return;
			}

			if (!allow) {
				const steepClassifierId = checkClassifierId({
					prev: value,
					next: internalValue,
				});

				if (!steepClassifierId) {
					setShowTypeModal(true);
					return;
				}

				const isTime = validateTime();
				if (!isTime) {
					setShowTimeModal(true);
					return;
				}

				const isTariff = validateTariff();
				if (!isTariff) {
					setShowTariffModal(true);
					return;
				}

				onSave(internalValue as EditModal.Value.Validated);
			} else onSave(internalValue as EditModal.Value.Validated);
		},
		[
			contentRef,
			internalValue,
			onSave,
			validateTariff,
			validateTime,
			value,
		],
	);

	const typeModalOnCancel = useCallback(() => {
		setShowTypeModal(false);
	}, []);

	const typeModalOnConfirm = useCallback(async () => {
		const isTime = validateTime();
		if (!isTime) {
			setShowTimeModal(true);
			setShowTypeModal(false);
			return;
		}

		const isTariff = validateTariff();
		if (!isTariff) {
			setShowTariffModal(true);
			setShowTypeModal(false);
			return;
		}

		setShowTypeModal(false);
		handleSubmit(true);
	}, [handleSubmit, validateTariff, validateTime]);

	const timeModalOnCancel = useCallback(() => {
		setShowTimeModal(false);
	}, []);

	const timeModalOnConfirm = useCallback(async () => {
		const isTariff = validateTariff();
		if (!isTariff) {
			setShowTariffModal(true);
			setShowTimeModal(false);
			return;
		}

		setShowTimeModal(false);
		handleSubmit(true);
	}, [handleSubmit, validateTariff]);

	const tariffModalOnCancel = useCallback(() => {
		setShowTariffModal(false);
	}, []);

	const tariffModalOnConfirm = useCallback(async () => {
		setShowTariffModal(false);
		handleSubmit(true);
	}, [handleSubmit]);

	const textShowTypeModal = useMemo(() => {
		const time = dateFns.format(internalValue?.startDate || null, language);
		const {
			textTranslateBefore,
			textTranslateBetween,
			textTranslateAfter,
		} = translateTexts;

		return `${textTranslateBefore} "${typeName}" ${textTranslateBetween} ${time}, ${textTranslateAfter}.`;
	}, [dateFns, internalValue.startDate, language, translateTexts, typeName]);

	const textShowTariffModal = useMemo(() => {
		const time = dateFns.format(internalValue.startDate || null, language);

		return `${translateTexts.text1} ${time}.`;
	}, [dateFns, internalValue.startDate, language, translateTexts.text1]);

	const headerTitle = useMemo(
		() =>
			isSymbol(value.id) ? translateTexts.text2 : translateTexts.text3,
		[translateTexts.text2, translateTexts.text3, value.id],
	);

	controller.setContext({ contentRef });

	return (
		<EditModalRoot onCancel={onCancel} onSave={handleSubmit}>
			<Root>
				<Header title={headerTitle} createdAt={value.createdAt} />
				<InternalContent
					ref={setContentRef}
					value={contentValue}
					language={language}
					onChange={assignInternalValue}
					setTypeName={setTypeName}
				/>

				{showTypeModal && (
					<DeleteModal
						label={textShowTypeModal}
						onCancel={typeModalOnCancel}
						onConfirm={typeModalOnConfirm}
					/>
				)}

				{showTimeModal && (
					<DeleteModal
						label={translateTexts.text0}
						onCancel={timeModalOnCancel}
						onConfirm={timeModalOnConfirm}
					/>
				)}

				{showTariffModal && (
					<DeleteModal
						label={textShowTariffModal}
						onCancel={tariffModalOnCancel}
						onConfirm={tariffModalOnConfirm}
					/>
				)}
			</Root>
		</EditModalRoot>
	);
}, InternalController);

const EditModal = memo(EditModalBase);

declare namespace EditModal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;

		language: Language;

		onCancel: () => void;
		onSave: Dispatch<Value.Validated>;
	}

	interface Value extends InternalContent.Value {
		id: number | symbol;

		createdAt?: string;
	}

	namespace Value {
		type Validated = RequiredProperties<
			Value,
			"name" | "classifierId" | "startDate" | "mode"
		>;
	}

	namespace Content {
		namespace Settings {
			type Condition = InternalContent.Settings.Condition;
			type OrderType = InternalContent.Settings.OrderType;
		}
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
