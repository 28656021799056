import KeyBinder from "../services/KeyBinder";

import usePrevious from "./usePrevious";

export default function useKeyBindInit() {
	const previousBlurHandler = usePrevious(KeyBinder.blurHandler);
	const previousKeyDownHandler = usePrevious(KeyBinder.keyDownHandler);
	const previousKeyUpHandler = usePrevious(KeyBinder.keyUpHandler);

	if (previousBlurHandler)
		window.removeEventListener("blur", previousBlurHandler);

	if (previousKeyDownHandler)
		window.removeEventListener("keydown", previousKeyDownHandler);

	if (previousKeyUpHandler)
		window.removeEventListener("keyup", previousKeyUpHandler);

	window.addEventListener("blur", KeyBinder.blurHandler, false);
	window.addEventListener("keydown", KeyBinder.keyDownHandler, false);
	window.addEventListener("keyup", KeyBinder.keyUpHandler, false);
}
