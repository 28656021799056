import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	padding: 0px 16px;

	width: 292px;
	height: 36px;

	align-items: center;

	border-bottom: 1px solid ${theme.colors.color_border_basic};

	overflow-x: scroll;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

export default Root;
