import { TFunction } from "i18next";

import { IOrderPoint } from "../../redux/constants/OrdersPage/order";
import LegacyTableColumn from "../../types/TableColumn";
import getValidCityName from "../../utils/getValidCityName";

import PointOrderModalTable from "./components/PointOrderModalTable";

export const pointTableAccessors = [
	"id",
	"city",
	"street",
	"house",
	"entrance",
	"flat",
	"polygon",
	"setting",
] as const;

export type PointTableAccessor = (typeof pointTableAccessors)[number];

export const getPointTableSchema = (
	t: TFunction,
): LegacyTableColumn<PointTableAccessor>[] => [
	{
		key: "id",
		accessor: "id",
		isChecked: true,
		label: "№",
		width: 36,
		resizable: false,
	},
	{
		key: "city",
		accessor: "city",
		isChecked: true,
		label: t("orderPage.previewer.pointTableSchema.city") ?? "",
		width: 100,
		resizable: true,
	},
	{
		key: "street",
		accessor: "street",
		isChecked: true,
		label: t("orderPage.previewer.pointTableSchema.street") ?? "",
		width: 440,
		resizable: true,
	},
	{
		key: "house",
		accessor: "house",
		isChecked: true,
		label: t("orderPage.previewer.pointTableSchema.house") ?? "",
		width: 50,
		resizable: true,
	},
	{
		key: "entrance",
		accessor: "entrance",
		isChecked: true,
		label: t("orderPage.previewer.pointTableSchema.entrance") ?? "",
		width: 50,
		resizable: true,
	},
	{
		key: "polygon",
		accessor: "polygon",
		isChecked: true,
		label: t("orderPage.previewer.pointTableSchema.polygon") ?? "",
		width: 85,
		resizable: true,
	},
	{
		key: "setting",
		accessor: "setting",
		isChecked: true,
		label: " ",
		width: 66,
		resizable: false,
	},
];

export const formatTableOrderPoint = (
	point: IOrderPoint & {
		id: number;
		save: boolean;
		saveType?: PointOrderModalTable.SaveType;
	},
	lang = "ru",
) => {
	let streetBuffer = "";

	if (point.customStreet) {
		streetBuffer = point.customStreet;
	} else if (point.street) {
		streetBuffer = `${point.streetType} ${point.street}`.trim();
	}

	if (point.type === "street" && point.district) {
		streetBuffer += streetBuffer.length
			? ` (${point.district})`
			: point.district;
	}

	if (point.type === "localObject" || point.type === "object") {
		const name = point.name || (point as any).title;
		streetBuffer = name
			? `${name} (${streetBuffer}) ${
					streetBuffer ? `(${streetBuffer})` : ""
			  }`.trim()
			: streetBuffer;
	}

	return {
		raw: point,
		id: point.id,
		city: getValidCityName(point.settlementType, point.settlement),
		street: streetBuffer,
		name: point.name,
		title: point.title,
		house: point.customHouse || point.house,
		entrance: point.entrance,
		flat: point.flat,
		polygon: point.sector?.name?.[lang],
		coordinates: point.coordinates,
		save: point.save,
		saveType: point.saveType,
		type: point.type,
	};
};
