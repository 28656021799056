import React from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";

const ReduceButton = ({ onReduce, onExpand, isReduce }) => (
	<>
		{isReduce ? (
			<Root onClick={onExpand}>
				<Icon
					id="chevron-down"
					size={20}
					colors={[theme.colors.disabled_text]}
				/>
			</Root>
		) : (
			<Root onClick={onReduce}>
				<Icon
					id="chevron-up"
					size={20}
					colors={[theme.colors.disabled_text]}
				/>
			</Root>
		)}
	</>
);

export default ReduceButton;
