import styled from "styled-components";
import { theme } from "uikit";

const ParameterName = styled.div`
	font-family: Lato;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;

	color: ${theme.colors.primary};
`;

export default ParameterName;
