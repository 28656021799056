/* eslint-disable import/no-unresolved */

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBox,
	Flex,
	OrderSelect,
	Row,
	Stepper,
	TextBox,
	ToggleButton,
	ToggleGroup,
} from "uikit";
import { Keybind } from "uikit/dist/utils/keys";
import { SettingMetaData, SettingValue } from "../../../types/settingEntries";
import ColorPicker from "../../ColorPicker";
import KeybindInputWrapper from "./KeybindInputWrapper";
import MultiSelectWrapper from "./MultiSelectWrapper";
import SelectWrapper from "./SelectWrapper";
import RadioSelectWrapper from "./RadioSelectWrapper";

interface SettingsRowProps<SelectValue, MultiSelectValue> {
	name: string;
	value: SettingValue<SelectValue, MultiSelectValue>;
	entry: SettingMetaData<SelectValue, MultiSelectValue>;
	onChange: (value: SettingValue<SelectValue, MultiSelectValue>) => void;
}

function SettingsRow<SelectValue, MultiSelectValue>({
	name,
	value,
	entry,
	onChange,
}: SettingsRowProps<SelectValue, MultiSelectValue>) {
	const { t } = useTranslation();
	const [displayName, setDisplayName] = useState(true);
	const [vertical, setVertical] = useState(false);

	const row = useMemo(() => {
		setDisplayName(true);
		setVertical(false);

		const { type } = entry;

		switch (type) {
			case "boolean": {
				switch (entry.style) {
					case "toggle":
						return (
							<ToggleButton.ToggleButton
								value={value as boolean}
								onChange={onChange}
							/>
						);

					case "checkbox":
					default:
						return (
							<CheckBox
								value={value as boolean}
								onChange={onChange}
							/>
						);
				}
			}

			case "number":
				return (
					<Row gaps="15px*" align="center">
						{entry.unit && t(`units.${entry.unit}`)}

						<Stepper
							value={value as number}
							onChange={onChange}
							decimalCount={entry.decimalCount}
							min={entry.min}
							max={entry.max}
							step={entry.step}
							width={entry.width || "89px"}
						/>
					</Row>
				);

			case "string":
				return (
					<TextBox.TextBox
						value={value as string}
						onChange={onChange}
					/>
				);

			case "color":
				return (
					<ColorPicker
						color={value as string}
						onChange={onChange}
						label={undefined}
						defaultColor={undefined}
					/>
				);

			case "keybind":
				return (
					<KeybindInputWrapper
						value={value as Keybind}
						onChange={onChange}
					/>
				);

			case "select":
				switch (entry.style) {
					case "radio":
						setDisplayName(false);

						return (
							<RadioSelectWrapper<SelectValue>
								entry={entry}
								value={value as SelectValue}
								onChange={onChange}
							/>
						);

					case "dropdown":
					default:
						return (
							<SelectWrapper<SelectValue>
								entry={entry}
								value={value as SelectValue}
								onChange={onChange}
							/>
						);
				}

			case "multiselect":
				return (
					<MultiSelectWrapper<MultiSelectValue>
						entry={entry}
						value={value as MultiSelectValue[]}
						onChange={onChange}
					/>
				);

			case "orderselect":
				setVertical(true);

				return (
					<OrderSelect
						value={value as OrderSelect.Value}
						options={entry.options}
						onChange={onChange}
					/>
				);

			case "togglegroup":
				setDisplayName(false);

				return (
					<ToggleGroup
						value={value as ToggleGroup.Value}
						labels={entry.labels}
						onChange={onChange}
					/>
				);

			default:
				throw Error(
					`SettingsRow doesn't have a render method for a type "${type}"`,
				);
		}
	}, [entry, onChange, t, value]);

	return (
		<Flex
			dir={vertical ? "column" : "row"}
			justify={vertical ? "start" : "space-between"}
			align={vertical ? "stretch" : "center"}
			gaps={vertical ? "5px*" : "20px*"}
			sizes="1fr auto"
		>
			{displayName && (
				<div
					style={{
						lineHeight: "24px",
					}}
				>
					{name}
				</div>
			)}
			{row}
		</Flex>
	);
}

export default SettingsRow;
