// ? chat interface
import {
	UsersModalTabType as _UsersModalTabType,
	ModalType as _ModalType,
	MessagesTypeKeys as _MessagesTypeKeys,
	ScheduleActualType as _ScheduleActualType,
	ScheduleRequestData as _ScheduleRequestData,
	ContextMenuCoordinate as _ContextMenuCoordinate,
} from "./Chat/chats/interface";
import {
	MessagesTypeFilter as _MessagesTypeFilter,
	ModalFilter as _ModalFilter,
	BodyFilter as _BodyFilter,
} from "./Chat/filter/interface";
// ? executers interface
import { State as _DataExecuters } from "./Executors/data/interface";
import {
	ExecuterFilter as _ExecuterFilter,
	OrderInterfase as _OrderExecutersInterfase,
} from "./Executors/filter/interface";
// ? parking interface
import { ParkingFilter as _ParkingFilter } from "./Parking/filter/interface";
// ? map interface
import {
	OrderTab as _OrderTab,
	DefaultExecutorsFilters as _DefaultExecutorsFilters,
	State as _MapFilterAndSettings,
} from "./Map/filter/interface";
import { BtnOnMapToggles as _BtnOnMapToggles } from "./Map/toggles/interface";
// ? map modal add order interface
import {
	BtnOnMapToggles as _BtnOnMapFromModalAddToggles,
	BtnOnFooterToggles as _BtnOnFooterToggles,
} from "./MapFromAddModal/toggles/interface";
// ? tabs interface
import { OrderType as _OrderType } from "./Tabs/filter/interface";

export namespace Orders {
	export namespace Chat {
		/* types */
		export type UsersModalTabType = _UsersModalTabType;
		export type ModalType = _ModalType;
		export type MessagesTypeKeys = _MessagesTypeKeys;
		export type ScheduleActualType = _ScheduleActualType;
		export type MessagesTypeFilter = _MessagesTypeFilter;

		/* interfaces */
		export interface ScheduleRequestData extends _ScheduleRequestData {}
		export interface ContextMenuCoordinate extends _ContextMenuCoordinate {}
		export interface ModalFilter extends _ModalFilter {}
		export interface BodyFilter extends _BodyFilter {}
	}

	export namespace Executors {
		export interface DataExecuters extends _DataExecuters {}
		export interface ExecuterFilter extends _ExecuterFilter {}
		export interface OrderExecutersInterfase
			extends _OrderExecutersInterfase {}
	}

	export namespace Parking {
		export interface ParkingFilter extends _ParkingFilter {}
	}

	export namespace Map {
		export type OrderTab = _OrderTab;
		export interface DefaultExecutorsFilters
			extends _DefaultExecutorsFilters {}
		export interface MapFilterAndSettings extends _MapFilterAndSettings {}
		export interface BtnOnMapToggles extends _BtnOnMapToggles {}
	}

	export namespace MapFromAddModal {
		export interface BtnOnMapToggles extends _BtnOnMapFromModalAddToggles {}
		export interface BtnOnFooterToggles extends _BtnOnFooterToggles {}
	}

	export namespace Tabs {
		export type OrderType = _OrderType;
	}

	export const ConstantOrderSlice = {
		CHAT_CHATS: "orders/chat/chats",
		CHAT_FILTERS: "orders/chat/filter",
		PARKING_DATA: "orders/parking/data",
		PARKING_FILTERS: "orders/parking/filter",
		PARKING_TABLE_SETTINGS: "orders/parking/tableSettings",
		EXECUTORS_DATA: "orders/executors/data",
		EXECUTORS_FILTERS: "order/executors/filter",
		EXECUTORS_TABLE_SETTINGS: "orders/executors/tableSettings",
		MAP_FILTERS: "order/map/filter",
		MAP_TOGGLES: "order/map/toggles",
		MAP_FROM_ADD_MODEL_TOGGLES: "order/mapFromAddModel/toggles",
		TABS_FILTERS: "order/tabs/filter",
	};
}
