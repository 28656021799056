import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";

import { OrderFormProps } from "../../../types/FormProps";
import { PaymentAccounts } from "../../../../../../../../../redux/services/Order/getCustomerByPhone";

export interface BalancePaymentAccounts extends PaymentAccounts {}

export interface UseCustomerBalance {
	mainBalance: number | null;
	setMainBalance: Dispatch<SetStateAction<number | null>>;
	mainBalanceType: PaymentAccounts["type"] | null;
	setMainBalanceType: Dispatch<
		SetStateAction<PaymentAccounts["type"] | null>
	>;

	bonusBalance: number | null;
	setBonusBalance: Dispatch<SetStateAction<number | null>>;
	bonusBalanceType: PaymentAccounts["type"] | null;
	setBonusBalanceType: Dispatch<
		SetStateAction<PaymentAccounts["type"] | null>
	>;

	setPaymentAccounts: (arr: PaymentAccounts[]) => void;
	switchBalance: (type: PaymentAccounts["type"] | null) => void;
	resetBalance: () => void;
	currency: string | null;
	setCurrency: Dispatch<SetStateAction<string | null>>;
}

export type UseCustomerBalanceProps = (
	props: Pick<OrderFormProps, "form" | "tab">,
) => UseCustomerBalance;

/**
 *  `useCustomerBalance`
 */
export const useCustomerBalance: UseCustomerBalanceProps = ({ form, tab }) => {
	const [accounts, setAccounts] = useState<PaymentAccounts[]>([]);

	const [mainBalance, setMainBalance] =
		useState<UseCustomerBalance["mainBalance"]>(null);

	const [mainBalanceType, setMainBalanceType] = useState<
		PaymentAccounts["type"] | null
	>(null);

	const [bonusBalance, setBonusBalance] =
		useState<UseCustomerBalance["bonusBalance"]>(null);

	const [bonusBalanceType, setBonusBalanceType] = useState<
		PaymentAccounts["type"] | null
	>(null);

	const [currency, setCurrency] =
		useState<UseCustomerBalance["currency"]>(null);

	const resetBalance = useCallback(() => {
		setMainBalance(null);
		setMainBalanceType(null);
		setCurrency(null);
		setBonusBalance(null);
		setBonusBalanceType(null);
		if (tab.form.counterparty) return;
		form.setValue("paymentAccount", undefined);
	}, [
		form,
		setBonusBalance,
		setBonusBalanceType,
		setCurrency,
		setMainBalance,
		setMainBalanceType,
		tab.form,
	]);

	const setPaymentAccounts = useCallback<
		UseCustomerBalance["setPaymentAccounts"]
	>(
		(arr) => {
			if (!Array.isArray(arr)) {
				resetBalance();
				setAccounts([]);
				return;
			}
			setAccounts(arr);
			const main = arr.find((item) => item.type === "main");
			const bonus = arr.find((item) => item.type === "bonus");

			if (bonus) {
				setBonusBalance(bonus.amount);
				setBonusBalanceType(bonus.type);
				if (!tab.form.counterparty) {
					form.setValue("paymentAccount", {
						id: bonus.id,
						amount: bonus.amount,
						type: bonus.type,
					});
					form.setValue("bonusPaymentAccount", bonus);
				}
			}

			if (main) {
				setMainBalance(main.amount);
				setMainBalanceType(main.type);
				if (!tab.form.counterparty) {
					form.setValue("mainPaymentAccount", main);
				}
			}
		},
		[
			tab.form.counterparty,
			form,
			setAccounts,
			resetBalance,
			setMainBalance,
			setMainBalanceType,
			setBonusBalance,
			setBonusBalanceType,
		],
	);

	const switchBalance = useCallback<UseCustomerBalance["switchBalance"]>(
		(type) => {
			const exist = accounts.find((item) => item.type === type);

			if (exist) {
				setMainBalance(exist.amount);
				setMainBalanceType(exist.type);
				form.setValue("paymentAccount", {
					id: exist.id,
					amount: exist.amount,
					type: exist.type,
				});
			} else resetBalance();
		},
		[accounts, form, resetBalance, setMainBalance, setMainBalanceType],
	);

	useMemo(() => {
		if (!tab.form.counterparty) {
			const mainPaymentAccount = tab.form?.mainPaymentAccount;
			const bonusPaymentAccount = tab.form?.bonusPaymentAccount;

			if (bonusPaymentAccount) {
				const type = bonusPaymentAccount?.type || null;
				const amount = bonusPaymentAccount?.amount || 0;
				const currency = bonusPaymentAccount?.currency || "";
				setBonusBalance(amount);
				setBonusBalanceType(type);
				setCurrency(currency);
			}

			if (mainPaymentAccount) {
				const type = mainPaymentAccount?.type || null;
				const amount = mainPaymentAccount?.amount || 0;
				const currency = mainPaymentAccount?.currency || "";
				setMainBalance(amount);
				setMainBalanceType(type);
				setCurrency(currency);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		tab.form.counterparty,
		tab.form?.bonusPaymentAccount,
		tab.form?.mainPaymentAccount,
	]);

	return useMemo<UseCustomerBalance>(
		() => ({
			mainBalance,
			setMainBalance,
			mainBalanceType,
			setMainBalanceType,

			bonusBalance,
			setBonusBalance,
			bonusBalanceType,
			setBonusBalanceType,

			resetBalance,
			currency,
			setCurrency,
			setPaymentAccounts,
			switchBalance,
		}),
		[
			bonusBalance,
			bonusBalanceType,
			currency,
			mainBalance,
			mainBalanceType,
			resetBalance,
			setBonusBalance,
			setBonusBalanceType,
			setCurrency,
			setMainBalance,
			setMainBalanceType,
			setPaymentAccounts,
			switchBalance,
		],
	);
};
