import { TFunction } from "i18next";

const getTariffsScaleOptions = (t: TFunction): TariffScaleOption[] => [
	{
		key: 1,
		value: 1,
		label:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.ownTariff",
			) ?? "",
	},
	{
		key: 2,
		value: 2,
		label:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.extraChargeStandardTariff",
			) ?? "",
	},
	{
		key: 3,
		value: 3,
		label:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.coefficientStandardTariff",
			) ?? "",
	},
	{
		key: 4,
		value: 4,
		label:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.extraChargePreviousRule",
			) ?? "",
	},
	{
		key: 5,
		value: 5,
		label:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.coefficientPreviousRule",
			) ?? "",
	},
]; // tariffsScaleOptions

const getTariffsScaleAddBlock = (t: TFunction): TariffScaleAddBlockOption[] => [
	{
		id: "baseSettlement",
		textContent:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.baseSettlement",
			) ?? "",
	},
	{
		id: "outsideSettlement",
		textContent:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.outsideSettlement",
			) ?? "",
	},
	{
		id: "outsideSettlementRoundTrip",
		textContent:
			t(
				"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.tariffs.outsideSettlementRoundTrip",
			) ?? "",
	},
]; // tariffsScaleAddBlock

type TariffScaleOption = {
	key: number;
	value: number;
	label: string;
};

type TariffScaleAddBlockOption = {
	id: string;
	textContent: string;
};

export { getTariffsScaleOptions, getTariffsScaleAddBlock };
