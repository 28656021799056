import React, { memo, useCallback, useMemo } from "react";
import { Option, Row, Select } from "uikit";
import { useTranslation } from "react-i18next";
import tPath from "../../../../../../../../../../constants/tPath";
import { MapResolutions } from "../../../../../../../../../../../../../../../../services/MapSettings/types";
import { mapResolutionOptions } from "../../../../constants";

const path = `${tPath}.modal.tabs.main.mapServices`;

const MapService: React.FC<MapService.Props> = memo(
	({ options, label, placeholder, value, onChange }) => {
		const { t } = useTranslation();

		const handleValueChange = useCallback(
			(newValue) => {
				onChange(newValue);
			},
			[onChange],
		);

		const tOptions = useMemo(
			() =>
				(options ?? mapResolutionOptions).map((o) => ({
					...o,
					label: t(`${path}.options.${o.key}`),
				})),
			[options, t],
		);

		return (
			<Row align="center" gaps="8px*" sizes="1fr 1fr">
				<div>{label}</div>
				<Select
					placeholder={
						placeholder ?? (t(`${path}.placeholder`) as string)
					}
					options={tOptions}
					value={value}
					onChange={handleValueChange}
				/>
			</Row>
		);
	},
);

declare namespace MapService {
	interface Props {
		options?: Option<string | number>[];
		label: string;
		placeholder?: string;
		value: Value;
		onChange: (value: Value) => void;
	}

	type Value =
		| MapResolutions
		| (MapResolutions.GOOGLE | MapResolutions.HERE_MAPS);
}

export default MapService;
