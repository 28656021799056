import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Column,
	DateAndTimePicker,
	Option,
	Row,
	Stepper,
	TextBox,
	TextSelect,
} from "uikit";
import moment from "moment";

import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import useDatePickerLocale from "../../../../../../../../../../../../../../../../hooks/useDatePickerLocale";

import Root from "./components/Root";
import FeeSetting from "./components/FeeSetting";

export const marginLeft = { marginLeft: 30 };

const Content: React.FC<Content.Props> = memo(() => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const language = useTypedSelector((state) => state.session.language);

	const [name, setName] = useState("");
	const [stepperValue, setStepperValue] = useState(1);
	const [selectedPeriod, setSelectedPeriod] = useState<Option<string>>({
		key: 0,
		label: "",
		value: "",
	});
	const [selectedMode, setSelectedMode] = useState<Option<string>>({
		key: 0,
		label: "",
		value: "",
	});
	const [period, setPeriod] = useState(moment().startOf("day").toDate());

	const periodType = useMemo(
		() => [
			{
				key: 1,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str200",
					) ?? "",
				value: "days",
			},
			{
				key: 2,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str201",
					) ?? "",
				value: "weeks",
			},
			{
				key: 3,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str202",
					) ?? "",
				value: "months",
			},
			{
				key: 4,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str203",
					) ?? "",
				value: "shift",
			},
		],
		[t],
	);

	const modes = useMemo(
		() => [
			{
				key: 1,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str204",
					) ?? "",
				value: "afterOrderClosed",
			},
			{
				key: 2,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str205",
					) ?? "",
				value: "afterOrderPlacement",
			},
			{
				key: 3,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str206",
					) ?? "",
				value: "afterPeriodStart",
			},
			{
				key: 4,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str207",
					) ?? "",
				value: "afterPeriodEnd",
			},
			{
				key: 5,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str208",
					) ?? "",
				value: "afterShiftOpen",
			},
			{
				key: 6,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str209",
					) ?? "",
				value: "afterShiftClose",
			},
		],
		[t],
	);

	const feeType = useMemo(
		() => [
			{
				key: 1,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str210",
					) ?? "",
				value: "subscriptionFee",
			},
			{
				key: 2,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str211",
					) ?? "",
				value: "rental",
			},
			{
				key: 3,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str212",
					) ?? "",
				value: "radioStationRental",
			},
			{
				key: 4,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str213",
					) ?? "",
				value: "additionalSubscriptionFee",
			},
		],
		[t],
	);

	const feePeriod = useMemo(
		() => [
			{
				key: 1,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str214",
					) ?? "",
				value: "perDay",
			},
			{
				key: 2,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str215",
					) ?? "",
				value: "perPeriod",
			},
			{
				key: 3,
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str216",
					) ?? "",
				value: "perShift",
			},
		],
		[t],
	);
	const feeModesOptions = useMemo(() => {
		if (selectedPeriod.value === "shift") {
			return modes.filter(
				(mode) =>
					mode.value === "afterShiftOpen" ||
					mode.value === "afterShiftClose",
			);
		}
		return modes.filter(
			(mode) =>
				mode.value !== "afterShiftOpen" &&
				mode.value !== "afterShiftClose",
		);
	}, [selectedPeriod.value]);

	const onSelectPeriod = useCallback(
		(option: Option<string>) => {
			if (option.value !== "days") {
				setStepperValue(1);
			}
			if (option.value === "shift" && selectedPeriod.value !== "shift") {
				setSelectedMode({
					key: 0,
					label: "",
					value: "",
				});
			}
			if (option.value !== "shift" && selectedPeriod.value === "shift") {
				setSelectedMode({
					key: 0,
					label: "",
					value: "",
				});
			}
			setSelectedPeriod(option);
		},
		[selectedPeriod.value],
	);

	const onSelectMode = useCallback((option: Option<string>) => {
		setSelectedMode(option);
	}, []);

	const onChangeStepperValue = useCallback((value) => {
		setStepperValue(value);
	}, []);

	const getDayOfWeek = useCallback(
		(date) => {
			moment.locale(language);
			const dayOfWeek = moment(date).format("dddd");
			return dayOfWeek;
		},
		[language],
	);

	return (
		<Root hasPaddings>
			<Column gaps="27px*">
				<Row gaps="8px">
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str0",
							) ?? ""
						}
					>
						<TextBox.TextBox
							value={name}
							onChange={setName}
							placeholder={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str0",
								) ?? ""
							}
						/>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str2",
							) ?? ""
						}
					>
						<TextSelect
							options={feeType}
							placeholder={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str2",
								) ?? ""
							}
						/>
					</LabeledField>
				</Row>
				<Row gaps="8px">
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str4",
							) ?? ""
						}
					>
						<Row gaps="5px" align="center">
							<DateAndTimePicker
								value={period}
								onChange={(value) => setPeriod(value as any)}
								locale={locale}
							/>
							<span style={{ cursor: "default" }}>
								{getDayOfWeek(period)}
							</span>
						</Row>
					</LabeledField>
					<LabeledField
						label={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str5",
							) ?? ""
						}
					>
						<Row align="center" gaps="5px">
							<TextSelect
								options={periodType}
								value={selectedPeriod.label}
								onSelect={onSelectPeriod}
								placeholder={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str5",
									) ?? ""
								}
							/>
							<Stepper
								min={1}
								value={stepperValue}
								onChange={onChangeStepperValue}
								disabled={selectedPeriod.value !== "days"}
							/>
						</Row>
					</LabeledField>
				</Row>
				<LabeledField
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str7",
						) ?? ""
					}
				>
					<TextSelect
						value={selectedMode.label}
						onSelect={onSelectMode}
						options={feeModesOptions}
						placeholder={
							t(
								"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.membershipFee.modal.content.str7",
							) ?? ""
						}
					/>
				</LabeledField>
				<FeeSetting options={feePeriod} />
			</Column>
		</Root>
	);
});

declare namespace Content {
	interface Props {
		// value: Value;
		// onChange: (x: Value) => void;
	}

	interface Value {
		name: string;
		cost: number;
	}
}

export default Content;
