import React, { RefAttributes, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextBox, react } from "uikit";
import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import tPath from "../../../../../../constants/tPath";
import Controller from "./Controller";

const NameBase = react.withController<Name.PropsBase, Controller>((props) => {
	const { name, setName, controller } = props;

	const { t } = useTranslation();

	const [error, setError] = useState(false);

	const handleNameUpdate = useCallback(
		(newName) => {
			setError(false);
			setName(newName);
		},
		[setName],
	);

	controller.setContext({ value: name, setError });

	return (
		<LabeledField label={t(`${tPath}.modal.name`) || ""}>
			<TextBox.TextBox
				error={error}
				placeholder={t(`${tPath}.modal.name`) || ""}
				value={name}
				onChange={handleNameUpdate}
			/>
		</LabeledField>
	);
}, Controller);

const Name = memo(NameBase);

declare namespace Name {
	type Ref = Controller | null;

	interface PropsBase {
		name: string;
		setName: (x: string) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default Name;
