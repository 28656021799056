import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import { CheckBox, Stepper } from "uikit";

import useObjectEditor from "../../../hooks/useObjectEditor";
import {
	StyledGrid,
	StyledGridItem,
	StyledRow,
	StyledSpan,
	Style,
} from "../styles";
import ValueStepperBase, { ValueStepper } from "../ValueStepper";
import LiteStepper from "../LiteStepper";

const CheckBoxWithStepperBase: React.FC<CheckBoxWithStepperBase.Props> = ({
	value,
	onChange,
	disabled = false,
	disabledStepper = false,
	title,
	titleStepper,
	textAfterStepper,
	stepperSetting,
	liteStepperSetting,
	lite,
	...props
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const valueData = valueEditor.useGetter("value");
	const setValue = valueEditor.useSetter("value");
	const data = useMemo(() => {
		if (titleStepper === "units.second" || titleStepper === "units.km") {
			return valueData / 1e3;
		}

		if (
			titleStepper === "units.minute" ||
			titleStepper === "units.minutes"
		) {
			return valueData / 60e3;
		}

		return valueData;
	}, [titleStepper, valueData]);

	const handelValue = useCallback(
		(value: Stepper.Value) => {
			if (isUndefined(value)) {
				setValue(0);
				return;
			}
			if (
				titleStepper === "units.second" ||
				titleStepper === "units.km"
			) {
				setValue(isUndefined(value) ? 0 : value * 1e3);
				return;
			}

			if (
				titleStepper === "units.minute" ||
				titleStepper === "units.minutes"
			) {
				setValue(isUndefined(value) ? 0 : value * 60e3);
				return;
			}

			setValue(isUndefined(value) ? 0 : value);
		},
		[setValue, titleStepper],
	);

	const texts = useMemo(
		() => ({
			title: t(title),
			titleStepper: t([titleStepper || ""]),
			after: t(textAfterStepper || ""),
		}),
		[t, title, titleStepper, textAfterStepper],
	);

	const colorText = useMemo(() => (active ? "#21333f" : "#647079"), [active]);

	const stepperError = useMemo(() => {
		if (stepperSetting?.error)
			return `div {
							border-color: red;
						}`;

		return "";
	}, [stepperSetting?.error]);

	return (
		<StyledGrid
			areas="'checkbox stepper'"
			alignItems="center"
			gap="10px"
			columns="auto 1fr"
			{...props}
		>
			<StyledGridItem area="checkbox" gap="10px">
				<StyledRow alignItems="center" gap="10px">
					<CheckBox
						value={active}
						onChange={setActive}
						disabled={disabled}
					/>
					<StyledSpan colors={colorText}>{texts.title}</StyledSpan>
				</StyledRow>
			</StyledGridItem>
			<StyledGridItem area="stepper" gap="10px">
				<StyledRow alignItems="center" gap="10px" css={stepperError}>
					{!lite && (
						<ValueStepper
							min={0}
							decimalCount={0}
							width="60px"
							{...stepperSetting}
							value={data}
							disabled={!active || disabled || disabledStepper}
							onChange={handelValue}
						/>
					)}
					{lite && (
						<LiteStepper
							min={0}
							w="60px"
							{...liteStepperSetting}
							value={data}
							disabled={!active || disabled || disabledStepper}
							onChange={handelValue}
						/>
					)}
					<StyledSpan colors={colorText}>
						{texts.titleStepper}
						{texts.after}
					</StyledSpan>
				</StyledRow>
			</StyledGridItem>
		</StyledGrid>
	);
};

export const CheckBoxWithStepper = memo(CheckBoxWithStepperBase);

declare namespace CheckBoxWithStepperBase {
	interface Value extends Record<string, any> {
		active: boolean;
		value: number;
	}

	interface Props extends Partial<Style.Grid> {
		value: Value;
		onChange: (value: Value) => void;

		disabledStepper?: boolean;
		disabled?: boolean;

		title: string | string[];

		lite?: boolean;

		liteStepperSetting?: Omit<
			LiteStepper.Props,
			"disabled" | "value" | "onChange"
		>;
		stepperSetting?: Omit<
			ValueStepperBase.Props,
			"disabled" | "value" | "onChange"
		>;

		textAfterStepper?: string | string[];
		titleStepper?:
			| "units.pieces"
			| "units.millisecond"
			| "units.second"
			| "units.minute"
			| "units.hour"
			| "units.minutes"
			| "units.hours"
			| "units.day"
			| "units.kph"
			| "units.km"
			| "units.meter"
			| "units.mps";
	}
}

export default CheckBoxWithStepperBase;
