import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import Executor, {
	ExecutorSubscribeType,
} from "../../../../../../../services/Executor";
import Language from "../../../../../../../services/Language";
import { Orders } from "../../../../../../../redux/reducers/Orders";
import { baseFilters } from "../../../../../../../redux/reducers/Orders/Chat/filter";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import {
	MultiSelectExecutorWithModal,
	MultiSelectExecutorGroupWithModal,
	MultiSelectCarParkWithModal,
	StyledColumn,
	StyledRow,
} from "../../../../../../common";

import { Content, Header } from "./components";

const Filter: React.FC<Filter.Props> = ({
	filter,
	setFilter,
	language,
	onClose,
	onSave,
}) => {
	const { t } = useTranslation();

	const [companyAndTaxiServiceError, setCompanyAndTaxiServiceError] =
		useState({
			taxiService: false,
			company: false,
		});
	const [requestData, setRequestData] =
		useState<Orders.Chat.ModalFilter>(baseFilters);

	const clearErrors = useCallback(() => {
		setCompanyAndTaxiServiceError({ company: false, taxiService: false });
	}, []);

	useLayoutEffect(() => {
		setRequestData(filter);
	}, [filter]);

	const clearFilters = useCallback(() => {
		setRequestData(baseFilters);
		clearErrors();
	}, [clearErrors]);

	const valueEditor = useObjectEditor(requestData, setRequestData);

	const fleetIds = valueEditor.useGetter("fleetIds");
	const setFleetIds = valueEditor.useSetter("fleetIds");

	const executorIds = valueEditor.useGetter("executorIds");
	const setExecutorIds = valueEditor.useSetter("executorIds");

	const executorGroupIds = valueEditor.useGetter("executorGroupIds");
	const setExecutorGroupIds = valueEditor.useSetter("executorGroupIds");

	const companyIds = valueEditor.useGetter("companyIds");
	const setCompanyIds = valueEditor.useSetter("companyIds");

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			setCompanyIds(companyIds);
		},
		[setCompanyIds],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			setTaxiServiceIds(taxiServiceIds);
		},
		[setTaxiServiceIds],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIdsFilter,
		taxiServiceIdsFilter,
		setCompanyIdsFilter,
		setTaxiServiceIdsFilter,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyIds,
		taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const saveFilter = useCallback(() => {
		if (!requestData.taxiServiceIds.length)
			companyAndTaxiServiceError.taxiService = true;

		if (!requestData.companyIds.length)
			companyAndTaxiServiceError.company = true;

		if (
			!requestData.taxiServiceIds.length ||
			!requestData.companyIds.length
		) {
			return setCompanyAndTaxiServiceError(() => ({
				...companyAndTaxiServiceError,
			}));
		}

		setFilter(requestData);
		onSave(requestData);
		onClose();
		clearErrors();
		return true;
	}, [
		clearErrors,
		companyAndTaxiServiceError,
		onClose,
		onSave,
		requestData,
		setFilter,
	]);

	const cancelFilter = useCallback(() => {
		onClose();
		clearErrors();
	}, [clearErrors, onClose]);

	const optionsExceptionWithModal = useMemo(() => {
		const filterTaxiServiceIds: number[] = [];

		if (taxiServiceIds) {
			for (let i = 0; i < taxiServiceIds.length; i++) {
				const id = taxiServiceIds[i];
				if (id !== "all") {
					filterTaxiServiceIds.push(Number(id));
				}
			}
		}

		const retval: Executor.SubscribeOptions = {
			order: { callSign: "ASC" },
			taxiServiceIds: filterTaxiServiceIds,
			subscribeType: ExecutorSubscribeType.MAIN_ORDER_PARKING,
		};

		return retval;
	}, [taxiServiceIds]);

	return (
		<StyledColumn
			gap="16px 20px"
			w="500px"
			p="16px 20px"
			br="5px"
			bgC="#ffffff"
			shadow="0px 2px 10px rgba(0, 0, 0, 0.15)"
		>
			<Header onClose={onClose} />
			<StyledColumn gap="12px">
				<Content
					language={language}
					possibleCompanies={possibleCompanies}
					possibleTaxiServices={possibleTaxiServices}
					companyIds={companyIdsFilter}
					taxiServiceIds={taxiServiceIdsFilter}
					setCompanyIds={setCompanyIdsFilter}
					setTaxiServiceIds={setTaxiServiceIdsFilter}
					error={companyAndTaxiServiceError}
					onChangeError={setCompanyAndTaxiServiceError}
				/>

				<MultiSelectExecutorWithModal
					value={executorIds}
					onChange={setExecutorIds}
					titleText={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str200",
						) ?? ""
					}
					modalSetting={{
						zIndex: 999,
						containerId: "root",
					}}
					options={optionsExceptionWithModal}
				/>
				<MultiSelectExecutorGroupWithModal
					value={executorGroupIds}
					onChange={setExecutorGroupIds}
					titleText={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str201",
						) ?? ""
					}
					modalSetting={{
						zIndex: 999,
						containerId: "root",
					}}
				/>
				<MultiSelectCarParkWithModal
					value={fleetIds}
					onChange={setFleetIds}
					titleText={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str202",
						) ?? ""
					}
					modalSetting={{
						zIndex: 999,
						containerId: "root",
					}}
				/>
			</StyledColumn>
			<StyledRow justify="space-between">
				<Button.Button
					icon={<Icon size={18} id="refresh" />}
					variant="secondary"
					onClick={clearFilters}
				/>
				<StyledRow gap="20px">
					<Button.Button
						variant="secondary"
						text={t("cancel") || "Cancel"}
						onClick={cancelFilter}
					/>
					<Button.Button
						text={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.str0",
							) ?? ""
						}
						onClick={saveFilter}
					/>
				</StyledRow>
			</StyledRow>
		</StyledColumn>
	);
};

declare namespace Filter {
	interface Props {
		language: Language;
		filter: Orders.Chat.ModalFilter;
		setFilter: React.Dispatch<
			React.SetStateAction<Orders.Chat.ModalFilter>
		>;
		onClose: () => void;
		onSave: (data: Orders.Chat.ModalFilter) => void;
	}
}

export default Filter;
