import { TFunction } from "i18next";

import MultiSelectWithModal from "../../../../../../../../../../../../components/MultiSelectWithModal";

export type OrderTypeOptions =
	| "cashOrders"
	| "nonCashOrders"
	| "suburbanOrders"
	| "onlineOrders"
	| "bonusOrders"
	| "executorOrder"
	| "cardPaymentToTaxiService"
	| "cardPaymentToExecutor"
	| "terminalToTaxiService"
	| "terminalToExecutor"
	| "localTransfer"
	| "globalTransfer"
	| "externalTransfer";

export const getOrderTypeOptions = (
	t: TFunction,
): MultiSelectWithModal.Option<OrderTypeOptions>[] => [
	{
		key: "cashOrders",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.cashOrders",
			) ?? "",
		value: "cashOrders",
	},
	{
		key: "nonCashOrders",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.nonCashOrders",
			) ?? "",
		value: "nonCashOrders",
	},
	{
		key: "suburbanOrders",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.suburbanOrders",
			) ?? "",
		value: "suburbanOrders",
	},
	{
		key: "onlineOrders",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.onlineOrders",
			) ?? "",
		value: "onlineOrders",
	},
	{
		key: "bonusOrders",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.bonusOrders",
			) ?? "",
		value: "bonusOrders",
	},
	{
		key: "executorOrder",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.executorOrder",
			) ?? "",
		value: "executorOrder",
	},
	{
		key: "cardPaymentToTaxiService",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.cardPaymentToTaxiService",
			) ?? "",
		value: "cardPaymentToTaxiService",
	},
	{
		key: "cardPaymentToExecutor",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.cardPaymentToExecutor",
			) ?? "",
		value: "cardPaymentToExecutor",
	},
	{
		key: "terminalToTaxiService",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.terminalToTaxiService",
			) ?? "",
		value: "terminalToTaxiService",
	},
	{
		key: "terminalToExecutor",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.terminalToExecutor",
			) ?? "",
		value: "terminalToExecutor",
	},
	{
		key: "localTransfer",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.localTransfer",
			) ?? "",
		value: "localTransfer",
	},
	{
		key: "globalTransfer",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.globalTransfer",
			) ?? "",
		value: "globalTransfer",
	},
	{
		key: "externalTransfer",
		label:
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.mainTab.orderTypeOptions.externalTransfer",
			) ?? "",
		value: "externalTransfer",
	},
]; // orderTypeOptions
