import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	padding: 15px 24px;

	overflow: hidden;
`;

export default Root;
