import React, { MouseEvent, ReactNode, memo, useCallback } from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";
import Title from "./components/Title";
import CloseButton from "./components/CloseButton";

const Header = memo<Header.Props>(({ draggable, title, onClose, action }) => {
	const rootOnMouseDown = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
	}, []);

	return (
		<Root
			draggable={draggable}
			className="draggable-area"
			sizes="auto! 1fr auto!"
			gaps="7px*"
			align="center"
			maxedWidth
			onMouseDown={rootOnMouseDown}
		>
			<Icon
				id="dragging"
				size={12}
				colors={[theme.colors.disabled_text]}
			/>
			<Title>{title ?? ""}</Title>
			{action}
			<CloseButton onClick={onClose} />
		</Root>
	);
});

declare namespace Header {
	interface Props {
		draggable: boolean;
		title?: ReactNode;
		action?: ReactNode;

		onClose: () => void;
	}
}

export default Header;
