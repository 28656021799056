import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<Root.Props>`
	position: relative;

	width: 100%;
	height: 100%;

	padding: 24px;

	text-align: center;

	overflow: hidden;

	background: ${({ disabled }) =>
		disabled ? theme.colors.disabled_filled : theme.colors.white};

	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&:before {
		content: "";

		position: absolute;

		top: -4px;
		bottom: -4px;
		left: -4px;
		right: -4px;

		border: 5px dashed #d3d4db;
		border-radius: 4px;
	}
`;

declare namespace Root {
	interface Props {
		disabled: boolean;
	}
}

export default Root;
