import React, { useCallback, memo } from "react";
import { Button } from "uikit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import OrderStatus from "../../../../../../../../../../types/OrderStatus";
import { StyledRow } from "../../../../../../../../../../components/common";
import { useArchiveOrders, useArchiveSwitcher } from "../../../../../../hooks";

const ButtonFooter = styled(Button.Button)`
	height: clamp(29px, 3.9vh, 32px);
`;

declare namespace ArchiveInfoModalFooter {
	interface Props {}
}

const ArchiveInfoModalFooter: React.FC<ArchiveInfoModalFooter.Props> = () => {
	const { t } = useTranslation();
	const {
		orderToggles: {
			isInfoModal,
			isDisabledOrderForm,
			isSaveOrderForm,
			isButtonSaveOrderForm,
			isCancelOrderForm,
		},
		setInfoModalToggle,
		setDisabledOrderFormToggle,
		setSaveOrderForm,
		setButtonSaveOrderForm,
		setCancelOrderForm,
	} = useArchiveSwitcher();

	const {
		orders: { activePoint, order },
		player: { toggle },
		history: { history },
		setActivePoint,
		setPlayerToggle,
		setOrderHistory,
	} = useArchiveOrders();

	const onCloseModal = useCallback(() => {
		if (isInfoModal) setInfoModalToggle("off");
		if (isDisabledOrderForm) setDisabledOrderFormToggle("off");
		if (isButtonSaveOrderForm) setButtonSaveOrderForm("off");
		if (activePoint !== -1) setActivePoint(-1);
		if (toggle) setPlayerToggle("off");
		if (history.length) setOrderHistory([]);
	}, [
		isInfoModal,
		setInfoModalToggle,
		isDisabledOrderForm,
		setDisabledOrderFormToggle,
		isButtonSaveOrderForm,
		setButtonSaveOrderForm,
		activePoint,
		setActivePoint,
		toggle,
		setPlayerToggle,
		history.length,
		setOrderHistory,
	]);

	const onDisabledOrderForm = useCallback(() => {
		setDisabledOrderFormToggle("toggle");
		if (isButtonSaveOrderForm) setButtonSaveOrderForm("off");
	}, [
		isButtonSaveOrderForm,
		setButtonSaveOrderForm,
		setDisabledOrderFormToggle,
	]);

	const onSaveOrderForm = useCallback(() => {
		if (!isSaveOrderForm) setSaveOrderForm("on");
		if (isButtonSaveOrderForm) setButtonSaveOrderForm("off");
	}, [
		isButtonSaveOrderForm,
		isSaveOrderForm,
		setButtonSaveOrderForm,
		setSaveOrderForm,
	]);

	const onCancelOrderForm = useCallback(() => {
		if (!isCancelOrderForm) setCancelOrderForm("on");
		if (isButtonSaveOrderForm) setButtonSaveOrderForm("off");
	}, [
		isButtonSaveOrderForm,
		isCancelOrderForm,
		setButtonSaveOrderForm,
		setCancelOrderForm,
	]);

	return (
		<StyledRow
			justify="end"
			alignItems="center"
			gap="0 10px"
			p="0 10px"
			w="100%"
			h="100%"
			over="hidden"
		>
			<ButtonFooter
				disabled={order?.status === OrderStatus.CLOSED}
				variant={isDisabledOrderForm ? "primary" : "secondary"}
				text={t(`edit`) || "Edit"}
				onClick={onDisabledOrderForm}
			/>

			<ButtonFooter
				disabled={!isButtonSaveOrderForm}
				variant={isButtonSaveOrderForm ? "negative" : "secondary"}
				text={t(`cancel`) || "Сancel"}
				onClick={onCancelOrderForm}
			/>
			<ButtonFooter
				disabled={!isButtonSaveOrderForm}
				variant={isButtonSaveOrderForm ? "primary" : "secondary"}
				text={t(`save`) || "Save"}
				onClick={onSaveOrderForm}
				style={{ marginRight: "auto" }}
			/>

			<ButtonFooter
				disabled={isButtonSaveOrderForm}
				style={{ marginLeft: "auto" }}
				variant="primary"
				text={t(`close`) || "Close"}
				onClick={onCloseModal}
			/>
		</StyledRow>
	);
};

export const ArchiveInfoModalFooterMemo = memo(ArchiveInfoModalFooter);
export default ArchiveInfoModalFooter;
