import { Dispatch, RefObject } from "react";
import { react } from "uikit";

import TaxiServiceForm from "../../../../../../../../../../../../components/TaxiServiceForm";
import { NamesBase } from "../../../../../../../../../../../../components/common";

import Form from ".";

interface Context {
	value?: Form.Value;

	setSectorMultiSelectError: Dispatch<boolean>;

	taxiServiceFormRef: RefObject<TaxiServiceForm.Controller>;
	nameFormRef: RefObject<NamesBase.Controller>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameFormValid = this.context?.nameFormRef.current?.validate();

		const isSectorMultiSelectValid =
			(this.context?.value?.sectorIds?.length ?? 0) !== 0;

		if (!isSectorMultiSelectValid)
			this.context?.setSectorMultiSelectError(true);

		const isTaxiServiceFormValid =
			this.context?.taxiServiceFormRef.current?.validate();

		return (
			isTaxiServiceFormValid &&
			isSectorMultiSelectValid &&
			isNameFormValid
		);
	}
}
