const data = [
	{
		label: "Общие итого",
		value: "1",
		data: "c: 28.02.2023 14:55:00 по: 28.02.2023 16:08:00",
		children: [
			{
				label: "Итоги по расстоянию",
				value: "1-1",
				data: "0.0 км",
				children: [
					{
						label: "На заказе",
						value: "1-1-1",
						data: "0.0 км (0.0%)",
						children: [
							{
								label: "Подача",
								value: "1-1-1-1",
								data: "0.0 км (0.0%)",
							},
							{
								label: "Выполнение",
								value: "1-1-1-2",
								data: "0.0 км (0.0%)",
							},
						],
					},
					{
						label: "Не на заказе",
						value: "1-1-2",
						data: "0.0 км (0.0%)",
						children: [
							{
								label: "Свободен",
								value: "1-1-2-1",
								data: "0.0 км (0.0%)",
							},
							{
								label: "Обед",
								value: "1-1-2-2",
								data: "0.0 км (0.0%)",
							},
						],
					},
					{
						label: "Нахождение на стоянке",
						value: "1-1-3",
						data: "0.0 км (0.0%)",
					},
					{
						label: "Периоды выхода из приложения",
						value: "1-1-4",
						data: "0.0 км (0.0%)",
					},
					{
						label: "Отсутствие связи",
						value: "1-1-5",
						data: "0.0 км (0.0%)",
					},
				],
			},
			{
				label: "Итоги по продолжительности",
				value: "1-2",
				data: "1 час 12 мин 26 сек",
				children: [
					{
						label: "На заказе",
						value: "1-2-1",
						data: "0 час 0 мин 0 сек (0.0%)",
						children: [
							{
								label: "Подача",
								value: "1-2-1-1",
								data: "0 час 0 мин 0 сек (0.0%)",
							},
							{
								label: "Выполнение",
								value: "1-2-1-2",
								data: "0 час 0 мин 0 сек (0.0%)",
							},
						],
					},
					{
						label: "Не на заказе",
						value: "1-2-2",
						data: "1 час 12 мин 26 сек (100.0%)",
						children: [
							{
								label: "Свободен",
								value: "1-2-2-1",
								data: "0 час 0 мин 0 сек (0.0%)",
							},
							{
								label: "Обед",
								value: "1-2-2-2",
								data: "0 час 0 мин 0 сек (100.0%)",
							},
						],
					},
					{
						label: "Нахождение на стоянке",
						value: "1-2-3",
						data: "0 час 0 мин 0 сек (0.0%)",
					},
					{
						label: "Периоды выхода из приложения",
						value: "1-2-4",
						data: "0 час 0 мин 0 сек (0.0%)",
					},
					{
						label: "Отсутствие связи",
						value: "1-2-5",
						data: "0 час 0 мин 0 сек (0.0%)",
					},
				],
			},
			{
				label: "Итоги по суммам заказов",
				value: "1-3",
				data: "1000.00 грн",
				children: [
					{
						label: "Наличные",
						value: "1-3-1",
						data: "1000.00 грн",
					},
				],
			},
			{
				label: "Итоги по суммам штрафов",
				value: "1-4",
				data: "0.00 грн",
			},
		],
	},
	{
		label: "Смены",
		value: "2",
		data: "1",
		children: [
			{
				label: "Смена",
				value: "2-1",
				data: "c: 28.02.2023 14:55:34 по: 28.02.2023 16:08:00",
				children: [
					{
						label: "Продолжительность",
						value: "2-1-1",
						data: "1 час 12 мин 26 сек",
					},
					{
						label: "ФИО водителя/диспетчера",
						value: "2-1-2",
						data: "начал смену: Федор Штрек закончил смену: Федор Штрек",
					},
					{
						label: "Итоги по смене",
						value: "2-1-3",
						data: "",
						children: [
							{
								label: "Итоги по расстоянию",
								value: "2-1-3-1",
								data: "0.0 км",
								children: [
									{
										label: "На заказе",
										value: "2-1-3-1-1",
										data: "0.0 км (0.0%)",
										children: [
											{
												label: "Подача",
												value: "2-1-3-1-1-1",
												data: "0.0 км (0.0%)",
											},
											{
												label: "Выполнение",
												value: "2-1-3-1-1-2",
												data: "0.0 км (0.0%)",
											},
										],
									},
									{
										label: "Не на заказе",
										value: "2-1-3-1-2",
										data: "0.0 км (0.0%)",
										children: [
											{
												label: "Свободен",
												value: "2-1-3-1-2-1",
												data: "0.0 км (0.0%)",
											},
											{
												label: "Обед",
												value: "2-1-3-1-2-2",
												data: "0.0 км (0.0%)",
											},
										],
									},
									{
										label: "Нахождение на стоянке",
										value: "2-1-3-1-3",
										data: "0.0 км (0.0%)",
									},
									{
										label: "Периоды выхода из приложения",
										value: "2-1-3-1-4",
										data: "0.0 км (0.0%)",
									},
									{
										label: "Отсутствие связи",
										value: "2-1-3-1-5",
										data: "0.0 км (0.0%)",
									},
								],
							},
							{
								label: "Итоги по продолжительности",
								value: "2-1-3-2",
								data: "1 час 12 мин 26 сек",
								children: [
									{
										label: "На заказе",
										value: "2-1-3-2-1",
										data: "0 час 0 мин 0 сек (0.0%)",
										children: [
											{
												label: "Подача",
												value: "2-1-3-2-1-1",
												data: "0 час 0 мин 0 сек (0.0%)",
											},
											{
												label: "Выполнение",
												value: "2-1-3-2-1-2",
												data: "0 час 0 мин 0 сек (0.0%)",
											},
										],
									},
									{
										label: "Не на заказе",
										value: "2-1-3-2-2",
										data: "1 час 12 мин 26 сек (100.0%)",
										children: [
											{
												label: "Свободен",
												value: "2-1-3-2-2-1",
												data: "0 час 0 мин 0 сек (0.0%)",
											},
											{
												label: "Обед",
												value: "2-1-3-2-2-2",
												data: "0 час 0 мин 0 сек (100.0%)",
											},
										],
									},
									{
										label: "Нахождение на стоянке",
										value: "2-1-3-2-3",
										data: "0 час 0 мин 0 сек (0.0%)",
									},
									{
										label: "Периоды выхода из приложения",
										value: "2-1-3-2-4",
										data: "0 час 0 мин 0 сек (0.0%)",
									},
									{
										label: "Отсутствие связи",
										value: "2-1-3-2-5",
										data: "0 час 0 мин 0 сек (0.0%)",
									},
								],
							},
							{
								label: "Итоги по суммам заказов",
								value: "2-1-3-3",
								data: "1000.00 грн",
								children: [
									{
										label: "Наличные",
										value: "2-1-3-3-1",
										data: "1000.00 грн",
									},
								],
							},
							{
								label: "Итоги по суммам штрафов",
								value: "2-1-3-4",
								data: "0.00 грн",
							},
						],
					},
				],
			},
		],
	},
];

export default data;
