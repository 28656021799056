/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import {
	NumberEntry,
	DataKind,
	EntryList,
} from "../../../types/settingEntries";

export interface BaseFontEntries<Data extends DataKind> {
	tableFontSize: NumberEntry<Data>;
}

export type FontEntries<Data extends DataKind> = BaseFontEntries<Data> &
	EntryList<Data, never, never>;
export type FontMeta = FontEntries<"meta">;
export type FontState = FontEntries<"value">;
export type FontValue = FontEntries<any>[keyof FontEntries<any>];

export const fontMeta: FontMeta = {
	tableFontSize: {
		type: "number",
		unit: "px",
	},
};

const initialState: FontState = {
	tableFontSize: 16,
};

type Reducer<P> = CaseReducer<FontState, PayloadAction<P>>;

const setAll: Reducer<FontState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const fonts = createSlice({
	name: "fonts",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default fonts;
