import styled from "styled-components";
import { theme } from "uikit";

const Row = styled.div<{ active: boolean; background?: string }>`
	padding: 1px 6px;
	color: ${(props) => (props.active ? props.color : "lightgray")} !important;
	background: ${(props) => props?.background || "transparent"};
	height: 100%;
	width: 100%;
	display: flex;
	gap: 5px;
	font-size: 12px;
	white-space: nowrap;
	align-items: center;
	justify-content: flex-start;

	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	${theme.longWord}
`;

export default Row;
