import constants from "../../../constants/Accounts/Executors";

const initialState = {
	executors: [],
};

export default function executors(state = initialState, action: any = {}) {
	switch (action.type) {
		case constants.setExecutors:
			return {
				...state,
				executors: action.payload,
			};
		default:
			return state;
	}
}
