import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, theme } from "uikit";

import Call from "../../../../../../../../../../services/Call";
import { StyledRow } from "../../../../../../../../../../components/common";
import CustomWhisper from "../../../../../../../../../../components/CustomWhisper";
import CustomTooltip from "../../../../../../../../../../components/CustomTooltip";
import tPath from "../../../../constants/tPath";

const path = `${tPath}.callTypes`;

const Type: React.FC<Type.Props> = memo(({ type }) => {
	const { t } = useTranslation();

	const icon = useMemo(() => {
		switch (type) {
			case "IN_ANSWER":
			case "IN_COME":
				return (
					<CustomWhisper
						speaker={
							<CustomTooltip>
								{t(`${path}.incoming`)}
							</CustomTooltip>
						}
					>
						<Icon
							id="phone-call-incoming"
							size={30}
							colors={["#6dd453"]}
						/>
					</CustomWhisper>
				);

			case "OUT_ANSWER":
			case "OUT_COME":
			case "NEW_CALL":
				return (
					<CustomWhisper
						speaker={
							<CustomTooltip>
								{t(`${path}.outgoing`)}
							</CustomTooltip>
						}
					>
						<Icon
							id="phone-call-outgoing"
							size={30}
							colors={[theme.colors.accent_hover]}
						/>
					</CustomWhisper>
				);

			case "IN_NO_ANSWER":
			case "IN_RECALLED":
				return (
					<CustomWhisper
						speaker={
							<CustomTooltip>
								{t(`${path}.cancelled`)}
							</CustomTooltip>
						}
					>
						<Icon
							id="phone-call-incoming"
							size={30}
							colors={[theme.colors.error]}
						/>
					</CustomWhisper>
				);

			case "OUT_NO_ANSWER":
				return (
					<CustomWhisper
						speaker={
							<CustomTooltip>
								{t(`${path}.cancelled`)}
							</CustomTooltip>
						}
					>
						<Icon
							id="phone-call-outgoing"
							size={30}
							colors={[theme.colors.error]}
						/>
					</CustomWhisper>
				);

			default:
				return null;
		}
	}, [t, type]);

	return (
		<StyledRow justify="center" alignItems="center" m="3 0 0 0">
			{icon}
		</StyledRow>
	);
});

declare namespace Type {
	interface Props {
		type: Call.Model["type"];
	}
}

export default Type;
