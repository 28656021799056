export namespace Accounting {
	export const ConstantAccountingSlice = {
		EXECUTORS_ERRORS: "accounting/executors/errors",
		EXECUTORS_TOGGLES: "accounting/executors/toggles",
		EXECUTORS_LOADINGS: "accounting/executors/loadings",
		EXECUTORS_FILTERS: "accounting/executors/filters",
		EXECUTORS: "accounting/executors",
	};
	export type TypeOrderSlice = typeof ConstantAccountingSlice;
	export type ValueAccountingSlice =
		(typeof ConstantAccountingSlice)[keyof typeof ConstantAccountingSlice];
}
