import React from "react";

import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import {
	StyledColumn,
	StyledRow,
} from "../../../../../../../../components/common";
import { Tab } from "../../types/Tab";

import IntercityRoadTrip from "./IntercityRoadTrip";
import RateIncludePickupCost from "./RateIncludePickupCost";
import Hourly from "./Hourly";
import IncludePickupMargin from "./IncludePickupMargin";
import Taximeter from "./Taximeter";

interface OrderRideInformationProps {
	form: UseCreateFormReturn<Schema>;
	tab: Tab;
	disabled: boolean;
}

const OrderRideInformation: React.FC<OrderRideInformationProps> = ({
	disabled,
	form,
	tab,
}) => (
	<StyledRow bgC="#F6F6F6">
		<StyledColumn
			flex={{ wrap: "wrap" }}
			gap="2px 12px"
			h="44px"
			p="2px 12px"
			justify="start"
			alignItems="start"
			bgC="#F6F6F6"
			w="max-content"
		>
			<RateIncludePickupCost disabled={disabled} form={form} />
			<IntercityRoadTrip disabled={disabled} form={form} />
			<IncludePickupMargin disabled={disabled} form={form} />
			<Hourly disabled={disabled} form={form} />
			<Taximeter disabled={disabled} form={form} tab={tab} />
		</StyledColumn>
	</StyledRow>
);

export default OrderRideInformation;
