import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, react } from "uikit";
import { assign, clone, noop } from "lodash";
import Language from "../../../../../../../../../../../../../../services/Language";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import InternalController from "./Controller";

const Access = react.withController<Access.Props, Access.Controller>(
	({ controller, value, disabled, language, onChange }) => {
		const { t } = useTranslation();

		const currentValueRef = useRef(value);

		currentValueRef.current = value;

		const setField = useCallback(
			<Field extends keyof Access.Value>(
				field: Field,
				fieldValue: Access.Value[Field],
			) => {
				currentValueRef.current = assign(
					clone(currentValueRef.current),
					{
						[field]: fieldValue,
					},
				);

				onChange(currentValueRef.current);
			},
			[onChange],
		);

		const onChangeCompanyIds = useCallback(
			(companyIds: number[] | ["all"]) => {
				setCompanyIdsError(false);

				setField("companyIds", companyIds);
			},
			[setField],
		);

		const [
			possibleCompanies,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			possibleTaxiServices,
			companyIds,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			taxiServiceIds,
			setCompanyIds,
		] = useCompanyAndTaxiServiceIdsFilter(
			value.companyIds,
			[],
			onChangeCompanyIds,
			noop,
		);

		const [companyIdsError, setCompanyIdsError] = useState(false);

		const possibleCompanyOptions = useMemo(
			() =>
				possibleCompanies.map((company) => ({
					key: company.id,
					label: company.name[language],
					value: company.id,
				})),
			[language, possibleCompanies],
		);

		const companyIdsOnChange = useCallback(
			(newCompanyIds: MultiSelect.Value) => {
				setCompanyIds(newCompanyIds as number[]);
			},
			[setCompanyIds],
		);

		controller.setContext({
			value,

			setCompanyIdsError,
		});

		return (
			<>
				<LabeledField
					label={
						t(
							"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.access.str0",
						) ?? ""
					}
				>
					<MultiSelect
						value={companyIds}
						disabled={disabled}
						all
						placeholder={
							t(
								"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.access.str0",
							) ?? ""
						}
						error={companyIdsError}
						options={possibleCompanyOptions}
						onChange={companyIdsOnChange}
					/>
				</LabeledField>
			</>
		);
	},
	InternalController,
);

declare namespace Access {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		companyIds: number[] | ["all"];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Access;
