import { useMemo } from "react";

import { AdditionalTariff } from "../services";

import { useModelSubscribe } from ".";

/**
 * Hook to get the global currency setting for the project based on subscription to currency data.
 * The global currency can be changed in Settings -> Tariffs -> Additional -> Global Settings.
 *
 * @returns {string} - The name of the selected currency or an empty string if not set.
 */
const useCurrencyGlobalSettings = () => {
	const AdditionalTariffSubscribe = useModelSubscribe({}, AdditionalTariff);

	const settingsCurrency = useMemo(() => {
		const defaultView = "";
		const models = AdditionalTariffSubscribe.models ?? [];

		if (models.length === 0) return defaultView;

		const firstModel = models[0];
		const currencySettings = firstModel?.currency?.settings;
		const selectedCurrencyStyle =
			firstModel?.additionalFields?.general?.currency?.displayStyle;

		if (!currencySettings || !selectedCurrencyStyle) return defaultView;

		const currencyName =
			currencySettings[selectedCurrencyStyle] || defaultView;

		return currencyName;
	}, [AdditionalTariffSubscribe.models]);

	return settingsCurrency;
};

export default useCurrencyGlobalSettings;
