/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Float, Icon, Popover as PopoverBase, Row } from "uikit";
import { useTranslation } from "react-i18next";
import { assign, clone } from "lodash";

import Language from "../../../../../../../services/Language";
import TaxiService from "../../../../../../../services/TaxiService";
import { ExecutorStatus } from "../../../../../../../services/Executor";
import { ParkingFilter } from "../../../../../../../redux/reducers/OrdersPage/Filters";
import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import StoreList from "../../../../../../../types/StoreList";

import { Content, Header, Root } from "./components";

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	// TODO: fix useSize in Float/index.tsx, idk
	const floatSize = { width: 1000, height: 242 }; // float.getSize();

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

const Popover: React.FC<Popover.Props> = ({
	filter,
	taxiServiceData,
	taxiServiceIdsPosition,
	language,
	setFilter,
	open,
	onClose,
}) => {
	const { t } = useTranslation();

	const [companyAndTaxiServiceError, setCompanyAndTaxiServiceError] =
		useState({
			taxiService: false,
			company: false,
		});

	const clearErrors = useCallback(() => {
		setCompanyAndTaxiServiceError({ company: false, taxiService: false });
	}, []);

	const [requestData, setRequestData] = useState<ParkingFilter>({
		companyIds: [],
		taxiServiceIds: [],
		taxiServiceIdsPosition: [],
		statuses: [],
		online: [],
		workingStatus: [],
		isYourOrderStatus: false,
	});

	const [executorsStatuses, setExecutorsStatuses] = useState<
		Content.ExecutorStatus[]
	>(filter.statuses as Content.ExecutorStatus[]);

	const [companyAndTaxiServiceIds, setCompanyAndTaxiServiceIds] =
		useState<Popover.Filter>({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});

	const [taxiServiceIdsTablePosition, setTaxiServiceIdsTablePosition] =
		useState<number[]>(taxiServiceIdsPosition);

	const [isYourOrderStatus, setIsYourOrderStatus] = useState(
		filter.isYourOrderStatus,
	);

	const valueEditor = useObjectEditor(
		companyAndTaxiServiceIds,
		setCompanyAndTaxiServiceIds,
	);
	const companyAndTaxiServiceValue = valueEditor.usePicker([
		"companyIds",
		"taxiServiceIds",
	]);

	const currentValueRef = useRef(companyAndTaxiServiceValue);

	currentValueRef.current = companyAndTaxiServiceValue;

	const accessOnChange = useCallback(
		(accessValue: Popover.Filter) => {
			const assignValue: Partial<Popover.Filter> = {
				...accessValue,
			};

			valueEditor.assign(assignValue);
		},
		[valueEditor],
	);

	const setField = useCallback(
		<Field extends keyof Popover.Filter>(
			field: Field,
			fieldValue: Popover.Filter[Field],
		) => {
			currentValueRef.current = assign(clone(currentValueRef.current), {
				[field]: fieldValue,
			});

			accessOnChange(currentValueRef.current);
		},
		[accessOnChange],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			// setTaxiServiceIdsError(false);

			setField("taxiServiceIds", taxiServiceIds);
		},
		[setField],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			// setCompanyIdsError(false);

			setField("companyIds", companyIds);
		},
		[setField],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyAndTaxiServiceValue.companyIds,
		companyAndTaxiServiceValue.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const clearFilters = useCallback(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: ["all"],
			taxiServiceIds: ["all"],
		});
		setExecutorsStatuses([]);
		setOnlineStatus([]);
		setWorkingStatus([]);
		setIsYourOrderStatus(false);
		setTaxiServiceIdsTablePosition(taxiServiceIdsPosition);
		clearErrors();
	}, [clearErrors, taxiServiceIdsPosition]);

	const apply = useCallback(() => {
		if (!requestData.taxiServiceIds.length)
			companyAndTaxiServiceError.taxiService = true;
		if (!requestData.companyIds.length)
			companyAndTaxiServiceError.company = true;
		if (
			!requestData.taxiServiceIds.length ||
			!requestData.companyIds.length
		)
			return setCompanyAndTaxiServiceError(() => ({
				...companyAndTaxiServiceError,
			}));
		setFilter({
			...requestData,
			query: filter.query,
		});
		onClose();
		clearErrors();
	}, [
		clearErrors,
		companyAndTaxiServiceError,
		filter.query,
		onClose,
		requestData,
		setFilter,
	]);

	const [onlineStatus, setOnlineStatus] = useState(filter.online);

	const updateFilters = useCallback(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
		setExecutorsStatuses(filter.statuses as ExecutorStatus[]);
		setOnlineStatus(filter.online);
		setWorkingStatus(filter.workingStatus);
		setIsYourOrderStatus(filter.isYourOrderStatus);
		setTaxiServiceIdsTablePosition(taxiServiceIdsPosition);
		clearErrors();
	}, [
		clearErrors,
		filter.companyIds,
		filter.isYourOrderStatus,
		filter.online,
		filter.statuses,
		filter.taxiServiceIds,
		filter.workingStatus,
		taxiServiceIdsPosition,
	]);

	const [workingStatus, setWorkingStatus] = useState(filter.workingStatus);

	const cancel = useCallback(() => {
		updateFilters();
		onClose();
		clearErrors();
	}, [clearErrors, onClose, updateFilters]);

	useEffect(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
		setTaxiServiceIdsTablePosition(taxiServiceIdsPosition);
	}, [filter.companyIds, filter.taxiServiceIds, taxiServiceIdsPosition]);

	return (
		<PopoverBase
			open={open}
			containerId="root"
			trackerId="filters"
			offset={additionalFiltersOffsetCallback}
			onClose={cancel}
			style={{ boxShadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
		>
			<Root align="stretch" gaps="16px 20px">
				<Header onClose={cancel} />

				<Content
					taxiServiceData={taxiServiceData}
					taxiServiceIdsPosition={taxiServiceIdsPosition}
					language={language}
					onChangeRequestData={setRequestData}
					possibleCompanies={possibleCompanies}
					possibleTaxiServices={possibleTaxiServices}
					companyIds={companyIds}
					taxiServiceIds={taxiServiceIds}
					setCompanyIds={setCompanyIds}
					setTaxiServiceIds={setTaxiServiceIds}
					taxiServiceIdsTablePosition={taxiServiceIdsTablePosition}
					setTaxiServiceIdsTablePosition={
						setTaxiServiceIdsTablePosition
					}
					executorsStatuses={executorsStatuses}
					setExecutorsStatuses={setExecutorsStatuses}
					error={companyAndTaxiServiceError}
					onChangeError={setCompanyAndTaxiServiceError}
					onlineStatus={onlineStatus}
					setOnlineStatus={setOnlineStatus}
					workingStatus={workingStatus}
					setWorkingStatus={setWorkingStatus}
					isYourOrderStatus={isYourOrderStatus}
					setIsYourOrderStatus={setIsYourOrderStatus}
				/>

				<Row justify="space-between">
					<Button.Button
						icon={<Icon size={18} id="refresh" />}
						variant="secondary"
						onClick={clearFilters}
					/>
					<Row gaps="20px">
						<Button.Button
							variant="secondary"
							text={t("cancel") || "Cancel"}
							onClick={cancel}
						/>
						<Button.Button
							text={
								t(
									"orderPageWidgets.parking.filter.popover.str0",
								) ?? ""
							}
							onClick={apply}
						/>
					</Row>
				</Row>
			</Root>
		</PopoverBase>
	);
};

declare namespace Popover {
	interface Filter {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
	}

	interface Props {
		taxiServiceData: StoreList<TaxiService.Model> | undefined;
		taxiServiceIdsPosition: number[];
		language: Language;
		filter: ParkingFilter;
		setFilter: (parkingFilter: ParkingFilter) => void;
		open: boolean;
		onClose: () => void;
	}
}

export default Popover;
