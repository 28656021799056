/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface State {
	loading: boolean;
	data: DataItem[];
}

type Reducer<P> = CaseReducer<State, PayloadAction<P>>;

export interface DataItem {
	taxiServiceIds: any[];
	active: boolean;
	activeModeDelay: number;
	addPlus: boolean;
	alphaName: string;
	destAddrNpi: string;
	destAddrTon: string;
	enquireLink: number;
	host: string;
	id: number;
	isEnquireLink: boolean;
	isSystemType: boolean;
	login: string;
	name: string;
	password: string;
	port: number;
	sourceAddrNpi: string;
	sourceAddrTon: string;
	systemType: string;
	v: number;
}

const initialState: State = {
	loading: false,
	data: [],
};

const setData: Reducer<State["data"]> = (state, { payload }) => {
	state.data = payload;
};

const messageProviderSms = createSlice({
	name: "messageProviderSms",
	initialState,
	reducers: {
		setData,
	},
});

export default messageProviderSms;
