import styled from "styled-components";
import { theme } from "uikit";

const TextInput = styled.input`
	all: unset;

	width: 100%;

	font-family: Lato;
	font-size: 13px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;

	color: ${theme.colors.primary};

	&::placeholder {
		color: #9ba3a8;
	}
`;

export default TextInput;
