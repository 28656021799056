import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Column, Icon, theme } from "uikit";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../redux/store";
import softphoneWidget from "../../../../redux/reducers/softphoneWidget";
import CallButton from "./components";
import {
	getAndCallDown,
	callUp,
	toggleHold,
	toggleMute,
} from "../../../../utils/jsSip";

export const RightButtonsStyled = styled(Column)<{ focus: boolean }>`
	padding: 2px 0px 2px 2px;

	width: 44px;

	border-radius: 0px 8px 8px 0px;
	background-color: #f5f5f5;

	opacity: ${({ focus }) => (focus ? 1 : 0.8)};
	pointer-events: auto;
	z-index: 20;
`;

export const RightButton = styled(Button.Button)<{ active?: boolean }>`
	width: 40px;
	height: 40px;

	border-radius: 8px;
	background-color: ${({ active }) => (active ? "#E3F1F7" : "transparent")};
	&:hover {
		background-color: ${({ active }) =>
			active ? "#E3F1F7" : "transparent"};
	}
`;

const RightButtons = () => {
	const dispatch = useDispatch();
	const { selectedCall, numberQuery, isFocused, rerender } = useTypedSelector(
		(state) => state.softphoneWidget,
	);

	const onToggleMute = useCallback(() => {
		toggleMute();
		dispatch(softphoneWidget.actions.rerenderSoftphone());
	}, [dispatch]);

	const onToggleHold = useCallback(() => {
		toggleHold();
		dispatch(softphoneWidget.actions.rerenderSoftphone());
	}, [dispatch]);

	const openTransfer = useCallback(() => {
		dispatch(softphoneWidget.actions.setTransfer("normal"));
	}, [dispatch]);

	const openBlindTransfer = useCallback(() => {
		dispatch(softphoneWidget.actions.setTransfer("blind"));
	}, [dispatch]);

	const callButtonValidation = useMemo(() => {
		if (numberQuery.trim() !== "") {
			return false;
		}

		if (selectedCall && selectedCall.session) {
			// eslint-disable-next-line camelcase
			const { _is_confirmed, _direction } = selectedCall.session;

			// eslint-disable-next-line camelcase
			if (_is_confirmed) {
				return true;
			}
			if (_direction === "incoming") {
				return false;
			}
			if (_direction === "outgoing") {
				return true;
			}
		}
		return true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberQuery, selectedCall, rerender]);

	const callDown = useCallback(() => getAndCallDown(), []);

	return (
		<RightButtonsStyled focus={isFocused} gaps="2px*">
			<RightButton
				active={selectedCall?.session._localHold}
				onClick={onToggleHold}
				disabled={!selectedCall?.session._is_confirmed}
				transparent={
					!selectedCall?.session._is_confirmed || !selectedCall
				}
				icon={
					<Icon
						id="phone-pause"
						size={24}
						colors={
							!selectedCall?.session._is_confirmed ||
							!selectedCall
								? [theme.colors.disabled_text]
								: [
										selectedCall?.session._localHold
											? theme.colors.accent
											: theme.colors.secondary,
								  ]
						}
					/>
				}
			/>
			<RightButton
				active={selectedCall?.session._audioMuted}
				onClick={onToggleMute}
				icon={
					<Icon
						id={
							selectedCall?.session._audioMuted
								? "phone-micro-mute"
								: "phone-micro"
						}
						size={24}
						colors={
							!selectedCall?.session._is_confirmed ||
							!selectedCall
								? [theme.colors.disabled_text]
								: [
										selectedCall?.session._audioMuted
											? theme.colors.accent
											: theme.colors.secondary,
								  ]
						}
					/>
				}
				disabled={!selectedCall?.session._is_confirmed}
				transparent={
					!selectedCall?.session._is_confirmed || !selectedCall
				}
			/>
			<RightButton
				transparent={!selectedCall?.session._is_confirmed}
				disabled={!selectedCall?.session._is_confirmed}
				onClick={openTransfer}
				icon={
					<Icon
						id="phone-redirection"
						size={24}
						colors={
							!selectedCall?.session._is_confirmed
								? [theme.colors.disabled_text]
								: [theme.colors.secondary]
						}
					/>
				}
			/>
			<RightButton
				onClick={openBlindTransfer}
				transparent={!selectedCall?.session._is_confirmed}
				disabled={!selectedCall?.session._is_confirmed}
				icon={
					<Icon
						id="phone-redirection-anonymous"
						size={24}
						colors={
							!selectedCall?.session._is_confirmed
								? [theme.colors.disabled_text]
								: [theme.colors.secondary]
						}
					/>
				}
			/>
			<CallButton
				disabled={callButtonValidation}
				bgColor="#4CAF50"
				iconColor={theme.colors.white}
				iconName="call-start"
				onClick={callUp}
			/>
			<CallButton
				disabled={!selectedCall}
				bgColor={theme.colors.negative}
				iconColor={theme.colors.white}
				iconName="call-end"
				onClick={callDown}
			/>
		</RightButtonsStyled>
	);
};

export default RightButtons;
