/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */

import React, { useMemo } from "react";
import { Column, HR, Row, theme } from "uikit";
import { useTranslation } from "react-i18next";
import typedKeys from "../../../utils/typedKeys";
import {
	EntryGroup,
	EntryList,
	SettingValue,
} from "../../../types/settingEntries";
import SettingsRow from "../Row";

interface Props<
	SelectValue,
	MultiSelectValue,
	State extends EntryList<"value", SelectValue, MultiSelectValue>,
	Meta extends EntryList<"meta", SelectValue, MultiSelectValue>,
> {
	state: State;
	meta: Meta;
	name: (key: keyof State) => string;
	onChange: (
		value: SettingValue<SelectValue, MultiSelectValue>,
		key: keyof State,
	) => void;
	groups?: EntryGroup<Meta>[];
}

function SettingList<
	SelectValue,
	MultiSelectValue,
	State extends EntryList<"value", SelectValue, MultiSelectValue>,
	Meta extends EntryList<"meta", SelectValue, MultiSelectValue>,
>({
	state,
	meta,
	name,
	onChange,
	groups,
}: Props<SelectValue, MultiSelectValue, State, Meta>) {
	const { t } = useTranslation();

	const options = useMemo(() => {
		if (groups) return [];

		const keys = typedKeys(meta);

		return keys.map((key) => ({
			name: name(key),
			key,
			entry: meta[key],
		}));
	}, [groups, meta, name]);

	const groupedOptions = useMemo(() => {
		if (!groups) return [];

		return groups.map((group) => ({
			...group,
			options: group.entryKeys.map((key) => ({
				name: name(key),
				key,
				entry: meta[key],
			})),
		}));
	}, [groups, meta, name]);

	return groups ? (
		<Column gaps="10px*">
			{groupedOptions.map((group, i) => (
				<Column gaps="20px*" key={i}>
					{i !== 0 && <HR />}

					{group.title.length !== 0 && (
						<Row
							gaps="5px*"
							style={{
								color: theme.colors.primary,
								fontSize: "16px",
							}}
						>
							{group.title.map((s, i) => (
								<>
									{i !== 0 && <>/</>}
									<b>{t(s)}</b>
								</>
							))}
						</Row>
					)}

					{group.options.map((option) => {
						const value = state[option.key];

						return (
							<SettingsRow<SelectValue, MultiSelectValue>
								key={option.key}
								name={option.name}
								entry={option.entry}
								value={value}
								onChange={(newValue) => {
									onChange(newValue, option.key);
								}}
							/>
						);
					})}
				</Column>
			))}
		</Column>
	) : (
		<Column gaps="20px*">
			{options.map((option) => {
				const value = state[option.key];

				return (
					<SettingsRow<SelectValue, MultiSelectValue>
						key={option.key}
						name={option.name}
						entry={option.entry}
						value={value}
						onChange={(newValue) => {
							onChange(newValue, option.key);
						}}
					/>
				);
			})}
		</Column>
	);
}

export default SettingList;
