import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	padding-top: 20px;
	width: 100%;
	height: 350px;
`;

export default Root;
