import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
	useObjectEditor,
	useCurrencyGlobalSettings,
} from "../../../../../../hooks";
import {
	StyledRow,
	StyledSpan,
	ValueStepper,
} from "../../../../../../components/common";
import tPath from "../../constants/tPath";
import { RateAdjustmentToDefault } from "../../constants/constants";
import SurchargeSelect from "../SurchareSelect";

const SuburbanPickupCost: React.FC<SuburbanPickupCost.Props> = memo(
	({ disabled, value, onChange }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const valueEditor = useObjectEditor(value, onChange);

		const adjustmentType = valueEditor.useGetter("adjustmentType");
		const setAdjustmentType = valueEditor.useSetter("adjustmentType");

		const adjustmentValue = valueEditor.useGetter("value");
		const setAdjustmentValue = valueEditor.useSetter("value");

		useEffect(() => {
			if (
				adjustmentType === RateAdjustmentToDefault.CUSTOM_VALUE &&
				adjustmentValue < 0
			) {
				setAdjustmentValue(0);
			}
		}, [adjustmentType, adjustmentValue, setAdjustmentValue]);

		return (
			<StyledRow gap="10px" alignItems="center">
				<StyledRow gap="10px" alignItems="center">
					<StyledSpan w="250px">
						{t(
							`${tPath}.mainModal.tabs.main.mainSettings.suburbanPickupCost`,
						)}
					</StyledSpan>
					<SurchargeSelect
						disabled={disabled}
						value={adjustmentType}
						onChange={setAdjustmentType}
					/>
					<StyledRow gap="10px" alignItems="center" w="125px">
						<ValueStepper
							min={
								adjustmentType ===
								RateAdjustmentToDefault.CUSTOM_VALUE
									? 0
									: undefined
							}
							value={adjustmentValue}
							onChange={setAdjustmentValue}
						/>
						{adjustmentType !==
							RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE &&
							currencyGlobalSettings}
					</StyledRow>
				</StyledRow>
			</StyledRow>
		);
	},
);

declare namespace SuburbanPickupCost {
	interface Props {
		disabled: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		adjustmentType: RateAdjustmentToDefault;
		value: number;
	}
}

export default SuburbanPickupCost;
