import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Select, react } from "uikit";
import { useTranslation } from "react-i18next";

import PaymentSystem, {
	PaymentProvider,
} from "../../../../../../services/PaymentSystem";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../components/LabeledField";

import InternalController from "./Controller";

const SelectPaymentSystemsBase = react.withController<
	SelectPaymentSystems.PropsBase,
	SelectPaymentSystems.Controller
>(({ value, onChange, controller }) => {
	const [error, setError] = useState<string>("");
	controller.setContext({ value, setError });

	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const paymentProvider = valueEditor.useGetter("paymentProvider");
	const paymentProviderSet = valueEditor.useSetter("paymentProvider");

	const selectOptionsWrap = useCallback(
		(item: { id: PaymentProvider; label: PaymentProvider }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);

	const options = useMemo(
		() =>
			Object.values(PaymentProvider)
				.filter((item) => item !== PaymentProvider.FONDY)
				.map((label) =>
					selectOptionsWrap({
						id: label,
						label,
					}),
				),
		[selectOptionsWrap],
	);

	const updateSelectedValues = useCallback(
		(value) => paymentProviderSet(value),
		[paymentProviderSet],
	);

	return (
		<LabeledField
			label={
				t(
					"pages.settings.pages.paymentSystem.selectPaymentSystems.str0",
				) ?? ""
			}
		>
			<Select
				style={{ width: "200px" }}
				placeholder={
					t(
						"pages.settings.pages.paymentSystem.selectPaymentSystems.str1",
					) ?? ""
				}
				error={error}
				value={paymentProvider}
				options={options}
				onChange={updateSelectedValues}
			/>
		</LabeledField>
	);
}, InternalController);
const SelectPaymentSystems = memo(SelectPaymentSystemsBase);

declare namespace SelectPaymentSystems {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default SelectPaymentSystems;
