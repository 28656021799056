import { useMemo } from "react";
import { Option } from "uikit";

import useModelSubscribe from "../../../../../../../../../../../../../hooks/useModelSubscribe2";
import CarClass from "../../../../../../../../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../../../../../../../../redux/store";

function useCarClasses() {
	const subscribeOptions = useMemo<CarClass.SubscribeOptions>(() => ({}), []);
	const lang = useTypedSelector((state) => state.session.language);
	const data = useModelSubscribe(subscribeOptions, CarClass);

	const items = useMemo(() => data.models ?? [], [data.models]);

	const selectOptions = useMemo<Option<number>[]>(
		() =>
			items.map((item) => ({
				key: item.id,
				value: item.id,
				label: item.name?.[lang],
			})),
		[items, lang],
	);

	return { data, items, selectOptions };
}

export default useCarClasses;
