import styled from "styled-components";
import { Column } from "uikit";

const SipTable = styled(Column)`
	max-height: 92px;

	overflow-y: scroll;
	background-color: transparent;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

export default SipTable;
