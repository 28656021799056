import React, { Suspense, memo } from "react";
import { Tooltip } from "react-leaflet";
import Marker from "react-leaflet-enhanced-marker";
import { LatLngExpression } from "leaflet";
import { Icon } from "uikit";

import { ConstantColors } from "../../../../redux/reducers/Archives/GPSLog/interface";

const MarkerItemBase: React.FC<MarkerItemBase.Props> = ({
	children,
	position,
	toggle,
	...props
}): JSX.Element => (
	<Suspense>
		<Marker
			position={position}
			icon={
				<Icon
					style={{
						stroke: toggle
							? ConstantColors.BLUE
							: ConstantColors.GREEN,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						margin: "0 auto",
						position: "relative",
						bottom: "10px",
					}}
					id={"marker-gps-point"}
					size={{ width: "35px", height: "50px" }}
				/>
			}
			{...props}
		>
			<Tooltip>{children}</Tooltip>
		</Marker>
	</Suspense>
);

declare namespace MarkerItemBase {
	interface Props {
		children?: React.ReactNode;
		childrenIcon?: React.ReactNode;
		position: LatLngExpression;
		toggle?: boolean | string;
	}
}

export const MarkerItem = memo(MarkerItemBase);
export default MarkerItemBase;
