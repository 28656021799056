import React, { Dispatch, useCallback } from "react";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import { columnProps } from "./constants";
import LightTable from "../../../LightTable";
import TableSettings from "../../../TableSettings";
import {
	useColumns,
	useSort,
	useVisibleColumns,
} from "../../../../hooks/useTableSettings";
import Client from "../../../../services/Client";
import { ColumnIds } from "../../../../constants/tables/types";

const defaultColumnIds: ColumnIds<"client"> = [
	"lastName",
	"firstName",
	"fatherName",
	"phones",
	"company",
	"notes",
];

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,

	disabled = false,
	data,
	loading,
	saveSlot,

	onChangeSelected,
}) => {
	const { t } = useTranslation();

	const columns = useColumns("client");
	const { columnIds, setColumnIds } = useVisibleColumns(
		saveSlot,
		"client",
		defaultColumnIds,
	);
	const { sortMethod, setSortMethod } = useSort(saveSlot, "client");

	const updateSelected = useCallback(
		(item: RowDataType<Client.Model>) => {
			const model = item as Client.Model;

			if (selected.includes(model.id)) {
				onChangeSelected(selected.filter((id) => model.id !== id));
			} else {
				onChangeSelected([...selected, model.id]);
			}
		},
		[selected, onChangeSelected],
	);

	const selectAllOnChange = useCallback(
		(value: boolean) => {
			if (value) {
				onChangeSelected(data.map((item) => item.id));
			} else {
				onChangeSelected([]);
			}
		},
		[onChangeSelected, data],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={46}
				rowHeight={46}
				loading={loading}
				data={data}
				sortColumn={sortMethod?.column}
				sortType={sortMethod?.type}
				onSortColumn={(column, type) => {
					setSortMethod({ type, column });
				}}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							disabled={disabled}
							onChange={selectAllOnChange}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								disabled={disabled}
								onChange={() => updateSelected(rowData)}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>
				{columnIds.map((columnId) => (
					<LightTable.Column
						resizable
						key={columnId}
						{...columnProps[columnId]}
					>
						<LightTable.HeaderCell verticalAlign="middle">
							{t(`tableColumns.customers.clients.${columnId}`)}
						</LightTable.HeaderCell>

						<LightTable.Cell
							verticalAlign="middle"
							fullText
							dataKey={columnId}
						>
							{(rowData) => rowData[columnId]}
						</LightTable.Cell>
					</LightTable.Column>
				))}
			</LightTable>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];

		disabled?: boolean;
		data: any[];
		loading: boolean;
		saveSlot: string;

		onChangeSelected: Dispatch<number[]>;
	}
}

export default ModelTable;
