import makeTable from "./makeTable";

const customersClients = makeTable({
	allIds: [
		"id",
		"balance",
		"bonusBalance",
		"bonusRides",
		"totalOrderCount",
		"completedOrderCount",
		"orderEstimationCount",
		"canceledOrderCount",
		"canceledOrderCountNoCarFound",
		"lastName",
		"firstName",
		"fatherName",
		"birthday",
		"gender",
		"app",
		"email",
		"phones",
		"mainLoyaltyProgram",
		"additionalLoyaltyProgram",
		"bonusCard",
		"rating",
		"tariff",
		"defaultCarClass",
		"status",
		"company2",
		"defaultTaxiService",
		"latestTaxiService",
		"notes",
		"group",
		"createdAt",
		"updatedAt",
		"deletedAt",
	],
	defaultIds: [
		"firstName",
		"phones",
		"totalOrderCount",
		"balance",
		"bonusBalance",
		"app",
		"company2",
		"defaultTaxiService",
		"notes",
		"createdAt",
		"status",
	],
	i18tPath: (id) => `mainPage.customers.clients.columns.${id}`,
	initialSortMethod: {
		column: "createdAt",
		type: "desc",
	},
	initialWidths: {
		id: 50,
		balance: 110,
		bonusBalance: 110,
		bonusRides: 110,
		totalOrderCount: 80,
		completedOrderCount: 125,
		orderEstimationCount: 125,
		canceledOrderCount: 125,
		canceledOrderCountNoCarFound: 125,
		lastName: 150,
		firstName: 150,
		fatherName: 150,
		birthday: 125,
		gender: 75,
		app: 65,
		email: 150,
		phones: 140,
		mainLoyaltyProgram: 100,
		additionalLoyaltyProgram: 100,
		bonusCard: 120,
		rating: 75,
		tariff: 150,
		defaultCarClass: 250,
		status: 100,
		company2: 140,
		defaultTaxiService: 140,
		latestTaxiService: 140,
		notes: 260,
		group: 150,
		createdAt: 175,
		updatedAt: 175,
		deletedAt: 175,
	},
	initialResizable: {
		id: true,
		balance: true,
		bonusBalance: true,
		bonusRides: true,
		totalOrderCount: true,
		completedOrderCount: true,
		orderEstimationCount: true,
		canceledOrderCount: true,
		canceledOrderCountNoCarFound: true,
		lastName: true,
		firstName: true,
		fatherName: true,
		birthday: true,
		gender: true,
		app: true,
		email: true,
		phones: true,
		mainLoyaltyProgram: true,
		additionalLoyaltyProgram: true,
		bonusCard: true,
		rating: true,
		tariff: true,
		defaultCarClass: true,
		status: true,
		company2: true,
		defaultTaxiService: true,
		latestTaxiService: true,
		notes: true,
		group: true,
		createdAt: true,
		updatedAt: true,
		deletedAt: true,
	},
	initialSortable: {
		id: true,
		balance: true,
		bonusBalance: true,
		bonusRides: false,
		totalOrderCount: true,
		completedOrderCount: true,
		orderEstimationCount: true,
		canceledOrderCount: true,
		canceledOrderCountNoCarFound: false,
		lastName: true,
		firstName: true,
		fatherName: true,
		birthday: false,
		gender: false,
		app: true,
		email: false,
		phones: true,
		mainLoyaltyProgram: false,
		additionalLoyaltyProgram: false,
		bonusCard: false,
		rating: false,
		tariff: false,
		defaultCarClass: false,
		status: true,
		company2: true,
		defaultTaxiService: true,
		latestTaxiService: true,
		notes: false,
		group: false,
		createdAt: true,
		updatedAt: true,
		deletedAt: true,
	},
});

export default customersClients;
