import React, { memo, useCallback, useState } from "react";
import { Button, Icon, Row } from "uikit";
import Player from "../Player";
import Call from "../../../../../../../../../../services/Call";
import { downloadViaURL } from "../../../../../../../../../../utils/file";

const Record: React.FC<Record.Props> = memo(({ playSoundUrl }) => {
	const [showModal, setShowModal] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const downloadSound = useCallback(async () => {
		if (playSoundUrl) downloadViaURL(playSoundUrl, "Record");
	}, [playSoundUrl]);

	const handleOpen = useCallback(() => {
		setShowModal(true);
		setIsPlaying(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowModal(false);
		setIsPlaying(false);
	}, []);

	return (
		<Row gaps="8px*">
			<Button.Button
				disabled={!playSoundUrl}
				variant="secondary"
				icon={<Icon id={isPlaying ? "pause" : "play"} size={20} />}
				onClick={handleOpen}
			/>
			<Button.Button
				disabled={!playSoundUrl}
				onClick={downloadSound}
				variant="secondary"
				icon={<Icon id={"save"} size={20} />}
			/>
			{showModal && (
				<Player
					onClose={handleClose}
					setIsPlaying={setIsPlaying}
					src={playSoundUrl}
				/>
			)}
		</Row>
	);
});

declare namespace Record {
	interface Props {
		playSoundUrl?: Call.Model["playSoundUrl"];
		downloadSoundUrl?: Call.Model["downloadSoundUrl"];
	}
}

export default Record;
