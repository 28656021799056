import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ParametersCellContent: React.FC<ParametersCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();

	const taximeter = useMemo(
		() => item?.additionalFields?.taximeter ?? {},
		[item?.additionalFields?.taximeter],
	);

	const rateSettings = useMemo(
		() => item.additionalFields.rateSettings ?? {},
		[item.additionalFields.rateSettings],
	);

	const rateSettingTypes = useMemo(
		() => Object.keys(rateSettings),
		[rateSettings],
	);

	const content = useMemo(() => {
		const usedRateSettingTypes = rateSettingTypes.filter(
			(rateSettingType) => rateSettings[rateSettingType],
		);

		if (taximeter) {
			usedRateSettingTypes.push("taximeter");
		}

		const value = usedRateSettingTypes
			.map((rateSettingType) => {
				if (rateSettingType === "taximeter") {
					return (
						t(
							"pages.mainPage.pages.orders.orderModal.orderRideInformation.str207",
						) ?? ""
					);
				}

				return t(`order_rate_setting.${rateSettingType}`) ?? "";
			})
			.join(", ");

		return value;
	}, [rateSettingTypes, rateSettings, t, taximeter]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ParametersCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ParametersCellContent;
