/* eslint-disable import/no-unresolved */

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CheckBoxWithContent, react, Row } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Root from "./components/Root";

const ObjectGroupFooter = react.inputify<
	ObjectGroupFooter.PropsBase,
	ObjectGroupFooter.Value
>(({ value, onChange, onCancel, onSave }) => {
	const { t } = useTranslation();

	return (
		<Root align="center" justify="space-between" gaps="10px">
			<Row gaps="24px*">
				<CheckBoxWithContent
					gap="10px"
					value={value?.active}
					onChange={(active) =>
						value && onChange({ ...value, active })
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupFooter.str130",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					gap="10px"
					value={value?.visible}
					onChange={(visible) =>
						value && onChange({ ...value, visible })
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupFooter.str131",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					gap="10px"
					value={value?.type === "street"}
					onChange={(type) =>
						value &&
						onChange({ ...value, type: type ? "street" : "object" })
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupFooter.str132",
					) ?? ""}
				</CheckBoxWithContent>
			</Row>
			<Row sizes="120px*" gaps="16px">
				<Button.Button
					variant="secondary"
					text={
						t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupFooter.str0",
						) ?? ""
					}
					onClick={onCancel}
				/>
				<Button.Button
					variant="primary"
					text={
						t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupFooter.str1",
						) ?? ""
					}
					onClick={onSave}
				/>
			</Row>
		</Root>
	);
});

declare namespace ObjectGroupFooter {
	interface Value {
		active?: boolean;
		visible?: boolean;
		type?: "object" | "street";
	}

	export interface PropsBase {
		onCancel: () => void;
		onSave: () => void;
	}

	type Props = PropsBase & InputifiedComponentProps<Value>;
}

export default ObjectGroupFooter;
