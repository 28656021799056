import { combineReducers } from "@reduxjs/toolkit";

import {
	reportAgentBillReportDataState,
	reportAgentBillReportDataReducer,
	reportAgentBillReportDataAction,
} from "./data";

export type StateAgentBillReport = {
	data: reportAgentBillReportDataState;
};

export const agentBillReport = combineReducers<StateAgentBillReport>({
	data: reportAgentBillReportDataReducer,
});

export const reportsAgentBillReportAction = {
	data: reportAgentBillReportDataAction,
};

export default agentBillReport;
