import React, { Dispatch, useCallback, useMemo } from "react";
import { isNil, isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import { MultiSelect, Option } from "uikit";

const multiSelectValueByKey: Record<
	NonNullable<ComparisonSelect.Value>,
	MultiSelect.Value
> = {
	greater: ["greater"],
	less: ["less"],
	equal: ["equal"],
	greaterOrEqual: ["greater", "equal"],
	lessOrEqual: ["less", "equal"],
};

const ComparisonSelect: React.FC<ComparisonSelect.Props> = ({
	value,
	disabled,
	onChange,
}) => {
	const { t } = useTranslation();

	const options: Option<"greater" | "less" | "equal">[] = useMemo(
		() => [
			{
				key: "greater",
				value: "greater",
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.comparisonSelect.str200",
					) ?? "",
			},
			{
				key: "less",
				value: "less",
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.comparisonSelect.str201",
					) ?? "",
			},
			{
				key: "equal",
				value: "equal",
				label:
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.comparisonSelect.str202",
					) ?? "",
			},
		],
		[t],
	);

	const multiSelectValue = useMemo(
		() => (isNil(value) ? [] : multiSelectValueByKey[value]),
		[value],
	);

	const multiSelectOnSelect = useCallback(
		(option: Option<"greater" | "less" | "equal">) => {
			if (isUndefined(onChange)) return;

			if (option.value !== "equal") {
				if (
					value === "equal" ||
					value === "greaterOrEqual" ||
					value === "lessOrEqual"
				) {
					onChange(
						option.value === "greater"
							? "greaterOrEqual"
							: "lessOrEqual",
					);
				} else {
					onChange(option.value);
				}
			} else if (value === "greater" || value === "less") {
				onChange(
					value === "greater" ? "greaterOrEqual" : "lessOrEqual",
				);
			} else {
				onChange("equal");
			}
		},
		[onChange, value],
	);

	const multiSelectOnUnselect = useCallback(
		(option: Option<"greater" | "less" | "equal">) => {
			if (isUndefined(onChange)) return;

			if (value === "greaterOrEqual" || value === "lessOrEqual") {
				if (option.value === "equal") {
					onChange(value === "greaterOrEqual" ? "greater" : "less");
				} else {
					onChange("equal");
				}
			} else {
				onChange(undefined);
			}
		},
		[onChange, value],
	);

	return (
		<MultiSelect
			value={multiSelectValue}
			placeholder={
				t(
					"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.comparisonSelect.str0",
				) ?? ""
			}
			disabled={disabled}
			style={{ width: 150 }}
			options={options}
			onSelect={multiSelectOnSelect}
			onUnselect={multiSelectOnUnselect}
		/>
	);
};

declare namespace ComparisonSelect {
	type Value =
		| "greater"
		| "less"
		| "equal"
		| "greaterOrEqual"
		| "lessOrEqual"
		| undefined;

	interface Props {
		value?: Value;

		disabled?: boolean;

		onChange?: Dispatch<Value>;
	}
}

export default ComparisonSelect;
