import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const SourceCellContent: React.FC<SourceCellContent.Props> = ({ item }) => {
	const { t } = useTranslation();

	const content = useMemo(
		() => t(`order_source.${item.source}`),
		[item.source, t],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace SourceCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default SourceCellContent;
