import { getPRPC } from "../../../../../hooks/usePRPC";
import createRPCQuery from "../../../../../utils/createRPCQuery.util";

export default function deleteProviderSms(id) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() => prpcow?.theirsModel.sms.provider.delete(id))
			.then(async (result) => result)
			.catch((error) => console.error(error));
	};
}
