import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
	IOrderPoint as OrderPoint,
	stringifyPoint,
} from "../../../../../../../../../redux/constants/OrdersPage/order";
import CellContentRoot from "../CellContentRoot";

const StreetCellContent: React.FC<StreetCellContent.Props> = ({ item }) => {
	const { t } = useTranslation();
	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const content = useMemo(() => {
		const result = stringifyPoint(item, "street_district", pointOnMap);

		return result.length
			? result
			: t(
					"orderPageWidgets.orderDetails.points.table.streetCellContent.str200",
			  ) ?? "";
	}, [item, pointOnMap, t]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace StreetCellContent {
	interface Props {
		item: OrderPoint;
	}
}

export default StreetCellContent;
