import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isNumber } from "lodash";

import { Counterparty } from "../../../../services";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectCounterpartyBills: React.FC<
	MultiSelectCounterpartyBills.Props
> = ({
	value = [],
	onChange,
	companyIds = [],
	counterpartyIds = [],
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	subscribeOptions = {},
}) => {
	const { t } = useTranslation();

	const options = useMemo(() => subscribeOptions, [subscribeOptions]);
	const counterpartySubscribe = useModelSubscribe(options, Counterparty);

	const [error, setError] = useState("");

	const selectOptions = useMemo(
		() =>
			counterpartySubscribe.models
				?.filter((counterparty) =>
					counterpartyIds.includes(counterparty.id),
				)
				?.map((counterparty) =>
					counterparty.checks?.map((check) => ({
						...check,
						_nameCounterpartyId: `${
							counterparty.additionalFields?.name || "Unknown"
						}`,
					})),
				)
				?.flat()
				?.filter((bill) => isNumber(bill.id) && bill.id > 0)
				?.map((bill) => ({
					key: bill.id as number,
					label: `${bill.additionalFields?.checkValue || "Unknown"}${
						bill._nameCounterpartyId
							? ` (${bill._nameCounterpartyId})`
							: ""
					}`,
					value: bill.id as number,
					name: `${bill.additionalFields?.checkValue || "Unknown"}${
						bill._nameCounterpartyId
							? ` (${bill._nameCounterpartyId})`
							: ""
					}`,
					data: bill,
				})) ?? [],

		[counterpartyIds, counterpartySubscribe.models],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const ids = newValue.map((item) => item.value);
			onChange(ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t("billsChoise"),
			allText: t("all_counterparty_bills"),
			allActiveText: t("all_active_counterparty_bills"),
		}),
		[t],
	);

	useEffect(() => {
		if (
			!disabled &&
			companyIds?.length &&
			counterpartyIds?.length &&
			selectOptions.length > 0
		) {
			const allValues = selectOptions.map((item) => item.value);
			onChange(allValues);
		} else {
			onChange([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOptions, disabled, companyIds, counterpartyIds]);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				<StyledP>{t(titleText) ?? ""}</StyledP>
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={error}
					disabled={
						disabled ||
						!companyIds?.length ||
						!counterpartyIds?.length
					}
					title={texts.title}
					allText={texts.allText}
					allActiveText={texts.allActiveText}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					showSelectAll
					modalSetting={modalSetting}
					sort={{
						show: true,
						active: true,
						...(sort || {}),
					}}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectCounterpartyBills {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		companyIds?: number[];
		counterpartyIds?: number[];
		subscribeOptions?: Counterparty.SubscribeOptions;
	}

	type Value = number[];
}

export default memo(MultiSelectCounterpartyBills);
