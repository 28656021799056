import React, { memo } from "react";
import { isNumber } from "lodash";

import CellContentRoot from "../CellContentRoot";

const NumberCellContent: React.FC<NumberCellContent.Props> = memo(
	({ index }) => (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{isNumber(index) ? index + 1 : ""}
		</CellContentRoot>
	),
);

declare namespace NumberCellContent {
	interface Props {
		index?: number;
	}
}

export default NumberCellContent;
