import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

import tPath from "../../../../../../constants/tPath";
import {
	orderSourceOptions,
	OrderCreatedSource,
} from "../../../../../../../../../../../../../../../../../../../../types/OrderCreatedSource";

const OrderSource: React.FC<OrderSource.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const sources = valueEditor.useGetter("sources");
	const setSources = valueEditor.useSetter("sources");
	const handleSourcesChange = useCallback(
		(newSources) => {
			setSources(newSources);
		},
		[setSources],
	);

	const tOptions = useMemo(
		() =>
			orderSourceOptions
				.filter((value) => value.label !== "phone")
				.map((o) => ({
					...o,
					label: t(`${tPath}.modal.orderSource.options.${o.key}`),
				})),
		[t],
	);

	return (
		<Row
			align="center"
			gaps="10px*"
			sizes="auto 1fr"
			justify="space-between"
		>
			<CheckBox
				label={t(`${tPath}.modal.orderSource.title`) || ""}
				value={active}
				onChange={setActive}
			/>
			<MultiSelect
				style={{ maxWidth: "533.43px" }}
				placeholder={t(`${tPath}.modal.orderSource.placeholder`) || ""}
				options={tOptions}
				value={sources}
				onChange={handleSourcesChange}
			/>
		</Row>
	);
});

declare namespace OrderSource {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		sources: OrderCreatedSource[];
	}
}

export default OrderSource;
