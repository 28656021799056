import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, react } from "uikit";
import { assign, clone } from "lodash";

import Language from "../../../../../../../../../../../../../services/Language";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import LabeledField from "../../../../../../../../../../../../LabeledField";
import InternalController from "./Controller";

const TaxiServices = react.withController<
	TaxiServices.Props,
	TaxiServices.Controller
>(({ controller, value, disabled = false, language, onChange }) => {
	const { t } = useTranslation();
	const currentValueRef = useRef(value);

	currentValueRef.current = value;

	const setField = useCallback(
		<Field extends keyof TaxiServices.Value>(
			field: Field,
			fieldValue: TaxiServices.Value[Field],
		) => {
			currentValueRef.current = assign(clone(currentValueRef.current), {
				[field]: fieldValue,
			});

			onChange(currentValueRef.current);
		},
		[onChange],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			setTaxiServiceIdsError(false);

			setField("taxiServiceIds", taxiServiceIds);
		},
		[setField],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			setCompanyIdsError(false);

			setField("companyIds", companyIds);
		},
		[setField],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		value.companyIds,
		value.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const [companyIdsError, setCompanyIdsError] = useState(false);

	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name[language],
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	const companyIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setCompanyIds(newCompanyIds as number[]);
		},
		[setCompanyIds],
	);

	const [taxiServiceIdsError, setTaxiServiceIdsError] = useState(false);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: taxiService.settlement[language],
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const taxiServiceIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setTaxiServiceIds(newCompanyIds as number[]);
		},
		[setTaxiServiceIds],
	);

	controller.setContext({
		value,
		setCompanyIdsError,
		setTaxiServiceIdsError,
	});

	return (
		<>
			<LabeledField
				label={
					t(
						"orderPageWidgets.map2.header.filters.content.form.taxiServices.str0",
					) ?? ""
				}
			>
				<MultiSelect
					value={companyIds}
					disabled={disabled}
					all
					placeholder={
						t(
							"orderPageWidgets.map2.header.filters.content.form.taxiServices.str0",
						) ?? ""
					}
					error={companyIdsError}
					options={possibleCompanyOptions}
					onChange={companyIdsOnChange}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"orderPageWidgets.map2.header.filters.content.form.taxiServices.str2",
					) ?? ""
				}
			>
				<MultiSelect
					value={taxiServiceIds}
					disabled={disabled}
					all
					placeholder={
						t(
							"orderPageWidgets.map2.header.filters.content.form.taxiServices.str2",
						) ?? ""
					}
					error={taxiServiceIdsError}
					options={possibleTaxiServiceOptions}
					onChange={taxiServiceIdsOnChange}
				/>
			</LabeledField>
		</>
	);
}, InternalController);

declare namespace TaxiServices {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
	}

	interface PropsBase {
		value: Value;

		disabled?: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default TaxiServices;
