import styled from "styled-components";
import { Row, theme } from "uikit";
//
const Root = styled(Row)`
	padding-bottom: 10px;

	overflow: auto hidden;

	${theme.scrollbar}
`;

export default Root;
