import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	width: 330px;
	max-height: 400px;

	padding: 4px;

	overflow: hidden auto;

	${theme.scrollbar}
`;

export default Root;
