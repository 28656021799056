import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, Select } from "uikit";
import { useTranslation } from "react-i18next";
import tPath from "../../../../../../../../constants/tPath";
import ValueStepper from "../../../../../../../ValueStepper";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	TariffCommonSurchargeAdjustment,
	tariffCommonSurchargeOptions,
} from "../../../../../../../../../../../../../../types/TariffCommonSurchargeAdjustment";

const Surcharge: React.FC<Surcharge.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const surchargeValue = valueEditor.useGetter("value");
	const setSurchargeValue = valueEditor.useSetter("value");

	const type = valueEditor.useGetter("type");
	const setType = valueEditor.useSetter("type");
	const handleTypeChange = useCallback(
		(newType) => {
			setType(newType);
		},
		[setType],
	);

	const tOptions = useMemo(
		() =>
			tariffCommonSurchargeOptions.map((option) => ({
				...option,
				label: t(
					`${tPath}.modal.tabs.generalSurcharges.surcharges.options.${option.key}`,
				),
			})),
		[t],
	);

	return (
		<Row align="center" gaps="10px*" sizes="auto 1fr auto">
			<CheckBox
				label={
					t(
						`${tPath}.modal.tabs.generalSurcharges.surcharges.label`,
					) || ""
				}
				value={active}
				onChange={setActive}
			/>
			<Select
				placeholder={
					t(
						`${tPath}.modal.tabs.generalSurcharges.surcharges.placeholder`,
					) || ""
				}
				options={tOptions}
				value={type}
				onChange={handleTypeChange}
			/>
			<ValueStepper value={surchargeValue} onChange={setSurchargeValue} />
		</Row>
	);
});

declare namespace Surcharge {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
		type?: TariffCommonSurchargeAdjustment;
	}
}

export default Surcharge;
