import styled from "styled-components";
import { theme } from "uikit";

const Label = styled.span`
	font-family: Lato;
	font-size: 13px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 0em;

	color: ${theme.colors.primary};
`;

export default Label;
