import React, { useCallback, memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Select } from "uikit";

import { RegisteredIcon } from "../../../../../../assets/icons";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import { Archive } from "../../../../../../redux/reducers/Archives/interface";

import ToggleBadge from "../../ToggleBadge";
import { StyledRow, Select } from "../../../../../../components/common";

const badges: HistoryBadges.Badges = {
	date: { icon: "sort-down", tooltip: "date", default: "Data", name: "date" },
	base: { icon: "reset", tooltip: "reset", default: "Reset", name: "base" },
	cost: {
		icon: "price",
		tooltip: "rolesprice",
		default: "Price",
		name: "cost",
	},
	points: {
		icon: "marker-on-map",
		tooltip: "points",
		default: "Point on map",
		name: "points",
	},
	type: {
		icon: "accounting",
		tooltip: "type",
		default: "Type",
		name: "type",
	},
	orderNote: {
		icon: "notepad-edit",
		tooltip: "order_notes",
		default: "Order note",
		name: "orderNote",
	},
	status: {
		icon: "order-settings",
		tooltip: "status",
		default: "Status",
		name: "status",
	},
	orderNumber: {
		icon: "orders",
		tooltip: "order_number",
		default: "Order number",
		name: "orderNumber",
	},
	orderDate: {
		icon: "route",
		tooltip: "order_date",
		default: "Order date",
		name: "orderDate",
	},
	taxiService: {
		icon: "client-app-settings",
		tooltip: "taxi_service",
		default: "Taxi service",
		name: "taxiService",
	},
	services: {
		icon: "settings",
		tooltip: "services",
		default: "Services",
		name: "services",
	},
	"passenger.customers": {
		icon: "customers",
		tooltip: "passenger_customers",
		default: "Passenger customers",
		name: "passenger.customers",
	},
	"passenger.points": {
		icon: "marker-on-map",
		tooltip: "passenger_points",
		default: "Passenger points",
		name: "passenger.points",
	},
	closedOrdersComments: {
		icon: "close",
		tooltip: "order_closed_note",
		default: "Closed orders comments",
		name: "closedOrdersComments",
	},
	transportationType: {
		icon: "car",
		tooltip: "transportation_type",
		default: "Transportation type",
		name: "transportationType",
	},
	idleTimeMilliseconds: {
		icon: "backspace-number",
		tooltip: "idle_time",
		default: "Idle time",
		name: "idleTimeMilliseconds",
	},
	passengersCount: {
		icon: "settings",
		tooltip: "passengers_count",
		default: "Passengers Count",
		name: "passengersCount",
	},
	waitingTimeMilliseconds: {
		icon: "settings",
		tooltip: "waiting_time",
		default: "Waiting time",
		name: "waitingTimeMilliseconds",
	},
	executorNotes: {
		icon: "notepad-edit",
		tooltip: "executor_notes",
		default: "Executor notes",
		name: "executorNotes",
	},
	customerNotes: {
		icon: "notepad-edit",
		tooltip: "customer_notes",
		default: "Customer notes",
		name: "customerNotes",
	},
	outsideSettlementKm: {
		icon: "map",
		tooltip: "out_of_town",
		default: "Outside settlement",
		name: "outsideSettlementKm",
	},
	additionalCost: {
		icon: "rotate-clockwise",
		tooltip: "additional_cost",
		default: "Additional cost",
		name: "additionalCost",
	},
	hourlyMilliseconds: {
		icon: "rotate-counterclockwise",
		tooltip: "hourly",
		default: "Hourly",
		name: "hourlyMilliseconds",
	},
	rateSettings: {
		icon: "interface-settings",
		tooltip: "rate_settings",
		default: "Rate settings",
		name: "rateSettings",
	},
	paymentAccount: {
		icon: "payment-systems",
		tooltip: "payment_account",
		default: "Payment account",
		name: "paymentAccount",
	},
	billingCustomer: {
		icon: "executor-app-settings",
		tooltip: "billing_customer",
		default: "Billing customer",
		name: "billingCustomer",
	},
	source: {
		icon: "search",
		tooltip: "source",
		default: "Source",
		name: "source",
	},
	executors: {
		icon: "executor-app-settings",
		tooltip: "executors",
		default: "Executors",
		name: "executors",
	},
	carClasses: {
		icon: "car",
		tooltip: "car_classes",
		default: "Car classes",
		name: "carClasses",
	},
	cars: {
		icon: "car",
		tooltip: "cars",
		default: "Cars",
		name: "cars",
	},
	phones: {
		icon: "phone-history",
		tooltip: "phones",
		default: "Phones",
		name: "phones",
	},
};

const HistoryBadges: React.FC<HistoryBadges.Props> = ({
	value,
	onChange,
	style,
	isBadges = false,
}): JSX.Element => {
	const { t } = useTranslation();
	const internalValueEditor = useObjectEditor<HistoryBadges.DisplayBadges>(
		value,
		onChange,
	);

	const options = useMemo(
		() => [
			{ key: 1, value: "1", label: t("full_log") },
			{ key: 2, value: "2", label: t("distribution_log") },
			{ key: 3, value: "3", label: t("price_log") },
			// {key: "4", value: "4", text: t("taximeter_log") },
		],
		[t],
	);

	const [key, setKey] = useState<string | number>(options[0].key);
	// closedOrdersComments
	const closedOrdersCommentsValue = internalValueEditor.useGetter(
		badges.closedOrdersComments.name,
	);
	const closedOrdersCommentsOnChange = internalValueEditor.useSetter(
		badges.closedOrdersComments.name,
	);
	const valueClosedOrdersCommentsOnChange = useCallback(
		(data: boolean) => closedOrdersCommentsOnChange(data),
		[closedOrdersCommentsOnChange],
	);
	// cost
	const costValue = internalValueEditor.useGetter(badges.cost.name);
	const costOnChange = internalValueEditor.useSetter(badges.cost.name);
	const valueCostOnChange = useCallback(
		(data: boolean) => costOnChange(data),
		[costOnChange],
	);
	// orderDate
	const orderDateValue = internalValueEditor.useGetter(badges.orderDate.name);
	const orderDateOnChange = internalValueEditor.useSetter(
		badges.orderDate.name,
	);
	const valueOrderDateOnChange = useCallback(
		(data: boolean) => orderDateOnChange(data),
		[orderDateOnChange],
	);
	//  orderNote
	const orderNoteValue = internalValueEditor.useGetter(badges.orderNote.name);
	const orderNoteOnChange = internalValueEditor.useSetter(
		badges.orderNote.name,
	);
	const valueOrderNoteOnChange = useCallback(
		(data: boolean) => orderNoteOnChange(data),
		[orderNoteOnChange],
	);
	// orderNumber
	const orderNumberValue = internalValueEditor.useGetter(
		badges.orderNumber.name,
	);
	const orderNumberOnChange = internalValueEditor.useSetter(
		badges.orderNumber.name,
	);
	const valueOrderNumberOnChange = useCallback(
		(data: boolean) => orderNumberOnChange(data),
		[orderNumberOnChange],
	);
	// additionalCost
	const additionalCostValue = internalValueEditor.useGetter(
		badges.additionalCost.name,
	);
	const additionalCostOnChange = internalValueEditor.useSetter(
		badges.additionalCost.name,
	);
	const additionalCostValueOnChange = useCallback(
		(data: boolean) => additionalCostOnChange(data),
		[additionalCostOnChange],
	);
	// billingCustomer
	const billingCustomerValue = internalValueEditor.useGetter(
		badges.billingCustomer.name,
	);
	const billingCustomerOnChange = internalValueEditor.useSetter(
		badges.billingCustomer.name,
	);
	const billingCustomerValueOnChange = useCallback(
		(data: boolean) => billingCustomerOnChange(data),
		[billingCustomerOnChange],
	);
	// customerNotes
	const customerNotesValue = internalValueEditor.useGetter(
		badges.customerNotes.name,
	);
	const customerNotesOnChange = internalValueEditor.useSetter(
		badges.customerNotes.name,
	);
	const customerNotesValueOnChange = useCallback(
		(data: boolean) => customerNotesOnChange(data),
		[customerNotesOnChange],
	);
	// executorNotes
	const executorNotesValue = internalValueEditor.useGetter(
		badges.executorNotes.name,
	);
	const executorNotesOnChange = internalValueEditor.useSetter(
		badges.executorNotes.name,
	);
	const executorNotesValueOnChange = useCallback(
		(data: boolean) => executorNotesOnChange(data),
		[executorNotesOnChange],
	);

	// hourlyMilliseconds
	const hourlyMillisecondsValue = internalValueEditor.useGetter(
		badges.hourlyMilliseconds.name,
	);
	const hourlyMillisecondsOnChange = internalValueEditor.useSetter(
		badges.hourlyMilliseconds.name,
	);
	const hourlyMillisecondsValueOnChange = useCallback(
		(data: boolean) => hourlyMillisecondsOnChange(data),
		[hourlyMillisecondsOnChange],
	);
	// idleTimeMilliseconds
	const idleTimeMillisecondsValue = internalValueEditor.useGetter(
		badges.idleTimeMilliseconds.name,
	);
	const idleTimeMillisecondsOnChange = internalValueEditor.useSetter(
		badges.idleTimeMilliseconds.name,
	);
	const idleTimeMillisecondsValueOnChange = useCallback(
		(data: boolean) => idleTimeMillisecondsOnChange(data),
		[idleTimeMillisecondsOnChange],
	);
	// waitingTimeMilliseconds
	const waitingTimeMillisecondsValue = internalValueEditor.useGetter(
		badges.waitingTimeMilliseconds.name,
	);
	const waitingTimeMillisecondsOnChange = internalValueEditor.useSetter(
		badges.waitingTimeMilliseconds.name,
	);
	const waitingTimeMillisecondsValueOnChange = useCallback(
		(data: boolean) => waitingTimeMillisecondsOnChange(data),
		[waitingTimeMillisecondsOnChange],
	);
	// outsideSettlementKm
	const outsideSettlementKmValue = internalValueEditor.useGetter(
		badges.outsideSettlementKm.name,
	);
	const outsideSettlementKmOnChange = internalValueEditor.useSetter(
		badges.outsideSettlementKm.name,
	);
	const outsideSettlementKmValueOnChange = useCallback(
		(data: boolean) => outsideSettlementKmOnChange(data),
		[outsideSettlementKmOnChange],
	);
	// passenger.customers
	const passengerCustomersValue = internalValueEditor.useGetter(
		badges["passenger.customers"].name,
	);
	const passengerCustomersOnChange = internalValueEditor.useSetter(
		badges["passenger.customers"].name,
	);
	const passengerCustomersValueOnChange = useCallback(
		(data: boolean) => passengerCustomersOnChange(data),
		[passengerCustomersOnChange],
	);
	// passenger.points
	const passengerPointsValue = internalValueEditor.useGetter(
		badges["passenger.points"].name,
	);
	const passengerPointsOnChange = internalValueEditor.useSetter(
		badges["passenger.points"].name,
	);
	const passengerPointsValueOnChange = useCallback(
		(data: boolean) => passengerPointsOnChange(data),
		[passengerPointsOnChange],
	);
	// passengersCount
	const passengersCountValue = internalValueEditor.useGetter(
		badges.passengersCount.name,
	);
	const passengersCountOnChange = internalValueEditor.useSetter(
		badges.passengersCount.name,
	);
	const passengersCountValueOnChange = useCallback(
		(data: boolean) => passengersCountOnChange(data),
		[passengersCountOnChange],
	);
	// paymentAccount
	const paymentAccountValue = internalValueEditor.useGetter(
		badges.paymentAccount.name,
	);
	const paymentAccountOnChange = internalValueEditor.useSetter(
		badges.paymentAccount.name,
	);
	const paymentAccountValueOnChange = useCallback(
		(data: boolean) => paymentAccountOnChange(data),
		[paymentAccountOnChange],
	);
	// rateSettings
	const rateSettingsValue = internalValueEditor.useGetter(
		badges.rateSettings.name,
	);
	const rateSettingsOnChange = internalValueEditor.useSetter(
		badges.rateSettings.name,
	);
	const rateSettingsValueOnChange = useCallback(
		(data: boolean) => rateSettingsOnChange(data),
		[rateSettingsOnChange],
	);
	// services
	const servicesValue = internalValueEditor.useGetter(badges.services.name);
	const servicesOnChange = internalValueEditor.useSetter(
		badges.services.name,
	);
	const servicesValueOnChange = useCallback(
		(data: boolean) => servicesOnChange(data),
		[servicesOnChange],
	);
	// source
	const sourceValue = internalValueEditor.useGetter(badges.source.name);
	const sourceOnChange = internalValueEditor.useSetter(badges.source.name);
	const sourceValueOnChange = useCallback(
		(data: boolean) => sourceOnChange(data),
		[sourceOnChange],
	);
	// status
	const statusValue = internalValueEditor.useGetter(badges.status.name);
	const statusOnChange = internalValueEditor.useSetter(badges.status.name);
	const statusValueOnChange = useCallback(
		(data: boolean) => statusOnChange(data),
		[statusOnChange],
	);
	// taxiService
	const taxiServiceValue = internalValueEditor.useGetter(
		badges.taxiService.name,
	);
	const taxiServiceOnChange = internalValueEditor.useSetter(
		badges.taxiService.name,
	);
	const taxiServiceValueOnChange = useCallback(
		(data: boolean) => taxiServiceOnChange(data),
		[taxiServiceOnChange],
	);
	// transportationType
	const transportationTypeValue = internalValueEditor.useGetter(
		badges.transportationType.name,
	);
	const transportationTypeOnChange = internalValueEditor.useSetter(
		badges.transportationType.name,
	);
	const transportationTypeValueOnChange = useCallback(
		(data: boolean) => transportationTypeOnChange(data),
		[transportationTypeOnChange],
	);
	// type
	const typeValue = internalValueEditor.useGetter(badges.type.name);
	const typeOnChange = internalValueEditor.useSetter(badges.type.name);
	const typeValueOnChange = useCallback(
		(data: boolean) => typeOnChange(data),
		[typeOnChange],
	);

	// cars
	const carsValue = internalValueEditor.useGetter(badges.cars.name);
	const carsOnChange = internalValueEditor.useSetter(badges.cars.name);
	const carsValueOnChange = useCallback(
		(data: boolean) => carsOnChange(data),
		[carsOnChange],
	);
	// carClasses
	const carClassesValue = internalValueEditor.useGetter(
		badges.carClasses.name,
	);
	const carClassesOnChange = internalValueEditor.useSetter(
		badges.carClasses.name,
	);
	const carClassesValueOnChange = useCallback(
		(data: boolean) => carClassesOnChange(data),
		[carClassesOnChange],
	);
	// executors
	const executorsValue = internalValueEditor.useGetter(badges.executors.name);
	const executorsOnChange = internalValueEditor.useSetter(
		badges.executors.name,
	);
	const executorsValueOnChange = useCallback(
		(data: boolean) => executorsOnChange(data),
		[executorsOnChange],
	);

	// date
	const dateValue = internalValueEditor.useGetter(badges.date.name);
	const dateOnChange = internalValueEditor.useSetter(badges.date.name);
	const dateValueOnChange = useCallback(
		(data: boolean) => dateOnChange(data),
		[dateOnChange],
	);
	// phones
	const phonesValue = internalValueEditor.useGetter(badges.phones.name);
	const phonesOnChange = internalValueEditor.useSetter(badges.phones.name);
	const phonesValueOnChange = useCallback(
		(data: boolean) => phonesOnChange(data),
		[phonesOnChange],
	);
	// base
	const baseValue = internalValueEditor.useGetter(badges.base.name);
	const baseOnChange = internalValueEditor.useSetter(badges.base.name);
	const baseValueOnChange = useCallback(
		(data: boolean) => {
			baseOnChange(data);
			typeValueOnChange(data);
			transportationTypeValueOnChange(data);
			taxiServiceValueOnChange(data);
			statusValueOnChange(data);
			servicesValueOnChange(data);
			sourceValueOnChange(data);
			rateSettingsValueOnChange(data);
			paymentAccountValueOnChange(data);
			passengersCountValueOnChange(data);
			passengerPointsValueOnChange(data);
			passengerCustomersValueOnChange(data);
			outsideSettlementKmValueOnChange(data);
			waitingTimeMillisecondsValueOnChange(data);
			idleTimeMillisecondsValueOnChange(data);
			customerNotesValueOnChange(data);
			executorNotesValueOnChange(data);
			hourlyMillisecondsValueOnChange(data);
			billingCustomerValueOnChange(data);
			additionalCostValueOnChange(data);
			valueOrderNumberOnChange(data);
			valueOrderNoteOnChange(data);
			valueOrderDateOnChange(data);
			valueCostOnChange(data);
			valueClosedOrdersCommentsOnChange(data);
			executorsValueOnChange(data);
			carClassesValueOnChange(data);
			carsValueOnChange(data);
			phonesValueOnChange(data);
		},
		[
			additionalCostValueOnChange,
			baseOnChange,
			billingCustomerValueOnChange,
			carClassesValueOnChange,
			carsValueOnChange,
			customerNotesValueOnChange,
			executorNotesValueOnChange,
			executorsValueOnChange,
			hourlyMillisecondsValueOnChange,
			idleTimeMillisecondsValueOnChange,
			outsideSettlementKmValueOnChange,
			passengerCustomersValueOnChange,
			passengerPointsValueOnChange,
			passengersCountValueOnChange,
			paymentAccountValueOnChange,
			phonesValueOnChange,
			rateSettingsValueOnChange,
			servicesValueOnChange,
			sourceValueOnChange,
			statusValueOnChange,
			taxiServiceValueOnChange,
			transportationTypeValueOnChange,
			typeValueOnChange,
			valueClosedOrdersCommentsOnChange,
			valueCostOnChange,
			valueOrderDateOnChange,
			valueOrderNoteOnChange,
			valueOrderNumberOnChange,
			waitingTimeMillisecondsValueOnChange,
		],
	);

	const onChangeSelect = useCallback(
		(key: string) => {
			if (key === "1") baseValueOnChange(true);
			if (key === "2") {
				baseValueOnChange(false);
				taxiServiceValueOnChange(true);
				passengerPointsValueOnChange(true);
				orderDateOnChange(true);
				carsValueOnChange(true);
				carClassesValueOnChange(true);
				executorNotesValueOnChange(true);
				executorsValueOnChange(true);
			}
			if (key === "3") {
				baseValueOnChange(false);
				valueCostOnChange(true);
				paymentAccountValueOnChange(true);
			}
		},
		[
			baseValueOnChange,
			carClassesValueOnChange,
			carsValueOnChange,
			executorNotesValueOnChange,
			executorsValueOnChange,
			orderDateOnChange,
			passengerPointsValueOnChange,
			paymentAccountValueOnChange,
			taxiServiceValueOnChange,
			valueCostOnChange,
		],
	);

	return (
		<StyledRow gap="10px" style={style}>
			<ToggleBadge
				icon={dateValue ? badges.date.icon : "sort-up"}
				tooltip={t(badges.date.tooltip) || badges.date.default}
				value={dateValue}
				onChange={dateValueOnChange}
			/>
			<StyledRow
				h="32px"
				alignItems="center"
				b={"1px solid #DEE0E2"}
				br={"5px"}
			>
				<Select
					selected={Number(key) || 0}
					options={options}
					value={key}
					topSelect={"130%"}
					onSelect={(select) => {
						onChangeSelect(select.value as string);
						setKey(select.key as string);
						return <></>;
					}}
				/>
			</StyledRow>

			{isBadges && (
				<StyledRow gap="10px" w="100%">
					<ToggleBadge
						icon={badges.phones.icon}
						tooltip={
							t(badges.phones.tooltip) || badges.phones.default
						}
						value={phonesValue}
						onChange={phonesValueOnChange}
					/>
					<ToggleBadge
						icon={badges.base.icon}
						tooltip={t(badges.base.tooltip) || badges.base.default}
						value={baseValue}
						onChange={baseValueOnChange}
					/>
					<ToggleBadge
						icon={badges.executors.icon}
						tooltip={
							t(badges.executors.tooltip) ||
							badges.executors.default
						}
						value={executorsValue}
						onChange={executorsValueOnChange}
					/>
					<ToggleBadge
						icon={badges.cars.icon}
						tooltip={t(badges.cars.tooltip) || badges.cars.default}
						value={carsValue}
						onChange={carsValueOnChange}
					/>
					<ToggleBadge
						icon={badges.carClasses.icon}
						tooltip={
							t(badges.carClasses.tooltip) ||
							badges.carClasses.default
						}
						value={carClassesValue}
						onChange={carClassesValueOnChange}
					/>
					<ToggleBadge
						icon={badges.type.icon}
						tooltip={t(badges.type.tooltip) || badges.type.default}
						value={typeValue}
						onChange={typeValueOnChange}
					/>
					<ToggleBadge
						icon={badges.transportationType.icon}
						tooltip={
							t(badges.transportationType.tooltip) ||
							badges.transportationType.default
						}
						value={transportationTypeValue}
						onChange={transportationTypeValueOnChange}
					/>
					<ToggleBadge
						icon={badges.taxiService.icon}
						tooltip={
							t(badges.taxiService.tooltip) ||
							badges.taxiService.default
						}
						value={taxiServiceValue}
						onChange={taxiServiceValueOnChange}
					/>
					<ToggleBadge
						icon={badges.status.icon}
						tooltip={
							t(badges.status.tooltip) || badges.status.default
						}
						value={statusValue}
						onChange={statusValueOnChange}
					/>
					<ToggleBadge
						icon={badges.source.icon}
						tooltip={
							t(badges.source.tooltip) || badges.source.default
						}
						value={sourceValue}
						onChange={sourceValueOnChange}
					/>
					<ToggleBadge
						icon={badges.services.icon}
						tooltip={
							t(badges.services.tooltip) ||
							badges.services.default
						}
						value={servicesValue}
						onChange={servicesValueOnChange}
					/>
					<ToggleBadge
						icon={badges.rateSettings.icon}
						tooltip={
							t(badges.rateSettings.tooltip) ||
							badges.rateSettings.default
						}
						value={rateSettingsValue}
						onChange={rateSettingsValueOnChange}
					/>
					<ToggleBadge
						icon={badges.paymentAccount.icon}
						tooltip={
							t(badges.paymentAccount.tooltip) ||
							badges.paymentAccount.default
						}
						value={paymentAccountValue}
						onChange={paymentAccountValueOnChange}
					/>
					<ToggleBadge
						icon={badges.passengersCount.icon}
						tooltip={
							t(badges.passengersCount.tooltip) ||
							badges.passengersCount.default
						}
						value={passengersCountValue}
						onChange={passengersCountValueOnChange}
					/>
					<ToggleBadge
						icon={badges["passenger.points"].icon}
						tooltip={
							t(badges["passenger.points"].tooltip) ||
							badges["passenger.points"].default
						}
						value={passengerPointsValue}
						onChange={passengerPointsValueOnChange}
					/>
					<ToggleBadge
						icon={badges["passenger.customers"].icon}
						tooltip={
							t(badges["passenger.customers"].tooltip) ||
							badges["passenger.customers"].default
						}
						value={passengerCustomersValue}
						onChange={passengerCustomersValueOnChange}
					/>
					<ToggleBadge
						icon={badges.hourlyMilliseconds.icon}
						tooltip={
							t(badges.hourlyMilliseconds.tooltip) ||
							badges.hourlyMilliseconds.default
						}
						value={hourlyMillisecondsValue}
						onChange={hourlyMillisecondsValueOnChange}
					/>
					<ToggleBadge
						icon={badges.idleTimeMilliseconds.icon}
						tooltip={
							t(badges.idleTimeMilliseconds.tooltip) ||
							badges.idleTimeMilliseconds.default
						}
						value={idleTimeMillisecondsValue}
						onChange={idleTimeMillisecondsValueOnChange}
					/>
					<ToggleBadge
						icon={badges.waitingTimeMilliseconds.icon}
						tooltip={
							t(badges.waitingTimeMilliseconds.tooltip) ||
							badges.waitingTimeMilliseconds.default
						}
						value={waitingTimeMillisecondsValue}
						onChange={waitingTimeMillisecondsValueOnChange}
					/>
					<ToggleBadge
						icon={badges.outsideSettlementKm.icon}
						tooltip={
							t(badges.outsideSettlementKm.tooltip) ||
							badges.outsideSettlementKm.default
						}
						value={outsideSettlementKmValue}
						onChange={outsideSettlementKmValueOnChange}
					/>
					<ToggleBadge
						icon={badges.closedOrdersComments.icon}
						tooltip={
							t(badges.closedOrdersComments.tooltip) ||
							badges.closedOrdersComments.default
						}
						value={closedOrdersCommentsValue}
						onChange={valueClosedOrdersCommentsOnChange}
					/>
					<ToggleBadge
						icon={badges.cost.icon}
						tooltip={t(badges.cost.tooltip) || badges.cost.default}
						value={costValue}
						onChange={valueCostOnChange}
					/>
					<ToggleBadge
						icon={badges.orderDate.icon}
						tooltip={
							t(badges.orderDate.tooltip) ||
							badges.orderDate.default
						}
						value={orderDateValue}
						onChange={valueOrderDateOnChange}
					/>
					<ToggleBadge
						icon={badges.orderNote.icon}
						tooltip={
							t(badges.orderNote.tooltip) ||
							badges.orderNote.default
						}
						value={orderNoteValue}
						onChange={valueOrderNoteOnChange}
					/>
					<ToggleBadge
						icon={badges.orderNumber.icon}
						tooltip={
							t(badges.orderNumber.tooltip) ||
							badges.orderNumber.default
						}
						value={orderNumberValue}
						onChange={valueOrderNumberOnChange}
					/>
					<ToggleBadge
						icon={badges.additionalCost.icon}
						tooltip={
							t(badges.additionalCost.tooltip) ||
							badges.additionalCost.default
						}
						value={additionalCostValue}
						onChange={additionalCostValueOnChange}
					/>
					<ToggleBadge
						icon={badges.billingCustomer.icon}
						tooltip={
							t(badges.billingCustomer.tooltip) ||
							badges.billingCustomer.default
						}
						value={billingCustomerValue}
						onChange={billingCustomerValueOnChange}
					/>
					<ToggleBadge
						icon={badges.customerNotes.icon}
						tooltip={
							t(badges.customerNotes.tooltip) ||
							badges.customerNotes.default
						}
						value={customerNotesValue}
						onChange={customerNotesValueOnChange}
					/>
					<ToggleBadge
						icon={badges.executorNotes.icon}
						tooltip={
							t(badges.executorNotes.tooltip) ||
							badges.executorNotes.default
						}
						value={executorNotesValue}
						onChange={executorNotesValueOnChange}
					/>{" "}
				</StyledRow>
			)}
		</StyledRow>
	);
};

declare namespace HistoryBadges {
	type BadgesType = {
		icon: RegisteredIcon;
		tooltip: string;
		default: string;
		name: Archive.ValueOrderField | "date" | "base";
	};

	type Badges = Record<Archive.ValueOrderField | "date" | "base", BadgesType>;
	type DisplayBadges = Record<Archive.ValueOrderField, boolean> &
		Partial<BadgesFilters>;

	interface Props {
		value: DisplayBadges;
		onChange: (value: DisplayBadges) => void;
		style?: React.CSSProperties;
		isBadges?: boolean;
	}
	interface BadgesFilters {
		base: boolean;
		date: boolean;
	}
}

export const HistoryBadgesMemo = memo(HistoryBadges);
export default HistoryBadges;
