enum OrderStatus {
	CREATING = "creating",
	CREATED = "created",
	INSTANT_DISTRIBUTION = "instant_distribution",
	DISTRIBUTION = "distribution",
	REDISTRIBUTION = "redistribution",
	DISTRIBUTION_AND_FREE_WAVE = "distribution_and_free_wave",
	FREE_WAVE = "free_wave",
	ASSIGNED_MANUALLY = "assigned_manually",
	EXECUTING = "executing",
	CLOSED = "closed",
}

export default OrderStatus;
