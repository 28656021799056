import { Option } from "uikit";

import ExecutorRateSubscription from "../../../../../../../../../../../../../../../../services/ExecutorRateSubscription";
import ExecutorRateCommissionPlan from "../../../../../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
import CheckBoxSelect from "../../../../../../../../../../../../../../../../components/CheckBoxSelect";

export const periodUnitOptions: Option<ExecutorRateSubscription.Model.PeriodUnit>[] =
	[
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Daily,
			label: "Дней",
			value: ExecutorRateSubscription.Model.PeriodUnit.Daily,
		},
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Weekly,
			label: "Неделя",
			value: ExecutorRateSubscription.Model.PeriodUnit.Weekly,
		},
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Monthly,
			label: "Месяц",
			value: ExecutorRateSubscription.Model.PeriodUnit.Monthly,
		},
		{
			key: ExecutorRateSubscription.Model.PeriodUnit.Shift,
			label: "Смена",
			value: ExecutorRateSubscription.Model.PeriodUnit.Shift,
		},
	];

export const modeOptions: Option<ExecutorRateCommissionPlan.Model.Action>[] = [
	{
		key: ExecutorRateCommissionPlan.Model.Action.AfterOrderClosed,
		label: "После закрытия заказа",
		value: ExecutorRateCommissionPlan.Model.Action.AfterOrderClosed,
	},
	{
		key: ExecutorRateCommissionPlan.Model.Action.AfterOrderAssigned,
		label: "После постановки на заказ",
		value: ExecutorRateCommissionPlan.Model.Action.AfterOrderAssigned,
	},
	// {
	// 	key: ExecutorRateCommissionPlan.Model.Action.AfterPeriodEnd,
	// 	label: "После окончания периода",
	// 	value: ExecutorRateCommissionPlan.Model.Action.AfterPeriodEnd,
	// },
];

export const feePeriodOptions: Option<"perDay" | "perPeriod" | "perShift">[] = [
	{
		key: "perDay",
		label: "За день",
		value: "perDay",
	},
	{
		key: "perPeriod",
		label: "За период",
		value: "perPeriod",
	},
	{
		key: "perShift",
		label: "За смену",
		value: "perShift",
	},
];

export const weekdayOptions: CheckBoxSelect.Option<
	| "monday"
	| "tuesday"
	| "wednesday"
	| "thursday"
	| "friday"
	| "saturday"
	| "sunday"
>[] = [
	{
		key: "monday",
		label: "Понедельник",
	},
	{
		key: "tuesday",
		label: "Вторник",
	},
	{
		key: "wednesday",
		label: "Среда",
	},
	{
		key: "thursday",
		label: "Четверг",
	},
	{
		key: "friday",
		label: "Пятница",
	},
	{
		key: "saturday",
		label: "Суббота",
	},
	{
		key: "sunday",
		label: "Воскресенье",
	},
];

export default {
	periodUnitOptions,
	modeOptions,
	feePeriodOptions,
	weekdayOptions,
};
