import React, { PropsWithChildren, Suspense, memo, useMemo } from "react";
import styled from "styled-components";

import Modal from "../../Modal";
import { Style, StyledColumn, SuspenseLoader } from "../../common";
import { Header } from "../../common/Modal";
// import Header from "./Header";

const Wrap = styled(StyledColumn)`
	border-radius: 10px;
`;

const BaseModal: React.FC<BaseModal.Props> = ({
	title,
	dimmed,
	shadow = !dimmed,
	draggable = true,
	styles,
	onClose,
	children,
	header,
	headerDivider,
	...props
}) => {
	const content = useMemo(
		() => (
			<Wrap
				shadow={shadow ? "0px 3px 14px rgba(0,0,0,0.2)" : "none"}
				position="absolute"
				{...styles}
			>
				<Header
					title={title}
					onClose={onClose}
					draggable={draggable}
					divider={headerDivider}
				>
					{header}
				</Header>
				<Suspense fallback={<SuspenseLoader />}>{children}</Suspense>
			</Wrap>
		),
		[
			children,
			draggable,
			header,
			headerDivider,
			onClose,
			shadow,
			styles,
			title,
		],
	);

	return (
		<Modal {...props} dimmed={dimmed}>
			{content}
		</Modal>
	);
};

declare namespace BaseModal {
	interface Props extends PropsWithChildren {
		header?: React.ReactNode;
		dimmed?: boolean;
		headerDivider?: boolean;
		title?: string;
		shadow?: boolean | string;
		draggable?: boolean;
		styles?: Style.FlexType & Style.DistributeType;
		onClose: Header.Props["onClose"];
	}
}

export const BaseModalMemo = memo(BaseModal);
export default BaseModal;
