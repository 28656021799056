import React, { useMemo } from "react";
import { theme } from "uikit";
import Root from "./components/Root";
import Image from "./components/Image";
import getIconByExt from "../../../../../../utils/getIconByExt";
import Loader from "../../../../../Loader";
import Background from "../../../../../../pages/PreferencesPages/components/ScreenDirectory/components/Sectors/components/Content/components/List/components/Content/components/Group/components/Background";

const availableExtensions = [
	"pdf",
	"doc",
	"docx",
	"xls",
	"xlsx",
	"ppt",
	"pptx",
	"txt",
	"rtf",
];

const Preview: React.FC<Preview.Props> = ({
	url,
	type,
	extension,
	loading,

	onClick,
}) => {
	const content = useMemo(() => {
		if (type.startsWith("image")) return <Image url={url} />;

		if (availableExtensions.includes(extension))
			return getIconByExt(extension.toUpperCase(), {
				fill: theme.colors.secondary,
			});

		return null;
	}, [extension, type, url]);

	return (
		<Root onClick={onClick}>
			<Loader active={loading} dimmed>
				<Background
					align="center"
					justify="center"
					maxedWidth
					maxedHeight
				>
					{content}
				</Background>
			</Loader>
		</Root>
	);
};

declare namespace Preview {
	interface Props {
		loading: boolean;
		url: string;
		type: string;
		extension: string;

		onClick: () => void;
	}
}

export default Preview;
