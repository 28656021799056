import React, { memo, useCallback, useMemo } from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import CarClass from "../../../../../../../../../../services/CarClass";
import tPath from "../../../../constants/tPath";

const Footer: React.FC<Footer.Props> = memo(
	({ value, onChange, root, carClasses }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const isDefault = valueEditor.useGetter("default");
		const setIsDefault = valueEditor.useSetter("default");

		const setCarClassIds = valueEditor.useSetter("carClassIds");

		const isId = valueEditor.useGetter("id");

		const carClassIds = useMemo<number[]>(
			() => carClasses.map((item) => item.id),
			[carClasses],
		);

		const updateCarClassIds = useCallback(() => {
			if (isDefault) setCarClassIds(carClassIds || []);
		}, [setCarClassIds, isDefault, carClassIds]);

		useMemo(() => {
			if (isDefault) updateCarClassIds();
		}, [isDefault, updateCarClassIds]);

		const disabledDefault = useMemo(() => {
			if (root) return true;
			if (isId && isDefault) return true;
			return false;
		}, [isDefault, isId, root]);

		return (
			<Row align="center" gaps="25px*">
				<CheckBox
					label={t(`${tPath}.mainModal.active`) || "Active"}
					value={active}
					onChange={setActive}
				/>
				<CheckBox
					disabled={disabledDefault}
					label={t(`${tPath}.mainModal.base`) || "Base tariff"}
					value={isDefault}
					onChange={setIsDefault}
				/>
				<CheckBox
					disabled
					label={t(`${tPath}.mainModal.global`) || ""}
					value={root}
				/>
			</Row>
		);
	},
);

declare namespace Footer {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
		carClasses: CarClass.Model[];
		readonly root: boolean;
	}

	interface Value {
		active: boolean;
		default: boolean;
		carClassIds: number[];
		id: number;
	}
}

export default Footer;
