import styled from "styled-components";

const Root = styled.div<Root.Props>`
	display: flex;

	justify-content: center;
	align-items: center;

	min-width: 18px;
	min-height: 18px;

	${({ maxedSize }) =>
		maxedSize
			? `
				width: 100%;
				height: 100%;
			`
			: ""}

	background: ${({ dimmed }) =>
		dimmed ? "rgba(0, 0, 0, 0.5)" : "transparent"};
`;

declare namespace Root {
	interface Props {
		dimmed: boolean;
		maxedSize: boolean;
	}
}

export default Root;
