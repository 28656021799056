import getValidCityName from "./getValidCityName";

function objectToLabel(settlement: settlementToString.Settlement) {
	const settlementName = getValidCityName(
		settlement.settlementType,
		settlement.settlement,
	);

	return `${
		settlementName === " " || !settlement.settlement ? "" : settlementName
	}`.trim();
}

declare namespace settlementToString {
	interface Settlement {
		settlementType?: string;
		settlement?: string;
	}
}

export default objectToLabel;
