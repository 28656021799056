/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import { useCallback, useMemo, useState } from "react";
import { Nullable } from "uikit";
import { isNumber } from "lodash";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import ModelList from "../components/ModelList";
import CarBodyType from "../../../../../../../services/CarBodyType";
import CarModelToCarBodyType from "../../../../../../../services/CarModelToCarBodyType";
import { SortingOrder } from "../../../../../../../types/SortingOrder";

function useCarBodyTypeModelList(options: useCarBodyTypeModelList.Options) {
	const [offset, setOffset] = useState(0);
	const [chunkSize, setChunkSize] = useState(30);
	const [selected, setSelected] = useState<Nullable<number>>(null);
	const [sort, setSort] = useState<SortingOrder>("ASC");

	const subscribeOptions = useMemo<CarBodyType.SubscribeOptions>(
		() => ({
			offset,
			limit: chunkSize,

			carModelIds: isNumber(options.selectedCarModelId)
				? [options.selectedCarModelId]
				: [],

			active: options.active,
			native: options.native,
			query: options.search,

			order: {
				name: sort,
			},
		}),
		[
			offset,
			chunkSize,
			options.selectedCarModelId,
			options.active,
			options.native,
			options.search,
			sort,
		],
	);

	const data = useModelSubscribe(subscribeOptions, CarBodyType);

	const models = useMemo(() => data.models ?? [], [data.models]);

	const total = useMemo(
		() => (data.metadataState.total ?? 0) as number,
		[data.metadataState.total],
	);

	const items = useMemo<ModelList.Item[]>(
		() =>
			models.map((model) => {
				const carBodyTypeToCarModel = isNumber(
					options.selectedCarModelId,
				)
					? model.carBodyTypeToCarModels?.find(
							(carBodyTypeToCarModel) =>
								carBodyTypeToCarModel.carModel?.id ===
								options.selectedCarModelId,
					  )
					: null;

				return {
					id: model.id,

					disabled:
						!options.isSelectedCarBaseTypeActive ||
						!options.isSelectedCarBrandActive ||
						!options.isSelectedCarModelActive,

					active: carBodyTypeToCarModel?.active ?? false,
					// deletable: isBoolean(carBodyTypeToCarModel?.native)
					// 	? !carBodyTypeToCarModel!.native
					// 	: false,
					label: model.name,
				};
			}),
		[
			models,
			options.isSelectedCarBaseTypeActive,
			options.isSelectedCarBrandActive,
			options.isSelectedCarModelActive,
			options.selectedCarModelId,
		],
	);

	const selectedSubscribeOptions = useMemo<CarBodyType.SubscribeOptions>(
		() => ({
			ids: isNumber(selected) ? [selected] : [],
		}),
		[selected],
	);

	const selectedData = useModelSubscribe(
		selectedSubscribeOptions,
		CarBodyType,
	);

	const selectedModels = useMemo(
		() => selectedData.models ?? [],
		[selectedData.models],
	);

	const selectedItem = useMemo(
		() =>
			isNumber(options.selectedCarModelId)
				? selectedModels
						.at(0)
						?.carBodyTypeToCarModels?.find(
							(carBodyTypeToCarModel) =>
								carBodyTypeToCarModel.carModel?.id ===
								options.selectedCarModelId,
						)
				: undefined,
		[options.selectedCarModelId, selectedModels],
	);

	const isSelectedActive = useMemo(
		() => selectedItem?.active ?? false,
		[selectedItem?.active],
	);

	const isSelectedNative = useMemo(
		() => selectedItem?.native ?? false,
		[selectedItem?.native],
	);

	const onChangeActive = useCallback(
		(id: number, active: boolean) => {
			if (isNumber(options.selectedCarModelId))
				CarModelToCarBodyType.update(options.selectedCarModelId, id, {
					active,
				});
		},
		[options.selectedCarModelId],
	);

	const onDelete = useCallback(
		async (id: number) => {
			if (isNumber(options.selectedCarModelId))
				CarModelToCarBodyType.destroy(options.selectedCarModelId, id);
		},
		[options.selectedCarModelId],
	);

	return {
		offset,
		setOffset,

		chunkSize,
		setChunkSize,

		selected,
		setSelected,

		sort,
		setSort,

		data,
		models,
		total,
		items,

		isSelectedActive,
		isSelectedNative,

		onChangeActive,

		onDelete,
	};
}

declare namespace useCarBodyTypeModelList {
	interface Options {
		search?: string;

		active?: boolean;
		native?: boolean;

		isSelectedCarBaseTypeActive: boolean;
		isSelectedCarBrandActive: boolean;
		isSelectedCarModelActive: boolean;

		selectedCarModelId: Nullable<number>;
	}
}

export default useCarBodyTypeModelList;
