import { RefObject } from "react";
import { react } from "uikit";

import MainData from "./components/MainData";
import SettingsData from "./components/SettingsData";

interface Context {
	mainDataRef: RefObject<MainData.Ref | null>;
	settingsDataRef: RefObject<SettingsData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isSettingsDataValid =
			!!this.context?.settingsDataRef.current?.validate();
		const isMainDataValid = !!this.context?.mainDataRef.current?.validate();

		return isMainDataValid && isSettingsDataValid;
	}
}
