import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Select, react } from "uikit";
import { useTranslation } from "react-i18next";

import PaymentSystem, {
	PaymentCurrency,
} from "../../../../../../../../services/PaymentSystem";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";

import translationPath from "../../../../constants/translationPath";
import InternalController from "./Controller";

const AssetPaymentsCurrencyBase = react.withController<
	AssetPaymentsCurrency.PropsBase,
	AssetPaymentsCurrency.Controller
>(({ value, onChange, controller }) => {
	const [error, setError] = useState<boolean>(false);

	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const assetPaymentsCurrency = valueEditor.useGetter(
		"assetPaymentsCurrency",
	);
	const assetPaymentsCurrencySet = valueEditor.useSetter(
		"assetPaymentsCurrency",
	);

	const selectOptionsWrap = useCallback(
		(item: { id: PaymentCurrency; label: PaymentCurrency }) => ({
			key: item.id,
			value: item.id,
			label: item.label,
		}),
		[],
	);

	const options = useMemo(
		() =>
			Object.values(PaymentCurrency)
				.filter((item) => item !== PaymentCurrency.NULL)
				.map((label) =>
					selectOptionsWrap({
						id: label,
						label,
					}),
				),
		[selectOptionsWrap],
	);

	const updateSelectedValues = useCallback(
		(value) => {
			assetPaymentsCurrencySet(value);
			setError(false);
		},
		[assetPaymentsCurrencySet],
	);

	const textError = useMemo(() => {
		if (!error) return "";
		return t([
			`${translationPath}.errors.currency`,
			"Currency not selected",
		]);
	}, [t, error]);

	const placeholder = useMemo(
		() =>
			t([
				`${translationPath}.placeholders.currency`,
				"Choose a currency",
			]),
		[t],
	);

	controller.setContext({ value, setError });
	return (
		<Select
			style={{ width: "200px" }}
			placeholder={placeholder}
			error={textError}
			value={assetPaymentsCurrency}
			options={options}
			onChange={updateSelectedValues}
		/>
	);
}, InternalController);
const AssetPaymentsCurrency = memo(AssetPaymentsCurrencyBase);

declare namespace AssetPaymentsCurrency {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default AssetPaymentsCurrency;
