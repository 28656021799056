import React, { Dispatch, RefAttributes, memo } from "react";
import { react, useRefWithSetter } from "uikit";

import CarClass from "../../../../../../../../../../services/CarClass";
import Sector from "../../../../../../../../../../services/Sector";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../../components/common";

import { AutoTariffsTabs as Tabs } from "../../tabs";
import Main from "./tabs/Main";
import Root from "./components/Root";
import InternalController from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ carClasses, sectors, value, onChange, tab, controller }) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref | null>(
			null,
		);
		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker(
			[
				"name",
				"taxiServices",
				"taxiServiceIds",
				"disableOthers",
				"additionalFields",
				"rules",
			],
			"json",
		);

		const updateAllData = valueEditor.useAssigner();

		const getActualTab = (currentTab: Tabs) => {
			switch (currentTab) {
				case Tabs.MAIN:
					return (
						<Main
							ref={setMainTabRef}
							carClasses={carClasses}
							sectors={sectors}
							value={mainTabValue}
							onChange={updateAllData}
						/>
					);

				case Tabs.HISTORY:
					return (
						<StyledRow position="absolute" top="50%" left="50%">
							<SuspenseLoader />
						</StyledRow>
					);

				default:
					return <></>;
			}
		};
		return <Root>{getActualTab(tab)}</Root>;
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		tab: Tabs;
		carClasses: CarClass.Model[];
		sectors?: Sector.Model[];
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value {}
}

export default Content;
