import makeTable from "./makeTable";

const workShift = makeTable({
	allIds: ["active", "name", "openAndCloseType", "taxiServices", "createdAt"],
	defaultIds: [
		"active",
		"name",
		"openAndCloseType",
		"taxiServices",
		"createdAt",
	],
	initialWidths: {
		active: 90,
		name: 694,
		openAndCloseType: 250,
		taxiServices: 347,
		createdAt: 150,
	},
	i18tPath: (id) => `tableColumns.workShifts.${id}`,
});

export default workShift;
