import React, { memo, useMemo } from "react";
import useTime from "../../../../../../hooks/useTime";
import useFormatDate from "../../../../../../hooks/useFormatDate";
import LabeledContent from "../LabeledContent";

const DateTime = memo(() => {
	const now = useTime("every-second");

	const formatDate = useFormatDate();

	const date = useMemo(
		() => formatDate(now, "DD MMMM yyyy"),
		[formatDate, now],
	);

	const time = useMemo(() => formatDate(now, "kk:mm:ss"), [formatDate, now]);

	return <LabeledContent label={date}>{time}</LabeledContent>;
});

export default DateTime;
