import { RefObject } from "react";
import { react } from "uikit";
import Name from "./components/Name";
import CompaniesAndTaxiServices from "./components/CompaniesAndTaxiServices";

interface Context {
	nameRef: RefObject<Name.Ref | null>;
	companyBranchRef: RefObject<CompaniesAndTaxiServices.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = !!this.context?.nameRef.current?.validate();
		const isBranchesValid =
			!!this.context?.companyBranchRef.current?.validate();

		return isNameValid && isBranchesValid;
	}
}
