import styled from "styled-components";
import { Row, DatePicker, TimeInput } from "uikit";

export const StyledDatePicker = styled(DatePicker)`
	div {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: none;
	}
`;

export const StyledTimeInput = styled(TimeInput)`
	div {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
`;

const Root = styled(Row)`
	&:hover div {
		border-color: black;
	}
`;

export default Root;
