import { Dispatch, RefObject } from "react";
import { react } from "uikit";
import { omit } from "lodash";

import {
	findValuesByLanguageKey,
	findValueByLanguageKey,
	Language,
} from "../../../assets/languages/langs";

import NameForm from ".";

interface Context {
	rootRef: RefObject<HTMLDivElement | null>;

	value?: NameForm.Value;

	setError: Dispatch<boolean>;
	languageExclude: Language[];
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const languageExclude = this.context?.languageExclude || [];
		const names = this.context?.value || {};
		const isValues = findValuesByLanguageKey(names)
			.filter((item) => {
				const keys = omit(item, ...languageExclude);
				if (Object.keys(keys)?.length) return true;
				return false;
			})
			.map((item) => !!findValueByLanguageKey(item)?.trim()?.length);

		if (!isValues.every((item) => item === true)) {
			this.context?.setError(true);

			this.context?.rootRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});

			return false;
		}

		return true;
	}

	scrollIntoView(argument?: boolean | ScrollIntoViewOptions) {
		this.context?.rootRef.current?.scrollIntoView(argument);
	}
}
