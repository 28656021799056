/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateRuleOrderType {
	LIVE = "live",
	PRELIMINARY = "preliminary",
	EXECUTING = "executing",
}

export const orderTypeOptions = getOptions(
	Object.values(AutoRateRuleOrderType),
);
