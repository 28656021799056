import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Accounting } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	executors: [],
	executor: null,
	counters: null,
};

const setExecutors: ReduxReducer<State, State["executors"]> = (
	state,
	{ payload },
) => {
	state.executors = payload;
};

const setExecutor: ReduxReducer<State, State["executor"]> = (
	state,
	{ payload },
) => {
	state.executor = payload;
};

const updateExecutor: ReduxReducer<State, State["executor"]> = (
	state,
	{ payload },
) => {
	if (payload) {
		state.executors = [...state.executors].map((item) => {
			if (item.id === payload.id) return payload;
			return item;
		});
	}
};

const setCounters: ReduxReducer<State, State["counters"]> = (
	state,
	{ payload },
) => {
	state.counters = payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const data = createSlice({
	name: Accounting.ConstantAccountingSlice.EXECUTORS,
	initialState,
	reducers: {
		reset,
		setExecutors,
		setExecutor,
		setCounters,
		updateExecutor,
	},
});

export const dataAction = data.actions;
export const dataReducer = data.reducer;
export type dataState = State;

export default data;
