import React, { memo } from "react";
import styled from "styled-components";
import { Tabs } from "uikit";

const StyledTabs = styled(Tabs)`
	& > * {
		white-space: normal;
		word-wrap: break-word;
		height: auto;
		padding: 11px 10px;
	}
`;

const SideTabBase: React.FC<SideTabBase.Props> = ({ ...props }) => (
	<StyledTabs {...props} />
);

declare namespace SideTabBase {
	interface Props extends React.ComponentProps<typeof Tabs> {}
}

/**
 * `SideTabBase` is a specialized component designed for sidebar tabs within page layouts. It acts as local menus
 * to switch between internal sections or content areas within a page.
 *
 * This component enhances the default `Tabs` component with custom styling for a more user-friendly design.
 * Key style improvements include:
 * - Dynamic height adjustment to accommodate varying content sizes.
 * - Word wrapping to prevent content overflow.
 * - Added padding for better spacing and improved layout.
 *
 * These enhancements make `SideTabBase` ideal for sidebar navigation where tabs function as localized menus
 * with a flexible, responsive style.
 *
 * **TODO:**
 * - Replace the `Tabs` component from `uikit` with components from the `StyledColumn` and `StyledRow` subgroup.
 * - Minimize the use of `styled-components` for better optimization and maintainability.
 *
 * @component
 * @param props - Additional properties inherited from the `Tabs` component in `uikit`.
 */
export const SideTab = memo(SideTabBase);
export default SideTabBase;
