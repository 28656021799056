import styled from "styled-components";
import { Button as ButtonBase, theme } from "uikit";

const Button = styled(ButtonBase.Button)`
	width: 32px;
	height: 32px;

	box-sizing: border-box;

	${({ variant, disabled }) =>
		// eslint-disable-next-line no-nested-ternary
		variant === "secondary"
			? disabled
				? `
        border: 1px solid ${theme.colors.button_secondary} !important;
        background: ${theme.colors.white} !important;
		padding: 5px;
      `
				: `
        border: 1px solid #D7D9DA !important;
        background: ${theme.colors.button_secondary} !important;
        padding: 5px;
      `
			: ""}
`;

export default Button;
