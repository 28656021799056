import React, {
	useCallback,
	useState,
	useMemo,
	useLayoutEffect,
	memo,
} from "react";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";

import { RoundingMethod } from "../../../../../../../../../redux/constants/OrdersPage/order";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import { EditIcon } from "../../../../../../../../../icons/edit";
import {
	NumberInput,
	NumberInputMemo,
} from "../../../../../../../../../components/Orders";
import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";
import { Gray, Primary } from "../../styled";

import Clickable from "./Clickable";

interface Props extends OrderFormProps {
	edit: boolean;
	setEdit: (edit: boolean) => void;
}

const AdditionalCost: React.FC<Props> = ({
	disabled,
	edit,
	setEdit,
	form,
	tab,
}) => {
	const { t } = useTranslation();

	const [value, onChangeValue] = useState<NumberInput.Value>({
		action: "add",
		value: 0,
	});
	const watchedPrice = useMemo(() => tab.form?.price ?? 0, [tab.form?.price]);

	const roundingMultipleValue = useMemo(
		() => tab.form?.priceSettings?.rounding?.multiple?.value ?? 0,
		[tab.form?.priceSettings?.rounding?.multiple?.value],
	);
	const roundingMultipleActive = useMemo(
		() => tab.form?.priceSettings?.rounding?.multiple?.active ?? false,
		[tab.form?.priceSettings?.rounding?.multiple?.active],
	);
	const roundingMethod = useMemo(
		() =>
			tab.form?.priceSettings?.rounding?.method ?? RoundingMethod.GENERAL,
		[tab.form?.priceSettings?.rounding?.method],
	);

	const precision = useMemo(
		() => tab.form?.priceSettings?.rounding?.precision ?? 2,
		[tab.form?.priceSettings?.rounding?.precision],
	);

	const additionalCost = useMemo(
		() => tab.form.additionalCost || 0,
		[tab.form.additionalCost],
	);

	const currencyName = useMemo(
		() => tab.form?.currency?.settings?.name || "",
		[tab.form?.currency],
	);

	const valueEditor = useObjectEditor(value, onChangeValue);

	const setAction = valueEditor.useSetter("action");
	const valueCost = valueEditor.useGetter("value");
	const setValue = valueEditor.useSetter("value");

	const stringifyPrice = useCallback(
		(price: number | undefined) => {
			if (isUndefined(price)) return "-";

			let processedPrice = price;

			if (roundingMultipleActive) {
				processedPrice /= roundingMultipleValue;
			} else {
				processedPrice *= 10 ** precision;
			}

			switch (roundingMethod) {
				case RoundingMethod.GENERAL:
					processedPrice = Math.round(processedPrice);
					break;
				case RoundingMethod.CEIL:
					processedPrice = Math.ceil(processedPrice);
					break;
				case RoundingMethod.FLOOR:
					processedPrice = Math.floor(processedPrice);
					break;
				default:
					break;
			}

			if (roundingMultipleActive) {
				processedPrice *= roundingMultipleValue;
			} else {
				processedPrice /= 10 ** precision;
			}

			return `${processedPrice.toFixed(precision)}`.trim();
		},
		[
			precision,
			roundingMethod,
			roundingMultipleActive,
			roundingMultipleValue,
		],
	);

	useLayoutEffect(() => {
		if (valueCost !== additionalCost) setValue(additionalCost);
	}, [additionalCost, setValue, valueCost]);

	const onChange = useCallback(
		(data: NumberInput.Value) => {
			setValue(data.value);
			setAction(data.action);
			const dataValue = data.value;
			const cost = dataValue + Number(additionalCost);
			const prevPrice = Number(watchedPrice) - Number(additionalCost);
			const payload = Number(additionalCost) + dataValue;

			if (data.action === "set") {
				if (cost + prevPrice < 0) {
					form.setValue(
						"additionalCost",
						-Number(stringifyPrice(prevPrice)),
					);
					setEdit(false);
					return;
				}

				form.setValue("additionalCost", dataValue);
			} else {
				if (cost + prevPrice <= 0) {
					form.setValue(
						"additionalCost",
						-Number(stringifyPrice(prevPrice)),
					);
					setEdit(false);
					return;
				}

				form.setValue("additionalCost", payload);
			}
			setEdit(false);
		},
		[
			setValue,
			setAction,
			additionalCost,
			watchedPrice,
			setEdit,
			form,
			stringifyPrice,
		],
	);

	return (
		<StyledRow alignItems="end" gap="0 6px">
			<Primary>
				{`${t([
					`orderPage.order_form.additional_cost`,
					"Additional cost",
				])}:`}
			</Primary>
			<Gray style={{ whiteSpace: "nowrap" }}>
				{`${stringifyPrice(additionalCost)} ${currencyName}`}
			</Gray>

			{!disabled && (
				<Clickable onClick={() => setEdit(true)}>
					<EditIcon />
				</Clickable>
			)}

			{edit && (
				<NumberInputMemo
					shadow
					title={
						t([
							`orderPage.order_form.additional_cost`,
							"Additional cost",
						]) ?? ""
					}
					unit={currencyName ?? ""}
					value={value}
					onSubmit={onChange}
					onClose={() => setEdit(false)}
				/>
			)}
		</StyledRow>
	);
};

export default memo(AdditionalCost);
