import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { ordersAction as orders } from "../redux/reducers/Orders"; // TODO replace in chat, all addresses with direct ones from src/redux/reducers/Orders
import { Executor } from "../services";

const useExecutorsActions = (): useExecutorsActions.Props => {
	const dispatch = useDispatch();

	const onSaveColumnsIdsTable = useCallback(
		(value: string[]) => {
			dispatch(orders.executors.tableSettings.setColumnIds(value));
		},
		[dispatch],
	);

	const setExecutorsSort = useCallback(
		({ column, type }) => {
			dispatch(
				orders.executors.filter.setExecutorsSort({ column, type }),
			);
		},
		[dispatch],
	);

	const setIsSortMap = useCallback<useExecutorsActions.Props["setIsSortMap"]>(
		(bool) => {
			dispatch(orders.executors.filter.setIsSortMap(bool));
		},
		[dispatch],
	);

	const setExecutorsData = useCallback<
		useExecutorsActions.Props["setExecutorsData"]
	>(
		(data) => {
			dispatch(orders.executors.data.setExecutorsData(data));
		},
		[dispatch],
	);

	return useMemo(
		() => ({
			onSaveColumnsIdsTable,
			setExecutorsSort,
			setExecutorsData,
			setIsSortMap,
		}),
		[
			onSaveColumnsIdsTable,
			setExecutorsSort,
			setIsSortMap,
			setExecutorsData,
		],
	);
};

declare namespace useExecutorsActions {
	interface Props {
		onSaveColumnsIdsTable: (value: string[]) => void;
		setExecutorsSort: (data: { column: string; type: string }) => void;
		setIsSortMap: (bool: boolean) => void;
		setExecutorsData: (value: Executor.Model[]) => void;
	}
}

export default useExecutorsActions;
