/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, {
	PropsWithChildren,
	ReactNode,
	useCallback,
	useMemo,
} from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import StyleProps from "uikit/dist/types/StyleProps";
import { ClickAwayListener } from "@mui/material";
import { extend } from "lodash";
import { useDispatch } from "react-redux";
import { Row } from "uikit";
import styled from "styled-components";

import softphoneWidget from "../../redux/reducers/softphoneWidget";
import { useTypedSelector } from "../../redux/store";
import Modal from "../Modal";

import LeftButtons from "./components/LeftButtons";
import RightButtons from "./components/RightButtons";
import Header from "./components/Header";
import Root from "./components/Root";
import Footer from "./components/Footer";

const EmptyBlock = styled("div")`
	width: 44px;
`;

// eslint-disable-next-line prettier/prettier
const InputModalSoftphone = extend(
	(({
		children,

		style,
		className,

		draggable = true,
		title,
		dimmed = true,
		useDefaultHeader = true,
		useDefaultFooter = true,
		transfer = false,
		pointerEvents = "auto",
		action,
		position,
		onChangePosition,

		onClose,
		onSubmit,
	}) => {
		const dispatch = useDispatch();
		const headerOnClose = useCallback(() => {
			onClose?.();
		}, [onClose]);

		const footerOnCancel = useCallback(() => {
			onClose?.();
		}, [onClose]);

		const footerOnSave = useCallback(() => {
			onSubmit?.();
		}, [onSubmit]);

		const onFocus = useCallback(() => {
			dispatch(softphoneWidget.actions.setIsFocused(true));
		}, [dispatch]);

		const onUnfocus = useCallback(() => {
			dispatch(softphoneWidget.actions.setIsFocused(false));
		}, [dispatch]);

		const { isReduce, tab } = useTypedSelector(
			(state) => state.softphoneWidget,
		);

		const content = useMemo(
			() => (
				<Row
					align="center"
					style={{
						backgroundColor: "transparent",
						position: "relative",
					}}
				>
					{!transfer && !isReduce && <LeftButtons />}
					<Root
						style={style}
						className={className}
						sizes={"auto! 1fr auto!"}
					>
						{useDefaultHeader && (
							<Header
								draggable={draggable}
								title={title}
								onClose={headerOnClose}
								action={action}
								isReduce={isReduce}
							/>
						)}
						{children}
						{useDefaultFooter && (
							<Footer
								onCancel={footerOnCancel}
								onSave={footerOnSave}
							/>
						)}
					</Root>
					{!transfer && !isReduce && tab === "dialing" && (
						<RightButtons />
					)}
					{!transfer && !isReduce && tab !== "dialing" && (
						<EmptyBlock />
					)}
				</Row>
			),
			[
				action,
				children,
				className,
				draggable,
				footerOnCancel,
				footerOnSave,
				headerOnClose,
				isReduce,
				style,
				tab,
				title,
				transfer,
				useDefaultFooter,
				useDefaultHeader,
			],
		);

		return (
			<Modal
				dimmed={dimmed}
				propagateKeyBinds={true}
				useFocusTrap={false}
				zIndex={999}
				pointerEvents={pointerEvents}
			>
				{draggable ? (
					<ClickAwayListener
						mouseEvent="onMouseDown"
						onClickAway={onUnfocus}
					>
						<Draggable
							handle=".draggable-area"
							position={position}
							onStop={onChangePosition}
							onMouseDown={onFocus}
							bounds="parent"
						>
							{content}
						</Draggable>
					</ClickAwayListener>
				) : (
					content
				)}
			</Modal>
		);
	}) as React.FC<InputModalSoftphone.Props>,
	{
		root: (Root as any).styledComponentId,
	},
);

declare namespace InputModalSoftphone {
	interface Props extends PropsWithChildren, StyleProps {
		draggable?: boolean;
		title?: ReactNode;
		dimmed?: boolean;
		useDefaultHeader?: boolean;
		useDefaultFooter?: boolean;
		transfer?: boolean;
		pointerEvents?:
			| "none"
			| "auto"
			| "visiblePainted"
			| "visibleFill"
			| "visibleStroke"
			| "painted"
			| "fill"
			| "stroke";
		action?: ReactNode;
		position?: { x: number; y: number };
		onChangePosition?: DraggableEventHandler;

		onClose?: () => void;
		onSubmit?: () => void;
	}
}

export default InputModalSoftphone;
