import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import {
	getOrderPassengerPoints,
	stringifyPoint,
} from "../../../../../../../redux/constants/OrdersPage/order";

const DestinationAddressCellContent: React.FC<
	DestinationAddressCellContent.Props
> = ({ item }) => {
	const { t } = useTranslation();
	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const points = useMemo(() => getOrderPassengerPoints(item), [item]);

	const content = useMemo(
		() =>
			points?.at(-1)
				? stringifyPoint(points.at(-1), "address", pointOnMap)
				: "",
		[pointOnMap, points],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace DestinationAddressCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default DestinationAddressCellContent;
