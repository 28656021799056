/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CheckBox, Column, MultiSelect, Row, theme } from "uikit";

import Language from "../../../../../../../../../../../../../../services/Language";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import Company from "../../../../../../../../../../../../../../services/Company";
import PushMessage from "../../../../../../../../../../../../../../services/PushMessage";
import useCarBrandSubscription from "../../../../../../../../../../../../../../hooks/useCarBrandSubscription";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";

import StoreList from "../../../../../../../../../../../../../../types/StoreList";
import { ModalFilter } from "../../../../../../../..";

interface Props {
	taxiServiceData: StoreList<TaxiService.Model> | undefined;
	language: Language;
	simpleStatuses: PushMessage.NotificationMessageSimpleStatus[];
	setSimpleStatuses: React.Dispatch<
		React.SetStateAction<PushMessage.NotificationMessageSimpleStatus[]>
	>;
	types: PushMessage.NotificationMessageType[];
	setTypes: React.Dispatch<
		React.SetStateAction<PushMessage.NotificationMessageType[]>
	>;
	onChangeRequestData: React.Dispatch<React.SetStateAction<ModalFilter>>;
	possibleCompanies: Company.Model[];
	possibleTaxiServices: TaxiService.Model[];
	companyIds: number[];
	taxiServiceIds: number[];
	setCompanyIds: (newCompanyIds: number[]) => void;
	setTaxiServiceIds: (newTaxiServiceIds: number[]) => void;
	error: {
		taxiService: boolean;
		company: boolean;
	};
	onChangeError: React.Dispatch<
		React.SetStateAction<{
			taxiService: boolean;
			company: boolean;
		}>
	>;
}

const CheckboxText = styled("span")`
	font-size: 14px;
	color: ${theme.colors.text_placeholder};
	cursor: default;
	line-height: 20px;
`;

const Content: React.FC<Props> = ({
	language,
	simpleStatuses,
	setSimpleStatuses,
	types,
	setTypes,
	onChangeRequestData,
	possibleCompanies,
	possibleTaxiServices,
	companyIds,
	taxiServiceIds,
	setCompanyIds,
	setTaxiServiceIds,
	error,
	onChangeError,
}) => {
	const { t } = useTranslation();
	const possibleCompanyOptions = useMemo(
		() =>
			possibleCompanies.map((company) => ({
				key: company.id,
				label: company.name[language],
				value: company.id,
			})),
		[language, possibleCompanies],
	);

	const companyIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setCompanyIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, company: false }));
		},
		[onChangeError, setCompanyIds],
	);

	const possibleTaxiServiceOptions = useMemo(
		() =>
			possibleTaxiServices.map((taxiService) => ({
				key: taxiService.id,
				label: `${taxiService?.settlement[language]} (${taxiService.company?.name[language]}) `,
				value: taxiService.id,
			})),
		[language, possibleTaxiServices],
	);

	const taxiServiceIdsOnChange = useCallback(
		(newCompanyIds: MultiSelect.Value) => {
			setTaxiServiceIds(newCompanyIds as number[]);
			onChangeError((prev) => ({ ...prev, taxiService: false }));
		},
		[onChangeError, setTaxiServiceIds],
	);

	const [showCompany, setShowCompany] = useState(false);
	const [showTaxiService, setShowTaxiService] = useState(false);
	const openCompany = useCallback(() => {
		setShowCompany(true);
	}, []);

	const closeCompany = useCallback(() => {
		setShowCompany(false);
	}, []);

	const openTaxiService = useCallback(() => {
		setShowTaxiService(true);
	}, []);

	const closeTaxiService = useCallback(() => {
		setShowTaxiService(false);
	}, []);

	const onFocusCompany = useCallback(() => {
		openCompany();
		closeTaxiService();
	}, [closeTaxiService, openCompany]);

	const onFocusTaxiService = useCallback(() => {
		openTaxiService();
		closeCompany();
	}, [closeCompany, openTaxiService]);

	const closeCompanyAndTaxiService = useCallback(() => {
		closeCompany();
		closeTaxiService();
	}, [closeCompany, closeTaxiService]);

	const onChageStatusesCheckbox = useCallback(
		(
			value: boolean,
			status: PushMessage.NotificationMessageSimpleStatus,
		) => {
			if (value && !simpleStatuses?.includes(status)) {
				setSimpleStatuses([...simpleStatuses, status]);
			} else if (!value && simpleStatuses?.includes(status)) {
				setSimpleStatuses([
					...simpleStatuses.filter((el) => el !== status),
				]);
			}
		},
		[setSimpleStatuses, simpleStatuses],
	);

	const isDelivered = useMemo(
		() => simpleStatuses?.includes("DELIVERED"),
		[simpleStatuses],
	);
	const isUndeliverable = useMemo(
		() => simpleStatuses?.includes("UNDELIVERABLE"),
		[simpleStatuses],
	);
	const isRead = useMemo(
		() => simpleStatuses?.includes("READ"),
		[simpleStatuses],
	);

	const onChangeDelivered = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "DELIVERED");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeUndeliverable = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "UNDELIVERABLE");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);
	const onChangeRead = useCallback(
		(value) => {
			onChageStatusesCheckbox(value, "READ");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageStatusesCheckbox],
	);

	const onChageTypeCheckbox = useCallback(
		(value: boolean, type: PushMessage.NotificationMessageType) => {
			if (value && !types?.includes(type)) {
				setTypes([...types, type]);
			} else if (!value && types?.includes(type)) {
				setTypes([...types.filter((el) => el !== type)]);
			}
		},
		[setTypes, types],
	);

	const isInfo = useMemo(() => types?.includes("info"), [types]);
	const isOrder = useMemo(() => types?.includes("order"), [types]);

	const onChangeInfo = useCallback(
		(value) => {
			onChageTypeCheckbox(value, "info");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageTypeCheckbox],
	);
	const onChangeOrder = useCallback(
		(value) => {
			onChageTypeCheckbox(value, "order");
			closeCompanyAndTaxiService();
		},
		[closeCompanyAndTaxiService, onChageTypeCheckbox],
	);

	useEffect(() => {
		onChangeRequestData({
			taxiServiceIds,
			companyIds,
			simpleStatuses,
			types,
		});
	}, [
		companyIds,
		onChangeRequestData,
		simpleStatuses,
		taxiServiceIds,
		types,
	]);

	return (
		<Row gaps="12px*">
			<Column gaps="12px" maxedWidth>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str0",
						) ?? ""
					}
				>
					<MultiSelect
						value={companyIds}
						all
						open={showCompany}
						focused={showCompany}
						onFocus={onFocusCompany}
						onBlur={closeCompany}
						placeholder={
							t(
								"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str1",
							) ?? ""
						}
						error={error.company}
						options={possibleCompanyOptions}
						onChange={companyIdsOnChange}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str2",
						) ?? ""
					}
				>
					<MultiSelect
						value={taxiServiceIds}
						all
						open={showTaxiService}
						focused={showTaxiService}
						onFocus={onFocusTaxiService}
						onBlur={closeTaxiService}
						placeholder={
							t(
								"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str3",
							) ?? ""
						}
						error={error.taxiService}
						options={possibleTaxiServiceOptions}
						onChange={taxiServiceIdsOnChange}
					/>
				</LabeledField>
			</Column>
			<Column onClick={closeCompanyAndTaxiService} maxedWidth>
				<FieldsContainer
					label={
						t(
							"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str4",
						) ?? ""
					}
					labelColor={theme.colors.text_placeholder}
					style={{ height: "100%" }}
				>
					<Column gaps="12px*" maxedHeight>
						<Row gaps="10px">
							<CheckBox
								value={isOrder}
								onChange={onChangeOrder}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str100",
								) ?? ""}
							</CheckboxText>
						</Row>
						<Row gaps="10px">
							<CheckBox value={isInfo} onChange={onChangeInfo} />
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str101",
								) ?? ""}
							</CheckboxText>
						</Row>
					</Column>
				</FieldsContainer>
			</Column>
			<Column onClick={closeCompanyAndTaxiService} maxedWidth>
				<FieldsContainer
					label={
						t(
							"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str5",
						) ?? ""
					}
					labelColor={theme.colors.text_placeholder}
					style={{ height: "100%" }}
				>
					<Column gaps="12px*" maxedHeight>
						<Row gaps="10px">
							<CheckBox
								value={isDelivered}
								onChange={onChangeDelivered}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str102",
								) ?? ""}
							</CheckboxText>
						</Row>
						<Row gaps="10px">
							<CheckBox
								value={isUndeliverable}
								onChange={onChangeUndeliverable}
							/>
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str103",
								) ?? ""}
							</CheckboxText>
						</Row>
						<Row gaps="10px">
							<CheckBox value={isRead} onChange={onChangeRead} />
							<CheckboxText>
								{t(
									"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.content.str104",
								) ?? ""}
							</CheckboxText>
						</Row>
					</Column>
				</FieldsContainer>
			</Column>
		</Row>
	);
};

declare namespace Content {
	interface Value extends Pick<useCarBrandSubscription.Filter, "activity"> {}

	export interface PropsBase {}
}

export default Content;
