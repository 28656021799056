import React, { memo } from "react";
import { Key } from "uikit";

import { StyledColumn, CardModal, CardModalBase } from "../../../common";

import List from "./components/List";

const BaseListModal = <OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
	onSave,
	showCheckBox,
	onEvents,
	...props
}: BaseListModal.Props<OptionValue, ValueType>) => (
	<CardModal {...props} onSave={onSave}>
		<StyledColumn
			gap="8px"
			alignItems="center"
			w="450px"
			h={{ max: "500px" }}
			bgC="#ffffff"
		>
			<List
				value={value}
				options={options}
				onChange={onChange}
				onSave={onSave}
				onEvents={onEvents}
				showCheckBox={showCheckBox}
			/>
		</StyledColumn>
	</CardModal>
);

declare namespace BaseListModal {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key>
		extends CardModalBase.Props,
			List.Props<OptionValue, ValueType> {}
}

export const ListModal = memo(BaseListModal);

export default BaseListModal;
