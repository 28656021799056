import { Map } from "leaflet";
import { Dispatch } from "react";
import { react } from "uikit";
import { isUndefined } from "lodash";
import ExecutorLocations from "../../services/ExecutorLocations";

interface Context {
	setFocusedExecutorId: Dispatch<number | undefined>;

	map: Map;
	executorLocations: ExecutorLocations.Model[];
	focusedExecutorId: number | undefined;
}

export default class Controller extends react.ControllerBase<Context> {
	focus() {
		if (!this.context) return false;

		const { focusedExecutorId, executorLocations, map } = this.context;

		if (isUndefined(focusedExecutorId)) return false;

		const executorLocation = executorLocations.find(
			(executorLocation) =>
				executorLocation.executor.id === focusedExecutorId,
		);

		if (!executorLocation) return false;

		map.setView(executorLocation.point, 16, { animate: false });

		return true;
	}

	focusExecutor(id: number) {
		this.context?.setFocusedExecutorId(id);
	}

	unfocus() {
		this.context?.setFocusedExecutorId(undefined);
	}
}
