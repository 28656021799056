import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { StyledRow } from "../../../../../../../../../../components/common";

const Footer: React.FC<Footer.Props> = ({ value, onChange }) => (
	<StyledRow gap="24px"></StyledRow>
);

declare namespace Footer {
	interface Value {
		active: boolean;
		default: boolean;
	}

	interface Props {
		value: Value;

		disabled: boolean;
		assignableTo?: "dispatcher" | "executor";

		onChange: Dispatch<Value>;
	}
}

export default Footer;
