import styled from "styled-components";

const Root = styled.div`
	height: 40px;

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
`;

export default Root;
