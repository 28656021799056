import React from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, react } from "uikit";

const defaultValue: Content.Value = {
	showAll: true,
	showObjects: false,
	showStreets: false,
	showHiddenInMobileApp: false,
	hideNotActive: false,
};

const selectShowAll: Partial<Content.Value> = {
	showObjects: false,
	showStreets: false,
	showHiddenInMobileApp: false,
	hideNotActive: false,
};

const unselectShowAll: Partial<Content.Value> = {
	showObjects: true,
	showStreets: true,
	showHiddenInMobileApp: true,
	hideNotActive: true,
};

const selectNotShowAll: Partial<Content.Value> = {
	showAll: false,
};

const Content = react.inputify<Content.PropsBase, Content.Value>(
	({
		value = defaultValue,

		onChange,
	}) => {
		const { t } = useTranslation();
		return (
			<Column gaps="12px*">
				<CheckBoxWithContent
					gap="10px"
					value={value?.showAll}
					onChange={(showAll) =>
						onChange({
							...value,
							showAll,
							...(showAll ? selectShowAll : unselectShowAll),
						})
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.header.filters.popover.content.str130",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					value={value?.showObjects}
					gap="10px"
					onChange={(showObjects) =>
						onChange({
							...value,
							showObjects,
							...(showObjects ? selectNotShowAll : {}),
						})
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.header.filters.popover.content.str131",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					value={value?.showStreets}
					gap="10px"
					onChange={(showStreets) =>
						onChange({
							...value,
							showStreets,
							...(showStreets ? selectNotShowAll : {}),
						})
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.header.filters.popover.content.str132",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					gap="10px"
					value={value?.showHiddenInMobileApp}
					onChange={(showHiddenInMobileApp) =>
						onChange({
							...value,
							showHiddenInMobileApp,
							...(showHiddenInMobileApp ? selectNotShowAll : {}),
						})
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.header.filters.popover.content.str133",
					) ?? ""}
				</CheckBoxWithContent>
				<CheckBoxWithContent
					gap="10px"
					value={value?.hideNotActive}
					onChange={(hideNotActive) =>
						onChange({
							...value,
							hideNotActive,
							...(hideNotActive ? selectNotShowAll : {}),
						})
					}
				>
					{t(
						"pages.preferencesPages.screenDirectory.objects.header.filters.popover.content.str134",
					) ?? ""}
				</CheckBoxWithContent>
			</Column>
		);
	},
);

declare namespace Content {
	interface Value {
		showAll: boolean;
		showObjects: boolean;
		showStreets: boolean;
		showHiddenInMobileApp: boolean;
		hideNotActive: boolean;
	}

	export interface PropsBase {}
}

export default Content;
