/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { useTranslation } from "react-i18next";

import { useTypedDispatch } from "../../../../../redux/store";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
// import voip, {
// 	voipMeta,
// 	VoipMeta,
// 	VoipState,
// } from "../../../../../redux/reducers/settings/voip";

/* * * Seed for the future* * */

const General: React.FC<SettingsTabProps> = ({ methods, onHasChanges }) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	// const [snapshot, setSnapshot] = useSnapshot({
	// 	state: useTypedSelector((state) => state.settings.voip),
	// 	methods,
	// 	onHasChanges,
	// 	onSave(snapshot) {
	// 		dispatch(voip.actions.setAll(snapshot));
	// 	},
	// 	onResetDefaults() {
	// 		dispatch(voip.actions.resetDefaults());
	// 		return voip.getInitialState();
	// 	},
	// });

	return (
		<>soon</>

		// <SettingList<never, never, VoipState, VoipMeta>
		// 	state={snapshot}
		// 	meta={voipMeta}
		// 	name={(key) => t(`settings.voip.general.${key}`)}
		// 	onChange={(newValue, key) => {
		// 		setSnapshot((prev) =>
		// 			prev[key] === newValue
		// 				? prev
		// 				: { ...prev, [key]: newValue },
		// 		);
		// 	}}
		// />
	);
};

export default General;
