// /* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State, BtnOnMapToggles } from "./interface";

export const baseBtnOnMap: BtnOnMapToggles = {
	route: true,
	priceZones: false,
	sectors: false,
	parkings: false,
	executors: true,
	clients: false,
};

const initialState: State = {
	btnOnMap: baseBtnOnMap,
};

const toggleAllButtonsOnMap: ReduxReducer<State, BtnOnMapToggles> = (
	state,
	action,
) => {
	state.btnOnMap = { ...baseBtnOnMap, ...action.payload };
};

const orderMapToggles = createSlice({
	name: Orders.ConstantOrderSlice.MAP_TOGGLES,
	initialState,
	reducers: {
		toggleAllButtonsOnMap,
	},
});

export const orderMapTogglesAction = orderMapToggles.actions;
export const orderMapTogglesReducer = orderMapToggles.reducer;
export type orderMapTogglesState = State;

export default orderMapToggles;
