import React, { Dispatch, memo } from "react";

import Currency from "../../../../../../../../../../services/Currency";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	SuspenseLoader,
} from "../../../../../../../../../../components/common";
import { AdditionalTariffTabs } from "../../constants/tabs";

import Root from "./components/Root";
import Main from "./tabs/Main";

const Content: React.FC<Content.Props> = memo(
	({ currencies, value, onChange, tab }) => {
		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"name",
			"additionalFields",
			"currency",
		]);
		const updateAllData = valueEditor.useAssigner();

		const getActualTab = (currentTab: AdditionalTariffTabs) => {
			switch (currentTab) {
				case AdditionalTariffTabs.MAIN:
					return (
						<Main
							currencies={currencies}
							value={mainTabValue}
							onChange={updateAllData}
						/>
					);

				case AdditionalTariffTabs.HISTORY:
					return (
						<StyledRow position="absolute" top="50%" left="50%">
							<SuspenseLoader />
						</StyledRow>
					);

				default:
					return <></>;
			}
		};
		return <Root>{getActualTab(tab)}</Root>;
	},
);

declare namespace Content {
	interface Props {
		currencies: Currency.Model[];
		value: Value;
		onChange: Dispatch<Value>;
		tab: AdditionalTariffTabs;
	}
	interface Value extends Main.Value {}
}

export default Content;
