import { TFunction } from "i18next";

interface Column {
	id: string;
	label: string;
}

const getColumns = (t: TFunction): Column[] => [
	{
		id: "fullName",
		label: t("executorSelectTab.modelTable.fullName") ?? "",
	},
	{
		id: "roles",
		label: t("executorSelectTab.modelTable.roles") ?? "",
	},
	{
		id: "alias",
		label: t("executorSelectTab.modelTable.alias") ?? "",
	},
	{
		id: "company",
		label: t("executorSelectTab.modelTable.company") ?? "",
	},
	{
		id: "taxiService",
		label: t("executorSelectTab.modelTable.taxiService") ?? "",
	},
]; // columns

const defaultColumnIds = [
	"fullName",
	"roles",
	"alias",
	"company",
	"taxiService",
];

export { getColumns, defaultColumnIds };
