/**
 * Collection of simple constants for the project.
 */

const KEYBOARD_CODES = {
	ARROW_LEFT: "ArrowLeft",
	ARROW_RIGHT: "ArrowRight",
	ESCAPE: "Escape",
	ENTER: "Enter",
	SHIFT: "Shift",
	TAB: "Tab",
	ARROW_UP: "ArrowUp",
	ARROW_DOWN: "ArrowDown",
	NUMPAD_ADD: "NumpadAdd",
	MINUS: "Minus",
	ALT: "Alt",
	CONTROL: "Control",
	PAGE_UP: "PageUp",
	PAGE_DOWN: "PageDown",
} as const;

const OPERATIONAL_ROLES = {
	EXECUTORS: "executors",
	EXECUTOR: "executor",
	DISPATCHERS: "dispatchers",
	DISPATCHER: "dispatcher",
};

export { KEYBOARD_CODES, OPERATIONAL_ROLES };
