/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";

import { NonEditableProperties } from "../../types/NonEditableProperties";
import Base from "../Base";
import Language from "../Language";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createRPCQuery from "../../utils/createRPCQuery.util";
import TaxiService from "../TaxiService";
import Client from "../Client";
import SMSProvider from "../SMSProvider";
import Dispatcher from "../Dispatcher";
import SMSTemplate from "../SMSTemplate";

class Message extends Base {
	public static async send(data: Message.Send) {
		const res = await this.request((prpc) =>
			prpc.theirsModel.sms.message.send(data),
		);

		return res;
	}

	public static async sendPushNotice(data: Message.SendPushNotice) {
		const res = await this.request((prpc) =>
			prpc.theirsModel.notification.message.send(data),
		);

		return res;
	}

	public static async subscribe(
		options: Message.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Message.Model>,
	): Promise<Subscription<Message.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				createRPCQuery(() =>
					prpc.theirsModel.sms.message.subscribe({
						params: this.optionsToRequest(options),
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							console.error(error);
						},
					}),
				),
			{ name: "Message.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Message.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Message.SubscribeOptions>;
	}

	static fromResponse(data: any): Message.Model {
		return {
			id: data.id,
			additionalFields: data.additionalFields,
			phone: data.phone,
			text: data.text,
			status: data.status,
			simpleStatus: data.simpleStatus,
			type: data.type,
			sender: data.sender,

			customer: data.customer ? Client.fromResponse(data.customer) : null,
			dispatcher: data.dispatcher
				? Dispatcher.fromResponse(data.dispatcher)
				: null,
			smsProvider: data.smsProvider,
			smsTemplate: data.smsTemplate,
			taxiService: data.taxiService
				? TaxiService.fromResponse(data.taxiService)
				: null,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			expiredAt: data.expiredAt,
		};
	}

	private static optionsToRequest(options: Message.SubscribeOptions) {
		const request: Record<string, any> = {
			limit: options.limit,
			offset: options.offset,
			statuses: options.statuses,
			isActive: options.isActive,
			taxiServiceIds: options.taxiServiceIds,
			lang: options.language,
			order: options.order,
			dateRange: options.dateRange,
			simpleStatuses: options.simpleStatuses,
			types: options.types,
		};

		return request;
	}
}

declare namespace Message {
	interface Model extends Omit<NonEditableProperties, "deletedAt"> {
		additionalFields: any;
		customer: Client.Model | null;
		dispatcher: Dispatcher.Model | null;
		phone: string | null;
		smsProvider: SMSProvider.Model | null;
		smsTemplate: SMSTemplate.Model | null;
		status: SmsMessageStatus;
		simpleStatus: SmsMessageSimpleStatus;
		taxiService: TaxiService.Model | null;
		text: string;
		type: SmsMessageType;
		sender: string;

		expiredAt: string | null;
	}

	interface Send {
		contacts: {
			phone: string;
			taxiServiceId: number;
			smsProviderId: number;
		}[];
		data:
			| {
					smsTemplateId: number;
			  }
			| { text: string };
	}

	interface SendPushNotice {
		contacts: ({
			taxiServiceId: number;
		} & (
			| { customerId: number }
			| { executorId: number }
			| { agentId: number }
		))[];
		data:
			| {
					notificationTemplateId: number;
			  }
			| {
					text: string;
					title: string;
					bigPictureId: number;
					largeIconId: number;
			  };
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Message.Model> {
		statuses?: SmsMessageStatus[];
		isActive?: boolean;
		taxiServiceIds?: number[];
		language?: Language;
		dateRange?: {
			from: Date;
			to: Date;
		};
		simpleStatuses?: SmsMessageSimpleStatus[];
		types?: SmsMessageType[];
	}

	type SmsMessageStatus =
		| "PENDING"
		| "PENDING_CONNECTION_TIMEOUT"
		| "ENROUTE"
		| "ENROUTE_COMPLETE"
		| "EXPIRED"
		| "SCHEDULED"
		| "DELETED"
		| "DELIVERED"
		| "UNDELIVERABLE"
		| "REJECTED"
		| "REJECTED_CONNECTION_TIMEOUT";

	type SmsMessageSimpleStatus = "UNDELIVERABLE" | "DELIVERED";

	type SmsMessageType = "order" | "info" | "tech";
}

export default Message;
