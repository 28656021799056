import React from "react";
import Root from "./components/Root";
import Label from "./components/Label";
import DeleteButton from "./components/DeleteButton";

const Item: React.FC<Item.Props> = ({ label, onDelete }) => (
	<Root align="center" justify="space-between" sizes="1fr auto!" gaps="5px">
		<Label>{label}</Label>
		<DeleteButton onClick={onDelete} />
	</Root>
);

declare namespace Item {
	interface Props {
		label: string;

		onDelete: () => void;
	}
}

export default Item;
