function mapByKey<
	Element extends Record<string, any>,
	Key extends keyof Element,
>(array: Element[], key: Key) {
	return array.reduce((accumulator, element) => {
		accumulator[element[key]] = element;

		return accumulator;
	}, {} as Record<Element[Key], Element>);
}

export default mapByKey;
