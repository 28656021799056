/* eslint-disable import/no-unresolved */

import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, react, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Map from "../../../../../../../../../../redux/services/Map";
import {
	Names,
	NamesBase,
} from "../../../../../../../../../../components/common";
// import NameForm from "../NameForm";
import PointForm from "../PointForm";
import Stroke from "../Stroke";
import TaxiServiceForm from "../TaxiServiceForm";

import InternalController from "./Controller";

const ObjectForm = react.withController<
	ObjectForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<ObjectForm.PropsBaseWithController, ObjectForm.Value>(
		({ value, language, controller, onChange }) => {
			const { t } = useTranslation();
			const taxiServiceFormRef =
				useRef<TaxiServiceForm.Controller | null>(null);
			const nameFormRef = useRef<NamesBase.Controller | null>(null);
			// const nameFormRef = useRef<NameForm.Controller | null>(null);
			const pointFormRef = useRef<PointForm.Controller | null>(null);

			const defaultValue = useMemo<ObjectForm.Value>(() => ({}), []);

			const [internalValue] = useInternal(value ?? defaultValue);

			controller.setContext({
				taxiServiceFormRef,
				nameFormRef,
				pointFormRef,
			});

			const taxiServiceFormOnChange = useCallback(
				(taxiServiceId: TaxiServiceForm.Value) =>
					onChange({ ...internalValue, taxiServiceId }),
				[internalValue, onChange],
			);

			const nameFormOnChange = useCallback(
				(name: NamesBase.Value) => {
					onChange({
						...internalValue,

						name,
					});
				},
				[internalValue, onChange],
			);

			const pointFormOnChange = useCallback(
				(point: PointForm.Value) =>
					onChange({ ...internalValue, ...point }),
				[internalValue, onChange],
			);

			const autoResolveCheckBoxOnChange = useCallback(
				(customInput: boolean) =>
					onChange({
						...internalValue,
						autoResolve: !customInput,
					}),
				[internalValue, onChange],
			);

			const name = useMemo(
				() => internalValue?.name || {},
				[internalValue?.name],
			);

			return (
				<Column gaps="16px* 12px">
					<TaxiServiceForm
						ref={taxiServiceFormRef}
						value={internalValue.taxiServiceId}
						language={language}
						onChange={taxiServiceFormOnChange}
					/>
					<Stroke />

					<Names
						ref={nameFormRef}
						require
						value={name}
						onChange={nameFormOnChange}
						placeholder="pages.preferencesPages.screenDirectory.objects.editModal.objectForm.str0"
					/>
					{/*
					<NameForm
						ref={nameFormRef}
						placeholder={
							t(
								"pages.preferencesPages.screenDirectory.objects.editModal.objectForm.str0",
							) ?? ""
						}
						value={internalValue.name}
						onChange={nameFormOnChange}
					/> */}
					<Stroke />
					<PointForm
						ref={pointFormRef}
						autoResolve={internalValue.autoResolve ?? false}
						value={internalValue}
						language={language}
						onChange={pointFormOnChange}
					/>
					<CheckBoxWithContent
						value={!internalValue.autoResolve}
						gap="18px"
						onChange={autoResolveCheckBoxOnChange}
					>
						{t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectForm.str150",
						) ?? ""}
					</CheckBoxWithContent>
				</Column>
			);
		},
	),
	InternalController,
);

declare namespace ObjectForm {
	interface Value extends PointForm.Value {
		taxiServiceId?: number;
		name?: NamesBase.Value;
		autoResolve?: boolean;
	}

	interface PropsBase {
		language: Map.Language;
	}

	interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default ObjectForm;
