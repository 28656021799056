import React, { Dispatch, SetStateAction, memo, useState } from "react";
import { Button, Icon, Row } from "uikit";
import { useDebouncedCallback } from "use-debounce";

import { ModelId } from "../../../../../../../../types/ModelId";
import AutoTariff from "../../../../../../../../services/AutoTariff";
import { useTypedSelector } from "../../../../../../../../redux/store";

const Filters: React.FC<Filters.Props> = memo(
	({ autoTariffs, selectedId, selectedIndex }) => {
		const { inputRequestDelayMs } = useTypedSelector(
			(state) => state.settings.map,
		);

		const [counter, setCounter] = useState(0);

		const moveUp = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex == null) return;
			const prevPos = autoTariffs[selectedIndex - counter].position;
			await AutoTariff.rearrange(selectedId, prevPos + 1000);
			setCounter(0);
		}, inputRequestDelayMs);

		const moveDown = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex == null) return;
			const nextPos = autoTariffs[selectedIndex + counter].position;
			await AutoTariff.rearrange(selectedId, nextPos - 1000);
			setCounter(0);
		}, inputRequestDelayMs);

		const moveToTop = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex == null) return;
			const firstPos = autoTariffs[0].position;
			await AutoTariff.rearrange(selectedId, firstPos + 1000);
		}, inputRequestDelayMs);

		const moveToBottom = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex == null) return;
			const lastPos = autoTariffs.at(-1)?.position;
			if (!lastPos) return;
			await AutoTariff.rearrange(selectedId, lastPos - 1000);
		}, inputRequestDelayMs);

		return (
			<Row gaps="5px*">
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-up" size={23} />}
					onClick={() => {
						if (selectedIndex && counter < selectedIndex) {
							setCounter((prev) => prev + 1);
						}
						moveUp();
					}}
					disabled={!selectedId || selectedIndex === 0}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-down" size={23} />}
					onClick={() => {
						if (
							counter <
							(selectedIndex != null
								? autoTariffs.length - 1 - selectedIndex
								: NaN)
						) {
							setCounter((prev) => prev + 1);
						}
						moveDown();
					}}
					disabled={
						!selectedId || selectedIndex === autoTariffs.length - 1
					}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-top" size={23} />}
					onClick={moveToTop}
					disabled={!selectedId || selectedIndex === 0}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-bottom" size={23} />}
					onClick={moveToBottom}
					disabled={
						!selectedId || selectedIndex === autoTariffs.length - 1
					}
				/>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Props {
		autoTariffs: AutoTariff.Model[];
		selectedId: ModelId | false;
		setSelected: Dispatch<SetStateAction<ModelId[]>>;
		selectedIndex: number | null;
	}
}

export default Filters;
