import React, {
	memo,
	useCallback,
	useState,
	useMemo,
	useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import Reward, {
	RewardCalculationMethod,
} from "../../../../../../../../services/Reward";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import formatNumber from "../../../../../../../../utils/formatNumber";
import {
	StyledRow,
	Select,
	Text,
	Divider,
	StyledGrid,
	Popup,
	StyledP,
} from "../../../../../../../../components/common";
import { Tab } from "../../types/Tab";

const AgentRewardBase: React.FC<AgentRewardBase.Props> = ({
	disabled,
	form,
	tab,
}) => {
	const { t } = useTranslation();
	const translationPath = "orderPage.order_form.agentReward";

	const defaultValue = useMemo<AgentRewardBase.Value>(
		() => ({
			reward: {
				amount: 0,
				maxAmountFromOrderInPercent: 0,
				percent: 0,
				name: "",
				id: 0,
				rewardCalculationMethod: RewardCalculationMethod.ADD_TO_ORDER,
			},
			rewards: [],
			isRewardAllowed: false,
			isArbitraryRewardAllowed: false,
		}),
		[],
	);

	const [intervalValue, setIntervalValue] =
		useState<AgentRewardBase.Value>(defaultValue);
	const valueEditor = useObjectEditor(intervalValue, setIntervalValue);

	const isRewardAllowed = valueEditor.useGetter("isRewardAllowed");
	const isArbitraryRewardAllowed = valueEditor.useGetter(
		"isArbitraryRewardAllowed",
	);
	const rewards = valueEditor.useGetter("rewards");

	const reward = valueEditor.useGetter("reward");
	const setReward = valueEditor.useSetter("reward");

	const rewardEditor = useObjectEditor(reward, setReward);

	const rewardId = rewardEditor.useGetter("id");
	const percent = rewardEditor.useGetter("percent");
	const amount = rewardEditor.useGetter("amount");
	const name = rewardEditor.useGetter("name");
	const maxAmountFromOrderInPercent = rewardEditor.useGetter(
		"maxAmountFromOrderInPercent",
	);
	const rewardCalculationMethod = rewardEditor.useGetter(
		"rewardCalculationMethod",
	);

	const [show, setShow] = useState<boolean>(false);

	const currencyName = useMemo(
		() => tab.form?.currency?.settings?.name || "",
		[tab.form?.currency],
	);

	const price = useMemo(() => tab.form?.price || 0, [tab.form?.price]);
	const agent = useMemo(() => tab.form?.agent, [tab.form?.agent]);
	const agentRewardAddToPrice = useMemo(
		() => tab.form?.agentRewardAddToPrice,
		[tab.form?.agentRewardAddToPrice],
	);
	const discount = useMemo(() => tab.form.discount, [tab.form.discount]);

	const showPercent = useMemo(() => {
		if (!percent) return "0";
		return `${formatNumber(Number(percent))}%`;
	}, [percent]);

	const showAmount = useMemo(() => {
		if (!amount) return `${formatNumber(0)}  ${currencyName}`;
		return `${formatNumber(Number(amount))} ${currencyName}`;
	}, [amount, currencyName]);

	const showMaxAmount = useMemo(() => {
		if (!Number(maxAmountFromOrderInPercent)) return 0;
		return `${formatNumber(Number(maxAmountFromOrderInPercent))}%`;
	}, [maxAmountFromOrderInPercent]);

	const showPercentAmount = useMemo(() => {
		if (!rewardCalculationMethod) return "";
		if (agentRewardAddToPrice) {
			const value = agentRewardAddToPrice - Number(amount);
			return `(${formatNumber(Number(value))}  ${currencyName})`;
		}

		const value = (price / 100) * Number(percent);
		return `(${formatNumber(Number(value))}  ${currencyName})`;
	}, [
		rewardCalculationMethod,
		agentRewardAddToPrice,
		price,
		percent,
		currencyName,
		amount,
	]);

	const showMaxPercentAmount = useMemo(() => {
		if (!rewardCalculationMethod) return "";
		const value = (price / 100) * Number(maxAmountFromOrderInPercent);
		return `(${formatNumber(Number(value))}  ${currencyName})`;
	}, [
		rewardCalculationMethod,
		price,
		currencyName,
		maxAmountFromOrderInPercent,
	]);

	const getPercentAmount = useMemo(() => {
		if (!rewardCalculationMethod) return "";

		const percentAmount = agentRewardAddToPrice
			? agentRewardAddToPrice - Number(amount)
			: (price / 100) * Number(percent);
		const totalRewardAmount = percentAmount + Number(amount);

		if (
			rewardCalculationMethod ===
				RewardCalculationMethod.CUT_FROM_ORDER &&
			Number(maxAmountFromOrderInPercent) > 0
		) {
			const maxPercentAmount =
				(price / 100) * Number(maxAmountFromOrderInPercent);
			const value = Math.min(totalRewardAmount, maxPercentAmount);
			return `${formatNumber(Number(value))}  ${currencyName}`;
		}

		return `${formatNumber(Number(totalRewardAmount))}  ${currencyName}`;
	}, [
		rewardCalculationMethod,
		agentRewardAddToPrice,
		amount,
		price,
		percent,
		maxAmountFromOrderInPercent,
		currencyName,
	]);

	useLayoutEffect(() => {
		if (agent) {
			const payload: AgentRewardBase.Value = {
				...defaultValue,
				reward: {
					...(agent.reward || {}),
					id: agent.reward?.id || 0,
					amount: agent.reward?.amount || 0,
					maxAmountFromOrderInPercent:
						agent.reward?.maxAmountFromOrderInPercent || 0,
					percent: agent.reward?.percent || 0,
					name: agent.reward?.name || "",
					rewardCalculationMethod:
						agent.reward?.rewardCalculationMethod ||
						RewardCalculationMethod.ADD_TO_ORDER,
				},
				rewards: Array.isArray(agent?.rewards)
					? agent.rewards?.filter((item) => item?.active === true)
					: [],
				isRewardAllowed: agent.isRewardAllowed || false,
				isArbitraryRewardAllowed:
					agent.isArbitraryRewardAllowed || false,
			};

			setIntervalValue(payload);
		} else setIntervalValue(defaultValue);
	}, [defaultValue, agent]);

	useLayoutEffect(() => {
		if (agent && isRewardAllowed) setShow(true);
		else setShow(false);
	}, [agent, isRewardAllowed]);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const rewardToOption = useCallback(
		(value) => ({
			key: value.id,
			label: value.name,
			value,
		}),
		[],
	);

	const rewardOptions = useMemo(() => {
		if (!rewards.length) return [];
		return rewards.map(rewardToOption);
	}, [rewardToOption, rewards]);

	const selected = useMemo(
		() =>
			rewardOptions
				.map((item, i) => {
					if (reward.id === item?.key) return i + 1;
					return undefined;
				})
				.find((item) => item !== undefined) || undefined,
		[reward, rewardOptions],
	);

	const onSelect = useCallback(
		(option) => {
			if (option.key) {
				const exist = rewards.find((item) => item.id === option.key);
				if (exist) {
					setReward(exist);
					form.setValue("agent.reward", exist);
				}
			}

			handleSave();
		},
		[form, handleSave, rewards, setReward],
	);

	const textTranslation = useMemo(
		() => ({
			toAgent: t(`${translationPath}.toAgent`),
			isRewardAllowed: t([`${translationPath}.isRewardAllowed`]) || "",
			isArbitraryRewardAllowed:
				t([`${translationPath}.isArbitraryRewardAllowed`]) || "",
			percent: t([`${translationPath}.percent`]) || "",
			amount: "+ ",
			maxAmount: "<=",
			placeholder: t([`${translationPath}.placeholder`]) || "",
		}),
		[t],
	);

	const maxAmountText = useMemo(() => {
		if (Number(percent) <= 0 && Number(amount) <= 0) {
			return `${textTranslation.toAgent}:
					${showPercent}${showPercentAmount}
					 ${textTranslation.maxAmount}`;
		}

		return textTranslation.maxAmount;
	}, [
		amount,
		percent,
		showPercent,
		showPercentAmount,
		textTranslation.maxAmount,
		textTranslation.toAgent,
	]);

	if (!show) return <></>;
	return (
		<Divider
			side={discount ? "top" : "none"}
			flex={{ direction: "row" }}
			alignItems="center"
			justify="start"
			p="0 12px"
			w="100%"
		>
			<StyledGrid
				areas=""
				justify="start"
				alignItems="center"
				gap="2px 5px"
				columns="repeat(7,auto)"
			>
				<CheckBox
					disabled
					label={textTranslation.isRewardAllowed}
					value={isRewardAllowed}
					onChange={() => {}}
				/>
				<CheckBox
					disabled
					label={textTranslation.isArbitraryRewardAllowed}
					value={isArbitraryRewardAllowed}
					onChange={() => {}}
				/>

				<Popup
					useHoverControl
					offset={{ x: 10, y: -26 }}
					tracker={
						<Select
							w={{ width: "max-content", max: "100px" }}
							icon={false}
							value={rewardId}
							selected={selected}
							disabled={disabled || !isArbitraryRewardAllowed}
							options={rewardOptions}
							placeholder={textTranslation.placeholder}
							maxHeightSelect="calc(20.5px * 5)"
							minHeightRowSelect="20px"
							onSelect={(option) => {
								onSelect(option);
							}}
						/>
					}
				>
					{rewardId && (
						<StyledP
							br="4px"
							bgC="#000000"
							colors="#ffffff"
							p="5px 10px"
						>
							{name}
						</StyledP>
					)}
				</Popup>
				{Number(percent) > 0 && (
					<StyledRow alignItems="center">
						<Text
							textStyle={{
								font: {
									size: "13px",
									fw: "400",
									line: "1",
								},
							}}
							valueStyle={{
								font: {
									size: "13px",
									fw: "400",
									line: "1",
								},
								textAlign: "center",
							}}
							text={`${textTranslation.toAgent}:`}
							value={`${showPercent}${showPercentAmount}`}
						/>
					</StyledRow>
				)}
				{Number(amount) > 0 && (
					<StyledRow alignItems="center">
						<Text
							textStyle={{
								font: {
									size: "13px",
									fw: "400",
									line: "1",
								},
							}}
							valueStyle={{
								font: {
									size: "13px",
									fw: "400",
									line: "1",
								},
								textAlign: "center",
							}}
							text={
								Number(percent) > 0
									? textTranslation.amount
									: `${textTranslation.toAgent}:`
							}
							value={showAmount}
						/>
					</StyledRow>
				)}
				{rewardCalculationMethod ===
					RewardCalculationMethod.CUT_FROM_ORDER &&
					Number(maxAmountFromOrderInPercent) > 0 && (
						<StyledRow alignItems="center">
							<Text
								textStyle={{
									font: {
										size: "13px",
										fw: "400",
										line: "1",
									},
								}}
								valueStyle={{
									font: {
										size: "13px",
										fw: "400",
										line: "1",
									},
									textAlign: "center",
								}}
								text={maxAmountText}
								value={`${showMaxAmount}${showMaxPercentAmount}`}
							/>
						</StyledRow>
					)}

				<StyledRow alignItems="center">
					<Text
						textStyle={{
							font: {
								size: "13px",
								fw: "400",
								line: "1",
							},
						}}
						valueStyle={{
							font: {
								size: "13px",
								fw: "400",
								line: "1",
							},
							textAlign: "center",
						}}
						text={"="}
						value={`${getPercentAmount}`}
					/>
				</StyledRow>
			</StyledGrid>
		</Divider>
	);
};

declare namespace AgentRewardBase {
	interface Value {
		reward: Pick<
			Reward.Model,
			| "amount"
			| "maxAmountFromOrderInPercent"
			| "percent"
			| "name"
			| "id"
			| "rewardCalculationMethod"
		>;
		rewards: Reward.Model[];
		isRewardAllowed: boolean;
		isArbitraryRewardAllowed: boolean;
	}

	interface Props {
		form: UseCreateFormReturn<Schema>;
		tab: Tab;
		disabled: boolean;
	}
}

export const AgentReward = memo(AgentRewardBase);
export default AgentRewardBase;
