/* eslint-disable no-shadow */

import React, { Dispatch, forwardRef, useCallback, useMemo } from "react";
import Modal from "../../../../../../../../components/ModalController/Modal";
import Root from "./components/Root";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Language from "../../../../../../../../services/Language";

const EditModal = forwardRef<EditModal.Controller, EditModal.Props>(
	({ value, language, onChange, onCancel, onSave }, ref) => {
		const contentValue = useMemo<Content.Value>(
			() => ({
				id: value.id,
				name: value.name,
				suburban: value.suburban,
				taxiServiceId: value.taxiServiceId,
			}),
			[value.id, value.name, value.suburban, value.taxiServiceId],
		);

		const contentOnChange = useCallback(
			(contentValue: Content.Value) => {
				onChange({
					...value,
					name: contentValue.name,
					suburban: contentValue.suburban,
					taxiServiceId: contentValue.taxiServiceId,
				});
			},
			[onChange, value],
		);

		const footerValue = useMemo<Footer.Value>(
			() => ({
				active: value.active,
			}),
			[value.active],
		);

		const footerOnChange = useCallback(
			(footerValue: Footer.Value) => {
				onChange({
					...value,
					active: footerValue.active,
				});
			},
			[onChange, value],
		);

		const content = useMemo(
			() => (
				<Root>
					<Content
						ref={ref}
						language={language}
						value={contentValue}
						onChange={contentOnChange}
					/>
					<Footer
						value={footerValue}
						onChange={footerOnChange}
						onCancel={onCancel}
						onSave={onSave}
					/>
				</Root>
			),
			[
				contentOnChange,
				contentValue,
				footerOnChange,
				footerValue,
				language,
				onCancel,
				onSave,
				ref,
			],
		);

		return <Modal dimmer>{content}</Modal>;
	},
);

declare namespace EditModal {
	interface Value extends Content.Value, Footer.Value {}

	interface Props {
		value: Value;

		language: Language;

		onChange: Dispatch<Value>;

		onCancel: () => void;
		onSave: () => void;
	}

	type Controller = Content.Controller;
}

export default EditModal;
