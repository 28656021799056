import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import { CommunicationMethods } from "../components/Settings/tabProps";

interface UseSnapshotArgs<Snapshot> {
	state: Snapshot;
	onHasChanges: (hasChanges: boolean) => void;
	onSave?: (snapshot: Snapshot) => void;
	onCancel?: () => void;
	onResetDefaults?: () => Snapshot;
	methods: (methods: CommunicationMethods) => void;
}

export default function useSnapshot<State>({
	state,
	onHasChanges,
	onSave,
	onCancel,
	onResetDefaults,
	methods,
}: UseSnapshotArgs<State>) {
	const [snapshot, setSnapshot] = useState(state);
	const [hasChanges, setHasChanges] = useState(false);

	useEffect(() => {
		setHasChanges(!_.isEqual(state, snapshot));
	}, [state, snapshot]);

	const save = useCallback(() => {
		onSave?.(snapshot);
	}, [snapshot, onSave]);

	const cancel = useCallback(() => {
		onCancel?.();
		setSnapshot(state);
	}, [state, onCancel]);

	const resetDefaults = useCallback(() => {
		if (onResetDefaults) {
			setSnapshot(onResetDefaults());
		}
	}, [onResetDefaults]);

	useEffect(() => {
		methods({
			save,
			cancel,
			resetDefaults,
		});
	}, [methods, save, cancel, resetDefaults]);

	useEffect(() => {
		onHasChanges(hasChanges);
	}, [hasChanges, onHasChanges]);

	const wrappedSetSnapshot = useCallback(
		(setState: (newState: State) => State) => {
			setSnapshot((prev) => {
				const value = setState(prev);
				return value;
			});
		},
		[],
	);

	return [snapshot, wrappedSetSnapshot] as const;
}
