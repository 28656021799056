import { RefObject } from "react";
import { react } from "uikit";
import IgnoreOrders from "./components/IgnoreOrders/IgnoreOrders";

interface Context {
	ignoreOrdersRef: RefObject<IgnoreOrders.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isIgnoreOrdersValid =
			!!this.context?.ignoreOrdersRef.current?.validate();

		return isIgnoreOrdersValid;
	}
}
