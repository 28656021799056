import React, { useMemo, memo } from "react";
import { Map, theme } from "uikit";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-unresolved
import { PolygonList } from "uikit/dist/components/Map/components/PolygonEditor/type";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe";
import SectorModel from "../../../../../services/Sector";

const BaseSectors: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const sectors = useModelSubscribe({}, SectorModel);

	const localizedSectors = useMemo<PolygonList | undefined>(
		() =>
			sectors?.cache.map((sector) => ({
				id: sector.id,
				name:
					`${t(
						`mainPage.archives.orders.modal.map.polygons.sector`,
					)} ${sector.name?.[lang]}` ||
					Object.values(sector.name)?.[0],
				color: theme.colors.warning,
				vertices: sector.vertices.map((v) => [v.lat, v.lng]),
				editable: false,
			})),
		[lang, sectors?.cache, t],
	);

	return <Map.PolygonEditor value={localizedSectors} editing={false} />;
};

export const Sectors = memo(BaseSectors);

export default BaseSectors;
