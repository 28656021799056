import React, { memo, Suspense, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

import { History } from "../../../../../../redux/services/Order/getHistory";
import Mark from "../../Mark";

const TimeDifference: React.FC<TimeDifference.Props> = ({ change }) => {
	const { t } = useTranslation();

	const prevTime = useMemo(
		() =>
			`${moment.utc(change.previous || 0)?.format("HH:mm") ?? ""} ${
				t(`units.minute`) ?? "min"
			}`,
		[change.previous, t],
	);

	const newTime = useMemo(
		() =>
			`${moment.utc(change.actual || 0)?.format("HH:mm") ?? ""} ${
				t(`units.minute`) ?? "min"
			}`,
		[change.actual, t],
	);

	return (
		<Suspense>
			<Trans
				i18nKey={`change_history.orders.fields.${change.field}`}
				context={change.previous ? "change" : "set"}
				values={{
					prev: prevTime,
					new: newTime,
				}}
				components={{ mark: <Mark /> }}
			/>
		</Suspense>
	);
};

declare namespace TimeDifference {
	interface Props {
		change:
			| History.Field.WaitingTimeMilliseconds
			| History.Field.IdleTimeMilliseconds
			| History.Field.HourlyMilliseconds;
	}
}

export const TimeDifferenceMemo = memo(TimeDifference);
export default TimeDifference;
