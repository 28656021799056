import React from "react";

import CustomerNoteCellContent from "../components/CustomerNoteCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function customerNote({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CustomerNote,
		(item) => <CustomerNoteCellContent item={item} />,
		{
			width,
			sortable: false,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default customerNote;
