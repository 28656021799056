import React, { memo, Suspense, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";
import { StyledColumn, StyledRow } from "../../../../../../components/common";

import Mark from "../../Mark";
import Marker from "../../Marker";

import { useGetDifferenceInArray, TypeChange } from "../hooks";
import CarParams from "./CarParams";

const CarsDifference: React.FC<CarsDifference.Props> = ({ change }) => {
	const { t, i18n } = useTranslation();
	const cars = t("cars");

	const previous = useMemo(
		() =>
			change.previous?.length
				? change.previous.map((item) => {
						try {
							return JSON.stringify(item);
						} catch {
							return "";
						}
				  })
				: [],
		[change.previous],
	);
	const actual = useMemo(
		() =>
			change.actual?.length
				? change.actual.map((item) => {
						try {
							return JSON.stringify(item);
						} catch {
							return "";
						}
				  })
				: [],
		[change.actual],
	);

	const sort = useGetDifferenceInArray({
		previous,
		actual,
		type: change.type,
	});

	const items = useMemo<CarsDifference.Cars>(() => {
		const data = sort.map((item) => {
			try {
				return {
					context: item.context,
					prev: item.prev ? JSON.parse(item?.prev) : item.prev,
					new: item.new ? JSON.parse(item?.new) : item.new,
				};
			} catch {
				return { context: item.context, prev: null, new: null };
			}
		});
		return data;
	}, [sort]);

	return (
		<Suspense>
			{!!items.length && (
				<StyledColumn>
					<p key={cars}>{cars}:</p>
					{items.map((item, i) => {
						if (!item.prev && !item.new) return <></>;
						const car = item.prev || item.new;
						if (!car) return <></>;
						const color = car?.color[i18n.language];
						const carClass = car?.carClass[i18n.language];
						const carParam = {
							...car,
							color,
							carClass,
						};

						return (
							<>
								<StyledRow key={i} p="0 0 0 5px">
									<Marker />
									<Trans
										i18nKey={`change_history.orders.fields.${change.field}`}
										context={item.context}
										values={{
											prev: i + 1,
											new: i + 1,
										}}
										components={{ mark: <Mark /> }}
									/>
								</StyledRow>
								<CarParams
									key={`${
										item.prev?.id || item.new?.id
									}-${i}-${item.context}`}
									car={carParam}
								/>
							</>
						);
					})}
				</StyledColumn>
			)}
		</Suspense>
	);
};

declare namespace CarsDifference {
	interface Props {
		change: History.Field.Cars;
	}
	type CarParams = History.Field.Car;
	interface Car {
		context: TypeChange;
		prev: History.Field.Car | null;
		new: History.Field.Car | null;
	}
	type Cars = Car[];
}

export const CarsDifferenceMemo = memo(CarsDifference);
export default CarsDifference;
