import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon, theme } from "uikit";

import { StyledRow } from "../../../../../../common";

const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
`;

const CloseButton = styled.button`
	cursor: pointer;
`;

const Root = styled(StyledRow)`
	padding: 16px;
	height: 52px;

	font-family: Lato;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0px;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${theme.colors.primary};
`;

const Header: React.FC<Header.Props> = memo(({ onClick }) => {
	const handleClick = useCallback(() => {
		onClick();
	}, [onClick]);

	const { t } = useTranslation();
	return (
		<Root>
			<Title>
				{t("orderPageWidgets.chat.scheduleModal.header.str100") ?? ""}
			</Title>
			<CloseButton onClick={handleClick}>
				<Icon id="close" size={13} colors={[theme.colors.secondary]} />
			</CloseButton>
		</Root>
	);
});

declare namespace Header {
	interface Props {
		onClick: () => void;
	}
}

export default Header;
