import React, { ReactNode, memo } from "react";
import { Whisper, WhisperProps } from "rsuite";

import CustomTooltip from "../CustomTooltip";

const CustomWhisper: React.FC<CustomWhisper.Props> = memo(
	({ placement, children, speaker, arrowLeft, ...props }) => {
		const innerSpeaker = ({ className, left, top }, ref) => (
			<CustomTooltip
				className={className}
				left={left}
				top={top}
				arrowLeft={arrowLeft}
				ref={ref}
			>
				{children}
			</CustomTooltip>
		);

		const Speaker = speaker ?? innerSpeaker;

		return (
			<Whisper
				placement={placement ?? "bottom"}
				{...props}
				speaker={Speaker as JSX.Element}
			>
				<div>{children}</div>
			</Whisper>
		);
	},
);

declare namespace CustomWhisper {
	interface Props extends Omit<WhisperProps, "children" | "speaker"> {
		children: ReactNode;
		speaker?: WhisperProps["speaker"];
		arrowLeft?: string;
	}
}

export default CustomWhisper;
