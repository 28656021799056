import React, { memo } from "react";
import styled from "styled-components";

import { Style, StyledRow } from "../../../../../components/common";

const StyleLoading = styled.span`
	width: 60px;
	height: 40px;
	position: relative;
	display: inline-block;
	color: var(--rs-primary-500);

	&::before {
		content: "";
		left: 0;
		top: 0;
		position: absolute;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: var(--rs-primary-500);
		background-image: radial-gradient(
				circle 8px at 18px 18px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 18px 0px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 0px 18px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 36px 18px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 18px 36px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 30px 5px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 30px 5px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 30px 30px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 5px 30px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 4px at 5px 5px,
				var(--rs-gray-50) 100%,
				transparent 0
			);
		background-repeat: no-repeat;
		box-sizing: border-box;
		animation: rotationBack 3s linear infinite;
	}
	&::after {
		content: "";
		left: 35px;
		top: 15px;
		position: absolute;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: var(--rs-primary-500);
		background-image: radial-gradient(
				circle 5px at 12px 12px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 12px 0px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 0px 12px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 24px 12px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 12px 24px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 20px 3px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 20px 3px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 20px 20px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 3px 20px,
				var(--rs-gray-50) 100%,
				transparent 0
			),
			radial-gradient(
				circle 2.5px at 3px 3px,
				var(--rs-gray-50) 100%,
				transparent 0
			);
		background-repeat: no-repeat;
		box-sizing: border-box;
		animation: rotationBack 4s linear infinite reverse;
	}

	@keyframes rotationBack {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-360deg);
		}
	}
`;

// https://cssloaders.github.io/
/** @deprecated */
const SuspenseLoader: React.FC<SuspenseLoader.Props> = ({
	...props
}): JSX.Element => (
	<StyledRow
		// w="100%"
		// h="100%"
		position="absolute"
		justify="center"
		alignItems="center"
		{...props}
	>
		<StyleLoading />
	</StyledRow>
);

declare namespace SuspenseLoader {
	interface Props extends Style.FlexType {}
}

export const SuspenseLoaderMemo = memo(SuspenseLoader);
export default SuspenseLoader;
