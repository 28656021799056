import React, { memo, useCallback } from "react";
import { ToggleButton } from "uikit";
import Rules from "../../../..";

const ActiveColumn: React.FC<ActiveColumn.Props> = memo(
	({ rules, rule, saveHandler }) => {
		const handleActiveChange = useCallback(
			(active) => {
				const index = rules.indexOf(rule);
				saveHandler({ ...rule, active }, index);
			},
			[rule, rules, saveHandler],
		);

		return (
			<div
				onClick={(event) => event.stopPropagation()}
				style={{
					maxWidth: "max-content",
				}}
			>
				<ToggleButton.ToggleButton
					value={rule.active}
					onChange={handleActiveChange}
				/>
			</div>
		);
	},
);

declare namespace ActiveColumn {
	interface Props {
		rules: Rules.Rule[];
		rule: Rules.Rule;
		saveHandler: (rule: Rules.Rule, index?: number | null) => void;
	}
}

export default ActiveColumn;
