import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutingStageCellContent: React.FC<ExecutingStageCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();

	const content = useMemo(
		() =>
			item.executingStage
				? t(`order_executing_stages.${item.executingStage}`)
				: "",
		[item.executingStage, t],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutingStageCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutingStageCellContent;
