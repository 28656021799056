import React from "react";
import { Flex } from "uikit";
import { SuspenseLoader } from "../../../../../../components/common";

const CustomerPenalties = () => (
	<Flex align="center" justify="center" maxedWidth maxedHeight>
		<SuspenseLoader />
	</Flex>
);

export default CustomerPenalties;
