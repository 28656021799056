import { useMemo } from "react";

import { History } from "../../../../../../redux/services/Order/getHistory";

export type TypeChange = "set" | "removed" | "change";

export interface Item {
	context: TypeChange;
	prev: string;
	new: string;
}

export type Items = Item[];

export interface Params {
	previous: string[];
	actual: string[];
	type: History.FieldBase["type"];
}

export type UseGetDifferenceInArray = (params: Params) => Items;
export const useGetDifferenceInArray: UseGetDifferenceInArray = ({
	previous,
	actual,
	type,
}) => {
	const items = useMemo(() => {
		const length = Math.max(previous?.length, actual?.length);
		const arr = new Array(length).fill(1);
		const payload: Items = [];

		arr.forEach((_, i) => {
			const existAdd = actual.includes(previous?.[i]);
			const existRemove = previous.includes(actual?.[i]);
			if (existRemove && existAdd) return;

			let context: TypeChange;
			if (type === "array_item_update") context = "change";
			else if (type === "array_item_remove") context = "removed";
			else context = "set";

			if (context === "change") {
				if (existRemove) context = "removed";
				if (existAdd) context = "set";
			}

			const item: Item = {
				context,
				prev: previous?.[i],
				new: actual?.[i],
			};

			payload.push(item);
		});

		return payload;
	}, [previous, actual, type]);

	return items;
};

export default useGetDifferenceInArray;
