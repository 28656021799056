import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	& > * {
		margin-bottom: 10px;
	}
`;

export default Root;
