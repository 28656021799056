/* eslint-disable no-nested-ternary */
import React, {
	Suspense,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	theme,
	InputWithTips,
	InputBorders,
	TwoLineList,
	Row,
	Icon,
	useInternal,
} from "uikit";
import { Table } from "rsuite";
import { useDebouncedCallback } from "use-debounce/lib";
import styled, { css } from "styled-components";
import { cloneDeep } from "lodash";

import OrderMap from "../../services/Map";
import { useTypedSelector } from "../../redux/store";
import search from "../../redux/services/Map/search";
import getReveal from "../../redux/services/Map/getReveal";
import {
	createOrderPoint,
	createOrderPointFromQuery,
	stringifyPoint,
	STRINGIFY_POINT_TYPES,
} from "../../redux/constants/OrdersPage/order";
import getPopularAddress from "../../redux/services/Customer/getPopularAddresses";
import getSectorByPoint from "../../redux/services/Sector/getSectorByPoint";
import useKeyBind from "../../hooks/useKeyBind";
import getValidCityName from "../../utils/getValidCityName";
import SearchFilter from "../SearchFilter/SearchFilter";
import Map from "../Map/Map";
import MapExecutorLocations from "../MapExecutorLocations";
import { CardModal } from "../Orders";
import {
	Button,
	StyledGrid,
	StyledGridItem,
	StyledRow,
	SuspenseLoader,
} from "../common";

import styles from "./index.module.css";
// import LightTable from "../LightTable";

const { Column, HeaderCell, Cell } = Table;

const styles1 = {
	cell: (type: "header" | "body") => css`
		.rs-table-cell-content,
		.rs-table-cell {
			background: ${theme.colors.transparent} !important;

			font-family: "Lato";
			font-style: normal;
			${type === "header"
				? `
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                    `
				: `
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                    `}
			color: ${theme.colors.primary};
		}

		.rs-table-cell-content {
			padding: 14px 8px;
		}
	`,
};

const Root = styled(Table)`
	.rs-table-row-header {
		box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);

		& .rs-table-cell-header {
			${styles1.cell("header")}
		}
	}

	.rs-table-row:not(.rs-table-row-header) {
		.rs-table-cell-first,
		&:hover {
			z-index: 1;
		}

		&.selected {
			.rs-table-cell {
				background-color: #eaf8fe !important;
			}

			&:hover {
				& .rs-table-cell {
					background-color: #e2f5fe !important;
				}
			}
		}

		&.not-active {
			.rs-table-cell,
			.rs-table-cell-content {
				color: ${theme.colors.disabled_text} !important;
			}
		}

		& .rs-table-cell {
			${styles1.cell("body")}

			border-right: 1px solid var(--rs-border-secondary);

			.rs-table-cell-content {
				padding: 14px 8px;
			}
		}
	}
`;

const StyledInputTips = styled(InputWithTips.InputWithTips)`
	height: 32px;
	width: 100%;
	border-radius: 5px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
`;

const StyledTip = styled.div<{ isActive: boolean }>`
	width: 100%;
	padding: 4px 7px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	&:hover {
		background: #ebebeb;
	}
	${(props) =>
		props.isActive
			? `
		background: #EBEBEB;
	`
			: ""}
`;

const StyledInput = styled(InputBorders.InputBorders)`
	height: 32px;
	width: 100%;
	border-radius: 5px;

	.input-textfield {
		width: 100%;
		height: 100%;
		font-family: ${theme.font.n};
		font-weight: 400;
		line-height: 18px;
		font-size: 14px;
	}
`;

const isValidPointTemplate = (point) => {
	if (!point) return false;
	const plain = createOrderPoint(point);
	return Object.keys(plain).every((key) => key in point);
};

const createPointTemplate = (options, pointOnMap = "") => {
	if (isValidPointTemplate(options.defaultPoint)) {
		const point = options.defaultPoint;
		return {
			coordinates: point.coordinates,
			street: {
				index: 0,
				data: point,
				value: stringifyPoint(
					point,
					STRINGIFY_POINT_TYPES.STREET,
					pointOnMap,
				),
			},
			settlement: {
				index: 0,
				data: point,
				value: getValidCityName(point.settlementType, point.settlement),
			},
			house: {
				index: 0,
				data: point,
				value: point.customHouse || point.house,
			},
			entrance: point.entrance,
			flat: point.flat,
		};
	}

	return {
		coordinates: options.defaultSettlement?.coordinates || null,
		street: {
			index: 0,
			data: null,
			value: "",
		},
		settlement: {
			index: 0,
			data: options.defaultSettlement,
			value: options.defaultSettlement?.value || "",
		},
		house: {
			index: 0,
			data: null,
			value: "",
		},
		entrance: "",
		flat: "",
	};
};

const LIST_VERTICAL_KEYS = ["ArrowUp", "ArrowDown"];

const formatStreet = (orderPointObject, pointOnMap = "") => {
	if (orderPointObject.streetType && orderPointObject.street) {
		let buffer = `${orderPointObject.streetType} ${orderPointObject.street}`;
		if (orderPointObject.district) {
			buffer += `(${orderPointObject.district})`;
		}
		return buffer;
	}
	return pointOnMap;
};

const searchHouse = (house, options: any[] = []) => {
	const searchedItems = options.filter((e) => e.number === house);
	const selectedHouse = searchedItems.length
		? searchedItems[0]
		: options.filter((e) => e.number.includes(house))[0] || options[0];
	return selectedHouse;
};

function PointModal(props) {
	const {
		popularAddresses = [],
		onClose,
		onSubmit,
		isFirstPoint = true,
		editPoint,
		defaultCity,
		taxiServiceId,
		customerId,
		executorLocationsDataModels = [],
	} = props;
	const { t } = useTranslation();

	const schemaPointsModalTable = useMemo(
		() => [
			{
				key: "address",
				accessor: "address",
				isChecked: true,
				label: t("pointModal.address") ?? "",
				width: 440,
				resizable: true,
			},
			{
				key: "sector",
				accessor: "sector",
				isChecked: true,
				label: t("pointModal.sector") ?? "",
				width: 120,
				resizable: true,
			},
			{
				key: "rideNumber",
				accessor: "rideNumber",
				isChecked: true,
				label: t("pointModal.rideNumber") ?? "",
				resizable: true,
			},
		],
		[t],
	);

	const {
		mapLanguage,
		inputRequestDelayMs,
		searchRadiusMeters,
		minSearchQueryLength,
		defaultSearchType,
	} = useTypedSelector((state) => state.settings.map);

	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const validEditablePoint = useMemo(
		() => isValidPointTemplate(editPoint),
		[editPoint],
	);

	const idNameElements = {
		settlement: "point_modal_settlement_id",
		street: "point_modal_street_id",
		house: "point_modal_house_id",
		entrance: "point_modal_entrance_id",
		flat: "point_modal_flat_id",
		firstFavorite: "point_modal_first_address_id",
	};

	const [activeRow, setActivePopularAddress] = useInternal<number>(-1);
	const [nextStep, setNextStep] = useInternal<string>(
		"point_modal_street_id",
	);
	const [title, setTitle] = useState(
		validEditablePoint
			? [
					getValidCityName(
						editPoint.settlementType,
						editPoint.settlement,
					),
					stringifyPoint(editPoint, "address", pointOnMap),
			  ]
					.filter((item) => item)
					.join(", ")
			: "",
	);
	const [searchTypes, setSearchTypes] = useState<OrderMap.Search.Type[]>([
		"street",
		"localObject",
	]);
	const [isFavorite, setIsFavorite] = useState<boolean>(false);
	const [favorite, setFavorite] = useState<any[]>(popularAddresses || []);
	const [enterKeyActivated, setEnterKeyActivated] = useState<boolean>(false);
	const [mapCenter, setMapCenter] = useState<any>(defaultCity.coordinates);
	const [allowMapCentralization, setAllowMapCentralization] =
		useState<boolean>(true);
	const [settlementTips, setSettlementTips] = useState<any[]>([]);
	const [streetTips, setStreetTips] = useState<any[]>([]);
	const [houseTips, setHouseTips] = useState<any[]>([]);
	const [settlementTipsVisible, setSettlementTipsVisible] =
		useState<boolean>(true);
	const [streetTipsVisible, setStreetTipsVisible] = useState<boolean>(true);
	const [houseTipsVisible, setHouseTipsVisible] = useState<boolean>(true);
	const [point, setPoint] = useState(
		createPointTemplate({
			defaultPoint: editPoint,
			defaultSettlement: defaultCity,
			pointOnMap,
		}),
	);

	const handleSetCenter = useCallback((center) => {
		setMapCenter(center);
	}, []);

	const textFind: string = useMemo(
		() => `${t("pointModal.str208") ?? ""}...`,
		[t],
	);
	const textEntrance: string = useMemo(
		() => `${t("pointModal.str209") ?? ""}...`,
		[t],
	);

	useLayoutEffect(() => {
		if (Array.isArray(defaultSearchType)) {
			setSearchTypes(defaultSearchType);
		} else if (typeof defaultSearchType === "string") {
			setSearchTypes([defaultSearchType]);
		}
	}, [defaultSearchType]);

	const stopKeyEvents = useCallback((event) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const inputHandler = useCallback(
		(key, event) => {
			stopKeyEvents(event);
			setPoint({
				...point,
				[key]: event.target.value,
			});
		},
		[point, stopKeyEvents],
	);

	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) {
				elem.focus();
				if (elem?.nodeName === "INPUT") {
					const input = elem as HTMLInputElement;
					input?.select();
				}
			}
			return elem;
		},
		[],
	);

	const handleSubmit = useCallback(
		(immediatePoint?: any) => {
			const local = immediatePoint || point;

			if (local.coordinates) {
				const template = createOrderPoint({
					coordinates: local.coordinates,
					flat: local.flat.trim?.() || "",
					entrance: local.entrance.trim?.() || "",
					customStreet: "",
					customHouse: local.house.value?.trim?.() || "",
				} as any);

				let submitPoint;

				if (local.house.data) {
					submitPoint = createOrderPoint({
						...template,
						type: local.house.data.type,
						name: local.house.data.name || local.house.data.title,
						country: local.house.data.country,
						countryCode: local.house.data.countryCode,
						settlement: local.settlement.data.settlement,
						settlementType: local.settlement.data.settlementType,
						street: local.house.data.street,
						streetType: local.house.data.streetType,
						district:
							local.street.data?.district ||
							local.house.data?.district,
						house:
							local.house.data.number?.trim() ||
							local.house.data.house?.trim(),
					});
				} else if (local.street.data) {
					submitPoint = createOrderPoint({
						...template,
						type: local.street.data.type,
						name: local.street.data.name || local.street.data.title,
						country: local.street.data.country,
						countryCode: local.street.data.countryCode,
						settlement:
							local.street.data.settlement ||
							local.settlement.data.settlement,
						settlementType:
							local.street.data.settlementType ||
							local.settlement.data.settlementType,
						street: local.street.data.street,
						streetType: local.street.data.streetType,
						district: local.street.data.district,
						house:
							local.street.data.number?.trim() ||
							local.street.data.house?.trim(),
					});
				} else {
					submitPoint = createOrderPoint({
						...template,
						type: "settlement",
						name: local.settlement.data.settlement,
						country: "",
						countryCode: "",
						settlement: local.settlement.data.settlement,
						settlementType:
							local.settlement.data.settlementType || "",
						street: "",
						streetType: "",
						district: "",
						house: "",
					});
				}

				getSectorByPoint(local.coordinates).then((response) => {
					onSubmit({
						...submitPoint,
						sector: response
							? { id: response.id, name: response.name }
							: null,
					});
				});
			}

			onClose();
			setIsFavorite(false);
		},
		[onClose, onSubmit, point],
	);

	const debounceRequestSettlement = useDebouncedCallback((value) => {
		const canRequest = value.trim();

		if (canRequest) {
			search(value, {
				country: "ua",
				searchType: "settlement",
				lang: mapLanguage,
			}).then((tips) => setSettlementTips(tips));
		} else {
			setSettlementTips([]);
		}
	}, inputRequestDelayMs);

	const debounceRequestStreet = useDebouncedCallback((value) => {
		const canRequest = value && value.trim();
		const nearCoord =
			point.settlement.data?.coordinates || point.coordinates;
		if (canRequest) {
			search(value, {
				country: "ua",
				searchType:
					(searchTypes.length > 1 ? searchTypes : searchTypes[0]) ||
					"street",
				near: {
					point: nearCoord,
					radius: searchRadiusMeters,
				},
				lang: mapLanguage,
				taxiServiceId,
			} as any).then((tips) => {
				if (tips.length) {
					setStreetTips(tips);
					setHouseTips(tips[0].streetHouses || []);
				}
			});
		} else {
			setStreetTips([]);
		}
	}, inputRequestDelayMs);

	const requestHouseCoordinates = useCallback(
		async (house: any) => {
			const canRequest = point && house?.trim();

			if (canRequest) {
				return search(
					`${
						point.settlement.value
							? `${point.settlement.value},`
							: ""
					} ${point.street?.value}, ${point.house.value || house}`,
					{
						country: "ua",
						searchType: "address",
						near: {
							point:
								point.street.data?.coordinates ||
								point.settlement.data?.coordinates ||
								point?.coordinates,

							radius: searchRadiusMeters,
						},

						lang: mapLanguage,
					} as any,
				).then((result) => {
					const selectedHouse = searchHouse(
						point.house.value || house,
						result,
					);
					const updatedPoint = {
						...point,
						coordinates: selectedHouse?.coordinates,
						house: {
							data: {
								...selectedHouse,
								number:
									point.house.value ||
									house ||
									selectedHouse.number,
							},
							index: houseTips.findIndex(
								(e: any) =>
									e.number ===
									(point.house.value ||
										house ||
										selectedHouse.number),
							),
							value:
								point.house.value ||
								house ||
								selectedHouse.number,
						},
					};

					setPoint(updatedPoint);
					setMapCenter(selectedHouse?.coordinates);
					setTitle(
						[point?.settlement?.value, point.street?.value, house]
							.filter((a) => a?.trim())
							.join(", "),
					);
					setEnterKeyActivated(true);

					return updatedPoint;
				});
			}
			return null;
		},
		[houseTips, mapLanguage, point, searchRadiusMeters],
	);

	const handleSettlementClose = useCallback((visible) => {
		if (visible) {
			setStreetTipsVisible(false);
			setHouseTipsVisible(false);
		}
	}, []);

	const onPointChange = useCallback(
		(response, coordinates) => {
			if (response.objects?.length) {
				const newPoint = cloneDeep(point);
				const result = createOrderPointFromQuery(
					{
						...response.objects[0],
						coordinates,
					},
					mapLanguage,
				);

				newPoint.coordinates = coordinates;
				newPoint.settlement.data = result;
				newPoint.settlement.value = result.settlement;
				newPoint.street.data = result;
				newPoint.street.value = formatStreet(result, pointOnMap);

				if (result.type === "address") {
					newPoint.house.data = result;
					newPoint.house.value = result.house;
				} else {
					newPoint.house.value = "";
				}

				setMapCenter(coordinates);
				setStreetTips(
					response.objects.map((item) => ({
						...item.properties.language[mapLanguage],
						coordinates: item.properties?.geoCentroid,
					})),
				);
				setHouseTips([]);
				setSettlementTips([]);
				setPoint(newPoint);
			} else {
				setPoint({
					...point,
					coordinates,
					settlement: {
						value: "",
						data: null,
						index: 0,
					},
					street: {
						index: 0,
						data: null,
						value: "",
					},
					house: {
						index: 0,
						data: null,
						value: "",
					},
				});
			}
			setEnterKeyActivated(true);
		},
		[mapLanguage, point, pointOnMap],
	);

	const selectSettlement = useCallback(
		(option: any) => {
			setNextStep(idNameElements.street);
			if (!option?.coordinates) return false;
			setPoint({
				...point,
				coordinates: option.coordinates,
				settlement: {
					data: option,
					value: stringifyPoint(
						option,
						STRINGIFY_POINT_TYPES.SETTLEMENT,
						pointOnMap,
					),
					index: settlementTips.findIndex(
						(e: any) => e.settlement === option.settlement,
					),
				},
				street: { value: "", index: 0 } as any,
				house: { value: "", index: 0 } as any,
			});
			setStreetTipsVisible(true);
			setHouseTipsVisible(true);
			setSettlementTipsVisible(true);
			setAllowMapCentralization(true);
			setMapCenter(option.coordinates);
			setStreetTips([]);
			setHouseTips([]);
			setSettlementTips([]);
			return true;
		},
		[idNameElements.street, point, pointOnMap, setNextStep, settlementTips],
	);

	const selectStreet = useCallback(
		(option: any) => {
			const value = stringifyPoint(
				option,
				STRINGIFY_POINT_TYPES.STREET,
				pointOnMap,
			);

			if (option.streetHouses?.length) {
				setPoint({
					...point,
					coordinates: option.coordinates,
					settlement: {
						data: point.settlement.data,
						value: getValidCityName(
							option?.settlementType,
							option?.settlement,
						),
						index: 0,
					},
					street: {
						data: option,
						value,
						index: streetTips.findIndex(
							(e: any) => e.street === option.street,
						),
					},
					house: {
						data: null,
						value: "",
						index: 0,
					},
				});
				setHouseTips(option.streetHouses);
				setHouseTipsVisible(true);
			} else {
				setPoint({
					...point,
					coordinates: option.coordinates,
					settlement: {
						data: point.settlement.data,
						value: getValidCityName(
							option?.settlementType,
							option?.settlement,
						),
						index: 0,
					},
					street: {
						data: option,
						value,
						index: streetTips.findIndex(
							(e: any) => e.street === option.street,
						),
					},
					house: {
						value: option.number || "",
						number: option.number,
						index: 0,
					} as any,
				});

				setHouseTips(option.streetHouses);
				setHouseTipsVisible(true);
			}

			setEnterKeyActivated(true);
		},
		[point, pointOnMap, streetTips],
	);

	const onSettlementKeyboardEvent = useCallback(
		(event) => {
			setIsFavorite(false);
			if (LIST_VERTICAL_KEYS.includes(event.key)) {
				const oldIndex = point.settlement.index;
				const newActiveOption =
					event.key === "ArrowUp"
						? oldIndex - 1 < 0
							? settlementTips.length - 1
							: oldIndex - 1
						: oldIndex + 1 > settlementTips.length - 1
						? 0
						: oldIndex + 1;
				const newPoint = { ...point };
				const { settlement = "" } = settlementTips[newActiveOption];
				newPoint.settlement = {
					data: settlementTips[newActiveOption],
					value: point.settlement.value,
					index: newActiveOption,
				};
				setTitle(settlement);
				setPoint(newPoint);
				stopKeyEvents(event);
			} else if (event.key === "Enter") {
				if (settlementTips.length) {
					selectSettlement(settlementTips[point.settlement.index]);
				}
				stopKeyEvents(event);
			} else if (event.key === "Tab") {
				selectSettlement(settlementTips[point.settlement.index]);
			}

			return true;
		},
		[point, selectSettlement, settlementTips, stopKeyEvents],
	);

	const onStreetKeyboardEvent = useCallback(
		(event) => {
			setNextStep(idNameElements.house);
			setIsFavorite(false);

			if (LIST_VERTICAL_KEYS.includes(event.key) && streetTips.length) {
				const prevActiveOption = point.street?.index || 0;
				const newActiveOptionIndex =
					event.key === "ArrowUp"
						? prevActiveOption - 1 < 0
							? streetTips.length - 1
							: prevActiveOption - 1
						: prevActiveOption + 1 > streetTips.length - 1
						? 0
						: prevActiveOption + 1;
				const newPoint: any = point;
				const activeOption: any = streetTips[newActiveOptionIndex];
				const {
					street = "",
					streetType = "",
					name = "",
					address = "",
					streetHouses = [],
				} = activeOption;
				const value = address || name || `${streetType}  ${street} `;
				const houseValue: any = streetHouses.length
					? streetHouses[0]?.number
					: "";
				newPoint.street = {
					data: activeOption,
					value: point.street.value,
					index: newActiveOptionIndex,
				};
				newPoint.house = {
					value: houseValue,
					number: houseValue,
					index: 0,
				};
				setTitle(
					`${point?.settlement?.value}, ${value}${
						streetHouses[0]?.number
							? `, ${streetHouses[0]?.number}`
							: ""
					}`,
				);
				setPoint(newPoint);
				setHouseTips(streetHouses);
				stopKeyEvents(event);
			} else if (event.key === "Enter") {
				if (streetTips.length) {
					selectStreet(point.street.data);

					const street: any = {
						...point.street,
						data: streetTips[point.street.index],
					};

					if (street.data?.coordinates) {
						selectStreet(street.data);
					}
				}

				if (!point.street.data?.streetHouses?.length)
					setEnterKeyActivated(true);

				if (
					enterKeyActivated &&
					!point.street.data?.streetHouses?.length
				) {
					handleSubmit();
				}
				stopKeyEvents(event);
			} else if (event.key === "Tab") {
				if (!point.street.value) {
					const elem = nextElementById(idNameElements.firstFavorite);
					if (elem) {
						event?.target?.blur();
						setNextStep(idNameElements.firstFavorite);
						setActivePopularAddress(1);
						setIsFavorite(true);
						stopKeyEvents(event);
					}
				}

				if (streetTips.length) {
					const street: any = {
						...point.street,
						data: streetTips[point.street.index],
					};
					const value =
						street.data?.address ||
						street.data?.name ||
						`${street.data?.streetType} ${street.data.street}`;

					if (street.data?.coordinates) {
						selectStreet(street.data);
					}

					setTitle(
						`${point.settlement.value}, ${value}${
							street.data?.streetHouses[0]?.number
								? `, ${street.data?.streetHouses[0]?.number}`
								: ""
						}`,
					);

					if (!street?.streetHouses?.length) {
						setEnterKeyActivated(true);
					}
				}
			}
		},
		[
			setNextStep,
			idNameElements.house,
			idNameElements.firstFavorite,
			streetTips,
			point,
			stopKeyEvents,
			enterKeyActivated,
			selectStreet,
			handleSubmit,
			nextElementById,
			setActivePopularAddress,
		],
	);

	const onHouseKeyboardEvent = useCallback(
		(event: any) => {
			setIsFavorite(false);
			setNextStep(idNameElements.entrance);
			if (LIST_VERTICAL_KEYS.includes(event.key) && houseTips.length) {
				const prevActiveOption = point.house?.index || 0;
				const newActiveOption =
					event.key === "ArrowUp"
						? prevActiveOption - 1 < 0
							? houseTips.length - 1
							: prevActiveOption - 1
						: prevActiveOption + 1 > houseTips.length - 1
						? 0
						: prevActiveOption + 1;
				const newPoint = { ...point };
				const { number = "" } = houseTips[newActiveOption];
				newPoint.house = {
					data: houseTips[newActiveOption],
					value: point?.house?.value,
					index: newActiveOption,
				};

				setTitle(
					`${point?.settlement?.value}, ${point?.street?.value}, ${number}`,
				);
				setPoint(newPoint);
				stopKeyEvents(event);
			} else if (event.key === "Enter") {
				if (houseTips.length) {
					const value =
						houseTips[point?.house?.index]?.number ||
						point?.house?.value;

					if (enterKeyActivated) {
						requestHouseCoordinates(value).then(handleSubmit);
					} else {
						requestHouseCoordinates(value);
					}
				}
				if (!houseTips.length && enterKeyActivated) {
					handleSubmit();
				}
				stopKeyEvents(event);
			} else if (event.key === "Tab") {
				if (houseTips.length) {
					if (point?.house?.value.length) {
						requestHouseCoordinates(point?.house?.value);
					} else {
						const house = {
							...point.house,
							data: houseTips[point?.house?.index || 0],
							value: houseTips[point?.house?.index || 0].number,
							index: point?.house?.index || 0,
						};

						requestHouseCoordinates(house.value);
					}
				}
			}
		},

		[
			setNextStep,
			idNameElements.entrance,
			houseTips,
			point,
			stopKeyEvents,
			enterKeyActivated,
			requestHouseCoordinates,
			handleSubmit,
		],
	);

	useEffect(() => {
		if (editPoint) {
			const template = createPointTemplate({
				defaultPoint: editPoint,
				defaultSettlement: defaultCity,
				pointOnMap,
			});
			setPoint(template);
			const street = template.street.value || "";
			if (street?.length >= minSearchQueryLength)
				debounceRequestStreet(street);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editPoint, debounceRequestStreet, minSearchQueryLength]);

	const onClickGetReveal = useCallback(() => {
		getReveal(mapCenter).then((result) => {
			onPointChange(result, mapCenter);
		});
	}, [mapCenter, onPointChange]);

	// -----------

	const handleRowClickPopularAddresses = useCallback(
		(payload) => {
			if (!isFavorite) setIsFavorite(true);
			const option = payload;
			const valueStreet = stringifyPoint(
				option,
				STRINGIFY_POINT_TYPES.STREET,
				pointOnMap,
			);

			const updatedPoint = {
				...point,
				coordinates: option?.coordinates,
				settlement: {
					data: option,
					value: getValidCityName(
						option?.settlementType,
						option?.settlement,
					),
					index: -1,
				},
				street: {
					data: option,
					value: valueStreet,
					index: -1,
				},
				house: {
					data: option,
					index: -1,
					value: option.house || option.number,
				},
			};
			setPoint(updatedPoint);
			setMapCenter(option?.coordinates);
			setTitle(
				[
					point?.settlement?.value,
					point.street?.value,
					option?.house || option?.number,
				]
					.filter((a) => a?.trim())
					.join(", "),
			);
			setEnterKeyActivated(true);
			setActivePopularAddress(option.id);
		},
		[isFavorite, point, pointOnMap, setActivePopularAddress],
	);

	const getFavorite = useCallback(
		async (id: number) => {
			if (!id) return;

			const res = await getPopularAddress(id, defaultCity?.value);

			setFavorite(res || []);
		},
		[defaultCity?.value],
	);

	useMemo(() => {
		if (!customerId) return;
		getFavorite(customerId);
	}, [customerId, getFavorite]);

	const preparePopularAddresses = useMemo<any[]>(() => {
		if (!favorite.length) return [];
		const payload = [...favorite]
			.map((item) => ({
				...item.address,
				rideNumber: item?.rideNumber,
			}))
			.sort((prev, next) => {
				const prevItem = prev?.rideNumber || 0;
				const nextItem = next?.rideNumber || 0;
				if (!nextItem && !prevItem) return 0;
				if (prevItem > nextItem) return -1;
				return nextItem < prevItem ? 1 : 0;
			});
		return payload;
	}, [favorite]);

	const tableData = useMemo(
		() =>
			[...preparePopularAddresses].map((item, index) => {
				const {
					street,
					streetType,
					number,
					house,
					settlement,
					settlementType,
				} = item;
				return {
					...item,
					id: index + 1,
					address: street
						? `${getValidCityName(
								settlementType,
								settlement,
						  )}, ${streetType} ${street} ${number || house || ""}`
						: settlementType + settlement,
				};
			}),
		[preparePopularAddresses],
	);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (activeRow === rowData?.id) return "selected";
			return "not-active";
		},
		[activeRow],
	);

	useLayoutEffect(() => {
		if (isFavorite && activeRow > 0) {
			const rowData = tableData.find((point) => point.id === activeRow);
			if (!rowData) return;
			handleRowClickPopularAddresses(rowData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRow, isFavorite, tableData]);

	useKeyBind(["ArrowUp"], () => {
		setActivePopularAddress((prev) => {
			const items = tableData?.length || 0;
			const length = items + 1;
			return prev - 1 < 1 ? length - 1 : prev - 1;
		});
	});

	useKeyBind(["ArrowDown"], () => {
		setActivePopularAddress((prev) => {
			const items = tableData?.length || 0;
			const length = items + 1;
			return prev + 1 > length - 1 ? 1 : prev + 1;
		});
	});

	useKeyBind(["Enter"], () => {
		if (houseTips.length) {
			const value =
				houseTips[point?.house?.index]?.number || point?.house?.value;
			requestHouseCoordinates(value).then(handleSubmit);
		} else handleSubmit();
	});

	useKeyBind(["Escape"], () => {
		onClose();
		setIsFavorite(false);
	});

	useKeyBind(["Tab"], (event) => {
		const entrance = nextElementById(nextStep);
		if (entrance && nextStep === idNameElements.firstFavorite) {
			nextElementById(idNameElements.entrance);
			setNextStep(idNameElements.entrance);
		}
		if (!entrance) {
			if (nextStep === idNameElements.street) {
				nextElementById(idNameElements.house);
			} else if (nextStep === idNameElements.flat) {
				nextElementById(idNameElements.settlement);
			} else if (nextStep === idNameElements.settlement) {
				nextElementById(idNameElements.street);
			} else if (nextStep === idNameElements.entrance) {
				nextElementById(idNameElements.flat);
			} else if (nextStep === idNameElements.house) {
				nextElementById(idNameElements.entrance);
			} else if (nextStep === idNameElements.firstFavorite) {
				nextElementById(idNameElements.entrance);
			}
		}
		stopKeyEvents(event);
	});

	const queryHouse = useMemo(
		() => point?.house?.value || "",
		[point?.house?.value],
	);

	const queryStreet = useMemo(
		() => point?.street?.value || "",
		[point?.street?.value],
	);

	const querySettlement = useMemo(
		() => point?.settlement?.value || "",
		[point?.settlement?.value],
	);

	return (
		<CardModal
			title={
				editPoint
					? `${t([`orderPage.point_modal.edit_title`])}: ${title} `
					: `${t([`orderPage.point_modal.create_title`])}: ${title} `
			}
			onClose={onClose}
			onSave={() => {
				if (houseTips.length) {
					const value =
						houseTips[point?.house?.index]?.number ||
						point?.house?.value;

					requestHouseCoordinates(value).then(handleSubmit);
				} else {
					handleSubmit();
				}
			}}
		>
			<Suspense fallback={<SuspenseLoader />}>
				<Row sizes="60%! auto" gaps="8px" className={styles.content}>
					<div className={styles.fieldsContainer}>
						<div className={styles.row}>
							<StyledInputTips
								id={idNameElements.settlement}
								isOpen={settlementTipsVisible}
								setTipsVisible={handleSettlementClose}
								placeholder={textFind}
								query={querySettlement}
								onKeyDown={onSettlementKeyboardEvent}
								setQuery={(value) => {
									setPoint({
										...point,
										settlement: {
											...point.settlement,
											value,
										},
									});
									if (value.length >= minSearchQueryLength)
										debounceRequestSettlement(value);
								}}
								activeOption={point.settlement.index}
								options={settlementTips}
								onSelect={(selectedOptions) =>
									selectSettlement(
										selectedOptions[
											selectedOptions.length - 1
										],
									)
								}
								displaySelected={(selected) => (
									<div className={styles.selectedItem}>
										{selected.map((option: any, i) => (
											<div key={`${i + 1}`}>
												{stringifyPoint(
													option,
													STRINGIFY_POINT_TYPES.SETTLEMENT,
													pointOnMap,
												)}
											</div>
										))}
									</div>
								)}
								optionRender={(option: any, toggleSelect) => {
									let header = option.value.settlement;

									if (option.value.region) {
										header += header
											? ` (${option.value.region})`
											: option.value.region;
									}

									return (
										<StyledTip
											isActive={option.selected}
											onClick={toggleSelect}
										>
											<TwoLineList.TwoLineList
												items={[
													{
														header,
														footer: option.value
															?.settlementType,
													},
												]}
											/>
										</StyledTip>
									);
								}}
							/>

							<div>
								<SearchFilter
									setSearchType={(types) => {
										setSearchTypes(types);
										if (
											point.street.value.length >=
											minSearchQueryLength
										) {
											debounceRequestStreet(
												point?.street?.value,
											);
										}
									}}
									activeSearchTypes={searchTypes as any}
								/>
							</div>
						</div>
						<StyledGrid
							areas="'address house'"
							columns="1fr 13%"
							w="100%"
							gap="8px"
							css={`
								div[data-open="true"] {
									height: 200px;
								}
							`}
						>
							<div>
								<StyledInputTips
									id={idNameElements.street}
									isOpen={
										!settlementTips.length &&
										streetTipsVisible
									}
									setTipsVisible={(visible) =>
										visible && setStreetTipsVisible(visible)
									}
									query={queryStreet}
									autoFocus
									onKeyDown={onStreetKeyboardEvent}
									placeholder={textFind}
									activeOption={point.street.index}
									setQuery={(value) => {
										setPoint({
											...point,
											street: {
												...point.street,
												value,
											},
										});

										if (
											value.length >= minSearchQueryLength
										) {
											debounceRequestStreet(value);
										}
									}}
									options={streetTips}
									onSelect={(selectedOptions) =>
										selectStreet(
											selectedOptions[
												selectedOptions.length - 1
											],
										)
									}
									displaySelected={(selected) => (
										<div className={styles.selectedItem}>
											{selected.map((option: any, i) => {
												const {
													address,
													name,
													streetType,
													street,
												} = option;
												return (
													<div key={`${i + 1}`}>
														{address ||
															name ||
															`${streetType}  ${street} ` ||
															pointOnMap}
													</div>
												);
											})}
										</div>
									)}
									optionRender={(
										option: any,
										toggleSelect,
									) => {
										const {
											streetType,
											street,
											settlementType,
											settlement,
											number,
											house,
											district,
											region,
										} = option?.value || {};

										let value = { header: "", footer: "" };

										// 'title' is for 'localObject' type
										const name =
											option?.value?.name ||
											option?.value?.title;

										// TODO: Need refactoring.
										if (name) {
											value = {
												header:
													name ||
													district ||
													region ||
													pointOnMap,
												footer: street
													? `${streetType} ${street} ${
															number ||
															house ||
															""
													  }`
													: settlementType +
													  settlement
													? getValidCityName(
															settlementType,
															settlement,
													  )
													: district || region || "",
											};
										} else {
											const districtString = district
												? `(${district})`
												: "";

											value = {
												header:
													name || streetType + street
														? `${streetType} ${street} ${districtString}`.trim()
														: district ||
														  region ||
														  pointOnMap,
												footer:
													settlementType + settlement
														? getValidCityName(
																settlementType,
																settlement,
														  )
														: district ||
														  region ||
														  "",
											};
										}

										return (
											<StyledTip
												isActive={option.selected}
												onClick={toggleSelect}
											>
												<TwoLineList.TwoLineList
													items={[value]}
												/>
											</StyledTip>
										);
									}}
								/>
							</div>
							<div>
								<StyledInputTips
									id={idNameElements.house}
									isOpen={
										!settlementTips.length &&
										houseTipsVisible
									}
									setTipsVisible={(visible) =>
										visible && setHouseTipsVisible(visible)
									}
									query={queryHouse}
									onlyShowTips
									onKeyDown={onHouseKeyboardEvent}
									placeholder={textFind}
									activeOption={point?.house?.index}
									setQuery={(value) => {
										let index;

										if (houseTips.length) {
											const searchedItems =
												houseTips.filter(
													(e) =>
														e.number
															.trim()
															.toLowerCase() ===
														value
															.trim()
															.toLowerCase(),
												);
											const selectedHouse =
												searchedItems.length
													? searchedItems[0]
													: houseTips.filter((e) =>
															e.number.includes(
																value
																	.trim()
																	.toLowerCase(),
															),
													  )[0] || point?.house;
											index = houseTips.findIndex(
												(item) =>
													item.number ===
													selectedHouse.number,
											);
										}

										setPoint({
											...point,
											house: {
												...point?.house,
												value,
												index,
											},
										});
									}}
									options={houseTips}
									onSelect={(selectedOptions: any) => {
										requestHouseCoordinates(
											selectedOptions[
												selectedOptions.length - 1
											].number,
										);
									}}
									displaySelected={(selected) => (
										<div className={styles.selectedItem}>
											{selected.map((option: any, i) => (
												<div key={`${i + 1}`}>
													{option.number}
												</div>
											))}
										</div>
									)}
									optionRender={(
										option: any,
										toggleSelect,
									) => (
										<StyledTip
											isActive={option.selected}
											onClick={toggleSelect}
										>
											<TwoLineList.TwoLineList
												items={[
													{
														header: option?.value
															?.number,
														footer: "",
													},
												]}
											/>
										</StyledTip>
									)}
								/>
							</div>
						</StyledGrid>

						<Suspense fallback={<SuspenseLoader />}>
							<StyledRow h="300px">
								<Root
									autoFocus={isFavorite}
									loading={false}
									style={{
										width: "100%",
										border: "1px solid #DEE0E2",
										borderRadius: "5px",
										height:
											isFirstPoint ||
											!editPoint?.entrance ||
											!editPoint?.flat
												? "195px"
												: "235px",
									}}
									fillHeight
									showHeader
									bordered
									cellBordered
									data={tableData}
									headerHeight={34}
									rowHeight={40}
									onRowClick={handleRowClickPopularAddresses}
									rowClassName={tableRowClassName}
								>
									{schemaPointsModalTable.map((column, i) => {
										const { key, label } = column;
										return (
											<Column
												resizable={column.resizable}
												width={column.width}
												flexGrow={column.width ? 0 : 1}
												key={key}
											>
												<HeaderCell>{label}</HeaderCell>
												<Cell
													fullText
													dataKey={key}
													id={
														!i && key === "address"
															? idNameElements.firstFavorite
															: ""
													}
												>
													{(rowData) => {
														if (
															column.key ===
															"sector"
														) {
															return (
																<StyledRow>
																	{rowData?.[
																		column
																			.key
																	]?.name?.[
																		mapLanguage
																	] || ""}
																</StyledRow>
															);
														}
														return (
															<StyledRow>
																{rowData[
																	column.key
																] || ""}
															</StyledRow>
														);
													}}
												</Cell>
											</Column>
										);
									})}
								</Root>
								{/* <LightTable
									virtualized
									headerHeight={34}
									rowHeight={40}
									fillHeight
									style={{ width: "100%" }}
									data={tableData}
									onRowClick={handleRowClickPopularAddresses}
									rowClassName={tableRowClassName}
								>
									{schemaPointsModalTable.map((column, i) => (
										<LightTable.Column
											key={column.key}
											resizable={column.resizable}
											width={column.width}
											flexGrow={column.width ? 0 : 1}
											verticalAlign="middle"
										>
											<LightTable.HeaderCell
												verticalAlign="middle"
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												{column.label}
											</LightTable.HeaderCell>

											<LightTable.Cell
												verticalAlign="middle"
												id={
													!i && column.key === "address"
														? idNameElements.firstFavorite
														: ""
												}
												fullText
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "start",
												}}
												dataKey={column.key}
											>
												{(rowData) => {
													if (
														column.key === "sector"
													) {
														return (
															<StyledRow>
																{rowData?.[
																	column.key
																]?.name?.[
																	mapLanguage
																] || ""}
															</StyledRow>
														);
													}
													return (
														<StyledRow>
															{rowData[
																column.key
															] || ""}
														</StyledRow>
													);
												}}
											</LightTable.Cell>
										</LightTable.Column>
									))}
								</LightTable> */}
							</StyledRow>
						</Suspense>

						{isFirstPoint ||
						!editPoint?.entrance ||
						!editPoint?.flat ? (
							<div className={styles.fullSizeRow}>
								<StyledInput>
									<input
										className="input-textfield"
										type="text"
										placeholder={textEntrance}
										id={idNameElements.entrance}
										tabIndex={-1}
										onChange={(e) =>
											inputHandler("entrance", e)
										}
										onKeyDown={(event) => {
											const keyName = event.key;
											if (keyName === "Tab") {
												nextElementById(
													idNameElements.flat,
												);
												stopKeyEvents(event);
											}
										}}
									/>
								</StyledInput>
								<StyledInput>
									<input
										className="input-textfield"
										type="text"
										placeholder={t("pointModal.str0") ?? ""}
										id={idNameElements.flat}
										tabIndex={-1}
										onChange={(e) =>
											inputHandler("flat", e)
										}
										onKeyDown={(event) => {
											const keyName = event.key;
											if (keyName === "Tab") {
												nextElementById(
													idNameElements.settlement,
												);
												stopKeyEvents(event);
											}
										}}
									/>
								</StyledInput>
							</div>
						) : (
							false
						)}
					</div>
					<StyledGrid
						tabIndex={-3}
						areas="'buttons''map'"
						columns="1fr"
						rows="50px 1fr"
						w="100%"
						h="100%"
					>
						<StyledGridItem
							tabIndex={-3}
							area="buttons"
							justifySelf="start"
							alignSelf="center"
						>
							<Button
								variant="primary"
								title={t("pointModal.str1") ?? ""}
								tabIndex={-3}
								onClick={onClickGetReveal}
								w="clamp(29px, 3.9vh, 32px)"
								h="clamp(29px, 3.9vh, 32px)"
							>
								<Icon id="plus" size={16} />
							</Button>
						</StyledGridItem>
						<StyledGridItem area="map">
							<Suspense fallback={<SuspenseLoader />}>
								<Map
									allowMapCentralization={
										allowMapCentralization
									}
									setAllowMapCentralization={(value) =>
										setAllowMapCentralization(value)
									}
									points={
										point.coordinates ? [point as any] : []
									}
									setPoint={(_, newCoordinates, result) => {
										onPointChange(result, newCoordinates);
									}}
									setCenter={(newCenter) => {
										handleSetCenter(newCenter);
									}}
									centroid={mapCenter}
								>
									<MapExecutorLocations
										executorLocations={
											executorLocationsDataModels
										}
									/>
								</Map>
							</Suspense>
						</StyledGridItem>
					</StyledGrid>
				</Row>
			</Suspense>
		</CardModal>
	);
}

export default PointModal;
