import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	padding: 14px 20px;

	background: ${theme.colors.white};

	box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.2);

	z-index: 1;
`;

export default Root;
