import React, {
	useMemo,
	memo,
	useLayoutEffect,
	useCallback,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../services/Language";
import Sector from "../../../services/Sector";
import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import sectorsRedux from "../../../redux/reducers/sectors";
import { StyledP, StyledRow } from "../styles";

const SectorsByIdsBase: React.FC<SectorsByIdsBase.Props> = ({
	sectorIds,
	lang,
	onSize,
	missingSector,
}) => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const sectors = useTypedSelector<{ models?: Sector.Model[] }>(
		(state) => state.sectors,
	);

	const outsideSectorName = useMemo(
		() => t([`outsideSectors`, "Outside sectors"]) || "",
		[t],
	);
	const allText = useMemo(() => t(["all_sectors", "All sectors"]), [t]);

	const ref = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const height = ref?.current?.offsetHeight;
		if (height !== undefined) onSize?.(height);
	}, [onSize]);

	const items = useMemo(() => sectors.models || [], [sectors]);

	const getSectors = useCallback(async () => {
		const data = await Sector.getAll({});
		dispatch(sectorsRedux.actions.update({ hash: "models", data }));
	}, [dispatch]);

	useLayoutEffect(() => {
		if (!items.length) getSectors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const data = useMemo(() => {
		if (!items.length) return [];

		return items
			?.filter((item) => sectorIds.includes(item?.id))
			.map((item) => item?.name?.[lang]);
	}, [items, sectorIds, lang]);

	if (!data.length) {
		return missingSector ? (
			<StyledRow gap="0 8px">
				<StyledP>{outsideSectorName}</StyledP>
			</StyledRow>
		) : (
			<StyledRow></StyledRow>
		);
	}

	if (data.length === items.length) {
		if (missingSector) {
			return (
				<StyledRow gap="0 8px">
					<StyledP>{`${allText} + ${outsideSectorName}`}</StyledP>
				</StyledRow>
			);
		}

		return (
			<StyledRow gap="0 8px">
				<StyledP>{allText}</StyledP>
			</StyledRow>
		);
	}

	return (
		<StyledRow
			ref={ref}
			gap="0 8px"
			flex={onSize ? { wrap: "wrap" } : undefined}
			w={onSize ? { min: "200px" } : ""}
		>
			{missingSector && <StyledP>{outsideSectorName}</StyledP>}
			{data.length === 1 && <StyledP>{data[0]}</StyledP>}

			{data.length > 1 &&
				data.map((item, i, arr) => (
					<StyledP key={item}>{`${item}${
						arr?.[i + 1] ? "," : ""
					}`}</StyledP>
				))}
		</StyledRow>
	);
};

export const SectorsByIds = memo(SectorsByIdsBase);
declare namespace SectorsByIdsBase {
	interface Props {
		sectorIds: number[];
		lang: Language;
		onSize?: (value: number) => void;
		missingSector?: boolean;
	}
}

export default SectorsByIdsBase;
