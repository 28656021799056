import React, { useEffect, useState, memo, useMemo, useCallback } from "react";
import { Map as MapBaseUiKit } from "uikit";
import {
	LatLngBoundsLiteral,
	LatLngExpression,
	LatLngLiteral,
	Map,
} from "leaflet";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useTypedSelector } from "../../redux/store";
import {
	ConstantColors,
	LocationHistory,
	SegmentHistory,
} from "../../redux/reducers/Archives/GPSLog/interface";
import useGetGPSHistory from "../../hooks/useGetGPSHistory";
import useMapZoom from "../../utils/useMapZoom";
import { DateFns } from "../../utils/DateFns";

import CircleMarkerListType, {
	CircleMarkerList,
} from "./components/CircleMarkerList";
import { PolylineList } from "./components/PolylineList";
import { MarkerList, MarkerListBase } from "./components";
import { MarkerCar } from "./components/MarkerCar";

const MapComponent = styled(MapBaseUiKit)`
	div .leaflet-control-zoom {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		right: 10px;
		bottom: 25px;

		background-color: #ffffff;

		border: none;
		border-radius: 5px;

		padding: 2px;
		margin: 0px;
		gap: 5px;

		.leaflet-control-zoom-in::after,
		#map-zoom-in-control::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: #dee0e2;
			bottom: -3px;
		}

		#map-zoom-out-control,
		#map-zoom-in-control,
		.leaflet-control-zoom-out,
		.leaflet-control-zoom-in {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0px;
			gap: 0px;
			position: relative;
			width: 28px;
			height: 28px;

			cursor: pointer;

			border: none;
			border-radius: 5px;

			span {
				color: #21333f;
				width: 28px;
				height: 28px;
				font-weight: 800;
				font-style: normal;
				font-size: 20px;
				cursor: pointer;
			}

			:hover {
				background-color: #e4e4e4;
			}
		}
	}
`;

const MapGPSBase: React.FC<MapGPSBase.Props> = () => {
	const maxZoom = 18;
	const {
		centroid,
		mapBounds,
		positions,
		data: history,
		circleMarkerItems,
		trackStartAndEnd,
		player,
		setCircleMarkerItems,
		setTrackStartAndEnd,
		setRadius,
	} = useGetGPSHistory();
	const glob = useTypedSelector((state) => state.session.language);
	const [map, setMap] = useState<Map | null>(null);
	const zoom = useMapZoom(map, 9);
	const { t } = useTranslation();
	const speed = t(`speed`);
	const accuracy = t(`accuracy`);
	const altitude = t(`altitude`);
	const mpsUnit = t(`units.mps`);
	const kphUnit = t(`units.kph`);
	const meter = t(`units.meter`);

	useEffect(() => {
		if (map && mapBounds && mapBounds?.length) {
			map.fitBounds(mapBounds);
		}
	}, [map, mapBounds]);

	const radius = useMemo(() => {
		const k = 16;
		return Math.max((zoom * k) / 18 - 15, 0);
	}, [zoom]);

	const converterLine = useCallback(
		(data: SegmentHistory, start: SegmentHistory): [string, string][] => {
			const dateDns = new DateFns();
			const mps = `${data.speed?.toFixed(2) || 0} ${mpsUnit}`;
			const kph = `${(data.speed * 3.6)?.toFixed(2) || 0} ${kphUnit}`;
			const durationMS = dateDns.formatDistance(
				data.date,
				start.date,
				glob,
			);
			return [
				[t(`date`), new Date(data.date).toLocaleString(glob)],
				[t("mapGPS.str200") ?? "", dateDns.format3(start.date)],
				[t("mapGPS.str201") ?? "", dateDns.format3(data.date)],
				[
					t("mapGPS.str202") ?? "",
					data.date === start.date ? "" : durationMS,
				],
				[t("mapGPS.str203") ?? "", data?.carNumber || ""],
				[t("mapGPS.str205") ?? "", data?.car?.registrationNumber || ""],
				[t("mapGPS.str206") ?? "", data?.executorCallSign],
				[t("mapGPS.str207") ?? "", data.order?.executingStage || ""],
				[t("mapGPS.str208") ?? "", data?.executor?.status || ""],
				[speed, `${kph} (${mps})`],
				[altitude, `${data.altitude?.toFixed(2) || 0} ${meter}`],
				[accuracy, `${data.accuracy?.toFixed(2) || 0} ${meter}`],
			];
		},
		[accuracy, altitude, glob, kphUnit, meter, mpsUnit, speed, t],
	);

	/** These points indicate the starts and ends of the paths */
	const trackStartEnd = useMemo<MapGPSBase.TrackStartEnd[]>(() => {
		if (!history?.length) return [];
		const startEnd: MapGPSBase.TrackStartEnd[] = [];

		history.forEach(({ segments }) =>
			segments.forEach((segment): void => {
				const start = segment.at(0);
				const end = segment.at(-1);
				// The end path
				if (start && end) {
					startEnd.push({
						lines: converterLine(end, start),
						point: end.point,
						startTimestamp: false,
						endTimestamp: true,
					});
				}
				if (start) {
					// The start path
					startEnd.push({
						lines: converterLine(start, start),
						point: start.point,
						startTimestamp: true,
						endTimestamp: false,
					});
				}
			}),
		);

		return startEnd;
	}, [converterLine, history]);

	/** These circle markers on the map with descriptions of the speed, accuracy, altitude, mps, kph, meter. */
	const circleMarkerItemsMemo = useMemo<
		MapGPSBase.CircleMarkerItems[][]
	>(() => {
		if (!history?.length) return [];

		const modifiedItems = history
			.map(({ segments }, outerIndex) =>
				segments.map((segment, segmentIndex) =>
					segment.map((point, pointIndex) => {
						// TODO: maybe that's why it's looped
						const start = segment.at(0);
						// Speed is stored in M/S
						const rad = radius === 0 ? 1 : radius * 10;

						return {
							keyPoint: `${outerIndex}-${segmentIndex}-${pointIndex}`,
							center: point.point,
							radius: rad,
							lines: converterLine(point, start || point),
							date: point.date,
							duration: 0,
							deg: 0,
							color: point?.order
								? ConstantColors.RED
								: ConstantColors.BLUE,
						};
					}),
				),
			)
			.flat();

		return modifiedItems;
	}, [converterLine, history, radius]);

	useEffect(() => {
		if (setRadius) setRadius(radius);
	}, [radius, setRadius]);

	useEffect(() => {
		if (setCircleMarkerItems) setCircleMarkerItems(circleMarkerItemsMemo);
	}, [circleMarkerItemsMemo, setCircleMarkerItems]);

	useEffect(() => {
		if (setTrackStartAndEnd) setTrackStartAndEnd(trackStartEnd);
	}, [trackStartEnd, setTrackStartAndEnd]);

	const zoomControlZoomIn = document.getElementsByClassName(
		"leaflet-control-zoom-in",
	);
	const zoomControlZoomOut = document.getElementsByClassName(
		"leaflet-control-zoom-out",
	);

	useEffect(() => {
		if (zoomControlZoomIn) {
			const elem = zoomControlZoomIn.item(0);
			if (elem) {
				elem.setAttribute("id", "map-zoom-in-control");
			}
		}
		if (zoomControlZoomOut) {
			const elem = zoomControlZoomOut.item(0);
			if (elem) {
				elem.setAttribute("id", "map-zoom-out-control");
			}
		}
	}, [zoomControlZoomIn, zoomControlZoomOut]);

	const carElem = useMemo(
		() => (
			<MarkerCar
				duration={player.duration}
				position={player.position}
				next={player.next}
				toggle={player.toggle}
				speed={player.speed}
				zoom={zoom}
				run={player.run}
			/>
		),
		[
			player.duration,
			player.next,
			player.position,
			player.run,
			player.speed,
			player.toggle,
			zoom,
		],
	);

	return (
		<MapComponent
			center={centroid}
			zoom={zoom}
			maxZoom={maxZoom}
			onInit={setMap}
		>
			{player.max && carElem}
			<MarkerList data={trackStartAndEnd} />
			<CircleMarkerList data={circleMarkerItems} />
			<PolylineList points={positions} />
		</MapComponent>
	);
};

export const MapGPS = memo(MapGPSBase);

declare namespace MapGPSBase {
	interface Props {
		history?: LocationHistory[];
		centroid?: LatLngExpression;
		mapBounds?: LatLngBoundsLiteral | null;
		polylineCoordinations?: LatLngLiteral[][];
		playerPosition?: LatLngLiteral;
	}

	type TrackStartEnd = MarkerListBase.TrackStartEnd;

	type CircleMarkerItems = CircleMarkerListType.PointItemsMemo;
}

export default MapGPSBase;
