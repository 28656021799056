import React, { memo, useCallback } from "react";
import { Trans } from "react-i18next";
import { forIn } from "lodash";

import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../components/common";

import Mark from "../../../Mark";
import Marker from "../../../Marker";

import PassengerPointDiff from "../index";
import { PassengerFeatureMemo } from "./PassengerFeature";

const PassengerChangePoint: React.FC<PassengerChangePoint.Props> = ({
	passengerName,
	changedItems,
}): JSX.Element => {
	const getChanged = useCallback(
		(
			from: Partial<PassengerPointDiff.Feature>,
			to: Partial<PassengerPointDiff.Feature>,
		): Partial<PassengerPointDiff.Feature> | null => {
			if (!from || !to) return null;
			const payload: Partial<PassengerPointDiff.Feature> = {};
			forIn(from, (v, k) => {
				if (typeof to[k] === "object") {
					const jsonTo = JSON.stringify(to[k]);
					const jsonWith = JSON.stringify(from[k]);
					if (jsonTo !== jsonWith) payload[k] = v;
				} else if (to[k] !== v) payload[k] = v;
			});
			const changed = Object.keys(payload).length;
			if (!changed) return null;
			return payload;
		},
		[],
	);

	if (!changedItems.length || !passengerName) return <></>;

	return (
		<StyledColumn>
			<StyledRow>
				<Trans
					i18nKey={`change_history.orders.fields.passenger.points`}
					context="change"
					values={{ name: passengerName }}
					components={{ mark: <Mark /> }}
				/>
			</StyledRow>
			<StyledColumn p={"0 0 0px 10px"}>
				{changedItems.map((points, i) => {
					const withChange = points.withChange?.point;
					const toChange = points.toChange?.point;
					const addressWith = points.withChange?.feature;
					const addressTo = points.toChange?.feature;

					const isCoordinations = [
						!!withChange?.lat,
						!!withChange?.lng,
						!!toChange?.lat,
						!!toChange?.lng,
					].every((item) => item === true);
					if (!isCoordinations) return <></>;

					const from = getChanged(addressWith, addressTo);
					const to = getChanged(addressTo, addressWith);

					if (!to && !from) return <></>;
					return (
						<>
							<StyledRow key={`${i}-with`}>
								<Marker />
								<Trans
									i18nKey={`change_history.orders.fields.passenger.point`}
									context="with"
									values={{
										lat: `${withChange?.lat}`,
										lng: `${withChange?.lng}`,
									}}
									components={{ mark: <Mark /> }}
								/>
							</StyledRow>
							<PassengerFeatureMemo
								key={`${passengerName}-${i}-with-remove`}
								feature={from}
							/>
							<StyledRow key={`${i}-on`}>
								<Marker />
								<Trans
									i18nKey={`change_history.orders.fields.passenger.point`}
									context="on"
									values={{
										lat: `${toChange?.lat}`,
										lng: `${toChange?.lng}`,
									}}
									components={{ mark: <Mark /> }}
								/>
							</StyledRow>
							<PassengerFeatureMemo
								key={`${passengerName}-${i}-to-add`}
								feature={to}
							/>
							<StyledRow m="5px" />
						</>
					);
				})}
			</StyledColumn>
		</StyledColumn>
	);
};
declare namespace PassengerChangePoint {
	interface Props {
		passengerName: string;
		changedItems: ChangedItems;
	}
	interface ChangedItem {
		toChange: PointChanged[0];
		withChange: PointChanged[0];
	}

	type ChangedItems = ChangedItem[];
	type PointChanged = PassengerPointDiff.PointChange;
}

export const PassengerChangePointMemo = memo(PassengerChangePoint);
export default PassengerChangePoint;
