import styled from "styled-components";
import { Column } from "uikit";

const SipTable = styled(Column)`
	max-height: 92px;

	overflow-y: scroll;
	background-color: transparent;
	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}
`;

export default SipTable;
