import React, { memo, useState, useCallback, useMemo } from "react";
import { Button, Icon, Row } from "uikit";

import { ModelId } from "../../../../../../../../../../../../../../../../types/ModelId";

import Modal from "../Modal";

const Filters: React.FC<Filters.Props> = memo(
	({ rules, selectedId, selectedIndex, setRules, setSelectedIndex }) => {
		const [counter, setCounter] = useState(0);

		const moveUp = useCallback(() => {
			if (typeof selectedId === "boolean" && !selectedId) return;
			if (selectedIndex === null) return;
			const itemIndex = selectedIndex - 1;
			const upRule = rules.at(selectedIndex);
			const downRule = rules.at(itemIndex);

			if (upRule && downRule) {
				const newRules = [...rules];

				newRules[itemIndex] = {
					...upRule,
					position: downRule.position,
				};

				newRules[selectedIndex] = {
					...downRule,
					position: upRule.position,
				};

				setRules(newRules);
				setSelectedIndex(itemIndex);
			}
		}, [rules, selectedId, selectedIndex, setRules, setSelectedIndex]);

		const moveDown = useCallback(() => {
			if (typeof selectedId === "boolean" && !selectedId) return;
			if (selectedIndex === null) return;
			const itemIndex = selectedIndex + 1;
			const upRule = rules.at(itemIndex);
			const downRule = rules.at(selectedIndex);
			if (upRule && downRule) {
				const newRules = [...rules];

				newRules[selectedIndex] = {
					...upRule,
					position: downRule.position,
				};

				newRules[itemIndex] = {
					...downRule,
					position: upRule.position,
				};

				setRules(newRules);
				setSelectedIndex(itemIndex);
			}
		}, [rules, selectedId, selectedIndex, setRules, setSelectedIndex]);

		const moveToTop = useCallback(() => {
			if (typeof selectedId === "boolean" && !selectedId) return;
			if (selectedIndex === null) return;
			const rule = rules.at(selectedIndex);

			if (rule) {
				const newRules = [...rules].filter(
					(item) => item.name !== rule.name,
				);
				const first = { ...newRules.at(0) };

				newRules.unshift({
					...rule,
					position: first.position || rule.position,
				});

				const editRule = newRules.map((item) => {
					if (item.id === first.id) {
						return {
							...item,
							position: rule.position || item.position,
						};
					}
					return item;
				});

				setRules(editRule);
				setSelectedIndex(0);
			}
		}, [rules, selectedId, selectedIndex, setRules, setSelectedIndex]);

		const moveToBottom = useCallback(() => {
			if (typeof selectedId === "boolean" && !selectedId) return;
			if (selectedIndex === null) return;

			const rule = rules.at(selectedIndex);

			if (rule) {
				const newRules = [...rules].filter(
					(item) => item.name !== rule.name,
				);

				const last = { ...newRules.at(-1) };
				newRules.push({
					...rule,
					position: last.position || rule.position,
				});

				const editRule = newRules.map((item) => {
					if (item.id === last.id) {
						return {
							...item,
							position: rule.position || item.position,
						};
					}
					return item;
				});

				setRules(editRule);
				setSelectedIndex(newRules.length - 1);
			}
		}, [rules, selectedId, selectedIndex, setRules, setSelectedIndex]);

		const disable = useMemo(() => {
			if (typeof selectedId === "boolean" && !selectedId) return true;
			return false;
		}, [selectedId]);

		const disableFirstElement = useMemo(() => {
			if (disable) return true;
			return selectedIndex === 0;
		}, [disable, selectedIndex]);

		const disableLastElement = useMemo(() => {
			if (disable) return true;
			return selectedIndex === rules.length - 1;
		}, [disable, rules.length, selectedIndex]);

		return (
			<Row gaps="5px*">
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-up" size={23} />}
					onClick={() => {
						if (selectedIndex && counter < selectedIndex) {
							setCounter((prev) => prev + 1);
						}
						moveUp();
					}}
					disabled={disableFirstElement}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-down" size={23} />}
					onClick={() => {
						if (
							counter <
							(selectedIndex != null
								? rules.length - 1 - selectedIndex
								: NaN)
						) {
							setCounter((prev) => prev + 1);
						}
						moveDown();
					}}
					disabled={
						disable || (!disableFirstElement && disableLastElement)
					}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-top" size={23} />}
					onClick={moveToTop}
					disabled={disableFirstElement}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-bottom" size={23} />}
					onClick={moveToBottom}
					disabled={
						disable || (!disableFirstElement && disableLastElement)
					}
				/>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Props {
		rules: Modal.Value[];
		selectedId: ModelId | false;
		setRules: (rule: Modal.Value[]) => void;
		setSelectedIndex: (select: number) => void;
		selectedIndex: number | null;
	}
}

export default Filters;
