import React, { useCallback } from "react";
import styled from "styled-components";
import { Button, Column, Icon, theme } from "uikit";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../redux/store";
import softphoneWidget from "../../../../redux/reducers/softphoneWidget";

export const LeftButtonsColumn = styled(Column)<{ focus: boolean }>`
	padding: 2px 0px 2px 2px;

	position: absolute;
	top: 88px;
	left: -44px;

	width: 44px;

	border-radius: 8px 0px 0px 8px;
	background-color: #f5f5f5;

	opacity: ${({ focus }) => (focus ? 1 : 0.8)};
	pointer-events: auto;
`;

export const LeftButton = styled(Button.Button)<{ active?: boolean }>`
	width: 42px;
	height: 40px;

	border-radius: 8px 0px 0px 8px;
	background-color: ${({ active }) =>
		active ? theme.colors.white : theme.colors.transparent};
	box-shadow: ${({ active }) =>
		active ? "-2px 1px 2px 0px #0000001a" : "none"};

	cursor: pointer;
	z-index: ${({ active }) => (active ? "100" : "1")};

	&:hover {
		background-color: ${({ active }) =>
			active ? theme.colors.white : theme.colors.transparent};
	}
`;

const LeftButtons = () => {
	const dispatch = useDispatch();
	const { tab, isFocused } = useTypedSelector(
		(state) => state.softphoneWidget,
	);

	const selectDialingTab = useCallback(() => {
		dispatch(softphoneWidget.actions.setTab("dialing"));
	}, [dispatch]);
	const selectLatestTab = useCallback(() => {
		dispatch(softphoneWidget.actions.setTab("latest"));
	}, [dispatch]);
	return (
		<LeftButtonsColumn focus={isFocused} align="end">
			<LeftButton
				active={tab === "dialing"}
				onClick={selectDialingTab}
				icon={
					<Icon
						id="phone-mini-keyboard"
						size={24}
						colors={[
							tab === "dialing"
								? theme.colors.accent
								: theme.colors.secondary,
						]}
					/>
				}
			/>
			<LeftButton
				active={tab === "latest"}
				onClick={selectLatestTab}
				icon={
					<Icon
						id="phone-history"
						size={24}
						colors={[
							tab === "latest"
								? theme.colors.accent
								: theme.colors.secondary,
						]}
					/>
				}
			/>
		</LeftButtonsColumn>
	);
};

export default LeftButtons;
