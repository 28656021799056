import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<{ hasPaddings: boolean }>`
	width: 100%;
	height: 100%;

	padding: ${({ hasPaddings }) => (hasPaddings ? "24px" : "0")};

	overflow: auto;

	${theme.scrollbar}
`;

export default Root;
