/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface State {
	loaded: boolean;
}

const initialState: State = {
	loaded: false,
};

type Reducer<P> = CaseReducer<State, PayloadAction<P>>;

const setLoaded: Reducer<boolean> = (state, { payload }) => {
	state.loaded = payload;
};

const settingState = createSlice({
	name: "settingState",
	initialState,
	reducers: {
		setLoaded,
	},
});

export default settingState;
