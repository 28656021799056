import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Column } from "uikit";
import { useOrderForm } from "../../../../../../../../redux/constants/OrdersPage/order";
import FileListWithTitle from "../../../../../../../../components/FileListWithTitle";

const Root = styled.div`
	width: 100%;
	height: 100%;
	padding: 24px;
`;

export default function FilesWidget({ form: propForm, validation }) {
	const [values, setValues] = useState(new Date());
	const { t } = useTranslation();
	const form = useOrderForm(propForm);

	const filesOnChange = useCallback(
		(files, type) => {
			files.forEach((file) => file.upload());
			form.setValue(type, files);
		},
		[form.getValues()],
	);
	useEffect(() => {
		const subscriber = form.watch((data) => {
			if (data.otherFiles) {
				setValues(new Date());
			}
		});

		return () => {
			subscriber.unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.watch]);
	console.log(form.getValues().otherFiles, values, "TEST FILES");

	return (
		<Root>
			<Column
				gaps="23px*"
				sizes="250px!* 0.015625px!"
				maxedWidth
				maxedHeight
			>
				<FileListWithTitle
					title={
						t(
							"pages.mainPage.pages.orders.orderModal.filesWidget.str0",
						) ?? ""
					}
					value={form.getValues().otherFiles || []}
					onChange={(files) => filesOnChange(files, "otherFiles")}
				/>
				<div />
			</Column>
		</Root>
	);
}
