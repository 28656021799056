import makeTable from "./makeTable";

const history = makeTable({
	allIds: ["date", "action", "user", "comment"],
	i18tPath: (id) => `change_history.columns.${id}`,

	initialSortMethod: {
		column: "date",
		type: "desc",
	},
});

export default history;
