import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Float } from "uikit";
import Popover from "./components/Popover";
import { ModalFilter } from "../../../..";

interface Props {
	filter: ModalFilter;
	setFilter: React.Dispatch<React.SetStateAction<ModalFilter>>;
}

const Filter: React.FC<Props> = ({ filter, setFilter }) => {
	const isFilterActive = useMemo(() => {
		if (filter.statuses.length) return true;
		if (filter.types.length) return true;
		return false;
	}, [filter.statuses.length, filter.types.length]);

	const [isButtonLocked, setIsButtonLocked] = useState(false);
	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const buttonOnClick = useCallback(() => {
		openAdditionalFilters
			? undefined
			: !isButtonLocked && setOpenAdditionalFilters(true);
	}, [isButtonLocked, openAdditionalFilters]);

	const buttonOnMouseDown = useCallback(
		(e) => {
			e.preventDefault();
			if (openAdditionalFilters) setIsButtonLocked(true);
		},
		[openAdditionalFilters],
	);

	const popoverOnClose = useCallback(() => {
		setOpenAdditionalFilters(false);
	}, []);

	useEffect(() => {
		const unblockHandler = () => {
			setIsButtonLocked(false);
		};

		document.addEventListener("click", unblockHandler);

		return () => {
			document.removeEventListener("click", unblockHandler);
		};
	}, []);

	return (
		<>
			<Float.Tracker id="filters">
				<Button.Button
					style={
						isFilterActive
							? { backgroundColor: "rgb(109,190,255)" }
							: {}
					}
					variant={isFilterActive ? "primary" : "secondary"}
					icon={<Icon id="filters" size={20} />}
					onClick={buttonOnClick}
					onMouseDown={buttonOnMouseDown}
				/>
			</Float.Tracker>

			<Popover
				filter={filter}
				setFilter={setFilter}
				open={openAdditionalFilters}
				onClose={popoverOnClose}
			/>
		</>
	);
};

export default Filter;
