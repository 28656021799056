import React from "react";
import styled from "styled-components";
import { Icon, RadioButton } from "uikit";

import { Orders } from "../../../../../../../redux/reducers/Orders";
import { StyledRow } from "../../../../../../common";
import { messagesType } from "../../../../constants/business";

import Root from "./components/Root";
import Message from "./components/Message";
import Text from "./components/Text";
import RadioButtonSelector from "./components/RadioButtonSelector";

const Container = styled.div`
	position: relative;
`;

const MessageType: React.FC<MessageType.Props> = ({
	text,
	type,
	onSelect,
	selected,
	tabSelected,
}) => (
	<Root gap="12px" onClick={onSelect}>
		<Container>
			<RadioButton.RadioButton selected={selected} />
			{tabSelected && <RadioButtonSelector></RadioButtonSelector>}
		</Container>
		<Message bgColor={messagesType[type]?.secondaryColor}>
			<StyledRow gap="12px">
				{type !== "default" && (
					<Icon
						id={messagesType[type]?.iconName}
						size={16}
						colors={[messagesType[type]?.primaryColor]}
					/>
				)}
				<Text color={messagesType[type]?.primaryColor}>{text}</Text>
			</StyledRow>
		</Message>
	</Root>
);

declare namespace MessageType {
	interface Props {
		text: string;
		type: Orders.Chat.MessagesTypeKeys;
		onSelect: () => void;
		selected: boolean;
		tabSelected: boolean;
	}
}

export default MessageType;
