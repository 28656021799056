import styled from "styled-components";

const Root = styled.div`
	width: 400px;

	padding: 20px;

	border-radius: 5px;

	background-color: white;
`;

export default Root;
