import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Column, Row } from "uikit";

import { useTypedSelector } from "../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../hooks/useObjectEditor";

import TaxiServices from "./components/TaxiServices";
import MultiSelectList from "./components/MultiSelectList";
import { getShifts, getStatuses } from "./data";

const Form: React.FC<Form.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const shifts = useMemo(() => getShifts(t), [t]);
	const statuses = useMemo(() => getStatuses(t), [t]);

	const language = useTypedSelector((state) => state.session.language);

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServicesValue = valueEditor.usePicker([
		"companyIds",
		"taxiServiceIds",
	]);

	const assignValue = valueEditor.useAssigner();

	const onActiveOrderValue = valueEditor.useGetter("onActiveOrder");
	const onActiveOrderOnChange = valueEditor.useSetter("onActiveOrder");

	const statusesValue = valueEditor.useGetter("statuses");
	const statusesOnChange = valueEditor.useSetter("statuses");

	const shiftsValue = valueEditor.useGetter("shifts");
	const shiftsOnChange = valueEditor.useSetter("shifts");

	return (
		<Row gaps="12px*" sizes="1fr auto!*">
			<Column gaps="12px*">
				<TaxiServices
					value={taxiServicesValue}
					disabled={onActiveOrderValue}
					language={language}
					onChange={assignValue}
				/>
			</Column>
			<Column gaps="20px*">
				<CheckBoxWithContent
					value={onActiveOrderValue}
					gap="10px"
					onChange={onActiveOrderOnChange}
				>
					{t(
						"orderPageWidgets.map2.header.filters.content.form.str150",
					) ?? ""}
				</CheckBoxWithContent>
				<MultiSelectList
					value={statusesValue}
					disabled={onActiveOrderValue}
					label={
						t(
							"orderPageWidgets.map2.header.filters.content.form.str0",
						) ?? ""
					}
					options={statuses}
					onChange={statusesOnChange}
				/>
			</Column>
			<MultiSelectList
				value={shiftsValue}
				disabled={onActiveOrderValue}
				label={
					t(
						"orderPageWidgets.map2.header.filters.content.form.str1",
					) ?? ""
				}
				options={shifts}
				onChange={shiftsOnChange}
			/>
		</Row>
	);
};

declare namespace Form {
	interface Value {
		onActiveOrder: boolean;

		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];

		statuses: any;
		shifts: any;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Form;
