import { getPRPC } from "../../../../../hooks/usePRPC";
import createRPCQuery from "../../../../../utils/createRPCQuery.util";

interface Props {
	limit?: number;
}

export default function getProviderSms(props?: Props) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const data = props
			? await createRPCQuery(() =>
					prpcow?.theirsModel.sms.provider.getAll({
						limit: props?.limit,
					}),
			  )
			: await createRPCQuery(() =>
					prpcow?.theirsModel.sms.provider.getAll(),
			  );

		if (!data) return null;
		// dispatch(messageProviderSms.actions.setData(data.models));
		return data;
	};
}
