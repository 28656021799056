/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { Archive, ReduxReducer } from "../../interface";

import { State } from "./interface";

const DEFAULT_PAGINATION = {
	limit: undefined,
	count: 0,
	offset: 0,
};

const initialState: State = {
	pagination: DEFAULT_PAGINATION,
	filter: {
		dateFrom: moment().startOf("day").toDate(),
		dateTo: moment().endOf("day").toDate(),
		searchQuery: "",
		companyFilter: ["all"],
		taxiServiceFilter: ["all"],
	},
	internalDateRange: {
		from: moment().startOf("day").toDate(),
		to: moment().endOf("day").toDate(),
	},
	orders: {
		orderId: null,
	},
};

const setPagination: ReduxReducer<State, State["pagination"]> = (
	state,
	{ payload },
) => {
	state.pagination = payload;
};

const setFilter: ReduxReducer<State, State["filter"]> = (
	state,
	{ payload },
) => {
	state.filter = payload;
};
const modifyPagination: ReduxReducer<State, Partial<State["pagination"]>> = (
	state,
	{ payload },
) => {
	state.pagination = { ...state.pagination, ...payload };
};

const modifyFilter: ReduxReducer<State, Partial<State["filter"]>> = (
	state,
	{ payload },
) => {
	state.filter = { ...state.filter, ...payload };
};

const modifyOrdersFilter: ReduxReducer<State, Partial<State["orders"]>> = (
	state,
	{ payload },
) => {
	state.orders = { ...state.orders, ...payload };
};

const modifyInternalDateRange: ReduxReducer<
	State,
	Partial<State["internalDateRange"]>
> = (state, { payload }) => {
	state.internalDateRange = {
		...state.internalDateRange,
		...payload,
	};
};

const resetFilter: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrdersFilters = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_FILTERS,
	initialState,
	reducers: {
		resetFilter,
		setPagination,
		setFilter,
		modifyFilter,
		modifyPagination,
		modifyInternalDateRange,
		modifyOrdersFilter,
	},
});

export const archiveOrdersFiltersAction = archiveOrdersFilters.actions;
export const archiveOrdersFiltersReducer = archiveOrdersFilters.reducer;
export type archiveOrdersFiltersState = State;

export default archiveOrdersFilters;
