/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { clone } from "lodash";
import List from "./components/List";
import Editor from "./components/Editor";
import Language from "../../../../../../../../services/Language";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({
	value,

	language,

	canCancel,
	canSave,

	onChange,

	onSave,
	onCancel,
}) => {
	const [editing, setEditing] = useState(false);

	const mapValueItemByKey = useMemo(
		() =>
			value.reduce((accumulator, item) => {
				accumulator[item.id] = item;

				return accumulator;
			}, {} as Record<number, Content.Item>),
		[value],
	);

	const listValue = useMemo<List.Value>(
		() =>
			value
				.filter((item) => !item.disabled)
				.map((item) => ({
					id: item.id,

					taxiServiceId: item.taxiServiceId,
					suburban: item.suburban,
					position: item.position,
					name: item.name,

					selected: item.selected,
					modified: item.modified,
					valid: item.valid,

					active: item.active,
					bySector: item.bySector,
				})),
		[value],
	);

	const editorValue = useMemo<Editor.Value>(
		() =>
			value.map((item) => ({
				id: item.id,

				taxiServiceId: item.taxiServiceId,

				name: item.name,
				vertices: item.vertices,

				selected: item.selected,
				creating: item.creating,
				modified: item.modified,
				disabled: item.disabled,

				active: item.active,
			})),
		[value],
	);

	const listOnChange = useCallback(
		(listValue: List.Value) => {
			const newValue = clone(value);

			listValue.forEach((item) => {
				const newItem = clone(mapValueItemByKey[item.id]);
				const index = newValue.findIndex(
					(newValueItem) => newValueItem.id === item.id,
				);

				if (newItem.selected !== item.selected) setEditing(false);

				newItem.id = item.id;

				newItem.taxiServiceId = item.taxiServiceId;

				newItem.suburban = item.suburban;
				newItem.position = item.position;
				newItem.name = item.name;

				newItem.selected = item.selected;
				newItem.active = item.active;

				newValue[index] = newItem;
			});

			onChange(newValue);
		},
		[mapValueItemByKey, onChange, value],
	);

	const editorOnChange = useCallback(
		(editorValue: Editor.Value) => {
			onChange(
				editorValue.map((item) => {
					const newItem = clone(mapValueItemByKey[item.id]);

					if (newItem.selected !== item.selected) setEditing(false);

					newItem.id = item.id;

					newItem.vertices = item.vertices;

					newItem.selected = item.selected;
					newItem.creating = item.creating;
					newItem.modified = item.modified;
					newItem.disabled = item.disabled;

					return newItem;
				}),
			);
		},
		[mapValueItemByKey, onChange],
	);

	const stopEditingDecorator = useCallback(
		(callback: () => void) => () => {
			setEditing(false);
			callback();
		},
		[],
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const editorOnSave = useCallback(stopEditingDecorator(onSave), [onSave]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const editorOnCancel = useCallback(stopEditingDecorator(onCancel), [
		onCancel,
	]);

	return (
		<Root maxedWidth maxedHeight sizes="300px! 1fr">
			<List
				value={listValue}
				editing={editing}
				language={language}
				onChange={listOnChange}
				onChangeEditing={setEditing}
			/>
			<Editor
				value={editorValue}
				editing={editing}
				language={language}
				canCancel={canCancel}
				canSave={canSave}
				onChange={editorOnChange}
				onChangeEditing={setEditing}
				onSave={editorOnSave}
				onCancel={editorOnCancel}
			/>
		</Root>
	);
};

declare namespace Content {
	type Item = List.Item & Editor.Item;
	type Value = Item[];
	type Details = never;

	interface Props {
		value: Value;

		language: Language;

		canCancel: boolean;
		canSave: boolean;

		onChange: Dispatch<Value>;

		onSave: () => void;
		onCancel: () => void;
	}
}

export default Content;
