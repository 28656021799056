/* eslint-disable no-shadow */
export enum BaseRateAdjustmentType {
	SURCHARGE_TO_COST_PER_KM = "surcharge_to_cost_per_km",
	COEFFICIENT_TO_COST_PER_KM = "coefficient_to_cost_per_km",
	SURCHARGE_TO_ORDER_SUM = "surcharge_to_order_sum",
	COEFFICIENT_TO_ORDER_SUM = "coefficient_to_order_sum",
	SURCHARGE_TO_DEFAULT_RATE = "surcharge_to_default_rate",
	COEFFICIENT_TO_DEFAULT_RATE = "coefficient_to_default_rate",
}

export enum RateGridAdjustmentType {
	CUSTOM_RATE = "custom_rate",
	SURCHARGE_TO_STANDARD_RATE = "surcharge_to_standard_rate",
	COEFFICIENT_TO_STANDARD_RATE = "coefficient_to_standard_rate",
	SURCHARGE_TO_PREVIOUS_RULE = "surcharge_to_previous_rule",
	COEFFICIENT_TO_PREVIOUS_RULE = "coefficient_to_previous_rule",
}
