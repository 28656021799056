import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Overlayer, Row } from "uikit";

import Root from "./components/Root";
import ParameterName from "./components/ParameterName";
import ParameterValue from "./components/ParameterValue";
import OnlineStatus from "./components/OnlineStatus";

const ExecutorCard: React.FC<ExecutorCard.Props> = ({
	fullName,
	car,
	status,
	statusColor,
	online,
	needToSelectCar,
	assignedBy,

	onSelectCar,
}) => {
	const { t } = useTranslation();
	return (
		<Root gaps="8px*">
			<Row align="center" gaps="6px">
				<ParameterName>
					{t(
						"pages.mainPage.pages.orders.setOrderExecutorModal.content.executorCard.str200",
					) ?? ""}
					:
				</ParameterName>
				<ParameterValue>{fullName}</ParameterValue>
			</Row>
			<Row align="center" gaps="6px" sizes="auto! 1fr auto!">
				<ParameterName>
					{t(
						"pages.mainPage.pages.orders.setOrderExecutorModal.content.executorCard.str201",
					) ?? ""}
					:
				</ParameterName>
				<ParameterValue>{car}</ParameterValue>
				{needToSelectCar && (
					<Overlayer
						overlay={
							<Row
								style={{ height: 18 }}
								align="center"
								justify="center"
							>
								<Button.Button
									variant="primary"
									tabIndex={0}
									style={{ width: 32, height: 32 }}
									icon={<Icon id="car" size={18} />}
									onClick={onSelectCar}
								/>
							</Row>
						}
					>
						<div style={{ width: 32, height: 18 }} />
					</Overlayer>
				)}
			</Row>
			<Row align="center" gaps="6px*">
				<ParameterName>
					{t(
						"pages.mainPage.pages.orders.setOrderExecutorModal.content.executorCard.str202",
					) ?? ""}
					:
				</ParameterName>
				<OnlineStatus online={online} />
				<ParameterValue color={statusColor}>{status}</ParameterValue>
			</Row>
			<Row align="center" gaps="6px">
				<ParameterName>
					{t(
						"pages.mainPage.pages.orders.setOrderExecutorModal.content.executorCard.str203",
					) ?? ""}
					:
				</ParameterName>
				<ParameterValue>{assignedBy}</ParameterValue>
			</Row>
		</Root>
	);
};

declare namespace ExecutorCard {
	interface Props {
		fullName: string;
		car: string;
		status: string;
		statusColor: string;
		online: boolean;
		needToSelectCar: boolean;
		assignedBy: string;

		onSelectCar: () => void;
	}
}

export default ExecutorCard;
