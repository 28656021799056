import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)<Root.Props>`
	padding: 0px 16px;

	height: 47px;
	width: 100%;

	align-items: center;

	justify-content: space-between;

	background: ${theme.colors.white};

	${({ draggable }) => (draggable ? "cursor: grab;" : "")}

	overflow: hidden;
`;

declare namespace Root {
	interface Props extends Row.Props {
		draggable: boolean;
	}
}

export default Root;
