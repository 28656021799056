import styled from "styled-components";
import { theme } from "uikit";

import { StyledRow } from "../../../../../../../../common";

const Root = styled(StyledRow)<{ selected: boolean }>`
	padding: 0px 6px;

	height: 36px;

	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid ${theme.colors.color_border_basic};

	overflow-x: scroll;

	border: ${({ selected }) =>
		selected ? `1px solid ${theme.colors.accent}` : "none"};

	border-radius: 3px;

	z-index: 2;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
`;

export default Root;
