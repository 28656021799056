import makeTable from "./makeTable";

const executor = makeTable({
	allIds: [
		"fullName",
		"alias",
		"parkNumber",
		"phones",
		"balance",
		"bonusBalance",
		"role",
		"company",
		"taxiService",
		"registeredAt",
		"responsibleDispatcher",
		"notes",
	],
	defaultIds: [
		"fullName",
		"alias",
		"parkNumber",
		"phones",
		"balance",
		"bonusBalance",
		"role",
		"company",
		"taxiService",
		"registeredAt",
		"responsibleDispatcher",
		"notes",
	],
	initialSortMethod: {
		column: "alias",
		type: "asc",
	},
	initialWidths: {
		fullName: 137,
		alias: 100,
		parkNumber: 100,
		phones: 150,
		balance: 110,
		bonusBalance: 130,
		role: 140,
		company: 120,
		taxiService: 120,
		registeredAt: 150,
		responsibleDispatcher: 137,
		notes: 137,
	},
	i18tPath: (id) => `tableColumns.executors.${id}`,
});

export default executor;
