import makeTable from "./makeTable";

const customerRateRule = makeTable({
	allIds: ["orderCount", "discount", "onlineDiscount"],
	defaultIds: ["orderCount", "discount", "onlineDiscount"],
	initialWidths: {
		orderCount: 100,
		discount: 200,
		onlineDiscount: 200,
	},
	i18tPath: (id) => `tableColumns.customerRateRule.${id}`,
});

export default customerRateRule;
