import processAddressPath from "./processAddressPath";
import settlementToString from "./settlementToString";

function objectToLabel(
	city: cityToString.City,
	add: "full" | "district" | "region" | null = null,
) {
	const settlement = settlementToString(city);
	const district = processAddressPath(city.district);
	const region = processAddressPath(city.region);

	switch (add) {
		case "full":
			return `${settlement} ${district} ${region}`.trim();
		case "district":
			return `${settlement} ${district}`.trim();
		case "region":
			return `${settlement} ${region}`.trim();
		default:
			return `${settlement}`.trim();
	}
}

declare namespace cityToString {
	interface City {
		settlementType?: string;
		settlement?: string;
		district?: string;
		region?: string;
	}
}
export default objectToLabel;
