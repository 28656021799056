import React from "react";
import { Column } from "uikit";

import FileList from "../FileList";

import Title from "./components/Title";

const FileListWithTitle: React.FC<FileListWithTitle.Props> = ({
	title,

	...props
}) => (
	<Column gaps="15px" sizes="auto! 1fr">
		{title ? <Title>{title}</Title> : <></>}
		<FileList {...props} />
	</Column>
);

declare namespace FileListWithTitle {
	type Value = FileList.Value;

	interface Props extends FileList.Props {
		title?: string;
	}
}

export default FileListWithTitle;
