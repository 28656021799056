/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
import React, {
	Dispatch,
	memo,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SortType } from "rsuite-table";
import { CheckBox, Column } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";
import Call from "../../../../../../../../services/Call";
import LightTable from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import tPath from "../../constants/tPath";

import { Columns, columns, defaultColumnsId } from "./constants";
import Root from "./components/Root";
import Type from "./components/Type";
import Status from "./components/Status";
import Record from "./components/Record";

const Content: React.FC<Content.Props> = memo(
	({ value, selected, setSelected, sort, onSort, setLimit }) => {
		const { t } = useTranslation();
		const lang = useTypedSelector((state) => state.session.language);
		const tableRef = useRef<LightTable.Ref | null>(null);

		const tColumns = useMemo(
			() =>
				columns.map((c) => ({
					...c,
					label: t(`${tPath}.columns.${c.id}`),
				})),
			[t],
		);
		const [columnsId, setColumnsId] = useState(defaultColumnsId);

		const updateSelected = useCallback(
			(item) => {
				if (selected.includes(item.id)) {
					setSelected(selected.filter((id) => item.id !== id));
				} else {
					setSelected([...selected, item.id]);
				}
			},
			[setSelected, selected],
		);

		const robotName = useMemo(() => t(`${tPath}.robotName`), [t]);

		const renderColumns = useMemo(
			() =>
				columnsId.map((columnId) => {
					const column = tColumns.find((col) => col.id === columnId);
					if (!column) return null;

					return (
						<LightTable.Column
							resizable
							key={columnId}
							verticalAlign="middle"
							width={column.width}
							sortable={column.sortable}
							flexGrow={column.width ? 0 : 1}
						>
							<LightTable.HeaderCell
								style={{ padding: "0 15px" }}
							>
								{column.label}
							</LightTable.HeaderCell>
							<LightTable.Cell
								dataKey={column.id}
								fullText
								verticalAlign="middle"
								style={{ padding: "0 15px" }}
							>
								{(rowData) => {
									const data = rowData as Call.Model;

									const {
										type,
										startDate,
										operator,
										taxiService,
										servicePhone,
										phone,
										waitTimeInSeconds,
										talkTimeInSeconds,
										dialStatus,
										dispatcherToSip,
										playSoundUrl,
										downloadSoundUrl,
									} = data;

									switch (columnId) {
										case Columns.TYPE:
											return <Type type={type} />;

										case Columns.CALL_RECEIVED_TIME:
											return startDate ? (
												<Column gaps="3px*">
													<div>
														{new Date(
															startDate,
														).toLocaleTimeString()}
													</div>
													<div>
														{new Date(
															startDate,
														).toLocaleDateString()}
													</div>
												</Column>
											) : null;

										case Columns.EMPLOYEE:
											const {
												firstName,
												lastName,
												fatherName,
											} =
												dispatcherToSip?.dispatcher
													?.person ?? {};

											const person = [
												firstName,
												lastName,
												fatherName,
											]
												.filter(Boolean)
												.join(" ");

											if (!person.length)
												return robotName;

											return person;

										case Columns.SURNAME:
											return (
												<>
													{dispatcherToSip?.dispatcher
														?.person?.lastName ||
														""}
												</>
											);

										case Columns.NAME:
											return (
												<>
													{dispatcherToSip?.dispatcher
														?.person?.firstName ||
														""}
												</>
											);

										case Columns.FATHER_NAME:
											return (
												<>
													{dispatcherToSip?.dispatcher
														?.person?.fatherName ||
														""}
												</>
											);

										case Columns.SIP:
											return <>{operator || "A"}</>;

										case Columns.SERVICE_PHONE:
											return <>{servicePhone || ""}</>;

										case Columns.CUSTOMER_PHONE:
											return <>{phone || ""}</>;

										case Columns.STATUS:
											return (
												<Status status={dialStatus} />
											);

										case Columns.WAITING_TIME:
											const waitingDate = new Date(
												0,
												0,
												0,
												0,
											);
											waitingDate.setSeconds(
												waitTimeInSeconds || 0,
											);
											return (
												waitingDate?.toLocaleTimeString() ||
												""
											);

										case Columns.CALL_DURATION:
											const durationDate = new Date(
												0,
												0,
												0,
												0,
											);

											durationDate.setSeconds(
												talkTimeInSeconds || 0,
											);

											return (
												<>
													{durationDate?.toLocaleTimeString() ||
														""}
												</>
											);

										case Columns.COMPANY:
											return (
												<>
													{taxiService?.company
														?.name?.[lang] || ""}
												</>
											);

										case Columns.BRANCH:
											return (
												<>
													{
														taxiService
															?.settlement?.[
															lang || ""
														]
													}
												</>
											);

										case Columns.RECORD:
											return (
												<Record
													playSoundUrl={playSoundUrl}
													downloadSoundUrl={
														downloadSoundUrl
													}
												/>
											);

										default:
											return null;
									}
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);
				}),
			[columnsId, lang, tColumns, robotName],
		);

		const getClassName = useCallback(
			(item) => (item && selected.includes(item.id) ? "selected" : ""),
			[selected],
		);

		const handleAllSelectedChange = useCallback(
			(isAllSelected) => {
				if (isAllSelected) {
					setSelected(value?.map(({ id }) => id));
				} else {
					setSelected([]);
				}
			},
			[setSelected, value],
		);

		// scroll - start
		const [height, setHeight] = useState<number>(-1);
		const [width, setWidth] = useState<number>(-1);
		const handleScroll = useCallback(
			(x: number, y: number) => {
				const top = Math.round(Math.abs(y));
				const left = Math.round(Math.abs(x));
				if (width >= left && height === top) return;
				if (width < left && width !== 999999999) setWidth(left);
				if (top === 0) return;
				if (top > 0 && width < 999999999) setWidth(999999999);

				const contextHeight = value.length * 46;
				const tableContainerHeight =
					tableRef.current?.root.getBoundingClientRect().height ?? 0;
				const valueHeight = contextHeight - top - tableContainerHeight;

				if (valueHeight < 300) {
					if (height - 9 >= top) return;
					setHeight(top);
					setLimit((prev) => prev + 2);
				}
			},
			[width, height, value.length, setLimit],
		);
		// scroll - end

		return (
			<Root sizes="1fr auto!">
				<div>
					<LightTable
						ref={tableRef}
						rowHeight={46}
						data={value}
						virtualized
						fillHeight
						shouldUpdateScroll={false}
						rowClassName={getClassName}
						onRowClick={updateSelected}
						onScroll={handleScroll}
						sortColumn={sort?.column}
						sortType={sort?.type}
						onSortColumn={
							onSort as (
								dataKey: string,
								sortType?: SortType | undefined,
							) => void
						}
						wordWrap={false}
					>
						<LightTable.Column verticalAlign="middle" width={35}>
							<LightTable.HeaderCell
								style={{ padding: "0 auto" }}
							>
								<CheckBox
									value={
										selected.length === value.length &&
										value.length !== 0
									}
									onChange={handleAllSelectedChange}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								style={{ padding: "0 auto" }}
							>
								{(item) => (
									<CheckBox
										value={selected.includes(item.id)}
										onChange={() => {
											updateSelected(item);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
						{renderColumns}
					</LightTable>
				</div>
				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={tColumns}
					onChange={setColumnsId}
				/>
			</Root>
		);
	},
);

declare namespace Content {
	interface Props {
		value: Call.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		sort: {
			column: string | undefined;
			type: SortType | undefined;
		};
		onSort: (column: string, type: SortType) => void;
		setLimit: Dispatch<SetStateAction<number>>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
		sortable?: boolean;
	};
}

export default Content;
