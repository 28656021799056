import React, { memo, useCallback, useMemo } from "react";
import { Column, Row, Select } from "uikit";
import { useTranslation } from "react-i18next";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import { RoundingMethod, roundingMethodOptions } from "./constants";
import Multiple from "./components/Multiple";
import tPath from "../../../../../../../../constants/tPath";
import ImprovedStepper from "../../../../../../../../../../../../../../components/ImprovedStepper";

const Rounding: React.FC<Rounding.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const method = valueEditor.useGetter("method");
	const setMethod = valueEditor.useSetter("method");
	const handleMethodChange = useCallback(
		(newMethod) => {
			setMethod(newMethod);
		},
		[setMethod],
	);

	const precision = valueEditor.useGetter("precision");
	const setPrecision = valueEditor.useSetter("precision");

	const multiple = valueEditor.useGetter("multiple");
	const setMultiple = valueEditor.useSetter("multiple");

	const tOptions = useMemo(
		() =>
			roundingMethodOptions.map((o) => ({
				...o,
				label: t(
					`${tPath}.modal.tabs.main.rounding.method.options.${o.key}`,
				),
			})),
		[t],
	);

	return (
		<FieldsContainer
			label={t(`${tPath}.modal.tabs.main.rounding.title`) || ""}
		>
			<Column gaps="10px*">
				<Row gaps="10px*" align="center" sizes="18% 1fr">
					<div>
						{t(`${tPath}.modal.tabs.main.rounding.method.title`)}
					</div>
					<Select
						placeholder={
							t(
								`${tPath}.modal.tabs.main.rounding.method.placeholder`,
							) || ""
						}
						options={tOptions}
						value={method}
						onChange={handleMethodChange}
					/>
				</Row>
				<Row gaps="10px*" align="center" sizes="18% auto">
					<div>
						{t(
							`${tPath}.modal.tabs.main.rounding.method.precision`,
						)}
					</div>
					<ImprovedStepper
						value={precision}
						onChange={setPrecision}
					/>
				</Row>
				<Multiple value={multiple} onChange={setMultiple} />
			</Column>
		</FieldsContainer>
	);
});

declare namespace Rounding {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		method: RoundingMethod;
		precision: number;
		multiple: Multiple.Value;
	}
}

export default Rounding;
