import makeTable from "./makeTable";

const accountingAgentsFirstTable = makeTable({
	allIds: ["title", "contactPersonName", "balance", "company"],
	i18tPath: (id) => `mainPage.accounting.agents.columns.${id}`,
	defaultIds: ["title", "contactPersonName", "balance", "company"],
	initialSortMethod: {
		column: "title",
		type: "asc",
	},
	initialWidths: {
		title: 300,
		contactPersonName: 300,
		balance: 300,
		company: 300,
	},
	initialResizable: {
		title: true,
		contactPersonName: true,
		balance: true,
		company: true,
	},
	initialSortable: {
		title: true,
		contactPersonName: true,
		balance: true,
		company: true,
	},
});

export default accountingAgentsFirstTable;
