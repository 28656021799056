import { cloneDeep } from "lodash";

import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import { preferences } from "../../../reducers";

interface Props {
	limit?: number;
	sortType?: string;
}

export default function getServices(props?: Props) {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		try {
			const data = props
				? await createRPCQuery(() =>
						prpcow?.theirsModel.service.getAll({
							limit: props?.limit,
						}),
				  )
				: await createRPCQuery(() =>
						prpcow?.theirsModel.service.getAll({}),
				  );

			if (!data) return null;

			return dispatch(
				preferences.services.data.setServices(cloneDeep(data?.items)),
			);
		} catch {
			return null;
		}
	};
}
