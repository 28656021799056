import styled from "styled-components";
import { Button, InputGroup, theme, TextBox } from "uikit";

import {
	typographyStyles,
	baseStyles,
	flexStyles,
	distributeStyles,
	Style,
	StyledRow,
	StyledSpan,
	StyledP,
} from "../../../../../../../components/common";

export const StyledInputGroup = styled(InputGroup.InputGroup)`
	width: 100%;

	& div div div:nth-child(1) {
		width: 100%;
	}
`;
// h={"clamp(26px, 32px, 80vh)"}
export const StyledTextBox = styled(TextBox.TextBox)`
	height: clamp(27px, 3.7vh, 32px);

	.${TextBox.classes.input} {
		font-size: clamp(12px, 1.7vh, 14px);
		font-style: normal;
		font-weight: 400;
		line-height: 18px;

		color: ${theme.colors.primary};
		::placeholder {
			text-transform: none;
			color: #9ba3a8;
			font-weight: 300;
			font-size: clamp(12px, 1.7vh, 14px);
			text-transform: none;
			line-height: 18px;
			height: 25px;
		}
	}
`;

export const StyledAccentText = styled(StyledSpan)`
	font-weight: 700;
	color: #03a9f4;
`;

export const StyledSuccess = styled(StyledSpan)`
	font-weight: 700;
	color: #4caf50;
`;

export const StyledFailed = styled(StyledSpan)`
	font-weight: 700;
	color: #f83528;
`;

export const StyledTrustLevel = styled(StyledSpan)`
	color: #647079;
	font-weight: 700;
	padding: 0 2px;
`;

export const StyledPreWrap = styled(StyledRow)`
	overflow: visible;
`;

export const StyledWrapCityIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	padding: 2px 12px;
`;

export const StyledPassengersIcon = styled.div`
	margin: 0 8px 0 0;
	height: 16px;
	width: 16px;

	& svg {
		fill: #5e6b73;
		height: 16px;
		width: 16px;
	}
`;

export const StyledWrapIcon = styled(StyledRow)`
	& svg {
		${distributeStyles}
		${baseStyles}
		${flexStyles}
		${({ colors }) => {
			if (typeof colors === "string") return `fill:${colors};`;
			if (typeof colors === "object") {
				return `
				fill: ${colors?.light};

				@media (prefers-color-scheme: dark) {
					& {
						fill: ${colors?.dark};
					}
				};
				`;
			}
			return undefined;
		}};
	}
`;

export const StyledBalance = styled(StyledSpan)`
	display: flex;
	color: rgb(76, 175, 80);
	word-wrap: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 14px;

	${distributeStyles}
	${baseStyles}
	${flexStyles}
	${typographyStyles}
`;

export const StyledButton = styled(Button.Button)<
	Button.Props & {
		fill?: Style.BaseType["colors"];
		fillHover?: Style.BaseType["colors"];
		width?: string;
		height?: string;
		minWidth?: string;
	}
>`
	width: ${({ width = "32px" }) => width};
	height: ${({ height = "32px" }) => height};
	min-width: ${({ minWidth = "32px" }) => minWidth};
	padding: 0px;

	& svg:focus,
	& svg:focus-visible {
		border: none;
		outline: none;
	}
	& svg {
		${({ fill }) => {
			if (typeof fill === "string") return `fill:${fill};`;
			if (typeof fill === "object") {
				return `
				fill: ${fill?.light};

				@media (prefers-color-scheme: dark) {
					& {
						fill: ${fill?.dark};
					}
				};
				`;
			}
			return undefined;
		}};

		&:hover {
			${({ fillHover }) => {
				if (typeof fillHover === "string") return `fill:${fillHover};`;
				if (typeof fillHover === "object") {
					return `
				fill: ${fillHover?.light};

				@media (prefers-color-scheme: dark) {
					&:hover {
						fill: ${fillHover?.dark};
					}
				};
				`;
				}
				return undefined;
			}};
		}
	}
`;

export const StyledLabel = styled.div`
	height: min-content;
	color: ${theme.colors.primary};
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface BlockProps {
	width: string;
}
export const Block = styled.div<BlockProps>`
	width: ${(props) => props.width};
`;

export const Primary = styled(StyledP)`
	color: #21333f;
	justify-content: center;
	align-items: center;

	font-weight: 600;
	font-size: 12px;
	line-height: 14.4px;
`;

export const Gray = styled(StyledP)`
	color: #647079;
	justify-content: center;
	align-items: center;

	font-weight: 400;
	font-size: 12px;
	line-height: 14.4px;
`;

export const Red = styled(StyledP)`
	color: #f83528;

	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
`;

export const Green = styled(StyledP)`
	color: #4caf50;

	font-weight: 400;
	font-size: 12px;
	line-height: 14.4px;
`;
