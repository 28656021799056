import { combineReducers } from "@reduxjs/toolkit";

import { dataAction, dataReducer, dataState } from "./data";
import { errorsAction, errorsReducer, errorsState } from "./errors";

export interface StateAutodetectPhones {
	data: dataState;
	errors: errorsState;
}

export const autodetectPhone = combineReducers<StateAutodetectPhones>({
	data: dataReducer,
	errors: errorsReducer,
});

export const autodetectPhoneAction = {
	data: dataAction,
	errors: errorsAction,
};

export default autodetectPhone;
