import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import CreateOrderDateCellContent from "../components/CreateOrderDateCellContent";

function createOrderDate({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CreateOrderDate,
		(item) => <CreateOrderDateCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default createOrderDate;
