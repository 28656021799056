import React, { memo } from "react";

import { useCurrencyGlobalSettings } from "../../../../../../../../../../hooks";
import {
	ValueStepper,
	StyledRow,
	StyledP,
	StyledSpan,
	Style,
} from "../../../../../../../../../../components/common";

const Item: React.FC<Item.Props> = memo(
	({ title, disabled = false, value, onChange, titleStyle, styles }) => {
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		return (
			<StyledRow gap="10px" alignItems="center" w="100%" {...styles}>
				<StyledP {...titleStyle}>{title}</StyledP>
				<ValueStepper
					disabled={disabled}
					min={0}
					value={value}
					onChange={onChange}
				/>
				<StyledSpan>{currencyGlobalSettings}</StyledSpan>
			</StyledRow>
		);
	},
);

declare namespace Item {
	interface Props {
		title: string;
		titleStyle?: Style.TextType;
		styles?: Style.Row;
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	type Value = number;
}

export default Item;
