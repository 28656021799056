import React, { Dispatch, memo, useMemo } from "react";
import {
	Column,
	Row,
	CheckBox,
	Stepper,
	Select,
	react,
	useRefWithSetter,
} from "uikit";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../../../services/Language";
import { useTypedSelector } from "../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import {
	languageOptions,
	languages,
} from "../../../../../../../../../assets/languages/langs";
import translationPath from "../../../../constants/translationPath";
import {
	MessageTemplateActions,
	MessageTemplateTypes,
} from "../../../../../../../../../types/MessageTemplateEnums";
import { useAppContext } from "../../../../../../../../../context";

import InternalController from "./Controller";
import Root from "./components/Root";
import Name from "./components/Name";
import CompaniesAndTaxiServices from "./components/CompaniesAndTaxiServices";
import TemplateBody from "./components/TemplateBody";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ value, onChange, register, controller }) => {
		const { getTaxiServiceLanguages } = useAppContext();
		const defaultTaxiServiceId = useTypedSelector(
			(state) => state.session.defaultTaxiServiceId,
		);

		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);

		const [companyBranchRef, setCompanyBranchRef] =
			useRefWithSetter<CompaniesAndTaxiServices.Ref | null>(null);

		controller.setContext({
			nameRef,
			companyBranchRef,
		});
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const isDefault = valueEditor.get("isDefault");

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const templateText = valueEditor.useGetter("templateText");
		const setTemplateText = valueEditor.useSetter("templateText");

		const type = valueEditor.get("type");

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "taxiServices"],
			"json",
		);

		const otherData = valueEditor.usePicker(
			[
				"lang",
				"automaticSending",
				"autoSendOrderChanges",
				"disableAutoSendForPreOrder",
				"disableAutoSendForOnlineOrder",
				"isAutoSendDelay",
				"autoSendDelay",
				"excludeOrderChanges",
				"isValidityPeriod",
				"validityPeriod",
				"action",
				"isTransliteration",
				"isMaxSmsPartCount",
				"maxSmsPartCount",
				"isMinTimeoutSendingSms",
				"minTimeoutSendingSms",
			],
			"json",
		);
		const updateAllData = valueEditor.useAssigner();

		const carWillBe =
			otherData.action === MessageTemplateActions.WILL_BE_TIME;
		const allowAll =
			otherData.action === MessageTemplateActions.UNKNOWN_ACTION;

		const languageExclude = useMemo<Language[]>(() => {
			if (!defaultTaxiServiceId) return [];
			const existLanguages =
				getTaxiServiceLanguages(defaultTaxiServiceId);
			return languages.filter((lang) => !existLanguages.includes(lang));
		}, [defaultTaxiServiceId, getTaxiServiceLanguages]);

		const selectOptions = useMemo(
			() =>
				languageOptions.filter(
					(item) => !languageExclude.includes(item.value),
				),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[languageOptions],
		);

		return (
			<Root hasPaddings>
				<Column gaps="25px*" maxedWidth maxedHeight>
					<Row sizes="40%! 30%! 30% - 19px!" gaps="16px*">
						<Name
							ref={setNameRef}
							name={name}
							setName={setName}
							isDefault={isDefault}
							register={register}
						/>
						<CompaniesAndTaxiServices
							ref={setCompanyBranchRef}
							value={taxiServicesData}
							onChange={updateAllData}
						/>
					</Row>
					<Row align="center" gaps="10px">
						<span>{t(`${translationPath}.modal.lang`)}:</span>
						<Select
							style={{ width: "120px" }}
							options={selectOptions}
							value={otherData.lang}
							onChange={(newValue) => {
								updateAllData({
									...otherData,
									lang: newValue as Language,
								});
							}}
						/>
					</Row>
					<TemplateBody
						value={templateText}
						onChange={setTemplateText}
						type={type}
					/>
					<Row justify="space-between">
						<Column gaps="15px*">
							<CheckBox
								label={
									t(
										`${translationPath}.modal.automaticSending`,
									) || ""
								}
								value={otherData.automaticSending}
								onChange={(newValue) => {
									updateAllData({
										...otherData,
										automaticSending: newValue,
									});
								}}
							/>
							{(carWillBe || allowAll) && (
								<>
									<CheckBox
										label={
											t(
												`${translationPath}.modal.autoSendOrderChanges`,
											) || ""
										}
										value={otherData.autoSendOrderChanges}
										onChange={(newValue) => {
											updateAllData({
												...otherData,
												autoSendOrderChanges: newValue,
											});
										}}
									/>
									<CheckBox
										label={
											t(
												`${translationPath}.modal.disableAutoSendForPreOrder`,
											) || ""
										}
										value={
											otherData.disableAutoSendForPreOrder
										}
										onChange={(newValue) => {
											updateAllData({
												...otherData,
												disableAutoSendForPreOrder:
													newValue,
											});
										}}
									/>
								</>
							)}
							<CheckBox
								label={
									t(
										`${translationPath}.modal.disableAutoSendForOnlineOrder`,
									) || ""
								}
								value={otherData.disableAutoSendForOnlineOrder}
								onChange={(newValue) => {
									updateAllData({
										...otherData,
										disableAutoSendForOnlineOrder: newValue,
									});
								}}
							/>
							<CheckBox
								label={
									t(
										`${translationPath}.modal.transliteration`,
									) || ""
								}
								value={otherData.isTransliteration}
								onChange={(newValue) => {
									updateAllData({
										...otherData,
										isTransliteration: newValue,
									});
								}}
							/>
						</Column>
						<Column
							gaps="14px*"
							style={{ flexGrow: 1, maxWidth: "58%" }}
						>
							{(allowAll || carWillBe) && (
								<Row gaps="20px" align="center">
									<Row align="center" gaps="10px*all">
										<CheckBox
											label={
												t(
													`${translationPath}.modal.isAutoSendDelay.main`,
												) || ""
											}
											value={otherData.isAutoSendDelay}
											onChange={(newValue) => {
												updateAllData({
													...otherData,
													isAutoSendDelay: newValue,
												});
											}}
										/>
										<Stepper
											width="60px"
											value={otherData.autoSendDelay}
											onChange={(newValue) => {
												updateAllData({
													...otherData,
													autoSendDelay:
														newValue as number,
												});
											}}
										/>
										<span>
											{t(
												`${translationPath}.modal.isAutoSendDelay.time`,
											)}
										</span>
									</Row>
									<CheckBox
										label={
											t(
												`${translationPath}.modal.excludeOrderChanges`,
											) || ""
										}
										value={otherData.excludeOrderChanges}
										onChange={(newValue) => {
											updateAllData({
												...otherData,
												excludeOrderChanges: newValue,
											});
										}}
									/>
								</Row>
							)}
							<Row align="center" gaps="10px*all">
								<CheckBox
									label={
										t(
											`${translationPath}.modal.isValidityPeriod.main`,
										) || ""
									}
									value={otherData.isValidityPeriod}
									onChange={(newValue) => {
										updateAllData({
											...otherData,
											isValidityPeriod: newValue,
										});
									}}
								/>
								<Stepper
									width="80px"
									value={otherData.validityPeriod}
									onChange={(newValue) => {
										updateAllData({
											...otherData,
											validityPeriod: newValue as number,
										});
									}}
								/>
								<span>
									{t(
										`${translationPath}.modal.isValidityPeriod.time`,
									)}
								</span>
							</Row>
							<Row align="center" gaps="10px*all">
								<CheckBox
									label={
										t(
											`${translationPath}.modal.isMaxSmsPartCount`,
										) || ""
									}
									value={otherData.isMaxSmsPartCount}
									onChange={(newValue) => {
										updateAllData({
											...otherData,
											isMaxSmsPartCount: newValue,
										});
									}}
								/>
								<Stepper
									value={otherData.maxSmsPartCount}
									onChange={(newValue) => {
										updateAllData({
											...otherData,
											maxSmsPartCount: newValue,
										});
									}}
								/>
							</Row>
							<Row align="center" gaps="10px*all">
								<CheckBox
									label={
										t(
											`${translationPath}.modal.minTimeoutSendingSms.main`,
										) || ""
									}
									value={otherData.isMinTimeoutSendingSms}
									onChange={(newValue) => {
										updateAllData({
											...otherData,
											isMinTimeoutSendingSms: newValue,
										});
									}}
								/>
								<Stepper
									width="80px"
									value={otherData.minTimeoutSendingSms}
									onChange={(newValue) => {
										updateAllData({
											...otherData,
											minTimeoutSendingSms:
												newValue as number,
										});
									}}
								/>
								<span>
									{t(
										`${translationPath}.modal.minTimeoutSendingSms.time`,
									)}
								</span>
							</Row>
						</Column>
					</Row>
				</Column>
			</Root>
		);
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		register: UseFormRegister<any>;
	}
	interface Value extends CompaniesAndTaxiServices.Value {
		name: string;
		templateText: TemplateBody.Value;
		automaticSending: boolean;
		autoSendOrderChanges?: boolean;
		disableAutoSendForPreOrder: boolean;
		disableAutoSendForOnlineOrder: boolean;
		isAutoSendDelay: boolean;
		autoSendDelay: number;
		excludeOrderChanges: boolean;
		isValidityPeriod: boolean;
		validityPeriod: number;
		isTransliteration: boolean;
		isMaxSmsPartCount: boolean;
		maxSmsPartCount: number;
		isMinTimeoutSendingSms: boolean;
		minTimeoutSendingSms: number;
		lang: Language;
		readonly action: MessageTemplateActions;
		readonly type: MessageTemplateTypes;
		readonly isDefault: boolean;
	}
}

export default Content;
