import styled from "styled-components";

import LightTable from "../../../../../../../../../../../../components/LightTable";

const TableBase = styled(LightTable)`
	.rs-table-row:not(.rs-table-row-header) {
		&.selected {
			background-color: #f2faff;

			.rs-table-cell-content {
				background-color: #f2faff;
			}
		}
	}
`;

export default TableBase;
