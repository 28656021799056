import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	grid-area: info;

	padding: 8px 12px;

	background: white;

	box-shadow: 0px -3px 8px 0px #21333f1a;

	z-index: 1;
`;

export default Root;
