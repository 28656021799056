import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";

import CounterpartyCheckCard from "../components/CounterpartyCheckCardContent";

function counterpartyCheckCard({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CounterpartyCheckCard,
		(item) => <CounterpartyCheckCard item={item} />,
		{
			width,
			sortable: false,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default counterpartyCheckCard;
