import React, { Dispatch } from "react";
import { CheckBoxWithContent } from "uikit";
import Root from "./components/Root";

const Item: React.FC<Item.Props> = ({ value, label, onChange, active }) => (
	<Root
		id={label}
		aria-label={label}
		onClick={() => onChange(!value)}
		active={active}
	>
		<CheckBoxWithContent value={value} gap="10px" onChange={onChange}>
			{label}
		</CheckBoxWithContent>
	</Root>
);

declare namespace Item {
	type Value = boolean;

	interface Props {
		active?: boolean;
		value: Value;
		label: string;
		onChange: Dispatch<Value>;
	}
}

export default Item;
