/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	Key,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { Button, Column, Icon, Overlayer, theme } from "uikit";
import { LatLngTuple, Map as LeafletMap } from "leaflet";
import Control from "react-leaflet-custom-control";
import { clone, isNumber } from "lodash";

import TaxiService from "../../../../../../../../../../services/TaxiService";
import Language from "../../../../../../../../../../services/Language";
import Sector from "../../../../../../../../../../services/Sector";
import { numberTimeToMilliseconds } from "../../../../../../../../../../services/Sector/utils";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe";

import Map from "./components/Map";
import Footer from "./components/Footer";
import Root from "./components/Root";
import Shadow from "./components/Shadow";
import ButtonGroup from "./components/ButtonGroup";

const Editor: React.FC<Editor.Props> = ({
	value,

	language,
	editing,

	canCancel,
	canSave,

	onChange,
	onChangeEditing,

	onSave,
	onCancel,
}) => {
	const mapRef = useRef<LeafletMap | null>(null);
	const mapPolygonEditorRef = useRef<Map.PolygonEditor.Controller | null>(
		null,
	);

	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [footerValue, setFooterValue] = useState<Footer.Value>({
		showOnlySelectedTaxiServices: false,
	});

	const selectedItems = useMemo(
		() => value.filter((item) => item.selected),
		[value],
	);
	const selectedItemsWithVertices = useMemo(
		() => selectedItems.filter((item) => !!item.vertices),
		[selectedItems],
	);
	const selectedItem = useMemo(
		() => (selectedItems.length === 1 ? selectedItems[0] : undefined),
		[selectedItems],
	);

	const setSelectedItem = useCallback(
		(modified: Editor.Item) => {
			const newValue = clone(value);
			const index = newValue.findIndex(
				(item) => item.id === selectedItem!.id,
			);

			newValue[index] = modified;

			onChange(newValue);
		},
		[selectedItem, onChange, value],
	);

	const mapCenter = useMemo(() => {
		if (selectedItem) {
			const taxiService = taxiServices?.find(
				(taxiService) => taxiService.id === selectedItem.taxiServiceId,
			);

			if (taxiService) return taxiService.coordinates;
		}

		return [50.455002, 30.511284] as LatLngTuple;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServices]);

	const mapOnReady = useCallback(() => {
		if (
			!mapPolygonEditorRef.current ||
			selectedItemsWithVertices.length === 0
		)
			return;

		mapPolygonEditorRef.current.focus(
			selectedItemsWithVertices.map((item) => item.id),
		);
	}, [selectedItemsWithVertices]);

	const mapPolygonEditorEditing = useMemo(
		() => selectedItem?.creating || editing,
		[editing, selectedItem?.creating],
	);

	const canAddPolygon = useMemo(
		() => !!selectedItem && !selectedItem.vertices,
		[selectedItem],
	);

	const canEditPolygon = useMemo(
		() => !mapPolygonEditorEditing && !!selectedItem?.vertices,
		[mapPolygonEditorEditing, selectedItem?.vertices],
	);

	const canDeletePolygon = useMemo(
		() => !!selectedItem?.vertices,
		[selectedItem],
	);

	const mapPolygonEditorValue = useMemo<Map.PolygonEditor.Value>(
		() =>
			value
				.filter(
					(item) =>
						item.vertices &&
						(!footerValue.showOnlySelectedTaxiServices ||
							!item.disabled),
				)
				.map((item) => {
					const name = `<div>Название сектора: ${item.name?.[language]}</div>`;

					const maxSpeed =
						item.isMaxSpeedEnabled && item.maxSpeed
							? `<div>Ограничение скорости: ${item.maxSpeed} км/ч</div>`
							: `<div>Ограничение скорости: Выключено </div>`;

					const averageSpeedByTime = item.averageSpeed?.find(
						(item) => {
							const startTime = numberTimeToMilliseconds(
								item.start,
								false,
							);
							const endTime = numberTimeToMilliseconds(
								item.end,
								false,
							);
							const time = numberTimeToMilliseconds(
								Date.now(),
								true,
							);

							return time >= startTime && time <= endTime;
						},
					);

					const averageSpeedText = isNumber(averageSpeedByTime?.speed)
						? `${averageSpeedByTime?.speed} км/ч`
						: "Нет данных";

					const averageSpeed = item?.isAverageSpeedEnabled
						? `<div>Средняя скорость: ${averageSpeedText}</div>`
						: `<div>Средняя скорость: Выключено </div>`;

					return {
						id: item.id,
						name: `<div>
								${name}
								${maxSpeed}
								${averageSpeed}
								</div>`,
						vertices: item.vertices!,
						// eslint-disable-next-line no-nested-ternary
						color: item.disabled
							? theme.colors.secondary
							: // eslint-disable-next-line no-nested-ternary
							item.selected
							? "#43D881"
							: item.modified
							? "#F89C27"
							: undefined,
						creating: item.selected && selectedItem?.creating,
						disabled: item.disabled,
					};
				}),
		[
			footerValue.showOnlySelectedTaxiServices,
			language,
			selectedItem?.creating,
			value,
		],
	);

	const mapPolygonEditorOnChange = useCallback(
		(mapPolygonEditorValue: Map.PolygonEditor.Value) => {
			const newValue = clone(value);

			mapPolygonEditorValue.forEach((mapPolygonEditorItem) => {
				const index = newValue.findIndex(
					(item) => item.id === mapPolygonEditorItem.id,
				);

				if (index === -1) return;

				newValue[index] = clone(newValue[index]);

				newValue[index].vertices = mapPolygonEditorItem.vertices;
				newValue[index].creating =
					mapPolygonEditorItem.vertices.length < 3 ||
					(mapPolygonEditorItem.creating ?? false);
			});

			onChange(newValue);
		},
		[onChange, value],
	);

	const mapPolygonEditorOnChangeSelected = useCallback(
		(id?: Key) => {
			const newValue = value.map((item) =>
				item.id === id
					? { ...item, selected: true }
					: { ...item, selected: false },
			);

			onChange(newValue);
		},
		[onChange, value],
	);

	const mapPolygonEditorOnChangeEditing = useCallback(
		(editing: boolean) => {
			onChangeEditing(editing);
		},
		[onChangeEditing],
	);

	const addPolygonButtonIcon = useMemo(
		() => (
			<Icon
				id="plus"
				size={16}
				colors={[
					canAddPolygon
						? theme.colors.white
						: theme.colors.disabled_text,
				]}
			/>
		),
		[canAddPolygon],
	);

	const editPolygonButtonIcon = useMemo(
		() => (
			<Icon
				id="pencil"
				size={20}
				colors={[
					canEditPolygon
						? theme.colors.secondary
						: theme.colors.disabled_text,
				]}
			/>
		),
		[canEditPolygon],
	);

	const removePolygonButtonIcon = useMemo(
		() => (
			<Icon
				id="trash"
				size={20}
				colors={[
					canDeletePolygon
						? theme.colors.secondary
						: theme.colors.disabled_text,
				]}
			/>
		),
		[canDeletePolygon],
	);

	const addPolygonButtonOnClick = useCallback(() => {
		setSelectedItem({
			...selectedItem!,

			vertices: [],
			creating: true,
		});
	}, [selectedItem, setSelectedItem]);

	const editPolygonButtonOnClick = useCallback(() => {
		if (!editing) onChangeEditing(true);
	}, [editing, onChangeEditing]);

	const removePolygonButtonOnClick = useCallback(() => {
		setSelectedItem({
			...selectedItem!,

			vertices: undefined,
		});

		if (editing) onChangeEditing(false);
	}, [editing, onChangeEditing, selectedItem, setSelectedItem]);

	const controls = useMemo(
		() => (
			<Column gaps="8px*">
				<Button.Button
					style={{ boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)" }}
					icon={addPolygonButtonIcon}
					disabled={!canAddPolygon}
					onClick={addPolygonButtonOnClick}
				/>
				<ButtonGroup>
					<Button.Button
						variant="white"
						icon={editPolygonButtonIcon}
						disabled={!canEditPolygon}
						onClick={editPolygonButtonOnClick}
					/>
					<Button.Button
						variant="white"
						icon={removePolygonButtonIcon}
						disabled={!canDeletePolygon}
						onClick={removePolygonButtonOnClick}
					/>
				</ButtonGroup>
			</Column>
		),
		[
			addPolygonButtonIcon,
			addPolygonButtonOnClick,
			canAddPolygon,
			canDeletePolygon,
			canEditPolygon,
			editPolygonButtonIcon,
			editPolygonButtonOnClick,
			removePolygonButtonIcon,
			removePolygonButtonOnClick,
		],
	);

	return (
		<Overlayer overlay={<Shadow />}>
			<Root sizes="auto 1fr auto" maxedWidth maxedHeight>
				<Map
					ref={mapRef}
					center={mapCenter}
					zoom={11}
					maxZoom={19}
					onReady={mapOnReady}
				>
					<Map.PolygonEditor
						ref={mapPolygonEditorRef}
						value={mapPolygonEditorValue}
						selected={
							mapPolygonEditorEditing && selectedItem
								? selectedItem.id
								: undefined
						}
						editing={mapPolygonEditorEditing}
						onChange={mapPolygonEditorOnChange}
						onChangeSelected={mapPolygonEditorOnChangeSelected}
						onChangeEditing={mapPolygonEditorOnChangeEditing}
					/>
					<Control prepend position="topleft">
						{controls}
					</Control>
				</Map>
				<Footer
					value={footerValue}
					canCancel={canCancel}
					canSave={canSave}
					onChange={setFooterValue}
					onCancel={onCancel}
					onSave={onSave}
				/>
			</Root>
		</Overlayer>
	);
};

declare namespace Editor {
	interface Item {
		id: number;

		taxiServiceId: number;

		name: Record<Language, string>;
		vertices?: LatLngTuple[];
		isMaxSpeedEnabled: boolean;
		maxSpeed: number;

		selected: boolean;
		creating: boolean;
		modified: boolean;
		disabled: boolean;

		isAverageSpeedEnabled?: boolean;
		averageSpeed?: Sector.AverageSpeed[];
	}

	type Value = Item[];

	interface Props {
		value: Value;

		language: Language;
		editing: boolean;

		canCancel: boolean;
		canSave: boolean;

		onChange: Dispatch<Value>;
		onChangeEditing: Dispatch<boolean>;

		onSave: () => void;
		onCancel: () => void;
	}
}

export default Editor;
