/* eslint-disable no-shadow */
export enum MessageTemplateActions {
	WILL_BE_TIME = 101,
	OUTPUT_PASSENGERS = 102,
	LATE = 103,
	CAR_NOT_FOUND = 104,
	ORDER_SUCCESSFULLY_COMPLETED = 105,
	WITH_PASSENGERS = 106,
	DRIVER_SWITCHED = 107,
	DRIVER_REMOVED = 108,
	AT_ADDRESS = 109,
	ORDER_UPDATED = 110,

	ORDER_ASSIGMENT = 200,
	MANUAL_ORDER_OFFER = 201,
	AUTO_ORDER_OFFER = 202,
	ORDER_IS_PAID = 203,
	EXECUTOR_WITHDRAWN = 204,
	ORDER_TRANSFERRED = 205,
	OUTPUT_PASSENGERS_RESPONSE = 206,
	WITH_PASSENGERS_RESPONSE = 207,
	AUTO_PRE_ORDER_OFFER = 208,
	TRANSFER_RESPONSE = 209,
	LATE_RESPONSE = 210,
	ORDER_CLOSED_INFO = 211,
	ORDER_ACCEPTED_INFO = 212,

	VALIDATION_CODE = 301,
	UNKNOWN_ACTION = 302,
	PROBLEMS = 303,
	MANUAL_SENDING = 304,
}

export enum MessageTemplateTypes {
	TECHNICAL = "technical",
	EMPTY = "empty",
	CAR = "car",
	ORDER = "order",
	CUSTOMER = "customer",
	CUSTOMER_ORDER = "customerOrder",
	CAR_EXECUTOR = "carExecutor",
	CAR_ORDER = "carOrder",
	CAR_EXECUTOR_ORDER = "carExecutorOrder",
	CAR_EXECUTOR_ORDER_CUSTOMER = "carExecutorOrderCustomer",
}
