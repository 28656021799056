import React from "react";
import { useTranslation } from "react-i18next";
import { theme, Icon } from "uikit";

import { StyledRow, StyledP } from "../../../../../../../../common";

const Header: React.FC<Header.Props> = ({ onClose }) => {
	const { t } = useTranslation();

	return (
		<StyledRow alignItems="center" justify="space-between">
			<StyledP
				colors={theme.colors.text_placeholder}
				font={{
					fs: "normal",
					fw: 600,
					size: "18px",
					line: "22px",
				}}
				text={{ ls: "0.15px" }}
			>
				{t(
					"orderPageWidgets.chat.executorsFilterPanel.popover.header.str200",
				) ?? ""}
			</StyledP>
			<StyledRow onClick={onClose} cursor="pointer">
				<Icon id="close" size={12} colors={[theme.colors.secondary]} />
			</StyledRow>
		</StyledRow>
	);
};

declare namespace Header {
	interface Props {
		onClose: () => void;
	}
}

export default Header;
