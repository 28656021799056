export interface WebStream {
	write: (
		data: string | Buffer | Array<number>,
	) => Promise<WebStream.WriteResult>;
	end: () => Promise<WebStream.EndResult>;
}

export declare namespace WebStream {
	interface SuccessWriteResult {
		success: true;
	}

	interface FailureWriteResult {
		success: false;
		error: Error;
	}

	type WriteResult = SuccessWriteResult | FailureWriteResult;

	interface SuccessEndResult {
		success: true;
		data: any;
	}

	interface FailureEndResult {
		success: false;
		error: Error;
	}

	type EndResult = SuccessEndResult | FailureEndResult;
}

export default async function pipe(
	webStream: WebStream,
	localStream: ReadableStream,
) {
	const reader = localStream.getReader();

	// eslint-disable-next-line no-constant-condition
	while (true) {
		const { done, value } = await reader.read();

		if (done) break;

		const writeResult = await webStream.write(
			new TextDecoder().decode(value),
		);

		if (!writeResult.success) throw writeResult.error;
	}

	const endResult = await webStream.end();

	return endResult as any;
}
