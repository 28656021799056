import { Option } from "uikit";

import ExecutorRateDiscountPlan from "../../../../../../../../../../../../../../../../services/ExecutorRateDiscountPlan";
import CheckBoxSelect from "../../../../../../../../../../../../../../../../components/CheckBoxSelect";

export const periodUnitOptions: Option<ExecutorRateDiscountPlan.Model.Period.Type>[] =
	[
		{
			key: ExecutorRateDiscountPlan.Model.Period.Type.Daily,
			label: "Дней",
			value: ExecutorRateDiscountPlan.Model.Period.Type.Daily,
		},
		{
			key: ExecutorRateDiscountPlan.Model.Period.Type.Weekly,
			label: "Неделя",
			value: ExecutorRateDiscountPlan.Model.Period.Type.Weekly,
		},
		{
			key: ExecutorRateDiscountPlan.Model.Period.Type.Monthly,
			label: "Месяц",
			value: ExecutorRateDiscountPlan.Model.Period.Type.Monthly,
		},
	];

export const modeOptions: Option<ExecutorRateDiscountPlan.Model.Action>[] = [
	{
		key: ExecutorRateDiscountPlan.Model.Action.AfterOrderClosed,
		label: "После закрытия заказа",
		value: ExecutorRateDiscountPlan.Model.Action.AfterOrderClosed,
	},
	{
		key: ExecutorRateDiscountPlan.Model.Action.AfterOrderAssigned,
		label: "После постановки на заказ",
		value: ExecutorRateDiscountPlan.Model.Action.AfterOrderAssigned,
	},
	{
		key: ExecutorRateDiscountPlan.Model.Action.AfterPeriodEnd,
		label: "После окончания периода",
		value: ExecutorRateDiscountPlan.Model.Action.AfterPeriodEnd,
	},
];

export const feePeriodOptions: Option<"perDay" | "perPeriod" | "perShift">[] = [
	{
		key: "perDay",
		label: "За день",
		value: "perDay",
	},
	{
		key: "perPeriod",
		label: "За период",
		value: "perPeriod",
	},
	{
		key: "perShift",
		label: "За смену",
		value: "perShift",
	},
];

export const weekdayOptions: CheckBoxSelect.Option<
	| "monday"
	| "tuesday"
	| "wednesday"
	| "thursday"
	| "friday"
	| "saturday"
	| "sunday"
>[] = [
	{
		key: "monday",
		label: "Понедельник",
	},
	{
		key: "tuesday",
		label: "Вторник",
	},
	{
		key: "wednesday",
		label: "Среда",
	},
	{
		key: "thursday",
		label: "Четверг",
	},
	{
		key: "friday",
		label: "Пятница",
	},
	{
		key: "saturday",
		label: "Суббота",
	},
	{
		key: "sunday",
		label: "Воскресенье",
	},
];

export default {
	periodUnitOptions,
	modeOptions,
	feePeriodOptions,
	weekdayOptions,
};
