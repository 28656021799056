import React, { Dispatch, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { getTabsModeOne } from "../../../../../../../../../../../../../../../../../../utils";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { StyledGrid } from "../../../../../../../../../../../../../../../../../../components/common";
import Modal from "../../../../../../../../../../../../../../../../../../components/ModalController/Modal";

import Content from "./components/Content";
import Footer from "./components/Footer";

const EditModal: React.FC<EditModal.Props> = ({
	onCancel,
	onSave,
	value,
	onChange,
}) => {
	const { t } = useTranslation();

	const tabOptions = useMemo(() => {
		const selectedTabs: Parameters<typeof getTabsModeOne>[1] = [
			"main",
			"averageSpeed",
		];

		return getTabsModeOne(t, selectedTabs);
	}, [t]);

	const [type, setType] = useState<EditModal.Type>("main");

	const onSelectTab = useCallback(
		(option: Tabs.Option<any>) => setType(option.value),
		[setType],
	);

	const valueEditor = useObjectEditor(value, onChange);
	const averageSpeed = valueEditor.usePicker([
		"averageSpeed",
		"isMaxSpeedEnabled",
		"maxSpeed",
		"isAverageSpeedEnabled",
		"isBasicAverageSpeedEnabled",
		"basicAverageSpeed",
	]);
	const assigner = valueEditor.useAssigner();

	const content = useMemo(
		() => (
			<StyledGrid
				areas=""
				rows="50px 1fr 50px"
				w="800px"
				h="650px"
				br="5px"
				bgC="#ffffff"
				over="hidden"
			>
				<Tabs
					options={tabOptions}
					value={type}
					onChangeSelectedOption={onSelectTab}
					variant="panels"
				/>

				<Content type={type} value={averageSpeed} onChange={assigner} />

				<Footer onCancel={onCancel} onSave={onSave} />
			</StyledGrid>
		),
		[
			assigner,
			averageSpeed,
			onCancel,
			onSave,
			onSelectTab,
			tabOptions,
			type,
		],
	);

	return <Modal dimmer>{content}</Modal>;
};

declare namespace EditModal {
	type Type = "main" | "averageSpeed";

	type Value = Content.Value;

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		onCancel: () => void;
		onSave: () => void;
	}

	type Controller = any;
}

export default EditModal;
