import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	position: absolute;

	left: 75px;
	bottom: 50px;

	width: 320px;
	height: 308px;

	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 24px 2px;

	background-color: ${theme.colors.white};
	z-index: 1002;
`;

export default Root;
