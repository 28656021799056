import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CounterpartyCheckCardContent: React.FC<
	CounterpartyCheckCardContent.Props
> = ({ item }) => {
	const content = useMemo(() => {
		const payload = item?.checkCard?.code?.value;

		return payload || "";
	}, [item]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CounterpartyCheckCardContent {
	interface Props {
		item: Order.Model;
	}
}

export default CounterpartyCheckCardContent;
