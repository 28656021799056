import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";
import IgnoreOrders from "./IgnoreOrders";

interface Context {
	value: IgnoreOrders.Value;
	optionsLength: number;
	setTypesError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const optionsLength = this.context?.optionsLength;
		const currentTypesLength = this.context?.value.types.length;

		let isIgnoreOrdersTypesValid = true;
		if (optionsLength && currentTypesLength) {
			isIgnoreOrdersTypesValid = currentTypesLength < optionsLength;
			if (!isIgnoreOrdersTypesValid) this.context?.setTypesError(true);
		}

		return isIgnoreOrdersTypesValid;
	}
}
