export const getNumberIds = (arr: number[] | ["all"]): number[] => {
	const ids: number[] = [];

	arr.forEach((item: number | "all") => {
		if (item === "all" || ids.includes(item)) return;
		ids.push(item);
	});

	return ids;
};

export default getNumberIds;
