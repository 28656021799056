/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	Key,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Column, Icon, Overlayer, theme } from "uikit";
import { LatLngTuple, Map as LeafletMap } from "leaflet";
import Control from "react-leaflet-custom-control";
import { clone } from "lodash";

import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe";
import TaxiService from "../../../../../../../../../../services/TaxiService";
import Language from "../../../../../../../../../../services/Language";
import Sector from "../../../../../../../../../../services/Sector";

import Map from "./components/Map";
import Footer from "./components/Footer";
import Root from "./components/Root";
import Shadow from "./components/Shadow";
import ButtonGroup from "./components/ButtonGroup";

const Editor: React.FC<Editor.Props> = ({
	value,
	language,
	editing,
	sectors,
	canCancel,
	canSave,
	onChange,
	onChangeEditing,
	onSave,
	onCancel,
}) => {
	const { t } = useTranslation();

	const mapRef = useRef<LeafletMap | null>(null);
	const mapPolygonEditorRef = useRef<Map.PolygonEditor.Controller | null>(
		null,
	);

	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [showSectors, setShowSectors] = useState(false);

	const selectedItems = useMemo(
		() => value.items.filter((item) => item.selected),
		[value],
	);
	const selectedItemsWithVertices = useMemo(
		() => selectedItems.filter((item) => !!item.vertices),
		[selectedItems],
	);
	const selectedItem = useMemo(
		() => (selectedItems.length === 1 ? selectedItems[0] : undefined),
		[selectedItems],
	);

	const setSelectedItem = useCallback(
		(modified: Editor.Item) => {
			const newValue = clone(value);

			newValue.items = clone(newValue.items);

			const index = newValue.items.findIndex(
				(item) => item.id === selectedItem!.id,
			);

			newValue.items[index] = modified;

			onChange(newValue);
		},
		[selectedItem, onChange, value],
	);

	const footerValue = useMemo<Footer.Value>(
		() => ({
			showOnlyFiltered: value.showOnlyFiltered,
		}),
		[value.showOnlyFiltered],
	);

	const footerOnChange = useCallback(
		(footerValue: Footer.Value) =>
			onChange({
				...value,
				showOnlyFiltered: footerValue.showOnlyFiltered,
			}),
		[onChange, value],
	);

	const mapCenter = useMemo(() => {
		if (selectedItem) {
			const taxiService = taxiServices?.find(
				(taxiService) => taxiService.id === selectedItem.taxiServiceId,
			);

			if (taxiService) return taxiService.coordinates;
		}

		return [50.455002, 30.511284] as LatLngTuple;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServices]);

	const mapOnReady = useCallback(() => {
		if (
			!mapPolygonEditorRef.current ||
			selectedItemsWithVertices.length === 0
		)
			return;

		mapPolygonEditorRef.current.focus(
			selectedItemsWithVertices.map((item) => item.id),
		);
	}, [selectedItemsWithVertices]);

	const mapPolygonEditorEditing = useMemo(
		() => selectedItem?.creating || editing,
		[editing, selectedItem?.creating],
	);

	const canAddPolygon = useMemo(
		() => !!selectedItem && !selectedItem.vertices,
		[selectedItem],
	);

	const canEditPolygon = useMemo(
		() => !mapPolygonEditorEditing && !!selectedItem?.vertices,
		[mapPolygonEditorEditing, selectedItem?.vertices],
	);

	const canDeletePolygon = useMemo(
		() => !!selectedItem?.vertices,
		[selectedItem],
	);

	const mainMapPolygonEditorValue = useMemo<Map.PolygonEditor.Value>(
		() =>
			value.items
				.filter(
					(item) =>
						item.vertices &&
						(!footerValue.showOnlyFiltered || !item.disabled),
				)
				.map((item) => ({
					id: item.id,
					name: item.name[language],
					vertices: item.vertices!,
					// eslint-disable-next-line no-nested-ternary
					color: item.disabled
						? theme.colors.secondary
						: // eslint-disable-next-line no-nested-ternary
						item.selected
						? "#43D881"
						: item.modified
						? "#F89C27"
						: undefined,
					creating: item.selected && selectedItem?.creating,
					disabled: item.disabled,
				})),
		[footerValue.showOnlyFiltered, language, selectedItem?.creating, value],
	);

	const sectorsMapPolygonEditorValue = useMemo<
		Map.PolygonEditor.Value | undefined
	>(
		() =>
			sectors
				? sectors.map((sector) => ({
						id: `sector-${sector.id}`,
						name: sector.name[language],
						vertices: sector.vertices.map((vertex) => [
							vertex.lat,
							vertex.lng,
						]),
						color: theme.colors.disabled_text,

						creating: false,
						editable: false,
				  }))
				: undefined,
		[language, sectors],
	);

	const mapPolygonEditorValue = useMemo(
		() =>
			showSectors && sectorsMapPolygonEditorValue
				? [
						...sectorsMapPolygonEditorValue,
						...mainMapPolygonEditorValue,
				  ]
				: mainMapPolygonEditorValue,
		[mainMapPolygonEditorValue, sectorsMapPolygonEditorValue, showSectors],
	);

	const mapPolygonEditorOnChange = useCallback(
		(mapPolygonEditorValue: Map.PolygonEditor.Value) => {
			const newValue = clone(value);

			newValue.items = clone(newValue.items);

			mapPolygonEditorValue.forEach((mapPolygonEditorItem) => {
				const index = newValue.items.findIndex(
					(item) => item.id === mapPolygonEditorItem.id,
				);

				if (index === -1) return;

				newValue.items[index] = clone(newValue.items[index]);

				newValue.items[index].vertices = mapPolygonEditorItem.vertices;
				newValue.items[index].creating =
					mapPolygonEditorItem.vertices.length < 3 ||
					(mapPolygonEditorItem.creating ?? false);
			});

			onChange(newValue);
		},
		[onChange, value],
	);

	const mapPolygonEditorOnChangeSelected = useCallback(
		(id?: Key) => {
			const newValue = clone(value);

			newValue.items = value.items.map((item) =>
				item.id === id
					? { ...item, selected: true }
					: { ...item, selected: false },
			);

			onChange(newValue);
		},
		[onChange, value],
	);

	const mapPolygonEditorOnChangeEditing = useCallback(
		(editing: boolean) => {
			onChangeEditing(editing);
		},
		[onChangeEditing],
	);

	const addPolygonButtonIcon = useMemo(
		() => (
			<Icon
				id="plus"
				size={16}
				colors={[
					canAddPolygon
						? theme.colors.white
						: theme.colors.disabled_text,
				]}
			/>
		),
		[canAddPolygon],
	);

	const editPolygonButtonIcon = useMemo(
		() => (
			<Icon
				id="pencil"
				size={20}
				colors={[
					canEditPolygon
						? theme.colors.secondary
						: theme.colors.disabled_text,
				]}
			/>
		),
		[canEditPolygon],
	);

	const removePolygonButtonIcon = useMemo(
		() => (
			<Icon
				id="trash"
				size={20}
				colors={[
					canDeletePolygon
						? theme.colors.secondary
						: theme.colors.disabled_text,
				]}
			/>
		),
		[canDeletePolygon],
	);

	const addPolygonButtonOnClick = useCallback(() => {
		setSelectedItem({
			...selectedItem!,

			vertices: [],
			creating: true,
		});
	}, [selectedItem, setSelectedItem]);

	const editPolygonButtonOnClick = useCallback(() => {
		if (!editing) onChangeEditing(true);
	}, [editing, onChangeEditing]);

	const removePolygonButtonOnClick = useCallback(() => {
		setSelectedItem({
			...selectedItem!,

			vertices: undefined,
		});

		if (editing) onChangeEditing(false);
	}, [editing, onChangeEditing, selectedItem, setSelectedItem]);

	const polygonControls = useMemo(
		() => (
			<Column gaps="8px*">
				<Button.Button
					style={{ boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)" }}
					icon={addPolygonButtonIcon}
					disabled={!canAddPolygon}
					onClick={addPolygonButtonOnClick}
				/>
				<ButtonGroup>
					<Button.Button
						variant="white"
						icon={editPolygonButtonIcon}
						disabled={!canEditPolygon}
						onClick={editPolygonButtonOnClick}
					/>
					<Button.Button
						variant="white"
						icon={removePolygonButtonIcon}
						disabled={!canDeletePolygon}
						onClick={removePolygonButtonOnClick}
					/>
				</ButtonGroup>
			</Column>
		),
		[
			addPolygonButtonIcon,
			addPolygonButtonOnClick,
			canAddPolygon,
			canDeletePolygon,
			canEditPolygon,
			editPolygonButtonIcon,
			editPolygonButtonOnClick,
			removePolygonButtonIcon,
			removePolygonButtonOnClick,
		],
	);

	const showSectorsButtonIcon = useMemo(
		() => <Icon id="map" size={20} colors={["currentcolor"]} />,
		[],
	);

	const showSectorsButtonOnClick = useCallback(() => {
		setShowSectors(!showSectors);
	}, [showSectors]);

	const viewControls = useMemo(
		() => (
			<ButtonGroup>
				<Button.Button
					variant="white"
					icon={showSectorsButtonIcon}
					text={
						showSectors
							? t(
									"pages.preferencesPages.screenDirectory.parkings.content.editor.str200",
							  ) ?? ""
							: t(
									"pages.preferencesPages.screenDirectory.parkings.content.editor.str201",
							  ) ?? ""
					}
					onClick={showSectorsButtonOnClick}
				/>
			</ButtonGroup>
		),
		[showSectors, showSectorsButtonIcon, showSectorsButtonOnClick, t],
	);

	return (
		<Overlayer overlay={<Shadow />}>
			<Root sizes="auto 1fr auto" maxedWidth maxedHeight>
				<Map
					ref={mapRef}
					center={mapCenter}
					zoom={11}
					maxZoom={19}
					onReady={mapOnReady}
				>
					<Map.PolygonEditor
						ref={mapPolygonEditorRef}
						value={mapPolygonEditorValue}
						selected={
							mapPolygonEditorEditing && selectedItem
								? selectedItem.id
								: undefined
						}
						editing={mapPolygonEditorEditing}
						onChange={mapPolygonEditorOnChange}
						onChangeSelected={mapPolygonEditorOnChangeSelected}
						onChangeEditing={mapPolygonEditorOnChangeEditing}
					/>
					<Control prepend position="topleft">
						{polygonControls}
					</Control>
					<Control prepend position="topright">
						{viewControls}
					</Control>
				</Map>
				<Footer
					value={footerValue}
					canCancel={canCancel}
					canSave={canSave}
					onChange={footerOnChange}
					onCancel={onCancel}
					onSave={onSave}
				/>
			</Root>
		</Overlayer>
	);
};

declare namespace Editor {
	interface Item {
		id: number;

		taxiServiceId: number;

		name: Record<Language, string>;
		vertices?: LatLngTuple[];

		selected: boolean;
		creating: boolean;
		modified: boolean;
		disabled: boolean;
	}

	interface Value {
		items: Item[];

		showOnlyFiltered: boolean;
	}

	interface Props {
		value: Value;

		language: Language;
		editing: boolean;
		sectors?: Sector.Model[];

		canCancel: boolean;
		canSave: boolean;

		onChange: Dispatch<Value>;
		onChangeEditing: Dispatch<boolean>;

		onSave: () => void;
		onCancel: () => void;
	}
}

export default Editor;
