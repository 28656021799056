import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FromTo from "./FromTo";

const Range: React.FC<Range.Props> = memo(({ disabled, value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);
	const { t } = useTranslation();
	const from = valueEditor.useGetter("from");
	const setFrom = valueEditor.useSetter("from");

	const to = valueEditor.useGetter("to");
	const setTo = valueEditor.useSetter("to");

	return (
		<Row gaps="50px*" align="center">
			<FromTo
				disabled={disabled}
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.range.str0",
					) ?? ""
				}
				value={from}
				onChange={setFrom}
			/>
			<FromTo
				disabled={disabled}
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.range.str1",
					) ?? ""
				}
				value={to}
				onChange={setTo}
			/>
		</Row>
	);
});

declare namespace Range {
	interface Props {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		from: FromTo.Value;
		to: FromTo.Value;
	}
}

export default Range;
