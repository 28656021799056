import React from "react";
import { Nullable } from "uikit";
import Root from "./components/Root";
import Phone from "./components/Phone";
import { DispatcherPhone, ExecutorPhone } from "../../types";

interface Props {
	data: DispatcherPhone[] | ExecutorPhone[];

	selectedPhone: Nullable<DispatcherPhone | ExecutorPhone>;
	onSelectPhone: (phone: DispatcherPhone | ExecutorPhone) => void;
}

const PhoneList: React.FC<Props> = ({ data, onSelectPhone, selectedPhone }) => (
	<Root>
		{data.map((executor) => (
			<Phone
				key={executor.id}
				data={executor}
				onSelectPhone={onSelectPhone}
				selectedPhone={selectedPhone}
			/>
		))}
	</Root>
);

export default PhoneList;
