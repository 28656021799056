import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)`
	height: 36px;

	padding-inline: 4px;
`;

export default Root;
