import { combineReducers } from "@reduxjs/toolkit";

import {
	orderMapFromAddModalTogglesState,
	orderMapFromAddModalTogglesReducer,
	orderMapFromAddModalTogglesAction,
} from "./toggles";

export type StateMapFromAddModal = {
	toggles: orderMapFromAddModalTogglesState;
};

export const mapFromAddModal = combineReducers<StateMapFromAddModal>({
	toggles: orderMapFromAddModalTogglesReducer,
});

export const ordersMapFromAddModalAction = {
	toggles: orderMapFromAddModalTogglesAction,
};

export default mapFromAddModal;
