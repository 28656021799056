import React, {
	Dispatch,
	RefAttributes,
	useMemo,
	useRef,
	useState,
	memo,
} from "react";
import { react, useInternal } from "uikit";
import { uniq } from "lodash";

import {
	Language,
	languages,
	languagePackages,
} from "../../../assets/languages/langs";
import { useTypedSelector } from "../../../redux/store";
import { useAppContext } from "../../../context";
import Style, { StyledGrid, StyledGridItem } from "../styles";
import { TextWithInput } from "../TextWithInput";

import InternalController from "./Controller";

const NamesBase: React.FC<NamesBase.Props> = react.withController<
	NamesBase.PropsBase,
	NamesBase.Controller
>(
	({ value, placeholder, controller, onChange, require, ...props }) => {
		const { getTaxiServiceLanguages } = useAppContext();

		const defaultTaxiServiceId = useTypedSelector(
			(state) => state.session.defaultTaxiServiceId,
		);
		const defaultValue = useMemo<NamesBase.Value>(() => ({}), []);
		const rootRef = useRef<HTMLDivElement | null>(null);

		const languageExclude = useMemo<Language[]>(() => {
			if (!defaultTaxiServiceId) return [];
			const existLanguages =
				getTaxiServiceLanguages(defaultTaxiServiceId);
			return languages.filter((lang) => !existLanguages.includes(lang));
		}, [defaultTaxiServiceId, getTaxiServiceLanguages]);

		const [internalValue, setInternalValue] = useInternal(
			value ?? defaultValue,
		);

		const [error, setError] = useState(false);

		const languageList = useMemo(() => {
			if (!languages) return [];
			return uniq(languages)
				.filter((item) => !languageExclude.includes(item))
				.map((item) => ({
					lang: item,
					title: languagePackages?.[item]?.sortName || "",
				}));
		}, [languageExclude]);

		controller.setContext({
			rootRef,
			value,
			setError,
			languageExclude,
		});
		return (
			<StyledGrid areas="" ref={rootRef} gap="8px" {...props}>
				{languageList.map(({ lang, title }) => {
					const handleName = (value: string) => {
						setError(false);
						const newValue = {
							...internalValue,
							[lang]: value,
						};
						onChange?.(newValue);
						setInternalValue(newValue);
					};

					return (
						<StyledGridItem
							key={lang}
							area={props.areas ? lang : ""}
						>
							<TextWithInput
								value={internalValue[lang] || ""}
								setValue={handleName}
								title={title}
								placeholder={placeholder}
								error={error}
								setError={setError}
								require={require}
							/>
						</StyledGridItem>
					);
				})}
			</StyledGrid>
		);
	},

	InternalController,
);

export const Names = memo(NamesBase);

declare namespace NamesBase {
	type Ref = InternalController | null;
	type Controller = InternalController;
	type Value = Partial<Record<Language, string>>;

	interface PropsBase extends Partial<Style.Grid> {
		value?: Value;
		onChange?: Dispatch<Value>;

		require?: boolean;
		placeholder?: string | string[];
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default NamesBase;
