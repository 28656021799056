import styled from "styled-components";
import { Row } from "uikit";

const Header = styled(Row)`
	height: 40px;
	width: 100%;
	background: #ffffff;
	z-index: 9;
	padding: 4px 12px;
	box-shadow: 0px 2px 4px #4b576126;
`;

export default Header;
