/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { CheckBox, theme } from "uikit";
import PhonesColumn from "./components/PhonesColumn";
import Avatar from "./components/Avatar";

import { useTypedSelector } from "../../../../../../../../../redux/store";

import { StyledRow } from "../../../../../../../../common";
import {
	ChatDispatcherPhone,
	ChatExecutorPhone,
} from "../../../../../../types";

const PhoneNumber = styled.div`
	display: inline;
	height: 20px;
	width: 100%;

	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Name = styled.div`
	color: ${theme.colors.secondary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
`;

const Root = styled(StyledRow)<{ selected?: boolean; lightSelected?: boolean }>`
	padding: 8px 16px;

	width: 100%;
	min-height: 37px;
	max-height: 53px;

	justify-content: center;
	align-items: center;

	background-color: ${({ selected, lightSelected }) =>
		lightSelected ? "#e6e6e6" : selected ? "#f5f5f5" : theme.colors.white};
	border-bottom: 1px solid #f5f5f5;
	cursor: pointer;
`;

interface Props {
	data: ChatDispatcherPhone | ChatExecutorPhone;
	onSelectPhone: (userId: number) => void;
	userIndex: number;
	selectedUserIndex: number;
}

const Phone: React.FC<Props> = ({
	data,
	onSelectPhone,
	userIndex,
	selectedUserIndex,
}) => {
	const { booferSelectedUsers } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);

	const phoneText = useMemo(() => {
		const dispatcherPhone = data.phone;
		const availableSips = data?.dispatchersAvailableSips?.length
			? `sip: ${data.dispatchersAvailableSips.join(", ")}`
			: "";
		if (dispatcherPhone && !availableSips) {
			return dispatcherPhone;
		}
		if (!dispatcherPhone && availableSips) {
			return availableSips;
		}
		if (dispatcherPhone && availableSips) {
			return `${dispatcherPhone}, ${availableSips}`;
		}
		return null;
	}, [data.dispatchersAvailableSips, data.phone]);

	const selectPhone = useCallback(() => {
		onSelectPhone(data.id);
	}, [data.id, onSelectPhone]);

	const isUserSelected = useMemo(() => {
		const dispatcher = booferSelectedUsers.dispatchers.find(
			(dispatcher) =>
				dispatcher.id === data.id && dispatcher.alias === data.alias,
		);
		const executor = booferSelectedUsers.executors.find(
			(executor) =>
				executor.id === data.id && executor.alias === data.alias,
		);
		return dispatcher || executor;
	}, [
		booferSelectedUsers.dispatchers,
		booferSelectedUsers.executors,
		data.alias,
		data.id,
	]);

	return (
		<Root
			selected={!!isUserSelected}
			gap="12px*"
			onClick={selectPhone}
			lightSelected={selectedUserIndex === userIndex}
		>
			<CheckBox onChange={selectPhone} value={!!isUserSelected} />
			<Avatar data={data} />
			<PhonesColumn
				selected={!!isUserSelected}
				lightSelected={selectedUserIndex === userIndex}
			>
				{data.type === "dispatcher" && !!phoneText && (
					<PhoneNumber>{phoneText}</PhoneNumber>
				)}
				{data.type === "executor" && !!data.phone && (
					<PhoneNumber>{data.phone}</PhoneNumber>
				)}
				{data.name && (
					<Name>
						{data.name}
						{data.alias && `, ${data.alias}`}
					</Name>
				)}
			</PhonesColumn>
		</Root>
	);
};

export default Phone;
