import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { getOrderPassengerPoints } from "../../../../../../../redux/constants/OrdersPage/order";
import Language from "../../../../../../../services/Language";

const DestinationSectorCellContent: React.FC<
	DestinationSectorCellContent.Props
> = ({
	item,

	language,
}) => {
	const points = useMemo(() => getOrderPassengerPoints(item), [item]);

	const content = useMemo(
		() => points.at(-1)?.sector?.name?.[language] ?? "",
		[language, points],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace DestinationSectorCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default DestinationSectorCellContent;
