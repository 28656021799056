import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Column, Float } from "uikit";

import { useTypedSelector } from "../../redux/store";
import { SVGSourceGeneral } from "../../utils/generalSprite";
import RightMenu from "../../pages/MainPage/pages/Orders/components/RightMenu";
import CallsList from "../../pages/MainPage/pages/Orders/components/OrderRightMenu/components";
import ModalController from "../ModalController/Controller";

import Header from "./components/Header";

const StyledMainPage = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	overflow: hidden;

	.page {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		& > div {
			height: 100%;

			& > div > div {
				height: 100%;
			}
		}
	}

	.rightMenuContent {
		position: relative;
		width: 56px;
		height: 100%;
		border-left: 1px solid #dee0e2;

		background: white;

		z-index: 2000;
	}

	.softFon {
		position: fixed;
		right: 70px;
		bottom: 3px;
		display: flex;
		/* height: 100%; */
		width: 342px;
		flex-direction: column;
		/* justify-content: flex-end; */
		/* align-items: flex-end; */
		z-index: 1;
	}
`;

type Props = PropsWithChildren;

const MainLayout: React.FC<Props> = ({ children }) => {
	const { isOpen } = useTypedSelector((state) => state.softphoneWidget);
	return (
		<StyledMainPage>
			<SVGSourceGeneral />
			<div
				className="page"
				key="main-page"
				style={{
					position: "relative",
				}}
			>
				<Float.Container id="page-content">
					<Float.Tracker id="page-content">
						<Column sizes="auto! 1fr" maxedWidth maxedHeight>
							<Header />

							{children}
						</Column>
						<ModalController />
					</Float.Tracker>
				</Float.Container>
			</div>
			<div className="rightMenuContent">
				<RightMenu />
				{!isOpen && <CallsList />}
			</div>
		</StyledMainPage>
	);
};

export default MainLayout;
