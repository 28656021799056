import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { theme } from "uikit";

import {
	Style,
	baseStyles,
	distributeStyles,
	flexStyles,
	typographyStyles,
	visible,
} from "../../../styles";

const variants = {
	negative: css<Root.Variant.Props>``,
	white: css<Root.Variant.Props>``,
	transpiration: css<Root.Variant.Props>`
		width: 28px;
		height: 28px;
		color: #ffffff;

		background: transpiration;
		border: none;

		--icon-color-1: ${theme.colors.white};

		&:hover {
			&::before {
				content: "";

				position: absolute;

				width: 100%;
				height: 100%;

				background: transpiration;
			}
		}
	`,
	primary: css<Root.Variant.Props>`
		width: 28px;
		height: 28px;
		color: #ffffff;

		background: ${theme.colors.accent};

		--icon-color-1: ${theme.colors.white};

		&:hover {
			&::before {
				content: "";

				position: absolute;

				width: 100%;
				height: 100%;

				background: rgba(0, 0, 0, 0.05);
			}
		}
	`,
	secondary: css<Root.Variant.Props>`
		width: 28px;
		height: 28px;
		color: #21333f;

		background: ${theme.colors.button_secondary};

		--icon-color-1: ${({ active = false }) =>
			active ? theme.colors.accent : theme.colors.primary};

		&:hover {
			background: ${({ active = false }) =>
				active ? "#dddddd" : "#e5e5e5"};

			border: ${({ active = false }) =>
				active ? "none" : "1px solid #cfd1d2"};

			--icon-color-1: ${theme.colors.accent};
		}

		&:disabled {
			background: ${theme.colors.button_secondary};

			border: none;

			--icon-color-1: #b5c0c7;
		}
	`,
};

const Root = styled.button<Root.Props>`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	box-sizing: border-box;

	width: max-content;
	padding: 5px;

	font-family: Lato;
	font-size: 13px;
	font-weight: 600;
	line-height: 18px;

	border-radius: 5px;
	border: 1px solid transparent;
	cursor: pointer;

	overflow: hidden;

	${({ variant }) => variants[variant]}
	${baseStyles}
	${flexStyles}
	${distributeStyles}
	${typographyStyles}
	${visible}
`;

declare namespace Root {
	type Variant = keyof typeof variants;

	interface Props extends Variant.Props {
		variant: Variant;
	}

	namespace Variant {
		interface Props
			extends Style.ButtonType,
				PropsWithChildren,
				Omit<
					DetailedHTMLProps<
						HTMLAttributes<HTMLButtonElement>,
						HTMLButtonElement
					>,
					"ref"
				> {
			ref?:
				| ((instance: HTMLButtonElement | null) => void)
				| React.RefObject<HTMLButtonElement | null>
				| React.MutableRefObject<HTMLDivElement | null>
				| null
				| undefined;
			active?: boolean;
			onClick: (
				event?:
					| React.MouseEvent<HTMLButtonElement, MouseEvent>
					| KeyboardEvent,
			) => void;
		}
	}
}

export default Root;
