/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "uikit";

import LabeledField from "../../../../../../../../../../../../../components/LabeledField";
import PushNotice, {
	NoticeDestinationApp,
} from "../../../../../../../../../../../../../services/PushNotice";
import translationPath from "../../../../../../../../constants/translationPath";
import { MessageTemplateTypes } from "../../../../../../../../../../../../../types/MessageTemplateEnums";

import StyledRow from "./components/StyledRow";
import Param from "./components/Param";

const NotificationParams: React.FC<NotificationParams.Props> = memo(
	({ type, destinationApp }) => {
		const { t } = useTranslation();

		const [loading, setLoading] = useState(true);
		const [noticeParams, setNoticeParams] = useState<string[] | []>([]);

		useEffect(() => {
			setLoading(true);
			PushNotice.getParams({ destinationApp })
				.then((res) => {
					const allowedParams =
						res[type]?.createTemplateParam?.map((item) =>
							t(
								`${translationPath}.modal.templateBody.messageParams.${item}`,
							),
						) || [];

					allowedParams.push(
						t(`${translationPath}.modal.templateBody.number`),
						t(
							`${translationPath}.modal.templateBody.messageParams.#if`,
						),
						t(
							`${translationPath}.modal.templateBody.messageParams.isLess`,
						),
						t(
							`${translationPath}.modal.templateBody.messageParams.isGreater`,
						),
						t(
							`${translationPath}.modal.templateBody.messageParams.isEqual`,
						),
						t(
							`${translationPath}.modal.templateBody.messageParams./if`,
						),
					);
					setNoticeParams(allowedParams);
				})
				.finally(() => {
					setLoading(false);
				});
		}, [destinationApp, t, type]);

		const getParamStyle = (p) =>
			p ===
				(t(
					"pages.settings.pages.message.pushNotices.modal.content.templateBody.notifiactionParams.str0",
				) ?? "") ||
			p ===
				(t(
					"pages.settings.pages.message.pushNotices.modal.content.templateBody.notifiactionParams.str1",
				) ?? "") ||
			p ===
				(t(
					"pages.settings.pages.message.pushNotices.modal.content.templateBody.notifiactionParams.str2",
				) ?? "") ||
			p ===
				(t(
					"pages.settings.pages.message.pushNotices.modal.content.templateBody.notifiactionParams.str3",
				) ?? "") ||
			p === (t(`${translationPath}.modal.templateBody.number`) ?? "") ||
			p ===
				(t(
					"pages.settings.pages.message.pushNotices.modal.content.templateBody.notifiactionParams.str4",
				) ?? "")
				? { fontStyle: "italic" }
				: {};

		return (
			<LabeledField
				label={
					`${t(`${translationPath}.modal.templateBody.title`)}:` || ""
				}
			>
				<StyledRow wrap="wrap" align="center">
					{loading ? (
						<CircularProgress />
					) : noticeParams.length ? (
						noticeParams.map((param) => (
							<Param key={param} style={getParamStyle(param)}>
								{param}
							</Param>
						))
					) : (
						<span
							style={{
								fontStyle: "italic",
								padding: 15,
								flex: 1,
								textAlign: "center",
								backgroundColor: theme.colors.background_color,
								borderRadius: theme.borderRadius.m,
							}}
						>
							{t(
								`${translationPath}.modal.templateBody.notFound`,
							)}
						</span>
					)}
				</StyledRow>
			</LabeledField>
		);
	},
);

declare namespace NotificationParams {
	interface Props {
		type: MessageTemplateTypes;
		destinationApp: NoticeDestinationApp;
	}
}

export default NotificationParams;
