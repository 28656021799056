/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

// import * as ModelEvent from "@node-elion/syncron";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Base from "../Base";
import ExecutorRateClassifier from "../ExecutorRateClassifier";
import Language from "../Language";
// import SubscriptionBase from "../../types/Subscription";
// import SubscriptionPool from "../../redux/services/SubscriptionPool";

class ExecutorRateSubscription extends Base {
	static fromResponse(data: any): ExecutorRateSubscription.Model {
		const classifierFn = ({ classifier }: any) => {
			try {
				if (!classifier) return undefined;
				return ExecutorRateClassifier.fromResponse(data.classifier);
			} catch (error) {
				return undefined;
			}
		};
		const classifier = classifierFn(data);
		const classifierId = classifier ? classifier.id : undefined;

		return {
			id: data.id,

			classifierId,
			classifier,

			name: data.name,

			startDate: data.startDate,

			period: data.configuration?.period
				? {
						unit: data.configuration.period.unit,
						value: data.configuration.period.value,
				  }
				: undefined,

			fee: data.configuration?.fee
				? {
						value: data.configuration.fee.value,
				  }
				: undefined,

			accrual: data.configuration?.accrual || undefined,
			action: data.action,

			active: data.active,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model:
			| ExecutorRateSubscription.Model.New
			| ExecutorRateSubscription.Model.Modified,
	): any {
		return {
			classifierId: model.classifierId,

			name: model.name,
			active: model.active,
			action: model.action,
			startDate: model.startDate,

			configuration: {
				accrual: model.accrual ? model.accrual : undefined,

				period: model.period
					? {
							unit: model.period.unit,
							value: model.period.value,
					  }
					: undefined,

				fee: model.fee
					? {
							value: model.fee.value,
					  }
					: undefined,
			},
		};
	}
}

namespace ExecutorRateSubscription {
	export interface Model {
		id: number;

		classifierId?: number;
		classifier?: ExecutorRateClassifier.Model;

		name: Record<Language, string>;

		startDate: Date | string;

		period?: {
			unit: Model.PeriodUnit;
			value: number;
		};

		fee?: {
			value: number;
		};

		accrual?: {
			chargeOnOrderCompletion: boolean;
		};

		action: Model.Action;

		active: boolean;

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions
		extends Omit<
			ServiceSubscribeOptionsBase<ExecutorRateSubscription.Model>,
			"order"
		> {
		order?: Partial<Record<keyof Model, SortingOrder>>;
	}

	export namespace Model {
		export enum Action {
			AfterOrderClosed = "after_order_closed",
			AfterOrderAssigned = "after_order_assigned",
			AfterPeriodStart = "after_start_period",
			AfterPeriodEnd = "after_end_period",
			AfterShiftOpen = "after_shift_open",
			AfterShiftClose = "after_shift_close",
			AfterShiftPeriodClose = "after_shift_period_close",
		}

		export enum PeriodUnit {
			Daily = "daily",
			Shift = "shift",
			Weekly = "weekly",
			Monthly = "monthly",
		}

		export interface New {
			classifierId?: number;

			name: Record<Language, string>;

			startDate: Date | string;

			period?: {
				unit: Model.PeriodUnit;
				value: number;
			};

			fee?: {
				value: number;
			};

			accrual?: {
				chargeOnOrderCompletion: boolean;
			};

			action: Model.Action;

			active: boolean;
		}
		export type Modified = Partial<New> & { id: number };
	}
}

export default ExecutorRateSubscription;
