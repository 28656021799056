import React, { memo } from "react";
import { Row } from "uikit";
import ValidityTime from "../../../../../Modal/components/Content/components/ValidityTime";

const ValidityTimeOutput: React.FC<ValidityTimeOutput.Props> = memo(
	({ value }) =>
		value.active ? (
			<>
				<Row gaps="3px*">
					<div>{new Date(value.from).toLocaleTimeString()} –</div>
					<div>{new Date(value.to).toLocaleTimeString()}</div>
				</Row>
				<span>/</span>
			</>
		) : null,
);

declare namespace ValidityTimeOutput {
	interface Props {
		value: ValidityTime.Value;
	}
}

export default ValidityTimeOutput;
