import React, { memo } from "react";
import { Row, CheckBox } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../../../../../../../hooks";
import ValueStepper from "../../../../../../../../../../../../../ValueStepper";
import tPath from "../../../../../../constants/tPath";

const MinOrderCost: React.FC<MinOrderCost.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();
		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const orderCost = valueEditor.useGetter("value");
		const setOrderCost = valueEditor.useSetter("value");

		return (
			<Row align="center" gaps="10px*">
				<CheckBox
					label={t(`${tPath}.modal.minOrderCost.title`) || ""}
					value={active}
					onChange={setActive}
				/>
				<ValueStepper value={orderCost} onChange={setOrderCost} />
				{currencyGlobalSettings}
			</Row>
		);
	},
);

declare namespace MinOrderCost {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
	}
}

export default MinOrderCost;
