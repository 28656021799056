import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getColumnsModeTwo } from "../../../../../../../../../../utils";
import LightTable from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import PassengersSurcharge from "../..";

import Root from "./components/Root";
import IntervalValue from "./components/IntervalValue";

const Content: React.FC<Content.Props> = ({
	intervals,
	selected,
	setSelected,
	onEdit,
}) => {
	const { t } = useTranslation();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => ["count", "value"],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const [columnsId, setColumnsId] = useState(defaultColumnIds);

	const updateSelected = useCallback(
		(index: number) => {
			if (selected.includes(index)) {
				setSelected(selected.filter((value) => index !== value));
			} else {
				const newSelected = [...selected];
				newSelected.push(index);
				setSelected(newSelected);
			}
		},
		[setSelected, selected],
	);

	const data = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						flexGrow={columnId === "value" ? 1 : 0}
						width={200}
						sortable={columnId === "count"}
					>
						<LightTable.HeaderCell verticalAlign="middle">
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell>
							{(rowData) => {
								const model =
									rowData as PassengersSurcharge.Interval;

								switch (columnId) {
									case "count":
										return model.count;

									case "value":
										return <IntervalValue value={model} />;

									default:
										return "";
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columns, columnsId],
	);

	return (
		<Root sizes="1fr auto!">
			<div>
				<LightTable
					data={intervals}
					virtualized
					headerHeight={44}
					rowHeight={44}
					fillHeight
					shouldUpdateScroll={true}
					style={{ width: "100%" }}
					rowClassName={(rowData) =>
						rowData && selected.includes(intervals.indexOf(rowData))
							? "selected"
							: ""
					}
					onRowClick={(intvl) => {
						updateSelected(intervals.indexOf(intvl));
					}}
					onRowDoubleClick={(intvl) => {
						onEdit && onEdit(intervals.indexOf(intvl));
					}}
				>
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
					{data}
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
				</LightTable>
			</div>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		intervals: PassengersSurcharge.Interval[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: (x: number) => void;
	}

	interface ColumnType {
		id: string;
		label: string;
	}
}

export default Content;
