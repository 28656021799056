import { useCallback, useMemo } from "react";
import { LatLngExpression } from "leaflet";

import {
	ordersAction as orders,
	Orders,
} from "../../../../redux/reducers/Orders";
import { useTypedDispatch } from "../../../../redux/store";

const useMapActions = (): useMapActions.Props => {
	const dispatch = useTypedDispatch();

	const setMapWidgetOrderTabExecutorFilters = useCallback(
		(
			tab: Orders.Map.OrderTab,
			filters: Orders.Map.DefaultExecutorsFilters,
		) => {
			dispatch(
				orders.map.filter.setMapWidgetOrderTabExecutorFilters({
					tab,
					filters,
				}),
			);
		},
		[dispatch],
	);

	const setMapWidgetCenter = useCallback(
		(center: LatLngExpression) => {
			if (Array.isArray(center)) {
				dispatch(
					orders.map.filter.setMapWidgetCenter({
						lat: center[0],
						lng: center[1],
					}),
				);
			} else {
				dispatch(orders.map.filter.setMapWidgetCenter(center));
			}
		},
		[dispatch],
	);

	const setMapWidgetZoom = useCallback(
		(zoom: number) => {
			dispatch(orders.map.filter.setMapWidgetZoom(zoom));
		},
		[dispatch],
	);

	const setAll = useCallback(
		(value: Orders.Map.MapFilterAndSettings) => {
			dispatch(orders.map.filter.setAll(value));
		},
		[dispatch],
	);

	const setALLBtnToggles = useCallback(
		(value: Orders.Map.BtnOnMapToggles) => {
			dispatch(orders.map.toggles.toggleAllButtonsOnMap(value));
		},
		[dispatch],
	);

	return useMemo(
		() => ({
			setMapWidgetOrderTabExecutorFilters,
			setMapWidgetCenter,
			setMapWidgetZoom,
			setAll,
			setALLBtnToggles,
		}),
		[
			setMapWidgetOrderTabExecutorFilters,
			setMapWidgetCenter,
			setMapWidgetZoom,
			setAll,
			setALLBtnToggles,
		],
	);
};

declare namespace useMapActions {
	interface Props {
		setMapWidgetOrderTabExecutorFilters: (
			tab: Orders.Map.OrderTab,
			filters: Orders.Map.DefaultExecutorsFilters,
		) => void;
		setMapWidgetCenter: (center: LatLngExpression) => void;
		setMapWidgetZoom: (zoom: number) => void;
		setAll: (value: Orders.Map.MapFilterAndSettings) => void;
		setALLBtnToggles: (value: Orders.Map.BtnOnMapToggles) => void;
	}
}

export default useMapActions;
