import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Preferences } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	models: [],
	carClasses: [],
	carClass: null,
};

const setModels: ReduxReducer<State, State["models"]> = (
	state,
	{ payload },
) => {
	state.models = payload;
};

const setCarClasses: ReduxReducer<State, State["carClasses"]> = (
	state,
	{ payload },
) => {
	state.carClasses = payload;
};

const setCarClass: ReduxReducer<State, State["carClass"]> = (
	state,
	{ payload },
) => {
	state.carClass = payload;
};

const updateCarClass: ReduxReducer<State, State["carClass"]> = (
	state,
	{ payload },
) => {
	if (payload) {
		state.carClasses = [...state.carClasses].map((item) => {
			if (item.id === payload.id) return payload;
			return item;
		});
	}
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		if (key === "models") return;
		state[key] = initialState[key];
	});
};

const data = createSlice({
	name: Preferences.ConstantPreferencesSlice.CAR_CLASSES_DATA,
	initialState,
	reducers: {
		reset,
		setModels,
		setCarClasses,
		setCarClass,
		updateCarClass,
	},
});

export const dataAction = data.actions;
export const dataReducer = data.reducer;
export type dataState = State;

export default data;
