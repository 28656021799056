import React from "react";
import { Icon, theme } from "uikit";
import Root from "./components/Root";
import Title from "./components/Title";
import CloseButton from "./components/CloseButton";

const Header: React.FC<Header.Props> = ({ title, onClose }) => (
	<Root
		className="draggable-area"
		sizes="auto! 1fr auto!"
		gaps="7px*"
		align="center"
		maxedWidth
	>
		<Icon id="dragging" size={12} colors={[theme.colors.disabled_text]} />
		<Title>{title ?? ""}</Title>
		<CloseButton onClick={onClose} />
	</Root>
);

declare namespace Header {
	interface Props {
		title?: string;

		onClose: () => void;
	}
}

export default Header;
