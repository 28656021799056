import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { Button, CheckBoxWithContent, Row } from "uikit";
import Root from "./components/Root";

const Footer: React.FC<Footer.Props> = ({
	value,
	onChange,
	onCancel,
	onSave,
}) => {
	const { t } = useTranslation();
	return (
		<Root gaps="10px" align="center" justify="space-between">
			<Row gaps="24px*">
				<CheckBoxWithContent
					gap="10px"
					value={value.active}
					onChange={(active) => onChange({ ...value, active })}
				>
					{t(
						"pages.preferencesPages.screenDirectory.sectors.editModal.footer.str130",
					) ?? ""}
				</CheckBoxWithContent>
			</Row>
			<Row gaps="16px">
				<Button.Button
					style={{ padding: "9px 25px", height: "36px" }}
					variant="secondary"
					text={
						t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.footer.str0",
						) ?? ""
					}
					onClick={onCancel}
				/>
				<Button.Button
					style={{ padding: "9px 25px", height: "36px" }}
					text={
						t(
							"pages.preferencesPages.screenDirectory.sectors.editModal.footer.str1",
						) ?? ""
					}
					onClick={onSave}
				/>
			</Row>
		</Root>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;

		onCancel: () => void;
		onSave: () => void;
	}
}

export default Footer;
