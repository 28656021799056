import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, RadioList2, Row, TextBox } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledBlock from "../../../../../../../../../../../../components/LabeledBlock";

import Root from "./components/Root";
import RadioButtonLabel from "./components/RadioButtonLabel";

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const typeValue = valueEditor.useGetter("type");
	const typeOnChange = valueEditor.useSetter("type");

	const numberValue = valueEditor.useGetter("number");
	const numberOnChange = valueEditor.useSetter("number");

	const typeOptions: RadioList2.Option<"alias" | "garage" | "state">[] =
		useMemo(
			() => [
				{
					key: "alias",
					value: "alias",
					content:
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str200",
						) ?? "",
				},
				{
					key: "garage",
					value: "garage",
					content:
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str201",
						) ?? "",
				},
				{
					key: "state",
					value: "state",
					content:
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str202",
						) ?? "",
				},
			],
			[t],
		);

	return (
		<Root gaps="16px*">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str0",
					) ?? ""
				}
			>
				<Column gaps="16px*">
					<RadioList2
						options={typeOptions}
						autoFocus
						value={typeValue}
						onChange={typeOnChange}
					>
						{(radios) => (
							<Row align="center" gaps="32px*" sizes="1fr*">
								{radios.map((radio, index) => (
									<RadioButtonLabel key={index}>
										{radio}
									</RadioButtonLabel>
								))}
							</Row>
						)}
					</RadioList2>

					<TextBox.TextBox
						value={numberValue}
						placeholder={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByExecutorModal.content.str1",
							) ?? ""
						}
						onChange={numberOnChange}
					/>
				</Column>
			</LabeledBlock>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		type: "alias" | "garage" | "state";
		number: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
