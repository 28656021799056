/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import DistanceCalcMethod from "../../../../../types/DistanceCalcMethod";
import {
	DataKind,
	EntryList,
	BooleanEntry,
	OrderSelectEntry,
	ToggleGroupEntry,
	EntryGroup,
} from "../../../../../types/settingEntries";

export type ExecutorAppInterfaceGeneralSelectTypes = DistanceCalcMethod;
export type ExecutorAppInterfaceGeneralMultiSelectTypes = never;

export interface BaseExecutorAppInterfaceGeneralEntries<Data extends DataKind> {
	displayLiveTab: BooleanEntry<Data>;
	separatePreliminaryOrders: BooleanEntry<Data>;

	menuItemsLayout: OrderSelectEntry<Data>;
	freeStatus: BooleanEntry<Data>;
	danger: BooleanEntry<Data>;

	filter: ToggleGroupEntry<Data>;
	sort: ToggleGroupEntry<Data>;
	parking: ToggleGroupEntry<Data>;
	call: ToggleGroupEntry<Data>;
	currentBalance: ToggleGroupEntry<Data>;
	income: ToggleGroupEntry<Data>;
	taximeter: ToggleGroupEntry<Data>;
	ownOrder: ToggleGroupEntry<Data>;
	rating: ToggleGroupEntry<Data>;
	chat: ToggleGroupEntry<Data>;
}

export type ExecutorAppInterfaceGeneralEntries<Data extends DataKind> =
	BaseExecutorAppInterfaceGeneralEntries<Data> &
		EntryList<
			Data,
			ExecutorAppInterfaceGeneralSelectTypes,
			ExecutorAppInterfaceGeneralMultiSelectTypes
		>;
export type ExecutorAppInterfaceGeneralMeta =
	ExecutorAppInterfaceGeneralEntries<"meta">;
export type ExecutorAppInterfaceGeneralState =
	ExecutorAppInterfaceGeneralEntries<"value">;
export type ExecutorAppInterfaceGeneralValue =
	ExecutorAppInterfaceGeneralEntries<any>[keyof ExecutorAppInterfaceGeneralEntries<any>];

const toggle: BooleanEntry<"meta"> = {
	type: "boolean",
	style: "toggle",
};

export const executorAppInterfaceGeneralMeta: ExecutorAppInterfaceGeneralMeta =
	{
		displayLiveTab: toggle,
		separatePreliminaryOrders: toggle,

		menuItemsLayout: {
			type: "orderselect",
			options: [
				{
					key: "main",
					label: "settings.executorApp.interface_general.menuLayoutItems.main",
					value: "main",
				},
				{
					key: "orders",
					label: "settings.executorApp.interface_general.menuLayoutItems.orders",
					value: "orders",
				},
				{
					key: "map",
					label: "settings.executorApp.interface_general.menuLayoutItems.map",
					value: "map",
				},
				{
					key: "settings",
					label: "settings.executorApp.interface_general.menuLayoutItems.settings",
					value: "settings",
				},
				{
					key: "chat",
					label: "settings.executorApp.interface_general.menuLayoutItems.chat",
					value: "chat",
				},
			],
		},
		freeStatus: toggle,
		danger: toggle,

		filter: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.filter.title", // Order filter - on / off
				display: {
					displayPickupSector:
						"settings.executorApp.interface_general.toggleGroups.filter.pickupSector", // Pickup sector
					displayDestinationSector:
						"settings.executorApp.interface_general.toggleGroups.filter.destinationSector", // Destination sector
					displayRadius:
						"settings.executorApp.interface_general.toggleGroups.filter.radius", // Radius
					displayOnlyOwnOrders:
						"settings.executorApp.interface_general.toggleGroups.filter.onlyOwnOrders", // Only own orders
					displayCost:
						"settings.executorApp.interface_general.toggleGroups.filter.cost", // Order cost
					displayCostPerKm:
						"settings.executorApp.interface_general.toggleGroups.filter.costPerKm", // Cost per kilometer
					displayRouteLength:
						"settings.executorApp.interface_general.toggleGroups.filter.routeLength", // Route length
					displayDistanceToPickup:
						"settings.executorApp.interface_general.toggleGroups.filter.distanceToPickup", // Distance to pickup
				},
			},
		},

		sort: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.sort.title", // Order sorting - on / off
				display: {
					displayAcceptDate:
						"settings.executorApp.interface_general.toggleGroups.sort.acceptDate", // Acceptance date/time
					displayOwnOrders:
						"settings.executorApp.interface_general.toggleGroups.sort.ownOrders", // Own orders
					displayCost:
						"settings.executorApp.interface_general.toggleGroups.sort.cost", // Order cost
					displayCostPerKm:
						"settings.executorApp.interface_general.toggleGroups.sort.costPerKm", // Cost per kilometer
					displayRouteLength:
						"settings.executorApp.interface_general.toggleGroups.sort.costPerKm", // Route length
				},
			},
		},

		parking: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.parking.title", // Parking in sector/lot - on / off
				display: {
					displayPark:
						"settings.executorApp.interface_general.toggleGroups.parking.park", // Park
					displayGetOutParking:
						"settings.executorApp.interface_general.toggleGroups.parking.getOutParking", // Leave parking
					displayGiveUpParking:
						"settings.executorApp.interface_general.toggleGroups.parking.giveUpParking", // Give up parking queue
				},
			},
		},

		call: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.call.title", // Communication
				display: {
					displayCallDispatcher:
						"settings.executorApp.interface_general.toggleGroups.call.callDispatcher", // Call dispatcher
					displayRequestCallback:
						"settings.executorApp.interface_general.toggleGroups.call.requestCallback", // Request callback
				},
			},
		},

		currentBalance: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.currentBalance.title", // Current balance
				display: {
					displayAllowTransactionsView:
						"settings.executorApp.interface_general.toggleGroups.currentBalance.allowTransactionsView", // Allow viewing transactions
				},
			},
		},

		income: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.income.title", // Income of the executor - on / off
				display: {
					displayTotal:
						"settings.executorApp.interface_general.toggleGroups.income.total", // Total income amount
					displayTotalOrders:
						"settings.executorApp.interface_general.toggleGroups.income.totalOrders", // Total orders
					displayCompleted:
						"settings.executorApp.interface_general.toggleGroups.income.completed", // Completed
					displayCash:
						"settings.executorApp.interface_general.toggleGroups.income.cash", // Cash
					displayNonCash:
						"settings.executorApp.interface_general.toggleGroups.income.nonCash", // Non-cash
					displayCard:
						"settings.executorApp.interface_general.toggleGroups.income.card", // Card payment
					displayCompensation:
						"settings.executorApp.interface_general.toggleGroups.income.compensation", // With compensation
					displayClientRefusal:
						"settings.executorApp.interface_general.toggleGroups.income.clientRefusal", // Client refusal
					displayDriverRefusal:
						"settings.executorApp.interface_general.toggleGroups.income.driverRefusal", // Driver refusal
					displayBalanceReplenishment:
						"settings.executorApp.interface_general.toggleGroups.income.balanceReplenishment", // Balance replenishment
					displayCommission:
						"settings.executorApp.interface_general.toggleGroups.income.commission", // Commission
					displaySubscriptionFee:
						"settings.executorApp.interface_general.toggleGroups.income.subscriptionFee", // Subscription fee
					displayAdditionalPayment:
						"settings.executorApp.interface_general.toggleGroups.income.additionalPayment", // Additional payment
					displayFines:
						"settings.executorApp.interface_general.toggleGroups.income.fines", // Fines
					displayBonuses:
						"settings.executorApp.interface_general.toggleGroups.income.bonuses", // Bonuses
				},
			},
		},

		taximeter: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.taximeter.title", // Taximeter - on / off
				display: {
					displayOrder:
						"settings.executorApp.interface_general.toggleGroups.taximeter.order", // By order
					displayOwnOrder:
						"settings.executorApp.interface_general.toggleGroups.taximeter.ownOrder", // Own order
				},
			},
		},

		ownOrder: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.ownOrder.title", // Own order - on / off
				display: {
					displayByPoints:
						"settings.executorApp.interface_general.toggleGroups.ownOrder.byPoints", // By points
					displayTotalCost:
						"settings.executorApp.interface_general.toggleGroups.ownOrder.totalCost", // Total cost
				},
			},
		},

		rating: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.rating.title", // Rating - on / off
				display: {
					displayAllowHistoryView:
						"settings.executorApp.interface_general.toggleGroups.rating.allowHistoryView", // Allow history view
				},
			},
		},

		chat: {
			type: "togglegroup",
			labels: {
				displaySelf:
					"settings.executorApp.interface_general.toggleGroups.chat.title", // Chat - on / off
				display: {
					displayDispatcher:
						"settings.executorApp.interface_general.toggleGroups.chat.dispatcher", // Dispatcher
					displayExecutors:
						"settings.executorApp.interface_general.toggleGroups.chat.executors", // Executors
					displayClients:
						"settings.executorApp.interface_general.toggleGroups.chat.clients", // Clients
				},
			},
		},
	};

const initialState: ExecutorAppInterfaceGeneralState = {
	displayLiveTab: true,
	separatePreliminaryOrders: true,
	menuItemsLayout:
		executorAppInterfaceGeneralMeta.menuItemsLayout.options.map(
			(item) => item.value,
		),
	freeStatus: true,
	danger: true,

	filter: {
		displaySelf: true, // Order filter - on / off
		display: {
			displayPickupSector: true, // Pickup sector
			displayDestinationSector: true, // Destination sector
			displayRadius: true, // Radius
			displayOnlyOwnOrders: true, // Only own orders
			displayCost: true, // Order cost
			displayCostPerKm: true, // Cost per kilometer
			displayRouteLength: true, // Route length
			displayDistanceToPickup: true, // Distance to pickup
		},
	},

	sort: {
		displaySelf: true, // Order sorting - on / off
		display: {
			displayAcceptDate: true, // Acceptance date/time
			displayOwnOrders: true, // Own orders
			displayCost: true, // Order cost
			displayCostPerKm: true, // Cost per kilometer
			displayRouteLength: true, // Route length
		},
	},

	parking: {
		displaySelf: true, // Parking in sector/lot - on / off
		display: {
			displayPark: true, // Park
			displayGetOutParking: true, // Leave parking
			displayGiveUpParking: true, // Give up parking queue
		},
	},

	call: {
		displaySelf: true, // Communication
		display: {
			displayCallDispatcher: true, // Call dispatcher
			displayRequestCallback: true, // Request callback
		},
	},

	currentBalance: {
		displaySelf: true, // Current balance
		display: {
			displayAllowTransactionsView: true, // Allow viewing transactions
		},
	},

	income: {
		displaySelf: true, // Driver's income - on / off
		display: {
			displayTotal: true, // Total income amount
			displayTotalOrders: true, // Total orders
			displayCompleted: true, // Completed
			displayCash: true, // Cash
			displayNonCash: true, // Non-cash
			displayCard: true, // Card payment
			displayCompensation: true, // With compensation
			displayClientRefusal: true, // Client refusal
			displayDriverRefusal: true, // Driver refusal
			displayBalanceReplenishment: true, // Balance replenishment
			displayCommission: true, // Commission
			displaySubscriptionFee: true, // Subscription fee
			displayAdditionalPayment: true, // Additional payment
			displayFines: true, // Fines
			displayBonuses: true, // Bonuses
		},
	},

	taximeter: {
		displaySelf: true, // Taximeter - on / off
		display: {
			displayOrder: true, // By order
			displayOwnOrder: true, // Own order
		},
	},

	ownOrder: {
		displaySelf: true, // Own order - on / off
		display: {
			displayByPoints: true, // By points
			displayTotalCost: true, // Total cost
		},
	},

	rating: {
		displaySelf: true, // Rating - on / off
		display: {
			displayAllowHistoryView: true, // Allow history view
		},
	},

	chat: {
		displaySelf: true, // Chat - on / off
		display: {
			displayDispatcher: true, // Dispatcher
			displayExecutors: true, // Executors
			displayClients: true, // Clients
		},
	},
} satisfies ExecutorAppInterfaceGeneralState;

export const executorAppInterfaceGeneralGroups: EntryGroup<
	BaseExecutorAppInterfaceGeneralEntries<any>
>[] = [
	{
		title: [],
		entryKeys: [
			"displayLiveTab",
			"separatePreliminaryOrders",
			"menuItemsLayout",
			"freeStatus",
			"danger",
		],
	},
	{ title: [], entryKeys: ["filter"] },
	{ title: [], entryKeys: ["sort"] },
	{ title: [], entryKeys: ["parking"] },
	{ title: [], entryKeys: ["call"] },
	{ title: [], entryKeys: ["currentBalance"] },
	{ title: [], entryKeys: ["income"] },
	{ title: [], entryKeys: ["taximeter"] },
	{ title: [], entryKeys: ["ownOrder"] },
	{ title: [], entryKeys: ["rating"] },
	{ title: [], entryKeys: ["chat"] },
];

type Reducer<P> = CaseReducer<
	ExecutorAppInterfaceGeneralState,
	PayloadAction<P>
>;

const setAll: Reducer<ExecutorAppInterfaceGeneralState> = (_, { payload }) =>
	payload;
const resetDefaults: Reducer<void> = () => initialState;

const modify: Reducer<Partial<ExecutorAppInterfaceGeneralState>> = (
	state,
	{ payload },
) => {
	Object.keys(payload).forEach((k) => {
		state[k] = payload[k];
	});
};

const executorAppInterfaceGeneral = createSlice({
	name: "executorAppInterfaceGeneral",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
		modify,
	},
});

export default executorAppInterfaceGeneral;
