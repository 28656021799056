import React, { memo, useMemo } from "react";
import { Map, theme } from "uikit";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-unresolved
import { PolygonList } from "uikit/dist/components/Map/components/PolygonEditor/type";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe";
import PriceZoneModel from "../../../../../services/PriceZone";

const BasePriceZones: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const priceZones = useModelSubscribe({}, PriceZoneModel);

	const localizedSectors = useMemo<PolygonList | undefined>(
		() =>
			priceZones?.cache.map((priceZone) => ({
				id: priceZone.id,
				name:
					`${t(
						`mainPage.archives.orders.modal.map.polygons.priceZone`,
					)} ${priceZone.name?.[lang]}` ||
					Object.values(priceZone.name)?.[0],
				color: theme.colors.success,
				vertices: priceZone.vertices.map((v) => [v.lat, v.lng]),
				editable: false,
			})),
		[lang, priceZones?.cache, t],
	);

	return <Map.PolygonEditor value={localizedSectors} editing={false} />;
};

export const PriceZones = memo(BasePriceZones);
export default BasePriceZones;
