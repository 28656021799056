/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import { useCallback, useMemo, useState } from "react";
import { Nullable } from "uikit";
import { isNumber } from "lodash";
import CarBrand from "../../../../../../../services/CarBrand";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import ModelList from "../components/ModelList";
import CarBaseTypeToCarBrand from "../../../../../../../services/CarBaseTypeToCarBrand";
import { SortingOrder } from "../../../../../../../types/SortingOrder";

function useCarBrandModelList(options: useCarBrandModelList.Options) {
	const [offset, setOffset] = useState(0);
	const [chunkSize, setChunkSize] = useState(30);
	const [selected, setSelected] = useState<Nullable<number>>(null);
	const [sort, setSort] = useState<SortingOrder>("ASC");

	const carBrandSubscribeOptions = useMemo<CarBrand.SubscribeOptions>(
		() => ({
			offset,
			limit: chunkSize,

			carBaseTypeIds: isNumber(options.selectedCarBaseType)
				? [options.selectedCarBaseType]
				: [],

			query: options.search,
			active: options.active,
			favorite: options.favorite,
			native: options.native,

			order: {
				name: sort,
			},
		}),
		[
			offset,
			chunkSize,
			options.selectedCarBaseType,
			options.search,
			options.active,
			options.favorite,
			options.native,
			sort,
		],
	);

	const carBrandData = useModelSubscribe(carBrandSubscribeOptions, CarBrand);

	const carBrandModels = useMemo(
		() => carBrandData.models ?? [],
		[carBrandData.models],
	);

	const carBrandTotal = useMemo(
		() => (carBrandData.metadataState.total ?? 0) as number,
		[carBrandData.metadataState.total],
	);

	const items = useMemo<ModelList.Item[]>(
		() =>
			carBrandModels.map((model) => {
				const carBaseTypeToCarBrand = isNumber(
					options.selectedCarBaseType,
				)
					? model.carBaseTypeToCarBrands?.find(
							(carBaseTypeToCarBrand) =>
								carBaseTypeToCarBrand.carBaseType?.id ===
								options.selectedCarBaseType,
					  )
					: undefined;

				return {
					id: model.id,

					disabled: !options.isSelectedCarBaseTypeActive,

					active: carBaseTypeToCarBrand?.active ?? false,
					// deletable: isBoolean(carBaseTypeToCarBrand?.native)
					// 	? !carBaseTypeToCarBrand!.native
					// 	: false,
					favorite: carBaseTypeToCarBrand?.favorite ?? false,
					label: model.name,
				};
			}),
		[
			carBrandModels,
			options.isSelectedCarBaseTypeActive,
			options.selectedCarBaseType,
		],
	);

	const selectedSubscribeOptions = useMemo<CarBrand.SubscribeOptions>(
		() => ({
			ids: isNumber(selected) ? [selected] : [],
		}),
		[selected],
	);

	const selectedData = useModelSubscribe(selectedSubscribeOptions, CarBrand);

	const selectedModels = useMemo(
		() => selectedData.models ?? [],
		[selectedData.models],
	);

	const selectedItem = useMemo(
		() =>
			isNumber(options.selectedCarBaseType)
				? selectedModels
						.at(0)
						?.carBaseTypeToCarBrands?.find(
							(carBaseTypeToCarBrand) =>
								carBaseTypeToCarBrand.carBaseType?.id ===
								options.selectedCarBaseType,
						)
				: undefined,
		[options.selectedCarBaseType, selectedModels],
	);

	const isSelectedActive = useMemo(
		() => selectedItem?.active ?? false,
		[selectedItem?.active],
	);

	const isSelectedNative = useMemo(
		() => selectedItem?.native ?? false,
		[selectedItem?.native],
	);

	const onChangeActive = useCallback(
		(id: number, active: boolean) => {
			if (isNumber(options.selectedCarBaseType))
				CarBaseTypeToCarBrand.update(options.selectedCarBaseType, id, {
					active,
				});
		},
		[options.selectedCarBaseType],
	);

	const onChangeFavorite = useCallback(
		(id: number, favorite: boolean) => {
			if (isNumber(options.selectedCarBaseType))
				CarBaseTypeToCarBrand.update(options.selectedCarBaseType, id, {
					favorite,
				});
		},
		[options.selectedCarBaseType],
	);

	const onDelete = useCallback(
		async (id: number) => {
			if (isNumber(options.selectedCarBaseType))
				await CarBaseTypeToCarBrand.destroy(
					options.selectedCarBaseType,
					id,
				);
		},
		[options.selectedCarBaseType],
	);

	return {
		offset,
		setOffset,

		chunkSize,
		setChunkSize,

		selected,
		setSelected,

		sort,
		setSort,

		data: carBrandData,
		models: carBrandModels,
		total: carBrandTotal,
		items,

		isSelectedActive,
		isSelectedNative,

		onChangeActive,
		onChangeFavorite,

		onDelete,
	};
}

declare namespace useCarBrandModelList {
	interface Options {
		search?: string;

		active?: boolean;
		favorite?: boolean;
		native?: boolean;

		isSelectedCarBaseTypeActive: boolean;

		selectedCarBaseType: Nullable<number>;
	}
}

export default useCarBrandModelList;
