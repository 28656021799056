import initialConstants from "../constants/initial.constants";

const initialState = {
	prpcow: null,
	activeSearchTypes: ["streets"],
};

export default function initialReducer(state = initialState, action: any = {}) {
	switch (action.type) {
		case initialConstants.prpcow:
			return {
				...state,
				prpcow: action.payload,
			};

		default:
			return state;
	}
}
