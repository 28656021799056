import { Option } from "uikit";

export type DisplayOrderPoints = "first" | "firstAndLast" | "all";

export const displayOrderPointsOptions: Option<DisplayOrderPoints>[] = [
	{
		key: "first",
		label: "settings.executorApp.interface_orders.order_points.first",
		value: "first",
	},
	{
		key: "first_and_last",
		label: "settings.executorApp.interface_orders.order_points.first_and_last",
		value: "firstAndLast",
	},
	{
		key: "all",
		label: "settings.executorApp.interface_orders.order_points.all",
		value: "all",
	},
];
