import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import AdditionalPhoneCellContent from "../components/AdditionalPhoneCellContent";

function additionalPhone({ width, onResize }: Column.Options) {
	return base(
		ColumnId.AdditionalPhone,
		(item) => <AdditionalPhoneCellContent item={item} />,
		{
			width,
			sortable: false,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default additionalPhone;
