import React, {
	Dispatch,
	RefAttributes,
	memo,
	// useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, react } from "uikit";

import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import Tariff from "../../../../../../../../../../../../../../services/Tariff";
// import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import {
	MultiSelectCarClassWithModal,
	// MultiSelectWithModal,
	// MultiSelectWithModalBase,
} from "../../../../../../../../../../../../../../components/common";

import InternalController from "./Controller";

/** @description use `MultiSelectCarClassWithModal` */
const AutoClassesBase = react.withController<
	AutoClasses.PropsBase,
	AutoClasses.Controller
>((props) => {
	const {
		// defaultTariffWithAllCarClassesAndOneBranch,
		// carClasses,
		value,
		onChange,
		disabled,
		controller,
		taxiServiceIds,
		// tariffs,
		// companyIds,
	} = props;
	const { t } = useTranslation();
	// const lang = useTypedSelector((state) => state.session.language);

	const [error, setError] = useState("");

	// const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
	// 	() =>
	// 		carClasses.map((item) => ({
	// 			key: item.id,
	// 			label: item.name[lang],
	// 			value: item.id,
	// 			name: item.name[lang],
	// 			data: item,
	// 			active: item.active,
	// 		})),
	// 	[carClasses, lang],
	// );

	// const selectOnChange = useCallback(
	// 	(newValue: MultiSelectWithModalBase.Value) => {
	// 		setError("");
	// 		const carClassIds = newValue.map((item) => item.value);
	// 		onChange(carClassIds);
	// 	},
	// 	[onChange],
	// );

	controller.setContext({
		value: {
			currentValue: value,
			// defaultTariffWithAllCarClassesAndOneBranch,
			// carClassesLength: carClasses.length,
			// taxiServiceIds,
			// tariffs,
			// companyIds,
		},
		setError,
	});

	const texts = useMemo(
		() => ({
			span:
				t(
					"pages.settings.pages.mobileApps.executor.modal.content.tabs.main.str208",
				) ?? "",
			title:
				t(
					"pages.settings.pages.mobileApps.executor.modal.content.tabs.main.str1",
				) ?? "",
			// allText:
			// 	t(
			// 		"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.mainTab.autoClasses.str202",
			// 	) ?? "",
		}),
		[t],
	);

	// const selected = useMemo<MultiSelectWithModalBase.Value>(
	// 	() =>
	// 		selectOptions.filter((item) =>
	// 			value.find((data) => data === item.value),
	// 		),

	// 	[selectOptions, value],
	// );

	return (
		<Row align="center" gaps="10px" sizes="auto 1fr">
			<span>{texts.span}</span>
			{/* <MultiSelectWithModal
				value={selected}
				disabled={disabled}
				error={error}
				title={texts.title}
				allText={texts.allText}
				options={selectOptions}
				onChange={selectOnChange}
				required
				pullUpItemInsideArray
			/> */}
			<MultiSelectCarClassWithModal
				value={value}
				disabled={disabled}
				error={error}
				onChange={onChange}
				titleText={texts.title}
				subscribeOptions={{ taxiServiceIds }}
				required
				showAllItem
			/>
		</Row>
	);
}, InternalController);

/** @description use `MultiSelectCarClassWithModal` */
const AutoClasses = memo(AutoClassesBase);

/** @description use `MultiSelectCarClassWithModal` */
declare namespace AutoClasses {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		defaultTariffWithAllCarClassesAndOneBranch?: Tariff.Model;
		value: Value;
		carClasses: CarClass.Model[];
		onChange: Dispatch<Value>;
		disabled?: boolean;
		taxiServiceIds: number[];
		tariffs: Tariff.Model[];
		companyIds: number[];
	}
	type Props = PropsBase & RefAttributes<Ref>;

	type Value = number[];
}

/** @description use `MultiSelectCarClassWithModal` */
export default AutoClasses;
