import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "uikit";
import { noop } from "lodash";

import Modal from "../Modal";
import KeyBind from "../KeyBind";
import { Style, StyledGrid, StyledGridItem, StyledRow } from "../common";

const ModalMessage = styled.div`
	text-align: center;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
`;

const DeleteModal: React.FC<DeleteModal.Props> = ({
	disabled = false,
	label,
	isConfirm = true,
	isCancel = true,
	confirmButtonLabel,
	onCancel,
	onConfirm,
	...props
}) => {
	const { t } = useTranslation();

	const _label = label ?? t("deleteModal.str200") ?? "";
	const _confirmButtonLabel =
		confirmButtonLabel ?? t("deleteModal.str201") ?? "";

	const content = useMemo(() => {
		if (typeof _label !== "string") return _label;
		return <ModalMessage>{_label}</ModalMessage>;
	}, [_label]);

	return (
		<Modal dimmed>
			<KeyBind keys={["Escape"]} onUse={onCancel} />
			{isConfirm && !disabled ? (
				<KeyBind keys={["Enter"]} onUse={onConfirm} />
			) : null}
			<StyledGrid
				w="320px"
				br="5px"
				bgC="#ffffff"
				gap="20px"
				rows="1fr 50px"
				{...props}
				areas="'content' 'footer'"
			>
				<StyledGridItem area="content" p="20px 20px 0 20px">
					{content}
				</StyledGridItem>
				<StyledGridItem
					area="footer"
					tabIndex={0}
					justify="center"
					alignSelf="center"
					p="0 20px"
				>
					<StyledRow gap="10px" tabIndex={0}>
						{isCancel && (
							<Button.Button
								variant="secondary"
								text={t("deleteModal.str1") ?? ""}
								onClick={onCancel}
							/>
						)}
						{isConfirm && (
							<Button.Button
								disabled={disabled}
								text={_confirmButtonLabel}
								onClick={disabled ? noop : onConfirm}
							/>
						)}
					</StyledRow>
				</StyledGridItem>
			</StyledGrid>
		</Modal>
	);
};

declare namespace DeleteModal {
	interface Props extends Partial<Style.GridType> {
		disabled?: boolean;
		label?: string | React.ReactNode;
		isConfirm?: boolean;
		isCancel?: boolean;
		confirmButtonLabel?: string;
		onCancel: () => void;
		onConfirm: () => void;
	}
}

export default DeleteModal;
