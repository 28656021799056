/* eslint-disable no-shadow */
import getOptions from "../utils/generateOptions";

export enum OrderCreatedSource {
	APP = "app",
	PHONE = "phone",
	WEBSITE = "website",
	EXECUTOR = "executor",
	DISPATCHER = "dispatcher",
}

export const orderSourceOptions = getOptions(Object.values(OrderCreatedSource));
