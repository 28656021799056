import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ClickAwayListener } from "@mui/material";
import styled from "styled-components";
import { Column, theme } from "uikit";

import {
	ordersAction as orders,
	Orders,
} from "../../../../../../../redux/reducers/Orders";
import { gray } from "../../../../../../../constants/styles/colors";

const Root = styled(Column)<ContextMenu.Props["coordinate"]>`
	position: absolute;
	width: 264px;
	height: 144px;
	border-radius: 12px;
	background-color: ${theme.colors.white};
	box-shadow: 0px 9px 18px 0px #00000029;
	top: ${({ y = 0 }) => `${y}px`};
	${({ isCurrentUser = false, x = 0 }) =>
		isCurrentUser
			? `right: ${window.innerWidth - (x || 0) - 60}px`
			: `left: ${x}px`};
	z-index: 1000;
	& > button:nth-child(1) {
		border-radius: 12px 12px 0px 0px;
	}
	& > button:nth-child(3) {
		border-radius: 0px 0px 12px 12px;
	}
`;
const Button = styled.button<{ disabled?: boolean }>`
	padding: 12px 16px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: ${(props) => (props.disabled ? gray[5] : theme.colors.primary)};
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	&:hover {
		color: ${(props) => (props.disabled ? gray[5] : theme.colors.primary)};
		background-color: #f2f4f7;
	}
`;

const ContextMenu: React.FC<ContextMenu.Props> = ({
	booferAnswerParentID,
	coordinate,
	onClose,
}) => {
	const dispatch = useDispatch();

	const handleClickAway = useCallback(() => {
		onClose();
	}, [onClose]);

	const onClickAnswer = () => {
		// console.log("chat ContextMenu: ", booferAnswerParentID);
		dispatch(orders.chats.chat.setAnswerParent(booferAnswerParentID));
		onClose();
	};

	const { t } = useTranslation();
	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Root
				x={coordinate.x}
				y={coordinate.y}
				isCurrentUser={coordinate.isCurrentUser}
			>
				<Button onClick={onClickAnswer}>
					{t("orderPageWidgets.chat.chatList.contextMenu.str100") ??
						""}
				</Button>
				<Button disabled={true} onClick={onClose}>
					{t("orderPageWidgets.chat.chatList.contextMenu.str150") ??
						""}
				</Button>
				<Button disabled={true} onClick={onClose}>
					{t("orderPageWidgets.chat.chatList.contextMenu.str151") ??
						""}
				</Button>
			</Root>
		</ClickAwayListener>
	);
};

declare namespace ContextMenu {
	interface Props {
		coordinate: Orders.Chat.ContextMenuCoordinate;
		onClose: () => void;
		booferAnswerParentID: number | null;
	}
}

export default ContextMenu;
