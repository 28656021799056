import { Option } from "uikit";

// eslint-disable-next-line import/prefer-default-export
export const surchargesOptions: Option<string>[] = [
	{
		key: "surcharge_to_cost_per_km",
		value: "surcharge_to_cost_per_km",
		label: "",
	},
	{
		key: "coefficient_to_cost_per_km",
		value: "coefficient_to_cost_per_km",
		label: "",
	},
	{
		key: "surcharge_to_order_sum",
		value: "surcharge_to_order_sum",
		label: "",
	},
	{
		key: "coefficient_to_order_sum",
		value: "coefficient_to_order_sum",
		label: "",
	},
];
