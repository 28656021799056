import React, { ComponentType, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import getGlobalSettings from "../../redux/services/Settings/global/get";
import {
	StyledGrid,
	StyledGridItem,
	SuspenseLoader,
} from "../../components/common";
import { ROUTES } from "../../constants/routes";

const ConnectionWrapperStyled = styled.div`
	.ConnectionWrapper-connectionStatus {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 18000;
		background-color: rgba(0, 0, 0, 0.4);
		display: flex;
		justify-content: center;
		align-items: center;

		.ConnectionWrapper-status {
			font-size: 24px;
			color: #e13d3d;
			font-style: bold;
			text-transform: uppercase;
		}
	}
`;

interface ConnectionWrapperProps {
	connected: boolean;
	children: React.ReactNode;
}

// TODO: Temporary component to show connection lost status. Will be replaced with a snackbar
const ConnectionWrapper: React.FC<ConnectionWrapperProps> = (props) => (
	<ConnectionWrapperStyled>
		{!props.connected && (
			<div className="ConnectionWrapper-connectionStatus">
				<div className="ConnectionWrapper-status">Connection lost</div>
			</div>
		)}
		{props.children}
	</ConnectionWrapperStyled>
);

export default function RequireAuth(Component: ComponentType) {
	return function ComponentWithMiddleware(props: Record<string, unknown>) {
		const dispatch = useTypedDispatch();
		const history = useHistory();

		const token = useTypedSelector((state) => state.session.token);
		const user = useTypedSelector((state) => state.account.user);
		const settingsLoaded = useTypedSelector(
			(state) => state.settingState.loaded,
		);
		const { globalSettings, orderSettings } = useTypedSelector(
			(state) => state.loading,
		);

		const loaded = globalSettings && orderSettings && user;

		const { connected, authorized } = useTypedSelector(
			(state) => state.prpc,
		);

		useEffect(() => {
			if (connected && !authorized) {
				history.push(ROUTES.LOGIN);
			} else if (!settingsLoaded) {
				dispatch(getGlobalSettings());
			}
		}, [history, connected, authorized, settingsLoaded, dispatch]);

		return authorized && loaded ? (
			<ConnectionWrapper connected={connected}>
				<Component {...props} />
			</ConnectionWrapper>
		) : (
			<StyledGrid areas="'main'" w="100%" h="100vh">
				<StyledGridItem
					area="main"
					justifySelf="center"
					alignSelf="center"
				>
					<SuspenseLoader />
				</StyledGridItem>
			</StyledGrid>
		);
	};
}
