import makeTable from "./makeTable";

const dispatchersActivityReport = makeTable({
	allIds: [
		"dispatcherPosition",
		"lastName",
		"firstName",
		"middleName",
		"ownOrdersCreatedCount",
		"ownOrdersCreatedSum",
		"ownOrdersCompletedCount",
		"ownOrdersCompletedSum",
		"ownOrdersCancelledCount",
		"ownOrdersCancelledSum",
		"forwardedOrdersCompletedCount",
	],
	i18tPath: (id) =>
		`mainPage.reports.salary.dispatchersActivity.columns.${id}`,
	defaultIds: [
		"dispatcherPosition",
		"lastName",
		"firstName",
		"middleName",
		"ownOrdersCreatedCount",
		"ownOrdersCreatedSum",
		"ownOrdersCompletedCount",
		"ownOrdersCompletedSum",
		"ownOrdersCancelledCount",
		"ownOrdersCancelledSum",
		"forwardedOrdersCompletedCount",
	],
	initialSortMethod: {
		column: "dispatcherPosition",
		type: "asc",
	},
	initialWidths: {
		dispatcherPosition: 150,
		lastName: 150,
		firstName: 150,
		middleName: 150,
		ownOrdersCreatedCount: 150,
		ownOrdersCreatedSum: 150,
		ownOrdersCompletedCount: 150,
		ownOrdersCompletedSum: 150,
		ownOrdersCancelledCount: 150,
		ownOrdersCancelledSum: 150,
		forwardedOrdersCompletedCount: 150,
	},
	initialResizable: {
		dispatcherPosition: true,
		lastName: true,
		firstName: true,
		middleName: true,
		ownOrdersCreatedCount: true,
		ownOrdersCreatedSum: true,
		ownOrdersCompletedCount: true,
		ownOrdersCompletedSum: true,
		ownOrdersCancelledCount: true,
		ownOrdersCancelledSum: true,
		forwardedOrdersCompletedCount: true,
	},
	initialSortable: {
		dispatcherPosition: true,
		lastName: true,
		firstName: true,
		middleName: true,
		ownOrdersCreatedCount: true,
		ownOrdersCreatedSum: true,
		ownOrdersCompletedCount: true,
		ownOrdersCompletedSum: true,
		ownOrdersCancelledCount: true,
		ownOrdersCancelledSum: true,
		forwardedOrdersCompletedCount: true,
	},
});

export default dispatchersActivityReport;
