export namespace Preferences {
	export const ConstantPreferencesSlice = {
		SERVICES_ERRORS: "preferences/services/errors",
		SERVICES_FILTERS: "references/services/filters",
		SERVICES_DATA: "preferences/services/data",
		SERVICES: "references/services",

		CAR_CLASSES_ERRORS: "preferences/carClasses/errors",
		CAR_CLASSES_FILTERS: "references/carClasses/filters",
		CAR_CLASSES_DATA: "preferences/carClasses/data",
		CAR_CLASSES: "references/carClasses",
	};
	export type TypeOrderSlice = typeof ConstantPreferencesSlice;
	export type ValuePreferencesSlice =
		(typeof ConstantPreferencesSlice)[keyof typeof ConstantPreferencesSlice];
}
