import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";

export default function closeOrderCard(...ids: number[]) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		await createRPCQuery(() => prpcow.theirsModel.orders.card.close(ids));

		return null;
	};
}
