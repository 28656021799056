import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<Root.Props>`
	${({ width }) => `width: ${width}px;`}

	max-height : 200px;

	border-radius: 5px;

	overflow: hidden auto;

	${theme.scrollbar}
`;

declare namespace Root {
	interface Props {
		width: number;
	}
}

export default Root;
