import React, { Dispatch, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import Sector from "../../../../../../../../../../../../../../../../../../../../services/Sector";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import mapByKey from "../../../../../../../../../../../../../../../../../../../../utils/mapByKey";
import DeleteModal from "../../../../../../../../../../../../../../../../../../../../components/DeleteModal";
import DefaultPageHeader from "../../../../../../../../../../../../../../../../../../../../components/DefaultPageHeader";
import { StyledColumn } from "../../../../../../../../../../../../../../../../../../../../components/common";
import { useTableOptions } from "../../../../../../../../../../../../../../../../../../../../components/LightTable";
import Modal from "../../../../../../../../../../../../../Modal";
import AverageSpeedTable from "../../../../../../../../../../../../../AverageSpeedTable";
import { DateFns } from "../../../../../../../../../../../../../../../../../../../../utils/DateFns";

const defaultValue = () => {
	const id = Date.now();
	const dateFns = new DateFns();
	const startDay = 0;
	const endDay = 24 * 60 * 60 * 1000 - 1000;

	return {
		id: cloneDeep(id),
		start: dateFns.millisecondsToDate(startDay, {
			utc: false,
		}),
		end: dateFns.millisecondsToDate(endDay, {
			utc: false,
		}),
		speed: 30,
		dow: [],
	};
};

const AverageSpeed: React.FC<AverageSpeed.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const { editor, onChange: onChangeEditor } = useTableOptions();
	const dateFns = useMemo(() => new DateFns(), []);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isAdd, setIsAdd] = useState(false);
	const [selected, setSelected] = useState<number[]>([]);
	const [actualDefaultValue] = useState(defaultValue());
	const [editingItem, setEditingItem] = useState<any>(actualDefaultValue);

	const valueEditor = useObjectEditor(value, onChange);
	const averageSpeed = valueEditor.useGetter("averageSpeed");
	const setAverageSpeed = valueEditor.useSetter("averageSpeed");
	const setIsBasicAverageSpeedEnabled = valueEditor.useSetter(
		"isBasicAverageSpeedEnabled",
	);

	const modelItemById = useMemo(
		() => mapByKey(averageSpeed, "id"),
		[averageSpeed],
	);

	const allAverageTime = useMemo(
		() =>
			averageSpeed?.map(({ start, end, dow, id }) => ({
				start,
				end,
				dow,
				id,
			})),
		[averageSpeed],
	);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			const payload = {
				...item,
				start: dateFns.millisecondsToDate(item.start, {
					utc: false,
				}),
				end: dateFns.millisecondsToDate(item.end, {
					utc: false,
				}),
			};

			setEditingItem(cloneDeep(payload));
			setShowModal(true);
			setSelected([]);
		},
		[dateFns, modelItemById],
	);

	const addHandler = useCallback(() => {
		const value = defaultValue();
		setIsAdd(true);
		setEditingItem(value);
		setShowModal(true);
	}, []);

	const saveHandler = useCallback(
		(data: Modal.Value) => {
			setIsAdd(false);
			const payload = {
				...data,
				start: dateFns.millisecondsFromDate(data.start, { utc: false }),
				end: dateFns.millisecondsFromDate(data.end, { utc: false }),
			};
			const exist = modelItemById[data.id];

			if (exist) {
				const items = averageSpeed.map((item) => {
					if (item.id === exist.id) return payload;
					return item;
				});
				setAverageSpeed(items);
			} else {
				const items = [...averageSpeed, payload];
				setAverageSpeed(items);
			}

			setShowModal(false);
			setEditingItem(actualDefaultValue);
			setIsBasicAverageSpeedEnabled(false);
		},
		[
			actualDefaultValue,
			averageSpeed,
			dateFns,
			modelItemById,
			setAverageSpeed,
			setIsBasicAverageSpeedEnabled,
		],
	);

	const editContentHandler = useCallback(
		(item: Sector.AverageSpeed) => {
			edit(item.id);
		},
		[edit],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		const items = [...averageSpeed];
		const payload = items.filter((item) => !selected.includes(item.id));
		setAverageSpeed(payload);
		setSelected([]);
		setShowDeleteModal(false);
	}, [averageSpeed, selected, setAverageSpeed]);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setIsAdd(false);
		setSelected([]);
	}, []);

	const cancelHandler = useCallback(() => {
		setIsAdd(false);
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const labelDeleteModal = useMemo(() => {
		if (selected.length > 1) {
			return (
				t(
					"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.averageSpeed.str0",
				) ?? ""
			);
		}
		return (
			t(
				"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.averageSpeed.str0",
			) ?? ""
		);
	}, [selected.length, t]);

	return (
		<StyledColumn w="100%" h="100%" p="16px 0 0 0">
			<DefaultPageHeader
				canAdd={true}
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onEdit={editHeaderHandler}
				onDelete={preDeleteHandler}
			/>
			<AverageSpeedTable
				value={averageSpeed}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChangeEditor}
			/>
			{showModal && (
				<Modal
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					allAverageTime={allAverageTime}
					isAdd={isAdd}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={labelDeleteModal}
					onCancel={cancelDelete}
					onConfirm={deleteHandler}
				/>
			)}
		</StyledColumn>
	);
};

declare namespace AverageSpeed {
	type Value = {
		averageSpeed: AverageSpeedTable.Value;
		isBasicAverageSpeedEnabled: boolean;
	};

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Controller = any;
}

export default AverageSpeed;
