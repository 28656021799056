import styled from "styled-components";
import { theme } from "uikit";

const Marker = styled.div`
	&.__ignore,
	.leaflet-marker-icon:has(div > &) {
		pointer-events: none;
	}

	display: flex;

	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;

	margin: 0px auto;

	border: 3px solid ${theme.colors.primary};
	border-radius: 10px;

	color: white;

	background-color: ${theme.colors.accent};

	pointer-events: all;

	&::after {
		content: "";

		position: absolute;

		left: 50%;
		top: 17px;

		transform: translateX(-50%);

		border: 7px solid transparent;
		border-top: 7px solid ${theme.colors.primary};

		z-index: -1;
	}
`;

export default Marker;
