/* eslint-disable no-shadow */

import React, { useCallback } from "react";
import {
	CriteriaMode,
	FieldValues,
	Path,
	useForm,
	UseFormReturn,
	ValidationMode,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ObjectShape } from "yup/lib/object";

type OnSubmit = (data: any) => Promise<any>;

interface SubmitCallback {
	onSuccess: (result: any) => void;
	onError: (error: any, fn: (error: any) => void) => void;
}

type Submit = (onSubmit: OnSubmit, callbacks?: SubmitCallback) => Promise<void>;

export interface UseCreateFormReturn<FV extends FieldValues>
	extends UseFormReturn<FV> {
	isLoading: boolean;
	submit: Submit;
}

interface Props<V> {
	yupObject: ObjectShape;
	mode?: keyof ValidationMode;
	criteriaMode?: CriteriaMode;
	values?: V;
}

function useCreateForm<SchemaGeneric extends FieldValues>({
	yupObject,
	mode = "onChange",
	criteriaMode,
	values,
}: Props<SchemaGeneric>): UseCreateFormReturn<SchemaGeneric> {
	const [isLoading, setIsLoading] = React.useState(false);
	const { control, setError, getValues, handleSubmit, ...rest } =
		useForm<SchemaGeneric>({
			resolver: yupResolver(yup.object(yupObject).required()),
			criteriaMode,
			mode,
			values,
		});

	const setErrors = useCallback(
		(fields: any) => {
			Object.keys(fields).forEach((field) =>
				setError(
					field as Path<SchemaGeneric>,
					Array.isArray(fields[field])
						? { message: fields[field][0], type: fields[field][1] }
						: { message: fields[field] },
				),
			);
		},
		[setError],
	);

	const submit: Submit = useCallback(
		(onSubmit, callbacks) =>
			handleSubmit((data) => {
				setIsLoading(true);
				onSubmit(data)
					.then((result: any) => {
						setIsLoading(false);
						if (callbacks?.onSuccess) callbacks.onSuccess(result);
					})
					.catch((error: any) => {
						setIsLoading(false);
						if (callbacks?.onError) {
							callbacks.onError(error, setErrors);
						}
					});
			})(),
		[setErrors, handleSubmit],
	);

	return {
		...rest,
		isLoading,
		control,
		submit,
		getValues,
		setError,
		handleSubmit,
	};
}

export { useCreateForm };
