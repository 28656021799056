import React, { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton } from "uikit";

import { StyledRow, StyledSpan, Style } from "../styles";

const ToggleButtonWithTextBase: React.FC<ToggleButtonWithTextBase.Props> = ({
	value,
	onChange,
	disabled = false,
	title,
	titlePosition = "right",
	label,
	labelPosition,
	...props
}) => {
	const { t } = useTranslation();
	const [data, setData] = useState<ToggleButtonWithTextBase.Value>(false);

	const text = useMemo(() => t(title), [t, title]);
	const tLabel = useMemo(() => (label ? t(label) : ""), [t, label]);

	const colorText = useMemo(() => {
		if (disabled) return "#647079";
		if (value || data) return "#21333f";
		return "#647079";
	}, [value, disabled, data]);

	return (
		<StyledRow alignItems="center" gap="10px" {...props}>
			{titlePosition === "right" && (
				<StyledSpan colors={colorText}>{text}</StyledSpan>
			)}
			<ToggleButton.ToggleButton
				value={onChange ? value : data}
				onChange={onChange || setData}
				disabled={disabled}
				label={tLabel}
				labelPosition={labelPosition}
			/>
			{titlePosition === "left" && (
				<StyledSpan colors={colorText}>{text}</StyledSpan>
			)}
		</StyledRow>
	);
};

export const ToggleButtonWithText = memo(ToggleButtonWithTextBase);

declare namespace ToggleButtonWithTextBase {
	type Value = boolean;

	interface Props extends Style.Row {
		value?: Value;
		onChange?: (value: Value) => void;
		title: string | string[];
		disabled?: boolean;
		titlePosition?: "left" | "right";
		label?: string | string[];
		labelPosition?: ToggleButton.Props["labelPosition"];
	}
}

export default ToggleButtonWithTextBase;
