import React from "react";
import { TabData, TabGroup } from "rc-dock";

const groups: Record<string, TabGroup> = {
	default: {
		animated: false,
		floatable: true,
		maximizable: false,
	},
	static: {
		animated: false,
		floatable: false,
		maximizable: false,
		tabLocked: true,
		disableDock: true,
	},
};

const defaultTab: TabData = {
	title: "",
	content: <></>,
	closable: true,
	group: "default",
	minWidth: 450,
	minHeight: 150,
};

const pathTranslationsMain = "pages.mainPage.pages.orders.mainOrderPage.data.";

const tableTab: TabData = {
	...defaultTab,
	id: "table",
	title: `${pathTranslationsMain}str200`,
	group: "static",
	closable: false,
	minHeight: 164,
};

const previewerTab: TabData = {
	...defaultTab,
	id: "previewer",
	title: `${pathTranslationsMain}str201`,
	minHeight: 323,
};

const mapTab: TabData = {
	...defaultTab,
	id: "map",
	title: `${pathTranslationsMain}str202`,
	cached: false,
};

const parkingTab: TabData = {
	...defaultTab,
	id: "parking",
	title: `${pathTranslationsMain}str203`,
};

const executorsTab: TabData = {
	...defaultTab,
	id: "executors",
	title: `${pathTranslationsMain}str204`,
};

const chatTab: TabData = {
	...defaultTab,
	id: "chat",
	title: `${pathTranslationsMain}str205`,
};

const defaultTabs = {
	table: tableTab,
	previewer: previewerTab,
	map: mapTab,
	parking: parkingTab,
	executors: executorsTab,
	chat: chatTab,
};

export { groups, defaultTab, defaultTabs, pathTranslationsMain };
