import { Option } from "uikit";

export default function getOptions(
	items: (string | number)[],
): Option<string | number>[] {
	return items.map((item) => ({
		key: item,
		value: item,
		label: item.toString(),
	}));
}
