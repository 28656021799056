import makeTable from "./makeTable";

const accountingAgentsSecondTable = makeTable({
	allIds: [
		"date",
		"operationDescription",
		"order",
		"type",
		"source",
		"user",
		"amount",
		"balance",
	],
	i18tPath: (id) => `mainPage.accounting.agents.columns.${id}`,
	defaultIds: [
		"date",
		"operationDescription",
		"order",
		"type",
		"source",
		"user",
		"amount",
		"balance",
	],
	initialSortMethod: {
		column: "date",
		type: "asc",
	},
	initialWidths: {
		date: 155, // minimum for excel file format
		operationDescription: 180,
		order: 180,
		type: 180,
		source: 180,
		user: 180,
		amount: 180,
		balance: 180,
	},
	initialResizable: {
		date: true,
		operationDescription: true,
		order: true,
		type: true,
		source: true,
		user: true,
		amount: true,
		balance: true,
	},
	initialSortable: {
		date: true,
		operationDescription: true,
		order: true,
		type: true,
		source: true,
		user: true,
		amount: true,
		balance: true,
	},
});

export default accountingAgentsSecondTable;
