import React, {
	PropsWithChildren,
	createContext,
	memo,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";
import { LatLngLiteral } from "leaflet";
import { useInternal } from "uikit";

import Search from "../../../../../services/Map";
import { useTypedSelector } from "../../../../../redux/store";
import { Language } from "../../../../../services";

export const PointOrderModalTableContext =
	createContext<Provider.Context | null>(null);

export const usePointOrderModalTableContext = (): Provider.Context => {
	const store = useContext<Provider.Context | null>(
		PointOrderModalTableContext,
	);
	if (!store) {
		throw new Error(
			"Missing PointOrderModalTableContext.Provider in the tree",
		);
	}
	return store;
};

export const PointOrderModalTableProvider: React.FC<Provider.Props> = ({
	children,
}): JSX.Element => {
	const lang = useTypedSelector((state) => state.session.language);
	const [rowId, setRowId] = useInternal<Provider.Value["rowId"]>(-1);
	const [reload, setReload] = useInternal<Provider.Value["reload"]>(false);
	const [disabled, setDisabled] =
		useInternal<Provider.Value["disabled"]>(false);
	const [customerId, setCustomerId] =
		useInternal<Provider.Value["customerId"]>(-1);
	// value

	const [settlements, setSettlements] = useState<
		Provider.Value["settlements"]
	>(new Map());
	const [streets, setStreets] = useState<Provider.Value["streets"]>(
		new Map(),
	);
	const [settlementsCoordinates, setSettlementsCoordinates] = useState<
		Provider.Value["mapCoordinates"]
	>(new Map());
	const [streetsCoordinates, setStreetsCoordinates] = useState<
		Provider.Value["mapCoordinates"]
	>(new Map());
	const [housesCoordinates, setHousesCoordinates] = useState<
		Provider.Value["mapCoordinates"]
	>(new Map());

	const getData = useCallback<Provider.Context["getData"]>(
		(id) => {
			if (id) {
				const settlement = settlements.get(id);
				const street = streets.get(id);
				const settlementCoordinates = settlementsCoordinates.get(id);
				const streetCoordinates = streetsCoordinates.get(id);
				const houseCoordinates = housesCoordinates.get(id);

				setReload(false);
				setRowId(-1);

				return {
					street,
					settlement,
					streetCoordinates,
					settlementCoordinates,
					houseCoordinates,
				};
			}
			setRowId(-1);
			setReload(false);
			return { street: undefined, settlement: undefined };
		},
		[
			housesCoordinates,
			setReload,
			setRowId,
			settlements,
			settlementsCoordinates,
			streets,
			streetsCoordinates,
		],
	);

	const reset = useCallback<Provider.Context["reset"]>(
		(id: number) => {
			streets.set(id, undefined);
			streetsCoordinates.set(id, undefined);
			housesCoordinates.set(id, undefined);
			setRowId(id);
			setReload(true);
		},
		[housesCoordinates, setReload, setRowId, streets, streetsCoordinates],
	);

	const addStreet = useCallback<Provider.Context["addStreet"]>(
		(streetObject: Search.Search.Object | undefined, id: number) => {
			setStreets((prev) => prev.set(id, streetObject));
			setStreetsCoordinates((prev) =>
				prev.set(id, streetObject?.coordinates),
			);
			setReload(true);
			setRowId(id);
		},
		[setReload, setRowId, setStreetsCoordinates],
	);

	const addSettlement = useCallback<Provider.Context["addSettlement"]>(
		(settlementObject, id) => {
			if (!settlementObject) {
				reset(id);
				return;
			}

			setSettlements((prev) => prev.set(id, settlementObject));
			setSettlementsCoordinates((prev) =>
				prev.set(id, settlementObject.coordinates),
			);
			setStreets((prev) => prev.set(id, undefined));
			setStreetsCoordinates((prev) => prev.set(id, undefined));
			setReload(true);
			setRowId(id);
		},
		[reset, setReload, setRowId, setStreetsCoordinates],
	);

	const addSettlementCoordinates = useCallback<
		Provider.Context["addSettlementCoordinates"]
	>(
		(coordinates, id) => {
			setSettlementsCoordinates((prev) => prev.set(id, coordinates));
			setRowId(id);
			setReload(true);
		},
		[setReload, setRowId],
	);

	const addStreetCoordinates = useCallback<
		Provider.Context["addStreetCoordinates"]
	>(
		(coordinates, id) => {
			setStreetsCoordinates((prev) => prev.set(id, coordinates));
			setRowId(id);
			setReload(true);
		},
		[setReload, setRowId],
	);

	const addHouseCoordinates = useCallback<
		Provider.Context["addHouseCoordinates"]
	>(
		(coordinates, id) => {
			setHousesCoordinates((prev) => prev.set(id, coordinates));
			setRowId(id);
			setReload(true);
		},
		[setReload, setRowId],
	);

	return (
		<PointOrderModalTableContext.Provider
			value={useMemo(
				() => ({
					reload,
					getData,

					addSettlement,
					addStreet,
					addSettlementCoordinates,
					addStreetCoordinates,
					addHouseCoordinates,
					rowId,
					setRowId,

					disabled,
					setDisabled,

					customerId,
					setCustomerId,

					reset,
					lang,
				}),
				[
					reload,
					getData,
					addSettlement,
					addStreet,
					addSettlementCoordinates,
					addStreetCoordinates,
					addHouseCoordinates,
					rowId,
					setRowId,
					disabled,
					setDisabled,
					customerId,
					setCustomerId,
					reset,
					lang,
				],
			)}
		>
			{children}
		</PointOrderModalTableContext.Provider>
	);
};

export declare namespace Provider {
	export interface Props extends PropsWithChildren {}

	type Settlement = Search.Search.Object;
	type Street = Search.Search.Object;
	interface GetData {
		settlement: Settlement | undefined;
		street: Street | undefined;
		streetCoordinates?: LatLngLiteral | undefined;
		settlementCoordinates?: LatLngLiteral | undefined;
		houseCoordinates?: LatLngLiteral | undefined;
	}
	interface Value {
		disabled: boolean;
		customerId: number;
		rowId: number;
		reload: boolean;

		mapCoordinates: Map<number, LatLngLiteral | undefined>;
		settlements: Map<number, Settlement | undefined>;
		streets: Map<number, Street | undefined>;
	}
	interface Context {
		lang: Language;
		reset: (id: number) => void;
		getData: (id: number) => GetData;

		reload: boolean;

		addSettlement: (settlement: Settlement | undefined, id: number) => void;
		addStreet: (street: Street | undefined, id: number) => void;

		addSettlementCoordinates: (
			coordinates: LatLngLiteral | undefined,
			id: number,
		) => void;
		addStreetCoordinates: (
			coordinates: LatLngLiteral | undefined,
			id: number,
		) => void;

		addHouseCoordinates: (
			coordinates: LatLngLiteral | undefined,
			id: number,
		) => void;

		rowId: number;
		setRowId: (value: number) => void;

		disabled: boolean;
		setDisabled: (value: boolean) => void;

		customerId?: number;
		setCustomerId: (value: number) => void;
	}
}

export const PointOrderModalTableProviderMemo = memo(
	PointOrderModalTableProvider,
);

export const ConstantAccessors = {
	id: "id",
	city: "city",
	house: "house",
	street: "street",
	entrance: "entrance",
	flat: "flat",
	polygon: "polygon",
	setting: "setting",
} as const;
export type TypeAccessors = typeof ConstantAccessors;
export type ValueAccessors =
	(typeof ConstantAccessors)[keyof typeof ConstantAccessors];
