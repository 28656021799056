import makeTable from "./makeTable";

const contrAgent = makeTable({
	allIds: [
		"id",
		"lastName",
		"firstName",
		"fatherName",
		"createdAt",
		"updatedAt",
		"deletedAt",
	],
	i18tPath: (id) => `tableColumns.customers.contrAgents.${id}`,

	initialSortMethod: {
		column: "createdAt",
		type: "desc",
	},
});

export default contrAgent;
