import React, { memo, useState } from "react";
import { Column, Row } from "uikit";
import { useTranslation } from "react-i18next";

import CarClass from "../../../../../../../../../../../../services/CarClass";
import TabRoot from "../../components/TabRoot";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import tPath from "../../../../../../constants/tPath";
import { MultiSelectCarClassWithModal } from "../../../../../../../../../../../../components/common";

import CompaniesAndTaxiServices from "./components/CompaniesAndTaxiServices";
import Name from "./components/Name";
import AutoClasses from "./components/AutoClasses";
import Surcharge from "./components/Surcharge";
import Propagation from "./components/Propagation";

const GeneralSurcharges: React.FC<GeneralSurcharges.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();

		const [error] = useState(false);

		const valueEditor = useObjectEditor(value, onChange);

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const carClassIds = valueEditor.useGetter("carClassIds");
		const setCarClassIds = valueEditor.useSetter("carClassIds");

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "taxiServices"],
			"json",
		);

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
		const additional = valueEditor.useGetter("additionalFields");
		const setAdditional = valueEditor.useSetter("additionalFields");
		const additionalEditor = useObjectEditor(additional, setAdditional);

		const general = additionalEditor.useGetter("general");
		const setGeneral = additionalEditor.useSetter("general");
		const generalEditor = useObjectEditor(general, setGeneral);

		const surcharge = generalEditor.useGetter("surcharge");
		const setSurcharge = generalEditor.useSetter("surcharge");

		const propagation = generalEditor.useGetter("propagation");
		const setPropagation = generalEditor.useSetter("propagation");

		const onChangeContent = valueEditor.useAssigner();

		return (
			<TabRoot hasPaddings visible={true}>
				<Column gaps="30px*" maxedWidth maxedHeight>
					<Row sizes="39%! 29%! 29%!" justify="space-between">
						<Name value={name} onChange={setName} />
						<CompaniesAndTaxiServices
							value={taxiServicesData}
							onChange={onChangeContent}
						/>
					</Row>
					<FieldsContainer
						label={
							t(
								`${tPath}.modal.tabs.generalSurcharges.surcharges.title`,
							) || ""
						}
					>
						<Surcharge value={surcharge} onChange={setSurcharge} />
					</FieldsContainer>
					<FieldsContainer
						label={`${
							t(
								`${tPath}.modal.tabs.generalSurcharges.surcharges.applySurcharges`,
							) || ""
						}:`}
					>
						<Column gaps="10px*">
							<Row align="center" gaps="10px*" sizes="12% 1fr">
								<div>
									{t(
										"pages.settings.pages.mobileApps.executor.modal.content.tabs.main.str208",
									)}
								</div>

								<MultiSelectCarClassWithModal
									disabled={!taxiServiceIds.length}
									value={carClassIds || []}
									onChange={setCarClassIds}
									error={
										error &&
										(t(
											`${tPath}.modal.tabs.generalSurcharges.surcharges.autoClasses.error`,
										) ||
											"")
									}
									// required
									titleText={
										"pages.settings.pages.mobileApps.executor.modal.content.tabs.main.str1"
									}
									subscribeOptions={{
										taxiServiceIds,
									}}
									showAllItem
								/>
							</Row>
							<Propagation
								value={propagation}
								onChange={setPropagation}
							/>
						</Column>
					</FieldsContainer>
				</Column>
			</TabRoot>
		);
	},
);

declare namespace GeneralSurcharges {
	interface Props {
		autoClasses: CarClass.Model[];
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value extends CompaniesAndTaxiServices.Value {
		name: Name.Value;
		carClassIds: AutoClasses.Value;
		additionalFields: AdditionalFields;
	}

	interface AdditionalFields {
		general: General;
	}

	interface General {
		surcharge: Surcharge.Value;
		propagation: Propagation.Value;
	}
}

export default GeneralSurcharges;
