import React from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";

import Root from "./components/Root";
import Text from "./components/Text";

const NoFiles: React.FC<NoFiles.Props> = ({ disabled, onClick }) => {
	const { t } = useTranslation();
	return (
		<Root
			disabled={disabled}
			align="center"
			justify="center"
			gaps="5px*"
			onClick={disabled ? noop : onClick}
		>
			<Text>{t("noFiles.str100") ?? ""}</Text>
		</Root>
	);
};

declare namespace NoFiles {
	interface Props {
		disabled: boolean;

		onClick?: () => void;
	}
}

export default NoFiles;
