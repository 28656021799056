import React, { ReactNode, useCallback, useLayoutEffect, useMemo } from "react";
import { useReactiveRef, useRender, useSize } from "uikit";

import Nullable from "../../types/Nullable";

import Root from "./components/Root";
import Container from "./components/Container";
import Item from "./components/Item";

const VirtualList: React.FC<VirtualList.Props> = ({
	totalItems,
	gap = 0,

	itemHeight,

	children,

	onChangeViewBox,
}) => {
	const render = useRender();

	const [rootRef, setRootRef] = useReactiveRef<HTMLDivElement | null>(null);

	const { height: rootSizeHeight } = useSize(rootRef.current);

	const listTotalHeight = useMemo(
		() => (itemHeight + gap) * totalItems - gap,
		[itemHeight, totalItems, gap],
	);

	const rootScrollTop = useMemo(
		() => rootRef.current?.scrollTop ?? 0,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rootRef.current?.scrollTop],
	);

	const itemsOffset = useMemo(
		() => Math.floor((rootScrollTop + gap) / (itemHeight + gap)),
		[gap, itemHeight, rootScrollTop],
	);

	const itemsCount = useMemo(() => {
		const bottomIndex = Math.floor(
			(rootScrollTop + rootSizeHeight + gap) / (itemHeight + gap),
		);

		const result = bottomIndex - itemsOffset + 1;

		return result;
	}, [gap, itemHeight, itemsOffset, rootScrollTop, rootSizeHeight]);

	const renderItem = useCallback(
		(index: number) => {
			const node = children(index);

			if (node)
				return (
					<Item
						key={`_${index}`}
						offset={(itemHeight + gap) * index}
						height={itemHeight}
					>
						{node}
					</Item>
				);

			return (
				<Item
					key={`_${index}`}
					offset={(itemHeight + gap) * index}
					height={itemHeight}
				/>
			);
		},
		[children, gap, itemHeight],
	);

	const items = useMemo(
		() =>
			Array(totalItems)
				.fill(null)
				.map((_, index) => {
					const item = renderItem(index);

					return item;
				}),
		[totalItems, renderItem],
	);

	const rootOnScroll = useCallback(() => {
		render(true);
	}, [render]);

	useLayoutEffect(() => {
		onChangeViewBox(itemsOffset, itemsCount);
	}, [itemsOffset, itemsCount, onChangeViewBox]);

	return (
		<Root ref={setRootRef} onScroll={rootOnScroll}>
			<Container height={listTotalHeight} maxedWidth>
				{items}
			</Container>
		</Root>
	);
};

declare namespace VirtualList {
	interface Props {
		totalItems: number;
		itemHeight: number;
		gap?: number;

		children: (index: number) => Nullable<ReactNode>;

		onChangeViewBox: (offset: number, count: number) => void;
	}
}

export default VirtualList;
