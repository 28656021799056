/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";
import { LatLngLiteral } from "leaflet";
import { clone, isEqual } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import Subscription from "../../types/Subscription";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Base from "../Base";
import Language from "../Language";
import Executor from "../Executor";

class Parking extends Base {
	// Its needed due to typescript bundler conflict
	// private static _Card: Card | null = null;

	// public static get Card() {
	// 	if (this._Card) return this._Card;

	// 	this._Card = new Card((prpc) => prpc.theirsModel.parking.card);

	// 	return this._Card;
	// }

	static fromResponse(data: any): Parking.Model {
		const sectorIds = data?.parkingToSectors?.map(
			(parkingToSector) => parkingToSector?.sector?.id,
		) as (number | "outside")[];

		if (data?.outside) sectorIds?.unshift("outside");

		const payload = {
			id: data?.id,

			taxiServiceId: data?.taxiService?.id,
			sectorIds,

			executorsQueue: data?.executorsQueue || [],

			position: new Date(data?.position || 1),
			name: data?.name,
			vertices: data?.vertices?.[0]?.slice(0, -1),

			active: data?.status,
			bySector: data?.bySector,

			createdAt: "",
			updatedAt: "",
			deletedAt: null,
		};

		return payload;
	}

	static toRequest(model: Parking.Model.New | Parking.Model.Modified): any {
		const vertices = clone(model.vertices);

		if (vertices && !isEqual(vertices[vertices.length - 1], vertices[0]))
			vertices.push(vertices[0]);

		const data: any = {
			vertices,
			taxiServiceId: model.taxiServiceId,
			executorsQueue: model.executorsQueue,
			position: model.position,
			name: model.name,
			point: model.point,
			status: model.active,
		};
		if (!model.point) delete data.point;
		if (!model.executorsQueue) delete data.executorsQueue;

		if (Array.isArray(model.sectorIds)) {
			const outside = model.sectorIds.includes("outside");
			const sectorIds = model.sectorIds.filter(
				(sectorId) => sectorId !== "outside",
			);

			data.sectorIds = sectorIds;
			data.outside = outside;
		}

		return data;
	}

	public static async store(object: Parking.Model.New) {
		this.request((prpc) =>
			prpc.theirsModel.parking.create(Parking.toRequest(object)),
		);
	}

	public static async update(object: Parking.Model.Modified) {
		try {
			const params = Parking.toRequest(object);

			const res = await this.request(
				(prpc) => prpc.theirsModel.parking.update(object.id, params),
				{ silent: false, error: true },
			);

			if (!res.id) {
				console.log("[Parking] update ", { res, params });
			}

			return res;
		} catch (err: any) {
			console.log("[Parking] update err", { err, object });
			return null;
		}
	}

	public static async getAll(object: Parking.SubscribeOptions) {
		return this.request((prpc) => prpc.theirsModel.parking.getAll(object));
	}

	public static async destroy(executorsIds: number[]) {
		if (Array.isArray(executorsIds))
			await Promise.all(executorsIds.map((id) => this.destroyOne(id)));
	}

	public static async leave(id: number[]) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.leaveOne(id)));
	}

	public static async change(
		id: number,
		executorsIds: { executorId: number }[],
	) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.parking.queue.change(id, executorsIds),
				{ silent: false, error: true },
			);

			console.log("[Parking] change", { id, res, executorsIds });

			return res;
		} catch (err: any) {
			console.log("[Parking] change err", { err, id, executorsIds });
			return null;
		}
	}

	public static async subscribe(
		options: Parking.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Parking.Model>,
	): Promise<Subscription<Parking.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Parking] subscribe", { options, state });
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.parking.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{
				name: "Parking.subscribe",
				metadata: this.optionsToRequest(options),
			},
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Parking.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Parking.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.parking.delete(id));
	}

	private static async leaveOne(executorId: number) {
		this.request((prpc) =>
			prpc.theirsModel.parking.queue.leave({ executorId }),
		);
	}

	private static optionsToRequest(options: Parking.SubscribeOptions) {
		return {
			limit: options.limit,
			offset: options.offset,
			lang: options.language,
			query: options.query,
			name: options.name,
			status: options.status,
			bySector: options.bySector,
			taxiServiceIds: options.taxiServiceIds,
			sectorIds: options.sectorIds,
			point: options.point,
			order: options.order,
			subscribeType: options.subscribeType,
		};
	}
}

export enum ParkingSubscribeTypeEnum {
	PARKING_PAGE = "parking_page",
	PARKING_MODAL = "parking_modal",
	PARKING_MULTISELECT_MODAL = "parking_multiselect_modal",
	MAIN_ORDER_PAGE = "main_order_page",
	MAIN_ORDER_GET_ALL_PAGE = "main_order_get_all_page",
	MAIN_ORDER_PAGE_SAVE_MODAL = "main_order_page_save_modal",
}
export const ParkingSubscribeType = {
	PARKING_PAGE: "parking_page",
	PARKING_MODAL: "parking_modal",
	PARKING_MULTISELECT_MODAL: "parking_multiselect_modal",
	MAIN_ORDER_PAGE: "main_order_page",
	MAIN_ORDER_GET_ALL_PAGE: "main_order_get_all_page",
	MAIN_ORDER_PAGE_SAVE_MODAL: "main_order_page_save_modal",
} as const;
export type TypeParkingSubscribeType = typeof ParkingSubscribeType;
export type ValueParkingSubscribeType =
	(typeof ParkingSubscribeType)[keyof typeof ParkingSubscribeType];

declare namespace Parking {
	interface Model {
		id: number;

		taxiServiceId: number;
		sectorIds: (number | "outside")[];

		executorsQueue: {
			id: number;
			executor: Executor.Model & { callSign: string };
			createdAt: string;
			position: string;
		}[];

		position: Date | number;
		name: Record<Language, string>;
		vertices: LatLngLiteral[];

		point?: {
			lat: number;
			lng: number;
		};

		active: boolean;
		bySector: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface ExecutorModel {
		id: number;
		bySector?: boolean;
		geoCentroid?: { type: string; coordinates: number[] };
		name?: {
			ru?: string;
			en?: string;
			uk?: string;
		};
		outside?: boolean;
		position?: string;
		status?: boolean;
		vertices?: {
			type: string;
			coordinates: number[][];
		};
	}
	interface CurrentParking {
		createdAt?: string;
		id: number;
		parking: ExecutorModel;
		position: string;
	}

	interface RearrangePosition {
		executorId: number;
		position: number | Date;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Parking.Model> {
		subscribeType?: ValueParkingSubscribeType;
		taxiServiceIds?: number[];
		sectorIds?: number[];
		name?: string;
		point?: {
			lat: number;
			lng: number;
		};

		status?: boolean;
		bySector?: boolean;

		language?: Language;

		active?: boolean;
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type New = Omit<
			Model,
			"id" | "createdAt" | "updatedAt" | "deletedAt" | "bySector"
		>;
		interface Modified
			extends Partial<
				Omit<
					Model,
					| "createdAt"
					| "updatedAt"
					| "deletedAt"
					| "bySector"
					| "executorsQueue"
				>
			> {
			executorsQueue?: number[];
		}
	}
}

export default Parking;
