import React, { memo, useCallback } from "react";
import { Column, Row, Tabs } from "uikit";
import { useTranslation } from "react-i18next";
import Title from "./components/Title";
import Root from "./components/Root";
import tPath from "../../../../constants/tPath";
import { MapTabs, tabs } from "../../constants/tabs";

const Header: React.FC<Header.Props> = memo(({ tab, title, createdAt, setTab }) => {
	const { t } = useTranslation();
	const translatedTabs = tabs.map((internalTab) => ({
		...internalTab,
		label: t(`${tPath}.modal.tabs.${internalTab.key}.title`),
	}));

	const changeTabHandler = useCallback(
		(newTab) => {
			setTab(newTab);
		},
		[setTab],
	);

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "16px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Column align="end" gaps="15px">
					<Row gaps="7px">
						<span>{t(`createdAt`)}:</span>
						<span style={{ color: "black", fontWeight: "600" }}>
							{createdAt
								? new Date(createdAt).toLocaleDateString()
								: new Date().toLocaleDateString()}
						</span>
					</Row>
				</Column>
			</Row>
			<Tabs
				value={tab}
				options={translatedTabs}
				variant="panels"
				onChange={changeTabHandler}
			/>
		</Root>
	);
});

declare namespace Header {
	interface Props {
		tab: MapTabs;
		title: string;
		createdAt?: string;
		setTab: (x: MapTabs) => void;
	}
}

export default Header;
