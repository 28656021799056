import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Row, TextBox, DateAndTimePicker } from "uikit";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import useDatePickerLocale from "../../../../../../../../../../hooks/useDatePickerLocale";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import { useArchiveFilters, useArchiveSwitcher } from "../../../../../../hooks";
import { StyledRow } from "../../../../../../../../../../components/common";

const CenterPart: React.FC<CenterPart.Props> = (): JSX.Element => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const mapLang = useTypedSelector((state) => state.mapReducer.mapLang);

	const {
		orderFilters: {
			internalDateRange,
			filter: {
				dateFrom,
				dateTo,
				searchQuery,
				companyFilter,
				taxiServiceFilter,
			},
		},
		modifyFilter,
		modifyInternalDateRange,
	} = useArchiveFilters();

	const {
		orderToggles: { isSearch },
	} = useArchiveSwitcher();

	const onChangeDataFrom = useCallback(
		(data: DateAndTimePicker.Value) => {
			if (data) modifyInternalDateRange({ from: data });
		},
		[modifyInternalDateRange],
	);

	const onChangeDataTo = useCallback(
		(data: DateAndTimePicker.Value) => {
			if (data) modifyInternalDateRange({ to: data });
		},
		[modifyInternalDateRange],
	);

	const onChangeCompanies = useCallback(
		(data: number[] | ["all"]) => {
			modifyFilter({ companyFilter: data });
		},
		[modifyFilter],
	);

	const onChangeTaxiServices = useCallback(
		(data: number[] | ["all"]) => {
			modifyFilter({ taxiServiceFilter: data });
		},
		[modifyFilter],
	);

	const onChangeSearchQuery = useCallback(
		(data: string) => {
			modifyFilter({ searchQuery: data });
		},
		[modifyFilter],
	);

	return (
		<StyledRow w="100%" gap="5px" justify="center" alignItems="center">
			<StyledRow gap="5px" alignItems="center">
				{t(`from`, { context: "capitalized" })}
				<DateAndTimePicker
					value={!isSearch ? dateFrom : internalDateRange.from}
					onChange={onChangeDataFrom}
					locale={locale}
				/>

				{t(`to`)}
				<DateAndTimePicker
					value={!isSearch ? dateTo : internalDateRange.to}
					onChange={onChangeDataTo}
					locale={locale}
				/>
			</StyledRow>

			<Row
				style={{ flexGrow: 1, maxWidth: "400px" }}
				gaps="5px*"
				align="center"
				sizes="1fr!*2"
			>
				<CompaniesAndTaxiServicesFilter
					companies={companyFilter}
					taxiServices={taxiServiceFilter}
					language={mapLang}
					onChangeCompanies={onChangeCompanies}
					onChangeTaxiServices={onChangeTaxiServices}
				/>
			</Row>
			<TextBox.TextBox
				placeholder={`${t("search") ?? "Search"}...`}
				value={searchQuery}
				onChange={onChangeSearchQuery}
			/>
		</StyledRow>
	);
};

export const CenterPartMemo = memo(CenterPart);

declare namespace CenterPart {
	interface Props {}
}

export default CenterPart;
