import styled from "styled-components";
import { theme } from "uikit";

import { StyledColumn } from "../../../../../../../common";

const Root = styled(StyledColumn)`
	width: 100%;
	max-height: 435px;
	overflow-y: auto;
	${theme.scrollbar}

	& > *:not(:last-child) {
		border-bottom: 1px solid #c8cfd6;
	}
`;

export default Root;
