/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Column, react, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Map from "../../../../../../../../../../redux/services/Map";
import {
	NamesBase,
	Names,
} from "../../../../../../../../../../components/common";
import ObjectHeader from "../ObjectHeader";
import PointForm from "../PointForm";
// import NameForm from "../NameForm";

import InternalController from "./Controller";

const GroupObjectForm = react.withController<
	GroupObjectForm.Props,
	GroupObjectForm.Controller
>(
	react.inputify<GroupObjectForm.InternalPropsBase, GroupObjectForm.Value>(
		({ controller, value, title, language, onChange, onDelete }) => {
			const { t } = useTranslation();

			const rootRef = useRef<HTMLDivElement | null>(null);
			// const nameFormRef = useRef<NameForm.Controller | null>(null);
			const nameFormRef = useRef<NamesBase.Controller | null>(null);
			const pointFormRef = useRef<PointForm.Controller | null>(null);

			const defaultValue = useMemo<GroupObjectForm.Value>(() => ({}), []);

			const [internalValue] = useInternal(value ?? defaultValue);

			const objectHeaderValue = useMemo<ObjectHeader.Value>(
				() => ({
					autoResolve: internalValue.autoResolve,
				}),
				[internalValue.autoResolve],
			);

			const objectHeaderOnChange = useCallback(
				(objectHeaderValue: ObjectHeader.Value) =>
					onChange({
						...internalValue,

						...objectHeaderValue,
					}),
				[internalValue, onChange],
			);

			const nameFormOnChange = useCallback(
				(name: NamesBase.Value) =>
					onChange({
						...internalValue,

						name,
					}),
				[internalValue, onChange],
			);

			const pointFormOnChange = useCallback(
				(point: PointForm.Value) =>
					onChange({ ...internalValue, ...point }),
				[internalValue, onChange],
			);

			const name = useMemo(
				() => internalValue?.name || {},
				[internalValue?.name],
			);
			controller.setContext({
				rootRef,
				nameFormRef,
				pointFormRef,
			});

			return (
				<Column ref={rootRef} gaps="14px 8px">
					<ObjectHeader
						value={objectHeaderValue}
						title={title}
						onChange={objectHeaderOnChange}
						onDelete={onDelete}
					/>
					<Names
						ref={nameFormRef}
						require
						value={name}
						onChange={nameFormOnChange}
						placeholder="pages.preferencesPages.screenDirectory.objects.editModal.groupObjectForm.str0"
					/>
					{/* <NameForm
						ref={nameFormRef}
						placeholder={
							t(
								"pages.preferencesPages.screenDirectory.objects.editModal.groupObjectForm.str0",
							) ?? ""
						}
						value={internalValue.name}
						onChange={nameFormOnChange}
					/> */}
					<PointForm
						ref={pointFormRef}
						value={internalValue}
						language={language}
						autoResolve={internalValue.autoResolve ?? false}
						onChange={pointFormOnChange}
					/>
				</Column>
			);
		},
	),
	InternalController,
);

declare namespace GroupObjectForm {
	interface Value extends PointForm.Value {
		name?: NamesBase.Value;
		autoResolve?: boolean;
	}

	interface PropsBase {
		title: string;
		language: Map.Language;

		onDelete: () => void;
	}

	interface InternalPropsBase extends PropsBase {
		controller: InternalController;
	}

	type Props = PropsBase & InputifiedComponentProps<Value>;

	type Controller = InternalController;
}

export default GroupObjectForm;
