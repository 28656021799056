import { Dispatch, RefObject } from "react";
import { react } from "uikit";

import { NameBase } from "../../../../../../../../../../../../../../../../components/common";

import { ContentErrors } from ".";

interface Context {
	nameRef: RefObject<NameBase.Ref | null>;
	commissionPlanIdValue?: number;
	minFeeValueValue?: number;
	minFeeActiveValue?: boolean;
	maxFeeValueValue?: number;
	maxFeeActiveValue?: boolean;
	isStartDate?: boolean;
	setErrors: Dispatch<ContentErrors>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isNameValid = !!this.context?.nameRef.current?.validate();
		const isCommissionPlanIdValue = !!this.context?.commissionPlanIdValue;

		const minFee = this.context?.minFeeValueValue || 0;
		const minFeeActiveValue = this.context?.minFeeActiveValue;
		const maxFee = this.context?.maxFeeValueValue || 0;
		const maxFeeActiveValue = this.context?.maxFeeActiveValue;
		const isStartDate = this.context?.isStartDate;
		const setErrors = this.context?.setErrors;

		const errors = {
			startDate: !isStartDate,
			commissionPlanIdValue: !isCommissionPlanIdValue,
			minFeeValueValue: false,
			maxFeeValueValue: false,
		};

		if (minFeeActiveValue && maxFeeActiveValue) {
			errors.minFeeValueValue = minFee > maxFee;
			errors.maxFeeValueValue = maxFee < minFee;
		}

		if (setErrors) setErrors(errors);
		return (
			isNameValid &&
			isCommissionPlanIdValue &&
			!errors.maxFeeValueValue &&
			!errors.minFeeValueValue &&
			isStartDate
		);
	}
}
