import React, { useEffect, useRef } from "react";
import { ClickAwayListener } from "@mui/material";

import { useTypedSelector } from "../../../../../../../redux/store";
import { useChatActions } from "../../../../hooks";
import { Root, SearchBar } from "./components";

const Search: React.FC<Search.Props> = ({ tabIndex, onBlur, onFocus }) => {
	const { onChangeQuery } = useChatActions();

	const { chats } = useTypedSelector((state) => state.orderPageFilters);

	const inputRef = useRef<any>();

	useEffect(() => {
		if (inputRef.current) {
			setTimeout(() => {
				const input = inputRef.current.querySelector("input");
				if (tabIndex === 0) {
					input.focus();
				} else {
					input.blur();
				}
			}, 100);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabIndex]);
	return (
		<ClickAwayListener onClickAway={onBlur}>
			<Root ref={inputRef}>
				<SearchBar
					onBlur={onBlur}
					onChange={onChangeQuery}
					onFocus={onFocus}
					value={chats.filters.query}
				/>
			</Root>
		</ClickAwayListener>
	);
};

declare namespace Search {
	export interface Props {
		tabIndex: number;
		onBlur: () => void;
		onFocus: () => void;
	}
}

export default Search;
