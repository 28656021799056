import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Float,
	Popover as PopoverBase,
	react,
	useInternal,
} from "uikit";

import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

const defaultValue: Content.Value = {};

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	// TODO: fix useSize in Float/index.tsx, idk
	const floatSize = { width: 320, height: 242 }; // float.getSize();

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

const Popover = react.inputify<Popover.PropsBase, Popover.Value>(
	({ value, open, onChange, onClose }) => {
		const [internalValue, setInternalValue] = useInternal(
			value ?? defaultValue,
		);
		const { t } = useTranslation();

		const close = useCallback(
			(save = false) => {
				if (!save) setInternalValue(value ?? defaultValue);
				else onChange(internalValue);

				onClose();
			},
			[internalValue, onChange, onClose, setInternalValue, value],
		);

		const content = useMemo(
			() => (
				<Root align="stretch" gaps="16px 20px">
					<Header onClose={close} />
					<Content
						value={internalValue}
						onChange={setInternalValue}
					/>
					<Button.Button
						text={
							t(
								"pages.preferencesPages.screenDirectory.carModelsAndBrands.header.filters.popover.str0",
							) ?? ""
						}
						onClick={() => {
							close(true);
							onChange(internalValue);
						}}
					/>
				</Root>
			),
			[close, internalValue, onChange, setInternalValue, t],
		);

		return (
			<PopoverBase
				open={open}
				containerId="root"
				trackerId="filters"
				offset={additionalFiltersOffsetCallback}
				onClose={() => close()}
			>
				{content}
			</PopoverBase>
		);
	},
);

declare namespace Popover {
	type Value = Content.Value;

	interface PropsBase {
		open: boolean;

		onClose: () => void;
	}
}

export default Popover;
