/* eslint-disable import/no-unresolved */

import React, { MutableRefObject, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, react, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";
import { clone } from "lodash";
import { LatLngTuple } from "leaflet";

import Map from "../../../../../../../../../../redux/services/Map";
import useSizedArray from "../../../../../../../../../../hooks/useSizedArray";
import {
	Names,
	NamesBase,
} from "../../../../../../../../../../components/common";
// import NameForm from "../NameForm";
import PointForm from "../PointForm";
import Stroke from "../Stroke";
import TaxiServiceForm from "../TaxiServiceForm";
import GroupObjectForm from "../GroupObjectForm";

import PointsTitle from "./components/PointsTitle";
import InternalController from "./Controller";

const ObjectGroupForm = react.withController<
	ObjectGroupForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<
		ObjectGroupForm.PropsBaseWithController,
		ObjectGroupForm.Value
	>(({ value, language, controller, onChange, onDeletePoint }) => {
		const { t } = useTranslation();

		const taxiServiceFormRef = useRef<TaxiServiceForm.Controller | null>(
			null,
		);
		const nameFormRef = useRef<NamesBase.Controller | null>(null);
		const pointFormRef = useRef<PointForm.Controller | null>(null);

		const defaultValue = useMemo<ObjectGroupForm.Value>(() => ({}), []);

		const [internalValue] = useInternal(value ?? defaultValue);

		const groupObjectFormRefs = useSizedArray(
			internalValue.localObjects?.length ?? 0,
			() =>
				({
					current: null,
				} as MutableRefObject<GroupObjectForm.Controller | null>),
		);

		const taxiServiceFormOnChange = useCallback(
			(taxiServiceId: TaxiServiceForm.Value) =>
				onChange({ ...internalValue, taxiServiceId }),
			[internalValue, onChange],
		);

		const nameFormOnChange = useCallback(
			(name: NamesBase.Value) => {
				onChange({
					...internalValue,

					name,
				});
			},
			[internalValue, onChange],
		);

		const name = useMemo(
			() => internalValue?.name || {},
			[internalValue?.name],
		);

		controller.setContext({
			taxiServiceFormRef,
			nameFormRef,
			pointFormRef,
			groupObjectFormRefs,
		});

		return (
			<Column gaps="16px*2 20px*">
				<TaxiServiceForm
					ref={taxiServiceFormRef}
					value={internalValue.taxiServiceId}
					language={language}
					onChange={taxiServiceFormOnChange}
				/>
				<Stroke />

				<Names
					ref={nameFormRef}
					require
					value={name}
					onChange={nameFormOnChange}
					placeholder="pages.preferencesPages.screenDirectory.objects.editModal.objectGroupForm.str0"
				/>

				{/* <NameForm
					ref={nameFormRef}
					placeholder={
						t(
							"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupForm.str0",
						) ?? ""
					}
					value={internalValue?.name}
					onChange={nameFormOnChange}
				/> */}
				{internalValue.vertices && (
					<>
						<Stroke />
						<Row align="center" justify="space-between" gaps="10px">
							<PointsTitle>
								{t(
									"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupForm.str1",
								) ?? ""}
							</PointsTitle>
						</Row>
						{internalValue.localObjects?.map(
							(localObject, index) => (
								<GroupObjectForm
									ref={groupObjectFormRefs[index]}
									key={index}
									value={localObject}
									language={language}
									title={`${
										t(
											"pages.preferencesPages.screenDirectory.objects.editModal.objectGroupForm.str2",
										) ?? ""
									} ${index + 1}`}
									onChange={(newLocalObject) => {
										const newValue = clone(internalValue);

										const newValueLocalObjects = clone(
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											newValue.localObjects!,
										);

										newValueLocalObjects[index] =
											newLocalObject;

										newValue.localObjects =
											newValueLocalObjects;

										onChange(newValue);
									}}
									onDelete={() => {
										const newValue = clone(internalValue);

										const newValueLocalObjects = clone(
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											newValue.localObjects!,
										);

										newValueLocalObjects.splice(index, 1);

										newValue.localObjects =
											newValueLocalObjects;

										onChange(newValue);

										onDeletePoint?.(index);
									}}
								/>
							),
						)}
					</>
				)}
			</Column>
		);
	}),
	InternalController,
);

declare namespace ObjectGroupForm {
	interface Value {
		taxiServiceId?: number;

		name?: NamesBase.Value;
		localObjects?: GroupObjectForm.Value[];
		vertices?: LatLngTuple[];
	}

	interface PropsBase {
		language: Map.Language;

		onDeletePoint?: (index: number) => void;
	}

	interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default ObjectGroupForm;
