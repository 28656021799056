import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)<{ reduce?: boolean }>`
	padding: 0px 12px;

	height: ${({ reduce }) => (!reduce ? "64px" : "308px")};
	width: 100%;

	justify-content: flex-end;
	align-items: center;

	border-radius: 12px 12px 0px 0px;
	box-shadow: 0px -2px 6px 0px #0000001a;
`;

export default Root;
