import React, { memo, PropsWithChildren } from "react";
import { Icon, theme } from "uikit";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { StyledGrid } from "../../styles";

const DraggableArea = styled.div`
	cursor: grab;
`;

const ItemBase: React.FC<Item.Props> = ({ id, index, children }) => (
	<Draggable key={id} draggableId={id} index={index}>
		{(provided) => (
			<StyledGrid
				areas=""
				alignItems="center"
				gap="10px"
				columns="auto 1fr"
				bgC="#ffffff"
				w="100%"
				ref={provided.innerRef}
				{...provided.draggableProps}
			>
				<DraggableArea
					{...provided.dragHandleProps}
					tabIndex={undefined}
				>
					<Icon
						id="dragging"
						colors={[theme.colors.disabled_text]}
						size={12}
					/>
				</DraggableArea>

				{children}
			</StyledGrid>
		)}
	</Draggable>
);

const Item = memo(ItemBase);

declare namespace Item {
	interface Props extends PropsWithChildren {
		disabled?: boolean;
		id: string;
		index: number;
	}
}

export default Item;
