import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import tPath from "../../../../../../constants/tPath";
import OrderSource from "../../../../../Modal/components/Content/components/OrderSource";

const OrderSourceOutput: React.FC<OrderSourceOutput.Props> = memo(
	({ value }) => {
		const { t } = useTranslation();

		return value.active && value.sources.length ? (
			<>
				<div>
					{value.sources
						.map((s) =>
							t(`${tPath}.modal.orderSource.options.${s}`),
						)
						.join(", ")}
				</div>
				<span>/</span>
			</>
		) : null;
	},
);

declare namespace OrderSourceOutput {
	interface Props {
		value: OrderSource.Value;
	}
}

export default OrderSourceOutput;
