import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Row } from "uikit";

import Root from "./components/Root";

const Footer: React.FC<Footer.Props> = ({ onCancel, onSave }) => {
	const { t } = useTranslation();
	return (
		<Root gaps="10px" align="center" justify="end">
			<Row gaps="16px">
				<Button.Button
					style={{ padding: "9px 25px", height: "36px" }}
					variant="secondary"
					text={
						t(
							"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.footer.str0",
						) ?? ""
					}
					onClick={onCancel}
				/>
				<Button.Button
					style={{ padding: "9px 25px", height: "36px" }}
					text={
						t(
							"pages.preferencesPages.screenDirectory.sectors.content.list.content.group.header.modal.footer.str1",
						) ?? ""
					}
					onClick={onSave}
				/>
			</Row>
		</Root>
	);
};

declare namespace Footer {
	interface Props {
		onCancel: () => void;
		onSave: () => void;
	}
}

export default Footer;
