import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)`
	padding-inline: 4px;

	box-shadow: 1px 0px 4px 0px #00000033;

	z-index: 401;
`;

export default Root;
