import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	width: 100%;
	height: 100%;

	border-radius: ${theme.borderRadius.m};

	cursor: pointer;
`;

export default Root;
