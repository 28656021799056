import { useMemo } from "react";
import { isUndefined } from "lodash";

import Executor from "../../../../services/Executor";
import { Orders } from "../../../../redux/reducers/Orders";

const useFilteredExecutors = (
	executors: Executor.Model[],
	filters: Orders.Chat.ModalFilter,
): Executor.Model[] =>
	useMemo(() => {
		const canNotShow = true;
		const сanShow = false;

		type typeIdOrNot = number | undefined;
		// type typeId = number | string;
		// type typeArrId = (number | string)[];
		type typeId = number | "all";
		type typeArrId = (number | "all")[];

		const isAll = (arr?: typeArrId) =>
			Array.isArray(arr) && arr.length === 1 && arr[0] === "all";

		const isEmptyArr = (arr?: typeArrId) =>
			Array.isArray(arr) && arr.length === 0;

		const isCanSkipFilter = (arr?: typeArrId) =>
			isAll(arr) || isEmptyArr(arr);

		const toStr = (id: typeId) => id.toString();

		const filterByIds = (id: typeIdOrNot, filterIds: typeArrId) => {
			if (isUndefined(id)) return canNotShow;
			return filterIds.some((filterId) => toStr(filterId) === toStr(id));
		};

		const checkCompanyId = (
			// Company
			companyId: typeIdOrNot,
			companyIds: typeArrId,
		): boolean => {
			if (isCanSkipFilter(companyIds)) return сanShow;

			return !filterByIds(companyId, companyIds || []);
		};

		const checkTaxiServiceId = (
			// Fils
			taxiServiceId: typeIdOrNot,
			taxiServiceIds: typeArrId,
		): boolean => {
			if (isCanSkipFilter(taxiServiceIds)) return сanShow;

			return !filterByIds(taxiServiceId, taxiServiceIds || []);
		};

		const checkExecutorId = (
			// Executors
			executorId: typeId,
			executorIds: typeArrId,
		): boolean => {
			if (isCanSkipFilter(executorIds)) return сanShow;

			return !executorIds.includes(executorId);
		};

		const checkExecutorGroupId = (
			// Vehicle fleets
			groupId: typeIdOrNot,
			executorGroupIds: typeArrId,
		): boolean => {
			if (isCanSkipFilter(executorGroupIds)) return сanShow;

			return !filterByIds(groupId, executorGroupIds || []);
		};

		const checkFleetId = (
			// Brigade
			fleetId: typeIdOrNot,
			fleetIds: typeArrId,
		): boolean => {
			if (isCanSkipFilter(fleetIds)) return сanShow;

			return !filterByIds(fleetId, fleetIds || []);
		};

		const {
			executorIds,
			taxiServiceIds,
			companyIds,
			executorGroupIds,
			fleetIds,
		} = filters;

		return executors.filter((executor) => {
			const { id, taxiService, group, currentParking } = executor;
			const company = taxiService?.company;

			if (
				checkCompanyId(company?.id, companyIds) ||
				checkTaxiServiceId(taxiService?.id, taxiServiceIds) ||
				checkExecutorId(id, executorIds) ||
				checkExecutorGroupId(group?.id, executorGroupIds) ||
				checkFleetId(currentParking?.id, fleetIds)
			) {
				return false;
			}

			return true;
		});
	}, [executors, filters]);

export default useFilteredExecutors;
