import React, { memo } from "react";
import { Row, CheckBox } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import ImprovedStepper from "../../../../../../../../../../../../../../../../components/ImprovedStepper";
import tPath from "../../../../../../../../../../constants/tPath";

const Multiple: React.FC<Multiple.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const multipleValue = valueEditor.useGetter("value");
	const setMultipleValue = valueEditor.useSetter("value");

	return (
		<Row gaps="10px*" align="center" sizes="18% auto">
			<div>
				<CheckBox
					label={
						t(
							`${tPath}.modal.tabs.main.rounding.method.multiple`,
						) || ""
					}
					value={active}
					onChange={setActive}
				/>
			</div>
			<ImprovedStepper
				value={multipleValue}
				onChange={setMultipleValue}
			/>
		</Row>
	);
});

declare namespace Multiple {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		value: number;
	}
}

export default Multiple;
