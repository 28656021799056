import styled from "styled-components";
import { InputBorders, TextBox } from "uikit";

const SearchTextBox = styled(TextBox.TextBox)`
	.${InputBorders.classes.borders} {
		padding: 6px 8px 6px 11px;
	}

	.${TextBox.classes.input} {
		line-height: 18px;
		width: 100%;
		height: 18px;
	}
`;

export default SearchTextBox;
