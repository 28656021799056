/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import map from "../../../../../../redux/reducers/settings/map";
import { ModelId } from "../../../../../../types/ModelId";
import Modal from "./components/Modal";
import Root from "./components/Root";
import Content from "./components/Content";
import MapSettings from "../../../../../../services/MapSettings";

const Main = () => {
	const dispatch = useTypedDispatch();
	const lang = useTypedSelector((state) => state.session.language);

	const [data, setData] = useState<MapSettings.Model[]>([]);

	useEffect(() => {
		MapSettings.getAll().then((res) => {
			setData(res);
		});
	}, []);

	const [showModal, setShowModal] = useState(false);

	const [selected, setSelected] = useState<ModelId[]>([]);

	const editHeaderHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const editContentHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
	}, []);

	const saveHandler = useCallback(
		async (settings: MapSettings.Model) => {
			await MapSettings.update(settings);
			const res = await MapSettings.getAll();
			setData(res);
			if (res?.[0]) {
				const settings = res[0].settings?.general?.defaultSearchType;
				dispatch(map.actions.setDefaultSearchType(settings));
			}
			setShowModal(false);
		},
		[dispatch],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				buttons={[
					{
						icon: { id: "pencil", size: 20 },
						onClick: editHeaderHandler,
						disabled: selected.length !== 1,
					},
				]}
			/>
			<Content
				value={data}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={cloneDeep(data[0])}
					headerTitle={data[0]?.name?.[lang] ?? ""}
				/>
			)}
		</Root>
	);
};

export default Main;
