import { useMemo } from "react";

import TaxiService from "../services/TaxiService2";
import { useTypedSelector } from "../redux/store";

function useCurrentTaxiServices() {
	const defaultTaxiService = useTypedSelector(
		(state) => state.account.user?.defaultTaxiService,
	);

	const processedDefaultTaxiService = useMemo(
		() => TaxiService.fromResponse(defaultTaxiService),
		[defaultTaxiService],
	);

	const currentTaxiService = useTypedSelector((state) =>
		state.taxiServices.models.find(
			(taxiService) =>
				taxiService.id === state.account.user?.defaultTaxiService.id,
		),
	);

	return currentTaxiService ?? processedDefaultTaxiService;
}

export default useCurrentTaxiServices;
