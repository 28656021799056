import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "uikit";

import { useTypedSelector } from "../../../../../../redux/store";
import CarClass from "../../../../../../services/CarClass";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import SelectWithModal from "../../../SelectWithModal";
import BasePassenger from "../..";

const CarClasses: React.FC<CarClasses.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const modelData = useModelSubscribe({}, CarClass);
	const language = useTypedSelector((state) => state.session.language);

	const valueEditor = useObjectEditor(value, onChange);
	const carClassId = valueEditor.useGetter("carClassId");
	const setCarClassId = valueEditor.useSetter("carClassId");
	const setCarClassName = valueEditor.useSetter("carClassName");

	const items = useMemo(() => modelData.models, [modelData.models]);

	const selectOptions = useMemo<Option<number>[]>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[language],
				value: item.id,
			})),
		[items, language],
	);

	const selectOnChange = useCallback(
		(newValue: SelectWithModal.Value<number>) => {
			setCarClassId(newValue as number | undefined);
			const exist = selectOptions.find((item) => item.key === newValue);
			if (exist) setCarClassName(exist.label);
		},
		[selectOptions, setCarClassId, setCarClassName],
	);

	const title = useMemo(
		() =>
			t([
				`mainPage.customers.clients.main.additionalData.vehicleClass`,
				"Transport class",
			]),
		[t],
	);

	return (
		<SelectWithModal
			value={carClassId || undefined}
			title={title}
			options={selectOptions}
			onChange={selectOnChange}
			onSubmit={selectOnChange}
			pullUpItemInsideArray
		/>
	);
};

declare namespace CarClasses {
	interface Props {
		value: BasePassenger.Value;
		onChange: Dispatch<BasePassenger.Value>;
	}
}

export default CarClasses;
