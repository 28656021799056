import { createContext } from "react";

const Context = createContext<Context.Value | null>(null);

declare namespace Context {
	interface Value {
		layerId: string;
	}
}

export default Context;
