import { theme } from "uikit";
import { TFunction } from "i18next";

import { RegisteredIcon } from "../../assets/icons";

interface CallData {
	primaryColor: string;
	secondaryColor: string;
	text: string;
	iconName: RegisteredIcon;
}
interface LatestCallData {
	iconColor: string;
	iconName: RegisteredIcon;
}

const getCallTypes = (t: TFunction): { [key: string]: CallData } => ({
	incoming: {
		primaryColor: "#4CAF50",
		secondaryColor: "#dbefdc",
		text: t("softphoneWidget.incoming") ?? "",
		iconName: "phone-call-incoming",
	},
	outgoing: {
		primaryColor: theme.colors.accent,
		secondaryColor: "#E3F1F7",
		text: t("softphoneWidget.outgoing") ?? "",
		iconName: "phone-call-outgoing",
	},
	pause: {
		primaryColor: "#F2BD00",
		secondaryColor: "#fdf4d4",
		text: t("softphoneWidget.pause") ?? "",
		iconName: "phone-pause",
	},
	current: {
		primaryColor: "#8000FF",
		secondaryColor: "#F0E0FF",
		text: t("softphoneWidget.current") ?? "",
		iconName: "phone-call-transfer",
	},
}); // callTypes

export const latestCallTypes: { [key: string]: LatestCallData } = {
	IN_ANSWER: {
		iconName: "phone-call-incoming",
		iconColor: "#4CAF50",
	},
	IN_COME: {
		iconName: "phone-call-incoming",
		iconColor: "#4CAF50",
	},
	IN_NO_ANSWER: {
		iconName: "phone-call-incoming",
		iconColor: theme.colors.negative,
	},
	OUT_ANSWER: {
		iconName: "phone-call-outgoing",
		iconColor: theme.colors.accent,
	},
	OUT_COME: {
		iconName: "phone-call-outgoing",
		iconColor: theme.colors.accent,
	},
	OUT_NO_ANSWER: {
		iconName: "phone-call-outgoing",
		iconColor: theme.colors.negative,
	},
	NEW_CALL: {
		iconName: "phone-call-outgoing",
		iconColor: theme.colors.accent,
	},
	IN_RECALLED: {
		iconName: "phone-call-outgoing",
		iconColor: theme.colors.accent,
	},
};

export default getCallTypes;
