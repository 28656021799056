import React, { memo, useMemo, useCallback } from "react";
import { Trans } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";
import { StyledColumn, StyledRow } from "../../../../../../components/common";
import Mark from "../../Mark";
import Marker from "../../Marker";

const CostDiff: React.FC<CostDiff.Props> = ({ change }) => {
	const modify = useCallback<(data: CostDiff.Modify) => CostDiff.Item | null>(
		(data) => {
			try {
				const symbol = change.actual?.currency?.settings?.name;
				const i18nKey = "change_history.orders.fields.cost";
				if (data.context === "add") {
					return {
						context: data.context,
						i18nKey: `${i18nKey}.${data.context}.${data.stage}`,
						value: `${data.value?.toFixed(2)} ${symbol}`,
					};
				}
				const preSymbol = change.previous?.currency?.settings?.name;
				return {
					context: data.context,
					i18nKey: `${i18nKey}.${data.context}.${data.stage}`,
					new: `${data.new?.toFixed(2)} ${preSymbol || symbol}`,
					prev: `${data.prev?.toFixed(2)} ${symbol}`,
				};
			} catch {
				return null;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const price = useMemo<CostDiff.Item | null>(() => {
		if (!change.actual) return null;
		if (change.previous?.value) {
			return modify({
				stage: "price",
				context: "change",
				prev: change.previous?.value,
				new: change.actual?.value,
			});
		}
		return modify({
			stage: "price",
			context: "add",
			value: change.actual?.value,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [change]);

	const items = useMemo<CostDiff.Items>(() => {
		const payload: CostDiff.Items = [];
		if (!change.actual) return payload;

		change.actual?.stages.forEach(({ stage, value }) => {
			const data: CostDiff.Modify = { context: "add", stage, value };
			if (change.previous?.stages?.length) {
				change.previous.stages?.forEach((prev) => {
					if (prev.stage === stage && prev.value !== value) {
						const item = modify({
							stage,
							context: "change",
							prev: prev.value,
							new: value,
						});
						if (item) payload.push(item);
					}
				});
			} else {
				const item = modify(data);
				if (item) payload.push(item);
			}
		});

		return payload;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [change]);

	if (!price) return <></>;
	return (
		<>
			{price.context === "add" && (
				<Trans
					i18nKey={price.i18nKey}
					context={price.context}
					values={{ value: price.value }}
					components={{ mark: <Mark /> }}
				/>
			)}
			{price.context === "change" && (
				<Trans
					i18nKey={price.i18nKey}
					context={price.context}
					values={{
						prev: price.prev,
						new: price.new,
					}}
					components={{ mark: <Mark /> }}
				/>
			)}

			<StyledColumn style={{ paddingLeft: "10px" }}>
				{!!items.length &&
					items.map((item, i) => {
						switch (item.context) {
							case "add":
								return (
									<StyledRow key={i}>
										<Marker />
										<Trans
											i18nKey={item.i18nKey}
											context={item.context}
											values={{ value: item.value }}
											components={{ mark: <Mark /> }}
										/>
									</StyledRow>
								);
							case "change":
								return (
									<StyledRow key={i}>
										<Marker />
										<Trans
											i18nKey={item.i18nKey}
											context={item.context}
											values={{
												prev: item.prev,
												new: item.new,
											}}
											components={{ mark: <Mark /> }}
										/>
									</StyledRow>
								);
							default:
								return <></>;
						}
					})}
			</StyledColumn>
		</>
	);
};

declare namespace CostDiff {
	interface Props {
		change: History.Field.CostChange;
	}
	interface ItemAdd {
		context: "add";
		i18nKey: string;
		value: string;
	}
	interface ItemChange {
		context: "change";
		i18nKey: string;
		prev: string;
		new: string;
	}
	interface Modify {
		stage: string;
		context: "add" | "change";
		value?: number;
		new?: number;
		prev?: number;
	}

	type Item = ItemAdd | ItemChange;
	type Items = Item[];
}

export const CostDiffMemo = memo(CostDiff);
export default CostDiff;
