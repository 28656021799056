import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	NAME = "name",
	COMPANIES = "companies",
	BRANCHES = "branches",
	TARIFF_GROUP_TYPE = "tariffGroupType",
	AUTO_CLASSES = "autoClasses",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.NAME, label: "", width: 200 },
	{ id: Columns.COMPANIES, label: "", width: 220 },
	{ id: Columns.BRANCHES, label: "", width: 300 },
	{ id: Columns.TARIFF_GROUP_TYPE, label: "", width: 220 },
	{ id: Columns.AUTO_CLASSES, label: "" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
