import React, {
	Dispatch,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { uniq } from "lodash";

import Sector from "../../../../services/Sector";
import { useTypedSelector } from "../../../../redux/store";
import useModelSubscribe from "../../../../hooks/useModelSubscribe";
import { createObjectLanguageNames } from "../../../../assets/languages/langs";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectSectorWithModalBase: React.FC<
	MultiSelectSectorWithModalBase.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	error = "",
	sort,
	additionData = [],
	addOutsideSector,
	missingSector,
	setMissingSector,
	...props
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const subscribe = useModelSubscribe({}, Sector);

	const [errorInternal, setErrorInternal] = useState<string | boolean>("");

	useEffect(() => {
		setErrorInternal(error);
	}, [error]);

	const outsideSectorName = useMemo(() => t([`outsideSectors`]) || "", [t]);

	const addOutsideSectorId = useMemo(() => -111, []);

	const outsideSector = useMemo(() => {
		const payload: Sector.Model = {
			name: createObjectLanguageNames(outsideSectorName),
			id: addOutsideSectorId,
			taxiServiceId: 0,
			position: 0,
			vertices: [],
			isMaxSpeedEnabled: false,
			maxSpeed: 0,
			active: false,
			availableForExecutor: false,
			isParking: false,
			isPriceZone: false,
			createdAt: "",
			updatedAt: "",
			deletedAt: null,
		};

		return payload;
	}, [addOutsideSectorId, outsideSectorName]);

	const items = useMemo(() => {
		if (addOutsideSector) {
			return [
				outsideSector,
				...additionData,
				...(subscribe?.cache || []),
			];
		}

		return [...additionData, ...(subscribe?.cache || [])];
	}, [addOutsideSector, additionData, subscribe?.cache, outsideSector]);

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[lang],
				value: item.id,
				name: item.name?.[lang],
				data: item,
			})),
		[items, lang],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(() => {
		if (addOutsideSector && missingSector) {
			const addIds = uniq([addOutsideSectorId, ...value]);
			return selectOptions.filter((item) =>
				addIds.find((data) => data === item.value),
			);
		}

		return selectOptions.filter((item) =>
			value.find((data) => data === item.value),
		);
	}, [
		addOutsideSector,
		addOutsideSectorId,
		missingSector,
		selectOptions,
		value,
	]);

	const selectOnChange = useCallback(
		(newValue) => {
			setErrorInternal("");

			const ids: number[] = newValue.map((item) => item.value);

			const exist = ids.find((id) => id === addOutsideSectorId);
			if (exist) setMissingSector?.(true);
			else setMissingSector?.(false);

			const newIds = ids.filter((id) => id !== addOutsideSectorId);
			onChange(newIds);
		},
		[addOutsideSectorId, onChange, setMissingSector],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t(["all_sectors"]),
			allActiveText: t(["all_active_sectors"]),
		}),
		[t, titleText],
	);

	const allText = useMemo(
		() =>
			addOutsideSector && missingSector
				? `${texts.allText} + ${outsideSectorName}`
				: texts.allText,
		[addOutsideSector, missingSector, outsideSectorName, texts.allText],
	);

	const allActiveText = useMemo(
		() =>
			addOutsideSector && missingSector
				? `${texts.allActiveText} + ${outsideSectorName}`
				: texts.allActiveText,
		[
			addOutsideSector,
			missingSector,
			outsideSectorName,
			texts.allActiveText,
		],
	);

	const lengthValue = useMemo(() => {
		const existAddOptions = selectOptions?.find?.(
			(item) => item.value === addOutsideSectorId,
		);
		const existAddValue = selected?.find?.(
			(item) => item.value === addOutsideSectorId,
		);

		const optionsLength =
			existAddOptions && !existAddValue
				? (selectOptions?.length || 1) - 1
				: selectOptions?.length;

		return {
			optionsLength,
			valueLength: selected.length,
		};
	}, [addOutsideSectorId, selectOptions, selected]);

	return (
		<MultiSelectWithModal
			value={selected}
			onChange={selectOnChange}
			options={selectOptions}
			error={errorInternal}
			modalSetting={modalSetting}
			disabled={disabled}
			title={texts.title}
			allText={allText}
			allActiveText={allActiveText}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			optionsLength={lengthValue.optionsLength}
			valueLength={lengthValue.valueLength}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectSectorWithModal = memo(MultiSelectSectorWithModalBase);

declare namespace MultiSelectSectorWithModalBase {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		additionData?: Sector.Model[];
		addOutsideSector?: boolean;
		missingSector?: boolean;
		setMissingSector?: Dispatch<boolean>;
	}

	type Value = number[];
}

export default MultiSelectSectorWithModalBase;
