import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

import TaxiService from "../../services/TaxiService2";

interface State {
	models: TaxiService.Model[];
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setModels: Reducer<State["models"]> = (state, { payload }) => {
	state.models = payload;
};

const initialState: State = {
	models: [],
};

const taxiServices = createSlice({
	name: "taxiServices",
	initialState,
	reducers: {
		setModels,
	},
});

export default taxiServices;
