import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "uikit";

import { OrderFormProps } from "../../types/FormProps";
import { Primary, Green } from "../styled";
import { StyledRow } from "../../../../../../../../components/common";

const Car: React.FC<OrderFormProps> = ({ tab }) => {
	const { t } = useTranslation();

	const sip = useMemo(() => tab.form?.executorSip, [tab.form?.executorSip]);
	const phone = useMemo(
		() => tab.form?.executorPhone,
		[tab.form?.executorPhone],
	);
	const name = useMemo(
		() => tab.form?.executorName,
		[tab.form?.executorName],
	);
	const carName = useMemo(
		() => tab.form?.executorCarName,
		[tab.form?.executorCarName],
	);

	const text = useMemo(
		() => [sip, name, phone, carName].filter(Boolean).join(", "),
		[carName, name, phone, sip],
	);

	return (
		<StyledRow
			p="0 12px"
			h="28px"
			bgC={`${theme.colors.success}26`}
			alignItems="center"
			gap="14px"
		>
			<Primary>{`${t([`orderPage.order_form.car`, "Car"])}:`}</Primary>
			<Green style={{ fontWeight: 600 }}>{text}</Green>
		</StyledRow>
	);
};

export default Car;
