import React, { useMemo } from "react";

import CarClass from "../../../../../../../../../../services/CarClass";
import Language from "../../../../../../../../../../services/Language";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { CompaniesByIds } from "../../../../../../../../../../components/common";

const CompanyCellContent: React.FC<CompanyCellContent.Props> = ({
	item,
	language,
}) => {
	const taxiServiceIds = useMemo(() => item.taxiServiceIds || [], [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<CompaniesByIds taxiServiceIds={taxiServiceIds} lang={language} />
		</CellContentRoot>
	);
};

declare namespace CompanyCellContent {
	interface Props {
		item: CarClass.Model;
		language: Language;
	}
}

export default CompanyCellContent;
