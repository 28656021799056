import React, { Dispatch } from "react";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledRow,
	Tab,
} from "../../../../../../../../../../../../../../../../../../../../components/common";

import AverageSpeed from "../AverageSpeed";
import Main from "../Main";
import EditModal from "../..";

const Content: React.FC<Content.Props> = ({ value, onChange, type }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const averageSpeed = valueEditor.usePicker([
		"isBasicAverageSpeedEnabled",
		"averageSpeed",
	]);
	const main = valueEditor.usePicker([
		"isMaxSpeedEnabled",
		"maxSpeed",
		"isBasicAverageSpeedEnabled",
		"isAverageSpeedEnabled",
		"basicAverageSpeed",
		"averageSpeed",
	]);

	const assigner = valueEditor.useAssigner();

	return (
		<StyledRow>
			<Tab visible={type === "main"}>
				<Main value={main} onChange={assigner} />
			</Tab>
			<Tab visible={type === "averageSpeed"}>
				<AverageSpeed value={averageSpeed} onChange={assigner} />
			</Tab>
		</StyledRow>
	);
};

declare namespace Content {
	interface Value extends AverageSpeed.Value, Main.Value {}

	interface Props {
		value: Value;
		type: EditModal.Type;
		onChange: Dispatch<Value>;
	}
}

export default Content;
