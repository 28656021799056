import React, { memo } from "react";
import OrdersCounter from "../../../../../../../Modal/components/Content/components/Sectors/components/OrdersCounter";
import RangeOutput from "./RangeOutput";

const OrdersCounterOutput: React.FC<OrdersCounterOutput.Props> = memo(
	({ value }) => (value.active ? <RangeOutput value={value.range} /> : null),
);

declare namespace OrdersCounterOutput {
	interface Props {
		value: OrdersCounter.Value;
	}
}

export default OrdersCounterOutput;
