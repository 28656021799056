/* eslint-disable prettier/prettier */
import { string } from "yup";

export enum CodePhone {
	UK = "38",
	AZE = "994",
	PLN = "48",
}

/** Validate phone number
 * https://vdmt.com.ua/codes_of_mobile_operators
 * https://minfin.com.ua/telecom/european-country-dialing-codes
 * https://github.com/skotniczny/phonePL
 * https://www.npmjs.com/package/google-libphonenumber
 * @param phone - the phone number as a string
 */
export const validatePhone = (phone: string): boolean => {
	if (!phone) return false;

	// Ukraine
	if (phone.substring(0, 2) === CodePhone.UK) {
		// '^380(?:39|50|6[3678]|73|9[356789])\\d{7}$'
		const ukPhone = string().matches(
			/^\+?\b([38]){2}(050|048|066|095|099|063|073|093|070|700|090|900|067|068|096|097|098|091|092|020|094|089|039|031|032|033|034|035|036|038|041|042|043|044|045|046)[0-9]{7}\b$/,
		);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Azerbaijan
	if (phone.substring(0, 3) === CodePhone.AZE) {
		const azPhone = string().matches(
			/^\+?\b([994]){3}(050|051|055|077|070)[0-9]{6,7}\b$/,
		);
		const isValid = azPhone.isValidSync(phone);
		return isValid;
	}

	// Poland
	if (phone.substring(0, 2) === CodePhone.PLN) {
		const plPhone = string().matches(
			/^\+?\b([48]){2}(45|50|51|53|57|60|66|69|72|73|78|79|88)[0-9]{7}\b$/,
		);
		const isValid = plPhone.isValidSync(phone);
		return isValid;
	}

	// General
	const general: string[] = ["050", "070"];
	if (general.includes(phone.substring(0, 3))) {
		const ukPhone = string().matches(/^\+?\b(050|070)[0-9]{6,7}\b$/);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Azerbaijan
	const az: string[] = ["050", "051", "055", "077", "070"];
	if (az.includes(phone.substring(0, 3))) {
		const ukPhone = string().matches(/^\+?\b(051|055|077)[0-9]{6,7}\b$/);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Ukraine
	const uk: string[] = [
		"020", // 2
		"039", // 3
		"031",
		"032",
		"033",
		"034",
		"035",
		"036",
		"038",
		"041", // 4
		"042",
		"043",
		"044",
		"045",
		"046",
		"048",
		"050", // 5
		"066", // 6
		"063",
		"067",
		"068",
		"073", // 7
		"070",
		"700",
		"089", // 8
		"900", // 9
		"090",
		"091",
		"092",
		"093",
		"094",
		"095",
		"096",
		"097",
		"098",
		"099",
	];

	if (uk.includes(phone.substring(0, 3))) {
		const ukPhone = string().matches(
			/^\+?\b(020|039|031|032|033|034|035|036|038|041|042|043|044|045|046|048|050|066|063|067|068|073|070|700|089|900|090|091|092|093|094|095|096|097|098|099])[0-9]{7}\b$/,
		);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Poland
	const pl: string[] = [
		"26",
		"39",
		"47",
		"45",
		"50",
		"51",
		"53",
		"57",
		"60",
		"66",
		"69",
		"72",
		"73",
		"78",
		"79",
		"88",
	];
	if (pl.includes(phone.substring(0, 2))) {
		const plPhone = string().matches(
			/^\+?\b(26|39|47|45|50|51|53|57|60|66|69|72|73|78|79|88)[0-9]{7}\b$/,
		);
		const isValid = plPhone.isValidSync(phone);
		return isValid;
	}

	return false;
};

export const countryCodePhone = (
	phone: string,
	country: "az" | "uk" | "pl",
): string => {
	// Azerbaijan
	const az: string[] = ["050", "051", "055", "077", "070"];
	if (az.includes(phone.substring(0, 3)) && country === "az") {
		return `${CodePhone.AZE}${phone}`;
	}

	// Ukraine
	const uk: string[] = [
		"020", // 2
		"039", // 3
		"031",
		"032",
		"033",
		"034",
		"035",
		"036",
		"038",
		"041", // 4
		"042",
		"043",
		"044",
		"045",
		"046",
		"048",
		"050", // 5
		"066", // 6
		"063",
		"067",
		"068",
		"073", // 7
		"070",
		"700",
		"089", // 8
		"900", // 9
		"090",
		"091",
		"092",
		"093",
		"094",
		"095",
		"096",
		"097",
		"098",
		"099",
	];

	if (uk.includes(phone.substring(0, 3)) && country === "uk") {
		return `${CodePhone.UK}${phone}`;
	}

	// Poland
	const pl: string[] = [
		"26",
		"39",
		"47",
		"45",
		"50",
		"51",
		"53",
		"57",
		"60",
		"66",
		"69",
		"72",
		"73",
		"78",
		"79",
		"88",
	];
	if (pl.includes(phone.substring(0, 2)) && country === "pl") {
		return `${CodePhone.PLN}${phone}`;
	}

	return phone;
};

export default validatePhone;
