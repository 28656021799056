import styled from "styled-components";
import { theme } from "uikit";

const RadioButtonSelector = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;

	top: -5px;
	left: -5px;

	height: 26px;
	width: 26px;

	border: 2px solid ${theme.colors.accent};
	border-radius: 50%;
	pointer-events: none;
`;

export default RadioButtonSelector;
