import React from "react";

import SettlementCellContent from "../components/SettlementCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function settlement({ width, onResize }: Column.Options) {
	return base(
		ColumnId.Settlement,
		(item) => <SettlementCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default settlement;
