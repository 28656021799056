import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import { Button } from "../common";

const EntityStatusButtons: React.FC<EntityStatusButtons.Props> = ({
	isShowActive,
	isNotShowActive,
	onShowActive,
	onShowNotActive,
	titlesActive,
	titlesNotActive,
}) => {
	const { t } = useTranslation();

	const buttonConfig = useMemo(
		() => [
			{
				isActive: isShowActive,
				onClick: onShowActive,
				title:
					t(titlesActive || "entityStatusButtons.showActive") ?? "",
			},
			{
				isActive: isNotShowActive,
				onClick: onShowNotActive,
				title: t(
					(titlesNotActive || "entityStatusButtons.showNotActive") ??
						"",
				),
			},
		],
		[
			isShowActive,
			isNotShowActive,
			onShowActive,
			onShowNotActive,
			t,
			titlesActive,
			titlesNotActive,
		],
	);

	return (
		<>
			{buttonConfig.map(({ isActive, onClick, title }, index) => (
				<Button
					key={index}
					variant={isActive ? "primary" : "secondary"}
					disabled={false}
					onClick={onClick}
					title={title}
					h={{
						height: "32px",
					}}
					w={{ min: "32px" }}
				>
					<Icon
						style={{ fill: isActive ? "#ffffff" : "#657079" }}
						id="counterpart"
						size={20}
					/>
				</Button>
			))}
		</>
	);
};

declare namespace EntityStatusButtons {
	interface Props {
		isShowActive: boolean;
		isNotShowActive: boolean;
		onShowActive: () => void;
		onShowNotActive: () => void;
		titlesActive?: string;
		titlesNotActive?: string;
	}
}

export default EntityStatusButtons;
