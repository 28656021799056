import React, { useMemo } from "react";
import moment from "moment";
import { isUndefined } from "lodash";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutingStageArrivedTimeCellContent: React.FC<
	ExecutingStageArrivedTimeCellContent.Props
> = ({ item }) => {
	const timestamp = useMemo(
		() => item.executingStages?.arrived?.timestamp,
		[item.executingStages?.arrived?.timestamp],
	);

	const content = useMemo(
		() =>
			isUndefined(timestamp) ? "" : moment(timestamp).format("HH:mm:ss"),
		[timestamp],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutingStageArrivedTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutingStageArrivedTimeCellContent;
