import { LatLngLiteral } from "leaflet";

import {
	defaultLanguage,
	getValueByLanguageKey,
	Language,
} from "../../../assets/languages/langs";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import { getPRPC } from "../../../hooks/usePRPC";
import { store } from "../../store";
import SearchType, { TypeSearch } from "../../../types/SearchType";
// import language from "../../reducers/language";

export const ConstantGeometry = {
	LINE_STRING: "LineString",
	POLYGON: "Polygon",
	MULTI_POLYGON: "MultiPolygon",
} as const;
export type TypeGeometry = typeof ConstantGeometry;
export type ValueGeometry =
	(typeof ConstantGeometry)[keyof typeof ConstantGeometry];

export interface StreetHouses {
	id: number;
	number: string;
}
export interface Geometry {
	type: ValueGeometry | string;
	coordinates: LatLngLiteral[];
}

export interface MapAddress {
	id?: number;

	bbox: LatLngLiteral[];
	coordinates: LatLngLiteral;
	country: string;
	countryCode: string;
	geometry: Geometry;

	settlement: string;
	settlementClass: string;
	settlementType: string;

	street?: string;
	streetHouses?: StreetHouses[];
	streetType?: string;
	distanceMeters?: number;

	type: SearchType;
	number?: any;
	name?: string;
	address?: string;
	district?: string;
	house?: string;
	region?: string;
}

export interface Settlement
	extends Omit<
		MapAddress,
		| "region"
		| "geometry"
		| "type"
		| "settlement"
		| "settlementClass"
		| "settlementType"
	> {
	settlement: string;
	settlementClass: string;
	settlementType: string;
	region: string;
	geometry: { type: TypeGeometry["POLYGON"]; coordinates: LatLngLiteral[] };
	type: TypeSearch["SETTLEMENT"];
}

export interface Street
	extends Omit<
		MapAddress,
		| "region"
		| "geometry"
		| "type"
		| "settlement"
		| "settlementClass"
		| "settlementType"
		| "distanceMeters"
	> {
	distanceMeters: number;
	geometry: {
		type: TypeGeometry["LINE_STRING"];
		coordinates: LatLngLiteral[];
	};

	settlement: string;
	settlementClass: string;
	settlementType: string;

	street: string;
	streetHouses: StreetHouses[];
	streetType: string;

	type: TypeSearch["STREET"] | TypeSearch["ADDRESS"];
}

export type Address = StreetHouses[];

export interface IPoint {
	lat: number;
	lng: number;
}

export interface IMapNearParams {
	point: IPoint;
	zoom?: number;
	radius?: number;
}

export interface ISearchInterestsParams {
	customerId?: number;
	searchType: SearchType | SearchType[];
	country?: string;
	near?: IMapNearParams;
	lang?: Language;
}

/**
	search(query: string, params: Partial<ISearchInterestsParams>);
 */
export default async function search(query, params: ISearchInterestsParams) {
	const prpcow = getPRPC();

	if (!prpcow) return null;

	return createRPCQuery(() => prpcow.theirsModel.map.search(query, params), {
		silent: true,
	}).then((result) => {
		if (!Array.isArray(result?.objects)) return [];

		return result.objects.map((item) => {
			const language =
				store.getState().session.language || defaultLanguage;

			const payload =
				getValueByLanguageKey({
					value: item?.properties?.language,
					language,
				}) || {};

			return {
				...payload,
				coordinates: item.properties?.geoCentroid,
				bbox: item?.properties?.bbox,
				geometry: item?.properties?.geometry,
				type: item?.type,
			};
		});
	});
}
