import getOptions from "../../../../../../../../utils/generateOptions";

export enum MainTariffTabs {
	MAIN = "main",
	SERVICES = "services",
	TARIFF_SCALE = "tariffScale",
	FIXED_TARIFFS = "fixedTariffs",
}

export const tabs = getOptions(Object.values(MainTariffTabs));
