const linksConstants = {
	index: "/",
	preferences: {
		index: "/preferences",
		objects: "/preferences/objects",
		polygons: "/preferences/polygons",
		parkings: "/preferences/parkings",
		pricesZone: "/preferences/pricesZone",
		sectors: "/preferences/sectors",
		carSettings: "/preferences/carSettings",
		colors: "/preferences/colors",
		carClass: "/preferences/carClass",
		carTypes: "/preferences/carTypes",
		carServices: "/preferences/carServices",
		smsTemplates: "/preferences/smsTemplates",
	},
	settings: {
		index: "/settings",
	},
};

export default Object.seal(linksConstants);
