import React from "react";
import Root from "./component/Root";
import ColorCircle from "./component/ColorCircle";
import Name from "./component/Name";
import Bind from "./component/Bind";

const Item: React.FC<Item.Props> = ({ selected, color, name, index, onClick }) => (
	<Root align="center" gaps="12px*" sizes="auto! 1fr auto!" onClick={onClick}>
		<ColorCircle selected={selected} color={color} />
		<Name selected={selected}>{name}</Name>
		<Bind>Ctrl+{index + 1}</Bind>
	</Root>
);

declare namespace Item {
	interface Props {
		selected: boolean;

		color: string;
		name: string;
		index: number;

		onClick: VoidFunction;
	}
}

export default Item;
