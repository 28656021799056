import { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { History, Location, Blocker } from "history";

import useNavigationBlocker from "./useNavigationBlocker";

function useNavigationPrompt(when: boolean) {
	const navigate = useHistory() as History;
	const location = useLocation() as Location;
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
		setLastLocation(null);
	}, []);

	const handleBlockedNavigation = useCallback<Blocker>(
		(nextLocation) => {
			if (
				!confirmedNavigation &&
				(nextLocation as any).pathname !== location.pathname
			) {
				setShowPrompt(true);
				setLastLocation(nextLocation as unknown as Location);

				return false;
			}

			return true;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[confirmedNavigation, location],
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate.push(lastLocation.pathname);

			setConfirmedNavigation(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmedNavigation, lastLocation]);

	useNavigationBlocker(handleBlockedNavigation, when);

	return [showPrompt, confirmNavigation, cancelNavigation] as [
		showPrompt: typeof showPrompt,
		confirmNavigation: typeof confirmNavigation,
		cancelNavigation: typeof cancelNavigation,
	];
}

export default useNavigationPrompt;
