import { RefObject } from "react";
import { react } from "uikit";
import TonAndNpiData from ".";

interface Context {
	tonAndNpiDataRef: RefObject<TonAndNpiData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isTonAndNpiDataValid =
			!!this.context?.tonAndNpiDataRef.current?.validate();
		// const isFullNameValid = !!this.context?.fullNameRef.current?.validate();

		return isTonAndNpiDataValid;
		// return isAuthorizationValid && isFullNameValid;
	}
}
