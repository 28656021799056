import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { Button, Icon, theme } from "uikit";

import { useTypedSelector } from "../../../../../redux/store";
import { gray } from "../../../../../constants/styles/colors";
import { KEYBOARD_CODES } from "../../../../../constants/business";
import Loader from "../../../../Loader";
import { StyledRow, StyledColumn } from "../../../../common";
import { messagesType } from "../../constants/business";
import { useChatActions } from "../../hooks";
import { MarkedUserSendVersion } from "..";

const ButtonOnModal = styled(Button.Button)<{ disabled?: boolean }>`
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	background-color: ${(props) =>
		props.disabled ? gray[5] : theme.colors.accent};
`;

const Root = styled(StyledColumn)`
	display: flex;
	height: auto;
	width: 100%;
	padding: 15px 12px 12px;
	border-top: 1px solid ${theme.colors.color_border_basic};
`;

const InputContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;

const CustomStyledRow = styled(StyledRow)<{
	align?: string | undefined | null;
}>`
	align-items: ${({ align }) => align ?? "center"};
	cursor: ${({ align }) => (align ? "not-allowed" : "auto")};
`;

const StyledTextarea = styled(TextareaAutosize)`
	flex: 1;
	caret-color: ${theme.colors.accent};
	width: 100%;
	padding: 10px;
	font-family: Lato;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	color: ${theme.colors.primary};
	border: 1px solid ${theme.colors.color_border_basic};
	border-radius: 4px;
	resize: none;
	background-color: transparent;
	overflow-y: hidden;
	&:focus {
		outline: none;
		border-color: ${theme.colors.accent};
	}
`;

const ButtonSendMessage = styled.button<{ disabled?: boolean }>`
	background-color: ${(props) =>
		props.disabled ? gray[5] : theme.colors.accent};
	border: none;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	display: flex;
	justify-content: center;
	align-items: center;
	&:focus {
		outline: none;
	}
`;

const IconTypeMessage = styled.div<{ backgroundColor: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 6px;
	background-color: ${(props) => props.backgroundColor};
`;

const SendBar: React.FC<SendBar.Props> = ({
	inputRef,
	onBlur,
	onFocus,
	onClickSendMessage,
	clearInputs,
	isLoadingSendButton,
}) => {
	const { openUserModal, openFlagModal, openScheduleModal } =
		useChatActions();

	const { booferSelectedMessageType, selectedUsers, modalType } =
		useTypedSelector((state) => state.orders.chats.chat);

	const [inputValue, setInputValue] = useState<string>("");

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			setInputValue(event.target.value);
		},
		[],
	);

	const handleSendMessage = useCallback(() => {
		onClickSendMessage();
		setInputValue("");
		clearInputs();
	}, [onClickSendMessage, clearInputs]);

	const isNotEmptyContent = (text: string | undefined): boolean =>
		typeof text === "string" && text.trim() !== "";

	const keyHotForSend: string[] = useTypedSelector(
		(state) =>
			state.settings.keybinds.sendMessageInChatWidget || ([] as string[]),
	);

	const handleOnKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
			const { key, altKey, ctrlKey, shiftKey } = event;
			const blockedKeys: string[] = [" ", "к", "щ", "r", "o"]; // TODO Solve the issue so that when focusing on input there is no need to make exceptions for these buttons at all, since keyboards from different countries may differ

			if (blockedKeys.includes(key.toLowerCase())) {
				event.stopPropagation();
			}

			if (keyHotForSend?.length > 0) {
				// ? logic send message after touch hot keys

				const lastHotKey: string | undefined = keyHotForSend.at(-1);
				const isOnLastHotKeyPressed: boolean = key === lastHotKey;

				const hasAlt: boolean =
					altKey && keyHotForSend.includes(KEYBOARD_CODES.ALT);
				const hasControl: boolean =
					ctrlKey && keyHotForSend.includes(KEYBOARD_CODES.CONTROL);
				const hasShift: boolean =
					shiftKey && keyHotForSend.includes(KEYBOARD_CODES.SHIFT);

				const hasDuoHotKey: boolean = hasAlt || hasControl || hasShift;
				const hasDuoTouch: boolean = altKey || ctrlKey || shiftKey;
				const hasntDuoHotKey: boolean =
					keyHotForSend.length === 1 && !hasDuoTouch;

				const isHotKeyPressed: boolean =
					isOnLastHotKeyPressed && (hasntDuoHotKey || hasDuoHotKey);

				if (isHotKeyPressed) {
					event.stopPropagation();
					event.preventDefault();

					handleSendMessage();
				}
			}
		},
		[keyHotForSend, handleSendMessage],
	);

	const { t } = useTranslation();
	return (
		<Root>
			<InputContainer>
				<StyledTextarea
					ref={inputRef}
					onFocus={onFocus}
					onBlur={onBlur}
					placeholder={
						t("orderPageWidgets.chat.sendBar.str204") ?? ""
					}
					value={inputValue}
					onChange={handleInputChange}
					rows={1}
					minRows={1}
					maxRows={6}
					onKeyDown={handleOnKeyDown}
				/>
			</InputContainer>
			<CustomStyledRow justify="space-between" align="center">
				<CustomStyledRow gap="16px*">
					<Button.Button
						icon={
							<Icon
								id="chat-users"
								size={24}
								colors={[
									modalType === "users"
										? theme.colors.accent
										: theme.colors.primary,
								]}
							/>
						}
						transparent={modalType !== "users"}
						variant="secondary"
						onClick={openUserModal}
					/>
					<ButtonOnModal
						icon={
							<Icon id="chat-flag" size={24} colors={[gray[5]]} />
						}
						transparent={modalType !== "flags"}
						variant="secondary"
						onClick={openFlagModal}
						disabled
					/>
					<Button.Button
						icon={
							<Icon
								id="chat-schedule"
								size={24}
								colors={[
									modalType === "schedule"
										? theme.colors.accent
										: theme.colors.primary,
								]}
							/>
						}
						transparent={modalType !== "schedule"}
						variant="secondary"
						onClick={openScheduleModal}
					/>
				</CustomStyledRow>
				<CustomStyledRow gap="12px*" justify="flex-end" align="center">
					{!!(
						selectedUsers.dispatchers.length ||
						selectedUsers.executors.length
					) && <MarkedUserSendVersion />}
					{booferSelectedMessageType !== "default" && (
						<IconTypeMessage
							backgroundColor={
								messagesType[booferSelectedMessageType]
									.secondaryColor
							}
						>
							<Icon
								id={
									messagesType[booferSelectedMessageType]
										.iconName
								}
								colors={[
									messagesType[booferSelectedMessageType]
										.primaryColor,
								]}
								size={16}
							/>
						</IconTypeMessage>
					)}
					<ButtonSendMessage
						type="button"
						onClick={handleSendMessage}
						aria-label={
							isLoadingSendButton
								? t("orderPageWidgets.chat.sendBar.str203") ??
								  ""
								: t("orderPageWidgets.chat.sendBar.str204") ??
								  ""
						}
						disabled={
							!isNotEmptyContent(inputValue) ||
							isLoadingSendButton
						}
					>
						{isLoadingSendButton ? (
							<Loader active={true} dimmed={false} />
						) : (
							<Icon
								id="chat-send"
								size={22}
								colors={[theme.colors.white]}
							/>
						)}
					</ButtonSendMessage>
				</CustomStyledRow>
			</CustomStyledRow>
		</Root>
	);
};

declare namespace SendBar {
	interface Props {
		inputRef: React.RefObject<HTMLTextAreaElement>;
		onBlur: () => void;
		onFocus: () => void;
		onClickSendMessage: () => void;
		clearInputs: () => void;
		isLoadingSendButton: boolean;
	}
}

export default SendBar;
