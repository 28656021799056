import { combineReducers } from "@reduxjs/toolkit";

import {
	reportCashlessAccountsSummaryReportDataState,
	reportCashlessAccountsSummaryReportDataReducer,
	reportCashlessAccountsSummaryReportDataAction,
} from "./data";

export type StateCashlessAccountsSummaryReport = {
	data: reportCashlessAccountsSummaryReportDataState;
};

export const cashlessAccountsSummaryReport =
	combineReducers<StateCashlessAccountsSummaryReport>({
		data: reportCashlessAccountsSummaryReportDataReducer,
	});

export const reportsCashlessAccountsSummaryReportAction = {
	data: reportCashlessAccountsSummaryReportDataAction,
};

export default cashlessAccountsSummaryReport;
