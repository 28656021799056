/* eslint-disable no-shadow */

import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import Block from "../Block";
import Header from "./components/Header";
import List from "./components/List";

const Selector = memo<Selector.Props>(
	({
		value,
		onChange,

		items,
		disabledItems = [],
	}) => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const valueSet = useMemo(() => new Set(value), [value.length]);

		const [search, setSearch] = useState("");
		const words = useMemo(
			() => search.toLowerCase().split(/\s+/) || [],
			[search],
		);

		const filteredItems = useMemo(() => {
			if (words.length === 0) return items;

			return items.filter((item) =>
				words.every(
					(word) => item.label.toLowerCase().indexOf(word) !== -1,
				),
			);
		}, [items, words]);

		const areAllSelected = useMemo(
			() =>
				[...filteredItems]
					.filter((item) => !disabledItems.includes(item.id))
					.every((item) => valueSet.has(item.id)),
			[disabledItems, filteredItems, valueSet],
		);

		const onSelectAll = useCallback(
			(select: boolean) => {
				const filterDisabledValue = [...filteredItems].filter(
					(item) => !disabledItems.includes(item.id),
				);
				const selectedItems = new Set(value);

				if (select) {
					filterDisabledValue.forEach((item) => {
						selectedItems.add(item.id);
					});
				} else {
					filterDisabledValue.forEach((item) => {
						selectedItems.delete(item.id);
					});
				}

				onChange(Array.from(selectedItems));
			},
			[disabledItems, filteredItems, onChange, value],
		);

		return (
			<Block>
				<Header
					search={search}
					onChangeSearch={setSearch}
					selectAll={areAllSelected}
					onChangeSelectAll={onSelectAll}
				/>
				<List
					value={value}
					onChange={onChange}
					items={filteredItems}
					disabledItems={disabledItems}
				/>
			</Block>
		);
	},
);

declare namespace Selector {
	type Item = List.Item;
	type Value = List.Value;

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;

		items: Item[];
		disabledItems?: Item["id"][];
	}
}

export default Selector;
