import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";

import translationPath from "../../../../../../constants/translationPath";
import { MessageTemplateTypes } from "../../../../../../../../../../../types/MessageTemplateEnums";
import { NoticeDestinationApp } from "../../../../../../../../../../../services/PushNotice";

import s from "./index.module.css";
import NotificationParams from "./components/NotifiactionParams";
import suggestion from "./helpers/suggestion";
import getFilteredItems from "./helpers/getFilteredParams";
import EdtiorWrapper from "./components/EditorWrapper";

const CustomMention = Mention.extend({ draggable: true });

const TemplateBody: React.FC<TemplateBody.Props> = memo(
	({ value, onChange, type, destinationApp }) => {
		const { t } = useTranslation();

		const editor = useEditor({
			extensions: [
				StarterKit,
				CustomMention.configure({
					HTMLAttributes: {
						class: s.param,
					},
					suggestion: {
						...suggestion,
						items: ({ query }) =>
							getFilteredItems(query, t, type, destinationApp),
						allowSpaces: true,
					},
				}),
				Placeholder.configure({
					placeholder:
						t(
							`${translationPath}.modal.templateBody.placeholder`,
						) || "",
					emptyEditorClass: s.isEmpty,
				}),
			],
			editorProps: {
				attributes: {
					class: s.editor,
				},
			},
			content: (value as JSONContent) || "",
			autofocus: "end",
			onUpdate: (val) => {
				const jsonVal = val.editor.getJSON();
				onChange(jsonVal as TemplateBody.Value);
			},
		});

		if (!editor) {
			return null;
		}

		return (
			<EdtiorWrapper gaps="20px" sizes="auto 150px">
				<NotificationParams
					type={type}
					destinationApp={destinationApp}
				/>
				<EditorContent style={{ height: "150px" }} editor={editor} />
			</EdtiorWrapper>
		);
	},
);

declare namespace TemplateBody {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
		type: MessageTemplateTypes;
		destinationApp: NoticeDestinationApp;
	}

	type Value =
		| {
				type: "doc";
				content: {
					type: "paragraph";
					content: ({
						type: "text" | "mention";
						text?: string;
						attrs?: {
							id: { name: string; args: string[] };
							label: string;
						};
					} | null)[];
				}[];
		  }
		| "";
}

export default TemplateBody;
