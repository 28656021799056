/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import CarModel from "../../../services/CarModel";
import CarBrand from "../../../services/CarBrand";
import CarBodyType from "../../../services/CarBodyType";

/* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *\
|                                                                       |
|   TODO: Delete this reducer and replace with the model subscription   |
|                                                                       |
\* *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** *** */

interface CarState {
	brands: CarBrand.Model[];
	cars: CarModel.Model[];
	bodyTypes: CarBodyType.Model[];
}

const initialState: CarState = {
	brands: [],
	cars: [],
	bodyTypes: [],
};

type Reducer<P> = CaseReducer<CarState, PayloadAction<P>>;

const setCars: Reducer<CarState["cars"]> = (state, { payload }) => {
	state.cars = payload;
};

const updateCars: Reducer<CarModel.Model[]> = (state, { payload }) => {
	payload.forEach((newCar) => {
		const index = state.cars.findIndex((c) => c.id === newCar.id);

		if (index === -1) {
			state.cars.push(newCar);
		} else {
			state.cars[index] = {
				...state.cars[index],
				...newCar,
			};
		}
	});
};

const addBrands: Reducer<CarBrand.Model[]> = (state, { payload }) => {
	payload.forEach((newBrand) => {
		const index = state.brands.findIndex((c) => c.id === newBrand.id);

		if (index === -1) {
			state.brands.push(newBrand);
		} else {
			state.brands[index] = newBrand;
		}
	});
};

type BrandUpdateParams = Pick<CarBrand.Model, "id"> &
	Partial<Omit<CarBrand.Model, "id">>;

const updateBrands: Reducer<BrandUpdateParams[]> = (state, { payload }) => {
	payload.forEach((newBrand) => {
		const index = state.brands.findIndex((c) => c.id === newBrand.id);

		if (index !== -1) {
			state.brands[index] = {
				...state.brands[index],
				...newBrand,
			};
		}
	});
};

const setBodyTypes: Reducer<CarState["bodyTypes"]> = (state, { payload }) => {
	state.bodyTypes = payload;
};

const carReducer = createSlice({
	name: "carReducer",
	initialState,
	reducers: {
		setCars,
		updateCars,

		addBrands,
		updateBrands,

		// setBrandModels,
		setBodyTypes,
	},
});

export default carReducer;
