/* eslint-disable no-shadow */

import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import { CheckBoxWithContent, useInternal } from "uikit";

import useObjectEditor from "../../hooks/useObjectEditor";
import FieldsContainer from "../FieldsContainer";
import { Style, StyledGrid, CheckBoxWithText } from "../common";

type KeyBase = string | number;

const CheckBoxSelect = <OptionKey extends KeyBase>({
	value,
	disabled = false,
	title = "",
	options,
	onChange,
	height,
	verticalScroll = false,
	...props
}: CheckBoxSelect.Props<OptionKey>) => {
	const { t } = useTranslation();
	const defaultValue = useMemo(() => value ?? [], [value]);

	const [internalValue, setInternalValue] = useInternal(
		value ?? defaultValue,
	);

	const internalValueOnChange = useCallback(
		(newValue: CheckBoxSelect.Value<OptionKey>) => {
			if (onChange) onChange(newValue);
			else setInternalValue(newValue);
		},
		[onChange, setInternalValue],
	);

	const valueEditor = useObjectEditor(internalValue, internalValueOnChange);

	const label = useMemo(() => t(title), [t, title]);
	return (
		<FieldsContainer label={label}>
			<StyledGrid
				areas=""
				gap="12px"
				columns="repeat(auto-fill, minmax(200px, 1fr))"
				scrollbar={verticalScroll}
				overY="auto"
				h={height ? `${height}px` : "21px"}
				{...props}
			>
				{options?.map((option) => (
					<CheckBoxWithText
						key={option.key}
						value={valueEditor.includes(option.key)}
						gap="10px"
						disabled={disabled}
						onChange={(serviceEnable) => {
							if (serviceEnable) valueEditor.pushUniq(option.key);
							else valueEditor.removeByValue(option.key);
						}}
						title={option.label}
					/>
				))}
			</StyledGrid>
		</FieldsContainer>
	);
};

declare namespace CheckBoxSelect {
	type Value<Key extends KeyBase> = Key[];

	interface Option<Key extends KeyBase> {
		key: Key;
		label: string;
	}

	interface Props<OptionKey extends KeyBase> extends Partial<Style.Grid> {
		value?: Value<OptionKey>;

		disabled?: boolean;
		title?: string | string[];
		options?: Option<OptionKey>[];
		height?: number;
		verticalScroll?: boolean;

		onChange?: (value: Value<OptionKey>) => void;
	}
}

export default CheckBoxSelect;
