import React, {
	Suspense,
	memo,
	useRef,
	useMemo,
	useCallback,
	useLayoutEffect,
	useState,
} from "react";
import { Marker, useMap } from "react-leaflet";
import { useDebouncedCallback } from "use-debounce";

import { Marker as MarkerType, LatLngLiteral, divIcon } from "leaflet";

import YellowCar from "./yellowCar.png";

// https://leafletjs.com/reference.html#icon
// https://leafletjs.com/reference.html#posanimation
// https://github.com/alexandra-c/leaflet-tracking-marker
const MarkerCarBase: React.FC<MarkerCarBase.Props> = ({
	position,
	next,
	toggle,
	speed = 1.2,
	run = true,
	duration = 60,
}): JSX.Element => {
	const map = useMap();
	const ref = useRef<MarkerType | null>(null);

	const [value, onChange] = useState<{
		point: LatLngLiteral | null;
		nextPoint: LatLngLiteral | null | undefined;
		pointStep: number;
		pointNextStep: number;
	}>({
		point: position,
		nextPoint: next,
		pointStep: 0,
		pointNextStep: 1,
	});

	/** travel time between the two points */
	const durationTime = useMemo<number>(
		() => (duration * 1000) / speed,
		[speed, duration],
	);
	/** calculate the degree of rotation of the car on the map. */
	const angleCards = useCallback(
		(prevLatlng: LatLngLiteral | null, latlng: LatLngLiteral | null) => {
			if (!map) return 0;
			if (!latlng || !prevLatlng) return 0;
			const pxStart = map.project(prevLatlng);
			const pxEnd = map.project(latlng);
			const y = pxStart.y - pxEnd.y;
			const x = pxStart.x - pxEnd.x;
			return (Math.atan2(y, x) / Math.PI) * 180 - 90;
		},
		[map],
	);

	/** Add points to the map between two points */
	const addPoints = useCallback(
		(
			startPoint: LatLngLiteral | null,
			endPoint: LatLngLiteral | null,
			numPoints = 0,
		) => {
			if (!map) return [];
			if (!startPoint || !endPoint) return [];
			const latDiff = (endPoint.lat - startPoint.lat) / (numPoints + 1);
			const lngDiff = (endPoint.lng - startPoint.lng) / (numPoints + 1);

			const points: LatLngLiteral[] = [];

			for (let i = 1; i <= numPoints; i++) {
				const lat = startPoint.lat + i * latDiff;
				const lng = startPoint.lng + i * lngDiff;
				points.push({ lat, lng });
			}
			return [startPoint, ...points, endPoint];
		},
		[map],
	);

	/** The points to the map between two points */
	const points = useMemo<LatLngLiteral[]>(() => {
		if (!next || !position) return [];
		const numPoint = Math.round((duration * 1000) / 100);
		const payload = addPoints(position, next, numPoint);
		return payload;
	}, [next, position, duration, addPoints]);

	/** starts the movement between two points  */
	const onNextPoint = useDebouncedCallback(() => {
		onChange((prev) => {
			const payload = {
				point: points[prev.pointNextStep],
				pointStep: prev.pointNextStep,
				nextPoint: points[prev.pointNextStep + 1],
				pointNextStep: prev.pointNextStep + 1,
			};
			return payload;
		});
	}, durationTime / points.length);

	/** the degree of rotation of the car on the map. */
	const deg = useMemo<number>(() => {
		if (!next || !position) return 0;
		const payload = angleCards(position, next);

		return payload;
	}, [next, position, angleCards]);

	const imgHtml = useMemo<HTMLElement | undefined>(() => {
		const img = document.createElement("img");
		img.src = YellowCar;
		img.alt = "yellow car";
		img.role = "button";
		img.id = "archive-history-map-marker-car-id";
		img.className =
			"leaflet-marker-icon leaflet-zoom-animated leaflet-interactive";
		img.style.position = "absolute";
		img.style.transform = `translate(-50%, -50%)`;
		img.style.width = "30px";
		img.style.height = "50px";
		img.style.outlineStyle = "none";
		return img;
	}, []);

	useLayoutEffect(() => {
		onChange({
			point: position,
			nextPoint: next,
			pointStep: 0,
			pointNextStep: 1,
		});
	}, [next, position]);

	useLayoutEffect(() => {
		if (toggle && run && ref?.current && value.nextPoint) {
			onNextPoint();
		}
	}, [onNextPoint, run, toggle, value.nextPoint]);

	useLayoutEffect(() => {
		const elem = ref.current?.getElement();
		if (!elem) return;
		const image = document.getElementById(
			"archive-history-map-marker-car-id",
		);
		if (image) {
			image.setAttribute(
				"style",
				`position: absolute; transform: rotate(${deg}deg) translate(-50%, -50%); width: 30px; height: 50px; outline-style: none;`,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref?.current, deg]);

	return (
		<Suspense>
			{toggle && value.point && (
				<Marker
					ref={ref}
					position={value.point}
					icon={divIcon({
						iconSize: [0, 0],
						iconAnchor: [0, 0],
						html: imgHtml,
					})}
				/>
			)}
		</Suspense>
	);
};

declare namespace MarkerCarBase {
	interface Props {
		position: LatLngLiteral | null;
		next?: LatLngLiteral | null;
		speed: number;
		toggle: boolean;
		run?: boolean;
		zoom: number;
		duration: number;
	}
}

export const MarkerCar = memo(MarkerCarBase);
export default MarkerCarBase;
