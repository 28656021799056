import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	width: 620px;

	padding: 16px 20px;

	border-radius: 5px;

	background: ${theme.colors.white};

	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
`;

export default Root;
