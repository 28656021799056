import React, { memo } from "react";
import { Row } from "uikit";
import ValidityDate from "../../../../../Modal/components/Content/components/ValidityDate";

const ValidityDateOutput: React.FC<ValidityDateOutput.Props> = memo(
	({ value }) =>
		value.active ? (
			<>
				<Row gaps="3px*">
					<div>{new Date(value.from).toLocaleDateString()} –</div>
					<div>{new Date(value.to).toLocaleDateString()}</div>
				</Row>
				<span>/</span>
			</>
		) : null,
);

declare namespace ValidityDateOutput {
	interface Props {
		value: ValidityDate.Value;
	}
}

export default ValidityDateOutput;
