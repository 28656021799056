import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { Stepper } from "uikit";

import {
	CheckBoxWithText,
	StyledColumn,
	StyledRow,
	RadioButton,
} from "../../../../../../../../../../../../../../components/common";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

export enum ShowCustomerPhoneNumbersType {
	Always = "always",
	Sometimes = "sometimes",
}

const ShowCustomerPhoneNumbers: React.FC<ShowCustomerPhoneNumbers.Props> = ({
	value,
	onChange,
}) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const activeValue = valueEditor.useGetter("active");
	const activeOnChange = valueEditor.useSetter("active");

	const typeValue = valueEditor.useGetter("type");
	const typeOnChange = valueEditor.useSetter("type");

	const conditionsEditor = valueEditor.usePropertyEditor("conditions");

	const showManuallyValue = conditionsEditor.useGetter("showManually");
	const showManuallyOnChange = conditionsEditor.useSetter("showManually");

	const afterArrivedMessageValue = conditionsEditor.useGetter(
		"afterArrivedMessage",
	);
	const afterArrivedMessageOnChange = conditionsEditor.useSetter(
		"afterArrivedMessage",
	);

	const afterWaitingCustomerMessageValue = conditionsEditor.useGetter(
		"afterWaitingCustomerMessage",
	);
	const afterWaitingCustomerMessageOnChange = conditionsEditor.useSetter(
		"afterWaitingCustomerMessage",
	);

	const beforeArrivalTimeEditor =
		valueEditor.usePropertyEditor("beforeArrivalTime");

	const beforeArrivalTimeActiveValue =
		beforeArrivalTimeEditor.useGetter("active");
	const beforeArrivalTimeActiveOnChange =
		beforeArrivalTimeEditor.useSetter("active");

	const beforeArrivalTimeThresholdValue =
		beforeArrivalTimeEditor.useGetter("threshold");
	const beforeArrivalTimeThresholdOnChange =
		beforeArrivalTimeEditor.useSetter("threshold");

	const afterAcceptingOrderEditor = valueEditor.usePropertyEditor(
		"afterAcceptingOrder",
	);

	const afterAcceptingOrderActiveValue =
		afterAcceptingOrderEditor.useGetter("active");
	const afterAcceptingOrderActiveOnChange =
		afterAcceptingOrderEditor.useSetter("active");

	const afterAcceptingOrderThresholdValue =
		afterAcceptingOrderEditor.useGetter("threshold");
	const afterAcceptingOrderThresholdOnChange =
		afterAcceptingOrderEditor.useSetter("threshold");

	return (
		<StyledColumn gap="10px" p="10px" baseBorder>
			<CheckBoxWithText
				value={activeValue}
				gap="10px"
				onChange={activeOnChange}
				title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str200"
			/>
			<StyledColumn m="0 0 0 30px" gap="5px">
				<RadioButton
					value={typeValue === ShowCustomerPhoneNumbersType.Always}
					disabled={!activeValue}
					title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str0"
					onChange={() =>
						typeOnChange(ShowCustomerPhoneNumbersType.Always)
					}
				/>
				<RadioButton
					value={typeValue === ShowCustomerPhoneNumbersType.Sometimes}
					disabled={!activeValue}
					title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str1"
					onChange={() =>
						typeOnChange(ShowCustomerPhoneNumbersType.Sometimes)
					}
				/>
				<StyledRow m="0 0 0 30px" p="10px" baseBorder gap="30px">
					<CheckBoxWithText
						value={showManuallyValue}
						disabled={
							!activeValue ||
							typeValue !== ShowCustomerPhoneNumbersType.Sometimes
						}
						gap="10px"
						onChange={showManuallyOnChange}
						title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str150"
					/>
					<CheckBoxWithText
						disabled
						value={afterArrivedMessageValue}
						// disabled={
						// 	!activeValue || typeValue !== Type.Sometimes
						// }
						gap="10px"
						onChange={afterArrivedMessageOnChange}
						title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str201"
					/>
					<CheckBoxWithText
						disabled
						value={afterWaitingCustomerMessageValue}
						// disabled={
						// 	!activeValue || typeValue !== Type.Sometimes
						// }
						gap="10px"
						onChange={afterWaitingCustomerMessageOnChange}
						title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str203"
					/>
				</StyledRow>
			</StyledColumn>
			<StyledRow m="0 0 0 30px" gap="30px">
				<StyledRow gap="8px" alignItems="center">
					<CheckBoxWithText
						disabled
						value={beforeArrivalTimeActiveValue}
						// disabled={
						// 	!activeValue || typeValue !== Type.Sometimes
						// }
						gap="10px"
						onChange={beforeArrivalTimeActiveOnChange}
						title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str151"
					/>
					<Stepper
						disabled
						value={beforeArrivalTimeThresholdValue / 1e3}
						// disabled={
						// 	!activeValue || typeValue !== Type.Sometimes
						// }
						onChange={(value) =>
							beforeArrivalTimeThresholdOnChange(
								(value ? value * 1e3 : value) as number,
							)
						}
					/>
					<span>
						{t(
							"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str100",
						) ?? ""}
					</span>
				</StyledRow>
				<StyledRow gap="8px" alignItems="center">
					<CheckBoxWithText
						disabled
						value={afterAcceptingOrderActiveValue}
						// disabled={
						// 	!activeValue || typeValue !== Type.Sometimes
						// }
						gap="10px"
						onChange={afterAcceptingOrderActiveOnChange}
						title="pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str152"
					/>
					<Stepper
						disabled
						value={afterAcceptingOrderThresholdValue / 1e3}
						// disabled={
						// 	!activeValue || typeValue !== Type.Sometimes
						// }
						onChange={(value) =>
							afterAcceptingOrderThresholdOnChange(
								(value ? value * 1e3 : value) as number,
							)
						}
					/>
					<span>
						{t(
							"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str101",
						) ?? ""}
					</span>
				</StyledRow>
			</StyledRow>
		</StyledColumn>
	);
};

declare namespace ShowCustomerPhoneNumbers {
	interface Value {
		active: boolean;

		type: ShowCustomerPhoneNumbersType;

		conditions: {
			showManually: boolean;
			afterArrivedMessage: boolean;
			afterWaitingCustomerMessage: boolean;
		};

		beforeArrivalTime: {
			active: boolean;
			threshold: number;
		};

		afterAcceptingOrder: {
			active: boolean;
			threshold: number;
		};
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default ShowCustomerPhoneNumbers;
