import React, { memo } from "react";
import { MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const CompanyBranch: React.FC<CompanyBranch.Props> = memo((props) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	return (
		<>
			<LabeledField
				label={
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.companyBranch.str200",
					) ?? ""
				}
			>
				<MultiSelect
					all
					placeholder={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.companyBranch.str201",
						) ?? ""
					}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.companyBranch.str202",
					) ?? ""
				}
			>
				<MultiSelect
					all
					placeholder={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans.modal.content.tabs.main.companyBranch.str203",
						) ?? ""
					}
				/>
			</LabeledField>
		</>
	);
});

declare namespace CompanyBranch {
	interface Props {
		// taxiServiceIds: Value;
		// setTaxiServiceIds: (value: Value) => void;
	}

	type Value = number[];
}

export default CompanyBranch;
