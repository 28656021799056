import makeTable from "./makeTable";

const counterpartyEmployee = makeTable({
	allIds: [
		"surname",
		"name",
		"fatherName",
		"phones",
		"check",
		"card",
		"personalCardNumber",
		"keyword",
		"orderCount",
		"registrationDate",
		"firstTripDate",
		"lastTripDate",
		"mobileApp",
		"corporateCabinet",
	],
	defaultIds: ["surname", "name", "phones", "personalCardNumber", "keyword"],
	i18tPath: (id) =>
		`tableColumns.customers.counterpartyEmployeesCheckCard.${id}`,
});

export default counterpartyEmployee;
