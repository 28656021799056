import React, { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { useCurrencyGlobalSettings } from "../../../../../../../../hooks";
import {
	StyledColumn,
	StyledRow,
	StyledSpan,
	ValueStepper,
	ImprovedStepper,
} from "../../../../../../../../components/common";
import tPath from "../../../../constants/tPath";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
} from "../../../../constants/constants";
import BinaryAdjustmentSelect from "../../../BinaryAdjustmentSelect";
import SurchargeSelect from "../../../SurchareSelect";

import Item from "./components/Item";

const MinHourlyRate: React.FC<MinHourlyRate.Props> = memo(
	({
		root,
		value,
		onChange,
		hourlyServiceAction,
		setHourlyServiceAction,
	}) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();
		const valueEditor = useObjectEditor(value, onChange);

		const minutes = valueEditor.useGetter("minutes");
		const setMinutes = valueEditor.useSetter("minutes");

		const adjustments = valueEditor.useGetter("adjustments");
		const setAdjustments = valueEditor.useSetter("adjustments");
		const adjustmentsEditor = useObjectEditor(adjustments, setAdjustments);
		const adjustmentsValue = adjustmentsEditor.useGetter("value");
		const setAdjustmentsValue = adjustmentsEditor.useSetter("value");
		const adjustmentsMinutes = adjustmentsEditor.useGetter("minutes");
		const setAdjustmentsMinutes = adjustmentsEditor.useSetter("minutes");
		const adjustmentsInitialKm = adjustmentsEditor.useGetter("initialKm");
		const setAdjustmentsInitKm = adjustmentsEditor.useSetter("initialKm");

		const initialKm = valueEditor.useGetter("initialKm");
		const setInitialKm = valueEditor.useSetter("initialKm");

		const currencyValue = valueEditor.useGetter("value");
		const setCurrencyValue = valueEditor.useSetter("value");

		const appliesToRoute = valueEditor.useGetter("appliesToEntireRoute");
		const setAppliesToRoute = valueEditor.useSetter("appliesToEntireRoute");

		const onChangeHourlyServiceAction = useCallback(
			(value: boolean) => {
				setHourlyServiceAction(!value);
			},
			[setHourlyServiceAction],
		);

		const customSettlementCostPerKm = valueEditor.useGetter(
			"customSettlementCostPerKm",
		);
		const setCustomSettlementCostPerKm = valueEditor.useSetter(
			"customSettlementCostPerKm",
		);
		const customSuburbanCostPerKm = valueEditor.useGetter(
			"customSuburbanCostPerKm",
		);
		const setCustomSuburbanCostPerKm = valueEditor.useSetter(
			"customSuburbanCostPerKm",
		);

		useEffect(() => {
			if (
				adjustmentsValue === RateAdjustmentToDefault.CUSTOM_VALUE &&
				currencyValue < 0
			) {
				setCurrencyValue(0);
			}
		}, [currencyValue, adjustmentsValue, setCurrencyValue]);

		return (
			<StyledColumn gap="8px">
				<StyledRow gap="10px" alignItems="center">
					<StyledSpan>
						{t(
							`${tPath}.mainModal.tabs.main.hourlyService.minHourlyRate.title`,
						)}
					</StyledSpan>
					<SurchargeSelect
						disabled={root}
						value={adjustmentsValue}
						onChange={setAdjustmentsValue}
					/>
					<ValueStepper
						min={
							adjustmentsValue ===
							RateAdjustmentToDefault.CUSTOM_VALUE
								? 0
								: undefined
						}
						value={currencyValue}
						onChange={setCurrencyValue}
					/>
					{adjustmentsValue !==
						RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE &&
						currencyGlobalSettings}
				</StyledRow>
				<StyledRow gap="10px" alignItems="center" m="0 0 0 30px">
					<StyledSpan>
						{t(
							`${tPath}.mainModal.tabs.main.hourlyService.minHourlyRate.include`,
						)}
					</StyledSpan>
					<BinaryAdjustmentSelect
						disabled={root}
						value={adjustmentsMinutes}
						onChange={setAdjustmentsMinutes}
					/>
					<ImprovedStepper
						disabled={
							adjustmentsMinutes ===
							RateBinaryAdjustmentToDefault.DEFAULT_VALUE
						}
						value={minutes}
						onChange={setMinutes}
					/>
					<StyledSpan>
						{t(
							`${tPath}.mainModal.tabs.main.hourlyService.minHourlyRate.minutesAnd`,
						)}
					</StyledSpan>
					<BinaryAdjustmentSelect
						disabled={root}
						value={adjustmentsInitialKm}
						onChange={setAdjustmentsInitKm}
					/>

					<ValueStepper
						decimalCount={3}
						disabled={
							adjustmentsInitialKm ===
							RateBinaryAdjustmentToDefault.DEFAULT_VALUE
						}
						value={initialKm}
						onChange={setInitialKm}
					/>
					<StyledSpan>{t(`units.km`)}.</StyledSpan>
					<CheckBox
						value={appliesToRoute}
						onChange={setAppliesToRoute}
						label={
							t(
								`${tPath}.mainModal.tabs.main.hourlyService.minHourlyRate.appliesToEntireRoute`,
							) || ""
						}
					/>
				</StyledRow>
				{appliesToRoute && (
					<StyledColumn gap="8px" m="0 34.5% 0 auto">
						<Item
							value={customSettlementCostPerKm}
							title={t(
								`${tPath}.mainModal.tabs.main.mainSettings.settlementCostPerKm`,
							)}
							onChange={setCustomSettlementCostPerKm}
						/>
						<Item
							value={customSuburbanCostPerKm}
							title={t(
								`${tPath}.mainModal.tabs.main.mainSettings.suburbanCostPerKm`,
							)}
							onChange={setCustomSuburbanCostPerKm}
						/>
						<CheckBox
							value={!hourlyServiceAction}
							onChange={onChangeHourlyServiceAction}
							label={
								t(
									`${tPath}.mainModal.tabs.main.mainSettings.hourlyServiceAction`,
								) || "Stop hourly calculation"
							}
						/>
					</StyledColumn>
				)}
			</StyledColumn>
		);
	},
);

declare namespace MinHourlyRate {
	interface Props {
		root: boolean;
		value: Value;
		hourlyServiceAction: boolean;
		setHourlyServiceAction: (value: boolean) => void;
		onChange: (value: Value) => void;
	}

	interface Value {
		value: number;
		minutes: number;
		initialKm: number;
		appliesToEntireRoute: boolean;
		customSettlementCostPerKm: number;
		customSuburbanCostPerKm: number;

		adjustments: {
			value: RateAdjustmentToDefault;
			minutes: RateBinaryAdjustmentToDefault;
			initialKm: RateBinaryAdjustmentToDefault;
			appliesToEntireRoute: RateBinaryAdjustmentToDefault;
		};
	}
}

export default MinHourlyRate;
