/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { defaults } from "lodash";

import ModelService from "../../redux/services/ModelService";
import workShifts from "../../redux/reducers/workShifts";
import { getPRPC } from "../../hooks/usePRPC";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import createRPCQuery from "../../utils/createRPCQuery.util";
import { createObjectLanguageNames } from "../../assets/languages/langs";
import Language from "../Language";
import Schedule from "../Schedule";
import Card from "../Card";

import { destroyOne } from "./utils";

class WorkShift extends ModelService<
	WorkShift.SubscribeOptions,
	WorkShift.Model,
	"workShifts"
>(workShifts, (state) => state.workShifts) {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.schedule.card);

		return this._Card;
	}

	static defaultSharedOptions: WorkShift.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): WorkShift.Model {
		return {
			id: data.id,

			executorIds: data.executors?.map((executor) => executor.id),
			taxiServiceIds: data.scheduleToTaxiServices?.map(
				(toTaxiService) => toTaxiService.taxiService.id,
			),
			// TODO: is not correct
			name: data.name.uk,
			description: data.additionalFields?.description ?? "",
			openAndCloseType: data.opened ? "manually" : "auto",

			isSpentMinuteRangeEnabled: data.interval,
			minimumMinutesSpent: data.minInterval,
			maximumMinutesSpent: data.maxInterval,

			isLunchTimeEnabled: data.isLunchIntervalEnabled,
			lunchTime: data.lunchInterval,

			isSchedulesEnabled: data.isWeek,
			schedules: data.scheduleWeeks?.map(Schedule.fromResponse),

			active: data.active,
			default: data.default,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model: WorkShift.Model.New | WorkShift.Model.Modified,
	): any {
		return {
			executorIds: model.executorIds,
			dispatcherIds: [],
			taxiServiceIds: model.taxiServiceIds,
			scheduleWeekIds: model.scheduleIds,
			name: createObjectLanguageNames(model.name),
			// name: {
			// 	uk: model.name,
			// 	az: model.name,
			// 	tr: model.name,
			// 	en: model.name,
			// 	ru: model.name,
			// },

			// description: model.description,
			opened: model.openAndCloseType === "manually",

			interval: model.isSpentMinuteRangeEnabled,
			minInterval: model.minimumMinutesSpent,
			maxInterval: model.maximumMinutesSpent,

			isLunchIntervalEnabled: model.isLunchTimeEnabled,
			lunchInterval: model.lunchTime,

			isWeek: model.isSchedulesEnabled,

			active: model.active,
			default: model.default,

			additionalFields: {
				description: model.description,
			},
		};
	}

	static async store(
		object: WorkShift.Model.New,
		options?: WorkShift.StoreOptions,
	) {
		options = defaults(options, WorkShift.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.schedule.create(WorkShift.toRequest(object)),
		);

		if (options.deprecate) WorkShift.deprecateAll();
	}

	static async update(
		object: WorkShift.Model.Modified,
		options?: WorkShift.UpdateOptions,
	) {
		options = defaults(options, WorkShift.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.schedule.update(
				object.id,
				WorkShift.toRequest(object),
			),
		);

		if (options.deprecate) WorkShift.deprecateAll();
	}

	static async destroy(
		id: number[] | number,
		options?: WorkShift.DestroyOptions,
	) {
		options = defaults(options, WorkShift.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		if (options.deprecate) WorkShift.deprecateAll();
	}

	static Global = {
		async index(options: WorkShift.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.schedule.getAll({
					limit: options.limit,
					offset: options.offset,
					query: options.query,
					order: options.order,

					active: options.active,
					default: options.default,
					lang: options.language,
					taxiServiceIds: options.taxiServiceIds,
				}),
			);

			return {
				cache: result.items.map(WorkShift.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}

declare namespace WorkShift {
	type OpenAndCloseType = "auto" | "manually";

	interface Model {
		id: number;

		executorIds: number[];
		taxiServiceIds: number[];

		name: string;
		description: string;
		openAndCloseType: OpenAndCloseType;

		isSpentMinuteRangeEnabled: boolean;
		minimumMinutesSpent: number;
		maximumMinutesSpent: number;

		isLunchTimeEnabled: boolean;
		lunchTime: number;

		isSchedulesEnabled: boolean;
		schedules: Schedule.Model[];

		active: boolean;
		default: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<WorkShift.Model> {
		active?: boolean;
		default?: boolean;
		language?: Language;
		taxiServiceIds?: number[];
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type NonEditablePropertyNames =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		type ModifiedPropertyNames = "schedules";

		interface ModifiedProperties {
			scheduleIds: number[];
		}

		type New = Omit<
			Model,
			ModifiedPropertyNames | NonEditablePropertyNames
		> &
			ModifiedProperties;
		type Modified = Partial<
			Omit<Model, ModifiedPropertyNames | NonEditablePropertyNames> &
				ModifiedProperties
		> &
			Pick<Model, "id">;
	}
}

export default WorkShift;
