/* eslint-disable no-shadow */

import { getPRPC } from "../../../../../hooks/usePRPC";
import defaultValidationsValue from "../../../../../pages/Settings/pages/Message/Provider/constants/defaultValidationsValue";
import { validResponse } from "../../../../../pages/Settings/pages/Message/Provider/providerHelper";
import createRPCQuery from "../../../../../utils/createRPCQuery.util";

export enum SourceAddrTon {
	UNKNOWN = "0",
	INTERNATIONAL = "1",
	NATIONAL = "2",
	NETWORK_SPECIFIC = "3",
	SUBSCRIBER_NUMBER = "4",
	ALPHANUMERIC = "5",
	ABBREVIATED = "6",
}

export enum SourceAddrNpi {
	UNKNOWN = "0",
	ISDN = "1",
	DATA = "3",
	TELEX = "4",
	LAND_MOBILE = "6",
	NATIONAL = "8",
	PRIVATE = "9",
	ERMES = "10",
	INTERNET = "14",
	WAP_CLIENT_ID = "18",
}

export enum DestAddrTon {
	UNKNOWN = "0",
	INTERNATIONAL = "1",
	NATIONAL = "2",
	NETWORK_SPECIFIC = "3",
	SUBSCRIBER_NUMBER = "4",
	ALPHANUMERIC = "5",
	ABBREVIATED = "6",
}

export enum DestAddrNpi {
	UNKNOWN = "0",
	ISDN = "1",
	DATA = "3",
	TELEX = "4",
	LAND_MOBILE = "6",
	NATIONAL = "8",
	PRIVATE = "9",
	ERMES = "10",
	INTERNET = "14",
	WAP_CLIENT_ID = "18",
}

export default function createProviderSms(service, closeModal, setValidation) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(() =>
			prpcow?.theirsModel.sms.provider.create(service),
		)
			.then(async () => {
				setValidation(defaultValidationsValue);
				closeModal();
			})
			.catch((error) => {
				const isValid = validResponse(error.message);
				setValidation(isValid);
			});
	};
}
