import React, { useCallback, useMemo } from "react";

import { useTypedSelector } from "../../../../../redux/store";
import Dispatcher from "../../../../../services/Dispatcher";
import Executor from "../../../../../services/Executor";

import { convertDataToFullname } from "../../../../SoftphoneWidget/components/Transfer/helper";

import { ChatDispatcherPhone, ChatExecutorPhone } from "../../types";

import { Root, Avatar } from "./components";

const MarkedUserSendVersion: React.FC = () => {
	const { selectedUsers } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);
	const { sipValue: userSipValue } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const dispatcherId = useTypedSelector((state) => state.account.user?.id);

	const convertDispatcher = useCallback(
		(dispatcher: Dispatcher.Model): ChatDispatcherPhone => ({
			id: dispatcher.id,
			alias: dispatcher?.alias || "",
			dispatchersAvailableSips: dispatcher?.dispatcherToSIPs
				? dispatcher.dispatcherToSIPs
						.map((sip) => sip?.sip?.value)
						.filter((sip) => sip !== userSipValue)
				: [],
			name: convertDataToFullname(dispatcher),
			phone: dispatcher?.additionalFields?.phones?.length
				? `+${dispatcher.additionalFields?.phones[0]}`
				: "",
			type: "dispatcher",
			avatar: dispatcher?.personalFiles.length
				? dispatcher?.personalFiles[0]
				: null,
			parkNumber: "",
		}),
		[userSipValue],
	);
	const convertExecutor = useCallback(
		(executor: Executor.Model): ChatExecutorPhone => ({
			id: executor.id,
			alias: executor.alias,
			name: convertDataToFullname(executor),
			phone: executor?.person?.phones?.length
				? `+${executor.person?.phones[0]?.number}`
				: "",
			type: "executor",
			avatar: executor?.avatarFiles?.length
				? executor?.avatarFiles[0]
				: null,
			parkNumber: executor.cars?.length
				? executor.cars[0].parkNumber
				: "",
		}),
		[],
	);

	const userListData = useMemo(() => {
		const dispatchersData = selectedUsers.dispatchers
			.map((dispatcher) => convertDispatcher(dispatcher))
			.filter((dispatcher) => dispatcher.id !== dispatcherId);

		const executorsData = selectedUsers.executors.map((executor) =>
			convertExecutor(executor),
		);

		return [...dispatchersData, ...executorsData];
	}, [
		convertDispatcher,
		convertExecutor,
		dispatcherId,
		selectedUsers.dispatchers,
		selectedUsers.executors,
	]);

	const cuttedUserListData = useMemo(() => {
		const firstFourUsers = [...userListData].splice(0, 4);
		const otherUsersLength = userListData.length - 4;

		const data: ChatDispatcherPhone = {
			alias: otherUsersLength.toString(),
			avatar: null,
			dispatchersAvailableSips: null,
			id: 0,
			name: `+${otherUsersLength.toString()}`,
			phone: "",
			type: "dispatcher",
			parkNumber: "",
		};

		return [...firstFourUsers, data];
	}, [userListData]);

	return (
		<Root
			width={userListData.length <= 4 ? userListData.length * 17.6 : 88}
		>
			{userListData.length <= 4
				? userListData.map((user) => (
						<Avatar key={user.id} data={user} />
				  ))
				: cuttedUserListData.map((user, i) => (
						<Avatar
							key={user.id}
							data={user}
							last={cuttedUserListData.length === i + 1}
						/>
				  ))}
		</Root>
	);
};

export default MarkedUserSendVersion;
