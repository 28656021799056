import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Nullable, theme } from "uikit";
import Root from "./components/Root";
import { DispatcherPhone, ExecutorPhone } from "../../../../types";

const PhoneNumber = styled.div`
	display: inline;
	height: 20px;
	width: 100%;

	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Name = styled.div`
	color: ${theme.colors.secondary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
`;

interface Props {
	data: DispatcherPhone | ExecutorPhone;
	onSelectPhone: (phone: DispatcherPhone | ExecutorPhone) => void;
	selectedPhone: Nullable<DispatcherPhone | ExecutorPhone>;
}

const Phone: React.FC<Props> = ({ data, onSelectPhone, selectedPhone }) => {
	const phoneText = useMemo(() => {
		const dispatcherPhone = data.phone;
		const availableSips = data?.dispatchersAvailableSips?.length
			? `sip: ${data.dispatchersAvailableSips.join(", ")}`
			: "";
		if (dispatcherPhone && !availableSips) {
			return dispatcherPhone;
		}
		if (!dispatcherPhone && availableSips) {
			return availableSips;
		}
		if (dispatcherPhone && availableSips) {
			return `${dispatcherPhone}, ${availableSips}`;
		}
		return null;
	}, [data.dispatchersAvailableSips, data.phone]);

	const selectPhone = useCallback(() => {
		onSelectPhone(data);
	}, [data, onSelectPhone]);
	return (
		<Root
			selected={
				selectedPhone?.id === data.id &&
				selectedPhone?.phone === data.phone
			}
			onClick={selectPhone}
		>
			{data.type === "dispatcher" && !!phoneText && (
				<PhoneNumber>{phoneText}</PhoneNumber>
			)}
			{data.type === "executor" && !!data.phone && (
				<PhoneNumber>{data.phone}</PhoneNumber>
			)}
			{data.name && (
				<Name>
					{data.name}
					{data.alias && `, ${data.alias}`}
				</Name>
			)}
		</Root>
	);
};

export default Phone;
