import { isObject } from "lodash";

import { ACCESS_TO_THE_COMPONENT, DisplayFields } from ".";

/**
 * Function to verify if a user has access to a specific component or feature
 * based on their final "super" role (combined system-user role).
 *
 * This function checks the access permission by traversing through the
 * access hierarchy using the provided `accessName`, which represents a dot-separated
 * path (e.g., "role.permissions.view"). It evaluates whether the user has the
 * `show` permission enabled at the specific point in the role structure.
 *
 * Key features:
 * - Takes an access name (a string path) and verifies if the final "show" permission
 *   is true, indicating access.
 * - The function first checks the `personalRole` (the user's specific role).
 *   If undefined, it falls back to a default role structure (`ACCESS_TO_THE_COMPONENT`).
 * - It splits the `accessName` into individual keys and traverses the nested role object
 *   to check the associated permissions.
 * - If any intermediate key is undefined, or if the final `show` property is not truthy,
 *   access is denied.
 * - Returns `true` if access is granted, otherwise `false`.
 *
 * Parameters:
 * @param accessName - A string representing the hierarchical path of access permissions.
 * @param personalRole - (Optional) A role object that holds the user's custom permissions.
 *
 * Returns:
 * - A boolean value indicating whether access is granted (`true`) or denied (`false`).
 */

const hasAccess = (
	accessName: string,
	personalRole?: DisplayFields,
): boolean => {
	try {
		if (!accessName) return false;

		const isValidObject = (value?: DisplayFields): boolean =>
			isObject(value) && value !== null;

		let access = isValidObject(personalRole)
			? personalRole
			: ACCESS_TO_THE_COMPONENT;

		const keys = accessName.split("."); // Split the accessName by dots to handle nested properties

		// Traverse the object to reach the desired property
		for (const key of keys) {
			access = access?.[key];
			if (access === undefined) {
				return false; // Intermediate value is `undefined`, access is denied
			}
		}

		const isAccessTrue = !!access?.show; // Check if the final property has a truthy `show` attribute

		return isAccessTrue;
	} catch {
		return false; // Any error results in returning `false`
	}
};

export default hasAccess;
