import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import Call from "../../../../../../../../../../../../services/Call";
import {
	StyledColumn,
	StyledP,
	TextMemo,
} from "../../../../../../../../../../../../components/common";

const Status: React.FC<Status.Props> = memo(({ status, duration }) => {
	const path = `mainPage.archives.callsHistory.status`;
	const { t } = useTranslation();

	const { text, ...props } = useMemo(() => {
		switch (status) {
			case 1:
				return {
					colors: "#4CAF50",
					bgC: "#DBEFDC",
					text: t(`${path}.success`),
				};

			case 4:
				return { colors: "", bgC: "", text: t(`${path}.cancel`) };

			case 2:
			case 3:
			case 5:
			case 6:
			case 7:
			case 8:
			case 9:
			case 10:
			case 11:
			case 12:
				return {
					colors: "#F83528",
					bgC: "#FFD8D8",
					text: t(`${path}.fail`),
				};

			default:
				return {
					colors: "#F83528",
					bgC: "#FFD8D8",
					text: "",
				};
		}
	}, [status, t, path]);

	return (
		<StyledColumn p="8px 0px" gap="2px">
			<StyledP
				{...props}
				br="5px"
				p="3px"
				h="14px"
				justify="center"
				alignItems="center"
				font={{
					fw: "600",
					size: "11px",
					line: "13.2px",
				}}
			>
				{text}
			</StyledP>
			<TextMemo
				textStyle={{ colors: props.colors, h: "15px" }}
				valueStyle={{
					colors: props.colors,
					h: "15px",
					font: {
						fw: "500",
						size: "11px",
						line: "15px",
					},
				}}
				text={
					<Icon
						id="order-modal-call-enter"
						size={12}
						style={{ fill: props.colors, padding: "0px" }}
					/>
				}
				value={duration}
			/>
		</StyledColumn>
	);
});

declare namespace Status {
	interface Props {
		status: Call.Model["dialStatus"];
		duration: string;
	}
}

export default Status;
