/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { Dispatch } from "react";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createRPCQuery from "../../utils/createRPCQuery.util";
import createLogger from "../../utils/logger.util";
import Base from "../Base";

const logger = createLogger({
	name: "GlobalSettings",
});

class GlobalSettings extends Base {
	static fromResponse(data: any): GlobalSettings.Model {
		console.log("[GlobalSettings] fromResponse", { data });

		return data.settings;
	}

	static toRequest(model: GlobalSettings.Model.Modified): any {
		console.log("[GlobalSettings] toRequest", { model });
		return model;
	}

	public static async get() {
		return this.fromResponse(
			await this.request((prpc) =>
				prpc.theirsModel.settings.global.get(),
			),
		);
	}

	public static async update(object: GlobalSettings.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.settings.global.update(
				GlobalSettings.toRequest(object),
			),
		);
	}

	public static async subscribe(
		onUpdate: Dispatch<GlobalSettings.Model>,
	): Promise<GlobalSettings.Subscription | null> {
		const subscription = await SubscriptionPool.add((prpc) =>
			createRPCQuery(() =>
				prpc.theirsModel.settings.global.subscribe({
					onEvent: (events) => {
						onUpdate(events?.settings);
					},
					onError: (error) => {
						logger.error("Global subscription error: %o", error);
					},
				}),
			),
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
		} as GlobalSettings.Subscription;
	}
}

namespace GlobalSettings {
	export interface Subscription {
		unsubscribe(): Promise<void>;
	}

	export interface Model {
		ui: Model.UI;
	}

	export namespace Model {
		export interface UI {
			colors: UI.Colors;
			hotkeys: UI.Hotkeys;
		}

		export namespace UI {
			export interface Colors {
				orders: Colors.Orders;
			}

			export namespace Colors {
				export interface Orders {
					selected: string;
					palette: Orders.Palette;
					autoPalette: Orders.AutoPalette;
					paymentType: Orders.PaymentType;
					createdSource: Orders.CreatedSource;
				}

				export namespace Orders {
					export type AutoPaletteNames =
						| "order"
						| "orderOnline"
						| "orderPre"
						| "orderPreOnline";

					export type AutoPalette = Record<AutoPaletteNames, string>;

					type PaletteNames =
						| "color1"
						| "color2"
						| "color3"
						| "color4"
						| "color5"
						| "color6"
						| "color7"
						| "color8";

					export type Palette = Record<PaletteNames, string>;

					type PaymentTypeNames =
						| "card"
						| "cash"
						| "invoice"
						| "companyBill";

					export type PaymentType = Record<PaymentTypeNames, string>;

					type CreatedSourceNames =
						| "app"
						| "phone"
						| "website"
						| "executor"
						| "dispatcher";

					export type CreatedSource = Record<
						CreatedSourceNames,
						string
					>;
				}
			}

			type HotkeyNames =
				| "newOrder"
				| "editOrder"
				| "closeOrder"
				| "copyOrder"
				| "addPoint"
				| "insertPoint"
				| "editPoint"
				| "deletePoint"
				| "tab1"
				| "tab2"
				| "tab3"
				| "tab4"
				| "tab5"
				| "tab6"
				| "tab7"
				| "tab8"
				| "tab9"
				| "tab10"
				| "tab11"
				| "tab12"
				| "searchFilterStreet"
				| "searchFilterObjects"
				| "searchFilterLocalObjects";

			export type Hotkeys = Record<HotkeyNames, string[]>;
		}

		export type Modified = Partial<Model>;
	}
}

export default GlobalSettings;
