import React, { Dispatch, useCallback, useMemo } from "react";
import { ToggleButton } from "uikit";
import { isBoolean, noop } from "lodash";
import Root from "./components/Root";
import FavoriteButton from "./components/FavoriteButton";
import DeleteButton from "./components/DeleteButton";

const Item: React.FC<Item.Props> = ({
	disabled = false,

	enabled,
	favorite,
	deletable = false,

	selected = false,
	label = "",
	loading = false,

	onChangeEnabled,
	onChangeFavorite,

	onClick,
	onDelete,
}) => {
	const toggleButtonContainerOnClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
			event.stopPropagation(),
		[],
	);

	const content = useMemo(
		() => (
			<>
				{isBoolean(enabled) ? (
					<div onClick={toggleButtonContainerOnClick}>
						<ToggleButton.ToggleButton
							value={enabled}
							disabled={disabled}
							onChange={onChangeEnabled}
						/>
					</div>
				) : null}

				<div style={{ flex: "1 0 0%" }}>{label}</div>

				{isBoolean(favorite) ? (
					<FavoriteButton
						value={favorite}
						disabled={disabled}
						onChange={onChangeFavorite}
					/>
				) : null}

				{deletable ? <DeleteButton onClick={onDelete} /> : null}
			</>
		),
		[
			deletable,
			disabled,
			enabled,
			favorite,
			label,
			onChangeEnabled,
			onChangeFavorite,
			onDelete,
			toggleButtonContainerOnClick,
		],
	);
	return (
		<Root active={selected} onClick={loading || disabled ? noop : onClick}>
			{loading ? null : content}
		</Root>
	);
};

declare namespace Item {
	interface Props {
		disabled?: boolean;

		enabled?: boolean;
		favorite?: boolean;
		deletable?: boolean;

		selected?: boolean;
		label?: string;
		loading?: boolean;

		onChangeEnabled?: Dispatch<boolean>;
		onChangeFavorite?: Dispatch<boolean>;

		onClick?: VoidFunction;
		onDelete?: VoidFunction;
	}
}

export default Item;
