import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon, InputGroup, theme } from "uikit";

import SearchIconBorders from "../../../../../../../../SearchIconBorders";
import SearchTextBox from "../../../../../../../../SearchTextBox";
import { useTypedSelector } from "../../../../../../../../../redux/store";
import { useChatActions } from "../../../../../../hooks";
import { Filter } from "../../../../..";

const SearchBarContainer = styled("div")`
	padding: 0 6px;

	height: 48px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: ${theme.colors.white};
	width: 100%;
	box-sizing: border-box;
`;

const InputGroupStyled = styled(InputGroup.InputGroup)`
	flex-grow: 1;
	padding-right: 12px;
`;

const SearchBar: React.FC<SearchBar.Props> = (props) => {
	const { value, onChange, onBlur, onFocus } = props;
	const { saveFilterExecutorsWrite } = useChatActions();
	const { filterExecutorsWrite } = useTypedSelector(
		(state) => state.orders.chats.filter,
	);

	const { t } = useTranslation();
	return (
		<SearchBarContainer onClick={onFocus}>
			<InputGroupStyled sizes="auto 1fr">
				<SearchIconBorders style={{ width: 26, position: "relative" }}>
					<Icon
						id="search"
						size={20}
						colors={[theme.colors.disabled_text]}
						style={{ position: "absolute", top: 6, left: 10 }}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t(
							"orderPageWidgets.chat.usersModal.search.searchBar.str200",
						) ?? ""
					}...`}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</InputGroupStyled>
			<Filter
				saveFilterOnRedux={saveFilterExecutorsWrite}
				initFilter={filterExecutorsWrite}
			/>
		</SearchBarContainer>
	);
};

declare namespace SearchBar {
	interface Props {
		value: string;
		onChange: (value: string) => void;
		onBlur: () => void;
		onFocus: () => void;
	}
}

export default SearchBar;
