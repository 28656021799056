import { Calendar } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../redux/store";

export default function useDatePickerLocale(): Calendar.Props["locale"] {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	return {
		moment: language,
		submitButton: t(`ok`) || "Ok",
		cancelButton: t(`cancel`) || "Cancel",
		todayButton: t(`today`) || "Today",
	};
}
