import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useMemo,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { Icon, useInternal } from "uikit";

import OrderMap from "../../../../../../../../../../services/Map";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import { Orders } from "../../../../../../../../../../redux/reducers/Orders";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { StyledRow } from "../../../../../../../../../../components/common";
import useMapActions from "../../../../hooks/useMapActions";
import { ButtonFooter } from "../../styled";

const BaseSearchFilter: React.FC<BaseSearchFilter.Props> = ({
	activeSearchTypes = [],
	setSearchType,
	disabled,
	toggles,
	onChangeToggle,
}) => {
	const { t } = useTranslation();
	const { setALLBtnTogglesForFooter } = useMapActions();

	const btnOnFooter = useTypedSelector(
		(state) => state.orders.mapFromAddModal.toggles.btnOnFooter,
	);

	const [active, setActive] =
		useInternal<Orders.MapFromAddModal.BtnOnFooterToggles>(btnOnFooter);

	const internalValueEditor = useObjectEditor<BaseSearchFilter.Value>(
		toggles || active,
		onChangeToggle || setActive,
	);

	const streetActiveValue = internalValueEditor.useGetter("street");
	const streetValueOnChange = internalValueEditor.useSetter("street");

	const objectActiveValue = internalValueEditor.useGetter("object");
	const objectOnChange = internalValueEditor.useSetter("object");

	const localObjectActiveValue = internalValueEditor.useGetter("localObject");
	const localObjectOnChange = internalValueEditor.useSetter("localObject");

	const handleChangeSearchType = useCallback(
		(type: OrderMap.Search.Type) => {
			const isActive = activeSearchTypes?.includes(type);

			setSearchType(
				isActive
					? activeSearchTypes.filter((item) => item !== type)
					: [...activeSearchTypes, type],
			);
		},
		[activeSearchTypes, setSearchType],
	);

	const addActive = useCallback((): void => {
		if (activeSearchTypes.includes("street")) {
			streetValueOnChange(true);
		} else streetValueOnChange(false);

		if (activeSearchTypes.includes("object")) {
			objectOnChange(true);
		} else objectOnChange(false);

		if (activeSearchTypes.includes("localObject")) {
			localObjectOnChange(true);
		} else localObjectOnChange(false);
	}, [
		activeSearchTypes,
		localObjectOnChange,
		objectOnChange,
		streetValueOnChange,
	]);

	useMemo(() => {
		addActive();
	}, [addActive]);

	useEffect(() => {
		const allBtnToggles: Orders.MapFromAddModal.BtnOnFooterToggles = {
			street: streetActiveValue,
			object: objectActiveValue,
			localObject: localObjectActiveValue,
		};
		setALLBtnTogglesForFooter(allBtnToggles);
	}, [
		setALLBtnTogglesForFooter,
		streetActiveValue,
		objectActiveValue,
		localObjectActiveValue,
	]);

	return (
		<StyledRow gap="5px">
			<ButtonFooter
				disabled={disabled}
				variant="secondary"
				active={streetActiveValue}
				settingsKeyId="orderModalSearchFilterStreet"
				title={
					t(
						"pages.mainPage.pages.orders.orderModal.footer.searchFilter.str0",
					) ?? ""
				}
				onClick={() => handleChangeSearchType("street")}
			>
				<Icon id="order-modal-search-street" size={20} />
			</ButtonFooter>
			<ButtonFooter
				disabled={disabled}
				active={localObjectActiveValue}
				variant="secondary"
				settingsKeyId="orderModalSearchFilterLocalObjects"
				onClick={() => handleChangeSearchType("localObject")}
				title={
					t(
						"pages.mainPage.pages.orders.orderModal.footer.searchFilter.str1",
					) ?? ""
				}
			>
				<Icon id="order-modal-search-localObject" size={20} />
			</ButtonFooter>

			<ButtonFooter
				disabled={disabled}
				active={objectActiveValue}
				variant="secondary"
				settingsKeyId="orderModalSearchFilterObjects"
				onClick={() => handleChangeSearchType("object")}
				title={
					t(
						"pages.mainPage.pages.orders.orderModal.footer.searchFilter.str2",
					) ?? ""
				}
			>
				<Icon id="order-modal-search-object" size={22} />
			</ButtonFooter>
		</StyledRow>
	);
};

declare namespace BaseSearchFilter {
	interface Props extends PropsWithChildren {
		activeSearchTypes: OrderMap.Search.Type[];
		setSearchType: (types: OrderMap.Search.Type[]) => void;
		disabled?: boolean;
		toggles?: Value;
		onChangeToggle?: React.Dispatch<React.SetStateAction<Value>>;
	}

	interface Value {
		street: boolean;
		object: boolean;
		localObject: boolean;
	}
}

export const SearchFilter = memo(BaseSearchFilter);
export default BaseSearchFilter;
