// eslint-disable-next-line import/prefer-default-export
export const normalizeExecutorObject = (executor) =>
	({
		id: executor.id,
		alias: executor?.alias || executor?.callSign,
		taxiServiceId: executor?.taxiService?.id,
		companyId: executor?.taxiService?.company?.id,
	} as {
		id: number;
		alias: string;
		taxiServiceId: number;
		companyId: number;
	});
