import React from "react";
import styled from "styled-components";

import Map from "./Map2";
import WidgetChat from "./Chat";
import WidgetParking from "./Parking";
import WidgetSoftPhone from "./SoftPhone";
import OrderTable from "./OrderTable";
import OrderDetails from "./OrderDetails";
import ExecutorsWidget from "./Executors";

const StyledWidgetWrapper = styled.div`
	overflow: hidden;
	height: 100%;
`;

const routeWidgetByType = (props) => {
	switch (props.type) {
		case "map":
			return <Map />;
		case "table":
			return <OrderTable />;
		case "chat":
			return <WidgetChat {...props} />;
		case "parking":
			return <WidgetParking {...props} />;
		case "softphone":
			return <WidgetSoftPhone {...props} />;
		case "previewer":
			return <OrderDetails />;
		case "executors":
			return <ExecutorsWidget {...props} />;
		default:
			return false;
	}
};

function WidgetRouter(props) {
	return (
		<StyledWidgetWrapper>{routeWidgetByType(props)}</StyledWidgetWrapper>
	);
}

export default WidgetRouter;
