import styled, { css } from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<Root.Props>`
	${({ width }) =>
		width
			? css`
					width: ${width}px;
			  `
			: ""}

	${({ usePaddings }) =>
		usePaddings
			? css`
					padding: 20px;
			  `
			: ""}

	border-radius: 10px;

	background: ${theme.colors.white};

	pointer-events: auto;
	overflow: hidden;
`;

declare namespace Root {
	interface Props {
		width?: number;
		usePaddings?: boolean;
	}
}

export default Root;
