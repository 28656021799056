import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { clone } from "lodash";
import { useChanged } from "uikit";

import orderPage from "../../../redux/reducers/OrdersPage";
import { IOrderPoint } from "../../../redux/constants/OrdersPage/order";
import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import DeleteModal from "../../DeleteModal";
import PointModal from "../../PointModal";

import useActiveOrderPointUpdater from "./hooks/useActiveOrderPointUpdater";
import Info from "./components/Info";
import Root from "./components/Root";
import Controls from "./components/Controls";
import Points from "./components/Points";

interface PointModalState {
	show: boolean;
	mode: "add" | "insert" | "edit";
	value?: IOrderPoint;
}

const OrderDetails: React.FC = () => {
	const dispatch = useTypedDispatch();

	const language = useTypedSelector((state) => state.session.language);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const activePoint = useTypedSelector(
		(state) => state.ordersPageReducer.activePoint,
	);

	const [pointModal, setPointModal] = useState<PointModalState>({
		show: false,
		mode: "add",
		value: undefined,
	});
	const [showDeletePointModal, setShowDeletePointModal] = useState(false);

	const updatePoints = useActiveOrderPointUpdater();

	const movePoint = useCallback(
		(offset: number) => {
			if (!("id" in activeOrder)) {
				return;
			}

			const { points } = activeOrder;

			const newPoints = clone(points);

			const pointToSwapWithIndex = activePoint + offset;

			if (
				pointToSwapWithIndex < 0 ||
				pointToSwapWithIndex >= points.length
			) {
				return;
			}

			const temp = newPoints[pointToSwapWithIndex];

			newPoints[pointToSwapWithIndex] = newPoints[activePoint];
			newPoints[activePoint] = temp;

			dispatch(orderPage.actions.setActivePoint(pointToSwapWithIndex));

			updatePoints(newPoints);
		},
		[activeOrder, activePoint, dispatch, updatePoints],
	);

	const deletePoint = useCallback(() => {
		if (!("id" in activeOrder)) {
			return;
		}

		const { points } = activeOrder;

		const newPoints = clone(points);

		newPoints.splice(activePoint, 1);

		const newActivePointIndex = Math.min(activePoint, newPoints.length - 1);

		if (newActivePointIndex !== activePoint) {
			dispatch(orderPage.actions.setActivePoint(newActivePointIndex));
		}

		updatePoints(newPoints);
	}, [activeOrder, activePoint, dispatch, updatePoints]);

	const controlsOnAdd = useCallback(() => {
		if (!("id" in activeOrder)) {
			return;
		}

		setPointModal({
			show: true,
			mode: "add",
			value: undefined,
		});
	}, [activeOrder]);

	const controlsOnInsert = useCallback(() => {
		if (!("id" in activeOrder)) {
			return;
		}

		setPointModal({
			show: true,
			mode: "insert",
			value: undefined,
		});
	}, [activeOrder]);

	const controlsOnEdit = useCallback(() => {
		if (!("id" in activeOrder)) {
			return;
		}

		setPointModal({
			show: true,
			mode: "edit",
			value: {
				...activeOrder.points[activePoint],

				customStreet: "",
				customHouse: "",
			},
		});
	}, [activeOrder, activePoint]);

	const controlsOnDelete = useCallback(() => {
		setShowDeletePointModal(true);
	}, []);

	const controlsOnMoveUp = useCallback(() => {
		movePoint(-1);
	}, [movePoint]);

	const controlsOnMoveDown = useCallback(() => {
		movePoint(1);
	}, [movePoint]);

	const pointModalTaxiServiceId = useMemo(
		() => activeOrder?.taxiService?.id,
		[activeOrder?.taxiService?.id],
	);

	const pointModalDefaultCity = useMemo(() => {
		const settlement = activeOrder?.taxiService?.settlement[language];

		return {
			id: activeOrder?.taxiService?.id,
			value: settlement,
			settlement,
			coordinates: activeOrder?.taxiService?.settlementPoint,
		};
	}, [activeOrder?.taxiService, language]);

	const pointModalPopularAddresses = useMemo(() => [], []);

	const pointModalIsFirstPoint = useMemo(
		() => activeOrder?.points?.length === 0,
		[activeOrder?.points?.length],
	);

	const pointModalOnClose = useCallback(() => {
		setPointModal({
			show: false,
			mode: "add",
			value: undefined,
		});
	}, []);

	const pointModalOnSubmit = useCallback(
		(point) => {
			if (!("id" in activeOrder)) {
				return;
			}

			const newPoints = clone(activeOrder.points);

			if (pointModal.mode === "add") {
				newPoints.push(point);
			} else if (pointModal.mode === "insert") {
				newPoints.splice(activePoint, 0, point);
			} else if (pointModal.mode === "edit") {
				newPoints[activePoint] = point;
			}

			updatePoints(newPoints);

			pointModalOnClose();
		},
		[
			activeOrder,
			activePoint,
			pointModal.mode,
			pointModalOnClose,
			updatePoints,
		],
	);

	const deletePointModalOnCancel = useCallback(() => {
		setShowDeletePointModal(false);
	}, []);

	const deletePointModalOnConfirm = useCallback(() => {
		deletePoint();
		setShowDeletePointModal(false);
	}, [deletePoint]);

	useChanged(
		() => {
			process.nextTick(() => {
				pointModalOnClose();
				deletePointModalOnCancel();
			});
		},
		[
			activeOrder?.id,
			activePoint,
			activeOrder?.points?.[activePoint],
			pointModalOnClose,
		],
		true,
	);

	const { t } = useTranslation();
	return (
		<>
			<Root>
				<Points language={language} />
				<Info language={language} />
				<Controls
					onAdd={controlsOnAdd}
					onInsert={controlsOnInsert}
					onEdit={controlsOnEdit}
					onDelete={controlsOnDelete}
					onMoveUp={controlsOnMoveUp}
					onMoveDown={controlsOnMoveDown}
				/>
			</Root>
			{pointModal.show ? (
				<PointModal
					taxiServiceId={pointModalTaxiServiceId}
					defaultCity={pointModalDefaultCity}
					popularAddresses={pointModalPopularAddresses}
					isFirstPoint={pointModalIsFirstPoint}
					editPoint={pointModal.value}
					onClose={pointModalOnClose}
					onSubmit={pointModalOnSubmit}
				/>
			) : null}
			{showDeletePointModal ? (
				<DeleteModal
					label={t("orderPageWidgets.orderDetails.str200") ?? ""}
					onCancel={deletePointModalOnCancel}
					onConfirm={deletePointModalOnConfirm}
				/>
			) : null}
		</>
	);
};

export default OrderDetails;
