import React from "react";
import { Icon, Row, theme } from "uikit";
import { noop } from "lodash";
import Text from "./components/Text";
import Subtext from "./components/Subtext";
import Button from "./components/Button";
import InformationContainer from "./components/InformationContainer";

const Footer: React.FC<Footer.Props> = ({
	disabled,
	name,
	size,
	onDownload,
	onDelete,
}) => (
	<Row align="center" gaps="20px*" sizes="1fr auto!*">
		<InformationContainer gaps="2px">
			<Text>{name}</Text>
			<Subtext>{size}</Subtext>
		</InformationContainer>
		<Row gaps="8px*">
			<Button onClick={onDownload}>
				<Icon
					id="save"
					size={20}
					colors={[theme.colors.secondary]}
				></Icon>
			</Button>
			<Button disabled={disabled} onClick={disabled ? noop : onDelete}>
				<Icon
					id="trash"
					size={20}
					colors={[theme.colors.secondary]}
				></Icon>
			</Button>
		</Row>
	</Row>
);

declare namespace Footer {
	interface Props {
		disabled: boolean;
		name: string;
		size: string;

		onDownload: () => void;
		onDelete: () => void;
	}
}

export default Footer;
