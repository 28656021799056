import { useCallback, useMemo } from "react";
import { LatLngLiteral } from "leaflet";
import { useInternal } from "uikit";

import { IOrderPoint } from "../../../../../redux/constants/OrdersPage/order";
import getSectorByPoint from "../../../../../redux/services/Sector/getSectorByPoint";

import PointOrderModalTableBase from "..";

export type Sector = IOrderPoint["sector"];

export interface UseGetSector {
	response: Sector;
	get: (coordinates: LatLngLiteral) => Promise<Sector>;
	addSector: (
		row: PointOrderModalTableBase.OrderModal,
		value?: Sector,
	) => PointOrderModalTableBase.OrderModal;
	reset: () => void;
}

export type UseGetSectorProps = () => UseGetSector;

/**
 *  `useGetSector`
 */
export const useGetSector: UseGetSectorProps = () => {
	const [response, setResponse] = useInternal<Sector>(undefined);

	const get = useCallback<UseGetSector["get"]>(
		async (coordinates) => {
			const res = await getSectorByPoint(coordinates);
			setResponse(res);

			return res;
		},
		[setResponse],
	);

	const addSector = useCallback<UseGetSector["addSector"]>(
		(row, value?: Sector) => {
			const payload = value || response;
			if (!payload) return row;
			const sector = { id: payload?.id, name: payload?.name };
			return { ...row, raw: { ...row.raw, sector } };
		},
		[response],
	);

	const reset = useCallback(() => {
		setResponse(undefined);
	}, [setResponse]);

	return useMemo<UseGetSector>(
		() => ({
			response,
			addSector,
			reset,
			get,
		}),
		[response, addSector, reset, get],
	);
};
