import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import Settings from "../../../../components/Settings";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";

import ColorSettings from "./Colors";
import AppearanceSettings from "./Fonts";
import KeybindSettings from "./Keybinds";

const InterfaceSettings: React.FC = () => {
	const [ACCESS_SECTION, ACCESS_SECTION_2] = [
		AccessKey.SETTINGS,
		AccessKey.SETTINGS_INTERFACE,
	];
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [hasChanges, setHasChanges] = useState(false);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "colors",
					label: t("settings.interface.colors.title"),
					value: {
						render() {
							return (
								<ColorSettings
									methods={(communicationMethods) => {
										methods.current = communicationMethods;
									}}
									onHasChanges={setHasChanges}
								/>
							);
						},
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.COLORS,
					),
				},
				{
					key: "font",
					label: t("settings.interface.fonts.title"),
					value: {
						render() {
							return (
								<AppearanceSettings
									methods={(communicationMethods) => {
										methods.current = communicationMethods;
									}}
									onHasChanges={setHasChanges}
								/>
							);
						},
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.FONT,
					),
				},
				{
					key: "keybinds",
					label: t("settings.interface.keybinds.title"),
					value: {
						render() {
							return (
								<KeybindSettings
									methods={(communicationMethods) => {
										methods.current = communicationMethods;
									}}
									onHasChanges={setHasChanges}
								/>
							);
						},
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.KEYBINDS,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, ACCESS_SECTION_2, personalRole],
	);

	return (
		<Settings
			tabs={tabs}
			methods={methods}
			hasChanges={hasChanges}
			onSetHasChanges={setHasChanges}
		/>
	);
};

export default InterfaceSettings;
