import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Column, Icon, Nullable, Row, theme } from "uikit";
import { useTranslation } from "react-i18next";

import { Call as CallType } from "../../../../../../../../../redux/reducers/softphoneWidget";
import getCallTypes from "../../../../../../../../../components/SoftphoneWidget/constants";
import { RegisteredIcon } from "../../../../../../../../../assets/icons";
import { getAndCallDown, callUp } from "../../../../../../../../../utils/jsSip";

const CallStyled = styled(Row)`
	padding: 8px;
	height: 52px;
	width: 292px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	border-radius: 12px;
	background-color: ${theme.colors.white};
	box-shadow: 0px 6px 16px 0px #00000026;

	cursor: pointer;
`;

const Avatar = styled("div")<{ bgColor: string; fontColor: string }>`
	height: 36px;
	width: 36px;

	display: flex;
	justify-content: center;
	align-items: center;

	color: ${({ fontColor }) => fontColor};
	font-family: Lato;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: center;

	border-radius: 50%;
	background-color: ${({ bgColor }) => bgColor};
	cursor: pointer;
`;

const PhoneNumber = styled(Row)`
	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	cursor: pointer;
`;

const CallerName = styled(Row)`
	color: ${theme.colors.secondary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
	cursor: pointer;
`;

const Duration = styled("span")<{ color: string }>`
	color: ${({ color }) => color || theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: center;
	cursor: pointer;
`;

interface CallTypeAndDurationProps {
	color: string;
	iconName: RegisteredIcon;
	duration: string | null;
}

const CallTypeAndDuration: React.FC<CallTypeAndDurationProps> = ({
	color,
	iconName,
	duration,
}) => (
	<Row style={{ height: 16 }} align="center" gaps="4px">
		<Icon id={iconName} size={16} colors={[color]} />
		<Duration color={color}>{duration}</Duration>
	</Row>
);

interface Props {
	data: CallType;
	selectedCall: Nullable<CallType>;
	callerIndex: number;
	onSelectCaller: (callerData: CallType) => void;
}

const Call: React.FC<Props> = ({
	data,
	selectedCall,
	callerIndex,
	onSelectCaller,
}) => {
	const { t } = useTranslation();
	const callTypes = useMemo(() => getCallTypes(t), [t]);

	const [isHovered, setIsHovered] = useState(false);
	const callPausedBgColor = useMemo(
		() =>
			selectedCall?.session._localHold &&
			selectedCall?.session.id === data?.session.id
				? callTypes.pause.primaryColor
				: callTypes.pause.secondaryColor,
		[
			callTypes.pause.primaryColor,
			callTypes.pause.secondaryColor,
			data?.session.id,
			selectedCall?.session._localHold,
			selectedCall?.session.id,
		],
	);

	const callPausedFontColor = useMemo(
		() =>
			selectedCall?.session._localHold &&
			selectedCall?.session.id === data?.session.id
				? theme.colors.white
				: callTypes.pause.primaryColor,
		[
			callTypes.pause.primaryColor,
			data?.session.id,
			selectedCall?.session._localHold,
			selectedCall?.session.id,
		],
	);

	const callBgColor = useMemo(() => {
		if (data?.session._is_confirmed) {
			if (selectedCall?.session.id === data?.session.id) {
				return callTypes.current.primaryColor;
			}
			return callTypes.current.secondaryColor;
		}
		return selectedCall?.session.id === data?.session.id
			? callTypes[data.session.direction].primaryColor
			: callTypes[data.session.direction].secondaryColor;
	}, [
		callTypes,
		data.session._is_confirmed,
		data.session.direction,
		data.session.id,
		selectedCall?.session.id,
	]);
	const callFontColor = useMemo(() => {
		selectedCall?.session.id === data?.session.id
			? theme.colors.white
			: callTypes[data.session.direction].primaryColor;

		if (data?.session._is_confirmed) {
			if (selectedCall?.session.id === data?.session.id) {
				return theme.colors.white;
			}
			return callTypes.current.primaryColor;
		}
		return selectedCall?.session.id === data?.session.id
			? theme.colors.white
			: callTypes[data.session.direction].primaryColor;
	}, [
		callTypes,
		data.session._is_confirmed,
		data.session.direction,
		data.session.id,
		selectedCall?.session.id,
	]);

	const bgColor = useMemo(
		() => (data?.session._localHold ? callPausedBgColor : callBgColor),
		[callBgColor, callPausedBgColor, data?.session._localHold],
	);

	const fontColor = useMemo(
		() => (data?.session._localHold ? callPausedFontColor : callFontColor),
		[callFontColor, callPausedFontColor, data?.session._localHold],
	);

	const selectCaller = useCallback(() => {
		onSelectCaller(data);
	}, [data, onSelectCaller]);

	const showCallButtons = useCallback(() => {
		setIsHovered(true);
	}, []);
	const hideCallButton = useCallback(() => {
		setIsHovered(false);
	}, []);

	const durationColor = useMemo(() => {
		if (data.session._localHold) {
			return callTypes[data.session.direction].primaryColor;
		}
		if (data.session._is_confirmed) {
			return callTypes.current.primaryColor;
		}
		return callTypes[data.session.direction].primaryColor;
	}, [
		callTypes,
		data.session._is_confirmed,
		data.session._localHold,
		data.session.direction,
	]);

	const durationIcon = useMemo(() => {
		if (data.session._localHold) {
			return callTypes[data.session.direction].iconName;
		}
		if (data.session._is_confirmed) {
			return callTypes.current.iconName;
		}
		return callTypes[data.session.direction].iconName;
	}, [
		callTypes,
		data.session._is_confirmed,
		data.session._localHold,
		data.session.direction,
	]);

	const callDown = useCallback(() => getAndCallDown(), []);

	const callButtonOnClick = useCallback(() => callUp(), []);

	return (
		<CallStyled
			onClick={selectCaller}
			onMouseEnter={showCallButtons}
			onMouseLeave={hideCallButton}
		>
			<Row gaps="8px">
				<Avatar bgColor={bgColor} fontColor={fontColor}>
					{callerIndex}
				</Avatar>
				<Column justify="center">
					<PhoneNumber>
						{data.session.remote_identity.uri.user}
					</PhoneNumber>
					{data.session?.name && (
						<CallerName>{data.session?.name}</CallerName>
					)}
				</Column>
			</Row>
			{!isHovered && (
				<Column gaps="2px">
					<CallTypeAndDuration
						color={durationColor}
						iconName={durationIcon}
						duration={data.callDuration}
					/>
					{data.session._localHold && (
						<CallTypeAndDuration
							color={callTypes.pause.primaryColor}
							iconName={callTypes.pause.iconName}
							duration={data.holdDuration}
						/>
					)}
				</Column>
			)}
			{isHovered && (
				<Row gaps="8px">
					<Button.Button
						onClick={callButtonOnClick}
						style={{
							height: 36,
							width: 36,
							borderRadius: "50%",
							backgroundColor: "#4CAF50",
						}}
						disabled={
							data.session._is_confirmed ||
							data.session._direction === "outgoing"
						}
						icon={<Icon id="call-start" size={18} />}
					/>
					<Button.Button
						onClick={callDown}
						style={{
							height: 36,
							width: 36,
							borderRadius: "50%",
							backgroundColor: theme.colors.negative,
						}}
						icon={<Icon id="call-end" size={18} />}
					/>
				</Row>
			)}
		</CallStyled>
	);
};

export default Call;
