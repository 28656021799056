import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Column, Icon, Row, theme, Button as KitButton } from "uikit";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "./components/Button";
import softphoneWidget from "../../../../../../../../redux/reducers/softphoneWidget";
import { useTypedSelector } from "../../../../../../../../redux/store";

const KeyboardStyled = styled(Column)`
	padding: 15px;

	width: 100%;
	height: 180px;
`;

const NumberInput = styled("input")`
	height: 48px;
	width: 100%;

	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;

	&::placeholder {
		color: ${theme.colors.disabled_text};
	}
	:focus {
		caret-color: ${theme.colors.accent};
`;

interface Props {
	query: string;
}

const Keyboard: React.FC<Props> = ({ query }) => {
	const dispatch = useDispatch();

	const { isFocused } = useTypedSelector((state) => state.softphoneWidget);

	const onPressButton = useCallback(
		(value) => {
			dispatch(
				softphoneWidget.actions.setTransferNumberQuery(query + value),
			);
		},
		[dispatch, query],
	);

	const onChange = useCallback(
		(e) => {
			dispatch(
				softphoneWidget.actions.setTransferNumberQuery(
					e.currentTarget.value,
				),
			);
		},
		[dispatch],
	);

	const clearQuery = useCallback(() => {
		dispatch(softphoneWidget.actions.setTransferNumberQuery(""));
	}, [dispatch]);

	const inputRef = useRef<any>(null);
	const onFocus = useCallback(() => {
		setTimeout(() => {
			inputRef?.current.focus();
		}, 30);
	}, []);

	const onBlur = useCallback(() => {
		setTimeout(() => {
			inputRef?.current.blur();
		}, 30);
	}, []);
	useEffect(() => {
		if (isFocused) {
			onFocus();
		} else if (!isFocused) {
			onBlur();
		}
	}, [isFocused, onBlur, onFocus]);

	const { t } = useTranslation();
	return (
		<>
			<Row align="center" style={{ position: "relative" }} maxedWidth>
				<NumberInput
					placeholder={`${
						t(
							"softphoneWidget.transfer.footerButtons.keyboard.str200",
						) ?? ""
					}...`}
					onChange={onChange}
					value={query}
					ref={inputRef}
				/>
				{query && (
					<KitButton.Button
						style={{
							backgroundColor: theme.colors.white,
							position: "absolute",
							right: 0,
							top: 7,
						}}
						icon={
							<Icon
								id="backspace-number"
								size={80}
								style={{
									position: "absolute",
									top: 3,
									left: 2,
									width: 35,
									height: 35,
								}}
								colors={[theme.colors.secondary]}
							/>
						}
						onClick={clearQuery}
					/>
				)}
			</Row>
			<KeyboardStyled gaps="4px*">
				<Row gaps="4px*">
					<Button text="1" value="1" onPressButton={onPressButton} />
					<Button text="2" value="2" onPressButton={onPressButton} />
					<Button text="3" value="3" onPressButton={onPressButton} />
				</Row>
				<Row gaps="4px*">
					<Button text="4" value="4" onPressButton={onPressButton} />
					<Button text="5" value="5" onPressButton={onPressButton} />
					<Button text="6" value="6" onPressButton={onPressButton} />
				</Row>
				<Row gaps="4px*">
					<Button text="7" value="7" onPressButton={onPressButton} />
					<Button text="8" value="8" onPressButton={onPressButton} />
					<Button text="9" value="9" onPressButton={onPressButton} />
				</Row>
				<Row gaps="4px*">
					<Button
						value="*"
						onPressButton={onPressButton}
						text={
							<Icon
								id="phone-star"
								size={24}
								colors={[theme.colors.secondary]}
							/>
						}
					/>
					<Button text="0" value="0" onPressButton={onPressButton} />
					<Button
						value="#"
						onPressButton={onPressButton}
						text={
							<Icon
								id="phone-hash"
								size={24}
								colors={[theme.colors.secondary]}
							/>
						}
					/>
				</Row>
			</KeyboardStyled>
		</>
	);
};

export default Keyboard;
