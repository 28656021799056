import { useMemo, useState } from "react";

import {
	IOrderPoint,
	Schema,
	createOrderFormBy,
} from "../../../../../redux/constants/OrdersPage/order";
import Order from "../../../../../services/Order";
import { Tab, TabState } from "../../Orders/components/OrderModal/types/Tab";
import { useTypedSelector } from "../../../../../redux/store";
import { validateOrderForm } from "../../../../../utils/validateOrderForm";
import { DefaultCity } from "../../../../../redux/reducers/map.reducer";

export type UseGetOrderTab = {
	points: IOrderPoint[];
	tab: Tab | null;
	defaultCity: DefaultCity;
};

/**
 *  `useGetOrderTab`
 */
export const useGetOrderTab = (order: Order.Model | null): UseGetOrderTab => {
	const { defaultCity } = useTypedSelector((state) => state.mapReducer);

	const orderForm = useMemo<Schema | null>(() => {
		if (order) return createOrderFormBy(order);
		return null;
	}, [order]);

	const [tabState, setTabState] = useState<TabState>({
		customerId: null,
		defaultCity,
		popularAddresses: [],
		additionalNumbers: Math.max(
			1,
			orderForm?.additionalPhones?.length || 0,
		),
		modals: {
			time: false,
			classes: false,
			services: false,
		},
		route: null,
		routeTaximeter: null,
	});

	const tab = useMemo<Tab | null>(() => {
		if (!orderForm) return null;
		return {
			uid: 0,
			id: order?.id,
			data: {
				color: "red",
				label: "label",
				number: 42,
				type: "type",
			},
			form: orderForm,
			validator: validateOrderForm(orderForm),
			state: tabState,
			setState: setTabState,
		};
	}, [order, orderForm, tabState, setTabState]);

	const points = useMemo(() => tab?.form.points || [], [tab]);

	return useMemo<UseGetOrderTab>(
		() => ({ points, tab, defaultCity }),
		[points, tab, defaultCity],
	);
};

export default useGetOrderTab;
