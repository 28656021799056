import React, { memo, useMemo } from "react";

import Transaction from "../../../../../../../../../../services/Transaction";
import {
	StyledP,
	SuspenseLoader,
} from "../../../../../../../../../../components/common";
import Language from "../../../../../../../../../../services/Language";

const Credentials: React.FC<Credentials.Props> = memo(({ value }) => {
	const { relation, language } = value;

	const credentials = useMemo(() => {
		const {
			company,
			customer,
			taxiService,
			executor,
			agent,
			counterparty,
			counterpartyCheck,
		} = relation;
		const text: string[] = [];

		const getFullName = (person: Transaction.Person) => {
			const fullName: string[] = [];
			if (person?.surname) fullName.push(person?.surname);
			if (person?.name) fullName.push(person?.name);
			if (person?.fatherName) fullName.push(person?.fatherName);
			if (!fullName.length) return "";
			return fullName.join(" ");
		};

		const getName = (name: Transaction.Name | string): string => {
			if (name) {
				if (typeof name === "object") return name[language];
				if (typeof name === "string") return name;
			}
			return "";
		};

		try {
			if (customer) {
				const { person, company } = customer;
				if (person) text.push(getFullName(person));
				if (company?.name) text.push(getName(company.name));
			}
			if (executor) {
				const { person, callSign, taxiService } = executor;
				if (person) text.push(getFullName(person));
				if (callSign) text.push(callSign);
				if (taxiService) {
					const { settlement, company } = taxiService;
					if (company?.name) text.push(getName(company.name));
					if (settlement) text.push(getName(settlement));
				}
			}
			if (agent) {
				const { company, name } = agent;
				if (name) text.push(getName(name));
				if (company?.name) text.push(getName(company.name));
			}

			if (counterpartyCheck) {
				const { company, checkValue } = counterpartyCheck;
				if (company?.name) text.push(getName(company.name));
				if (checkValue) text.push(checkValue);
			}

			if (counterparty) {
				const { company, name } = counterparty;
				if (name) text.push(getName(name));
				if (company?.name) text.push(getName(company.name));
			}

			if (company) {
				const { name } = company;
				if (name) text.push(getName(name));
			}

			if (taxiService) {
				const { settlement, company } = taxiService;
				if (company?.name) text.push(getName(company.name));
				if (settlement) text.push(getName(settlement));
			}
		} catch {
			return <SuspenseLoader loader="4" />;
		}

		if (!text.length) return <></>;

		return text.join(", ");
	}, [relation, language]);

	return <StyledP>{credentials}</StyledP>;
});

declare namespace Credentials {
	interface Props {
		value: Value;
	}

	interface Value {
		relation: Transaction.PaymentAccountRelation;
		language: Language;
	}
}

export default Credentials;
