import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface CodeConfirmationContentProps {
	keyword: string;
}

const StyledContent = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	gap: 10px;

	.title {
		font-size: 14px;
		line-height: 16px;
	}

	.code {
		font-size: 24px;
		line-height: 28px;
		font-weight: 600;
		letter-spacing: 0.05em;
	}
`;

export default function CodeConfirmationContent({
	keyword,
}: CodeConfirmationContentProps) {
	const { t } = useTranslation();

	return (
		<StyledContent>
			<p className="title">
				{t([
					`orderPage.order_form.code_confirmation.keyword_title`,
					"Verification word",
				]) || ""}
			</p>
			<div className="code">{keyword}</div>
		</StyledContent>
	);
}
