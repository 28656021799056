import React, {
	Dispatch,
	memo,
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";
import {
	CheckBoxWithContent,
	Column,
	DateAndTimePicker,
	DatePicker,
	Option,
	Row,
	Select,
	TimeInput,
	react,
	useRefWithSetter,
} from "uikit";

import ExecutorRateClassifier from "../../../../../../../../../../../../../../../../services/ExecutorRateClassifier";
import Language from "../../../../../../../../../../../../../../../../services/Language";
import {
	useCurrencyGlobalSettings,
	useModelSubscribe,
	useObjectEditor,
	useDatePickerLocale,
} from "../../../../../../../../../../../../../../../../hooks";
import {
	ConstantFormat,
	DateFns,
} from "../../../../../../../../../../../../../../../../utils/DateFns";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";
import FieldsContainer from "../../../../../../../../../../../../../../../../components/FieldsContainer";
import CheckBoxSelect from "../../../../../../../../../../../../../../../../components/CheckBoxSelect";
import {
	NameBase,
	Name,
	CheckBoxWithStepper,
	StyledColumn,
} from "../../../../../../../../../../../../../../../../components/common";

import InternalController from "./Controller";
import { modeOptions, periodUnitOptions, weekdayOptions } from "./data";
import Root from "./components/Root";
import Commissions from "./components/Commissions";

export const marginLeft = { marginLeft: 30 };

export interface ContentErrors {
	classifierId: boolean;
	minFeeValueValue: boolean | string;
	maxFeeValueValue: boolean | string;
}

const ContentBase: React.FC<Content.Props> = react.withController<
	Content.PropsBase,
	Content.Controller
>(({ value, language, onChange, setTypeName, controller }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const [nameRef, setNameRef] = useRefWithSetter<NameBase.Ref | null>(null);
	const classifierData = useModelSubscribe({}, ExecutorRateClassifier);
	const dateFns = useMemo(() => new DateFns(), []);

	const [errors, setErrors] = useState<ContentErrors>({
		classifierId: false,
		minFeeValueValue: false,
		maxFeeValueValue: false,
	});

	const classifierOptions = useMemo<Option<number>[]>(
		() =>
			classifierData.models
				.filter(
					(classifier) =>
						classifier.type ===
						ExecutorRateClassifier.Model.Type.Commission,
				)
				.map((classifier) => ({
					key: classifier.id,
					value: classifier.id,
					label: classifier.name[language],
				})),
		[classifierData.models, language],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const nameValue = valueEditor.useGetter("name");
	const nameOnChange = valueEditor.useSetter("name");

	const classifierIdValue = valueEditor.useGetter("classifierId");
	const classifierIdOnChange = valueEditor.useSetter("classifierId");
	const onSelect = useCallback(
		(option: Option<number>) => {
			setErrors((prev) => ({
				...prev,
				classifierId: false,
			}));
			setTypeName(option.label);
			classifierIdOnChange(option.value);
		},
		[classifierIdOnChange, setTypeName],
	);

	const startDateEditor = valueEditor.usePropertyEditor("startDate");

	const startDateValueValue = startDateEditor.useGetter("value");
	const startDateValueOnChange = startDateEditor.useSetter("value");

	const timeRangeEditor = valueEditor.usePropertyEditor("timeRange");

	const timeRangeActiveValue = timeRangeEditor.useGetter("active");
	const timeRangeActiveOnChange = timeRangeEditor.useSetter("active");

	const timeRangeFromValue = timeRangeEditor.useGetter("from");
	const timeRangeFromOnChange = timeRangeEditor.useSetter("from");

	const timeRangeToValue = timeRangeEditor.useGetter("to");
	const timeRangeToOnChange = timeRangeEditor.useSetter("to");

	const modeEditor = valueEditor.usePropertyEditor("mode");

	const modeValueValue = modeEditor.useGetter("value");
	const modeValueOnChange = modeEditor.useSetter("value");

	const weekdaysValue = valueEditor.useGetter("weekdays");
	const weekdaysOnChange = valueEditor.useSetter("weekdays");

	const weekdayCheckBoxSelectValue = useMemo(
		() =>
			Object.entries(weekdaysValue)
				.filter(([, value]) => value)
				.map(([key]) => key) as (keyof Content.Value.Weekdays)[],
		[weekdaysValue],
	);

	const weekdayCheckBoxSelectOnChange = useCallback(
		(weekdays: (keyof Content.Value.Weekdays)[]) => {
			weekdaysOnChange({
				monday: weekdays.includes("monday"),
				tuesday: weekdays.includes("tuesday"),
				wednesday: weekdays.includes("wednesday"),
				thursday: weekdays.includes("thursday"),
				friday: weekdays.includes("friday"),
				saturday: weekdays.includes("saturday"),
				sunday: weekdays.includes("sunday"),
			});
		},
		[weekdaysOnChange],
	);

	const commissionsValue = valueEditor.useGetter("commissions");
	const commissionsOnChange = valueEditor.useSetter("commissions");

	const minFeeEditor = valueEditor.usePropertyEditor("minFee");
	const minFee = valueEditor.useGetter("minFee");
	const setMinFee = valueEditor.useSetter("minFee");
	const minFeeActiveValue = minFeeEditor.useGetter("active");
	const minFeeValueValue = minFeeEditor.useGetter("value");

	const maxFeeEditor = valueEditor.usePropertyEditor("maxFee");
	const maxFee = valueEditor.useGetter("maxFee");
	const setMaxFee = valueEditor.useSetter("maxFee");
	const maxFeeActiveValue = maxFeeEditor.useGetter("active");
	const maxFeeValueValue = maxFeeEditor.useGetter("value");

	const locale = useDatePickerLocale();

	const onSelectMode = useCallback(
		(option: Option<(typeof modeOptions)[number]["value"]>) => {
			modeValueOnChange(option.value);
		},
		[modeValueOnChange],
	);

	const getDayOfWeek = useCallback(
		(date) => {
			const dayOfWeek = dateFns.formatTime(
				date,
				ConstantFormat.day,
				language,
			);
			return dayOfWeek;
		},
		[dateFns, language],
	);

	const feeModesOptions = useMemo(() => modeOptions, []);

	useLayoutEffect(() => {
		if (
			minFeeActiveValue &&
			maxFeeActiveValue &&
			isNumber(minFeeValueValue) &&
			isNumber(maxFeeValueValue)
		) {
			const minFee = minFeeValueValue;
			const maxFee = maxFeeValueValue;

			setErrors((prev) => ({
				...prev,
				minFeeValueValue: minFee > maxFee,
				maxFeeValueValue: maxFee < minFee,
			}));
		}

		if (!minFeeActiveValue || !maxFeeActiveValue) {
			setErrors((prev) => ({
				...prev,
				minFeeValueValue: false,
				maxFeeValueValue: false,
			}));
		}
	}, [
		maxFeeActiveValue,
		maxFeeValueValue,
		minFeeActiveValue,
		minFeeValueValue,
	]);

	const minMaxOptions = useMemo(
		() => ({
			min: {
				decimalCount: 2,
				error: errors.minFeeValueValue,
			},
			max: {
				decimalCount: 2,
				error: errors.maxFeeValueValue,
			},
		}),
		[errors.maxFeeValueValue, errors.minFeeValueValue],
	);

	controller.setContext({
		nameRef,
		classifierIdValue,
		minFeeValueValue,
		maxFeeValueValue,
		minFeeActiveValue,
		maxFeeActiveValue,
		setErrors,
	});

	return (
		<Root hasPaddings>
			<Column gaps="19px*">
				<FieldsContainer
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str0",
						) ?? ""
					}
				>
					<Column gaps="10px*">
						<Row gaps="8px" sizes="1fr*">
							<Name
								ref={setNameRef}
								name={nameValue || ""}
								setName={nameOnChange}
								label={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str200",
									) ?? ""
								}
							/>

							<LabeledField
								label={
									t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str1",
									) ?? ""
								}
							>
								<Select
									error={errors.classifierId}
									value={classifierIdValue}
									options={classifierOptions}
									placeholder={
										t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str1",
										) ?? ""
									}
									onSelect={onSelect}
								/>
							</LabeledField>
						</Row>
						<Row gaps="8px" sizes="1fr*">
							<Row
								gaps="10px*"
								align="center"
								style={{ height: 32, lineHeight: "18px" }}
							>
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str100",
									) ?? ""}
								</span>
								{timeRangeActiveValue ? (
									<DatePicker
										value={startDateValueValue}
										locale={locale}
										onChange={startDateValueOnChange}
									/>
								) : (
									<DateAndTimePicker
										value={startDateValueValue}
										locale={locale}
										onChange={startDateValueOnChange}
										step={60}
									/>
								)}
								<span>{getDayOfWeek(startDateValueValue)}</span>
							</Row>
							<div>
								<CheckBoxWithContent
									value={timeRangeActiveValue}
									style={{ width: "max-content" }}
									gap="10px"
									onChange={timeRangeActiveOnChange}
								>
									<Row
										gaps="10px*"
										align="center"
										style={{
											height: 32,
											lineHeight: "18px",
										}}
									>
										<span>
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str101",
											) ?? ""}
										</span>
										<TimeInput
											value={timeRangeFromValue}
											disabled={!timeRangeActiveValue}
											onChange={timeRangeFromOnChange}
										/>
										<span>
											{t(
												"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str102",
											) ?? ""}
										</span>
										<TimeInput
											value={timeRangeToValue}
											disabled={!timeRangeActiveValue}
											onChange={timeRangeToOnChange}
										/>
									</Row>
								</CheckBoxWithContent>
							</div>
						</Row>
						<Row gaps="8px" sizes="1fr*">
							<Row
								align="center"
								gaps="10px*"
								sizes="auto! 350px!"
							>
								<span>
									{t(
										"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str3",
									) ?? ""}
								</span>
								<Select
									value={modeValueValue}
									options={feeModesOptions}
									placeholder={
										t(
											"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str3",
										) ?? ""
									}
									onSelect={onSelectMode}
								/>
							</Row>
						</Row>
					</Column>
				</FieldsContainer>

				<CheckBoxSelect
					value={weekdayCheckBoxSelectValue}
					title={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str4",
						) ?? ""
					}
					options={weekdayOptions}
					onChange={weekdayCheckBoxSelectOnChange}
				/>

				<Commissions
					value={commissionsValue}
					onChange={commissionsOnChange}
				/>

				<FieldsContainer
					label={
						t(
							"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str201",
						) ?? ""
					}
				>
					<StyledColumn gap="10px">
						<CheckBoxWithStepper
							value={minFee}
							onChange={setMinFee}
							textAfterStepper={currencyGlobalSettings}
							stepperSetting={minMaxOptions.min}
							columns={"1fr auto"}
							title={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str203",
								) ?? ""
							}
						/>
						<CheckBoxWithStepper
							value={maxFee}
							onChange={setMaxFee}
							textAfterStepper={currencyGlobalSettings}
							stepperSetting={minMaxOptions.max}
							columns={"1fr auto"}
							title={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.editModal.content.str205",
								) ?? ""
							}
						/>
					</StyledColumn>
				</FieldsContainer>
			</Column>
		</Root>
	);
}, InternalController);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;

		language: Language;

		onChange: Dispatch<Value>;
		setTypeName: Dispatch<string>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value {
		classifierId?: number;

		name?: string;

		startDate: Value.StartDate;
		timeRange: Value.TimeRange;
		mode: Value.Mode;
		weekdays: Value.Weekdays;
		commissions: Commissions.Value;
		minFee: Value.MinMaxFee;
		maxFee: Value.MinMaxFee;
	}

	namespace Value {
		interface Weekdays {
			monday: boolean;
			tuesday: boolean;
			wednesday: boolean;
			thursday: boolean;
			friday: boolean;
			saturday: boolean;
			sunday: boolean;
		}

		interface StartDate {
			active: boolean;
			value?: Date | null;
		}

		interface TimeRange {
			active: boolean;
			from?: Date | null;
			to?: Date | null;
		}

		interface Period {
			active: boolean;
			unit: (typeof periodUnitOptions)[number]["value"];
			value: number;
		}

		interface Mode {
			active: boolean;
			value?: (typeof modeOptions)[number]["value"];
		}

		interface MinMaxFee {
			active: boolean;
			value: number;
		}
	}
}

export default Content;
