import React, { useState } from "react";
import MonacoEditor, { OnMount } from "@monaco-editor/react";

const JsonEditor: React.FC<JsonEditor.Props> = ({ initialJson, onChange }) => {
	const [jsonValue, setJsonValue] = useState<string>(
		JSON.stringify(initialJson, null, 8),
	);

	const handleEditorChange = (value: string | undefined) => {
		setJsonValue(value || "");
		try {
			const parsedJson = JSON.parse(value || "");
			onChange(parsedJson);
		} catch (error) {
			console.error("Invalid JSON:", error);
		}
	};

	const handleEditorMount: OnMount = (editor, monaco) => {
		editor.getModel()?.updateOptions({ insertSpaces: true });
		monaco.editor.setTheme("vs-dark");
	};

	return (
		<MonacoEditor
			height="75vw"
			language="json"
			value={jsonValue}
			onChange={handleEditorChange}
			onMount={handleEditorMount}
			options={{
				minimap: { enabled: false },
				automaticLayout: true,
				formatOnType: true,
				formatOnPaste: true,
				insertSpaces: true,
			}}
		/>
	);
};

declare namespace JsonEditor {
	interface Props {
		initialJson: Record<string, any>;
		onChange: (updatedJson: Record<string, any>) => void;
	}
}

export default JsonEditor;
