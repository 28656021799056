import React, { Dispatch, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { getTabsModeOne } from "../../../../../../../../../../utils";

import Root from "./components/Root";
import Title from "./components/Title";
import InformationContainer from "./components/InformationContainer";

const Header: React.FC<Header.Props> = memo(({ tab, title, onChangeTab }) => {
	const { t } = useTranslation();

	const tabs = useMemo(() => {
		const selectedTabs: Parameters<typeof getTabsModeOne>[1] = ["main"];

		return getTabsModeOne(t, selectedTabs);
	}, [t]);

	return (
		<Root sizes="auto!*">
			<InformationContainer
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
			</InformationContainer>
			<Tabs
				value={tab}
				options={tabs}
				variant="panels"
				onChange={(newTab) =>
					onChangeTab(newTab.toString() as Header.Tab)
				}
			/>
		</Root>
	);
});

declare namespace Header {
	type Tab = "main";

	interface Props {
		tab: Tab;

		title: string;

		onChangeTab: Dispatch<Tab>;
	}
}

export default Header;
