import React, { memo, useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Column } from "uikit";
import { SortType } from "rsuite-table";

import { useTypedSelector } from "../../../../../../redux/store";
import ServiceTransaction from "../../../../../../services/Transaction";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";

import Header from "./components/Header";
import Content from "./components/Content";

export interface Filter {
	dateRange: {
		dateFrom: Date;
		dateTo: Date;
	};
}

export interface ModalFilter {
	paymentAccountIds: number[];
	targetAction: ServiceTransaction.SubscribeOptions["targetAction"];
	operationId: ServiceTransaction.SubscribeOptions["operationId"];
}

const Transactions: React.FC<Transactions.Props> = memo(() => {
	const language = useTypedSelector((state) => state.session.language);

	const [selected, setSelected] = useState<number[]>([]);
	const [limit, setLimit] = useState<number>(30);
	const [query, setQuery] = useState("");

	const [sort, setSort] = useState<{
		dataKey: string;
		sortType: SortType | undefined;
	}>({ dataKey: "", sortType: "asc" });

	const [filter, setFilter] = useState<Filter>({
		dateRange: {
			dateFrom: moment().startOf("day").toDate(),
			dateTo: moment().endOf("day").toDate(),
		},
	});

	const [modalFilter, setModalFilter] = useState<ModalFilter>({
		paymentAccountIds: [],
		targetAction: undefined,
		operationId: undefined,
	});

	const onSortTable = useCallback(
		(dataKey: string, sortType: SortType | undefined) => {
			setSort({ dataKey, sortType });
		},
		[],
	);

	const options = useMemo<ServiceTransaction.SubscribeOptions>(() => {
		const order: Record<string, any> = {};
		if (
			sort.dataKey === "toIsProvider" ||
			sort.dataKey === "fromIsProvider" ||
			sort.dataKey === "createdAt" ||
			sort.dataKey === "type"
		) {
			order[sort.dataKey] = sort.sortType?.toUpperCase();
		}

		const result: ServiceTransaction.SubscribeOptions = {
			limit,
			dateRange: {
				from: filter.dateRange.dateFrom,
				to: filter.dateRange.dateTo,
			},
			order: { ...order },
			operationId: modalFilter.operationId,
			targetAction: modalFilter.targetAction,
			paymentAccountIds: modalFilter.paymentAccountIds,
		};
		return result;
	}, [
		filter.dateRange.dateFrom,
		filter.dateRange.dateTo,
		sort,
		modalFilter.operationId,
		modalFilter.targetAction,
		modalFilter.paymentAccountIds,
		limit,
	]);

	const transactionSubscribeData = useModelSubscribe(
		options,
		ServiceTransaction,
	);

	const transactionModels = useMemo(
		() => transactionSubscribeData.models || [],
		[transactionSubscribeData.models],
	);

	return (
		<Column gaps="5px*" sizes="auto 1fr" maxedWidth maxedHeight>
			<Header
				query={query}
				setQuery={setQuery}
				filter={filter}
				modalFilter={modalFilter}
				setFilter={setFilter}
				setModalFilter={setModalFilter}
			/>
			<Content
				value={transactionModels}
				selected={selected}
				setSelected={setSelected}
				sort={sort}
				onSort={onSortTable}
				language={language}
				setLimit={setLimit}
			/>
		</Column>
	);
});

declare namespace Transactions {
	interface Props {}
}

export default Transactions;
