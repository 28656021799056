import { memo } from "react";
import styled from "styled-components";

const Item = memo(styled.div<Item.Props>`
	position: absolute;

	top: ${({ offset }) => offset}px;

	width: 100%;

	min-height: ${({ height }) => height}px;
	height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
`);

declare namespace Item {
	interface Props {
		offset: number;
		height: number;
	}
}

export default Item;
