import React, { memo, useCallback, useMemo } from "react";
import { Row, Select } from "uikit";
import { useTranslation } from "react-i18next";
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";
import tPath from "../../../../../../../../constants/tPath";
import { RouteCalculationMode } from "../../../../../../../../../../../../../../services/MapSettings/types";

const path = `${tPath}.modal.tabs.main.routeCalculationMode`;
const options = getOptions(Object.values(RouteCalculationMode));

const RouteCalculation: React.FC<RouteCalculation.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();

		const handleValueChange = useCallback(
			(newValue) => {
				onChange(newValue);
			},
			[onChange],
		);

		const tOptions = useMemo(
			() =>
				options.map((o) => ({
					...o,
					label: t(`${path}.options.${o.key}`),
				})),
			[t],
		);

		return (
			<Row align="center" gaps="8px*" sizes="1fr 1fr">
				<div>{t(`${path}.title`)}</div>
				<Select
					placeholder={t(`${path}.placeholder`) || ""}
					options={tOptions}
					value={value}
					onChange={handleValueChange}
				/>
			</Row>
		);
	},
);

declare namespace RouteCalculation {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	type Value = RouteCalculationMode;
}

export default RouteCalculation;
