import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";
import CompaniesAndTaxiServices from ".";

interface Context {
	value: CompaniesAndTaxiServices.Value & { selectedCompanies?: number[] };
	setCompaniesError: Dispatch<SetStateAction<boolean>>;
	setTaxiServicesError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompaniesValid =
			!!this.context?.value.selectedCompanies?.length;
		if (!isCompaniesValid) this.context?.setCompaniesError(true);

		const isBranchesValid = !!this.context?.value.taxiServiceIds.length;
		if (!isBranchesValid) this.context?.setTaxiServicesError(true);

		return isCompaniesValid && isBranchesValid;
	}
}
