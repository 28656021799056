/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

import { State } from "./interface";
import { ReduxReducer, Archive, ValueToggle } from "../../interface";

const initialState: State = {
	toggle: false,
	position: null,
	next: null,
	date: 0,
	max: 0,
	speed: 1,
	run: true,
	duration: 0,
};

const setOrderPlayerPosition: ReduxReducer<State, State["position"]> = (
	state,
	{ payload },
) => {
	state.position = payload;
};

const setOrderPlayerNext: ReduxReducer<State, State["next"]> = (
	state,
	{ payload },
) => {
	state.next = payload;
};

const setOrderPlayerSpeed: ReduxReducer<State, State["speed"]> = (
	state,
	{ payload },
) => {
	state.speed = payload;
};

const setOrderPlayerDate: ReduxReducer<State, State["date"]> = (
	state,
	{ payload },
) => {
	state.date = payload;
};
const setOrderPlayerDuration: ReduxReducer<State, State["duration"]> = (
	state,
	{ payload },
) => {
	state.duration = payload;
};
const setOrderPlayerMax: ReduxReducer<State, State["max"]> = (
	state,
	{ payload },
) => {
	state.max = payload;
};

const setOrderPlayerToggle: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.toggle = !state.toggle;
	else state.toggle = payload === "on";
};

const setOrderPlayerRun: ReduxReducer<State, ValueToggle> = (
	state,
	{ payload },
) => {
	if (payload === "toggle") state.run = !state.run;
	else state.run = payload === "on";
};

const resetOrderPlayer: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrderPlayer = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_PLAYER,
	initialState,
	reducers: {
		resetOrderPlayer,
		setOrderPlayerToggle,
		setOrderPlayerMax,
		setOrderPlayerDate,
		setOrderPlayerNext,
		setOrderPlayerSpeed,
		setOrderPlayerPosition,
		setOrderPlayerDuration,
		setOrderPlayerRun,
	},
});

export const archiveOrderPlayerAction = archiveOrderPlayer.actions;
export const archiveOrderPlayerReducer = archiveOrderPlayer.reducer;
export type archiveOrderPlayerState = State;

export default archiveOrderPlayer;
