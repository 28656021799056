import { react } from "uikit";

interface Context {}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isTrue = !!this.context;
		return isTrue;
	}
}
