import React, { memo, useCallback, useMemo } from "react";
import { Row, CheckBox, MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import tPath from "../../../../../../../../constants/tPath";

import { AutoRateRulePaymentType, paymentOptions } from "./constants";

const Payment: React.FC<Payment.Props> = memo(
	({ value, onChange, disabled }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const types = valueEditor.useGetter("types");
		const setTypes = valueEditor.useSetter("types");
		const handleTypesChange = useCallback(
			(newTypes) => {
				setTypes(newTypes);
			},
			[setTypes],
		);

		const tOptions = useMemo(
			() =>
				paymentOptions.map((o) => ({
					...o,
					label: t(`${tPath}.modal.orders.payment.options.${o.key}`),
				})),
			[t],
		);

		return (
			<Row align="center" gaps="10px" sizes="auto! 1fr">
				<CheckBox
					disabled={disabled}
					label={t(`${tPath}.modal.orders.payment.title`) || ""}
					value={active}
					onChange={setActive}
				/>
				<MultiSelect
					disabled={disabled}
					placeholder={
						t(`${tPath}.modal.orders.payment.placeholder`) || ""
					}
					options={tOptions}
					value={types}
					onChange={handleTypesChange}
				/>
			</Row>
		);
	},
);

declare namespace Payment {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
		disabled: boolean;
	}

	interface Value {
		active: boolean;
		types: AutoRateRulePaymentType[];
	}
}

export default Payment;
