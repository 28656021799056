import React, {
	Dispatch,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, TextSelect } from "uikit";
import Map from "../../../../../../../../../../../../services/Map";
import Language from "../../../../../../../../../../../../services/Language";
import MapSearch from "../MapSearch";
import objectToLabel from "./utils/objectToLabel";

const Settlement: React.FC<Settlement.Props> = ({
	value,
	language,
	autoResolve = true,
	error,
	onChange,
	onSelectObject,
}) => {
	const { t } = useTranslation();
	const [result, setResult] = useState<MapSearch.Result>({
		loading: true,
	});

	const mapSearchOptions = useMemo<MapSearch.Options>(
		() => ({
			language,
			searchType: "settlement",
			country: "ua",
			query: value ?? "",
		}),
		[language, value],
	);

	useLayoutEffect(() => {
		if (result.loading) return;

		if (result.objects[0]) onSelectObject?.(result.objects[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result]);

	const options = useMemo<Option<Map.Search.Object>[]>(() => {
		if (result.loading) return [];

		return result.objects.map((object) => ({
			key: object.id,
			label: objectToLabel(object),
			value: object,
		}));
	}, [result]);

	const textSelectOnSelect = useCallback(
		(option: Option<Map.Search.Object>) => {
			onSelectObject?.(option.value);
		},
		[onSelectObject],
	);

	return (
		<>
			<TextSelect
				value={value}
				placeholder={
					t(
						"pages.preferencesPages.screenDirectory.objects.editModal.pointForm.settlement.str0",
					) ?? ""
				}
				options={autoResolve ? options : undefined}
				error={error}
				onChange={onChange}
				onSelect={textSelectOnSelect}
			/>
			{autoResolve && (
				<MapSearch options={mapSearchOptions} onChange={setResult} />
			)}
		</>
	);
};

declare namespace Settlement {
	type Value = string;

	interface Props {
		value?: Value;

		language?: Language;
		autoResolve?: boolean;
		error?: boolean;

		onChange?: Dispatch<Value>;

		onSelectObject?: Dispatch<Map.Search.Object>;
	}
}

export default Settlement;
