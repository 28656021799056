import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useInternal } from "uikit";

import { Schema } from "../../../../redux/constants/OrdersPage/order";
import { ValueClientPhoneType } from "../../../../redux/services/Order/getPhoneInfo";
import useKeyBind from "../../../../hooks/useKeyBind";
import {
	StyledColumn,
	StyledRow,
	StyledP,
	CardModal,
	CardModalBase,
	BaseElementIdEnum,
} from "../../../common";

const Content = styled(StyledColumn)`
	gap: 10px;
	padding: 8px 8px 1rem 8px;
	justify-content: start;
	background: #ffffff;
	overflow: hidden;
	width: 600px;
`;

const BaseCompanyBillMetadata: React.FC<BaseCompanyBillMetadata.Props> = ({
	value,
	onSave,
	onClose,
	content,
	footer,
	footerDivider,
	...props
}) => {
	const path = "orderPage.order_form.payment_status_modal";
	const { t } = useTranslation();
	const [nextStep, setNextStep] = useInternal<BaseElementIdEnum>(
		BaseElementIdEnum.SAVE,
	);

	const [action, setAction] = useInternal<"save" | "close">("save");

	const texts = useMemo(() => {
		const payload = {
			title: t([`${path}.title`, "Insufficient funds warning"]),
			close: t([`${path}.close`, `cancel`, "Cancel"]),
			save: t([`${path}.save`, `confirm`, "Confirm"]),
			executable: t([`${path}.executable`, ``]),
			live: t([`${path}.live`, ``]),
			preliminary: t([`${path}.preliminary`, ``]),
			orders: t([`${path}.orders`, ``]),
			orders_text: t([`${path}.orders_text`, ``]),
			orders_tab: t([`${path}.orders_tab`, ``]),
			shortage: t([`${path}.shortage`, ``]),
			amount: t([`${path}.amount`, ``]),
			save_text: t([`${path}.save_text`, ``]),
			close_text: t([`${path}.close_text`, ``]),
			balance: t([`${path}.balance`, ``]),
			threshold: t([`${path}.threshold`, ``]),
			currency: value?.currency?.settings?.name,
		};
		return payload;
	}, [t, value?.currency?.settings?.name]);

	const balance = useMemo(() => {
		const data = value?.companyBillMetadata?.paymentAccountAmount ?? 0;
		const amount = Number(data.toFixed(2));
		return (
			<StyledRow gap="5px" p="0 0 0 1rem">
				<StyledP>{texts.balance}</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>{amount}</StyledP>
				<StyledP>{texts.currency}.</StyledP>
			</StyledRow>
		);
	}, [
		texts.balance,
		texts.currency,
		value?.companyBillMetadata?.paymentAccountAmount,
	]);

	const threshold = useMemo(() => {
		const data = value?.companyBillMetadata?.threshold ?? 0;
		const amount = Number(data.toFixed(2));
		return (
			<StyledRow gap="5px" p="0 0 0 1rem">
				<StyledP>{texts.threshold}</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>{amount}</StyledP>
				<StyledP>{texts.currency}.</StyledP>
			</StyledRow>
		);
	}, [
		texts.currency,
		texts.threshold,
		value?.companyBillMetadata?.threshold,
	]);

	const shortage = useMemo(() => {
		const missingAmount = value?.companyBillMetadata?.missingAmount ?? 0;
		const amount = Number(missingAmount.toFixed(2));
		return (
			<StyledRow gap="5px" p="0 0 0 1rem">
				<StyledP>{texts.shortage}</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>{amount}</StyledP>
				<StyledP>{texts.currency}.</StyledP>
			</StyledRow>
		);
	}, [
		value?.companyBillMetadata?.missingAmount,
		texts.shortage,
		texts.currency,
	]);

	const sumOrder = useMemo(() => {
		const liveOrders = value?.companyBillMetadata?.tabs?.live?.orders ?? 0;
		const executableOrders =
			value?.companyBillMetadata?.tabs?.executable?.orders ?? 0;
		const preliminaryOrders =
			value?.companyBillMetadata?.tabs?.preliminary?.orders ?? 0;
		const orders = liveOrders + executableOrders + preliminaryOrders;

		return (
			<StyledRow gap="5px" p="0 0 0 1rem">
				<StyledP>{texts.orders_text}</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>{orders}.</StyledP>
			</StyledRow>
		);
	}, [
		texts.orders_text,
		value?.companyBillMetadata?.tabs?.executable?.orders,
		value?.companyBillMetadata?.tabs?.live?.orders,
		value?.companyBillMetadata?.tabs?.preliminary?.orders,
	]);

	const executable = useMemo(() => {
		const data = value?.companyBillMetadata?.tabs?.executable;
		return (
			<StyledRow gap="5px" p="0 0 0 3rem">
				<StyledP>{texts.orders_tab}</StyledP>
				<StyledP>{texts.executable}:</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>
					{data?.orders || 0}
				</StyledP>
				<StyledP>{texts.amount}</StyledP>
				<StyledRow>
					<StyledP font={{ fw: 700, size: "15px" }}>
						{data?.amount || 0}
					</StyledP>
					<StyledP>{texts.currency}.</StyledP>{" "}
				</StyledRow>
			</StyledRow>
		);
	}, [texts, value?.companyBillMetadata?.tabs?.executable]);

	const live = useMemo(() => {
		const data = value?.companyBillMetadata?.tabs?.live;

		return (
			<StyledRow gap="5px" p="0 0 0 3rem">
				<StyledP>{texts.orders_tab}</StyledP>
				<StyledP>{texts.live}:</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>
					{data?.orders || 0}
				</StyledP>
				<StyledP>{texts.amount}</StyledP>
				<StyledRow>
					<StyledP font={{ fw: 700, size: "15px" }}>
						{data?.amount || 0}
					</StyledP>
					<StyledP>{texts.currency}.</StyledP>{" "}
				</StyledRow>
			</StyledRow>
		);
	}, [texts, value?.companyBillMetadata?.tabs?.live]);

	const preliminary = useMemo(() => {
		const data = value?.companyBillMetadata?.tabs?.preliminary;

		return (
			<StyledRow gap="5px" p="0 0 0 3rem">
				<StyledP>{texts.orders_tab}</StyledP>
				<StyledP>{texts.preliminary}:</StyledP>
				<StyledP font={{ fw: 700, size: "15px" }}>
					{data?.orders || 0}
				</StyledP>
				<StyledP>{texts.amount}</StyledP>
				<StyledRow>
					<StyledP font={{ fw: 700, size: "15px" }}>
						{data?.amount || 0}
					</StyledP>
					<StyledP>{texts.currency}.</StyledP>{" "}
				</StyledRow>
			</StyledRow>
		);
	}, [texts, value?.companyBillMetadata?.tabs?.preliminary]);

	const onConfirm = useCallback<CardModalBase.Props["onSave"]>(
		(event) => {
			if (action === "close") onClose(event);
			else onSave(event);

			event?.preventDefault?.();
			event?.stopPropagation?.();
		},
		[action, onClose, onSave],
	);

	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) elem.focus();

			return elem;
		},
		[],
	);

	useKeyBind(["Tab"], (event) => {
		if (nextStep === BaseElementIdEnum.SAVE) {
			setAction("close");
			nextElementById(BaseElementIdEnum.CLOSE);
			setNextStep(BaseElementIdEnum.CLOSE);
		} else {
			setAction("save");
			nextElementById(BaseElementIdEnum.SAVE);
			setNextStep(BaseElementIdEnum.SAVE);
		}

		event.preventDefault();
		event.stopPropagation();
	});

	const phoneTypeIsEmployee = useMemo(
		() => value?.phoneType === "employee",
		[value?.phoneType],
	);

	if (value === null) return <></>;

	return (
		<CardModal
			{...props}
			title={texts.title}
			onClose={onClose}
			onSave={onConfirm}
			textClose={texts.close}
			textSave={texts.save}
			footer={footer}
			footerDivider={footerDivider}
			buttonSave={{
				autoFocus: !phoneTypeIsEmployee,
				disabled: phoneTypeIsEmployee,
			}}
			buttonClose={{
				autoFocus: phoneTypeIsEmployee,
			}}
		>
			<Content>
				<StyledColumn gap="10px">
					{balance}

					{threshold}

					<StyledColumn gap="10px" p="10px 0">
						{sumOrder}
						{live}
						{preliminary}
						{executable}
					</StyledColumn>

					{shortage}

					<StyledColumn p="1rem 10px" gap="10px">
						{!phoneTypeIsEmployee && (
							<StyledP text={{ ws: "normal" }}>
								{texts.save_text}
							</StyledP>
						)}
						<StyledP text={{ ws: "normal" }}>
							{texts.close_text}
						</StyledP>
					</StyledColumn>
				</StyledColumn>
				{content}
			</Content>
		</CardModal>
	);
};

declare namespace BaseCompanyBillMetadata {
	type Value = {
		companyBillMetadata: Schema["companyBillMetadata"];
		currency: Schema["currency"];
		phoneType?: ValueClientPhoneType;
	};
	interface Props extends CardModalBase.Props {
		value: Value | null;
		content?: React.ReactNode;
	}
}

export const CompanyBillMetadata = memo(BaseCompanyBillMetadata);
export default BaseCompanyBillMetadata;
