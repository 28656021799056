import React, { memo, useCallback, useMemo, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { TimeInput } from "uikit";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { StyledRow } from "../../../../../../../../components/common";

const ValidityTime: React.FC<ValidityTime.Props> = memo(
	({ value, onChange, errors, onErrors }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);
		const errorsEditor = useObjectEditor(errors, onErrors);

		const errorTo = errorsEditor.useGetter("end");
		const setErrorTo = errorsEditor.useSetter("end");

		const errorFrom = errorsEditor.useGetter("start");
		const setErrorFrom = errorsEditor.useSetter("start");

		const from = valueEditor.useGetter("start");
		const setFrom = valueEditor.useSetter("start");
		const handleFromChange = useCallback(
			(date) => {
				setErrorFrom(false);
				setErrorTo(false);
				setFrom(date);
			},
			[setErrorFrom, setErrorTo, setFrom],
		);

		const to = valueEditor.useGetter("end");
		const setTo = valueEditor.useSetter("end");
		const handleToChange = useCallback(
			(date) => {
				setErrorFrom(false);
				setErrorTo(false);
				setTo(date);
			},
			[setErrorFrom, setErrorTo, setTo],
		);

		const errorsStyle = useMemo(
			() => ({
				from: errorFrom
					? { border: "1px solid red", borderRadius: "6px" }
					: undefined,
				to: errorTo
					? { border: "1px solid red", borderRadius: "6px" }
					: undefined,
			}),
			[errorFrom, errorTo],
		);

		return (
			<StyledRow gap="10px" alignItems="center">
				<TimeInput
					style={errorsStyle.from}
					value={from}
					onChange={handleFromChange}
				/>
				<span>
					{t(
						"pages.preferencesPages.screenDirectory.sectors.validityTime.str100",
					) ?? ""}
				</span>
				<TimeInput
					style={errorsStyle.to}
					value={to}
					onChange={handleToChange}
				/>
			</StyledRow>
		);
	},
);

declare namespace ValidityTime {
	interface Errors {
		end: boolean;
		start: boolean;
	}

	interface Value {
		start: Date;
		end: Date;
	}

	interface Props {
		value: Value;
		onChange: (value: Value) => void;
		errors: Errors;
		onErrors: Dispatch<Errors>;
	}
}

export default ValidityTime;
