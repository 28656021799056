import React, { memo, useCallback, useMemo } from "react";
import { CheckBox, MultiSelect, Column } from "uikit";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import { AutoRateRuleOrderType, orderTypeOptions } from "./constants";
import Payment from "./components/Payment";
import tPath from "../../../../../../constants/tPath";

const Orders: React.FC<Orders.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const payment = valueEditor.useGetter("payment");
	const setPayment = valueEditor.useSetter("payment");

	const types = valueEditor.useGetter("types");
	const setTypes = valueEditor.useSetter("types");
	const handleTypesChange = useCallback(
		(newTypes) => {
			setTypes(newTypes);
		},
		[setTypes],
	);

	const tOptions = useMemo(
		() =>
			orderTypeOptions
				.filter((item) => item.label !== "executing")
				.map((o) => ({
					...o,
					label: t(`${tPath}.modal.orders.types.options.${o.key}`),
				})),
		[t],
	);

	return (
		<Column gaps="15px*">
			<CheckBox
				label={t(`${tPath}.modal.orders.title`) || ""}
				value={active}
				onChange={setActive}
			/>
			<Column gaps="15px*" style={{ marginLeft: 30 }}>
				<Payment
					disabled={!active}
					value={payment}
					onChange={setPayment}
				/>
				<LabeledField
					label={t(`${tPath}.modal.orders.types.title`) || ""}
				>
					<MultiSelect
						disabled={!active}
						placeholder={
							t(`${tPath}.modal.orders.types.title`) || ""
						}
						options={tOptions}
						value={types}
						onChange={handleTypesChange}
					/>
				</LabeledField>
			</Column>
		</Column>
	);
});

declare namespace Orders {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		payment: Payment.Value;
		types: AutoRateRuleOrderType[];
	}
}

export default Orders;
