import styled from "styled-components";

const Label = styled.div`
	padding-inline: 25px 11px;
	padding-block: 4px;

	font-family: Lato;
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: center;

	color: #ffffff;
`;

export default Label;
