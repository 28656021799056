import makeTable from "./makeTable";

const executor = makeTable({
	allIds: ["name", "companies", "branches"],
	defaultIds: ["name", "companies", "branches"],
	initialSortMethod: {
		column: "name",
		type: "asc",
	},
	initialWidths: {
		name: 300,
		companies: 200,
		branches: 200,
	},
	i18tPath: (id) => `tableColumns.settings.parameters.${id}`,
});

export default executor;
