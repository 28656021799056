import React, { useMemo } from "react";
import moment from "moment";
import { isUndefined } from "lodash";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutingStageWithClientsTimeCellContent: React.FC<
	ExecutingStageWithClientsTimeCellContent.Props
> = ({ item }) => {
	const timestamp = useMemo(
		() => item.executingStages?.with_clients?.timestamp,
		[item.executingStages?.with_clients?.timestamp],
	);

	const content = useMemo(
		() =>
			isUndefined(timestamp) ? "" : moment(timestamp).format("HH:mm:ss"),
		[timestamp],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutingStageWithClientsTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutingStageWithClientsTimeCellContent;
