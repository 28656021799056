import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

export interface RevertOrderParams {
	id: number;
	executorLogin?: string;
}

export function revertOrder(options: RevertOrderParams) {
	return async () => {
		const prpcow = getPRPC();
		if (!prpcow) return null;

		const data = await createRPCQuery(
			() => prpcow?.theirsModel.orders.revert(options),
			{ verbose: true, name: "revertOrder" },
		);

		return data;
	};
}
