/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { Column, theme } from "uikit";

const PhonesColumn = styled(Column)<{
	selected?: boolean;
	lightSelected?: boolean;
}>`
	padding: 8px 16px 8px 0px;

	width: 100%;
	min-height: 37px;
	max-height: 53px;

	justify-content: center;
	align-items: flex-start;

	background-color: ${({ selected, lightSelected }) =>
		lightSelected ? "#e6e6e6" : selected ? "#f5f5f5" : theme.colors.white};
	cursor: pointer;
`;

// &:hover {
// 	background-color: ${({ selected }) =>
// 		selected ? "#f5f5f5" : "#fafafa"};
// }

export default PhonesColumn;
