import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import JSPDF from "jspdf";
import JSZip from "jszip";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";
import { DatePicker } from "uikit";

import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import {
	formatDate,
	formatDistance,
	formatMoney,
	formatTimeToMinutes,
} from "../../pages/Reports/pages/AccountingReports/utils/formatForFile";
import { Base, Language } from "..";

class CashlessOrdersReport extends Base {
	static fromResponse(data: any): CashlessOrdersReport.Model {
		return data || [];
	}

	public static async getCashlessOrdersReport(
		params: CashlessOrdersReport.SearchOptions,
	): Promise<CashlessOrdersReport.GetCashlessOrdersReportResponse | null> {
		try {
			console.log(
				"[CashlessOrdersReport] getCashlessOrdersReport params",
				{
					params,
				},
			);
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getCashlessOrdersReport(params),
			);

			console.log(
				"[CashlessOrdersReport] getCashlessOrdersReport response",
				{
					response,
				},
			);
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			console.log("[CashlessOrdersReport] Error", error);

			return null;
		}
	}

	/** src\pages\PreferencesPages\components\ScreenDirectory\components\Objects\components\ImportModal */
	public static exportPDF = async (
		data: CashlessOrdersReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			// get width from table
			width: number;
			// get height from table
			height: number;
			general: boolean;
			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
		},
	) => {
		if (!data?.length) return;
		const {
			tableColumn = [],
			width = 3500,
			height = 780,
			general = true,
			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
		} = options;

		const totalWidth = columnStyles
			? Object.values(columnStyles).reduce(
					(sum, { cellWidth }) => sum + +(cellWidth || 0),
					0,
			  ) +
			  Object.keys(columnStyles).length * (5.1 / 3.56) +
			  0.1 / 3 // добавляем небольшие отступы
			: width; // если columnStyles нет, используйте дефолтную ширину

		const realWidth = totalWidth || width;
		const doc = new JSPDF({
			orientation: "landscape",
			format: [realWidth, height],
		});

		let currentY = 10;
		let _dateFrom;
		let _dateTo;

		data.forEach(({ taxiService, orders, dateRange, counter }) => {
			doc.setFont("Roboto-Regular", "normal");

			const textCompany = taxiService?.company?.name ?? "";
			const textFill = taxiService?.name ? `, ${taxiService?.name}` : "";
			const textCounterparty = taxiService?.counterpartyName
				? taxiService?.counterpartyName
				: "";

			const dateFrom = formatDate({
				date: dateRange.from,
				isTitleFormat: true,
			});

			const dateTo = formatDate({
				date: dateRange.to,
				isTitleFormat: true,
			});

			if (!_dateFrom) _dateFrom = dateFrom;
			if (!_dateTo) _dateTo = dateTo;

			// const nameTab = headerTitle;

			// Получаем ширину страницы
			const pageWidth = doc.internal.pageSize.getWidth();
			const centerX = pageWidth / 2;

			const textArray = [
				`${textCompany} ${textFill}`,
				// `${nameTab}`,
				`${textCounterparty} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
			];

			// Проходим по каждой строке текста и выводим ее по центру
			textArray.forEach((text) => {
				// Получаем ширину текста
				const textWidth = doc.getTextWidth(text);

				// Вычисляем позицию для центрирования текста
				const textX = centerX - textWidth / 2;

				// Рисуем текст
				doc.text(text, textX, currentY);

				// Увеличиваем Y для следующей строки
				currentY += 10; // Например, отступ в 10 единиц между строками
			});

			const tableRows = orders.map((order) => {
				const payload: RowInput = [];
				const columnIdSet = new Set(columnIds);

				const retval: RowInput = [];

				if (!general) {
					if (columnIdSet.has(ColumnId.Order)) {
						payload.push({
							content: `${order.orderNumber}` || "",
						});
					}
				}

				if (columnIdSet.has(ColumnId.Name)) {
					retval.push({ content: `${order.counterpartyName}` });
				}

				if (columnIdSet.has(ColumnId.Card)) {
					retval.push({ content: `${order.checkCard}` });
				}
				if (!general) {
					if (columnIdSet.has(ColumnId.Phone)) {
						payload.push({
							content: `${order.phone}` || "",
						});
					}
				}
				if (columnIdSet.has(ColumnId.Passenger)) {
					retval.push({ content: `${order.fullName}` });
				}
				if (columnIdSet.has(ColumnId.From)) {
					retval.push({ content: `${order.addressFrom}` });
				}
				if (columnIdSet.has(ColumnId.To)) {
					retval.push({ content: `${order.addressTo}` });
				}
				if (columnIdSet.has(ColumnId.Note)) {
					retval.push({ content: `${order.orderNotes}` });
				}
				if (columnIdSet.has(ColumnId.OrderNote)) {
					retval.push({
						content: `${order.executorNotes}`,
					});
				}
				if (columnIdSet.has(ColumnId.Created)) {
					retval.push({
						content: formatDate({ date: order.createdAt }),
					});
				}
				if (columnIdSet.has(ColumnId.When)) {
					retval.push({
						content: formatDate({ date: order.arrivalTime }),
					});
				}
				if (columnIdSet.has(ColumnId.Completed)) {
					retval.push({
						content: formatDate({ date: order.closedAt }),
					});
				}
				if (columnIdSet.has(ColumnId.CallSign)) {
					retval.push({
						content: `${order.executorCallSign}`,
					});
				}
				if (columnIdSet.has(ColumnId.LicensePlate)) {
					retval.push({
						content: `${order.registrationNumber}`,
					});
				}
				if (columnIdSet.has(ColumnId.Model)) {
					retval.push({ content: `${order.carType}` });
				}
				if (columnIdSet.has(ColumnId.Color)) {
					retval.push({ content: `${order.carColor}` });
				}
				if (columnIdSet.has(ColumnId.DistanceKm)) {
					retval.push({
						content: formatDistance(order.distance),
					});
				}
				if (columnIdSet.has(ColumnId.ExecutionTimeMin)) {
					retval.push({
						content: `${formatTimeToMinutes(order.executingTime)}`,
					});
				}
				if (columnIdSet.has(ColumnId.IdleTimeMin)) {
					retval.push({ content: order.delay });
				}
				if (columnIdSet.has(ColumnId.AmountUah)) {
					retval.push({
						content: `${formatMoney(order.price)}`,
					});
				}
				if (columnIdSet.has(ColumnId.Status)) {
					retval.push({
						content: order.status
							? `${textTranslations.completed}`
							: `${textTranslations.pending}`,
					});
				}

				return [...payload, ...retval] as RowInput;
			});

			// Генерация таблицы с помощью autoTable
			autoTable(doc, {
				head: [tableColumn],
				body: tableRows,
				startY: currentY,
				styles: {
					fontSize: 12, // Размер шрифта
					cellPadding: 5, // Внутренние отступы
					lineWidth: 0.1, // Толщина линии границы
					lineColor: [0, 0, 0], // Цвет границы (черный)
					font: "Roboto-Regular",
					fontStyle: "normal",
				},
				columnStyles,
				headStyles: {
					fillColor: [41, 128, 185], // Цвет фона заголовка
					textColor: [255, 255, 255], // Цвет текста заголовка
					fontSize: 10,
				},
				margin: { top: 20 }, // Отступы сверху
				tableLineWidth: 0, // Толщина линии таблицы
				tableLineColor: [0, 0, 0], // Цвет линии таблицы
				theme: "grid",
			});

			currentY = doc?.lastAutoTable?.finalY;

			const currency = orders?.[0]?.currency?.name || "";

			doc.setFont("Roboto-Regular", "normal");
			doc.text(
				[
					`${textTranslations.successful}: ${counter.success.count}    ${textTranslations.unsuccessful}: ${counter.filed.count}    ${textTranslations.totalOrders}: ${counter.orders.count}`,
					`${textTranslations.amount}, ${currency}: ${counter.success.amount}   ${textTranslations.amount}, ${currency}: ${counter.filed.amount}   ${textTranslations.total}, ${currency}: ${counter.orders.amount}`,
				],
				30,
				currentY + 20,
			);
			currentY += 50;
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}.pdf`;

		// Сохранение PDF
		doc.save(namefile);
	};

	public static exportExcel = async (
		_data: CashlessOrdersReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;
			general: boolean;
			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
			isZip: boolean;
			dateFrom: DatePicker.Value;
			dateTo: DatePicker.Value;
		},
	) => {
		if (!_data?.length) return;

		const {
			tableColumn = [],
			width = 3500,
			height = 780,
			general = true,
			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
			isZip,
			dateFrom,
			dateTo,
		} = options;

		const _dateFrom = formatDate({
			date: dateFrom ?? new Date(),
			isTitleFormat: true,
		});
		const _dateTo = formatDate({
			date: dateTo ?? new Date(),
			isTitleFormat: true,
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}`;

		const zip = new JSZip();
		const workbook = new ExcelJS.Workbook(); // Создаем новую книгу Excel

		const clearWorksheets = () => {
			workbook.worksheets.forEach((sheet) =>
				workbook.removeWorksheet(sheet.name),
			);
		};

		// Функция для создания и добавления отчета
		const createReport = async (
			data: CashlessOrdersReport.Model.Item[],
			index: number,
		) => {
			const worksheet = workbook.addWorksheet(namefile); // Добавляем новый лист для каждого отчета

			tableColumn.forEach((colIndex, index) => {
				const cellWidth: number = +(
					columnStyles?.[index]?.cellWidth ?? 80
				); // Получаем ширину из columnStyles, если не определено - используем 80

				if (typeof cellWidth === "number" && cellWidth > 0) {
					// Устанавливаем ширину колонки
					worksheet.getColumn(index + 1).width = Math.round(
						cellWidth / 2.5,
					); // Используем индекс + 1, так как индексация начинается с 1
				} else {
					console.warn(
						`2 Width for column ${colIndex} is undefined or invalid: cellWidth`,
						cellWidth,
					);
				}
			});

			data.forEach(({ taxiService, orders, dateRange, counter }) => {
				const textCompany = taxiService?.company?.name ?? "";
				const textFill = taxiService?.name
					? `, ${taxiService?.name}`
					: "";
				const textCounterparty = taxiService?.counterpartyName || "";

				const dateFrom = formatDate({
					date: dateRange.from,
					isTitleFormat: true,
				});
				const dateTo = formatDate({
					date: dateRange.to,
					isTitleFormat: true,
				});

				const emptyCellsHeader = Math.floor(
					(tableColumn.length - 1) / 2,
				);

				// Функция для добавления заголовка в Excel
				const addHeaderRow = (
					text: string,
					emptyCellsHeader: number,
				) => {
					const headerRow = worksheet.addRow([text]); // Добавляем строку для текущего элемента массива
					headerRow.font = {
						// bold: true,
						size: 10,
						name: "Arial",
					}; // Жирный шрифт для шапки
					headerRow.alignment = {
						horizontal: "left", // Выровнено по левому краю
						vertical: "middle",
						wrapText: false, // Отключаем перенос текста
					};

					// Установка высоты строки, чтобы текст был полностью виден
					headerRow.height = 30; // Устанавливаем высоту строки, вы можете изменить значение по необходимости

					// Объединение ячеек (от текущей ячейки до 6-й вправо)
					worksheet.mergeCells(
						`A${headerRow.number}:F${headerRow.number}`,
					);

					// Выравнивание содержимого объединенной ячейки по центру
					headerRow.getCell(1).alignment = {
						horizontal: "center",
						vertical: "middle",
					};
				};

				// Массив заголовков
				const textheaderTable = [
					`${textCompany} ${textFill}`,
					`${textCounterparty} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
				];

				// Добавляем заголовки
				textheaderTable.forEach((text, index) => {
					addHeaderRow(text, emptyCellsHeader);
				});

				// Применяем стили к заголовкам
				const titleRow = worksheet.addRow(tableColumn);
				titleRow.font = {
					// bold: true,
					name: "Calibri",
					size: 11,
					color: { argb: "ffffff" },
				};
				titleRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				};
				titleRow.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "4baac6" },
				}; // Устанавливаем цвет ячейки заголовков
				titleRow.border = {
					top: { style: "thin", color: { argb: "FF000000" } },
					bottom: { style: "thin", color: { argb: "FF000000" } },
				};

				// Рассчитываем высоту строки на основе содержимого заголовка

				const maxHeight = Math.min(
					3, // Ограничение не более 3 строк
					Math.max(
						...tableColumn.map((cell) => {
							if (typeof cell === "string") {
								// Пример: вычисляем высоту, основываясь на длине текста
								return Math.ceil(cell.length / 10); // Здесь 15 — это количество символов на строку
							}
							return 1; // Минимальная высота для нестроковых значений
						}),
					),
				);

				// Устанавливаем высоту строки
				titleRow.height = maxHeight * 13; // Пример: 15 — это базовая высота строки, можно настроить по своему усмотрению

				orders.forEach((order, index) => {
					const row: (string | number | undefined | Date | null)[] =
						[];

					const columnIdSet = new Set(columnIds);

					if (!general) {
						if (columnIdSet.has(ColumnId.Order))
							row.push(order.orderNumber || "");
					}
					if (columnIdSet.has(ColumnId.Name))
						row.push(order.counterpartyName);
					if (columnIdSet.has(ColumnId.Card))
						row.push(order.checkCard);
					if (!general) {
						if (columnIdSet.has(ColumnId.Phone))
							row.push(order.phone || "");
					}
					if (columnIdSet.has(ColumnId.Passenger))
						row.push(order.fullName);
					if (columnIdSet.has(ColumnId.From))
						row.push(order.addressFrom);
					if (columnIdSet.has(ColumnId.To)) row.push(order.addressTo);
					if (columnIdSet.has(ColumnId.Note))
						row.push(order.orderNotes);
					if (columnIdSet.has(ColumnId.OrderNote))
						row.push(order.executorNotes);
					// if (columnIdSet.has(ColumnId.Created))
					// 	row.push(formatDate({ date: order.createdAt }));
					// if (columnIdSet.has(ColumnId.When))
					// 	row.push(formatDate({ date: order.arrivalTime }));
					// if (columnIdSet.has(ColumnId.Completed))
					// 	row.push(formatDate({ date: order.closedAt }));
					// if (columnIdSet.has(ColumnId.Created)) {
					// 	const createdDate = order.createdAt
					// 		? new Date(order.createdAt).toISOString()
					// 		: ""; // Преобразуем в строку
					// 	row.push(createdDate);
					// }
					// if (columnIdSet.has(ColumnId.When)) {
					// 	const whenDate = order.arrivalTime
					// 		? new Date(order.arrivalTime).toISOString()
					// 		: ""; // Преобразуем в строку
					// 	row.push(whenDate);
					// }
					// if (columnIdSet.has(ColumnId.Completed)) {
					// 	const completedDate = order.closedAt
					// 		? new Date(order.closedAt).toISOString()
					// 		: ""; // Преобразуем в строку
					// 	row.push(completedDate);
					// }

					if (columnIdSet.has(ColumnId.Created)) {
						const createdDate = order.createdAt
							? new Date(order.createdAt)
							: null;
						row.push(createdDate);
					}
					if (columnIdSet.has(ColumnId.When)) {
						const whenDate = order.arrivalTime
							? new Date(order.arrivalTime)
							: null; // Преобразуем в миллисекунды
						row.push(whenDate);
					}
					if (columnIdSet.has(ColumnId.Completed)) {
						const completedDate = order.closedAt
							? new Date(order.closedAt)
							: null; // Преобразуем в миллисекунды
						row.push(completedDate);
					}
					if (columnIdSet.has(ColumnId.CallSign))
						row.push(order.executorCallSign);
					if (columnIdSet.has(ColumnId.LicensePlate))
						row.push(order.registrationNumber);
					if (columnIdSet.has(ColumnId.Model))
						row.push(order.carType);
					if (columnIdSet.has(ColumnId.Color))
						row.push(order.carColor);
					if (columnIdSet.has(ColumnId.DistanceKm)) {
						const distance = parseFloat(
							formatDistance(order.distance),
						); // Преобразуем в число с плавающей точкой
						row.push(distance); // Добавляем в строку как число
					}
					// if (columnIdSet.has(ColumnId.DistanceKm))
					// 	row.push(formatDistance(order.distance));
					if (columnIdSet.has(ColumnId.ExecutionTimeMin))
						row.push(formatTimeToMinutes(order.executingTime));
					if (columnIdSet.has(ColumnId.IdleTimeMin))
						row.push(formatTimeToMinutes(order.delay));

					// if (columnIdSet.has(ColumnId.AmountUah))
					// 	row.push(formatMoney(order.price));
					if (columnIdSet.has(ColumnId.AmountUah)) {
						const formattedPrice = formatMoney(order.price); // Получаем форматированную строку
						row.push(parseFloat(formattedPrice)); // Преобразуем строку в число
					}
					// if (columnIdSet.has(ColumnId.AmountUah)) {
					// 	row.push(order.price); // Добавляем цену напрямую как число
					// }
					if (columnIdSet.has(ColumnId.Status)) {
						row.push(
							order.status
								? textTranslations.completed
								: textTranslations.pending,
						);
					}

					function isOdd(num: number): boolean {
						return num % 2 !== 0; // Проверяем, нечетное ли число
					}

					const newRow = worksheet.addRow(row);
					newRow.alignment = {
						horizontal: "center",
						vertical: "middle",
						wrapText: true,
					}; // Централизация строк
					newRow.font = { name: "Arial", size: 10 }; // Применяем стиль к строкам

					// Функция для получения индекса столбца
					const getColumnIndex = (columnId) =>
						Array.from(columnIdSet).indexOf(columnId) + 1;

					if (columnIdSet.has(ColumnId.DistanceKm)) {
						const distanceCell = newRow.getCell(
							getColumnIndex(ColumnId.DistanceKm),
						);
						distanceCell.numFmt = "0.000"; // Формат для чисел с 3 знаками после запятой
					}

					if (columnIdSet.has(ColumnId.ExecutionTimeMin)) {
						const executionCell = newRow.getCell(
							getColumnIndex(ColumnId.ExecutionTimeMin),
						);
						executionCell.numFmt = "0"; // Формат для целых чисел (можете настроить по необходимости)
					}

					if (columnIdSet.has(ColumnId.IdleTimeMin)) {
						const idleCell = newRow.getCell(
							getColumnIndex(ColumnId.IdleTimeMin),
						);
						idleCell.numFmt = "0"; // Формат для целых чисел (можете настроить по необходимости)
					}

					// Форматирование ячейки для AmountUah
					if (columnIdSet.has(ColumnId.AmountUah)) {
						const amountCell = newRow.getCell(
							getColumnIndex(ColumnId.AmountUah),
						);
						const currency = orders?.[0]?.currency?.symbol || "₴";
						const currencyFormat =
							{
								"₴": "[$₴-uk-UA]",
								"₼": "[$₼-az-AZ]",
								zł: "[$zł-pl-PL]",
							}[currency] || "[$₴-uk-UA]";

						amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
						amountCell.alignment = { wrapText: true }; // Включаем перенос текста
					}

					if (columnIdSet.has(ColumnId.Created)) {
						const createdCell = newRow.getCell(
							getColumnIndex(ColumnId.Created),
						);

						createdCell.numFmt = "DD.MM.YYYY HH:mm:ss"; // Формат даты и времени
						createdCell.alignment = { wrapText: true };
					}
					if (columnIdSet.has(ColumnId.When)) {
						const whenCell = newRow.getCell(
							getColumnIndex(ColumnId.When),
						);

						whenCell.numFmt = "DD.MM.YYYY HH:mm:ss"; // Формат даты и времени
						whenCell.alignment = { wrapText: true };
					}
					if (columnIdSet.has(ColumnId.Completed)) {
						const completedCell = newRow.getCell(
							getColumnIndex(ColumnId.Completed),
						);

						completedCell.numFmt = "DD.MM.YYYY HH:mm:ss"; // Формат даты и времени
						completedCell.alignment = { wrapText: true };
					}

					if (isOdd(index)) {
						newRow.fill = {
							type: "pattern",
							pattern: "solid",
							fgColor: { argb: "d9d9d9" },
						}; // Устанавливаем цвет ячейки заголовков
					}
					if (orders.length - 1 === index) {
						newRow.border = {
							bottom: {
								style: "thin",
								color: { argb: "FF000000" },
							},
						};
					}
					const maxHeight = Math.min(
						3, // Ограничение не более 3 строк
						Math.max(
							...row.map((cell) => {
								if (typeof cell === "string") {
									// Пример: вычисляем высоту, основываясь на длине текста
									return Math.ceil(cell.length / 10); // Здесь 15 — это количество символов на строку
								}
								return 1; // Минимальная высота для нестроковых значений
							}),
						),
					);

					// Устанавливаем высоту строки
					newRow.height = maxHeight * 13; // Пример: 15 — это базовая высота строки, можно настроить по своему усмотрению

					newRow.alignment = {
						horizontal: "center", // Горизонтальное центрирование
						vertical: "middle", // Вертикальное центрирование
						wrapText: true, // Перенос текста
					};
				});

				// Итоговые строки с количеством заказов и суммами
				const currency = orders?.[0]?.currency?.name || "";

				// Строка с количеством заказов
				const footerOne = worksheet.addRow([
					textTranslations.successful,
					counter.success.count || 0,
					textTranslations.unsuccessful,
					counter.filed.count || 0,
					textTranslations.totalOrders,
					counter.orders.count || 0,
				]);

				// Строка с суммами
				const footerTwo = worksheet.addRow([
					`${textTranslations.amount}, ${currency}:`,
					counter.success.amount || 0,
					`${textTranslations.amount}, ${currency}:`,
					counter.filed.amount || 0,
					`${textTranslations.total}, ${currency}:`,
					counter.orders.amount || 0,
				]);

				// Применение стилей
				footerOne.eachCell((cell) => {
					cell.border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" },
					};
					cell.alignment = {
						horizontal: "center",
						vertical: "middle",
					};
					cell.font = { name: "Calibri", size: 11 };
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "F9F9F9" },
					};
				});

				footerTwo.eachCell((cell) => {
					cell.border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" },
					};
					cell.alignment = {
						horizontal: "center",
						vertical: "middle",
					};
					cell.font = { name: "Calibri", size: 11 };
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "F9F9F9" },
					};
				});

				// Добавляем пустую строку для разделения между разными отчетами
				worksheet.addRow([]);
				worksheet.addRow([]);
			});

			const buffer = await workbook.xlsx.writeBuffer();

			if (isZip) {
				// Генерация файла Excel

				zip.file(
					`${data[0]?.taxiService?.counterpartyName ?? "Report"} (${
						data[0]?.taxiService?.edrpou ?? ""
					}).xlsx`,
					buffer,
				); // Добавляем файл в ZIP-архив
			} else {
				const blob = new Blob([buffer], {
					type: "application/octet-stream",
				});
				saveAs(blob, nameFileExcelOnly);
			}
		};

		const nameFileZip = `${namefile}.zip`;
		const nameFileExcelOnly = `${namefile}.xlsx`;

		// Сохранение файла Excel

		if (isZip) {
			// Создаем отчеты
			for (let index = 0; index < _data.length; index++) {
				clearWorksheets(); // Очищаем существующие листы перед добавлением нового
				await createReport([_data[index]], index);
			}

			// Сохранение ZIP-файла
			const zipContent = await zip.generateAsync({ type: "blob" });
			saveAs(zipContent, nameFileZip);

			// zip.file("data.xlsx", buffer);
			// const content = await zip.generateAsync({ type: "blob" });
			// saveAs(content, nameFileZip);
		} else {
			createReport(_data, 0);
			// const blob = new Blob([buffer], {
			// 	type: "application/octet-stream",
			// });
			// saveAs(blob, nameFileExcelOnly);
		}
	};
}

declare namespace CashlessOrdersReport {
	type Model = CashlessOrdersReport.Model.Item;

	interface GetCashlessOrdersReportResponse
		extends IResponseWithItems<Model> {}

	interface SearchOptions {
		successes?: boolean;
		general?: boolean;
		taxiServiceIds?: number[];
		counterpartyIds?: number[];
		cardIds?: number[];
		dateRange: DateRange;
		lang: Language;
	}

	// interface UploadOptions {
	// 	type: "pdf" | "xlsx" | "xlsx:zip";
	// 	taxiServiceId: number;
	// 	stream: ReadableStream;
	// }

	namespace Model {
		interface AmountCount {
			count: number;
			amount: number;
		}
		interface Counter {
			success: AmountCount;
			filed: AmountCount;
			orders: AmountCount;
		}

		interface Order {
			id: number;
			orderNumber?: number;
			phone?: string;
			checkCard: string;
			fullName: string;

			addressFrom: string;
			addressTo: string;

			orderNotes: string;
			executorNotes: string;

			createdAt: string | Date;
			arrivalTime: number;
			closedAt: string | Date;

			executorCallSign: string;

			registrationNumber: string;
			carType: string;
			carColor: string;

			distance: number;

			delay: number;
			executingTime: number;

			price: number;
			currency: {
				code: string;
				name: string;
				symbol: string;
			};
			status: boolean;

			counterpartyName?: string;
		}

		interface Item {
			dateRange: {
				from: Date;
				to: Date;
			};
			taxiService: {
				id: number;
				name: string;
				company: {
					id: number;
					name: string;
				};
				counterpartyName?: string;
				edrpou?: string;
			};
			orders: Order[];
			counter: Counter;
		}
	}
}

export default CashlessOrdersReport;
