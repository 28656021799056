/* eslint-disable no-shadow */
import { Dispatch, useEffect, useState } from "react";
import { useRender } from "uikit";

export interface PRPCSubscribe<Req = any, Res = any> {
	params: Req;
	ping?: () => boolean;
	onEvent: (res: Res) => void;
	onError?: (error: any) => void;
}

export type GetPRPC = {
	sessionId: string;
	closeRequest: (...data: any[]) => any;
	theirsModel: Record<string, any> & {
		executorLocations: {
			latest: (...params: any[]) => Promise<any>;
			history: <Req = any, Res = any>(params: Req) => Promise<Res>;
			subscribe: <Req = any, Res = any>(
				data: PRPCSubscribe<Req, Res>,
			) => Res;
		};
		company: Record<string, any>;
		orders: Record<string, any> & {
			getHistory: <Req = any, Res = any>(id: Req) => Promise<Res>;
		};
	};
};

const PRPCSetters: Dispatch<any>[] = [];
const PRPCRenders: Dispatch<boolean | undefined>[] = [];
const state: { prpc: any } = { prpc: null };

export function setPRPC(prpc: any) {
	if (state.prpc && state.prpc !== prpc) {
		state.prpc.removeAllListeners();
		state.prpc.session.close();
	}

	state.prpc = prpc;

	PRPCSetters.forEach((PRPCSetter) => PRPCSetter(prpc));
	PRPCRenders.forEach((render) => render(true));
}

export function getPRPC(): GetPRPC | null {
	return state.prpc;
}

export default function usePRPC() {
	const render = useRender();
	const [PRPC, setPRPC] = useState<any>(null);

	useEffect(
		() => () => {
			PRPCSetters.splice(PRPCSetters.indexOf(setPRPC), 1);
			PRPCRenders.slice(PRPCRenders.indexOf(render), 1);
		},
		[render],
	);

	PRPCSetters.push(setPRPC);
	PRPCRenders.push(render);

	return PRPC;
}
