import React, { RefAttributes, useCallback, useState } from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../components/EditModal";

import Root from "./components/Root";
import Content from "./components/Content";
import InternalController from "./Controller";

const Modal = react.withController<Modal.PropsBase, Modal.Controller>(
	({ controller, value, onCancel, onSave, allAverageTime, isAdd }) => {
		const [contentRef, setContentRef] =
			useRefWithSetter<Content.Ref | null>(null);
		controller.setContext({ contentRef });

		const [internalValue, setInternalValue] = useState(value);

		const valueEditor = useObjectEditor(internalValue, setInternalValue);

		const contentValue = valueEditor.usePicker([
			"id",
			"dow",
			"speed",
			"end",
			"start",
		]);

		const assigner = valueEditor.useAssigner();

		const modalOnSave = useCallback(async () => {
			const validate = contentRef.current?.validate();
			if (!validate) return;

			onSave(internalValue);
		}, [contentRef, internalValue, onSave]);

		return (
			<EditModal onCancel={onCancel} onSave={modalOnSave}>
				<Root sizes="auto! 1fr">
					<Content
						ref={setContentRef}
						value={contentValue}
						onChange={assigner}
						allAverageTime={allAverageTime}
						isAdd={isAdd}
					/>
				</Root>
			</EditModal>
		);
	},
	InternalController,
);

declare namespace Modal {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		allAverageTime: Content.PropsBase["allAverageTime"];
		value: Value;
		onCancel: () => void;
		onSave: (value: Value) => void;
		isAdd: boolean;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Content.Value {
		speed: number;
	}
}

export default Modal;
