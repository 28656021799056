export const types = [
	{
		key: "objects",
		label: "Объекты",
		value: "objects",
	},
	{
		key: "object-groups",
		label: "Группы объектов",
		value: "object-groups",
	},
];

export default {
	types,
};
