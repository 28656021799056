/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, TextSelect, react } from "uikit";
import Language from "../../../../../../../../../../../../services/Language";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import MultiSelectWithModal from "../../../../../../../../../../../../components/MultiSelectWithModal";
import TabRoot from "../TabRoot";
import useCarBaseTypes from "./hooks/useCarBaseTypes";
import useCarBrands from "./hooks/useCarBrands";
import useCarModels from "./hooks/useCarModels";
import useCarBodyTypes from "./hooks/useCarBodyTypes";
import useCarClasses from "./hooks/useCarClasses";
import InternalController from "./Controller";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ controller, value, visible, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const [carBaseTypeNameError, setCarBaseTypeNameError] =
				useState(false);

			const [carBrandNameError, setCarBrandNameError] = useState(false);

			const [carModelNameError, setCarModelNameError] = useState(false);

			const [carBodyTypeNameError, setCarBodyTypeNameError] =
				useState(false);

			const carBaseTypeNameValue =
				valueEditor.useGetter("carBaseTypeName");
			const setCarBaseTypeNameValue =
				valueEditor.useSetter("carBaseTypeName");

			const carBaseTypeNameOnChange = useCallback(
				(value: string) => {
					setCarBaseTypeNameValue(value);

					setCarBaseTypeNameError(false);
				},
				[setCarBaseTypeNameValue],
			);

			const carBrandNameValue = valueEditor.useGetter("carBrandName");
			const setCarBrandNameValue = valueEditor.useSetter("carBrandName");

			const carBrandNameOnChange = useCallback(
				(value: string) => {
					setCarBrandNameValue(value);

					setCarBrandNameError(false);
				},
				[setCarBrandNameValue],
			);

			const carModelNameValue = valueEditor.useGetter("carModelName");
			const setCarModelNameValue = valueEditor.useSetter("carModelName");

			const carModelNameOnChange = useCallback(
				(value: string) => {
					setCarModelNameValue(value);

					setCarModelNameError(false);
				},
				[setCarModelNameValue],
			);

			const carBodyTypeNameValue =
				valueEditor.useGetter("carBodyTypeName");
			const setCarBodyTypeNameValue =
				valueEditor.useSetter("carBodyTypeName");

			const carBodyTypeNameOnChange = useCallback(
				(value: string) => {
					setCarBodyTypeNameValue(value);

					setCarBodyTypeNameError(false);
				},
				[setCarBodyTypeNameValue],
			);

			const carClassIdsValue = valueEditor.useGetter("carClassIds");
			const carClassIdsOnChange = valueEditor.useSetter("carClassIds");

			const carBaseTypes = useCarBaseTypes({
				search: carBaseTypeNameValue,
			});

			const carBrands = useCarBrands({
				search: carBrandNameValue,
			});

			const carModels = useCarModels({
				search: carModelNameValue,
			});

			const carBodyTypes = useCarBodyTypes({
				search: carBodyTypeNameValue,
			});

			const carClasses = useCarClasses();

			controller.setContext({
				value,

				setCarBaseTypeNameError,
				setCarBrandNameError,
				setCarModelNameError,
				setCarBodyTypeNameError,
			});

			return (
				<TabRoot hasPaddings visible={visible}>
					<Row sizes="1fr*" gaps="19px*" maxedWidth maxedHeight>
						<LabeledField
							label={
								t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<TextSelect
								value={carBaseTypeNameValue}
								style={{
									minHeight: "32px",
								}}
								error={carBaseTypeNameError}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str0",
									) ?? ""
								}
								options={carBaseTypes.selectOptions}
								onChange={carBaseTypeNameOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str2",
								) ?? ""
							}
						>
							<TextSelect
								value={carBrandNameValue}
								style={{
									minHeight: "32px",
								}}
								error={carBrandNameError}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str2",
									) ?? ""
								}
								options={carBrands.selectOptions}
								onChange={carBrandNameOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str4",
								) ?? ""
							}
						>
							<TextSelect
								value={carModelNameValue}
								style={{
									minHeight: "32px",
								}}
								error={carModelNameError}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str4",
									) ?? ""
								}
								options={carModels.selectOptions}
								onChange={carModelNameOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str6",
								) ?? ""
							}
						>
							<TextSelect
								value={carBodyTypeNameValue}
								style={{
									minHeight: "32px",
								}}
								error={carBodyTypeNameError}
								placeholder={
									t(
										"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str6",
									) ?? ""
								}
								options={carBodyTypes.selectOptions}
								onChange={carBodyTypeNameOnChange}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str8",
								) ?? ""
							}
						>
							<MultiSelectWithModal
								value={carClassIdsValue}
								title={
									t(
										"pages.preferencesPages.screenDirectory.carModelsAndBrands.editModal.content.mainTab.str8",
									) ?? ""
								}
								options={carClasses.selectOptions}
								onChange={carClassIdsOnChange}
							/>
						</LabeledField>
					</Row>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		carBaseTypeName: string;
		carBrandName: string;
		carModelName: string;
		carBodyTypeName: string;
		carClassIds: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
