import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	padding: 6px 13px;

	left: 64px;
	bottom: 55px;

	width: 100px;
	height: 84px;

	position: absolute;

	border: 1px solid #d4d4d5;
	border-radius: 5px;

	background-color: ${theme.colors.white};
	overflow: hidden;
	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
		0 2px 10px 0 rgba(34, 36, 38, 0.15);
`;
export default Root;
