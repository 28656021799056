import React, {
	Dispatch,

	ReactNode,
	useCallback,
	useMemo,
	useState,
} from "react";
import { Column } from "uikit";
import { isNil } from "lodash";
import CustomerRate from "../../../../../../../../../../../../../../services/CustomerRate";
import ModelTable from "./components/ModelTable";
import { useSort } from "../../../../../../../../../../../../../../hooks/useTableSettings";
import DefaultPageHeader from "../../../../../../../../../../../../../../components/DefaultPageHeader";
import EditModal from "./components/EditModal";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import DeleteModal from "../../../../../../../../../../../../../../components/DeleteModal";

const Rules: React.FC<Rules.Props> = ({ value, disabled, leftFilters, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const [selected, setSelected] = useState<number[]>([]);
	const [editValue, setEditValue] = useState<EditModal.Value | null>(null);
	const [editIndex, setEditIndex] = useState<number | null>(null);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"settings.mutualSettlements.customerRates.rules",
		"customerRateRule",
	);

	const edit = useCallback(
		(index: number) => {
			const item = value[index];

			setEditValue(item);
			setEditIndex(index);
		},
		[value],
	);

	const canAdd = useMemo(() => !disabled, [disabled]);

	const canEdit = useMemo(
		() => !disabled && selected.length === 1,
		[disabled, selected.length],
	);

	const canDelete = useMemo(
		() => !disabled && selected.length > 0,
		[disabled, selected.length],
	);

	const headerOnAdd = useCallback(() => {
		setEditValue({
			threshold: 0,

			discount: {
				value: 0,
				unit: CustomerRate.Model.Unit.Percentage,
			},

			onlineDiscount: {
				active: false,
				value: 0,
				unit: CustomerRate.Model.Unit.Percentage,
			},
		});

		setEditIndex(null);
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const modelTableOnEdit = useCallback(
		(item: ModelTable.Item) => {
			edit(value.indexOf(item));
		},
		[edit, value],
	);

	const editModalOnClose = useCallback(() => {
		setEditValue(null);
		setEditIndex(null);
	}, []);

	const editModalOnSubmit = useCallback(
		(value: EditModal.Value) => {
			if (isNil(editIndex)) valueEditor.push(value);
			else valueEditor.set(editIndex, value);

			setEditValue(null);
			setEditIndex(null);
		},
		[editIndex, valueEditor],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(() => {
		valueEditor.removeByValues(selected.map((index) => value[index]));

		setSelected([]);
		setShowDeleteModal(false);
	}, [selected, value, valueEditor]);

	return (
		<>
			<Column gaps="16px*">
				<DefaultPageHeader
					canAdd={canAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					leftFilters={leftFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<ModelTable
					selected={selected}
					sort={sort}
					loading={false}
					data={value}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={modelTableOnEdit}
				/>
			</Column>
			{editValue ? (
				<EditModal
					value={editValue}
					onClose={editModalOnClose}
					onSubmit={editModalOnSubmit}
				/>
			) : null}
			{showDeleteModal ? (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			) : null}
		</>
	);
};

declare namespace Rules {
	type Value = CustomerRate.Model.ModeData.Item[];

	interface Props {
		value: Value;

		disabled?: boolean;
		leftFilters?: ReactNode;

		onChange: Dispatch<Value>;
	}
}

export default Rules;
