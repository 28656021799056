import React from "react";

import TotalRideCountCellContent from "../components/TotalRideCountCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function totalRideCount({ width, onResize }: Column.Options) {
	return base(
		ColumnId.TotalRideCount,
		(item) => <TotalRideCountCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default totalRideCount;
