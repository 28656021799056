import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Row, Button } from "uikit";
import Root from "./components/Root";

const Footer = memo<Footer.Props>(
	({ canSubmit = true, onCancel, onReset, onSubmit }) => {
		const { t } = useTranslation();
		return (
			<Root align="center" justify="space-between" gaps="10px*">
				<Button.Button
					variant="secondary"
					text={
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.footer.str0",
						) ?? ""
					}
					onClick={onReset}
				/>

				<Row gaps="10px*">
					<Button.Button
						variant="secondary"
						text={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.footer.str1",
							) ?? ""
						}
						onClick={onCancel}
					/>
					<Button.Button
						disabled={!canSubmit}
						text={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersByCarModal.footer.str2",
							) ?? ""
						}
						onClick={onSubmit}
					/>
				</Row>
			</Root>
		);
	},
);

declare namespace Footer {
	interface Props {
		canSubmit?: boolean;

		onCancel?: () => void;
		onReset?: () => void;
		onSubmit?: () => void;
	}
}

export default Footer;
