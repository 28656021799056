import makeTable from "./makeTable";

const color = makeTable({
	allIds: [
		"checkbox",
		"active",
		"colorName",
		"assignedColor",
		"company",
		"branch",
	],
	i18tPath: (id) => `tableColumns.colors.${id}`,
});

export default color;
