import Map from "../../../../../../../../../../../../../../services/Map";

export const columns = [
	{ id: "name", label: "Название", width: 200 },
	{ id: "period", label: "Период", width: 150 },
	{ id: "time", label: "Время", width: 150 },
	{ id: "mode", label: "Режим", width: 200 },
];
export const selectOptions: {
	key: Map.Search.Type;
	value: Map.Search.Type;
	label: string;
	label2: string;
}[] = [
	{
		key: "settlement",
		value: "settlement",
		label: "Город",
		label2: "города",
	},
	{ key: "address", value: "address", label: "Адрес", label2: "адреса" },
	{ key: "object", value: "object", label: "Объект", label2: "объекта" },
	{
		key: "price_zone",
		value: "price_zone",
		label: "Тарифная зона",
		label2: "тарифной зоны",
	},
	{ key: "parking", value: "parking", label: "Стоянка", label2: "стоянок" },
];

export const defaultColumnsId = columns.map(({ id }) => id);
