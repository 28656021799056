import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)<Root.Props>`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 24px;
	text-align: center;
	overflow: hidden;
	background: ${({ disabled }) =>
		disabled ? theme.colors.disabled_filled : theme.colors.white};
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D3D4DBFF' stroke-width='1' stroke-dasharray='8%2c4' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
`;
// background-image — https://kovart.github.io/dashed-border-generator/

// Another border variant:
// &:before,
// 	&:after {
// 		content: "";
// 		position: absolute;
// 		width: 101.3%;
// 		height: 101.3%;
// 	}
// 	&:before {
// 		top: 0px;
// 		left: 0px;
// 		border-right: 5px dashed ${theme.colors.color_border_basic};
// 		border-bottom: 5px dashed ${theme.colors.color_border_basic};
// 	}
// 	&:after {
// 		right: 0px;
// 		bottom: 0px;
// 		border-top: 5px dashed ${theme.colors.color_border_basic};
// 		border-left: 5px dashed ${theme.colors.color_border_basic};
// 	}

declare namespace Root {
	interface Props {
		disabled: boolean;
	}
}

export default Root;
