import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import type { History, Blocker, Transition } from "history";

function useNavigationBlocker(blocker: Blocker, when = true): void {
	const history = useHistory() as History;

	useEffect(() => {
		if (!when) return undefined;

		const unblock = history.block((transition: Transition) => {
			const autoUnblockingTx = {
				...transition,
				retry() {
					unblock();
					transition.retry();
				},
			};

			return blocker(autoUnblockingTx);
		});

		return unblock;
	}, [history, blocker, when]);
}

export default useNavigationBlocker;
