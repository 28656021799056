import { useCallback, useMemo } from "react";

import Company from "../services/Company";

import useModelSubscribe from "./useModelSubscribe";

type EncodedIds = number[] | ["all"];

function isAllIds(taxiServices: EncodedIds): taxiServices is ["all"] {
	return taxiServices[0] === "all";
}

function useCompanyIdsDecoder() {
	const companyData = useModelSubscribe({}, Company);

	const companies = useMemo(
		() => companyData?.cache ?? [],
		[companyData?.cache],
	);

	const availableCompanyIds = useMemo(
		() => companies.map((company) => company.id),
		[companies],
	);

	const decoder = useCallback(
		(companyIds: EncodedIds) => {
			if (isAllIds(companyIds)) return availableCompanyIds;

			return availableCompanyIds.filter((companyId) =>
				companyIds.includes(companyId),
			);
		},
		[availableCompanyIds],
	);

	return decoder;
}

export default useCompanyIdsDecoder;
