import React, { memo, Suspense, useMemo } from "react";
import { Trans } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";

import Mark from "../../Mark";

const ValueDifference: React.FC<ValueDifference.Props> = ({ change }) => {
	const previous = useMemo(() => change.previous, [change.previous]);
	const actual = useMemo(() => change.actual, [change.actual]);

	return (
		<Suspense>
			<Trans
				i18nKey={`change_history.orders.fields.${change.field}`}
				context={change.previous ? "change" : "set"}
				values={{
					prev: previous,
					new: actual,
				}}
				components={{ mark: <Mark /> }}
			/>
		</Suspense>
	);
};

declare namespace ValueDifference {
	interface Props {
		change:
			| History.Field.TypeChange
			| History.Field.StatusChange
			| History.Field.OrderNoteChange
			| History.Field.OrderNumberChange
			| History.Field.ClosedOrdersCommentChange
			| History.Field.AdditionalCost
			| History.Field.OutsideSettlementKm
			| History.Field.PassengersCount
			| History.Field.TransportationType
			| History.Field.ExecutorNotes
			| History.Field.CustomerNotes
			| History.Field.AdditionalCost
			| History.Field.BillingCustomer
			| History.Field.PaymentAccount;
	}
}

export const ValueDifferenceMemo = memo(ValueDifference);
export default ValueDifference;
