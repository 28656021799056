/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../../../../../../../utils/generateOptions";

export enum AutoRateRuleWeekday {
	MONDAY = "monday",
	TUESDAY = "tuesday",
	WEDNESDAY = "wednesday",
	THURSDAY = "thursday",
	FRIDAY = "friday",
	SATURDAY = "saturday",
	SUNDAY = "sunday",
}

export const weekOptions = getOptions(Object.values(AutoRateRuleWeekday));
