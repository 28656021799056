import React, { memo, useMemo } from "react";

import { StyledGridItem } from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";

import CountPassengers from "../CountPassengers";

const GridItemPassengers: React.FC<GridItemPassengers.Props> = ({
	tab,
	form,
	disabled,
	area,
}) => {
	const styles = useMemo(
		() => ({
			area: area || "passenger",
			h: "28px",
			p: "0 0 0 10px",
			alignSelf: "center",
			justifySelf: "start",
		}),
		[area],
	);

	return (
		<StyledGridItem {...styles}>
			<CountPassengers form={form} tab={tab} disabled={disabled} />
		</StyledGridItem>
	);
};

declare namespace GridItemPassengers {
	interface Props extends OrderFormProps {
		area?: string;
	}
}

export const GridItemPassengersMemo = memo(GridItemPassengers);
export default GridItemPassengers;
