import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	width: 100%;
	align-items: flex-start;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
	& > div:last-child {
		border-bottom: none;
	}
`;

export default Root;
