import PushMessage from "../../../../../../../../services/PushMessage";

export const getMessageType = (type: PushMessage.NotificationMessageType) => {
	if (type === "info") return "Информационные";
	if (type === "order") return "По заказу";
	return "";
};

export const getMessageStatus = (
	status: PushMessage.NotificationMessageSimpleStatus,
) => {
	if (status === "UNDELIVERABLE") return "Не доставлено";
	if (status === "DELIVERED") return "Доставлено";
	if (status === "READ") return "Прочитано";
	return "";
};

export const getSender = (sender) => {
	if (sender === "system") return "Система";
	return sender;
};
