export default function deepParse(object) {
	if (typeof object === "string") {
		try {
			const parsed = JSON.parse(object);
			if (typeof parsed === "object" && parsed !== null) {
				return deepParse(parsed);
			}
			return parsed;
		} catch (e) {
			return object;
		}
	} else if (Array.isArray(object)) {
		return object.map(deepParse);
	} else if (typeof object === "object" && object !== null) {
		return Object.keys(object).reduce((acc, key) => {
			acc[key] = deepParse(object[key]);
			return acc;
		}, {});
	}
	return object;
}
