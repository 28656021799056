/* eslint-disable no-shadow */
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";

export enum RoundingMethod {
	GENERAL = "general",
	CEIL = "ceil",
	FLOOR = "floor",
}

export const roundingMethodOptions = getOptions(Object.values(RoundingMethod));
