import React, {
	Dispatch,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Option as OptionBase } from "uikit";
import Root from "./components/Root";
import Item from "./components/Item";
import useKeyBind from "../../../../../../../../../hooks/useKeyBind";

const itemOnMouseDown = (
	event: React.MouseEvent<HTMLDivElement, MouseEvent>,
): void => {
	event.preventDefault();
	event.stopPropagation();
};

// eslint-disable-next-line prettier/prettier
const OptionList = <OptionValue, >({
	width,
	options,
	onSelect,
}: OptionList.Props<OptionValue>) => {
	const hoveredItemRef = useRef<HTMLDivElement | null>(null);
	const [hovered, setHovered] = useState<number>(0);

	const selectPrevious = useCallback(() => {
		setHovered((previous) => {
			const lastItemIndex = Math.max((options?.length ?? 0) - 1, 0);

			return previous - 1 < 0 ? lastItemIndex : previous - 1;
		});
	}, [options?.length]);

	const selectNext = useCallback(() => {
		setHovered((prev) => {
			const lastItemIndex = Math.max((options?.length ?? 0) - 1, 0);

			return prev + 1 > lastItemIndex ? 0 : prev + 1;
		});
	}, [options?.length]);

	useKeyBind(["ArrowUp"], (event) => {
		event.preventDefault();
		event.stopPropagation();

		selectPrevious();
	});

	useKeyBind(["ArrowDown"], (event) => {
		event.preventDefault();
		event.stopPropagation();

		selectNext();
	});

	useKeyBind(["Enter"], (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (!options) return;

		onSelect?.(options[hovered]);
	});

	useLayoutEffect(() => {
		if (hoveredItemRef.current) {
			hoveredItemRef.current?.scrollIntoView({
				behavior: "smooth",
				inline: "nearest",
				block: "nearest",
			});
		}
	}, [hovered]);

	const items = useMemo(
		() =>
			options?.map((option, index) => (
				<Item
					ref={hovered === index ? hoveredItemRef : null}
					key={option.key}
					hovered={hovered === index}
					align="center"
					onMouseDown={itemOnMouseDown}
					onClick={() => onSelect?.(option)}
				>
					{option.label}
				</Item>
			)),
		[hovered, onSelect, options],
	);

	return <Root width={width}>{items}</Root>;
};

declare namespace OptionList {
	type Option<OptionValue> = OptionBase<OptionValue>;

	interface Props<OptionValue> {
		width: number;

		options?: Option<OptionValue>[];

		onSelect?: Dispatch<Option<OptionValue>>;
	}
}

export default OptionList;
