import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, isNumber, isString, isUndefined } from "lodash";
import moment from "moment";

import Service from "../../../../../../services/Service";
import CarClass from "../../../../../../services/CarClass";
import { useTypedSelector } from "../../../../../../redux/store";
import LightInputModal from "../../../../../../components/LightInputModal";

import Content from "./components/Content";

const SetOrderExecutorModal: React.FC<SetOrderExecutorModal.Props> = ({
	orderTime,
	onSubmit,
	carClasses = [],
	services = [],
	...props
}) => {
	const { t } = useTranslation();
	const orderSettings = useTypedSelector((state) => state.settings.order);

	const timeToDelivery = useMemo(
		() =>
			isNumber(orderTime) || isString(orderTime)
				? Math.max(
						moment(new Date(orderTime)).diff(
							moment.now(),
							"minutes",
						),
						0,
				  )
				: undefined,
		[orderTime],
	);

	const [value, setValue] = useState<Content.Value>({
		arrivalTime: isNumber(timeToDelivery)
			? timeToDelivery + 1
			: (orderSettings.general?.defaultExecutorArrivalTime?.value ??
					60e3) / 60e3,
	});

	const canSubmit = useMemo(
		() =>
			!isUndefined(value.executor) &&
			(value.executor.isWorking || (value.openShift ?? false)) &&
			(value.executor.cars?.length === 1 || isNumber(value.carId)),
		[value.carId, value.executor, value.openShift],
	);

	const modalOnSubmit = useCallback(() => {
		const params = cloneDeep(value);
		onSubmit?.(params);
		setValue({
			arrivalTime:
				(orderSettings.general?.defaultExecutorArrivalTime?.value ??
					60e3) / 60e3,
		});
	}, [
		onSubmit,
		orderSettings.general?.defaultExecutorArrivalTime?.value,
		value,
	]);

	return (
		<LightInputModal
			{...props}
			title={
				t("pages.mainPage.pages.orders.setOrderExecutorModal.str0") ??
				""
			}
			canSubmit={canSubmit}
			onSubmit={modalOnSubmit}
		>
			<Content
				value={value}
				onChange={setValue}
				carClasses={carClasses}
				services={services}
			/>
		</LightInputModal>
	);
};

declare namespace SetOrderExecutorModal {
	type Value = Content.Value;

	interface Props extends Omit<LightInputModal.Props, "title" | "onSubmit"> {
		orderTime?: string | number;

		onSubmit?: Dispatch<Value>;

		carClasses: CarClass.Model[];
		services: Service.Model[];
	}
}

export default SetOrderExecutorModal;
