import { Dispatch, useLayoutEffect } from "react";
import useMapSearch from "../../../../../../../../../../../../hooks/useMapSearch";

const MapSearch: React.FC<MapSearch.Props> = ({ options, onChange }) => {
	const mapSearchResult = useMapSearch(options);

	useLayoutEffect(() => {
		onChange?.(mapSearchResult);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapSearchResult]);

	return null;
};

declare namespace MapSearch {
	type Options = useMapSearch.Options;
	type Result = useMapSearch.Result;

	interface Props {
		options: Options;

		onChange?: Dispatch<Result>;
	}
}

export default MapSearch;
