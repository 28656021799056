import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)<Root.Props>`
	padding: 7px 6px;

	background: ${theme.colors.white};

	cursor: pointer;

	${({ selected }) =>
		selected
			? `
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

  z-index: 5;
`
			: ""}
`;

declare namespace Root {
	interface Props {
		selected: boolean;
	}
}

export default Root;
