import React, { Dispatch } from "react";
import { CheckBoxWithContent } from "uikit";

import Root from "./components/Root";

const Item: React.FC<Item.Props> = ({
	value,
	label,
	onChange,
	active,
	showCheckBox = true,
}) => (
	<Root
		id={label}
		aria-label={label}
		onClick={() => {
			onChange(!value);
		}}
		active={active}
	>
		{showCheckBox ? (
			<CheckBoxWithContent value={value} gap="10px" onChange={onChange}>
				{label}
			</CheckBoxWithContent>
		) : (
			label
		)}
	</Root>
);

declare namespace Item {
	type Value = boolean;

	interface Props {
		active?: boolean;
		value: Value;
		label: string;
		showCheckBox?: boolean;
		onChange: Dispatch<Value>;
	}
}

export default Item;
