import React, { Dispatch, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, InputGroup, theme } from "uikit";

import SearchIconBorders from "../../../SearchIconBorders";
import SearchTextBox from "../../../SearchTextBox";
import Chips from "./components/Chips";
import List from "./components/List";
import Root from "./components/Root";

type Key = string | number;

const Content = <OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
}: Content.Props<OptionValue, ValueType>) => {
	const [search, setSearch] = useState("");

	const filteredOptions = useMemo(
		() =>
			options.filter((option) =>
				option.label
					.toLocaleLowerCase()
					.match(search.toLocaleLowerCase()),
			),
		[options, search],
	);

	const { t } = useTranslation();
	return (
		<Root align="stretch" sizes="auto!*2 1fr" gaps="15px*">
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t("multiSelectModal.content.str200") ?? ""
					}...`}
					value={search}
					onChange={setSearch}
				/>
			</InputGroup.InputGroup>
			<Chips value={value} options={options} onChange={onChange} />
			<List value={value} options={filteredOptions} onChange={onChange} />
		</Root>
	);
};

declare namespace Content {
	type Value<Type extends Key> = Type[];

	type Option<Value> = List.Option<Value>;

	interface Props<OptionValue, ValueType extends Key> {
		value: Value<ValueType>;

		options: Option<OptionValue>[];

		onChange: Dispatch<Value<ValueType>>;
	}
}

export default Content;
