/* eslint-disable no-shadow */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Column, Option, Row, Select } from "uikit";
import { useTranslation } from "react-i18next";

import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import CurrencyService from "../../../../../../../../../../../../../../services/Currency";
import { useTypedSelector } from "../../../../../../../../../../../../../../redux/store";
import mapByKey from "../../../../../../../../../../../../../../utils/mapByKey";
import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";
import tPath from "../../../../../../../../constants/tPath";

import { CurrencyDisplayStyle } from "./constants";

const Currency: React.FC<Currency.Props> = memo(
	({ currency, setCurrency, currencies, value, onChange }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);
		const lang = useTypedSelector((state) => state.session.language);

		const displayStyle = valueEditor.useGetter("displayStyle");
		const setDisplayStyle = valueEditor.useSetter("displayStyle");

		const handleDisplayStyleChange = useCallback(
			(newDisplayStyle) => {
				setDisplayStyle(newDisplayStyle);
			},
			[setDisplayStyle],
		);

		const labels = useMemo(
			() => Object.values(currency.settings),
			[currency],
		);

		const [currencyId, setCurrencyId] = useState<ModelId>(currency.id);
		const [styleOptions, setStyleOptions] = useState<Option<string>[]>(
			Object.keys(currency.settings).map((item, i) => ({
				key: item,
				label: labels[i],
				value: item,
			})),
		);

		const modelItemById = useMemo(
			() => mapByKey(currencies, "id"),
			[currencies],
		);

		useEffect(() => {
			if (currencyId) {
				const labels = Object.values(
					modelItemById[currencyId].settings,
				);
				setStyleOptions(
					Object.keys(modelItemById[currencyId].settings).map(
						(item, i) => ({
							key: item,
							label: labels[i],
							value: item,
						}),
					) || [],
				);
			}
		}, [currencyId, modelItemById, setDisplayStyle]);

		const handleCurrencyIdChange = useCallback(
			(newCurrencyId) => {
				setCurrencyId(newCurrencyId);
				setDisplayStyle(null);
			},
			[setDisplayStyle],
		);
		const currenciesOptions = useMemo<Option<number>[]>(
			() =>
				currencies.map((item) => ({
					key: item.id,
					label: item.name[lang],
					value: item.id,
				})),
			[currencies, lang],
		);

		useEffect(() => {
			setCurrency(modelItemById[currencyId]);
		}, [currencyId, modelItemById, setCurrency]);

		return (
			<FieldsContainer
				label={t(`${tPath}.modal.tabs.main.currency.title`) || ""}
			>
				<Column gaps="10px*">
					<Row align="center" gaps="10px*" sizes="14% 1fr">
						<div>
							{t(`${tPath}.modal.tabs.main.currency.choose`)}
						</div>
						<Select
							placeholder={
								t(
									`${tPath}.modal.tabs.main.currency.placeholder`,
								) || ""
							}
							options={currenciesOptions}
							value={currencyId}
							onChange={handleCurrencyIdChange}
						/>
					</Row>
					<Row align="center" gaps="10px*" sizes="14% 1fr">
						<div>
							{t(
								`${tPath}.modal.tabs.main.currency.shortName.title`,
							)}
						</div>
						<Select
							placeholder={
								t(
									`${tPath}.modal.tabs.main.currency.shortName.placeholder`,
								) || ""
							}
							options={styleOptions}
							value={displayStyle ?? undefined}
							onChange={handleDisplayStyleChange}
						/>
					</Row>
				</Column>
			</FieldsContainer>
		);
	},
);

declare namespace Currency {
	interface Props {
		currency: CurrencyService.Model;
		setCurrency: (value: CurrencyService.Model) => void;
		currencies: CurrencyService.Model[];
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		displayStyle: CurrencyDisplayStyle | null;
	}
}

export default Currency;
