import makeTable from "./makeTable";

const transaction = makeTable({
	allIds: [
		"date",
		"type",
		"paymentAccountType",
		"comment",
		"balanceBefore",
		"sum",
		"balanceAfter",
		"notes",
		"transactionAuthor",
		"orderNumber",
	],
	i18tPath: (id) => `tableColumns.transactions.${id}`,
	initialWidths: {
		date: 150,
		orderNumber: 100,
		type: 150,
		paymentAccountType: 100,
		comment: 120,
		balanceBefore: 100,
		sum: 100,
		balanceAfter: 110,
		notes: 200,
		transactionAuthor: 170,
	},
});

export default transaction;
