import SubscriptionPool from "../../../redux/services/SubscriptionPool";
import Base from "../../Base";

class SIPToDispatcherEvent extends Base {
	public static async subscribe({ sipToDispatcherId, onEvent, onError }) {
		try {
			const res = await SubscriptionPool.add(
				(prpc) =>
					prpc.theirsModel.sipToDispatcher.events.subscribe({
						onEvent,
						sipToDispatcherId,
						onError,
					}),
				{ name: "SIPToDispatcherEvent.subscribe" },
			);
			return res;
		} catch (error) {
			return console.error(error);
		}
	}
}

export default SIPToDispatcherEvent;
