import React from "react";
import styled from "styled-components";
import { theme } from "uikit";

import { useTypedSelector } from "../../../../../../../redux/store";

const Root = styled.div`
	width: 20px;
	height: 19px;

	display: flex;
	justify-content: center;
	align-items: center;

	font-family: Inter;
	font-size: 11px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;

	color: ${theme.colors.white};

	background-color: #e54f3a;

	border-radius: 50%;
	border: 2px solid ${theme.colors.white};
`;

const Budge: React.FC = () => {
	const { lastRead } = useTypedSelector((state) => state.orders.chats.chat);
	const { remaining } = lastRead;

	if (!remaining) return null;
	return <Root>{remaining}</Root>;
};

export default Budge;
