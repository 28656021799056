import Reward, { RewardCalculationMethod } from "../../../services/Reward";
import { Language } from "../../../assets/languages/langs";
import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

import { DiscountPlan } from "./getPhoneInfo";

export declare namespace OrderCodeInfo {
	interface PaymentAccount {
		id: number;
		type: "main" | "bonus";
		amount: number;
		currency: string;
		currencySymbol: string;
	}

	interface Code {
		/** The agent or counterparty id */
		id: number;
		type: "agent" | "counterparty";
		name: Record<Language, string>;
		companyId: number;
		phone: string | null;
		checkCardId: number;

		code: {
			keyword: string;
			value: string;
		};
		paymentAccounts: PaymentAccount[];

		notes?: string;
		orderNotes?: string;
		executorNotes?: string;

		/** if `type === agent` */
		reward?: Reward.Model;
		/** if `type === agent` */
		rewards?: Reward.Model[];
		/** if `type === agent` */
		isArbitraryRewardAllowed?: boolean;
		/** if `type === agent` */
		isRewardAllowed?: boolean;
		/** if `type === agent` */
		rewardCalculationMethod?: RewardCalculationMethod;

		/** if `type === counterparty` */
		additionalPlan?: DiscountPlan;
		/** if `type === counterparty` */
		mainPlan?: DiscountPlan;
	}
}

export const getPlanFromCodeInfo = (
	data: Record<string, any>,
): DiscountPlan => {
	const type = data?.configuration?.type;
	const types =
		data?.configuration?.general?.types || data?.configuration?.types;
	const discount =
		types?.[type]?.discount || types?.[type]?.grid?.[0]?.discount;

	const payload = {
		active: data.active,
		name: data.name,
		discount,
		maxDiscount: data?.configuration?.general?.maxDiscount,
		type,
		types,
		appliesToMinimal: data?.configuration?.general?.appliesToMinimal,
	};

	return payload;
};

export default function getOrderCodeInfo(
	code: string,
	onResponse: (response: OrderCodeInfo.Code | null) => void,
) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const response: OrderCodeInfo.Code | null = await createRPCQuery(
			() => prpcow?.theirsModel.code.getOrderCodeInfo(code),
			{ verbose: true, silent: true, name: "code.getOrderCodeInfo" },
		);

		return onResponse(response);
	};
}
