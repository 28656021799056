import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./utils/i18n";
import "semantic-ui-css/semantic.min.css";
import "./styles/index.scss";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./components/common/styles";
import { store, persistedStore } from "./redux/store";
import { SuspenseLoader } from "./components/common";
import { AppProvider } from "./context";
import { NotificationProvider } from "./components/NotificationProvider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate
				loading={<SuspenseLoader />}
				persistor={persistedStore}
			>
				<GlobalStyle />

				<NotificationProvider>
					<AppProvider>
						<App />
					</AppProvider>
				</NotificationProvider>
			</PersistGate>
		</Provider>
	</BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
