import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { TextBox } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import { StyledColumn } from "../../../../../common";

import BasePassenger from "../..";

const FullName: React.FC<FullName.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLInputElement | null>(null);

	const locale = useMemo(
		() => ({
			name:
				t(`mainPage.customers.clients.main.personalData.name`) ||
				"Name",
			surname:
				t(`mainPage.customers.clients.main.personalData.surname`) ||
				"Surname",
			fatherName:
				t(`mainPage.customers.clients.main.personalData.fatherName`) ||
				"Father name",
		}),
		[t],
	);

	const valueEditor = useObjectEditor(value, onChange);

	const name = valueEditor.useGetter("name");
	const setName = valueEditor.useSetter("name");

	const surname = valueEditor.useGetter("surname");
	const setSurname = valueEditor.useSetter("surname");

	const fatherName = valueEditor.useGetter("fatherName");
	const setFatherName = valueEditor.useSetter("fatherName");

	const capitalSymbol = useCallback(
		(first: string, str: string, step = 1): string => {
			const value = first.substring(0, step).toUpperCase();
			const value2 = str.substring(step).toLowerCase();
			return `${value}${value2}`;
		},
		[],
	);

	const handelName = useCallback(
		(str: string) => {
			setName(capitalSymbol(str, str));
		},
		[capitalSymbol, setName],
	);
	const handelSurname = useCallback(
		(str: string) => {
			setSurname(capitalSymbol(str, str));
		},
		[capitalSymbol, setSurname],
	);
	const handelFatherName = useCallback(
		(str: string) => {
			setFatherName(capitalSymbol(str, str));
		},
		[capitalSymbol, setFatherName],
	);

	useEffect(() => {
		const elem = ref?.current;
		if (elem) elem.setAttribute("id", "customer-modal-surname-id");
	}, [ref]);

	const queryElement = useMemo(() => {
		try {
			return (
				<TextBox.TextBox
					inputRef={ref}
					style={{
						minHeight: "32px",
						flex: "1 0 0",
					}}
					autoComplete="one-time-code"
					placeholder={locale.surname}
					value={surname}
					onChange={handelSurname}
				/>
			);
		} catch {
			return <></>;
		}
	}, [handelSurname, locale.surname, surname]);

	return (
		<StyledColumn gap="10px">
			{queryElement}

			<TextBox.TextBox
				autoFocus
				style={{
					minHeight: "32px",
					flex: "1 0 0",
				}}
				autoComplete="one-time-code"
				placeholder={locale.name}
				value={name}
				onChange={handelName}
			/>

			<TextBox.TextBox
				style={{
					minHeight: "32px",
					flex: "1 0 0",
				}}
				autoComplete="one-time-code"
				placeholder={locale.fatherName}
				value={fatherName}
				onChange={handelFatherName}
			/>
		</StyledColumn>
	);
};

declare namespace FullName {
	interface Props {
		value: BasePassenger.Value;
		onChange: Dispatch<BasePassenger.Value>;
	}
}

export default FullName;
