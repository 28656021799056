import { react } from "uikit";
import { Dispatch, SetStateAction } from "react";

import CompanyBranch from ".";

interface Context {
	value: CompanyBranch.Value & {
		isDefault: boolean;
		selectedCompanies: number[];
	};

	setCompanyError: Dispatch<SetStateAction<boolean>>;
	setBranchError: Dispatch<SetStateAction<string>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCompanyValid = !!this.context?.value.selectedCompanies.length;
		if (!isCompanyValid) this.context?.setCompanyError(true);

		let isBranchesValid = true;
		const branchesLength = this.context?.value.taxiServiceIds.length;
		const isDefault = this.context?.value.isDefault;
		if (!isDefault && branchesLength === 0) {
			isBranchesValid = false;
			this.context?.setBranchError("Филиал обязателен");
		} else if (isDefault && !branchesLength) {
			isBranchesValid = false;
			this.context?.setBranchError("Минимум 1 филиал");
		}

		return isCompanyValid && isBranchesValid;
	}
}
