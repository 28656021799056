import { MessageTemplateActions } from "../../../../../../../types/MessageTemplateEnums";

import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	ALLOWED = "allowed",
	AUTOMATIC_SENDING = "automatic_sending",
	NAME = "name",
	ACTION = "action",
	COMPANIES = "companies",
	BRANCHES = "branches",
}

export const columns: Content.ColumnType[] = [
	{ id: Columns.ALLOWED, label: "Allowed", width: 100 },
	{ id: Columns.AUTOMATIC_SENDING, label: "Auto Sending", width: 120 },
	{ id: Columns.NAME, label: "Name", width: 280 },
	{ id: Columns.ACTION, label: "Action", width: 200 },
	{ id: Columns.COMPANIES, label: "Companies", width: 220 },
	{ id: Columns.BRANCHES, label: "Branches" },
]; // * label translation is done inside the component through a for

export const defaultColumnsId = columns.map(({ id }) => id);

export const convertActionToString = {};
const actionValues = Object.values(MessageTemplateActions);
actionValues.forEach((value) => {
	convertActionToString[value] = MessageTemplateActions[value];
});
