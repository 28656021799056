import { ReactNode } from "react";

import { Language } from "../../../../../../../../../../../services";
import { translationTablePath } from "../constants";

import name from "./name";
import companies from "./companies";
import branches from "./branches";

export enum ColumnId {
	Name = "name",
	Companies = "companies",
	Branches = "branches",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;
		language: Language;
		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.Name]: {
		render: name,
		label: [`${translationTablePath}.${ColumnId.Name}`, "Name"],
	},

	[ColumnId.Companies]: {
		render: companies,
		label: [`${translationTablePath}.${ColumnId.Companies}`, "Companies"],
	},

	[ColumnId.Branches]: {
		render: branches,
		label: [`${translationTablePath}.${ColumnId.Branches}`, "Branches"],
	},
};

export const defaultColumnIds = [
	ColumnId.Name,
	ColumnId.Companies,
	ColumnId.Branches,
];

export const disabledColumnsIds = [];

export default columns;
