import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)`
	padding: 8px 16px 8px 20px;

	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);

	z-index: 1000;
`;

export default Root;
