import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	overflow: hidden;
	width: max-content;
	height: 310px;
`;

export default Root;
