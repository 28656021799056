/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import createUIDMaker from "../../utils/createUIDMaker";

export const nextUID = createUIDMaker();

export interface ModalWindow {
	type: "window";
	uid: number;
	content: React.ReactNode;
	dimmer?: boolean | string;
}

export interface ModalController {
	type: "controller";
	uid: number;
}

interface IState {
	stack: (ModalWindow | ModalController)[];
}

type ModalReducer<P> = CaseReducer<IState, PayloadAction<P>>;

const pushController: ModalReducer<ModalController> = (state, { payload }) => {
	if (state.stack.every((w) => w.type === "window"))
		state.stack.unshift(payload);
	else state.stack.push(payload);
};

const removeController: ModalReducer<number> = (state, { payload }) => {
	state.stack = state.stack.filter(
		(controller) => controller.uid !== payload,
	);
};

const pushWindow: ModalReducer<ModalWindow> = (state, { payload }) => {
	state.stack.push(payload);
};

const editWindow: ModalReducer<ModalWindow> = (state, { payload }) => {
	const index = state.stack.findIndex((w) => w.uid === payload.uid);

	if (index !== -1) state.stack[index] = payload;
};

const removeWindow: ModalReducer<number> = (state, { payload }) => {
	state.stack = state.stack.filter((window) => window.uid !== payload);
};

const initialState: IState = {
	stack: [],
};

const modal = createSlice({
	name: "modal",
	initialState,
	reducers: {
		pushController,
		removeController,
		pushWindow,
		editWindow,
		removeWindow,
	},
});

export default modal;
