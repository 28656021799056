import React, { memo, useCallback, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Select, Button, Icon, useInternal, CheckBox } from "uikit";

import { StyledColumn, StyledRow } from "../../../../../../components/common";
import tPath from "../../constants/tPath";

import { pickupCostOptions, PickupCostType } from "../../constants/constants";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";

const ButtonMap = styled(Button.Button)`
	min-width: 32px;
	width: 32px;
	height: 32px;
	margin: 0 10px;
`;

const PickupCost: React.FC<PickupCost.Props> = memo(
	({ disabled = false, value, onChange }) => {
		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const [open, setOpen] = useInternal<boolean>(false);
		const [showCheckBox, setShowCheckBox] = useInternal<boolean>(false);

		const type = valueEditor.useGetter("type");
		const setType = valueEditor.useSetter("type");

		const considerSuburbanZone = valueEditor.useGetter(
			"considerSuburbanZone",
		);
		const setConsiderSuburbanZone = valueEditor.useSetter(
			"considerSuburbanZone",
		);

		const onChangeConsiderSuburbanZone = useCallback(
			(value: boolean) => {
				setConsiderSuburbanZone(!value);
			},
			[setConsiderSuburbanZone],
		);

		useLayoutEffect(() => {
			if (type !== PickupCostType.NEAREST) setOpen(true);
			else setOpen(false);

			if (type === PickupCostType.ZONE_REVERSE) {
				setShowCheckBox(true);
			} else setShowCheckBox(false);
		}, [setOpen, setShowCheckBox, type]);

		const handleTypeChange = useCallback(
			(newType) => {
				setType(newType);
			},
			[setType],
		);

		const options = useMemo(
			() =>
				pickupCostOptions.map((option) => ({
					...option,
					label: t(
						`${tPath}.mainModal.tabs.main.mainSettings.optionsPickup.${option.key}`,
					),
				})),
			[t],
		);

		return (
			<StyledColumn gap="0.5rem 0" alignItems="start">
				<StyledRow>
					<Select
						disabled={disabled}
						style={{ width: "250px" }}
						options={options}
						value={type}
						onChange={handleTypeChange}
					/>
					{open && (
						<ButtonMap
							disabled={disabled}
							variant="secondary"
							icon={<Icon id="map" size={16} />}
						/>
					)}
				</StyledRow>
				{showCheckBox && (
					<CheckBox
						value={!considerSuburbanZone}
						onChange={onChangeConsiderSuburbanZone}
						label={
							t(
								`${tPath}.mainModal.tabs.main.mainSettings.actionZoneRevers`,
							) || "Exclude suburban tariff zones"
						}
					/>
				)}
			</StyledColumn>
		);
	},
);

declare namespace PickupCost {
	interface Props {
		value: Value;
		disabled: boolean;
		onChange: (value: Value) => void;
	}

	type Value = {
		type: PickupCostType;
		considerSuburbanZone: boolean;
	};
}

export default PickupCost;
