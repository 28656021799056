import React, { Dispatch } from "react";

import { StyledColumn } from "../../../../../../../../../../../../components/common";
import List from "../List";

const PopupContent: React.FC<PopupContent.Props> = ({ data, onSelect }) => (
	<StyledColumn w="330px" h={{ max: "400px" }} p="4px" overY="auto" scrollbar>
		<List items={data} onSelect={onSelect} />
	</StyledColumn>
);

declare namespace PopupContent {
	interface Props {
		data: List.Value;
		onSelect: Dispatch<List.Value[0]["value"]>;
	}
}

export default PopupContent;
