import { Option } from "uikit";

function createFileTypes<Value extends string>(options: Option<Value>[]) {
	return options;
}

export const fileTypes = createFileTypes([
	{
		key: "json",
		label: "JSON",
		value: "json",
	},
	{
		key: "csv",
		label: "CSV",
		value: "csv",
	},
]);

export default {
	fileTypes,
};
