/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */

import React, { useLayoutEffect } from "react";
import { RgbaColorPicker } from "react-colorful";
import colorConverter from "hex-and-rgba";
import styled from "styled-components";
import { theme } from "uikit";

import { SVGgeneral } from "../../utils/generalSprite";
import { Popup } from "../common";

const rgbaToObject = (rgba) => {
	const startBracket = rgba.indexOf("(");
	const endBracket = rgba.indexOf(")");

	if (startBracket === -1 || endBracket === -1) {
		return false;
	}

	const colors = rgba.slice(startBracket + 1, endBracket).split(", ");

	return { r: colors[0], g: colors[1], b: colors[2], a: colors[3] };
};

const positionToFlexDirection = (position) => {
	switch (position) {
		case "left":
			return "row";
		case "right":
			return "row-reverse";
		case "top":
			return "column";
		case "bottom":
			return "column-reverse";
		default:
			return "row";
	}
};

const ColorPickerContainer = styled.div`
	${({ disabled }) => (disabled ? "cursor: not-allowed;" : "")}

	.ColorPicker-input-wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		flex-direction: ${(props) =>
			positionToFlexDirection(props.config.labelPosition)};
	}

	.ColorPicker-label {
		font-family: ${theme.font.n};
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: var(--base-color-violet);
	}

	.ColorPicker-input {
		padding: 8px 10px;
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(33, 51, 63, 0.15);
		background: #ffffff;
		box-sizing: border-box;
		border-radius: 3px;
		fill: rgba(18, 21, 42, 0.6);

		.ColorPicker-color-box {
			width: 14px;
			height: 14px;
			border-radius: 3px;
			border: 1px solid var(--base-color-light2-gray);
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
		}
	}
`;

const ColorPickerModal = styled.div`
	.MuiPaper-root {
		overflow: hidden;
	}

	.ColorPicker-modal {
		width: 200px;
		overflow-y: none;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

		.ColorPicker-modal-content {
			.ColorPicker-color-picker {
				.react-colorful {
					height: 270px;
					width: 100%;
					background: #ffffff;
				}

				.react-colorful__hue,
				.react-colorful__alpha {
					margin: 0 15px 0 15px;
				}

				.react-colorful__saturation {
					margin-bottom: 25px;
					border-radius: 0;
					border-bottom: none;
				}

				.react-colorful__hue,
				.react-colorful__alpha {
					height: 8px;
					border-radius: 4px;
					margin-bottom: 24px;
				}
				.react-colorful__hue-pointer,
				.react-colorful__alpha-pointer {
					width: 15px;
					height: 15px;
				}
			}

			.ColorPicker-information {
				padding: 0 10px 10px 10px;
				.ColorPicker-information-block {
					display: flex;
					justify-content: space-between;

					.ColorPicker-information-block-title {
						color: var(--base-color-violet);
					}

					.ColorPicker-information-block-value {
						width: 70%;
						display: flex;
						justify-content: flex-end;
						color: var(--base-color-dark-violet);

						&.info-value-hex {
						}

						&.info-value-rgba {
							gap: 5px;
						}
					}
				}
			}
		}
	}
`;

export default function ColorPicker({
	disabled = false,
	label,
	labelPosition = "left",
	color,
	defaultColor,
	onChange,
	offset = undefined,
}) {
	// Convert any color format to rgba object
	const anyToColorObject = (color) => {
		if (typeof color === "string") {
			const isHex = color.includes("#");
			const isRGB = color.includes("rgb");

			if (isHex) return anyToColorObject(colorConverter.hexToRgba(color));
			if (isRGB) return rgbaToObject(color);

			return false;
		}

		if (Array.isArray(color)) {
			const [r, g, b, a] = color;
			return { r, g, b, a };
		}

		if (typeof color === "object") {
			return { r: 255, g: 255, b: 255, a: 1, ...color };
		}

		return false;
	};

	const [modalOpen, setModalOpen] = React.useState(false);
	const [currentColorObject, setCurrentColorObject] = React.useState(
		anyToColorObject(color || defaultColor),
	);

	useLayoutEffect(() => {
		setCurrentColorObject(anyToColorObject(color || defaultColor));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [color]);

	const offsetFn = ({ tracker, float }) => {
		if (offset) return offset;
		if (!tracker || !float) return { x: 0, y: 0 };
		const trackerSize = tracker.getSize();

		return {
			x: 0,
			y: Number(trackerSize.height) + 8,
		};
	};
	const onColorPicketChange = (color) => {
		const colorObject = anyToColorObject(color);

		if (Number.isNaN(color.a)) {
			colorObject.a = 1;
		}

		setCurrentColorObject(colorObject);
		onChange(
			colorConverter.rgbaToHex(
				colorObject.r,
				colorObject.g,
				colorObject.b,
				colorObject.a,
			),
		);
	};

	const toggleModalVisibility = () => {
		setModalOpen(!modalOpen);
	};

	const onInputClick = () => {
		toggleModalVisibility();
	};

	return (
		<Popup
			useClickControl
			offset={offsetFn}
			tracker={
				<ColorPickerContainer
					config={{ labelPosition }}
					disabled={disabled}
					className="ColorPicker-root"
				>
					<div className="ColorPicker-input-wrapper">
						{label && (
							<div className="ColorPicker-label">{label}</div>
						)}
						<button
							type="button"
							disabled={disabled}
							className="ColorPicker-input"
							onClick={onInputClick}
						>
							<div
								className="ColorPicker-color-box"
								style={{
									background: `rgba(${currentColorObject.r}, ${currentColorObject.g}, ${currentColorObject.b}, ${currentColorObject.a})`,
								}}
							/>
							<div className="ColorPicker-color-box-arrow">
								<SVGgeneral
									id="down-arrow"
									style={{
										transform: modalOpen
											? "rotate(180deg)"
											: "",
									}}
								/>
							</div>
						</button>
					</div>
				</ColorPickerContainer>
			}
		>
			<ColorPickerModal>
				<div className="ColorPicker-modal">
					<div className="ColorPicker-modal-content">
						<div className="ColorPicker-color-picker">
							<RgbaColorPicker
								color={currentColorObject}
								onChange={onColorPicketChange}
							/>
						</div>
						<div className="ColorPicker-information">
							<div className="ColorPicker-information-block">
								<div className="ColorPicker-information-block-title">
									hex
								</div>
								<div className="ColorPicker-information-block-value info-value-hex">
									{colorConverter.rgbaToHex(
										currentColorObject.r,
										currentColorObject.g,
										currentColorObject.b,
										currentColorObject.a,
									)}
								</div>
							</div>
							<div className="ColorPicker-information-block">
								<div className="ColorPicker-information-block-title">
									rgba
								</div>
								<div className="ColorPicker-information-block-value info-value-rgba">
									<div className="rgba-block">
										{currentColorObject.r}
									</div>
									<div className="rgba-block">
										{currentColorObject.g}
									</div>
									<div className="rgba-block">
										{currentColorObject.b}
									</div>
									<div className="rgba-block">
										{`${Math.round(
											currentColorObject.a * 100,
										)}%`}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ColorPickerModal>
		</Popup>
	);
}
