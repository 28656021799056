/* eslint-disable no-param-reassign */

import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
	globalSettings: boolean;
	orderSettings: boolean;
	orderDistributionSettings: boolean;
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const markLoaded: Reducer<keyof State> = (state, { payload }) => {
	state[payload] = true;
};

const initialState: State = {
	globalSettings: false,
	orderSettings: false,
	orderDistributionSettings: false,
};

const loading = createSlice({
	name: "loading",
	initialState,
	reducers: {
		markLoaded,
	},
});

export default loading;
