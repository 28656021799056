import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "../../../../../redux/store";
import {
	ordersAction as orders,
	Orders,
} from "../../../../../redux/reducers/Orders";
import useKeyBind from "../../../../../hooks/useKeyBind";
import Dispatcher from "../../../../../services/Dispatcher";
import Executor from "../../../../../services/Executor";
import { convertDataToFullname } from "../../../../SoftphoneWidget/components/Transfer/helper";
import { useChatActions, useFilteredExecutors } from "../../hooks";
import { ChatDispatcherPhone, ChatExecutorPhone } from "../../types";

import {
	Search,
	Table,
	Root,
	Header,
	NavBar,
	FooterButtons,
} from "./components";

const UsersModal: React.FC<UsersModal.Props> = ({ onClose }) => {
	const dispatch = useDispatch();
	const { onSubmitUsers } = useChatActions();

	const { userModalTab, booferSelectedUsers, userModalData } =
		useTypedSelector((state) => state.orders.chats.chat);

	const { filterExecutorsWrite } = useTypedSelector(
		(state) => state.orders.chats.filter,
	);

	const showedExecutorIds = useFilteredExecutors(
		userModalData.executors,
		filterExecutorsWrite.criteria,
	);

	const [tabIndex, setTabIndex] = useState(0);

	const { sipValue: userSipValue } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const dispatcherId = useTypedSelector((state) => state.account.user?.id);

	const convertDispatcher = useCallback(
		(dispatcher: Dispatcher.Model): ChatDispatcherPhone => ({
			id: dispatcher.id,
			alias: dispatcher?.alias || "",
			dispatchersAvailableSips: dispatcher?.dispatcherToSIPs
				? dispatcher.dispatcherToSIPs
						.map((sip) => sip?.sip?.value)
						.filter((sip) => sip !== userSipValue)
				: [],
			name: convertDataToFullname(dispatcher),
			phone: dispatcher?.additionalFields?.phones?.length
				? `${dispatcher.additionalFields?.phones[0]}`
				: "",
			type: "dispatcher",
			avatar: dispatcher?.personalFiles.length
				? dispatcher?.personalFiles[0]
				: null,
			parkNumber: "",
		}),
		[userSipValue],
	);
	const convertExecutor = useCallback(
		(executor: Executor.Model): ChatExecutorPhone => ({
			id: executor.id,
			alias: executor.alias,
			name: convertDataToFullname(executor),
			phone: executor?.person?.phones?.length
				? `${executor.person?.phones[0]?.number}`
				: "",
			type: "executor",
			avatar: executor?.avatarFiles?.length
				? executor?.avatarFiles[0]
				: null,
			parkNumber: executor.cars?.length
				? executor.cars[0].parkNumber
				: "",
		}),
		[],
	);

	const phoneListData = useMemo(() => {
		const dispatchersData =
			userModalData.dispatchers
				?.filter((dispatcher) => dispatcher.id !== dispatcherId)
				?.map((dispatcher) => convertDispatcher(dispatcher)) || [];

		const executorsData =
			showedExecutorIds?.map((executor) => convertExecutor(executor)) ||
			[];

		return {
			dispatchers: dispatchersData,
			executors: executorsData,
		};
	}, [
		convertDispatcher,
		convertExecutor,
		dispatcherId,
		userModalData.dispatchers,

		showedExecutorIds,
	]);

	const onSelectDispatcher = useCallback(
		(userId: number) => {
			const dispatcher = booferSelectedUsers.dispatchers.find(
				(dispatcher) => dispatcher.id === userId,
			);
			if (dispatcher) {
				dispatch(
					orders.chats.chat.removeDispatcherFromBoofer(dispatcher),
				);
			} else {
				const dispatcher = userModalData.dispatchers.find(
					(dispatcher) => dispatcher.id === userId,
				);
				if (dispatcher) {
					dispatch(
						orders.chats.chat.addDispatcherToBoofer(dispatcher),
					);
				}
			}
		},
		[booferSelectedUsers.dispatchers, dispatch, userModalData.dispatchers],
	);

	const onSelectDispatcherKeyboard = useCallback(
		(userId: number, event: "select" | "unselect") => {
			const dispatcher = booferSelectedUsers.dispatchers.find(
				(dispatcher) => dispatcher.id === userId,
			);
			if (event === "unselect" && dispatcher) {
				dispatch(
					orders.chats.chat.removeDispatcherFromBoofer(dispatcher),
				);
			}
			if (event === "select") {
				const dispatcher = userModalData.dispatchers.find(
					(dispatcher) => dispatcher.id === userId,
				);
				if (dispatcher) {
					dispatch(
						orders.chats.chat.addDispatcherToBoofer(dispatcher),
					);
				}
			}
		},
		[booferSelectedUsers.dispatchers, dispatch, userModalData.dispatchers],
	);

	const onSelectExecutor = useCallback(
		(userId: number) => {
			const executor = booferSelectedUsers.executors.find(
				(executor) => executor.id === userId,
			);
			if (executor) {
				dispatch(orders.chats.chat.removeExecutorFromBoofer(executor));
			} else {
				const executor = userModalData.executors.find(
					(executor) => executor.id === userId,
				);
				if (executor) {
					dispatch(orders.chats.chat.addExecutorToBoofer(executor));
				}
			}
		},
		[booferSelectedUsers.executors, dispatch, userModalData.executors],
	);

	const onSelectExecutorKeyboard = useCallback(
		(userId: number, event: "select" | "unselect") => {
			const executor = booferSelectedUsers.executors.find(
				(executor) => executor.id === userId,
			);
			if (executor && event === "unselect") {
				dispatch(orders.chats.chat.removeExecutorFromBoofer(executor));
			}
			if (event === "select") {
				const executor = userModalData.executors.find(
					(executor) => executor.id === userId,
				);
				if (executor) {
					dispatch(orders.chats.chat.addExecutorToBoofer(executor));
				}
			}
		},
		[booferSelectedUsers.executors, dispatch, userModalData.executors],
	);

	const toggleAllDispatchers = useCallback(
		(event?: "select" | "unselect") => {
			if (event === "select") {
				dispatch(
					orders.chats.chat.setDispatchersInBoofer(
						userModalData.dispatchers,
					),
				);
				return;
			}

			if (event === "unselect") {
				dispatch(orders.chats.chat.setDispatchersInBoofer([]));
				return;
			}

			if (
				booferSelectedUsers.dispatchers.length ===
				userModalData.dispatchers.length
			) {
				dispatch(orders.chats.chat.setDispatchersInBoofer([]));
			} else {
				dispatch(
					orders.chats.chat.setDispatchersInBoofer(
						userModalData.dispatchers,
					),
				);
			}
		},
		[
			booferSelectedUsers.dispatchers.length,
			dispatch,
			userModalData.dispatchers,
		],
	);

	const toggleAllExecutors = useCallback(
		(event?: "select" | "unselect") => {
			if (event === "select") {
				dispatch(
					orders.chats.chat.setExecutorsInBoofer(
						userModalData.executors,
					),
				);
				return;
			}

			if (event === "unselect") {
				dispatch(orders.chats.chat.setExecutorsInBoofer([]));
				return;
			}

			if (
				booferSelectedUsers.executors.length ===
				userModalData.executors.length
			) {
				dispatch(orders.chats.chat.setExecutorsInBoofer([]));
			} else {
				dispatch(
					orders.chats.chat.setExecutorsInBoofer(
						userModalData.executors,
					),
				);
			}
		},
		[
			booferSelectedUsers.executors.length,
			dispatch,
			userModalData.executors,
		],
	);

	const onCloseModal = useCallback(() => {
		dispatch(orders.chats.chat.resetUsersBoofer());
		onClose();
	}, [dispatch, onClose]);

	const selectTab = useCallback(
		(tab: Orders.Chat.UsersModalTabType) => {
			dispatch(orders.chats.chat.setUserModalTab(tab));
		},
		[dispatch],
	);

	useKeyBind(["Tab"], () => {
		if (tabIndex === 3) {
			setTabIndex(0);
		} else {
			setTabIndex((prev) => prev + 1);
		}
	});

	useKeyBind(["Shift", "Tab"], () => {
		if (tabIndex === 0) {
			setTabIndex(3);
		} else {
			setTabIndex((prev) => prev - 1);
		}
	});

	useKeyBind(["ArrowRight"], () => {
		if (tabIndex === 1) {
			if (userModalTab === "executors") selectTab("dispatchers");
			if (userModalTab === "dispatchers") selectTab("exchange");
		}
	});

	useKeyBind(["ArrowLeft"], () => {
		if (tabIndex === 1) {
			if (userModalTab === "exchange") selectTab("dispatchers");
			if (userModalTab === "dispatchers") selectTab("executors");
		}
	});

	useKeyBind(["Enter"], () => {
		onSubmitUsers();
	});

	useKeyBind(["Escape"], () => {
		onCloseModal();
	});

	const tableDataAndHandlers = useMemo(() => {
		if (userModalTab === "executors") {
			// console.log(
			// 	"[chat executors]  phoneListData.executors",
			// 	phoneListData.executors,
			// );
			// alias: "111";
			// avatar: null;
			// id: 6;
			// name: "Valera";
			// parkNumber: "999";
			// phone: "380000000001";
			// type: "executor";
			return {
				data: phoneListData.executors,
				onSelect: onSelectExecutor,
				onSelectKeyboard: onSelectExecutorKeyboard,
				toggleAll: toggleAllExecutors,
				type: "executors",
			};
		}

		if (userModalTab === "dispatchers") {
			return {
				data: phoneListData.dispatchers,
				onSelect: onSelectDispatcher,
				onSelectKeyboard: onSelectDispatcherKeyboard,
				toggleAll: toggleAllDispatchers,
				type: "dispatchers",
			};
		}

		if (userModalTab === "exchange") {
			return {
				data: [],
				onSelect: () => "" as any,
				onSelectKeyboard: () => "" as any,
				toggleAll: () => "" as any,
				type: "exchange",
			};
		}
		return {
			data: [],
			onSelect: () => "" as any,
			onSelectKeyboard: () => "" as any,
			toggleAll: () => "" as any,
			type: "none",
		};
	}, [
		onSelectDispatcher,
		onSelectDispatcherKeyboard,
		onSelectExecutor,
		onSelectExecutorKeyboard,
		phoneListData.dispatchers,
		phoneListData.executors,
		toggleAllDispatchers,
		toggleAllExecutors,
		userModalTab,
	]);

	const blurSearchBar = useCallback(() => {
		setTabIndex(-1);
	}, []);

	const focusSearchBar = useCallback(() => {
		setTabIndex(0);
	}, []);

	return (
		<Root sizes="auto*3 1fr auto" maxedHeight>
			<Header onClick={onCloseModal} />
			<Search
				tabIndex={tabIndex}
				onBlur={blurSearchBar}
				onFocus={focusSearchBar}
			/>
			<NavBar
				tabIndex={tabIndex}
				navigation={userModalTab}
				transferTab={userModalTab}
			/>
			<Table
				data={tableDataAndHandlers.data}
				onSelect={tableDataAndHandlers.onSelect}
				onSelectKeyboard={tableDataAndHandlers.onSelectKeyboard}
				toggleAll={tableDataAndHandlers.toggleAll}
				tabIndex={tabIndex}
				type={tableDataAndHandlers.type}
			/>
			<FooterButtons onSubmit={onSubmitUsers} onClose={onCloseModal} />
		</Root>
	);
};

declare namespace UsersModal {
	interface Props {
		onClose: () => void;
	}
}

export default UsersModal;
