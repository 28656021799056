import React, { memo, useCallback, useMemo } from "react";
import { Icon } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../../redux/store";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import {
	ListSelect,
	ListSelectMemo,
} from "../../../../../../../../components/Orders";
import {
	StyledWrapIcon,
	StyledColumn,
	Text,
	Style,
	StyledRow,
	StyledSpan,
	StyledP,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";
import { useOrderModalContext } from "../../../../../context";

const TaxiClass: React.FC<TaxiClass.Props> = ({ tab, form, disabled }) => {
	const { t } = useTranslation();
	const { nextElementById, language } = useOrderModalContext();

	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const { models: carClassModels } = useTypedSelector(
		(state) => state.preferencesReducer.carClasses.data,
	);

	const styles = useMemo<TaxiClass.Styles>(
		() => ({
			wrap: {
				justify: "center",
				alignItems: "space-between",
				w: "max-content",
				bgC: "transparent",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			icon: {
				w: "16px",
				h: "16px",
				colors: "#5e6b73",
			},
			arrow: {
				w: "16px",
				h: "16px",
				colors: "#5e6b73",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			text: {
				colors: disabled ? "#9BA3A8" : "#21333F",
			},
		}),
		[disabled],
	);

	const rateId = useMemo(() => tab?.form?.rateId, [tab?.form?.rateId]);

	const orderServices = useMemo(
		() => tab?.form?.services || [],
		[tab?.form?.services],
	);

	const rateCarClassIds = useMemo<number[]>(() => {
		const rate =
			rates?.find((item) => item.id === rateId) ||
			rates?.find((item) => item.root === true) ||
			rates?.at(0);
		return rate?.carClassIds || [];
	}, [rateId, rates]);

	const companyID = useMemo(
		() => tab?.form?.companyID,
		[tab?.form?.companyID],
	);

	const taxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);

	const taxiClass = useMemo(
		() => tab?.form?.taxiClass,
		[tab?.form?.taxiClass],
	);

	const carClassModalOptions = useMemo<ListSelect.Options>(() => {
		const tabClasses = carClassModels.map((item) => {
			const {
				active,
				taxiServices,
				// id
			} = item;

			const isClass = taxiServices?.some(
				(taxiService) =>
					taxiService?.id === taxiServiceId &&
					taxiService?.company?.id === companyID,
			);

			/** Car class filter by rates */
			// const isTariff = isClass && active && rateCarClassIds.includes(id);
			const isTariff = isClass && active;

			return { ...item, isTariff };
		});

		return tabClasses.map((item) => ({
			value: item.id,
			name: item.name?.[language],
			active: item?.isTariff,
		}));
	}, [carClassModels, companyID, taxiServiceId, language, rateCarClassIds]);

	const title = useMemo(() => t(["car_classes", "Car class"]), [t]);
	const value = useMemo(
		() =>
			taxiClass
				? taxiClass?.map((item) => item?.name).join(", ")
				: t("none"),
		[t, taxiClass],
	);

	const onIsTaxiClass = useCallback(
		(classes: boolean) => {
			if (!disabled) {
				tab.setState((prev) => ({
					...prev,
					modals: { ...prev.modals, classes },
				}));
			}
		},
		[disabled, tab],
	);

	const changedRateId = useCallback(
		(carClasses: { value: number; name: string }[]) => {
			const carClassIds = carClasses.map((item) => item.value);

			const foundRates = rates.filter((rate) => {
				if (rate.root) return false;

				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const taxi = rate.taxiServiceIds.includes(taxiServiceId);
				const company = rate.taxiServices.find((service) => {
					if (service.company?.id === companyID) return true;
					return false;
				});

				if (taxi && company && cars) return true;
				return false;
			});

			let rateId: number | undefined = foundRates?.at(0)?.id;

			if (!rateId) {
				rateId = rates?.find((item) => item.root === true)?.id;
			}

			form.setValue("rateId", rateId || 1);
		},
		[companyID, rates, form, taxiServiceId],
	);

	const changedServices = useCallback(
		(carClasses: { value: number; name: string }[]) => {
			const serviceIdsForSelectedCarClasses = carClassModels
				?.filter((item) =>
					carClasses?.some(
						(selectedCarClass) =>
							selectedCarClass.value === item.id,
					),
				)
				?.map(({ serviceAvailableIds }) => serviceAvailableIds || [])
				?.flat();

			const modifyServices = orderServices?.filter((item) =>
				serviceIdsForSelectedCarClasses.includes(item.value),
			);

			form.setValue("services", modifyServices);
		},
		[carClassModels, form, orderServices],
	);

	const onClassOptionSelect = useCallback(
		(options) => {
			onIsTaxiClass(false);

			if (!options.length) return;

			const classes = options.map((item) => ({
				value: item.value,
				name: item.name,
			}));

			changedRateId(classes);
			changedServices(classes);
			form.setValue("taxiClass", classes);
			form.setValue("orderSave", true);
			form.setValue("orderPointSave", false);
			nextElementById("customer-phone-id");
		},
		[changedRateId, form, onIsTaxiClass, changedServices, nextElementById],
	);

	const onClickTaxiClass = useCallback(() => {
		const isOpen = tab.state.modals.classes;
		if (!disabled && !isOpen) onIsTaxiClass(true);
		if (isOpen) onIsTaxiClass(false);
	}, [disabled, onIsTaxiClass, tab.state?.modals?.classes]);

	useKeyBindSettings("orderModalTaxiClassModal", (event) => {
		if (!disabled) {
			onClickTaxiClass();
		}
		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<>
			<StyledRow {...styles.wrap} onClick={onClickTaxiClass}>
				<StyledSpan {...styles.text}>
					<StyledWrapIcon {...styles.icon}>
						<Icon id="order-modal-tariff" size={16} />
					</StyledWrapIcon>
				</StyledSpan>
				<StyledRow alignItems="center">
					<StyledP
						colors={disabled ? "#9BA3A8" : "#21333F"}
						over="hidden"
						textAlign="left"
						w={{ max: "100px" }}
						dis="inline-block"
						text={{
							over: "ellipsis",
							ws: "nowrap",
							td: "none",
						}}
						p="0 5px"
						m="auto 0 0 0"
					>
						{value}
					</StyledP>
				</StyledRow>
				<StyledSpan>
					<StyledWrapIcon {...styles.arrow}>
						<Icon id="arrow-select-down" size={16} />
					</StyledWrapIcon>
				</StyledSpan>
			</StyledRow>
			{tab.state.modals.classes && (
				<ListSelectMemo
					hiddenButton={false}
					pullUpItemInsideArray
					required
					headerDivider={false}
					title={title}
					value={taxiClass}
					options={carClassModalOptions}
					onSubmit={onClassOptionSelect}
					onClose={onClickTaxiClass}
					selectOnlyOne
				/>
			)}
		</>
	);
};

declare namespace TaxiClass {
	interface Props extends OrderFormProps {}

	interface Styles {
		wrap: Style.FlexType;
		icon: Style.FlexType;
		arrow: Style.FlexType;
		text: Style.TextType;
	}
}
export const TaxiClassMemo = memo(TaxiClass);
export default TaxiClass;
