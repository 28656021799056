import { Dispatch, SetStateAction } from "react";
import { react } from "uikit";

import IgnoreExecutors from ".";

interface Context {
	value: IgnoreExecutors.Value;
	optionsLength: number;
	setStatusesError: Dispatch<SetStateAction<boolean>>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const optionsLength = this.context?.optionsLength;
		const currentStatusesLength = this.context?.value.statuses.length;

		let isIgnoreExecutorsStatusesValid = true;
		if (optionsLength && currentStatusesLength) {
			isIgnoreExecutorsStatusesValid =
				currentStatusesLength < optionsLength;
			if (!isIgnoreExecutorsStatusesValid)
				this.context?.setStatusesError(true);
		}

		return isIgnoreExecutorsStatusesValid;
	}
}
