import { LatLngBoundsLiteral, LatLngExpression, LatLngLiteral } from "leaflet";

import { GetExecutorLocationsHistoryFilters } from "../../../services/Executor/getExecutorTrack";
import { DateRange, DateRangeLiteral } from "../../../../types/DataRange";
import { Archive } from "../interface";

export interface Point {
	order: boolean;
	points: LatLngLiteral[];
}
export interface GPSHistoryFilters {
	carNumber?: string;
	executorCallSign?: string;
	carRegistrationNumber?: string;
	dateRange?: DateRange | DateRangeLiteral;
	taxiServiceIds?: number[];
	sectorIds?: number[];
	executorIds?: number[];
	near?: {
		point?: { lat: number; lng: number };
		radius?: number;
	};
}

export const ConstantSegmentHistoryOrderStatus = {
	/** order accepted by the contractor. */
	ACCEPTED: "accepted",
	/** the driver arrived on the scene */
	ARRIVED: "arrived",
	/** the driver waiting for a client */
	WAITING_CLIENT: "waiting_client",
	/** driver with clients */
	WITH_CLIENTS: "with_clients",
	/** driver executed the order */
	EXECUTED: "executed",
	/** driver is delayed. */
	DELAYED: "delayed",
} as const;

export type TypeSegmentHistoryOrderStatus =
	typeof ConstantSegmentHistoryOrderStatus;

export type SegmentHistoryOrderStatus =
	(typeof ConstantSegmentHistoryOrderStatus)[keyof typeof ConstantSegmentHistoryOrderStatus];

export const ConstantSegmentHistoryStatusExecutor = {
	AVAILABLE: "available",
	BUSY: "busy",
	DINNER: "dinner",
	HOME: "home",
	ON_ORDER: "on_order",
	CLOSED: "closed",
} as const;

export type TypeSegmentHistoryStatusExecutor =
	typeof ConstantSegmentHistoryStatusExecutor;

export type SegmentHistoryStatusExecutor =
	(typeof ConstantSegmentHistoryStatusExecutor)[keyof typeof ConstantSegmentHistoryStatusExecutor];
export interface SegmentHistoryOrder {
	id: number;
	executingStage: SegmentHistoryOrderStatus;
}
export interface SegmentHistoryExecutor {
	id: number;
	status: SegmentHistoryStatusExecutor;
}
export interface SegmentHistoryCar {
	id: number;
	callSign: string;
	registrationNumber: string;
}

export interface SegmentHistory {
	order?: SegmentHistoryOrder;
	car?: SegmentHistoryCar;
	carNumber: string;
	carId: number;
	executor: SegmentHistoryExecutor;
	accuracy: number;
	altitude: number;
	date: number;
	direction: LatLngLiteral;
	executorCallSign: string;
	executorId: number;
	point: LatLngLiteral;
	speed: number;
	taxiServiceId: number;
}

export interface CountersHistory {
	distances: {
		total: number;
		order: number;
		pickup: number;
		available: number;
	};
}
export interface LocationHistory {
	id: number;
	executorCallSign: string;
	carNumber: string;
	segments: SegmentHistory[][];
	counters: CountersHistory;
}

export interface DistanceHistory
	extends CountersHistory,
		Pick<LocationHistory, "id" | "executorCallSign"> {}

export const ConstantColors = {
	BLUE: "#0000ff",
	RED: "#ff0000",
	YELLOW: "#ffff00",
	GREEN: "#008000",
	ORANGE: "#ffa500",
	ORANGE_RED: "#ff4500",
	GOLDENROD: "#daa520",
} as const;
export type TypeColors = typeof ConstantColors;
export type ValueColors = (typeof ConstantColors)[keyof typeof ConstantColors];

export interface PointItems {
	keyPoint: string;
	center: LatLngLiteral;
	radius: number;
	lines: [string, string][];
	date: number;
	color: ValueColors;
	deg: number;
}

export interface TrackStartEnd {
	point: LatLngLiteral;
	lines: [string, string][];
	startTimestamp: boolean;
	endTimestamp: boolean;
}

export interface Track {
	startTimestamp: number;
	endTimestamp: number;
	durationMS: number;
	data: TrackData;
}

export interface TrackData {
	executorCallSign: string;
	id: number;
	segments: TrackPoint[][];
}

export interface TrackPoint {
	carCallSign: string;
	carId: number;
	carNumber: string;

	executorCallSign: string;
	executorId: number;

	date: number;

	accuracy: number;
	point: LatLngLiteral;
	direction: LatLngLiteral;
	altitude: number;
	speed: number;

	taxiServiceId: number[];
}

export interface HistoryPlayer extends Archive.Player {
	max: number;
	deg: number;
	run: boolean;
}

export interface State {
	loading: boolean;
	callsignExists?: boolean;
	track?: Track;

	filters: GetExecutorLocationsHistoryFilters | GPSHistoryFilters;
	player: HistoryPlayer;
	history: {
		items: LocationHistory[];
		error: string | null;
		isLoading: boolean;
		positions: Point[];
		mapBounds: LatLngBoundsLiteral | null;
		centroid: LatLngExpression;
		trackStartAndEnd: TrackStartEnd[];
		circleMarkerItems: PointItems[][];
		radius: number;
		distances: DistanceHistory[];
	};
}
