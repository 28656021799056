import React from "react";
import { Row } from "uikit";
import { useTranslation } from "react-i18next";
import Title from "./components/Title";
import Root from "./components/Root";
import translationPath from "../../../../constants/translationPath";

const Header: React.FC<Header.Props> = ({ title, createdAt }) => {
	const { t } = useTranslation();

	return (
		<Root sizes="auto!*">
			<Row
				style={{ padding: "16px 20px" }}
				align="center"
				justify="space-between"
				gaps="10px"
			>
				<Title>{title}</Title>
				<Row gaps="7px">
					<span>{t(`${translationPath}.modal.createdAt`)}:</span>
					<span style={{ color: "black", fontWeight: "600" }}>
						{createdAt}
					</span>
				</Row>
			</Row>
		</Root>
	);
};

declare namespace Header {
	interface Props {
		title: string;
		createdAt?: string;
	}
}

export default Header;
