import { useMemo } from "react";

function useURLParams() {
	return useMemo(() => {
		// useParams returns empty object

		const { href } = window.location;
		const pos = href.indexOf("?");

		if (pos !== -1) {
			const params = href.substring(pos);
			return new URLSearchParams(params);
		}

		return new URLSearchParams();
	}, []);
}

export default useURLParams;
