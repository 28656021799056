import { BaseRateAdjustmentType } from "../../../../../../../services/AutoTariff/enums";
import {
	RateAdjustmentToDefault,
	RateBinaryAdjustmentToDefault,
	PickupCostType,
	Units,
} from "../../../constants/constants";
import Modal from "../components/Modal";

const defaultValue: Modal.Value = {
	name: "",
	active: true,
	disableOthers: false,
	rules: [],
	additionalFields: {
		general: {
			ignoreCoefficient: false,
			surchargeToOrderSum: {
				active: false,
				type: BaseRateAdjustmentType.SURCHARGE_TO_ORDER_SUM,
				value: 0,
			},
			boardingCost: {
				value: 100,
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
			},
			minOrderCost: {
				value: 100,
				initialKm: 0,
				strict: true,
				adjustments: {
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					initialKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			minHourlyRate: {
				value: 100,
				minutes: 0,
				initialKm: 0,
				appliesToEntireRoute: false,
				customSettlementCostPerKm: 0, // Custom value for cost per km in the city
				customSuburbanCostPerKm: 0, // Custom value for cost per km outside the city
				adjustments: {
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					minutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
					initialKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
					appliesToEntireRoute:
						RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			idleMinuteCost: {
				value: 100,
				active: false,
				adjustments: {
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					freeMinutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
				freeMinutes: 0,
			},
			rideMinuteCost: {
				type: "fixed",
				value: 100,
				active: false,
				adjustments: {
					type: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					freeMinutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
				freeMinutes: 0,
			},
			hourlyServiceCost: {
				value: 100,
				adjustments: {
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					additionalKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
				additionalKm: 0,
			},
			suburbanCostPerKm: {
				value: 100,
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
			},
			waitingMinuteCost: {
				value: 100,
				active: false,
				adjustments: {
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					freeMinutes: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
				freeMinutes: 0,
			},
			roundTripCostPerKm: {
				value: 100,
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
			},
			suburbanPickupCost: {
				value: 100,
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
			},
			settlementCostPerKm: {
				value: 100,
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
			},
			settlementPickupCost: {
				value: 100,
				adjustmentType:
					RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
			},
			pickupCostCalculation: {
				type: PickupCostType.NEAREST,
				considerSuburbanZone: true,
			},
			hourlyServiceInterval: {
				units: Units.HOURS,
				value: 0,
				active: true,
				adjustments: {
					value: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
			minPreliminaryOrderCost: {
				value: 100,
				initialKm: 0,
				strict: true,
				adjustments: {
					value: RateAdjustmentToDefault.COEFFICIENT_TO_DEFAULT_RATE,
					initialKm: RateBinaryAdjustmentToDefault.DEFAULT_VALUE,
				},
			},
		},
	},
	taxiServiceIds: [],
	taxiServices: [],
};

export default defaultValue;
