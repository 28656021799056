import getOptions from "../../../../../../../../../utils/generateOptions";

// eslint-disable-next-line no-shadow
export enum AdditionalTariffTabs {
	MAIN = "main",
	HISTORY = "history",
}

const tabs = getOptions(Object.values(AdditionalTariffTabs));

export default tabs;
