import React, { useMemo, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import { useGetRating } from "../../../../../../../../hooks/useGetRating";
import {
	StyledRow,
	StyledSpan,
	Text,
	Star,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";
import {
	StyledPreWrap,
	StyledAccentText,
	StyledSuccess,
	StyledFailed,
	StyledTrustLevel,
} from "../styled";

const Right: React.FC<OrderFormProps> = ({ tab }) => {
	const { t } = useTranslation();
	const { get, response } = useGetRating();
	const { form } = tab;

	useLayoutEffect(() => {
		const id = tab?.state?.customerId;
		if (id) get(id, "customer");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab?.state?.customerId]);

	const failed = useMemo(() => {
		const cancelled = form?.counterRideCancelled || 0;
		const fail = form?.counterRideFailed || 0;
		return cancelled + fail;
	}, [form?.counterRideCancelled, form?.counterRideFailed]);

	const success = useMemo(
		() => form?.counterRideSuccess || 0,
		[form?.counterRideSuccess],
	);
	const rideNumber = useMemo(() => {
		if (form.status === "creating") {
			return form?.rideNumber ? form.rideNumber + 1 : 1;
		}

		return form?.rideNumber ? form.rideNumber : 1;
	}, [form?.rideNumber, form.status]);

	const total = useMemo(() => failed + success, [failed, success]);

	const trustLevel = useMemo(() => {
		const rating = response || 5;
		return rating;
	}, [response]);

	return (
		<StyledRow
			gap="11px 17px"
			alignItems="center"
			h="clamp(26px, 3.6vh, 32px)"
			over="visible"
		>
			<StyledPreWrap>
				<StyledRow alignItems="center">
					<Text
						text={`${t([
							`orderPage.order_form.current_ride_number`,
						])}: `}
						value={rideNumber}
						valueStyle={{ colors: "#03A9F4" }}
						textStyle={{
							text: { ws: "nowrap" },
						}}
					/>
				</StyledRow>
			</StyledPreWrap>

			<StyledPreWrap>
				<StyledRow alignItems="center">
					<StyledSpan text={{ ws: "nowrap" }} p="0 5px 0 0">
						{`${t([`orderPage.order_form.total_rides`])}: `}
					</StyledSpan>
					<StyledAccentText>{total}</StyledAccentText>
					<StyledTrustLevel>/</StyledTrustLevel>
					<StyledSuccess>{success}</StyledSuccess>
					<StyledTrustLevel>/</StyledTrustLevel>
					<StyledFailed>{failed}</StyledFailed>
					<StyledRow m="0 0 0 10px" w="90px" h="20px">
						<StyledRow w="90px" h="16px">
							<Star value={trustLevel} starNumber={1} />
							<Star value={trustLevel} starNumber={2} />
							<Star value={trustLevel} starNumber={3} />
							<Star value={trustLevel} starNumber={4} />
							<Star value={trustLevel} starNumber={5} />
						</StyledRow>
						<StyledTrustLevel colors="#21333F">
							{trustLevel}
						</StyledTrustLevel>
					</StyledRow>
				</StyledRow>
			</StyledPreWrap>
		</StyledRow>
	);
};

export default Right;
