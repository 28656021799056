/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBoolean, isNil, isNumber, noop } from "lodash";
import { Column, Icon, Nullable, Row } from "uikit";

import CarClass from "../../../../../../services/CarClass";
import CarDescription from "../../../../../../services/CarDescription";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import DeleteModal from "../../../../../../components/DeleteModal";
import DefaultPageHeaderButton from "../../../../../../components/DefaultPageHeaderButton";
import MultiSelectModal from "../../../../../../components/MultiSelectModal";

import useCarBaseTypeModelList from "./hooks/useCarBaseTypeModelList";
import useCarBrandModelList from "./hooks/useCarBrandModelList";
import useCarModelModelList from "./hooks/useCarModelModelList";
import useCarBodyTypeModelList from "./hooks/useCarBodyTypeModelList";
import useCarClassModelList from "./hooks/useCarClassModelList";
import Header from "./components/Header";
import ModelList from "./components/ModelList";
import Divider from "./components/Divider";
import EditModal from "./components/EditModal";

function Cars() {
	const { t } = useTranslation();

	const [filters, setFilters] = useState<Header.Value>({
		search: "",
		searchType: "car-class",
	});
	const [item, setItem] = useState<EditModal.Value | null>(null);
	const [carBaseTypeToDelete, setCarBaseTypeToDelete] =
		useState<Nullable<number>>(null);
	const [carBrandToDelete, setCarBrandToDelete] =
		useState<Nullable<number>>(null);
	const [carModelToDelete, setCarModelToDelete] =
		useState<Nullable<number>>(null);
	const [carBodyTypeToDelete, setCarBodyTypeToDelete] =
		useState<Nullable<number>>(null);
	const [carClassIdToDelete, setCarClassToDelete] =
		useState<Nullable<number>>(null);
	const [showCarClassSelectModal, setShowCarClassSelectModal] =
		useState(false);

	const carBaseTypeModelList = useCarBaseTypeModelList({
		search:
			filters.searchType === "car-base-type" ? filters.search : undefined,

		active: filters.active,
		native: filters.native,
	});

	const carBrandModelList = useCarBrandModelList({
		search: filters.searchType === "car-brand" ? filters.search : undefined,

		active: filters.active,
		favorite: filters.favorite,
		native: filters.native,

		isSelectedCarBaseTypeActive: carBaseTypeModelList.isSelectedActive,

		selectedCarBaseType: carBaseTypeModelList.selected,
	});

	const carModelModelList = useCarModelModelList({
		search: filters.searchType === "car-model" ? filters.search : undefined,

		active: filters.active,
		favorite: filters.favorite,
		native: filters.native,

		isSelectedCarBaseTypeActive: carBaseTypeModelList.isSelectedActive,
		isSelectedCarBrandActive: carBrandModelList.isSelectedActive,

		selectedCarBaseTypeId: carBaseTypeModelList.selected,
		selectedCarBrandId: carBrandModelList.selected,
	});

	const carBodyTypeModelList = useCarBodyTypeModelList({
		search:
			filters.searchType === "car-body-type" ? filters.search : undefined,

		active: filters.active,
		native: filters.native,

		isSelectedCarBaseTypeActive: carBaseTypeModelList.isSelectedActive,
		isSelectedCarBrandActive: carBrandModelList.isSelectedActive,
		isSelectedCarModelActive: carModelModelList.isSelectedActive,

		selectedCarModelId: carModelModelList.selected,
	});

	const carClassModelList = useCarClassModelList({
		search: filters.searchType === "car-class" ? filters.search : undefined,

		selectedCarModelId: carModelModelList.selected,
		selectedCarBodyTypeId: carBodyTypeModelList.selected,
	});

	const canDelete = useMemo(() => {
		if (isNumber(carBodyTypeModelList.selected)) {
			return !carBodyTypeModelList.isSelectedNative;
		}

		if (isNumber(carModelModelList.selected)) {
			return !carModelModelList.isSelectedNative;
		}

		if (isNumber(carBrandModelList.selected)) {
			return !carBrandModelList.isSelectedNative;
		}

		if (isNumber(carBaseTypeModelList.selected)) {
			return !carBaseTypeModelList.isSelectedNative;
		}

		return false;
	}, [
		carBaseTypeModelList.isSelectedNative,
		carBaseTypeModelList.selected,
		carBodyTypeModelList.isSelectedNative,
		carBodyTypeModelList.selected,
		carBrandModelList.isSelectedNative,
		carBrandModelList.selected,
		carModelModelList.isSelectedNative,
		carModelModelList.selected,
	]);

	useLayoutEffect(() => {
		if (!isBoolean(filters.active)) return;

		if (carBaseTypeModelList.isSelectedActive !== filters.active) {
			carBaseTypeModelList.setSelected(null);
			carBrandModelList.setSelected(null);
			carModelModelList.setSelected(null);
			carBodyTypeModelList.setSelected(null);
		} else if (carBrandModelList.isSelectedActive !== filters.active) {
			carBrandModelList.setSelected(null);
			carModelModelList.setSelected(null);
			carBodyTypeModelList.setSelected(null);
		} else if (carModelModelList.isSelectedActive !== filters.active) {
			carModelModelList.setSelected(null);
			carBodyTypeModelList.setSelected(null);
		} else if (carBodyTypeModelList.isSelectedActive !== filters.active) {
			carBodyTypeModelList.setSelected(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filters.active,
		carBaseTypeModelList.isSelectedActive,
		carBrandModelList.isSelectedActive,
		carModelModelList.isSelectedActive,
		carBodyTypeModelList.isSelectedActive,
	]);

	const carBaseTypeModelListOnChangeSelected = useCallback(
		(newSelectedCarBaseType: Nullable<number>) => {
			if (carBaseTypeModelList.selected === newSelectedCarBaseType) {
				return;
			}

			carBaseTypeModelList.setSelected(newSelectedCarBaseType);
			carBrandModelList.setSelected(null);
			carModelModelList.setSelected(null);
			carBodyTypeModelList.setSelected(null);
		},
		[
			carBaseTypeModelList,
			carBodyTypeModelList,
			carBrandModelList,
			carModelModelList,
		],
	);

	const carBrandModelListOnChangeSelected = useCallback(
		(newSelectedCarBrand: Nullable<number>) => {
			if (carBrandModelList.selected === newSelectedCarBrand) {
				return;
			}

			carBrandModelList.setSelected(newSelectedCarBrand);
			carModelModelList.setSelected(null);
			carBodyTypeModelList.setSelected(null);
		},
		[carBodyTypeModelList, carBrandModelList, carModelModelList],
	);

	const carModelModelListOnChangeSelected = useCallback(
		(newSelectedCarModel: Nullable<number>) => {
			if (carModelModelList.selected === newSelectedCarModel) {
				return;
			}

			carModelModelList.setSelected(newSelectedCarModel);
			carBodyTypeModelList.setSelected(null);
		},
		[carBodyTypeModelList, carModelModelList],
	);

	const carBodyTypeModelListOnChangeSelected = useCallback(
		(newSelectedCarBodyType: Nullable<number>) => {
			if (carBodyTypeModelList.selected === newSelectedCarBodyType) {
				return;
			}

			carBodyTypeModelList.setSelected(newSelectedCarBodyType);
		},
		[carBodyTypeModelList],
	);

	// const carBaseTypeModelListOnDelete = useCallback((id: number) => {
	// 	setCarBaseTypeToDelete(id);
	// }, []);

	// const carBrandModelListOnDelete = useCallback((id: number) => {
	// 	setCarBrandToDelete(id);
	// }, []);

	// const carModelModelListOnDelete = useCallback((id: number) => {
	// 	setCarModelToDelete(id);
	// }, []);

	// const carBodyTypeModelListOnDelete = useCallback((id: number) => {
	// 	setCarBodyTypeToDelete(id);
	// }, []);

	const carClassModelListOnDelete = useCallback((id: number) => {
		setCarClassToDelete(id);
	}, []);

	const carClassModelListOnEdit = useCallback(() => {
		setShowCarClassSelectModal(true);
	}, []);

	const deleteCarBaseTypeModalOnCancel = useCallback(() => {
		setCarBaseTypeToDelete(null);
	}, []);

	const deleteCarBaseTypeModalOnConfirm = useCallback(async () => {
		await carBaseTypeModelList.onDelete(carBaseTypeToDelete!);

		setCarBaseTypeToDelete(null);
	}, [carBaseTypeModelList, carBaseTypeToDelete]);

	const deleteCarBrandModalOnCancel = useCallback(() => {
		setCarBrandToDelete(null);
	}, []);

	const deleteCarBrandModalOnConfirm = useCallback(async () => {
		await carBrandModelList.onDelete(carBrandToDelete!);

		setCarBrandToDelete(null);
	}, [carBrandModelList, carBrandToDelete]);

	const deleteCarModelModalOnCancel = useCallback(() => {
		setCarModelToDelete(null);
	}, []);

	const deleteCarModelModalOnConfirm = useCallback(async () => {
		await carModelModelList.onDelete(carModelToDelete!);

		setCarModelToDelete(null);
	}, [carModelModelList, carModelToDelete]);

	const deleteCarBodyTypeModalOnCancel = useCallback(() => {
		setCarBodyTypeToDelete(null);
	}, []);

	const deleteCarBodyTypeModalOnConfirm = useCallback(async () => {
		await carBodyTypeModelList.onDelete(carBodyTypeToDelete!);

		setCarBodyTypeToDelete(null);
	}, [carBodyTypeModelList, carBodyTypeToDelete]);

	const deleteCarClassModalOnCancel = useCallback(() => {
		setCarClassToDelete(null);
	}, []);

	const deleteCarClassModalOnConfirm = useCallback(async () => {
		setCarClassToDelete(null);

		const carModelId = carModelModelList.selected;
		const carBodyTypeId = carBodyTypeModelList.selected;

		if (
			isNil(carModelId) ||
			isNil(carBodyTypeId) ||
			isNil(carClassIdToDelete)
		)
			return;

		await CarClass.unlinkToModelAndBodyType(
			carModelId,
			carBodyTypeId,
			carClassIdToDelete,
		);
	}, [
		carBodyTypeModelList.selected,
		carClassIdToDelete,
		carModelModelList.selected,
	]);

	const carClassData = useModelSubscribe({}, CarClass);

	const carClassSelectValue = useMemo(
		() => carClassModelList.items.map((item) => item.id),
		[carClassModelList.items],
	);

	const carClassSelectModalOptions = useMemo<Option<number>[]>(
		() =>
			carClassData.models.map((model) => ({
				key: model.id,
				label: model.name.en,
				value: model.id,
			})),
		[carClassData.models],
	);

	const carClassSelectModalOnSubmit = useCallback(
		async (ids: number[]) => {
			setShowCarClassSelectModal(false);

			const carModelId = carModelModelList.selected;
			const carBodyTypeId = carBodyTypeModelList.selected;

			if (isNil(carModelId) || isNil(carBodyTypeId)) return;

			await CarClass.relinkToModelAndBodyType(
				carModelId,
				carBodyTypeId,
				ids,
			);
		},
		[carBodyTypeModelList.selected, carModelModelList.selected],
	);

	const carClassSelectModalOnClose = useCallback(() => {
		setShowCarClassSelectModal(false);
	}, []);

	const headerOnAdd = useCallback(() => {
		setItem({
			carBaseTypeName: "",
			carBrandName: "",
			carModelName: "",
			carBodyTypeName: "",
			carClassIds: [],
		});
	}, []);

	const headerOnDelete = useCallback(() => {
		if (isNumber(carBodyTypeModelList.selected)) {
			setCarBodyTypeToDelete(carBodyTypeModelList.selected);

			return;
		}

		if (isNumber(carModelModelList.selected)) {
			setCarModelToDelete(carModelModelList.selected);

			return;
		}

		if (isNumber(carBrandModelList.selected)) {
			setCarBrandToDelete(carBrandModelList.selected);

			return;
		}

		if (isNumber(carBaseTypeModelList.selected)) {
			setCarBaseTypeToDelete(carBaseTypeModelList.selected);
		}
	}, [
		carBaseTypeModelList.selected,
		carBodyTypeModelList.selected,
		carBrandModelList.selected,
		carModelModelList.selected,
	]);

	const editModalOnCancel = useCallback(() => {
		setItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (item: EditModal.Value.Validated) => {
			await CarDescription.addRoute(item);

			setItem(null);
		},
		[],
	);

	return (
		<Column
			sizes="auto! 1fr"
			gaps="16px"
			maxedWidth
			maxedHeight
			style={{ paddingTop: 16, overflow: "hidden" }}
		>
			<Header
				value={filters}
				canDelete={canDelete}
				onChange={setFilters}
				onAdd={headerOnAdd}
				onDelete={headerOnDelete}
			/>
			<Row
				sizes="1fr auto! 1fr auto! 1fr auto! 1fr auto! 1fr"
				style={{ overflow: "hidden" }}
			>
				<ModelList
					selected={carBaseTypeModelList.selected}
					offset={carBaseTypeModelList.offset}
					count={carBaseTypeModelList.total}
					sort={carBaseTypeModelList.sort}
					title={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str0",
						) ?? ""
					}
					items={carBaseTypeModelList.items}
					onChangeSelected={carBaseTypeModelListOnChangeSelected}
					onOffsetChange={carBaseTypeModelList.setOffset}
					onChunkSizeChange={carBaseTypeModelList.setChunkSize}
					onChangeSort={carBaseTypeModelList.setSort}
					onChangeActive={carBaseTypeModelList.onChangeActive}
				/>
				<Divider />
				<ModelList
					selected={carBrandModelList.selected}
					offset={carBrandModelList.offset}
					count={carBrandModelList.total}
					sort={carBrandModelList.sort}
					title={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str1",
						) ?? ""
					}
					overlayLabel={
						carBaseTypeModelList.selected
							? undefined
							: t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.str200",
							  ) ?? ""
					}
					items={carBrandModelList.items}
					onChangeSelected={carBrandModelListOnChangeSelected}
					onOffsetChange={carBrandModelList.setOffset}
					onChunkSizeChange={carBrandModelList.setChunkSize}
					onChangeSort={carBrandModelList.setSort}
					onChangeActive={carBrandModelList.onChangeActive}
					onChangeFavorite={carBrandModelList.onChangeFavorite}
				/>
				<Divider />
				<ModelList
					selected={carModelModelList.selected}
					offset={carModelModelList.offset}
					count={carModelModelList.total}
					sort={carModelModelList.sort}
					title={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str2",
						) ?? ""
					}
					overlayLabel={
						carBrandModelList.selected
							? undefined
							: t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.str201",
							  ) ?? ""
					}
					items={carModelModelList.items}
					onChangeSelected={carModelModelListOnChangeSelected}
					onOffsetChange={carModelModelList.setOffset}
					onChunkSizeChange={carModelModelList.setChunkSize}
					onChangeSort={carModelModelList.setSort}
					onChangeActive={carModelModelList.onChangeActive}
					onChangeFavorite={carModelModelList.onChangeFavorite}
				/>
				<Divider />
				<ModelList
					selected={carBodyTypeModelList.selected}
					offset={carBodyTypeModelList.offset}
					count={carBodyTypeModelList.total}
					sort={carBodyTypeModelList.sort}
					title={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str3",
						) ?? ""
					}
					overlayLabel={
						carModelModelList.selected
							? undefined
							: t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.str202",
							  ) ?? ""
					}
					items={carBodyTypeModelList.items}
					onChangeSelected={carBodyTypeModelListOnChangeSelected}
					onOffsetChange={carBodyTypeModelList.setOffset}
					onChangeSort={carBodyTypeModelList.setSort}
					onChunkSizeChange={carBodyTypeModelList.setChunkSize}
					onChangeActive={carBodyTypeModelList.onChangeActive}
				/>
				<Divider />
				<ModelList
					selected={null}
					offset={carClassModelList.offset}
					count={carClassModelList.total}
					actions={
						<DefaultPageHeaderButton
							disabled={!isNumber(carBodyTypeModelList.selected)}
							variant="secondary"
							icon={<Icon id="pencil" size={20} />}
							onClick={carClassModelListOnEdit}
						/>
					}
					title={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str4",
						) ?? ""
					}
					overlayLabel={
						carBodyTypeModelList.selected
							? undefined
							: t(
									"pages.preferencesPages.screenDirectory.carModelsAndBrands.str203",
							  ) ?? ""
					}
					items={carClassModelList.items}
					onChangeSelected={noop}
					onOffsetChange={carClassModelList.setOffset}
					onChunkSizeChange={carClassModelList.setChunkSize}
					onChangeActive={noop}
					onDelete={carClassModelListOnDelete}
				/>
			</Row>
			{isNumber(carBaseTypeToDelete) ? (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str204",
						) ?? ""
					}
					onCancel={deleteCarBaseTypeModalOnCancel}
					onConfirm={deleteCarBaseTypeModalOnConfirm}
				/>
			) : null}
			{isNumber(carBrandToDelete) ? (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str205",
						) ?? ""
					}
					onCancel={deleteCarBrandModalOnCancel}
					onConfirm={deleteCarBrandModalOnConfirm}
				/>
			) : null}
			{isNumber(carModelToDelete) ? (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str206",
						) ?? ""
					}
					onCancel={deleteCarModelModalOnCancel}
					onConfirm={deleteCarModelModalOnConfirm}
				/>
			) : null}
			{isNumber(carBodyTypeToDelete) ? (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str207",
						) ?? ""
					}
					onCancel={deleteCarBodyTypeModalOnCancel}
					onConfirm={deleteCarBodyTypeModalOnConfirm}
				/>
			) : null}
			{isNumber(carClassIdToDelete) ? (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str208",
						) ?? ""
					}
					onCancel={deleteCarClassModalOnCancel}
					onConfirm={deleteCarClassModalOnConfirm}
				/>
			) : null}
			{showCarClassSelectModal ? (
				<MultiSelectModal
					title={
						t(
							"pages.preferencesPages.screenDirectory.carModelsAndBrands.str4",
						) ?? ""
					}
					value={carClassSelectValue}
					options={carClassSelectModalOptions}
					onSubmit={carClassSelectModalOnSubmit}
					onClose={carClassSelectModalOnClose}
				/>
			) : null}
			{item ? (
				<EditModal
					value={item}
					language={"uk"}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			) : null}
		</Column>
	);
}

type Key = string | number;
interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

export default Cars;
