import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
	Button,
	Column,
	Flex,
	Grid,
	GridDragArea,
	Icon,
	Row,
	theme,
	useDebounce,
} from "uikit";
import { SortType } from "rsuite-table";
import { useDispatch } from "react-redux";

import Parking, { ParkingSubscribeType } from "../../../services/Parking2";
import { useTypedSelector } from "../../../redux/store";
import { defaultColumnIds } from "../../../redux/reducers/Orders/Parking/tableSettings";
import { ordersAction as orders } from "../../../redux/reducers/Orders";
import useModelSubscribe from "../../../hooks/useModelSubscribe2";
import { SVGgeneral } from "../../../utils/generalSprite";
import DeleteModal from "../../DeleteModal";
import TableSettings from "../../TableSettings";
import SearchBar from "../../SearchBar";

import useParkingActions from "./hooks/useParkingActions";
import getColumns from "./constants";
import { Filter, ParkingsModal, ParkingTable } from "./components";

const Header = styled(Row)`
	height: 40px;
	width: 100%;
	background: #ffffff;
	z-index: 9;
	padding: 4px 12px;
	box-shadow: 0px 2px 4px #4b576126;
`;

const DragButtonRoot = styled(Flex)`
	flex-basis: 18px;

	cursor: grab;
`;

const StyledSearchBar = styled(SearchBar)`
	width: 280px;
`;

const borderColor = "#cbcfd2";

const TopText = styled(Row)`
	padding: 7px 6px;
	font-family: Lato;
	font-size: 13px;
	font-weight: 700;
	line-height: 11px;
	letter-spacing: 0em;
	text-align: left;
	color: #21333f !important;
	background-color: ${theme.colors.button_secondary};
	border-right: 1px solid ${borderColor};
	border-bottom: 1px solid ${borderColor};
`;

function DragButton() {
	const item = useContext(Grid.Item.ItemContext);

	return item?.indexes.length === 1 ? (
		<GridDragArea.GridDragArea indexes={item.indexes}>
			<DragButtonRoot align="center" justify="start">
				<SVGgeneral id="drag_icon" />
			</DragButtonRoot>
		</GridDragArea.GridDragArea>
	) : null;
}

function ParkingsWidget({ parkingsSubscribeData, taxiServiceData }) {
	const dispatch = useDispatch();
	const { onSaveColumnsIdsTable, onChangeParkingsPosition } =
		useParkingActions();
	const { t } = useTranslation();

	const columns = useMemo(() => getColumns(t), [t]);

	const lang = useTypedSelector((state) => state.session.language);
	const globalLang = useTypedSelector((state) => state.session.language);
	const { layout }: any = useTypedSelector((state) => state.widgets);
	const data = useTypedSelector((state) => state.orders.parking.data);
	const { criteria: filters } = useTypedSelector(
		(state) => state.orders.parking.filter,
	);
	const { columnIds } = useTypedSelector(
		(state) => state.orders.parking.tableSettings,
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [rerender, setRerender] = useState(false);
	const [showParkingModal, setShowParkingModal] = useState<boolean>(false);
	const [booferQuery, setBooferQuery] = useState(filters.query || "");
	const [isEditModal, setIsEditModal] = useState<boolean>(false);
	const [showClearParkings, setShowClearParkings] = useState(false);
	const [addressSearchFieldQuery, setAddressSearchFieldQuery] = useState({
		settlement: "",
		address: "",
		street: "",
	});
	const [addressSearchFieldError, setAddressSearchFieldError] =
		useState<boolean>(false);
	const [selectedParkingsTableId, setSelectedParkingsTableId] =
		useState<number>(0);
	const [
		searchedCompanyAndTaxiServiceIds,
		setSearchedCompanyAndTaxiServiceIds,
	] = useState<{ companyId: number; taxiServiceId: number } | null>(null);

	const onChangeParkingsQuery = useCallback(() => {
		dispatch(orders.parking.filter.setParkingsQuery(booferQuery));
	}, [booferQuery, dispatch]);

	const debounceExecutors = useDebounce(onChangeParkingsQuery);

	const onSubmitAddress = useCallback(
		async (coordinatesData) => {
			try {
				const response = await Parking.getAll({
					point: coordinatesData.coordinates,
					subscribeType: ParkingSubscribeType.MAIN_ORDER_GET_ALL_PAGE,
				});

				if (response.items.length > 0) {
					const parking = response.items[0];
					if (
						filters.taxiServiceIds.includes(parking.taxiService.id)
					) {
						setSelectedParkingsTableId(parking.id);
						setSearchedCompanyAndTaxiServiceIds({
							companyId: parking.taxiService.company.id,
							taxiServiceId: parking.taxiService.id,
						});
						setAddressSearchFieldError(false);
						setIsEditModal(true);
					} else {
						setAddressSearchFieldError(true);
					}
				} else {
					setAddressSearchFieldError(true);
				}
				setAddressSearchFieldQuery({
					settlement: `${
						coordinatesData?.street?.data?.settlement || ""
					}`,
					address: `${
						coordinatesData?.street?.data?.streetType || ""
					} ${coordinatesData?.street?.data?.street || ""}`,
					street: `${coordinatesData?.house?.data?.number || ""}`,
				});
			} catch (error) {
				console.error(error);
			}
		},
		[filters.taxiServiceIds],
	);

	const parkings = useMemo<Parking.Model[]>(
		() => parkingsSubscribeData?.models,
		[parkingsSubscribeData?.models],
	);

	const dataForTable = useMemo(() => {
		const dataArray: {
			type?: SortType;
			column?: SortType;
			data: Parking.Model[];
		}[] = [];

		const taxiServiceIds: number[] = [...filters.taxiServiceIdsPosition];
		parkings.forEach((parking) => {
			if (!taxiServiceIds.includes(parking.taxiServiceId)) {
				taxiServiceIds.push(parking.taxiServiceId);
			}
		});
		taxiServiceIds.forEach((taxiServiceId) => {
			const parkingsByTaxiServiceId = parkings.filter(
				(parking) => parking.taxiServiceId === taxiServiceId,
			);
			dataArray.push({
				type: undefined,
				column: undefined,
				data: parkingsByTaxiServiceId,
			});
		});
		const filtredDataArray = dataArray.filter(
			(parking) => parking.data.length,
		);

		const retval = {
			data: filtredDataArray,
			taxiServiceIds,
		};

		return retval;
	}, [filters, parkings]);

	const allReservedIds = useMemo(() => {
		const allQueues = parkings?.map((parking) => parking.executorsQueue);
		const allExecutorsIds = allQueues?.map((queue) =>
			queue?.map((executor: any) => executor.executor.callSign),
		);

		return allExecutorsIds.flat();
	}, [parkings]);

	const calculateExecutors = useMemo(() => {
		// const filtredExecutors = data.executors
		// 	.filter((executor) =>
		// 		filters.statuses.length
		// 			? filters.statuses.includes(executor.status)
		// 			: executor,
		// 	)
		// 	.filter((executor) =>
		// 		filters.taxiServiceIds.length
		// 			? filters.taxiServiceIds.includes(
		// 					executor?.taxiService?.id || 0,
		// 			  )
		// 			: executor,
		// 	)
		// 	.filter((executor) => {
		// 		const onlineStatus = executor.online ? "online" : "offline";

		// 		return filters.online.length
		// 			? filters.online.includes(onlineStatus)
		// 			: executor;
		// 	});

		// console.log("filtredExecutors", data);

		const filtredExecutors = data.executors.filter((executor) => {
			const statusMatch =
				!filters.statuses.length ||
				filters.statuses.includes(executor.status);
			const taxiServiceMatch =
				!filters.taxiServiceIds.length ||
				filters.taxiServiceIds.includes(executor?.taxiService?.id || 0);
			const onlineStatus = executor.online ? "online" : "offline";
			const onlineMatch =
				!filters.online.length || filters.online.includes(onlineStatus);

			return statusMatch && taxiServiceMatch && onlineMatch;
		});

		const onParking = filtredExecutors.filter(
			(executor) => executor?.currentParking,
		);
		const outsideParking = filtredExecutors.filter(
			(executor) => executor?.currentParking == null,
		);

		const executorOnline = filtredExecutors.reduce(
			(acc, executor) => acc + +executor.online,
			0,
		);

		const executorsOffline = filtredExecutors.reduce(
			(acc, executor) => acc + +!executor.online,
			0,
		);

		return {
			onParking: onParking.length,
			outsideParking: outsideParking.length,
			online: executorOnline,
			offline: executorsOffline,
		};
	}, [
		data.executors,
		filters.online,
		filters.statuses,
		filters.taxiServiceIds,
	]);

	const parkingsModalData = useMemo(
		() =>
			parkings.find((parking) => parking.id === selectedParkingsTableId),
		[parkings, selectedParkingsTableId],
	);

	const openClearParkingsModal = useCallback(() => {
		setShowClearParkings(true);
	}, []);
	const closelearParkingsModal = useCallback(() => {
		setShowClearParkings(false);
	}, []);

	const tableFooter = useMemo(
		() => (
			<>
				<Row
					gaps="20px*"
					style={{
						borderTop: `1.5px solid ${theme.colors.button_secondary}`,
						paddingInline: "8px",
						paddingBlock: "4px",
						backgroundColor: "#EBEBEB",
						fontSize: "12px",
						fontWeight: "600",
						color: "#21333F",
					}}
				>
					<span>
						{t("orderPageWidgets.parking.str200") ?? ""}
						{":  "}
						{calculateExecutors.onParking}
					</span>
					<span>
						{t("orderPageWidgets.parking.str201") ?? ""}
						{": "}
						{calculateExecutors.outsideParking}
					</span>
					<span>
						{t("orderPageWidgets.parking.str202") ?? ""}:{" "}
						{calculateExecutors.online}
					</span>
					<span>
						{t("orderPageWidgets.parking.str203") ?? ""}:{" "}
						{calculateExecutors.offline}
					</span>
				</Row>
			</>
		),
		[
			calculateExecutors.onParking,
			calculateExecutors.outsideParking,
			calculateExecutors.online,
			calculateExecutors.offline,
			t,
		],
	);

	const allParkingsData = useModelSubscribe(
		{ subscribeType: ParkingSubscribeType.MAIN_ORDER_PAGE_SAVE_MODAL },
		Parking,
	);

	const onSaveData = useCallback(
		async (parkingId: number, executorIds: number[], isDataWasChanged) => {
			try {
				if (!isDataWasChanged) return;
				const executorsOnParkingIds = data?.executors
					?.filter((executor) => !!executor.currentParking)
					.map((executor) => executor?.id);

				const currentParking = allParkingsData?.models?.find(
					(parking) => parking?.id === parkingId,
				);
				const executorIdsFromParking =
					currentParking?.executorsQueue?.map(
						(queue) => queue?.executor?.id,
					);

				// const executorIdsToChange = executorIds
				// 	.filter(
				// 		(executorId) =>
				// 			!executorIdsFromParking?.includes(executorId),
				// 	)
				// 	.filter((executorId) =>
				// 		executorsOnParkingIds.includes(executorId),
				// 	);

				const executorIdsToChange = executorIds?.filter(
					(executorId) =>
						!executorIdsFromParking?.includes(executorId) &&
						executorsOnParkingIds.includes(executorId),
				);

				const executorIdsDataToResponse = executorIdsToChange.map(
					(executorId) => ({ executorId }),
				);

				await Parking.change(parkingId, executorIdsDataToResponse);

				await Parking.update({
					id: parkingId,
					executorsQueue: executorIds,
				});
			} catch (error) {
				console.error(error);
			} finally {
				setShowParkingModal(false);
				setIsEditModal(false);
				setAddressSearchFieldQuery({
					address: "",
					settlement: "",
					street: "",
				});
				setAddressSearchFieldError(false);
				setSearchedCompanyAndTaxiServiceIds(null);
			}
		},
		[allParkingsData?.models, data.executors],
	);

	const closeParkingModal = useCallback(() => {
		setShowParkingModal(false);
		setIsEditModal(false);
		setAddressSearchFieldQuery({
			address: "",
			settlement: "",
			street: "",
		});
		setSearchedCompanyAndTaxiServiceIds(null);
		setAddressSearchFieldError(false);
	}, []);

	const editParking = useCallback(() => {
		setIsEditModal(true);
		setShowParkingModal(true);
	}, []);

	const companyAndTaxiServiceField = useCallback(
		(taxiServiceId: number) => {
			const companyAndTaxiService = taxiServiceData?.cache.find(
				(taxiService) => taxiService.id === taxiServiceId,
			);

			return `${companyAndTaxiService?.company?.name?.[lang]} / ${companyAndTaxiService?.settlement?.[lang]}`;
		},
		[taxiServiceData, lang],
	);

	const onChangeSort = useCallback(
		(
			{ type, column }: { type: SortType; column: SortType },
			taxiServiceId: number,
		) => {
			const foundedParking = dataForTable?.data?.find(
				(parking) =>
					parking?.data?.[0]?.taxiServiceId === taxiServiceId,
			);
			if (foundedParking?.type && foundedParking?.column) {
				foundedParking.type = type;
				foundedParking.column = column;
			}
		},
		[dataForTable],
	);

	const clearSearchField = useCallback(() => {
		setBooferQuery("");
	}, []);

	const allParkingsIdsWithExecutors = useMemo(() => {
		const allParkingsWithExecutors = parkingsSubscribeData.models?.filter(
			(parking) => parking.executorsQueue.length,
		);
		const parkingsIdsToClear = allParkingsWithExecutors.map(
			(parking) => parking.id,
		);
		return parkingsIdsToClear;
	}, [parkingsSubscribeData]);

	const removeAllExecutorsFromAllParkings = useCallback(() => {
		allParkingsIdsWithExecutors.map(async (parkingId) => {
			await Parking.update({
				id: parkingId,
				executorsQueue: [],
			});
		});
		closelearParkingsModal();
	}, [allParkingsIdsWithExecutors, closelearParkingsModal]);

	// useKeyBind(["KeyE"], () => {});
	// useKeyBind(["KeyI"], () => {});

	useEffect(() => {
		const taxiServiceIdsPosition = filters.taxiServiceIdsPosition.filter(
			(id) =>
				filters.taxiServiceIds.includes(id) &&
				parkingsSubscribeData.models.find((model) => model.id === id),
		);

		onChangeParkingsPosition(taxiServiceIdsPosition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, filters.taxiServiceIds, parkingsSubscribeData.models]);

	useEffect(() => {
		if (layout?.dockbox?.children[1]?.activeId === "parking") {
			setRerender((prev) => !prev);
		}
	}, [layout?.dockbox?.children]);

	useEffect(() => {
		debounceExecutors();
		// onChangeParkingsQuery(booferQuery);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [booferQuery]);

	return (
		<Column maxedWidth maxedHeight>
			<Header maxedWidth justify="space-between">
				<Row gaps="4px*" align="center">
					<DragButton />
					<Button.Button
						icon={<Icon id="plus" size={16} />}
						onClick={() => setShowParkingModal(true)}
					/>
					<Button.Button
						variant="secondary"
						onClick={editParking}
						icon={<Icon id="pencil" size={20} />}
						disabled={!selectedParkingsTableId}
					/>
					<Button.Button
						icon={<Icon id="trash" size={20} />}
						variant="secondary"
						disabled={!allParkingsIdsWithExecutors.length}
						onClick={openClearParkingsModal}
					/>
				</Row>
				<Row gaps="10px*">
					<Row style={{ position: "relative" }}>
						<StyledSearchBar
							value={booferQuery}
							onChange={setBooferQuery}
							placeholder={
								t("orderPageWidgets.parking.str204") ?? ""
							}
						/>
						<div
							onClick={clearSearchField}
							style={{
								position: "absolute",
								right: 0,
								top: 9,
								cursor: "pointer",
								width: 25,
							}}
						>
							<Icon
								size={15}
								id="close"
								style={{ fill: theme.colors.disabled_text }}
							/>
						</div>
					</Row>
					<Filter
						taxiServiceData={taxiServiceData}
						taxiServiceIdsPosition={dataForTable.taxiServiceIds}
						language={globalLang}
					/>
				</Row>
			</Header>
			<Column
				sizes="1fr"
				maxedHeight
				maxedWidth
				style={{ overflowY: "auto" }}
			>
				<Column maxedWidth maxedHeight style={{ overflowY: "auto" }}>
					{!parkingsSubscribeData.loading &&
					parkingsSubscribeData.models.length
						? dataForTable.data.map((taxiService, index) => (
								<Column key={taxiService.data[0]?.id ?? index}>
									<TopText>
										{companyAndTaxiServiceField(
											taxiService.data[0]?.taxiServiceId,
										)}
									</TopText>
									<ParkingTable
										// executorRef={columnWidthRef}
										data={taxiService}
										selectedId={selectedParkingsTableId}
										onChangeSelectedId={
											setSelectedParkingsTableId
										}
										onChangeIsEditModal={setIsEditModal}
										columnIds={columnIds}
										globalLang={globalLang}
										sort={{
											column: taxiService.column,
											type: taxiService.type,
										}}
										onChangeSort={onChangeSort}
										onShowParkingModal={setShowParkingModal}
									/>
								</Column>
						  ))
						: []}
				</Column>
				<Column>
					<div>{tableFooter}</div>
					<TableSettings
						value={columnIds}
						defaultValue={defaultColumnIds}
						columns={columns}
						onChange={onSaveColumnsIdsTable}
					/>
				</Column>
			</Column>
			{showClearParkings && (
				<DeleteModal
					label={t("orderPageWidgets.parking.str205") ?? ""}
					onConfirm={removeAllExecutorsFromAllParkings}
					onCancel={closelearParkingsModal}
				/>
			)}
			{showParkingModal && (
				<ParkingsModal
					data={isEditModal ? parkingsModalData : (undefined as any)}
					isEditModal={isEditModal}
					addressSearchFieldQuery={addressSearchFieldQuery}
					onChangeAddressSearchFieldQuery={setAddressSearchFieldQuery}
					selectedParkingsTableId={selectedParkingsTableId}
					onChangeSelectedParkingsTableId={setSelectedParkingsTableId}
					addressSearchFieldError={addressSearchFieldError}
					onChangeAddressSearchFieldError={setAddressSearchFieldError}
					onChangeIsEditModal={setIsEditModal}
					allReservedIds={allReservedIds}
					onSave={onSaveData}
					onClose={closeParkingModal}
					language={globalLang}
					onSubmitAddress={onSubmitAddress}
					taxiServiceData={taxiServiceData}
					filter={filters}
					searchedCompanyAndTaxiServiceIds={
						searchedCompanyAndTaxiServiceIds
					}
				/>
			)}
		</Column>
	);
}

export default ParkingsWidget;
