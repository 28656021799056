import { ValueLanguage } from "../../assets/languages/langs";

import shortenedSelectOptions from "./constants";

type Language = ValueLanguage;

namespace Language {
	export const constants = {
		select: {
			options: {
				shortened: shortenedSelectOptions,
			},
		},
	};
}

export default Language;
