import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	min-width: 450px;

	padding-inline: 24px;
`;

export default Root;
