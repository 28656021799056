import React from "react";
import { Column, ColumnId } from ".";
import TimeToDeliveryCellContent from "../components/TimeToDeliveryCellContent";
import base from "./base";

function timeToDelivery({ width, onResize }: Column.Options) {
	return base(
		ColumnId.TimeToDelivery,
		(item) => <TimeToDeliveryCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default timeToDelivery;
