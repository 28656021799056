import styled from "styled-components";
import { theme } from "uikit";

const Label = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;

	letter-spacing: 0.15px;

	color: ${theme.colors.primary};
`;

export default Label;
