import { Action, combineReducers } from "@reduxjs/toolkit";

import { Reports } from "./interface";
import {
	cashlessOrders,
	reportsCashlessOrdersAction,
	StateCashlessOrders,
} from "./CashlessOrders";
import {
	cashlessOrdersGeneral,
	reportsCashlessOrdersGeneralAction,
	StateCashlessOrdersGeneral,
} from "./CashlessOrdersGeneral";
import {
	cashlessOrdersPeriodReport,
	reportsCashlessOrdersPeriodReportAction,
	StateCashlessOrdersPeriodReport,
} from "./CashlessOrdersPeriodReport";
import {
	cashlessAccountsSummaryReport,
	reportsCashlessAccountsSummaryReportAction,
	StateCashlessAccountsSummaryReport,
} from "./CashlessAccountsSummaryReport";
import {
	agentOrdersReport,
	reportsAgentOrdersReportAction,
	StateAgentOrdersReport,
} from "./AgentOrdersReport";
import {
	agentBillReport,
	reportsAgentBillReportAction,
	StateAgentBillReport,
} from "./AgentBillReport";

export { Reports };

export type State = {
	cashlessOrders: StateCashlessOrders;
	cashlessOrdersGeneral: StateCashlessOrdersGeneral;
	cashlessOrdersPeriodReport: StateCashlessOrdersPeriodReport;
	cashlessAccountsSummaryReport: StateCashlessAccountsSummaryReport;
	agentOrdersReport: StateAgentOrdersReport;
	agentBillReport: StateAgentBillReport;
};

export type Actions = {
	cashlessOrders: typeof reportsCashlessOrdersAction;
	cashlessOrdersGeneral: typeof reportsCashlessOrdersGeneralAction;
	cashlessOrdersPeriodReport: typeof reportsCashlessOrdersPeriodReportAction;
	cashlessAccountsSummaryReport: typeof reportsCashlessAccountsSummaryReportAction;
	agentOrdersReport: typeof reportsAgentOrdersReportAction;
	agentBillReport: typeof reportsAgentBillReportAction;
};

export const reportsReducer = combineReducers<State, Action<Actions>>({
	cashlessOrders,
	cashlessOrdersGeneral,
	cashlessOrdersPeriodReport,
	cashlessAccountsSummaryReport,
	agentOrdersReport,
	agentBillReport,
});

export const reportsAction: Actions = {
	cashlessOrders: reportsCashlessOrdersAction,
	cashlessOrdersGeneral: reportsCashlessOrdersGeneralAction,
	cashlessOrdersPeriodReport: reportsCashlessOrdersPeriodReportAction,
	cashlessAccountsSummaryReport: reportsCashlessAccountsSummaryReportAction,
	agentOrdersReport: reportsAgentOrdersReportAction,
	agentBillReport: reportsAgentBillReportAction,
};

export default reportsReducer;
