import { TFunction } from "i18next";

import Content from "./index";

// eslint-disable-next-line no-shadow
export enum Columns {
	CREATED_AT = "createdAt",
	TYPE = "type",
	PROVIDER_NAME = "providerName",
	ALPHA_NAME = "alphaName",
	SIMPLE_STATUS = "simpleStatus",
	PHONE = "phone",
	TEXT = "text",
	SENDER = "sender",
	COMPANY = "company",
	TAXI_SERVICE = "taxiService",
}

export const getColumns = (t: TFunction): Content.ColumnType[] => [
	{
		id: Columns.CREATED_AT,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.createdAt",
			) ?? "",
		width: 100,
		sortable: true,
	},
	{
		id: Columns.TYPE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.type",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.PROVIDER_NAME,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.providerName",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.ALPHA_NAME,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.alphaName",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.SIMPLE_STATUS,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.simpleStatus",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.PHONE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.phone",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.TEXT,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.text",
			) ?? "",
		minWidth: 300,
		sortable: true,
	},
	{
		id: Columns.SENDER,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.sender",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.COMPANY,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.company",
			) ?? "",
		width: 150,
		sortable: true,
	},
	{
		id: Columns.TAXI_SERVICE,
		label:
			t(
				"pages.mainPage.pages.archives.tabs.messagesHistory.content.taxiService",
			) ?? "",
		width: 120,
		sortable: true,
	},
]; // columns
