import React, { Dispatch } from "react";
import Root from "./components/Root";
import SearchBar from "./components/SearchBar";
import useObjectEditor from "../../../../../hooks/useObjectEditor";
import Filters from "./components/Filters";
import SearchType from "./components/SearchType";
import Divider from "../../../../Divider";

// eslint-disable-next-line prettier/prettier
const Header = <SearchOptionValue, >({
	value,

	searchOptions,
	defaultFiltersValue,

	onChange,

	onSelectSearchOption,
}: Header.Props<SearchOptionValue>) => {
	const valueEditor = useObjectEditor(value, onChange);

	const searchValue = valueEditor.useGetter("search");
	const searchOnChange = valueEditor.useSetter("search");

	const searchTypeValue = valueEditor.useGetter("searchType");
	const searchTypeOnChange = valueEditor.useSetter("searchType");

	const filtersValue = valueEditor.usePicker([
		"onActiveOrder",
		"companyIds",
		"taxiServiceIds",
		"statuses",
		"shifts",
	]);

	const assignValue = valueEditor.useAssigner();

	return (
		<Root align="center" sizes="1fr auto! 112px! auto!" gaps="12px*">
			<SearchBar
				value={searchValue}
				options={searchOptions}
				onChange={searchOnChange}
				onSelect={onSelectSearchOption}
			/>
			<Divider orientation="vertical" size={18} color="#e0e0e0" />
			<SearchType value={searchTypeValue} onChange={searchTypeOnChange} />
			<Filters
				value={filtersValue}
				defaultValue={defaultFiltersValue}
				onApply={assignValue}
			/>
		</Root>
	);
};

declare namespace Header {
	type SearchOption<OptionValue> = SearchBar.Option<OptionValue>;

	type FiltersValue = Filters.Value;

	interface Value extends FiltersValue {
		search: string;
		searchType: SearchType.Value;
	}

	interface Props<SearchOptionValue> {
		value: Value;

		searchOptions: SearchOption<SearchOptionValue>[];
		defaultFiltersValue: FiltersValue;

		onChange: Dispatch<Value>;

		onSelectSearchOption: Dispatch<SearchOption<SearchOptionValue>>;
	}
}

export default Header;
