/* eslint-disable import/no-unresolved */

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Map from "../../../../../../../../../../redux/services/Map";
import FileBrowser from "../../../../../../../../../../components/FileBrowser";
import TaxiServiceForm from "../../../../../../../../../../components/TaxiServiceForm";
import InternalController from "./Controller";

const Form = react.withController<Form.InputifiedPropsBase, Form.Controller>(
	react.inputify<Form.PropsBaseWithController, Form.Value>(
		({ controller, language, value, onChange }) => {
			const { t } = useTranslation();
			const taxiServiceFormRef =
				useRef<TaxiServiceForm.Controller | null>(null);

			const [fileBrowserError, setFileBrowserError] = useState(false);

			controller.setContext({
				taxiServiceFormRef,
				value,
				setFileBrowserError,
			});

			return (
				<Column gaps="10px" maxedWidth maxedHeight>
					<TaxiServiceForm
						ref={taxiServiceFormRef}
						language={language}
						value={value?.taxiServiceId}
						onChange={(taxiServiceId) => {
							value &&
								onChange({
									...value,
									taxiServiceId,
								});
						}}
					/>
					<FileBrowser
						placeholder={
							t(
								"pages.preferencesPages.screenDirectory.objects.importModal.form.str0",
							) ?? ""
						}
						type=".json,.csv"
						value={value?.file}
						error={fileBrowserError}
						onChange={(file) => {
							setFileBrowserError(false);
							value && onChange({ ...value, file: file as File });
						}}
					/>
				</Column>
			);
		},
	),
	InternalController,
);

declare namespace Form {
	interface Value {
		taxiServiceId?: number;
		file?: File;
	}

	export interface PropsBase {
		language: Map.Language;
	}

	export interface PropsBaseWithController extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = PropsBaseWithController & InputifiedPropsBase;

	type Controller = InternalController;
}

export default Form;
