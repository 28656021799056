import { react } from "uikit";
import { RefObject } from "react";

import {
	CompaniesAndTaxiServicesBase,
	NamesBase,
} from "../../../../../../../../../../../../components/common";

interface Context {
	companyBranchRef: RefObject<CompaniesAndTaxiServicesBase.Ref | null>;
	nameFormRef: RefObject<NamesBase.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isBranchesValid =
			!!this.context?.companyBranchRef.current?.validate();
		const isNamesValid = this.context?.nameFormRef.current?.validate();

		return isBranchesValid && isNamesValid;
	}
}
