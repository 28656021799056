import makeTable from "./makeTable";

const customersCounterparties = makeTable({
	allIds: [
		"name",
		"edrpou",
		"counterpartyGroupName",
		"contactName",
		"phones",
		"email",
		"orderCount",
		// "overallBalance",
		// "overallBonusBalance",
		// "disconnectionThreshold",
		"accountsBalance",
		"accountsBonusBalance",
		"accountsDisconnectionThreshold",
		"company",
		"registrationBranch",
		"active",
	],
	defaultIds: [
		"name",
		"edrpou",
		"counterpartyGroupName",
		"contactName",
		"phones",
		"email",
		"orderCount",
		// "overallBalance",
		"accountsBalance",
		"company",
		"registrationBranch",
		"active",
	],
	i18tPath: (id) => `mainPage.customers.counterparties.columns.${id}`,
	initialSortMethod: {
		column: "name",
		type: "desc",
	},
	initialWidths: {
		name: 150,
		edrpou: 100,
		counterpartyGroupName: 150,
		contactName: 150,
		phones: 140,
		email: 150,
		orderCount: 125,
		// overallBalance: 110,
		// overallBonusBalance: 110,
		// disconnectionThreshold: 125,
		accountsBalance: 110,
		accountsBonusBalance: 110,
		accountsDisconnectionThreshold: 125,
		company: 140,
		registrationBranch: 140,
		active: 75,
	},
	initialResizable: {
		name: true,
		edrpou: true,
		counterpartyGroupName: true,
		contactName: true,
		phones: true,
		email: true,
		orderCount: true,
		// overallBalance: true,
		// overallBonusBalance: true,
		// disconnectionThreshold: true,
		accountsBalance: true,
		accountsBonusBalance: true,
		accountsDisconnectionThreshold: true,
		company: true,
		registrationBranch: true,
		active: true,
	},
	initialSortable: {
		name: true,
		edrpou: true,
		counterpartyGroupName: true,
		contactName: true,
		phones: true,
		email: true,
		orderCount: true,
		// overallBalance: true,
		// overallBonusBalance: false,
		// disconnectionThreshold: false,
		accountsBalance: true,
		accountsBonusBalance: false,
		accountsDisconnectionThreshold: false,
		company: true,
		registrationBranch: true,
		active: true,
	},
});

export default customersCounterparties;
