import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	padding-left: 10px;
	background-color: #ebebeb;
	overflow: hidden;
`;

export const ActiveTab = styled("button")`
	padding: 8px 14px 8px 14px;
	border-radius: 7px 7px 0px 0px;
	color: ${theme.colors.accent};
	background-color: #fff;
`;

export const InactiveTab = styled("button")`
	padding: 8px 14px 8px 14px;
	border-radius: 7px 7px 0px 0px;
	color: ${theme.colors.primary};
	background-color: transparent;
`;

export default Root;
