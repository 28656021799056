import styled from "styled-components";

const Shadow = styled.div`
	width: 100%;
	height: 100%;

	border-top-left-radius: 5px;

	box-shadow: inset 3px 3px 10px -3px rgba(0, 0, 0, 0.08);

	pointer-events: none;
`;

export default Shadow;
