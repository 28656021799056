import { useCallback, useMemo } from "react";

import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import {
	State,
	archiveAction as archive,
} from "../../../../../redux/reducers/Archives";
import { ValueToggle } from "../../../../../redux/reducers/Archives/interface";
import getOrderHistoryById from "../../../../../redux/services/Archives/Orders/getOrderHistoryById";

export type UseArchiveOrders = {
	orders: State["orders"]["orders"];
	errors: State["orders"]["errors"];
	player: State["orders"]["player"];
	history: State["orders"]["history"];
	/** Set active point at map */
	setActivePoint: (data: State["orders"]["orders"]["activePoint"]) => void;
	/** Set order id into store */
	setActiveOrderId: (id: State["orders"]["orders"]["activeOrderId"]) => void;
	/** Set orders into store */
	setOrders: (data: State["orders"]["orders"]["orders"]) => void;
	/** Set active order into store */
	setOrder: (data: State["orders"]["orders"]["order"]) => void;
	/** Add order into the orders store */
	addOrder: (data: State["orders"]["orders"]["orders"][0]) => void;
	/** Update order inside the `orders arr` store. */
	updateOrder: (data: State["orders"]["orders"]["orders"][0]) => void;
	/** Set the order history to the store. */
	setOrderHistory: (data: State["orders"]["history"]["history"]) => void;
	setPlayerPosition: (data: State["orders"]["player"]["position"]) => void;
	setPlayerNext: (data: State["orders"]["player"]["next"]) => void;
	setPlayerDate: (data: State["orders"]["player"]["date"]) => void;
	setPlayerMax: (data: State["orders"]["player"]["max"]) => void;
	setPlayerSpeed: (data: State["orders"]["player"]["speed"]) => void;
	setPlayerDuration: (data: State["orders"]["player"]["duration"]) => void;
	setPlayerToggle: (data: ValueToggle) => void;
	setPlayerRun: (data: ValueToggle) => void;

	/**
	 * Get the order history by id from the database.
	 * @param id the `activeOrderId` is taken by default, if `id` is not passed.
	 */
	getOrderHistory: (id?: State["orders"]["orders"]["activeOrderId"]) => void;
};

/** `useArchiveOrders`
 ** Hook to get all the orders inside the redux store archive.
 ** And has functions for changing the state of orders.
 */
export const useArchiveOrders = (): UseArchiveOrders => {
	const dispatch = useTypedDispatch();
	const orders = useTypedSelector((state) => state.archive.orders.orders);
	const errors = useTypedSelector((state) => state.archive.orders.errors);
	const player = useTypedSelector((state) => state.archive.orders.player);
	const history = useTypedSelector((state) => state.archive.orders.history);

	const getOrderHistory = useCallback<UseArchiveOrders["getOrderHistory"]>(
		(id) => {
			const orderId = id || orders.order?.id;
			if (orderId) getOrderHistoryById(orderId)(dispatch);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[orders.order],
	);

	const setOrderHistory = useCallback<UseArchiveOrders["setOrderHistory"]>(
		(data) => {
			dispatch(archive.orders.history.setOrderHistory(data));
		},
		[dispatch],
	);

	const setActivePoint = useCallback(
		(data: State["orders"]["orders"]["activePoint"]) => {
			dispatch(archive.orders.orders.setActivePoint(data));
		},
		[dispatch],
	);

	const setActiveOrderId = useCallback(
		(data: State["orders"]["orders"]["activeOrderId"]) => {
			dispatch(archive.orders.orders.setActiveOrderId(data));
		},
		[dispatch],
	);

	const setOrders = useCallback(
		(data: State["orders"]["orders"]["orders"]) => {
			dispatch(archive.orders.orders.setOrders(data));
		},
		[dispatch],
	);

	const setOrder = useCallback(
		(data: State["orders"]["orders"]["order"]) => {
			dispatch(archive.orders.orders.setOrder(data));
		},
		[dispatch],
	);

	const addOrder = useCallback(
		(data: State["orders"]["orders"]["orders"][0]) => {
			dispatch(archive.orders.orders.addOrder(data));
		},
		[dispatch],
	);
	const updateOrder = useCallback(
		(data: State["orders"]["orders"]["orders"][0]) => {
			dispatch(archive.orders.orders.updateOrder(data));
		},
		[dispatch],
	);

	const setPlayerSpeed = useCallback<UseArchiveOrders["setPlayerSpeed"]>(
		(data) => {
			dispatch(archive.orders.player.setOrderPlayerSpeed(data));
		},
		[dispatch],
	);
	const setPlayerMax = useCallback<UseArchiveOrders["setPlayerSpeed"]>(
		(data) => {
			dispatch(archive.orders.player.setOrderPlayerMax(data));
		},
		[dispatch],
	);
	const setPlayerPosition = useCallback<
		UseArchiveOrders["setPlayerPosition"]
	>(
		(data) => {
			dispatch(archive.orders.player.setOrderPlayerPosition(data));
		},
		[dispatch],
	);

	const setPlayerDuration = useCallback<
		UseArchiveOrders["setPlayerDuration"]
	>(
		(data) => {
			dispatch(archive.orders.player.setOrderPlayerDuration(data));
		},
		[dispatch],
	);

	const setPlayerNext = useCallback<UseArchiveOrders["setPlayerNext"]>(
		(data) => {
			dispatch(archive.orders.player.setOrderPlayerNext(data));
		},
		[dispatch],
	);

	const setPlayerDate = useCallback<UseArchiveOrders["setPlayerDate"]>(
		(data) => {
			dispatch(archive.orders.player.setOrderPlayerDate(data));
		},
		[dispatch],
	);

	const setPlayerToggle = useCallback<UseArchiveOrders["setPlayerToggle"]>(
		(data: ValueToggle) => {
			dispatch(archive.orders.player.setOrderPlayerToggle(data));
		},
		[dispatch],
	);

	const setPlayerRun = useCallback<UseArchiveOrders["setPlayerToggle"]>(
		(data: ValueToggle) => {
			dispatch(archive.orders.player.setOrderPlayerRun(data));
		},
		[dispatch],
	);

	return useMemo<UseArchiveOrders>(
		() => ({
			orders,
			errors,
			player,
			history,
			setActiveOrderId,
			setActivePoint,
			setOrders,
			setOrder,
			updateOrder,
			addOrder,
			setPlayerToggle,
			setPlayerDate,
			setPlayerDuration,
			setPlayerNext,
			setPlayerPosition,
			setPlayerMax,
			setPlayerSpeed,
			setOrderHistory,
			getOrderHistory,
			setPlayerRun,
		}),
		[
			errors,
			history,
			orders,
			player,
			addOrder,
			setActiveOrderId,
			setActivePoint,
			setOrder,
			setOrderHistory,
			setOrders,
			setPlayerDate,
			setPlayerMax,
			setPlayerNext,
			setPlayerPosition,
			setPlayerSpeed,
			setPlayerDuration,
			setPlayerToggle,
			updateOrder,
			getOrderHistory,
			setPlayerRun,
		],
	);
};

export default useArchiveOrders;
