import React, { memo, useMemo } from "react";
import { Row } from "uikit";
import { useTranslation } from "react-i18next";

import tPath from "../../../../../../../../constants/tPath";
import { surchargesOptions } from "../../../../../../constants/options";
import PassengersSurcharge from "../../../..";

const IntervalValue: React.FC<IntervalValue.Props> = memo(({ value }) => {
	const { t } = useTranslation();
	const path = `${tPath}.mainModal.tabs.main.surcharges.options`;

	const surcharge = useMemo(
		() =>
			t(
				`${path}.${
					surchargesOptions.find(
						(option) => option.key === value.type,
					)?.key
				}`,
			),
		[path, t, value.type],
	);

	return (
		<Row align="center" gaps="10px">
			<span>{surcharge}:</span>
			<span
				style={{
					color: "black",
					fontWeight: "600",
					fontSize: "14px",
				}}
			>
				{value.value}
			</span>
		</Row>
	);
});

declare namespace IntervalValue {
	interface Props {
		value: PassengersSurcharge.Interval;
	}
}

export default IntervalValue;
