import React from "react";
import { ToggleButton } from "uikit";

import { CellContentRoot } from "../../../../../../../../../../../../components/LightTable";

const BranchesCellContent: React.FC<BranchesCellContent.Props> = ({ item }) => {
	const content = item.branches ?? "_branches";

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace BranchesCellContent {
	interface Props {
		item: any;
	}
}

export default BranchesCellContent;
