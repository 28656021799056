import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Column, Icon, Row, theme } from "uikit";
import { useDispatch } from "react-redux";
import softphoneWidget from "../../../../../../../../../../redux/reducers/softphoneWidget";
import { latestCallTypes } from "../../../../../../../../constants";
import { secondsFormater, timestampToTime } from "./helper";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import CallService from "../../../../../../../../../../services/Call";
import Circle from "../../../../../../../../../Circle";

const CallStyled = styled(Row)<{ bgColor?: string }>`
	padding: 8px 12px;
	border-bottom: 1px solid ${theme.colors.color_border_basic};
	background-color: ${({ bgColor }) => bgColor || "transparent"};
	&:hover {
		background-color: #f5f5f5;
	}
`;

const Number = styled("div")`
	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	cursor: default;
`;

// const Name = styled("div")`
// 	color: ${theme.colors.secondary};
// 	font-family: Lato;
// 	font-size: 12px;
// 	font-weight: 400;
// 	line-height: 16px;
// 	letter-spacing: 0px;
// 	text-align: left;
// 	cursor: default;
// `;

const CallDuration = styled(Row)<{ color?: string }>`
	align-items: center;

	color: ${({ color }) => color || theme.colors.primary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: center;
`;

const CallTime = styled(Row)<{ color?: string }>`
	color: ${({ color }) => color || theme.colors.secondary};
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
`;

interface Props {
	data: CallService.Model;
}

const Call: React.FC<Props> = ({ data }) => {
	const dispatch = useDispatch();

	const { callsData } = useTypedSelector((state) => state.softphoneWidget);

	const { uaConfig } = useTypedSelector((state) => state.sipToDispatcher);

	const [isHovered, setIsHovered] = useState(false);

	const showCallButton = useCallback(() => {
		setIsHovered(true);
	}, []);
	const hideCallButton = useCallback(() => {
		setIsHovered(false);
	}, []);

	const call = useCallback(async () => {
		const caller = callsData.find(
			(call) => call.session.remote_identity.uri.user === data.phone,
		);

		const missedCallsPhones = localStorage.getItem("missedCallsPhones");

		const number = `sip:${data.phone}@phone.mobilauto.com.ua`;
		if (!caller) {
			if (missedCallsPhones) {
				const parsedMissedCalls = JSON.parse(missedCallsPhones);
				parsedMissedCalls.includes(data.phone) &&
					(await CallService.setNoAnswerAsRecall(data.phone));
			}
			uaConfig.ua.call(number, uaConfig.callOptions);
		} else if (caller) {
			dispatch(softphoneWidget.actions.setSelectedCaller(caller));
		}

		dispatch(softphoneWidget.actions.setTab("dialing"));
	}, [callsData, data.phone, dispatch, uaConfig?.callOptions, uaConfig?.ua]);

	const callDuration = useMemo(() => {
		const checks =
			!!data.durationInSeconds &&
			(data.type === "IN_ANSWER" ||
				data.type === "IN_COME" ||
				data.type === "OUT_ANSWER" ||
				data.type === "OUT_COME");
		return checks ? secondsFormater(data.durationInSeconds) : null;
	}, [data.durationInSeconds, data.type]);

	const durationBgColor = useMemo(
		() => (data.type === "IN_NO_ANSWER" ? "#fafafa" : "transparent"),
		[data.type],
	);

	return (
		<CallStyled
			justify="space-between"
			bgColor={durationBgColor}
			onMouseEnter={showCallButton}
			onMouseLeave={hideCallButton}
		>
			<Column justify="center">
				<Number>{data.phone}</Number>
				{/* {data.operator && <Name>{data.operator}</Name>} */}
			</Column>
			<Column
				justify="center"
				align="flex-end"
				style={{ height: 36, width: 44 }}
			>
				{!isHovered && (
					<>
						<CallDuration
							gaps="4px"
							color={latestCallTypes[data.type].iconColor}
						>
							{data.type === "IN_NO_ANSWER" && (
								<Circle
									color={theme.colors.negative}
									size={8}
								/>
							)}
							<Icon
								id={latestCallTypes[data.type].iconName}
								size={16}
								colors={[latestCallTypes[data.type].iconColor]}
							/>
							{callDuration}
						</CallDuration>
						<CallTime>{timestampToTime(data.createdAt)}</CallTime>
					</>
				)}
				{isHovered && (
					<Button.Button
						onClick={call}
						style={{
							height: 32,
							borderRadius: "50%",
							backgroundColor: "#4CAF50",
						}}
						icon={<Icon id="call-start" size={18} />}
					/>
				)}
			</Column>
		</CallStyled>
	);
};

export default Call;
