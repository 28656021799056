/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import path from "path";

import React, { useCallback, useMemo, useRef, useState } from "react";
import { useInternal, usePrevValue, useRefWithSetter } from "uikit";
import { isNumber, merge, set } from "lodash";

import TaxiService from "../../../../../../services/TaxiService";
import { useTypedSelector } from "../../../../../../redux/store";
import LocalObject from "../../../../../../redux/services/Map/LocalObject";
import Map from "../../../../../../redux/services/Map";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import DeleteModal from "../../../../../../components/DeleteModal";
import {
	createObjectLanguage,
	Language,
} from "../../../../../../assets/languages/langs";
import { useTableOptions } from "../../../../../../components/LightTable";

import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";
import ExportModal from "./components/ExportModal";
import ImportModal from "./components/ImportModal";

export interface Props {}

export function makeDefaultObject(
	additions: Partial<EditModal.LocalObjectValue> = {},
): EditModal.LocalObjectValue {
	return merge(
		{
			status: true,
			visibility: true,
			fields: createObjectLanguage<{ title: string }>({ title: "" }),
		},
		additions,
	);
}

export function makeDefaultObjectGroup(
	additions: Partial<EditModal.LocalObjectGroupValue> = {},
): EditModal.LocalObjectGroupValue {
	return merge(
		{
			status: true,
			visibility: true,
			fields: createObjectLanguage<{ title: string }>({ title: "" }),
			localObjects: [],
		},
		additions,
	);
}

const Objects: React.FC<Props> = () => {
	const { editor, onChange, sort, query, setQuery } = useTableOptions();

	const [editModalRef, editModalRefSetter] =
		useRefWithSetter<EditModal.Controller | null>(null);

	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const [type, setType] = useState<EditModal.Type | null>(null);

	const [localObject, setLocalObject] =
		useState<EditModal.LocalObjectValue | null>(null);
	const [localObjectGroup, setLocalObjectGroup1] =
		useState<EditModal.LocalObjectGroupValue | null>(null);

	function setLocalObjectGroup(
		...args: Parameters<typeof setLocalObjectGroup1>
	) {
		setLocalObjectGroup1(...args);
	}

	const [selected, setSelected] = useState<number[]>([]);
	const [companiesFilter, setCompanies] = useState<number[] | ["all"]>([
		"all",
	]);
	const [taxiServicesFilter, setTaxiServices] = useState<number[] | ["all"]>([
		"all",
	]);

	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [limit, setLimit] = useState(30);
	const [language, setLanguage] = useState<Language>(settingsLanguage);
	const [dataType, setDataType] = useState<"objects" | "object-groups">(
		"objects",
	);

	const [additionalFilters, setAdditionalFilters] =
		useState<Header.Filters.Popover.Value>({
			showAll: true,
			showObjects: false,
			showStreets: false,
			showHiddenInMobileApp: false,
			hideNotActive: false,
		});
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [exportOptions, setExportOptions] =
		useState<ExportModal.Value | null>(null);
	const [importOptions, setImportOptions] =
		useState<ImportModal.Value | null>(null);

	const status = useMemo(
		() => (additionalFilters.hideNotActive ? true : undefined),
		[additionalFilters.hideNotActive],
	);

	const visibility = useMemo(
		() =>
			additionalFilters.showAll || additionalFilters.showHiddenInMobileApp
				? undefined
				: true,
		[additionalFilters.showAll, additionalFilters.showHiddenInMobileApp],
	);

	const isStreet = useMemo(
		() =>
			additionalFilters.showAll ||
			additionalFilters.showStreets === additionalFilters.showObjects
				? undefined
				: additionalFilters.showStreets > additionalFilters.showObjects,
		[
			additionalFilters.showAll,
			additionalFilters.showObjects,
			additionalFilters.showStreets,
		],
	);

	const taxiServiceIds = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			taxiServicesFilter[0] !== "all"
				? (taxiServicesFilter as number[])
				: companiesFilter[0] !== "all"
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								(companiesFilter as Array<number>).includes(
									taxiService.company!.id,
								),
						)
						.map((taxiService) => taxiService.id)
				: undefined,
		[companiesFilter, taxiServices, taxiServicesFilter],
	);

	const order = useMemo(
		() =>
			sort.dataKey && sort.sortType
				? set(
						{} as Exclude<
							LocalObject.SubscribeOptions["order"],
							undefined
						>,
						sort.dataKey,
						sort.sortType?.toUpperCase(),
				  )
				: undefined,
		[sort.dataKey, sort.sortType],
	);

	const objectOptions = useMemo<LocalObject.SubscribeOptions>(
		() => ({
			grouped: false,
			limit,
			query,
			status,
			visibility,
			taxiServiceIds,
			order,
		}),
		[limit, order, query, status, taxiServiceIds, visibility],
	);

	const currentObjectOptionsRef = useRef(objectOptions);

	if (dataType === "objects") currentObjectOptionsRef.current = objectOptions;

	const objectGroupOptions = useMemo<Map.LocalObjectGroup.SubscribeOptions>(
		() => ({
			limit,
			query,
			status,
			visibility,
			isStreet,
			taxiServiceIds,
		}),
		[isStreet, limit, query, status, taxiServiceIds, visibility],
	);

	const currentObjectGroupOptionsRef = useRef(objectGroupOptions);

	if (dataType === "object-groups")
		currentObjectGroupOptionsRef.current = objectGroupOptions;

	const localObjects = useModelSubscribe(
		currentObjectOptionsRef.current,
		Map.LocalObject,
	);

	const localObjectGroups = useModelSubscribe(
		currentObjectGroupOptionsRef.current,
		Map.LocalObjectGroup,
	);

	const objects = useMemo(
		() => (dataType === "objects" ? localObjects : localObjectGroups),
		[dataType, localObjectGroups, localObjects],
	);

	const prevObjects = usePrevValue(objects);

	const data = useMemo(
		() => (objects?.deprecated ? prevObjects?.cache : objects?.cache) ?? [],
		[objects?.deprecated, objects?.cache, prevObjects?.cache],
	);

	const [loading, setLoading] = useInternal(
		!!objects?.deprecated && (!prevObjects || prevObjects.deprecated),
	);

	const headerOnAdd = useCallback(() => {
		const additions = {
			taxiServiceId:
				taxiServicesFilter.length === 1 &&
				typeof taxiServicesFilter[0] === "number"
					? taxiServicesFilter[0]
					: undefined,
		};

		setType(dataType === "objects" ? "object" : "object-group");
		setLocalObject(makeDefaultObject(additions));
		setLocalObjectGroup(makeDefaultObjectGroup(additions));
	}, [dataType, taxiServicesFilter]);

	const headerOnEdit = useCallback(() => {
		setType(dataType === "objects" ? "object" : "object-group");

		if (dataType === "objects")
			setLocalObject(
				(data as Map.LocalObject[]).find(
					(object) => object.id === selected[0],
				)!,
			);
		else
			setLocalObjectGroup(
				(data as Map.LocalObjectGroup[]).find(
					(object) => object.id === selected[0],
				)!,
			);
	}, [data, dataType, selected]);

	const headerOnDelete = useCallback(() => setShowDeleteModal(true), []);
	const headerOnExport = useCallback(
		() => setExportOptions({ type: "json" }),
		[],
	);
	const headerOnImport = useCallback(() => setImportOptions({}), []);

	const contentOnEdit = useCallback(
		(id: number) => {
			setType(dataType === "objects" ? "object" : "object-group");

			if (dataType === "objects")
				setLocalObject(
					(data as Map.LocalObject[]).find(
						(object) => object.id === id,
					)!,
				);
			else
				setLocalObjectGroup(
					(data as Map.LocalObjectGroup[]).find(
						(object) => object.id === id,
					)!,
				);
		},
		[data, dataType],
	);

	const contentOnLoadMore = useCallback(() => {
		setLimit((currentLimit) => currentLimit + 30);
	}, []);

	const editModalOnCancel = useCallback(() => {
		setLocalObject(null);
		setLocalObjectGroup(null);
	}, []);
	const editModalOnSave = useCallback(() => {
		if (!editModalRef.current?.validate()) return;

		if (type === "object") {
			const readyObject = localObject as Map.LocalObject;

			if (readyObject.id) Map.LocalObject.update(readyObject);
			else Map.LocalObject.store(readyObject);
		} else if (type === "object-group") {
			const readyObjectGroup = localObjectGroup as Map.LocalObjectGroup;

			if (readyObjectGroup.id)
				Map.LocalObjectGroup.update(readyObjectGroup);
			else Map.LocalObjectGroup.store(readyObjectGroup);
		}

		setLocalObject(null);
		setLocalObjectGroup(null);
	}, [editModalRef, localObject, localObjectGroup, type]);

	const deleteModalOnCancel = useCallback(
		() => setShowDeleteModal(false),
		[],
	);
	const deleteModalOnConfirm = useCallback(async () => {
		setLoading(true);

		setShowDeleteModal(false);

		if (dataType === "objects") await Map.LocalObject.destroy(selected);
		else if (dataType === "object-groups")
			await Map.LocalObjectGroup.destroy(selected);

		setSelected([]);
		setLoading(false);
	}, [dataType, selected, setLoading]);

	const exportModalOnCancel = useCallback(() => setExportOptions(null), []);
	const exportModalOnConfirm = useCallback(() => {
		if (!exportOptions) return;

		LocalObject.unload(exportOptions);

		setExportOptions(null);
	}, [exportOptions]);

	const importModalOnCancel = useCallback(() => setImportOptions(null), []);
	const importModalOnConfirm = useCallback(async () => {
		const validatedImportOptions =
			importOptions as Required<ImportModal.Value>;

		setLoading(true);
		setImportOptions(null);

		await LocalObject.upload({
			taxiServiceId: validatedImportOptions.taxiServiceId,
			type: path
				.extname(validatedImportOptions.file.name)
				.substring(1) as LocalObject.ImportOptions["type"],
			stream: validatedImportOptions.file.stream(),
		});

		setLoading(false);
	}, [importOptions, setLoading]);

	const editModalProps = useMemo(() => {
		switch (type) {
			case "object":
				return localObject
					? ({
							type,
							value: localObject,
							onChange: setLocalObject,
					  } as Pick<
							EditModal.LocalObjectProps,
							"type" | "value" | "onChange"
					  >)
					: null;
			case "object-group":
				return localObjectGroup
					? ({
							type,
							value: localObjectGroup,
							onChange: setLocalObjectGroup,
					  } as Pick<
							EditModal.LocalObjectGroupProps,
							"type" | "value" | "onChange"
					  >)
					: null;
			default:
				return null;
		}
	}, [localObject, localObjectGroup, type]);

	const contentDataProps = useMemo(
		() =>
			dataType === "objects"
				? { type: dataType, data: data as Map.LocalObject[] }
				: { type: dataType, data: data as Map.LocalObjectGroup[] },
		[data, dataType],
	);

	const content = useMemo(
		() => (
			<>
				<Header
					companies={companiesFilter}
					taxiServices={taxiServicesFilter}
					language={language}
					dataType={dataType}
					search={query}
					additionalFilters={additionalFilters}
					canEdit={selected.length === 1}
					canDelete={selected.length !== 0}
					onChangeCompanies={(value) => {
						setLimit(30);
						setCompanies(value);
					}}
					onChangeTaxiServices={(value) => {
						setLimit(30);
						setTaxiServices(value);
					}}
					onChangeLanguage={(value) => {
						setLimit(30);
						setLanguage(value);
					}}
					onChangeDataType={(value) => {
						setLimit(30);
						setDataType(value);
					}}
					onChangeSearch={(value) => {
						setLimit(30);
						setQuery(value);
					}}
					onChangeAdditionalFilters={(value) => {
						setLimit(30);
						setAdditionalFilters(value);
					}}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
					onExport={headerOnExport}
					onImport={headerOnImport}
				/>

				<Content
					{...contentDataProps}
					loading={loading}
					language={language}
					selected={selected}
					onChangeSelected={setSelected}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
					editorTable={editor}
					onChangeTable={onChange}
				/>
			</>
		),
		[
			additionalFilters,
			companiesFilter,
			contentDataProps,
			contentOnEdit,
			contentOnLoadMore,
			dataType,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			headerOnExport,
			headerOnImport,
			language,
			loading,
			query,
			selected,
			setQuery,
			sort,
			taxiServicesFilter,
		],
	);

	return (
		<Root sizes="auto 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}

			{editModalProps && (
				<EditModal
					ref={editModalRefSetter}
					{...editModalProps}
					language={settingsLanguage}
					onChangeType={setType}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}

			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}

			{exportOptions && (
				<ExportModal
					value={exportOptions}
					language={settingsLanguage}
					onChange={setExportOptions}
					onCancel={exportModalOnCancel}
					onConfirm={exportModalOnConfirm}
				/>
			)}

			{importOptions && (
				<ImportModal
					value={importOptions}
					language={settingsLanguage}
					onChange={setImportOptions}
					onCancel={importModalOnCancel}
					onConfirm={importModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default Objects;
