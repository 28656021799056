import styled from "styled-components";
import { theme } from "uikit";

const FormRoot = styled.div`
	padding: 20px 16px;

	overflow: auto;

	${theme.scrollbar}
`;

export default FormRoot;
