import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { StyledColumn } from "../../../../../../../../components/common";
import { JsonEditor, EditModal } from "..";

const Root = styled(StyledColumn)`
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const Title = styled.h5`
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	overflow: hidden;
	flex-shrink: 0;
`;

const BoxEditJson = styled.div`
	flex: 1;
	margin: 20px;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const ErrorText = styled.p`
	color: red;
	margin-top: 10px;
`;

const Modal: React.FC<Modal.Props> = ({
	value,
	onCancel,
	onSave,
	headerTitle,
	isLoading,
}) => {
	const [jsonData, setJsonData] = useState<Record<string, any>>(value);
	const [error, setError] = useState<string | null>(null);

	const validateJson = (json: Record<string, any>): boolean => {
		try {
			if (json && typeof json === "object" && !Array.isArray(json)) {
				const jsonString = JSON.stringify(json);
				const parsedJson = JSON.parse(jsonString);
				return JSON.stringify(parsedJson) === jsonString;
			}
		} catch (error) {
			console.error(
				"[Settings > Parametes > Programs]: JSON validation error:",
				error,
			);
		}

		return false;
	};

	const handleJsonChange = (updatedJson: Record<string, any>) => {
		if (validateJson(updatedJson)) {
			setJsonData(updatedJson);
			setError(null);
		} else {
			setError("Invalid JSON format");
		}
	};

	const handleSubmit = useCallback(() => {
		onSave(headerTitle, jsonData);
	}, [headerTitle, jsonData, onSave]);

	return (
		<EditModal
			onCancel={onCancel}
			onSave={handleSubmit}
			loading={isLoading}
		>
			<Root>
				<Title>{headerTitle}</Title>
				<BoxEditJson>
					<JsonEditor
						initialJson={jsonData}
						onChange={handleJsonChange}
					/>
					{error && <ErrorText>{error}</ErrorText>}
				</BoxEditJson>
			</Root>
		</EditModal>
	);
};

declare namespace Modal {
	interface Props {
		value: Record<string, any>;
		headerTitle: string;
		onCancel: () => void;
		onSave: (headerTitle: string, template: Record<string, any>) => void;
		isLoading: boolean;
	}
}

export default Modal;
