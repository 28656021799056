import { TFunction } from "i18next";
import moment from "moment";

class MomentFnc {
	/**
	 * Formats a date string to a human-readable format.
	 * @param args - The arguments object containing the date string and the bad value.
	 * @returns The formatted date string or the badValue if invalid.
	 */
	public toDateNavigator = ({
		t,
		dateToFormat,
		badValue = "",
		formatVersion = "",
	}: MomentFnc.ToDateNavigator): string | null => {
		if (!dateToFormat) {
			return badValue;
		}

		const targetMoment = moment(dateToFormat);

		if (!targetMoment.isValid()) {
			return badValue;
		}

		const today = moment().startOf("day");
		const targetDay = targetMoment.startOf("day");
		const diffInDays = targetDay.diff(today, "days");

		let formatFinish: undefined | string;

		const [todayT, yesterdayT, tomorrowT] = [
			t("dates.today") ?? "Today",
			t("dates.yesterday") ?? "Yesterday",
			t("dates.tomorrow") ?? "Tomorrow",
		];

		if (formatVersion === "format1") {
			formatFinish = new Map<number, string>([
				[0, todayT],
				[1, yesterdayT],
				[-1, tomorrowT],
			]).get(diffInDays);
		}

		if (formatVersion === "format2") {
			formatFinish = diffInDays === 0 ? "" : undefined;
		}

		const retval = formatFinish ?? targetMoment.format("DD.MM.YYYY");

		return retval;
	};

	/**
	 * Converts a date string to time in hours and minutes format.
	 * @param dateString - The date string to convert.
	 * @returns The time in hours and minutes (e.g., "15:30") or empty string if invalid.
	 */
	public toClockFaceTime = (
		dateString: string | null | undefined,
		badValue: string | null | undefined = "",
	): string | null => {
		if (!dateString) {
			return badValue;
		}

		const targetMoment = moment(dateString);

		if (!targetMoment.isValid()) {
			return badValue;
		}

		const timeFormat = targetMoment.format("HH:mm");

		return timeFormat;
	};
}

declare namespace MomentFnc {
	interface ToDateNavigator {
		t: TFunction;
		dateToFormat: string | null | undefined;
		badValue?: string | null | undefined;
		formatVersion?: string | null | undefined;
	}
}

export default MomentFnc;
