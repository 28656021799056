import { Dispatch } from "redux";

import { getPRPC } from "../../../../../hooks/usePRPC";
import createRPCQuery from "../../../../../utils/createRPCQuery.util";
import { archiveGPS } from "../../../../reducers";

import { ReqGetGPSHistory, ResGetGPSHistory } from "./interface";

/** Retrieving data from the server. */
export const getGPSHistory =
	(params: ReqGetGPSHistory = {}) =>
	async (dispatch: Dispatch<any>) => {
		const prpcow = getPRPC();
		if (!prpcow) return null;

		dispatch(archiveGPS.resetGPSHistory({}));
		dispatch(archiveGPS.setLoadingGPSHistory(true));

		try {
			const fn = (): ReqGetGPSHistory => {
				if (params.filters) {
					const { filters } = params;
					if (filters.dateRange) {
						const { dateRange } = filters;
						return {
							...params,
							filters: {
								...params.filters,
								dateRange: {
									from: new Date(dateRange.from).getTime(),
									to: new Date(dateRange.to).getTime(),
								},
							},
						};
					}
				}
				return {
					...params,
					filters: {
						...params.filters,
					},
				};
			};

			const filtersAndSort: ReqGetGPSHistory = fn();
			const controller: ResGetGPSHistory["data"] = await createRPCQuery(
				() =>
					prpcow.theirsModel.executorLocations.history<
						ReqGetGPSHistory,
						ResGetGPSHistory
					>(filtersAndSort),
				{
					verbose: true,
					name: "getGPSHistory",
				},
			);

			dispatch(archiveGPS.addGPSHistory(controller || []));
			dispatch(archiveGPS.setErrorGPSHistory(null));
			dispatch(archiveGPS.setLoadingGPSHistory(false));
		} catch (error) {
			if (error instanceof Error) {
				dispatch(archiveGPS.setErrorGPSHistory(error.message));
			}
			dispatch(archiveGPS.setLoadingGPSHistory(false));
		}
		return null;
	};

export default getGPSHistory;
