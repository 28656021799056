/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
import React, {
	Dispatch,
	KeyboardEvent,
	memo,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";

import Message from "../../../../../../../../services/Message";
import Language from "../../../../../../../../services/Language";
import BankTransaction from "../../../../../../../../services/BankTransaction";
import LightTable from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import { StyledP } from "../../../../../../../../components/common";

import { Columns, getColumns } from "./constants";
import { getExecutorFullName, getMessageStatus, getMessageType } from "./utils";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = memo(
	({ value, selected, setSelected, sort, onSort, language, setLimit }) => {
		const { t } = useTranslation();

		const columns = useMemo(() => getColumns(t), [t]);
		const defaultColumnsId = useMemo(
			() => columns.map(({ id }) => id),
			[columns],
		);

		const [ctrlPressed, setCtrlPressed] = useState(false);
		const tableRef = useRef<LightTable.Ref | null>(null);

		const tColumns = useMemo(
			() => columns.map((c) => ({ ...c, label: c.label })),
			[columns],
		);

		const [columnsId, setColumnsId] = useState(
			defaultColumnsId.filter(
				(id) => id !== "company" && id !== "taxiService",
			),
		);

		const updateSelected = useCallback(
			(item: RowDataType<Message.Model>, force = false) => {
				const model = item as Message.Model;
				if (ctrlPressed || force) {
					if (selected.includes(model.id)) {
						setSelected(selected.filter((id) => model.id !== id));
					} else {
						setSelected([...selected, model.id]);
					}
				} else {
					selected.includes(model.id)
						? setSelected([])
						: setSelected([model.id]);
				}
			},
			[ctrlPressed, selected, setSelected],
		);

		const renderColumns = useMemo(
			() =>
				columnsId.map((columnId) => {
					const column = columns.find((col) => col.id === columnId);
					if (!column) return null;

					return (
						<LightTable.Column
							key={columnId}
							verticalAlign="middle"
							width={column.width}
							flexGrow={column.width ? 0 : 1}
							sortable={column.sortable}
							fullText={columnId === Columns.STATUS_DESCRIPTION}
							resizable={true}
						>
							<LightTable.HeaderCell
								style={{
									padding: "0 15px",
									flexWrap: "nowrap",
								}}
							>
								{column.label}
							</LightTable.HeaderCell>
							<LightTable.Cell
								style={{ padding: "0 15px" }}
								dataKey={column.id}
								autoHeight={column.id === "text"}
							>
								{(rowData) => {
									const data =
										rowData as BankTransaction.Model;

									const {
										createdAt,
										type,
										amount,
										amountWithFee,
										transactionId,
										status,
										executor,
										customer,
										paymentSystemAccount,
										taxiService,
										orderNumber,
										remountTransactionType,
										amountFromCustomer,
										amountToExecutorCard,
										amountToExecutorMain,
										statusDescription,
									} = data;

									switch (columnId) {
										case Columns.TRANSACTION_ID:
											return <div>{transactionId}</div>;
										case Columns.CREATED_AT:
											return (
												<Column gaps="5px">
													<div>
														{new Date(
															createdAt,
														).toLocaleTimeString()}
													</div>
													<div>
														{new Date(
															createdAt,
														).toLocaleDateString()}
													</div>
												</Column>
											);
										case Columns.CLIENT_NAME:
											return (
												<div>
													{
														customer?.person
															?.firstName
													}
												</div>
											);
										case Columns.TRANSACTION_AMOUNT:
											return (
												<div>
													{parseFloat(
														`${amount}`,
													).toFixed(2)}
												</div>
											);
										case Columns.TRANSACTION_AMOUNT_COMMISSION:
											return (
												<div>
													{parseFloat(
														`${amountWithFee}`,
													).toFixed(2)}
												</div>
											);
										case Columns.STATUS:
											return (
												<div>
													{getMessageStatus(status)}
												</div>
											);
										case Columns.STATUS_DESCRIPTION:
											return (
												<StyledP
													colors={
														statusDescription.includes(
															"Warning",
														)
															? "red"
															: ""
													}
												>
													{statusDescription}
												</StyledP>
											);
										case Columns.CALLSIGN:
											return <div>{executor?.alias}</div>;
										case Columns.EXECUTOR_FULL_NAME:
											return (
												<div>
													{getExecutorFullName(
														executor,
													)}
												</div>
											);

										case Columns.AMOUNT_THAT_CAME:
											return (
												<div>
													{parseFloat(
														`${amountToExecutorCard}`,
													).toFixed(2)}
												</div>
											);
										case Columns.AMOUNT_DRIVER_BALANCE:
											return (
												<div>
													{parseFloat(
														`${amountToExecutorMain}`,
													).toFixed(2)}
												</div>
											);
										case Columns.AMOUNT_FROM_CLIENT:
											return (
												<div>
													{parseFloat(
														`${amountFromCustomer}`,
													).toFixed(2)}
												</div>
											);
										case Columns.PAYMENT_SYSTEM:
											return (
												<div>
													{
														paymentSystemAccount
															?.configuration
															?.name
													}
												</div>
											);
										case Columns.TRANSACTION_TYPE:
											return (
												<div>
													{getMessageType(type)}
												</div>
											);
										case Columns.COMPANY:
											return (
												<div>
													{
														taxiService?.company
															?.name[language]
													}
												</div>
											);
										case Columns.TAXI_SERVICE:
											return (
												<div>
													{
														taxiService?.settlement[
															language
														]
													}
												</div>
											);
										case Columns.REMOUNT_TRANSACTION_TYPE:
											return (
												<div>
													{remountTransactionType}
												</div>
											);
										case Columns.ORDER_NUMBER:
											return <div>{orderNumber}</div>;
										default:
											return null;
									}
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);
				}),
			[columns, columnsId, language],
		);

		const getClassName = useCallback(
			(item) => (item && selected.includes(item.id) ? "selected" : ""),
			[selected],
		);

		const changeAllSelectedHandler = useCallback(
			(isAllSelected: boolean) => {
				if (isAllSelected) {
					setSelected(value.map(({ id }) => id));
				} else {
					setSelected([]);
				}
			},
			[setSelected, value],
		);

		const handleKeyDown = useCallback(
			(e: KeyboardEvent<HTMLDivElement>) => {
				if (e.ctrlKey) setCtrlPressed(true);
			},
			[],
		);

		const handleKeyUp = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
			if (!e.ctrlKey) {
				setCtrlPressed(false);
			}
		}, []);

		// scroll - start
		const [height, setHeight] = useState<number>(-1);
		const [width, setWidth] = useState<number>(-1);
		const handleScroll = useCallback(
			(x: number, y: number) => {
				const top = Math.round(Math.abs(y));
				const left = Math.round(Math.abs(x));
				if (width >= left && height === top) return;
				if (width < left && width !== 999999999) setWidth(left);
				if (top === 0) return;
				if (top > 0 && width < 999999999) setWidth(999999999);

				const contextHeight = value.length * 46;
				const tableContainerHeight =
					tableRef.current?.root.getBoundingClientRect().height ?? 0;
				const valueHeight = contextHeight - top - tableContainerHeight;

				if (valueHeight < 300) {
					if (height - 9 >= top) return;
					setHeight(top);
					setLimit((prev) => prev + 2);
				}
			},
			[width, height, value.length, setLimit],
		);
		// scroll - end
		return (
			<Root sizes="1fr auto!">
				<div
					tabIndex={-1}
					onKeyDown={handleKeyDown}
					onKeyUp={handleKeyUp}
				>
					<LightTable
						data={value}
						virtualized
						fillHeight
						rowHeight={46}
						shouldUpdateScroll={false}
						rowClassName={getClassName}
						onRowClick={updateSelected}
						onScroll={handleScroll}
						sortColumn={sort?.column}
						sortType={sort?.type}
						onSortColumn={
							onSort as (
								dataKey: string,
								sortType?: SortType | undefined,
							) => void
						}
						wordWrap={false}
					>
						<LightTable.Column verticalAlign="middle" width={35}>
							<LightTable.HeaderCell
								style={{ padding: "0 auto" }}
							>
								<CheckBox
									value={
										selected.length === value.length &&
										value.length !== 0
									}
									onChange={changeAllSelectedHandler}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								style={{ padding: "0 auto" }}
							>
								{(item) => (
									<CheckBox
										value={selected.includes(item.id)}
										onChange={() => {
											updateSelected(item, true);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
						{renderColumns}
					</LightTable>
				</div>
				{/* <TableFooter data={value} /> */}
				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={tColumns}
					onChange={setColumnsId}
				/>
			</Root>
		);
	},
);

declare namespace Content {
	interface Props {
		value: BankTransaction.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		sort;
		onSort: (column: string, type: string) => void;
		language: Language;
		setLimit: Dispatch<SetStateAction<number>>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
		sortable?: boolean;
	};
}

export default Content;
