import React from "react";

import Divider from "../../../Divider";

import Root from "./components/Root";
import DateTime from "./components/DateTime";
import User from "./components/User";
import SIP from "./components/SIP";
import TaxiService from "./components/TaxiService";
import Language from "./components/Language";
import Settings from "./components/Settings";
import Logout from "./components/Logout";
import Logo from "./components/Logo";

const Header: React.FC = () => (
	<Root align="center" gaps="32px" sizes="auto!*2 1fr auto!*">
		<Logo />
		<Divider orientation="vertical" size={34} color="#374752" />
		<DateTime />
		<Divider orientation="vertical" size="max" color="#374752" />
		<User />
		<Divider orientation="vertical" size="max" color="#374752" />
		<SIP />
		<Divider orientation="vertical" size="max" color="#374752" />
		<TaxiService />
		<Divider orientation="vertical" size="max" color="#374752" />
		<Language />
		<Divider orientation="vertical" size="max" color="#374752" />
		<Settings />
		<Divider orientation="vertical" size="max" color="#374752" />
		<Logout />
	</Root>
);

export default Header;
