import { LatLngLiteral } from "leaflet";

import { Language } from "../../../assets/languages/langs";
import { getPRPC } from "../../../hooks/usePRPC";
import OrderStatus from "../../../types/OrderStatus";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import { IOrderPoint } from "../../constants/OrdersPage/order";
import { Archive } from "../../reducers/Archives/interface";

interface HistoryBase {
	id: number;
	type: "default";
	status: OrderStatus;

	action: "create" | "update" | "delete" | "close" | "revert" | "refuse";
	changes: History.Change[];
	comment: string; // comment used to describe changes (can be set manually or automatically by server)

	timestamp: number;
	version: number;
}

interface HistoryFromBackend extends HistoryBase {
	user?: {
		id: number;
		name: string;
		surname: string;
		fatherName: string;
		type: "dispatcher";
	};
}

export interface History extends HistoryBase {
	user?: Required<HistoryFromBackend["user"]> & {
		firstName: string;
		lastName: string;
	};
}

export namespace History {
	export namespace Field {
		/// type ///
		export interface TypeChange {
			field: "type";
			previous: "default";
			actual: "default";
			type: FieldBase["type"];
		}

		/// status ///
		export interface StatusChange {
			field: "status";
			previous: OrderStatus;
			actual: OrderStatus;
			type: FieldBase["type"];
		}

		/// orderNote ///
		export interface OrderNoteChange {
			field: "orderNote";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}

		/// orderNumber ///
		export interface OrderNumberChange {
			field: "orderNumber";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}

		/// orderDate ///
		export interface OrderDateChange {
			field: "orderDate";
			previous: number | string;
			actual: number | string;
			type: FieldBase["type"];
		}

		/// taxiService ///
		export interface TaxiServiceChange {
			field: "taxiService";
			previous: Record<Language, string>;
			actual: Record<Language, string>;
			type: FieldBase["type"];
		}

		/// services ///
		interface Service {
			id: number;
			name: Record<Language, string>;
			required: null | any;
		}
		export interface ServiceChange {
			field: "services";
			previous: Service[];
			actual: Service[];
			type: FieldBase["type"];
		}

		/// passenger.customer ///
		export interface PassengerCustomer {
			id: number;
			customer: {
				id: number;
				name: string;
				surname: string;
				fatherName: string;
			};
			type: FieldBase["type"];
		}
		/// passenger.customers ///
		export interface PassengerCustomerChange {
			field: "passenger.customers";
			previous: PassengerCustomer[];
			actual: PassengerCustomer[];
			type: FieldBase["type"];
		}

		/// points ///
		export interface Point {
			id: number;
			passengerPoints: LatLngLiteral[];
			type: FieldBase["type"];
		}
		export interface PointChange {
			field: "points";
			previous: IOrderPoint[];
			actual: IOrderPoint[];
			type: FieldBase["type"];
		}

		/// passenger.points ///

		export interface PassengerPoint {
			id: number;
			passengerPoints: {
				feature: Archive.Orders.PointFeature & {
					coordinates: LatLngLiteral;
				};
				point: LatLngLiteral;
			}[];
			type: FieldBase["type"];
		}
		export interface PassengerPointChange {
			field: "passenger.points";
			previous: PassengerPoint[];
			actual: PassengerPoint[];
			type: FieldBase["type"];
		}

		/// closedOrdersComments ///

		export interface ClosedOrdersCommentChange {
			field: "closedOrdersComments";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}

		/// cost///
		export interface CostChange {
			field: "cost";
			previous: OrderCost;
			actual: OrderCost;
			type: FieldBase["type"];
		}
		/// idleTimeMilliseconds ///
		export interface IdleTimeMilliseconds {
			field: "idleTimeMilliseconds";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}

		/// passengersCount ///
		export interface PassengersCount {
			field: "passengersCount";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}

		/// waitingTimeMilliseconds ///
		export interface WaitingTimeMilliseconds {
			field: "waitingTimeMilliseconds";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}

		/// transportationType ///
		export interface TransportationType {
			field: "transportationType";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}

		/// executorNotes ///
		export interface ExecutorNotes {
			field: "executorNotes";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}
		/// customerNotes ///
		export interface CustomerNotes {
			field: "customerNotes";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}

		/// outsideSettlementKm ///
		export interface OutsideSettlementKm {
			field: "outsideSettlementKm";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}
		/// additionalCost ///
		export interface AdditionalCost {
			field: "additionalCost";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}

		/// hourlyMilliseconds ///
		export interface HourlyMilliseconds {
			field: "hourlyMilliseconds";
			previous: number;
			actual: number;
			type: FieldBase["type"];
		}

		///  paymentAccount ///
		export interface PaymentAccount {
			field: "paymentAccount";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}

		/// billingCustomer ///
		export interface BillingCustomer {
			field: "billingCustomer";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}

		///  rateSettings ///

		export interface RateSetting {
			includePickupCost: boolean;
			isRoundTrip: boolean;
			type: FieldBase["type"];
		}

		export interface RateSettings {
			field: "rateSettings";
			previous: RateSetting;
			actual: RateSetting;
			type: FieldBase["type"];
		}

		/// source ///
		export interface Source {
			field: "source";
			previous: string;
			actual: string;
			type: FieldBase["type"];
		}
		///	carClasses ///
		export interface CarClass {
			id: number;
			name: Record<Language, string>;
		}
		export interface CarClasses {
			field: "carClasses";
			previous: CarClass[];
			actual: CarClass[];
			type: FieldBase["type"];
		}
		/// executors ///

		export interface Executor {
			arrivalTime: number;
			callSign: string;
			id: number;
		}
		export interface Executors {
			field: "executors";
			previous: Executor[];
			actual: Executor[];
			type: FieldBase["type"];
		}
		/// cars ///
		export interface Car {
			brand: string;
			callSign: string;
			carClass: Record<Language, string>;
			color: Record<Language, string>;
			id: number;
			model: string;
			registrationNumber: string;
		}
		export interface Cars {
			field: "cars";
			previous: Car[];
			actual: Car[];
			type: FieldBase["type"];
		}
		export interface Phone {
			number: string;
			group: number;
		}
		export interface Phones {
			field: "phones";
			previous: Phone[];
			actual: Phone[];
			type: FieldBase["type"];
		}
	}

	export interface OrderCostStage {
		value: number;
		stage: Archive.ValueOrderCostCalculationStage;
		details: {
			/**
			 * ```ts
			 * "{{settlementDistance}} * ({{settlementCostPerKm}} + {{settlementCostMargin}})"
			 * ```
			 * */
			expression: string;
			variables: Record<string, any> & {
				settlementCostPerKm: number;
				settlementCostMargin: number;
				settlementDistance: number;
			};
		};
	}

	export interface OrderCostCurrency {
		id: number;
		settings: { code: string; name: string; symbol: string };
		name: Record<Language, string>;
	}
	export interface OrderCost {
		value: number;
		raw: number;
		/** As a number */
		rounded: string;
		currency: OrderCostCurrency;
		settings: Record<string, any> & {
			rounding: {
				method: string;
				multiple: {
					active: boolean;
					value: number;
				};
				precision: number;
			};
		};
		stages: OrderCostStage[];
	}

	// Combined field
	export type Field =
		| Field.TypeChange
		| Field.StatusChange
		| Field.OrderNoteChange
		| Field.OrderNumberChange
		| Field.OrderDateChange
		| Field.TaxiServiceChange
		| Field.ServiceChange
		| Field.PassengerCustomerChange
		| Field.PointChange
		| Field.PassengerPointChange
		| Field.ClosedOrdersCommentChange
		| Field.CostChange
		| Field.IdleTimeMilliseconds
		| Field.PassengersCount
		| Field.WaitingTimeMilliseconds
		| Field.TransportationType
		| Field.ExecutorNotes
		| Field.CustomerNotes
		| Field.OutsideSettlementKm
		| Field.AdditionalCost
		| Field.HourlyMilliseconds
		| Field.RateSettings
		| Field.PaymentAccount
		| Field.BillingCustomer
		| Field.Source
		| Field.CarClasses
		| Field.Cars
		| Field.Executors
		| Field.Phones;

	export interface FieldBase {
		type:
			| "default"
			| "update"
			| "array_item_add"
			| "array_item_remove"
			| "array_item_update";
	}

	export type Change = FieldBase & Field;
}

export default function getOrderHistory(
	orderId: number,
	callback: (history: History[]) => void,
) {
	return async () => {
		const prpcow = getPRPC();
		if (!prpcow) return;
		try {
			const data: HistoryFromBackend[] = await createRPCQuery(() =>
				prpcow?.theirsModel.orders.getHistory(orderId),
			);

			data.reverse().sort((a, b) => a.timestamp - b.timestamp);

			callback(
				data.map((historyEntry) => ({
					...historyEntry,
					user: historyEntry.user && {
						...historyEntry.user,
						firstName: historyEntry.user?.name,
						lastName: historyEntry.user?.surname,
					},
				})),
			);
		} catch {
			callback([]);
		}
	};
}
