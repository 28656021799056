/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Float, Icon, Popover as PopoverBase, Row } from "uikit";
import { useTranslation } from "react-i18next";
import { assign, clone } from "lodash";
import PushMessage from "../../../../../../../../../../../../services/PushMessage";
import Language from "../../../../../../../../../../../../services/Language";
import TaxiService from "../../../../../../../../../../../../services/TaxiService";

import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import StoreList from "../../../../../../../../../../../../types/StoreList";
import { ModalFilter } from "../../../../../..";

import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	const floatSize = { width: 890, height: 242 };

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

interface Props {
	taxiServiceData: StoreList<TaxiService.Model> | undefined;
	language: Language;
	filter: ModalFilter;
	setFilter: React.Dispatch<React.SetStateAction<ModalFilter>>;
	open: boolean;
	onClose: () => void;
}

const Popover: React.FC<Props> = ({
	filter,
	taxiServiceData,
	language,
	setFilter,
	open,
	onClose,
}) => {
	const { t } = useTranslation();

	const [companyAndTaxiServiceError, setCompanyAndTaxiServiceError] =
		useState({
			taxiService: false,
			company: false,
		});

	const clearErrors = useCallback(() => {
		setCompanyAndTaxiServiceError({ company: false, taxiService: false });
	}, []);

	const [requestData, setRequestData] = useState<ModalFilter>({
		companyIds: [],
		taxiServiceIds: [],
		simpleStatuses: [],
		types: [],
	});

	const [companyAndTaxiServiceIds, setCompanyAndTaxiServiceIds] =
		useState<Filter.Value>({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});

	const [simpleStatuses, setSimpleStatuses] = useState<
		PushMessage.NotificationMessageSimpleStatus[]
	>(filter.simpleStatuses);
	const [types, setTypes] = useState<PushMessage.NotificationMessageType[]>(
		filter.types,
	);

	const valueEditor = useObjectEditor(
		companyAndTaxiServiceIds,
		setCompanyAndTaxiServiceIds,
	);
	const companyAndTaxiServiceValue = valueEditor.usePicker([
		"companyIds",
		"taxiServiceIds",
	]);

	const currentValueRef = useRef(companyAndTaxiServiceValue);

	currentValueRef.current = companyAndTaxiServiceValue;

	const accessOnChange = useCallback(
		(accessValue: Filter.Value) => {
			const assignValue: Partial<Filter.Value> = {
				...accessValue,
			};

			valueEditor.assign(assignValue);
		},
		[valueEditor],
	);

	const setField = useCallback(
		<Field extends keyof Filter.Value>(
			field: Field,
			fieldValue: Filter.Value[Field],
		) => {
			currentValueRef.current = assign(clone(currentValueRef.current), {
				[field]: fieldValue,
			});

			accessOnChange(currentValueRef.current);
		},
		[accessOnChange],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			// setTaxiServiceIdsError(false);

			setField("taxiServiceIds", taxiServiceIds);
		},
		[setField],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			// setCompanyIdsError(false);

			setField("companyIds", companyIds);
		},
		[setField],
	);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIds,
		taxiServiceIds,
		setCompanyIds,
		setTaxiServiceIds,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyAndTaxiServiceValue.companyIds,
		companyAndTaxiServiceValue.taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const clearFilters = useCallback(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: ["all"],
			taxiServiceIds: ["all"],
		});
		setSimpleStatuses([]);
		setTypes([]);
		clearErrors();
	}, [clearErrors]);

	const apply = useCallback(() => {
		if (!requestData.taxiServiceIds.length)
			companyAndTaxiServiceError.taxiService = true;
		if (!requestData.companyIds.length)
			companyAndTaxiServiceError.company = true;
		if (
			!requestData.taxiServiceIds.length ||
			!requestData.companyIds.length
		)
			return setCompanyAndTaxiServiceError(() => ({
				...companyAndTaxiServiceError,
			}));
		setFilter(requestData);
		onClose();
		clearErrors();
	}, [
		clearErrors,
		companyAndTaxiServiceError,
		onClose,
		requestData,
		setFilter,
	]);

	const updateFilters = useCallback(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
		setSimpleStatuses(filter.simpleStatuses);
		setTypes(filter.types);
		clearErrors();
	}, [
		clearErrors,
		filter.companyIds,
		filter.simpleStatuses,
		filter.taxiServiceIds,
		filter.types,
	]);

	const cancel = useCallback(() => {
		updateFilters();
		onClose();
		clearErrors();
	}, [clearErrors, onClose, updateFilters]);

	useEffect(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
	}, [filter.companyIds, filter.taxiServiceIds]);

	return (
		<PopoverBase
			open={open}
			containerId="root"
			trackerId="filters"
			offset={additionalFiltersOffsetCallback}
			onClose={onClose}
			style={{ boxShadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
		>
			<Root align="stretch" gaps="16px 20px">
				<Header onClose={onClose} />

				<Content
					taxiServiceData={taxiServiceData}
					language={language}
					simpleStatuses={simpleStatuses}
					setSimpleStatuses={setSimpleStatuses}
					types={types}
					setTypes={setTypes}
					onChangeRequestData={setRequestData}
					possibleCompanies={possibleCompanies}
					possibleTaxiServices={possibleTaxiServices}
					companyIds={companyIds}
					taxiServiceIds={taxiServiceIds}
					setCompanyIds={setCompanyIds}
					setTaxiServiceIds={setTaxiServiceIds}
					error={companyAndTaxiServiceError}
					onChangeError={setCompanyAndTaxiServiceError}
				/>

				<Row justify="space-between">
					<Button.Button
						icon={<Icon size={18} id="refresh" />}
						variant="secondary"
						onClick={clearFilters}
					/>
					<Row gaps="20px">
						<Button.Button
							variant="secondary"
							text={t("cancel") || "Cancel"}
							onClick={cancel}
						/>
						<Button.Button
							text={
								t(
									"pages.mainPage.pages.archives.tabs.pushHistory.header.filter.popover.str0",
								) ?? ""
							}
							onClick={apply}
						/>
					</Row>
				</Row>
			</Root>
		</PopoverBase>
	);
};

declare namespace Filter {
	interface Value {
		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];
	}
}

export default Popover;
