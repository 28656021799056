import styled from "styled-components";

const Bottom = styled.div<Bottom.Props>`
	display: grid;

	grid-template-columns: repeat(
		${({ compactLevel }) => {
			if (compactLevel === 0) return 3;
			if (compactLevel === 1) return 2;

			return 1;
		}},
		1fr
	);
	grid-template-rows: ${({ compactLevel, hasHourlyTimeField }) => {
		if (compactLevel === 0) {
			if (hasHourlyTimeField) return "24px 24px 24px";
			return "24px 24px";
		}
		if (compactLevel === 1) {
			if (hasHourlyTimeField) return "24px 24px 24px 24px";
			return "24px 24px 24px";
		}

		return "auto";
	}};

	grid-template-areas: ${({ compactLevel, hasHourlyTimeField }) => {
		if (compactLevel === 0) {
			if (hasHourlyTimeField)
				return `
					"a        a                    idle-time    "
					"distance out-of-town-distance awaiting-time"
					"price    additional-price     hourly-time  "
				`;

			return `
				"distance out-of-town-distance idle-time    "
				"price    additional-price     awaiting-time"
			`;
		}

		if (compactLevel === 1) {
			if (hasHourlyTimeField)
				return `
					"distance    out-of-town-distance"
					"idle-time   awaiting-time       "
					"hourly-time hourly-time         "
					"price       additional-price    "
				`;

			return `
				"distance    out-of-town-distance"
				"idle-time   awaiting-time       "
				"price       additional-price    "
			`;
		}

		if (hasHourlyTimeField)
			return `
				"distance            "
				"out-of-town-distance"
				"idle-time           "
				"awaiting-time       "
				"hourly-time         "
				"price               "
				"additional-price    "
			`;

		return `
			"distance            "
			"out-of-town-distance"
			"idle-time           "
			"awaiting-time       "
			"price               "
			"additional-price    "
		`;
	}};

	gap: 4px 10px;

	width: 100%;
	height: 100%;
`;

declare namespace Bottom {
	interface Props {
		compactLevel: number;
		hasHourlyTimeField: boolean;
	}
}

export default Bottom;
