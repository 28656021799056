import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)`
	padding: 12px 24px;

	background: ${theme.colors.white};
`;

export default Root;
