import { type AutodetectPhone } from "./autodetectPhone/data/interface";

export namespace Customers {
	export type CustomerAutodetectPhone = AutodetectPhone;

	// path inside redux
	export const ConstantCustomersSlice = {
		COUNTERPARTY_ERRORS: "customers/counterparty/errors",
		COUNTERPARTY_TOGGLES: "customers/counterparty/toggles",
		COUNTERPARTY_LOADINGS: "customers/counterparty/loadings",
		COUNTERPARTY_FILTERS: "customers/counterparty/filters",
		COUNTERPARTY: "customers/counterparty",

		AUTODETECT_PHONE: "customers/autodetectPhone",
		AUTODETECT_PHONE_ERRORS: "customers/autodetectPhone/errors",
	};
	export type TypeOrderSlice = typeof ConstantCustomersSlice;
	export type ValueCustomersSlice =
		(typeof ConstantCustomersSlice)[keyof typeof ConstantCustomersSlice];
}
