import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	position: absolute;

	bottom: 51px;
	left: 12px;

	width: 230px;
	height: 238px;

	border-radius: 15px;
	background-color: ${theme.colors.white};
	box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 24px 2px;

	& > div:last-child {
		border-bottom: none;
	}
`;

export default Root;
