import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import createLogger from "../../../utils/logger.util";
import orderPage from "../../reducers/OrdersPage";

const logger = createLogger({
	name: "loadCarsPositions",
});

/**
 * @deprecated Use ExecutorLocations service instead
 */
export default function loadCarsPositions() {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return;

		const isRecent = (value) => {
			if (!value) return false;

			const start = value.date;
			const end = Date.now();
			const delta = Math.ceil((end - start) / 1000);

			return delta <= 30;
		};

		try {
			const latestPositions = await createRPCQuery(() =>
				prpcow.theirsModel.executorLocations.latest(),
			);

			dispatch(
				orderPage.actions.setCarsPosition(
					latestPositions.filter(isRecent),
				),
			);
		} catch (error) {
			logger.error("Failed to load cars positions", error);
		}
	};
}
