import styled from "styled-components";

const OnlineStatus = styled.div<OnlineStatus.Props>`
	width: 10px;
	height: 10px;

	border-radius: 100%;

	background-color: ${({ online }) =>
		online ? "rgb(76, 175, 80)" : "#bebebe"};
`;

declare namespace OnlineStatus {
	interface Props {
		online: boolean;
	}
}

export default OnlineStatus;
