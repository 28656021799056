import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MultilineTextBox } from "uikit";

import useObjectEditor from "../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledRow,
	CardModal,
	CardModalBase,
} from "../../../common";

import FullName from "./components/FullName";
import { Birthday } from "./components/Birthday";
import CarClasses from "./components/CarClasses";

const Content = styled(StyledColumn)`
	gap: 10px;
	padding: 8px;
	justify-content: start;
	background: #ffffff;
	overflow: hidden;
	width: 450px;
`;

const BasePassenger: React.FC<BasePassenger.Props> = ({
	content,
	onSave,
	data,
	...props
}) => {
	const { t } = useTranslation();

	const [passenger, setPassenger] = useState<BasePassenger.Value>({
		name: "",
		fatherName: "",
		surname: "",
		notes: "",
		birthday: null,
		carClassId: null,
		carClassName: "",
	});

	useLayoutEffect(() => {
		if (data) setPassenger((prev) => ({ ...prev, ...data }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const valueEditor = useObjectEditor(passenger, setPassenger);
	const notes = valueEditor.useGetter("notes");
	const setNotes = valueEditor.useSetter("notes");

	const onSubmit = useCallback<CardModalBase.Props["onSave"]>(
		(event) => {
			onSave(passenger, event);
		},
		[onSave, passenger],
	);

	const focusToSurname = useCallback(() => {
		const nextElem = document.getElementById("customer-modal-surname-id");
		if (nextElem) nextElem.focus();
		return nextElem;
	}, []);

	return (
		<CardModal
			{...props}
			onSave={onSubmit}
			title={t("orders.modals.passenger.str200") ?? ""}
		>
			<Content>
				<FullName value={passenger} onChange={setPassenger} />
				<StyledRow w="100%" gap="10px">
					<Birthday value={passenger} onChange={setPassenger} />
				</StyledRow>

				<CarClasses value={passenger} onChange={setPassenger} />

				<MultilineTextBox
					value={notes}
					placeholder={t(`notes`) || "Notes"}
					onChange={setNotes}
					style={{
						height: "128px",
					}}
					onBlur={focusToSurname}
				/>
				{content}
			</Content>
		</CardModal>
	);
};

declare namespace BasePassenger {
	interface Value {
		name: string;
		surname: string;
		fatherName: string;
		birthday: Date | null | undefined;
		carClassId: number | null | undefined;
		carClassName: string;
		notes: string;
	}

	interface Props extends Omit<CardModalBase.Props, "onSave"> {
		data?: Value;
		content?: React.ReactNode;
		onSave: (data: Value, event?: CardModalBase.Event) => void;
	}
}

export const Passenger = memo(BasePassenger);
export default BasePassenger;
