import React, { RefAttributes, memo } from "react";
import { useTranslation } from "react-i18next";
import { Column, CheckBox, RadioButton, react, useRefWithSetter } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import { marginLeft } from "../../../../../../../../../../../../../../../../../../../../../MainPage/pages/Accounts/tabs/ExecutorTeams/components/Modal/components/Content/tabs/Main/constants/styles";
import { AutoRateRuleSectorItemCounterType as Type } from "../../constants";
import IgnoreOrders from "./components/IgnoreOrders/IgnoreOrders";

import Range from "./components/Range";
import Controller from "./Controller";

const OrdersCounterBase = react.withController<
	OrdersCounter.PropsBase,
	Controller
>(({ disabled, value, onChange, controller }) => {
	const { t } = useTranslation();
	const [ignoreOrdersRef, setIgnoreOrdersRef] =
		useRefWithSetter<IgnoreOrders.Ref | null>(null);
	controller.setContext({ ignoreOrdersRef });

	const valueEditor = useObjectEditor(value, onChange);

	const active = valueEditor.useGetter("active");
	const setActive = valueEditor.useSetter("active");

	const ignoreOrders = valueEditor.useGetter("ignoreOrders");
	const setIgnoreOrders = valueEditor.useSetter("ignoreOrders");

	const range = valueEditor.useGetter("range");
	const setRange = valueEditor.useSetter("range");

	const type = valueEditor.useGetter("type");
	const setType = valueEditor.useSetter("type");

	return (
		<Column gaps="15px*">
			<CheckBox
				disabled={disabled}
				label={
					t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.str0",
					) ?? ""
				}
				value={active}
				onChange={setActive}
			/>
			<Column gaps="15px*" style={marginLeft}>
				<Range
					disabled={disabled || !active}
					value={range}
					onChange={setRange}
				/>
				<IgnoreOrders
					ref={setIgnoreOrdersRef}
					disabled={disabled || !active}
					value={ignoreOrders}
					onChange={setIgnoreOrders}
				/>
				<FieldsContainer
					label={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.str1",
						) ?? ""
					}
				>
					<Column gaps="10px*">
						<RadioButton.RadioButton
							disabled={disabled || !active}
							label={
								t(
									"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.str2",
								) ?? ""
							}
							selected={type === Type.EACH}
							onClick={() => {
								setType(Type.EACH);
							}}
						/>
						<RadioButton.RadioButton
							disabled={disabled || !active}
							label={
								t(
									"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.ordersCounter.str3",
								) ?? ""
							}
							selected={type === Type.OVERALL}
							onClick={() => {
								setType(Type.OVERALL);
							}}
						/>
					</Column>
				</FieldsContainer>
			</Column>
		</Column>
	);
}, Controller);

const OrdersCounter = memo(OrdersCounterBase);

declare namespace OrdersCounter {
	type Ref = Controller | null;
	interface PropsBase {
		disabled?: boolean;
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;
	interface Value {
		active: boolean;
		ignoreOrders: IgnoreOrders.Value;
		range: Range.Value;
		type: Type;
	}
}

export default OrdersCounter;
