import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { MultiSelect } from "uikit";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import getCompanies from "../../../../../../../../../../../../../../redux/services/Company/getCompanies";
import {
	useTypedSelector,
	useTypedDispatch,
} from "../../../../../../../../../../../../../../redux/store";
import TaxiService from "../../../../../../../../../../../../../../services/TaxiService";
import tPath from "../../../../../../../../constants/tPath";

const CompaniesAndTaxiServices: React.FC<CompaniesAndTaxiServices.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();
		const lang = useTypedSelector((state) => state.session.language);

		const valueEditor = useObjectEditor(value, onChange);

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
		const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

		const taxiServices = valueEditor.get("taxiServices");

		const [selectedCompanies, setSelectedCompanies] = useState<number[]>();
		useEffect(() => {
			const newCompanies = taxiServices?.map(
				(service) => service.company?.id,
			);
			const newSelectedCompanies = new Set(newCompanies);
			setSelectedCompanies(Array.from(newSelectedCompanies) as number[]);
		}, [taxiServices]);

		const { companies } = useTypedSelector(
			(state) => state.ordersPageReducer,
		);
		const dispatch = useTypedDispatch();
		useEffect(() => {
			dispatch(getCompanies());
		}, [dispatch]);

		const selectOptionsWrap = useCallback(
			(item: { id: number; label: string }) => ({
				key: item.id,
				value: item.id,
				label: item.label,
			}),
			[],
		);
		const companiesOptions = useMemo(
			() =>
				companies.items.map((company) =>
					selectOptionsWrap({
						id: company.id,
						label: company.name[lang],
					}),
				),
			[companies.items, lang, selectOptionsWrap],
		);
		const taxiServiceOptions = useMemo(
			() =>
				companies.items
					.filter(({ id }) => selectedCompanies?.includes(id))
					.map((company) =>
						company.taxiServices.map((service) =>
							selectOptionsWrap({
								id: service.id,
								label: service?.settlement[lang],
							}),
						),
					)
					.flat(),
			[companies.items, lang, selectOptionsWrap, selectedCompanies],
		);

		const updateSelectedValues = useCallback(
			(newCompanies) => {
				setSelectedCompanies(newCompanies);
				const newTaxiServiceIds = taxiServiceIds.filter((serviceId) => {
					const serviceCompany = companies.items.find((company) =>
						company.taxiServices.some(
							(service) => service.id === serviceId,
						),
					);
					return (
						serviceCompany &&
						newCompanies.includes(serviceCompany.id)
					);
				});
				setTaxiServiceIds(newTaxiServiceIds);
			},
			[companies.items, taxiServiceIds, setTaxiServiceIds],
		);

		return (
			<>
				<LabeledField
					label={
						t(
							`${tPath}.modal.tabs.generalSurcharges.company.title`,
						) || ""
					}
				>
					<MultiSelect
						all
						placeholder={
							t(
								`${tPath}.modal.tabs.generalSurcharges.company.choose`,
							) || ""
						}
						value={selectedCompanies}
						options={companiesOptions}
						onChange={updateSelectedValues}
					/>
				</LabeledField>
				<LabeledField
					label={
						t(
							`${tPath}.modal.tabs.generalSurcharges.branch.title`,
						) || ""
					}
				>
					<MultiSelect
						all
						placeholder={
							t(
								`${tPath}.modal.tabs.generalSurcharges.branch.choose`,
							) || ""
						}
						value={taxiServiceIds}
						disabled={taxiServiceOptions.length === 0}
						options={
							taxiServiceOptions.length === 0
								? undefined
								: taxiServiceOptions
						}
						onChange={(ids) => {
							setTaxiServiceIds(ids as number[]);
						}}
					/>
				</LabeledField>
			</>
		);
	},
);

declare namespace CompaniesAndTaxiServices {
	interface Props {
		value: Value;
		onChange: (x: Value) => void;
	}

	interface Value {
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
	}
}

export default CompaniesAndTaxiServices;
