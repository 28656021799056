import React, { Dispatch, memo, useCallback, useMemo } from "react";
import { Select } from "uikit";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { StyledRow } from "../../../../../../../../components/common";

import { weekOptions } from "./constants";

const WeekDays: React.FC<WeekDays.Props> = memo(
	({ value, onChange, errors, onErrors }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);
		const errorsEditor = useObjectEditor(errors, onErrors);

		const errorDow = errorsEditor.useGetter("dow");
		const setErrorDow = errorsEditor.useSetter("dow");
		const setErrorTo = errorsEditor.useSetter("end");
		const setErrorFrom = errorsEditor.useSetter("start");

		const days = valueEditor.useGetter("dow");
		const setDays = valueEditor.useSetter("dow");
		const handleDaysChange = useCallback(
			(newDays) => {
				setErrorDow(false);
				setErrorTo(false);
				setErrorFrom(false);
				setDays(newDays.value);
			},
			[setDays, setErrorDow, setErrorFrom, setErrorTo],
		);

		const options = useMemo(
			() =>
				weekOptions.map((item) => {
					const value: number[] = [];
					let label = "";
					if (item.value === 7) {
						value.push(...[0, 1, 2, 3, 4, 5, 6]);
						label =
							t(
								"pages.preferencesPages.screenDirectory.sectors.weekDays.str0",
							) ?? "";
					} else if (item.value === 8) {
						value.push(...[0, 1, 2, 3, 4]);
						label = `${moment()
							.weekday(0)
							.format("dddd")} - ${moment()
							.weekday(4)
							.format("dddd")}`;
					} else if (item.value === 9) {
						value.push(...[5, 6]);
						label = `${moment()
							.weekday(5)
							.format("dddd")} - ${moment()
							.weekday(6)
							.format("dddd")}`;
					} else {
						label = moment()
							.weekday(Number(item.value))
							.format("dddd");
						value.push(Number(item.value));
					}

					return {
						...item,
						value,
						label,
					};
				}),
			[t],
		);

		const selectDay = useMemo(() => {
			const all = [0, 1, 2, 3, 4, 5, 6];
			const mf = [0, 1, 2, 3, 4];
			const ss = [5, 6];
			if (all.every((day) => days.includes(day))) return 7;
			if (mf.every((day) => days.includes(day))) return 8;
			if (ss.every((day) => days.includes(day))) return 9;
			return days.at(0);
		}, [days]);

		return (
			<StyledRow gap="10px" w="100%" flex={{ flex: "1 1 100%" }}>
				<Select
					error={errorDow}
					style={{ minWidth: "350px" }}
					placeholder={
						t(
							"pages.preferencesPages.screenDirectory.sectors.weekDays.str200",
						) ?? ""
					}
					options={options}
					value={selectDay}
					onSelect={handleDaysChange}
				/>
			</StyledRow>
		);
	},
);

declare namespace WeekDays {
	interface Errors {
		dow: boolean;
		end: boolean;
		start: boolean;
	}

	interface Props {
		value: Value;
		onChange: (value: Value) => void;

		errors: Errors;
		onErrors: Dispatch<Errors>;
	}

	interface Value {
		dow: number[];
	}
}

export default WeekDays;
