import React, { Dispatch, forwardRef } from "react";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import Language from "../../../../../../../../../../services/Language";
import {
	StyledRow,
	Tab,
} from "../../../../../../../../../../components/common";
import EditModal from "../..";

import Main from "./tabs/Main";
import AverageSpeed from "./tabs/AverageSpeed";

const Content = forwardRef<Content.Controller, Content.Props>(
	({ value, language, onChange, type, sectorDows }, ref) => {
		const valueEditor = useObjectEditor(value, onChange);

		const main = valueEditor.usePicker([
			"id",

			"taxiServiceId",

			"name",
			"isMaxSpeedEnabled",
			"maxSpeed",

			"isAverageSpeedEnabled",
			"isBasicAverageSpeedEnabled",
			"basicAverageSpeed",
			"averageSpeed",

			"availableForExecutor",
			"isParking",
			"isPriceZone",
		]);
		const averageSpeed = valueEditor.usePicker([
			"averageSpeed",
			"isBasicAverageSpeedEnabled",
			"isMaxSpeedEnabled",
			"maxSpeed",
		]);
		const assigner = valueEditor.useAssigner();

		return (
			<StyledRow scrollbar overY="scroll">
				<Tab visible={type === "main"}>
					<Main
						value={main}
						language={language}
						onChange={assigner}
						ref={ref}
					/>
				</Tab>
				<Tab visible={type === "averageSpeed"}>
					<AverageSpeed
						value={averageSpeed}
						onChange={assigner}
						sectorDows={sectorDows}
					/>
				</Tab>
			</StyledRow>
		);
	},
);

declare namespace Content {
	interface Value extends Main.Value, AverageSpeed.Value {}

	interface Props {
		value: Value;

		language: Language;

		type: EditModal.Type;
		onChange: Dispatch<Value>;
		sectorDows: AverageSpeed.SectorDow[];
	}

	type Controller = Main.Controller;
}

export default Content;
