import React, {
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { MultiSelect, react } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import getCompanies from "../../../redux/services/Company/getCompanies";
import useObjectEditor from "../../../hooks/useObjectEditor";
import LabeledField from "../../LabeledField";

import InternalController from "./Controller";

const CompaniesAndTaxiServicesBase = react.withController<
	CompaniesAndTaxiServicesBase.PropsBase,
	CompaniesAndTaxiServicesBase.Controller
>(
	/**
	 * @param {boolean} autoSelectAllTaxiServices
	 * A flag to enable or disable the auto-selection of all taxi services when companies are selected.
	 * If true, when a company is selected, all associated taxi services will be automatically selected.
	 * If false, only the previously selected taxi services will remain.
	 */
	({
		value,
		onChange,
		controller,

		labelCompany = "settings.tariffs.main.mainModal.tabs.main.company",
		placeholderCompany = "settings.tariffs.main.mainModal.tabs.main.chooseCompany",
		labelTaxiServices = "settings.tariffs.main.mainModal.tabs.main.branch",
		placeholderTaxiServices = "settings.tariffs.main.mainModal.tabs.main.chooseBranch",

		required = true,
		disabled = false,
		autoSelectAllTaxiServices = false,
	}) => {
		const dispatch = useTypedDispatch();
		const language = useTypedSelector((state) => state.session.language);
		const { companies } = useTypedSelector(
			(state) => state.ordersPageReducer,
		);
		const { t } = useTranslation();

		const [errorCompanyIds, setCompanyIdsError] = useState(false);
		const [errorTaxiServiceIds, setTaxiServiceIdsError] = useState(false);

		const valueEditor = useObjectEditor(value, onChange);

		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
		const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

		const selectedCompanies = valueEditor.useGetter("companyIds");
		const setSelectedCompanies = valueEditor.useSetter("companyIds");

		useEffect(() => {
			dispatch(getCompanies());
		}, [dispatch]);

		const selectOptionsWrap = useCallback(
			(item: { id: number; label: string }) => ({
				key: item.id,
				value: item.id,
				label: item.label,
			}),
			[],
		);

		const companiesOptions = useMemo(
			() =>
				companies.items.map((company) =>
					selectOptionsWrap({
						id: company.id,
						label: company.name?.[language],
					}),
				),
			[companies.items, language, selectOptionsWrap],
		);

		const taxiServiceOptions = useMemo(
			() =>
				companies.items
					.filter(({ id }) => selectedCompanies?.includes(id))
					.map((company, _, arr) =>
						company.taxiServices?.map((service) => {
							if (arr.length > 1) {
								const label = `${service?.settlement?.[language]} (${company.name?.[language]})`;
								return selectOptionsWrap({
									id: service.id,
									label,
								});
							}

							return selectOptionsWrap({
								id: service.id,
								label: service?.settlement?.[language],
							});
						}),
					)
					.flat(),
			[companies.items, language, selectOptionsWrap, selectedCompanies],
		);

		const updateSelectedValues = useCallback(
			(newCompanies) => {
				setSelectedCompanies(newCompanies);

				// Auto-select all taxi services if the flag is true
				if (autoSelectAllTaxiServices) {
					const allTaxiServices = companies.items
						.filter((company) => newCompanies.includes(company.id))
						.flatMap(
							(company) =>
								company.taxiServices?.map(
									(service) => service.id,
								) || [],
						);

					setTaxiServiceIds(allTaxiServices);
				} else {
					const newTaxiServiceIds = taxiServiceIds.filter(
						(serviceId) => {
							const serviceCompany = companies.items.find(
								(company) =>
									company.taxiServices.some(
										(service) => service.id === serviceId,
									),
							);
							return (
								serviceCompany &&
								newCompanies.includes(serviceCompany.id)
							);
						},
					);

					setTaxiServiceIds(newTaxiServiceIds);
				}

				if (newCompanies.length) setCompanyIdsError(false);
			},
			[
				setSelectedCompanies,
				taxiServiceIds,
				setTaxiServiceIds,
				companies.items,
				autoSelectAllTaxiServices,
			],
		);

		const updateSelectedTaxiServiceValues = useCallback(
			(ids) => {
				if (ids.length) setTaxiServiceIdsError(false);
				setTaxiServiceIds(ids as number[]);
			},
			[setTaxiServiceIds],
		);

		controller.setContext({
			value,
			setCompanyIdsError,
			setTaxiServiceIdsError,
			required,
		});

		return (
			<>
				<LabeledField label={t(labelCompany) || ""}>
					<MultiSelect
						all
						disabled={disabled}
						placeholder={t(placeholderCompany) || ""}
						error={errorCompanyIds}
						value={selectedCompanies}
						options={companiesOptions}
						onChange={updateSelectedValues}
					/>
				</LabeledField>
				<LabeledField label={t(labelTaxiServices) || ""}>
					<MultiSelect
						all
						placeholder={t(placeholderTaxiServices) || ""}
						value={taxiServiceIds}
						disabled={disabled || taxiServiceOptions.length === 0}
						error={errorTaxiServiceIds}
						options={
							taxiServiceOptions.length === 0
								? undefined
								: taxiServiceOptions
						}
						onChange={updateSelectedTaxiServiceValues}
					/>
				</LabeledField>
			</>
		);
	},
	InternalController,
);

declare namespace CompaniesAndTaxiServicesBase {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface Value {
		taxiServiceIds: number[];
		companyIds: number[];
	}
	interface PropsBase {
		value: Value;
		onChange: (value: Value) => void;

		labelCompany?: string[] | string;
		placeholderCompany?: string[] | string;
		labelTaxiServices?: string[] | string;
		placeholderTaxiServices?: string[] | string;

		/** if required === "false" at that validate === `true` */
		required?: boolean;
		disabled?: boolean;
		autoSelectAllTaxiServices?: boolean;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export const CompaniesAndTaxiServices = memo(CompaniesAndTaxiServicesBase);

declare namespace CompaniesAndTaxiServices {
	interface PropsBase extends CompaniesAndTaxiServicesBase.PropsBase {}
	interface Value extends CompaniesAndTaxiServicesBase.Value {}

	type Ref = CompaniesAndTaxiServicesBase.Ref;
	type Controller = CompaniesAndTaxiServicesBase.Controller;
	type Props = PropsBase & RefAttributes<Ref>;
}

export default CompaniesAndTaxiServicesBase;
