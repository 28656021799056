/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, ReactElement, RefAttributes, memo } from "react";
import { react } from "uikit";
import { noop } from "lodash";

import InternalController from "./Controller";
import ModelTable from "./components/ModelTable";

type Key = string | number;

const TableSelect = memo(
	react.withController(
		// eslint-disable-next-line prettier/prettier, comma-spacing
		<OptionValue,>({
			value,
			sort,
			columnIds,

			disabled = false,
			options,
			defaultColumnIds,

			isOptionActive,

			Columns,

			onChange,
			onChangeSort,
			onChangeColumnIds,
		}: TableSelect.InternalProps<OptionValue>) => (
			<ModelTable
				selected={value}
				sort={sort}
				columnIds={columnIds}
				disabled={disabled}
				loading={false}
				data={options}
				defaultColumnIds={defaultColumnIds}
				isOptionActive={isOptionActive}
				Columns={Columns}
				onChangeSelected={onChange}
				onChangeSort={onChangeSort}
				onChangeColumnIds={onChangeColumnIds}
				onLoadMore={noop}
			/>
		),
		InternalController,
	),
) as unknown as <OptionValue>(
	props: TableSelect.Props<OptionValue>,
) => ReactElement;

declare namespace TableSelect {
	type Ref = InternalController | null;

	type Sort = ModelTable.Sort;
	type Column = ModelTable.Column;
	type Option<Value> = ModelTable.Option<Value>;

	type Controller = InternalController;

	type Value = Key[];

	interface PropsBase<OptionValue> {
		value: Value;
		sort: ModelTable.Sort;
		columnIds: string[];

		disabled?: boolean;
		options: Option<OptionValue>[];
		defaultColumnIds: string[];

		isOptionActive: (option: Option<OptionValue>) => boolean;

		Columns: ModelTable.Column[];

		onChange: Dispatch<Value>;
		onChangeSort: Dispatch<ModelTable.Sort>;
		onChangeColumnIds: Dispatch<string[]>;
	}

	type InternalProps<OptionValue> = PropsBase<OptionValue> & {
		controller: InternalController;
	};

	type Props<OptionValue> = PropsBase<OptionValue> & RefAttributes<Ref>;
}

export default TableSelect;
