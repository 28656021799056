/**
 * Defines a color palette structure that can be accessed both as an array and by properties.
 * The structure mirrors the color definitions specified in Ant Design's color specification.
 * @see https://ant.design/docs/spec/colors
 */

const red: Colors.Palette = {
	length: 10,
	0: "#fff1f0",
	1: "#ffccc7",
	2: "#ffa39e",
	3: "#ff7875",
	4: "#ff4d4f",
	5: "#f5222d",
	primary: "#f5222d",
	6: "#cf1322",
	7: "#a8071a",
	8: "#820014",
	9: "#5c0011",
	name: "red",
};

const volcano: Colors.Palette = {
	length: 10,
	0: "#fff2e8",
	1: "#ffd8bf",
	2: "#ffbb96",
	3: "#ff9c6e",
	4: "#ff7a45",
	5: "#fa541c",
	primary: "#fa541c",
	6: "#d4380d",
	7: "#ad2102",
	8: "#871400",
	9: "#610b00",
	name: "volcano",
};

const orange: Colors.Palette = {
	length: 10,
	0: "#fff7e6",
	1: "#ffe7ba",
	2: "#ffd591",
	3: "#ffc069",
	4: "#ffa940",
	5: "#fa8c16",
	primary: "#fa8c16",
	6: "#d46b08",
	7: "#ad4e00",
	8: "#873800",
	9: "#612500",
	name: "orange",
};

const gold: Colors.Palette = {
	length: 10,
	0: "#fffbe6",
	1: "#fff1b8",
	2: "#ffe58f",
	3: "#ffd666",
	4: "#ffc53d",
	5: "#faad14",
	primary: "#faad14",
	6: "#d48806",
	7: "#ad6800",
	8: "#874d00",
	9: "#613400",
	name: "gold",
};

const yellow: Colors.Palette = {
	length: 10,
	0: "#feffe6",
	1: "#ffffb8",
	2: "#fffb8f",
	3: "#fff566",
	4: "#ffec3d",
	5: "#fadb14",
	primary: "#fadb14",
	6: "#d4b106",
	7: "#ad8b00",
	8: "#876800",
	9: "#614700",
	name: "yellow",
};

const lime: Colors.Palette = {
	length: 10,
	0: "#fcffe6",
	1: "#f4ffb8",
	2: "#eaff8f",
	3: "#d3f261",
	4: "#bae637",
	5: "#a0d911",
	primary: "#a0d911",
	6: "#7cb305",
	7: "#5b8c00",
	8: "#3f6600",
	9: "#254000",
	name: "lime",
};

const green: Colors.Palette = {
	length: 10,
	0: "#f6ffed",
	1: "#d9f7be",
	2: "#b7eb8f",
	3: "#95de64",
	4: "#73d13d",
	5: "#52c41a",
	primary: "#52c41a",
	6: "#389e0d",
	7: "#237804",
	8: "#135200",
	9: "#092b00",
	name: "green",
};

const cyan: Colors.Palette = {
	length: 10,
	0: "#e6fffb",
	1: "#b5f5ec",
	2: "#87e8de",
	3: "#5cdbd3",
	4: "#36cfc9",
	5: "#13c2c2",
	primary: "#13c2c2",
	6: "#08979c",
	7: "#006d75",
	8: "#00474f",
	9: "#002329",
	name: "cyan",
};

const blue: Colors.Palette = {
	length: 10,
	0: "#e6f4ff",
	1: "#bae0ff",
	2: "#91caff",
	3: "#69b1ff",
	4: "#4096ff",
	5: "#1677ff",
	primary: "#1677ff",
	6: "#0958d9",
	7: "#003eb3",
	8: "#002c8c",
	9: "#001d66",
	name: "blue",
};

const greekblue: Colors.Palette = {
	length: 10,
	0: "#f0f5ff",
	1: "#d6e4ff",
	2: "#adc6ff",
	3: "#85a5ff",
	4: "#597ef7",
	5: "#2f54eb",
	primary: "#2f54eb",
	6: "#1d39c4",
	7: "#10239e",
	8: "#061178",
	9: "#030852",
	name: "greekblue",
};

const purple: Colors.Palette = {
	length: 10,
	0: "#f9f0ff",
	1: "#efdbff",
	2: "#d3adf7",
	3: "#b37feb",
	4: "#9254de",
	5: "#722ed1",
	primary: "#722ed1",
	6: "#531dab",
	7: "#391085",
	8: "#22075e",
	9: "#120338",
	name: "purple",
};

const magenta: Colors.Palette = {
	length: 10,
	0: "#fff0f6",
	1: "#ffd6e7",
	2: "#ffadd2",
	3: "#ff85c0",
	4: "#f759ab",
	5: "#eb2f96",
	primary: "#eb2f96",
	6: "#c41d7f",
	7: "#9e1068",
	8: "#780650",
	9: "#520339",
	name: "magenta",
};

const gray: Colors.Palette = {
	length: 13,
	0: "#ffffff",
	1: "#fafafa",
	2: "#f5f5f5",
	3: "#f0f0f0",
	4: "#d9d9d9",
	5: "#bfbfbf",
	6: "#8c8c8c",
	primary: "#8c8c8c",
	7: "#595959",
	8: "#434343",
	9: "#262626",
	10: "#1f1f1f",
	11: "#141414",
	12: "#000000",
	name: "gray",
};

declare namespace Colors {
	type Palette = {
		length: number;
		[index: number]: string;
		name: string;
		primary: string;
	};
}

export {
	red,
	volcano,
	orange,
	gold,
	yellow,
	lime,
	green,
	cyan,
	blue,
	greekblue,
	purple,
	magenta,
	gray,
};
