import React, { memo, useMemo } from "react";
import { Tooltip, Whisper } from "rsuite";
import { Overlayer, Table, useRefSize } from "uikit";
import { HeaderCellProps, RowDataType, RowKeyType } from "rsuite-table";
import Content from "./components/Content";
import OverlayContent from "./components/OverlayContent";

const HeaderCell = memo<HeaderCell.Props>(
	({ children, hint = true, ...props }) => {
		const { size: contentSize, ref: contentRef } = useRefSize();

		const isWhispered = useMemo(
			() =>
				hint &&
				contentSize.width + (props.sortable ? 37 : 16) >
					(props.width ?? 0),
			[contentSize.width, hint, props.sortable, props.width],
		);

		const whispered = useMemo(
			() => (
				<Whisper
					followCursor
					placement="bottom"
					speaker={<Tooltip>{children}</Tooltip>}
				>
					<Table.HeaderCell {...props}>
						<Overlayer
							style={{ overflow: "hidden" }}
							overlay={
								<OverlayContent ref={contentRef}>
									{children}
								</OverlayContent>
							}
						>
							<Content>{children}</Content>
						</Overlayer>
					</Table.HeaderCell>
				</Whisper>
			),
			[children, props, contentRef],
		);

		const notWhispered = useMemo(
			() => (
				<Table.HeaderCell {...props}>
					<Overlayer
						style={{ overflow: "hidden" }}
						overlay={
							<OverlayContent ref={contentRef}>
								{children}
							</OverlayContent>
						}
					>
						<Content>{children}</Content>
					</Overlayer>
				</Table.HeaderCell>
			),
			[children, props, contentRef],
		);

		return isWhispered ? whispered : notWhispered;
	},
);

declare namespace HeaderCell {
	interface Props<
		Row extends RowDataType = RowDataType,
		Key extends RowKeyType = RowKeyType,
	> extends HeaderCellProps<Row, Key> {
		hint?: boolean;
	}
}

export default HeaderCell;
