import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<{
	active?: boolean;
}>`
	width: 100%;

	padding: 16px;

	cursor: pointer;

	background-color: ${(props) => (props.active ? "#ebebeb" : "#ffffff")};

	&:not(:last-child) {
		border-bottom: 1px solid rgb(200, 207, 214);
	}

	&:hover {
		background: ${theme.colors.button_secondary};
	}
`;

export default Root;
