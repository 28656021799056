import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "uikit";

import { StyledRow } from "../../../../../../common";

interface Props {
	onClose: () => void;
	onSubmit: () => void;
}

const FooterButtons: React.FC<Props> = ({ onClose, onSubmit }) => {
	const { t } = useTranslation();
	return (
		<StyledRow
			justify="flex-end"
			gap="10px"
			style={{ padding: "12px 16px" }}
		>
			<Button.Button
				text={
					t("orderPageWidgets.chat.usersModal.footerButtons.str0") ??
					""
				}
				variant="secondary"
				onClick={onClose}
			/>
			<Button.Button
				text={
					t("orderPageWidgets.chat.usersModal.footerButtons.str1") ??
					""
				}
				onClick={onSubmit}
			/>
		</StyledRow>
	);
};

export default FooterButtons;
