import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";
import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";

const Footer: React.FC<Footer.Props> = ({ value, disabled, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const activeValue = valueEditor.useGetter("active");
	const activeOnChange = valueEditor.useSetter("active");

	const defaultValue = valueEditor.useGetter("default");
	const defaultOnChange = valueEditor.useSetter("default");

	const { t } = useTranslation();
	return (
		<Row gaps="24px">
			<CheckBoxWithContent
				gap="10px"
				disabled={disabled}
				value={activeValue}
				onChange={activeOnChange}
			>
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.footer.str150",
				) ?? ""}
			</CheckBoxWithContent>
			<CheckBoxWithContent
				gap="10px"
				disabled={disabled}
				value={defaultValue}
				onChange={defaultOnChange}
			>
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.footer.str151",
				) ?? ""}
			</CheckBoxWithContent>
		</Row>
	);
};

declare namespace Footer {
	interface Value {
		active: boolean;
		default: boolean;
	}

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Footer;
