import { useCallback } from "react";

import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import {
	State,
	archiveAction as archive,
} from "../../../../../redux/reducers/Archives";

export type UseArchiveFilters = {
	gpsFilters: State["gps"]["filters"];
	orderFilters: State["orders"]["filters"];
	setPagination: (data: State["orders"]["filters"]["pagination"]) => void;
	setFilter: (data: State["orders"]["filters"]["filter"]) => void;
	modifyFilter: (data: Partial<State["orders"]["filters"]["filter"]>) => void;
	modifyPagination: (
		data: Partial<State["orders"]["filters"]["pagination"]>,
	) => void;
	modifyInternalDateRange: (
		data: Partial<State["orders"]["filters"]["internalDateRange"]>,
	) => void;
};

/** `useArchiveFilters`
 ** Hook to get all the `filters`inside the redux store archive.
 ** And has functions for changing the state of `filters`.
 */
export const useArchiveFilters = (): UseArchiveFilters => {
	const dispatch = useTypedDispatch();
	const gpsFilters = useTypedSelector((state) => state.archiveGPSLog.filters);
	const orderFilters = useTypedSelector(
		(state) => state.archive.orders.filters,
	);

	const setPagination = useCallback(
		(data: State["orders"]["filters"]["pagination"]) => {
			dispatch(archive.orders.filters.setPagination(data));
		},
		[dispatch],
	);

	const setFilter = useCallback(
		(data: State["orders"]["filters"]["filter"]) => {
			dispatch(archive.orders.filters.setFilter(data));
		},
		[dispatch],
	);

	const modifyPagination = useCallback(
		(data: Partial<State["orders"]["filters"]["pagination"]>) => {
			dispatch(archive.orders.filters.modifyPagination(data));
		},
		[dispatch],
	);

	const modifyFilter = useCallback(
		(data: Partial<State["orders"]["filters"]["filter"]>) => {
			dispatch(archive.orders.filters.modifyFilter(data));
		},
		[dispatch],
	);

	const modifyInternalDateRange = useCallback(
		(data: Partial<State["orders"]["filters"]["internalDateRange"]>) => {
			dispatch(archive.orders.filters.modifyInternalDateRange(data));
		},
		[dispatch],
	);

	return {
		gpsFilters,
		orderFilters,
		setPagination,
		modifyInternalDateRange,
		modifyFilter,
		modifyPagination,
		setFilter,
	};
};

export default useArchiveFilters;
