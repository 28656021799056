import React, {
	Dispatch,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import moment from "moment";

import ExecutorRateClassifier from "../../../../../../../../../../../../services/ExecutorRateClassifier";
import ExecutorRateCommissionPlan from "../../../../../../../../../../../../services/ExecutorRateCommissionPlan";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import Root from "../../../../../../../../components/Root";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { createObjectLanguageNames } from "../../../../../../../../../../../../assets/languages/langs";
import TabRoot from "../TabRoot";

import Content from "./components/Content";
import EditModal from "./components/EditModal";

const sort = {};

const defaultCommission = {
	percent: 0,
	amount: 0,

	condition: {
		active: false,

		type: ExecutorRateCommissionPlan.Model.Rate.Commission.Threshold
			.Comparison.Direction.Equal,
		value: 0,

		percent: 0,
		amount: 0,
	},
};

const defaultCommissions = Object.values(
	ExecutorRateCommissionPlan.Model.Rate.Commission.Type,
).map((type) => ({ id: type, ...defaultCommission }));

const CommissionPlanTab: React.FC<CommissionPlanTab.Props> = memo(
	({ value, visible, onChange }) => {
		const { t } = useTranslation();
		const language = useTypedSelector((state) => state.session.language);

		const [classifiers, setClassifiers] = useState<
			ExecutorRateClassifier.Model[]
		>([]);

		const [selected, setSelected] = useState<Content.Item.Id[]>([]);
		const [showDeleteModal, setShowDeleteModal] = useState(false);
		const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
			null,
		);

		const valueEditor = useObjectEditor(value, onChange);

		const commissionPlans = valueEditor.useGetter("commissionPlans");

		const commissionPlansEditor =
			valueEditor.usePropertyEditor("commissionPlans");

		const editItem = useCallback(
			(id: Content.Item.Id) => {
				const commissionPlan = commissionPlans.find(
					(subscription) => subscription.id === id,
				);

				if (!commissionPlan) {
					return;
				}

				setEditingItem({
					id,
					createdAt: commissionPlan?.createdAt,
					name: commissionPlan.name[language],

					classifierId: commissionPlan.classifier?.id,

					startDate: {
						active: true,
						value: commissionPlan.startDate,
					},

					timeRange: {
						active: commissionPlan.timeRange.active,

						from: new Date(commissionPlan.timeRange.from),
						to: new Date(commissionPlan.timeRange.to),
					},

					mode: {
						active: true,
						value: commissionPlan.action,
					},

					weekdays: commissionPlan.weekdays,

					commissions:
						commissionPlan.rate?.commissions.map((commission) => ({
							id: commission.type,

							percent: commission.percent,
							amount: commission.amount,

							condition: {
								active: commission.threshold.active,

								type: commission.threshold.comparison.direction,
								value: commission.threshold.comparison.value,

								percent: commission.threshold.fee.percent,
								amount: commission.threshold.fee.amount,
							},
						})) ?? [],

					minFee: {
						active:
							commissionPlan.rate?.minCommission?.active ?? false,
						value: commissionPlan.rate?.minCommission?.amount ?? 0,
					},

					maxFee: {
						active:
							commissionPlan.rate?.maxCommission?.active ?? false,
						value: commissionPlan.rate?.maxCommission?.amount ?? 0,
					},
				});
			},
			[language, commissionPlans],
		);

		const defaultPageHeaderCanEdit = useMemo(
			() => selected.length === 1,
			[selected.length],
		);

		const defaultPageHeaderCanDelete = useMemo(
			() => selected.length !== 0,
			[selected.length],
		);

		const defaultPageHeaderCanCopy = useMemo(
			() => selected.length === 1,
			[selected.length],
		);

		const defaultPageHeaderOnAdd = useCallback(() => {
			setEditingItem({
				id: Symbol("commission plan id"),
				classifierId: undefined,
				startDate: {
					active: false,
					value: moment().startOf("day").toDate(),
				},

				timeRange: {
					active: false,
				},

				mode: {
					active: false,
					value: ExecutorRateCommissionPlan.Model.Action
						.AfterOrderClosed,
				},

				weekdays: {
					monday: true,
					tuesday: true,
					wednesday: true,
					thursday: true,
					friday: true,
					saturday: true,
					sunday: true,
				},

				commissions: defaultCommissions,

				minFee: {
					active: false,
					value: 0,
				},

				maxFee: {
					active: false,
					value: 0,
				},
			});
		}, []);

		const defaultPageHeaderOnEdit = useCallback(() => {
			editItem(selected[0]);
		}, [editItem, selected]);

		const defaultPageHeaderOnDelete = useCallback(() => {
			setShowDeleteModal(true);
		}, []);

		const defaultPageHeaderOnCopy = useCallback(() => {
			const subscription = commissionPlans.find(
				(subscription) => subscription.id === selected[0],
			);

			if (!subscription) {
				return;
			}

			const newSubscription = {
				...subscription,
				id: Symbol("subscription id"),
			};

			commissionPlansEditor.push(newSubscription);
		}, [selected, commissionPlans, commissionPlansEditor]);

		const editModalOnCancel = useCallback(() => {
			setEditingItem(null);
		}, []);

		const editModalOnSave = useCallback(
			(value: EditModal.Value.Validated) => {
				const item: CommissionPlanTab.Value.CommissionPlan = {
					id: value.id,

					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					classifier: classifiers.find(
						(classifier) => classifier.id === value.classifierId,
					)!,
					name: createObjectLanguageNames(value.name),
					// name: {
					// 	uk: value.name,
					// 	az: value.name,
					// 	tr: value.name,
					// 	en: value.name,
					// 	ru: value.name,
					// },

					startDate: value.startDate.value ?? new Date(),

					weekdays: value.weekdays,

					timeRange: {
						active: value.timeRange.active,

						from: value.timeRange.from?.getTime() ?? 0,
						to: value.timeRange.to?.getTime() ?? 0,
					},

					rate: {
						includeCurrentOrdersInDiscountedCommissions: false,

						minCommission: {
							active: value.minFee.active,
							amount: value.minFee.value ?? 0,
						},

						maxCommission: {
							active: value.maxFee.active,
							amount: value.maxFee.value ?? 0,
						},

						commissions: value.commissions.map((commission) => ({
							type: commission.id,

							percent: commission.percent,
							amount: commission.amount,

							threshold: {
								active: commission.condition.active ?? false,

								comparison: {
									direction:
										commission.condition.type ??
										ExecutorRateCommissionPlan.Model.Rate
											.Commission.Threshold.Comparison
											.Direction.Equal,
									value: commission.condition.value || 0,
								},

								fee: {
									percent: commission.condition.percent,
									amount: commission.condition.amount,
								},
							},
						})),
					},

					action:
						value.mode.value ??
						ExecutorRateCommissionPlan.Model.Action
							.AfterOrderClosed,

					active: true,

					createdAt: value.createdAt ?? new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					deletedAt: null,
				};

				const index = commissionPlansEditor.value.findIndex(
					(subscription) => subscription.id === value.id,
				);

				if (index === -1) {
					commissionPlansEditor.push(item);
				} else {
					commissionPlansEditor.set(index, item);
				}

				setEditingItem(null);
			},
			[classifiers, commissionPlansEditor],
		);

		const deleteModalOnCancel = useCallback(() => {
			setShowDeleteModal(false);
		}, []);

		const deleteModalOnConfirm = useCallback(() => {
			selected.forEach((id) => {
				const index = commissionPlansEditor.value.findIndex(
					(subscription) => subscription.id === id,
				);

				if (index !== -1) {
					commissionPlansEditor.remove(index);
				}
			});

			setShowDeleteModal(false);
		}, [selected, commissionPlansEditor]);

		useEffect(() => {
			ExecutorRateClassifier.getAll({}).then(setClassifiers);
		}, []);

		return (
			<TabRoot hasPaddings={false} visible={visible}>
				<Root sizes="auto! 1fr" maxedWidth maxedHeight>
					<DefaultPageHeader
						canEdit={defaultPageHeaderCanEdit}
						canDelete={defaultPageHeaderCanDelete}
						onAdd={defaultPageHeaderOnAdd}
						onDelete={defaultPageHeaderOnDelete}
						onEdit={defaultPageHeaderOnEdit}
						afterAdditionalButtons={[
							{
								icon: { id: "copy", size: 20 },
								onClick: defaultPageHeaderOnCopy,
								disabled: !defaultPageHeaderCanCopy,
							},
						]}
					/>
					<Content
						selected={selected}
						sort={sort}
						data={commissionPlans}
						loading={false}
						onChangeSelected={setSelected}
						onChangeSort={noop}
						onEdit={editItem}
						onLoadMore={noop}
					/>
					{editingItem && (
						<EditModal
							value={editingItem}
							language={language}
							onCancel={editModalOnCancel}
							onSave={editModalOnSave}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							label={
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.commissionPlanTab.str200",
								) ?? ""
							}
							onCancel={deleteModalOnCancel}
							onConfirm={deleteModalOnConfirm}
						/>
					)}
				</Root>
			</TabRoot>
		);
	},
);

declare namespace CommissionPlanTab {
	interface Value {
		commissionPlans: Value.CommissionPlan[];
	}

	namespace Value {
		interface CommissionPlan
			extends Omit<ExecutorRateCommissionPlan.Model, "id"> {
			id: number | symbol;
		}
	}

	interface Props {
		value: Value;

		visible: boolean;
		disabled?: boolean;

		onChange: Dispatch<Value>;
	}
}

export default CommissionPlanTab;
