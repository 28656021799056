import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import PropsOf from "uikit/src/types/PropsOf";
import styled from "styled-components";

import { AutoBreadcrumbs } from "../..";

import NavigationItem from "./NavigationItem";

const Container = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	padding-inline: 136px;
	height: 48px;
	flex-shrink: 0;
`;

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 64px;
	padding-inline: 136px;
	padding-block: 32px;
	overflow: hidden;
	overflow-y: auto;
`;

const NavigationLayout: React.FC<NavigationLayout.Props> = ({ links }) => {
	if (links.length === 0) return null;

	return (
		<Container>
			<Header>
				<AutoBreadcrumbs />
			</Header>
			<Content>
				{links.map((item, i) => (
					<NavigationItem key={i} {...item} />
				))}
			</Content>
		</Container>
	);
};

declare namespace NavigationLayout {
	export interface LinkStyled extends PropsOf<typeof Link> {
		disabled?: boolean;
	}

	export interface ImageStyled {
		colorizeFill?: boolean;
		colorizeStroke?: boolean;
		disabled?: boolean;
	}

	export interface ILink {
		text: string;
		Image: () => React.ReactElement;
		link: string;
		accessName: string;
		disabled?: boolean;
		colorizeFill?: boolean;
		colorizeStroke?: boolean;
	}

	export interface Props extends PropsWithChildren {
		links: ILink[];
	}
}

export default NavigationLayout;
