import { TFunction } from "i18next";
import translationPath from "./translationPath";

export default function getDeleteModalTitle(
	selectedLength: number,
	t: TFunction<"translation", undefined, "translation">,
) {
	const title =
		selectedLength === 1
			? t(`${translationPath}.deleteModal.title`)
			: t(`${translationPath}.deleteModal.title2`);
	if (title.includes("preferences.car_classes")) {
		return "Delete selected car class(-es)?";
	}
	return title;
}
