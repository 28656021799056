import React, {
	Dispatch,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, TextSelect } from "uikit";
import Language from "../../../../../../../../../../../../services/Language";
import Map from "../../../../../../../../../../../../services/Map";
import MapSearch from "../MapSearch";
import objectToLabel from "./utils/objectToLabel";

const Street: React.FC<Street.Props> = ({
	value,
	settlementObject,
	language,
	autoResolve = true,
	error,
	onChange,
	onSelectObject,
}) => {
	const { t } = useTranslation();
	const [result, setResult] = useState<MapSearch.Result>({
		loading: true,
	});

	const mapSearchOptions = useMemo<MapSearch.Options>(
		() => ({
			language,
			searchType: "street",
			country: "ua",
			query: value ?? "",
			near: settlementObject?.coordinates
				? {
						point: settlementObject.coordinates,
				  }
				: undefined,
		}),
		[language, settlementObject?.coordinates, value],
	);

	useLayoutEffect(() => {
		if (result.loading) return;

		if (result.objects[0]) onSelectObject?.(result.objects[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result]);

	const options = useMemo<Option<Map.Search.Object>[]>(() => {
		if (result.loading) return [];

		return result.objects
			.filter(
				(object) =>
					object.settlement === settlementObject?.settlement &&
					object.settlementType === settlementObject?.settlementType,
			)
			.map((object) => ({
				key: object.id,
				label: objectToLabel(object),
				value: object,
			}));
	}, [
		result,
		settlementObject?.settlement,
		settlementObject?.settlementType,
	]);

	const textSelectOnSelect = useCallback(
		(option: Option<Map.Search.Object>) => {
			onSelectObject?.(option.value);
		},
		[onSelectObject],
	);

	return (
		<>
			<TextSelect
				value={value}
				placeholder={
					t(
						"pages.preferencesPages.screenDirectory.objects.editModal.pointForm.street.str0",
					) ?? ""
				}
				options={autoResolve ? options : undefined}
				error={error}
				onChange={onChange}
				onSelect={textSelectOnSelect}
			/>
			{autoResolve && settlementObject && (
				<MapSearch options={mapSearchOptions} onChange={setResult} />
			)}
		</>
	);
};

declare namespace Street {
	type Value = string;

	interface Props {
		value?: Value;

		settlementObject?: Map.Search.Object;
		language?: Language;
		autoResolve?: boolean;
		error?: boolean;

		onChange?: Dispatch<Value>;

		onSelectObject?: Dispatch<Map.Search.Object>;
	}
}

export default Street;
