import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { TextBox } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledBlock from "../../../../../../../../../../../../components/LabeledBlock";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const phoneValue = valueEditor.useGetter("phone");
	const phoneOnChange = valueEditor.useSetter("phone");

	const fromValue = valueEditor.useGetter("from");
	const fromOnChange = valueEditor.useSetter("from");

	const toValue = valueEditor.useGetter("to");
	const toOnChange = valueEditor.useSetter("to");

	return (
		<Root gaps="16px*">
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str0",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={phoneValue}
					placeholder={`${
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str200",
						) ?? ""
					}...`}
					onChange={phoneOnChange}
				/>
			</LabeledBlock>
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str1",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={toValue}
					placeholder={`${
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str200",
						) ?? ""
					}...`}
					onChange={toOnChange}
				/>
			</LabeledBlock>
			<LabeledBlock
				label={
					t(
						"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str2",
					) ?? ""
				}
			>
				<TextBox.TextBox
					value={fromValue}
					placeholder={`${
						t(
							"pages.mainPage.pages.orders.orderHeader.firstRow.filterOrdersModal.content.str200",
						) ?? ""
					}...`}
					onChange={fromOnChange}
				/>
			</LabeledBlock>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		phone: string;
		from: string;
		to: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
