import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Icon, InputGroup, Row, theme } from "uikit";
import SearchIconBorders from "../../../../../SearchIconBorders";
import SearchTextBox from "../../../../../SearchTextBox";

const SearchBarContainer = styled(Row)`
	padding: 8px;

	height: 48px;

	position: fixed;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: ${theme.colors.white};
	border-bottom: 1px solid ${theme.colors.color_border_basic};
`;

const InputGroupStyled = styled(InputGroup.InputGroup)`
	width: 234px;
`;

const SearchBar = ({ value, onChange, onChangeCallsFilter, showAllCalls }) => {
	const { t } = useTranslation();
	return (
		<SearchBarContainer gaps="8px">
			<InputGroupStyled sizes="auto 1fr">
				<SearchIconBorders style={{ width: 26, position: "relative" }}>
					<Icon
						id="search"
						size={20}
						colors={[theme.colors.disabled_text]}
						style={{ position: "absolute", top: 6, left: 10 }}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t("softphoneWidget.latestTab.searchBar.str200") ?? ""
					}...`}
					value={value}
					onChange={onChange}
				/>
			</InputGroupStyled>
			<Button.Button
				onClick={onChangeCallsFilter}
				icon={
					<Icon
						id={showAllCalls ? "phone-users" : "phone-user"}
						size={showAllCalls ? 30 : 26}
					/>
				}
				variant="secondary"
			/>
		</SearchBarContainer>
	);
};

export default SearchBar;
