import styled from "styled-components";

const Root = styled.div`
	padding-inline: 12px;

	font-family: Lato;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;

	color: #bcc1c5;

	cursor: pointer;

	user-select: none;
`;

export default Root;
