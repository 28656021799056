import { useEffect } from "react";

import getCompanies from "../../../../../redux/services/Company/getCompanies";
import { useTypedDispatch } from "../../../../../redux/store";

/**
 *  `useGetCompanies`
 */
export const useGetCompanies = (): void => {
	const dispatch = useTypedDispatch();
	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);
};

export default useGetCompanies;
