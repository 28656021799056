import { useCallback } from "react";
import moment from "moment-timezone";
import { isString } from "lodash";

import { useTypedSelector } from "../redux/store";

function useFormatDate() {
	const timezoneName = useTypedSelector(
		(state) => state.applicationConfig.timezone.name,
	);

	const formatDate = useCallback(
		(date: moment.MomentInput, format: string) => {
			const momentDate = moment(date);

			if (isString(timezoneName)) momentDate.tz(timezoneName);

			return momentDate.format(format);
		},
		[timezoneName],
	);

	return formatDate;
}

export default useFormatDate;
