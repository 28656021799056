import styled from "styled-components";
import { Column } from "uikit";

const Root = styled(Column)`
	overflow: hidden;
	width: 700px;
	height: auto;
`;

export default Root;
