import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const Notification = styled.div<{ type: string; isExiting: boolean }>`
	position: fixed;
	right: 70px;
	background-color: ${(props) => {
		switch (props.type) {
			case "success":
				return "#52c41a";
			case "info":
				return "#1890ff";
			case "warn":
				return "#faad14";
			case "error":
				return "#f5222d";
			default:
				return "#ffffff";
		}
	}};
	color: #fff;
	padding: 16px;
	border-radius: 4px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	min-width: 300px;
	max-width: 400px;
	font-family: Arial, sans-serif;
	animation: ${(props) => (props.isExiting ? fadeOut : fadeIn)} 0.5s forwards;
`;

interface NotificationContainerProps {
	type: "success" | "info" | "warn" | "error";
	message: string;
	description: string;
	style: React.CSSProperties;
	isExiting: boolean;
}

const NotificationContainer: React.FC<NotificationContainerProps> = ({
	type,
	message,
	description,
	style = {},
	isExiting = false,
}) => (
	<Notification type={type} style={style} isExiting={isExiting}>
		<strong>{message}</strong>
		<p>{description}</p>
	</Notification>
);

export default NotificationContainer;
