import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "uikit";

import Settings, { SettingsTab } from "../../../../components/Settings";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";

import General from "./General";

const VoipSettings = () => {
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();
	const [hasChanges, setHasChanges] = useState(false);

	const tabs = useMemo<Option<SettingsTab>[]>(
		() => [
			{
				key: "general",
				label: t("settings.voip.general.title"),
				value: {
					render() {
						return (
							<General
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						);
					},
				},
			},
		],
		[t],
	);

	return (
		<Settings
			tabs={tabs}
			methods={methods}
			hasChanges={hasChanges}
			onSetHasChanges={setHasChanges}
		/>
	);
};

export default VoipSettings;
