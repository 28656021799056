import React, { memo, useMemo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { History } from "../../../../../../redux/services/Order/getHistory";
import getArrayChanges from "../../../../../../utils/getArrayChanges";

import Mark from "../../Mark";

const CustomerDiff: React.FC<CustomerDiff.Props> = ({
	change,
}): JSX.Element => {
	const { t } = useTranslation();

	const pointDiff = getArrayChanges(change.previous, change.actual, {
		useChanged: true,
	});

	const getName = useCallback(
		(
			passengerCustomer: History.Field.PassengerCustomer,
			fallback?: string,
		) => {
			const payload =
				[
					passengerCustomer?.customer?.surname,
					passengerCustomer?.customer?.name,
					passengerCustomer?.customer?.fatherName,
				]
					.filter((name) => !!name)
					.join(" ") || fallback;

			return payload;
		},
		[],
	);

	const unnamed = useMemo(() => t(`unnamed`), [t]);

	return (
		<>
			{pointDiff.removedItems.map((customer, i) => {
				const name = getName(customer, unnamed);

				return (
					<div key={i}>
						<Trans
							i18nKey={`change_history.orders.fields.customer`}
							context="remove"
							values={{ value: name }}
							components={{ mark: <Mark /> }}
						/>
					</div>
				);
			})}

			{pointDiff.changedItemIndexes.map((index, i) => {
				const prevName = getName(change.previous[index], unnamed);
				const actualName = getName(change.actual[index], unnamed);

				return (
					<div key={i}>
						<Trans
							i18nKey={`change_history.orders.fields.customer`}
							context="change"
							values={{
								prev: prevName,
								new: actualName,
							}}
							components={{ mark: <Mark /> }}
						/>
					</div>
				);
			})}

			{pointDiff.addedItems.map((customer, i) => {
				const name = getName(customer, unnamed);

				return (
					<div key={i}>
						<Trans
							i18nKey={`change_history.orders.fields.customer`}
							context="add"
							values={{ value: name }}
							components={{ mark: <Mark /> }}
						/>
					</div>
				);
			})}
		</>
	);
};

declare namespace CustomerDiff {
	interface Props {
		change: History.Change & History.Field.PassengerCustomerChange;
	}
}

export const CustomerDiffMemo = memo(CustomerDiff);
export default CustomerDiff;
