import React, { Dispatch, SetStateAction, memo, useState } from "react";
import { Button, Icon, Row } from "uikit";
import { useDebouncedCallback } from "use-debounce";

import CarClass from "../../../../../../../../services/CarClass";
import { ModelId } from "../../../../../../../../types/ModelId";

const Filters: React.FC<Filters.Props> = memo(
	({ models, selectedId, selectedIndex }) => {
		const [counter, setCounter] = useState(0);

		const moveUp = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex === null) return;
			const dowItem = models[selectedIndex - counter];
			const upItem = models[selectedIndex];

			// up
			await CarClass.updatePosition({
				id: upItem.id,
				position: dowItem.position,
				name: upItem.name,
			});
			// dow
			await CarClass.updatePosition({
				id: dowItem.id,
				position: upItem.position,
				name: dowItem.name,
			});
			setCounter(0);
		}, 100);

		const moveDown = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex === null) return;

			const dowItem = models[selectedIndex];
			const upItem = models[selectedIndex + counter];

			// dow
			await CarClass.updatePosition({
				id: dowItem.id,
				position: upItem.position,
				name: dowItem.name,
			});
			// up
			await CarClass.updatePosition({
				id: upItem.id,
				position: dowItem.position,
				name: upItem.name,
			});
			setCounter(0);
		}, 100);

		const moveToTop = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex === null) return;

			const firstItem = models[0];
			const upItem = models[selectedIndex];

			// first
			await CarClass.updatePosition({
				id: upItem.id,
				position: firstItem.position,
				name: upItem.name,
			});
			// last
			await CarClass.updatePosition({
				id: firstItem.id,
				position: upItem.position,
				name: firstItem.name,
			});
		}, 100);

		const moveToBottom = useDebouncedCallback(async () => {
			if (!selectedId || selectedIndex === null) return;
			const dowItem = models[selectedIndex];
			const lastItem = models.at(-1);
			if (!lastItem) return;
			// last
			await CarClass.updatePosition({
				id: dowItem.id,
				position: lastItem.position,
				name: dowItem.name,
			});
			// first
			await CarClass.updatePosition({
				id: lastItem.id,
				position: dowItem.position,
				name: lastItem.name,
			});
		}, 100);

		return (
			<Row gaps="5px*">
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-up" size={23} />}
					onClick={() => {
						if (selectedIndex && counter < selectedIndex) {
							setCounter((prev) => prev + 1);
						}
						moveUp();
					}}
					disabled={!selectedId || selectedIndex === 0}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-down" size={23} />}
					onClick={() => {
						if (
							counter <
							(selectedIndex != null
								? models.length - 1 - selectedIndex
								: NaN)
						) {
							setCounter((prev) => prev + 1);
						}
						moveDown();
					}}
					disabled={
						!selectedId || selectedIndex === models.length - 1
					}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-top" size={23} />}
					onClick={moveToTop}
					disabled={!selectedId || selectedIndex === 0}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="arrow-bottom" size={23} />}
					onClick={moveToBottom}
					disabled={
						!selectedId || selectedIndex === models.length - 1
					}
				/>
			</Row>
		);
	},
);

declare namespace Filters {
	interface Props {
		models: CarClass.Model[];
		selectedId: ModelId | false;
		setSelected: Dispatch<SetStateAction<ModelId[]>>;
		selectedIndex: number | null;
	}
}

export default Filters;
