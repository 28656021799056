import { RefObject } from "react";
import { react } from "uikit";

interface Context {}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		return !!this;
	}
}
