/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";
import Root from "./components/Root";
import MainTab from "./components/MainTab";
import Language from "../../../../../../../../../../../../services/Language";
import InternalController from "./Controller";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, type, language, currentIP, onChange }) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						visible={type === "main"}
						language={language}
						onChange={() => ""}
					/>
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				type,
				language,
				anyTabOnChange,
				currentIP,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type = "main";

	type Value = MainTab.Value;
	interface PropsBase {
		value: Value;

		type: Type;
		language: Language;
		currentIP: string;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
