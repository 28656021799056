import makeTable from "./makeTable";

const orderArchiveModal = makeTable({
	allIds: ["id", "city", "street", "house", "entrance", "polygon", "setting"],
	i18tPath: (id) =>
		id === "setting" ? "" : `tableColumns.orderArchiveModal.${id}`,

	initialSortMethod: {
		column: "id",
		type: "desc",
	},

	initialWidths: {
		id: 36,
		city: 100,
		street: 440,
		house: 50,
		entrance: 50,
		polygon: 85,
		setting: 62,
	},
});

export default orderArchiveModal;
