import React, { Dispatch, memo } from "react";
import List from "./components/List";

const CommissionsBase: React.FC<Commissions.Props> = ({ value, onChange }) => (
	<List value={value} onChange={onChange} />
);

const Commissions = memo(CommissionsBase);

declare namespace Commissions {
	type Value = List.Value;

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Commissions;
