import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import LightInputModal from "../../../../../../components/LightInputModal";

import Content from "./components/Content";

const CopyOrderModal: React.FC<CopyOrderModal.Props> = ({
	onSubmit,
	...props
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState<Content.Value>({
		amount: 1,
		type: "all-points",
		pointsAmount: 1,
	});

	const modalOnSubmit = useCallback(() => {
		onSubmit?.(value);
	}, [onSubmit, value]);

	return (
		<LightInputModal
			{...props}
			title={t("pages.mainPage.pages.orders.copyOrderModal.str0") ?? ""}
			onSubmit={modalOnSubmit}
		>
			<Content value={value} onChange={setValue} />
		</LightInputModal>
	);
};

declare namespace CopyOrderModal {
	type Value = Content.Value;

	interface Props extends Omit<LightInputModal.Props, "title" | "onSubmit"> {
		onSubmit?: Dispatch<Value>;
	}
}

export default CopyOrderModal;
