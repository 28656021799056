import React, { memo, useCallback, useMemo } from "react";

import { History } from "../../../../../../redux/services/Order/getHistory";

import ChangedPassengers from "./components/ChangedPassengers";

const PassengerPointDiff: React.FC<PassengerPointDiff.Props> = ({ change }) => {
	const equalFeature = useCallback<PassengerPointDiff.Check>(
		(actual, old) => {
			const actualFeature = JSON.stringify(actual.feature);
			const oldFeature = JSON.stringify(old.feature);
			const isEqual = actualFeature === oldFeature;
			return isEqual;
		},
		[],
	);

	const equalPoint = useCallback<PassengerPointDiff.Check>((actual, old) => {
		const isEqual = [
			actual?.point?.lat === old?.point?.lat,
			actual?.point?.lng === old?.point?.lng,
		].every((item) => item === true);
		return isEqual;
	}, []);

	const getPoints = useCallback<PassengerPointDiff.GetPoints>(
		(first, second, opt = {}) => {
			const { isUpdate, isAdd } = opt;

			const prev = first.map((item) => item.passengerPoints);
			const actual = second.map((item) => item.passengerPoints);

			const payload = actual.map((items, i) => {
				const points = items.filter((item) => {
					const exist = prev[i].find((prevItem) => {
						const equal = equalPoint(item, prevItem);
						if (!equal && isUpdate) {
							const ef = equalFeature(item, prevItem);
							return isAdd ? ef : !ef;
						}
						return equal;
					});
					return isAdd ? !exist : exist;
				});
				const passengerName = `#${i + 1}`;
				return { passengerName, points };
			});
			return payload;
		},
		[equalFeature, equalPoint],
	);

	const getChanged = useCallback<PassengerPointDiff.GetChanged>(
		(actual, old) => {
			const items: ChangedPassengers.PassengerPoints = [];
			const pointsMap: Map<string, ChangedPassengers.PassengerPoint> =
				new Map();

			try {
				const toChange = getPoints(actual, old, {
					isUpdate: true,
					isAdd: false,
				});
				const withChange = getPoints(old, actual, {
					isUpdate: false,
					isAdd: false,
				});

				const addedItems = getPoints(actual, old, { isAdd: true });
				const removedItems = getPoints(old, actual, { isAdd: true });

				const length = Math.max(toChange?.length, withChange?.length);
				const arrPassengers = new Array(length).fill(1);

				arrPassengers.forEach((_, i) => {
					const changed: ChangedPassengers.PointDiff["changedItems"] =
						[];
					const added: ChangedPassengers.PointDiff["addedItems"] = [];
					const removed: ChangedPassengers.PointDiff["removedItems"] =
						[];

					const remove = removedItems[i]?.points;
					const add = addedItems[i]?.points;
					const item = withChange[i]?.points;
					const to = toChange[i]?.points;

					const passengerName =
						removedItems[i]?.passengerName ||
						addedItems[i]?.passengerName ||
						toChange[i]?.passengerName ||
						withChange[i]?.passengerName;

					if (add.length) added.push(...add);
					if (remove.length) removed.push(...remove);

					if (!item.length) return;
					if (!to.length) return;

					const length = Math.max(item.length, to.length);
					const arrPoints = new Array(length).fill(1);

					arrPoints.forEach((_, p) => {
						const point = { withChange: item[p], toChange: to[p] };
						if (!point.withChange) return;
						if (!point.toChange) return;
						changed.push(point);
					});

					pointsMap.set(passengerName, {
						passengerName,
						pointDiff: {
							changedItems: changed,
							addedItems: added,
							removedItems: removed,
						},
					});
				});

				pointsMap.forEach((item) => items.push(item));
			} catch {
				return items;
			}

			return items;
		},
		[getPoints],
	);

	const pointsChange = useMemo(() => {
		const items: ChangedPassengers.PassengerPoints = getChanged(
			change.previous,
			change.actual,
		);
		return items;
	}, [change.actual, change.previous, getChanged]);

	return (
		<ChangedPassengers
			passengerPoints={pointsChange}
			key={`${change.field}-${change.type}`}
		/>
	);
};

declare namespace PassengerPointDiff {
	interface Props {
		change: History.Field.PassengerPointChange;
	}

	type PassengerPoint = History.Field.PassengerPoint;
	type PointChange = PassengerPoint["passengerPoints"];
	type Feature = PassengerPoint["passengerPoints"][0]["feature"];

	type Check = (actual: PointChange[0], old: PointChange[0]) => boolean;

	type Point = {
		passengerName: string;
		points: PointChange;
	};

	type UpdatedPoint = {
		withChange: Point;
		toChange: Point;
	};

	type UpdatedPoints = UpdatedPoint[];

	type GetPoints = (
		first: PassengerPoint[],
		second: PassengerPoint[],
		opt?: { isAdd?: boolean; isUpdate?: boolean },
	) => Point[];

	type GetChanged = (
		first: PassengerPoint[],
		second: PassengerPoint[],
	) => ChangedPassengers.PassengerPoints;
}

export const PassengerPointDiffMemo = memo(PassengerPointDiff);
export default PassengerPointDiff;
