import React, { useMemo } from "react";
import { theme } from "uikit";

import Order from "../../../../../../../services/Order";
import { useTypedSelector } from "../../../../../../../redux/store";
import OrderStatus from "../../../../../../../types/OrderStatus";
import { ShowCustomerPhoneNumbersType } from "../../../../../../../pages/Settings/pages/Orders/tabs/Orders/components/Modal/components/Content/tabs/Main/components/ShowCustomerPhoneNumbers";
import CellContentRoot from "../CellContentRoot";

const PhoneCellContent: React.FC<PhoneCellContent.Props> = ({ item }) => {
	const orderSettings = useTypedSelector((state) => state.settings.order);

	const showCustomerPhoneNumbers = useMemo(
		() =>
			orderSettings?.general?.showCustomerPhoneNumbers ?? {
				active: false,
			},
		[orderSettings?.general?.showCustomerPhoneNumbers],
	);

	const isOrderCreatingOrCreated = useMemo(
		() =>
			item.status === OrderStatus.CREATED ||
			item.status === OrderStatus.CREATING,
		[item.status],
	);

	const content = useMemo(() => {
		const { phones } = item;
		return phones?.at(-1)?.number ?? "";
	}, [item]);

	const backgroundColor = useMemo(
		() =>
			isOrderCreatingOrCreated ? theme.colors.disabled_text : undefined,
		[isOrderCreatingOrCreated],
	);

	const textColor = useMemo(
		() => (isOrderCreatingOrCreated ? theme.colors.white : undefined),
		[isOrderCreatingOrCreated],
	);

	const highlighted = useMemo(
		() =>
			showCustomerPhoneNumbers.active &&
			showCustomerPhoneNumbers.type ===
				ShowCustomerPhoneNumbersType.Sometimes &&
			showCustomerPhoneNumbers.conditions.showManually &&
			(item.additionalFields.displaySettings?.showCustomerPhone ?? false),
		[
			item.additionalFields.displaySettings?.showCustomerPhone,
			showCustomerPhoneNumbers.active,
			showCustomerPhoneNumbers.conditions.showManually,
			showCustomerPhoneNumbers.type,
		],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			highlighted={highlighted}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace PhoneCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default PhoneCellContent;
