/* eslint-disable no-nested-ternary */
import React, { memo, useMemo, useState, useCallback } from "react";
import { Column } from "uikit";
import { SortType } from "rsuite-table";

import { useTypedSelector } from "../../../../../../redux/store";
import TaxiService from "../../../../../../services/TaxiService2";
import Call from "../../../../../../services/Call";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";

import Header from "./components/Header";
import Content from "./components/Content";

interface Filter {
	sort: {
		column: string | undefined;
		type: SortType | undefined;
	};
}

const CallsHistory: React.FC<CallsHistory.Props> = memo(() => {
	const [selected, setSelected] = useState<number[]>([]);
	const [limit, setLimit] = useState<number>(40);
	const {
		companyFilter,
		taxiServiceFilter,
		dateFrom: from,
		dateTo: to,
		searchQuery: query,
	} = useTypedSelector((state) => state.archiveCalls.filter);

	const taxiServices = useModelSubscribe2({}, TaxiService);

	const taxiServicesModels = useMemo(
		() => taxiServices?.models || [],
		[taxiServices],
	);

	const taxiServiceIds = useMemo(
		() =>
			taxiServiceFilter[0] !== "all"
				? (taxiServiceFilter as number[])
				: companyFilter[0] !== "all"
				? taxiServicesModels
						?.filter(
							(taxiService) =>
								taxiService.company?.id &&
								(companyFilter as number[]).includes(
									taxiService.company.id,
								),
						)
						.map((taxiService) => taxiService.id)
				: undefined,
		[companyFilter, taxiServiceFilter, taxiServicesModels],
	);

	const [filter, setFilter] = useState<Filter>({
		sort: {
			column: undefined,
			type: undefined,
		},
	});

	const onSortTable = useCallback((column: string, type: SortType) => {
		setFilter((prev) => ({ ...prev, sort: { column, type } }));
	}, []);

	const options = useMemo<Call.SubscribeOptions>(
		() => ({
			dateRange: { from, to },
			taxiServiceIds,
			query,
			limit,
		}),
		[from, query, taxiServiceIds, to, limit],
	);

	const subscribeCall = useModelSubscribe2(options, Call);

	const calls = useMemo(() => subscribeCall?.models || [], [subscribeCall]);

	return (
		<Column gaps="5px*" sizes="auto 1fr" maxedWidth maxedHeight>
			<Header />
			<Content
				value={calls}
				selected={selected}
				setSelected={setSelected}
				setLimit={setLimit}
				sort={filter.sort}
				onSort={onSortTable}
			/>
		</Column>
	);
});

declare namespace CallsHistory {
	interface Props {}
}

export default CallsHistory;
