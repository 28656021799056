/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { Float, Icon } from "uikit";

import Button from "../../../../../components/Button";

import InternalPopover from "./components/Popover";

const Filters: React.FC<Filters.Props> = ({ value, onChange }) => {
	const [isButtonLocked, setIsButtonLocked] = useState(false);
	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const buttonOnClick = useCallback(() => {
		openAdditionalFilters
			? undefined
			: !isButtonLocked && setOpenAdditionalFilters(true);
	}, [isButtonLocked, openAdditionalFilters]);

	const popoverOnClose = useCallback(() => {
		setOpenAdditionalFilters(false);
	}, []);

	useEffect(() => {
		const unblockHandler = () => {
			setIsButtonLocked(false);
		};

		document.addEventListener("click", unblockHandler);

		return () => {
			document.removeEventListener("click", unblockHandler);
		};
	}, []);

	return (
		<>
			<Float.Tracker id="filters">
				<Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={buttonOnClick}
					onMouseDown={() => {
						if (openAdditionalFilters) setIsButtonLocked(true);
					}}
				/>
			</Float.Tracker>
			<InternalPopover
				value={value}
				open={openAdditionalFilters}
				onChange={onChange}
				onClose={popoverOnClose}
			/>
		</>
	);
};

declare namespace Filters {
	type Value = Popover.Value;

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}

	namespace Popover {
		type Value = InternalPopover.Value;
	}
}

export default Filters;
