import styled from "styled-components";
import { HR } from "uikit";

const Stroke = styled(HR)`
	width: auto;

	margin-left: 36px;
`;

export default Stroke;
