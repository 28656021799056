import React, { useCallback, useMemo } from "react";
import { ToggleButton } from "uikit";

import CarClass from "../../../../../../../../../../services/CarClass";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import { StyledRow } from "../../../../../../../../../../components/common";

const ActiveCellContent: React.FC<ActiveCellContent.Props> = ({ item }) => {
	const active = useMemo(() => item.active, [item]);
	const isDefault = useMemo(() => item.default, [item]);
	const id = useMemo(() => item.id, [item]);

	const update = useCallback(
		(value: boolean) =>
			CarClass.updateActiveStatus({
				id,
				active: value,
			}),
		[id],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			<StyledRow onClick={(event) => event.stopPropagation()}>
				<ToggleButton.ToggleButton
					disabled={isDefault}
					value={active}
					onChange={update}
				/>
			</StyledRow>
		</CellContentRoot>
	);
};

declare namespace ActiveCellContent {
	interface Props {
		item: CarClass.Model;
	}
}

export default ActiveCellContent;
