/* eslint-disable @typescript-eslint/no-non-null-assertion */

import File from "../services/File";

async function fileToIds(files: File.Model[]) {
	return (await Promise.all(files.map((file) => file.upload())))
		.filter(Boolean)
		.map((file) => file!.id!);
}

export default fileToIds;
