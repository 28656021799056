import styled from "styled-components";
import { Row } from "uikit";

const Root = styled(Row)`
	padding: 8px 24px;

	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
`;

export default Root;
