import { isObject } from "lodash";

import { DisplayFields, AccessKey } from ".";

/**
 * Merges the system's default access tree with the user's "super role",
 * creating a comprehensive access structure for the user.
 *
 * In this system, there exists a default role hierarchy that represents
 * the most up-to-date and complete access permissions for the system.
 * The user's "super role" is the result of merging multiple roles into one,
 * but it may contain outdated access permissions or deprecated branches.
 *
 * This function overlays the user's super role onto the default role tree,
 * creating what can be referred to as a "super super role" — a final,
 * combined role where the user's permissions are aligned with the system's
 * most recent structure. If the user has outdated or unused permissions,
 * they will be filtered out, and any new access restrictions from the system
 * will be applied to the final merged role.
 *
 * Importantly, any restrictions present in the default role tree will override
 * permissions in the user's super role, ensuring that the resulting role respects
 * the system's current limitations.
 *
 * @param defaultData - The system's default role tree, representing the most up-to-date version.
 * @param subData - The user's super role, potentially containing older access values.
 * @returns A merged DisplayFields object where the user's role has been adjusted
 * to align with the system's current default role tree.
 */

const mergeAccessData = (
	defaultData: DisplayFields,
	subData: DisplayFields,
): DisplayFields => {
	const merge = (
		defaultObj: DisplayFields,
		subObj: DisplayFields,
	): DisplayFields => {
		const result: DisplayFields = { ...defaultObj };

		const isValidObject = (value: any): boolean =>
			isObject(value) && value !== null;

		if (!isValidObject(subObj)) {
			return result;
		}

		Object.keys(subObj).forEach((key) => {
			if (key === AccessKey.SHOW) {
				result[key] = subObj[key];
			} else if (
				isValidObject(subObj[key]) &&
				isValidObject(result[key])
			) {
				result[key] = merge(
					result[key] as DisplayFields,
					subObj[key] as DisplayFields,
				);
			}
		});

		return result;
	};

	return merge(defaultData, subData);
};

export default mergeAccessData;
