import { Option } from "uikit";
import { TFunction } from "i18next";

import translationPath from "./translationPath";

export enum ModalTabs {
	MAIN = "main",
	SERVICES = "services",
}

export const getTabs = (t: TFunction): Option<string>[] => [
	{
		key: ModalTabs.MAIN,
		value: ModalTabs.MAIN,
		label: t(`${translationPath}.tabs.main`) ?? "",
	},
	{
		key: ModalTabs.SERVICES,
		value: ModalTabs.SERVICES,
		label: t(`${translationPath}.tabs.service`) ?? "",
	},
]; // tabs
