import React, { RefAttributes, memo, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import CarClass from "../../../../../../../../../../services/CarClass";
import Tariff from "../../../../../../../../../../services/Tariff";
import { MainTariffTabs } from "../../tabs";

import MainTab from "./tabs/MainTab";
import ServicesTab from "./tabs/ServicesTab";
import TariffScaleTab from "./tabs/TariffScaleTab";
import FixedTariffsTab from "./tabs/FixedTariffsTab";
import Root from "./components/Root";
import InternalController from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({
		defaultTariffWithAllCarClassesAndOneBranch,
		tab,
		value,
		onChange,
		disabled,
		isDefault,
		carClasses,
		root,
		controller,
		tariffs,
	}) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);
		controller.setContext({ mainTabRef });

		const mainValueEditor = useObjectEditor(value, onChange);

		const mainTabValuesWithoutGeneral = mainValueEditor.usePicker(
			[
				"name",
				"taxiServiceIds",
				"taxiServices",
				"carClassIds",
				"companyIds",
			],
			"json",
		);
		const updateAllMainTabValues = mainValueEditor.useAssigner();

		const additionalFields = mainValueEditor.useGetter("additionalFields");
		const updateAdditionalFields =
			mainValueEditor.useSetter("additionalFields");

		const additionalFieldsEditor = useObjectEditor(
			additionalFields,
			updateAdditionalFields,
		);
		const generalSettings = additionalFieldsEditor.useGetter("general");
		const setGeneralSettings = additionalFieldsEditor.useSetter("general");

		const rateGrid = additionalFieldsEditor.useGetter("rateGrid");
		const setRateGrid = additionalFieldsEditor.useSetter("rateGrid");

		const fixedRate = additionalFieldsEditor.useGetter("fixedRate");
		const setFixedRate = additionalFieldsEditor.useSetter("fixedRate");

		const taxiServiceIds = mainValueEditor.useGetter("taxiServiceIds");
		const services = mainValueEditor.useGetter("services");
		const setServices = mainValueEditor.useSetter("services");

		const actualTab = useMemo(
			() => (
				<>
					<MainTab
						defaultTariffWithAllCarClassesAndOneBranch={
							defaultTariffWithAllCarClassesAndOneBranch
						}
						ref={setMainTabRef}
						root={root}
						carClasses={carClasses}
						visible={tab === MainTariffTabs.MAIN}
						value={mainTabValuesWithoutGeneral}
						generalSettings={generalSettings}
						updateGeneralSettings={setGeneralSettings}
						onChange={updateAllMainTabValues}
						disabled={disabled}
						isDefault={isDefault}
						tariffs={tariffs}
					/>
					<ServicesTab
						root={root}
						visible={tab === MainTariffTabs.SERVICES}
						initialServices={services}
						onChange={setServices}
						disabled={disabled}
						taxiServiceIds={taxiServiceIds}
					/>
					<TariffScaleTab
						visible={tab === MainTariffTabs.TARIFF_SCALE}
						value={rateGrid}
						onChange={setRateGrid}
						disabled={disabled}
					/>
					<FixedTariffsTab
						value={fixedRate}
						onChange={setFixedRate}
						visible={tab === MainTariffTabs.FIXED_TARIFFS}
						disabled={disabled}
					/>
				</>
			),
			[
				carClasses,
				defaultTariffWithAllCarClassesAndOneBranch,
				disabled,
				fixedRate,
				generalSettings,
				isDefault,
				mainTabValuesWithoutGeneral,
				rateGrid,
				root,
				services,
				setFixedRate,
				setGeneralSettings,
				setMainTabRef,
				setRateGrid,
				setServices,
				tab,
				updateAllMainTabValues,
				tariffs,
				taxiServiceIds,
			],
		);

		return <Root>{actualTab}</Root>;
	},
	InternalController,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		defaultTariffWithAllCarClassesAndOneBranch?: Tariff.Model;
		tariffs: Tariff.Model[];
		value: Value;
		onChange: (x: Value) => void;
		tab: MainTariffTabs;
		disabled?: boolean;
		isDefault: boolean;
		carClasses: CarClass.Model[];
		root: boolean;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends MainTab.Value {
		additionalFields: AdditionalFields;
		services: ServicesTab.ServiceType[];
	}

	interface AdditionalFields {
		general: MainTab.GeneralSettings;
		rateGrid: TariffScaleTab.Value;
		fixedRate: FixedTariffsTab.Value;
	}
}

export default Content;
