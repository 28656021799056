import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { SideTab } from "../../../../components/common";

import PushNotices from "./PushNotices";
import Templates from "./Templates";
import Provider from "./Provider";
import { TabKeys, TabAccessNames } from "./constants/access";

const MessageSettings: React.FC = () => {
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [, setHasChanges] = useState(false);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.PROVIDER_SMS,
					label: t("settings.messages.providerSms.title"),
					value: {
						render() {
							return (
								<Provider
									methods={(communicationMethods) => {
										methods.current = communicationMethods;
									}}
									onHasChanges={setHasChanges}
								/>
							);
						},
					},
					accessName: TabAccessNames[TabKeys.PROVIDER_SMS],
				},
				{
					key: TabKeys.TEMPLATES_SMS,
					label: t("settings.messages.templatesSms.title"),
					value: {
						render: () => (
							<Templates
								methods={() => ""}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.TEMPLATES_SMS],
				},
				{
					key: TabKeys.PUSH_NOTICES,
					label: t("settings.messages.pushNotices.title"),
					value: {
						render: () => (
							<PushNotices
								methods={() => ""}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: TabAccessNames[TabKeys.PUSH_NOTICES],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);
	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default MessageSettings;
