/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Reports } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	reportData: [],
};

const setReportsData: ReduxReducer<State, State> = (state, action) => {
	state.reportData = action.payload.reportData;
};

const reportAgentOrdersReportData = createSlice({
	name: Reports.ConstantReportSlice.AGENT_ORDERS_REPORT,
	initialState,
	reducers: {
		setReportsData,
	},
});

export const reportAgentOrdersReportDataAction =
	reportAgentOrdersReportData.actions;
export const reportAgentOrdersReportDataReducer =
	reportAgentOrdersReportData.reducer;
export type reportAgentOrdersReportDataState = State;

export default reportAgentOrdersReportData;
