import React, { memo, useCallback, useLayoutEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useInternal } from "uikit";

import useKeyBind from "../../../../hooks/useKeyBind";
import { BaseElementIdEnum } from "../../../common";
import { Footer } from "../../../common/Modal";

import BaseModal from "../BaseModal";

// import Footer from "../Footer";

const BaseCheckCode: React.FC<BaseCheckCode.Props> = ({
	onSubmit,
	onClose,
	content,
	footer,
	footerDivider,
	...props
}) => {
	const [nextStep, setNextStep] = useInternal<BaseElementIdEnum>(
		BaseElementIdEnum.SAVE,
	);
	const [action, setAction] = useInternal<"save" | "close">("save");

	const onSave = useCallback(() => {
		onSubmit();
	}, [onSubmit]);

	useKeyBind(["Escape"], (event) => {
		onClose();
		event.preventDefault();
		event.stopPropagation();
	});

	useKeyBind(["Enter"], (event) => {
		if (action === "close") onClose(event);
		else onSave();
		event.preventDefault();
		event.stopPropagation();
	});

	const debounced = useDebouncedCallback(() => {
		nextElementById(BaseElementIdEnum.SAVE);
		setAction("save");
	}, 300);

	const nextElementById = useCallback(
		(elemId: string): HTMLElement | null => {
			const elem = document.getElementById(elemId);
			if (elem) elem.focus();
			return elem;
		},
		[],
	);

	useLayoutEffect(() => {
		nextElementById(BaseElementIdEnum.SAVE);
		setAction("save");
		debounced();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useKeyBind(["Tab"], (event) => {
		if (nextStep === BaseElementIdEnum.SAVE) {
			setAction("close");
			nextElementById(BaseElementIdEnum.CLOSE);
			setNextStep(BaseElementIdEnum.CLOSE);
		} else {
			setAction("save");
			nextElementById(BaseElementIdEnum.SAVE);
			setNextStep(BaseElementIdEnum.SAVE);
		}

		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<BaseModal {...props} onClose={onClose}>
			{content}
			<Footer onSave={onSave} onClose={onClose} divider={footerDivider}>
				{footer}
			</Footer>
		</BaseModal>
	);
};

declare namespace BaseCheckCode {
	interface Props extends BaseModal.Props {
		value: any;
		content: React.ReactNode;
		footer?: React.ReactNode;
		header?: React.ReactNode;
		footerDivider?: boolean;
		headerDivider?: boolean;
		hideDefaultButtons?: boolean;
		onSubmit: () => void;
		onClose: (
			event?:
				| KeyboardEvent
				| React.MouseEvent<HTMLButtonElement, MouseEvent>
				| undefined,
		) => void;
	}

	type InputModalContent<T> = {
		value: T;
		setValue: React.Dispatch<React.SetStateAction<T>>;
		onSubmit: () => void;
		onClose: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	};
}

export const CheckCode = memo(BaseCheckCode);
export default BaseCheckCode;
