import { modelResolvers } from "prpcow";
import { uniq } from "lodash";

import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery, {
	CreateRPCQueryOptions,
} from "../../utils/createRPCQuery.util";

abstract class Base {
	protected static request(
		callback: (prpc: any) => any,
		options: CreateRPCQueryOptions = {},
	) {
		const prpc = getPRPC();

		if (!prpc) return null;

		return createRPCQuery(() => callback(prpc), options);
	}

	protected static JSONLike<T extends Record<string, unknown>>(
		params: T,
	): ReturnType<typeof modelResolvers.DefaultModelResolver.JSONLike> {
		return modelResolvers.DefaultModelResolver.JSONLike(params);
	}

	protected static getIds(data: { id: number }[]) {
		if (!data?.length) return [];
		return uniq<number>(data?.map(({ id }) => id));
	}
}

export default Base;
