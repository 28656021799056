import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div<{ active?: boolean }>`
	height: 26px;
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	color: ${({ active }) =>
		active ? theme.colors.accent : theme.colors.secondary};
	font-family: Lato;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;

	white-space: nowrap;
	cursor: pointer;

	&::before {
		content: "";
		display: ${({ active }) => (active ? "block" : "none")};
		height: 2px;
		position: absolute;
		bottom: -5px;
		left: auto;
		right: auto;
		width: 40px;
		border-radius: 3px 3px 0px 0px;
		background-color: ${theme.colors.accent};
	}
`;

export default Root;
