import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import MapSettings from "../../../../../../../../../../../../../../services/MapSettings";
import MapService from "./components/MapService";
import tPath from "../../../../../../../../constants/tPath";
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";
import { MapResolutions } from "../../../../../../../../../../../../../../services/MapSettings/types";

const path = `${tPath}.modal.tabs.main.mapServices`;

const MapServices: React.FC<MapServices.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const mapServices = valueEditor.useGetter("mapServices");
	const setMapServices = valueEditor.useSetter("mapServices");
	const mapServicesEditor = useObjectEditor(mapServices, setMapServices);
	const route = mapServicesEditor.useGetter("route");
	const setRoute = mapServicesEditor.useSetter("route");
	const search = mapServicesEditor.useGetter("search");
	const setSearch = mapServicesEditor.useSetter("search");
	const geocode = mapServicesEditor.useGetter("geocode");
	const setGeocode = mapServicesEditor.useSetter("geocode");
	const pickupDistance = mapServicesEditor.useGetter("pickupDistance");
	const setPickupDistance = mapServicesEditor.useSetter("pickupDistance");
	const pickupTime = mapServicesEditor.useGetter("pickupTime");
	const setPickupTime = mapServicesEditor.useSetter("pickupTime");
	const travelTime = mapServicesEditor.useGetter("travelTime");
	const setTravelTime = mapServicesEditor.useSetter("travelTime");

	const addMapServices = valueEditor.useGetter("additionalMapServices");
	const setAddMapServices = valueEditor.useSetter("additionalMapServices");
	const additionalEditor = useObjectEditor(addMapServices, setAddMapServices);
	const addRoute = additionalEditor.useGetter("route");
	const setAddRoute = additionalEditor.useSetter("route");
	const addSearch = additionalEditor.useGetter("search");
	const setAddSearch = additionalEditor.useSetter("search");
	const addGeocode = additionalEditor.useGetter("geocode");
	const setAddGeocode = additionalEditor.useSetter("geocode");

	return (
		<>
			<MapService
				label={t(`${path}.search`) || ""}
				value={search}
				onChange={setSearch}
			/>
			<MapService
				label={t(`${path}.addSearch`) || ""}
				value={addSearch}
				onChange={setAddSearch}
			/>
			<MapService
				label={t(`${path}.geocode`) || ""}
				value={geocode}
				onChange={setGeocode}
			/>
			<MapService
				label={t(`${path}.addGeocode`) || ""}
				value={addGeocode}
				onChange={setAddGeocode}
			/>
			<MapService
				label={t(`${path}.route`) || ""}
				value={route}
				onChange={setRoute}
			/>
			<MapService
				label={t(`${path}.addRoute`) || ""}
				value={addRoute}
				onChange={setAddRoute}
			/>
			<MapService
				label={t(`${path}.addRoute`) || ""}
				value={addRoute}
				onChange={setAddRoute}
			/>
			<MapService
				options={getOptions([
					MapResolutions.GOOGLE,
					MapResolutions.HERE_MAPS,
				])}
				label={t(`${path}.pickupTime`) || ""}
				value={pickupTime}
				onChange={setPickupTime as any}
			/>
			<MapService
				options={getOptions([
					MapResolutions.GOOGLE,
					MapResolutions.HERE_MAPS,
				])}
				label={t(`${path}.travelTime`) || ""}
				value={travelTime}
				onChange={setTravelTime as any}
			/>
			<MapService
				label={t(`${path}.pickupDistance`) || ""}
				value={pickupDistance}
				onChange={setPickupDistance}
			/>
		</>
	);
});

declare namespace MapServices {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		mapServices: MapSettings.MapServices;
		additionalMapServices: MapSettings.AdditionalMapServices;
	}
}

export default MapServices;
