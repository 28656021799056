import styled from "styled-components";
import { theme } from "uikit";

import { StyledRow } from "../../../../../../../../common";

const Root = styled(StyledRow)`
	align-items: center;

	padding: 16px;
	height: 48px;

	border-bottom: 1px solid ${theme.colors.color_border_basic};
	cursor: pointer;
`;

export default Root;
