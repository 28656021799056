/* eslint-disable react/require-default-props */

import React, { PropsWithChildren } from "react";
import { Row } from "uikit";

interface Props extends PropsWithChildren {
	style?: Row.Props["style"];
}

const Header: React.FC<Props> = ({ children, style }) => (
	<Row
		maxedWidth
		justify="space-between"
		align="center"
		gaps="10px*"
		style={{
			paddingBlock: "8px",
			paddingInline: "10px",
			boxShadow: `0px 3px 8px rgba(0,0,0,0.08)`,
			...style,
		}}
	>
		{children}
	</Row>
);

export default Header;
