import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useActiveTab from "../../../../hooks/useActiveTab";
import { SettingsTab } from "../../../../components/Settings";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { SideTab } from "../../../../components/common";

import Main from "./tabs/Main";

type Key = string | number;

interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

const MapSettings = () => {
	const { t } = useTranslation();

	const tabs = useMemo<Option<SettingsTab>[]>(
		() => [
			{
				key: "main",
				label: t("settings.map.tabs.main.title"),
				value: {
					render() {
						return <Main />;
					},
				},
			},
		],
		[t],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default MapSettings;
