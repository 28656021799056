import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "uikit";

const PreviousIntervalOrStandard: React.FC<PreviousIntervalOrStandard.Props> =
	memo(() => {
		const { t } = useTranslation();
		return (
			<p
				style={{
					width: 400,
					textAlign: "center",
					border: `1px solid ${theme.colors.color_border_basic}`,
					padding: "6px 15px",
					borderRadius: "5px",
				}}
			>
				{t(
					"pages.settings.pages.tariffs.tabs.main.modal.content.tabs.tariffScaleTab.modal.content.previousIntervalOrStandard.str150",
				) ?? ""}
			</p>
		);
	});

declare namespace PreviousIntervalOrStandard {
	interface Props {}
}

export default PreviousIntervalOrStandard;
