import { createObjectFromConstant } from "../../../../../assets/languages/langs";
import ExecutorGroup, {
	ShowCustomerPhoneNumbersType,
} from "../../../../../services/ExecutorGroup";

const defaultExecutorTeam: Omit<ExecutorGroup.Model, "id"> & { id?: number } = {
	name: createObjectFromConstant(),
	active: true,
	default: false,
	executorIds: [],
	taxiServiceIds: [],
	configuration: {
		enableReOffer: false,
		displayRequiredOffersInLiveTabAfterCancel: false,
		displayRequiredOffersInLiveTabAfterExpire: false,
		displayFreeWayOffersInLiveTabAfterCancel: false,
		displayFreeWayOffersInLiveTabAfterExpire: false,
		returnToParkingOnMandatoryOrderRefusal: false,
		returnToParkingOnFreeWaveOrderRefusal: false,
		returnToParkingOnMandatoryOrderAfterExpire: false,
		returnToParkingOnDispatcherRemovalFromMandatoryOrder: false,
		returnToParkingOnOrderCancellation: false,

		allowExecutorParking: true,
		allowExecutorParkingByGeolocation: false,
		allowParkingWithDebtStatus: false,
		allowParkingWithLunchStatus: false,
		allowParkingWithHomeStatus: false,
		allowParkingWithBusyStatus: false,

		autoAssignToParkingAfterOrderClosure: false,
		suggestParkingAfterOrderClosure: false,
		autoParkWhenInParkingZone: false,

		suggestParkingByGPSAfterShiftStart: false,
		autoLeaveParkingWhenOutOfZone: false,
		restrictParkingUntilAllOrdersClosed: false,
		excludePreliminaryOrder: false,
		excludeOwnOrder: false,

		showOwnOrdersInBroadcastForParkingOnly: false,
		showOwnPreOrdersInBroadcastForParkingOnly: false,
		showMarketOrdersInBroadcastForParkingOnly: false,
		showMarketPreOrdersInBroadcastForParkingOnly: false,

		showOrdersInBroadcastForOnOrderStatus: false,
		showOrdersInBroadcastForOwnOrderStatus: false,
		showOrdersInBroadcastForDebtStatus: false,
		showOrdersInBroadcastForLunchStatus: false,
		showOrdersInBroadcastForHomeStatus: false,
		showOrdersInBroadcastForBusyStatus: false,
		showExecutorsPreOrdersInBroadcast: false,

		allowTakingOrdersWithOnOrderStatus: false,
		allowTakingOrdersWithOwnOrderStatus: false,
		allowTakingOrdersWithDebtStatus: false,
		allowTakingOrdersWithLunchStatus: false,
		allowTakingOrdersWithHomeStatus: false,
		allowTakingOrdersWithBusyStatus: false,
		allowTakingPreOrdersInBroadcast: false,
	},
	showCustomerPhoneNumbers: {
		active: false,
		type: ShowCustomerPhoneNumbersType.Always,
		conditions: {
			showManually: false,
			afterArrivedMessage: false,
			afterWaitingCustomerMessage: false,
		},
		beforeArrivalTime: {
			active: false,
			threshold: 0,
		},
		afterAcceptingOrder: {
			active: false,
			threshold: 0,
		},
	},
	allowBeforeCompletion: {
		active: false,
		maxOrders: 0,
		ignorePreliminary: false,
		completionSources: [],
	},

	taxiServices: [],
	companyIds: [],
	id: undefined,
	executorAppId: 0,
	priority: 0,
	isAllowToSelectCarClassesInApp: false,
};

export default defaultExecutorTeam;
