import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "uikit";

import { useCurrencyGlobalSettings } from "../../../../../../../../../../hooks";
import Surcharge from "../../../Modal/components/Content/tabs/GeneralSurcharges/components/Surcharge";
import tPath from "../../../../constants/tPath";
import { TariffCommonSurchargeAdjustment } from "../../../../../../../../../../types/TariffCommonSurchargeAdjustment";

const GeneralSurchargeOutput: React.FC<GeneralSurchargeOutput.Props> = memo(
	({ value }) => {
		const { t } = useTranslation();
		const currencyGlobalSettings = useCurrencyGlobalSettings();

		const isUah =
			value.type ===
				TariffCommonSurchargeAdjustment.SURCHARGE_TO_ORDER_SUM ||
			value.type ===
				TariffCommonSurchargeAdjustment.SURCHARGE_TO_COST_PER_KM;

		return value.active ? (
			<Row gaps="5px*">
				<div>
					{t(
						`${tPath}.modal.tabs.generalSurcharges.surcharges.options.${value.type}`,
					)}
				</div>
				<div>{value.value}</div>
				{isUah && <div> {currencyGlobalSettings}</div>}
			</Row>
		) : (
			<div>
				{t(`${tPath}.modal.tabs.generalSurcharges.surcharges.disabled`)}
			</div>
		);
	},
);

declare namespace GeneralSurchargeOutput {
	interface Props {
		value: Surcharge.Value;
	}
}

export default GeneralSurchargeOutput;
