import * as ModelEvent from "@node-elion/syncron";

import SubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import formatNumber from "../../utils/formatNumber";

export enum RewardCalculationMethod {
	ADD_TO_ORDER = "add_to_order",
	CUT_FROM_ORDER = "cut_from_order",
}

class Reward extends Base {
	public static fromResponse(data: any): Reward.Model {
		const percent = Number(Number(data?.percent || 0).toFixed(0));
		const maxAmountFromOrderInPercent = Number(
			Number(data?.maxAmountFromOrderInPercent || 0).toFixed(0),
		);

		const amount = Number(formatNumber(Number(data?.amount || 0)));

		const payload: Reward.Model = {
			id: data.id,
			active: data.active,
			default: data.default,

			name: data.name,

			percent,
			amount,
			maxAmountFromOrderInPercent,
			rewardCalculationMethod: data.rewardCalculationMethod,

			agentId: data.agentId,
			rewardToAgents: data.rewardToAgents || [],

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};

		return payload;
	}

	public static toRequest(model: Reward.New | Reward.Modified) {
		const payload: Record<string, any> = {
			active: model.active,
			default: model.default,
			name: model.name,
			rewardCalculationMethod: model.rewardCalculationMethod,
			percent: model.percent,
			amount: model.amount,
			maxAmountFromOrderInPercent: model.maxAmountFromOrderInPercent,
			agentId: model.agentId,
		};

		console.log("[Reward] toRequest", { payload, model });
		return payload;
	}

	public static async store(object: Reward.New): Promise<any> {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.reward.create(this.toRequest(object)),
				{ silent: false, error: true },
			);

			console.log("[Reward] store", { res, object });
			if (res.error) return res;

			return this.fromResponse(res);
		} catch (err) {
			console.error("[Reward] Error store", err);
			return null;
		}
	}

	public static async update(object: Reward.Modified): Promise<any> {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.reward.update(
						object.id,
						this.toRequest(object),
					),
				{ silent: false, error: true },
			);

			console.log("[Reward] update", { res, object });
			if (res.error) return res;
			return this.fromResponse(res);
		} catch (err) {
			console.error("[Reward] Error update", err);
			return null;
		}
	}

	public static async delete(ids: number[] | number) {
		await this.request((prpc) => prpc.theirsModel.reward.delete(ids));
	}

	public static async getById(
		id?: number | null,
	): Promise<Reward.Model | null> {
		try {
			if (!id) return null;

			const res = await this.request((prpc) =>
				prpc.theirsModel.reward.getById(id),
			);
			console.log("[Reward] getById", { res, id });

			return res;
		} catch (err) {
			console.error("[Reward] Error update", err);
			return null;
		}
	}

	public static async subscribe(
		options: Reward.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Reward.Model>,
	): Promise<Subscription<Reward.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Reward] subscribe", { state });
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.reward.subscribe({
					params: options,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{ name: "Reward.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Reward.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<Reward.SubscribeOptions>;
	}
}

declare namespace Reward {
	interface Model extends NonEditableProperties {
		active: boolean;
		default: boolean;

		percent: number;
		amount: number;
		maxAmountFromOrderInPercent: number;
		rewardCalculationMethod: RewardCalculationMethod;
		name: string;
		agentId?: number | null;
		rewardToAgents?: Record<string, any>[];
	}

	type New = Omit<Model, NonEditablePropertyNames>;

	type Modified = Partial<New> & {
		readonly id: number;
	};

	type RewardOrder = Record<
		| "default"
		| "active"
		| "createdAt"
		| "percent"
		| "amount"
		| "maxAmountFromOrderInPercent"
		| "rewardCalculationMethod",
		"ASC" | "DESC"
	>;

	interface SubscribeOptions extends SubscribeOptionsBase<RewardOrder> {
		active?: boolean;
		default?: boolean;
		agentId?: number;
	}
}

export default Reward;
