import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option, Select } from "uikit";

import { ChartType } from "./Chart";

interface Props {
	value: ChartType;
	onChange: (value: ChartType) => void;
}

const OPTIONS: Option<ChartType>[] = [
	{
		key: "linear",
		label: "mainPage.reports.charts.type.linear",
		value: "linear",
	},
	{
		key: "columnar",
		label: "mainPage.reports.charts.type.columnar",
		value: "columnar",
	},
	{
		key: "circular",
		label: "mainPage.reports.charts.type.circular",
		value: "circular",
	},
];

const ChartTypeSelect: React.FC<Props> = ({ value, onChange }) => {
	const { t } = useTranslation();

	const options = useMemo<Option<ChartType>[]>(
		() =>
			OPTIONS.map((option) => ({
				...option,
				label: t(option.label) || option.label,
			})),
		[t],
	);

	return (
		<Select<ChartType>
			value={value}
			onChange={(key) => {
				onChange(key as ChartType);
			}}
			options={options}
		/>
	);
};

export default ChartTypeSelect;
