import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import { CheckBox, Stepper, react } from "uikit";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../hooks/useObjectEditor";
import PaymentSystem from "../../../../../../services/PaymentSystem";
import {
	StyledColumn,
	StyledRow,
	StyledP,
} from "../../../../../../components/common";

import InternalController from "./Controller";
import ValueStepper from "../ValueStepper";

const FondyPaymentBase = react.withController<
	FondyPayment.PropsBase,
	FondyPayment.Controller
>(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const fondyGlobalFeeFromSystemValue = valueEditor.useGetter(
		"fondyGlobalFeeFromSystem",
	);
	const setFondyGlobalFeeFromSystemValue = valueEditor.useSetter(
		"fondyGlobalFeeFromSystem",
	);

	// 	fondyFeePercentage
	const fondyFeePercentageValue = valueEditor.useGetter("fondyFeePercentage");
	const setFondyFeePercentageValue =
		valueEditor.useSetter("fondyFeePercentage");
	const handleFondyFeePercentage = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setFondyFeePercentageValue(value);
			}
		},
		[setFondyFeePercentageValue],
	);

	//	fondyAdditionalFee
	const fondyAdditionalFeeValue = valueEditor.useGetter("fondyAdditionalFee");
	const setFondyAdditionalFeeValue =
		valueEditor.useSetter("fondyAdditionalFee");
	const handleFondyAdditionalFee = useCallback(
		(value: Stepper.Value) => {
			if (typeof value === "number") {
				setFondyAdditionalFeeValue(value);
			}
		},
		[setFondyAdditionalFeeValue],
	);

	const fondyCurrency = valueEditor.useGetter("fondyCurrency");
	const fondyCountryIso = valueEditor.useGetter("fondyCountryIso");

	return (
		<StyledColumn gap="10px">
			<StyledRow alignItems="center" gap="10px">
				<CheckBox
					label={t(["fondyGlobalFeeFromSystem"]) || ""}
					value={fondyGlobalFeeFromSystemValue}
					onChange={setFondyGlobalFeeFromSystemValue}
				/>
			</StyledRow>
			<StyledRow alignItems="center" gap="10px">
				<StyledP>{"fondyFeePercentageValue :"}</StyledP>
				<ValueStepper
					width="80px"
					value={fondyFeePercentageValue}
					min={0}
					onChange={handleFondyFeePercentage}
				/>
			</StyledRow>
			<StyledRow alignItems="center" gap="10px">
				<StyledP>{"fondyAdditionalFeeValue :"}</StyledP>
				<ValueStepper
					width="80px"
					value={fondyAdditionalFeeValue}
					min={0}
					onChange={handleFondyAdditionalFee}
				/>
			</StyledRow>

			<StyledRow alignItems="center" gap="10px">
				<StyledP>{"fondyCurrency :"}</StyledP>
				<StyledP>{fondyCurrency}</StyledP>
			</StyledRow>

			<StyledRow alignItems="center" gap="10px">
				<StyledP>{"fondyCountryIso :"}</StyledP>
				<StyledP>{fondyCountryIso}</StyledP>
			</StyledRow>
		</StyledColumn>
	);
}, InternalController);
const FondyPayment = memo(FondyPaymentBase);

declare namespace FondyPayment {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends PaymentSystem.Model {}
}

export default FondyPayment;
