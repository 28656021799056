import React, {
	Dispatch,
	SetStateAction,
	memo,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "uikit";

import { ModelId } from "../../../../../../../../../../../../../../types/ModelId";
import getOptions from "../../../../../../../../../../../../../../utils/generateOptions";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";

const Phones: React.FC<Phones.Props> = memo(
	({
		error,
		setError,
		phones,
		mainPhone,
		taxiServiceId,
		value,
		onChange,
	}) => {
		const { t } = useTranslation();
		const selectValue = useMemo(
			() => value.map((item) => item.phone),
			[value],
		);

		const phoneOptions = useMemo(
			() =>
				phones?.length
					? getOptions(phones)
					: getOptions([mainPhone || ""]),
			[phones, mainPhone],
		);

		const handlePhonesChange = useCallback(
			(newPhones) => {
				setError(false);
				if (taxiServiceId) {
					onChange(
						newPhones.map((phone) => ({
							taxiServiceId,
							phone,
						})),
					);
				}
			},
			[onChange, setError, taxiServiceId],
		);

		useEffect(() => {
			if (mainPhone && taxiServiceId) {
				onChange([
					{
						taxiServiceId,
						phone: mainPhone,
					},
				]);
			}
		}, [taxiServiceId, mainPhone, onChange]);

		useEffect(() => {
			console.error("error:", error);
		}, [error]);

		const disabledSelect = useMemo(() => !mainPhone, [mainPhone]);
		return (
			<LabeledField
				label={
					selectValue.length === 1
						? t(
								"pages.mainPage.pages.orders.orderModal.footer.sendingSMS.modal.phones.str200",
						  ) ?? ""
						: t(
								"pages.mainPage.pages.orders.orderModal.footer.sendingSMS.modal.phones.str201",
						  ) ?? ""
				}
			>
				<MultiSelect
					error={error}
					disabled={disabledSelect}
					placeholder={
						t(
							"pages.mainPage.pages.orders.orderModal.footer.sendingSMS.modal.phones.str0",
						) ?? ""
					}
					options={phoneOptions}
					value={selectValue}
					onChange={handlePhonesChange}
				/>
			</LabeledField>
		);
	},
);

declare namespace Phones {
	interface Props {
		mainPhone?: string;
		phones: string[];
		taxiServiceId?: number;
		error: boolean;
		setError: Dispatch<SetStateAction<boolean>>;
		value: Value[];
		onChange: (x: Value[]) => void;
	}

	interface Value {
		phone: string;
		taxiServiceId: ModelId;
	}
}

export default Phones;
